import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material'

import container from './WebsiteDetails.container'
import { Form, Formik } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import { useNavigate, useParams } from 'react-router-dom'
import RichTextEditor from 'components/RichTextEditor'

import * as yup from 'yup'
import { regMatch } from 'utils'
import _ from 'lodash'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onClearMerchantPremiumCode?: () => void

  onCreateClubStart?: (params) => void
  onCreateStep2DraftStart?: (params) => void
  onCreateStep2GeneralStart?: (params) => void
  onFetchMerchantDraftWebsiteInfoPanelUserStart?: (params) => void
  onOpenAlert?: (params) => void
  siteCoordinator?: any
  handleChangeTab?: any
  merchant?: any
  club?: any
  auth?: any
  adminRoute?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  onCloseAddCountry,
  onCreateStep2DraftStart,
  onCreateStep2GeneralStart,
  onFetchMerchantDraftWebsiteInfoPanelUserStart,
  handleChangeTab,
  onOpenAlert,
  siteCoordinator: { globalCountry, recentMerchant },
  merchant: { isCreatingDraft, isCreating, isFetching, draftWebsiteInfo },
  adminRoute,
  onClearMerchantPremiumCode,
}) => {
  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)
  const [fieldIsRequired, setFieldIsRequired] = useState<boolean>(true)

  let { id } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    onFetchMerchantDraftWebsiteInfoPanelUserStart(id)
  }, [id])

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  useEffect(() => {
    onClearMerchantPremiumCode()
  }, [])

  const closeModal = () => {
    onCloseAddCountry()
    setMultiplePersonApply(false)
  }

  const validationSchema = yup.object({
    websiteLink: yup
      .string()
      .nullable()
      .matches(regMatch, 'Website link should be a valid URL'),
    instagramLink: yup
      .string()
      .nullable()
      .matches(regMatch, 'Instagram link should be a valid URL'),
    facebookLink: yup
      .string()
      .nullable()
      .matches(regMatch, 'Facebook link should be a valid URL'),
  })

  const [pageMarkupContent, setPageMarkupContent] = useState('')

  useEffect(() => {
    if (!_.isEmpty(draftWebsiteInfo)) {
      setPageMarkupContent(draftWebsiteInfo?.merchantDescription ?? '')
    }
  }, [draftWebsiteInfo])

  return (
    <>
      {isFetching ? (
        '...loading'
      ) : (
        <Formik
          validateOnChange={true}
          initialValues={{
            websiteLink: draftWebsiteInfo?.websiteLink ?? null,
            instagramLink: draftWebsiteInfo?.instagramLink ?? null,
            openingHourInfo: draftWebsiteInfo?.openingHourInfo ?? '',
            facebookLink: draftWebsiteInfo?.facebookLink ?? null,
            merchantDescription: draftWebsiteInfo?.merchantDescription ?? '',

            countryId: globalCountry?.id,
          }}
          validationSchema={validationSchema}
          onSubmit={(data: any, { setSubmitting }) => {
            setSubmitting(true)

            if (data?.action === 'draft') {
              if (!pageMarkupContent?.length) {
                return onOpenAlert({
                  message: 'Merchant Bio is required',
                  severity: 'error',
                })
              }
              onCreateStep2DraftStart({
                params: {
                  ...data,
                  countryId: globalCountry?.id,
                  stateId: data?.stateId?.id,
                  regionId: data?.regionId?.id,
                  areaId: data?.areaId?.id,
                  merchantId: id ? id : recentMerchant?.id,
                  merchantDescription: pageMarkupContent,
                  websiteLink:
                    data?.websiteLink === '' ? null : data?.websiteLink,
                  instagramLink:
                    data?.instagramLink === '' ? null : data?.instagramLink,
                  facebookLink:
                    data?.facebookLink === '' ? null : data?.facebookLink,
                },
                closeModal,
                handleChangeTab,
                navigate,
                route: `/${adminRoute}/draftList`,
              })
            } else if (data?.action === 'general') {
              if (!pageMarkupContent?.length) {
                return onOpenAlert({
                  message: 'Merchant Bio is required',
                  severity: 'error',
                })
              }
              onCreateStep2GeneralStart({
                params: {
                  ...data,
                  countryId: globalCountry?.id,
                  stateId: data?.stateId?.id,
                  regionId: data?.regionId?.id,
                  areaId: data?.areaId?.id,
                  merchantId: id ? id : recentMerchant?.id,
                  merchantDescription: pageMarkupContent,
                  websiteLink:
                    data?.websiteLink === '' ? null : data?.websiteLink,
                  instagramLink:
                    data?.instagramLink === '' ? null : data?.instagramLink,
                  facebookLink:
                    data?.facebookLink === '' ? null : data?.facebookLink,
                },
                closeModal,
                handleChangeTab,
                refreshTab: true,
              })
            }

            setSubmitting(false)
          }}
          render={({ setFieldValue }) => (
            <Form>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={4}>
                  {/* <Grid item xs={12}>
                    <Typography variant="h4">
                      Website Specific Details
                    </Typography>
                  </Grid> */}

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Website Link'}
                      label={'Website Link'}
                      name="websiteLink"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Instagram Link'}
                      label={'Instagram Link'}
                      name="instagramLink"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Facebook Link'}
                      label={'Facebook Link'}
                      name="facebookLink"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item container xs={12} lg={6} marginY={4}>
                    <AppTextField
                      placeholder={'Business Opening Hours'}
                      label={'Business Opening Hours'}
                      name="openingHourInfo"
                      required={fieldIsRequired}
                      multiline={true}
                      rows={8}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                </Grid>

                {/* <Grid container spacing={3} sx={{ mt: 3 }}>
                  <Grid item xs={12} lg={6}>
                    <AppTextField
                      placeholder={'Merchant Bio'}
                      label={'Merchant Bio'}
                      multiline={true}
                      required={fieldIsRequired}
                      rows={5}
                      name="merchantDescription"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                </Grid> */}

                <Grid item xs={12}>
                  <Typography gutterBottom>Merchant Bio *</Typography>
                  <RichTextEditor
                    markup={pageMarkupContent}
                    setMarkup={setPageMarkupContent}
                  />
                </Grid>

                <Box sx={{ height: 20 }} />
                <Box sx={{ height: 30 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isCreatingDraft}
                    onClick={() => {
                      setFieldIsRequired(false)

                      setFieldValue('action', 'draft')
                    }}
                  >
                    {isCreatingDraft ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Save as Draft'
                    )}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isCreating}
                    onClick={() => {
                      setFieldIsRequired(true)

                      setFieldValue('action', 'general')
                    }}
                  >
                    {isCreating ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Save & Continue'
                    )}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    onClick={() => navigate(`/${adminRoute}/draftList`)}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        />
      )}
    </>
  )
}

export default container(AddNewClub)
