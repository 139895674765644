import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableHeading from './Heading'
import TableItem from './Item'
import {
  TablePagination,
  Box,
  TableRow,
  TableCell,
  CircularProgress,
} from '@mui/material'
import AppTableContainer from '@crema/core/AppTableContainer'
import TableDataNotFound from 'components/TableDataNotFound'

interface prizeDrawTableProps {
  generalSettingsData: any[]
  page: any
  pageNumber: number
  totalCount: any
  loadingTable: any
  setPage: any
  rowsPerPage: any
  setRowsPerPage: any
  handleChangePage: any
  handleChangeRowsPerPage: any
  refreshPageData?: any
  currentUser?: any
  setSelectedObj?: any
  selectedObj?: any
  value?: string

  hasMoreData: boolean
  onDeleteGroupInformationListStart?: (payload?: any) => void
  onChangeNotificationOnOrOffStart?: (payload?: any) => void
  onDeleteNotificationStart?: (payload?: any) => void
  OnMarkNotificationAsUnseenStart?: (payload?: any) => void
}

const PrizeDrawTable: React.FC<prizeDrawTableProps> = ({
  generalSettingsData,
  page,
  pageNumber,
  totalCount,
  loadingTable,
  rowsPerPage,
  hasMoreData,
  handleChangePage,
  handleChangeRowsPerPage,
  refreshPageData,
  onDeleteGroupInformationListStart,
  onChangeNotificationOnOrOffStart,
  onDeleteNotificationStart,
  OnMarkNotificationAsUnseenStart,
  currentUser,
  selectedObj,
  setSelectedObj,
  value,
}) => {
  return (
    <AppTableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          nextIconButtonProps={{ disabled: !hasMoreData }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Table stickyHeader>
        <TableHead>
          <TableHeading />
        </TableHead>
        <TableBody>
          {loadingTable ? (
            <TableRow className="item-hover">
              <TableCell align="center" colSpan={8}>
                <CircularProgress size={20} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {generalSettingsData?.length ? (
                generalSettingsData.map((data, index) => (
                  <TableItem
                    data={data}
                    key={data.sn}
                    count={index + 1}
                    page={page}
                    pageNumber={pageNumber}
                    rowsPerPage={rowsPerPage}
                    refreshPageData={refreshPageData}
                    onDeleteGroupInformationListStart={
                      onDeleteGroupInformationListStart
                    }
                    onChangeNotificationOnOrOffStart={
                      onChangeNotificationOnOrOffStart
                    }
                    OnMarkNotificationAsUnseenStart={
                      OnMarkNotificationAsUnseenStart
                    }
                    onDeleteNotificationStart={onDeleteNotificationStart}
                    currentUser={currentUser}
                    setSelectedObj={setSelectedObj}
                    selectedObj={selectedObj}
                    value={value}
                  />
                ))
              ) : (
                <TableDataNotFound />
              )}
            </>
          )}
        </TableBody>
      </Table>
    </AppTableContainer>
  )
}

export default PrizeDrawTable
