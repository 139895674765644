// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchReviewTextList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/reviewText/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createReviewText = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/reviewText/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOneReviewTextById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/reviewText/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteReviewText = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/reviewText/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const activeDeactiveReviewText = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/reviewText/activeDeactive/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editReviewText = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/reviewText/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantReviewList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantReview/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantReviewListByAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantReview/getAllByAdmin`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
