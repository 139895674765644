import { createSlice } from '@reduxjs/toolkit'

import { IGlobalAdminSettings } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IGlobalAdminSettings = {
  prizeDrawList: listObj,
  couponList: listObj,
  couponCodeList: listObj,
  solePrizeDraw: {},
  soleGeneralSetting: {},
  soleCoupon: {},
  soleDocument: {},
  smtpData: {},
  soleGroupInformation: {},
  soleContactInformation: {},
  soleCouponSetting: {},
  soleCompany: {},
  soleAppRangeSetting: {},
  soleDocumentManagementSetting: {},
  soleEmailData: {},
  soleNotification: {},
  notificationListByUser: {},
  payoutInvoiceLog: {},
  invoiceInfoLog: [],
  generalSettingList: listObj,
  piiinkInformationGetFirst: listObj,
  piiinkInformationGetByCountry: listObj,
  groupInformationList: listObj,
  contactList: listObj,
  documentList: listObj,
  emailList: listObj,
  reportList: listObj,
  notificationList: listObj,
  reportListById: listObj,
  couponSettingList: listObj,
  appRangeSettingList: listObj,
  documentManagementSettingList: listObj,
  temporaryPasswordList: listObj,
  allNotificationList: listObj,
  companyList: listObj,
  paymentLogHistoryList: listObj,

  error: null,
  loading: false,
  isFetching: false,
  isFetchingNotification: false,
  isCreating: false,
  isEditing: false,
  isActivating: false,
  isDeleting: false,
  isGenerating: false,
  isSending: false,
  isSendingAsDraft: false,
  isChanging: false,
  hasMoreData: false,
  isFetchingGroup: false,
}

export const globalAdminSettingsSlice = createSlice({
  name: 'globalSettings',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPrizeDrawStart: (state: any, action) => {
      state.loading = true
    },
    fetchPrizeDrawSuccess: (state: any, action) => {
      state.prizeDrawList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPrizeDrawFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchPrizeDrawByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPrizeDrawByIdSuccess: (state: any, action) => {
      state.solePrizeDraw = action.payload.data
      state.isFetching = false
    },
    fetchPrizeDrawByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGeneralSettingStart: (state: any, action) => {
      state.loading = true
    },
    fetchGeneralSettingSuccess: (state: any, action) => {
      state.generalSettingList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchGeneralSettingFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPiiinkInformationGetFirstStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPiiinkInformationGetFirstSuccess: (state: any, action) => {
      state.piiinkInformationGetFirst = action.payload
      state.isFetching = false
    },
    fetchPiiinkInformationGetFirstFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchPiiinkInformationGetByCountryStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPiiinkInformationGetByCountrySuccess: (state: any, action) => {
      state.piiinkInformationGetByCountry = action.payload
      state.isFetching = false
    },
    fetchPiiinkInformationGetByCountryFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchGeneralSettingByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchGeneralSettingByIdSuccess: (state: any, action) => {
      state.soleGeneralSetting = action.payload.data
      state.isFetching = false
    },
    fetchGeneralSettingByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editGeneralSettingStart: (state: any, action) => {
      state.isEditing = true
    },
    editGeneralSettingSuccess: (state: any, action) => {
      const result = state.generalSettingList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.generalSettingList.data = result
      state.isEditing = false
    },
    editGeneralSettingFailure: (state: any, action) => {
      state.loading = false
      state.isEditing = false
      state.error = action.payload
    },

    createPrizeDrawStart: (state: any, action) => {
      state.loading = true
      state.isCreating = true
    },
    createPrizeDrawSuccess: (state: any, action) => {
      state.loading = false
      state.isCreating = false
      state.prizeDrawList.data = [...state.prizeDrawList.data, action.payload]
    },
    createPrizeDrawFailure: (state: any, action) => {
      state.loading = false
      state.isCreating = false
      state.error = action.payload
    },

    createGeneralSettingsStart: (state: any, action) => {
      state.isCreating = true
    },
    createGeneralSettingsSuccess: (state: any, action) => {
      // state.generalSettingList.data = [
      //   ...state.generalSettingList.data,
      //   action.payload,
      // ]
      state.isCreating = false
    },
    createGeneralSettingsFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editPrizeDrawStart: (state: any, action) => {
      state.loading = true
      state.isEditing = true
    },
    editPrizeDrawSuccess: (state: any, action) => {
      const result = state.prizeDrawList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.prizeDrawList.data = result
      state.isEditing = false
    },
    editPrizeDrawFailure: (state: any, action) => {
      state.loading = false
      state.isEditing = false
      state.error = action.payload
    },

    createCouponStart: (state: any, action) => {
      state.isCreating = true
    },
    createCouponSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createCouponFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchCouponListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCouponListSuccess: (state: any, action) => {
      state.couponList = action.payload
      state.isFetching = false
    },
    fetchCouponListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCouponListByPanelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCouponListByPanelSuccess: (state: any, action) => {
      state.couponList = action.payload
      state.isFetching = false
    },
    fetchCouponListByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editCouponStart: (state: any, action) => {
      state.isEditing = true
    },
    editCouponSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editCouponFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    getOneCouponStart: (state: any, action) => {
      state.isFetching = true
    },
    getOneCouponSuccess: (state: any, action) => {
      state.soleCoupon = action.payload
      state.isFetching = false
    },
    getOneCouponFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createCouponCodeStart: (state: any, action) => {
      state.isCreating = true
    },
    createCouponCodeSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createCouponCodeFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchCouponCodeListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCouponCodeListSuccess: (state: any, action) => {
      state.couponList = action.payload
      state.isFetching = false
    },
    fetchCouponCodeListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCouponCodeListByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCouponCodeListByIdSuccess: (state: any, action) => {
      state.couponCodeList = action.payload
      state.isFetching = false
    },
    fetchCouponCodeListByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    activateDeactivateCouponByIdStart: (state: any, action) => {
      state.isActivating = true
    },
    activateDeactivateCouponByIdSuccess: (state: any, action) => {
      state.isActivating = false
    },
    activateDeactivateCouponByIdFailure: (state: any, action) => {
      state.isActivating = false
      state.error = action.payload
    },

    fetchSmtpDataByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchSmtpDataByIdSuccess: (state: any, action) => {
      state.smtpData = action.payload
      state.isFetching = false
    },
    fetchSmtpDataByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editSmtpConfigurationStart: (state: any, action) => {
      state.isEditing = true
    },
    editSmtpConfigurationSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editSmtpConfigurationFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    sendTestEmailStart: (state: any, action) => {
      state.isSending = true
    },
    sendTestEmailSuccess: (state: any, action) => {
      state.isSending = false
    },
    sendTestEmailFailure: (state: any, action) => {
      state.isSending = false
      state.error = action.payload
    },

    fetchGroupInformationListStart: (state: any, action) => {
      state.isFetchingGroup = true
    },
    fetchGroupInformationListSuccess: (state: any, action) => {
      state.groupInformationList = action.payload
      state.isFetchingGroup = false
    },
    fetchGroupInformationListFailure: (state: any, action) => {
      state.isFetchingGroup = false
      state.error = action.payload
    },

    fetchGroupInformationListByPanelStart: (state: any, action) => {
      state.isFetchingGroup = true
    },
    fetchGroupInformationListByPanelSuccess: (state: any, action) => {
      state.groupInformationList = action.payload
      state.isFetchingGroup = false
    },
    fetchGroupInformationListByPanelFailure: (state: any, action) => {
      state.isFetchingGroup = false
      state.error = action.payload
    },

    createGroupInformationListStart: (state: any, action) => {
      state.isCreating = true
    },
    createGroupInformationListSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createGroupInformationListFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    deleteGroupInformationListStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteGroupInformationListSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteGroupInformationListFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    fetchGroupInformationByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchGroupInformationByIdSuccess: (state: any, action) => {
      state.soleGroupInformation = action.payload
      state.isFetching = false
    },
    fetchGroupInformationByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editGroupInformationListStart: (state: any, action) => {
      state.isEditing = true
    },
    editGroupInformationListSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editGroupInformationListFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchContactListStart: (state: any, action) => {
      state.loading = true
    },
    fetchContactListSuccess: (state: any, action) => {
      state.contactList = action.payload
      state.loading = false
    },
    fetchContactListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchContactListByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchContactListByPanelSuccess: (state: any, action) => {
      state.contactList = action.payload
      state.loading = false
    },
    fetchContactListByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createContactInfoStart: (state: any, action) => {
      state.isCreating = true
    },
    createContactInfoSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createContactInfoFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchContactByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchContactByIdSuccess: (state: any, action) => {
      state.soleContactInformation = action.payload
      state.isFetching = false
    },
    fetchContactByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editContactInfoStart: (state: any, action) => {
      state.isEditing = true
    },
    editContactInfoSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editContactInfoFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    deleteContactInfoStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteContactInfoSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteContactInfoFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    updateCodesFromCSVStart: (state: any, action) => {
      state.isSending = true
    },
    updateCodesFromCSVSuccess: (state: any, action) => {
      state.isSending = false
    },
    updateCodesFromCSVFailure: (state: any, action) => {
      state.isSending = false
      state.error = action.payload
    },

    fetchDocumentListStart: (state: any, action) => {
      state.loading = true
    },
    fetchDocumentListSuccess: (state: any, action) => {
      state.documentList = action.payload
      state.loading = false
    },
    fetchDocumentListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchDocumentListByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchDocumentListByPanelSuccess: (state: any, action) => {
      state.documentList = action.payload
      state.loading = false
    },
    fetchDocumentListByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createDocumentStart: (state: any, action) => {
      state.isCreating = true
    },
    createDocumentSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createDocumentFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editDocumentStart: (state: any, action) => {
      state.isEditing = true
    },
    editDocumentSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editDocumentFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchDocumentByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchDocumentByIdSuccess: (state: any, action) => {
      state.soleDocument = action.payload
      state.isFetching = false
    },
    fetchDocumentByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    deleteDocumentStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteDocumentSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteDocumentFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    fetchEmailListStart: (state: any, action) => {
      state.loading = true
    },
    fetchEmailListSuccess: (state: any, action) => {
      state.emailList = action.payload
      state.loading = false
    },
    fetchEmailListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchEmailDataByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchEmailDataByIdSuccess: (state: any, action) => {
      state.soleEmailData = action.payload
      state.loading = false
    },
    fetchEmailDataByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchEmailListByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchEmailListByPanelSuccess: (state: any, action) => {
      state.emailList = action.payload
      state.loading = false
    },
    fetchEmailListByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    sendEmailAsDraftStart: (state: any, action) => {
      state.isSendingAsDraft = true
    },
    sendEmailAsDraftSuccess: (state: any, action) => {
      state.isSendingAsDraft = false
    },
    sendEmailAsDraftFailure: (state: any, action) => {
      state.isSendingAsDraft = false
      state.error = action.payload
    },

    saveAndSendEmailStart: (state: any, action) => {
      state.isSending = true
    },
    saveAndSendEmailSuccess: (state: any, action) => {
      state.isSending = false
    },
    saveAndSendEmailFailure: (state: any, action) => {
      state.isSending = false
      state.error = action.payload
    },

    generateTemporaryPasswordStart: (state: any, action) => {
      state.isGenerating = true
    },
    generateTemporaryPasswordSuccess: (state: any, action) => {
      state.isGenerating = false
    },
    generateTemporaryPasswordFailure: (state: any, action) => {
      state.isGenerating = false
      state.error = action.payload
    },

    fetchTemporaryPasswordListStart: (state: any, action) => {
      state.loading = true
    },
    fetchTemporaryPasswordListSuccess: (state: any, action) => {
      state.temporaryPasswordList = action.payload
      state.loading = false
    },
    fetchTemporaryPasswordListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCouponSettingListStart: (state: any, action) => {
      state.loading = true
    },
    fetchCouponSettingListSuccess: (state: any, action) => {
      state.couponSettingList = action.payload
      state.loading = false
    },
    fetchCouponSettingListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createCouponSettingStart: (state: any, action) => {
      state.isCreating = true
    },
    createCouponSettingSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createCouponSettingFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchCouponSettingByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCouponSettingByIdSuccess: (state: any, action) => {
      state.soleCouponSetting = action.payload
      state.isFetching = false
    },
    fetchCouponSettingByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editCouponSettingStart: (state: any, action) => {
      state.isEditing = true
    },
    editCouponSettingSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editCouponSettingFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchAppRangeSettingListStart: (state: any, action) => {
      state.loading = true
    },
    fetchAppRangeSettingListSuccess: (state: any, action) => {
      state.appRangeSettingList = action.payload
      state.loading = false
    },
    fetchAppRangeSettingListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createAppRangeSettingStart: (state: any, action) => {
      state.isCreating = true
    },
    createAppRangeSettingSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createAppRangeSettingFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchAppRangeSettingByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAppRangeSettingByIdSuccess: (state: any, action) => {
      state.soleAppRangeSetting = action.payload
      state.isFetching = false
    },
    fetchAppRangeSettingByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editAppRangeSettingStart: (state: any, action) => {
      state.isEditing = true
    },
    editAppRangeSettingSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editAppRangeSettingFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchDocumentManagementSettingListStart: (state: any, action) => {
      state.loading = true
    },
    fetchDocumentManagementSettingListSuccess: (state: any, action) => {
      state.documentManagementSettingList = action.payload
      state.loading = false
    },
    fetchDocumentManagementSettingListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createDocumentManagementSettingStart: (state: any, action) => {
      state.isCreating = true
    },
    createDocumentManagementSettingSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createDocumentManagementSettingFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchDocumentManagementSettingByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchDocumentManagementSettingByIdSuccess: (state: any, action) => {
      state.soleDocumentManagementSetting = action.payload
      state.isFetching = false
    },
    fetchDocumentManagementSettingByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editDocumentManagementSettingStart: (state: any, action) => {
      state.isEditing = true
    },
    editDocumentManagementSettingSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editDocumentManagementSettingFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchReportListStart: (state: any, action) => {
      state.loading = true
    },
    fetchReportListSuccess: (state: any, action) => {
      state.reportList = action.payload
      state.loading = false
    },
    fetchReportListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchReportListByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchReportListByIdSuccess: (state: any, action) => {
      state.reportListById = action.payload
      state.loading = false
    },
    fetchReportListByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchReportListByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchReportListByPanelSuccess: (state: any, action) => {
      state.reportList = action.payload
      state.loading = false
    },
    fetchReportListByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    assignContactsToGroupStart: (state: any, action) => {
      state.loading = true
    },
    assignContactsToGroupSucccess: (state: any, action) => {
      state.loading = false
    },
    assignContactsToGroupFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createNotificationStart: (state: any, action) => {
      state.isCreating = true
    },
    createNotificationSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createNotificationFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editNotificationStart: (state: any, action) => {
      state.isEditing = true
    },
    editNotificationSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editNotificationFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchNotificationListStart: (state: any, action) => {
      state.loading = true
    },
    fetchNotificationListSuccess: (state: any, action) => {
      state.notificationList = action.payload
      state.loading = false
    },
    fetchNotificationListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchNotificationByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchNotificationByIdSuccess: (state: any, action) => {
      state.soleNotification = action.payload
      state.isFetching = false
    },
    fetchNotificationByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchNotificationByUserStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchNotificationByUserSuccess: (state: any, action) => {
      state.notificationListByUser = action.payload
      state.isFetching = false
    },
    fetchNotificationByUserFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchNotificationByUserTypeSeenStart: (state: any, action) => {
      state.allNotificationList = []
      state.isFetchingNotification = true
    },
    fetchNotificationByUserTypeSeenSuccess: (state: any, action) => {
      state.allNotificationList = action.payload
      state.isFetchingNotification = false
    },
    fetchNotificationByUserTypeSeenFailure: (state: any, action) => {
      state.isFetchingNotification = false
      state.error = action.payload
    },

    fetchNotificationByUserTypeUnSeenStart: (state: any, action) => {
      state.allNotificationList = []

      state.isFetchingNotification = true
    },
    fetchNotificationByUserTypeUnSeenSuccess: (state: any, action) => {
      state.allNotificationList = action.payload
      state.isFetchingNotification = false
    },
    fetchNotificationByUserTypeUnSeenFailure: (state: any, action) => {
      state.isFetchingNotification = false
      state.error = action.payload
    },

    changeNotificationOnOrOffStart: (state: any, action) => {
      state.loading = true
    },
    changeNotificationOnOrOffSuccess: (state: any, action) => {
      state.loading = false
    },
    changeNotificationOnOrOffFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    notificationCloseStart: (state: any, action) => {
      state.loading = true
    },
    notificationCloseSuccess: (state: any, action) => {
      state.loading = false
    },
    notificationCloseFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    markNotificationAsUnseenStart: (state: any, action) => {
      state.isChanging = true
    },
    markNotificationAsUnseenSuccess: (state: any, action) => {
      state.isChanging = false
    },
    markNotificationAsUnseenFailure: (state: any, action) => {
      state.isChanging = false
      state.error = action.payload
    },

    deleteNotificationStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteNotificationSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteNotificationFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    fetchCompanyListStart: (state: any, action) => {
      state.loading = true
    },
    fetchCompanyListSuccess: (state: any, action) => {
      state.companyList = action.payload
      state.loading = false
    },
    fetchCompanyListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCompanyByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCompanyByIdSuccess: (state: any, action) => {
      state.soleCompany = action.payload
      state.isFetching = false
    },
    fetchCompanyByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createCompanyStart: (state: any, action) => {
      state.isCreating = true
    },
    createCompanySuccess: (state: any, action) => {
      state.isCreating = false
    },
    createCompanyFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editCompanyStart: (state: any, action) => {
      state.isEditing = true
    },
    editCompanySuccess: (state: any, action) => {
      state.isEditing = false
    },
    editCompanyFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    deleteCompanyStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteCompanySuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteCompanyFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    fetchPayoutInvoiceLogStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPayoutInvoiceLogSuccess: (state: any, action) => {
      state.isFetching = false
      if (action.payload?.canBePaidOut) {
        state.payoutInvoiceLog = action.payload
      } else {
        state.invoiceInfoLog = action.payload.inVoiceInfo
      }
    },
    fetchPayoutInvoiceLogFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAllPayoutInvoiceHistoryStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllPayoutInvoiceHistorySuccess: (state: any, action) => {
      state.isFetching = false
      state.paymentLogHistoryList = action.payload
    },
    fetchAllPayoutInvoiceHistoryFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    stripeChargePayoutStart: (state: any, action) => {
      state.isCreating = true
    },
    stripeChargePayoutSuccess: (state: any, action) => {
      state.isCreating = false
      state.payoutInvoiceLog = {}
      state.invoiceInfoLog = []
    },
    stripeChargePayoutFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    clearPayoutInvoiceLog: (state: any, action) => {
      state.payoutInvoiceLog = {}
      state.invoiceInfoLog = []
      state.paymentLogHistoryList = []
    },
  },
})

export const {
  fetchPrizeDrawStart,
  fetchPrizeDrawSuccess,
  fetchPrizeDrawFailure,
  createPrizeDrawStart,
  createPrizeDrawSuccess,
  createPrizeDrawFailure,

  fetchPrizeDrawByIdStart,
  fetchPrizeDrawByIdSuccess,
  fetchPrizeDrawByIdFailure,
  editPrizeDrawStart,
  editPrizeDrawSuccess,
  editPrizeDrawFailure,

  fetchGeneralSettingStart,
  fetchGeneralSettingSuccess,
  fetchGeneralSettingFailure,

  editGeneralSettingStart,
  editGeneralSettingSuccess,
  editGeneralSettingFailure,

  createGeneralSettingsStart,
  createGeneralSettingsSuccess,
  createGeneralSettingsFailure,

  fetchGeneralSettingByIdStart,
  fetchGeneralSettingByIdSuccess,
  fetchGeneralSettingByIdFailure,

  fetchPiiinkInformationGetFirstStart,
  fetchPiiinkInformationGetFirstSuccess,
  fetchPiiinkInformationGetFirstFailure,

  fetchPiiinkInformationGetByCountryStart,
  fetchPiiinkInformationGetByCountrySuccess,
  fetchPiiinkInformationGetByCountryFailure,

  createCouponStart,
  createCouponSuccess,
  createCouponFailure,

  fetchCouponListStart,
  fetchCouponListSuccess,
  fetchCouponListFailure,

  editCouponStart,
  editCouponSuccess,
  editCouponFailure,

  getOneCouponStart,
  getOneCouponSuccess,
  getOneCouponFailure,

  createCouponCodeStart,
  createCouponCodeSuccess,
  createCouponCodeFailure,

  fetchCouponCodeListStart,
  fetchCouponCodeListSuccess,
  fetchCouponCodeListFailure,

  fetchCouponCodeListByIdStart,
  fetchCouponCodeListByIdSuccess,
  fetchCouponCodeListByIdFailure,

  activateDeactivateCouponByIdStart,
  activateDeactivateCouponByIdSuccess,
  activateDeactivateCouponByIdFailure,

  fetchSmtpDataByIdStart,
  fetchSmtpDataByIdSuccess,
  fetchSmtpDataByIdFailure,

  editSmtpConfigurationStart,
  editSmtpConfigurationSuccess,
  editSmtpConfigurationFailure,

  sendTestEmailStart,
  sendTestEmailSuccess,
  sendTestEmailFailure,

  fetchGroupInformationListStart,
  fetchGroupInformationListSuccess,
  fetchGroupInformationListFailure,

  createGroupInformationListStart,
  createGroupInformationListSuccess,
  createGroupInformationListFailure,

  deleteGroupInformationListStart,
  deleteGroupInformationListSuccess,
  deleteGroupInformationListFailure,

  fetchGroupInformationByIdStart,
  fetchGroupInformationByIdSuccess,
  fetchGroupInformationByIdFailure,

  editGroupInformationListStart,
  editGroupInformationListSuccess,
  editGroupInformationListFailure,

  fetchContactListStart,
  fetchContactListSuccess,
  fetchContactListFailure,

  createContactInfoStart,
  createContactInfoSuccess,
  createContactInfoFailure,

  deleteContactInfoStart,
  deleteContactInfoSuccess,
  deleteContactInfoFailure,

  fetchContactByIdStart,
  fetchContactByIdSuccess,
  fetchContactByIdFailure,

  editContactInfoStart,
  editContactInfoSuccess,
  editContactInfoFailure,

  updateCodesFromCSVStart,
  updateCodesFromCSVSuccess,
  updateCodesFromCSVFailure,

  fetchDocumentListStart,
  fetchDocumentListSuccess,
  fetchDocumentListFailure,

  createDocumentStart,
  createDocumentSuccess,
  createDocumentFailure,

  editDocumentStart,
  editDocumentSuccess,
  editDocumentFailure,

  fetchDocumentByIdStart,
  fetchDocumentByIdSuccess,
  fetchDocumentByIdFailure,

  deleteDocumentStart,
  deleteDocumentSuccess,
  deleteDocumentFailure,

  fetchEmailListStart,
  fetchEmailListSuccess,
  fetchEmailListFailure,

  sendEmailAsDraftStart,
  sendEmailAsDraftSuccess,
  sendEmailAsDraftFailure,

  saveAndSendEmailStart,
  saveAndSendEmailSuccess,
  saveAndSendEmailFailure,

  generateTemporaryPasswordStart,
  generateTemporaryPasswordSuccess,
  generateTemporaryPasswordFailure,

  fetchTemporaryPasswordListStart,
  fetchTemporaryPasswordListSuccess,
  fetchTemporaryPasswordListFailure,

  fetchCouponSettingListStart,
  fetchCouponSettingListSuccess,
  fetchCouponSettingListFailure,

  createCouponSettingStart,
  createCouponSettingSuccess,
  createCouponSettingFailure,

  fetchCouponSettingByIdStart,
  fetchCouponSettingByIdSuccess,
  fetchCouponSettingByIdFailure,

  editCouponSettingStart,
  editCouponSettingSuccess,
  editCouponSettingFailure,

  fetchAppRangeSettingListStart,
  fetchAppRangeSettingListSuccess,
  fetchAppRangeSettingListFailure,

  createAppRangeSettingStart,
  createAppRangeSettingSuccess,
  createAppRangeSettingFailure,

  fetchAppRangeSettingByIdStart,
  fetchAppRangeSettingByIdSuccess,
  fetchAppRangeSettingByIdFailure,

  editAppRangeSettingStart,
  editAppRangeSettingSuccess,
  editAppRangeSettingFailure,

  fetchCouponListByPanelStart,
  fetchCouponListByPanelSuccess,
  fetchCouponListByPanelFailure,

  fetchDocumentManagementSettingListStart,
  fetchDocumentManagementSettingListSuccess,
  fetchDocumentManagementSettingListFailure,

  createDocumentManagementSettingStart,
  createDocumentManagementSettingSuccess,
  createDocumentManagementSettingFailure,

  fetchDocumentManagementSettingByIdStart,
  fetchDocumentManagementSettingByIdSuccess,
  fetchDocumentManagementSettingByIdFailure,

  editDocumentManagementSettingStart,
  editDocumentManagementSettingSuccess,
  editDocumentManagementSettingFailure,

  fetchGroupInformationListByPanelStart,
  fetchGroupInformationListByPanelSuccess,
  fetchGroupInformationListByPanelFailure,

  fetchContactListByPanelStart,
  fetchContactListByPanelSuccess,
  fetchContactListByPanelFailure,

  fetchDocumentListByPanelStart,
  fetchDocumentListByPanelSuccess,
  fetchDocumentListByPanelFailure,

  fetchEmailListByPanelStart,
  fetchEmailListByPanelSuccess,
  fetchEmailListByPanelFailure,

  fetchReportListStart,
  fetchReportListSuccess,
  fetchReportListFailure,

  fetchEmailDataByIdStart,
  fetchEmailDataByIdSuccess,
  fetchEmailDataByIdFailure,

  fetchReportListByPanelStart,
  fetchReportListByPanelSuccess,
  fetchReportListByPanelFailure,

  fetchReportListByIdStart,
  fetchReportListByIdSuccess,
  fetchReportListByIdFailure,

  assignContactsToGroupStart,
  assignContactsToGroupSucccess,
  assignContactsToGroupFailure,

  createNotificationStart,
  createNotificationSuccess,
  createNotificationFailure,

  editNotificationStart,
  editNotificationSuccess,
  editNotificationFailure,

  fetchNotificationListStart,
  fetchNotificationListSuccess,
  fetchNotificationListFailure,

  fetchNotificationByIdStart,
  fetchNotificationByIdSuccess,
  fetchNotificationByIdFailure,

  changeNotificationOnOrOffStart,
  changeNotificationOnOrOffSuccess,
  changeNotificationOnOrOffFailure,

  deleteNotificationStart,
  deleteNotificationSuccess,
  deleteNotificationFailure,

  fetchNotificationByUserStart,
  fetchNotificationByUserSuccess,
  fetchNotificationByUserFailure,

  fetchNotificationByUserTypeSeenStart,
  fetchNotificationByUserTypeSeenSuccess,
  fetchNotificationByUserTypeSeenFailure,

  fetchNotificationByUserTypeUnSeenStart,
  fetchNotificationByUserTypeUnSeenSuccess,
  fetchNotificationByUserTypeUnSeenFailure,

  notificationCloseStart,
  notificationCloseSuccess,
  notificationCloseFailure,

  markNotificationAsUnseenStart,
  markNotificationAsUnseenSuccess,
  markNotificationAsUnseenFailure,

  fetchCompanyByIdStart,
  fetchCompanyByIdSuccess,
  fetchCompanyByIdFailure,

  fetchCompanyListStart,
  fetchCompanyListSuccess,
  fetchCompanyListFailure,

  createCompanyStart,
  createCompanySuccess,
  createCompanyFailure,

  editCompanyStart,
  editCompanySuccess,
  editCompanyFailure,

  deleteCompanyStart,
  deleteCompanySuccess,
  deleteCompanyFailure,

  fetchPayoutInvoiceLogStart,
  fetchPayoutInvoiceLogSuccess,
  fetchPayoutInvoiceLogFailure,

  stripeChargePayoutStart,
  stripeChargePayoutSuccess,
  stripeChargePayoutFailure,

  clearPayoutInvoiceLog,

  fetchAllPayoutInvoiceHistoryStart,
  fetchAllPayoutInvoiceHistorySuccess,
  fetchAllPayoutInvoiceHistoryFailure,
} = globalAdminSettingsSlice.actions

export default globalAdminSettingsSlice.reducer
