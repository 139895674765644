import React, { ReactNode } from 'react'
import { GrHome } from 'react-icons/gr'
import { MdOutlinePayment } from 'react-icons/md'
import AccountBoxIcon from '@mui/icons-material/AccountBox'

import { RoutePermittedRole } from '../../shared/constants/AppConst'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const merchantAdminRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
      },

      {
        id: 'merchantTransactions',
        title: 'merchantTransactions',
        messageId: 'Merchant Transactions',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'MerchantReport',
            title: 'Merchant Report',
            messageId: 'Merchant Report',
            type: 'item',
            url: '/merchant/friendship-earning/asmerchantTerminal/detail',
          },
          {
            // icon: <Wallet />,
            id: 'merchantWallet',
            title: 'merchantWallet',
            messageId: 'Merchant Rebate',
            type: 'item',
            url: '/merchant/terminal-merchant-wallet',
          },
        ],
      },

      {
        icon: <AccountBoxIcon />,
        id: 'myAccount',
        title: 'myAccount',
        messageId: 'My Account',
        type: 'collapse',
        children: [
          {
            id: 'myAccount',
            title: 'myAccount',
            messageId: 'My Profile',
            type: 'item',
            // icon: <FiUser />,
            url: '/my-account',
          },
        ],
      },
    ],
  },
]

export default merchantAdminRoutesConfig
