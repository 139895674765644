import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  resetGlobalAdminPasswordStart,
  resetGlobalAdminPasswordSuccess,
  resetGlobalAdminPasswordFailure,
  resetRegionOwnerPasswordStart,
  resetRegionOwnerPasswordSuccess,
  resetRegionOwnerPasswordFailure,
  resetCountryAdminPasswordStart,
  resetCountryAdminPasswordSuccess,
  resetCountryAdminPasswordFailure,
  resetStateOwnerPasswordStart,
  resetStateOwnerPasswordSuccess,
  resetStateOwnerPasswordFailure,
  resetAreaOwnerPasswordStart,
  resetAreaOwnerPasswordSuccess,
  resetAreaOwnerPasswordFailure,
  resetMerchantPasswordStart,
  resetMerchantPasswordSuccess,
  resetMerchantPasswordFailure,
  resetWhiteLabelPasswordStart,
  resetWhiteLabelPasswordSuccess,
  resetWhiteLabelPasswordFailure,
  resetClubPasswordStart,
  resetClubPasswordSuccess,
  resetClubPasswordFailure,
  resetCharityPasswordStart,
  resetCharityPasswordFailure,
  resetGmcAdminPasswordStart,
  resetGmcAdminPasswordSuccess,
  resetGmcAdminPasswordFailure,
  resetSalesAgentPasswordStart,
  resetSalesAgentPasswordSuccess,
  resetSalesAgentPasswordFailure,
  resetCharityPasswordSuccess,
  resetCPPasswordStart,
  resetCPPasswordSuccess,
  resetCPPasswordFailure,
  resetWebsiteAdminPasswordStart,
  resetWebsiteAdminPasswordSuccess,
  resetWebsiteAdminPasswordFailure,
  resetMerchantFranchisePasswordSuccess,
  resetMerchantFranchisePasswordFailure,
} from './forgotPasswordSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  resetGlobalAdminPassword,
  resetRegionOwnerPassword,
  resetCountryOwnerPassword,
  resetStateOwnerPassword,
  resetAreaOwnerPassword,
  resetMerchantPassword,
  resetWhiteLabelPassword,
  resetClubPassword,
  resetCharityPassword,
  resetGmcAdminPassword,
  resetSalesAgentPassword,
  resetCollaborationPartnerPassword,
  resetWebsiteAdminPassword,
  resetMerchantFranchisePassword,
} from '../../api/resetPassword'

import _ from 'lodash'
import { removeParam } from 'utils'
import { watchAreaAdminLogin } from 'store/auth/authSagas'

export function* resetGlobalAdminPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetGlobalAdminPassword(payload.params))

    yield put(resetGlobalAdminPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetGlobalAdminPasswordFailure(err))
  }
}

export function* resetRegionOwnerPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetRegionOwnerPassword(payload.params))

    yield put(resetGlobalAdminPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetRegionOwnerPasswordFailure(err))
  }
}

export function* resetCountryOwnerPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetCountryOwnerPassword(payload.params))

    yield put(resetCountryAdminPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetCountryAdminPasswordFailure(err))
  }
}

export function* resetStateOwnerPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetStateOwnerPassword(payload.params))

    yield put(resetStateOwnerPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetStateOwnerPasswordFailure(err))
  }
}

export function* resetAreaOwnerPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetAreaOwnerPassword(payload.params))

    yield put(resetAreaOwnerPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetAreaOwnerPasswordFailure(err))
  }
}

export function* resetMerchantPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetMerchantPassword(payload.params))

    yield put(resetMerchantPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetMerchantPasswordFailure(err))
  }
}

export function* resetMerchantFranchisePasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetMerchantFranchisePassword(payload.params))

    yield put(resetMerchantFranchisePasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetMerchantFranchisePasswordFailure(err))
  }
}

export function* resetWhiteLabelPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetWhiteLabelPassword(payload.params))

    yield put(resetWhiteLabelPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetWhiteLabelPasswordFailure(err))
  }
}

export function* resetClubPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetClubPassword(payload.params))
    yield put(resetClubPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetClubPasswordFailure(err))
  }
}

export function* resetCharityPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetCharityPassword(payload.params))

    yield put(resetCharityPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetCharityPasswordFailure(err))
  }
}

export function* resetGmcAdminPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetGmcAdminPassword(payload.params))

    yield put(resetGmcAdminPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(resetGmcAdminPasswordFailure(err))
  }
}

export function* resetSalesAgentPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetSalesAgentPassword(payload.params))

    yield put(resetSalesAgentPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({ message: err?.response?.data?.message, severity: 'error' })
    )
    yield put(resetSalesAgentPasswordFailure(err))
  }
}

export function* resetCPPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      resetCollaborationPartnerPassword(payload.params)
    )

    yield put(resetCPPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({ message: err?.response?.data?.message, severity: 'error' })
    )
    yield put(resetCPPasswordFailure(err))
  }
}

export function* resetWebsiteAdminPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetWebsiteAdminPassword(payload.params))

    yield put(resetWebsiteAdminPasswordSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Reset link sent successfully. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({ message: err?.response?.data?.message, severity: 'error' })
    )
    yield put(resetWebsiteAdminPasswordFailure(err))
  }
}

export function* watchResetGlobalAdminPassword() {
  yield takeLatest(
    'passwordReset/resetGlobalAdminPasswordStart',
    resetGlobalAdminPasswordAsync
  )
}

export function* watchResetRegionOwnerPassword() {
  yield takeLatest(
    'passwordReset/resetRegionOwnerPasswordStart',
    resetRegionOwnerPasswordAsync
  )
}

export function* watchResetCountryAdminPassword() {
  yield takeLatest(
    'passwordReset/resetCountryAdminPasswordStart',
    resetCountryOwnerPasswordAsync
  )
}

export function* watchResetStateOwnerPassword() {
  yield takeLatest(
    'passwordReset/resetStateOwnerPasswordStart',
    resetStateOwnerPasswordAsync
  )
}

export function* watchResetAreaOwnerPassword() {
  yield takeLatest(
    'passwordReset/resetAreaOwnerPasswordStart',
    resetAreaOwnerPasswordAsync
  )
}

export function* watchResetWhiteLabelPassword() {
  yield takeLatest(
    'passwordReset/resetWhiteLabelPasswordStart',
    resetWhiteLabelPasswordAsync
  )
}

export function* watchResetMerchantPassword() {
  yield takeLatest(
    'passwordReset/resetMerchantPasswordStart',
    resetMerchantPasswordAsync
  )
}

export function* watchResetMerchantFranchisePassword() {
  yield takeLatest(
    'passwordReset/resetMerchantFranchisePasswordStart',
    resetMerchantFranchisePasswordAsync
  )
}

export function* watchResetClubPassword() {
  yield takeLatest(
    'passwordReset/resetClubPasswordStart',
    resetClubPasswordAsync
  )
}

export function* watchResetCharityPassword() {
  yield takeLatest(
    'passwordReset/resetCharityPasswordStart',
    resetCharityPasswordAsync
  )
}

export function* watchResetGmcAdminPassword() {
  yield takeLatest(
    'passwordReset/resetGmcAdminPasswordStart',
    resetGmcAdminPasswordAsync
  )
}

export function* watchResetSalesAgentPassword() {
  yield takeLatest(
    'passwordReset/resetSalesAgentPasswordStart',
    resetSalesAgentPasswordAsync
  )
}

export function* watchResetCollaborationPartnerPassword() {
  yield takeLatest('passwordReset/resetCPPasswordStart', resetCPPasswordAsync)
}

export function* watchResetWebsiteAdminPassword() {
  yield takeLatest(
    'passwordReset/resetWebsiteAdminPasswordStart',
    resetWebsiteAdminPasswordAsync
  )
}

export function* forgotPasswordSagas() {
  yield all([
    call(watchResetGlobalAdminPassword),
    call(watchResetRegionOwnerPassword),
    call(watchResetCountryAdminPassword),
    call(watchResetStateOwnerPassword),
    call(watchResetAreaOwnerPassword),
    call(watchResetMerchantPassword),
    call(watchResetWhiteLabelPassword),
    call(watchResetClubPassword),
    call(watchResetCharityPassword),
    call(watchResetGmcAdminPassword),
    call(watchResetSalesAgentPassword),
    call(watchResetCollaborationPartnerPassword),
    call(watchResetWebsiteAdminPassword),
    call(watchResetMerchantFranchisePassword),
  ])
}
