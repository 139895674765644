import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchFriendshipReportListSuccess,
  fetchFriendshipReportListFailure,
  fetchWlMerchantSignerReportListSuccess,
  fetchWlMerchantSignerReportListFailure,
  fetchCpSignerIssuerFriendshipReportListSuccess,
  fetchCpSignerIssuerFriendshipReportListFailure,
  fetchCpMerchantSignerFriendshipReportListFailure,
  fetchCpMerchantSignerFriendshipReportListSuccess,
  fetchWlIssuerReportListFailure,
  fetchWlIssuerReportListSuccess,
  fetchCpSignerIssuerMembershipReportListSuccess,
  fetchCpSignerIssuerMembershipReportListFailure,
  fetchWlIssuerMembershipReportListSuccess,
  fetchWlIssuerMembershipReportListFailure,
} from './reportsSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import _ from 'lodash'
import {
  fetchCpMerchantSignerFriendshipReportList,
  fetchCpSignerIssuerFriendshipReportList,
  fetchCpSignerIssuerMembershipReportList,
  fetchFriendshipReportList,
  fetchMembershipReportList,
  fetchWlIssuerMembershipReportList,
  fetchWlMerchantSignerReportList,
  fetchhWlIssuerReportList,
} from 'api/reports'

export function* fetchFriendshipReportListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchFriendshipReportList(payload))

    yield put(fetchFriendshipReportListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchFriendshipReportListFailure(err))
  }
}

export function* fetchMembershipReportListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMembershipReportList(payload))

    yield put(fetchFriendshipReportListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchFriendshipReportListFailure(err))
  }
}

export function* fetchWlIssuerReportListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchhWlIssuerReportList(payload))

    yield put(fetchWlIssuerReportListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWlIssuerReportListFailure(err))
  }
}

export function* fetchWlMerchantSignerReportListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWlMerchantSignerReportList(payload))

    yield put(fetchWlMerchantSignerReportListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWlMerchantSignerReportListFailure(err))
  }
}

export function* fetchWlIssuerMembershipReportListAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchWlIssuerMembershipReportList(payload))

    yield put(fetchWlIssuerMembershipReportListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWlIssuerMembershipReportListFailure(err))
  }
}

export function* fetchCpSignerIssuerFriendshipReportListAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchCpSignerIssuerFriendshipReportList(payload)
    )

    yield put(fetchCpSignerIssuerFriendshipReportListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCpSignerIssuerFriendshipReportListFailure(err))
  }
}

export function* fetchCpMerchantSignerFriendshipReportListAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchCpMerchantSignerFriendshipReportList(payload)
    )

    yield put(fetchCpMerchantSignerFriendshipReportListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCpMerchantSignerFriendshipReportListFailure(err))
  }
}

export function* fetchCpSignerIssuerMembershipReportListAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchCpSignerIssuerMembershipReportList(payload)
    )

    yield put(fetchCpSignerIssuerMembershipReportListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCpSignerIssuerMembershipReportListFailure(err))
  }
}

export function* watchFetchFriendshipReportList() {
  yield takeLatest(
    'reports/fetchFriendshipReportListStart',
    fetchFriendshipReportListAsync
  )
}

export function* watchFetchMembershipReportList() {
  yield takeLatest(
    'reports/fetchMembershipReportListStart',
    fetchMembershipReportListAsync
  )
}

export function* watchFetchWlIssuerReportList() {
  yield takeLatest(
    'reports/fetchWlIssuerReportListStart',
    fetchWlIssuerReportListAsync
  )
}

export function* watchFetchWlMerchantSignerReportList() {
  yield takeLatest(
    'reports/fetchWlMerchantSignerReportListStart',
    fetchWlMerchantSignerReportListAsync
  )
}
export function* watchfetchWlIssuerMembershipReportList() {
  yield takeLatest(
    'reports/fetchWlIssuerMembershipReportListStart',
    fetchWlIssuerMembershipReportListAsync
  )
}

export function* watchFetchCpSignerIssuerFriendshipReportList() {
  yield takeLatest(
    'reports/fetchCpSignerIssuerFriendshipReportListStart',
    fetchCpSignerIssuerFriendshipReportListAsync
  )
}

export function* watchFetchCpMerchantSignerFriendshipReportList() {
  yield takeLatest(
    'reports/fetchCpMerchantSignerFriendshipReportListStart',
    fetchCpMerchantSignerFriendshipReportListAsync
  )
}

export function* watchFetchCpSignerIssuerMembershipReportList() {
  yield takeLatest(
    'reports/fetchCpSignerIssuerMembershipReportListStart',
    fetchCpSignerIssuerMembershipReportListAsync
  )
}

export function* reportsSagas() {
  yield all([
    call(watchFetchFriendshipReportList),
    call(watchFetchMembershipReportList),
    call(watchFetchWlIssuerReportList),
    call(watchFetchWlMerchantSignerReportList),
    call(watchFetchCpSignerIssuerFriendshipReportList),
    call(watchFetchCpMerchantSignerFriendshipReportList),
    call(watchFetchCpSignerIssuerMembershipReportList),
    call(watchfetchWlIssuerMembershipReportList),
  ])
}
