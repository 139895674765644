import React, { useState } from 'react'
import {
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  Divider,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import Previews from '../PreviewsListImage'
import AppConfirmDialog from '@crema/core/AppConfirmDialog'

// Here is an example of a form with an editable list.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
const ListDetails = ({
  index,
  arrayHelpers,
  values,
  item,
  setFieldValue,
  setSelectedOption,
  isEditForm,
  isAddForm,
  countryPrefixList,
  globalCountry,
  onDeleteAboutUsBySlugStart,
  refreshPageData,
  selectedSectionLangugage,
  setNewContentAdded,
}: any) => {
  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  const onDeleteSelectedContacts = () => {
    onDeleteAboutUsBySlugStart({
      slug: item?.slug,
      arrayHelpers,
      index,
      item,
      closeModal: closeDeleteModal,
      refreshPageData,
    })
    setDeleteDialogOpen(false)
  }

  const openDeleteModal = (item) => {
    setDeleteDialogOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteDialogOpen(false)
  }

  return (
    <div>
      {isDeleteDialogOpen && (
        <AppConfirmDialog
          open={isDeleteDialogOpen}
          onDeny={setDeleteDialogOpen}
          onConfirm={onDeleteSelectedContacts}
          title="Are you sure you want to delete this content section ?"
          dialogTitle="Delete Content Section"
        />
      )}

      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} lg={2}>
          <AppTextField
            placeholder={'Order'}
            label={'Order'}
            name={`list[${index}].order`}
            type="number"
            variant="outlined"
            required
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                fontSize: 14,
              },
            }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <AppTextField
            placeholder={'About List Title'}
            label={'About List Title'}
            name={`list[${index}].aboutlistTitle`}
            variant="outlined"
            required
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                fontSize: 14,
              },
            }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <AppTextField
            placeholder={'About List Description'}
            label={'About List Description'}
            name={`list[${index}].aboutlistDescription`}
            variant="outlined"
            required
            multiline={true}
            rows={5}
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                fontSize: 14,
              },
            }}
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" sx={{ mt: 2, mb: 5 }}>
            Upload About Us Image *
          </Typography>
          <Previews
            setUploadedFiles={setUploadedFiles} /* eslint-disable */
            uploadedFiles={item?.aboutlisImageUrl}
            setFieldValue={setFieldValue}
            defaultImage={[{ preview: '', type: 'image/png' }]}
            index={index}
          />
        </Grid>
        <Grid item container xs={12}>
          {!isEditForm || (isEditForm && selectedSectionLangugage === 'en') ? (
            <Grid item lg={2}>
              {values?.list?.length > 1 &&
              index > 0 &&
              index !== values?.list?.length - 1 ? (
                <>
                  <Button
                    onClick={() => {
                      if (isEditForm && item?.id) {
                        openDeleteModal(item)
                        setFieldValue(
                          'isTranslateToAlllang',
                          !values.isTranslateToAlllang
                        )
                      } else {
                        arrayHelpers.remove(index)
                        setNewContentAdded(false)
                      }
                    }}
                  >
                    Remove
                  </Button>
                </>
              ) : (
                <>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      // if (isEditForm) {
                      //   setFieldValue('isTranslateToAlllang', true)
                      // }
                      setNewContentAdded(true)

                      arrayHelpers.push({
                        aboutlistTitle: null,
                        aboutlistDescription: null,
                        aboutlisImageUrl: [],
                        order: index + 1,
                      })
                    }}
                  >
                    <AddIcon />
                  </IconButton>

                  {values?.list?.length > 1 && index > 0 && (
                    <Button
                      onClick={() => {
                        if (isEditForm && item?.id) {
                          openDeleteModal(item)
                          setFieldValue(
                            'isTranslateToAlllang',
                            !values.isTranslateToAlllang
                          )
                        } else {
                          arrayHelpers.remove(index)
                          setNewContentAdded(false)
                        }
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </>
              )}
            </Grid>
          ) : (
            ''
          )}
          {values?.list?.length > 1 ? (
            <Grid item md={10} sm={8} xs={5}>
              <Divider sx={{ mt: 5 }} />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default ListDetails
