/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import {
  fetchMerchantCategoryStart,
  fetchMerchantSubCategoryStart,
} from 'store/dropDown/dropDownSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  dropDown: state.dropDown,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchMerchantCategoryStart: (payload) =>
    dispatch(fetchMerchantCategoryStart(payload)),
  onFetchMerchantSubCategoryStart: (payload) =>
    dispatch(fetchMerchantSubCategoryStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
