import React, { useEffect } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'

import AppDialog from '@crema/core/AppDialog'
import container from './MerchantFormLinkModal.container'

import * as yup from 'yup'
import { Form, Formik } from 'formik'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  merchant?: any
  onOpenCountryTask?: () => void
  refreshPageData?: () => void
  onCloseAddCountry?: () => void
  clearGeneralData?: () => void
  onCreateClubStart?: (params) => void
  onFetchMerchantBasicInfoPanelUserStart?: (params) => void
  onGetReferralTokenStart?: () => void
  onClearReferralToken?: () => void
  onEditMerchantInfoStart?: (params) => void
  onOpenAlert?: (params) => void

  siteCoordinator: any
  merchantId?: number
  club: any
  auth: any
  adminRoute?: any
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const MerchantFormLinkModal: React.FC<AddNewCountryProps> = ({
  isAddCountryOpen,
  onCloseAddCountry,
  merchant: { isGenerating, sharableMerchantFormData },
  onGetReferralTokenStart,
  onClearReferralToken,
  onOpenAlert,
}) => {
  useEffect(() => {
    onGetReferralTokenStart()
  }, [])

  const closeModal = () => {
    onClearReferralToken()
    onCloseAddCountry()
  }
  const validationSchema = yup.object({})

  const copyToClipBoard = () => {
    onOpenAlert({
      message: 'Referral url copied to Clip board',
      severity: 'success',
    })
    navigator.clipboard.writeText(sharableMerchantFormData?.referralURL ?? '')
  }

  return (
    <>
      <AppDialog
        dividers
        maxWidth="md"
        open={isAddCountryOpen}
        onClose={() => closeModal()}
        title="Sharable Merchant Form Link"
      >
        <Box sx={{ width: '100%', p: 1 }}>
          <Formik
            validateOnChange={true}
            enableReinitialize
            initialValues={{
              referralURL: sharableMerchantFormData?.referralURL ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={(data: any, { setSubmitting }) => {
              setSubmitting(true)

              setSubmitting(false)
            }}
            render={() => {
              return (
                <Form>
                  <Box sx={{ p: 2 }}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Your sharable merchant form link :
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {/* <AppTextField
                            placeholder={'ReferralURL'}
                            label={'ReferralURL'}
                            name="referralURL"
                            variant="outlined"
                            required
                            sx={{
                              width: '100%',
                              '& .MuiInputBase-input': {
                                fontSize: 14,
                              },
                            }}
                            size="small"
                          /> */}
                        <Typography
                          variant="body2"
                          sx={{ wordBreak: 'break-all' }}
                        >
                          <strong>
                            {sharableMerchantFormData?.referralURL ?? ''}
                          </strong>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box sx={{ height: 20 }} />
                    <Box sx={{ height: 30 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        sx={{
                          color: '#ffffff',
                          display: 'flex',
                          background: '#00bfcd',
                          '&:hover': {
                            background: '#ec4785',
                          },
                          px: 5,
                        }}
                        type="button"
                        onClick={copyToClipBoard}
                        disabled={isGenerating}
                      >
                        Copy
                      </Button>
                      &nbsp; &nbsp;
                      <Button variant="outlined" onClick={() => closeModal()}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )
            }}
          />
        </Box>
      </AppDialog>
    </>
  )
}

export default container(MerchantFormLinkModal)
