import { createSlice } from '@reduxjs/toolkit'

import { IFriendshipFee } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IFriendshipFee = {
  friendshipFeeList: listObj,
  countryInternalBeneficiariesList: listObj,
  soleFriendshipFee: {},
  soleCountryInternalBeneficiaries: {},
  error: null,
  loading: false,
  isFetching: false,
  isDeleting: false,
  hasMoreData: false,
}

export const friendshipFeeSlice = createSlice({
  name: 'friendshipFee',
  initialState: INITIAL_STATE,
  reducers: {
    fetchFriendshipFeeStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipFeeSuccess: (state: any, action) => {
      state.friendshipFeeList = action.payload
      state.loading = false
    },
    fetchFriendshipFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipFeeByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchFriendshipFeeByIdSuccess: (state: any, action) => {
      state.soleFriendshipFee = action.payload.data
      state.isFetching = false
    },
    fetchFriendshipFeeByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createFriendshipFeeStart: (state: any, action) => {
      state.loading = true
    },
    createFriendshipFeeSuccess: (state: any, action) => {
      state.loading = false
      state.friendshipFeeList.data = [
        ...state.friendshipFeeList.data,
        action.payload,
      ]
    },
    createFriendshipFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteFriendshipFeeStart: (state: any, action) => {
      state.loading = true
    },
    deleteFriendshipFeeSuccess: (state: any, action) => {
      state.loading = false
      state.friendshipFeeList.data = state.friendshipFeeList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteFriendshipFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editFriendshipFeeStart: (state: any, action) => {
      state.loading = true
    },
    editFriendshipFeeSuccess: (state: any, action) => {
      const result = state.friendshipFeeList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.friendshipFeeList.data = result

      state.loading = false
    },
    editFriendshipFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createCountryInternalBeneficiariesStart: (state: any, action) => {
      state.loading = true
    },
    createCountryInternalBeneficiariesSuccess: (state: any, action) => {
      state.loading = false
    },
    createCountryInternalBeneficiariesFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryInternalBeneficiariesStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCountryInternalBeneficiariesSuccess: (state: any, action) => {
      state.countryInternalBeneficiariesList = action.payload
      state.isFetching = false
    },
    fetchCountryInternalBeneficiariesFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchCountryInternalBeneficiariesByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCountryInternalBeneficiariesByIdSuccess: (state: any, action) => {
      state.soleCountryInternalBeneficiaries = action.payload.data
      state.isFetching = false
    },
    fetchCountryInternalBeneficiariesByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editCountryInternalBeneficiariesStart: (state: any, action) => {
      state.loading = true
    },
    editCountryInternalBeneficiariesSuccess: (state: any, action) => {
      state.loading = false
    },
    editCountryInternalBeneficiariesFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteCountryInternalBeneficiariesStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteCountryInternalBeneficiariesSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteCountryInternalBeneficiariesFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },
  },
})

export const {
  fetchFriendshipFeeStart,
  fetchFriendshipFeeSuccess,
  fetchFriendshipFeeFailure,
  createFriendshipFeeStart,
  createFriendshipFeeSuccess,
  createFriendshipFeeFailure,
  deleteFriendshipFeeStart,
  deleteFriendshipFeeSuccess,
  deleteFriendshipFeeFailure,
  fetchFriendshipFeeByIdStart,
  fetchFriendshipFeeByIdSuccess,
  fetchFriendshipFeeByIdFailure,
  editFriendshipFeeStart,
  editFriendshipFeeSuccess,
  editFriendshipFeeFailure,

  createCountryInternalBeneficiariesStart,
  createCountryInternalBeneficiariesSuccess,
  createCountryInternalBeneficiariesFailure,

  fetchCountryInternalBeneficiariesStart,
  fetchCountryInternalBeneficiariesSuccess,
  fetchCountryInternalBeneficiariesFailure,

  fetchCountryInternalBeneficiariesByIdStart,
  fetchCountryInternalBeneficiariesByIdSuccess,
  fetchCountryInternalBeneficiariesByIdFailure,

  editCountryInternalBeneficiariesStart,
  editCountryInternalBeneficiariesSuccess,
  editCountryInternalBeneficiariesFailure,

  deleteCountryInternalBeneficiariesStart,
  deleteCountryInternalBeneficiariesSuccess,
  deleteCountryInternalBeneficiariesFailure,
} = friendshipFeeSlice.actions

export default friendshipFeeSlice.reducer
