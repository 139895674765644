import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchPageStart,
  fetchPageSuccess,
  fetchPageFailure,
  createPageSuccess,
  createPageFailure,
  deletePageSuccess,
  deletePageFailure,
  fetchPageByIdSuccess,
  fetchPageByIdFailure,
  fetchPageByRoleStart,
  fetchPageByRoleSuccess,
  fetchPageByRoleFailure,
  editPageSuccess,
  editPageFailure,
  fetchOneBySlugSuccess,
  fetchOneBySlugFailure,
  fetchPageByRoleAndCountrySuccess,
  fetchPageByRoleAndCountryFailure,
  eoiFetchPageByRoleAndCountryFailure,
  eoiFetchPageByRoleAndCountrySuccess,
} from './pageSetupSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchPageList,
  createPage,
  deletePage,
  fetchPageById,
  fetchPageByRole,
  editPage,
  fetchPageBySlug,
  fetchPageByRoleAndCountry,
  eoiFetchPageByRoleAndCountry,
} from '../../api/pageSetup'

import _ from 'lodash'

export function* fetchPageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPageList(payload))

    yield put(fetchPageSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPageFailure(err))
  }
}

export function* fetchPageByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPageById(payload))

    yield put(fetchPageByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPageByIdFailure(err))
  }
}

export function* fetchPageByRoleAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPageByRole(payload?.pageType))

    yield put(fetchPageByRoleSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPageByRoleFailure(err))
  }
}

export function* fetchPageByRoleAndCountryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      fetchPageByRoleAndCountry(payload?.pageType, payload?.params)
    )

    yield put(fetchPageByRoleAndCountrySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPageByRoleAndCountryFailure(err))
  }
}

export function* eoiFetchPageByRoleAndCountryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      eoiFetchPageByRoleAndCountry(payload?.pageType, payload?.params)
    )

    yield put(eoiFetchPageByRoleAndCountrySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(eoiFetchPageByRoleAndCountryFailure(err))
  }
}

export function* editPageAsync({ payload }: AnyAction) {
  try {
    if (!payload.params.description) {
      throw new Error('Page description is required')
    }

    const { data } = yield call(editPage(payload.params.id, payload.params))

    yield put(editPageSuccess(data.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchPageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }

    yield put(openAlert({ message: 'succesfully edited', severity: 'success' }))
    payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editPageFailure(err))
  }
}

export function* createPageAsync({ payload }: AnyAction) {
  try {
    if (!payload.params.description) {
      throw new Error('Page description is required')
    }

    const { data } = yield call(createPage({ ...payload.params }))

    yield put(createPageSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchPageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }

    yield put(
      openAlert({
        message: 'Page  succesfully created',
        severity: 'success',
      })
    )

    yield payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createPageFailure(err))
  }
}

export function* deletePageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deletePage(payload))

    if (data) {
      yield put(deletePageSuccess(payload))
      yield put(
        fetchPageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Page deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deletePageFailure(err))
  }
}

export function* fetchOneBySlugAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPageBySlug(payload?.slug))

    yield put(fetchOneBySlugSuccess(data?.data))
  } catch (err) {
    yield put(fetchOneBySlugFailure(err))
    console.error(err)
  }
}

export function* watchFetchPage() {
  yield takeLatest('pageSetup/fetchPageStart', fetchPageAsync)
}

export function* watchCreatePage() {
  yield takeLatest('pageSetup/createPageStart', createPageAsync)
}

export function* watchDeletePage() {
  yield takeLatest('pageSetup/deletePageStart', deletePageAsync)
}

export function* watchFetchPageById() {
  yield takeLatest('pageSetup/fetchPageByIdStart', fetchPageByIdAsync)
}

export function* watchFetchPageByRole() {
  yield takeLatest('pageSetup/fetchPageByRoleStart', fetchPageByRoleAsync)
}

export function* watchFetchPageByRoleAndCountry() {
  yield takeLatest(
    'pageSetup/fetchPageByRoleAndCountryStart',
    fetchPageByRoleAndCountryAsync
  )
}

export function* watchEoiFetchPageByRoleAndCountry() {
  yield takeLatest(
    'pageSetup/eoiFetchPageByRoleAndCountryStart',
    eoiFetchPageByRoleAndCountryAsync
  )
}

export function* watchEditPage() {
  yield takeLatest('pageSetup/editPageStart', editPageAsync)
}

export function* watchFetchPageBySlug() {
  yield takeLatest('pageSetup/fetchOneBySlugStart', fetchOneBySlugAsync)
}

export function* pageSetupSagas() {
  yield all([
    call(watchFetchPage),
    call(watchCreatePage),
    call(watchDeletePage),
    call(watchFetchPageById),
    call(watchEditPage),
    call(watchFetchPageByRole),
    call(watchFetchPageBySlug),
    call(watchFetchPageByRoleAndCountry),
    call(watchEoiFetchPageByRoleAndCountry),
  ])
}
