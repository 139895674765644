import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Checkbox,
  Tooltip,
} from '@mui/material'

import container from './PackagesAndBankInfo.container'
import { Form, Formik, Field } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import { useParams, useNavigate, Link } from 'react-router-dom'
import CheckoutForm from 'components/CheckoutForm/CheckoutForm.component'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import * as yup from 'yup'
import _ from 'lodash'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import InfoIcon from '@mui/icons-material/Info'
import { fixed2Decimal } from 'utils'
import PremiumCodeByPanelSelect from 'components/DropdownComponents/PremiumCodeByPanelSelect'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onClearMerchantCodeData?: () => void
  onCreateClubStart?: (params) => void
  onFetchMerchantPackageStart?: (params) => void
  onCreateBankInfoStart?: (params) => void
  onFetchMerchantGeneralBankInfoStart?: (params) => void
  onCheckApplyMerchantPremiumCodeStart?: (params) => void
  onFetchMerchantDraftBasicInfoPanelUserStart?: (params) => void
  clearMerchantPremiumCodeData?: () => void
  onOpenAlert?: (params) => void
  siteCoordinator?: any
  club?: any
  auth?: any
  merchantPackage?: any
  merchant?: any
  handleChangeTab?: any
  adminRoute?: any
  clearMerchantCouponCodeData?: () => void
  onClearCouponCodeData?: () => void
  onApplyMerchantCouponCodeStart?: (params) => void
  globalSettings?: any
  onFetchPiiinkInformationGetByCountryStart?: (params) => void
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  onCreateBankInfoStart,
  onFetchMerchantGeneralBankInfoStart,
  onCloseAddCountry,
  onFetchMerchantPackageStart,
  clearMerchantPremiumCodeData,
  onCheckApplyMerchantPremiumCodeStart,
  onFetchMerchantDraftBasicInfoPanelUserStart,
  onClearMerchantCodeData,
  onOpenAlert,
  auth: { currentUser },
  siteCoordinator: { globalCountry, recentMerchant },
  merchantPackage: {
    merchantPackageList: { data },
    loading,
  },
  globalSettings: { piiinkInformationGetByCountry },
  merchant: {
    draftBasicInfo,
    isCreating,
    isApplyingCode,
    merchantCodeResponse,
    merchantCodeData,
    merchantCodeError,
    couponCodeResponse,
    couponCodeData,
    couponCodeError,
    isApplyingCouponCode,
  },
  adminRoute,
  onApplyMerchantCouponCodeStart,
  clearMerchantCouponCodeData,
  onFetchPiiinkInformationGetByCountryStart,
}) => {
  const { id } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    onFetchPiiinkInformationGetByCountryStart(
      currentUser?.userCountry?.countryId
    )
  }, [currentUser])

  useEffect(() => {
    if (id) {
      onFetchMerchantGeneralBankInfoStart(id)
    }
  }, [id])

  useEffect(() => {
    if (id && currentUser?.role?.toLowerCase() === 'collaborationpartner') {
      onFetchMerchantDraftBasicInfoPanelUserStart(id)
    }
  }, [id, currentUser])

  const stripe = useStripe()
  const elements = useElements()
  const [submittingStripe, setSubmittingStripe] = useState<boolean>(false)

  const [packageType, setPackageType] = useState<any>('')
  const [packageObj, setPackageObj] = useState<any>({})
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [cardData, setCardData] = useState<any>({
    complete: false,
    empty: true,
  })

  const [couponCodeSubmitted, setCouponCodeSubmitted] = useState<boolean>(false)
  const [premiumCodeSubmitted, setPremiumCodeSubmitted] =
    useState<boolean>(false)

  const closeDescriptionModal = () => {
    setOpenDescriptionModal(false)
  }

  useEffect(() => {
    if (!_.isEmpty(data)) {
      // const filteredPackage = data?.find(
      //   (item) => item?.name?.toLowerCase() === 'premium'
      // )

      const highestValue = data?.reduce((a, b) =>
        b?.packageFee + b?.setupFee < a?.packageFee + a?.setupFee ? a : b
      )
      setPackageType(highestValue?.name?.toLowerCase() ?? '')
      setPackageObj(highestValue)
    }
  }, [data])

  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  const [tempLoader, setTempLoader] = useState(true)

  useEffect(() => {
    clearMerchantCouponCodeData()
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  useEffect(() => {
    if (currentUser?.role?.toLowerCase() === 'collaborationpartner') {
      if (!_.isEmpty(draftBasicInfo)) {
        onFetchMerchantPackageStart({
          countryId: draftBasicInfo?.__country__?.id,
          isFree: false,
          merchantId: id,
        })
      }
    } else {
      onFetchMerchantPackageStart({
        countryId: globalCountry?.id,
        isFree: false,
        merchantId: id,
      })
    }
  }, [currentUser, globalCountry, draftBasicInfo])

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  const closeModal = () => {
    onCloseAddCountry()
    setMultiplePersonApply(false)
  }
  const validationSchema = yup.object({})

  const submitPremiumCode = (data) => {
    setPremiumCodeSubmitted(true)
    onCheckApplyMerchantPremiumCodeStart({
      params: { merchantId: id, premiumCode: data, packageId: packageObj?.id },
    })
  }

  const submitCouponCode = (data) => {
    setCouponCodeSubmitted(true)
    onApplyMerchantCouponCodeStart({
      params: {
        merchantId: id,
        packageId: packageObj?.id,
        couponCode: data,
      },
    })
  }

  const showTotal = () => {
    if (merchantCodeData && !_.isEmpty(merchantCodeData)) {
      return 0
    } else if (couponCodeData && !_.isEmpty(couponCodeData)) {
      return couponCodeData?.totalAmount
    } else {
      return fixed2Decimal(
        packageObj?.packageFee +
          packageObj?.setupFee +
          packageObj?.packageFeeGST +
          packageObj?.setupFeeGST
      )
    }
  }

  const resetCouponApplied = () => {
    clearMerchantCouponCodeData()
    setCouponCodeSubmitted(false)
    setPremiumCodeSubmitted(false)
  }

  return (
    <>
      {openDescriptionModal && (
        <ModalRenderer
          open={openDescriptionModal}
          onDeny={closeDescriptionModal}
          onConfirm={closeDescriptionModal}
          title=""
          dialogTitle="Description"
        >
          <Typography
            dangerouslySetInnerHTML={{ __html: selectedItem?.description }}
            variant="body1"
          />
        </ModalRenderer>
      )}
      <Formik
        validateOnChange={true}
        initialValues={{
          countryId: globalCountry?.id,
          businessNumber: '',
          accountName: '',
          bankName: '',
          bankCode: '',
          branchCode: '',
          accountNumber: '',
          swiftCode: '',
          comments: '',
          reference: '',
          premiumCode: null,
          transactionCode: null,
          issuerCode: null,
          agreedTermAndCondition: false,
          agreedDirectDebitFee: false,
          couponCode: null,
        }}
        validationSchema={validationSchema}
        onSubmit={async (data: any, { setSubmitting }) => {
          setSubmitting(true)
          setSubmittingStripe(true)
          let omittedResult = data

          if (!packageObj?.getsIssuer) {
            omittedResult = _.omit(
              {
                ...data,
                // transactionCode: data?.transactionCode?.label,
                // issuerCode: data?.issuerCode?.label,
              },
              [
                'bankName',
                'BSB',
                'bankCode',
                'branchCode',
                'accountName',
                'accountNumber',
                'swiftCode',
                'premiumCode',
              ]
            )
          } else {
            omittedResult = {
              ...data,
              premiumCode: data?.premiumCode?.label
                ? data?.premiumCode?.label
                : null,
              couponCode: data?.couponCode === '' ? null : data?.couponCode,

              // transactionCode: data?.transactionCode?.label,
              // issuerCode: data?.issuerCode?.label,
            }
          }

          const card = elements.getElement(CardElement)

          await stripe.createToken(card).then(function (result) {
            // Handle result.error or result.token

            if (
              packageObj?.canApplyPremiumCode &&
              merchantCodeData?.status?.toLowerCase() === 'success'
            ) {
              if (!cardData?.empty && !_.isEmpty(result?.error)) {
                return onOpenAlert({
                  message: result?.error?.message,
                  severity: 'error',
                })
              }
            } else {
              if (!_.isEmpty(result?.error)) {
                if (
                  packageObj?.packageFee +
                    packageObj?.setupFee +
                    packageObj?.packageFeeGST +
                    packageObj?.setupFeeGST !==
                  0
                ) {
                  return onOpenAlert({
                    message: result?.error?.message,
                    severity: 'error',
                  })
                } else {
                  if (!cardData?.empty) {
                    return onOpenAlert({
                      message: result?.error?.message,
                      severity: 'error',
                    })
                  }
                }
              }
            }

            if (!draftBasicInfo?.latlon[0] || draftBasicInfo?.latlon[0] == 0) {
              return onOpenAlert({
                message: 'Latitude required in the basic information',
                severity: 'error',
              })
            }

            if (!draftBasicInfo?.latlon[1] || draftBasicInfo?.latlon[1] == 0) {
              return onOpenAlert({
                message: 'Longitude required in the basic information',
                severity: 'error',
              })
            }

            onCreateBankInfoStart({
              params: {
                ...omittedResult,
                merchantId: id ? id : recentMerchant?.id,
                merchantPackageId: packageObj?.id,
                // transactionCode: data?.transactionCode?.label,
                // issuerCode: data?.issuerCode?.label,
                stripeTokenId: result?.token?.id,
                bankCode: data?.bankCode === '' ? null : data?.bankCode,
                branchCode: data?.branchCode === '' ? null : data?.branchCode,
                swiftCode: data?.swiftCode === '' ? null : data?.swiftCode,
                couponCode: data?.couponCode === '' ? null : data?.couponCode,
              },
              closeModal,
              navigate,
              route: `/${adminRoute}/merchantList`,
            })
          })
          setSubmittingStripe(false)

          setSubmitting(false)
        }}
        render={({ values, setFieldValue }) => {
          if (values?.premiumCode?.length < 1) {
            clearMerchantPremiumCodeData()
            setPremiumCodeSubmitted(false)
          }

          if (values?.couponCode?.length < 1) {
            clearMerchantCouponCodeData()
            setCouponCodeSubmitted(false)
          }
          return (
            <Form>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Choose Package</Typography>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    {tempLoader || loading ? (
                      <Grid container justifyContent={'center'}>
                        <CircularProgress size={20} />
                      </Grid>
                    ) : (
                      <>
                        {data?.length ? (
                          data.map((item, index) => (
                            <Grid
                              item
                              md={4}
                              sm={6}
                              xs={12}
                              key={index + 1}
                              sx={{ position: 'relative' }}
                            >
                              {item?.description ? (
                                <Tooltip
                                  title={''}
                                  placement="top"
                                  sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 1,
                                    color: '#11182791',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setOpenDescriptionModal(true)
                                    setSelectedItem(item)
                                  }}
                                >
                                  <InfoIcon />
                                </Tooltip>
                              ) : (
                                ''
                              )}
                              <Card
                                sx={{
                                  minWidth: 275,
                                  cursor: 'pointer',
                                  border: item?.name?.toLowerCase() ===
                                    packageType && {
                                    border: '2px solid #00B59C',
                                    backgroundColor: '#00B59C',
                                  },
                                  '&:hover': {
                                    border: '2px solid #00B59C',
                                  },
                                }}
                                onClick={() => {
                                  setPackageType(item?.name?.toLowerCase())
                                  setPackageObj(item)
                                  onClearMerchantCodeData()
                                  setFieldValue('premiumCode', '')
                                  resetCouponApplied()
                                  setFieldValue('couponCode', '')
                                }}
                              >
                                <CardContent>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: item?.name?.toLowerCase() ===
                                        packageType && {
                                        color: '#ffffff',
                                      },
                                    }}
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    {item?.name}
                                  </Typography>
                                  {item?.setupFee ? (
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: item?.name?.toLowerCase() ===
                                          packageType && {
                                          color: '#ffffff',
                                        },
                                      }}
                                    >
                                      Setup Fee -{' '}
                                      {item?.__country__?.currencySymbol}
                                      {item?.setupFee
                                        ? fixed2Decimal(item?.setupFee)
                                        : ''}{' '}
                                      {item?.setupFeeGST
                                        ? `+ ${
                                            item?.__country__?.currencySymbol
                                          }${
                                            item?.setupFeeGST
                                              ? fixed2Decimal(item?.setupFeeGST)
                                              : ''
                                          } ${
                                            item?.__country__?.gstDisplayText ??
                                            ''
                                          }`
                                        : ''}
                                    </Typography>
                                  ) : (
                                    ''
                                  )}

                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: item?.name?.toLowerCase() ===
                                        packageType && {
                                        color: '#ffffff',
                                      },
                                    }}
                                  >
                                    Package Fee -{' '}
                                    {item?.__country__?.currencySymbol}
                                    {item?.packageFee
                                      ? fixed2Decimal(item?.packageFee)
                                      : ''}{' '}
                                    {item?.packageFeeGST
                                      ? `+ ${
                                          item?.__country__?.currencySymbol
                                        }${
                                          item?.packageFeeGST
                                            ? fixed2Decimal(item?.packageFeeGST)
                                            : ''
                                        } ${
                                          item?.__country__?.gstDisplayText ??
                                          ''
                                        }`
                                      : ''}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: item?.name?.toLowerCase() ===
                                        packageType && {
                                        color: '#ffffff',
                                      },
                                    }}
                                  >
                                    <strong>
                                      Total Fee -{' '}
                                      {item?.__country__?.currencySymbol}
                                      {fixed2Decimal(
                                        item?.setupFee +
                                          item?.packageFee +
                                          item?.setupFeeGST +
                                          item?.packageFeeGST
                                      )}
                                    </strong>
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))
                        ) : (
                          <Grid container justifyContent={'center'}>
                            <Typography variant="h4">
                              No Package Found{' '}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>

                  {piiinkInformationGetByCountry?.allowCouponInMerchant && (
                    <Grid item container spacing={3}>
                      <Grid item xs={12} sx={{ position: 'relative' }}>
                        <Typography variant="h5">Coupon Code Info</Typography>
                        <Tooltip
                          title={
                            'Discount will be applied to amount excluding GST'
                          }
                          placement="top"
                          sx={{
                            position: 'absolute',
                            left: 143,
                            top: -3,
                            color: '#11182791',
                            cursor: 'pointer',
                          }}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Enter Coupon Code'}
                          label={'Coupon Code'}
                          name="couponCode"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                          disabled={premiumCodeSubmitted}
                        />
                        {couponCodeResponse && (
                          <Typography
                            variant="body2"
                            color={couponCodeError ? 'error' : '#2e7d32'}
                            align="center"
                          >
                            {couponCodeResponse}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'inline-flex' }}>
                          <Box>
                            <Button
                              sx={{
                                color: '#ffffff',
                                display: 'flex',
                                background: '#00bfcd',
                                '&:hover': {
                                  background: '#ec4785',
                                },
                                px: 5,
                                mr: 3,
                              }}
                              type="button"
                              onClick={() =>
                                submitCouponCode(values?.couponCode)
                              }
                              disabled={
                                isApplyingCouponCode || premiumCodeSubmitted
                              }
                            >
                              {isApplyingCouponCode ? (
                                <CircularProgress size={20} />
                              ) : (
                                'Apply'
                              )}
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              sx={{
                                color: '#ffffff',
                                background: '#ec4785',
                                '&:hover': {
                                  background: '#ec4785',
                                },
                                px: 5,
                              }}
                              type="button"
                              onClick={() => setFieldValue('couponCode', '')}
                              disabled={
                                isApplyingCouponCode || premiumCodeSubmitted
                              }
                            >
                              Clear
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  {packageObj?.canApplyPremiumCode && (
                    <Grid item container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h5">Premium Code Info</Typography>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        {/* <AppTextField
                          placeholder={'Enter Premium Code If you have any'}
                          label={'Enter Premium Code If you have any'}
                          name="premiumCode"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                          onKeyUp={() => {
                            if (values?.premiumCode?.length === 0) {
                              onClearMerchantCodeData()
                            }
                          }}
                        /> */}

                        <Field
                          // required
                          name="premiumCode"
                          component={PremiumCodeByPanelSelect}
                          merchantId={id}
                          onClearMerchantCodeData={onClearMerchantCodeData}
                          clearMerchantPremiumCodeData={
                            clearMerchantPremiumCodeData
                          }
                          disabled={couponCodeSubmitted}
                          merchantPackageId={packageObj?.id}
                        />

                        {merchantCodeResponse && (
                          <Typography
                            variant="body2"
                            color={merchantCodeError ? 'error' : '#2e7d32'}
                            align="center"
                          >
                            {merchantCodeResponse}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        {/* <Button
                          sx={{
                            color: '#ffffff',
                            display: 'flex',
                            background: '#00bfcd',
                            '&:hover': {
                              background: '#ec4785',
                            },
                            px: 5,
                          }}
                          type="button"
                          onClick={() =>
                            submitPremiumCode(values?.premiumCode?.label)
                          }
                          disabled={isApplyingCode || couponCodeSubmitted}
                        >
                          {isApplyingCode ? (
                            <CircularProgress size={20} />
                          ) : (
                            'Apply'
                          )}
                        </Button> */}

                        <Box sx={{ display: 'inline-flex' }}>
                          <Button
                            sx={{
                              color: '#ffffff',
                              display: 'flex',
                              background: '#00bfcd',
                              '&:hover': {
                                background: '#ec4785',
                              },
                              px: 5,
                              mr: 3,
                            }}
                            type="button"
                            onClick={() =>
                              submitPremiumCode(values?.premiumCode?.label)
                            }
                            disabled={isApplyingCode || couponCodeSubmitted}
                          >
                            {isApplyingCode ? (
                              <CircularProgress size={20} />
                            ) : (
                              'Apply'
                            )}
                          </Button>

                          <Box>
                            <Button
                              sx={{
                                color: '#ffffff',
                                background: '#ec4785',
                                '&:hover': {
                                  background: '#ec4785',
                                },
                                px: 5,
                              }}
                              type="button"
                              onClick={() => {
                                setFieldValue('premiumCode', '')

                                setCouponCodeSubmitted(false)
                              }}
                              disabled={isApplyingCode || couponCodeSubmitted}
                            >
                              Clear
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Total:{' '}
                        {merchantCodeData?.totalAmount ||
                        couponCodeData?.totalAmount ||
                        packageObj?.packageFee ||
                        packageObj?.setupFee
                          ? packageObj?.__country__?.currencySymbol
                          : ''}
                        {showTotal()}
                        {/* {!_.isEmpty(merchantCodeData)
                          ? 0
                          : fixed2Decimal(
                              packageObj?.packageFee +
                                packageObj?.setupFee +
                                packageObj?.setupFeeGST +
                                packageObj?.packageFeeGST
                            )} */}
                      </Typography>
                    </Grid>
                    {/* {packageType === 'premium' || packageType === 'partner' ? (
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          Note*: Selected package requires filling up the bank
                          information.
                        </Typography>
                      </Grid>
                    ) : (
                      ''
                    )} */}
                  </Grid>

                  <Grid container spacing={3} sx={{ mt: 4, ml: 2 }}>
                    <Grid item container>
                      <Typography variant="h5">QR Codes Information</Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} sx={{ position: 'relative' }}>
                      {/* <Field
                        name="transactionCode"
                        component={TransactionQrCodeSelect}
                        includeFilterByCountry={
                          currentUser?.role?.toLowerCase() ===
                          'collaborationpartner'
                            ? true
                            : false
                        }
                        filterByCountryId={
                          draftBasicInfo?.__country__
                            ? draftBasicInfo?.__country__
                            : null
                        }
                      /> */}
                      <Tooltip
                        title={'This field is optional'}
                        placement="top"
                        sx={{
                          position: 'absolute',
                          right: -8,
                          top: -12,
                          color: '#11182791',
                          cursor: 'pointer',
                        }}
                      >
                        <InfoIcon />
                      </Tooltip>

                      <AppTextField
                        placeholder={'Transaction Code'}
                        label={'Transaction Code'}
                        name="transactionCode"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} lg={3} sx={{ position: 'relative' }}>
                      {/* <Field
                        name="issuerCode"
                        component={IssuerQrCodeSelect}
                        includeFilterByCountry={
                          currentUser?.role?.toLowerCase() ===
                          'collaborationpartner'
                            ? true
                            : false
                        }
                        filterByCountryId={
                          draftBasicInfo?.__country__
                            ? draftBasicInfo?.__country__
                            : null
                        }
                      /> */}
                      <Tooltip
                        title={'This field is optional'}
                        placement="top"
                        sx={{
                          position: 'absolute',
                          right: -8,
                          top: -12,
                          color: '#11182791',
                          cursor: 'pointer',
                        }}
                      >
                        <InfoIcon />
                      </Tooltip>
                      <AppTextField
                        placeholder={'Issuer Code'}
                        label={'Issuer Code'}
                        name="issuerCode"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                      />
                    </Grid>
                  </Grid>

                  {packageObj?.getsIssuer ? (
                    <Grid container spacing={3} sx={{ mt: 4, ml: 2 }}>
                      <Grid item container>
                        <Typography variant="h4">Bank Information </Typography>
                      </Grid>

                      <Grid item container>
                        <Typography variant="h5">
                          Receiving Bank Account
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body1" color="#43bfcd">
                          <strong>
                            Note*: Piink will use this bank account details to
                            credit the earnings this merchant is entitled to
                          </strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Bank Name'}
                          label={'Bank Name'}
                          name="bankName"
                          required
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        {currentUser?.role?.toLowerCase() ===
                        'collaborationpartner' ? (
                          <AppTextField
                            placeholder={'BSB'}
                            label={'BSB'}
                            name="BSB"
                            required={
                              draftBasicInfo?.__country__?.countryName?.toLowerCase() ===
                              'australia'
                                ? true
                                : false
                            }
                            variant="outlined"
                            sx={{
                              width: '100%',
                              '& .MuiInputBase-input': {
                                fontSize: 14,
                              },
                            }}
                            size="small"
                          />
                        ) : (
                          <AppTextField
                            placeholder={'BSB'}
                            label={'BSB'}
                            name="BSB"
                            required={
                              globalCountry?.countryName?.toLowerCase() ===
                              'australia'
                                ? true
                                : false
                            }
                            variant="outlined"
                            sx={{
                              width: '100%',
                              '& .MuiInputBase-input': {
                                fontSize: 14,
                              },
                            }}
                            size="small"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Bank Code'}
                          label={'Bank Code'}
                          name="bankCode"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Branch Code'}
                          label={'Branch Code'}
                          name="branchCode"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Account Name'}
                          label={'Account Name'}
                          name="accountName"
                          required
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Account Number'}
                          label={'Account Number'}
                          name="accountNumber"
                          required
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        {currentUser?.role?.toLowerCase() ===
                        'collaborationpartner' ? (
                          <AppTextField
                            placeholder={'Swift Code'}
                            label={'Swift Code'}
                            name="swiftCode"
                            required={
                              draftBasicInfo?.__country__?.countryName?.toLowerCase() !==
                              'australia'
                                ? true
                                : false
                            }
                            variant="outlined"
                            sx={{
                              width: '100%',
                              '& .MuiInputBase-input': {
                                fontSize: 14,
                              },
                            }}
                            size="small"
                          />
                        ) : (
                          <AppTextField
                            placeholder={'Swift Code'}
                            label={'Swift Code'}
                            name="swiftCode"
                            required={
                              globalCountry?.countryName?.toLowerCase() !==
                              'australia'
                                ? true
                                : false
                            }
                            variant="outlined"
                            sx={{
                              width: '100%',
                              '& .MuiInputBase-input': {
                                fontSize: 14,
                              },
                            }}
                            size="small"
                          />
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>

                <Grid container spacing={3} sx={{ mt: 4 }}>
                  <Grid item container>
                    <Typography variant="h5">
                      Paying Credit/Debit Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="#43bfcd" fontSize={13}>
                      <strong>
                        Note*: We don't store bank credit card details due to
                        privacy issues, Stripe(Third party software) stores the
                        credit card details to protect your own privacy
                        information. We will debit your Friendship fee every
                        month from this card.
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} lg={4}>
                    <CheckoutForm setCardData={setCardData} />
                  </Grid>
                </Grid>

                {(packageObj?.canApplyPremiumCode &&
                  merchantCodeData?.status?.toLowerCase() === 'success') ||
                packageObj?.setupFee +
                  packageObj?.packageFee +
                  packageObj?.setupFeeGST +
                  packageObj?.packageFeeGST ===
                  0 ? (
                  ''
                ) : (
                  <>
                    <Box
                      sx={{
                        mt: 4,
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <label>
                            <Field
                              type="checkbox"
                              name="agreedDirectDebitFee"
                              checked={values?.agreedDirectDebitFee}
                              component={Checkbox}
                              size="small"
                              onChange={() =>
                                setFieldValue(
                                  'agreedDirectDebitFee',
                                  !values.agreedDirectDebitFee
                                )
                              }
                              required
                            />
                          </label>
                        </Grid>
                        <Grid item>
                          <Box sx={{ marginLeft: 2, mt: 2 }}>
                            I agree to direct debit fee{' '}
                            <Link
                              target="_blank"
                              to={`/document/direct-debit-fee-terms-and-conditions/${draftBasicInfo?.__country__?.id}`}
                            >
                              terms and conditions{' '}
                            </Link>
                            .
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    mt: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Checkbox color="primary" sx={{ ml: -3 }} /> */}
                    <Box component="span">
                      {packageObj?.getsIssuer ? (
                        <Grid container>
                          <Grid item>
                            <label>
                              <Field
                                type="checkbox"
                                name="agreedTermAndCondition"
                                checked={values?.agreedTermAndCondition}
                                component={Checkbox}
                                size="small"
                                onChange={() =>
                                  setFieldValue(
                                    'agreedTermAndCondition',
                                    !values.agreedTermAndCondition
                                  )
                                }
                                required
                              />
                            </label>
                          </Grid>
                          <Grid item>
                            <Box sx={{ marginLeft: 2, mt: 2 }}>
                              I agree to the{' '}
                              <Link
                                target="_blank"
                                to={`/document/terms-and-conditions/${draftBasicInfo?.__country__?.id}`}
                              >
                                Merchant
                              </Link>{' '}
                              and{' '}
                              <Link
                                target="_blank"
                                to={`/document/issuer-terms-and-conditions/${draftBasicInfo?.__country__?.id}`}
                              >
                                Issuer Agreement
                              </Link>{' '}
                              terms and conditions.
                            </Box>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container>
                          <Grid item>
                            <label>
                              <Field
                                type="checkbox"
                                name="agreedTermAndCondition"
                                checked={values?.agreedTermAndCondition}
                                component={Checkbox}
                                size="small"
                                onChange={() =>
                                  setFieldValue(
                                    'agreedTermAndCondition',
                                    !values.agreedTermAndCondition
                                  )
                                }
                                required
                              />
                            </label>
                          </Grid>
                          <Grid item>
                            <Box sx={{ marginLeft: 2, mt: 2 }}>
                              I agree to the{' '}
                              <Link
                                target="_blank"
                                to={`/document/terms-and-conditions/${draftBasicInfo?.__country__?.id}`}
                              >
                                Merchant
                              </Link>{' '}
                              Agreement terms and conditions.
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ height: 20 }} />
                <Box sx={{ height: 30 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isCreating}
                  >
                    {isCreating ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        {(packageObj?.canApplyPremiumCode &&
                          merchantCodeData?.status?.toLowerCase() ===
                            'success') ||
                        values?.isImported ||
                        packageObj?.setupFee +
                          packageObj?.packageFee +
                          packageObj?.setupFeeGST +
                          packageObj?.packageFeeGST ===
                          0
                          ? 'Register'
                          : 'Pay'}
                      </>
                    )}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    onClick={() => navigate(`/${adminRoute}/merchantList`)}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      />
    </>
  )
}

export default container(AddNewClub)
