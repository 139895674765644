import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchCountryOwnerStart,
  fetchCountryOwnerSuccess,
  fetchCountryOwnerFailure,
  deleteCountryOwnerStart,
  deleteCountryOwnerSuccess,
  deleteCountryOwnerFailure,
  fetchCountryOwnerByIdStart,
  fetchCountryOwnerByIdSuccess,
  fetchCountryOwnerByIdFailure,
  createCountryOwnerStart,
  createCountryOwnerSuccess,
  createCountryOwnerFailure,
  editCountryOwnerStart,
  editCountryOwnerSuccess,
  editCountryOwnerFailure,
  fetchCountryOwnerProfileInfoStart,
  fetchCountryOwnerProfileInfoSuccess,
  fetchCountryOwnerProfileInfoFailure,
  uploadAgreementSuccess,
  uploadAgreementFailure,
  editOwnCountryProfileSuccess,
  editOwnCountryProfileFailure,
} from './countryOwnerSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchCountryOwnerList,
  createCountryOwner,
  fetchCountryOwnerById,
  editCountryOwner,
  deleteCountryOwner,
  fetchCountryOwnerProfileInfo,
  createCountryOwnerAgreement,
  editOwnCountryProfile,
} from '../../api/GlobalAdmin/countryOwners'

import _ from 'lodash'
import { getImageUrl, uploadImage } from 'api/imageUpload'
import { removeParam } from 'utils'

export function* fetchCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryOwnerList(payload))

    yield put(fetchCountryOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchCountryOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchCountryOwnerProfileInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryOwnerProfileInfo())
    yield put(fetchCountryOwnerProfileInfoSuccess(data))
  } catch (err) {
    yield put(fetchCountryOwnerProfileInfoFailure(err))
  }
}

export function* createCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createCountryOwner(payload.params))

    yield put(createCountryOwnerSuccess(data?.data))
    yield put(
      fetchCountryOwnerStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Country Licensee succesfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createCountryOwnerFailure(err))

    console.error(err)
  }
}

export function* deleteCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteCountryOwner(payload?.id))

    if (data) {
      yield put(deleteCountryOwnerSuccess(payload?.id))
      yield put(
        fetchCountryOwnerStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeDeleteModal()
      yield put(
        openAlert({
          message: 'Country Licensee deleted successfully',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteCountryOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchCountryOwnerByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryOwnerById(payload))

    yield put(
      fetchCountryOwnerByIdSuccess({
        ...data?.data,
        issuerCode: data?.issuerCode,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchCountryOwnerByIdFailure(err))
    console.error(err)
  }
}

export function* editCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editCountryOwner(payload?.params?.id, payload?.params)
    )

    yield put(editCountryOwnerSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Country Licensee succesfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editCountryOwnerFailure(err))
    console.error(err)
  }
}

export function* uploadAgreementAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.type)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createCountryOwnerAgreement(payload?.id, {
          agreementUrl: logoUrl,
        })
      )

      yield put(uploadAgreementSuccess())

      yield payload?.closeModal()

      if (payload?.refreshPageData) {
        yield call(payload?.refreshPageData)
      }

      yield put(
        openAlert({
          message: 'Agreement Document Uploaded Successfully',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(uploadAgreementFailure(err))
  }
}

export function* editOwnCountryProfileAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editOwnCountryProfile(payload.params))
    yield put(editOwnCountryProfileSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    yield put(
      openAlert({
        message: 'Country Profile Succesfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editOwnCountryProfileFailure(err))
    console.error(err)
  }
}

export function* watchFetchCountryOwner() {
  yield takeLatest(
    'countryOwner/fetchCountryOwnerStart',
    fetchCountryOwnerAsync
  )
}

export function* watchFetchCountryOwnerProfileInfo() {
  yield takeLatest(
    'countryOwner/fetchCountryOwnerProfileInfoStart',
    fetchCountryOwnerProfileInfoAsync
  )
}

export function* watchCreateCountryOwner() {
  yield takeLatest(
    'countryOwner/createCountryOwnerStart',
    createCountryOwnerAsync
  )
}

export function* watchDeleteCountryOwner() {
  yield takeLatest(
    'countryOwner/deleteCountryOwnerStart',
    deleteCountryOwnerAsync
  )
}

export function* watchFetchCountryOwnerById() {
  yield takeLatest(
    'countryOwner/fetchCountryOwnerByIdStart',
    fetchCountryOwnerByIdAsync
  )
}

export function* watchEditCountryOwner() {
  yield takeLatest('countryOwner/editCountryOwnerStart', editCountryOwnerAsync)
}

export function* watchUploadAgreementAsync() {
  yield takeLatest('countryOwner/uploadAgreementStart', uploadAgreementAsync)
}

export function* watchEditOwnCountryProfile() {
  yield takeLatest(
    'countryOwner/editOwnCountryProfileStart',
    editOwnCountryProfileAsync
  )
}

export function* countryOwnerSagas() {
  yield all([
    call(watchFetchCountryOwner),
    call(watchFetchCountryOwnerProfileInfo),
    call(watchCreateCountryOwner),
    call(watchFetchCountryOwnerById),
    call(watchEditCountryOwner),
    call(watchDeleteCountryOwner),
    call(watchUploadAgreementAsync),
    call(watchEditOwnCountryProfile),
  ])
}
