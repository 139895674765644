import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  Grid,
  Autocomplete,
  Typography,
  CircularProgress,
} from '@mui/material'

import container from './Images.container'
import { Form, Formik, Field, FieldArray } from 'formik'

import Previews from '../Previews'

import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onClearMerchantPremiumCode?: () => void

  onCreateClubStart?: (params) => void
  onCreateStep3DraftStart?: (params) => void
  onCreateStep3GeneralStart?: (params) => void
  onFetchMerchantGeneralImagePanelUserStart?: (params) => void
  onFetchMerchantGeneralWebsiteInfoPanelUserStart?: (params) => void
  onClearDraftData?: () => void

  onOpenAlert?: (params) => void
  handleChangeTab?: any
  siteCoordinator?: any
  club?: any
  auth?: any
  merchant?: any
  adminRoute?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  onCloseAddCountry,
  onCreateClubStart,
  onCreateStep3DraftStart,
  onCreateStep3GeneralStart,
  onFetchMerchantGeneralWebsiteInfoPanelUserStart,
  onFetchMerchantGeneralImagePanelUserStart,
  handleChangeTab,
  onOpenAlert,
  auth: { currentUser },
  siteCoordinator: { globalCountry, recentMerchant },
  club: { isCreating },
  merchant: { isCreatingDraft, isCreatingGeneral, generalImageInfo },
  adminRoute,
  onClearDraftData,
  onClearMerchantPremiumCode,
}) => {
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    onClearMerchantPremiumCode()
  }, [])

  useEffect(() => {
    if (!_.isEmpty(generalImageInfo) && id) {
      setTimeout(() => {
        setUploadedFiles([
          generalImageInfo?.slider1 && {
            preview: generalImageInfo?.slider1,
            type: 'image/png',
          },
          generalImageInfo?.slider2 && {
            preview: generalImageInfo?.slider2,
            type: 'image/png',
          },
          generalImageInfo?.slider3 && {
            preview: generalImageInfo?.slider3,
            type: 'image/png',
          },
          generalImageInfo?.slider4 && {
            preview: generalImageInfo?.slider4,
            type: 'image/png',
          },
          generalImageInfo?.slider5 && {
            preview: generalImageInfo?.slider5,
            type: 'image/png',
          },
          generalImageInfo?.slider6 && {
            preview: generalImageInfo?.slider6,
            type: 'image/png',
          },
        ])
        setLogoImage({
          name: generalImageInfo?.logoUrl,
          preview: generalImageInfo?.logoUrl,
          type: 'image/png',
        })
      }, 2000)
    }
  }, [generalImageInfo?.logoUrl])

  useEffect(() => {
    if (id) {
      onFetchMerchantGeneralImagePanelUserStart(id)
    }
  }, [id])

  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [logoImage, setLogoImage] = useState(null)
  const [fieldIsRequired, setFieldIsRequired] = useState<boolean>(true)

  const closeModal = () => {
    onCloseAddCountry()
  }
  const validationSchema = yup.object({})

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        merchantName: '',
        phonePrefix: '+1',
        phoneNumber: '',

        countryId: globalCountry?.id,
        regionId: null,
        stateId: null,
        areaId: null,
      }}
      validationSchema={validationSchema}
      onSubmit={(data: any, { setSubmitting }) => {
        setSubmitting(true)
        const imageArray = uploadedFiles.reduce(
          (obj, cur, index) => ({ ...obj, [`slider${index + 1}`]: cur }),
          {}
        )

        if (data?.action === 'draft') {
          if (!uploadedFiles?.length && !logoImage?.name) {
            navigate(`/globaladmin/draftList`)
            return
          }
          onCreateStep3DraftStart({
            params: {
              ...imageArray,
              logoUrl: logoImage,
              merchantId: id ? id : recentMerchant?.id,
            },
            closeModal,
            navigate,
            route: `/globaladmin/draftList`,
          })
        } else if (data?.action === 'general') {
          if (fieldIsRequired) {
            if (!uploadedFiles?.length) {
              return onOpenAlert({
                message: 'Images required',
                severity: 'error',
              })
            }
            if (!logoImage?.name) {
              return onOpenAlert({
                message: 'Logo required',
                severity: 'error',
              })
            }
          }
          if (uploadedFiles?.length < 2) {
            onOpenAlert({
              message: 'Upload at least two slider images',
              severity: 'error',
            })
          }

          onCreateStep3GeneralStart({
            params: {
              ...imageArray,
              logoUrl: logoImage,
              merchantId: id ? id : recentMerchant?.id,
            },
            closeModal,
            handleChangeTab,
            refreshTab: true,
          })
        }

        setSubmitting(false)
      }}
      render={({ values, setFieldValue, handleBlur, handleChange }) => (
        <Form>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={4}>
              <Grid item container>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Upload Images {fieldIsRequired && '*'}
                  </Typography>
                </Grid>

                <Grid item xs={12} lg={3} sx={{ mt: 3 }}>
                  <Previews
                    setUploadedFiles={setUploadedFiles}
                    uploadedFiles={uploadedFiles}
                    onOpenAlert={onOpenAlert}
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <div>
                  <Typography variant="h4" sx={{ mb: 3 }}>
                    Upload Logo {fieldIsRequired && '*'}
                  </Typography>
                  {logoImage?.preview && logoImage?.preview !== 'undefined' && (
                    <div>
                      <img
                        alt="not found"
                        width={'250px'}
                        src={
                          logoImage?.preview ||
                          (!_.isEmpty(logoImage)
                            ? URL.createObjectURL(logoImage)
                            : '')
                        }
                      />
                      <br />
                      <button onClick={() => setLogoImage(null)}>Remove</button>
                    </div>
                  )}
                  <br />

                  <input
                    type="file"
                    name="myImage"
                    onChange={(event) => {
                      setLogoImage(event.target.files[0])
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Box sx={{ height: 20 }} />
            <Box sx={{ height: 30 }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  color: '#ffffff',
                  display: 'flex',
                  background: '#00bfcd',
                  '&:hover': {
                    background: '#ec4785',
                  },
                  px: 5,
                }}
                type="submit"
                disabled={isCreatingDraft}
                onClick={() => {
                  setFieldIsRequired(false)

                  setFieldValue('action', 'draft')
                }}
              >
                {isCreatingDraft ? (
                  <CircularProgress size={20} />
                ) : (
                  'Save as Draft'
                )}
              </Button>
              &nbsp; &nbsp;
              <Button
                sx={{
                  color: '#ffffff',
                  display: 'flex',
                  background: '#00bfcd',
                  '&:hover': {
                    background: '#ec4785',
                  },
                  px: 5,
                }}
                type="submit"
                onClick={() => {
                  setFieldIsRequired(true)

                  setFieldValue('action', 'general')
                }}
                disabled={isCreatingGeneral}
              >
                {isCreatingGeneral ? (
                  <CircularProgress size={20} />
                ) : (
                  'Save & Continue'
                )}
              </Button>
              &nbsp; &nbsp;
              <Button
                onClick={() => navigate('/country-licensee/merchantList')}
                variant="outlined"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    />
  )
}

export default container(AddNewClub)
