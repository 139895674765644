import { createSlice } from '@reduxjs/toolkit'

import { ICountryWisePrefix } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: ICountryWisePrefix = {
  countryWisePrefixList: listObj,
  error: null,
  loading: false,
  hasMoreData: false,
}

export const countryWisePrefixSlice = createSlice({
  name: 'countryWisePrefix',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCountryWisePrefixStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryWisePrefixSuccess: (state: any, action) => {
      state.countryWisePrefixList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCountryWisePrefixFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountryWisePrefixByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryWisePrefixByIdSuccess: (state: any, action) => {
      state.countryWisePrefixList = action.payload.data
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCountryWisePrefixByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createCountryWisePrefixStart: (state: any, action) => {
      state.loading = true
    },
    createCountryWisePrefixSuccess: (state: any, action) => {
      state.loading = false
      state.countryWisePrefixList.data = [
        ...state.countryWisePrefixList.data,
        action.payload,
      ]
    },
    createCountryWisePrefixFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteCountryWisePrefixStart: (state: any, action) => {
      state.loading = true
    },
    deleteCountryWisePrefixSuccess: (state: any, action) => {
      state.loading = false
      state.countryWisePrefixList.data =
        state.countryWisePrefixList?.data.filter(
          (item) => item.id !== action.payload
        )
    },
    deleteCountryWisePrefixFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editCountryWisePrefixStart: (state: any, action) => {
      state.loading = true
    },
    editCountryWisePrefixSuccess: (state: any, action) => {
      const result = state.countryWisePrefixList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.countryWisePrefixList.data = result

      state.loading = false
    },
    editCountryWisePrefixFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchCountryWisePrefixStart,
  fetchCountryWisePrefixSuccess,
  fetchCountryWisePrefixFailure,
  createCountryWisePrefixStart,
  createCountryWisePrefixSuccess,
  createCountryWisePrefixFailure,
  editCountryWisePrefixStart,
  editCountryWisePrefixSuccess,
  editCountryWisePrefixFailure,
  fetchCountryWisePrefixByIdStart,
  fetchCountryWisePrefixByIdSuccess,
  fetchCountryWisePrefixByIdFailure,
  deleteCountryWisePrefixStart,
  deleteCountryWisePrefixSuccess,
  deleteCountryWisePrefixFailure,
} = countryWisePrefixSlice.actions

export default countryWisePrefixSlice.reducer
