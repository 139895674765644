import React from 'react'
import { authRouteConfig } from '../pages/auth'
import { unauthorizedConfig } from '../pages/EoiPages'
import { initialUrl } from 'shared/constants/AppConst'
import Error403 from '../pages/errorPages/Error403'
import { useEffect, useState } from 'react'
import { errorPagesConfigs } from '../pages/errorPages'
import { setupConfig } from '../pages/GlobalAdminDashboard/Setup'
import { whiteLabelSetupConfig } from '../pages/GlobalAdminDashboard/WhiteLabelSetup'
import { countryOwnersConfig } from '../pages/GlobalAdminDashboard/CountryOwners'
import { globalSettingsConfig } from '../pages/GlobalAdminDashboard/Settings'
import { globalCPConfig } from '../pages/GlobalAdminDashboard/CollaborationPartners'
import { packageSetupConfig } from '../pages/PackageSetup'
import { memberSetupConfig } from '../pages/Members/Members.routes'
import { countryAdminConfig } from '../pages/CountryAdmin'
import { whiteLabelAdminConfig } from '../pages/WhiteLabelAdmin'
import { gmcAdminConfig } from '../pages/GlobalMarketingCompanyAdmin'
import { profilePage } from '../pages/profile'
import { areaAdminConfig } from 'pages/AreaAdmin'
import { stateAdminConfig } from 'pages/StateAdmin'
import { regionAdminConfig } from 'pages/RegionAdmin'
import { globalUserListSetupConfig } from 'pages/GlobalAdminDashboard/UsersList'
import { charityAdminConfig } from 'pages/CharityAdmin'
import { clubAdminConfig } from 'pages/ClubAdmin'
import { salesAgentConfig } from 'pages/SalesAgentAdmin'
import { collaborationPartnerConfig } from 'pages/CollaborationPartnerAdmin'
import { CPBeneficiariesConfig } from 'pages/GlobalAdminDashboard/CPBeneficiaries'
import { merchantAdminConfig } from 'pages/MerchantAdmin'
import { merchantFranchiseAdminConfig } from 'pages/MerchantFranchiseAdmin'
import { websiteAdminConfig } from '../pages/WebsiteAdmin'
import { companyAdminConfig } from '../pages/CompanyAdmin'
import { globalAdminConfig } from 'pages/GlobalAdminDashboard'
import { globalAdminWithinCountryConfig } from 'pages/GlobalAdminWithCountry'
import { globalAgreementTemplateConfig } from 'pages/GlobalAdminDashboard/AgreementTemplateSetup'
import { commonAnonymousRoutesConfig, commonRoutesConfig } from 'pages/Common'
import Error404 from 'pages/errorPages/Error404/'
import { RootStateOrAny, useSelector } from 'react-redux'
import { getUnauthorizedFallbackUrl } from 'utils/unauthorizedFallbackRoutes'

const useRoutesStructure = () => {
  const [authorizedStructure, setAuthorizedStructure] = useState({})

  const {
    currentUser: { role },
  } = useSelector((state: RootStateOrAny) => state.auth)

  const orgRole = role?.toLowerCase()

  useEffect(() => {
    setAuthorizedStructure({
      fallbackPath: getUnauthorizedFallbackUrl(orgRole),
      unAuthorizedComponent: <Error403 />,
      routes: [
        // ...samplePagesConfigs,
        ...commonRoutesConfig,
        ...profilePage,
        ...memberSetupConfig,
        ...setupConfig,
        ...packageSetupConfig,
        ...countryAdminConfig,
        ...gmcAdminConfig,
        ...websiteAdminConfig,
        ...whiteLabelSetupConfig,
        ...countryOwnersConfig,
        ...globalCPConfig,
        ...areaAdminConfig,
        ...stateAdminConfig,
        ...regionAdminConfig,
        ...charityAdminConfig,
        ...clubAdminConfig,
        ...whiteLabelAdminConfig,
        ...CPBeneficiariesConfig,
        ...globalUserListSetupConfig,
        ...salesAgentConfig,
        ...collaborationPartnerConfig,
        ...merchantAdminConfig,
        ...globalAdminConfig,
        ...globalSettingsConfig,
        ...globalAdminWithinCountryConfig,
        ...globalAgreementTemplateConfig,
        ...merchantFranchiseAdminConfig,
        ...companyAdminConfig,
      ],
    })
  }, [orgRole])

  const unAuthorizedStructure = {
    fallbackPath: initialUrl,
    routes: [...authRouteConfig],
  }

  const subDominStructure = {
    fallbackPath: initialUrl,
    routes: [...unauthorizedConfig],
  }

  const anonymousStructure = {
    routes: [
      ...commonAnonymousRoutesConfig,
      ...errorPagesConfigs,
      {
        path: '*',
        element: <Error404 />,
      },
    ],
  }

  // const anonymousStructure = {
  //   routes: errorPagesConfigs.concat([
  //     {
  //       path: '*',
  //       element: <Error404 />,
  //     },
  //   ]),
  // }

  return {
    authorizedStructure,
    unAuthorizedStructure,
    anonymousStructure,
    subDominStructure,
  }
}

export default useRoutesStructure
