import { axiosRequestCMS } from 'utils'

export const fetchMultilingualList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/multilingual/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMultilingualById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/multilingual/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMultilingual = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/multilingual/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteMultilingual = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/multilingual/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMultilingual = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(
        `/api/multilingual/edit/${id}?isTranslateToAlllang=${params.isTranslateToAlllang}`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchLanguageCodeList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/country/getAlllang`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
