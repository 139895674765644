import { takeLatest, call, all, put, delay, select } from 'redux-saga/effects'
import {
  fetchStateOwnerStart,
  fetchStateOwnerSuccess,
  fetchStateOwnerFailure,
  deleteStateOwnerSuccess,
  deleteStateOwnerFailure,
  createStateOwnerFailure,
  createStateOwnerSuccess,
  fetchStateOwnerByIdSuccess,
  fetchStateOwnerByIdFailure,
  editStateOwnerSuccess,
  editStateOwnerFailure,
  fetchPendingStateOwnerSuccess,
  fetchPendingStateOwnerFailure,
  deletePendingStateOwnerSuccess,
  deletePendingStateOwnerFailure,
  verifyStateOwnerStart,
  verifyStateOwnerSuccess,
  verifyStateOwnerFailure,
  rejectStateOwnerStart,
  rejectStateOwnerSuccess,
  rejectStateOwnerFailure,
  updatePendingStateOwnerStart,
  updatePendingStateOwnerSuccess,
  updatePendingStateOwnerFailure,
  deleteStateOwnerContactSuccess,
  deleteStateOwnerContactFailure,
  fetchStateOwnerProfileInfoStart,
  fetchStateOwnerProfileInfoSuccess,
  fetchStateOwnerProfileInfoFailure,
  uploadAgreementSuccess,
  uploadAgreementFailure,
  editStateOwnerOwnProfileInfoSuccess,
  editStateOwnerOwnProfileInfoFailure,
} from './stateOwnerSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchStateOwnerList,
  createStateOwner,
  fetchStateOwnerById,
  editStateOwner,
  rejectStateOwner,
  deleteStateOwner,
  verifyPendingStateOwner,
  rejectPendingStateOwner,
  saveUpdatePendingStateOwner,
  deleteStateOwnerContact,
  fetchStateOwnerProfileInfo,
  createStateOwnerAgreement,
  editStateOwnerOwnProfile,
} from '../../api/stateOwner'

import _ from 'lodash'
import { getImageUrl, uploadImage } from 'api/imageUpload'
import { removeParam } from 'utils'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchPendingStateOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchStateOwnerList(payload))

    yield put(fetchPendingStateOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchPendingStateOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchStateOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchStateOwnerList(payload))

    yield put(fetchStateOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchStateOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchStateOwnerProfileInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchStateOwnerProfileInfo())
    yield put(fetchStateOwnerProfileInfoSuccess(data))
  } catch (err) {
    yield put(fetchStateOwnerProfileInfoFailure(err))
  }
}

export function* createStateOwnerAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    const { data } = yield call(createStateOwner(payload?.params))

    yield put(createStateOwnerSuccess(data?.results))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'State Licensee Successfully Created',
        severity: 'success',
      })
    )
    yield put(
      fetchStateOwnerStart({
        page: 1,
        limit: 10,
        offset: 0,
        isPending: false,
        countryId: siteCoordinator?.globalCountry?.id,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createStateOwnerFailure(err))

    console.error(err)
  }
}

export function* deleteStateOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteStateOwner(payload?.id))

    yield put(deleteStateOwnerSuccess(payload?.id))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'State Licensee Successfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteStateOwnerFailure(err))
    console.error(err)
  }
}

export function* deletePendingStateOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteStateOwner(payload?.id))

    yield put(deletePendingStateOwnerSuccess(payload?.id))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'State Licensee Request Deleted Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deletePendingStateOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchStateOwnerByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchStateOwnerById(payload))

    yield put(
      fetchStateOwnerByIdSuccess({
        ...data?.data,
        issuerCode: data?.issuerCode,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchStateOwnerByIdFailure(err))
    console.error(err)
  }
}

export function* editStateOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editStateOwner(payload?.params?.id, payload?.params)
    )

    yield put(editStateOwnerSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'State Licensee Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editStateOwnerFailure(err))
    console.error(err)
  }
}

export function* verifyStateOwnerAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    const { data } = yield call(
      verifyPendingStateOwner(payload?.params?.id, payload?.params)
    )

    yield put(verifyStateOwnerSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'State Licensee Succesfully verified',
        severity: 'success',
      })
    )
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchStateOwnerStart({
          page: 1,
          limit: 10,
          offset: 0,
          isPending: false,
          countryId: siteCoordinator?.globalCountry?.id,
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(verifyStateOwnerFailure(err))
    console.error(err)
  }
}

export function* rejectStateOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(rejectPendingStateOwner(payload?.id, payload))

    yield put(rejectStateOwnerSuccess(payload?.id))
    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'State Licensee Succesfully Rejected',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(rejectStateOwnerFailure(err))
    console.error(err)
  }
}

export function* updatePendingStateOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      saveUpdatePendingStateOwner(payload?.params?.id, payload?.params)
    )

    yield put(updatePendingStateOwnerSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'State Licensee Request Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(updatePendingStateOwnerFailure(err))
    console.error(err)
  }
}

export function* deleteStateOwnerContactAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteStateOwnerContact(payload?.id))

    yield put(deleteStateOwnerContactSuccess(payload?.id))

    yield payload.closeModal()
    yield payload?.arrayHelpers?.remove(payload?.index)
    yield put(
      openAlert({
        message: 'State Licensee Successfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteStateOwnerContactFailure(err))
    console.error(err)
  }
}

export function* uploadAgreementAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.type)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createStateOwnerAgreement(payload?.id, {
          agreementUrl: logoUrl,
        })
      )

      yield put(uploadAgreementSuccess())

      yield payload?.closeModal()

      if (payload?.refreshPageData) {
        yield call(payload?.refreshPageData)
      }

      yield put(
        openAlert({
          message: 'Agreement Document Uploaded Successfully',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(uploadAgreementFailure(err))
  }
}

export function* editStateOwnerOwnProfileInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editStateOwnerOwnProfile(payload?.params))

    yield put(editStateOwnerOwnProfileInfoSuccess(data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'State Licensee Profile Successfully Edited',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editStateOwnerOwnProfileInfoFailure(err))
    console.error(err)
  }
}

export function* watchPendingStateOwner() {
  yield takeLatest(
    'stateOwner/fetchPendingStateOwnerStart',
    fetchPendingStateOwnerAsync
  )
}

export function* watchFetchStateOwner() {
  yield takeLatest('stateOwner/fetchStateOwnerStart', fetchStateOwnerAsync)
}

export function* watchFetchStateOwnerProfileInfo() {
  yield takeLatest(
    'stateOwner/fetchStateOwnerProfileInfoStart',
    fetchStateOwnerProfileInfoAsync
  )
}

export function* watchCreateStateOwner() {
  yield takeLatest('stateOwner/createStateOwnerStart', createStateOwnerAsync)
}

export function* watchDeleteStateOwner() {
  yield takeLatest('stateOwner/deleteStateOwnerStart', deleteStateOwnerAsync)
}

export function* watchPendingDeleteStateOwner() {
  yield takeLatest(
    'stateOwner/deletePendingStateOwnerStart',
    deletePendingStateOwnerAsync
  )
}

export function* watchFetchPendingStateOwnerById() {
  yield takeLatest(
    'stateOwner/fetchStateOwnerByIdStart',
    fetchStateOwnerByIdAsync
  )
}

export function* watchEditStateOwner() {
  yield takeLatest('stateOwner/editStateOwnerStart', editStateOwnerAsync)
}

export function* watchVerifyStateOwner() {
  yield takeLatest('stateOwner/verifyStateOwnerStart', verifyStateOwnerAsync)
}

export function* watchRejectStateOwner() {
  yield takeLatest('stateOwner/rejectStateOwnerStart', rejectStateOwnerAsync)
}

export function* watchUpdatePendingStateOwner() {
  yield takeLatest(
    'stateOwner/updatePendingStateOwnerStart',
    updatePendingStateOwnerAsync
  )
}

export function* watchDeleteStateOwnerContact() {
  yield takeLatest(
    'stateOwner/deleteStateOwnerContactStart',
    deleteStateOwnerContactAsync
  )
}

export function* watchUploadAgreementAsync() {
  yield takeLatest('stateOwner/uploadAgreementStart', uploadAgreementAsync)
}

export function* watchEditStateOwnerOwnProfileInfoAsync() {
  yield takeLatest(
    'stateOwner/editStateOwnerOwnProfileInfoStart',
    editStateOwnerOwnProfileInfoAsync
  )
}

export function* stateOwnerSagas() {
  yield all([
    call(watchPendingStateOwner),
    call(watchFetchStateOwner),
    call(watchFetchStateOwnerProfileInfo),
    call(watchCreateStateOwner),
    call(watchDeleteStateOwner),
    call(watchFetchPendingStateOwnerById),
    call(watchEditStateOwner),
    call(watchPendingDeleteStateOwner),
    call(watchVerifyStateOwner),
    call(watchRejectStateOwner),
    call(watchUpdatePendingStateOwner),
    call(watchDeleteStateOwnerContact),
    call(watchUploadAgreementAsync),

    call(watchEditStateOwnerOwnProfileInfoAsync),
    // call(watchEditCountry),
  ])
}
