import React, { useEffect } from 'react'

import _ from 'lodash'
import container from './LogOut.container'

interface AddNewCountryProps {
  onLogOut?: () => void
}

const LogOut: React.FC<AddNewCountryProps> = ({ onLogOut }) => {
  useEffect(() => {
    onLogOut()
  }, [])

  return <></>
}

export default container(LogOut)
