import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  Autocomplete,
} from '@mui/material'
import { FieldProps, getIn } from 'formik'

interface AddNewCountryProps {
  dropDown: any
  setSelectedCountry: any
  selectedArea?: any
  defaultValue?: number
  statusOption?: any[]
  label?: string
}

const StatusSelect: React.FC<FieldProps & AddNewCountryProps> = ({
  field,
  form,
  setSelectedCountry,
  defaultValue,
  selectedArea,
  statusOption,
  label,
  ...props
}) => {
  const alternateOption: any = [
    { label: 'All', name: 'all' },
    { label: 'Sunday', name: 'sunday' },
    { label: 'Monday', name: 'monday' },
    { label: 'Tuesday', name: 'tuesday' },
    { label: 'Wednesday', name: 'wednesday' },
    { label: 'Thursday', name: 'thursday' },
    { label: 'Friday', name: 'friday' },
    { label: 'Saturday', name: 'saturday' },
  ]

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <Autocomplete
      {...props}
      {...field}
      disablePortal
      id="combo-box-demo"
      size="small"
      value={field.value}
      getOptionLabel={(option) => (option ? option.label : '')}
      onChange={(e, value) => {
        form.setFieldValue(field.name, value)
        //
      }}
      options={alternateOption}
      defaultValue={defaultValue ? defaultValue : null}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={errorText?.value || errorText}
          error={!!errorText}
          label={label ? label : 'Select Day'}
        />
      )}
    />
  )
}

export default StatusSelect
