import { axiosRequest } from 'utils'

export const fetchAllCountryPrefix = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/countryWisePrefix/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCountryWisePrefix = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/countryWisePrefix/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCountryWisePrefix = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/countryWisePrefix/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCountryWisePrefix = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/countryWisePrefix/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryWisePrefixId = (id) => {
  return () =>
    axiosRequest
      .get(` /api/globalSetting/countryWisePrefix/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
