import { axiosRequest } from 'utils'

export const globalAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`api/auth/globalAdminLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const countryAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/countryOwnerLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const globalMarketingCompanyAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/gmcUser`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const areaAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`api/auth/areaOwnerLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const stateAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`api/auth/stateOwnerLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const regionAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`api/auth/regionOwnerLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const charityAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`api/auth/charityLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const clubAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`api/auth/clubLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const salesAgentLogin = (params) => {
  return () =>
    axiosRequest
      .post(`api/auth/salesAgentLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
//collaborationPartnerLogin
export const collaborationPartnerLogin = (params) => {
  return () =>
    axiosRequest
      .post(`api/auth/collaborationPartnerLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const whiteLabelAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/whiteLabelUserLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const merchantAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/merchantLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const merchantFranchisorAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/merchantFranchisor`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const websiteAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/websiteAdminLogin`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const companyAdminLogin = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/company`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
