import { axiosRequest } from 'utils'

export const fetchCpGlobalMembershipBeneficiaryList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/cpMembershipBeneficiary/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCpGlobalMembershipBeneficiaryById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/cpMembershipBeneficiary/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCpGlobalMembershipBeneficiary = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/cpMembershipBeneficiary/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCpGlobalMembershipBeneficiary = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/cpMembershipBeneficiary/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCpGlobalMembershipBeneficiary = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/cpMembershipBeneficiary/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
