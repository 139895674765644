import React from 'react'
import List from '@mui/material/List'

import { useRoutes } from '../../../../../routes/routesConfig'
import NavVerticalGroup from './VerticalNavGroup'
import VerticalCollapse from './VerticalCollapse'
import VerticalItem from './VerticalItem'

const VerticalNav = () => {
  const allRoutes = useRoutes()
  return (
    <List
      sx={{
        position: 'relative',
        padding: 0,
      }}
      component="div"
    >
      {allRoutes?.map((item: any) => (
        <React.Fragment key={item?.id}>
          {item?.type === 'group' && <NavVerticalGroup item={item} level={0} />}

          {item?.type === 'collapse' && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item?.type === 'item' && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </List>
  )
}

export default VerticalNav
