import React, { useState } from 'react'
import AppCard from '@crema/core/AppCard'
import { Box, Grid, Stack, TextField, Button } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import AddState from '../AddPrizeDraw'
import { removeEmptyFields } from 'utils'
import CountrySelect from 'components/DropdownComponents/CountrySelect'

import OrganizerTypeSelect from '../OrganizerTypeSelect'

const ListTop = ({ setFilterFormData, clearFilterForm, ...rest }) => {
  const [isAddCountryOpen, setAddCountryOpen] = useState<boolean>(false)

  const organizerTypes = [
    { label: 'Global Admin', value: 'globalAdmin' },
    { label: 'Country Licensee', value: 'countryOwner' },
    { label: 'State Licensee', value: 'stateOwner' },
    { label: 'Region Licensee', value: 'regionOwner' },
    { label: 'Area Licensee', value: 'areaOwner' },
    { label: 'Collaboration Partner', value: 'collaborationPartner' },
    { label: 'Sales Agent', value: 'salesAgent' },
    { label: 'White Label User', value: 'whiteLabelUser' },
    { label: 'Club', value: 'club' },
    { label: 'Charity', value: 'charity' },
    { label: 'Merchant', value: 'merchant' },
  ]

  const onOpenAddCountry = () => {
    setAddCountryOpen(true)
  }

  const onCloseAddCountry = () => {
    setAddCountryOpen(false)
  }

  const validationSchema = yup.object({})

  const onSubmitSearch = (data) => {
    setFilterFormData(
      removeEmptyFields({
        ...data,
        organizerType: data?.organizerType?.value,
        prizeName__substring: data?.prizeName__substring,
        countryId: data?.countryId?.id,
      })
    )
  }

  return (
    <>
      <Button
        onClick={onOpenAddCountry}
        sx={{
          color: '#ffffff',
          display: 'flex',
          marginTop: '-1rem',
          marginLeft: 'auto',
          background: '#00bfcd',
          '&:hover': {
            background: '#ec4785',
          },
          px: 5,
          mb: 5,
        }}
      >
        Add Prize Draw
      </Button>
      <AppCard sx={{ padding: '1rem 0' }}>
        <Box>
          <Formik
            validateOnChange={true}
            initialValues={{
              organizerId: '',
              organizerType: '',
              prizeName__substring: '',
              countryId: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)
              onSubmitSearch(data)
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, resetForm }) => (
              <Form>
                <Grid container spacing={3}>
                  {rest?.currentUser?.role?.toLowerCase() === 'globaladmin' && (
                    <Grid item xs={12} lg={2}>
                      <Field name="countryId" component={CountrySelect} />
                    </Grid>
                  )}
                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      label="Prize draw name"
                      name="prizeName__substring"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  {rest?.currentUser?.role?.toLowerCase() === 'globaladmin' ? (
                    <Grid item xs={12} lg={2}>
                      <AppTextField
                        placeholder="Organizer ID"
                        label="Organizer ID"
                        name="organizerId"
                        type="number"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {rest?.currentUser?.role?.toLowerCase() === 'globaladmin' ? (
                    <Grid item xs={12} sm={6} lg={2}>
                      <Field
                        name="organizerType"
                        component={OrganizerTypeSelect}
                        referredRoles={organizerTypes}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {/* <Grid item xs={12} lg={2}>
                    <Field name="trainingType" component={TrainingType} />
                  </Grid> */}
                  <Grid item>
                    <Button
                      size="small"
                      sx={{
                        px: 5,
                        color: '#ffffff',
                        backgroundColor: '#ec4785',
                        '&:hover': {
                          background: '#ec4785',
                        },
                      }}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      sx={{
                        px: 5,
                      }}
                      variant="outlined"
                      type="submit"
                      onClick={() => {
                        clearFilterForm()
                        resetForm()
                      }}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <AddState
            isAddCountryOpen={isAddCountryOpen}
            onCloseAddCountry={onCloseAddCountry}
          />
        </Box>
      </AppCard>
      <Box sx={{ height: 30 }} />
    </>
  )
}

export default ListTop
