import React, { useEffect, useState } from 'react'
import UploadModern from '../UploadModern'
import { useDropzone } from 'react-dropzone'
import PreviewThumb from '../PreviewThumb/index'
import AppGrid from '@crema/core/AppGrid'

const Previews = ({
  uploadedFiles,
  setUploadedFiles,
  defaultImage,
  setFieldValue,
  index,
}) => {
  // list[${index}].aboutlistDescription
  const dropzone = useDropzone({
    accept: 'image/*,video/*',
    onDrop: (acceptedFiles) => {
      setFieldValue(
        `list[${index}].aboutlisImageUrl`,
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })
  // useEffect(() => {
  //   // setUploadedFiles(dropzone.acceptedFiles)

  //   setFieldValue(`list[${index}].aboutlistImageUrl`, dropzone.acceptedFiles)
  // }, [dropzone.acceptedFiles])

  const onDeleteUploadFile = (file: any) => {
    dropzone.acceptedFiles.splice(dropzone.acceptedFiles.indexOf(file), 1)
    // setUploadedFiles([...dropzone.acceptedFiles])

    setFieldValue(`list[${index}].aboutlisImageUrl`, [
      ...dropzone.acceptedFiles,
    ])
  }

  return (
    <section className="container" style={{ cursor: 'pointer' }}>
      <UploadModern
        uploadText="Drag n drop some files here, or click to select files"
        dropzone={dropzone}
      />
      <AppGrid
        sx={{
          maxWidth: 500,
        }}
        data={uploadedFiles}
        column={4}
        itemPadding={5}
        renderRow={(file, index) => (
          <PreviewThumb
            file={file}
            onDeleteUploadFile={onDeleteUploadFile}
            key={index + file.path}
          />
        )}
      />
    </section>
  )
}

export default Previews
