import React, { useState } from 'react'
import {
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  Autocomplete,
  Typography,
  CircularProgress,
} from '@mui/material'
import { Form, Formik, Field } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import * as yup from 'yup'
import CountrySelect from 'components/DropdownComponents/CountrySelect'
import { documentType } from 'utils'

import AppDialog from '@crema/core/AppDialog'
import container from './AddPrizeDraw.container'
import TrainingType from '../RoleSelect'
import Previews from '../Previews'

interface AddPrizeDrawProps {
  isAddCountryOpen: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry: () => void
  onCreatePrizeDrawByPanelStart: (payload: any) => void
  onOpenAlert: (params) => void
  prizeDraw?: any
  auth?: any
}

const AddPrizeDraw: React.FC<AddPrizeDrawProps> = ({
  isAddCountryOpen,
  onOpenCountryTask,
  onCloseAddCountry,
  onCreatePrizeDrawByPanelStart,
  onOpenAlert,
  prizeDraw: { loading, isCreating },
  auth,
}) => {
  const { currentUser: role } = auth

  const [selectedCountry, setSelectedCountry] = useState<any>(null)

  const [selectedDocumentType, setSelectedDocumentType] = useState(null)
  const [uploadedFiles, setUploadedFiles] = useState<any>([])

  const validationSchema = yup.object({
    // countryId: yup.string().nullable(),
    prizeName: yup.string().required('Name is required'),
    prizeDescription: yup.string().required('Description is required'),
  })

  const closeModal = () => {
    onCloseAddCountry()
  }

  return (
    <AppDialog
      sxStyle={{
        '& .MuiModal-root-MuiDialog-root .MuiDialog-paper .MuiPaper-root-MuiDialog-paper':
          {
            overflowY: '!none',
          },
      }}
      dividers
      maxWidth="md"
      open={isAddCountryOpen}
      onClose={() => onCloseAddCountry()}
      title="Add Prize Draw"
    >
      <Formik
        validateOnChange={true}
        initialValues={{
          countryId: '' || null,
          prizeName: '',
          prizeDescription: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(data: any, { setSubmitting }) => {
          setSubmitting(true)
          onCreatePrizeDrawByPanelStart({
            params: {
              ...data,
              countryId:
                role?.role.toLowerCase() === 'globaladmin'
                  ? data?.countryId?.id
                  : role?.userCountry?.countryId,
              prizeName: data?.prizeName,
              prizeDescription: data?.prizeDescription,
            },
            closeModal,
          })

          setSubmitting(false)
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Box sx={{ p: 5 }}>
              <Grid container spacing={4}>
                {role?.role.toLowerCase() === 'globaladmin' && (
                  <Grid item xs={12} lg={6}>
                    <Field
                      name="countryId"
                      required
                      component={CountrySelect}
                      label="Select Country"
                      onChange={(e, value) => {}}
                    />
                  </Grid>
                )}
                <Grid item xs={12} lg={6}>
                  <AppTextField
                    placeholder={'Name'}
                    name="prizeName"
                    required
                    label={'Prize Name'}
                    variant="outlined"
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <AppTextField
                    placeholder={'Description'}
                    name="prizeDescription"
                    required
                    multiline={true}
                    rows={5}
                    label={'Description'}
                    variant="outlined"
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                    size="small"
                  />
                </Grid>
              </Grid>
              <Box sx={{ height: 30 }} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{
                    color: '#ffffff',
                    display: 'flex',
                    background: '#00bfcd',
                    '&:hover': {
                      background: '#ec4785',
                    },
                    px: 5,
                  }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {/* {isCreating ? <CircularProgress size={20} /> : 'Save'}
                   */}
                  Save
                </Button>
                &nbsp; &nbsp;
                <Button onClick={() => onCloseAddCountry()} variant="outlined">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </AppDialog>
  )
}

export default container(AddPrizeDraw)
