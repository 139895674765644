import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchEoiCountryOwnerStart,
  fetchEoiCountryOwnerSuccess,
  fetchEoiCountryOwnerFailure,
  fetchEoiCountryOwnerByIdStart,
  fetchEoiCountryOwnerByIdSuccess,
  fetchEoiCountryOwnerByIdFailure,
  editEoiCountryOwnerStart,
  editEoiCountryOwnerSuccess,
  editEoiCountryOwnerFailure,
  deleteEoiCountryOwnerSuccess,
  deleteEoiCountryOwnerFailure,
  verifyEoiCountryOwnerStart,
  verifyEoiCountryOwnerSuccess,
  verifyEoiCountryOwnerFailure,
  rejectEoiCountryOwnerStart,
  rejectEoiCountryOwnerSuccess,
  rejectEoiCountryOwnerFailure,
  updateEoiCountryOwnerStart,
  updateEoiCountryOwnerSuccess,
  updateEoiCountryOwnerFailure,
  deleteCountryOwnerContactStart,
  deleteCountryOwnerContactSuccess,
  deleteCountryOwnerContactFailure,
} from './eoiCountryOwnerSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchEoiCountryOwnerList,
  deleteEoiCountryOwner,
  fetchEoiCountryOwnerById,
  editEoiCountryOwner,
  rejectEoiCountryOwner,
  verifyEoiCountryOwner,
  saveUpdateEoiCountryOwner,
  deleteCountryOwnerContact,
} from '../../api/GlobalAdmin/eoiCountryOwners'

import { fetchCountryOwnerList } from '../../api/GlobalAdmin/countryOwners'

import _ from 'lodash'

// export function* fetchEoiCountryOwnerAsync({ payload }: AnyAction) {
//   try {
//     const { data } = yield call(fetchEoiCountryList(payload))

//     yield put(fetchAreaOwnerSuccess(data))
//   } catch (err) {
//     yield put(
//       openAlert({
//         message: err.message,
//         severity: 'error',
//       })
//     )
//     yield put(fetchAreaOwnerFailure(err))
//     console.error(err)
//   }
// }

export function* fetchEoiCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchEoiCountryOwnerList(payload))

    yield put(fetchEoiCountryOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchEoiCountryOwnerFailure(err))
    console.error(err)
  }
}

export function* deleteEoiCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      rejectEoiCountryOwner(payload?.id, {
        rejectReason: payload?.rejectReason,
      })
    )

    yield put(deleteEoiCountryOwnerSuccess(payload?.id))
    yield put(
      fetchEoiCountryOwnerStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Country Licensee EOI Successfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteEoiCountryOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchEoiCountryOwnerByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchEoiCountryOwnerById(payload))

    yield put(fetchEoiCountryOwnerByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchEoiCountryOwnerByIdFailure(err))
    console.error(err)
  }
}

export function* editEoiCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editEoiCountryOwner(payload?.params?.id, payload?.params)
    )

    yield put(editEoiCountryOwnerSuccess(data?.data))
    yield payload.closeModal()
    fetchEoiCountryOwnerStart({
      page: 1,
      limit: 10,
      offset: 0,
    })
    yield put(
      openAlert({
        message: 'Country Licensee EOI Succesfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editEoiCountryOwnerFailure(err))
    console.error(err)
  }
}

export function* verifyEoiCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      verifyEoiCountryOwner(payload?.params?.id, payload?.params)
    )

    yield put(verifyEoiCountryOwnerSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      fetchEoiCountryOwnerStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )

    yield put(
      openAlert({
        message: 'Country Licensee EOI Succesfully Verified',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(verifyEoiCountryOwnerFailure(err))
    console.error(err)
  }
}

export function* rejectEoiCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(rejectEoiCountryOwner(payload?.id, payload))

    yield put(rejectEoiCountryOwnerSuccess(payload?.id))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Country Licensee EOI Succesfully Rejected',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(rejectEoiCountryOwnerFailure(err))
    console.error(err)
  }
}

export function* updateEoiCountryOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      saveUpdateEoiCountryOwner(payload?.params.id, payload?.params)
    )

    yield put(updateEoiCountryOwnerSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      fetchEoiCountryOwnerStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )

    yield put(
      openAlert({
        message: 'Country Licensee EOI Successfully Saved',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(updateEoiCountryOwnerFailure(err))
    console.error(err)
  }
}

export function* deleteCountryOwnerContactAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteCountryOwnerContact(payload?.id))

    yield put(deleteCountryOwnerContactSuccess(payload?.id))

    yield payload.closeModal()
    yield payload?.arrayHelpers?.remove(payload?.index)
    yield put(
      openAlert({
        message: 'Country Licensee Contact Successfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteCountryOwnerContactFailure(err))
    console.error(err)
  }
}

export function* watchEoiCountryOwner() {
  yield takeLatest(
    'eoiCountryOwner/fetchEoiCountryOwnerStart',
    fetchEoiCountryOwnerAsync
  )
}

export function* watchDeleteEoiCountryOwner() {
  yield takeLatest(
    'eoiCountryOwner/deleteEoiCountryOwnerStart',
    deleteEoiCountryOwnerAsync
  )
}

export function* watchDeleteEoiCountryOwnerContacts() {
  yield takeLatest(
    'eoiCountryOwner/deleteCountryOwnerContactStart',
    deleteCountryOwnerContactAsync
  )
}

export function* watchFetchEoiCountryOwnerById() {
  yield takeLatest(
    'eoiCountryOwner/fetchEoiCountryOwnerByIdStart',
    fetchEoiCountryOwnerByIdAsync
  )
}

export function* watchEditEoiCountryOwner() {
  yield takeLatest(
    'eoiCountryOwner/editEoiCountryOwnerStart',
    editEoiCountryOwnerAsync
  )
}

export function* watchVerifyEoiCountryOwner() {
  yield takeLatest(
    'eoiCountryOwner/verifyEoiCountryOwnerStart',
    verifyEoiCountryOwnerAsync
  )
}

export function* watchRejectEoiCountryOwner() {
  yield takeLatest(
    'eoiCountryOwner/rejectEoiCountryOwnerStart',
    rejectEoiCountryOwnerAsync
  )
}

export function* watchUpdateEoiCountryOwner() {
  yield takeLatest(
    'eoiCountryOwner/updateEoiCountryOwnerStart',
    updateEoiCountryOwnerAsync
  )
}

export function* eoiCountryOwnerSagas() {
  yield all([
    call(watchEoiCountryOwner),
    call(watchDeleteEoiCountryOwner),
    call(watchDeleteEoiCountryOwnerContacts),
    call(watchFetchEoiCountryOwnerById),
    call(watchEditEoiCountryOwner),
    call(watchVerifyEoiCountryOwner),
    call(watchRejectEoiCountryOwner),
    call(watchUpdateEoiCountryOwner),
    call(watchEditEoiCountryOwner),
  ])
}
