import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchPremiumCodesStart,
  fetchPremiumCodesSuccess,
  fetchPremiumCodesFailure,
  fetchMerchantPremiumCodesStart,
  fetchMerchantPremiumCodesSuccess,
  fetchMerchantPremiumCodesFailure,
  fetchPremiumMerchantCodesListStart,
  fetchPremiumMerchantCodesListSuccess,
  fetchPremiumMerchantCodesListFailure,
  fetchPremiumMerchantCodesGetAllStart,
  fetchPremiumMerchantCodesGetAllSuccess,
  fetchPremiumMerchantCodesGetAllFailure,
  fetchPremiumMemberCodesGetAllStart,
  fetchPremiumMemberCodesGetAllSuccess,
  fetchPremiumMemberCodesGetAllFailure,
  fetchPremiumMemberCodesListStart,
  fetchPremiumMemberCodesListSuccess,
  fetchPremiumMemberCodesListFailure,
  updatePremiumMerchantCodeStart,
  updatePremiumMerchantCodeSuccess,
  updatePremiumMerchantCodeFailure,
  updatePremiumMemberCodeStart,
  updatePremiumMemberCodeSuccess,
  updatePremiumMemberCodeFailure,
  createPremiumMemberCodeStart,
  createPremiumMemberCodeSuccess,
  createPremiumMemberCodeFailure,
  createPremiumMerchantCodeStart,
  createPremiumMerchantCodeSuccess,
  createPremiumMerchantCodeFailure,
  fetchMerchantPremiumCodesByPanelStart,
  fetchMerchantPremiumCodesByPanelSuccess,
  fetchMerchantPremiumCodesByPanelFailure,
  fetchMemberPremiumCodesByPanelStart,
  fetchMemberPremiumCodesByPanelSuccess,
  fetchMemberPremiumCodesByPanelFailure,
  fetchAllMemberPremiumCodeTransactionSuccess,
  fetchAllMemberPremiumCodeTransactionFailure,
  fetchAssignablePremiumMerchantCountSuccess,
  fetchAssignablePremiumMerchantCountFailure,
  fetchAssignablePremiumMemberCountSuccess,
  fetchAssignablePremiumMemberCountFailure,
} from './premiumCodeSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  getPremiumCodes,
  getMerchantPremiumCodes,
  updatePremiumMerchantCode,
  updatePremiumMemberCode,
  getPremiumMerchantCodesList,
  getPremiumMemberCodesList,
  getPremiumMerchantCodesAll,
  getPremiumMemberCodesAll,
  createPremiumMemberCode,
  createPremiumMerchantCode,
  getMerchantPremiumCodesByPanel,
  getMemberPremiumCodesByPanel,
  fetchAssignablePremiumMemberCount,
  fetchAssignablePremiumMerchantCount,
} from '../../api/premiumCodes'

import { fetchAllMemberPremiumCode } from 'api/memberPremiumCode'

import _ from 'lodash'

export function* fetchPremiumCodesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumCodes(payload))

    yield put(fetchPremiumCodesSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumCodesFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantPremiumCodesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getMerchantPremiumCodes(payload))

    yield put(fetchMerchantPremiumCodesSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantPremiumCodesFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantPremiumCodesByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getMerchantPremiumCodesByPanel(payload))

    yield put(fetchMerchantPremiumCodesByPanelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantPremiumCodesByPanelFailure(err))
    console.error(err)
  }
}

export function* fetchMemberPremiumCodesByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getMemberPremiumCodesByPanel(payload))

    yield put(fetchMemberPremiumCodesByPanelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMemberPremiumCodesByPanelFailure(err))
    console.error(err)
  }
}

export function* updatePremiumMerchantCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      updatePremiumMerchantCode(payload?.id, { isGiveaway: true })
    )

    yield put(updatePremiumMerchantCodeSuccess(data))
    yield put(
      openAlert({
        message: 'Marked as Giveway Successfully',
        severity: 'success',
      })
    )
    yield put(
      fetchPremiumMerchantCodesListStart({
        collaborationPackageId: payload?.params?.collaborationPackageId,
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(updatePremiumMerchantCodeFailure(err))
    console.error(err)
  }
}

export function* updatePremiumMemberCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      updatePremiumMemberCode(payload?.id, { isGiveaway: true })
    )

    yield put(updatePremiumMemberCodeSuccess(data))
    yield put(
      openAlert({
        message: 'Marked as Giveway Successfully',
        severity: 'success',
      })
    )

    yield put(
      fetchPremiumMemberCodesListStart({
        collaborationPackageId: payload?.params?.collaborationPackageId,
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(updatePremiumMemberCodeFailure(err))
    console.error(err)
  }
}

export function* createPremiumMemberCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createPremiumMemberCode(payload.params))

    yield put(createPremiumMemberCodeSuccess(data))
    yield put(
      openAlert({
        message: 'Premium Member Codes Generated Successfully',
        severity: 'success',
      })
    )
    yield payload?.closeModal()
    yield put(
      fetchPremiumMemberCodesGetAllStart({
        id: payload?.params?.id,
        countryId: payload?.params?.countryId,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createPremiumMemberCodeFailure(err))
    console.error(err)
  }
}

export function* createPremiumMerchantCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createPremiumMerchantCode(payload.params))

    yield put(createPremiumMerchantCodeSuccess(data))
    yield put(
      openAlert({
        message: 'Premium Merchant Codes Generated Successfully',
        severity: 'success',
      })
    )
    yield payload?.closeModal()
    yield put(
      fetchPremiumMerchantCodesGetAllStart({
        id: payload?.params?.id,
        countryId: payload?.params?.countryId,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createPremiumMerchantCodeFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumMerchantCodesListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumMerchantCodesList(payload))

    yield put(fetchPremiumMerchantCodesListSuccess(data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumMerchantCodesListFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumMerchantCodesGetAllAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumMerchantCodesAll(payload))

    yield put(fetchPremiumMerchantCodesGetAllSuccess(data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumMerchantCodesGetAllFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumMemberCodesGetAllAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumMemberCodesAll(payload))

    yield put(fetchPremiumMemberCodesGetAllSuccess(data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumMemberCodesGetAllFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumMemberCodesListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumMemberCodesList(payload))

    yield put(fetchPremiumMemberCodesListSuccess(data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumMemberCodesListFailure(err))
    console.error(err)
  }
}

export function* fetchAllMemberPremiumCodeTransactionAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchAllMemberPremiumCode(payload))

    yield put(fetchAllMemberPremiumCodeTransactionSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllMemberPremiumCodeTransactionFailure(err))
    console.error(err)
  }
}

export function* fetchAssignablePremiumMerchantCountAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchAssignablePremiumMerchantCount(payload))

    yield put(fetchAssignablePremiumMerchantCountSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAssignablePremiumMerchantCountFailure(err))
    console.error(err)
  }
}

export function* fetchAssignablePremiumMemberCountAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchAssignablePremiumMemberCount(payload))

    yield put(fetchAssignablePremiumMemberCountSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAssignablePremiumMemberCountFailure(err))
    console.error(err)
  }
}

export function* watchFetchPremiumCodes() {
  yield takeLatest(
    'premiumCodes/fetchPremiumCodesStart',
    fetchPremiumCodesAsync
  )
}

export function* watchFetchMerchantPremiumCodes() {
  yield takeLatest(
    'premiumCodes/fetchMerchantPremiumCodesStart',
    fetchMerchantPremiumCodesAsync
  )
}
export function* watchFetchPremiumMerchantCodesList() {
  yield takeLatest(
    'premiumCodes/fetchPremiumMerchantCodesListStart',
    fetchPremiumMerchantCodesListAsync
  )
}

export function* watchFetchPremiumMerchantCodesGetAll() {
  yield takeLatest(
    'premiumCodes/fetchPremiumMerchantCodesGetAllStart',
    fetchPremiumMerchantCodesGetAllAsync
  )
}

export function* watchFetchPremiumMemberCodesGetAll() {
  yield takeLatest(
    'premiumCodes/fetchPremiumMemberCodesGetAllStart',
    fetchPremiumMemberCodesGetAllAsync
  )
}

export function* watchFetchPremiumMemberCodesList() {
  yield takeLatest(
    'premiumCodes/fetchPremiumMemberCodesListStart',
    fetchPremiumMemberCodesListAsync
  )
}

export function* watchupdatePremiumMerchantCode() {
  yield takeLatest(
    'premiumCodes/updatePremiumMerchantCodeStart',
    updatePremiumMerchantCodeAsync
  )
}

export function* watchupdatePremiumMemberCode() {
  yield takeLatest(
    'premiumCodes/updatePremiumMemberCodeStart',
    updatePremiumMemberCodeAsync
  )
}

export function* watchcreatePremiumMemberCode() {
  yield takeLatest(
    'premiumCodes/createPremiumMemberCodeStart',
    createPremiumMemberCodeAsync
  )
}

export function* watchcreatePremiumMerchantCode() {
  yield takeLatest(
    'premiumCodes/createPremiumMerchantCodeStart',
    createPremiumMerchantCodeAsync
  )
}

export function* watchFetchAllMemberPremiumCodeTransaction() {
  yield takeLatest(
    'premiumCodes/fetchAllMemberPremiumCodeTransactionStart',
    fetchAllMemberPremiumCodeTransactionAsync
  )
}

export function* watchFetchAssignablePremiumMerchantCountAsync() {
  yield takeLatest(
    'premiumCodes/fetchAssignablePremiumMerchantCountStart',
    fetchAssignablePremiumMerchantCountAsync
  )
}

export function* watchFetchAssignablePremiumMemberCountAsync() {
  yield takeLatest(
    'premiumCodes/fetchAssignablePremiumMemberCountStart',
    fetchAssignablePremiumMemberCountAsync
  )
}

export function* premiumCodesSagas() {
  yield all([
    call(watchFetchPremiumCodes),
    call(watchFetchMerchantPremiumCodes),
    call(watchFetchPremiumMerchantCodesList),
    call(watchFetchPremiumMerchantCodesGetAll),
    call(watchFetchPremiumMemberCodesGetAll),
    call(watchFetchPremiumMemberCodesList),
    call(watchupdatePremiumMerchantCode),
    call(watchupdatePremiumMemberCode),
    call(watchcreatePremiumMemberCode),
    call(watchcreatePremiumMerchantCode),
    call(watchFetchAllMemberPremiumCodeTransaction),
    call(watchFetchAssignablePremiumMerchantCountAsync),
    call(watchFetchAssignablePremiumMemberCountAsync),
  ])
}
