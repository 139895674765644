// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchWhiteLabelUserList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelUser/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelUserByWhiteLabel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelUser/getAllByWhiteLabel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const checkActivationEligibility = (id) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/checkActivationEligibility/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const activateWhiteLabel = (id, params) => {
  return () =>
    axiosRequest
      .patch(`/api/whiteLabel/activate/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createWhiteLabelUser = (params) => {
  return () =>
    axiosRequest
      .post(`/api/whiteLabel/whiteLabelUser/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelUserById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelUser/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabelUser = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/whiteLabel/whiteLabelUser/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteWhiteLabelUser = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/whiteLabel/whiteLabelUser/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelReferredCPList = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/salesAgent/getAllCP?salesAgentType=collaborationPartner&isReferredAsCP=true`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelProfileInfo = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelUser/getOwnProfile`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelUserListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelUser/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editOwnWhiteLabelProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/whiteLabel/whiteLabelUser/editOwnProfile`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
