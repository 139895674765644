import { createSlice } from '@reduxjs/toolkit'

import { IClubState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IClubState = {
  clubList: listObj,
  clubListByPanel: listObj,
  allClubList: listObj,
  allClubListByLocation: listObj,

  soleClub: {},
  pendingClubList: listObj,
  error: null,
  loading: false,
  isEditing: false,
  isVerifing: false,
  isRejecting: false,
  isCreating: false,
  hasMoreData: false,
  isGenerating: false,
  sharableClubFormData: {},

  clubProfileInfo: listObj,
}

export const clubSlice = createSlice({
  name: 'club',
  initialState: INITIAL_STATE,
  reducers: {
    fetchClubStart: (state: any, action) => {
      state.loading = true
    },
    fetchClubSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.clubList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchClubFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchClubByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchClubByPanelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.clubListByPanel = action.payload
      state.loading = false
    },
    fetchClubByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPendingClubStart: (state: any, action) => {
      state.loading = true
    },
    fetchPendingClubSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pendingClubList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPendingClubFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchClubByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchClubByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleClub = action.payload

      state.loading = false
    },
    fetchClubByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deletePendingClubStart: (state: any, action) => {
      state.loading = true
    },
    deletePendingClubSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.pendingClubList = state.pendingClubList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePendingClubFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteClubStart: (state: any, action) => {
      state.loading = true
    },
    deleteClubSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.clubList.data = state.clubList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteClubFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createClubStart: (state: any, action) => {
      state.isCreating = true
    },
    createClubSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isCreating = false
      // state.clubList.data = [...state.clubList.data, action.payload]
    },
    createClubFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editClubStart: (state: any, action) => {
      state.isEditing = true
    },
    editClubSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      // const result = state.clubList.data.map((obj) =>
      //   obj.id === action.payload.id ? action.payload : obj
      // )

      // state.clubList.data = result

      const result = state.clubList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.clubList.data = result

      state.isEditing = false
    },
    editClubFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    verifyClubStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifyClubSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isVerifing = false
      state.pendingClubList.data = state.pendingClubList.data.filter(
        (item) => item.id !== action.payload.id
      )
    },
    verifyClubFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },
    rejectClubStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectClubSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.pendingClubList.data = state.pendingClubList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    rejectClubFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },

    updatePendingClubStart: (state: any, action) => {
      state.isEditing = true
    },
    updatePendingClubSuccess: (state: any, action) => {
      state.isEditing = false
      // state.clubList.data = [...state.clubList.data, action.payload]

      const result = state.pendingClubList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.pendingClubList.data = result
    },
    updatePendingClubFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
    sendClubReqStart: (state: any, action) => {
      state.isCreating = true
    },
    sendClubReqSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isCreating = false
      // state.clubList.data = [...state.clubList.data, action.payload]
    },
    sendClubReqFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchClubProfileInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchClubProfileInfoSuccess: (state: any, action) => {
      state.clubProfileInfo = action.payload
      state.isFetching = false
    },
    fetchClubProfileInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchAllClubStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllClubSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.allClubList = action.payload
      state.isFetching = false
    },
    fetchAllClubFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAllClubByLocationStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllClubByLocationSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.allClubListByLocation = action.payload
      state.isFetching = false
    },
    fetchAllClubByLocationFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editOwnClubProfileStart: (state: any, action) => {
      state.isEditing = true
    },
    editOwnClubProfileSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editOwnClubProfileFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    getClubReferralTokenStart: (state: any) => {
      state.isGenerating = true
    },
    getClubReferralTokenSuccess: (state: any, action) => {
      state.isGenerating = false
      state.sharableClubFormData = action.payload
    },
    getClubReferralTokenFailure: (state: any, action) => {
      state.isGenerating = false
      state.error = action.payload
    },
  },
})

export const {
  fetchClubStart,
  fetchClubSuccess,
  fetchClubFailure,
  deleteClubStart,
  deleteClubSuccess,
  deleteClubFailure,
  fetchClubByIdStart,
  fetchClubByIdSuccess,
  fetchClubByIdFailure,
  createClubStart,
  createClubSuccess,
  createClubFailure,
  editClubStart,
  editClubSuccess,
  editClubFailure,
  fetchPendingClubStart,
  fetchPendingClubSuccess,
  fetchPendingClubFailure,
  deletePendingClubStart,
  deletePendingClubSuccess,
  deletePendingClubFailure,
  verifyClubStart,
  verifyClubSuccess,
  verifyClubFailure,
  rejectClubStart,
  rejectClubSuccess,
  rejectClubFailure,
  updatePendingClubStart,
  updatePendingClubFailure,
  updatePendingClubSuccess,
  sendClubReqStart,
  sendClubReqSuccess,
  sendClubReqFailure,
  fetchClubProfileInfoStart,
  fetchClubProfileInfoSuccess,
  fetchClubProfileInfoFailure,

  fetchClubByPanelStart,
  fetchClubByPanelSuccess,
  fetchClubByPanelFailure,

  fetchAllClubStart,
  fetchAllClubSuccess,
  fetchAllClubFailure,

  editOwnClubProfileStart,
  editOwnClubProfileSuccess,
  editOwnClubProfileFailure,

  getClubReferralTokenStart,
  getClubReferralTokenSuccess,
  getClubReferralTokenFailure,

  fetchAllClubByLocationStart,
  fetchAllClubByLocationSuccess,
  fetchAllClubByLocationFailure,
} = clubSlice.actions

export default clubSlice.reducer
