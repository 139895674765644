import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchCpGlobalMembershipBeneficiaryStart,
  fetchCpGlobalMembershipBeneficiarySuccess,
  fetchCpGlobalMembershipBeneficiaryFailure,
  createCpGlobalMembershipBeneficiarySuccess,
  createCpGlobalMembershipBeneficiaryFailure,
  deleteCpGlobalMembershipBeneficiarySuccess,
  deleteCpGlobalMembershipBeneficiaryFailure,
  fetchCpGlobalMembershipBeneficiaryByIdSuccess,
  fetchCpGlobalMembershipBeneficiaryByIdFailure,
  editCpGlobalMembershipBeneficiarySuccess,
  editCpGlobalMembershipBeneficiaryFailure,
} from './CPGlobalMembershipBeneficiarySlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchCpGlobalMembershipBeneficiaryList,
  createCpGlobalMembershipBeneficiary,
  deleteCpGlobalMembershipBeneficiary,
  fetchCpGlobalMembershipBeneficiaryById,
  editCpGlobalMembershipBeneficiary,
} from '../../api/cpGlobalMembershipBeneficiary'

import _ from 'lodash'

export function* fetchCpGlobalMembershipBeneficiaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCpGlobalMembershipBeneficiaryList(payload))

    yield put(fetchCpGlobalMembershipBeneficiarySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCpGlobalMembershipBeneficiaryFailure(err))
  }
}

export function* fetchCpGlobalMembershipBeneficiaryByIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCpGlobalMembershipBeneficiaryById(payload))

    yield put(fetchCpGlobalMembershipBeneficiaryByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCpGlobalMembershipBeneficiaryByIdFailure(err))
  }
}

export function* editCpGlobalMembershipBeneficiaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      editCpGlobalMembershipBeneficiary(payload.params.id, payload.params)
    )

    yield put(editCpGlobalMembershipBeneficiarySuccess(data?.data))
    yield put(
      fetchCpGlobalMembershipBeneficiaryStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'CP Membership beneficiaries successfully edited',
        severity: 'success',
      })
    )
    payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editCpGlobalMembershipBeneficiaryFailure(err))
  }
}

export function* createCpGlobalMembershipBeneficiaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      createCpGlobalMembershipBeneficiary(payload.params)
    )

    yield put(createCpGlobalMembershipBeneficiarySuccess(data?.data))
    yield put(
      fetchCpGlobalMembershipBeneficiaryStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'CP Global Membership Beneficiary succesfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createCpGlobalMembershipBeneficiaryFailure(err))
  }
}

export function* deleteCpGlobalMembershipBeneficiaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(deleteCpGlobalMembershipBeneficiary(payload))

    if (data) {
      yield put(deleteCpGlobalMembershipBeneficiarySuccess(payload))
      yield put(
        fetchCpGlobalMembershipBeneficiaryStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Cp Global Membership Beneficiary deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteCpGlobalMembershipBeneficiaryFailure(err))
  }
}

export function* watchFetchCpGlobalMembershipBeneficiary() {
  yield takeLatest(
    'cpGlobalMembershipBeneficiary/fetchCpGlobalMembershipBeneficiaryStart',
    fetchCpGlobalMembershipBeneficiaryAsync
  )
}

export function* watchCreateCpGlobalMembershipBeneficiary() {
  yield takeLatest(
    'cpGlobalMembershipBeneficiary/createCpGlobalMembershipBeneficiaryStart',
    createCpGlobalMembershipBeneficiaryAsync
  )
}

export function* watchDeleteCpGlobalMembershipBeneficiary() {
  yield takeLatest(
    'cpGlobalMembershipBeneficiary/deleteCpGlobalMembershipBeneficiaryStart',
    deleteCpGlobalMembershipBeneficiaryAsync
  )
}

export function* watchFetchCpGlobalMembershipBeneficiaryById() {
  yield takeLatest(
    'cpGlobalMembershipBeneficiary/fetchCpGlobalMembershipBeneficiaryByIdStart',
    fetchCpGlobalMembershipBeneficiaryByIdAsync
  )
}

export function* watchEditCpGlobalMembershipBeneficiary() {
  yield takeLatest(
    'cpGlobalMembershipBeneficiary/editCpGlobalMembershipBeneficiaryStart',
    editCpGlobalMembershipBeneficiaryAsync
  )
}

export function* cpGlobalMembershipBeneficiarySagas() {
  yield all([
    call(watchFetchCpGlobalMembershipBeneficiary),
    call(watchCreateCpGlobalMembershipBeneficiary),
    call(watchDeleteCpGlobalMembershipBeneficiary),
    call(watchFetchCpGlobalMembershipBeneficiaryById),
    call(watchEditCpGlobalMembershipBeneficiary),
  ])
}
