import { axiosRequest } from 'utils'
import unauthorizedAxiosRequest from 'utils/unauthorizedAxiosRequest'

export const fetchPageList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/page/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPageById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/page/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPageByRole = (role) => {
  return () =>
    axiosRequest
      .get(`/api/page/getOneByRole/${role}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPageByRoleAndCountry = (role, params) => {
  return () =>
    axiosRequest
      .get(`/api/page/getByRole/${role}`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiFetchPageByRoleAndCountry = (role, params) => {
  return () =>
    unauthorizedAxiosRequest
      .get(`/api/page/getByRoleForEoi/${role}`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPage = (params) => {
  return () =>
    axiosRequest
      .post(`/api/page/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deletePage = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/page/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editPage = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/page/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPageBySlug = (slug) => {
  return () =>
    axiosRequest
      .get(`/api/page/getOneBySlug/${slug}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
