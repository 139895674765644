import { createSlice } from '@reduxjs/toolkit'

import { ICollaborationPartnerState } from '../interfaces'

import { listObj } from 'utils'

const INITIAL_STATE: ICollaborationPartnerState = {
  collaborationPartnerList: listObj,
  cpProfileInfo: listObj,
  stripeInfo: listObj,
  globalCollaborationPartnerList: listObj,
  soleCollaborationPartner: {},
  pendingCollaborationPartnerList: [],
  globalCollaborationPackageList: listObj,
  countryAdminCollaborationPackageList: listObj,
  premiumCodeList: listObj,
  premiumCodeListByPanel: listObj,
  error: null,
  loading: false,
  isFetching: false,
  isEditing: false,
  hasMoreData: false,
}

export const collaborationPartnerSlice = createSlice({
  name: 'collaborationPartner',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCollaborationPartnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchCollaborationPartnerSuccess: (state: any, action) => {
      state.collaborationPartnerList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCollaborationPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalCollaborationPartnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalCollaborationPartnerSuccess: (state: any, action) => {
      state.globalCollaborationPartnerList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchGlobalCollaborationPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalCollaborationPackageStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalCollaborationPackageSuccess: (state: any, action) => {
      state.globalCollaborationPackageList = action.payload.data.map((item) => {
        return { ...item, label: item.name }
      })
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchGlobalCollaborationPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryAdminCollaborationPackageStart: (state: any, action) => {
      state.loading = true
    },

    clearCollaborationPackageStart: (state: any) => {
      state.countryAdminCollaborationPackageList = []
    },

    fetchCountryAdminCollaborationPackageSuccess: (state: any, action) => {
      state.countryAdminCollaborationPackageList = action.payload.data.map(
        (item) => {
          return { ...item, label: item.name }
        }
      )
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCountryAdminCollaborationPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCPByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCPByIdSuccess: (state: any, action) => {
      state.soleCollaborationPartner = {
        ...action?.payload,
        issuerCode: action?.payload?.issuerCode,
      }
      state.isFetching = false
    },
    fetchCPByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    clearCPById: (state: any) => {
      state.soleCollaborationPartner = {}
    },
    fetchPremiumCodesStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumCodesSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumCodesFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchPremiumCodesByPanelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumCodesByPanelSuccess: (state: any, action) => {
      state.premiumCodeListByPanel = action.payload
      state.isFetching = false
    },
    fetchPremiumCodesByPanelFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantPremiumCodesStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantPremiumCodesSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchMerchantPremiumCodesFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchPremiumMerchantCodesListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumMerchantCodesListSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumMerchantCodesListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchPremiumMerchantCodesGetAllStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumMerchantCodesGetAllSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumMerchantCodesGetAllFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchPremiumMemberCodesGetAllStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumMemberCodesGetAllSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumMemberCodesGetAllFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchPremiumMemberCodesListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumMemberCodesListSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumMemberCodesListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    updatePremiumMerchantCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    updatePremiumMerchantCodeSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    updatePremiumMerchantCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    updatePremiumMemberCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    updatePremiumMemberCodeSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    updatePremiumMemberCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createPremiumMemberCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    createPremiumMemberCodeSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    createPremiumMemberCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createPremiumMerchantCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    createPremiumMerchantCodeSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    createPremiumMerchantCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchPendingCollaborationPartnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchPendingCollaborationPartnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pendingCollaborationPartnerList = action.payload.data
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPendingCollaborationPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteCollaborationPartnerStart: (state: any, action) => {
      state.loading = true
    },
    deleteCollaborationPartnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.CollaborationPartnerList = state.CollaborationPartnerList.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteCollaborationPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createCollaborationPartnerStart: (state: any, action) => {
      state.loading = true
    },
    createCollaborationPartnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.CollaborationPartnerList.results = [
        ...state.CollaborationPartnerList.results,
        action.payload,
      ]
    },
    createCollaborationPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editCollaborationPartnerStart: (state: any, action) => {
      state.isEditing = true
    },
    editCollaborationPartnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isEditing = false
      // state.collaborationPartnerList = [
      //   ...state.collaborationPartnerList,
      //   action.payload,
      // ]
    },
    editCollaborationPartnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
    fetchCPProfileInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCPProfileInfoSuccess: (state: any, action) => {
      state.cpProfileInfo = action.payload
      state.isFetching = false
    },
    fetchCPProfileInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchStripeInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchStripeInfoSuccess: (state: any, action) => {
      state.stripeInfo = action.payload
      state.isFetching = false
    },
    fetchStripeInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editOwnCpProfileStart: (state: any, action) => {
      state.isEditing = true
    },
    editOwnCpProfileSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editOwnCpProfileFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    editCpOwnerOwnProfileInfoStart: (state: any, action) => {
      state.isEditing = true
    },
    editCpOwnerOwnProfileInfoSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editCpOwnerOwnProfileInfoFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
  },
})

export const {
  fetchCollaborationPartnerStart,
  fetchCollaborationPartnerSuccess,
  fetchCollaborationPartnerFailure,
  deleteCollaborationPartnerStart,
  deleteCollaborationPartnerSuccess,
  deleteCollaborationPartnerFailure,
  createCollaborationPartnerStart,
  createCollaborationPartnerSuccess,
  createCollaborationPartnerFailure,
  editCollaborationPartnerStart,
  editCollaborationPartnerSuccess,
  editCollaborationPartnerFailure,
  fetchPendingCollaborationPartnerStart,
  fetchPendingCollaborationPartnerSuccess,
  fetchPendingCollaborationPartnerFailure,
  fetchGlobalCollaborationPackageStart,
  fetchGlobalCollaborationPackageSuccess,
  fetchGlobalCollaborationPackageFailure,
  fetchCPByIdStart,
  fetchCPByIdSuccess,
  fetchCPByIdFailure,
  fetchGlobalCollaborationPartnerStart,
  fetchGlobalCollaborationPartnerSuccess,
  fetchGlobalCollaborationPartnerFailure,
  fetchCountryAdminCollaborationPackageStart,
  fetchCountryAdminCollaborationPackageSuccess,
  clearCollaborationPackageStart,
  fetchCountryAdminCollaborationPackageFailure,
  fetchPremiumCodesStart,
  fetchPremiumCodesSuccess,
  fetchPremiumCodesFailure,
  fetchMerchantPremiumCodesStart,
  fetchMerchantPremiumCodesSuccess,
  fetchMerchantPremiumCodesFailure,
  fetchPremiumMerchantCodesListStart,
  fetchPremiumMerchantCodesListSuccess,
  fetchPremiumMerchantCodesListFailure,
  fetchPremiumMerchantCodesGetAllStart,
  fetchPremiumMerchantCodesGetAllSuccess,
  fetchPremiumMerchantCodesGetAllFailure,
  fetchPremiumMemberCodesGetAllStart,
  fetchPremiumMemberCodesGetAllSuccess,
  fetchPremiumMemberCodesGetAllFailure,
  fetchPremiumMemberCodesListStart,
  fetchPremiumMemberCodesListSuccess,
  fetchPremiumMemberCodesListFailure,
  updatePremiumMerchantCodeStart,
  updatePremiumMerchantCodeSuccess,
  updatePremiumMerchantCodeFailure,
  updatePremiumMemberCodeStart,
  updatePremiumMemberCodeSuccess,
  updatePremiumMemberCodeFailure,
  createPremiumMemberCodeStart,
  createPremiumMemberCodeSuccess,
  createPremiumMemberCodeFailure,
  createPremiumMerchantCodeStart,
  createPremiumMerchantCodeSuccess,
  createPremiumMerchantCodeFailure,
  fetchCPProfileInfoStart,
  fetchCPProfileInfoSuccess,
  fetchCPProfileInfoFailure,
  editOwnCpProfileStart,
  editOwnCpProfileSuccess,
  editOwnCpProfileFailure,

  fetchPremiumCodesByPanelStart,
  fetchPremiumCodesByPanelSuccess,
  fetchPremiumCodesByPanelFailure,

  fetchStripeInfoStart,
  fetchStripeInfoSuccess,
  fetchStripeInfoFailure,

  clearCPById,
} = collaborationPartnerSlice.actions

export default collaborationPartnerSlice.reducer
