import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'

const UserProfile = React.lazy(() => import('./UserProfile'))
export const profilePage = [
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/my-account',
    element: <UserProfile />,
  },
]
