import axiosRequest from 'utils/axiosRequest'
import unauthorizedAxiosRequest from 'utils/unauthorizedAxiosRequest'
import { removeEmptyFields } from 'utils'
import axios from 'axios'

export const createSalesAgentEOI = (params) => {
  return () =>
    unauthorizedAxiosRequest
      .post(`/api/salesAgent/eoiCreate`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCharityEOI = (params) => {
  return () =>
    unauthorizedAxiosRequest
      .post(`/api/charity/eoiCreate`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createClubEOI = (params) => {
  return () =>
    unauthorizedAxiosRequest
      .post(`/api/club/eoiCreate`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateStep1Draft = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi-draft/step1`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateStep1General = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi/step1`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiEditStep1Draft = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/eoi-draft/step1/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiEditStep1General = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/eoi/step1/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateStep2Draft = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi-draft/step2`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateStep2General = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi/step2`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiEditStep2Draft = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/eoi-draft/step2/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiEditStep2General = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/eoi/step2/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateStep3Draft = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi-draft/step3`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateStep3General = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi/step3`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiEditStep3Draft = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/eoi-draft/step3/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiEditStep3General = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/eoi/step3/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateStep4Draft = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi-draft/step4`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateStep4General = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi/step4`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiEditStep4Draft = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/eoi-draft/step4/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiEditStep4General = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/eoi/step4/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiFetchMerchantBasicInfo = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/eoi?merchantToken=${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiFetchMerchantWebsiteInfo = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/eoi/webSite?merchantToken=${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiFetchMerchantImage = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/eoi/image?merchantToken=${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiFetchMerchantDiscount = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/eoi/discount?merchantToken=${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiFetchMerchantBankInfo = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/eoi/bank?merchantToken=${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateBankInfo = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi/step5`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCouponCodeLogUpdate = (params) => {
  return () =>
    axiosRequest
      .put(
        `/api/merchant/eoi/step5/CouponCodeLogUpdate/${params?.couponLogId}`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCheckApplyMerchantCodeUponUpgrade = (params) => {
  return () =>
    unauthorizedAxiosRequest
      .post(
        `/api/merchant/general/register/checkApplyMerchantCodeUponUpgrade`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCheckApplyMerchantPremiumCode = (params) => {
  return () =>
    unauthorizedAxiosRequest
      .post(
        `/api/merchant/general/register/checkApplyMerchantCode`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiFetchReferralFromToken = (params) => {
  return () =>
    unauthorizedAxiosRequest
      .get(`/api/merchant/eoi/getReferralFromToken`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCheckApplyCouponCode = (params) => {
  return () =>
    unauthorizedAxiosRequest
      .post(`/api/merchant/eoi/step5/CouponCode`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const eoiCreateMerchantGroup = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/fanchisor/createEOI`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
