import { axiosRequestCMS } from 'utils'

export const fetchClientList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/clientOfItCompany/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchClientById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/clientOfItCompany/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createClient = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/clientOfItCompany/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteClient = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/clientOfItCompany/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editClient = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/clientOfItCompany/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
