/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'
import { fetchCPProfileInfoStart } from 'store/collaborationPartner/collaborationPartnerSlice'

import { fetchMemberStart } from 'store/members/membersSlice'
import {
  fetchMerchantGeneralStart,
  fetchAllReferredMerchantListStart,
  approveReferredMerchantStart,
  rejectReferredMerchantStart,
} from 'store/merchant/merchantSlice'
// import {
//   fetchCountryStart,
//   createCountryStart,
// } from '../../../store/countrySetup/countrySetupSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  areaOwner: state.areaOwner,
  stateOwners: state.stateOwner,
  charities: state.charities,
  club: state.club,
  members: state.members,
  merchant: state.merchant,
  siteCoordinator: state.siteCoordinator,
  collaborationPartner: state.collaborationPartner,
})
const mapDispatchToProps = (dispatch) => ({
  onFetchMemberStart: (payload) => dispatch(fetchMemberStart(payload)),
  onFetchMerchantGeneralStart: (payload) =>
    dispatch(fetchMerchantGeneralStart(payload)),
  onFetchCPProfileInfoStart: (payload) =>
    dispatch(fetchCPProfileInfoStart(payload)),
  onFetchAllReferredMerchantListStart: (payload) =>
    dispatch(fetchAllReferredMerchantListStart(payload)),
  onApproveReferredMerchantStart: (payload) =>
    dispatch(approveReferredMerchantStart(payload)),
  onRejectReferredMerchantStart: (payload) =>
    dispatch(rejectReferredMerchantStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
