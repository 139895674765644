import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableHeading from './TableHeading'
import TableItem from './TableItem'
import {
  TablePagination,
  Box,
  Button,
  TableRow,
  CircularProgress,
  TableCell,
} from '@mui/material'
import AppTableContainer from '@crema/core/AppTableContainer'
import TableDataNotFound from 'components/TableDataNotFound'
import { CSVLink, CSVDownload } from 'react-csv'

// import { RecentOrders } from "types/models/ecommerce/EcommerceApp";
import { CountrySetup } from 'types/models/CountrySetup'
import moment from 'moment-timezone'

interface countySetupTableProps {
  countryData: CountrySetup[]
  page: any
  setPage: any
  rowsPerPage: any
  setRowsPerPage: any
  handleChangePage: any
  handleChangeRowsPerPage: any
  hasMoreData?: boolean
  loading?: boolean
  totalCount?: number
  pageNumber?: number
  adminRoute?: any
  currentUser?: any
  onDeleteClubStart?: (payload: any) => void
  refreshPageData?: () => void
  onRejectReferredMerchantStart?: (payload: any) => void
  onApproveReferredMerchantStart?: (payload: any) => void
  isRejecting?: boolean
  isApproving?: boolean
}

const CountryTable: React.FC<countySetupTableProps> = ({
  countryData,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  hasMoreData,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  pageNumber,
  onDeleteClubStart,
  refreshPageData,
  adminRoute,
  currentUser,
  onRejectReferredMerchantStart,
  onApproveReferredMerchantStart,
  isRejecting,
  isApproving,
}) => {
  const [tempLoader, setTempLoader] = useState(true)
  const [openMerchantFormLink, setOpenMerchantFormLink] = useState(false)

  const closeMerchantFormLink = () => {
    setOpenMerchantFormLink(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  const toExportData = countryData?.length
    ? countryData?.map((item: any) => {
        return {
          Id: item?.id,
          'Merchant Name': item?.merchantName,
          Email: item?.merchantEmail,
          Username: item?.username,
          Phone: item?.merchantPhoneNumber
            ? ` ${item?.__country__?.phonePrefix} ${item?.merchantPhoneNumber}`
            : '-',
          State: item?.__state__?.stateName,
          Region: item?.__region__?.regionName,
          Area: item?.__area__?.areaName,
          Status: item?.registrationStatus,
          'Issuer Code': item?.issuerCode ?? '-',
          'Transaction Code': item?.transactionCode ?? '-',
          'Registration Date':
            item?.createdAt == null
              ? '-'
              : moment(item?.createdAt).format('DD-MM-YYYY'),
        }
      })
    : []

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, 1000, 2000]}
          component="div"
          count={totalCount}
          page={page}
          nextIconButtonProps={{ disabled: !hasMoreData }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <AppTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableHeading />
          </TableHead>
          <TableBody>
            {tempLoader ? (
              <TableRow className="item-hover">
                <TableCell align="center" colSpan={12}>
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {countryData?.length ? (
                  countryData.map((data, index) => (
                    <TableItem
                      data={data}
                      key={data.sn}
                      count={index + 1}
                      onDeleteClubStart={onDeleteClubStart}
                      refreshPageData={refreshPageData}
                      pageNumber={pageNumber}
                      rowsPerPage={rowsPerPage}
                      adminRoute={adminRoute}
                      currentUser={currentUser}
                      onRejectReferredMerchantStart={
                        onRejectReferredMerchantStart
                      }
                      onApproveReferredMerchantStart={
                        onApproveReferredMerchantStart
                      }
                      isRejecting={isRejecting}
                      isApproving={isApproving}
                    />
                  ))
                ) : (
                  <TableDataNotFound colSpan={12} />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </AppTableContainer>
    </>
  )
}

export default CountryTable
