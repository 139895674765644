import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material'

import container from './WebsiteDetails.container'
import { Form, Formik } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import RichTextEditor from 'components/RichTextEditor'

import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { regMatch } from 'utils'
import _ from 'lodash'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onClearMerchantPremiumCode?: () => void

  onCreateClubStart?: (params) => void
  onOpenAlert?: (params) => void
  onCreateStep2DraftStart?: (params) => void
  onCreateStep2GeneralStart?: (params) => void
  onFetchMerchantGeneralWebsiteInfoPanelUserStart?: (params) => void
  onFetchMerchantDraftBasicInfoPanelUserStart?: (params) => void
  siteCoordinator?: any
  handleChangeTab?: any
  merchant?: any
  club?: any
  auth?: any
  adminRoute?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  onCloseAddCountry,
  onCreateStep2DraftStart,
  onCreateStep2GeneralStart,
  onFetchMerchantGeneralWebsiteInfoPanelUserStart,
  onOpenAlert,
  handleChangeTab,
  merchant: { isCreatingDraft, isCreating, generalWebsiteInfo },
  adminRoute,
  onClearMerchantPremiumCode,
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [fieldIsRequired, setFieldIsRequired] = useState<boolean>(true)

  useEffect(() => {
    onClearMerchantPremiumCode()
  }, [])

  useEffect(() => {
    if (id) {
      onFetchMerchantGeneralWebsiteInfoPanelUserStart(id)
    }
  }, [id])

  const closeModal = () => {
    onCloseAddCountry()
  }

  const validationSchema = yup.object({
    websiteLink: yup
      .string()
      .nullable()
      .matches(regMatch, 'Website link should be a valid URL'),
    instagramLink: yup
      .string()
      .nullable()
      .matches(regMatch, 'Instagram link should be a valid URL'),
    facebookLink: yup
      .string()
      .nullable()
      .matches(regMatch, 'Facebook link should be a valid URL'),
  })

  const [pageMarkupContent, setPageMarkupContent] = useState('')

  useEffect(() => {
    if (!_.isEmpty(generalWebsiteInfo)) {
      setPageMarkupContent(generalWebsiteInfo?.merchantDescription ?? '')
    }
  }, [generalWebsiteInfo])
  return (
    <Formik
      validateOnChange={true}
      enableReinitialize
      initialValues={{
        websiteLink: generalWebsiteInfo?.websiteLink ?? null,
        instagramLink: generalWebsiteInfo?.instagramLink ?? null,
        openingHourInfo: generalWebsiteInfo?.openingHourInfo ?? '',
        facebookLink: generalWebsiteInfo?.facebookLink ?? null,
        merchantDescription: generalWebsiteInfo?.merchantDescription ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={(data: any, { setSubmitting }) => {
        setSubmitting(true)

        if (data?.action === 'draft') {
          if (!pageMarkupContent?.length) {
            return onOpenAlert({
              message: 'Merchant Bio is required',
              severity: 'error',
            })
          }
          onCreateStep2DraftStart({
            params: {
              ...data,
              merchantId: id,
              merchantDescription: pageMarkupContent,
              websiteLink: data?.websiteLink === '' ? null : data?.websiteLink,
              instagramLink:
                data?.instagramLink === '' ? null : data?.instagramLink,
              facebookLink:
                data?.facebookLink === '' ? null : data?.facebookLink,
            },
            closeModal,
            handleChangeTab,
            navigate,
            route: `/${adminRoute}/draftList`,
          })
        } else if (data?.action === 'general') {
          if (!pageMarkupContent?.length) {
            return onOpenAlert({
              message: 'Merchant Bio is required',
              severity: 'error',
            })
          }
          onCreateStep2GeneralStart({
            params: {
              ...data,
              merchantId: id,
              merchantDescription: pageMarkupContent,
              websiteLink: data?.websiteLink === '' ? null : data?.websiteLink,
              instagramLink:
                data?.instagramLink === '' ? null : data?.instagramLink,
              facebookLink:
                data?.facebookLink === '' ? null : data?.facebookLink,
            },
            closeModal,
            handleChangeTab,
          })
        }

        setSubmitting(false)
      }}
      render={({ setFieldValue }) => (
        <Form>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Website Specific Details</Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <AppTextField
                  placeholder={'Website'}
                  label={'Website'}
                  name="websiteLink"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <AppTextField
                  placeholder={'Instagram'}
                  label={'Instagram'}
                  name="instagramLink"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <AppTextField
                  placeholder={'Facebook'}
                  label={'Facebook'}
                  name="facebookLink"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>

              <Grid item container xs={12} lg={6} marginY={4}>
                <AppTextField
                  placeholder={'Business Opening Hours'}
                  label={'Business Opening Hours'}
                  name="openingHourInfo"
                  required={fieldIsRequired}
                  multiline={true}
                  rows={8}
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>
            </Grid>

            {/* <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12} lg={6}>
                <AppTextField
                  placeholder={'Merchant Bio'}
                  label={'Merchant Bio'}
                  multiline={true}
                  required={fieldIsRequired}
                  rows={5}
                  name="merchantDescription"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>
            </Grid> */}

            <Grid item xs={12}>
              <Typography gutterBottom>Merchant Bio *</Typography>
              <RichTextEditor
                markup={pageMarkupContent}
                setMarkup={setPageMarkupContent}
              />
            </Grid>

            <Box sx={{ height: 20 }} />
            <Box sx={{ height: 30 }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  color: '#ffffff',
                  display: 'flex',
                  background: '#00bfcd',
                  '&:hover': {
                    background: '#ec4785',
                  },
                  px: 5,
                }}
                type="submit"
                disabled={isCreatingDraft}
                onClick={() => {
                  setFieldIsRequired(false)

                  setFieldValue('action', 'draft')
                }}
              >
                {isCreatingDraft ? (
                  <CircularProgress size={20} />
                ) : (
                  'Save as Draft'
                )}
              </Button>
              &nbsp; &nbsp;
              <Button
                sx={{
                  color: '#ffffff',
                  display: 'flex',
                  background: '#00bfcd',
                  '&:hover': {
                    background: '#ec4785',
                  },
                  px: 5,
                }}
                type="submit"
                disabled={isCreating}
                onClick={() => {
                  setFieldIsRequired(true)

                  setFieldValue('action', 'general')
                }}
              >
                {isCreating ? (
                  <CircularProgress size={20} />
                ) : (
                  'Save & Continue'
                )}
              </Button>
              &nbsp; &nbsp;
              <Button
                onClick={() => navigate(`/${adminRoute}/merchantList`)}
                variant="outlined"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    />
  )
}

export default container(AddNewClub)
