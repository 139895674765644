import { axiosRequest } from 'utils'

export const fetchAreaList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/area/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
