// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchGmcCollaborationPartnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getAllCP?salesAgentType=collaborationPartner`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createGmcCollaborationPartner = (params) => {
  return () =>
    axiosRequest
      .post(`/api/salesAgent/createCP`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGmcCollaborationPartnerById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPartner/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editGmcCollaborationPartner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/collaborationPartner/upadateVerifyEOI/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const viewGmcCollaborationPartner = (id, params) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPartner/upadateVerifyEOI/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSalesAgentByEmail = (email) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/findSalesAgentByEmail?email=${email}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGmcCollaborationPackageList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPackage/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const gmcUpgradeToCP = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/upgradeToCP/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const gmcUpgradeCPPackage = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/upgradeCPPackage/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const gmcUpdateCPPaymentStatus = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/updateCPPaymentStatus/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGmcReferredCPList = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/salesAgent/getAllCP?salesAgentType=collaborationPartner&isReferredAsCP=true`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGmcReferredById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const gmcReferredUpdate = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/updateAndVerifyCP/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectGmcReferred = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyGmcReferred = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/updateAndVerifyCP/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveGmcReferred = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/saveCPRefer/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGmcPurchaseHistoryList = (salesAgentId: number, params) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPackageLog/getAll?salesAgentId=${salesAgentId}`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAssignIssuerQRCodeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getAllUnUsedIssuerCodesByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCpPurchaseHistoryList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPackageLog/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const upgradeCPPackageRepurchaseApi = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/upgradeCPPackageRepurchase/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
