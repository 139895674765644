/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { openAlert } from 'store/alert/alertSlice'

import {
  createTransactionBatchStart,
  fetchAssignerStart,
} from 'store/qrCodes/qrCodesSlice'

import { fetchMultipleAssignerByRoleStart } from 'store/merchant/merchantSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  qrCodes: state.qrCodes,
  siteCoordinator: state.siteCoordinator,
  merchant: state.merchant,
})

const mapDispatchToProps = (dispatch) => ({
  onCreatePageStart: (payload) =>
    dispatch(fetchMultipleAssignerByRoleStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
