import React, { useEffect } from 'react'
import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import { Box, Tabs, Tab, Typography } from '@mui/material'
import AppComponentHeader from '@crema/core/AppComponentHeader'
import AppCard from '@crema/core/AppCard'
import BasicInfo from './components/BasicInfo'
import WebsiteDetails from './components/WebsiteDetails'
import Images from './components/Images'
import DiscountOffered from './components/DiscountOffered'
import PackagesAndBankInfo from './components/PackagesAndBankInfo'

import container from './EditMerchantRequest.container'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import _ from 'lodash'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  disabled?: boolean
  adminRoute?: any
}

function handleClick(event) {
  event.preventDefault()
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  if (index == 0) {
    return
  }
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CustomizedBreadcrumbs = ({ adminRoute }) => {
  const navigate = useNavigate()

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          label="Merchants"
          icon={<HomeIcon fontSize="small" />}
          onClick={() => navigate('/dashboard')}
        />
        <StyledBreadcrumb
          label="Draft List"
          onClick={() => navigate(`/${adminRoute}/draftList`)}
        />
        <StyledBreadcrumb label="Send Merchant Request" />
      </Breadcrumbs>
    </div>
  )
}

const SendMerchantRequest = ({
  onFetchMemberStart,
  onCreateStep1DraftStart,
  stateOwners: { hasMoreData },
  members: {
    memberList: { data, hasMore, totalCount, count, page: pageNumber },
    loading,
  },
  onFetchMerchantDraftBasicInfoPanelUserStart,
  countrySetup: { countryList, countryStripeKeyInfo, isFetching },

  auth: { currentUser },
  siteCoordinator: { globalCountry },
  merchant: { draftBasicInfo },
  onClearDraftData,
  onClearGeneralData,
  adminRoute,
  onClearCountryStripeKey,
  onFetchCountryStripeKeyStart,
  ...rest
}) => {
  let { id } = useParams()

  const stripePromise = loadStripe(
    countryStripeKeyInfo?.stripePublishableKey
      ? countryStripeKeyInfo?.stripePublishableKey
      : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  )

  useEffect(() => {
    if (id) {
      if (!_.isEmpty(draftBasicInfo?.__country__)) {
        onClearCountryStripeKey()
        onFetchCountryStripeKeyStart(draftBasicInfo?.__country__?.id)
      }
    } else {
      if (globalCountry) {
        onClearCountryStripeKey()
        onFetchCountryStripeKeyStart(globalCountry?.id)
      }
    }
  }, [globalCountry, draftBasicInfo])

  useEffect(() => {
    if (id) {
      onClearGeneralData()
      onClearDraftData()
      onFetchMerchantDraftBasicInfoPanelUserStart(id)
    } else {
      onClearDraftData()
    }
  }, [id])

  const refreshPageData = () => {
    onFetchMerchantDraftBasicInfoPanelUserStart(id)
  }

  useEffect(() => {
    if (id && !_.isEmpty(draftBasicInfo)) {
      setValue(draftBasicInfo?.registrationCompletedStep)
    }
  }, [id, draftBasicInfo])

  const location: any = useLocation()

  const [value, setValue] = React.useState(0)

  useEffect(() => {
    if (!_.isEmpty(location?.state)) {
      setValue(location?.state?.step)
    }
  }, [location])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box style={{ color: '#000000' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <AppComponentHeader title="Send Merchant Request" />
        <CustomizedBreadcrumbs adminRoute={adminRoute} />
      </Box>

      <AppCard>
        <>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Basics" {...a11yProps(0)} />
                <Tab
                  label="Specifics"
                  {...a11yProps(1)}
                  disabled={
                    (id &&
                      1 <= draftBasicInfo?.registrationCompletedStep - 1) ||
                    (id && 1 === draftBasicInfo?.registrationCompletedStep)
                      ? false
                      : true
                  }
                />
                <Tab
                  label="Images"
                  {...a11yProps(2)}
                  disabled={
                    (id &&
                      2 <= draftBasicInfo?.registrationCompletedStep - 1) ||
                    (id && 2 === draftBasicInfo?.registrationCompletedStep)
                      ? false
                      : true
                  }
                />
                <Tab
                  label="Discounts"
                  {...a11yProps(3)}
                  disabled={
                    (id &&
                      3 <= draftBasicInfo?.registrationCompletedStep - 1) ||
                    (id && 3 === draftBasicInfo?.registrationCompletedStep)
                      ? false
                      : true
                  }
                />
                <Tab
                  label="Packages & Bank Info"
                  {...a11yProps(4)}
                  disabled={
                    (id &&
                      4 <= draftBasicInfo?.registrationCompletedStep - 1) ||
                    (id && 4 === draftBasicInfo?.registrationCompletedStep)
                      ? false
                      : true
                  }
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <BasicInfo
                handleChangeTab={handleChange}
                adminRoute={adminRoute}
                refreshPageData={refreshPageData}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <WebsiteDetails
                handleChangeTab={handleChange}
                adminRoute={adminRoute}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Images handleChangeTab={handleChange} adminRoute={adminRoute} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <DiscountOffered
                handleChangeTab={handleChange}
                adminRoute={adminRoute}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              {isFetching ? (
                '...loading'
              ) : (
                <Elements stripe={stripePromise}>
                  <PackagesAndBankInfo
                    handleChangeTab={handleChange}
                    adminRoute={adminRoute}
                  />
                </Elements>
              )}
            </TabPanel>
          </Box>
          <Box style={{ height: 30 }} />
        </>
      </AppCard>
    </Box>
  )
}

export default container(SendMerchantRequest)
