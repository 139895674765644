import React, { ReactNode } from 'react'
import Button from '@mui/material/Button'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Divider,
  Grid,
} from '@mui/material'

import { Fonts } from 'shared/constants/AppEnums'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  // eslint-disable-next-line no-undef
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface AppConfirmDialogProps {
  dialogTitle?: string | ReactNode
  open?: boolean
  onDeny?: (isOpen: boolean) => void
  deleteFunction?: (payload: any) => void
  onCloseEditDialog?: () => void
  title?: string | ReactNode
  onConfirm?: () => void
  onChangeReason?: any
  redirectRoute?: any
  toDeleteId?: number
  isDeleting?: boolean
  hideReasonBox?: boolean
  placeHolderName?: string
}

const AppConfirmDialog: React.FC<AppConfirmDialogProps> = ({
  open,
  onDeny,
  title,
  dialogTitle,
}) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={() => onDeny(false)}
      fullWidth
    >
      <DialogTitle>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            mb: 3,
            fontWeight: Fonts.SEMI_BOLD,
          }}
          id="alert-dialog-title"
        >
          {dialogTitle}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent
        sx={{ color: 'text.secondary', fontSize: 14 }}
        id="alert-dialog-description"
      >
        <Grid container>
          <Grid item xs={12}>
            {title}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          pb: 5,
          px: 6,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            fontWeight: Fonts.MEDIUM,
          }}
          type="button"
          color="primary"
          autoFocus
          onClick={() => onDeny(false)}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppConfirmDialog
