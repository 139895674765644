import { AttachMoney, HeadsetMic, PlayArrow, Policy } from '@mui/icons-material'
import React, { ReactNode } from 'react'
import { FaUsers } from 'react-icons/fa'
import { FiUser } from 'react-icons/fi'
import { GrHome } from 'react-icons/gr'
import {
  MdOutlineHomeWork,
  MdOutlinePayment,
  MdOutlineStorefront,
} from 'react-icons/md'
import { MonetizationOn } from '@mui/icons-material'

import { RoutePermittedRole } from '../../shared/constants/AppConst'
import LogoutIcon from '@mui/icons-material/Logout'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import PaymentIcon from '@mui/icons-material/Payment'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const whiteLabelAdminRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            messageId: 'Dashboard',
            type: 'item',
            url: '/dashboard',
          },
        ],
      },
      {
        id: 'myAccount',
        title: 'myAccount',
        messageId: 'My Account',
        type: 'item',
        // icon: <PersonOutline />,
        icon: <FiUser />,
        url: '/my-account',
      },
      {
        id: 'members',
        title: 'Members',
        messageId: 'Members',
        type: 'collapse',
        icon: <FaUsers />,
        children: [
          {
            id: 'memberslist',
            title: 'Members List',
            messageId: 'Members List',
            type: 'item',
            url: '/whiteLabel/memberslist',
          },

          {
            id: 'whiteLabelMemberslist',
            title: 'WhiteLabel Members List',
            messageId: 'WhiteLabel Members List',
            type: 'item',
            url: '/whiteLabel/whitelabel-members-list',
          },
        ],
      },
      {
        id: 'Merchant',
        title: 'Merchants',
        messageId: 'Merchants',
        type: 'collapse',
        icon: <MdOutlineStorefront />,
        children: [
          {
            id: 'sendMerchantRequest',
            title: 'Send Merchant Request',
            messageId: 'Send Merchant Request',
            type: 'item',
            url: '/whiteLabel/sendMerchantRequest',
          },
          {
            id: 'draftList',
            title: 'Draft List',
            messageId: 'Draft List',
            type: 'item',
            url: '/whiteLabel/draftList',
          },
          {
            id: 'merchantList',
            title: 'Merchants List',
            messageId: 'Merchants List',
            type: 'item',
            url: '/whiteLabel/merchantList',
          },

          {
            id: 'referredMerchantList',
            title: 'Referred Merchant List',
            messageId: 'Referred Merchant List',
            type: 'item',
            url: '/whiteLabel/referredMerchantList',
          },
          {
            id: 'merchantActivityReports',
            title: 'Merchant Activity Reports',
            messageId: 'Merchant Activity Reports',
            type: 'item',
            url: '/whiteLabel/merchant-activity-reports',
          },
          // {
          //   id: 'sendMerchantEmail',
          //   title: 'Send Merchant Email',
          //   messageId: 'Send Merchant Email',
          //   type: 'item',
          //   url: '/whiteLabel/sendMerchantEmail',
          // },
        ],
      },

      {
        id: 'charitieslist',
        title: 'Charities',
        messageId: 'Charities',
        type: 'item',
        icon: <MdOutlineHomeWork />,
        url: '/whiteLabel/charitieslist',
      },
      {
        id: 'clubList',
        title: 'Clubs',
        messageId: 'Clubs',
        type: 'item',
        icon: <MdOutlineHomeWork />,
        url: '/whiteLabel/clublist',
      },

      {
        id: 'gmcreferredcp',
        title: 'Collaboration Partners',
        messageId: 'Collaboration Partners',
        type: 'item',
        icon: <MdOutlinePayment />,

        url: '/whiteLabel/referedcplist',
      },

      // {
      //   id: 'gmcreferredcp',
      //   title: 'Collaboration Partners',
      //   messageId: 'Collaboration Partners',
      //   type: 'collapse',
      //   icon: <GrDatabase />,
      //   children: [
      //     {
      //       id: 'gmcreferredcplist',
      //       title: 'Referred Collaboration Partners List',
      //       messageId: 'Referred CP',
      //       type: 'item',
      //       url: 'whiteLabel/referedcplist',
      //     },
      //   ],
      // },

      {
        id: 'PremiumCodes',
        title: 'Codes',
        messageId: 'Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'premiummerchantcodes',
            title: 'Merchant Codes',
            messageId: 'Merchant Codes',
            type: 'item',
            url: '/whiteLabel/premium-merchant-codes',
          },
          {
            id: 'premiummerchantcodes',
            title: 'Premium Member Codes',
            messageId: 'Premium Member Codes',
            type: 'item',
            url: '/whiteLabel/premium-member-codes',
          },
        ],
      },
      {
        id: 'QrCodes',
        title: 'QrCodes',
        messageId: 'QR Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'transactionQrCodes',
            title: 'Transaction QR Codes',
            messageId: 'Transaction QR Codes',
            type: 'item',
            url: '/whiteLabel/transaction-qr-codes',
          },
          {
            id: 'issuerQrCodes',
            title: 'Issuer QR Codes',
            messageId: 'Issuer QR Codes',
            type: 'item',
            url: '/whiteLabel/issuer-qr-codes',
          },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'friendshipearning',
        title: 'FriendshipEarning',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/whitelabel/friendship-earning/asissuer',
          },
          {
            id: 'asMerchantSigner',
            title: 'AsMerchantSigner',
            messageId: 'As Merchant Signer',
            type: 'item',
            url: '/whitelabel/friendship-earning/asmerchantsigner',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/whitelabel/friendship-earning/ascpsigner',
          },

          {
            id: 'fromIssuerBranch',
            title: 'fromIssuerBranch',
            messageId: 'From Issuer Branch of W/L',
            type: 'item',
            url: '/whitelabel/friendship-earning/fromIssuerBranchOfWL',
          },
          {
            id: 'fromMerchantSignerBranch',
            title: 'fromMerchantSignerBranch',
            messageId: 'From Merchant Signer Branch of W/L',
            type: 'item',
            url: '/whitelabel/friendship-earning/fromMerchantSignerBranchOfWL',
          },
          {
            id: 'fromCpSignerBranch',
            title: 'fromCpSignerBranch',
            messageId: 'From CP Signer Branch of W/L',
            type: 'item',
            url: '/whitelabel/friendship-earning/fromCpSignerBranchOfWL',
          },
        ],
      },

      {
        icon: <AttachMoney />,
        id: 'friendshipearningwithoutissuer',
        title: 'FriendshipEarningWithoutIssuer',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          {
            id: 'asMerchantSigner',
            title: 'AsMerchantSigner',
            messageId: 'As Merchant Signer',
            type: 'item',
            url: '/whitelabel/friendship-earning/asmerchantsigner',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/whitelabel/friendship-earning/ascpsigner',
          },

          {
            id: 'fromIssuerBranch',
            title: 'fromIssuerBranch',
            messageId: 'From Issuer Branch of W/L',
            type: 'item',
            url: '/whitelabel/friendship-earning/fromIssuerBranchOfWL',
          },
          {
            id: 'fromMerchantSignerBranch',
            title: 'fromMerchantSignerBranch',
            messageId: 'From Merchant Signer Branch of W/L',
            type: 'item',
            url: '/whitelabel/friendship-earning/fromMerchantSignerBranchOfWL',
          },
          {
            id: 'fromCpSignerBranch',
            title: 'fromCpSignerBranch',
            messageId: 'From CP Signer Branch of W/L',
            type: 'item',
            url: '/whitelabel/friendship-earning/fromCpSignerBranchOfWL',
          },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'memberearning',
        title: 'memberhipEarning',
        messageId: 'Membership Earning',
        type: 'collapse',
        children: [
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/whitelabel/membership-earning/asissuer',
          },
          {
            id: 'asCharityClub',
            title: 'AsCharityClub',
            messageId: 'As Club/Charity Signer',
            type: 'item',
            url: '/whitelabel/membership-earning/asclubcharity',
          },
          {
            id: 'cpSigner',
            title: 'CPSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/whitelabel/membership-earning/ascpsigner',
          },

          {
            id: 'fromIssuerBranch',
            title: 'fromIssuerBranch',
            messageId: 'From Issuer Branch of W/L',
            type: 'item',
            url: '/whitelabel/membership-earning/fromIssuerBranchOfWL',
          },
          // {
          //   id: 'fromCharityClubBranch',
          //   title: 'fromCharityClubBranch',
          //   messageId: 'From Club/Charity Signer Branch Of W/L',
          //   type: 'item',
          //   url: '/whitelabel/membership-earning/fromCharityClubBranchOfWL',
          // },
          {
            id: 'fromCpSignerBranch',
            title: 'fromCpSignerBranch',
            messageId: 'From Cp Signer Branch Of W/L',
            type: 'item',
            url: '/whitelabel/membership-earning/fromCpSignerBranchOfWL',
          },
          // {
          //   id: 'fromMemberPremiumCode',
          //   title: 'fromMemberPremiumCode',
          //   messageId: 'From Member Premium Code',
          //   type: 'item',
          //   url: '/whitelabel/membership-earning/fromMemberPremiumCode',
          // },
        ],
      },

      {
        icon: <AttachMoney />,
        id: 'memberearningwithoutissuer',
        title: 'memberhipEarningWithoutIssuer',
        messageId: 'Membership Earning',
        type: 'collapse',
        children: [
          {
            id: 'asCharityClub',
            title: 'AsCharityClub',
            messageId: 'As Club/Charity Signer',
            type: 'item',
            url: '/whitelabel/membership-earning/asclubcharity',
          },
          {
            id: 'cpSigner',
            title: 'CPSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/whitelabel/membership-earning/ascpsigner',
          },

          {
            id: 'fromIssuerBranch',
            title: 'fromIssuerBranch',
            messageId: 'From Issuer Branch of W/L',
            type: 'item',
            url: '/whitelabel/membership-earning/fromIssuerBranchOfWL',
          },
          // {
          //   id: 'fromCharityClubBranch',
          //   title: 'fromCharityClubBranch',
          //   messageId: 'From Club/Charity Signer Branch Of W/L',
          //   type: 'item',
          //   url: '/whitelabel/membership-earning/fromCharityClubBranchOfWL',
          // },
          {
            id: 'fromCpSignerBranch',
            title: 'fromCpSignerBranch',
            messageId: 'From Cp Signer Branch Of W/L',
            type: 'item',
            url: '/whitelabel/membership-earning/fromCpSignerBranchOfWL',
          },
          // {
          //   id: 'fromMemberPremiumCode',
          //   title: 'fromMemberPremiumCode',
          //   messageId: 'From Member Premium Code',
          //   type: 'item',
          //   url: '/whitelabel/membership-earning/fromMemberPremiumCode',
          // },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'merchantPackage',
        title: 'merchantPackage',

        messageId: 'Merchant Package Earning',
        type: 'item',

        url: '/whitelabel/merchant-package/earning',
      },
      {
        icon: <PlayArrow />,
        id: 'training',
        title: 'training',
        messageId: 'Trainings',
        type: 'item',
        url: '/common/trainings',
        // url: '/training-videos',
      },
      {
        icon: <HeadsetMic />,
        id: 'support',
        title: 'support',
        messageId: 'Contact Piiink',
        type: 'item',
        url: '/support',
      },

      //panda docs section starts
      {
        id: 'documentManagement',
        title: 'document Management',
        messageId: 'Document Management',
        type: 'collapse',
        icon: <ContentPasteIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/whitelabel/setting/smtp',
          // },
          {
            id: 'group',
            title: 'Group',
            messageId: 'Group',
            type: 'item',
            url: '/whitelabel/setting/group',
          },

          {
            id: 'contacts',
            title: 'Contacts',
            messageId: 'Contacts',
            type: 'item',
            url: '/whitelabel/setting/contacts',
          },

          {
            id: 'documents',
            title: 'Documents',
            messageId: 'Documents',
            type: 'item',
            url: '/whitelabel/setting/documents',
          },

          {
            id: 'emails',
            title: 'Emails',
            messageId: 'Emails',
            type: 'item',
            url: '/whitelabel/setting/emails',
          },
        ],
      },

      //panda docs section ends

      {
        id: 'payments',
        title: 'payments',
        messageId: 'Payments',
        type: 'collapse',
        icon: <PaymentIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/salesagent/setting/smtp',
          // },
          {
            id: 'connect stripe',
            title: 'connect stripe',
            messageId: 'Connect Stripe',
            type: 'item',
            url: '/whitelabel/payment/connect-stripe',
          },
        ],
      },

      {
        icon: <MonetizationOn />,
        id: 'prizedraw',
        title: 'prizedraw',
        messageId: 'Prize Draw',
        type: 'item',
        url: '/prizedraw',
      },

      {
        icon: <Policy />,
        id: 'terms',
        title: 'terms',
        messageId: 'Terms & Conditions',
        type: 'item',
        url: '/terms',
      },
      {
        icon: <ReceiptIcon />,
        id: 'coupons',
        title: 'coupons',
        messageId: 'Coupons',
        type: 'item',
        url: '/whitelabel/coupons',
      },

      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },

      // {
      //   icon: <AttachMoney />,
      //   id: 'friendshipearning',
      //   title: 'FriendshipEarning',
      //   messageId: 'Friendship Earning',
      //   type: 'collapse',
      //   children: [
      //     {
      //       id: 'asIssuer',
      //       title: 'AsIssuer',
      //       messageId: 'As an Issuer',
      //       type: 'item',
      //       url: '/whiteLabel/friendship-earning/asissuer',
      //     },
      //     {
      //       id: 'asMerchantSigner',
      //       title: 'AsMerchantSigner',
      //       messageId: 'As Merchant Signer',
      //       type: 'item',
      //       url: '/whiteLabel/friendship-earning/asmerchantsigner',
      //     },
      //     {
      //       id: 'asCpSigner',
      //       title: 'AsCpSigner',
      //       messageId: 'As CP Signer',
      //       type: 'item',
      //       url: '/whiteLabel/friendship-earning/ascpsigner',
      //     },
      //   ],
      // },
      // {
      //   icon: <AttachMoney />,
      //   id: 'friendshipearningAsIntroducer',
      //   title: 'FriendshipEarningAsIntroducer',
      //   messageId: 'Friendship Earning As Introducer',
      //   type: 'collapse',
      //   children: [
      //     {
      //       id: 'asIssuer',
      //       title: 'AsIssuer',
      //       messageId: 'As an Issuer',
      //       type: 'item',
      //       url: '/whiteLabel/friendship-earning/asissuer',
      //     },
      //     {
      //       id: 'asMerchantSigner',
      //       title: 'AsMerchantSigner',
      //       messageId: 'As Merchant Signer',
      //       type: 'item',
      //       url: '/whiteLabel/friendship-earning/asmerchantsigner',
      //     },
      //     {
      //       id: 'asCpSigner',
      //       title: 'AsCpSigner',
      //       messageId: 'As CP Signer',
      //       type: 'item',
      //       url: '/whiteLabel/friendship-earning/ascpsigner',
      //     },
      //   ],
      // },
      // {
      //   icon: <AttachMoney />,
      //   id: 'memberearning',
      //   title: 'memberhipEarning',
      //   messageId: 'Membership Earning',
      //   type: 'collapse',
      //   children: [
      //     {
      //       id: 'asIssuer',
      //       title: 'AsIssuer',
      //       messageId: 'As an Issuer',
      //       type: 'item',
      //       url: '/whiteLabel/membership-earning/asissuer',
      //     },
      //     {
      //       id: 'asCpSigner',
      //       title: 'AsCpSigner',
      //       messageId: 'As CP Signer',
      //       type: 'item',
      //       url: '/whiteLabel/membership-earning/ascpsigner',
      //     },

      //     {
      //       id: 'asCharityClub',
      //       title: 'AsCharityClub',
      //       messageId: 'As Club/Charity Signer',
      //       type: 'item',
      //       url: '/whiteLabel/membership-earning/asclubcharity',
      //     },
      //   ],
      // },
      // {
      //   icon: <AttachMoney />,
      //   id: 'memberearningAsIntroducer',
      //   title: 'memberhipEarningAsIntroducer',
      //   messageId: 'Membership Earning As Introducer',
      //   type: 'collapse',
      //   children: [
      //     {
      //       id: 'asIssuer',
      //       title: 'AsIssuer',
      //       messageId: 'As an Issuer',
      //       type: 'item',
      //       url: '/whiteLabel/membership-earning/asissuer',
      //     },
      //     {
      //       id: 'asCpSigner',
      //       title: 'AsCpSigner',
      //       messageId: 'As CP Signer',
      //       type: 'item',
      //       url: '/whiteLabel/membership-earning/ascpsigner',
      //     },
      //   ],
      // },
    ],
  },
]
export default whiteLabelAdminRoutesConfig
