import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import { fetchPostStart, fetchPostSuccess, fetchPostFailure } from './postSlice'
import { fetchPosts } from '../../api/posts'

import _ from 'lodash'

export function* fetchPostAsync() {
  try {
    const { data } = yield call(fetchPosts())

    yield put(fetchPostSuccess(data))
  } catch (err) {
    yield put(fetchPostFailure(err))
    console.error(err)
  }
}

export function* watchFetchPost() {
  yield takeLatest('post/fetchPostStart', fetchPostAsync)
}

export function* postSagas() {
  yield all([call(watchFetchPost)])
}
