import React, { useState } from 'react'
import {
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  Typography,
  Card,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
} from '@mui/material'
import {
  createAssignerMerchantPremiumCodeStart,
  createAssignerMemberPremiumCodeStart,
} from 'store/qrCodes/qrCodesSlice'
import { generateTemporaryPasswordStart } from 'store/globalAdminSettings/globalAdminSettingsSlice'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import AppDialog from 'components/ReusableModalComponents/CustomAlertModal'

const UserCard = ({
  user,
  selectedRole,
  closeModal,
  codeType,
  refreshPageData,
}: {
  user: any
  selectedRole: string
  codeType: string

  closeModal: () => void
  refreshPageData?: any
}) => {
  const [numOfCodes, setNumOfCodes] = useState(0)
  const [openGeneratePasswordDialog, setOpenGeneratePasswordDialog] =
    useState(false)

  const dispatch = useDispatch()
  const { id: batchId } = useParams()

  const closeGeneratePasswordDialog = () => {
    setOpenGeneratePasswordDialog(false)
  }

  const onOpenGeneratePasswordDialog = () => {
    setOpenGeneratePasswordDialog(true)
  }

  return (
    <Card sx={{ mt: 5 }}>
      <List sx={{ width: '100%' }}>
        <ListItem>
          {/* <ListItemAvatar>
            <Avatar />
          </ListItemAvatar> */}
          <ListItemText
            primary={
              user?.salesAgentName ||
              user?.clubName ||
              user?.charityName ||
              user?.primaryContactName ||
              user?.username ||
              user?.firstName + user?.lastName
            }
            secondary={
              <>
                <Typography gutterBottom>{user?.email}</Typography>
                <Typography>{user?.phoneNumber}</Typography>
              </>
            }
          ></ListItemText>
          {/* <ListItemButton> */}
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={5}
            sx={{ width: 1 }}
          >
            {/* <TextField
              label="Number of codes"
              size="small"
              value={numOfCodes}
              type="number"
              onChange={(val) => setNumOfCodes(parseInt(val.target.value, 10))}
              sx={{ maxWidth: 200 }}
            /> */}
            <Button
              variant="contained"
              sx={{
                color: '#ffffff',
                display: 'flex',
                marginTop: '-1rem',
                marginLeft: 'auto',
                background: '#00bfcd',
                '&:hover': {
                  background: '#ec4785',
                },
                px: 5,
                mb: 5,
              }}
              onClick={() => {
                const payload = {
                  params: {
                    userType: selectedRole,
                    userId: user?.id,
                    email: user?.email ?? null,
                    username: user?.username ?? null,
                    // countryId: user?.countryId,
                  },
                  // closeModal,
                  batchId,
                  refreshPageData,
                  onOpenGeneratePasswordDialog,
                }

                // codeType === 'member'
                //   ? dispatch(createAssignerMemberPremiumCodeStart(payload))
                //   : dispatch(createAssignerMerchantPremiumCodeStart(payload))
                dispatch(generateTemporaryPasswordStart(payload))
                setOpenGeneratePasswordDialog(true)
              }}
            >
              Generate Temporary Password
            </Button>
          </Stack>
          {/* </ListItemButton> */}
        </ListItem>
      </List>
      {openGeneratePasswordDialog && (
        <AppDialog
          title="Temporary password has been generated successfully! Please login within 5 minutes and the logged in session will be valid only for 30 minutes"
          open={openGeneratePasswordDialog}
          dialogTitle=""
          onDeny={() => {
            setOpenGeneratePasswordDialog(false)
            closeModal()
          }}
        />
      )}
    </Card>
  )
}

export default UserCard
