import { createSlice } from '@reduxjs/toolkit'

import { IMerchantCategorySetupState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IMerchantCategorySetupState = {
  merchantCategoryList: listObj,
  error: null,
  loading: false,
  hasMoreData: false,
}

export const merchantCategorySetupSlice = createSlice({
  name: 'merchantCategorySetup',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMerchantCategoryStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantCategorySuccess: (state: any, action) => {
      state.merchantCategoryList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchMerchantCategoryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMerchantCategoryByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantCategoryByIdSuccess: (state: any, action) => {
      state.merchantCategoryList = action.payload.data
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchMerchantCategoryByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createMerchantCategoryStart: (state: any, action) => {
      state.loading = true
    },
    createMerchantCategorySuccess: (state: any, action) => {
      state.loading = false
      state.merchantCategoryList.data = [
        ...state.merchantCategoryList.data,
        action.payload,
      ]
    },
    createMerchantCategoryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteMerchantCategoryStart: (state: any, action) => {
      state.loading = true
    },
    deleteMerchantCategorySuccess: (state: any, action) => {
      state.loading = false
      state.merchantCategoryList.data = state.merchantCategoryList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteMerchantCategoryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editMerchantCategoryStart: (state: any, action) => {
      state.loading = true
    },
    editMerchantCategorySuccess: (state: any, action) => {
      const result = state.merchantCategoryList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.merchantCategoryList.data = result

      state.loading = false
    },
    editMerchantCategoryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchMerchantCategoryStart,
  fetchMerchantCategorySuccess,
  fetchMerchantCategoryFailure,
  createMerchantCategoryStart,
  createMerchantCategorySuccess,
  createMerchantCategoryFailure,
  deleteMerchantCategoryStart,
  deleteMerchantCategorySuccess,
  deleteMerchantCategoryFailure,
  fetchMerchantCategoryByIdStart,
  fetchMerchantCategoryByIdSuccess,
  fetchMerchantCategoryByIdFailure,
  editMerchantCategoryStart,
  editMerchantCategorySuccess,
  editMerchantCategoryFailure,
} = merchantCategorySetupSlice.actions

export default merchantCategorySetupSlice.reducer
