import { createSlice } from '@reduxjs/toolkit'

import { IEarningState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IEarningState = {
  // friendship
  friendshipEarning: listObj, // as merchant
  friendshipEarningList: listObj, // as merchant

  friendshipEarningAsIssuer: listObj,
  friendshipEarningAsIssuerList: listObj,
  supposedFriendshipEarningAsIssuerList: listObj,
  supposedMembershipEarningAsIssuerList: listObj,

  friendshipEarningAsMerchantSigner: listObj,
  friendshipEarningAsMerchantSignerList: listObj,

  friendshipEarningAsCpSigner: listObj,
  friendshipEarningAsCpSignerList: listObj,

  friendshipEarningAsStateOwner: listObj,
  friendshipEarningAsStateOwnerList: listObj,

  friendshipEarningAsRegionOwner: listObj,
  friendshipEarningAsRegionOwnerList: listObj,

  friendshipEarningAsAreaOwner: listObj,
  friendshipEarningAsAreaOwnerList: listObj,

  friendshipEarningAsCharity: listObj,
  friendshipEarningAsCharityList: listObj,

  friendshipEarningAsPiiinkIssuer: listObj,
  friendshipEarningAsPiiinkEntities: listObj,
  membershipEarningAsPiiinkEntities: listObj,

  friendshipEarningAsPiiinkEntitiesDetail: listObj,
  membershipEarningAsPiiinkEntitiesDetail: listObj,

  friendshipEarningAsPiiinkIssuerList: listObj,

  friendshipEarningAsPiiinkRegionOwner: listObj,
  friendshipEarningAsPiiinkRegionOwnerList: listObj,

  friendshipEarningAsPiiinkAreaOwner: listObj,
  friendshipEarningAsPiiinkAreaOwnerList: listObj,

  friendshipEarningAsPiiinkCharity: listObj,
  friendshipEarningAsPiiinkCharityList: listObj,

  friendshipEarningFromCountryPool: listObj,
  friendshipEarningFromCountryPoolDetail: listObj,

  friendshipEarningAsCpSignerAsSigner: listObj,
  friendshipEarningAsCpSignerAsSignerList: listObj,

  // country specific
  countrySpecificFriendshipEarningAsIssuer: listObj,
  countrySpecificFriendshipEarningAsIssuerList: listObj,

  countrySpecificFriendshipEarningTotal: listObj,
  countrySpecificFriendshipEarningTotalList: listObj,

  countrySpecificFriendshipEarningAsMerchantSignerList: listObj,
  countrySpecificFriendshipEarningAsMerchantSigner: listObj,

  countrySpecificFriendshipEarningAsCpSigner: listObj,
  countrySpecificFriendshipEarningAsCpSignerList: listObj,

  countrySpecificFriendshipEarningAsCpSignerAsSigner: listObj,
  countrySpecificFriendshipEarningAsCpSignerAsSignerList: listObj,

  // white label specific
  whitelabelSpecificFriendshipEarningTotal: listObj,
  whitelabelSpecificFriendshipEarningTotalList: listObj,

  whitelabelSpecificFriendshipEarningAsIssuer: listObj,
  whitelabelSpecificFriendshipEarningAsIssuerList: listObj,

  whitelabelSpecificFriendshipEarningAsMerchantSigner: listObj,
  whitelabelSpecificFriendshipEarningAsMerchantSignerList: listObj,

  whitelabelSpecificFriendshipEarningAsCpSignerAsIssuer: listObj,
  whitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList: listObj,

  whitelabelSpecificFriendshipEarningAsCpSignerAsSigner: listObj,
  whitelabelSpecificFriendshipEarningAsCpSignerAsSignerList: listObj,

  // membership
  membershipEarning: listObj,
  membershipEarningList: listObj,

  membershipEarningAsIssuer: listObj,
  membershipEarningAsIssuerList: listObj,

  countrySpecificMembershipEarningAsIssuer: listObj,
  countrySpecificMembershipEarningAsIssuerList: listObj,

  membershipEarningAsClubCharitySigner: listObj,
  membershipEarningAsClubCharitySignerList: listObj,

  membershipEarningFromStateAllocation: listObj,
  membershipEarningFromStateAllocationList: listObj,

  membershipEarningFromRegionAllocation: listObj,
  membershipEarningFromRegionAllocationList: listObj,

  membershipEarningFromAreaAllocation: listObj,
  membershipEarningFromAreaAllocationList: listObj,

  membershipEarningFromCharityAllocation: listObj,
  membershipEarningFromCharityAllocationList: listObj,

  membershipEarningFromCpRefer: listObj,
  membershipEarningFromCpReferList: listObj,

  membershipEarningAsCpSigner: listObj,
  membershipEarningAsCpSignerList: listObj,

  membershipEarningAsPiiinkIssuer: listObj,
  membershipEarningAsPiiinkIssuerList: listObj,

  membershipEarningAsPiiinkRegionOwner: listObj,
  membershipEarningAsPiiinkRegionOwnerList: listObj,

  membershipEarningAsPiiinkAreaOwner: listObj,
  membershipEarningAsPiiinkAreaOwnerList: listObj,

  membershipEarningAsPiiinkCharity: listObj,
  membershipEarningAsPiiinkCharityList: listObj,

  membershipEarningFromCountryPool: listObj,
  membershipEarningFromCountryPoolDetail: listObj,

  // country specific
  countrySpecificMembershipEarningAsClubCharitySigner: listObj,
  countrySpecificMembershipEarningAsClubCharitySignerList: listObj,

  countrySpecificMembershipEarningTotal: listObj,
  countrySpecificMembershipEarningTotalList: listObj,

  countrySpecificMembershipEarningAsCpSigner: listObj,
  countrySpecificMembershipEarningAsCpSignerList: listObj,

  // white label specific
  whitelabelSpecificMembershipEarningTotal: listObj,
  whitelabelSpecificMembershipEarningTotalList: listObj,

  whitelabelSpecificMembershipEarningAsIssuer: listObj,
  whitelabelSpecificMembershipEarningAsIssuerList: listObj,

  whitelabelSpecificMembershipEarningAsClubCharitySigner: listObj,
  whitelabelSpecificMembershipEarningAsClubCharitySignerList: listObj,

  whitelabelSpecificMembershipEarningAsCpSigner: listObj,
  whitelabelSpecificMembershipEarningAsCpSignerList: listObj,

  friendshipEarningFromCountryOrGlobalPool: listObj,
  friendshipEarningFromCountryPoolOrGlobalDetail: listObj,

  membershipEarningFromCountryOrGlobalPool: listObj,
  membershipEarningFromCountryPoolOrGlobalDetail: listObj,

  friendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer: listObj,
  friendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSigner:
    listObj,
  membershipEarningFromCountryOrGlobalPoolDetail: listObj,

  friendshipEarningFromCountryOrGlobalPoolAsCountryOwnerSummaryAsIssuer:
    listObj,
  friendshipEarningFromCountryOrGlobalPoolAsCountryOwnerSummaryAsMerchant:
    listObj,
  membershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer: listObj,

  memberPremiumCodeTransaction: listObj,
  memberPremiumCodeTransactionDetail: listObj,

  friendshipTransactionByTerminalList: listObj,
  countryRevenueFromFriendshipSummary: listObj,
  countryRevenueFromFriendshipDetail: listObj,
  countryRevenueFromMembershipSummary: listObj,
  countryRevenueFromMembershipDetail: listObj,

  friendshipEarningAsCharityGlobalAdminWithCountrySummary: listObj,
  friendshipEarningAsCharityGlobalAdminWithCountryDetails: listObj,

  friendshipEarningAsAreaOwnerGlobalAdminWithCountrySummary: listObj,
  friendshipEarningAsAreaOwnerGlobalAdminWithCountryDetails: listObj,

  friendshipEarningAsRegionOwnerGlobalAdminWithCountrySummary: listObj,
  friendshipEarningAsRegionOwnerGlobalAdminWithCountryDetails: listObj,

  friendshipEarningAsStateOwnerGlobalAdminWithCountrySummary: listObj,
  friendshipEarningAsStateOwnerGlobalAdminWithCountryDetails: listObj,

  friendshipEarningAsCountryOwnerGlobalAdminWithCountrySummary: listObj,
  friendshipEarningAsCountryOwnerGlobalAdminWithCountryDetails: listObj,

  membershipEarningAsCharityGlobalAdminWithCountrySummary: listObj,
  membershipEarningAsCharityGlobalAdminWithCountryDetails: listObj,

  membershipEarningAsAreaOwnerGlobalAdminWithCountrySummary: listObj,
  membershipEarningAsAreaOwnerGlobalAdminWithCountryDetails: listObj,

  membershipEarningAsRegionOwnerGlobalAdminWithCountrySummary: listObj,
  membershipEarningAsRegionOwnerGlobalAdminWithCountryDetails: listObj,

  membershipEarningAsStateOwnerGlobalAdminWithCountrySummary: listObj,
  membershipEarningAsStateOwnerGlobalAdminWithCountryDetails: listObj,

  membershipEarningAsCountryOwnerGlobalAdminWithCountrySummary: listObj,
  membershipEarningAsCountryOwnerGlobalAdminWithCountryDetails: listObj,

  error: null,
  loading: false,
  hasMoreData: false,
  isCanceling: false,
}

export const earningSlice = createSlice({
  name: 'earning',
  initialState: INITIAL_STATE,
  reducers: {
    // friendship slices==================================
    // as merchant
    fetchFriendshipEarningStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningSuccess: (state: any, action) => {
      state.friendshipEarning = action.payload
      state.loading = false
    },
    fetchFriendshipEarningFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningListSuccess: (state: any, action) => {
      state.friendshipEarningList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipTransactionByTerminalUserStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipTransactionByTerminalUserSuccess: (state: any, action) => {
      state.friendshipTransactionByTerminalList = action.payload
      state.loading = false
    },
    fetchFriendshipTransactionByTerminalUserFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // as  issuer
    fetchFriendshipEarningAsIssuerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerSuccess: (state: any, action) => {
      state.friendshipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsIssuerTypeBFStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerTypeBFSuccess: (state: any, action) => {
      state.friendshipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerTypeBFFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsIssuerTypeNormalStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerTypeNormalSuccess: (state: any, action) => {
      state.friendshipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerTypeNormalFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsIssuerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerListSuccess: (state: any, action) => {
      state.friendshipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsIssuerListTypeNormalStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerListTypeNormalSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerListTypeNormalFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsIssuerListTypeBFStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerListTypeBFSuccess: (state: any, action) => {
      state.friendshipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerListTypeBFFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchSupposedFriendshipEarningAsIssuerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchSupposedFriendshipEarningAsIssuerListSuccess: (state: any, action) => {
      state.supposedFriendshipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchSupposedFriendshipEarningAsIssuerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchSupposedMembershipEarningAsIssuerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchSupposedMembershipEarningAsIssuerListSuccess: (state: any, action) => {
      state.supposedMembershipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchSupposedMembershipEarningAsIssuerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    // for white label ================================================================

    // as  Merchant signer
    fetchFriendshipEarningAsMerchantSignerForWhitelabelStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsMerchantSignerForWhitelabelSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsMerchantSigner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsMerchantSignerForWhitelabelFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsMerchantSignerListForWhitelabelStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsMerchantSignerListForWhitelabelSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsMerchantSignerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsMerchantSignerListForWhitelabelFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  issuer
    fetchFriendshipEarningAsIssuerForWhitelabelStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerForWhitelabelSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerForWhitelabelFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsIssuerListForWhitelabelStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerListForWhitelabelSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerListForWhitelabelFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // whitelabelSpecificFriendshipEarningTotal
    fetchWhitelabelSpecificFriendshipEarningTotalStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningTotalSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningTotal = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningTotalFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // whitelabelSpecificFriendshipEarningTotalList
    fetchWhitelabelSpecificFriendshipEarningTotalListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningTotalListSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningTotalList = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningTotalListFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificFriendshipEarningAsIssuer
    fetchWhitelabelSpecificFriendshipEarningAsIssuerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningAsIssuerSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningAsIssuerFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificFriendshipEarningAsIssuerList
    fetchWhitelabelSpecificFriendshipEarningAsIssuerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningAsIssuerListSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningAsIssuerListFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificFriendshipEarningAsMerchantSigner
    fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningAsMerchantSigner = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificFriendshipEarningAsMerchantSignerList
    fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerListSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningAsMerchantSignerList =
        action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerListFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificFriendshipEarningAsCpSignerAsIssuer
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningAsCpSignerAsIssuer =
        action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerListSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList =
        action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerListFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificFriendshipEarningAsCpSignerAsSigner
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningAsCpSignerAsSigner =
        action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificFriendshipEarningAsCpSignerAsSignerList
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerListSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificFriendshipEarningAsCpSignerAsSignerList =
        action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerListFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    //country specific
    fetchCountrySpecificFriendshipEarningAsIssuerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningAsIssuerSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningAsIssuerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountrySpecificFriendshipEarningAsIssuerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningAsIssuerListSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningAsIssuerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificFriendshipEarningAsMerchantSignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningAsMerchantSignerSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningAsMerchantSigner = action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningAsMerchantSignerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificFriendshipEarningAsMerchantSignerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningAsMerchantSignerListSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningAsMerchantSignerList =
        action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningAsMerchantSignerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificFriendshipEarningAsCpSignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningAsCpSigner = action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningAsCpSignerAsSigner = action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningAsCpSignerAsSignerList =
        action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelStart:
      (state: any, action) => {
        state.loading = true
      },

    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelSuccess:
      (state: any, action) => {
        state.countrySpecificFriendshipEarningAsCpSignerAsSignerList =
          action.payload
        state.loading = false
      },

    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },

    fetchCountrySpecificFriendshipEarningAsCpSignerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerListSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificMembershipEarningAsCpSignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificMembershipEarningAsCpSignerSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificMembershipEarningAsCpSigner = action.payload
      state.loading = false
    },
    fetchCountrySpecificMembershipEarningAsCpSignerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountrySpecificMembershipEarningAsCpSignerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificMembershipEarningAsCpSignerListSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificMembershipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchCountrySpecificMembershipEarningAsCpSignerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  Merchant signer
    fetchFriendshipEarningAsMerchantSignerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsMerchantSignerSuccess: (state: any, action) => {
      state.friendshipEarningAsMerchantSigner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsMerchantSignerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsMerchantSignerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsMerchantSignerListSuccess: (state: any, action) => {
      state.friendshipEarningAsMerchantSignerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsMerchantSignerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsListSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsMerchantSignerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  cp signer
    fetchFriendshipEarningAsCpSignerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerSuccess: (state: any, action) => {
      state.friendshipEarningAsCpSigner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsCpSignerAsSignerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerAsSignerSuccess: (state: any, action) => {
      state.friendshipEarningAsCpSignerAsSigner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerAsSignerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsCpSignerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerListSuccess: (state: any, action) => {
      state.friendshipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsCpSignerAsSignerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerAsSignerListSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCpSignerAsSignerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerAsSignerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  state owner
    fetchFriendshipEarningAsStateOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsStateOwnerSuccess: (state: any, action) => {
      state.friendshipEarningAsStateOwner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsStateOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsStateOwnerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsStateOwnerListSuccess: (state: any, action) => {
      state.friendshipEarningAsStateOwnerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsStateOwnerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // as region owner
    fetchFriendshipEarningAsRegionOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsRegionOwnerSuccess: (state: any, action) => {
      state.friendshipEarningAsRegionOwner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsRegionOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsRegionOwnerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsRegionOwnerListSuccess: (state: any, action) => {
      state.friendshipEarningAsRegionOwnerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsRegionOwnerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // as  area owner
    fetchFriendshipEarningAsAreaOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsAreaOwnerSuccess: (state: any, action) => {
      state.friendshipEarningAsAreaOwner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsAreaOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsAreaOwnerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsAreaOwnerListSuccess: (state: any, action) => {
      state.friendshipEarningAsAreaOwnerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsAreaOwnerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // as  charity
    fetchFriendshipEarningAsCharityStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCharitySuccess: (state: any, action) => {
      state.friendshipEarningAsCharity = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCharityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsCharityListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCharityListSuccess: (state: any, action) => {
      state.friendshipEarningAsCharityList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCharityListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // PiiinkIssuer
    fetchFriendshipEarningAsPiiinkIssuerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkIssuerSuccess: (state: any, action) => {
      state.friendshipEarningAsPiiinkIssuer = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkIssuerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsPiiinkEntitiesStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkEntitiesSuccess: (state: any, action) => {
      state.friendshipEarningAsPiiinkEntities = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkEntitiesFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsPiiinkEntitiesDetailStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkEntitiesDetailSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsPiiinkEntitiesDetail = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkEntitiesDetailFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsPiiinkEntitiesStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkEntitiesSuccess: (state: any, action) => {
      state.membershipEarningAsPiiinkEntities = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkEntitiesFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsPiiinkEntitiesDetailStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkEntitiesDetailSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsPiiinkEntitiesDetail = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkEntitiesDetailFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsPiiinkIssuerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkIssuerListSuccess: (state: any, action) => {
      state.friendshipEarningAsPiiinkIssuerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkIssuerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // PiiinkRegionOwner
    fetchFriendshipEarningAsPiiinkRegionOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkRegionOwnerSuccess: (state: any, action) => {
      state.friendshipEarningAsPiiinkRegionOwner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkRegionOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsPiiinkRegionOwnerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkRegionOwnerListSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsPiiinkRegionOwnerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkRegionOwnerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  country
    fetchFriendshipEarningFromCountryPoolAsCountryOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },

    fetchFriendshipEarningFromCountryPoolAsCountryOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningFromCountryPool = action.payload
      state.loading = false
    },
    fetchFriendshipEarningFromCountryPoolAsCountryOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },

    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerSuccess:
      (state: any, action) => {
        state.membershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer =
          action.payload
        state.loading = false
      },
    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },

    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },

    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningFromCountryOrGlobalPoolAsCountryOwnerSummaryAsIssuer =
        action.payload
      state.loading = false
    },
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantStart:
      (state: any, action) => {
        state.loading = true
      },

    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSuccess:
      (state: any, action) => {
        state.friendshipEarningFromCountryOrGlobalPoolAsCountryOwnerSummaryAsMerchant =
          action.payload
        state.loading = false
      },
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },

    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },

    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningFromCountryOrGlobalPool = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningFromCountryPoolDetail = action.payload
      state.loading = false
    },
    fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetailStart:
      (state: any, action) => {
        state.loading = true
      },
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetailSuccess:
      (state: any, action) => {
        state.friendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer =
          action.payload
        state.loading = false
      },
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetailFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },

    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetailStart:
      (state: any, action) => {
        state.loading = true
      },
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetailSuccess:
      (state: any, action) => {
        state.friendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSigner =
          action.payload
        state.loading = false
      },
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetailFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },

    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetailStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetailSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningFromCountryOrGlobalPoolDetail = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetailFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // PiiinkAreaOwner
    fetchFriendshipEarningAsPiiinkAreaOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkAreaOwnerSuccess: (state: any, action) => {
      state.friendshipEarningAsPiiinkAreaOwner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkAreaOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsPiiinkAreaOwnerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkAreaOwnerListSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsPiiinkAreaOwnerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkAreaOwnerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    //PiiinkCharity
    fetchFriendshipEarningAsPiiinkCharityStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkCharitySuccess: (state: any, action) => {
      state.friendshipEarningAsPiiinkCharity = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkCharityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsPiiinkCharityListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkCharityListSuccess: (state: any, action) => {
      state.friendshipEarningAsPiiinkCharityList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkCharityListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // membership slices============================================
    fetchMembershipEarningStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningSuccess: (state: any, action) => {
      state.membershipEarning = action.payload
      state.loading = false
    },
    fetchMembershipEarningFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningListSuccess: (state: any, action) => {
      state.membershipEarningList = action.payload
      state.loading = false
    },
    fetchMembershipEarningListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // as  issuer
    fetchMembershipEarningAsIssuerStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsIssuerSuccess: (state: any, action) => {
      state.membershipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsIssuerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsIssuerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsIssuerListSuccess: (state: any, action) => {
      state.membershipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsIssuerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificMembershipEarningAsIssuerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificMembershipEarningAsIssuerSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificMembershipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchCountrySpecificMembershipEarningAsIssuerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountrySpecificMembershipEarningAsIssuerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificMembershipEarningAsIssuerListSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificMembershipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchCountrySpecificMembershipEarningAsIssuerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  club charity signer
    fetchMembershipEarningAsClubCharityStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsClubCharitySuccess: (state: any, action) => {
      state.membershipEarningAsClubCharitySigner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsClubCharityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsClubCharityListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsClubCharityListSuccess: (state: any, action) => {
      state.membershipEarningAsClubCharitySignerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsClubCharityListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificMembershipEarningAsClubCharityStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },

    fetchCountrySpecificMembershipEarningAsClubCharitySuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificMembershipEarningAsClubCharitySigner = action.payload
      state.loading = false
    },

    fetchCountrySpecificMembershipEarningAsClubCharityFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountrySpecificMembershipEarningAsClubCharityListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificMembershipEarningAsClubCharityListSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificMembershipEarningAsClubCharitySignerList =
        action.payload
      state.loading = false
    },
    fetchCountrySpecificMembershipEarningAsClubCharityListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // from state allocation
    fetchMembershipEarningFromStateAllocationStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningFromStateAllocationSuccess: (state: any, action) => {
      state.membershipEarningFromStateAllocation = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromStateAllocationFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningFromStateAllocationListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningFromStateAllocationListSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningFromStateAllocationList = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromStateAllocationListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // from region allocation
    fetchMembershipEarningFromRegionAllocationStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningFromRegionAllocationSuccess: (state: any, action) => {
      state.membershipEarningFromRegionAllocation = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromRegionAllocationFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningFromRegionAllocationListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningFromRegionAllocationListSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningFromRegionAllocationList = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromRegionAllocationListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // from area allocation
    fetchMembershipEarningFromAreaAllocationStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningFromAreaAllocationSuccess: (state: any, action) => {
      state.membershipEarningFromAreaAllocation = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromAreaAllocationFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningFromAreaAllocationListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningFromAreaAllocationListSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningFromAreaAllocationList = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromAreaAllocationListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // from charity allocation
    fetchMembershipEarningFromCharityAllocationStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningFromCharityAllocationSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningFromCharityAllocation = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromCharityAllocationFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningFromCharityAllocationListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningFromCharityAllocationListSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningFromCharityAllocationList = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromCharityAllocationListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    //  from cp refer
    fetchMembershipEarningFromCpReferStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningFromCpReferSuccess: (state: any, action) => {
      state.membershipEarningFromCpRefer = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromCpReferFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningFromCpReferListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningFromCpReferListSuccess: (state: any, action) => {
      state.membershipEarningFromCpReferList = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromCpReferListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    // PiiinkIssuer
    fetchMembershipEarningAsPiiinkIssuerStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkIssuerSuccess: (state: any, action) => {
      state.membershipEarningAsPiiinkIssuer = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkIssuerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsPiiinkIssuerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkIssuerListSuccess: (state: any, action) => {
      state.membershipEarningAsPiiinkIssuerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkIssuerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // PiiinkRegionOwner
    fetchMembershipEarningAsPiiinkRegionOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkRegionOwnerSuccess: (state: any, action) => {
      state.membershipEarningAsPiiinkRegionOwner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkRegionOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsPiiinkRegionOwnerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    // as  country
    fetchMembershipEarningFromCountryPoolAsCountryOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkRegionOwnerListSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsPiiinkRegionOwnerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkRegionOwnerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningFromCountryPoolAsCountryOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningFromCountryPool = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromCountryPoolAsCountryOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // PiiinkAreaOwner
    fetchMembershipEarningAsPiiinkAreaOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkAreaOwnerSuccess: (state: any, action) => {
      state.membershipEarningAsPiiinkAreaOwner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkAreaOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsPiiinkAreaOwnerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkAreaOwnerListSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsPiiinkAreaOwnerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkAreaOwnerListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningFromCountryPoolAsCountryOwnerDetailStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningFromCountryPoolAsCountryOwnerDetailSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningFromCountryPoolDetail = action.payload
      state.loading = false
    },
    fetchMembershipEarningFromCountryPoolAsCountryOwnerDetailFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    //PiiinkCharity
    fetchMembershipEarningAsPiiinkCharityStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkCharitySuccess: (state: any, action) => {
      state.membershipEarningAsPiiinkCharity = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkCharityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsPiiinkCharityListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkCharityListSuccess: (state: any, action) => {
      state.membershipEarningAsPiiinkCharityList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkCharityListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // friendship for global admin ========================================================

    // as  issuer
    fetchFriendshipEarningAsIssuerForGlobalAdminStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsIssuerListForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerListForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerListForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  Merchant signer
    fetchFriendshipEarningAsMerchantSignerForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsMerchantSignerForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsMerchantSigner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsMerchantSignerForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsMerchantSignerListForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsMerchantSignerListForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsMerchantSignerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsMerchantSignerListForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    // as  cp signer
    fetchFriendshipEarningAsCpSignerForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCpSigner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsCpSignerListForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerListForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerListForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    // as  cp signer as signer
    fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCpSignerAsSigner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCpSignerAsSignerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // friendship for global admin with country========================================================
    // PiiinkRegionOwner
    fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsPiiinkRegionOwner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryStart:
      (state: any, action) => {
        state.loading = true
      },
    fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountrySuccess:
      (state: any, action) => {
        state.friendshipEarningAsPiiinkRegionOwnerList = action.payload
        state.loading = false
      },
    fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },
    //PiiinkCharity
    fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsPiiinkCharity = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },

    fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsPiiinkCharityList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // PiiinkAreaOwner
    fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsPiiinkAreaOwner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountrySuccess:
      (state: any, action) => {
        state.friendshipEarningAsPiiinkAreaOwnerList = action.payload
        state.loading = false
      },
    fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },

    // as  issuer
    fetchFriendshipEarningAsIssuerForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  Merchant signer
    fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsMerchantSigner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountrySuccess:
      (state: any, action) => {
        state.friendshipEarningAsMerchantSignerList = action.payload
        state.loading = false
      },
    fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountryFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },
    // as  cp signer
    fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCpSigner = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // Total transaction for country admin
    fetchCountrySpecificFriendshipEarningTotalStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningTotalSuccess: (state: any, action) => {
      state.countrySpecificFriendshipEarningTotal = action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningTotalFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificFriendshipEarningTotalListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificFriendshipEarningTotalListSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificFriendshipEarningTotalList = action.payload
      state.loading = false
    },
    fetchCountrySpecificFriendshipEarningTotalListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // membership for global admin ========================================================
    // as  issuer
    fetchMembershipEarningAsIssuerForGlobalAdminStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsIssuerForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsIssuerForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsIssuerListForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsIssuerListForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsIssuerListForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  Merchant signer
    fetchMembershipEarningAsClubCharitySignerForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsClubCharitySignerForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsClubCharitySigner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsClubCharitySignerForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsClubCharitySignerListForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsClubCharitySignerListForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsClubCharitySignerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsClubCharitySignerListForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // as  cp signer
    fetchMembershipEarningAsCpSignerForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsCpSignerForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsCpSigner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsCpSignerForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsCpSignerListForGlobalAdminStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsCpSignerListForGlobalAdminSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsCpSignerListForGlobalAdminFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // membership for global admin with country========================================================
    // PiiinkRegionOwner
    fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsPiiinkRegionOwner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryStart:
      (state: any, action) => {
        state.loading = true
      },
    fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountrySuccess:
      (state: any, action) => {
        state.membershipEarningAsPiiinkRegionOwnerList = action.payload
        state.loading = false
      },
    fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },
    //PiiinkCharity
    fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsPiiinkCharity = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },

    fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsPiiinkCharityList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // PiiinkAreaOwner
    fetchMembershipEarningAsPiiinkAreaOwnerStartForGlobalAdminWithCountryStart:
      (state: any, action) => {
        state.loading = true
      },
    fetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsPiiinkAreaOwner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountrySuccess:
      (state: any, action) => {
        state.membershipEarningAsPiiinkAreaOwnerList = action.payload
        state.loading = false
      },
    fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },

    // as  issuer
    fetchMembershipEarningAsIssuerForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsIssuerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsIssuerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsIssuerListForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsIssuerListForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsIssuerListForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipEarningAsCpSignerListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsCpSignerListSuccess: (state: any, action) => {
      state.membershipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsCpSignerListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // as   club charity
    fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsClubCharitySigner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountryStart:
      (state: any, action) => {
        state.loading = true
      },
    fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountrySuccess:
      (state: any, action) => {
        state.membershipEarningAsClubCharitySignerList = action.payload
        state.loading = false
      },
    fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountryFailure:
      (state: any, action) => {
        state.loading = false
        state.error = action.payload
      },
    // as  cp signer
    fetchMembershipEarningAsCpSignerForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsCpSignerForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsCpSigner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsCpSignerForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountrySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsCpSignerStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsCpSignerSuccess: (state: any, action) => {
      state.membershipEarningAsCpSigner = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsCpSignerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // Total transaction for country admin
    fetchCountrySpecificMembershipEarningTotalStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountrySpecificMembershipEarningTotalSuccess: (state: any, action) => {
      state.countrySpecificMembershipEarningTotal = action.payload
      state.loading = false
    },
    fetchCountrySpecificMembershipEarningTotalFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountrySpecificMembershipEarningTotalListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountrySpecificMembershipEarningTotalListSuccess: (
      state: any,
      action
    ) => {
      state.countrySpecificMembershipEarningTotalList = action.payload
      state.loading = false
    },
    fetchCountrySpecificMembershipEarningTotalListFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // white label membership ========================================================
    // as  issuer
    fetchMembershipEarningAsIssuerForWhitelabelStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipEarningAsIssuerForWhitelabelSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsIssuerForWhitelabelFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipEarningAsIssuerListForWhitelabelStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchMembershipEarningAsIssuerListForWhitelabelSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchMembershipEarningAsIssuerListForWhitelabelFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // whitelabelSpecificMembershipEarningTotal
    fetchWhitelabelSpecificMembershipEarningTotalStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificMembershipEarningTotalSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificMembershipEarningTotal = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificMembershipEarningTotalFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificMembershipEarningTotalList
    fetchWhitelabelSpecificMembershipEarningTotalListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificMembershipEarningTotalListSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificMembershipEarningTotalList = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificMembershipEarningTotalListFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificMembershipEarningAsIssuer
    fetchWhitelabelSpecificMembershipEarningAsIssuerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificMembershipEarningAsIssuerSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificMembershipEarningAsIssuer = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificMembershipEarningAsIssuerFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificMembershipEarningAsIssuerList
    fetchWhitelabelSpecificMembershipEarningAsIssuerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificMembershipEarningAsIssuerListSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificMembershipEarningAsIssuerList = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificMembershipEarningAsIssuerListFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificMembershipEarningAsClubCharitySigner
    fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificMembershipEarningAsClubCharitySigner =
        action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificMembershipEarningAsClubCharitySignerList
    fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerListSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificMembershipEarningAsClubCharitySignerList =
        action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerListFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificMembershipEarningAsCpSigner
    fetchWhitelabelSpecificMembershipEarningAsCpSignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificMembershipEarningAsCpSignerSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificMembershipEarningAsCpSigner = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificMembershipEarningAsCpSignerFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    // whitelabelSpecificMembershipEarningAsCpSignerList
    fetchWhitelabelSpecificMembershipEarningAsCpSignerListStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhitelabelSpecificMembershipEarningAsCpSignerListSuccess: (
      state: any,
      action
    ) => {
      state.whitelabelSpecificMembershipEarningAsCpSignerList = action.payload
      state.loading = false
    },
    fetchWhitelabelSpecificMembershipEarningAsCpSignerListFailure: (
      state: any,
      action
    ) => {
      state.error = action.payload
      state.loading = false
    },

    fetchMemberPremiumCodeTransactionStart: (state: any, action) => {
      state.loading = true
    },
    fetchMemberPremiumCodeTransactionSuccess: (state: any, action) => {
      state.memberPremiumCodeTransaction = action.payload
      state.loading = false
    },
    fetchMemberPremiumCodeTransactionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMemberPremiumCodeTransactionDetailStart: (state: any, action) => {
      state.loading = true
    },
    fetchMemberPremiumCodeTransactionDetailSuccess: (state: any, action) => {
      state.memberPremiumCodeTransactionDetail = action.payload
      state.loading = false
    },
    fetchMemberPremiumCodeTransactionDetailFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryRevenueFromFriendshipSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryRevenueFromFriendshipSummarySuccess: (state: any, action) => {
      state.countryRevenueFromFriendshipSummary = action.payload
      state.loading = false
    },
    fetchCountryRevenueFromFriendshipSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryRevenueFromFriendshipDetailStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryRevenueFromFriendshipDetailSuccess: (state: any, action) => {
      state.countryRevenueFromFriendshipDetail = action.payload
      state.loading = false
    },
    fetchCountryRevenueFromFriendshipDetailFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryRevenueFromMembershipSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryRevenueFromMembershipSummarySuccess: (state: any, action) => {
      state.countryRevenueFromMembershipSummary = action.payload
      state.loading = false
    },
    fetchCountryRevenueFromMembershipSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryRevenueFromMembershipDetailStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryRevenueFromMembershipDetailSuccess: (state: any, action) => {
      state.countryRevenueFromMembershipDetail = action.payload
      state.loading = false
    },
    fetchCountryRevenueFromMembershipDetailFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharityStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharitySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCharityGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharityFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharityStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharitySuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCharityGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharityFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsAreaOwnerGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsAreaOwnerGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsRegionOwnerGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsRegionOwnerGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsStateOwnerGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsStateOwnerGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCountryOwnerGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwnerSuccess: (
      state: any,
      action
    ) => {
      state.friendshipEarningAsCountryOwnerGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    //membership section starts

    fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharityStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharitySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsCharityGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharityFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailMembershipEarningAsCharityStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsCharitySuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsCharityGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsCharityFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsAreaOwnerGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsAreaOwnerGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsRegionOwnerGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsRegionOwnerGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsStateOwnerGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsStateOwnerGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsCountryOwnerGlobalAdminWithCountrySummary =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwnerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwnerSuccess: (
      state: any,
      action
    ) => {
      state.membershipEarningAsCountryOwnerGlobalAdminWithCountryDetails =
        action.payload
      state.loading = false
    },
    fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwnerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    cancelTransactionStart: (state: any, action) => {
      state.isCanceling = true
    },
    cancelTransactionSuccess: (state: any, action) => {
      state.isCanceling = false
    },
    cancelTransactionFailure: (state: any, action) => {
      state.isCanceling = false
      state.error = action.payload
    },
  },
})

export const earningActions = earningSlice.actions

export default earningSlice.reducer
