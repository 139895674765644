import { axiosRequestCMS } from 'utils'

export const fetchFaqList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/faq/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFaqById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/faq/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createFaq = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/faq/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteFaq = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/faq/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editFaq = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/faq/edit/${id}?isTranslateToAlllang=${params.isTranslateToAlllang}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
