import React, { ReactNode } from 'react'
import { GrHome } from 'react-icons/gr'
import { FiSettings, FiUser, FiUsers } from 'react-icons/fi'
import {
  MdCardMembership,
  MdOutlineAccountBalanceWallet,
  MdLoyalty,
  MdOutlinePayment,
  MdVideoSettings,
} from 'react-icons/md'
import { FaRegHospital } from 'react-icons/fa'

// import { RoutePermittedRole } from '../shared/constants/AppConst';
import { RoutePermittedRole } from '../../shared/constants/AppConst'
import { AttachMoney, MonetizationOn } from '@mui/icons-material'
import LogoutIcon from '@mui/icons-material/Logout'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import BusinessIcon from '@mui/icons-material/Business'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const SetupRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            messageId: 'Dashboard',
            type: 'item',
            url: '/dashboard',
          },
        ],
      },
      {
        id: 'myAccount',
        title: 'myAccount',
        messageId: 'My Account',
        type: 'item',
        // icon: <PersonOutline />,
        icon: <FiUser />,
        url: '/my-account',
      },

      {
        id: 'settings',
        title: 'Settings',
        messageId: 'Settings',
        type: 'collapse',
        icon: <FiSettings />,
        children: [
          {
            id: 'generalSettings',
            title: 'General setting',
            messageId: 'General setting',
            type: 'item',
            url: '/globaladmin/setting/generalsetting',
          },
          {
            id: 'prizedraw',
            title: 'Prize Draw',
            messageId: 'Prize draw',
            type: 'item',
            url: '/globaladmin/setting/prizedraw',
          },
          {
            id: 'temporaryPassword',
            title: 'Temporary Password',
            messageId: 'Temporary Password',
            type: 'item',
            url: '/globaladmin/setting/temporary-password',
          },
          {
            id: 'coupon',
            title: 'coupon',
            messageId: 'Coupon',
            type: 'item',
            url: '/globaladmin/setting/coupon',
          },
          {
            id: 'appRange',
            title: 'appRange',
            messageId: 'App Range',
            type: 'item',
            url: '/globaladmin/setting/app-range',
          },

          {
            id: 'reviewAutoText',
            title: 'reviewAutoText',
            messageId: 'Review Auto Text',
            type: 'item',
            url: '/globaladmin/review/autotext',
          },
          {
            id: 'documentManagementSetting',
            title: 'documentManagementSetting',
            messageId: 'Document Mgmt Setting',
            type: 'item',
            url: '/globaladmin/setting/document-management-setting',
          },
          {
            id: 'notifications',
            title: 'notifications',
            messageId: 'Notifications',
            type: 'item',
            url: '/globaladmin/setting/notifications',
          },
        ],
      },
      {
        id: 'setup',
        title: 'Setup',
        messageId: 'Setup',
        type: 'collapse',
        icon: <FiSettings />,
        children: [
          {
            id: 'countryprefix',
            title: 'Country Prefix Setup',
            messageId: 'Country Prefix Setup',
            type: 'item',
            url: '/setup/countryprefix',
          },
          {
            id: 'countrysetup',
            title: 'Country Setup',
            messageId: 'Country Setup',
            type: 'item',
            url: '/setup/countrysetup',
          },

          {
            id: 'currencysetup',
            title: 'Currency Setup',
            messageId: 'Currency Setup',
            type: 'item',
            url: '/setup/currency-setup',
          },
          {
            id: 'statesetup',
            title: 'State Setup',
            messageId: 'State / Province Setup',
            type: 'item',
            url: '/setup/statesetup',
          },
          {
            id: 'regionsetup',
            title: 'Region Setup',
            messageId: 'Region Setup',
            type: 'item',
            url: '/setup/regionsetup',
          },
          {
            id: 'areasetup',
            title: 'Area Setup',
            messageId: 'Area Setup',
            type: 'item',
            url: '/setup/areasetup',
          },
          {
            id: 'merchantcategorysetup',
            title: 'Merchant Category Setup',
            messageId: 'Merchant Category Setup',
            type: 'item',
            url: '/setup/merchantcategorysetup',
          },
          {
            id: 'emailTemplatessetup',
            title: 'Email Templates Setup',
            messageId: 'Email Templates Setup',
            type: 'item',
            url: '/setup/emailtemplatessetup',
          },
          {
            id: 'pageSetup',
            title: 'Page Setup',
            messageId: 'Page Setup',
            type: 'item',
            url: '/setup/pagesetup',
          },
        ],
      },
      {
        id: 'packagesetup',
        title: 'Package Setup',
        messageId: 'Package Setup',
        type: 'collapse',
        icon: <MdCardMembership />,
        children: [
          {
            id: 'collaborationpackage',
            title: 'Colloboration Package Setup',
            messageId: 'Colloboration Package Setup',
            type: 'item',
            url: '/setup/collaborationpackage',
          },
          {
            id: 'merchantpackagesetup',
            title: 'Merchant Package Setup',
            messageId: 'Merchant Package Setup',
            type: 'item',
            url: '/setup/merchantpackage',
          },
        ],
      },
      {
        id: 'feesetup',
        title: 'Fee Setup',
        messageId: 'Fee Setup',
        type: 'collapse',
        icon: <MdOutlineAccountBalanceWallet />,
        children: [
          {
            id: 'friendshipbeneficiaries',
            title: 'Friendship Beneficiaries',
            messageId: 'Friendship Beneficiaries',
            type: 'item',
            url: '/setup/friendshipbeneficiaries',
          },
          {
            id: 'membershipbeneficiaries',
            title: 'Membership Beneficiaries',
            messageId: 'Membership Beneficiaries',
            type: 'item',
            url: '/setup/membershipbeneficiaries',
          },
          {
            id: 'countryinternalbeneficiaries',
            title: 'Country Internal Beneficiaries',
            messageId: 'Country Internal Beneficiaries',
            type: 'item',
            url: '/setup/countryinternalbeneficiaries',
          },
          {
            id: 'transactionsetup',
            title: 'Merchant transaction fee',
            messageId: 'Merchant transaction fee',
            type: 'item',
            url: '/setup/merchanttransactionfee',
          },
          {
            id: 'piiinksandfee',
            title: 'Membership Package',
            messageId: ' Membership Package',
            type: 'item',
            url: '/setup/piiinksandfee',
          },
        ],
      },
      {
        id: 'whitelebelsetupparent',
        title: 'White Label Setup',
        messageId: 'White Label Setup',
        type: 'collapse',
        icon: <FaRegHospital />,
        children: [
          {
            id: 'whitelebelsetup',
            title: 'White Label Setup',
            messageId: 'Setup',
            type: 'item',
            url: '/setup/whiteLabelSetup',
          },
          // {
          //   id: 'whiteLabelLevelsSetup',
          //   title: 'whiteLabelLevelsSetup',
          //   messageId: 'White Label Levels Setup',
          //   type: 'item',
          //   url: '/setup/whiteLabelLevelsSetup',
          // },
          {
            id: 'whitelabelfriendshipBeneficiaries',
            title: 'White Label Friendship Beneficiaries',
            messageId: `Friendship Beneficiaries`,
            type: 'item',
            url: '/setup/whiteLabelFriendshipBeneficiaries',
          },
          {
            id: 'whitelabelmembershipBeneficiaries',
            title: 'White Label Membership Beneficiaries',
            messageId: `Membership Beneficiaries`,
            type: 'item',
            url: '/setup/whiteLabelMembershipBeneficiaries',
          },
        ],
      },
      // {
      //   id: 'agreementTemplateSetup',
      //   title: 'Agreement Template Setup',
      //   messageId: 'Agreement Template Setup',
      //   type: 'collapse',
      //   icon: <FaHandshake />,
      //   children: [
      //     {
      //       id: 'merchantAgreement',
      //       title: 'Merchant Agreement',
      //       messageId: 'Merchant Agreement',
      //       type: 'item',
      //       url: '/globaladmin/merchantagreement',
      //     },
      //     {
      //       id: 'charityAgreement',
      //       title: 'Charity Agreement',
      //       messageId: 'Charity Agreement',
      //       type: 'item',
      //       url: '/globaladmin/charityagreement',
      //     },
      //     {
      //       id: 'clubAgreement',
      //       title: 'Club Agreement',
      //       messageId: 'Club Agreement',
      //       type: 'item',
      //       url: '/globaladmin/clubagreement',
      //     },
      //     {
      //       id: 'issuerAgreement',
      //       title: 'Issuer Agreement',
      //       messageId: 'Issuer Agreement',
      //       type: 'item',
      //       url: '/globaladmin/issueragreement',
      //     },
      //   ],
      // },
      {
        id: 'cpbeneficiariessetup',
        title: 'CP Beneficiaries Setup',
        messageId: 'CP Beneficiaries Setup',
        type: 'collapse',
        icon: <MdLoyalty />,
        children: [
          {
            id: 'cpglobalfriendshipbeneficiaries',
            title: 'CP Global Friendship',
            messageId: 'CP Normal Friendship',
            type: 'item',
            url: '/globaladmin/cpglobalfriendshipbeneficiaries',
          },
          // {
          //   id: 'cpandwlfriendshipbeneficiaries',
          //   title: 'CP and WL Friendship',
          //   messageId: `CP and WL Friendship`,
          //   type: 'item',
          //   url: '/globaladmin/cpandwlfriendshipbeneficiaries',
          // },
          {
            id: 'cpglobalmembershipbeneficiaries',
            title: 'CP Global Membership',
            messageId: `CP Normal Membership`,
            type: 'item',
            url: '/globaladmin/cpglobalmembershipbeneficiaries',
          },
          // {
          //   id: 'cpandwlmembershipbeneficiaries',
          //   title: 'CP and WL Membership',
          //   messageId: 'CP and WL Membership',
          //   type: 'item',
          //   url: '/globaladmin/cpandwlmembershipbeneficiaries',
          // },
        ],
      },
      {
        id: 'countryowners',
        title: 'countryowners',
        messageId: 'Country Licensee',
        type: 'collapse',
        icon: <FiUsers />,
        children: [
          {
            id: 'expressionofinterest',
            title: 'Expression of Interest',
            messageId: 'Expression of Interest',
            type: 'item',
            url: '/globaladmin/eoicountryowner',
          },
          {
            id: 'countryownerslist',
            title: 'Country Licensee List',
            messageId: 'Country Licensee List',
            type: 'item',
            url: '/globaladmin/countryowner',
          },
        ],
      },
      {
        id: 'userslist',
        title: 'White Label Users',
        messageId: 'White Label Users',
        icon: <FiUsers />,
        type: 'item',
        url: '/globaladmin/usersList',
      },

      {
        id: 'company',
        title: 'Company',
        messageId: 'Company',
        icon: <BusinessIcon />,
        type: 'item',
        url: '/globaladmin/company',
      },

      {
        id: 'collaborationpartners',
        title: 'collaborationpartners',
        messageId: 'Collaboration Partners',
        icon: <FiUsers />,
        type: 'item',
        url: '/globaladmin/collaborationpartnerlist',
      },
      {
        id: 'PremiumCodes',
        title: 'Codes',
        messageId: 'Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'premiummerchantcodes',
            title: 'Merchant Codes',
            messageId: 'Merchant Codes',
            type: 'item',
            url: '/globaladmin/premium-merchant-codes',
          },
          {
            id: 'premiummembercodes',
            title: 'Premium Member Codes',
            messageId: 'Premium Member Codes',
            type: 'item',
            url: '/globaladmin/premium-member-codes',
          },
        ],
      },
      {
        id: 'QrCodes',
        title: 'QrCodes',
        messageId: 'QR Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'transactionQrCodes',
            title: 'Transaction QR Codes',
            messageId: 'Transaction QR Codes',
            type: 'item',
            url: '/globaladmin/transaction-qr-codes',
          },
          {
            id: 'issuerQrCodes',
            title: 'Issuer QR Codes',
            messageId: 'Issuer QR Codes',
            type: 'item',
            url: '/globaladmin/issuer-qr-codes',
          },
        ],
      },
      {
        id: 'trainingSetup',
        title: 'Training Setup',
        messageId: 'Training Setup',
        type: 'item',
        url: '/setup/training-setup',
        // url: '/setup/trainingvideos',
        icon: <MdVideoSettings />,
      },

      //panda docs section starts
      {
        id: 'documentManagement',
        title: 'document Management',
        messageId: 'Document Management',
        type: 'collapse',
        icon: <ContentPasteIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/globaladmin/setting/smtp',
          // },
          {
            id: 'group',
            title: 'Group',
            messageId: 'Group',
            type: 'item',
            url: '/globaladmin/setting/group',
          },

          {
            id: 'contacts',
            title: 'Contacts',
            messageId: 'Contacts',
            type: 'item',
            url: '/globaladmin/setting/contacts',
          },

          {
            id: 'documents',
            title: 'Documents',
            messageId: 'Documents',
            type: 'item',
            url: '/globaladmin/setting/documents',
          },

          {
            id: 'emails',
            title: 'Emails',
            messageId: 'Emails',
            type: 'item',
            url: '/globaladmin/setting/emails',
          },
        ],
      },

      //panda docs section ends

      {
        icon: <MonetizationOn />,
        id: 'prizedraw',
        title: 'prizedraw',
        messageId: 'Prize Draw',
        type: 'item',
        url: '/prizedraw',
      },

      {
        icon: <AttachMoney />,
        id: 'friendshipearning',
        title: 'FriendshipEarning',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          // {
          //   id: 'asPiiinkIssuer',
          //   title: 'AsPiiinkIssuer',
          //   messageId: 'As Piiink Issuer',
          //   type: 'item',
          //   url: '/globaladmin/friendship-earning/aspiiinkissuer',
          // },
          {
            id: 'asPiiinkCharity',
            title: 'AsPiiinkCharity',
            messageId: 'As Piiink Charity',
            type: 'item',
            url: '/globaladmin/friendship-earning/aspiiinkcharity',
          },
          // {
          //   id: 'asPiiinkRegion',
          //   title: 'AsPiiinkRegion',
          //   messageId: 'As Piiink Region',
          //   type: 'item',
          //   url: '/globaladmin/friendship-earning/aspiiinkregion',
          // },
          // {
          //   id: 'aspiiinkarea',
          //   title: 'AsPiiinkArea',
          //   messageId: 'As Piiink Area',
          //   type: 'item',
          //   url: '/globaladmin/friendship-earning/aspiiinkarea',
          // },
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/globaladmin/friendship-earning/asissuer',
          },
          {
            id: 'asMerchantSigner',
            title: 'AsMerchantSigner',
            messageId: 'As Merchant Signer',
            type: 'item',
            url: '/globaladmin/friendship-earning/asmerchantsigner',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/globaladmin/friendship-earning/ascpsigner',
          },

          {
            id: 'asPiiinkEntities',
            title: 'asPiiinkEntities',
            messageId: 'As Piiink Entities',
            type: 'item',
            url: '/globaladmin/friendship-earning/aspiiinkentities',
          },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'memberearning',
        title: 'memberhipEarning',
        messageId: 'Membership Earning',
        type: 'collapse',
        children: [
          // {
          //   id: 'asPiiinkIssuer',
          //   title: 'AsPiiinkIssuer',
          //   messageId: 'As Piiink Issuer',
          //   type: 'item',
          //   url: '/globaladmin/membership-earning/aspiiinkissuer',
          // },
          {
            id: 'asPiiinkCharity',
            title: 'AsPiiinkCharity',
            messageId: 'As Piiink Charity',
            type: 'item',
            url: '/globaladmin/membership-earning/aspiiinkcharity',
          },
          {
            id: 'asPiiinkRegion',
            title: 'AsPiiinkRegion',
            messageId: 'As Piiink Region',
            type: 'item',
            url: '/globaladmin/membership-earning/aspiiinkregion',
          },
          {
            id: 'aspiiinkarea',
            title: 'AsPiiinkArea',
            messageId: 'As Piiink Area',
            type: 'item',
            url: '/globaladmin/membership-earning/aspiiinkarea',
          },
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/globaladmin/membership-earning/asissuer',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/globaladmin/membership-earning/ascpsigner',
          },

          {
            id: 'asCharityClub',
            title: 'AsCharityClub',
            messageId: 'As Club/Charity Signer',
            type: 'item',
            url: '/globaladmin/membership-earning/asclubcharity',
          },

          {
            id: 'asPiiinkEntities',
            title: 'asPiiinkEntities',
            messageId: 'As Piiink Entities',
            type: 'item',
            url: '/globaladmin/membership-earning/aspiiinkentities',
          },
          // {
          //   id: 'fromMemberPremiumCode',
          //   title: 'fromMemberPremiumCode',
          //   messageId: 'From Member Premium Code',
          //   type: 'item',
          //   url: '/globaladmin/membership-earning/fromMemberPremiumCode',
          // },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'countryRevenue',
        title: 'countryRevenue',
        messageId: 'Country Revenue',
        type: 'collapse',
        children: [
          {
            id: 'fromFriendship',
            title: 'fromFriendship',
            messageId: 'From Friendship',
            type: 'item',
            url: '/globaladmin/country-revenue/fromFriendship',
          },
          {
            id: 'fromMembership',
            title: 'fromMembership',
            messageId: 'From Membership',
            type: 'item',
            url: '/globaladmin/country-revenue/fromMembership',
          },
        ],
      },

      {
        icon: <AttachMoney />,
        id: 'merchantPackage',
        title: 'merchantPackage',
        messageId: 'Merchant Package',
        type: 'collapse',
        children: [
          {
            id: 'earning',
            title: 'earning',
            messageId: 'Piiink Earning',
            type: 'item',
            url: '/globaladmin/merchant-package/earning',
          },
          {
            id: 'payable',
            title: 'payable',
            messageId: 'Payable',
            type: 'item',
            url: '/globaladmin/merchant-package/payable',
          },
        ],
      },

      // {
      //   icon: <ArticleIcon />,
      //   id: 'review',
      //   title: 'review',
      //   messageId: 'Review',
      //   type: 'collapse',
      //   children: [
      //     {
      //       id: 'autotext',
      //       title: 'autotext',
      //       messageId: 'AutoText',
      //       type: 'item',
      //       url: '/globaladmin/review/autotext',
      //     },
      //   ],
      // },

      {
        icon: <AccountBalanceIcon />,
        id: 'payment',
        title: 'payment',
        messageId: 'Payment',
        type: 'collapse',
        children: [
          {
            id: 'payout',
            title: 'payout',
            messageId: 'Payout',
            type: 'item',
            url: '/globaladmin/payment/payout',
          },
          {
            id: 'paymentLogHistory',
            title: 'paymentLogHistory',
            messageId: 'Payment Log History',
            type: 'item',
            url: '/globaladmin/payment/payment-log-history',
          },
        ],
      },

      {
        icon: <ReceiptIcon />,
        id: 'coupons',
        title: 'coupons',
        messageId: 'Coupons',
        type: 'item',
        url: '/globaladmin/coupons',
      },

      // {
      //   icon: <AttachMoney />,
      //   id: 'globalMemberPremiumCodeEarning',
      //   title: 'globalMemberPremiumCodeEarning',
      //   messageId: 'Global Member Premium Code Earning',
      //   url: '/globaladmin/global-member-premium-code',

      //   type: 'item',
      // },

      // {
      //   icon: <PolicyIcon />,
      //   id: 'terms',
      //   title: 'terms',
      //   messageId: 'Terms & Conditions',
      //   type: 'item',
      //   url: '/terms',
      // },

      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },
    ],
  },
]
export default SetupRoutesConfig
