import React, { useEffect, useState } from 'react'
import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import { Box } from '@mui/material'
import AppComponentHeader from '@crema/core/AppComponentHeader'
import AppCard from '@crema/core/AppCard'
import ListTop from './components/ListTop/ListTop'
import CountryDemo from './components/MerchantTable'
import container from './ReferredMerchantList.container'
import { useNavigate } from 'react-router-dom'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

function handleClick(event) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

const CustomizedBreadcrumbs = () => {
  const navigate = useNavigate()

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          label="Merchants"
          icon={<HomeIcon fontSize="small" />}
          onClick={() => navigate('/dashboard')}
        />
        <StyledBreadcrumb label="Referred Merchant List" />
      </Breadcrumbs>
    </div>
  )
}

const AreaAdmin = ({
  onFetchMemberStart,
  onFetchMerchantGeneralStart,
  onFetchCPProfileInfoStart,
  onFetchAllReferredMerchantListStart,
  onRejectReferredMerchantStart,
  onApproveReferredMerchantStart,
  merchant: {
    referredMerchantList: {
      data,
      hasMore,
      totalCount,
      count,
      page: pageNumber,
    },
    loading,
    isApproving,
    isRejecting,
    isSetting,
  },
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  adminRoute,
  onAcceptOrRejectreferredMerchantStart,
  ...rest
}) => {
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterFormData, setFilterFormData] = useState({})

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setOffset(offset + rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const clearFilterForm = () => {
    setFilterFormData({})
  }

  useEffect(() => {
    onFetchAllReferredMerchantListStart({
      page: page + 1,
      limit: rowsPerPage,
      offset,
      // countryId: globalCountry?.id,
      ...filterFormData,
    })
  }, [page, rowsPerPage, filterFormData, currentUser])

  const refreshPageData = () => {
    onFetchAllReferredMerchantListStart({
      page: page + 1,
      limit: rowsPerPage,
      offset,
      // countryId: globalCountry?.id,
      ...filterFormData,
    })
  }

  return (
    <Box style={{ color: '#000000' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <AppComponentHeader title="Referred Merchants" />
        <CustomizedBreadcrumbs />
      </Box>
      <ListTop
        {...rest}
        setFilterFormData={(data) => {
          setFilterFormData(data)
          setPage(0)
        }}
        clearFilterForm={() => {}}
        globalCountry={globalCountry}
      />
      <AppCard>
        <CountryDemo
          countryData={data}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          hasMoreData={hasMore}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refreshPageData={refreshPageData}
          currentUser={currentUser}
          //   onDeleteClubStart={onDeleteClubStart}
          totalCount={totalCount}
          loading={loading}
          pageNumber={pageNumber}
          adminRoute={adminRoute}
          onRejectReferredMerchantStart={onRejectReferredMerchantStart}
          onApproveReferredMerchantStart={onApproveReferredMerchantStart}
          isRejecting={isRejecting}
          isApproving={isApproving}
          onAcceptOrRejectreferredMerchantStart={
            onAcceptOrRejectreferredMerchantStart
          }
          isSetting={isSetting}
        />
        <Box style={{ height: 30 }} />
      </AppCard>
    </Box>
  )
}

export default container(AreaAdmin)
