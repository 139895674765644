import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchCpGlobalFriendshipBeneficiaryStart,
  fetchCpGlobalFriendshipBeneficiarySuccess,
  fetchCpGlobalFriendshipBeneficiaryFailure,
  createCpGlobalFriendshipBeneficiarySuccess,
  createCpGlobalFriendshipBeneficiaryFailure,
  deleteCpGlobalFriendshipBeneficiarySuccess,
  deleteCpGlobalFriendshipBeneficiaryFailure,
  fetchCpGlobalFriendshipBeneficiaryByIdSuccess,
  fetchCpGlobalFriendshipBeneficiaryByIdFailure,
  editCpGlobalFriendshipBeneficiarySuccess,
  editCpGlobalFriendshipBeneficiaryFailure,
} from './CPGlobalFriendshipBeneficiarySlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchCpGlobalFriendshipBeneficiaryList,
  createCpGlobalFriendshipBeneficiary,
  deleteCpGlobalFriendshipBeneficiary,
  fetchCpGlobalFriendshipBeneficiaryById,
  editCpGlobalFriendshipBeneficiary,
} from '../../api/cpGlobalFriendshipBeneficiary'

import _ from 'lodash'

export function* fetchCpGlobalFriendshipBeneficiaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCpGlobalFriendshipBeneficiaryList(payload))

    yield put(fetchCpGlobalFriendshipBeneficiarySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCpGlobalFriendshipBeneficiaryFailure(err))
  }
}

export function* fetchCpGlobalFriendshipBeneficiaryByIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCpGlobalFriendshipBeneficiaryById(payload))

    yield put(fetchCpGlobalFriendshipBeneficiaryByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCpGlobalFriendshipBeneficiaryByIdFailure(err))
  }
}

export function* editCpGlobalFriendshipBeneficiaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      editCpGlobalFriendshipBeneficiary(payload.params.id, payload.params)
    )

    yield put(editCpGlobalFriendshipBeneficiarySuccess(data?.data))
    yield put(
      fetchCpGlobalFriendshipBeneficiaryStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'CP Friendship beneficiaries successfully edited',
        severity: 'success',
      })
    )
    payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editCpGlobalFriendshipBeneficiaryFailure(err))
  }
}

export function* createCpGlobalFriendshipBeneficiaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      createCpGlobalFriendshipBeneficiary(payload.params)
    )

    yield put(createCpGlobalFriendshipBeneficiarySuccess(data?.data))
    yield put(
      fetchCpGlobalFriendshipBeneficiaryStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'CP Global Friendship Beneficiary succesfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createCpGlobalFriendshipBeneficiaryFailure(err))
  }
}

export function* deleteCpGlobalFriendshipBeneficiaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(deleteCpGlobalFriendshipBeneficiary(payload))

    if (data) {
      yield put(deleteCpGlobalFriendshipBeneficiarySuccess(payload))
      yield put(
        fetchCpGlobalFriendshipBeneficiaryStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Cp Global Friendship Beneficiary deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteCpGlobalFriendshipBeneficiaryFailure(err))
  }
}

export function* watchFetchCpGlobalFriendshipBeneficiary() {
  yield takeLatest(
    'cpGlobalFriendshipBeneficiary/fetchCpGlobalFriendshipBeneficiaryStart',
    fetchCpGlobalFriendshipBeneficiaryAsync
  )
}

export function* watchCreateCpGlobalFriendshipBeneficiary() {
  yield takeLatest(
    'cpGlobalFriendshipBeneficiary/createCpGlobalFriendshipBeneficiaryStart',
    createCpGlobalFriendshipBeneficiaryAsync
  )
}

export function* watchDeleteCpGlobalFriendshipBeneficiary() {
  yield takeLatest(
    'cpGlobalFriendshipBeneficiary/deleteCpGlobalFriendshipBeneficiaryStart',
    deleteCpGlobalFriendshipBeneficiaryAsync
  )
}

export function* watchFetchCpGlobalFriendshipBeneficiaryById() {
  yield takeLatest(
    'cpGlobalFriendshipBeneficiary/fetchCpGlobalFriendshipBeneficiaryByIdStart',
    fetchCpGlobalFriendshipBeneficiaryByIdAsync
  )
}

export function* watchEditCpGlobalFriendshipBeneficiary() {
  yield takeLatest(
    'cpGlobalFriendshipBeneficiary/editCpGlobalFriendshipBeneficiaryStart',
    editCpGlobalFriendshipBeneficiaryAsync
  )
}

export function* cpGlobalFriendshipBeneficiarySagas() {
  yield all([
    call(watchFetchCpGlobalFriendshipBeneficiary),
    call(watchCreateCpGlobalFriendshipBeneficiary),
    call(watchDeleteCpGlobalFriendshipBeneficiary),
    call(watchFetchCpGlobalFriendshipBeneficiaryById),
    call(watchEditCpGlobalFriendshipBeneficiary),
  ])
}
