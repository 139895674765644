import { createSlice } from '@reduxjs/toolkit'

import { ICountryOwnerState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: ICountryOwnerState = {
  countryOwnerList: listObj,
  soleCountryOwner: {},
  countryOwnerProfileInfo: listObj,
  error: null,
  loading: false,
  isEditing: false,
  isDeleting: false,
  isVerifing: false,
  isRejecting: false,
  hasMoreData: false,
  isUploading: false,
}

export const countryOwnerSlice = createSlice({
  name: 'countryOwner',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCountryOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryOwnerSuccess: (state: any, action) => {
      state.countryOwnerList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCountryOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryOwnerByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryOwnerByIdSuccess: (state: any, action) => {
      state.soleCountryOwner = action.payload
      state.loading = false
    },
    fetchCountryOwnerByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteCountryOwnerStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteCountryOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
      state.countryOwnerList.data = state.countryOwnerList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteCountryOwnerFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    createCountryOwnerStart: (state: any, action) => {
      state.loading = true
    },
    createCountryOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.countryOwnerList.data = [
        ...state.countryOwnerList.data,
        action.payload,
      ]
    },
    createCountryOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editCountryOwnerStart: (state: any, action) => {
      state.isEditing = true
    },
    editCountryOwnerSuccess: (state: any, action) => {
      state.isEditing = false
      const result = state?.countryOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.countryOwnerList.data = result
    },
    editCountryOwnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
    fetchCountryOwnerProfileInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCountryOwnerProfileInfoSuccess: (state: any, action) => {
      state.countryOwnerProfileInfo = action.payload
      state.isFetching = false
    },
    fetchCountryOwnerProfileInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    uploadAgreementStart: (state: any, action) => {
      state.isUploading = true
    },
    uploadAgreementSuccess: (state: any) => {
      state.isUploading = false
    },
    uploadAgreementFailure: (state: any, action) => {
      state.isUploading = false
      state.error = action.payload
    },
    editOwnCountryProfileStart: (state: any, action) => {
      state.isEditing = true
    },
    editOwnCountryProfileSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editOwnCountryProfileFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
  },
})

export const {
  fetchCountryOwnerStart,
  fetchCountryOwnerSuccess,
  fetchCountryOwnerFailure,
  deleteCountryOwnerStart,
  deleteCountryOwnerSuccess,
  deleteCountryOwnerFailure,
  fetchCountryOwnerByIdStart,
  fetchCountryOwnerByIdSuccess,
  fetchCountryOwnerByIdFailure,
  createCountryOwnerStart,
  createCountryOwnerSuccess,
  createCountryOwnerFailure,
  editCountryOwnerStart,
  editCountryOwnerSuccess,
  editCountryOwnerFailure,
  fetchCountryOwnerProfileInfoStart,
  fetchCountryOwnerProfileInfoSuccess,
  fetchCountryOwnerProfileInfoFailure,
  uploadAgreementStart,
  uploadAgreementSuccess,
  uploadAgreementFailure,
  editOwnCountryProfileStart,
  editOwnCountryProfileSuccess,
  editOwnCountryProfileFailure,
} = countryOwnerSlice.actions

export default countryOwnerSlice.reducer
