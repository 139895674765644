import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchCollaborationPackageStart,
  fetchCollaborationPackageSuccess,
  fetchCollaborationPackageFailure,
  createCollaborationPackageSuccess,
  createCollaborationPackageFailure,
  deleteCollaborationPackageSuccess,
  deleteCollaborationPackageFailure,
  fetchCollaborationPackageByIdSuccess,
  fetchCollaborationPackageByIdFailure,
  editCollaborationPackageSuccess,
  editCollaborationPackageFailure,
} from './collaborationPackageSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchCollaborationPackageList,
  createCollaborationPackage,
  deleteCollaborationPackage,
  fetchCollaborationPackageById,
  editCollaborationPackage,
} from '../../api/collaborationPackage'

import _ from 'lodash'

export function* fetchCollaborationPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCollaborationPackageList(payload))

    yield put(fetchCollaborationPackageSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCollaborationPackageFailure(err))
  }
}

export function* fetchCollaborationPackageByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCollaborationPackageById(payload))

    yield put(fetchCollaborationPackageByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCollaborationPackageByIdFailure(err))
  }
}

export function* editCollaborationPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editCollaborationPackage(payload?.params?.id, payload?.params)
    )

    yield put(editCollaborationPackageSuccess(data?.updateResult))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchCollaborationPackageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Collaboration package successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editCollaborationPackageFailure(err))
  }
}

export function* createCollaborationPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createCollaborationPackage(payload?.params))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchCollaborationPackageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: 'Collaboration package successfully created',
        severity: 'success',
      })
    )
    yield put(createCollaborationPackageSuccess(data?.resultsShow))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createCollaborationPackageFailure(err))
  }
}

export function* deleteCollaborationPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteCollaborationPackage(payload))

    if (data) {
      yield put(deleteCollaborationPackageSuccess(payload))
      yield put(
        fetchCollaborationPackageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Collaboration package deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteCollaborationPackageFailure(err))
  }
}

export function* watchFetchCollaborationPackage() {
  yield takeLatest(
    'collaborationPackage/fetchCollaborationPackageStart',
    fetchCollaborationPackageAsync
  )
}

export function* watchCreateCollaborationPackage() {
  yield takeLatest(
    'collaborationPackage/createCollaborationPackageStart',
    createCollaborationPackageAsync
  )
}

export function* watchDeleteCollaborationPackage() {
  yield takeLatest(
    'collaborationPackage/deleteCollaborationPackageStart',
    deleteCollaborationPackageAsync
  )
}

export function* watchFetchCollaborationPackageById() {
  yield takeLatest(
    'collaborationPackage/fetchCollaborationPackageByIdStart',
    fetchCollaborationPackageByIdAsync
  )
}

export function* watchEditCollaborationPackage() {
  yield takeLatest(
    'collaborationPackage/editCollaborationPackageStart',
    editCollaborationPackageAsync
  )
}

export function* collaborationPackageSagas() {
  yield all([
    call(watchFetchCollaborationPackage),
    call(watchCreateCollaborationPackage),
    call(watchDeleteCollaborationPackage),
    call(watchFetchCollaborationPackageById),
    call(watchEditCollaborationPackage),
  ])
}
