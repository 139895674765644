import React from 'react'
import AppCard from '@crema/core/AppCard'
import { Box, Grid, TextField, Button } from '@mui/material'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import { formatDateIntoFromTo, removeEmptyFields } from 'utils'

import _ from 'lodash'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment-timezone'

const ListTop = ({
  setFilterFormData,
  clearFilterForm,
  refreshPageData,
  currentUser: { role, userCountry },
  ...props
}) => {
  const validationSchema = yup.object({})

  const onSubmitSearch = (data) => {
    const modifiedCreatedAt = data?.createdAt__between
      ? moment(new Date(data?.createdAt__between)).toString()
      : null
    setFilterFormData(
      removeEmptyFields({
        title: data?.title ? data?.title?.trim() : null,
        ...formatDateIntoFromTo(modifiedCreatedAt),
      })
    )
  }

  return (
    <>
      <AppCard sx={{ padding: '1rem 0' }}>
        <Box>
          <Formik
            validateOnChange={true}
            initialValues={{
              title: '',
              createdAt__between: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)
              onSubmitSearch(data)
              setSubmitting(false)
            }}
          >
            {({ resetForm, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'Notification Title'}
                      label={'Notification Title'}
                      type="text"
                      name="title"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD-MM-YYYY"
                        label="Notification Date"
                        value={values?.createdAt__between}
                        onChange={(value) => {
                          setFieldValue('createdAt__between', value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            name="createdAt__between"
                            size="small"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item>
                    <Button
                      size="small"
                      sx={{
                        px: 5,
                        color: '#ffffff',
                        backgroundColor: '#ec4785',
                        '&:hover': {
                          background: '#ec4785',
                        },
                      }}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      sx={{
                        px: 5,
                      }}
                      variant="outlined"
                      type="submit"
                      onClick={() => {
                        clearFilterForm()
                        resetForm()
                      }}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </AppCard>
      <Box sx={{ height: 30 }} />
    </>
  )
}

export default ListTop
