import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import DraftList from 'components/GlobalMerchantForm/DraftList/DraftList.component'
import EditMerchantRequest from 'components/GlobalMerchantForm/DraftList/components/EditMerchantRequest/EditMerchantRequest.component'
import MerchantList from 'components/GlobalMerchantForm/MerchantList'
import SendMerchantRequest from 'components/GlobalMerchantForm/SendMerchantRequest'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import AllNotificationList from 'components/AllNotificationList'
import ConnectStripe from 'components/Payments/ConnectStripe'

const CharitiesList = React.lazy(() => import('./Charities/CharitiesList'))
const ClubList = React.lazy(() => import('./Club/ClubList'))
const MembersList = React.lazy(() => import('./Members/MembersList'))
const MerchantCodes = React.lazy(() => import('./PremiumCodes/MerchantCodes'))
const MemberCodes = React.lazy(() => import('./PremiumCodes/MemberCodes'))
const CodesHistory = React.lazy(() => import('./PremiumCodes/CodesHistory'))
const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))

const MerchantCodesList = React.lazy(
  () => import('./PremiumCodes/MerchantCodesList')
)
const MemberCodesList = React.lazy(
  () => import('./PremiumCodes/MemberCodesList')
)
const ViewPurchaseHistory = React.lazy(() => import('./ViewPurchaseHistory'))
const ReferredCPList = React.lazy(
  () => import('./CollaborationPartners/ReferredCPList')
)

const TransactionQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/TransactionQrCode/QrCodes')
)

const IssuerQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/IssuerQrCode')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)
const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)
const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const FriendshipEarningAsCpSignerAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgnerAsSigner')
)
const MembershipEarningFromCpRefer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsCpSigner')
)
const MembershipEarningFromCpReferDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsCpSigner')
)
const MembershipEarningAsClubCharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsClubCharitySigner')
)
const MembershipEarningAsClubCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsClubCharitySigner')
)
const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)

const FriendshipEarningFromCountryOrGlobalPool = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/FromCountryOrGlobalPool')
)

const FriendshipEarningFromCountryOrGlobalPoolDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/FromCountryOrGlobalPool')
)

const FriendshipEarningFromCountryOrGlobalPoolAsMerchantSignerDetail =
  React.lazy(
    () =>
      import(
        './Earning/Friendship/DetailsPages/FromCountryOrGlobalPoolAsMerchant'
      )
  )

const MembershipEarningFromCountryOrGlobalPool = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromCountryOrGlobalPool')
)

const MembershipEarningFromCountryOrGlobalPoolDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromCountryOrGlobalPool')
)

const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)

const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)

const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)
const EditMerchantFromMerchantList = React.lazy(
  () => import('components/GlobalMerchantForm/EditMerchantRequest')
)

const MerchantActivityReports = React.lazy(
  () => import('components/GlobalMerchantForm/MerchantActivityReports')
)

const MembersInfoList = React.lazy(
  () => import('components/GlobalMerchantForm/MembersList')
)

export const collaborationPartnerConfig = [
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/charitieslist',
    element: <CharitiesList />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/clublist',
    element: <ClubList />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/memberslist',
    element: <MembersList />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/premium-merchant-codes',
    // element: <MerchantCodes />,
    element: <MerchantCodesList />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/premium-member-codes',
    // element: <MemberCodes />,
    element: <MemberCodesList />,
  },
  // {
  //   permittedRole: RoutePermittedRole.collaborationPartner,
  //   path: '/collaborationpartner/premium-merchant-codes-list/:id',
  //   element: <MerchantCodesList />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.collaborationPartner,
  //   path: '/collaborationpartner/premium-member-codes-list/:id',
  //   element: <MemberCodesList />,
  // },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/premium-codes-history',
    element: <CodesHistory />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/purchase-history',
    element: <ViewPurchaseHistory />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/referredcplist',
    element: <ReferredCPList />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'collaborationPartner'} />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'collaborationPartner'} />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/editMerchantRequest/:id',
    element: <EditMerchantRequest adminRoute={'collaborationPartner'} />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/merchantList',
    element: <MerchantList adminRoute={'collaborationPartner'} />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/draftList',
    element: <DraftList adminRoute={'collaborationPartner'} />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/transaction-qr-codes',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/issuer-qr-codes',
    element: <IssuerQrCodes />,
  },
  // issuer
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },
  // merchant signer
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },
  // cp signer
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/ascpsigner/detail',
    element: <FriendshipEarningAsCpSignerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/ascpsigner/asmerchantsigner/detail',
    element: <FriendshipEarningAsCpSignerAsMerchantSignerDetail />,
  },

  // issuer
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },
  // area allocation
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/membership-earning/fromcprefer',
    element: <MembershipEarningFromCpRefer />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/membership-earning/fromcprefer/detail',
    element: <MembershipEarningFromCpReferDetail />,
  },
  // club/charity
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/membership-earning/asclubcharity',
    element: <MembershipEarningAsClubCharity />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/membership-earning/asclubcharity/detail',
    element: <MembershipEarningAsClubCharityDetail />,
  },

  // CountryOrGlobalPool
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/fromCountry-GlobalPool',
    element: <FriendshipEarningFromCountryOrGlobalPool />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/fromCountry-GlobalPool/detail',
    element: <FriendshipEarningFromCountryOrGlobalPoolDetail />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/friendship-earning/fromCountry-GlobalPool/asmerchantsigner/detail',
    element: <FriendshipEarningFromCountryOrGlobalPoolAsMerchantSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/membership-earning/fromCountry-GlobalPool',
    element: <MembershipEarningFromCountryOrGlobalPool />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/membership-earning/fromCountry-GlobalPool/detail',
    element: <MembershipEarningFromCountryOrGlobalPoolDetail />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/coupons',
    element: <Coupons adminRoute={'collaborationpartner'} />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/coupon-codes/:id',
    element: <CouponCodes />,
  },

  // {
  //   permittedRole: RoutePermittedRole.collaborationPartner,
  //   path: '/collaborationpartner/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/setting/smtp',
    element: <SMTP adminRoute={'collaborationpartner'} />,
  },
  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/setting/group',
    element: <Group adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/setting/contacts',
    element: <Contacts adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/setting/documents',
    element: <Documents adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/setting/emails',
    element: <Emails adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/setting/reports/:id',
    element: <Reports adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/setting/recipients',
    element: <Recipients adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/merchantList/editMerchant/:id',
    element: (
      <EditMerchantFromMerchantList adminRoute={'collaborationpartner'} />
    ),
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/merchant-activity-reports',
    element: <MerchantActivityReports adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/member-list/:id',
    element: <MembersInfoList adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/all-notification',
    element: <AllNotificationList adminRoute={'collaborationpartner'} />,
  },

  {
    permittedRole: RoutePermittedRole.collaborationPartner,
    path: '/collaborationpartner/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'collaborationpartner'} />,
  },
]
