import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import DeleteModal from '@crema/core/AppConfirmDialog'
import { Tooltip, IconButton, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import RejectReasonViewer from 'components/RejectReasonViewer'
import moment from 'moment-timezone'
import { useNavigate } from 'react-router-dom'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import ForwardDialogForm from '../ForwardDialogForm'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  paddingLeft: 0,
  paddingTop: 9,
  paddingBottom: 9,
  lineHeight: 1.43,
  paddingRight: 20,
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  count: number
  pageNumber?: number
  rowsPerPage?: number
  adminRoute?: any
  refreshPageData?: any
  currentUser?: any
  onDeleteClubStart?: (payload: any) => void
}

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  pageNumber,
  rowsPerPage,
  onDeleteClubStart,
  adminRoute,
  refreshPageData,
  currentUser,
}) => {
  const navigate = useNavigate()

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [forwardDialogOpen, setForwardDialogOpen] = useState<boolean>(false)

  const [isEditDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [toDeleteId, setToDeleteId] = useState<number>(null)
  const [reason, setReason] = useState<string>('')
  const [selectedMerchant, setSelectedMerchant] = useState<any>({})

  const [openReasonModal, setOpenReasonModal] = useState<boolean>(false)

  const [rejectReason, setRejectReason] = useState<string>('')

  const onDeleteSelectedContacts = () => {
    onDeleteClubStart({
      id: toDeleteId,
      rejectReason: reason,
      closeDeleteModal,
    })
    // setDeleteDialogOpen(false)
  }

  const closeForwardModal = () => {
    setForwardDialogOpen(false)
  }

  const closeDeleteModal = () => {
    setDeleteDialogOpen(false)
  }

  const onCloseEditDialog = () => {
    setEditDialogOpen(false)
  }

  const onSelectContactsForDelete = (id) => {
    setToDeleteId(id)
    setDeleteDialogOpen(true)
  }

  const reasonModalOpen = (data) => {
    setRejectReason(data)
    setOpenReasonModal(true)
  }

  const checkSentViaEmail = (mailSentBySigner) => {
    if (mailSentBySigner == true) {
      return 'Yes'
    } else if (mailSentBySigner == false) {
      return 'No'
    } else {
      return '-'
    }
  }
  return (
    <>
      <DeleteModal
        open={isDeleteDialogOpen}
        onDeny={setDeleteDialogOpen}
        onConfirm={onDeleteSelectedContacts}
        title="Are you sure you want to delete this club?"
        dialogTitle="Delete Club"
      />

      {openReasonModal && (
        <RejectReasonViewer
          open={openReasonModal}
          onDeny={setOpenReasonModal}
          defaultValue={rejectReason}
          title="Rejection Reason"
          dialogTitle="Rejection Details"
        />
      )}

      {forwardDialogOpen && (
        <ModalRenderer
          open={forwardDialogOpen}
          onDeny={closeForwardModal}
          onConfirm={onDeleteSelectedContacts}
          title="Are you sure you want to refer this merchant?"
          dialogTitle="Confirm ?"
          size={'sm'}
        >
          <ForwardDialogForm
            selectedStateOwnerId={selectedMerchant?.id}
            closeMarkAsPopularModal={closeForwardModal}
            refreshPageData={refreshPageData}
            selectedMerchantObj={selectedMerchant}
          />
        </ModalRenderer>
      )}
      <TableRow key={data.sn} className="item-hover">
        <StyledTableCell
          align="left"
          sx={{ position: 'sticky', left: 0, zIndex: 1, background: '#fff' }}
        >
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{ position: 'sticky', left: 50, zIndex: 1, background: '#fff' }}
        >
          {data?.id}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{ position: 'sticky', left: 100, zIndex: 2, background: '#fff' }}
        >
          {data?.merchantName}
        </StyledTableCell>
        {currentUser?.role?.toLowerCase() === 'collaborationpartner' &&
          currentUser?.userCountry?.partnerType?.toLowerCase() === 'global' && (
            <StyledTableCell align="left">
              {data?.__country__?.id}
            </StyledTableCell>
          )}

        <StyledTableCell align="left">{data?.merchantEmail}</StyledTableCell>
        <StyledTableCell align="left">{data?.username}</StyledTableCell>

        <StyledTableCell align="left">
          {data?.__country__?.phonePrefix} {data?.merchantPhoneNumber}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.__state__?.stateName ?? '-'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {checkSentViaEmail(data?.mailSentBySigner)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.memberAsRefererId ? 'Yes' : 'No'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.memberAsRefererId ? data?.memberAsRefererId : '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.savedDate == null
            ? '-'
            : moment(data?.savedDate).format('DD-MM-YYYY')}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Button
            onClick={() =>
              navigate(`/${adminRoute}/editMerchantRequest/${data?.id}`, {
                state: { step: data?.registrationCompletedStep },
              })
            }
          >
            Edit and Complete
          </Button>

          <Tooltip placement="top" title="Send Merchant Email">
            <IconButton
              onClick={() => {
                setForwardDialogOpen(true)
                setSelectedMerchant(data)
              }}
            >
              <ForwardToInboxIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Tooltip>
        </StyledTableCell>
      </TableRow>
    </>
  )
}

export default TableItem
