import { axiosRequest } from 'utils'

export const resetGlobalAdminPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/globalAdmin/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetRegionOwnerPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/regionOwner/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetCountryOwnerPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/countryOwner/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetStateOwnerPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/stateOwner/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetAreaOwnerPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/areaOwner/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetWhiteLabelPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/whiteLabelUser/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetMerchantPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/merchant/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetMerchantFranchisePassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/merchantFranchise/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetClubPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/club/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetCharityPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/charity/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetGmcAdminPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/gmcAdmin/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetSalesAgentPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/salesAgent/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetCollaborationPartnerPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/collaborationPartner/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetWebsiteAdminPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password/websiteAdmin/req`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const resetPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/reset_password`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const checkResetTokenValidity = (params) => {
  return () =>
    axiosRequest
      .post(`/api/auth/check/reset_token/validity`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
