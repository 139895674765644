/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { closeAlert } from 'store/alert/alertSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  alert: state.alert,
})

const mapDispatchToProps = (dispatch) => ({
  onCloseAlert: () => dispatch(closeAlert()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
