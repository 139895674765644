import { axiosRequestCMS as axiosRequest } from 'utils'

export const fetchWebSliderList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/slider/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWebSliderById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/slider/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createWebSlider = (params) => {
  return () =>
    axiosRequest
      .post(`/api/slider/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteWebSlider = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/slider/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWebSlider = (id, params) => {
  return () =>
    axiosRequest
      .put(
        `/api/slider/edit/${id}?isTranslateToAlllang=${params.isTranslateToAlllang}`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createHomeVideo = (params) => {
  return () =>
    axiosRequest
      .post(`/api/websiteVideo/videoInfo/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllHomeVideo = (params) => {
  return () =>
    axiosRequest
      .get(`/api/websiteVideo/videoInfo/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editHomeVideo = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/websiteVideo/videoInfo/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllFileRepository = (params) => {
  return () =>
    axiosRequest
      .get(`/api/fileRepository/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createFileRepository = (params) => {
  return () =>
    axiosRequest
      .post(`/api/fileRepository/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFileRepositoryById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/fileRepository/getone/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editFileRepository = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/fileRepository/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
