import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchStateStart,
  fetchStateSuccess,
  fetchStateFailure,
  createStateSuccess,
  createStateFailure,
  deleteStateSuccess,
  deleteStateFailure,
  fetchStateByIdSuccess,
  fetchStateByIdFailure,
  editStateSuccess,
  editStateFailure,
} from './stateSetupSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchStateList,
  createState,
  deleteState,
  fetchStateById,
  editState,
} from '../../api/state'

import _ from 'lodash'

export function* fetchStateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchStateList(payload))

    yield put(fetchStateSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchStateFailure(err))
  }
}

export function* fetchStateByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchStateById(payload))

    yield put(fetchStateByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchStateByIdFailure(err))
  }
}

export function* editStateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editState(payload.params.id, payload.params))
    yield put(editStateSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      fetchStateStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({ message: 'State successfully edited', severity: 'success' })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editStateFailure(err))
    console.error(err)
  }
}

export function* createStateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createState(payload.params))

    yield put(createStateSuccess(data?.data))
    yield put(
      fetchStateStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({ message: 'State created successfully', severity: 'success' })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createStateFailure(err))
    console.error(err)
  }
}

export function* deleteStateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteState(payload))

    if (data) {
      yield put(deleteStateSuccess(payload))
      yield put(
        fetchStateStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'State successfully deleted',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteStateFailure(err))
  }
}

export function* watchFetchState() {
  yield takeLatest('stateSetup/fetchStateStart', fetchStateAsync)
}

export function* watchCreateState() {
  yield takeLatest('stateSetup/createStateStart', createStateAsync)
}

export function* watchDeleteState() {
  yield takeLatest('stateSetup/deleteStateStart', deleteStateAsync)
}

export function* watchFetchStateById() {
  yield takeLatest('stateSetup/fetchStateByIdStart', fetchStateByIdAsync)
}

export function* watchEditState() {
  yield takeLatest('stateSetup/editStateStart', editStateAsync)
}

export function* stateSetupSagas() {
  yield all([
    call(watchFetchState),
    call(watchCreateState),
    call(watchDeleteState),
    call(watchFetchStateById),
    call(watchEditState),
  ])
}
