import { axiosRequestCMS } from 'utils'

export const fetchPartnerList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/partner/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPartnerById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/partner/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPartner = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/partner/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deletePartner = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/partner/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editPartner = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/partner/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
