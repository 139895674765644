import { takeLatest, call, all, put, select } from 'redux-saga/effects'
import {
  fetchSalesAgentStart,
  fetchSalesAgentSuccess,
  fetchSalesAgentFailure,
  deleteSalesAgentSuccess,
  deleteSalesAgentFailure,
  fetchSalesAgentByIdSuccess,
  fetchSalesAgentByIdFailure,
  createSalesAgentSuccess,
  createSalesAgentFailure,
  editSalesAgentSuccess,
  editSalesAgentFailure,
  fetchPendingSalesAgentStart,
  fetchPendingSalesAgentSuccess,
  fetchPendingSalesAgentFailure,
  deletePendingSalesAgentStart,
  deletePendingSalesAgentSuccess,
  deletePendingSalesAgentFailure,
  verifySalesAgentSuccess,
  verifySalesAgentFailure,
  rejectSalesAgentSuccess,
  rejectSalesAgentFailure,
  updatePendingSalesAgentFailure,
  updatePendingSalesAgentSuccess,
  editSalesAgentOwnerOwnProfileInfoSuccess,
  editSalesAgentOwnerOwnProfileInfoFailure,
  generateConnectAccountLinkSuccess,
  generateConnectAccountLinkFailure,
  createStripePayoutAccountFailure,
  createStripePayoutAccountSuccess,
  stripeCheckOnboardStatusSuccess,
  stripeCheckOnboardStatusFailure,
  stripeLoginUrlFailure,
  stripeLoginUrlSuccess,
} from './salesAgentSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchSalesAgentList,
  createSalesAgent,
  fetchSalesAgentById,
  editSalesAgent,
  rejectSalesAgent,
  verifySalesAgent,
  deleteSalesAgent,
  saveUpdatePendingSalesAgent,
  editSalesAgentOwnProfile,
  stripeGenerateConnectAccountLink,
  createStripePayoutAccount,
  stripeCheckOnboardStatus,
  stripeLoginUrl,
} from '../../api/salesAgent'

import _ from 'lodash'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchPendingSalesAgentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSalesAgentList(payload))

    yield put(fetchPendingSalesAgentSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchPendingSalesAgentFailure(err))
    console.error(err)
  }
}

export function* fetchSalesAgentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSalesAgentList(payload))

    yield put(fetchSalesAgentSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchSalesAgentFailure(err))
    console.error(err)
  }
}

export function* createSalesAgentAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    const { data } = yield call(createSalesAgent(payload.params))
    yield put(createSalesAgentSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      openAlert({ message: 'succesfully created', severity: 'success' })
    )
    yield put(
      fetchSalesAgentStart({
        page: 1,
        limit: 10,
        offset: 0,
        isPending: false,
        salesAgentType: 'salesAgent',
        countryId: siteCoordinator?.globalCountry?.id,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createSalesAgentFailure(err))

    console.error(err)
  }
}

export function* deleteSalesAgentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      deleteSalesAgent(payload?.id, { rejectReason: payload?.rejectReason })
    )

    if (data) {
      yield put(deleteSalesAgentSuccess(payload?.id))
      yield payload.closeDeleteModal()
      yield put(
        openAlert({
          message: 'Successfully deleted',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteSalesAgentFailure(err))
  }
}

export function* deletePendingSalesAgentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      rejectSalesAgent(payload?.id, { rejectReason: payload?.rejectReason })
    )

    if (data) {
      yield put(deleteSalesAgentSuccess(payload?.id))
      yield payload.closeDeleteModal()
      yield put(
        openAlert({
          message: 'Rejected Region Licensee',
          severity: 'error',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteSalesAgentFailure(err))
    console.error(err)
  }
}

export function* fetchSalesAgentByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSalesAgentById(payload))

    yield put(
      fetchSalesAgentByIdSuccess({
        ...data?.data,
        issuerCode: data?.issuerCode,
        issuerCodeActive: data?.issuerCodeActive ?? null,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchSalesAgentByIdFailure(err))
    console.error(err)
  }
}

export function* editSalesAgentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editSalesAgent(payload?.params?.id, payload?.params)
    )
    yield put(editSalesAgentSuccess())
    yield payload?.closeModal()

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Sales Agent Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editSalesAgentFailure(err))
    console.error(err)
  }
}

export function* verifySalesAgentAsync({ payload }: AnyAction) {
  let siteCoordinator = yield select(getSiteCoordinator)

  try {
    const { data } = yield call(
      verifySalesAgent(payload?.params?.id, payload?.params)
    )

    yield put(verifySalesAgentSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchPendingSalesAgentStart({
          page: 1,
          limit: 10,
          offset: 0,
          isPending: true,
          salesAgentType: 'salesAgent',
          countryId: siteCoordinator?.globalCountry?.id,
        })
      )
    }
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Sales Agent Successfully Verified',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(verifySalesAgentFailure(err))
    console.error(err)
  }
}

export function* rejectSalesAgentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(rejectSalesAgent(payload?.id, payload))

    yield put(rejectSalesAgentSuccess(payload?.id))
    yield payload.closeModal()

    yield put(
      openAlert({ message: 'succesfully rejected', severity: 'success' })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(rejectSalesAgentFailure(err))
    console.error(err)
  }
}

export function* updatePendingSalesAgentAsync({ payload }: AnyAction) {
  let siteCoordinator = yield select(getSiteCoordinator)

  try {
    const { data } = yield call(
      saveUpdatePendingSalesAgent(payload?.params?.id, payload?.params)
    )
    // console.log('updatePendingSalesAgentAsync>>>', data?.updateResult)
    yield put(updatePendingSalesAgentSuccess(data?.data))
    yield payload?.closeModal()
    yield put(
      openAlert({
        message: 'Sales Agent Request Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(updatePendingSalesAgentFailure(err))
    console.error(err)
  }
}

export function* editSalesAgentOwnerOwnProfileInfoAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(editSalesAgentOwnProfile(payload?.params))

    yield put(editSalesAgentOwnerOwnProfileInfoSuccess(data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Sales Agent Profile Successfully Edited',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editSalesAgentOwnerOwnProfileInfoFailure(err))
    console.error(err)
  }
}

export function* createStripePayoutAccountAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createStripePayoutAccount(payload.params))
    yield put(createStripePayoutAccountSuccess(data))
    if (payload?.handleNext) {
      yield payload?.handleNext()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createStripePayoutAccountFailure(err))

    console.error(err)
  }
}

export function* generateConnectAccountLinkAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      stripeGenerateConnectAccountLink(payload?.params)
    )

    if (data?.accountLinkURL) {
      window.open(data?.accountLinkURL, '_blank')
    }
    if (payload?.handleNext && _.isEmpty(data)) {
      yield payload?.handleNext()
    }
    yield put(generateConnectAccountLinkSuccess(data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Account Link Successfully Generated',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(generateConnectAccountLinkFailure(err))
    console.error(err)
  }
}

export function* stripeCheckOnboardStatusAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(stripeCheckOnboardStatus(payload.params))
    yield put(stripeCheckOnboardStatusSuccess(data))
    if (payload?.handleNext) {
      yield payload?.handleNext()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(stripeCheckOnboardStatusFailure(err))

    console.error(err)
  }
}

export function* stripeLoginUrlAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(stripeLoginUrl(payload.params))

    if (data?.stripeLoginLink) {
      window.open(data?.stripeLoginLink, '_blank')
    }

    yield put(stripeLoginUrlSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(stripeLoginUrlFailure(err))

    console.error(err)
  }
}

export function* watchPendingSalesAgent() {
  yield takeLatest(
    'salesAgent/fetchPendingSalesAgentStart',
    fetchPendingSalesAgentAsync
  )
}

export function* watchFetchSalesAgent() {
  yield takeLatest('salesAgent/fetchSalesAgentStart', fetchSalesAgentAsync)
}

export function* watchCreateSalesAgent() {
  yield takeLatest('salesAgent/createSalesAgentStart', createSalesAgentAsync)
}

export function* watchVerifySalesAgent() {
  yield takeLatest('salesAgent/verifySalesAgentStart', verifySalesAgentAsync)
}

export function* watchUpdatePendingSalesAgent() {
  yield takeLatest(
    'salesAgent/updatePendingSalesAgentStart',
    updatePendingSalesAgentAsync
  )
}

export function* watchDeletePendingSalesAgent() {
  yield takeLatest(
    'salesAgent/deletePendingSalesAgentStart',
    deletePendingSalesAgentAsync
  )
}

export function* watchDeleteSalesAgent() {
  yield takeLatest('salesAgent/deleteSalesAgentStart', deleteSalesAgentAsync)
}

export function* watchFetchPendingSalesAgentById() {
  yield takeLatest(
    'salesAgent/fetchSalesAgentByIdStart',
    fetchSalesAgentByIdAsync
  )
}

export function* watchEditSalesAgent() {
  yield takeLatest('salesAgent/editSalesAgentStart', editSalesAgentAsync)
}

export function* watchRejectSalesAgent() {
  yield takeLatest('salesAgent/rejectSalesAgentStart', rejectSalesAgentAsync)
}

export function* watchEditSalesAgentOwnerOwnProfileInfoAsync() {
  yield takeLatest(
    'salesAgent/editSalesAgentOwnerOwnProfileInfoStart',
    editSalesAgentOwnerOwnProfileInfoAsync
  )
}

export function* watchGenerateConnectAccountLinkAsync() {
  yield takeLatest(
    'salesAgent/generateConnectAccountLinkStart',
    generateConnectAccountLinkAsync
  )
}

export function* watchCreateStripePayoutAccountAsync() {
  yield takeLatest(
    'salesAgent/createStripePayoutAccountStart',
    createStripePayoutAccountAsync
  )
}

export function* watchStripeCheckOnboardStatus() {
  yield takeLatest(
    'salesAgent/stripeCheckOnboardStatusStart',
    stripeCheckOnboardStatusAsync
  )
}

export function* watchStripeLoginUrlAsync() {
  yield takeLatest('salesAgent/stripeLoginUrlStart', stripeLoginUrlAsync)
}

export function* salesAgentSagas() {
  yield all([
    call(watchPendingSalesAgent),
    call(watchFetchSalesAgent),
    call(watchCreateSalesAgent),
    call(watchDeletePendingSalesAgent),
    call(watchFetchPendingSalesAgentById),
    call(watchEditSalesAgent),
    call(watchVerifySalesAgent),
    call(watchUpdatePendingSalesAgent),
    call(watchDeleteSalesAgent),
    call(watchRejectSalesAgent),
    call(watchEditSalesAgentOwnerOwnProfileInfoAsync),
    call(watchCreateStripePayoutAccountAsync),
    call(watchGenerateConnectAccountLinkAsync),
    call(watchStripeCheckOnboardStatus),
    call(watchStripeLoginUrlAsync),
    // call(watchEditCountry),
  ])
}
