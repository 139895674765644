import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableHeader from '@crema/core/AppTable/TableHeader'

const TableHeading = ({ currentUser }) => {
  return (
    <TableHeader>
      <TableCell align="left">SN</TableCell>
      <TableCell align="left">Country</TableCell>
      <TableCell align="left">Role</TableCell>
      <TableCell align="left">ID</TableCell>
      <TableCell align="left">Email</TableCell>
      <TableCell align="left">User Name</TableCell>
      {currentUser?.role?.toLowerCase() !== 'countryowner' && (
        <>
          <TableCell align="left">Creator Role</TableCell>
          <TableCell align="left">Creator Name</TableCell>
        </>
      )}
      <TableCell align="left">Password Created Date</TableCell>
      <TableCell align="left">Password Expiry Date</TableCell>
      <TableCell align="left">Password</TableCell>

      <TableCell align="left">Actions</TableCell>
    </TableHeader>
  )
}

export default TableHeading
