import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography, CircularProgress } from '@mui/material'

import container from './Images.container'

import _ from 'lodash'

import * as yup from 'yup'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onCreateClubStart?: (params) => void
  onCreateStep3DraftStart?: (params) => void
  onCreateStep3GeneralStart?: (params) => void
  onFetchMerchantGeneralImagePanelUserStart?: (params) => void
  onOpenAlert?: (params) => void
  handleChangeTab?: any
  siteCoordinator?: any
  club?: any
  auth?: any
  merchant?: any
  merchantId?: number
  adminRoute?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  onCloseAddCountry,

  merchantId,
  merchant: { generalImageInfo, isFetching },
  onFetchMerchantGeneralImagePanelUserStart,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [logoImage, setLogoImage] = useState(null)

  const [tempLoader, setTempLoader] = useState(true)
  const [isSliderImagesEmpty, setIsSliderImagesEmpty] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  useEffect(() => {
    onFetchMerchantGeneralImagePanelUserStart(merchantId)
  }, [merchantId])

  useEffect(() => {
    if (!_.isEmpty(generalImageInfo)) {
      setTimeout(() => {
        setUploadedFiles([
          generalImageInfo?.slider1 && {
            preview: generalImageInfo?.slider1,
            type: 'image/png',
          },
          generalImageInfo?.slider2 && {
            preview: generalImageInfo?.slider2,
            type: 'image/png',
          },
          generalImageInfo?.slider3 && {
            preview: generalImageInfo?.slider3,
            type: 'image/png',
          },
          generalImageInfo?.slider4 && {
            preview: generalImageInfo?.slider4,
            type: 'image/png',
          },
          generalImageInfo?.slider5 && {
            preview: generalImageInfo?.slider5,
            type: 'image/png',
          },
          generalImageInfo?.slider6 && {
            preview: generalImageInfo?.slider6,
            type: 'image/png',
          },
        ])

        setLogoImage({
          preview: generalImageInfo?.logoUrl,

          type: 'image/png',
        })
      }, 2000)
    }
  }, [generalImageInfo, merchantId])

  const closeModal = () => {
    onCloseAddCountry()
  }

  useEffect(() => {
    if (!_.isEmpty(generalImageInfo)) {
      const sliderImagesEmpty = uploadedFiles.every(
        (element) => element === null
      )
      setIsSliderImagesEmpty(sliderImagesEmpty)
    }
  }, [generalImageInfo, merchantId])

  return (
    <>
      {tempLoader || isFetching ? (
        <CircularProgress size={16} />
      ) : (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={4}>
            <Grid item container>
              {tempLoader || isFetching ? (
                <CircularProgress size={16} />
              ) : (
                <>
                  {uploadedFiles?.length && !isSliderImagesEmpty ? (
                    <Grid item xs={12}>
                      <Typography variant="h4">Images</Typography>
                    </Grid>
                  ) : (
                    ''
                  )}

                  {uploadedFiles?.length && !isSliderImagesEmpty ? (
                    uploadedFiles?.map((item, ind) => {
                      return (
                        item?.preview && (
                          <>
                            <Grid item xs={12} lg={4} sx={{ mt: 3 }}>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'inline-block',
                                  borderRadius: 2,
                                  border: '1px solid #eaeaea',
                                  marginBottom: 8,
                                  marginRight: 8,
                                  width: 200,
                                  height: 200,
                                  padding: 1,
                                  boxSizing: 'border-box',
                                  overflow: 'hidden',
                                  '& img': {
                                    display: 'block',
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                  },
                                }}
                              >
                                <img alt="preview" src={item?.preview} />
                              </Box>
                            </Grid>
                          </>
                        )
                      )
                    })
                  ) : (
                    <Typography variant="body2">Images not found!</Typography>
                  )}
                </>
              )}
            </Grid>

            {generalImageInfo?.logoUrl ? (
              <Grid item container>
                <div>
                  <Typography variant="h4" sx={{ mb: 3 }}>
                    Logo
                  </Typography>
                  {logoImage?.preview && logoImage?.preview !== 'undefined' && (
                    <div>
                      <img
                        alt="not found"
                        width={'250px'}
                        src={
                          logoImage?.preview ||
                          (!_.isEmpty(logoImage)
                            ? URL.createObjectURL(logoImage)
                            : '')
                        }
                      />
                    </div>
                  )}
                </div>
              </Grid>
            ) : (
              <Typography variant="body2" ml={4} mt={5}>
                Logo not found!
              </Typography>
            )}
          </Grid>

          <Box sx={{ height: 20 }} />
          <Box sx={{ height: 30 }} />
        </Box>
      )}
    </>
  )
}

export default container(AddNewClub)
