// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchAreaOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/areaOwner/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createAreaOwner = (params) => {
  return () =>
    axiosRequest
      .post(`/api/areaOwner/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteAreaOwner = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/areaOwner/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAreaOwnerById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/areaOwner/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectAreaOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/areaOwner/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editAreaOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/areaOwner/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectPendingAreaOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/areaOwner/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editPendingAreaOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/areaOwner/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyPendingAreaOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/areaOwner/updateAndVerifyEOI/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveUpdatePendingAreaOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/areaOwner/saveUpdate/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteAreaOwnerContact = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/areaOwner/deleteAreaOwnerContact/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAreaOwnerProfileInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/areaOwner/getOwnProfile`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createAreaOwnerAgreement = (id, params) => {
  return () =>
    axiosRequest
      .patch(`/api/areaOwner/saveAgreement/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editAreaOwnerOwnProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/areaOwner/editOwnProfile/`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
