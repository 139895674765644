import { createSlice } from '@reduxjs/toolkit'

import { ICountrySetupState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: ICountrySetupState = {
  countryList: listObj,
  currencyList: listObj,
  soleCountry: {},
  soleCurrency: {},
  activateStatus: null,
  error: null,
  loading: false,
  isCreating: false,
  isDeleting: false,
  isEditing: false,
  isFetching: false,
  hasMoreData: false,
  countryStripeKeyInfo: null,
}

export const countrySetupSlice = createSlice({
  name: 'countrySetup',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCountryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountrySuccess: (state: any, action) => {
      state.countryList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountryByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryByIdSuccess: (state: any, action) => {
      state.soleCountry = action.payload.data
      state.loading = false
    },
    fetchCountryByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createCountryStart: (state: any, action) => {
      state.loading = true
    },
    createCountrySuccess: (state: any, action) => {
      state.loading = false
      state.countryList.data = [...state.countryList.data, action.payload]
    },
    createCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteCountryStart: (state: any, action) => {
      state.loading = true
    },
    deleteCountrySuccess: (state: any, action) => {
      state.loading = false
      state.countryList.data = state.countryList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editCountryStart: (state: any, action) => {
      state.loading = true
    },
    editCountrySuccess: (state: any, action) => {
      const result = state.countryList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.countryList.data = result

      state.loading = false
    },
    editCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    activateCountryStart: (state: any, action) => {
      state.loading = true
    },
    activateCountrySuccess: (state: any, action) => {
      state.loading = false
      // state.countryList.data = state.countryList?.data.filter(
      //   (item) => item.id !== action.payload
      // )
    },
    activateCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchActivateCountryStatusStart: (state: any, action) => {
      state.loading = true
    },
    fetchActivateCountryStatusSuccess: (state: any, action) => {
      state.loading = false
      state.activateStatus = action.payload
    },
    fetchActivateCountryStatusFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryStripeKeyStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchConstantStripeKeyStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCountryStripeKeySuccess: (state: any, action) => {
      state.isFetching = false
      state.countryStripeKeyInfo = action.payload
    },
    fetchCountryStripeKeyFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchAllCurrencyStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllCurrencySuccess: (state: any, action) => {
      state.currencyList = action.payload
      state.loading = false
    },
    fetchAllCurrencyFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createCurrencyStart: (state: any, action) => {
      state.isCreating = true
    },
    createCurrencySuccess: (state: any, action) => {
      state.isCreating = false
    },
    createCurrencyFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchCurrencyByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCurrencyByIdSuccess: (state: any, action) => {
      state.soleCurrency = action.payload
      state.isFetching = false
    },
    fetchCurrencyByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editCurrencyStart: (state: any, action) => {
      state.isEditing = true
    },
    editCurrencySuccess: (state: any, action) => {
      state.isEditing = false
    },

    editCurrencyFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    deleteCurrencyStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteCurrencySuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteCurrencyFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    clearCountryStripeKey: (state: any) => {
      state.countryStripeKeyInfo = null
    },
  },
})

export const {
  fetchCountryStart,
  fetchCountrySuccess,
  fetchCountryFailure,
  createCountryStart,
  createCountrySuccess,
  createCountryFailure,
  deleteCountryStart,
  deleteCountrySuccess,
  deleteCountryFailure,
  fetchCountryByIdStart,
  fetchCountryByIdSuccess,
  fetchCountryByIdFailure,
  editCountryStart,
  editCountrySuccess,
  editCountryFailure,
  fetchActivateCountryStatusFailure,
  fetchActivateCountryStatusStart,
  fetchActivateCountryStatusSuccess,
  activateCountryFailure,
  activateCountryStart,
  activateCountrySuccess,

  fetchCountryStripeKeyStart,
  fetchCountryStripeKeySuccess,
  fetchCountryStripeKeyFailure,

  fetchAllCurrencyStart,
  fetchAllCurrencySuccess,
  fetchAllCurrencyFailure,

  createCurrencyStart,
  createCurrencySuccess,
  createCurrencyFailure,

  editCurrencyStart,
  editCurrencySuccess,
  editCurrencyFailure,

  deleteCurrencyStart,
  deleteCurrencySuccess,
  deleteCurrencyFailure,

  fetchCurrencyByIdStart,
  fetchCurrencyByIdSuccess,
  fetchCurrencyByIdFailure,

  clearCountryStripeKey,
} = countrySetupSlice.actions

export default countrySetupSlice.reducer
