import React from 'react'
import { Box, Button, Grid, CircularProgress, Typography } from '@mui/material'

import container from './ForwardDialogForm.container'
import { Form, Formik } from 'formik'

import * as yup from 'yup'
import _ from 'lodash'

interface EditProps {
  isAddCountryOpen: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry: () => void
  onCreateStateOwnerStart?: (params) => void
  onFetchClubByIdStart: (params) => void
  onEditClubStart: (params) => void
  onSetMerchantPopularStart: (params) => void
  onReferMerchantMailFromDraftStart: (params) => void
  onCloseEditDialog?: () => void
  onDeny?: () => void
  closeMarkAsPopularModal?: () => void
  refreshPageData?: () => void
  selectedStateOwnerId: number
  club: any
  selectedMerchantObj?: any
}

const AddNewCountry: React.FC<EditProps & any> = ({
  closeMarkAsPopularModal,
  selectedStateOwnerId,
  club: { loading },
  merchant: { isReferring },
  refreshPageData,
  selectedMerchantObj,
  onReferMerchantMailFromDraftStart,
}) => {
  const validationSchema = yup.object({})

  return (
    <>
      {loading ? (
        '...isLoading'
      ) : (
        <Formik
          validateOnChange={true}
          initialValues={{
            popularOrder: selectedMerchantObj?.popularOrder ?? 0,
            isPopularFlag: true,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true)

            onReferMerchantMailFromDraftStart({
              params: {
                ...data,
                id: selectedStateOwnerId,
              },
              closeModal: closeMarkAsPopularModal,
              refreshPageData,
            })

            setSubmitting(false)
          }}
          render={({ values, setFieldValue, handleBlur, handleChange }) => (
            <Form>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} sx={{ mt: 3 }}>
                  <Grid item xs={12}>
                    <Typography variant="h4" align="center">
                      Are you sure you want email merchant form to this
                      merchant?
                    </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ height: 20 }} />
                <Box sx={{ height: 30 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isReferring}
                  >
                    {isReferring ? <CircularProgress size={20} /> : 'Ok'}
                  </Button>
                  &nbsp; &nbsp;
                  <Button onClick={closeMarkAsPopularModal} variant="outlined">
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        />
      )}
    </>
  )
}

export default container(AddNewCountry)
