import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  Typography,
  Card,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
} from '@mui/material'
import AppDialog from 'components/DialogCustom'
import AppComponentCard from '@crema/core/AppComponentCard'
import container from './GenerateTemporaryPasswordDialog.container'
import { Form, Formik, Field } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import CountrySelect from 'components/DropdownComponents/CountrySelect'
import * as yup from 'yup'
import RichTextEditor from 'components/RichTextEditor'
import RoleSelect from 'components/DropdownComponents/AssignCodesRoleSelect'
import { removeEmptyFields } from 'utils'
import UserCard from './UserCard'
import { useDispatch } from 'react-redux'
import { fetchAssignerSuccess } from 'store/qrCodes/qrCodesSlice'
import { useParams } from 'react-router-dom'

interface AddPageProps {
  isAddPageOpen: boolean
  onCloseAddPage: () => void
  refreshPageData?: any
  onCreatePageStart: (params) => void
  onOpenAlert: (params) => void
  siteCoordinator: any
  qrCodes: any
  batchId?: number
  codeType: string
  auth?: any
}

const AssignDialog: React.FC<AddPageProps> = ({
  isAddPageOpen,
  onCloseAddPage,
  onCreatePageStart,
  qrCodes,
  codeType,
  refreshPageData,
  auth: { currentUser },
}) => {
  const validationSchema = yup.object({})
  const [searchClicked, setSearchClicked] = useState(false)

  const [roleData, setRoleData] = useState<any>('')

  const closeModal = () => {
    onCloseAddPage()
  }

  const roleOptions =
    currentUser?.role?.toLowerCase() === 'countryowner'
      ? [
          { label: 'State Licensee', value: 'stateOwner' },
          { label: 'Region Licensee', value: 'regionOwner' },
          { label: 'Area Licensee', value: 'areaOwner' },
          { label: 'Collaboration Partner', value: 'collaborationPartner' },
          { label: 'Sales Agent', value: 'salesAgent' },
          // { label: 'Merchant', value: 'merchant' },
          { label: 'Charity', value: 'charity' },
          { label: 'Club', value: 'club' },
          { label: 'White Label User', value: 'whiteLabelUser' },

          // { label: 'member', value: 'member' },
          // { label: 'globalAdmin', value: 'globalAdmin' },
          { label: 'Merchant', value: 'merchant' },
        ]
      : [
          { label: 'Country Licensee', value: 'countryOwner' },
          { label: 'State Licensee', value: 'stateOwner' },
          { label: 'Region Licensee', value: 'regionOwner' },
          { label: 'Area Licensee', value: 'areaOwner' },
          { label: 'Collaboration Partner', value: 'collaborationPartner' },
          { label: 'Sales Agent', value: 'salesAgent' },
          // { label: 'Merchant', value: 'merchant' },
          { label: 'Charity', value: 'charity' },
          { label: 'Club', value: 'club' },
          { label: 'White Label User', value: 'whiteLabelUser' },

          // { label: 'member', value: 'member' },
          // { label: 'globalAdmin', value: 'globalAdmin' },
          { label: 'Merchant', value: 'merchant' },
        ]
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAssignerSuccess({ data: [] }))
  }, [])

  return (
    <>
      <AppDialog
        dividers
        maxWidth="md"
        open={isAddPageOpen}
        onClose={() => onCloseAddPage()}
        title="Generate Temporary Password"
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            email: '',
            username: '',
            role: null,
            usedDate__between: '',
            countryId: null,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true)

            let formValues = removeEmptyFields({
              ...data,
              role: data?.role?.value,
              countryId:
                // auth?.currentUser?.userCountry?.countryId ||
                data?.countryId?.id,

              username:
                data?.role?.value === 'collaborationPartner' ||
                data?.role?.value === 'salesAgent' ||
                data?.role?.value === 'whiteLabelUser' ||
                data?.role?.value === 'merchant'
                  ? data?.username
                  : '',
            })
            if (
              roleData === 'collaborationPartner' ||
              roleData === 'salesAgent'
            ) {
              formValues.salesAgentType = roleData
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onCreatePageStart({
              params: formValues,
              closeModal,
            }),
              setSearchClicked(true)
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, values, setFieldValue, resetForm }) => {
            setRoleData(values?.role?.value)
            return (
              <Form>
                <Box sx={{ padding: 6 }}>
                  <Grid container spacing={5}>
                    {currentUser?.role?.toLowerCase() !== 'countryowner' && (
                      <Grid item xs={12} md={4}>
                        <Field
                          name="countryId"
                          label="Select Country"
                          required
                          component={CountrySelect}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                      <Field
                        name="role"
                        component={RoleSelect}
                        statusOption={roleOptions}
                        required
                        label="Select Role"
                      />
                    </Grid>

                    {values?.role?.value === 'collaborationPartner' ||
                    values?.role?.value === 'salesAgent' ||
                    values?.role?.value === 'whiteLabelUser' ||
                    values?.role?.value === 'merchant' ? (
                      <Grid item xs={12} md={4}>
                        <AppTextField
                          label="Username"
                          name="username"
                          required
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={4}>
                        <AppTextField
                          label="Email"
                          name="email"
                          required
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          sx={{
                            color: '#ffffff',
                            backgroundColor: '#ec4785',
                            '&:hover': {
                              background: '#ec4785',
                            },
                            px: 5,
                          }}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Search
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                          onClick={() => {
                            resetForm()
                            dispatch(fetchAssignerSuccess({ data: [] }))
                            setSearchClicked(false)
                          }}
                          variant="outlined"
                        >
                          Clear
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 8 }}>
                    {qrCodes?.assignerList?.data?.length !== 0 ? (
                      <Typography>Search Results </Typography>
                    ) : (
                      searchClicked && (
                        <Typography textAlign="center">
                          No results found
                        </Typography>
                      )
                    )}
                    {qrCodes?.assignerList?.data?.map((user, ind) => {
                      return (
                        <UserCard
                          user={user}
                          selectedRole={values?.role?.value}
                          closeModal={closeModal}
                          codeType={codeType}
                          refreshPageData={refreshPageData}
                        />
                      )
                    })}
                  </Box>

                  {/* <Box>
                {qrCodes?.assignerList?.data?.map((user, ind) => {
                  return <Box>{JSON.stringify(user, null, 2)}</Box>
                })}
              </Box> */}

                  <Box sx={{ height: 50 }} />
                </Box>
              </Form>
            )
          }}
        </Formik>
        <Box sx={{ height: 200 }} />
      </AppDialog>
    </>
  )
}

export default container(AssignDialog)
