import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchCountryWisePrefixStart,
  fetchCountryWisePrefixSuccess,
  fetchCountryWisePrefixFailure,
  createCountryWisePrefixStart,
  createCountryWisePrefixSuccess,
  createCountryWisePrefixFailure,
  editCountryWisePrefixStart,
  editCountryWisePrefixSuccess,
  editCountryWisePrefixFailure,
  fetchCountryWisePrefixByIdStart,
  fetchCountryWisePrefixByIdSuccess,
  fetchCountryWisePrefixByIdFailure,
  deleteCountryWisePrefixStart,
  deleteCountryWisePrefixSuccess,
  deleteCountryWisePrefixFailure,
} from './countryWisePrefixSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchAllCountryPrefix,
  createCountryWisePrefix,
  editCountryWisePrefix,
  fetchCountryWisePrefixId,
  deleteCountryWisePrefix,
} from '../../api/countryPrefix'

import _ from 'lodash'
import { deleteImage, getImageUrl, uploadImage } from 'api/imageUpload'
import { getImageIdFromUrl, removeParam } from 'utils'

export function* fetchCountryWisePrefixAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllCountryPrefix(payload))

    yield put(fetchCountryWisePrefixSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCountryWisePrefixFailure(err))
  }
}

export function* fetchCountryWisePrefixByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryWisePrefixId(payload))

    yield put(fetchCountryWisePrefixByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCountryWisePrefixByIdFailure(err))
  }
}

export function* editCountryWisePrefixAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        editCountryWisePrefix(payload.params.id, {
          ...payload.params,
          logoUrl: imgUrl,
        })
      )
      yield call(deleteImage(getImageIdFromUrl(payload.oldImage)))

      yield put(editCountryWisePrefixSuccess(data?.data))
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, imageUrl: '' }
      const { data } = yield call(
        editCountryWisePrefix(payload.params.id, uploadData)
      )
      yield put(editCountryWisePrefixSuccess(data?.data))
    }

    if (payload?.closeModal) {
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Country prefix succesfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editCountryWisePrefixFailure(err))
  }
}

export function* createCountryWisePrefixAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.imageUrl)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.imageUrl)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createCountryWisePrefix({ ...payload.params, logoUrl: imgUrl })
      )
      yield put(createCountryWisePrefixSuccess(data?.data))
    } else {
      const { data } = yield call(
        createCountryWisePrefix({ ...payload?.params })
      )

      yield put(createCountryWisePrefixSuccess(data?.data))
    }

    yield put(
      fetchCountryWisePrefixStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Country prefix succesfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createCountryWisePrefixFailure(err))
  }
}

export function* deleteCountryWisePrefixAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteCountryWisePrefix(payload.id))

    if (data) {
      yield put(deleteCountryWisePrefixSuccess(payload.id))
      yield put(
        fetchCountryWisePrefixStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Country prefix deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteCountryWisePrefixFailure(err))
  }
}

export function* watchFetchCountryWisePrefix() {
  yield takeLatest(
    'countryWisePrefix/fetchCountryWisePrefixStart',
    fetchCountryWisePrefixAsync
  )
}

export function* watchCreateCountryWisePrefix() {
  yield takeLatest(
    'countryWisePrefix/createCountryWisePrefixStart',
    createCountryWisePrefixAsync
  )
}
export function* watchDeleteCountryWisePrefix() {
  yield takeLatest(
    'countryWisePrefix/deleteCountryWisePrefixStart',
    deleteCountryWisePrefixAsync
  )
}

export function* watchFetchCountryWisePrefixById() {
  yield takeLatest(
    'countryWisePrefix/fetchCountryWisePrefixByIdStart',
    fetchCountryWisePrefixByIdAsync
  )
}

export function* watchEditCountryWisePrefix() {
  yield takeLatest(
    'countryWisePrefix/editCountryWisePrefixStart',
    editCountryWisePrefixAsync
  )
}

export function* countryWisePrefixSagas() {
  yield all([
    call(watchFetchCountryWisePrefix),
    call(watchCreateCountryWisePrefix),
    call(watchDeleteCountryWisePrefix),
    call(watchEditCountryWisePrefix),
    call(watchFetchCountryWisePrefixById),
  ])
}
