import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, Grid, CircularProgress } from '@mui/material'

import AppDialog from '@crema/core/AppDialog'
import container from './AddTerminal.container'
import { Form, Formik, Field, FieldArray } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'

import * as yup from 'yup'

const countries = [
  { label: 'Company', year: 1994 },
  { label: 'Sole Proprietor', year: 1972 },
  { label: 'France', year: 1974 },
]
interface AddNewCountryProps {
  isAddCountryOpen: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry: () => void
  refreshPageData?: any
  onCreateClubStart: (params) => void
  onCreateTerminalStart?: (params) => void
  siteCoordinator: any
  club: any
  auth: any
  merchant?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  isAddCountryOpen,
  onOpenCountryTask,
  onCloseAddCountry,
  onCreateClubStart,
  onCreateTerminalStart,
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  merchant: { isCreating },
  refreshPageData,
}) => {
  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  const closeModal = () => {
    onCloseAddCountry()
  }
  const validationSchema = yup.object({})

  return (
    <AppDialog
      dividers
      maxWidth="xs"
      open={isAddCountryOpen}
      onClose={() => closeModal()}
      title="Add Terminal"
    >
      <Formik
        validateOnChange={true}
        initialValues={{
          terminalName: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true)

          onCreateTerminalStart({
            params: {
              ...data,
              countryId: globalCountry?.id,
              merchantId: currentUser?.userId,
            },
            closeModal,
            refreshPageData,
          })

          setSubmitting(false)
        }}
        render={({ values, setFieldValue, handleBlur, handleChange }) => (
          <Form>
            <Box sx={{ p: 5 }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <AppTextField
                    placeholder={'Name'}
                    label={'Name'}
                    name="terminalName"
                    variant="outlined"
                    required
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                    size="small"
                  />
                </Grid>
              </Grid>

              <Box sx={{ height: 20 }} />
              <Box sx={{ height: 30 }} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{
                    color: '#ffffff',
                    display: 'flex',
                    background: '#00bfcd',
                    '&:hover': {
                      background: '#ec4785',
                    },
                    px: 5,
                  }}
                  type="submit"
                  disabled={isCreating}
                >
                  {isCreating ? <CircularProgress size={20} /> : 'Save'}
                </Button>
                &nbsp; &nbsp;
                <Button onClick={() => closeModal()} variant="outlined">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      />
    </AppDialog>
  )
}

export default container(AddNewClub)
