import React from 'react'
import CommonSignIn from './Signin/CommonSignIn'
const ForgotPasswordGlobalAdmin = React.lazy(
  () => import('./ForgetPassword/GlobalAdminForgotPassword')
)

const ForgotPasswordRegionOwner = React.lazy(
  () => import('./ForgetPassword/RegionOwnerForgotPassword')
)

const ForgotPasswordCountryAdmin = React.lazy(
  () => import('./ForgetPassword/CountryAdminForgotPassword')
)

const ForgotPasswordAreaOwner = React.lazy(
  () => import('./ForgetPassword/AreaOwnerForgotPassword')
)

const ForgotPasswordGmcAdmin = React.lazy(
  () => import('./ForgetPassword/GmcAdminForgotPassword')
)

const StateOwnerForgotPassword = React.lazy(
  () => import('./ForgetPassword/StateOwnerForgotPassword')
)

const SalesAgentForgotPassword = React.lazy(
  () => import('./ForgetPassword/SalesAgentForgotPassword')
)

const CollaborationPartnerForgotPassword = React.lazy(
  () => import('./ForgetPassword/CollaborationPartnerForgotPassword')
)

const CharityForgotPassword = React.lazy(
  () => import('./ForgetPassword/CharityForgotPassword')
)

const ClubForgotPassword = React.lazy(
  () => import('./ForgetPassword/ClubForgotPassword')
)

const MerchantForgotPassword = React.lazy(
  () => import('./ForgetPassword/MerchantForgotPassword')
)

const MerchantGroupForgotPassword = React.lazy(
  () => import('./ForgetPassword/MerchantGroupForgotPassword')
)

const WhiteLabelForgotPassword = React.lazy(
  () => import('./ForgetPassword/WhiteLabelForgotPassword')
)

const WebsiteAdminForgotPassword = React.lazy(
  () => import('./ForgetPassword/WebsiteAdminForgotPassword')
)

const CompanyForgotPassword = React.lazy(
  () => import('./ForgetPassword/CompanyForgotPassword')
)

const ResetPassword = React.lazy(() => import('./ResetPassword'))
const Signin = React.lazy(() => import('./Signin/GlobalAdminSignIn'))
const GlobalAdminSignIn = React.lazy(() => import('./Signin/GlobalAdminSignIn'))
const AreaAdminSignIn = React.lazy(() => import('./Signin/AreaAdminSignIn'))
const StateAdminSignIn = React.lazy(() => import('./Signin/StateAdminSignIn'))
const RegionAdminSignIn = React.lazy(() => import('./Signin/RegionAdminSignIn'))
const CharityAdminSignIn = React.lazy(
  () => import('./Signin/CharityAdminSignIn')
)
const ClubAdminSignIn = React.lazy(() => import('./Signin/ClubAdminSignIn'))
const SalesAgentSignIn = React.lazy(() => import('./Signin/SalesAgentSignIn'))
const CollaborationPartnerSignIn = React.lazy(
  () => import('./Signin/CollaborationPartnerSignIn')
)
const CountryAdminSignIn = React.lazy(
  () => import('./Signin/CountryAdminSignIn')
)
const GmcAdminSignIn = React.lazy(
  () => import('./Signin/GlobalMarketingCompanyAdminSignIn')
)

const WhiteLabelAdminSignIn = React.lazy(
  () => import('./Signin/WhiteLabelAdminSignIn')
)

const MerchantAdminSignIn = React.lazy(
  () => import('./Signin/MerchantAdminSignIn')
)

const MerchantGroupAdminSignIn = React.lazy(
  () => import('./Signin/MerchantGroupAdminSignIn')
)

const WebsiteAdminSignIn = React.lazy(
  () => import('./Signin/WebsiteAdminSignIn')
)

const CompanyAdminSignIn = React.lazy(
  () => import('./Signin/CompanyAdminSignIn')
)

export const authRouteConfig = [
  { path: '/signin', element: <CommonSignIn /> },
  {
    path: '/signin/global-admin',
    element: <GlobalAdminSignIn />,
  },
  {
    path: '/signin/country-licensee',
    element: <CountryAdminSignIn />,
  },
  {
    path: '/signin/gmc-admin',
    element: <GmcAdminSignIn />,
  },
  // {
  //   path: '/signin/country-owner',
  //   element: <Signin />,
  // },
  {
    path: '/signin/area-licensee',
    element: <AreaAdminSignIn />,
  },
  {
    path: '/signin/state-licensee',
    element: <StateAdminSignIn />,
  },
  {
    path: '/signin/charity',
    element: <CharityAdminSignIn />,
  },
  {
    path: '/signin/club',
    element: <ClubAdminSignIn />,
  },
  {
    path: '/signin/region-licensee',
    element: <RegionAdminSignIn />,
  },
  {
    path: '/signin/sales-agent',
    element: <SalesAgentSignIn />,
  },
  {
    path: '/signin/collaboration-partner',
    element: <CollaborationPartnerSignIn />,
  },
  {
    path: '/global-admin/forgot-password',
    element: <ForgotPasswordGlobalAdmin />,
  },
  {
    path: '/region-licensee/forgot-password',
    element: <ForgotPasswordRegionOwner />,
  },
  {
    path: '/country-licensee/forgot-password',
    element: <ForgotPasswordCountryAdmin />,
  },
  {
    path: '/gmc-admin/forgot-password',
    element: <ForgotPasswordGmcAdmin />,
  },
  {
    path: '/area-licensee/forgot-password',
    element: <ForgotPasswordAreaOwner />,
  },
  {
    path: '/state-licensee/forgot-password',
    element: <StateOwnerForgotPassword />,
  },
  {
    path: '/sales-agent/forgot-password',
    element: <SalesAgentForgotPassword />,
  },
  {
    path: '/collaboration-partner/forgot-password',
    element: <CollaborationPartnerForgotPassword />,
  },
  {
    path: '/charity/forgot-password',
    element: <CharityForgotPassword />,
  },
  {
    path: '/club/forgot-password',
    element: <ClubForgotPassword />,
  },
  {
    path: '/company/forgot-password',
    element: <CompanyForgotPassword />,
  },
  {
    path: '/merchant/forgot-password',
    element: <MerchantForgotPassword />,
  },

  {
    path: '/group-merchant/forgot-password',
    element: <MerchantGroupForgotPassword />,
  },
  {
    path: '/whitelabel/forgot-password',
    element: <WhiteLabelForgotPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/signin/whiteLabel',
    element: <WhiteLabelAdminSignIn />,
  },
  {
    path: '/signin/merchant',
    element: <MerchantAdminSignIn />,
  },
  {
    path: '/signin/group-merchant',
    element: <MerchantGroupAdminSignIn />,
  },
  {
    path: '/signin/website-admin',
    element: <WebsiteAdminSignIn />,
  },
  {
    path: '/signin/company-admin',
    element: <CompanyAdminSignIn />,
  },
  {
    path: 'website-admin/forgot-password',
    element: <WebsiteAdminForgotPassword />,
  },
]
