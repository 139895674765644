export const getUnauthorizedFallbackUrl = (userRole) => {
  switch (userRole) {
    case 'admin':
      return '/signin/global-admin'
    case 'globaladmin':
      return '/signin/global-admin'
    case 'countryowner':
      return '/signin/country-licensee'
    case 'gmcuser':
      return '/signin/gmc-admin'
    case 'areaowner':
      return '/signin/area-licensee'
    case 'stateowner':
      return '/signin/state-licensee'
    case 'regionowner':
      return '/signin/region-licensee'
    case 'charity':
      return '/signin/charity'
    case 'club':
      return '/signin/club'
    case 'salesagent':
      return '/signin/sales-agent'
    case 'collaborationpartner':
      return '/signin/collaboration-partner'
    case 'whitelabeluser':
      return '/signin/whitelabel'
    case 'merchant':
      return '/signin/merchant'
    case 'merchantfranchisor':
      return '/signin/group-merchant'
    case 'websiteadmin':
      return '/signin/website-admin'
    default:
      return '/signin/'
  }
}
