import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableHeading from './TableHeading'
import TableItem from './TableItem'
import {
  TablePagination,
  Box,
  Button,
  CircularProgress,
  TableRow,
  TableCell,
} from '@mui/material'
import AppTableContainer from '@crema/core/AppTableContainer'
import TableDataNotFound from 'components/TableDataNotFound'

// import { RecentOrders } from "types/models/ecommerce/EcommerceApp";
// import { CountrySetup } from 'types/models/CountrySetup'

interface teamTableProps {
  teamData: any[]
  page: any
  pageNumber: any
  setPage: any
  rowsPerPage: any
  setRowsPerPage: any
  handleChangePage: any
  handleChangeRowsPerPage: any
  hasMoreData: boolean
  onDeleteSectionStart: (id: number) => void
  loading: any
  totalCount: any
  refreshPageData?: any
}

const TeamTable: React.FC<teamTableProps> = ({
  teamData,
  totalCount,
  page,
  setPage,
  rowsPerPage,
  pageNumber,
  setRowsPerPage,
  hasMoreData,
  handleChangePage,
  handleChangeRowsPerPage,
  onDeleteSectionStart,
  loading,
  refreshPageData,
}) => {
  const [loadingData, setLoadingData] = useState<boolean>(true)

  useEffect(() => {
    setTimeout(() => {
      setLoadingData(false)
    }, 1000)
  }, [])

  return (
    <AppTableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          nextIconButtonProps={{ disabled: !hasMoreData }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Table stickyHeader>
        <TableHead>
          <TableHeading />
        </TableHead>
        <TableBody>
          {loadingData ? (
            <TableRow className="item-hover">
              <TableCell align="center" colSpan={8}>
                <CircularProgress size={20} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {teamData.length ? (
                teamData?.map((item, index) => (
                  <TableItem
                    data={item}
                    key={item.sn}
                    count={index + 1}
                    page={page}
                    pageNumber={pageNumber}
                    rowsPerPage={rowsPerPage}
                    onDeleteSectionStart={onDeleteSectionStart}
                    refreshPageData={refreshPageData}
                  />
                ))
              ) : (
                <TableDataNotFound />
              )}
            </>
          )}
        </TableBody>
      </Table>
    </AppTableContainer>
  )
}

export default TeamTable
