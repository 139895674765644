import { useState } from 'react'
import { AppCard, AppComponentHeader } from '@crema'
import { Home } from '@mui/icons-material'
import { Box, Button, Stack, TextField, Typography, Grid } from '@mui/material'
import BreadCrumb from 'components/Common/BreadCrumb'
import { Form, Formik } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import container from './Support.container'
import Previews from 'components/Previews'

const Support = ({ onCreateContactUsMessage, onOpenAlert }) => {
  const [uploadedFiles, setUploadedFiles] = useState<any>([])

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <AppComponentHeader title="Contact Piiink" />
        <BreadCrumb
          list={[
            { label: 'Home', icon: <Home fontSize="small" /> },
            { label: 'Contact Piiink' },
          ]}
        />
      </Stack>

      <AppCard title="Send us a message" sx={{ mt: 8 }}>
        <Formik
          initialValues={{ message: '', fileUrl: '' }}
          onSubmit={(data, { resetForm }) => {
            // if (!uploadedFiles?.length) {
            //   return onOpenAlert({
            //     message: 'Please upload file!',
            //     severity: 'error',
            //   })
            // }
            onCreateContactUsMessage({
              params: {
                ...data,
                message: data?.message,
                file: uploadedFiles.length ? uploadedFiles[0] : {},
              },
              onSuccess: () => {
                resetForm()
                setUploadedFiles([])
              },
            })
          }}
        >
          {({}) => (
            <Form>
              <AppTextField
                name="message"
                multiline
                rows={8}
                required
                placeholder="Message content..."
                fullWidth
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: 14,
                  },
                }}
              />
              &nbsp; &nbsp;
              <Grid item xs={12} lg={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  textAlign={'left'}
                  sx={{ mt: 2, mb: 2 }}
                >
                  Upload File
                </Typography>
                &nbsp; &nbsp;
                <Previews
                  setUploadedFiles={setUploadedFiles}
                  uploadedFiles={uploadedFiles}
                />
              </Grid>
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 4, px: 8 }}
                  type="submit"
                >
                  Send
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </AppCard>
    </Box>
  )
}

export default container(Support)
