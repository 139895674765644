import {
  Autocomplete,
  TextField
} from '@mui/material'
import { FieldProps, getIn } from 'formik'
import React, { useEffect } from 'react'
import { usePrevious } from 'utils'

import _ from 'lodash'
import container from './AreaSelect.container'

interface AddNewCountryProps {
  onFetchAreaStart: (payload: any) => void
  dropDown: any
  setSelectedCountry: any
  selectedArea?: any
  defaultSelected?: any
  defaultValue?: number
  isAssignedToOwner?: boolean
  isModalOpen?: boolean
  makeDefaultEmpty?: boolean
  label?: string
  onClearAreaDropDown?: () => void
  filterByCountryId?: any
  includeFilterByCountry?: boolean
}

const AreaSelect: React.FC<FieldProps & AddNewCountryProps> = ({
  field,
  form,
  filterByCountryId,
  onFetchAreaStart,
  setSelectedCountry,
  dropDown: { areaList },
  defaultValue,
  defaultSelected,
  selectedArea,
  isAssignedToOwner,
  onClearAreaDropDown,
  makeDefaultEmpty,
  label,
  includeFilterByCountry,
  isModalOpen,
  ...props
}) => {
  const useHasChanged = (val: any) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }

  const stateHasChanged = useHasChanged(form?.values?.stateId?.id)
  const regionHasChanged = useHasChanged(form?.values?.regionId?.id)

  useEffect(() => {
    if (makeDefaultEmpty && !_.isEmpty(defaultSelected)) {
      onClearAreaDropDown()
      if (isAssignedToOwner === false) {
        onFetchAreaStart({
          limit: 100,
          regionId: defaultSelected?.id,
          isAssignedToOwner: false,
        })
      } else {
        if (includeFilterByCountry) {
          onFetchAreaStart({ limit: 100, countryId: filterByCountryId?.id })
        } else {
          onFetchAreaStart({ limit: 100, regionId: defaultSelected?.id })
        }
      }
    } else {
      onClearAreaDropDown()
    }
    // form.setFieldValue(field.name, null)
  }, [
    defaultSelected,
    makeDefaultEmpty,
    filterByCountryId,
    includeFilterByCountry,
  ])

  useEffect(() => {
    if (!makeDefaultEmpty && isModalOpen == false) {
      if (!_.isEmpty(filterByCountryId)) {
        onFetchAreaStart({ limit: 100, countryId: filterByCountryId?.id })
      } else {
        onFetchAreaStart({ limit: 100 })
      }
    }
  }, [makeDefaultEmpty, filterByCountryId, isModalOpen])

  useEffect(() => {
    const { initialValues, values } = form

    if (initialValues?.stateId || initialValues?.regionId) {
      if (
        (initialValues?.stateId?.id != values?.stateId?.id ||
          initialValues?.regionId?.id != values?.regionId?.id) &&
        (stateHasChanged || regionHasChanged)
      ) {
        form.setFieldValue(field.name, null)
      }
    } else {
      if (stateHasChanged || regionHasChanged) {
        form.setFieldValue(field.name, null)
      }
    }
  }, [form?.values])

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <Autocomplete
      {...props}
      {...field}
      disablePortal
      id="combo-box-demo"
      size="small"
      value={field.value}
      getOptionLabel={(option) => (option ? option.label : '')}
      onChange={(e, value) => {
        form.setFieldValue(field.name, value)
        //
      }}
      // onInputChange={(event, newInputValue) => {
      //   console.log('onInputChange>>>', newInputValue)
      // }}
      options={areaList}
      defaultValue={defaultValue ? defaultValue : null}
      // isOptionEqualToValue={defaultCountry ? defaultCountry : null}
      // value={selectedArea ? selectedArea : null}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={errorText?.value || errorText}
          error={!!errorText}
          label={label ? label : 'Select Area'}
        />
      )}
    />
  )
}

export default container(AreaSelect)
