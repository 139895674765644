import axiosRequest from 'utils/axiosRequest'
export const fetchAreaAdminReferredCPList = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/salesAgent/getAll?salesAgentType=collaborationPartner&isReferredAsCP=true`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAreaAdminReferredCPListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/salesAgent/getAllCPByPanel?salesAgentType=collaborationPartner&isReferredAsCP=true`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllSalesAgentByLocationApi = (params) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getAllByLocation`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
