import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import DraftList from 'components/GlobalMerchantForm/DraftList/DraftList.component'
import EditMerchantRequest from 'components/GlobalMerchantForm/DraftList/components/EditMerchantRequest/EditMerchantRequest.component'
import MerchantList from 'components/GlobalMerchantForm/MerchantList'
import SendMerchantRequest from 'components/GlobalMerchantForm/SendMerchantRequest'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import AllNotificationList from 'components/AllNotificationList'
import ConnectStripe from 'components/Payments/ConnectStripe'

const MembersList = React.lazy(() => import('./Members/MembersList'))
const TransactionQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/TransactionQrCode/QrCodes')
)

const IssuerQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/IssuerQrCode')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)
const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)
const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)
const MerchantCodesList = React.lazy(
  () => import('components/PremiumCodes/MerchantCodesList')
)
const MemberCodesList = React.lazy(
  () => import('components/PremiumCodes/MemberCodesList')
)

const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)
const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))
const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)

const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)
const EditMerchantFromMerchantList = React.lazy(
  () => import('components/GlobalMerchantForm/EditMerchantRequest')
)

const MerchantActivityReports = React.lazy(
  () => import('components/GlobalMerchantForm/MerchantActivityReports')
)

const MembersInfoList = React.lazy(
  () => import('components/GlobalMerchantForm/MembersList')
)

export const clubAdminConfig = [
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/premium-merchant-codes',
    element: <MerchantCodesList />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/premium-member-codes',
    element: <MemberCodesList />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/memberslist',
    element: <MembersList />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'club'} />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'club'} />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/editMerchantRequest/:id',
    element: <EditMerchantRequest adminRoute={'club'} />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/merchantList',
    element: <MerchantList adminRoute={'club'} />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/draftList',
    element: <DraftList adminRoute={'club'} />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/transaction-qr-codes',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/issuer-qr-codes',
    element: <IssuerQrCodes />,
  },

  // issuer
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },
  // merchant signer
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },
  // cp signer
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/friendship-earning/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/friendship-earning/ascpsigner/detail',
    element: <FriendshipEarningAsCpSignerDetail />,
  },

  // issuer
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/coupons',
    element: <Coupons adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/coupon-codes/:id',
    element: <CouponCodes />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/membership-earning/fromMemberPremiumCode/detail',
    element: <FromMemberPremiumCodeDetails />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/setting/smtp',
    element: <SMTP adminRoute={'club'} />,
  },
  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/setting/group',
    element: <Group adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/setting/contacts',
    element: <Contacts adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/setting/documents',
    element: <Documents adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/setting/emails',
    element: <Emails adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/setting/reports/:id',
    element: <Reports adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/setting/recipients',
    element: <Recipients adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/merchantList/editMerchant/:id',
    element: <EditMerchantFromMerchantList adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/merchant-activity-reports',
    element: <MerchantActivityReports adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/member-list/:id',
    element: <MembersInfoList adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/all-notification',
    element: <AllNotificationList adminRoute={'club'} />,
  },

  {
    permittedRole: RoutePermittedRole.club,
    path: '/club/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'club'} />,
  },
]
