/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { sendClubReqStart } from 'store/club/clubSlice'
import {
  checkApplyMerchantPremiumCodeStart,
  clearGeneralData,
  clearMerchantCodeData,
  clearMerchantPremiumCode,
  clearMerchantPremiumCodeData,
  createBankInfoStart,
  fetchMerchantGeneralBankInfoStart,
} from 'store/merchant/merchantSlice'

import {
  fetchMerchantPackageStart,
  upgradeMerchantPackageByMerchantIdStart,
  fetchLastMerchantPackageLogByMerchantIdStart,
  fetchUpgradeAmountStart,
  clearUpgradeAmount,
} from 'store/merchantPackage/merchantPackageSlice'
import { openAlert } from 'store/alert/alertSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
  merchantPackage: state.merchantPackage,
  merchant: state.merchant,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  clearGeneralData: () => dispatch(clearGeneralData()),
  onFetchMerchantPackageStart: (payload) =>
    dispatch(fetchMerchantPackageStart(payload)),
  onFetchMerchantGeneralBankInfoStart: (payload) =>
    dispatch(fetchMerchantGeneralBankInfoStart(payload)),
  onCheckApplyMerchantPremiumCodeStart: (payload) =>
    dispatch(checkApplyMerchantPremiumCodeStart(payload)),
  clearMerchantPremiumCodeData: () => dispatch(clearMerchantPremiumCodeData()),
  onCreateBankInfoStart: (payload) => dispatch(createBankInfoStart(payload)),
  onUpgradeMerchantPackageByMerchantIdStart: (payload) =>
    dispatch(upgradeMerchantPackageByMerchantIdStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
  onFetchLastMerchantPackageLogByMerchantIdStart: (payload) =>
    dispatch(fetchLastMerchantPackageLogByMerchantIdStart(payload)),
  onFetchUpgradeAmountStart: (payload) =>
    dispatch(fetchUpgradeAmountStart(payload)),
  onClearMerchantPremiumCode: () => dispatch(clearMerchantPremiumCode()),
  onClearUpgradeAmount: () => dispatch(clearUpgradeAmount()),
  onClearMerchantCodeData: () => dispatch(clearMerchantCodeData()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
