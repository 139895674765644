import { AppCircularProgress } from '@crema'
import { CircularProgress, Container } from '@mui/material'
import React, { useEffect } from 'react'

const CommonSignIn = () => {
  useEffect(() => {
    window.location.replace('https://piiink.org')
  }, [])

  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Container>
  )
}

export default CommonSignIn
