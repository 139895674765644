import { axiosRequest } from 'utils'

export const fetchFriendshipFeeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/friendshipBeneficiary/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipFeeById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/friendshipBeneficiary/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryInternalBeneficiariesById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/countryInternalBeneficiary/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createFriendshipFee = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/friendshipBeneficiary/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCountryInternalBeneficiaries = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/countryInternalBeneficiary/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryInternalBeneficiariesList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/countryInternalBeneficiary/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteFriendshipFee = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/friendshipBeneficiary/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editFriendshipFee = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/friendshipBeneficiary/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCountryInternalBeneficiaries = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/countryInternalBeneficiary/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCountryInternalBeneficiaries = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/countryInternalBeneficiary/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
