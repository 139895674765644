import { axiosRequest } from 'utils'

export const createContactUs = (params) => {
  return () =>
    axiosRequest
      .post(`/api/contact/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
