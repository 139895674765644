import { createSlice } from '@reduxjs/toolkit'

import { IMembershipFee } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IMembershipFee = {
  membershipFeeList: listObj,
  soleMembershipFee: {},
  error: null,
  loading: false,
  isFetching: false,
  hasMoreData: false,
}

export const membershipFeeSlice = createSlice({
  name: 'membershipFee',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMembershipFeeStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipFeeSuccess: (state: any, action) => {
      state.membershipFeeList = action.payload
      state.loading = false
    },
    fetchMembershipFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipFeeByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMembershipFeeByIdSuccess: (state: any, action) => {
      state.soleMembershipFee = action.payload.data
      state.isFetching = false
    },
    fetchMembershipFeeByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createMembershipFeeStart: (state: any, action) => {
      state.loading = true
    },
    createMembershipFeeSuccess: (state: any, action) => {
      state.loading = false
      state.membershipFeeList.data = [
        ...state.membershipFeeList.data,
        action.payload,
      ]
    },
    createMembershipFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteMembershipFeeStart: (state: any, action) => {
      state.loading = true
    },
    deleteMembershipFeeSuccess: (state: any, action) => {
      state.loading = false
      state.membershipFeeList.data = state.membershipFeeList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteMembershipFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editMembershipFeeStart: (state: any, action) => {
      state.loading = true
    },
    editMembershipFeeSuccess: (state: any, action) => {
      const result = state.membershipFeeList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.membershipFeeList.data = result

      state.loading = false
    },
    editMembershipFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchMembershipFeeStart,
  fetchMembershipFeeSuccess,
  fetchMembershipFeeFailure,
  createMembershipFeeStart,
  createMembershipFeeSuccess,
  createMembershipFeeFailure,
  deleteMembershipFeeStart,
  deleteMembershipFeeSuccess,
  deleteMembershipFeeFailure,
  fetchMembershipFeeByIdStart,
  fetchMembershipFeeByIdSuccess,
  fetchMembershipFeeByIdFailure,
  editMembershipFeeStart,
  editMembershipFeeSuccess,
  editMembershipFeeFailure,
} = membershipFeeSlice.actions

export default membershipFeeSlice.reducer
