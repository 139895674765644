import { createSlice } from '@reduxjs/toolkit'

import { IPageSetupState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IPageSetupState = {
  pageList: listObj,
  solePage: null,
  solePageForMerchant: null,
  error: null,
  loading: false,
  isFetching: false,
  hasMoreData: false,
  pageData: {},
}

export const pageSetupSlice = createSlice({
  name: 'pageSetup',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPageStart: (state: any, action) => {
      state.loading = true
    },
    fetchPageSuccess: (state: any, action) => {
      state.pageList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchPageByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchPageByIdSuccess: (state: any, action) => {
      state.pageList = action.payload.data
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPageByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchPageByRoleStart: (state: any, action) => {
      state.loading = true
    },
    fetchPageByRoleSuccess: (state: any, action) => {
      state.solePage = action.payload.data
      state.loading = false
    },
    fetchPageByRoleFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPageByRoleAndCountryStart: (state: any, action) => {
      state.loading = true
    },
    fetchPageByRoleAndCountrySuccess: (state: any, action) => {
      state.solePageForMerchant = action.payload.data
      state.loading = false
    },
    fetchPageByRoleAndCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    eoiFetchPageByRoleAndCountryStart: (state: any, action) => {
      state.loading = true
    },
    eoiFetchPageByRoleAndCountrySuccess: (state: any, action) => {
      state.solePageForMerchant = action.payload.data
      state.loading = false
    },
    eoiFetchPageByRoleAndCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createPageStart: (state: any, action) => {
      state.loading = true
    },
    createPageSuccess: (state: any, action) => {
      state.loading = false
      state.pageList.data = [...state.pageList.data, action.payload]
    },
    createPageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deletePageStart: (state: any, action) => {
      state.loading = true
    },
    deletePageSuccess: (state: any, action) => {
      state.loading = false
      state.pageList.data = state.pageList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editPageStart: (state: any, action) => {
      state.loading = true
    },
    editPageSuccess: (state: any, action) => {
      const result = state.pageList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.pageList.data = result

      state.loading = false
    },
    editPageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchOneBySlugStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchOneBySlugSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pageData = action.payload
      state.isFetching = false
    },
    fetchOneBySlugFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const {
  fetchPageStart,
  fetchPageSuccess,
  fetchPageFailure,
  createPageStart,
  createPageSuccess,
  createPageFailure,
  deletePageStart,
  deletePageSuccess,
  deletePageFailure,
  fetchPageByIdStart,
  fetchPageByIdSuccess,
  fetchPageByIdFailure,
  fetchPageByRoleStart,
  fetchPageByRoleSuccess,
  fetchPageByRoleFailure,
  editPageStart,
  editPageSuccess,
  editPageFailure,
  fetchOneBySlugStart,
  fetchOneBySlugSuccess,
  fetchOneBySlugFailure,
  fetchPageByRoleAndCountryStart,
  fetchPageByRoleAndCountrySuccess,
  fetchPageByRoleAndCountryFailure,
  eoiFetchPageByRoleAndCountryStart,
  eoiFetchPageByRoleAndCountrySuccess,
  eoiFetchPageByRoleAndCountryFailure,
} = pageSetupSlice.actions

export default pageSetupSlice.reducer
