import { createSlice } from '@reduxjs/toolkit'

import { IEmailTemplatesSetupState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IEmailTemplatesSetupState = {
  emailTemplatesList: listObj,
  error: null,
  loading: false,
  hasMoreData: false,
}

export const emailTemplatesSetupSlice = createSlice({
  name: 'emailTemplatesSetup',
  initialState: INITIAL_STATE,
  reducers: {
    fetchEmailTemplatesStart: (state: any, action) => {
      state.loading = true
    },
    fetchEmailTemplatesSuccess: (state: any, action) => {
      state.emailTemplatesList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchEmailTemplatesFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchEmailTemplatesByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchEmailTemplatesByIdSuccess: (state: any, action) => {
      state.emailTemplatesList = action.payload.data
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchEmailTemplatesByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createEmailTemplatesStart: (state: any, action) => {
      state.loading = true
    },
    createEmailTemplatesSuccess: (state: any, action) => {
      state.loading = false
      state.emailTemplatesList.data = [
        ...state.emailTemplatesList.data,
        action.payload,
      ]
    },
    createEmailTemplatesFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteEmailTemplatesStart: (state: any, action) => {
      state.loading = true
    },
    deleteEmailTemplatesSuccess: (state: any, action) => {
      state.loading = false
      state.emailTemplatesList.data = state.emailTemplatesList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteEmailTemplatesFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editEmailTemplatesStart: (state: any, action) => {
      state.loading = true
    },
    editEmailTemplatesSuccess: (state: any, action) => {
      const result = state.emailTemplatesList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.emailTemplatesList.data = result

      state.loading = false
    },
    editEmailTemplatesFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchEmailTemplatesStart,
  fetchEmailTemplatesSuccess,
  fetchEmailTemplatesFailure,
  createEmailTemplatesStart,
  createEmailTemplatesSuccess,
  createEmailTemplatesFailure,
  deleteEmailTemplatesStart,
  deleteEmailTemplatesSuccess,
  deleteEmailTemplatesFailure,
  fetchEmailTemplatesByIdStart,
  fetchEmailTemplatesByIdSuccess,
  fetchEmailTemplatesByIdFailure,
  editEmailTemplatesStart,
  editEmailTemplatesSuccess,
  editEmailTemplatesFailure,
} = emailTemplatesSetupSlice.actions

export default emailTemplatesSetupSlice.reducer
