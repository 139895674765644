import { createSlice } from '@reduxjs/toolkit'

import { IAuthState } from '../interfaces'

const INITIAL_STATE: IAuthState = {
  isAuthenticated: false,
  currentUser: {},
  newUser: {},
  error: null,
  loading: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    signInStart: (state: any, action) => {
      state.loading = true
    },
    signInSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    signInFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    globalAdminLoginStart: (state: any, action) => {
      state.loading = true
    },
    globalAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    globalAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    countryAdminLoginStart: (state: any, action) => {
      state.loading = true
    },
    countryAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    countryAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    areaAdminLoginStart: (state: any, action) => {
      state.loading = true
    },
    areaAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    areaAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    stateAdminLoginStart: (state: any, action) => {
      state.loading = true
    },
    stateAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    stateAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    regionAdminLoginStart: (state: any, action) => {
      state.loading = true
    },
    regionAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    regionAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    charityAdminLoginStart: (state: any, action) => {
      state.loading = true
    },
    charityAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    charityAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    clubAdminLoginStart: (state: any, action) => {
      state.loading = true
    },
    clubAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    clubAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    salesAgentLoginStart: (state: any, action) => {
      state.loading = true
    },
    salesAgentLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    salesAgentLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    collaborationPartnerLoginStart: (state: any, action) => {
      state.loading = true
    },
    collaborationPartnerLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    collaborationPartnerLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    whiteLabelAdminLoginStart: (state: any, action) => {
      state.loading = true
    },
    whiteLabelAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    whiteLabelAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    globalMarketingCompanyAdminLoginStart: (state: any, action) => {
      state.loading = true
    },

    globalMarketingCompanyAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },

    globalMarketingCompanyAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    merchantAdminLoginStart: (state: any, action) => {
      state.loading = true
    },

    merchantAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },

    merchantAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    websiteAdminLoginStart: (state: any, action) => {
      state.loading = true
    },

    websiteAdminLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },

    websiteAdminLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    logIn: (state: any) => {
      state.isAuthenticated = true
    },
    logOut: (state: any) => {
      state.isAuthenticated = false
      state.currentUser = { role: state.currentUser?.role }
      state.loading = false
    },

    agreeTermAndConditionStart: (state: any, action) => {
      state.loading = true
    },
    agreeTermAndConditionSuccess: (state: any) => {
      state.currentUser = { ...state.currentUser, agreedTermAndCondition: true }
      state.loading = false
    },
    agreeTermAndConditionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    subscriptionIdUpdate: (state: any, action) => {
      state.currentUser.userCountry = {
        ...state.currentUser.userCountry,
        stripeSubscriptionId: action.payload,
      }
      state.loading = false
    },

    stripeCustomerIdIdUpdate: (state: any, action) => {
      state.currentUser.userCountry = {
        ...state.currentUser.userCountry,
        stripeCustomerId: action.payload,
      }
      state.loading = false
    },

    merchantFranchisorLoginStart: (state: any, action) => {
      state.loading = true
    },

    merchantFranchisorLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },

    merchantFranchisorLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    companyLoginStart: (state: any, action) => {
      state.loading = true
    },

    companyLoginSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },

    companyLoginFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  signInStart,
  signInSuccess,
  signInFailure,
  logIn,
  logOut,
  globalAdminLoginStart,
  globalAdminLoginSuccess,
  globalAdminLoginFailure,
  countryAdminLoginStart,
  countryAdminLoginSuccess,
  countryAdminLoginFailure,
  globalMarketingCompanyAdminLoginStart,
  globalMarketingCompanyAdminLoginSuccess,
  globalMarketingCompanyAdminLoginFailure,
  areaAdminLoginStart,
  areaAdminLoginSuccess,
  areaAdminLoginFailure,
  stateAdminLoginStart,
  stateAdminLoginSuccess,
  stateAdminLoginFailure,
  regionAdminLoginStart,
  regionAdminLoginSuccess,
  regionAdminLoginFailure,
  charityAdminLoginStart,
  charityAdminLoginSuccess,
  charityAdminLoginFailure,
  clubAdminLoginStart,
  clubAdminLoginSuccess,
  clubAdminLoginFailure,
  salesAgentLoginStart,
  salesAgentLoginSuccess,
  salesAgentLoginFailure,
  collaborationPartnerLoginStart,
  collaborationPartnerLoginSuccess,
  collaborationPartnerLoginFailure,
  whiteLabelAdminLoginStart,
  whiteLabelAdminLoginSuccess,
  whiteLabelAdminLoginFailure,
  merchantAdminLoginStart,
  merchantAdminLoginSuccess,
  merchantAdminLoginFailure,
  merchantFranchisorLoginStart,
  merchantFranchisorLoginSuccess,
  merchantFranchisorLoginFailure,
  websiteAdminLoginStart,
  websiteAdminLoginSuccess,
  websiteAdminLoginFailure,
  agreeTermAndConditionStart,
  agreeTermAndConditionSuccess,
  agreeTermAndConditionFailure,
  subscriptionIdUpdate,
  stripeCustomerIdIdUpdate,

  companyLoginStart,
  companyLoginSuccess,
  companyLoginFailure,
} = authSlice.actions

export const getLocalCurrency = (state) =>
  state?.auth?.currentUser?.userCountry?.currencyName || ''

export default authSlice.reducer
