import React, { useEffect } from 'react'
import { Box, Tabs, Tab, Typography } from '@mui/material'

import AppDialog from '@crema/core/AppDialog'
import container from './MerchantDetails.container'
import BasicInfo from '../BasicInfo'
import WebsiteDetails from '../WebsiteDetails'
import ImagesNew from '../ImagesNew'
import DiscountOffered from '../DiscountOffered'
import PackagesAndBankInfo from '../PackagesAndBankInfo'

import * as yup from 'yup'

interface AddNewCountryProps {
  isAddCountryOpen: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry: () => void
  clearGeneralData?: () => void
  onCreateClubStart: (params) => void
  siteCoordinator: any
  merchantId?: number
  club: any
  auth: any
  adminRoute?: any
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function handleClick(event) {
  event.preventDefault()
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  if (index == 0) {
    return
  }
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  isAddCountryOpen,
  clearGeneralData,
  onCloseAddCountry,
  merchantId,
  adminRoute,
}) => {
  const [value, setValue] = React.useState(0)

  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const closeModal = () => {
    onCloseAddCountry()
    setMultiplePersonApply(false)
    clearGeneralData()
  }

  return (
    <AppDialog
      dividers
      maxWidth="lg"
      open={isAddCountryOpen}
      onClose={() => closeModal()}
      title="Merchant Details"
    >
      <Box sx={{ width: '100%', p: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Basics" {...a11yProps(0)} />
            <Tab label="Specifics" {...a11yProps(1)} />
            <Tab label="Images" {...a11yProps(2)} />
            <Tab label="Discounts" {...a11yProps(3)} />
            <Tab label="Packages & Bank Info" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <BasicInfo
            handleChangeTab={handleChange}
            merchantId={merchantId}
            adminRoute={adminRoute}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WebsiteDetails
            handleChangeTab={handleChange}
            merchantId={merchantId}
            adminRoute={adminRoute}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* <Images
            handleChangeTab={handleChange}
            merchantId={merchantId}
            adminRoute={adminRoute}
          /> */}
          <ImagesNew
            handleChangeTab={handleChange}
            merchantId={merchantId}
            adminRoute={adminRoute}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DiscountOffered merchantId={merchantId} adminRoute={adminRoute} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PackagesAndBankInfo
            merchantId={merchantId}
            adminRoute={adminRoute}
          />
        </TabPanel>
      </Box>
    </AppDialog>
  )
}

export default container(AddNewClub)
