import { all, call } from 'redux-saga/effects'
import { authSagas } from './auth/authSagas'
import { postSagas } from './post/postSagas'
import { countrySetupSagas } from './countrySetup/countrySetupSagas'
import { merchantCategorySetupSagas } from './merchantCategorySetup/merchantCategorySetupSagas'
import { stateSetupSagas } from './stateSetup/stateSetupSagas'
import { regionSetupSagas } from './regionSetup/regionSetupSagas'
import { areaSetupSagas } from './areaSetup/areaSetup.Sagas'
import { postalCodeSagas } from './postalCode/postalCodeSagas'
import { countryWisePrefixSagas } from './countryWisePrefix/countryWisePrefixSagas'
import { pendingAreaOwnerSagas } from './countryAdmin/countryAdminSaga'
import { dropDownSagas } from './dropDown/dropDownSaga'
import { areaOwnerSagas } from './areaOwner/areaOwnerSaga'
import { stateOwnerSagas } from './stateOwner/stateOwnerSaga'
import { regionOwnerSagas } from './regionOwner/regionOwnerSaga'
import { collaborationPartnerSagas } from './collaborationPartner/collaborationPartnerSaga'
import { collaborationPackageSagas } from './collaborationPackage/collaborationPackageSagas'
import { gmcCollaborationPartnerSagas } from './gmcCollaborationPartner/gmcCollaborationPartnerSagas'
import { gmcReferredCPSagas } from './gmcReferredCp/gmcReferredCpSagas'
import { whiteLabelReferredCPSagas } from './whiteLabelAdmin/ReferredCP/whiteLabelReferredCpSagas'
import { merchantPackageSagas } from './merchantPackage/merchantPackageSagas'
import { charitiesSagas } from './charities/charitiesSaga'
import { clubSagas } from './club/clubSaga'
import { friendshipFeeSagas } from './friendshipFee/friendshipFeeSagas'
import { membershipFeeSagas } from './membershipFee/membershipFeeSagas'
import { merchantFeeSagas } from './merchantFee/merchantFeeSagas'
import { membershipPackageSagas } from './membershipPackage/membershipPackageSagas'
import { salesAgentSagas } from './salesAgent/salesAgentSaga'
import { whiteLabelSagas } from './whiteLabel/whiteLabelSaga'
import { eoiCountryOwnerSagas } from './eoiCountryOwner/eoiCountryOwnerSaga'
import { countryOwnerSagas } from './countryOwner/countryOwnerSaga'
import { referredCPSagas } from './referredCP/referredCpSagas'
import { membersSagas } from './members/membersSaga'
import { merchantSagas } from './merchant/merchantSagas'
import { emailTemplatesSetupSagas } from './emailTemplatesSetup/emailTemplatesSetupSagas'
import { pageSetupSagas } from './pageSetup/pageSetupSagas'
import { cpGlobalMembershipBeneficiarySagas } from './cpGlobalMembershipBeneficiary/CPGlobalMembershipBeneficiarySagas'
import { cpGlobalFriendshipBeneficiarySagas } from './cpGlobalFriendshipBeneficiary/CPGlobalFriendshipBeneficiarySagas'
import { merchantAgreementTemplateSetupSagas } from './agreementTemplateSetup/merchantAgreementTemplateSagas'
import { forgotPasswordSagas } from './forgotPassword/forgotPasswordSagas'
import { resetPasswordSagas } from './resetPassword/resetPasswordSagas'
import { changePasswordSagas } from './changePassword/changePasswordSagas'
import { dashboardSagas } from './dashboard/dashboard.Sagas'
import { websiteAdminSagas } from './websiteAdmin/websiteAdminSagas'
import { qrCodesSagas } from './qrCodes/qrCodesSagas'
import { issuerCodesSagas } from './qrCodes/issuerCodesSagas'
import { earningSagas } from './earning/earningSaga'
import { trainingVideoSetupSagas } from './trainingVideos/trainingVideosSagas'
import { eoiSagas } from './eoi/eoiSaga'
import { globalAdminSettingsSagas } from './globalAdminSettings/globalAdminSettingsSagas'
import { contactUsSagas } from './contactUs/contactUsSagas'
import { premiumCodesSagas } from './premiumCode/premiumCodeSaga'
import { prizeDrawSagas } from './prizeDraw/prizeDrawSagas'
import { reviewSagas } from './review/reviewSaga'
import { reportsSagas } from './reports/reportsSagas'

export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(postSagas),
    call(countrySetupSagas),
    call(stateSetupSagas),
    call(regionSetupSagas),
    call(areaSetupSagas),
    call(postalCodeSagas),
    call(countryWisePrefixSagas),
    call(merchantCategorySetupSagas),
    call(collaborationPackageSagas),
    call(merchantPackageSagas),
    call(pendingAreaOwnerSagas),
    call(dropDownSagas),
    call(areaOwnerSagas),
    call(stateOwnerSagas),
    call(regionOwnerSagas),
    call(collaborationPartnerSagas),
    call(friendshipFeeSagas),
    call(membershipFeeSagas),
    call(merchantFeeSagas),
    call(membershipPackageSagas),
    call(gmcCollaborationPartnerSagas),
    call(gmcReferredCPSagas),
    call(charitiesSagas),
    call(clubSagas),
    call(salesAgentSagas),
    call(whiteLabelSagas),
    call(whiteLabelReferredCPSagas),
    call(eoiCountryOwnerSagas),
    call(countryOwnerSagas),
    call(referredCPSagas),
    call(membersSagas),
    call(merchantSagas),
    call(emailTemplatesSetupSagas),
    call(pageSetupSagas),
    call(cpGlobalMembershipBeneficiarySagas),
    call(cpGlobalFriendshipBeneficiarySagas),
    call(merchantAgreementTemplateSetupSagas),
    call(forgotPasswordSagas),
    call(resetPasswordSagas),
    call(changePasswordSagas),
    call(dashboardSagas),
    call(websiteAdminSagas),
    call(qrCodesSagas),
    call(issuerCodesSagas),
    call(earningSagas),
    call(trainingVideoSetupSagas),
    call(eoiSagas),
    call(globalAdminSettingsSagas),
    call(contactUsSagas),
    call(premiumCodesSagas),
    call(prizeDrawSagas),
    call(reviewSagas),
    call(reportsSagas),
  ])
}
