import React, { useState, useEffect } from 'react'
import { Box, Tabs, Tab, Typography, Grid } from '@mui/material'

import AppDialog from '@crema/core/AppDialog'
import container from './MerchantDetails.container'

import * as yup from 'yup'
import moment from 'moment'

interface AddNewCountryProps {
  isAddCountryOpen: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry: () => void
  clearGeneralData?: () => void
  onCreateClubStart: (params) => void
  onFetchReferredMerchantByIdStart: (params) => void
  siteCoordinator: any
  merchantId?: number
  selectedMerchant?: any
  refreshPageData?: any
  merchant?: any
  club: any
  auth: any
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function handleClick(event) {
  event.preventDefault()
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  if (index == 0) {
    return
  }
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  isAddCountryOpen,
  clearGeneralData,
  onCloseAddCountry,
  onCreateClubStart,
  merchantId,
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  club: { isCreating },
  selectedMerchant,
  refreshPageData,
  onFetchReferredMerchantByIdStart,
  merchant: { referredMerchantInfo, loading },
}) => {
  const [value, setValue] = React.useState(0)

  useEffect(() => {
    onFetchReferredMerchantByIdStart(selectedMerchant?.id)
  }, [selectedMerchant])

  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const closeModal = () => {
    onCloseAddCountry()
  }
  const validationSchema = yup.object({
    stateId: yup.object().nullable().required('state required'),
    regionId: yup.object().nullable().required('region required'),
  })

  return (
    <AppDialog
      dividers
      maxWidth="lg"
      open={isAddCountryOpen}
      onClose={() => closeModal()}
      title="Referred Merchant Details"
    >
      <Box sx={{ width: '100%', p: 4 }}>
        <Grid container spacing={4}>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">Merchant Info</Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography variant="h5">Merchant Name</Typography>
              <Typography variant="body2">
                {referredMerchantInfo?.merchantName ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography variant="h5">Merchant Email</Typography>
              <Typography variant="body2">
                {referredMerchantInfo?.merchantEmail ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography variant="h5">Merchant Phone Number</Typography>
              <Typography variant="body2">
                {referredMerchantInfo?.merchantPhoneNumber ?? '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={3} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Typography variant="h3">Contact Info</Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography variant="h5">Contact Person Name</Typography>
              <Typography variant="body2">
                {referredMerchantInfo?.contactPersonFirstName
                  ? `${referredMerchantInfo?.contactPersonFirstName} ${referredMerchantInfo?.contactPersonLastName}`
                  : ''}
              </Typography>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography variant="h5">Email</Typography>
              <Typography variant="body2">
                {referredMerchantInfo?.email ? referredMerchantInfo?.email : ''}
              </Typography>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography variant="h5">Phone Number</Typography>
              <Typography variant="body2">
                {referredMerchantInfo?.phoneNumber ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography variant="h5">City</Typography>
              <Typography variant="body2">
                {referredMerchantInfo?.city ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography variant="h5">Username</Typography>
              <Typography variant="body2">
                {referredMerchantInfo?.username ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography variant="h5">State</Typography>
              <Typography variant="body2">
                {referredMerchantInfo?.__state__?.stateName ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </AppDialog>
  )
}

export default container(AddNewClub)
