import axiosRequest from 'utils/axiosRequest'

export const agreeTermAndCondition = (role, params) => {
  return () =>
    axiosRequest
      .patch(`/api/${role}/agreedTermAndCondition`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
