import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchGmcCollaborationPartnerStart,
  fetchGmcCollaborationPartnerSuccess,
  fetchGmcCollaborationPartnerFailure,
  deleteGmcCollaborationPartnerStart,
  deleteGmcCollaborationPartnerSuccess,
  deleteGmcCollaborationPartnerFailure,
  fetchGmcCollaborationPartnerByIdStart,
  fetchGmcCollaborationPartnerByIdSuccess,
  fetchGmcCollaborationPartnerByIdFailure,
  fetchGmcCollaborationPartnerByEmailFailure,
  fetchGmcCollaborationPackageFailure,
  fetchGmcCollaborationPartnerByEmailSuccess,
  fetchGmcCollaborationPackageSuccess,
  fetchGmcAssignIssuerQRCodeStart,
  fetchGmcAssignIssuerQRCodeSuccess,
  fetchGmcAssignIssuerQRCodeFailure,
  clearGmcAssignIssuerQRCodeStart,
  fetchGmcCollaborationPackageStart,
  createGmcCollaborationPartnerStart,
  createGmcCollaborationPartnerSuccess,
  createGmcCollaborationPartnerFailure,
  editGmcCollaborationPartnerStart,
  editGmcCollaborationPartnerSuccess,
  editGmcCollaborationPartnerFailure,
  gmcUpgradeToCPStart,
  gmcUpgradeToCPSuccess,
  gmcUpgradeToCPFailure,
  fetchCollaborationPackageStart,
  fetchCollaborationPackageSuccess,
  fetchCollaborationPackageFailure,
  gmcUpgradeCPPackageStart,
  gmcUpgradeCPPackageSuccess,
  gmcUpgradeCPPackageFailure,
  fetchGmcPurchaseHistoryStart,
  fetchGmcPurchaseHistorySuccess,
  fetchGmcPurchaseHistoryFailure,
  gmcUpdateCPPaymentStatusStart,
  gmcUpdateCPPaymentStatusSuccess,
  gmcUpdateCPPaymentStatusFailure,
  fetchCpPurchaseHistoryFailure,
  fetchCpPurchaseHistorySuccess,
  upgradeCPPackageRepurchaseSuccess,
  upgradeCPPackageRepurchaseFailure,
} from './gmcCollaborationPartnerSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchGmcCollaborationPartnerList,
  createGmcCollaborationPartner,
  fetchGmcCollaborationPartnerById,
  fetchSalesAgentByEmail,
  fetchGmcCollaborationPackageList,
  fetchGmcPurchaseHistoryList,
  editGmcCollaborationPartner,
  viewGmcCollaborationPartner,
  gmcUpgradeToCP,
  gmcUpgradeCPPackage,
  fetchAssignIssuerQRCodeList,
  gmcUpdateCPPaymentStatus,
  fetchCpPurchaseHistoryList,
  upgradeCPPackageRepurchaseApi,
} from '../../api/gmcCollaborationPartner'

import { fetchCollaborationPackageList } from '../../api/collaborationPackage'

import _ from 'lodash'

export function* fetchGmcCollaborationPartnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGmcCollaborationPartnerList(payload))

    yield put(fetchGmcCollaborationPartnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchGmcCollaborationPartnerFailure(err))
    console.error(err)
  }
}

export function* fetchCollaborationPackageAsync({ payload }: AnyAction) {
  try {
    if (payload?.countryId === null && payload?.partnerType === 'country') {
      yield put(
        openAlert({
          message: 'Please select country first!!!',
          severity: 'error',
        })
      )
    }
    const { data } = yield call(fetchCollaborationPackageList(payload))

    yield put(fetchCollaborationPackageSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCollaborationPackageFailure(err))
    console.error(err)
  }
}

export function* createGmcCollaborationPartnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createGmcCollaborationPartner(payload?.params))

    yield put(createGmcCollaborationPartnerSuccess(data?.results))
    yield put(
      fetchGmcCollaborationPartnerStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Collaboration Partner successfully created',
        severity: 'success',
      })
    )
    payload?.navigate('/gmc/collaborationpartnerslist')
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createGmcCollaborationPartnerFailure(err))

    console.error(err)
  }
}

export function* fetchGmcCollaborationPartnerByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGmcCollaborationPartnerById(payload))

    yield put(fetchGmcCollaborationPartnerByIdSuccess(data?.data))
  } catch (err) {
    yield put(fetchGmcCollaborationPartnerByIdFailure(err))
    console.error(err)
  }
}

export function* fetchGmcCollaborationPartnerByEmailAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchSalesAgentByEmail(payload?.email))
    yield put(fetchGmcCollaborationPartnerByEmailSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGmcCollaborationPartnerByEmailFailure(err))
    console.error('check error here from payload !!!', err)
  }
}

export function* fetchGmcCollaborationPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGmcCollaborationPackageList(payload))
    yield put(fetchGmcCollaborationPackageSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: 'Please select country first',
        severity: 'error',
      })
    )
    yield put(fetchGmcCollaborationPackageFailure(err))
    console.error(err)
  }
}

export function* fetchGmcAssignIssuerQRCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAssignIssuerQRCodeList(payload))
    yield put(fetchGmcAssignIssuerQRCodeSuccess(data))
  } catch (err) {
    yield put(fetchGmcAssignIssuerQRCodeFailure(err))
    console.error(err)
  }
}

export function* editGmcCollaborationPartnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editGmcCollaborationPartner(payload?.id, payload)
    )

    yield put(editGmcCollaborationPartnerSuccess(data?.data))
    yield put(openAlert({ message: 'succesfully edited', severity: 'success' }))
  } catch (err) {
    yield put(editGmcCollaborationPartnerFailure(err))
    console.error(err)
  }
}
export function* upgradeGmcCollaborationPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      gmcUpgradeToCP(payload?.params.id, payload?.params)
    )

    yield put(gmcUpgradeToCPSuccess(data?.data))
    yield put(
      fetchGmcCollaborationPartnerStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Upgraded succesfully',
        severity: 'success',
      })
    )
    payload.navigate('/gmc/collaborationpartnerslist')
  } catch (err) {
    yield put(gmcUpgradeToCPFailure(err))
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
  }
}

export function* gmcUpgradeCPPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      gmcUpgradeCPPackage(payload?.params?.id, payload?.params)
    )
    yield put(gmcUpgradeCPPackageSuccess(data?.data))
    yield put(
      fetchGmcCollaborationPartnerStart({
        page: 1,
        limit: 10,
        offset: 0,
        isPending: false,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Package Succesfully Upgraded/Renewed',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(gmcUpgradeCPPackageFailure(err))
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
  }
}

export function* gmcUpdateCPPaymentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      gmcUpdateCPPaymentStatus(payload?.params?.id, payload?.params)
    )
    yield put(gmcUpdateCPPaymentStatusSuccess(data?.data))
    yield put(
      fetchGmcCollaborationPartnerStart({
        page: 1,
        limit: 10,
        offset: 0,
        isPending: false,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Payment status updated succesfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(gmcUpdateCPPaymentStatusFailure(err))
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
  }
}

export function* fetchGmcPurchaseHistoryAsync({
  salesAgentId,
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGmcPurchaseHistoryList(payload?.salesAgentId, null)
    )

    yield put(fetchGmcPurchaseHistorySuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchGmcPurchaseHistoryFailure(err))
    console.error(err)
  }
}

export function* fetchCpPurchaseHistoryAsync({
  salesAgentId,
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCpPurchaseHistoryList(payload))

    yield put(fetchCpPurchaseHistorySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCpPurchaseHistoryFailure(err))
    console.error(err)
  }
}

export function* upgradeCPPackageRepurchaseAsync({
  salesAgentId,
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      upgradeCPPackageRepurchaseApi(payload?.params?.id, payload?.params)
    )

    yield put(upgradeCPPackageRepurchaseSuccess(data))

    yield put(
      openAlert({
        message: 'Package Repurchased succesfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(upgradeCPPackageRepurchaseFailure(err))
    console.error(err)
  }
}

export function* watchGmcUpgradeToCP() {
  yield takeLatest(
    'gmcCollaborationPartner/gmcUpgradeToCPStart',
    upgradeGmcCollaborationPackageAsync
  )
}

export function* watchGmcUpgradeCPPackage() {
  yield takeLatest(
    'gmcCollaborationPartner/gmcUpgradeCPPackageStart',
    gmcUpgradeCPPackageAsync
  )
}

export function* watchGmcUpdateCPPayment() {
  yield takeLatest(
    'gmcCollaborationPartner/gmcUpdateCPPaymentStatusStart',
    gmcUpdateCPPaymentAsync
  )
}

export function* watchFetchGmcCollaborationPartnerByEmail() {
  yield takeLatest(
    'gmcCollaborationPartner/fetchGmcCollaborationPartnerByEmailStart',
    fetchGmcCollaborationPartnerByEmailAsync
  )
}

export function* watchFetchGmcCollaborationPackage() {
  yield takeLatest(
    'gmcCollaborationPartner/fetchGmcCollaborationPackageStart',
    fetchGmcCollaborationPackageAsync
  )
}

export function* watchFetchGmcAssignIssuerQRCode() {
  yield takeLatest(
    'gmcCollaborationPartner/fetchGmcAssignIssuerQRCodeStart',
    fetchGmcAssignIssuerQRCodeAsync
  )
}

export function* watchFetchCollaborationPackage() {
  yield takeLatest(
    'gmcCollaborationPartner/fetchCollaborationPackageStart',
    fetchCollaborationPackageAsync
  )
}

export function* watchFetchGmcCollaborationPartner() {
  yield takeLatest(
    'gmcCollaborationPartner/fetchGmcCollaborationPartnerStart',
    fetchGmcCollaborationPartnerAsync
  )
}

export function* watchCreateGmcCollaborationPartner() {
  yield takeLatest(
    'gmcCollaborationPartner/createGmcCollaborationPartnerStart',
    createGmcCollaborationPartnerAsync
  )
}

export function* watchEditGmcCollaborationPartner() {
  yield takeLatest(
    'gmcCollaborationPartner/editGmcCollaborationPartnerStart',
    editGmcCollaborationPartnerAsync
  )
}

export function* watchFetchGmcPurchaseHistory() {
  yield takeLatest(
    'gmcCollaborationPartner/fetchGmcPurchaseHistoryStart',
    fetchGmcPurchaseHistoryAsync
  )
}

export function* watchFetchCpPurchaseHistory() {
  yield takeLatest(
    'gmcCollaborationPartner/fetchCpPurchaseHistoryStart',
    fetchCpPurchaseHistoryAsync
  )
}

export function* watchUpgradeCPPackageRepurchaseAsync() {
  yield takeLatest(
    'gmcCollaborationPartner/upgradeCPPackageRepurchaseStart',
    upgradeCPPackageRepurchaseAsync
  )
}

export function* gmcCollaborationPartnerSagas() {
  yield all([
    call(watchFetchGmcCollaborationPartner),
    call(watchCreateGmcCollaborationPartner),
    call(watchEditGmcCollaborationPartner),
    call(watchFetchGmcCollaborationPartnerByEmail),
    call(watchFetchGmcCollaborationPackage),
    call(watchGmcUpgradeToCP),
    call(watchGmcUpgradeCPPackage),
    call(watchGmcUpdateCPPayment),
    call(watchFetchCollaborationPackage),
    call(watchFetchGmcPurchaseHistory),
    call(watchFetchGmcAssignIssuerQRCode),
    call(watchFetchCpPurchaseHistory),
    call(watchUpgradeCPPackageRepurchaseAsync),
  ])
}
