import React from 'react'
import { TextField, Autocomplete } from '@mui/material'
import { FieldProps, getIn } from 'formik'

interface AddNewCountryProps {
  dropDown: any
  setSelectedCountry: any
  selectedArea?: any
  defaultValue?: number
  statusOption?: any[]
  label?: string
  filterByParternType?: any
  onClearData?: () => void
}

const StatusSelect: React.FC<FieldProps & AddNewCountryProps> = ({
  field,
  form,
  setSelectedCountry,
  defaultValue,
  selectedArea,
  statusOption,
  label,
  filterByParternType,
  onClearData,
  ...props
}) => {
  const alternateOption: any = [
    { label: 'Pending', name: 'pending' },
    { label: 'Rejected', name: 'rejected' },
  ]

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <Autocomplete
      {...props}
      {...field}
      disablePortal
      id="combo-box-demo"
      size="small"
      value={field.value}
      getOptionLabel={(option) => (option ? option.label : '')}
      onChange={(e, value, reason: any) => {
        form.setFieldValue(field.name, value)
        if (reason === 'clear') {
          onClearData()
        }
      }}
      options={statusOption?.length ? statusOption : alternateOption}
      defaultValue={defaultValue ? defaultValue : null}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={errorText?.value || errorText}
          error={!!errorText}
          label={label ? label : 'Select Status'}
        />
      )}
    />
  )
}

export default StatusSelect
