import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableHeader from '@crema/core/AppTable/TableHeader'

const TableHeading = ({ currentUser, dataLength }) => {
  return (
    <TableHeader>
      <TableCell
        align="left"
        sx={dataLength ? { position: 'sticky', left: 0, zIndex: 7 } : null}
      >
        SN
      </TableCell>
      <TableCell
        align="left"
        sx={
          dataLength
            ? {
                position: 'sticky',
                left: 50,
                zIndex: 7,
              }
            : null
        }
      >
        ID
      </TableCell>

      <TableCell
        align="left"
        sx={
          dataLength
            ? {
                position: 'sticky',
                left: 100,

                zIndex: 7,
              }
            : null
        }
      >
        Merchant Name
      </TableCell>
      <TableCell
        align="left"
        sx={
          dataLength
            ? {
                position: 'sticky',
                left: 230,

                zIndex: 7,
              }
            : null
        }
      >
        Actions
      </TableCell>

      {currentUser?.role?.toLowerCase() === 'collaborationpartner' &&
        currentUser?.userCountry?.partnerType?.toLowerCase() === 'global' && (
          <TableCell align="left">Country Name</TableCell>
        )}

      <TableCell align="left">Merchant Email</TableCell>
      <TableCell align="left">Username</TableCell>
      <TableCell align="left">Merchant Phone</TableCell>
      <TableCell align="left">Contact Person Name</TableCell>
      <TableCell align="left">Contact Person Phone</TableCell>
      <TableCell align="left">Contact Person Email</TableCell>
      <TableCell align="left">Street Info</TableCell>
      <TableCell align="left">City</TableCell>
      <TableCell align="left">Email Sent?</TableCell>
      <TableCell align="left">State / Province</TableCell>
      <TableCell align="left">Region</TableCell>
      <TableCell align="left">Area</TableCell>
      <TableCell align="left">Saved via send merchant email?</TableCell>
      <TableCell align="left">Referred By Member?</TableCell>
      <TableCell align="left">Member ID</TableCell>
      <TableCell align="left">Subscription Status</TableCell>
      <TableCell align="left">Status</TableCell>
      <TableCell align="left">Registration Date</TableCell>
      <TableCell align="left">Signer Role</TableCell>
      <TableCell align="left">Signer Name</TableCell>
      <TableCell align="left">Issuer Code</TableCell>
      <TableCell align="left">Transaction Code</TableCell>
    </TableHeader>
  )
}

export default TableHeading
