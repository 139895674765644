import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchPostalCodeStart,
  fetchPostalCodeSuccess,
  fetchPostalCodeFailure,
  createPostalCodeSuccess,
  createPostalCodeFailure,
  deletePostalCodeSuccess,
  createPostalCodeByCsvStart,
  createPostalCodeByCsvSuccess,
  createPostalCodeByCsvFailure,
  deletePostalCodeFailure,
  fetchPostalCodeByIdSuccess,
  fetchPostalCodeByIdFailure,
  editPostalCodeSuccess,
  editPostalCodeFailure,
} from './postalCodeSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchPostalCodeList,
  createPostalCode,
  createPostalCodeByCsv,
  deletePostalCode,
  fetchPostalCodeById,
  editPostalCode,
} from '../../api/postalCode'

import _ from 'lodash'

export function* fetchPostalCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPostalCodeList(payload))

    yield put(fetchPostalCodeSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPostalCodeFailure(err))
  }
}

export function* fetchPostalCodeByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPostalCodeById(payload))

    yield put(fetchPostalCodeByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPostalCodeByIdFailure(err))
  }
}

export function* editPostalCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editPostalCode(payload.id, payload))

    yield put(editPostalCodeSuccess(data?.data))
    yield put(openAlert({ message: 'succesfully edited', severity: 'success' }))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editPostalCodeFailure(err))
  }
}

export function* createPostalCodeByCsvAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createPostalCodeByCsv(payload.params))

    const { formData } = payload

    yield put(createPostalCodeByCsvSuccess(data?.data))
    yield put(
      fetchPostalCodeStart({
        page: 1,
        limit: 10,
        areaId: formData?.areaId,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'postalCode succesfully created',
        severity: 'success',
      })
    )
    yield payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createPostalCodeByCsvFailure(err))
  }
}

export function* createPostalCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createPostalCode({ ...payload }))

    const { formData } = payload

    yield put(createPostalCodeSuccess(data?.data))
    yield put(
      fetchPostalCodeStart({
        page: 1,
        limit: 10,
        areaId: formData?.areaId,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'postalCode succesfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createPostalCodeFailure(err))
  }
}

export function* deletePostalCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deletePostalCode(payload.id))

    if (data) {
      yield put(deletePostalCodeSuccess(payload.id))
      yield put(
        fetchPostalCodeStart({
          page: 1,
          limit: 10,
          offset: 0,
          areaId: payload.areaId,
        })
      )
      yield put(
        openAlert({
          message: 'PostalCode deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deletePostalCodeFailure(err))
  }
}

export function* watchFetchPostalCode() {
  yield takeLatest('postalCode/fetchPostalCodeStart', fetchPostalCodeAsync)
}

export function* watchCreatePostalCode() {
  yield takeLatest('postalCode/createPostalCodeStart', createPostalCodeAsync)
}

export function* watchCreatePostalCodeByCsv() {
  yield takeLatest(
    'postalCode/createPostalCodeByCsvStart',
    createPostalCodeByCsvAsync
  )
}

export function* watchDeletePostalCode() {
  yield takeLatest('postalCode/deletePostalCodeStart', deletePostalCodeAsync)
}

export function* watchFetchPostalCodeById() {
  yield takeLatest(
    'postalCode/fetchPostalCodeByIdStart',
    fetchPostalCodeByIdAsync
  )
}

export function* watchEditPostalCode() {
  yield takeLatest('postalCode/editPostalCodeStart', editPostalCodeAsync)
}

export function* postalCodeSagas() {
  yield all([
    call(watchFetchPostalCode),
    call(watchCreatePostalCode),
    call(watchCreatePostalCodeByCsv),
    call(watchDeletePostalCode),
    call(watchFetchPostalCodeById),
    call(watchEditPostalCode),
  ])
}
