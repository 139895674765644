import axiosRequest from 'utils/axiosRequest'
import axios from 'axios'

export const getImageUrl = () => {
  return () =>
    axiosRequest
      .get(`/api/aws/s3/presignedurl/create`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

//////
export const uploadImage = (imageUrl, params) => {
  let headers = {
    'x-ms-blob-type': 'BlockBlob',
    'content-type': params?.type || 'application/octet-stream',
  }

  if (params?.type === 'image/png') {
    headers['content-type'] = 'image/png'
  } else if (params?.type === 'csv') {
    headers['content-type'] = 'text/csv'
  } else if (params?.type === 'image/jpeg') {
    headers['content-type'] = 'image/jpeg'
  } else if (params?.type === 'pdf') {
    headers['content-type'] = 'application/pdf'
  } else if (params?.type === 'video/mp4') {
    headers['content-type'] = 'video/mp4'
  }

  // return () =>
  //   axios
  //     .put(imageUrl, params, {
  //       headers: {
  //         'x-ms-blob-type': 'BlockBlob',
  //         'content-type': 'image/*',
  //       },
  //     })
  //     .then((res) => {
  //       return Promise.resolve(res)
  //     })
  //     .catch((err) => {
  //       return Promise.reject(err)
  //     })

  return () =>
    axios
      .put(imageUrl, params, {
        headers: headers,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const uploadPDF = (imageUrl, params) => {
  return () =>
    axios
      .put(imageUrl, params, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'content-type': 'application/pdf',
        },
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const uploadFile = (imageUrl, params) => {
  const fileType = params?.type

  let headers = {
    'x-ms-blob-type': 'BlockBlob',
    'content-type': params?.type || 'application/octet-stream',
  }

  if (params?.type === 'image/png') {
    headers['content-type'] = 'image/png'
  } else if (params?.type === 'csv') {
    headers['content-type'] = 'text/csv'
  } else if (params?.type === 'image/jpeg') {
    headers['content-type'] = 'image/jpeg'
  } else if (params?.type === 'pdf') {
    headers['content-type'] = 'application/pdf'
  }

  return () =>
    axios
      .put(imageUrl, params, {
        headers: headers,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteImage = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/aws/s3/object/delete?key=${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
