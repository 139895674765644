import { createSlice } from '@reduxjs/toolkit'

import { IContactUsState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IContactUsState = {
  contactUsList: listObj,
  soleContactUs: null,

  error: null,
  loading: false,
}

export const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState: INITIAL_STATE,
  reducers: {
    createContactUsStart: (state: any, action) => {
      state.loading = true
    },
    createContactUsSuccess: (state: any, action) => {
      state.loading = false
    },
    createContactUsFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  createContactUsStart,
  createContactUsSuccess,
  createContactUsFailure,
} = contactUsSlice.actions

export default contactUsSlice.reducer
