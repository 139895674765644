import { axiosRequest } from 'utils'

export const fetchCpGlobalFriendshipBeneficiaryList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/cpFriendshipBeneficiary/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCpGlobalFriendshipBeneficiaryById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/cpFriendshipBeneficiary/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCpGlobalFriendshipBeneficiary = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/cpFriendshipBeneficiary/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCpGlobalFriendshipBeneficiary = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/cpFriendshipBeneficiary/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCpGlobalFriendshipBeneficiary = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/cpFriendshipBeneficiary/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
