import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Edit, Delete, Visibility } from '@mui/icons-material'
import ConfirmDialogWithReason from 'components/ReusableModalComponents/ConfirmDialogWithReason'
import DeleteModal from '@crema/core/AppConfirmDialog'
import { Tooltip, IconButton, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AreaOwner } from 'types/models/AreaOwner'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import EditStateOwner from '../EditTerminal'

import { Link } from 'react-router-dom'
import RejectReasonViewer from 'components/RejectReasonViewer'
import { splitByCamelCase, formatDate } from 'utils'
import CloseIcon from '@mui/icons-material/Close'
import KeyIcon from '@mui/icons-material/Key'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  paddingLeft: 0,
  paddingTop: 9,
  paddingBottom: 9,
  lineHeight: 1.43,
  paddingRight: 20,
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  refreshPageData?: any
  count: number
  pageNumber?: number
  rowsPerPage?: number
  onDeleteClubStart?: (payload: any) => void
}

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  pageNumber,
  rowsPerPage,
  onDeleteClubStart,
  refreshPageData,
}) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [isEditDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [deactivateDialogOpen, setDeactivateDialogOpen] =
    useState<boolean>(false)

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState<boolean>(false)
  const [selectedStateOwnerId, setSelectedStateOwnerId] = useState<number>(null)
  const [toDeleteId, setToDeleteId] = useState<number>(null)
  const [reason, setReason] = useState<string>('')

  const [openReasonModal, setOpenReasonModal] = useState<boolean>(false)

  const [rejectReason, setRejectReason] = useState<string>('')

  const onDeleteSelectedContacts = () => {
    onDeleteClubStart({
      id: toDeleteId,
      rejectReason: reason,
      closeDeleteModal,
    })
    // setDeleteDialogOpen(false)
  }

  const closeDeleteModal = () => {
    setDeleteDialogOpen(false)
  }

  const onCloseEditDialog = () => {
    setEditDialogOpen(false)
  }

  const onCloseDeactivateDialog = () => {
    setDeactivateDialogOpen(false)
  }

  const onCloseChangePasswordDialog = () => {
    setChangePasswordDialogOpen(false)
  }

  const onSelectContactsForDelete = (id) => {
    setToDeleteId(id)
    setDeleteDialogOpen(true)
  }

  const reasonModalOpen = (data) => {
    setRejectReason(data)
    setOpenReasonModal(true)
  }
  return (
    <>
      <DeleteModal
        open={isDeleteDialogOpen}
        onDeny={setDeleteDialogOpen}
        onConfirm={onDeleteSelectedContacts}
        title="Are you sure you want to delete this club?"
        dialogTitle="Delete Club"
      />
      {isEditDialogOpen && (
        <ModalRenderer
          open={isEditDialogOpen}
          onDeny={onCloseEditDialog}
          onConfirm={onDeleteSelectedContacts}
          title=""
          dialogTitle="Edit Terminal"
          size="lg"
        >
          <EditStateOwner
            selectedStateOwnerId={selectedStateOwnerId}
            onCloseEditDialog={onCloseEditDialog}
            refreshPageData={refreshPageData}
          />
        </ModalRenderer>
      )}

      {openReasonModal && (
        <RejectReasonViewer
          open={openReasonModal}
          onDeny={setOpenReasonModal}
          defaultValue={rejectReason}
          title="Rejection Reason"
          dialogTitle="Rejection Details"
        />
      )}
      <TableRow key={data.sn} className="item-hover">
        <StyledTableCell align="left">
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.terminalName ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.createdAt ? formatDate(data?.createdAt) : '-'}
        </StyledTableCell>

        <StyledTableCell align="left">
          <Tooltip placement="top" title="Edit">
            <IconButton
              onClick={() => {
                setEditDialogOpen(true)
                setSelectedStateOwnerId(data?.id)
              }}
            >
              <Edit sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>{' '}
        </StyledTableCell>
      </TableRow>
    </>
  )
}

export default TableItem
