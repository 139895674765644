import React, { useState } from 'react'
import AppCard from '@crema/core/AppCard'
import {
  Box,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Button,
} from '@mui/material'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'

import { removeEmptyFields, formatDateRange } from 'utils'
import AddNewMember from '../AddTerminal'
import moment from 'moment-timezone'

const ListTop = ({
  setFilterFormData,
  clearFilterForm,
  globalCountry,
  currentUser,
  refreshPageData,
}) => {
  const [isAddCountryOpen, setAddCountryOpen] = useState<boolean>(false)

  const { userCountry } = currentUser

  const countries = [
    { label: 'Australia', year: 1994 },
    { label: 'United Kingdom', year: 1972 },
    { label: 'France', year: 1974 },
  ]

  const onOpenAddCountry = () => {
    setAddCountryOpen(true)
  }

  const onCloseAddCountry = () => {
    setAddCountryOpen(false)
  }

  const validationSchema = yup.object({})

  const onSubmitSearch = (data) => {
    const modifiedTurnedToPremium = data?.turnedToPremiumDate__between
      ? moment(new Date(data?.turnedToPremiumDate__between)).toString()
      : null

    setFilterFormData(
      removeEmptyFields({
        ...data,
      })
    )
  }

  const selectOption: any = [
    { label: 'Yes', name: 'premium' },
    { label: 'No', name: 'free' },
  ]

  return (
    <>
      <Button
        onClick={onOpenAddCountry}
        sx={{
          color: '#ffffff',
          display: 'flex',
          marginTop: '-1rem',
          marginLeft: 'auto',
          background: '#00bfcd',
          '&:hover': {
            background: '#ec4785',
          },
          px: 5,
          mb: 5,
        }}
      >
        Add Terminal
      </Button>
      <AppCard>
        <Box>
          <Formik
            validateOnChange={true}
            initialValues={{
              terminalName__substring: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)
              onSubmitSearch(data)

              setSubmitting(false)
            }}
          >
            {({ isSubmitting, resetForm, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'Name'}
                      label="Name"
                      name="terminalName__substring"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item>
                      <Button
                        size="small"
                        sx={{
                          px: 5,
                          color: '#ffffff',
                          backgroundColor: '#ec4785',
                          '&:hover': {
                            background: '#ec4785',
                          },
                        }}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        sx={{
                          px: 5,
                        }}
                        type="submit"
                        variant="outlined"
                        onClick={() => {
                          clearFilterForm()
                          resetForm()
                        }}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </AppCard>
      <AddNewMember
        isAddCountryOpen={isAddCountryOpen}
        onCloseAddCountry={onCloseAddCountry}
        refreshPageData={refreshPageData}
      />
      <Box sx={{ height: 30 }} />
    </>
  )
}

export default ListTop
