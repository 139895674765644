import { createSlice } from '@reduxjs/toolkit'

import { IReports } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IReports = {
  friendshipReportList: listObj,
  membershipReportList: listObj,
  wlMerchantSignerReportList: listObj,
  wlIssuerReportList: listObj,
  wlIssuerMembershipReportList: listObj,
  cpSignerIssuerFriendshipReportList: listObj,
  cpMerchantSignerFriendshipReportList: listObj,
  cpSignerIssuerMembershipReportList: listObj,
  error: null,
  loading: false,
  isFetching: false,
  hasMoreData: false,
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: INITIAL_STATE,
  reducers: {
    fetchFriendshipReportListStart: (state: any, action) => {
      state.loading = true
    },
    fetchFriendshipReportListSuccess: (state: any, action) => {
      state.friendshipReportList = action.payload
      state.loading = false
    },
    fetchFriendshipReportListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMembershipReportListStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipReportListSuccess: (state: any, action) => {
      state.membershipReportList = action.payload
      state.loading = false
    },
    fetchMembershipReportListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWlMerchantSignerReportListStart: (state: any, action) => {
      state.loading = true
    },
    fetchWlMerchantSignerReportListSuccess: (state: any, action) => {
      state.wlMerchantSignerReportList = action.payload
      state.loading = false
    },
    fetchWlMerchantSignerReportListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWlIssuerMembershipReportListStart: (state: any, action) => {
      state.loading = true
    },
    fetchWlIssuerMembershipReportListSuccess: (state: any, action) => {
      state.wlIssuerMembershipReportList = action.payload
      state.loading = false
    },
    fetchWlIssuerMembershipReportListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWlIssuerReportListStart: (state: any, action) => {
      state.loading = true
    },
    fetchWlIssuerReportListSuccess: (state: any, action) => {
      state.wlIssuerReportList = action.payload
      state.loading = false
    },
    fetchWlIssuerReportListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCpSignerIssuerFriendshipReportListStart: (state: any, action) => {
      state.loading = true
    },
    fetchCpSignerIssuerFriendshipReportListSuccess: (state: any, action) => {
      state.cpSignerIssuerFriendshipReportList = action.payload
      state.loading = false
    },
    fetchCpSignerIssuerFriendshipReportListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCpSignerIssuerMembershipReportListStart: (state: any, action) => {
      state.loading = true
    },
    fetchCpSignerIssuerMembershipReportListSuccess: (state: any, action) => {
      state.cpSignerIssuerMembershipReportList = action.payload
      state.loading = false
    },
    fetchCpSignerIssuerMembershipReportListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCpMerchantSignerFriendshipReportListStart: (state: any, action) => {
      state.loading = true
    },
    fetchCpMerchantSignerFriendshipReportListSuccess: (state: any, action) => {
      state.cpMerchantSignerFriendshipReportList = action.payload
      state.loading = false
    },
    fetchCpMerchantSignerFriendshipReportListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchFriendshipReportListStart,
  fetchFriendshipReportListSuccess,
  fetchFriendshipReportListFailure,

  fetchMembershipReportListStart,
  fetchMembershipReportListSuccess,
  fetchMembershipReportListFailure,

  fetchWlIssuerReportListStart,
  fetchWlIssuerReportListSuccess,
  fetchWlIssuerReportListFailure,

  fetchWlMerchantSignerReportListStart,
  fetchWlMerchantSignerReportListSuccess,
  fetchWlMerchantSignerReportListFailure,

  fetchWlIssuerMembershipReportListStart,
  fetchWlIssuerMembershipReportListSuccess,
  fetchWlIssuerMembershipReportListFailure,

  fetchCpSignerIssuerFriendshipReportListStart,
  fetchCpSignerIssuerFriendshipReportListSuccess,
  fetchCpSignerIssuerFriendshipReportListFailure,

  fetchCpMerchantSignerFriendshipReportListStart,
  fetchCpMerchantSignerFriendshipReportListSuccess,
  fetchCpMerchantSignerFriendshipReportListFailure,

  fetchCpSignerIssuerMembershipReportListStart,
  fetchCpSignerIssuerMembershipReportListSuccess,
  fetchCpSignerIssuerMembershipReportListFailure,
} = reportsSlice.actions

export default reportsSlice.reducer
