import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchGmcReferredCPStart,
  fetchGmcReferredCPSuccess,
  fetchGmcReferredCPFailure,
  deleteGmcReferredCPSuccess,
  deleteGmcReferredCPFailure,
  fetchGmcReferredCPByIdStart,
  fetchGmcReferredCPByIdSuccess,
  fetchGmcReferredCPByIdFailure,
  editGmcReferredCPSuccess,
  editGmcReferredCPFailure,
  rejectGmcReferredCPStart,
  rejectGmcReferredCPSuccess,
  rejectGmcReferredCPFailure,
  verifyGmcReferredCPStart,
  verifyGmcReferredCPSuccess,
  verifyGmcReferredCPFailure,
  saveGmcReferredCPStart,
  saveGmcReferredCPSuccess,
  saveGmcReferredCPFailure,
  fetchAllCpForBatchSuggestionSuccess,
  fetchAllCpForBatchSuggestionFailure,
} from './gmcReferredCpSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchGmcReferredCPList,
  fetchGmcReferredById,
  gmcReferredUpdate,
  rejectGmcReferred,
  verifyGmcReferred,
  saveGmcReferred,
} from '../../api/gmcCollaborationPartner'

import _ from 'lodash'
import { fetchAllCpForBatchSuggestion } from 'api/collaborationPartner'

export function* fetchGmcReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGmcReferredCPList(payload))
    yield put(fetchGmcReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGmcReferredCPFailure(err))
  }
}

export function* fetchGmcReferredByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGmcReferredById(payload))
    yield put(fetchGmcReferredCPByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGmcReferredCPByIdFailure(err))
  }
}

export function* gmcReferredUpdateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(gmcReferredUpdate(payload.id, payload))

    yield put(editGmcReferredCPSuccess(data?.updatedResult))
    yield put(
      openAlert({
        message: 'Referred Collaboration Partner succesfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editGmcReferredCPFailure(err))
  }
}

export function* rejectGmcReferredCPAsync({ payload }: AnyAction) {
  try {
    console.log('payload>>>', payload)
    const { data } = yield call(rejectGmcReferred(payload?.id, payload))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(rejectGmcReferredCPSuccess(payload?.id))

    yield payload?.navigate('/gmc/referredcplist')

    yield put(
      openAlert({ message: 'succesfully rejected', severity: 'success' })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(rejectGmcReferredCPFailure(err))
    console.error(err)
  }
}

export function* verifyGmcReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      verifyGmcReferred(payload?.params?.id, payload?.params)
    )

    yield put(verifyGmcReferredCPSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Referred Collaboration Partner succesfully verified',
        severity: 'success',
      })
    )
    payload?.navigate('/gmc/referredcplist')
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(verifyGmcReferredCPFailure(err))
    console.error(err)
  }
}

export function* saveGmcReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      saveGmcReferred(payload?.params?.id, payload?.params)
    )

    yield put(saveGmcReferredCPSuccess(data?.updateResult))
    yield put(
      openAlert({
        message: 'Referred Collaboration Partner succesfully saved',
        severity: 'success',
      })
    )
    payload?.navigate('/gmc/referredcplist')
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(saveGmcReferredCPFailure(err))
    console.error(err)
  }
}

export function* fetchAllCpForBatchSuggestionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllCpForBatchSuggestion(payload?.params))
    yield put(fetchAllCpForBatchSuggestionSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAllCpForBatchSuggestionFailure(err))
  }
}

export function* watchFetchGmcReferredCPList() {
  yield takeLatest(
    'gmcReferredCPList/fetchGmcReferredCPStart',
    fetchGmcReferredCPAsync
  )
}

export function* watchFetchGmcReferredById() {
  yield takeLatest(
    'gmcReferredCPList/fetchGmcReferredCPByIdStart',
    fetchGmcReferredByIdAsync
  )
}

export function* watchGmcReferredUpdate() {
  yield takeLatest(
    'gmcReferredCPList/editGmcReferredCPStart',
    gmcReferredUpdateAsync
  )
}

export function* watchRejectGmcReferredCP() {
  yield takeLatest(
    'gmcReferredCPList/rejectGmcReferredCPStart',
    rejectGmcReferredCPAsync
  )
}
export function* watchVerifyGmcReferredCP() {
  yield takeLatest(
    'gmcReferredCPList/verifyGmcReferredCPStart',
    verifyGmcReferredCPAsync
  )
}

export function* watchSaveGmcReferredCP() {
  yield takeLatest(
    'gmcReferredCPList/saveGmcReferredCPStart',
    saveGmcReferredCPAsync
  )
}

export function* watchFetchAllCpForBatchSuggestion() {
  yield takeLatest(
    'gmcReferredCPList/fetchAllCpForBatchSuggestionStart',
    fetchAllCpForBatchSuggestionAsync
  )
}

export function* gmcReferredCPSagas() {
  yield all([
    call(watchFetchGmcReferredCPList),
    call(watchFetchGmcReferredById),
    call(watchGmcReferredUpdate),
    call(watchRejectGmcReferredCP),
    call(watchVerifyGmcReferredCP),
    call(watchSaveGmcReferredCP),
    call(watchFetchAllCpForBatchSuggestion),
  ])
}
