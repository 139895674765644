import React, { ReactNode } from 'react'
import { HiOutlineGlobe } from 'react-icons/hi'
import {
  MdOutlineStorefront,
  MdOutlineHomeWork,
  MdOutlinePayment,
} from 'react-icons/md'
import { GrHome } from 'react-icons/gr'
import {
  FaUsers,
  FaGlobe,
  FaGlobeAmericas,
  FaHandshake,
  FaRegHospital,
} from 'react-icons/fa'

// import { RoutePermittedRole } from '../shared/constants/AppConst';
import { RoutePermittedRole } from '../../shared/constants/AppConst'
import { AttachMoney, HeadsetMic, PlayArrow, Policy } from '@mui/icons-material'
import { FiUser } from 'react-icons/fi'
import LogoutIcon from '@mui/icons-material/Logout'
import ArticleIcon from '@mui/icons-material/Article'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import FileCopyIcon from '@mui/icons-material/FileCopy'
export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const GlobalAdminWithinCountryRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            messageId: 'Dashboard',
            type: 'item',
            url: '/dashboard',
          },
        ],
      },
      {
        id: 'myAccount',
        title: 'myAccount',
        messageId: 'My Account',
        type: 'item',
        // icon: <PersonOutline />,
        icon: <FiUser />,
        url: '/my-account',
      },
      {
        id: 'members',
        title: 'Members',
        messageId: 'Members',
        type: 'item',
        icon: <FaUsers />,
        url: '/globaladmin/memberslist',
      },
      // {
      //   id: 'merchants',
      //   title: 'Merchants',
      //   messageId: 'Merchants',
      //   type: 'item',
      //   icon: <MdOutlineStorefront />,
      //   url: '/globaladmin/merchantslist',
      // },
      {
        id: 'Merchant',
        title: 'Merchants',
        messageId: 'Merchants',
        type: 'collapse',
        icon: <MdOutlineStorefront />,
        children: [
          // {
          //   id: 'sendMerchantRequest',
          //   title: 'Send Merchant Request',
          //   messageId: 'Send Merchant Request',
          //   type: 'item',
          //   url: '/globaladmin/sendMerchantRequest',
          // },
          {
            id: 'merchantList',
            title: 'Merchants List',
            messageId: 'Merchants List',
            type: 'item',
            url: '/globaladmin/merchantList',
          },
          {
            id: 'referredMerchantList',
            title: 'Referred Merchant List',
            messageId: 'Referred Merchant List',
            type: 'item',
            url: '/globaladmin/referredMerchantList',
          },
          // {
          //   id: 'sendMerchantEmail',
          //   title: 'Send Merchant Email',
          //   messageId: 'Send Merchant Email',
          //   type: 'item',
          //   url: '/globaladmin/sendMerchantEmail',
          // },
          // {
          //   id: 'draftList',
          //   title: 'Draft List',
          //   messageId: 'Draft List',
          //   type: 'item',
          //   url: '/globaladmin/draftList',
          // },
        ],
      },
      {
        id: 'stateowners',
        title: 'State Licensee',
        messageId: 'State Licensee',
        type: 'item',
        icon: <FaGlobe />,
        url: '/globaladmin/stateownerlist',
      },
      {
        id: 'regionowners',
        title: 'Region Licensee',
        messageId: 'Region Licensee',
        type: 'item',
        icon: <HiOutlineGlobe />,
        url: '/globaladmin/regionownerlist',
      },
      {
        id: 'areaowners',
        title: 'Area Licensee',
        messageId: 'Area Licensee',
        type: 'item',
        icon: <FaGlobeAmericas />,
        url: '/globaladmin/areaownerlist',
      },
      {
        id: 'salesAgents',
        title: 'Sales Agents',
        messageId: 'Sales Agents',
        type: 'item',
        icon: <FaUsers />,
        url: '/globaladmin/salesAgentList',
      },
      {
        id: 'collaborationpartners',
        title: 'Collaboration Partners',
        messageId: 'Collaboration Partners',
        type: 'item',
        icon: <FaHandshake />,
        url: '/globaladmin/country/collaborationpartnerlist',
      },
      {
        id: 'charitieslist',
        title: 'Charities',
        messageId: 'Charities',
        type: 'item',
        icon: <MdOutlineHomeWork />,
        url: '/globaladmin/charitieslist',
      },
      {
        id: 'clubList',
        title: 'Clubs',
        messageId: 'Clubs',
        type: 'item',
        icon: <MdOutlineHomeWork />,
        url: '/globaladmin/clublist',
      },
      {
        id: 'whitelebelsetupparent',
        title: 'White Labels',
        messageId: 'White Labels',
        type: 'item',
        icon: <FaRegHospital />,
        url: '/globaladmin/setup/whiteLabels',
      },
      {
        id: 'whitelabelsusers',
        title: 'whitelabelusers',
        messageId: 'White Label Users',
        type: 'item',
        icon: <FaUsers />,
        url: '/globaladmin/whitelabelusers',
      },
      {
        id: 'PremiumCodes',
        title: 'Codes',
        messageId: 'Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'premiummerchantcodes',
            title: 'Merchant Codes',
            messageId: 'Merchant Codes',
            type: 'item',
            url: '/globaladmin/premium-merchant-codes',
          },
          {
            id: 'premiummembercodes',
            title: 'Premium Member Codes',
            messageId: 'Premium Member Codes',
            type: 'item',
            url: '/globaladmin/premium-member-codes',
          },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'friendshipearning',
        title: 'FriendshipEarning',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          // {
          //   id: 'asPiiinkIssuer',
          //   title: 'AsPiiinkIssuer',
          //   messageId: 'As Piiink Issuer',
          //   type: 'item',
          //   url: '/globaladmin/friendship-earning/country/aspiiinkissuer',
          // },
          {
            id: 'asPiiinkCharity',
            title: 'AsPiiinkCharity',
            messageId: 'As Piiink Charity',
            type: 'item',
            url: '/globaladmin/friendship-earning/country/aspiiinkcharity',
          },

          {
            id: 'asCharity',
            title: 'AsCharity',
            messageId: 'As Charity',
            type: 'item',
            url: '/globaladmin/friendship-earning/country/ascharity',
          },

          {
            id: 'asAreaOwner',
            title: 'AsAreaOwner',
            messageId: 'As Area Licensee',
            type: 'item',
            url: '/globaladmin/friendship-earning/country/asareaowner',
          },
          {
            id: 'asRegionOwner',
            title: 'AsRegionOwner',
            messageId: 'As Region Licensee',
            type: 'item',
            url: '/globaladmin/friendship-earning/country/asregionowner',
          },
          {
            id: 'asStateOwner',
            title: 'AsStateOwner',
            messageId: 'As State Licensee',
            type: 'item',
            url: '/globaladmin/friendship-earning/country/asstateowner',
          },
          {
            id: 'asCountryOwner',
            title: 'asCountryOwner',
            messageId: 'As Country Licensee',
            type: 'item',
            url: '/globaladmin/friendship-earning/country/ascountryowner',
          },
          // {
          //   id: 'asPiiinkRegion',
          //   title: 'AsPiiinkRegion',
          //   messageId: 'As Piiink Region',
          //   type: 'item',
          //   url: '/globaladmin/friendship-earning/country/aspiiinkregion',
          // },
          // {
          //   id: 'aspiiinkarea',
          //   title: 'AsPiiinkArea',
          //   messageId: 'As Piiink Area',
          //   type: 'item',
          //   url: '/globaladmin/friendship-earning/country/aspiiinkarea',
          // },
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/globaladmin/friendship-earning/country/asissuer',
          },
          {
            id: 'asMerchantSigner',
            title: 'AsMerchantSigner',
            messageId: 'As Merchant Signer',
            type: 'item',
            url: '/globaladmin/friendship-earning/country/asmerchantsigner',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/globaladmin/friendship-earning/country/ascpsigner',
          },
        ],
      },

      {
        icon: <AttachMoney />,
        id: 'memberearning',
        title: 'memberhipEarning',
        messageId: 'Membership Earning',
        type: 'collapse',
        children: [
          // {
          //   id: 'asPiiinkIssuer',
          //   title: 'AsPiiinkIssuer',
          //   messageId: 'As Piiink Issuer',
          //   type: 'item',
          //   url: '/globaladmin/membership-earning/country/aspiiinkissuer',
          // },
          {
            id: 'asPiiinkCharity',
            title: 'AsPiiinkCharity',
            messageId: 'As Piiink Charity',
            type: 'item',
            url: '/globaladmin/membership-earning/country/aspiiinkcharity',
          },

          {
            id: 'asCharity',
            title: 'AsCharity',
            messageId: 'As Charity',
            type: 'item',
            url: '/globaladmin/membership-earning/country/ascharity',
          },

          {
            id: 'asAreaOwner',
            title: 'AsAreaOwner',
            messageId: 'As Area Licensee',
            type: 'item',
            url: '/globaladmin/membership-earning/country/asareaowner',
          },
          {
            id: 'asRegionOwner',
            title: 'AsRegionOwner',
            messageId: 'As Region Licensee',
            type: 'item',
            url: '/globaladmin/membership-earning/country/asregionowner',
          },
          {
            id: 'asStateOwner',
            title: 'AsStateOwner',
            messageId: 'As State Licensee',
            type: 'item',
            url: '/globaladmin/membership-earning/country/asstateowner',
          },
          {
            id: 'asCountryOwner',
            title: 'asCountryOwner',
            messageId: 'As Country Licensee',
            type: 'item',
            url: '/globaladmin/membership-earning/country/ascountryowner',
          },
          {
            id: 'asPiiinkRegion',
            title: 'AsPiiinkRegion',
            messageId: 'As Piiink Region',
            type: 'item',
            url: '/globaladmin/membership-earning/country/aspiiinkregion',
          },
          {
            id: 'aspiiinkarea',
            title: 'AsPiiinkArea',
            messageId: 'As Piiink Area',
            type: 'item',
            url: '/globaladmin/membership-earning/country/aspiiinkarea',
          },
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/globaladmin/membership-earning/country/asissuer',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/globaladmin/membership-earning/country/ascpsigner',
          },

          {
            id: 'asCharityClub',
            title: 'AsCharityClub',
            messageId: 'As Club/Charity Signer',
            type: 'item',
            url: '/globaladmin/membership-earning/country/asclubcharity',
          },
          // {
          //   id: 'fromMemberPremiumCode',
          //   title: 'fromMemberPremiumCode',
          //   messageId: 'From Member Premium Code',
          //   type: 'item',
          //   url: '/globaladmin/membership-earning/fromMemberPremiumCode',
          // },
        ],
      },

      {
        icon: <FileCopyIcon />,
        id: 'reports',
        title: 'Reports',
        messageId: 'Reports',
        type: 'collapse',
        children: [
          {
            id: 'friendshipReport',
            title: 'friendshipReport',
            messageId: 'Friendship Report',
            type: 'item',
            url: '/globaladmin/reports/friendship-report',
          },
          {
            id: 'membershipReport',
            title: 'membershipReport',
            messageId: 'Membership Report',
            type: 'item',
            url: '/globaladmin/reports/membership-report',
          },
          {
            id: 'wlFriendshipIssuerReport',
            title: 'wlFriendshipIssuerReport',
            messageId: 'WL Friendship Issuer Report',
            type: 'item',
            url: '/globaladmin/reports/wl-Friendship-issuer-report',
          },
          {
            id: 'wlMerchantSignerReport',
            title: 'wlMerchantSignerReport',
            messageId: 'WL Merchant Signer Report',
            type: 'item',
            url: '/globaladmin/reports/wl-merchant-signer-report',
          },

          {
            id: 'wlIssuerMembershipReport',
            title: 'wlIssuerMembershipReport',
            messageId: 'WL Issuer Membership Report',
            type: 'item',
            url: '/globaladmin/reports/wl-issuer-membership-report',
          },
          {
            id: 'cpSignerIssuerFriendshipReport',
            title: 'cpSignerIssuerFriendshipReport',
            messageId: 'CP Signer Issuer Friendship Report',
            type: 'item',
            url: '/globaladmin/reports/cp-signer-issuer-friendship-report',
          },

          {
            id: 'cpMerchantSignerFriendshipReport',
            title: 'cpMerchantSignerFriendshipReport',
            messageId: 'CP Merchant Signer Friendship Report',
            type: 'item',
            url: '/globaladmin/reports/cp-merchant-signer-friendship-report',
          },

          {
            id: 'cpSignerIssuerMembershipReport',
            title: 'cpSignerIssuerMembershipReport',
            messageId: 'CP Signer Issuer Membership Report',
            type: 'item',
            url: '/globaladmin/reports/cp-signer-issuer-membership-report',
          },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'merchantPackage',
        title: 'merchantPackage',
        messageId: 'Merchant Package',
        type: 'collapse',
        children: [
          {
            id: 'earning',
            title: 'earning',
            messageId: 'Earning',
            type: 'item',
            url: '/globaladmin/merchant-package/earning',
          },
          {
            id: 'payable',
            title: 'payable',
            messageId: 'Payable',
            type: 'item',
            url: '/globaladmin/merchant-package/payable',
          },
        ],
      },
      {
        icon: <PlayArrow />,
        id: 'training',
        title: 'training',
        messageId: 'Trainings',
        type: 'item',
        url: '/training-videos',
      },
      //panda docs section starts
      {
        id: 'documentManagement',
        title: 'document Management',
        messageId: 'Document Management',
        type: 'collapse',
        icon: <ContentPasteIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/globaladmin/setting/smtp',
          // },
          {
            id: 'group',
            title: 'Group',
            messageId: 'Group',
            type: 'item',
            url: '/globaladmin/setting/group',
          },

          {
            id: 'contacts',
            title: 'Contacts',
            messageId: 'Contacts',
            type: 'item',
            url: '/globaladmin/setting/contacts',
          },

          {
            id: 'documents',
            title: 'Documents',
            messageId: 'Documents',
            type: 'item',
            url: '/globaladmin/setting/documents',
          },

          {
            id: 'emails',
            title: 'Emails',
            messageId: 'Emails',
            type: 'item',
            url: '/globaladmin/setting/emails',
          },
        ],
      },

      //panda docs section ends
      {
        icon: <HeadsetMic />,
        id: 'support',
        title: 'support',
        messageId: 'Contact Piiink',
        type: 'item',
        url: '/support',
      },
      {
        icon: <Policy />,
        id: 'terms',
        title: 'terms',
        messageId: 'Terms & Conditions',
        type: 'item',
        url: '/terms',
      },
      {
        icon: <ArticleIcon />,
        id: 'reviews',
        title: 'reviews',
        messageId: 'Reviews',
        type: 'item',
        url: '/globaladmin/reviews',
      },

      {
        icon: <ReceiptIcon />,
        id: 'coupons',
        title: 'coupons',
        messageId: 'Coupons',
        type: 'item',
        url: '/globaladmin/coupons',
      },

      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },
    ],
  },
]
export default GlobalAdminWithinCountryRoutesConfig
