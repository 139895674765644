import React, { ReactNode } from 'react'
import { BiQuestionMark } from 'react-icons/bi'
import { FaRegHandshake } from 'react-icons/fa'

import { IoDocumentsOutline } from 'react-icons/io5'
import { RiServiceLine } from 'react-icons/ri'
import {
  MdOutlineStorefront,
  MdOutlineGroup,
  MdOutlinePhoneIphone,
  MdOutlineOpenInBrowser,
  MdOutlineQuestionAnswer,
} from 'react-icons/md'

import { MonetizationOn } from '@mui/icons-material'
import LanguageIcon from '@mui/icons-material/Language'
import LogoutIcon from '@mui/icons-material/Logout'
import GroupIcon from '@mui/icons-material/Group'

// import { RoutePermittedRole } from '../shared/constants/AppConst';
import { RoutePermittedRole } from '../../shared/constants/AppConst'
import VideocamIcon from '@mui/icons-material/Videocam'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const websiteAdminRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'Merchants',
        title: 'Merchants',
        messageId: 'Merchants',
        type: 'collapse',
        icon: <MdOutlineStorefront />,
        children: [
          {
            id: 'pendingMerchants',
            title: 'Pending Merchant Requests',
            messageId: 'Pending Merchant Requests',
            type: 'item',
            url: '/websiteadmin/pendingMerchantList',
          },
          // {
          //   id: 'draftList',
          //   title: 'Draft List',
          //   messageId: 'Draft List',
          //   type: 'item',
          //   url: '/websiteadmin/draftList',
          // },
          {
            id: 'merchantList',
            title: 'Merchant List',
            messageId: 'Merchant List',
            type: 'item',
            url: '/websiteadmin/merchantList',
          },
          // {
          //   id: 'sendMerchantEmail',
          //   title: 'Send Merchant Email',
          //   messageId: 'Send Merchant Email',
          //   type: 'item',
          //   url: '/websiteadmin/sendMerchantEmail',
          // },
        ],
      },
      {
        id: 'appslider',
        title: 'appslider',
        messageId: 'App Slider',
        type: 'item',
        icon: <MdOutlinePhoneIphone />,
        url: '/websiteadmin/appslider',
      },
      {
        id: 'country',
        title: 'Country',
        messageId: 'Country',
        type: 'item',
        icon: <LanguageIcon />,
        url: '/websiteadmin/country',
      },
      {
        id: 'webSlider',
        title: 'WebSlider',
        messageId: 'Web Slider',
        type: 'item',
        icon: <MdOutlineOpenInBrowser />,
        url: '/websiteadmin/webslider',
      },
      {
        id: 'partners',
        title: 'Partners',
        messageId: 'Partners',
        type: 'item',
        icon: <FaRegHandshake />,
        url: '/websiteadmin/partners',
      },
      {
        id: 'section',
        title: 'Section',
        messageId: 'Section',
        type: 'item',
        icon: <LanguageIcon />,
        url: '/websiteadmin/section',
      },
      {
        id: 'contentSection',
        title: 'ContentSection',
        messageId: 'Content Section',
        type: 'item',
        icon: <LanguageIcon />,
        url: '/websiteadmin/contentsection',
      },
      {
        id: 'homeVideo',
        title: 'HomeVideo',
        messageId: 'Home Video',
        type: 'item',
        icon: <VideocamIcon />,
        url: '/websiteadmin/homevideo',
      },
      {
        id: 'clients',
        title: 'Clients',
        messageId: 'Clients',
        type: 'item',
        icon: <RiServiceLine />,
        url: '/websiteadmin/clients',
      },
      {
        id: 'team',
        title: 'Teams',
        messageId: 'Teams',
        type: 'item',
        icon: <MdOutlineGroup />,
        url: '/websiteadmin/team',
      },
      {
        id: 'testimonial',
        title: 'Testimonials',
        messageId: 'Testimonials',
        type: 'item',
        icon: <MdOutlineQuestionAnswer />,
        url: '/websiteadmin/testimonial',
      },
      {
        id: 'multilingual',
        title: 'Multilingual',
        messageId: 'Multilingual',
        type: 'item',
        icon: <LanguageIcon />,
        url: '/websiteadmin/multilingual',
      },
      {
        id: 'MenuItem',
        title: 'MenuItem',
        messageId: 'MenuItem',
        type: 'item',
        icon: <LanguageIcon />,
        url: '/websiteadmin/menuItem',
      },

      {
        id: 'page',
        title: 'Page',
        messageId: 'Pages',
        type: 'item',
        icon: <IoDocumentsOutline />,
        url: '/websiteadmin/page',
      },

      {
        id: 'aboutUs',
        title: 'AboutUs',
        messageId: 'About Us',
        type: 'item',
        icon: <LanguageIcon />,
        url: '/websiteadmin/aboutUs',
      },
      {
        id: 'socialMedia',
        title: 'socialMedia',
        messageId: 'Social Media',
        type: 'item',
        icon: <GroupIcon />,
        url: '/websiteadmin/socialmedia',
      },

      {
        id: 'faq',
        title: 'Faq',
        messageId: 'Faq',
        type: 'item',
        icon: <BiQuestionMark />,
        url: '/websiteadmin/faq',
      },

      {
        icon: <MonetizationOn />,
        id: 'prizedraw',
        title: 'prizedraw',
        messageId: 'Prize Draw',
        type: 'item',
        url: '/prizedraw',
      },

      {
        icon: <PlayArrowIcon />,
        id: 'media',
        title: 'media',
        messageId: 'Media',
        type: 'item',
        url: '/websiteadmin/media',
      },
      // {
      //   id: 'constant',
      //   title: 'Constant',
      //   messageId: 'Constant',
      //   type: 'item',
      //   icon: <LanguageIcon />,
      //   url: '/websiteadmin/constant',
      // },
      // {
      //   icon: <ReceiptIcon />,
      //   id: 'coupons',
      //   title: 'coupons',
      //   messageId: 'Coupons',
      //   type: 'item',
      //   url: '/websiteadmin/coupons',
      // },

      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },
    ],
  },
]
export default websiteAdminRoutesConfig
