import React from 'react'
import Drawer from '@mui/material/Drawer'
import Hidden from '@mui/material/Hidden'
import clsx from 'clsx'
import { toggleNavCollapsed } from '../../../../store/settings/settingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import AppScrollbar from '../../AppScrollbar'
import VerticalNav from '../components/VerticalNav'
import MainSidebar from '../components/MainSidebar'
import { useLayoutContext } from '../../../utility/AppContextProvider/LayoutContextProvider'
import UserInfo from '../components/UserInfo'
import { useSidebarContext } from '../../../utility/AppContextProvider/SidebarContextProvider'
import { connect } from 'react-redux'

interface AppSidebarProps {
  position?: 'left' | 'top' | 'right' | 'bottom'
  variant?: string
  settings?: any
}

const AppSidebar: React.FC<AppSidebarProps> = ({
  variant = '',
  position = 'left',
  settings,
}) => {
  const dispatch = useDispatch()
  const navCollapsed = settings.navCollapsed
  const { footer, footerType } = useLayoutContext()

  const { sidebarTextColor } = useSidebarContext()

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed())
  }
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor={position}
          open={navCollapsed}
          onClose={() => handleToggleDrawer()}
          classes={{
            root: clsx(variant),
            paper: clsx(variant),
          }}
          style={{ position: 'absolute' }}
        >
          <MainSidebar>
            <UserInfo color={sidebarTextColor} />
            <AppScrollbar
              sx={{
                // overflow: 'scroll',
                py: 2,
                height: 'calc(100vh - 70px) !important',
                borderTop: (theme: { palette: { divider: string } }) =>
                  `solid 1px ${theme.palette.divider}`,
                mt: 0.5,
              }}
            >
              <VerticalNav />
            </AppScrollbar>
          </MainSidebar>
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <MainSidebar>
          <UserInfo color={sidebarTextColor} />
          <AppScrollbar
            className={clsx({
              'has-footer-fixed': footer && footerType === 'fixed',
            })}
            sx={{
              // scroll: 'hidden',
              py: 2,
              height: 'calc(100vh - 70px) !important',
              borderTop: (theme: { palette: { divider: string } }) =>
                `solid 1px ${theme.palette.divider}`,
              mt: 0.5,
              '&.has-footer-fixed': {
                height: {
                  xs: 'calc(100vh - 117px) !important',
                  xl: 'calc(100vh - 127px) !important',
                },
              },
            }}
          >
            <VerticalNav />
          </AppScrollbar>
        </MainSidebar>
      </Hidden>
    </>
  )
}

const mapStateToProps = (state, props) => ({
  settings: state.settings,
})

const container = connect(mapStateToProps, null)

export default container(AppSidebar)
