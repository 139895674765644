import { createSlice } from '@reduxjs/toolkit'

import { IGmcReferredCP } from '../interfaces'

import { listObj } from 'utils'

const INITIAL_STATE: IGmcReferredCP = {
  gmcReferredCPList: listObj,
  cpSuggestionList: listObj,
  soleGmcReferredCP: {},
  error: null,
  loading: false,
  isFetching: false,
  isRejecting: false,
  isVerifing: false,
  hasMoreData: false,
}

export const gmcReferredCPSlice = createSlice({
  name: 'gmcReferredCPList',
  initialState: INITIAL_STATE,
  reducers: {
    fetchGmcReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchGmcReferredCPSuccess: (state: any, action) => {
      state.gmcReferredCPList = action.payload
      state.loading = false
    },
    fetchGmcReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchGmcReferredCPByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchGmcReferredCPByIdSuccess: (state: any, action) => {
      state.soleGmcReferredCP = action.payload
      state.isFetching = false
    },
    fetchGmcReferredCPByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    rejectGmcReferredCPStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectGmcReferredCPSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.gmcReferredCPList.data = state.gmcReferredCPList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    rejectGmcReferredCPFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },
    deleteGmcReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    deleteGmcReferredCPSuccess: (state: any, action) => {
      state.loading = false
      state.gmcReferredCPList = state.gmcReferredCPList.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteGmcReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editGmcReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    editGmcReferredCPSuccess: (state: any, action) => {
      const result = state.gmcReferredCPList.results.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.gmcReferredCPList = result

      state.loading = false
    },
    editGmcReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    verifyGmcReferredCPStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifyGmcReferredCPSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isVerifing = false
      state.gmcReferredCPList.data = state.gmcReferredCPList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    verifyGmcReferredCPFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },

    saveGmcReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    saveGmcReferredCPSuccess: (state: any, action) => {
      state.loading = false
      state.gmcReferredCPList.data = state.gmcReferredCPList.data.filter(
        (item) => item.id == action.payload
      )
    },
    saveGmcReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllCpForBatchSuggestionStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllCpForBatchSuggestionSuccess: (state: any, action) => {
      state.cpSuggestionList = action.payload
      state.isFetching = false
    },
    fetchAllCpForBatchSuggestionFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const {
  fetchGmcReferredCPStart,
  fetchGmcReferredCPSuccess,
  fetchGmcReferredCPFailure,
  rejectGmcReferredCPStart,
  rejectGmcReferredCPSuccess,
  rejectGmcReferredCPFailure,
  deleteGmcReferredCPStart,
  deleteGmcReferredCPSuccess,
  deleteGmcReferredCPFailure,
  fetchGmcReferredCPByIdStart,
  fetchGmcReferredCPByIdSuccess,
  fetchGmcReferredCPByIdFailure,
  editGmcReferredCPStart,
  editGmcReferredCPSuccess,
  editGmcReferredCPFailure,
  verifyGmcReferredCPStart,
  verifyGmcReferredCPSuccess,
  verifyGmcReferredCPFailure,
  saveGmcReferredCPStart,
  saveGmcReferredCPSuccess,
  saveGmcReferredCPFailure,
  fetchAllCpForBatchSuggestionStart,
  fetchAllCpForBatchSuggestionSuccess,
  fetchAllCpForBatchSuggestionFailure,
} = gmcReferredCPSlice.actions

export default gmcReferredCPSlice.reducer
