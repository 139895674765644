import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {
  Edit,
  Delete,
  PlayArrowOutlined,
  PlayArrow,
  PlayCircle,
} from '@mui/icons-material'
import AppConfirmDialog from '@crema/core/AppConfirmDialog'
import AppDialog from '@crema/core/AppDialog'
import { Tooltip, IconButton, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { camelCaseToSpace, formatDate } from 'utils'
import _ from 'lodash'
// import { Link } from 'react-router-dom'
import { Link } from '@mui/material'

// const StyledTableCell = styled(TableCell)(() => ({
//   fontSize: 14,
//   padding: 10,
//   paddingRight: 20,
//   '&:first-of-type': {
//     paddingLeft: 30,
//   },
//   '&:last-of-type': {
//     paddingRight: 30,
//   },
// }))

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 0,
  paddingRight: 20,
  '&:first-of-type': {
    paddingLeft: 30,
    paddingTop: 14,
    paddingBottom: 14,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  count: number
  page: any
  pageNumber: number
  rowsPerPage: any
  onOpenCreateLuckyDraw?: any
  onOpenLuckDraw?: any
  setLuckyDrawOpen?: any
  onDeleteTrainingVideoStart: (id: number) => void
  onCloseLuckDraw?: () => void
  isLuckDrawOpen?: boolean
  selectedPrizeDraw?: any
  auth?: any
}

interface Props {
  isViewOpen: boolean
  data: any
  onCloseViewDescription: () => void
}

const ViewDescription: React.FC<Props> = ({
  data,
  isViewOpen,
  onCloseViewDescription,
}) => {
  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isViewOpen}
      onClose={() => onCloseViewDescription()}
      title="View Prize Description"
    >
      <Typography>{data?.prizeDescription}</Typography>
    </AppDialog>
  )
}

// const MemoizedGetPrizeWinner = React.memo(GetPrizeWinner)

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  page,
  pageNumber,
  rowsPerPage,
  onDeleteTrainingVideoStart,
  onOpenCreateLuckyDraw,
  onCloseLuckDraw,
  isLuckDrawOpen,
  setLuckyDrawOpen,
  onOpenLuckDraw,
  selectedPrizeDraw,
  auth,
}) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [toDeleteId, setDeleteToDeleteId] = useState<number>()

  const [isViewOpen, setViewOpen] = useState<boolean>(false)
  // const [selectedPrizeDraw, setSelectedPrizeDraw] = useState<Object>({})

  const [selectedTempData, setSelectedTempData] = useState(null)

  const onOpenViewDescription = (data) => {
    setViewOpen(true)
  }

  const onCloseViewDescription = () => {
    setViewOpen(false)
  }

  const onSelectContactsForDelete = (contactIds: number[]) => {
    setDeleteDialogOpen(true)
  }

  const handleClick = (data) => {
    setSelectedTempData(data)
  }

  return (
    <>
      <TableRow key={data.sn} className="item-hover">
        <StyledTableCell align="left">
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>
        {/* <StyledTableCell align="left">
          {data?.__country__ ? data?.__country__?.countryName : '-'}
        </StyledTableCell> */}
        <StyledTableCell align="left">
          {data?.prizeName ? data?.prizeName : '-'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.iteration === 0 || data?.iteration
            ? parseInt(data?.iteration)
            : '-'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {
            <>
              <Tooltip placement="top" title="View Description">
                <IconButton
                  sx={{ fontSize: '14px', color: '#00bfcd' }}
                  onClick={() => onOpenViewDescription(data)}
                >
                  View
                </IconButton>
              </Tooltip>
            </>
          }
        </StyledTableCell>

        <StyledTableCell align="left">
          {formatDate(data?.createdAt)}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.__member__?.firstname ?? '-'}{' '}
          {data?.__member__?.lastname ?? '-'}{' '}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.__member__?.email ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {' '}
          {data?.__member__?.phoneNumber ?? '-'}
        </StyledTableCell>

        {/* <StyledTableCell
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          align="left"
        >
          {_.isEmpty(data?.memberId) &&
            data?.organizerType !== 'globaladmin' &&
            !data?.limitReached && (
              <>
                <Tooltip placement="top" title="Get Winner">
                  <IconButton
                    sx={{ fontSize: '14px', color: '#00bfcd' }}
                    onClick={() => {
                      handleClick(data)
                      onOpenCreateLuckyDraw(data)
                    }}
                  >
                    Get Winner
                  </IconButton>
                </Tooltip>
              </>
            )}
        </StyledTableCell> */}

        <StyledTableCell
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          align="left"
        >
          {data?.organizerType.toLowerCase() !== 'globaladmin' &&
          !data?.limitReached &&
          _.isEmpty(data?.memberId) ? (
            <>
              <Tooltip placement="top" title="Get Winner">
                <IconButton
                  sx={{ fontSize: '14px', color: '#00bfcd' }}
                  onClick={() => {
                    handleClick(data)
                    onOpenCreateLuckyDraw(data)
                  }}
                >
                  Get Winner
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <StyledTableCell
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                align="left"
              >
                -
              </StyledTableCell>
            </>
          )}
        </StyledTableCell>

        <StyledTableCell
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          align="left"
        >
          {_.isEmpty(data?.memberId) ||
            data?.organizerType !== 'globaladmin' || (
              <>
                <Tooltip placement="top" title="Get Winner">
                  <IconButton
                    sx={{ fontSize: '14px', color: '#00bfcd' }}
                    onClick={() => {
                      handleClick(data)
                      onOpenCreateLuckyDraw(data)
                    }}
                  >
                    -
                  </IconButton>
                </Tooltip>
              </>
            )}
        </StyledTableCell>
      </TableRow>

      {isViewOpen && (
        <ViewDescription
          isViewOpen={isViewOpen}
          data={data}
          onCloseViewDescription={onCloseViewDescription}
        />
      )}
    </>
  )
}

export default TableItem
