import axios from 'axios'

export const fetchPosts = () => {
  return () =>
    axios
      .get('https://jsonplaceholder.typicode.com/posts')
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
