import { createSlice } from '@reduxjs/toolkit'

import { IAuthPasswordReset } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IAuthPasswordReset = {
  isAuthenticated: false,
  currentUser: {},
  newUser: {},
  error: null,
  loading: false,
}

export const forgotPasswordSlice = createSlice({
  name: 'passwordReset',
  initialState: INITIAL_STATE,
  reducers: {
    resetGlobalAdminPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetGlobalAdminPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetGlobalAdminPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    resetRegionOwnerPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetRegionOwnerPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetRegionOwnerPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    resetAreaOwnerPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetAreaOwnerPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetAreaOwnerPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    resetCountryAdminPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetCountryAdminPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetCountryAdminPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    resetGmcAdminPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetGmcAdminPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetGmcAdminPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    resetStateOwnerPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetStateOwnerPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetStateOwnerPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    resetCharityPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetCharityPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetCharityPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    resetClubPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetClubPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetClubPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    resetSalesAgentPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetSalesAgentPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetSalesAgentPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    resetMerchantPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetMerchantPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetMerchantPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    resetMerchantFranchisePasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetMerchantFranchisePasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetMerchantFranchisePasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    resetWhiteLabelPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetWhiteLabelPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetWhiteLabelPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    resetCPPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetCPPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetCPPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    resetWebsiteAdminPasswordStart: (state: any, action) => {
      state.loading = true
    },
    resetWebsiteAdminPasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    resetWebsiteAdminPasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  resetGlobalAdminPasswordStart,
  resetGlobalAdminPasswordSuccess,
  resetGlobalAdminPasswordFailure,
  resetRegionOwnerPasswordStart,
  resetRegionOwnerPasswordSuccess,
  resetRegionOwnerPasswordFailure,
  resetAreaOwnerPasswordStart,
  resetAreaOwnerPasswordSuccess,
  resetAreaOwnerPasswordFailure,
  resetCountryAdminPasswordStart,
  resetCountryAdminPasswordSuccess,
  resetCountryAdminPasswordFailure,
  resetStateOwnerPasswordStart,
  resetStateOwnerPasswordSuccess,
  resetStateOwnerPasswordFailure,
  resetGmcAdminPasswordStart,
  resetGmcAdminPasswordSuccess,
  resetGmcAdminPasswordFailure,
  resetCharityPasswordStart,
  resetCharityPasswordSuccess,
  resetCharityPasswordFailure,
  resetClubPasswordStart,
  resetClubPasswordSuccess,
  resetClubPasswordFailure,
  resetSalesAgentPasswordStart,
  resetSalesAgentPasswordSuccess,
  resetSalesAgentPasswordFailure,
  resetMerchantPasswordStart,
  resetMerchantPasswordSuccess,
  resetMerchantPasswordFailure,

  resetMerchantFranchisePasswordStart,
  resetMerchantFranchisePasswordSuccess,
  resetMerchantFranchisePasswordFailure,

  resetWhiteLabelPasswordStart,
  resetWhiteLabelPasswordSuccess,
  resetWhiteLabelPasswordFailure,
  resetCPPasswordStart,
  resetCPPasswordSuccess,
  resetCPPasswordFailure,
  resetWebsiteAdminPasswordStart,
  resetWebsiteAdminPasswordSuccess,
  resetWebsiteAdminPasswordFailure,
} = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer
