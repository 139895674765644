import { createSlice } from '@reduxjs/toolkit'

import { IeoiOwnerState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IeoiOwnerState = {
  eoiCountryOwnerList: listObj,
  soleEoiCountryOwner: {},
  error: null,
  loading: false,
  isFetching: false,
  isEditing: false,
  isVerifing: false,
  isRejecting: false,
  isDeleting: false,
  hasMoreData: false,
}

export const eoiCountryOwnerSlice = createSlice({
  name: 'eoiCountryOwner',
  initialState: INITIAL_STATE,
  reducers: {
    fetchEoiCountryOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchEoiCountryOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.eoiCountryOwnerList = action.payload
      state.loading = false
    },
    fetchEoiCountryOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchEoiCountryOwnerByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchEoiCountryOwnerByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleEoiCountryOwner = action.payload

      state.isFetching = false
    },
    fetchEoiCountryOwnerByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    deleteEoiCountryOwnerStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteEoiCountryOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
      state.eoiCountryOwnerList.data = state.eoiCountryOwnerList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteEoiCountryOwnerFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    deleteCountryOwnerContactStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteCountryOwnerContactSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteCountryOwnerContactFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    editEoiCountryOwnerStart: (state: any, action) => {
      state.isEditing = true
    },
    editEoiCountryOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      const result = state.eoiCountryOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.eoiCountryOwnerList.data = result
      state.isEditing = false
    },
    editEoiCountryOwnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    verifyEoiCountryOwnerStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifyEoiCountryOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isVerifing = false
      // state.eoiCountryOwnerList.data = state.eoiCountryOwnerList.data.filter(
      //   (item) => item.id !== action.payload
      // )

      state.isEditing = false
      const result = state?.eoiCountryOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.eoiCountryOwnerList.data = result
    },
    verifyEoiCountryOwnerFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },
    rejectEoiCountryOwnerStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectEoiCountryOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.eoiCountryOwnerList.data = state.eoiCountryOwnerList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    rejectEoiCountryOwnerFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },
    updateEoiCountryOwnerStart: (state: any, action) => {
      state.isEditing = true
    },
    updateEoiCountryOwnerSuccess: (state: any, action) => {
      const result = state.eoiCountryOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.eoiCountryOwnerList.data = result
      state.isEditing = false
    },
    updateEoiCountryOwnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
  },
})

export const {
  fetchEoiCountryOwnerStart,
  fetchEoiCountryOwnerSuccess,
  fetchEoiCountryOwnerFailure,
  deleteEoiCountryOwnerStart,
  deleteEoiCountryOwnerSuccess,
  deleteEoiCountryOwnerFailure,
  fetchEoiCountryOwnerByIdStart,
  fetchEoiCountryOwnerByIdSuccess,
  fetchEoiCountryOwnerByIdFailure,
  editEoiCountryOwnerStart,
  editEoiCountryOwnerSuccess,
  editEoiCountryOwnerFailure,
  verifyEoiCountryOwnerStart,
  verifyEoiCountryOwnerSuccess,
  verifyEoiCountryOwnerFailure,
  rejectEoiCountryOwnerStart,
  rejectEoiCountryOwnerSuccess,
  rejectEoiCountryOwnerFailure,
  updateEoiCountryOwnerStart,
  updateEoiCountryOwnerSuccess,
  updateEoiCountryOwnerFailure,
  deleteCountryOwnerContactStart,
  deleteCountryOwnerContactSuccess,
  deleteCountryOwnerContactFailure  

} = eoiCountryOwnerSlice.actions

export default eoiCountryOwnerSlice.reducer
