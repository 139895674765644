/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { fetchPostStart } from 'store/post/postSlice'

import { fetchClubByIdStart, editClubStart } from 'store/club/clubSlice'
import {
  referMerchantMailFromDraftStart,
  setMerchantPopularStart,
} from 'store/merchant/merchantSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  dropDown: state.dropDown,
  areaOwner: state.areaOwner,
  stateOwner: state.stateOwner,
  charities: state.charities,
  siteCoordinator: state.siteCoordinator,
  merchant: state.merchant,
  club: state.club,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchClubByIdStart: (payload) => dispatch(fetchClubByIdStart(payload)),
  onEditClubStart: (payload) => dispatch(editClubStart(payload)),
  onSetMerchantPopularStart: (payload) =>
    dispatch(setMerchantPopularStart(payload)),
  onReferMerchantMailFromDraftStart: (payload) =>
    dispatch(referMerchantMailFromDraftStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
