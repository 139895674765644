import React, { ReactNode } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Divider,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { Fonts } from 'shared/constants/AppEnums'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  // eslint-disable-next-line no-undef
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface AppConfirmDialogProps {
  dialogTitle: string | ReactNode
  open: boolean
  onDeny: (isOpen: boolean) => void
  title: string | ReactNode
  onConfirm: () => void
  size?: string
  children: any
  showCloseIcon?: boolean
}

const AppConfirmDialog: React.FC<AppConfirmDialogProps | any> = ({
  open,
  onDeny,
  size,
  children,
  dialogTitle,
  showCloseIcon,
}) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth={size ? size : 'lg'}
      onClose={() => onDeny(false)}
    >
      <DialogTitle>
        {showCloseIcon && (
          <IconButton
            aria-label="close"
            onClick={() => onDeny(false)}
            sx={{
              position: 'absolute',
              right: -4,
              top: -5,
              color: (theme) => theme.palette.grey[600],
              padding: 2,
              fontSize: 16,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <Typography
          component="h4"
          variant="h4"
          sx={{
            mb: 3,
            fontWeight: Fonts.SEMI_BOLD,
          }}
          id="alert-dialog-title"
        >
          {dialogTitle}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent sx={{ fontSize: 14 }} id="alert-dialog-description">
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default AppConfirmDialog
