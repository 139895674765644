import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material'

import container from './DiscountOffered.container'
import { Form, Formik, Field } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import WeekDaysSelect from './components/WeekDaysSelect'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import * as yup from 'yup'

const AddNewClub: React.FC<any> = ({
  onCloseAddCountry,
  siteCoordinator: { recentMerchant },
  merchant: {
    isCreatingDraft,
    isCreatingGeneral,
    isFetching,
    generalDiscountInfo,
  },
  handleChangeTab,
  onFetchMerchantGeneralDiscountInfoPanelUserStart,
  onCreateStep4GeneralStart,
  onCreateStep4DraftStart,
  adminRoute,
  onClearMerchantPremiumCode,
}) => {
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      onFetchMerchantGeneralDiscountInfoPanelUserStart(id)
    }
  }, [id])

  useEffect(() => {
    onClearMerchantPremiumCode()
  }, [])

  const [tempLoader, setTempLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  const [dateWiseTime, setDateWiseTime] = useState<any | null>({
    Monday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Tuesday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Wednesday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Thursday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Friday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Saturday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Sunday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (!isFetching && !_.isEmpty(generalDiscountInfo) && id) {
      setTimeout(() => {
        setDateWiseTime(generalDiscountInfo?.discountRates)
      }, 2000)
    }
  }, [generalDiscountInfo])

  const closeModal = () => {
    onCloseAddCountry()
  }
  const validationSchema = yup.object({})

  const changedTimeByDay = (values) => {
    if (
      !_.isEmpty(values?.day) &&
      values?.discountForAllHours >= 0 &&
      values?.discountForAllHours !== ''
    ) {
      //changed all the time on the object list

      if (values?.day?.name === 'all') {
        const duplicateObject = { ...dateWiseTime }

        const newObj = Object.entries(duplicateObject).reduce((acc, [k, v]) => {
          const newVal = Object.entries(v).reduce((acc, [k, v]) => {
            return { ...acc, [k]: values?.discountForAllHours }
          }, {})
          return { ...acc, [k]: newVal }
        }, {})
        setDateWiseTime(newObj)
      } else {
        const duplicateObject = { ...dateWiseTime }
        const modifiedWeekTime = Object.keys(
          duplicateObject[values?.day?.label]
        ).map(
          (name: any) =>
            (duplicateObject[name?.label] = values?.discountForAllHours)
        )

        const convertToObject = Object.assign({}, modifiedWeekTime)

        const lastObject = { [values?.day?.label]: convertToObject }

        const newObj = Object.entries({
          ...duplicateObject,
          ...lastObject,
        }).reduce((acc, [key, val]) => {
          if (key !== 'undefined') {
            return { ...acc, [key]: val }
          } else return acc
        }, {})

        setDateWiseTime(newObj)
      }
    }
  }

  const changedTimeByHour = (day, hour, event) => {
    const dayChanged = {
      [day]: {
        ...dateWiseTime[day],
        [hour]: event.target.value ? parseFloat(event.target.value) : 0,
      },
    }

    setDateWiseTime({ ...dateWiseTime, ...dayChanged })
  }

  const objectIterate = (): any => {
    return Object.keys(dateWiseTime).map(function (key) {
      let day = key
      return (
        <>
          <Grid container sx={{ mt: 3 }}>
            <Grid item container>
              <Typography variant="h4" key={key}>
                {key}
              </Typography>
            </Grid>
            <Grid item container>
              {Object.entries(dateWiseTime[key]).map((key: any, value: any) => {
                return (
                  <Typography variant="h5" key={key} sx={{ mr: 4, mt: 3 }}>
                    <span style={{ marginLeft: '10px' }}>{`${key[0]}:00`}</span>
                    <br />
                    <TextField
                      id="outlined-basic"
                      label=""
                      type="number"
                      variant="outlined"
                      size="small"
                      onChange={(e) => changedTimeByHour(day, key[0], e)}
                      defaultValue={key[1]}
                    />
                  </Typography>
                )
              })}
            </Grid>
          </Grid>
        </>
      )
    })
  }

  return (
    <>
      {tempLoader ? (
        '...loading'
      ) : (
        <Formik
          validateOnChange={true}
          initialValues={{
            discountForAllHours: null,
            day: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data: any, { setSubmitting }) => {
            setSubmitting(true)

            if (data?.action === 'draft') {
              onCreateStep4DraftStart({
                params: {
                  discountRates: dateWiseTime,

                  merchantId: id ? id : recentMerchant?.id,
                },
                closeModal,
                navigate,
                handleChangeTab,
                route: `/${adminRoute}/draftList`,
              })
            } else if (data?.action === 'general') {
              onCreateStep4GeneralStart({
                params: {
                  discountRates: dateWiseTime,

                  merchantId: id ? id : recentMerchant?.id,
                },
                closeModal,
                handleChangeTab,
                refreshTab: true,
              })
            }

            setSubmitting(false)
          }}
          render={({ values, setFieldValue }) => (
            <Form>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Add Discount</Typography>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Discount for all hours'}
                      label={'Discount for all hours'}
                      name="discountForAllHours"
                      variant="outlined"
                      type="number"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <Field name="day" component={WeekDaysSelect} />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <Button
                      sx={{
                        color: '#ffffff',
                        display: 'flex',
                        background: '#00bfcd',
                        '&:hover': {
                          background: '#ec4785',
                        },
                        px: 5,
                      }}
                      type="button"
                      onClick={() => changedTimeByDay(values)}
                    >
                      Change
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>{objectIterate()}</Grid>

                <Box sx={{ height: 20 }} />
                <Box sx={{ height: 30 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isCreatingDraft}
                    onClick={() => {
                      setFieldValue('action', 'draft')
                    }}
                  >
                    {isCreatingDraft ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Save As Draft'
                    )}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    onClick={() => {
                      setFieldValue('action', 'general')
                    }}
                    disabled={isCreatingGeneral}
                  >
                    {isCreatingGeneral ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Save & Continue'
                    )}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    onClick={() => navigate(`/${adminRoute}/merchantList`)}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        />
      )}
    </>
  )
}

export default container(AddNewClub)
