// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchCountryOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/countryOwner/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryOwnerById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/countryOwner/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCountryOwner = (params) => {
  return () =>
    axiosRequest
      .post(`/api/countryOwner/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCountryOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/countryOwner/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCountryOwner = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/countryOwner/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryOwnerProfileInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/countryOwner/getOwnProfile`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCountryOwnerAgreement = (id, params) => {
  return () =>
    axiosRequest
      .patch(`/api/countryOwner/saveAgreement/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editOwnCountryProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/countryOwner/editOwnProfile`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
