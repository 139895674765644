import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchWhiteLabelReferredCPStart,
  fetchWhiteLabelReferredCPSuccess,
  fetchWhiteLabelReferredCPFailure,
} from './whiteLabelReferredCpSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import { fetchWhiteLabelReferredCPList } from '../../../api/WhiteLabelAdmin/whiteLabelReferredCP'

import _ from 'lodash'

export function* fetchWhiteLabelReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelReferredCPList(payload))
    yield put(fetchWhiteLabelReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelReferredCPFailure(err))
  }
}

export function* watchFetchWhiteReferredCPList() {
  yield takeLatest(
    'whiteLabelReferredCPList/fetchWhiteLabelReferredCPStart',
    fetchWhiteLabelReferredCPAsync
  )
}

export function* whiteLabelReferredCPSagas() {
  yield all([call(watchFetchWhiteReferredCPList)])
}
