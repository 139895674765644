import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchPrizeDrawStart,
  fetchPrizeDrawSuccess,
  fetchPrizeDrawFailure,
  createPrizeDrawStart,
  createPrizeDrawSuccess,
  createPrizeDrawFailure,
  fetchPrizeDrawByIdSuccess,
  fetchPrizeDrawByIdFailure,
  editPrizeDrawStart,
  editPrizeDrawSuccess,
  editPrizeDrawFailure,
  editGeneralSettingStart,
  editGeneralSettingSuccess,
  editGeneralSettingFailure,
  fetchGeneralSettingStart,
  fetchGeneralSettingSuccess,
  fetchGeneralSettingFailure,
  fetchGeneralSettingByIdStart,
  fetchGeneralSettingByIdSuccess,
  fetchGeneralSettingByIdFailure,
  createGeneralSettingsStart,
  createGeneralSettingsSuccess,
  createGeneralSettingsFailure,
  fetchPiiinkInformationGetFirstSuccess,
  fetchPiiinkInformationGetFirstFailure,
  fetchPiiinkInformationGetByCountrySuccess,
  fetchPiiinkInformationGetByCountryFailure,
  createCouponSuccess,
  createCouponFailure,
  fetchCouponListSuccess,
  fetchCouponListFailure,
  editCouponSuccess,
  editCouponFailure,
  createCouponCodeFailure,
  createCouponCodeSuccess,
  fetchCouponCodeListSuccess,
  fetchCouponCodeListFailure,
  fetchCouponCodeListByIdSuccess,
  fetchCouponCodeListByIdFailure,
  activateDeactivateCouponByIdSuccess,
  activateDeactivateCouponByIdFailure,
  fetchSmtpDataByIdSuccess,
  fetchSmtpDataByIdFailure,
  editSmtpConfigurationSuccess,
  editSmtpConfigurationFailure,
  sendTestEmailSuccess,
  sendTestEmailFailure,
  fetchGroupInformationListSuccess,
  fetchGroupInformationListFailure,
  createGroupInformationListFailure,
  createGroupInformationListSuccess,
  fetchGroupInformationByIdSuccess,
  fetchGroupInformationByIdFailure,
  editGroupInformationListSuccess,
  editGroupInformationListFailure,
  fetchContactListSuccess,
  fetchContactListFailure,
  createContactInfoSuccess,
  createContactInfoFailure,
  fetchContactByIdFailure,
  fetchContactByIdSuccess,
  editContactInfoSuccess,
  editContactInfoFailure,
  updateCodesFromCSVFailure,
  updateCodesFromCSVSuccess,
  fetchDocumentListSuccess,
  fetchDocumentListFailure,
  createDocumentFailure,
  createDocumentSuccess,
  fetchDocumentByIdSuccess,
  fetchDocumentByIdFailure,
  deleteContactInfoSuccess,
  deleteContactInfoFailure,
  deleteGroupInformationListSuccess,
  deleteGroupInformationListFailure,
  editDocumentSuccess,
  editDocumentFailure,
  deleteDocumentFailure,
  deleteDocumentSuccess,
  fetchEmailListSuccess,
  fetchEmailListFailure,
  sendEmailAsDraftSuccess,
  sendEmailAsDraftFailure,
  saveAndSendEmailSuccess,
  saveAndSendEmailFailure,
  getOneCouponSuccess,
  getOneCouponFailure,
  fetchTemporaryPasswordListSuccess,
  fetchTemporaryPasswordListFailure,
  generateTemporaryPasswordSuccess,
  generateTemporaryPasswordFailure,
  fetchCouponSettingListSuccess,
  fetchCouponSettingListFailure,
  createCouponSettingSuccess,
  createCouponSettingFailure,
  fetchCouponSettingByIdSuccess,
  fetchCouponSettingByIdFailure,
  editCouponSettingSuccess,
  editCouponSettingFailure,
  fetchAppRangeSettingListSuccess,
  fetchAppRangeSettingListFailure,
  createAppRangeSettingSuccess,
  createAppRangeSettingFailure,
  fetchAppRangeSettingByIdSuccess,
  fetchAppRangeSettingByIdFailure,
  editAppRangeSettingSuccess,
  editAppRangeSettingFailure,
  fetchCouponListByPanelSuccess,
  fetchCouponListByPanelFailure,
  fetchDocumentManagementSettingListSuccess,
  fetchDocumentManagementSettingListFailure,
  createDocumentManagementSettingSuccess,
  createDocumentManagementSettingFailure,
  fetchDocumentManagementSettingByIdSuccess,
  fetchDocumentManagementSettingByIdFailure,
  editDocumentManagementSettingFailure,
  editDocumentManagementSettingSuccess,
  fetchGroupInformationListByPanelSuccess,
  fetchGroupInformationListByPanelFailure,
  fetchContactListByPanelSuccess,
  fetchContactListByPanelFailure,
  fetchDocumentListByPanelSuccess,
  fetchDocumentListByPanelFailure,
  fetchEmailListByPanelSuccess,
  fetchEmailListByPanelFailure,
  fetchReportListSuccess,
  fetchReportListFailure,
  fetchEmailDataByIdSuccess,
  fetchEmailDataByIdFailure,
  fetchReportListByPanelSuccess,
  fetchReportListByPanelFailure,
  fetchReportListByIdSuccess,
  fetchReportListByIdFailure,
  assignContactsToGroupSucccess,
  assignContactsToGroupFailure,
  fetchNotificationListFailure,
  fetchNotificationListSuccess,
  createNotificationSuccess,
  createNotificationFailure,
  fetchNotificationByIdSuccess,
  fetchNotificationByIdFailure,
  editNotificationSuccess,
  editNotificationFailure,
  changeNotificationOnOrOffSuccess,
  changeNotificationOnOrOffFailure,
  deleteNotificationFailure,
  deleteNotificationSuccess,
  fetchNotificationByUserFailure,
  fetchNotificationByUserSuccess,
  fetchNotificationByUserTypeSeenSuccess,
  fetchNotificationByUserTypeSeenFailure,
  fetchNotificationByUserTypeUnSeenSuccess,
  fetchNotificationByUserTypeUnSeenFailure,
  notificationCloseSuccess,
  notificationCloseFailure,
  markNotificationAsUnseenSuccess,
  markNotificationAsUnseenFailure,
  fetchCompanyListSuccess,
  fetchCompanyListFailure,
  createCompanyFailure,
  createCompanySuccess,
  editCompanySuccess,
  editCompanyFailure,
  fetchCompanyByIdSuccess,
  fetchCompanyByIdFailure,
  deleteCompanySuccess,
  deleteCompanyFailure,
  fetchPayoutInvoiceLogFailure,
  fetchPayoutInvoiceLogSuccess,
  stripeChargePayoutFailure,
  stripeChargePayoutSuccess,
  fetchAllPayoutInvoiceHistorySuccess,
  fetchAllPayoutInvoiceHistoryFailure,
} from './globalAdminSettingsSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchPrizeDrawList,
  fetchGeneralSettingList,
  createPrizeDraw,
  fetchPrizeDrawById,
  fetchGeneralSettingById,
  editPrizeDraw,
  editGeneralSettings,
  createGeneralSettings,
  fetchPiiinkInformationGetFirst,
  fetchPiiinkInformationGetByCountry,
  createCoupon,
  fetchCouponList,
  editCoupon,
  createCouponCode,
  fetchCouponCodeList,
  fetchCouponCodeListById,
  activateDeactivateCouponById,
  fetchSmtpDataById,
  editSmtpConfiguration,
  fetchGroupInformationList,
  sendTestEmail,
  createGroupInformationList,
  fetchGroupInformationById,
  editGroupInformationList,
  fetchContactList,
  createContactListList,
  fetchContactById,
  editContactInfo,
  fetchUploadDocumentList,
  fetchDocumentById,
  deleteContactInfo,
  deleteGroupInformationList,
  deleteDocument,
  fetchSendEmailList,
  saveEmailAsDraft,
  saveAndSendEmail,
  fetchCouponById,
  generateTemporaryPassword,
  fetchTemporaryPasswordList,
  fetchCouponSettingList,
  createCouponSetting,
  fetchCouponSettingById,
  editCouponSetting,
  fetchAppRangeSettingList,
  createAppRangeSetting,
  fetchAppRangeSettingById,
  editAppRangeSetting,
  fetchCouponListByPanel,
  editDocumentManagementSetting,
  fetchDocumentManagementSettingById,
  createDocumentManagementSetting,
  fetchDocumentManagementSettingList,
  fetchGroupInformationListByPanel,
  fetchContactListByPanel,
  fetchUploadDocumentListByPanel,
  fetchSendEmailListByPanel,
  fetchReportList,
  fetchEmailDataById,
  fetchReportListByPanel,
  fetchReportListById,
  assignContactsToGroup,
  fetchNotificationListByPanel,
  createNotification,
  fetchNotificationById,
  editNotification,
  changeNotificationStatus,
  deleteNotification,
  fetchNotificationListByUser,
  fetchNotificationByUserTypeSeen,
  fetchNotificationByUserTypeUnseen,
  notificationClose,
  markNotificationAsUnseen,
  fetchPayoutInvoiceLog,
  stripeChargePayout,
  fetchPayoutInvoiceHistory,
} from 'api/GlobalAdmin/settings'

import { getImageIdFromUrl, removeParam } from 'utils'
import _ from 'lodash'
import {
  createaDocumentInformationCSV,
  updateContactInformationCSV,
  updateDocumentInformationCSV,
} from 'api/transactionCodes'
import {
  deleteImage,
  getImageUrl,
  uploadImage,
  uploadPDF,
} from 'api/imageUpload'
import {
  createCompany,
  deleteCompany,
  editCompany,
  fetchCompanyById,
  fetchCompanyList,
} from 'api/company'

export function* fetchPrizeDrawAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPrizeDrawList(payload))

    yield put(fetchPrizeDrawSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPrizeDrawFailure(err))
  }
}

export function* fetchGeneralSettingsAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGeneralSettingList(payload))

    yield put(fetchGeneralSettingSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGeneralSettingFailure(err))
  }
}

export function* fetchPiiinkInformationGetFirstAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPiiinkInformationGetFirst(payload))

    yield put(fetchPiiinkInformationGetFirstSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPiiinkInformationGetFirstFailure(err))
  }
}

export function* fetchPiiinkInformationGetByCountryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchPiiinkInformationGetByCountry(payload))

    yield put(fetchPiiinkInformationGetByCountrySuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPiiinkInformationGetByCountryFailure(err))
  }
}

export function* fetchPrizeDrawByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPrizeDrawById(payload))

    yield put(fetchPrizeDrawByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPrizeDrawByIdFailure(err))
  }
}

export function* fetchGeneralSettingByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGeneralSettingById(payload))

    yield put(fetchGeneralSettingByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGeneralSettingByIdFailure(err))
  }
}

export function* editPrizeDrawAsync({ payload }: AnyAction) {
  try {
    // const { data } = yield call(editPrizeDraw(payload.id, payload))

    const { data } = yield call(
      editPrizeDraw(payload?.params?.id, payload?.params)
    )

    yield put(editPrizeDrawSuccess(data?.data))

    yield payload.closeModal()
    yield put(
      fetchPrizeDrawStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Prize draw settings successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editPrizeDrawFailure(err))
  }
}

export function* editGeneralSettingAsync({ payload }: AnyAction) {
  try {
    // const { data } = yield call(editPrizeDraw(payload.id, payload))

    let imageResponse1
    let imageResponse2

    if (
      !_.isEmpty(payload?.issuerCodeImage) ||
      !_.isEmpty(payload?.transactionCodeImage)
    ) {
      let imageUrl1 = ''
      if (!_.isEmpty(payload?.issuerCodeImage)) {
        const initialImageUrl1 = yield call(getImageUrl())
        if (!_.isEmpty(initialImageUrl1?.data)) {
          imageResponse1 = yield call(
            uploadImage(initialImageUrl1?.data?.url, payload?.issuerCodeImage)
          )

          imageUrl1 = removeParam(imageResponse1.config.url)
        }
        yield call(
          deleteImage(getImageIdFromUrl(payload.params.issuerCodeTemplateUrl))
        )
      }

      let imageUrl2 = ''
      if (!_.isEmpty(payload?.transactionCodeImage)) {
        const initialImageUrl2 = yield call(getImageUrl())
        if (!_.isEmpty(initialImageUrl2?.data)) {
          imageResponse2 = yield call(
            uploadImage(
              initialImageUrl2?.data?.url,
              payload?.transactionCodeImage
            )
          )

          imageUrl2 = removeParam(imageResponse2.config.url)
        }
        yield call(
          deleteImage(
            getImageIdFromUrl(payload.params.transactionCodeTemplateUrl)
          )
        )
      }

      const { data } = yield call(
        editGeneralSettings(payload?.params?.id, {
          ...payload.params,
          issuerCodeTemplateUrl: imageUrl1?.length
            ? imageUrl1
            : payload?.params?.issuerCodeTemplateUrl,
          transactionCodeTemplateUrl: imageUrl2?.length
            ? imageUrl2
            : payload?.params?.transactionCodeTemplateUrl,
        })
      )

      yield put(editGeneralSettingSuccess(data?.data))
    } else {
      const { data } = yield call(
        editGeneralSettings(payload?.params?.id, payload.params)
      )
      yield put(editGeneralSettingSuccess(data?.data))
    }

    yield payload.closeModal()

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      fetchGeneralSettingStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'General setting successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editGeneralSettingFailure(err))
  }
}

export function* createPrizeDrawAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createPrizeDraw(payload.params))
    yield put(createPrizeDrawSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      fetchPrizeDrawStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Prize draw settings successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createPrizeDrawFailure(err))
  }
}

export function* createGeneralSettingsAsync({ payload }: AnyAction) {
  try {
    let imageResponse1
    let imageResponse2

    if (!_.isEmpty(payload?.issuerCodeImage || payload?.transactionCodeImage)) {
      let imageUrl1 = ''
      const initialImageUrl1 = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl1?.data)) {
        imageResponse1 = yield call(
          uploadImage(initialImageUrl1?.data?.url, payload?.issuerCodeImage)
        )

        imageUrl1 = removeParam(imageResponse1.config.url)
      }

      let imageUrl2 = ''
      const initialImageUrl2 = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl2?.data)) {
        imageResponse2 = yield call(
          uploadImage(
            initialImageUrl2?.data?.url,
            payload?.transactionCodeImage
          )
        )

        imageUrl2 = removeParam(imageResponse2.config.url)
      }

      const { data } = yield call(
        createGeneralSettings({
          ...payload.params,
          issuerCodeTemplateUrl: imageUrl1,
          transactionCodeTemplateUrl: imageUrl2,
        })
      )

      yield put(createGeneralSettingsSuccess(data?.data))
    } else {
      const { data } = yield call(createGeneralSettings(payload.params))
      yield put(createGeneralSettingsSuccess(data?.data))
    }

    yield payload.closeModal()

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'General settings successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createGeneralSettingsFailure(err))
  }
}

export function* createCouponAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createCoupon(payload.params))
    yield put(createCouponSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    if (payload?.route && !_.isEmpty(data)) {
      payload?.navigate(`${payload?.route}/${data?.data?.id}`)
    }
    yield put(
      openAlert({
        message: 'Coupon successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createCouponFailure(err))
  }
}

export function* fetchCouponListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCouponList(payload))

    yield put(fetchCouponListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCouponListFailure(err))
  }
}

export function* fetchCouponListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCouponListByPanel(payload))

    yield put(fetchCouponListByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCouponListByPanelFailure(err))
  }
}

export function* editCouponAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editCoupon(payload?.id, payload?.params))

    yield put(editCouponSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Coupon successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editCouponFailure(err))
  }
}

export function* createCouponCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createCouponCode(payload.params))
    yield put(createCouponCodeSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    if (payload?.route && !_.isEmpty(data)) {
      payload?.navigate(`${payload?.route}/${payload?.params?.couponId}`)
    }
    yield put(
      openAlert({
        message: 'Coupon Code successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createCouponCodeFailure(err))
  }
}

export function* fetchCouponCodeListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCouponCodeList(payload))

    yield put(fetchCouponCodeListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCouponCodeListFailure(err))
  }
}

export function* fetchCouponCodeListByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      fetchCouponCodeListById(payload?.id, payload?.params)
    )

    yield put(fetchCouponCodeListByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCouponCodeListByIdFailure(err))
  }
}

export function* activateDeactivateCouponByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      activateDeactivateCouponById(payload?.id, {
        isActive: payload?.activeStatus,
      })
    )
    yield put(activateDeactivateCouponByIdSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: `Coupon Code successfully ${
          payload?.activeStatus ? 'activated' : 'deactivated'
        }`,
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(activateDeactivateCouponByIdFailure(err))
  }
}

export function* fetchSmtpDataByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSmtpDataById())

    yield put(fetchSmtpDataByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchSmtpDataByIdFailure(err))
  }
}

export function* editSmtpConfigurationAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editSmtpConfiguration(payload?.params?.id, payload?.params)
    )

    yield put(editSmtpConfigurationSuccess(data?.data))

    yield payload.closeModal()

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Smtp Configuration successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editSmtpConfigurationFailure(err))
  }
}

export function* sendTestEmailAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(sendTestEmail(payload?.params))

    yield put(sendTestEmailSuccess(data?.data))

    yield payload.closeModal()

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Test Email Sent successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(sendTestEmailFailure(err))
  }
}

export function* fetchGroupInformationListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGroupInformationList(payload))

    yield put(fetchGroupInformationListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGroupInformationListFailure(err))
  }
}

export function* fetchGroupInformationListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGroupInformationListByPanel(payload))

    yield put(fetchGroupInformationListByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGroupInformationListByPanelFailure(err))
  }
}

export function* createGroupInformationListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createGroupInformationList(payload.params))
    yield put(createGroupInformationListSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Group successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createGroupInformationListFailure(err))
  }
}

export function* fetchGroupInformationByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGroupInformationById(payload))

    yield put(fetchGroupInformationByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGroupInformationByIdFailure(err))
  }
}

export function* editGroupInformationListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editGroupInformationList(payload?.params?.id, payload?.params)
    )

    yield put(editGroupInformationListSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Group Information successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editGroupInformationListFailure(err))
  }
}

export function* deleteGroupInformationListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteGroupInformationList(payload?.id))

    yield put(deleteGroupInformationListSuccess(payload?.id))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Group Succesfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteGroupInformationListFailure(err))
    console.error(err)
  }
}

export function* fetchContactListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchContactList(payload))

    yield put(fetchContactListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchContactListFailure(err))
  }
}

export function* fetchContactListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchContactListByPanel(payload))

    yield put(fetchContactListByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchContactListByPanelFailure(err))
  }
}

export function* createContactInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createContactListList(payload.params))
    yield put(createContactInfoSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Contact Information successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createContactInfoFailure(err))
  }
}

export function* fetchContactByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchContactById(payload))

    yield put(fetchContactByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchContactByIdFailure(err))
  }
}

export function* editContactInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editContactInfo(payload?.params?.id, payload?.params)
    )

    yield put(editContactInfoSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Contact Information successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editContactInfoFailure(err))
  }
}

export function* deleteContactInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteContactInfo(payload?.id))

    yield put(deleteContactInfoSuccess(payload?.id))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Contact Info Succesfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteContactInfoFailure(err))
    console.error(err)
  }
}

export function* updateCodesFromCSVAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(updateContactInformationCSV(payload.params))
    yield put(updateCodesFromCSVSuccess(data))

    yield put(
      openAlert({
        message: 'Uploaded Successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(updateCodesFromCSVFailure(err))
  }
}

export function* fetchDocumentListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchUploadDocumentList(payload))

    yield put(fetchDocumentListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchDocumentListFailure(err))
  }
}

export function* fetchDocumentListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchUploadDocumentListByPanel(payload))

    yield put(fetchDocumentListByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchDocumentListByPanelFailure(err))
  }
}

export function* createDocumentAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.documentUrl)) {
      let documentUrl = ''

      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadPDF(initialImageUrl?.data?.url, payload?.params?.documentUrl)
        )

        documentUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createaDocumentInformationCSV({
          ...payload.params,
          documentUrl: documentUrl,
        })
      )

      yield put(createDocumentSuccess(data?.data))
    } else {
      const { data } = yield call(
        createaDocumentInformationCSV(payload?.params)
      )
      yield put(createDocumentSuccess(data?.data))
    }

    if (payload?.closeModal) {
      yield payload.closeModal()
    }

    yield put(
      openAlert({
        message: 'Uploaded Successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createDocumentFailure(err))
  }
}

export function* editDocumentAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.documentUrl)) {
      let documentUrl = ''

      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadPDF(initialImageUrl?.data?.url, payload?.params?.documentUrl)
        )

        documentUrl = removeParam(imageResponse.config.url)
      }

      const imageAwsId = getImageIdFromUrl(payload?.oldImage)

      const { data } = yield call(
        updateDocumentInformationCSV(payload?.params?.id, {
          ...payload.params,
          documentUrl: documentUrl,
        })
      )

      if (imageAwsId && payload?.params?.documentUrl?.size) {
        yield call(deleteImage(getImageIdFromUrl(payload?.oldImage)))
      }

      yield put(editDocumentSuccess(data?.data))
    } else {
      const { data } = yield call(
        updateDocumentInformationCSV(payload?.params?.id, payload?.params)
      )
      yield put(editDocumentSuccess(data?.data))
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Document successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editDocumentFailure(err))
  }
}

export function* fetchDocumentByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchDocumentById(payload))

    yield put(fetchDocumentByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchDocumentByIdFailure(err))
  }
}

export function* deleteDocumentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteDocument(payload?.id))

    if (payload?.oldDocumentUrl) {
      yield call(deleteImage(getImageIdFromUrl(payload?.oldDocumentUrl)))
    }

    yield put(deleteDocumentSuccess(payload?.id))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Document Succesfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteDocumentFailure(err))
    console.error(err)
  }
}

export function* fetchEmailListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSendEmailList(payload))

    yield put(fetchEmailListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchEmailListFailure(err))
  }
}

export function* fetchEmailListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSendEmailListByPanel(payload))

    yield put(fetchEmailListByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchEmailListByPanelFailure(err))
  }
}

export function* sendEmailAsDraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(saveEmailAsDraft(payload.params))
    yield put(sendEmailAsDraftSuccess(data))

    yield put(
      openAlert({
        message: 'Email sent as Draft Successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(sendEmailAsDraftFailure(err))
  }
}

export function* saveAndSendEmailAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(saveAndSendEmail(payload.params))
    yield put(saveAndSendEmailSuccess(data))

    yield put(
      openAlert({
        message: 'Email sent Successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(saveAndSendEmailFailure(err))
  }
}

export function* generateTemporaryPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(generateTemporaryPassword(payload.params))
    yield put(generateTemporaryPasswordSuccess(data))

    yield put(
      openAlert({
        message: 'Temporary Password Generated Successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (data) {
      if (payload?.onOpenGeneratePasswordDialog) {
        payload?.onOpenGeneratePasswordDialog()
      }
    }

    if (payload?.closeModal) {
      payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(generateTemporaryPasswordFailure(err))
  }
}

export function* fetchTemporaryPasswordListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTemporaryPasswordList(payload))

    yield put(fetchTemporaryPasswordListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTemporaryPasswordListFailure(err))
  }
}

export function* getOneCouponAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCouponById(payload))

    yield put(getOneCouponSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(getOneCouponFailure(err))
  }
}

export function* fetchCouponSettingListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCouponSettingList(payload))

    yield put(fetchCouponSettingListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCouponSettingListFailure(err))
  }
}

export function* createCouponSettingAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createCouponSetting(payload.params))
    yield put(createCouponSettingSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Coupon Setting successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createCouponSettingFailure(err))
  }
}

export function* fetchCouponSettingByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCouponSettingById(payload))

    yield put(fetchCouponSettingByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCouponSettingByIdFailure(err))
  }
}

export function* editCouponSettingAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editCouponSetting(payload?.params?.id, payload?.params)
    )

    yield put(editCouponSettingSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Coupon Setting successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editCouponSettingFailure(err))
  }
}

export function* fetchAppRangeSettingListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAppRangeSettingList(payload))

    yield put(fetchAppRangeSettingListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAppRangeSettingListFailure(err))
  }
}

export function* createAppRangeSettingAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createAppRangeSetting(payload.params))
    yield put(createAppRangeSettingSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Coupon Setting successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createAppRangeSettingFailure(err))
  }
}

export function* fetchAppRangeSettingByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAppRangeSettingById(payload))

    yield put(fetchAppRangeSettingByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAppRangeSettingByIdFailure(err))
  }
}

export function* editAppRangeSettingAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editAppRangeSetting(payload?.params?.id, payload?.params)
    )

    yield put(editAppRangeSettingSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Coupon Setting successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editAppRangeSettingFailure(err))
  }
}

export function* fetchDocumentManagementSettingListAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchDocumentManagementSettingList(payload))

    yield put(fetchDocumentManagementSettingListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchDocumentManagementSettingListFailure(err))
  }
}

export function* createDocumentManagementSettingAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createDocumentManagementSetting(payload.params))
    yield put(createDocumentManagementSettingSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Document Management Setting successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createDocumentManagementSettingFailure(err))
  }
}

export function* fetchDocumentManagementSettingByIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchDocumentManagementSettingById(payload))

    yield put(fetchDocumentManagementSettingByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchDocumentManagementSettingByIdFailure(err))
  }
}

export function* editDocumentManagementSettingAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editDocumentManagementSetting(payload?.params?.id, payload?.params)
    )

    yield put(editDocumentManagementSettingSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Document Management Setting successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editDocumentManagementSettingFailure(err))
  }
}

export function* fetchReportListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchReportList(payload))

    yield put(fetchReportListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchReportListFailure(err))
  }
}

export function* fetchReportListByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      fetchReportListById(payload?.id, payload?.params)
    )

    yield put(fetchReportListByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchReportListByIdFailure(err))
  }
}

export function* fetchReportListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchReportListByPanel(payload))

    yield put(fetchReportListByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchReportListByPanelFailure(err))
  }
}

export function* fetchEmailDataByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchEmailDataById(payload))

    yield put(fetchEmailDataByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchEmailDataByIdFailure(err))
  }
}

export function* assignContactsToGroupAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(assignContactsToGroup(payload?.params))
    yield put(assignContactsToGroupSucccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.unMarkAllonClick) {
      yield call(payload?.unMarkAllonClick)
    }

    yield put(
      openAlert({
        message: 'Contacts assigned to group successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(assignContactsToGroupFailure(err))
  }
}

export function* fetchNotificationListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchNotificationListByPanel(payload))

    yield put(fetchNotificationListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchNotificationListFailure(err))
  }
}

export function* createNotificationAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createNotification(payload?.params))
    yield put(createNotificationSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Notification successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createNotificationFailure(err))
  }
}

export function* fetchNotificationByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchNotificationById(payload))

    yield put(fetchNotificationByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchNotificationByIdFailure(err))
  }
}

export function* editNotificationAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editNotification(payload?.params?.id, payload?.params)
    )

    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(editNotificationSuccess(data?.data))

    yield put(
      openAlert({
        message: 'Notification successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editNotificationFailure(err))
  }
}

export function* changeNotificationOnOrOffAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      changeNotificationStatus(payload?.id, {
        isNotificationOn: payload?.isNotificationOn,
      })
    )
    yield put(changeNotificationOnOrOffSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: `Notification ${
          payload?.isNotificationOn ? 'published' : 'unpublished'
        } successfully`,
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(changeNotificationOnOrOffFailure(err))
  }
}

export function* deleteNotificationAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      deleteNotification(payload?.id, { rejectReason: payload?.rejectReason })
    )

    yield put(deleteNotificationSuccess(payload?.id))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Notification Successfully deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteNotificationFailure(err))
  }
}

export function* fetchNotificationListByUserAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchNotificationListByUser(payload))

    yield put(fetchNotificationByUserSuccess(data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchNotificationByUserFailure(err))
  }
}

export function* fetchNotificationByUserTypeSeenAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchNotificationByUserTypeSeen(payload))

    yield put(fetchNotificationByUserTypeSeenSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchNotificationByUserTypeSeenFailure(err))
  }
}

export function* fetchNotificationByUserTypeUnSeenAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchNotificationByUserTypeUnseen(payload))

    yield put(fetchNotificationByUserTypeUnSeenSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchNotificationByUserTypeUnSeenFailure(err))
  }
}

export function* notificationCloseAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      notificationClose(payload?.notificationForPanelId, {
        userType: payload?.userType,
        userId: payload?.userId,
      })
    )
    yield put(notificationCloseSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (!payload?.calledFromDashboard) {
      yield put(
        openAlert({
          message: `Notification Closed successfully`,
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(notificationCloseFailure(err))
  }
}

export function* markNotificationAsUnseenAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(markNotificationAsUnseen(payload?.id))
    yield put(markNotificationAsUnseenSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: `Notification marked as unseen successfully`,
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(markNotificationAsUnseenFailure(err))
  }
}

export function* fetchCompanyListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCompanyList(payload))

    yield put(fetchCompanyListSuccess(data))
  } catch (err) {
    yield put(fetchCompanyListFailure(err))
    console.error(err)
  }
}

export function* fetchCompanyByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCompanyById(payload))

    yield put(fetchCompanyByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCompanyByIdFailure(err))
  }
}

export function* createCompanyAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createCompany(payload?.params))
    yield put(createCompanySuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Company successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createCompanyFailure(err))
  }
}

export function* editCompanyAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editCompany(payload?.params?.id, payload?.params)
    )

    yield put(editCompanySuccess(data?.data))

    if (payload.closeModal) {
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Company successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editCompanyFailure(err))
  }
}

export function* deleteCompanyAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteCompany(payload?.id))

    yield put(deleteCompanySuccess(data?.data))

    if (payload.closeModal) {
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Company successfully deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteCompanyFailure(err))
  }
}

export function* fetchPayoutInvoiceLogAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPayoutInvoiceLog(payload?.params))

    if (payload?.displayInvoiceModal && data?.canBePaidOut == false) {
      yield payload?.displayInvoiceModal()
    }

    yield put(fetchPayoutInvoiceLogSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPayoutInvoiceLogFailure(err))
  }
}

export function* fetchAllPayoutInvoiceHistoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPayoutInvoiceHistory(payload?.params))

    yield put(fetchAllPayoutInvoiceHistorySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAllPayoutInvoiceHistoryFailure(err))
  }
}

export function* stripeChargePayoutAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(stripeChargePayout(payload?.params))

    yield put(stripeChargePayoutSuccess(data?.data))

    if (payload.closeModal) {
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Stripe payout completed successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(stripeChargePayoutFailure(err))
  }
}

export function* watchFetchPrizeDraw() {
  yield takeLatest('globalSettings/fetchPrizeDrawStart', fetchPrizeDrawAsync)
}

export function* watchFetchGeneralSetting() {
  yield takeLatest(
    'globalSettings/fetchGeneralSettingStart',
    fetchGeneralSettingsAsync
  )
}

export function* watchCreatePrizeDraw() {
  yield takeLatest('globalSettings/createPrizeDrawStart', createPrizeDrawAsync)
}

export function* watchGeneralSettingsDraw() {
  yield takeLatest(
    'globalSettings/createGeneralSettingsStart',
    createGeneralSettingsAsync
  )
}

export function* watchFetchPrizeDrawById() {
  yield takeLatest(
    'globalSettings/fetchPrizeDrawByIdStart',
    fetchPrizeDrawByIdAsync
  )
}

export function* watchFetchGeneralSettingById() {
  yield takeLatest(
    'globalSettings/fetchGeneralSettingByIdStart',
    fetchGeneralSettingByIdAsync
  )
}

export function* watchEditPrizeDraw() {
  yield takeLatest('globalSettings/editPrizeDrawStart', editPrizeDrawAsync)
}

export function* watchEditGeneralSetting() {
  yield takeLatest(
    'globalSettings/editGeneralSettingStart',
    editGeneralSettingAsync
  )
}

export function* watchFetchPiiinkInformationGetFirstAsync() {
  yield takeLatest(
    'globalSettings/fetchPiiinkInformationGetFirstStart',
    fetchPiiinkInformationGetFirstAsync
  )
}
export function* watchFetchPiiinkInformationGetByCountryAsync() {
  yield takeLatest(
    'globalSettings/fetchPiiinkInformationGetByCountryStart',
    fetchPiiinkInformationGetByCountryAsync
  )
}

export function* watchCreateCouponAsync() {
  yield takeLatest('globalSettings/createCouponStart', createCouponAsync)
}

export function* watchFetchCouponListAsync() {
  yield takeLatest('globalSettings/fetchCouponListStart', fetchCouponListAsync)
}

export function* watchFetchCouponListByPanelAsync() {
  yield takeLatest(
    'globalSettings/fetchCouponListByPanelStart',
    fetchCouponListByPanelAsync
  )
}

export function* watchEditCouponAsync() {
  yield takeLatest('globalSettings/editCouponStart', editCouponAsync)
}

export function* watchCreateCouponCode() {
  yield takeLatest(
    'globalSettings/createCouponCodeStart',
    createCouponCodeAsync
  )
}

export function* watchFetchCouponCodeList() {
  yield takeLatest(
    'globalSettings/fetchCouponCodeListStart',
    fetchCouponCodeListAsync
  )
}

export function* watchFetchCouponCodeListById() {
  yield takeLatest(
    'globalSettings/fetchCouponCodeListByIdStart',
    fetchCouponCodeListByIdAsync
  )
}
export function* watchActivateDeactivateCouponByIdAsync() {
  yield takeLatest(
    'globalSettings/activateDeactivateCouponByIdStart',
    activateDeactivateCouponByIdAsync
  )
}

export function* watchFetchSmtpDataByIdAsync() {
  yield takeLatest(
    'globalSettings/fetchSmtpDataByIdStart',
    fetchSmtpDataByIdAsync
  )
}

export function* watchEditSmtpConfiguration() {
  yield takeLatest(
    'globalSettings/editSmtpConfigurationStart',
    editSmtpConfigurationAsync
  )
}

export function* watchSendTestEmail() {
  yield takeLatest('globalSettings/sendTestEmailStart', sendTestEmailAsync)
}

export function* watchFetchGroupInformationList() {
  yield takeLatest(
    'globalSettings/fetchGroupInformationListStart',
    fetchGroupInformationListAsync
  )
}

export function* watchFetchGroupInformationListByPanel() {
  yield takeLatest(
    'globalSettings/fetchGroupInformationListByPanelStart',
    fetchGroupInformationListByPanelAsync
  )
}

export function* watchCreateGroupInformationList() {
  yield takeLatest(
    'globalSettings/createGroupInformationListStart',
    createGroupInformationListAsync
  )
}

export function* watchFetchGroupInformationById() {
  yield takeLatest(
    'globalSettings/fetchGroupInformationByIdStart',
    fetchGroupInformationByIdAsync
  )
}

export function* watchEditGroupInformationList() {
  yield takeLatest(
    'globalSettings/editGroupInformationListStart',
    editGroupInformationListAsync
  )
}

export function* watchFetchContactList() {
  yield takeLatest(
    'globalSettings/fetchContactListStart',
    fetchContactListAsync
  )
}

export function* watchFetchContactListByPanel() {
  yield takeLatest(
    'globalSettings/fetchContactListByPanelStart',
    fetchContactListByPanelAsync
  )
}

export function* watchCreateContactInfoStart() {
  yield takeLatest(
    'globalSettings/createContactInfoStart',
    createContactInfoAsync
  )
}

export function* watchFetchContactByIdAsync() {
  yield takeLatest(
    'globalSettings/fetchContactByIdStart',
    fetchContactByIdAsync
  )
}

export function* watchEditContactInfo() {
  yield takeLatest('globalSettings/editContactInfoStart', editContactInfoAsync)
}

export function* watchDeleteContactInfo() {
  yield takeLatest(
    'globalSettings/deleteContactInfoStart',
    deleteContactInfoAsync
  )
}

export function* watchDeleteGroupInformationList() {
  yield takeLatest(
    'globalSettings/deleteGroupInformationListStart',
    deleteGroupInformationListAsync
  )
}

export function* watchUpdateCodesFromCSV() {
  yield takeLatest(
    'globalSettings/updateCodesFromCSVStart',
    updateCodesFromCSVAsync
  )
}

export function* watchFetchDocumentList() {
  yield takeLatest(
    'globalSettings/fetchDocumentListStart',
    fetchDocumentListAsync
  )
}

export function* watchFetchDocumentListByPanel() {
  yield takeLatest(
    'globalSettings/fetchDocumentListByPanelStart',
    fetchDocumentListByPanelAsync
  )
}

export function* watchCreateDocument() {
  yield takeLatest('globalSettings/createDocumentStart', createDocumentAsync)
}

export function* watchEditDocument() {
  yield takeLatest('globalSettings/editDocumentStart', editDocumentAsync)
}

export function* watchFetchDocumentById() {
  yield takeLatest(
    'globalSettings/fetchDocumentByIdStart',
    fetchDocumentByIdAsync
  )
}

export function* watchDeleteDocument() {
  yield takeLatest('globalSettings/deleteDocumentStart', deleteDocumentAsync)
}

export function* watchFetchEmailList() {
  yield takeLatest('globalSettings/fetchEmailListStart', fetchEmailListAsync)
}

export function* watchFetchEmailListByPanel() {
  yield takeLatest(
    'globalSettings/fetchEmailListByPanelStart',
    fetchEmailListByPanelAsync
  )
}

export function* watchSendEmailAsDraft() {
  yield takeLatest(
    'globalSettings/sendEmailAsDraftStart',
    sendEmailAsDraftAsync
  )
}

export function* watchSaveAndSendEmailAsync() {
  yield takeLatest(
    'globalSettings/saveAndSendEmailStart',
    saveAndSendEmailAsync
  )
}

export function* watchGetOneCouponAsync() {
  yield takeLatest('globalSettings/getOneCouponStart', getOneCouponAsync)
}

export function* watchGenerateTemporaryPasswordAsync() {
  yield takeLatest(
    'globalSettings/generateTemporaryPasswordStart',
    generateTemporaryPasswordAsync
  )
}
export function* watchFetchTemporaryPasswordAsync() {
  yield takeLatest(
    'globalSettings/fetchTemporaryPasswordListStart',
    fetchTemporaryPasswordListAsync
  )
}

export function* watchFetchCouponSettingListAsync() {
  yield takeLatest(
    'globalSettings/fetchCouponSettingListStart',
    fetchCouponSettingListAsync
  )
}

export function* watchCreateCouponSettingAsync() {
  yield takeLatest(
    'globalSettings/createCouponSettingStart',
    createCouponSettingAsync
  )
}

export function* watchFetchCouponSettingByIdAsync() {
  yield takeLatest(
    'globalSettings/fetchCouponSettingByIdStart',
    fetchCouponSettingByIdAsync
  )
}
export function* watchEditCouponSettingAsync() {
  yield takeLatest(
    'globalSettings/editCouponSettingStart',
    editCouponSettingAsync
  )
}

export function* watchFetchAppRangeSettingListAsync() {
  yield takeLatest(
    'globalSettings/fetchAppRangeSettingListStart',
    fetchAppRangeSettingListAsync
  )
}

export function* watchCreateAppRangeSettingAsync() {
  yield takeLatest(
    'globalSettings/createAppRangeSettingStart',
    createAppRangeSettingAsync
  )
}

export function* watchFetchAppRangeSettingByIdAsync() {
  yield takeLatest(
    'globalSettings/fetchAppRangeSettingByIdStart',
    fetchAppRangeSettingByIdAsync
  )
}
export function* watchEditAppRangeSettingAsync() {
  yield takeLatest(
    'globalSettings/editAppRangeSettingStart',
    editAppRangeSettingAsync
  )
}

export function* watchFetchDocumentManagementSettingListAsync() {
  yield takeLatest(
    'globalSettings/fetchDocumentManagementSettingListStart',
    fetchDocumentManagementSettingListAsync
  )
}

export function* watchCreateDocumentManagementSettingAsync() {
  yield takeLatest(
    'globalSettings/createDocumentManagementSettingStart',
    createDocumentManagementSettingAsync
  )
}

export function* watchFetchDocumentManagementSettingByIdAsync() {
  yield takeLatest(
    'globalSettings/fetchDocumentManagementSettingByIdStart',
    fetchDocumentManagementSettingByIdAsync
  )
}
export function* watchEditDocumentManagementSettingAsync() {
  yield takeLatest(
    'globalSettings/editDocumentManagementSettingStart',
    editDocumentManagementSettingAsync
  )
}

export function* watchFetchReportList() {
  yield takeLatest('globalSettings/fetchReportListStart', fetchReportListAsync)
}

export function* watchFetchReportListById() {
  yield takeLatest(
    'globalSettings/fetchReportListByIdStart',
    fetchReportListByIdAsync
  )
}

export function* watchFetchReportListByPanel() {
  yield takeLatest(
    'globalSettings/fetchReportListByPanelStart',
    fetchReportListByPanelAsync
  )
}

export function* watchFetchEmailDataById() {
  yield takeLatest(
    'globalSettings/fetchEmailDataByIdStart',
    fetchEmailDataByIdAsync
  )
}

export function* watchAssignContactsToGroup() {
  yield takeLatest(
    'globalSettings/assignContactsToGroupStart',
    assignContactsToGroupAsync
  )
}

export function* watchFetchNotificationList() {
  yield takeLatest(
    'globalSettings/fetchNotificationListStart',
    fetchNotificationListAsync
  )
}

export function* watchCreateNotification() {
  yield takeLatest(
    'globalSettings/createNotificationStart',
    createNotificationAsync
  )
}

export function* watchFetchNotificationById() {
  yield takeLatest(
    'globalSettings/fetchNotificationByIdStart',
    fetchNotificationByIdAsync
  )
}

export function* watchEditNotification() {
  yield takeLatest(
    'globalSettings/editNotificationStart',
    editNotificationAsync
  )
}

export function* watchChangeNotification() {
  yield takeLatest(
    'globalSettings/changeNotificationOnOrOffStart',
    changeNotificationOnOrOffAsync
  )
}

export function* watchDeleteNotification() {
  yield takeLatest(
    'globalSettings/deleteNotificationStart',
    deleteNotificationAsync
  )
}

export function* watchFetchNotificationByUserAsync() {
  yield takeLatest(
    'globalSettings/fetchNotificationByUserStart',
    fetchNotificationListByUserAsync
  )
}

export function* watchFetchNotificationByUserTypeSeenAsync() {
  yield takeLatest(
    'globalSettings/fetchNotificationByUserTypeSeenStart',
    fetchNotificationByUserTypeSeenAsync
  )
}

export function* watchFetchNotificationByUserTypeUnseenAsync() {
  yield takeLatest(
    'globalSettings/fetchNotificationByUserTypeUnSeenStart',
    fetchNotificationByUserTypeUnSeenAsync
  )
}

export function* watchNotificationCloseAsync() {
  yield takeLatest(
    'globalSettings/notificationCloseStart',
    notificationCloseAsync
  )
}

export function* watchMarkNotificationAsUnseenAsync() {
  yield takeLatest(
    'globalSettings/markNotificationAsUnseenStart',
    markNotificationAsUnseenAsync
  )
}

export function* watchFetchCompanyListAsync() {
  yield takeLatest(
    'globalSettings/fetchCompanyListStart',
    fetchCompanyListAsync
  )
}

export function* watchCreateCompanyAsync() {
  yield takeLatest('globalSettings/createCompanyStart', createCompanyAsync)
}

export function* watchEditCompanyAsync() {
  yield takeLatest('globalSettings/editCompanyStart', editCompanyAsync)
}

export function* watchFetchCompanyByIdAsync() {
  yield takeLatest(
    'globalSettings/fetchCompanyByIdStart',
    fetchCompanyByIdAsync
  )
}

export function* watchFetchPayoutInvoiceLogAsync() {
  yield takeLatest(
    'globalSettings/fetchPayoutInvoiceLogStart',
    fetchPayoutInvoiceLogAsync
  )
}

export function* watchFetchAllPayoutInvoiceHistoryAsync() {
  yield takeLatest(
    'globalSettings/fetchAllPayoutInvoiceHistoryStart',
    fetchAllPayoutInvoiceHistoryAsync
  )
}

export function* watchStripeChargePayoutAsync() {
  yield takeLatest(
    'globalSettings/stripeChargePayoutStart',
    stripeChargePayoutAsync
  )
}

export function* watchDeleteCompanyAsync() {
  yield takeLatest('globalSettings/deleteCompanyStart', deleteCompanyAsync)
}

export function* globalAdminSettingsSagas() {
  yield all([
    call(watchFetchPrizeDraw),
    call(watchFetchGeneralSetting),
    call(watchCreatePrizeDraw),
    call(watchGeneralSettingsDraw),
    call(watchFetchPrizeDrawById),
    call(watchFetchGeneralSettingById),
    call(watchFetchGeneralSetting),
    call(watchEditPrizeDraw),
    call(watchEditGeneralSetting),
    call(watchFetchPiiinkInformationGetFirstAsync),
    call(watchFetchPiiinkInformationGetByCountryAsync),
    call(watchCreateCouponAsync),
    call(watchFetchCouponListAsync),
    call(watchEditCouponAsync),
    call(watchCreateCouponCode),
    call(watchFetchCouponCodeList),
    call(watchFetchCouponCodeListById),
    call(watchActivateDeactivateCouponByIdAsync),
    call(watchFetchSmtpDataByIdAsync),
    call(watchEditSmtpConfiguration),
    call(watchSendTestEmail),
    call(watchFetchGroupInformationList),
    call(watchCreateGroupInformationList),
    call(watchFetchGroupInformationById),
    call(watchEditGroupInformationList),
    call(watchFetchContactList),
    call(watchCreateContactInfoStart),
    call(watchFetchContactByIdAsync),
    call(watchEditContactInfo),
    call(watchUpdateCodesFromCSV),
    call(watchFetchDocumentList),
    call(watchCreateDocument),
    call(watchFetchDocumentById),
    call(watchDeleteContactInfo),
    call(watchDeleteGroupInformationList),
    call(watchEditDocument),
    call(watchDeleteDocument),
    call(watchFetchEmailList),
    call(watchSendEmailAsDraft),
    call(watchSaveAndSendEmailAsync),
    call(watchGetOneCouponAsync),
    call(watchGenerateTemporaryPasswordAsync),
    call(watchFetchTemporaryPasswordAsync),
    call(watchFetchCouponSettingListAsync),
    call(watchCreateCouponSettingAsync),
    call(watchFetchCouponSettingByIdAsync),
    call(watchEditCouponSettingAsync),
    call(watchFetchAppRangeSettingListAsync),
    call(watchCreateAppRangeSettingAsync),
    call(watchFetchAppRangeSettingByIdAsync),
    call(watchEditAppRangeSettingAsync),
    call(watchFetchCouponListByPanelAsync),
    call(watchFetchDocumentManagementSettingListAsync),
    call(watchCreateDocumentManagementSettingAsync),
    call(watchFetchDocumentManagementSettingByIdAsync),
    call(watchEditDocumentManagementSettingAsync),
    call(watchFetchGroupInformationListByPanel),
    call(watchFetchContactListByPanel),
    call(watchFetchDocumentListByPanel),
    call(watchFetchEmailListByPanel),
    call(watchFetchReportList),
    call(watchFetchEmailDataById),
    call(watchFetchReportListByPanel),
    call(watchFetchReportListById),
    call(watchAssignContactsToGroup),
    call(watchFetchNotificationList),
    call(watchCreateNotification),
    call(watchFetchNotificationById),
    call(watchEditNotification),
    call(watchChangeNotification),
    call(watchDeleteNotification),
    call(watchFetchNotificationByUserAsync),
    call(watchFetchNotificationByUserTypeSeenAsync),
    call(watchFetchNotificationByUserTypeUnseenAsync),
    call(watchNotificationCloseAsync),
    call(watchMarkNotificationAsUnseenAsync),
    call(watchFetchCompanyListAsync),
    call(watchCreateCompanyAsync),
    call(watchEditCompanyAsync),
    call(watchFetchCompanyByIdAsync),
    call(watchDeleteCompanyAsync),
    call(watchFetchPayoutInvoiceLogAsync),
    call(watchStripeChargePayoutAsync),
    call(watchFetchAllPayoutInvoiceHistoryAsync),
  ])
}
