import { axiosRequest, removeEmptyFields } from 'utils'

export const fetchTrainingVideoList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/trainingVideo/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTrainingVideoListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/trainingVideo/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTrainingVideoById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/trainingVideo/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createTrainingVideo = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/globalSetting/trainingVideo/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteTrainingVideo = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/trainingVideo/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editTrainingVideo = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/trainingVideo/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createTrainingVideoCategory = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/globalSetting/trainingVideoCategory/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editTrainingVideoCategory = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/trainingVideoCategory/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteTrainingVideoCategory = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/trainingVideoCategory/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTrainingVideoCategoryList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/trainingVideoCategory/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTrainingVideoCategoryListForOtherPanels = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/trainingVideoCategory/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTrainingVideoCategoryById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/trainingVideoCategory/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTrainingVideoCategoryListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/trainingVideoCategory/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
