// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchStateOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/stateOwner/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStateOwner = (params) => {
  return () =>
    axiosRequest
      .post(`/api/stateOwner/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchStateOwnerById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/stateOwner/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectStateOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/stateOwner/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editStateOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/stateOwner/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectPendingStateOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/stateOwner/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteStateOwner = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/stateOwner/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyPendingStateOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/stateOwner/updateAndVerifyEOI/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveUpdatePendingStateOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/stateOwner/saveUpdate/${id}`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteStateOwnerContact = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/stateOwner/deleteStateOwnerContact/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchStateOwnerProfileInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/stateOwner/getOwnProfile`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStateOwnerAgreement = (id, params) => {
  return () =>
    axiosRequest
      .patch(`/api/stateOwner/saveAgreement/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editStateOwnerOwnProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/stateOwner/editOwnProfile/`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
