import React, { useEffect } from 'react'
import { TextField, Autocomplete } from '@mui/material'
import { FieldProps, getIn } from 'formik'

import _ from 'lodash'
import container from './PremiumCodeByPanelSelect.container'
import { usePrevious } from 'utils'

interface AddNewCountryProps {
  onFetchAreaStart?: (payload: any) => void
  dropDown?: any
  setSelectedCountry?: any
  selectedArea?: any
  defaultSelected?: any
  auth?: any
  defaultValue?: number
  isAssignedToOwner?: boolean
  makeDefaultEmpty?: boolean
  assignedToType?: string
  label?: string
  onClearAreaDropDown?: () => void
  onFetchIssuerQrCodeStart?: (params) => void
  onFetchUnusedPremiumCodeByPanelStart?: (params) => void
  onClearMerchantCodeData?: () => void
  clearMerchantPremiumCodeData?: () => void
  filterByCountryId?: any
  includeFilterByCountry?: boolean
  assignedToId?: number
  merchantId?: number
  merchantPackageId?: number
}

const CountryPanelPremiumCodeSelect: React.FC<
  FieldProps & AddNewCountryProps
> = ({
  field,
  form,
  filterByCountryId,
  onFetchAreaStart,
  setSelectedCountry,
  dropDown: { unUsedPremiumCodeByPanelList },

  defaultValue,
  defaultSelected,
  selectedArea,
  isAssignedToOwner,
  onClearAreaDropDown,
  makeDefaultEmpty,
  label,
  includeFilterByCountry,
  onFetchIssuerQrCodeStart,
  onFetchUnusedPremiumCodeByPanelStart,
  assignedToId,
  assignedToType,
  onClearMerchantCodeData,
  clearMerchantPremiumCodeData,
  merchantId,
  merchantPackageId,
  ...props
}) => {
  useEffect(() => {
    onFetchUnusedPremiumCodeByPanelStart({
      limit: 250,
      merchantId,
      merchantPackageId: merchantPackageId ? merchantPackageId : null,
    })
  }, [assignedToId, assignedToType, merchantPackageId])

  const useHasChanged = (val: any) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }

  const premiumCodeHasChanged = useHasChanged(form?.values?.premiumCode?.id)

  if (premiumCodeHasChanged) {
    onClearMerchantCodeData()
    clearMerchantPremiumCodeData()
  }

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <Autocomplete
      {...props}
      {...field}
      disablePortal
      id="combo-box-demo"
      size="small"
      value={field.value}
      getOptionLabel={(option) => (option ? option.label : '')}
      onChange={(e, value, reason: any) => {
        form.setFieldValue(field.name, value)
        if (value?.length === 0) {
          onClearMerchantCodeData()
          clearMerchantPremiumCodeData()
        }

        if (reason === 'clear') {
          onClearMerchantCodeData()
          clearMerchantPremiumCodeData()
        }
        //
      }}
      // onInputChange={(event, newInputValue) => {
      //   console.log('onInputChange>>>', newInputValue)
      // }}
      options={unUsedPremiumCodeByPanelList}
      defaultValue={defaultValue ? defaultValue : null}
      // isOptionEqualToValue={defaultCountry ? defaultCountry : null}
      // value={selectedArea ? selectedArea : null}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={errorText?.value || errorText}
          error={!!errorText}
          label={label ? label : 'Enter Premium Code If you have any'}
        />
      )}
    />
  )
}

export default container(CountryPanelPremiumCodeSelect)
