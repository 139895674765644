import { createSlice } from '@reduxjs/toolkit'

import { IStateSetupState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IStateSetupState = {
  stateList: listObj,
  soleState: {},
  error: null,
  loading: false,
  isFetching: false,
  hasMoreData: false,
}

export const stateSetupSlice = createSlice({
  name: 'stateSetup',
  initialState: INITIAL_STATE,
  reducers: {
    fetchStateStart: (state: any, action) => {
      state.loading = true
    },
    fetchStateSuccess: (state: any, action) => {
      state.stateList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchStateFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchStateByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchStateByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleState = action.payload.data
      state.isFetching = false
    },
    fetchStateByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createStateStart: (state: any, action) => {
      state.loading = true
    },
    createStateSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.stateList.data = [...state.stateList.data, action.payload]
    },
    createStateFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteStateStart: (state: any, action) => {
      state.loading = true
    },
    deleteStateSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.stateList.data = state.stateList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteStateFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editStateStart: (state: any, action) => {
      state.loading = true
    },
    editStateSuccess: (state: any, action) => {
      const result = state.stateList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.stateList.data = result
      state.loading = false
    },
    editStateFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchStateStart,
  fetchStateSuccess,
  fetchStateFailure,
  createStateStart,
  createStateSuccess,
  createStateFailure,
  deleteStateStart,
  deleteStateSuccess,
  deleteStateFailure,
  fetchStateByIdStart,
  fetchStateByIdSuccess,
  fetchStateByIdFailure,
  editStateStart,
  editStateSuccess,
  editStateFailure,
} = stateSetupSlice.actions

export default stateSetupSlice.reducer
