import { axiosRequest, removeEmptyFields } from 'utils'

export const fetchPrizeDrawList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/prizeDrawSetting/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPrizeDrawById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/prizeDrawSetting/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPrizeDraw = (params) => {
  return () =>
    axiosRequest
      .post(`/api/prizeDrawSetting/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editPrizeDraw = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/prizeDrawSetting/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createGeneralSettings = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/globalSetting/piiinkInformation/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGeneralSettingList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/piiinkInformation/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGeneralSettingById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/piiinkInformation/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editGeneralSettings = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/piiinkInformation/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPiiinkInformationGetFirst = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/piiinkInformation/getFirst`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPiiinkInformationGetByCountry = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/piiinkInformation/getOneByCountry/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCoupon = (params) => {
  return () =>
    axiosRequest
      .post(`/api/coupon/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCouponCode = (params) => {
  return () =>
    axiosRequest
      .post(`/api/couponCode/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCouponList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/coupon/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCouponListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/coupon/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCoupon = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/coupon/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCouponCodeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/couponCode/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCouponById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/coupon/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCouponCodeListById = (id, params) => {
  return () =>
    axiosRequest
      .get(`/api/couponCode/getAllCodeByCoupon/${id}`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const activateDeactivateCouponById = (id, params) => {
  return () =>
    axiosRequest
      .patch(`/api/coupon/status/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSmtpDataById = () => {
  return () =>
    axiosRequest
      .get(`/api/documents/smtp/getOne`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editSmtpConfiguration = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/documents/smtp/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const sendTestEmail = (params) => {
  return () =>
    axiosRequest
      .post(`api/documents/sendEmail/sendTestEmail`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGroupInformationList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/groupInformation/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGroupInformationListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/groupInformation/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createGroupInformationList = (params) => {
  return () =>
    axiosRequest
      .post(`/api/documents/groupInformation/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGroupInformationById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/documents/groupInformation/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteGroupInformationList = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/documents/groupInformation/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editGroupInformationList = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/documents/groupInformation/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchContactList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/contactInformation/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGrouplessContactList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/contactInformation/getNotUsedGroup`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGrouplessContactListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/contactInformation/getNotUsedGroup/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchContactListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/contactInformation/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createContactListList = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/documents/contactInformation/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchContactById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/documents/contactInformation/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchEmailDataById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/documents/sendEmail/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editContactInfo = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/documents/contactInformation/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteContactInfo = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/documents/contactInformation/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchUploadDocumentList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/uploadDocument/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchUploadDocumentListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/uploadDocument/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchDocumentById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/documents/uploadDocument/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteDocument = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/documents/uploadDocument/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSendEmailList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/sendEmail/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSendEmailListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/sendEmail/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveEmailAsDraft = (params) => {
  return () =>
    axiosRequest
      .post(`/api/documents/sendEmail/saveAsDraft`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveAndSendEmail = (params) => {
  return () =>
    axiosRequest
      .post(`/api/documents/sendEmail/sendEmail`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchRecipientsById = async (id, params) => {
  return await axiosRequest
    .get(`api/documents/groupInformation/getByGroupId/${id}`, { params })
    .then((res) => {
      return Promise.resolve(res?.data?.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const generateTemporaryPassword = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalAdmin/createTemporaryPassword`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTemporaryPasswordList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalAdmin/getAllPasswordLog`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCouponSettingList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/couponSetting/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCouponSetting = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/globalSetting/couponSetting/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCouponSettingById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/couponSetting/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCouponSetting = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/couponSetting/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAppRangeSettingList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/appLocationRange/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createAppRangeSetting = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/globalSetting/appLocationRange/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAppRangeSettingById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/appLocationRange/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editAppRangeSetting = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/appLocationRange/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchDocumentManagementSettingList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/documentSetting/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createDocumentManagementSetting = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/globalSetting/documentSetting/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchDocumentManagementSettingById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/documentSetting/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editDocumentManagementSetting = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/documentSetting/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchReportList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/sendEmail/getAllEmailLog`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchReportListById = (id, params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/sendEmail/getOneEmailLog/${id}`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchReportListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/sendEmail/getAllEmailLogByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const assignContactsToGroup = (params) => {
  return () =>
    axiosRequest
      .put(`/api/documents/groupInformation/selectContactInfomation`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCreatorNameList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/documents/contactInformation/contactNameSearch`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPayoutContactNameList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/payout/invoice/contactNameSearch`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchNotificationListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/notification/notificationForPanel/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createNotification = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/notification/notificationForPanel/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editNotification = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/notification/notificationForPanel/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchNotificationById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/notification/notificationForPanel/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const changeNotificationStatus = (id, params) => {
  return () =>
    axiosRequest
      .put(
        `/api/notification/notificationForPanel/notificationOnOff/${id}`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteNotification = (id, params) => {
  return () =>
    axiosRequest
      .delete(`/api/notification/notificationForPanel/delete/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchNotificationListByUser = (params) => {
  return () =>
    axiosRequest
      .get(`/api/notification/notificationForPanel/getByUser`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchNotificationByUserTypeSeen = (params) => {
  return () =>
    axiosRequest
      .get(`/api/notification/notificationForPanel/getByUserTypeSeen`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchNotificationByUserTypeUnseen = (params) => {
  return () =>
    axiosRequest
      .get(`/api/notification/notificationForPanel/getByUserTypeUnSeen`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const notificationClose = (id, params) => {
  return () =>
    axiosRequest
      .put(
        `/api/notification/notificationForPanel/notificationClose/${id}`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const markNotificationAsUnseen = (id) => {
  return () =>
    axiosRequest
      .put(`/api/notification/notificationForPanel/markAsUnRead/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPayoutInvoiceLog = (params) => {
  return () =>
    axiosRequest
      .post(`/api/payout/invoice/log`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPayoutInvoiceHistory = (params) => {
  return () =>
    axiosRequest
      .get(`/api/payout/invoice/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const stripeChargePayout = (params) => {
  return () =>
    axiosRequest
      .post(`api/stripePayout/chargePayout`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
