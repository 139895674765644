/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'
import {
  fetchAboutUsStart,
  deleteAboutUsStart,
} from '../../../store/websiteAdmin/websiteAdminSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  websiteAdmin: state.websiteAdmin,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchSectionStart: (payload) => dispatch(fetchAboutUsStart(payload)),
  onDeleteSectionStart: (payload) => dispatch(deleteAboutUsStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
