/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { fetchPostStart } from 'store/post/postSlice'
import {
  fetchPageStart,
  createPageStart,
} from 'store/websiteAdmin/websiteAdminSlice'
import { openAlert } from 'store/alert/alertSlice'

import {
  createTransactionBatchStart,
  fetchAssignerStart,
} from 'store/qrCodes/qrCodesSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  qrCodes: state.qrCodes,
  siteCoordinator: state.siteCoordinator,
})

const mapDispatchToProps = (dispatch) => ({
  onCreatePageStart: (payload) => dispatch(fetchAssignerStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
