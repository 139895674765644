import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableHeader from '@crema/core/AppTable/TableHeader'

const TableHeading = () => {
  return (
    <TableHeader>
      <TableCell align="left">SN</TableCell>
      <TableCell align="left">Title</TableCell>
      <TableCell align="left">Short Description</TableCell>
      <TableCell align="left">Brief Description</TableCell>
      <TableCell align="left">Language Code</TableCell>
      <TableCell align="left">Actions</TableCell>
    </TableHeader>
  )
}

export default TableHeading
