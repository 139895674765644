import React, { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  Typography,
  Chip,
  Tooltip,
} from '@mui/material'

import container from './AssignCodeModal.container'
import { Form, Formik } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import InfoIcon from '@mui/icons-material/Info'
import ConfirmDialogWithReason from 'components/ReusableModalComponents/ConfirmDialogWithReason'

import * as yup from 'yup'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

interface EditProps {
  isAddCountryOpen: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry: () => void
  onCreateStateOwnerStart?: (params) => void
  onFetchClubByIdStart: (params) => void
  onEditClubStart: (params) => void
  onSetMerchantPopularStart: (params) => void
  onAssignIssuerTransactionCodeStart: (params) => void
  onDeleteTransactionCodeByIdStart?: (params) => void
  onDeleteIssuerCodeByIdStart?: (params) => void
  onCloseEditDialog?: () => void
  onDeny?: () => void
  closeMarkAsPopularModal?: () => void
  refreshPageData?: () => void
  selectedStateOwnerId: number
  club: any
  selectedMerchantObj?: any
  setSelectedMerchantObj?: any
}

const AssignCodeModal: React.FC<EditProps & any> = ({
  onCloseEditDialog,
  closeMarkAsPopularModal,
  onAssignIssuerTransactionCodeStart,
  club: { loading },
  merchant: { isAssigning },
  refreshPageData,
  selectedMerchantObj,
  onDeleteTransactionCodeByIdStart,
  onDeleteIssuerCodeByIdStart,
  setSelectedMerchantObj,
}) => {
  const navigate = useNavigate()
  const validationSchema = yup.object({})

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [codeToDelete, setCodeToDelete] = useState(null)
  const [selectedCodeType, setSelectedCodeType] = useState(null)

  const handleDelete = (item) => {
    setOpenDeleteModal(true)
    setCodeToDelete(item)
  }

  const closeRejectRequestModal = () => {
    setOpenDeleteModal(false)
  }

  const deleteIssuerOrTransaction = () => {
    if (selectedCodeType === 'transaction') {
      onDeleteTransactionCodeByIdStart({
        params: { id: codeToDelete },
        navigate,
        closeModal: closeRejectRequestModal,
        refreshPageData,
        selectedMerchantObj,
        setSelectedMerchantObj,
      })
    } else if (selectedCodeType === 'issuer') {
      onDeleteIssuerCodeByIdStart({
        params: { id: codeToDelete },
        navigate,
        closeModal: closeRejectRequestModal,
        refreshPageData,
        selectedMerchantObj,
        setSelectedMerchantObj,
      })
    }
  }

  return (
    <>
      {openDeleteModal && (
        <ConfirmDialogWithReason
          open={openDeleteModal}
          onDeny={setOpenDeleteModal}
          onConfirm={closeRejectRequestModal}
          toDeleteId={codeToDelete}
          // deleteFunction={onRejectClubStart}
          onCloseEditDialog={onCloseEditDialog}
          deleteFunction={deleteIssuerOrTransaction}
          title="Are you sure you want to delete this code?"
          dialogTitle="Confirm Delete?"
          hideReasonBox={true}
        />
      )}

      {loading ? (
        '...isLoading'
      ) : (
        <Formik
          validateOnChange={true}
          initialValues={{
            transactionCodes: null,
            issuerCodes: null,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true)

            const splitTransactionCodes = data?.transactionCodes
              ? data?.transactionCodes?.split(',')
              : null

            const splitIssuerCodes = data?.issuerCodes
              ? data?.issuerCodes?.split(',')
              : null

            onAssignIssuerTransactionCodeStart({
              params: {
                ...data,
                id: selectedMerchantObj?.id,
                transactionCodes: splitTransactionCodes?.length
                  ? splitTransactionCodes?.map((item) => item.trim())
                  : null,
                issuerCodes: splitIssuerCodes?.length
                  ? splitIssuerCodes?.map((item) => item.trim())
                  : null,
              },
              closeModal: closeMarkAsPopularModal,
              refreshPageData,
              setSelectedMerchantObj,
              selectedMerchantObj,
            })

            setSubmitting(false)
          }}
          render={() => (
            <Form>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={5}>
                  <Grid item container xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Issuer Code</Typography>
                    </Grid>
                    <Box sx={{ width: '100%', position: 'relative' }}>
                      <Tooltip
                        title={
                          'Enter comma separated issuer codes (eg. AU0000010119,AU0000010118) to assign multiple codes.'
                        }
                        placement="top"
                        sx={{
                          position: 'absolute',
                          right: -8,
                          top: -12,
                          color: '#11182791',
                          cursor: 'pointer',
                        }}
                      >
                        <InfoIcon />
                      </Tooltip>
                      <Grid item xs={12} sx={{ mt: 5 }}>
                        <AppTextField
                          placeholder={'Issuer Code'}
                          label={'Issuer Code'}
                          name="issuerCodes"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Transaction Code</Typography>
                    </Grid>

                    <Box sx={{ width: '100%', position: 'relative' }}>
                      <Tooltip
                        title={
                          'Enter comma separated transaction codes (eg. 6661100008722,6661100008721) to assign multiple codes.'
                        }
                        placement="top"
                        sx={{
                          position: 'absolute',
                          right: -8,
                          top: -12,
                          color: '#11182791',
                          cursor: 'pointer',
                        }}
                      >
                        <InfoIcon />
                      </Tooltip>
                      <Grid item xs={12} sx={{ mt: 5 }}>
                        <AppTextField
                          placeholder={'Transaction Code'}
                          label={'Transaction Code'}
                          name="transactionCodes"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                    </Box>
                  </Grid>
                  {selectedMerchantObj?.issuerCode?.length ? (
                    <Grid item container xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          Existing Issuer Codes
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 5 }}>
                        {selectedMerchantObj?.issuerCode?.length ? (
                          selectedMerchantObj?.issuerCode?.map(
                            (item, index) => (
                              <Chip
                                key={index}
                                label={item ?? '-'}
                                color="primary"
                                variant="outlined"
                                onDelete={() => {
                                  setSelectedCodeType('issuer')
                                  handleDelete(item)
                                }}
                                sx={{ ml: 3, mt: 2 }}
                              />
                            )
                          )
                        ) : (
                          <Box sx={{ m: 1 }}>
                            <Typography variant="body1" sx={{ ml: 3 }}>
                              Issuer Codes Not Found
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}

                  {selectedMerchantObj?.transactionCode?.length ? (
                    <Grid item container xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          Existing Transaction Codes
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 5 }}>
                        {selectedMerchantObj?.transactionCode?.length ? (
                          selectedMerchantObj?.transactionCode?.map(
                            (item, index) => (
                              <Chip
                                key={index}
                                label={item ?? '-'}
                                color="primary"
                                variant="outlined"
                                onDelete={() => {
                                  setSelectedCodeType('transaction')
                                  handleDelete(item)
                                }}
                                sx={{ ml: 3, mt: 2 }}
                              />
                            )
                          )
                        ) : (
                          <Box sx={{ m: 1 }}>
                            <Typography variant="body1" sx={{ ml: 3 }}>
                              Transaction Codes Not Found
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>

                <Box sx={{ height: 20 }} />
                <Box sx={{ height: 30 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isAssigning}
                  >
                    {isAssigning ? <CircularProgress size={20} /> : 'Save'}
                  </Button>
                  &nbsp; &nbsp;
                  <Button onClick={closeMarkAsPopularModal} variant="outlined">
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        />
      )}
    </>
  )
}

export default container(AssignCodeModal)
