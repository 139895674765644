import React, { ReactNode } from 'react'

import { RoutePermittedRole } from '../../shared/constants/AppConst'
import { GrHome } from 'react-icons/gr'

import { MdOutlineStorefront } from 'react-icons/md'

import LogoutIcon from '@mui/icons-material/Logout'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const merchantFranchiseAdminRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            messageId: 'Dashboard',
            type: 'item',
            url: '/dashboard',
          },
        ],
      },
      {
        id: 'Merchant',
        title: 'Merchants',
        messageId: 'Merchants',
        type: 'collapse',
        icon: <MdOutlineStorefront />,
        children: [
          {
            id: 'sendMerchantRequest',
            title: 'Send Merchant Request',
            messageId: 'Send Merchant Request',
            type: 'item',
            url: '/merchantgroup/sendMerchantRequest',
          },
          {
            id: 'draftList',
            title: 'Draft List',
            messageId: 'Draft List',
            type: 'item',
            url: '/merchantgroup/draftList',
          },
          {
            id: 'merchantList',
            title: 'Merchants List',
            messageId: 'Merchants List',
            type: 'item',
            url: '/merchantgroup/merchantList',
          },
        ],
      },

      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },
    ],
  },
]
export default merchantFranchiseAdminRoutesConfig
