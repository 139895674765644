import React from 'react'
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import AuthRoutes from '@crema/utility/AuthRoutes'
import AppContextProvider from '@crema/utility/AppContextProvider'
import AppThemeProvider from '@crema/utility/AppThemeProvider'
import AppStyleProvider from '@crema/utility/AppStyleProvider'
import AppLocaleProvider from '@crema/utility/AppLocaleProvider'
import AppLayout from '@crema/core/AppLayout'
import { BrowserRouter } from 'react-router-dom'
import { store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from 'store'

const options = {
  // passing the client secret obtained from the server
  clientSecret: '{{CLIENT_SECRET}}',
}

const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppThemeProvider>
          <AppStyleProvider>
            <AppLocaleProvider>
              <BrowserRouter>
                <AuthRoutes>
                  <CssBaseline />
                  <AppLayout />
                </AuthRoutes>
              </BrowserRouter>
            </AppLocaleProvider>
          </AppStyleProvider>
        </AppThemeProvider>
      </PersistGate>
    </Provider>
  </AppContextProvider>
)

export default App
