import { createSlice } from '@reduxjs/toolkit'

import { IMerchantFee } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IMerchantFee = {
  merchantFeeList: listObj,
  soleMerchantFee: {},
  error: null,
  loading: false,
  isFetching: false,
  hasMoreData: false,
}

export const merchantFeeSlice = createSlice({
  name: 'merchantFee',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMerchantFeeStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantFeeSuccess: (state: any, action) => {
      state.merchantFeeList = action.payload
      state.loading = false
    },
    fetchMerchantFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMerchantFeeByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantFeeByIdSuccess: (state: any, action) => {
      state.soleMerchantFee = action.payload.data
      state.isFetching = false
    },
    fetchMerchantFeeByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createMerchantFeeStart: (state: any, action) => {
      state.loading = true
    },
    createMerchantFeeSuccess: (state: any, action) => {
      state.loading = false
      state.merchantFeeList.data = [
        ...state.merchantFeeList.data,
        action.payload,
      ]
    },
    createMerchantFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteMerchantFeeStart: (state: any, action) => {
      state.loading = true
    },
    deleteMerchantFeeSuccess: (state: any, action) => {
      state.loading = false
      state.merchantFeeList.data = state.merchantFeeList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteMerchantFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editMerchantFeeStart: (state: any, action) => {
      state.loading = true
    },
    editMerchantFeeSuccess: (state: any, action) => {
      const result = state.merchantFeeList?.data?.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.merchantFeeList.data = result

      state.loading = false
    },
    editMerchantFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchMerchantFeeStart,
  fetchMerchantFeeSuccess,
  fetchMerchantFeeFailure,
  createMerchantFeeStart,
  createMerchantFeeSuccess,
  createMerchantFeeFailure,
  deleteMerchantFeeStart,
  deleteMerchantFeeSuccess,
  deleteMerchantFeeFailure,
  fetchMerchantFeeByIdStart,
  fetchMerchantFeeByIdSuccess,
  fetchMerchantFeeByIdFailure,
  editMerchantFeeStart,
  editMerchantFeeSuccess,
  editMerchantFeeFailure,
} = merchantFeeSlice.actions

export default merchantFeeSlice.reducer
