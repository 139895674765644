import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
const EoiCountryOwner = React.lazy(() => import('./EoiCountryOwner'))
const CountryOwner = React.lazy(() => import('./CountryOwner'))

export const countryOwnersConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/eoicountryowner',
    element: <EoiCountryOwner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/countryowner',
    element: <CountryOwner />,
  },
]
