import { takeLatest, call, all, select, put, delay } from 'redux-saga/effects'

import * as eoiAction from './eoiSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import { setRecentMerchant } from '../siteCoordinator/siteCoordinatorSlice'

import {
  createSalesAgentEOI,
  createCharityEOI,
  createClubEOI,
  eoiCreateStep1Draft,
  eoiCreateStep1General,
  eoiEditStep1Draft,
  eoiCreateStep2Draft,
  eoiCreateStep2General,
  eoiCreateStep3Draft,
  eoiCreateStep3General,
  eoiCreateStep4Draft,
  eoiCreateStep4General,
  eoiFetchMerchantBasicInfo,
  eoiFetchMerchantWebsiteInfo,
  eoiFetchMerchantImage,
  eoiFetchMerchantBankInfo,
  eoiFetchMerchantDiscount,
  eoiEditStep2General,
  eoiEditStep2Draft,
  eoiEditStep3General,
  eoiEditStep3Draft,
  eoiEditStep4General,
  eoiEditStep4Draft,
  eoiEditStep1General,
  eoiCreateBankInfo,
  eoiCheckApplyMerchantCodeUponUpgrade,
  eoiCheckApplyMerchantPremiumCode,
  eoiFetchReferralFromToken,
  eoiCreateMerchantGroup,
  eoiCheckApplyCouponCode,
  eoiCouponCodeLogUpdate,
} from 'api/eoi'

import { deleteImage, getImageUrl, uploadImage } from 'api/imageUpload'
import { getImageIdFromUrl, removeEmptyFields, removeParam } from 'utils'

import _ from 'lodash'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* createSalesAgentEOIAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createSalesAgentEOI(payload.params))
    yield put(eoiAction.createSalesAgentEOISuccess(data?.data))
    if (payload?.resetForm) {
      yield payload.resetForm()
    }
    yield put(
      openAlert({
        message:
          'Sales Agent EOI Request Successfully Submitted. You will soon receive confirmation email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(eoiAction.createSalesAgentEOIFailure(err))

    console.error(err)
  }
}

// export function* createCharityEOIAsync({ payload }: AnyAction) {
//   try {
//     const { data } = yield call(createCharityEOI(payload.params))
//     yield put(createCharityEOISuccess(data?.data))
//     yield payload.resetForm()
//     yield put(
//       openAlert({
//         message: 'Charity EOI succesfully created',
//         severity: 'success',
//       })
//     )
//   } catch (err) {
//     yield put(
//       openAlert({
//         message: err.message,
//         severity: 'error',
//       })
//     )

//     yield put(createCharityEOIFailure(err))

//     console.error(err)
//   }
// }

export function* createCharityEOIAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createCharityEOI({ ...payload.params, logoUrl: logoUrl })
      )

      yield put(eoiAction.createCharityEOISuccess(data?.data))

      yield payload.setUploadedFiles([])
    } else {
      const { data } = yield call(createCharityEOI(payload?.params))
      yield put(eoiAction.createCharityEOISuccess(data?.data))
    }

    if (payload?.resetForm) {
      yield payload?.resetForm()
    }

    yield put(
      openAlert({
        message:
          'Charity EOI Request Successfully Submitted. You will soon receive confirmation email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(eoiAction.createCharityEOIFailure(err))

    console.error(err)
  }
}

// export function* createClubEOIAsync({ payload }: AnyAction) {
//   try {
//     const { data } = yield call(createClubEOI(payload.params))
//     yield put(createClubEOISuccess(data?.data))
//     yield payload.resetForm()
//     yield put(
//       openAlert({
//         message: 'Club EOI succesfully created',
//         severity: 'success',
//       })
//     )
//   } catch (err) {
//     yield put(
//       openAlert({
//         message: err.message,
//         severity: 'error',
//       })
//     )

//     yield put(createClubEOIFailure(err))

//     console.error(err)
//   }
// }

export function* createClubEOIAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createClubEOI({ ...payload.params, logoUrl: logoUrl })
      )

      yield put(eoiAction.createClubEOISuccess(data?.data))

      yield payload.setUploadedFiles([])
    } else {
      const { data } = yield call(createClubEOI(payload?.params))
      yield put(eoiAction.createClubEOISuccess(data?.data))
    }

    if (payload?.resetForm) {
      yield payload?.resetForm()
    }

    yield put(
      openAlert({
        message:
          'Club EOI Request Successfully Submitted. You will soon receive confirmation email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(eoiAction.createClubEOIFailure(err))

    console.error(err)
  }
}

export function* eoiCreateStep1DraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiCreateStep1Draft(payload?.params))

    yield put(setRecentMerchant(data?.data?.merchantDraft))
    // yield payload?.handleChangeTab(null, 1)

    yield put(
      openAlert({
        message:
          'Merchant data successfully saved to draft. Please check your email',
        severity: 'success',
      })
    )

    if (payload?.hasReferralToken == true) {
      let newurl =
        window.location.href + `&merchantToken=${data?.data?.merchantToken}`
      window.history.pushState({ path: newurl }, '', newurl)
      let urlLink = new URLSearchParams(window.location.search)
      urlLink.set('merchantToken', data?.data?.merchantToken)

      yield put(eoiAction.eoiCreateStep1DraftSuccess(data?.data?.merchant))
    } else {
      yield put(eoiAction.eoiCreateStep1DraftSuccess(data?.data))
    }

    // if (payload?.route) {
    //   payload?.navigate(payload?.route)
    // }
    setTimeout(function () {
      window.location.replace('https://www.piiink.org/')
    }, 2000)
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiCreateStep1DraftFailure(err))
  }
}

export function* eoiCreateStep1GeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiCreateStep1General(payload?.params))

    yield put(setRecentMerchant(data?.data?.merchantToken))
    if (payload?.route) {
      payload?.navigate(
        `${payload?.route}?merchantToken=${data?.data?.merchantToken}`
      )

      yield payload?.handleChangeTab(null, 1)
    } else {
      yield payload?.handleChangeTab(null, 1)
    }

    if (payload?.hasReferralToken == true) {
      let newurl =
        window.location.href + `&merchantToken=${data?.data?.merchantToken}`
      window.history.pushState({ path: newurl }, '', newurl)
      let urlLink = new URLSearchParams(window.location.search)
      urlLink.set('merchantToken', data?.data?.merchantToken)

      yield put(eoiAction.eoiCreateStep1GeneralSuccess(data?.data?.merchant))
    } else {
      yield put(eoiAction.eoiCreateStep1GeneralSuccess(data?.data))
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.resetForm) {
      yield payload.resetForm()
    }

    yield put(
      openAlert({
        message: 'Merchant data successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiCreateStep1GeneralFailure(err))
  }
}

export function* eoiEditStep1Async({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      eoiEditStep1General(payload?.params?.merchantId, payload?.params)
    )
    yield put(eoiAction.eoiEditStep1Success(data?.data))

    if (!payload?.fromDraft) {
      yield payload?.handleChangeTab(null, 1)
    } else {
      payload?.navigate(payload?.route)
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.resetForm) {
      yield payload.resetForm()
    }
    yield put(
      openAlert({
        message: 'Merchant data successfully updated',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiEditStep1Failure(err))
  }
}

export function* eoiEditDraftStep1Async({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      eoiEditStep1Draft(payload?.params?.merchantId, payload?.params)
    )
    yield put(eoiAction.eoiEditStep1Success(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.resetForm) {
      yield payload.resetForm()
    }
    yield put(
      openAlert({
        message:
          'Merchant data successfully updated to draft. Please check your email',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiEditDraftStep1Failure(err))
  }
}

export function* eoiEditStep2Async({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      eoiEditStep2General(payload?.params?.merchantId, payload?.params)
    )
    yield put(eoiAction.eoiEditStep2Success(data?.data))

    yield payload?.handleChangeTab(null, 2)

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Merchant data successfully updated',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiEditStep2Failure(err))
  }
}

export function* eoiEditDraftStep2Async({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      eoiEditStep2Draft(payload?.params?.merchantId, payload?.params)
    )
    yield put(eoiAction.eoiEditDraftStep2Success(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message:
          'Merchant data successfully updated to draft. Please check your email',
        severity: 'success',
      })
    )

    setTimeout(function () {
      window.location.replace('https://www.piiink.org/')
    }, 2000)
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiEditDraftStep2Failure(err))
  }
}

export function* eoiCreateStep2DraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiCreateStep2Draft(payload?.params))
    yield put(eoiAction.eoiCreateStep2DraftSuccess(data?.data))
    // yield payload?.handleChangeTab(null, 2)
    // if (payload?.route) {
    //   payload?.navigate(payload?.route)
    // }

    if (payload?.saveOnly) {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved',
          severity: 'success',
        })
      )
    } else {
      yield put(
        openAlert({
          message:
            'Merchant data successfully saved to draft. Please check your email',
          severity: 'success',
        })
      )
    }

    setTimeout(function () {
      window.location.replace('https://www.piiink.org/')
    }, 2000)
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiCreateStep2DraftFailure(err))
  }
}

export function* eoiCreateStep2GeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiCreateStep2General(payload?.params))
    yield put(eoiAction.eoiCreateStep2GeneralSuccess(data?.data))
    yield payload?.handleChangeTab(null, 2)

    yield put(
      openAlert({
        message: 'Merchant data successfully saved',
        severity: 'success',
      })
    )
    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      } else {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      }
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiCreateStep2GeneralFailure(err))
  }
}

export function* eoiCreateStep3DraftAsync({ payload }: AnyAction) {
  try {
    let logoUrl = ''
    let silder1Url = ''
    let silder2Url = ''
    let silder3Url = ''
    let silder4Url = ''
    let silder5Url = ''
    let silder6Url = ''

    if (!_.isEmpty(payload?.params) && payload?.params?.logoUrl?.size) {
      const initialImageUrl = yield call(getImageUrl())

      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logoUrl)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider1) &&
      payload?.params?.slider1?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider1)
        )

        silder1Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider2) &&
      payload?.params?.slider2?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider2)
        )

        silder2Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider3) &&
      payload?.params?.slider3?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider3)
        )

        silder3Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider4) &&
      payload?.params?.slider4?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider4)
        )

        silder4Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider5) &&
      payload?.params?.slider5?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider5)
        )

        silder5Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider6) &&
      payload?.params?.slider6?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider6)
        )

        silder6Url = removeParam(imageResponse.config.url)
      }
    }

    const shouldSend = [
      silder1Url,
      silder2Url,
      silder3Url,
      silder4Url,
      silder5Url,
      silder6Url,
      logoUrl,
    ].some((item) => item)

    if (shouldSend) {
      const { data } = yield call(
        eoiCreateStep3Draft(
          removeEmptyFields({
            ...payload?.params,
            logoUrl,
            slider1: silder1Url,
            slider2: silder2Url,
            slider3: silder3Url,
            slider4: silder4Url,
            slider5: silder5Url,
            slider6: silder6Url,
          })
        )
      )
    }

    yield put(eoiAction.eoiCreateStep3DraftSuccess())
    // if (payload?.route) {
    //   payload?.navigate(payload?.route)
    // }
    // yield payload?.handleChangeTab(null, 3)

    yield put(
      openAlert({
        message:
          'Merchant data successfully saved to draft. Please check your email',
        severity: 'success',
      })
    )

    setTimeout(function () {
      window.location.replace('https://www.piiink.org/')
    }, 2000)
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiCreateStep3DraftFailure(err))
  }
}

export function* eoiCreateStep3GeneralAsync({ payload }: AnyAction) {
  try {
    let sliderUrls = []
    let logoUrl = ''

    // for (let i = 0; i < payload?.sliderImage.length || 0; i++) {
    for (let i = 0; i < 6; i++) {
      if (payload.sliderImage[i]?.size) {
        const initialImageUrl = yield call(getImageUrl())

        if (!_.isEmpty(initialImageUrl?.data)) {
          const imageResponse = yield call(
            uploadImage(initialImageUrl?.data?.url, payload.sliderImage[i])
          )

          sliderUrls[i] = removeParam(imageResponse.config.url)

          if (payload?.oldImages?.sliderImage?.[i]) {
            yield call(
              deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
            )
          }
        }
      } else {
        sliderUrls[i] = payload.sliderImage[i] || null

        if (
          payload?.oldImages?.sliderImage?.[i] &&
          !payload.sliderImage.some(
            (item) => payload?.oldImages?.sliderImage?.[i] === item
          )
        ) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
          )
        }
      }
    }

    if (payload?.logoImage?.size) {
      const initialImageUrl = yield call(getImageUrl())

      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logoImage)
        )

        logoUrl = removeParam(imageResponse.config.url)

        if (payload?.oldImages?.logoImage) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.logoImage))
          )
        }
      }
    } else {
      logoUrl = payload?.logoImage
    }

    const sliderUrlObj = sliderUrls.reduce(
      (acc, val, ind) => ({ ...acc, [`slider${ind + 1}`]: val }),
      {}
    )

    if (payload?.isDraft) {
      yield call(
        eoiCreateStep3Draft({
          ...payload?.params,
          ...sliderUrlObj,
          logoUrl,
        })
      )
    } else {
      yield call(
        eoiCreateStep3General({
          ...payload?.params,
          ...sliderUrlObj,
          logoUrl,
        })
      )
    }

    yield put(eoiAction.eoiCreateStep3GeneralSuccess())

    // yield put(
    //   eoiAction?.fetchMerchantGeneralImagePanelUserStart(
    //     payload?.params?.merchantId
    //   )
    // )

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      } else {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      }
    }

    if (payload?.changeTab) {
      yield payload?.changeTab()
    }
    if (payload?.isDraft) {
      yield put(
        openAlert({
          message:
            'Merchant data successfully saved to draft. Please check your email',
          severity: 'success',
        })
      )

      setTimeout(function () {
        window.location.replace('https://www.piiink.org/')
      }, 2000)
    } else {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    if (err.message === 'logoUrl should not be empty or empty') {
      yield put(openAlert({ message: 'Logo required', severity: 'error' }))
    } else {
      yield put(openAlert({ message: err.message, severity: 'error' }))
    }
    yield put(eoiAction.eoiCreateStep3GeneralFailure(err))
  }
}

export function* eoiEditStep3Async({ payload }: AnyAction) {
  try {
    let sliderUrls = []
    let logoUrl = ''

    // for (let i = 0; i < payload?.sliderImage.length || 0; i++) {
    for (let i = 0; i < 6; i++) {
      if (payload.sliderImage[i]?.size) {
        const initialImageUrl = yield call(getImageUrl())

        if (!_.isEmpty(initialImageUrl?.data)) {
          const imageResponse = yield call(
            uploadImage(initialImageUrl?.data?.url, payload.sliderImage[i])
          )

          sliderUrls[i] = removeParam(imageResponse.config.url)

          if (payload?.oldImages?.sliderImage?.[i]) {
            yield call(
              deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
            )
          }
        }
      } else {
        sliderUrls[i] = payload.sliderImage[i] || null

        if (
          payload?.oldImages?.sliderImage?.[i] &&
          !payload.sliderImage.some(
            (item) => payload?.oldImages?.sliderImage?.[i] === item
          )
        ) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
          )
        }
      }
    }

    if (payload?.logoImage?.size) {
      const initialImageUrl = yield call(getImageUrl())

      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logoImage)
        )

        logoUrl = removeParam(imageResponse.config.url)

        if (payload?.oldImages?.logoImage) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.logoImage))
          )
        }
      }
    } else {
      logoUrl = payload?.logoImage
    }

    const sliderUrlObj = sliderUrls.reduce(
      (acc, val, ind) => ({ ...acc, [`slider${ind + 1}`]: val }),
      {}
    )

    if (payload?.isDraft) {
      yield call(
        eoiEditStep3Draft(payload?.params?.merchantId, {
          ...payload?.params,
          ...sliderUrlObj,
          logoUrl,
        })
      )
    } else {
      yield call(
        eoiEditStep3General(payload?.params?.merchantId, {
          ...payload?.params,
          ...sliderUrlObj,
          logoUrl,
        })
      )
    }

    yield put(eoiAction.eoiEditStep3Success())

    // yield put(
    //   eoiAction?.fetchMerchantGeneralImagePanelUserStart(
    //     payload?.params?.merchantId
    //   )
    // )

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      } else {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      }
    }

    yield payload?.changeTab()

    if (payload?.isDraft) {
      yield put(
        openAlert({
          message:
            'Merchant data successfully saved to draft. Please check your email',
          severity: 'success',
        })
      )
      setTimeout(function () {
        window.location.replace('https://www.piiink.org/')
      }, 2000)
    } else {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiEditStep3Failure(err))
  }
}

/////////////
export function* eoiEditDraftStep3Async({ payload }: AnyAction) {
  try {
    let sliderUrls = []
    let logoUrl = ''

    // for (let i = 0; i < payload?.sliderImage.length || 0; i++) {
    for (let i = 0; i < 6; i++) {
      if (payload.sliderImage[i]?.size) {
        const initialImageUrl = yield call(getImageUrl())

        if (!_.isEmpty(initialImageUrl?.data)) {
          const imageResponse = yield call(
            uploadImage(initialImageUrl?.data?.url, payload.sliderImage[i])
          )

          sliderUrls[i] = removeParam(imageResponse.config.url)

          if (payload?.oldImages?.sliderImage?.[i]) {
            yield call(
              deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
            )
          }
        }
      } else {
        sliderUrls[i] = payload.sliderImage[i] || null

        if (
          payload?.oldImages?.sliderImage?.[i] &&
          !payload.sliderImage.some(
            (item) => payload?.oldImages?.sliderImage?.[i] === item
          )
        ) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
          )
        }
      }
    }

    if (payload?.logoImage?.size) {
      const initialImageUrl = yield call(getImageUrl())

      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logoImage)
        )

        logoUrl = removeParam(imageResponse.config.url)

        if (payload?.oldImages?.logoImage) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.logoImage))
          )
        }
      }
    } else {
      logoUrl = payload?.logoImage
    }

    const sliderUrlObj = sliderUrls.reduce(
      (acc, val, ind) => ({ ...acc, [`slider${ind + 1}`]: val }),
      {}
    )

    if (payload?.isDraft) {
      yield call(
        eoiEditStep3Draft(payload?.params?.merchantId, {
          ...payload?.params,
          ...sliderUrlObj,
          logoUrl,
        })
      )
    } else {
      yield call(
        eoiEditStep3General(payload?.params?.merchantId, {
          ...payload?.params,
          ...sliderUrlObj,
          logoUrl,
        })
      )
    }

    yield put(eoiAction.eoiEditDraftStep3Success())

    // yield put(
    //   eoiAction?.fetchMerchantGeneralImagePanelUserStart(
    //     payload?.params?.merchantId
    //   )
    // )

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      } else {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      }
    }

    // yield payload?.changeTab()

    if (payload?.isDraft) {
      yield put(
        openAlert({
          message:
            'Merchant data successfully saved to draft. Please check your email',
          severity: 'success',
        })
      )

      setTimeout(function () {
        window.location.replace('https://www.piiink.org/')
      }, 2000)
    } else {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiEditDraftStep3Failure(err))
  }
}

export function* eoiCreateStep4DraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiCreateStep4Draft(payload?.params))
    yield put(eoiAction.eoiCreateStep1DraftSuccess(data?.data))
    // yield payload?.handleChangeTab(null, 4)
    if (payload?.route) {
      payload?.navigate(payload?.route)
    }

    if (payload?.saveOnly) {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved',
          severity: 'success',
        })
      )
    } else {
      yield put(
        openAlert({
          message:
            'Merchant data successfully saved to draft. Please check your email',
          severity: 'success',
        })
      )
    }

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      } else {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      }
    }

    setTimeout(function () {
      window.location.replace('https://www.piiink.org/')
    }, 2000)
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiCreateStep4DraftFailure(err))
  }
}

export function* eoiCreateStep4GeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiCreateStep4General(payload?.params))
    yield put(eoiAction.eoiCreateStep4GeneralSuccess(data?.data))

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      } else {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      }
    }
    yield payload?.handleChangeTab(null, 4)

    yield put(
      openAlert({
        message: 'Merchant data successfully saved',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiCreateStep4GeneralFailure(err))
  }
}

export function* eoiEditStep4Async({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      eoiEditStep4General(payload?.params?.merchantId, payload?.params)
    )
    yield put(eoiAction.eoiEditStep4Success(data?.data))

    yield payload?.handleChangeTab(null, 4)

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      } else {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      }
    }

    yield put(
      openAlert({
        message: 'Merchant data successfully saved',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiEditStep4Failure(err))
  }
}

export function* eoiEditDraftStep4Async({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      eoiEditStep4Draft(payload?.params?.merchantId, payload?.params)
    )
    yield put(eoiAction.eoiEditDraftStep4Success(data?.data))

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(payload?.params?.merchantId)
        )
      } else {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(payload?.params?.merchantId)
        )
      }
    }

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      } else {
        yield put(
          eoiAction?.eoiFetchMerchantBasicInfoStart(
            payload?.params?.merchantToken
          )
        )
      }
    }

    // yield payload?.handleChangeTab(null, 4)

    yield put(
      openAlert({
        message:
          'Merchant data successfully saved to draft.Please check your email',
        severity: 'success',
      })
    )

    setTimeout(function () {
      window.location.replace('https://www.piiink.org/')
    }, 2000)
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiEditDraftStep4Failure(err))
  }
}

export function* eoiFetchMerchantBasicInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiFetchMerchantBasicInfo(payload))

    yield put(
      eoiAction.eoiFetchMerchantBasicInfoSuccess({
        ...data?.result,
        signerInfo: data?.signerInfo,
      })
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))

    if (err.response.status == 403) {
      window.location.replace('/error-403')
    }

    yield put(eoiAction.eoiFetchMerchantBasicInfoFailure(err))
  }
}

export function* eoiFetchMerchantWebsiteInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiFetchMerchantWebsiteInfo(payload))

    yield put(eoiAction.eoiFetchMerchantWebsiteInfoSuccess(data?.result))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiFetchMerchantWebsiteInfoFailure(err))
  }
}

export function* eoiFetchMerchantImageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiFetchMerchantImage(payload))

    yield put(eoiAction.eoiFetchMerchantImageSuccess(data?.result))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiFetchMerchantImageFailure(err))
  }
}

export function* eoiFetchMerchantDiscountInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiFetchMerchantDiscount(payload))

    yield put(eoiAction.eoiFetchMerchantDiscountInfoSuccess(data?.result))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiFetchMerchantDiscountInfoFailure(err))
  }
}

export function* eoiFetchMerchantBankInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiFetchMerchantBankInfo(payload))

    yield put(eoiAction.eoiFetchMerchantBankInfoSuccess(data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiFetchMerchantBankInfoFailure(err))
  }
}

export function* eoiCreateBankInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiCreateBankInfo(payload?.params))

    yield put(eoiAction.eoiCreateBankInfoSuccess(data?.result))
    // if (payload?.route) {
    //   payload?.navigate(payload?.route)
    // }
    // if (!_.isEmpty(data?._couponLog)) {
    //   yield call(
    //     eoiCouponCodeLogUpdate({
    //       transactionId: payload?.params?.stripeTokenId,
    //       couponLogId: data?._couponLog?.id,
    //     })
    //   )
    // }

    yield put(
      openAlert({
        message:
          'Congratulations!! Your EOI has been submitted successfully to Piiink. You will soon receive confirmation email',
        severity: 'success',
      })
    )

    yield (window.location.href = 'https://www.piiink.org')
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiCreateBankInfoFailure(err))
  }
}

export function* applyMerchantCouponCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiCheckApplyCouponCode(payload?.params))
    const successMessage = 'Coupon Code Applied Successfully'
    yield put(
      eoiAction.eoiApplyMerchantCouponCodeSuccess({
        successMessage,
        data: data,
      })
    )
    yield put(
      openAlert({
        message: 'Applied Coupon Code Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      eoiAction.eoiApplyMerchantCouponCodeFailure(err?.response?.data?.message)
    )
    yield put(eoiAction.clearMerchantCodeData())
  }
}

export function* eoiCheckApplyMerchantPremiumCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = payload?.fromUpgradeMerchant
      ? yield call(eoiCheckApplyMerchantCodeUponUpgrade(payload?.params))
      : yield call(eoiCheckApplyMerchantPremiumCode(payload?.params))

    const successMessage = 'Premium Code Applied Successfully'
    yield put(
      eoiAction.eoiCheckApplyMerchantPremiumCodeSuccess({
        successMessage,
        data: data,
      })
    )
    yield put(
      openAlert({
        message: 'Applied Premium Code Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    // yield put(
    //   openAlert({ message: err?.response?.data?.message, severity: 'error' })
    // )
    yield put(
      eoiAction.eoiCheckApplyMerchantPremiumCodeFailure(
        err?.response?.data?.message
      )
    )
  }
}

export function* eoiFetchReferralFromTokenAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(eoiFetchReferralFromToken(payload?.params))

    yield put(eoiAction.eoiFetchReferralFromTokenSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))

    yield put(eoiAction.eoiFetchReferralFromTokenFailure(err))
  }
}

export function* eoiCreateMerchantGroupAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        eoiCreateMerchantGroup({ ...payload.params, logoUrl: logoUrl })
      )

      yield put(eoiAction.eoiCreateMerchantGroupSuccess(data?.data))
    } else {
      const { data } = yield call(eoiCreateMerchantGroup({ ...payload.params }))

      yield put(eoiAction.eoiCreateMerchantGroupSuccess(data?.data))
    }

    if (payload?.resetForm) {
      yield payload.resetForm()
    }

    yield put(
      openAlert({
        message: 'Group Merchant successfully created',
        severity: 'success',
      })
    )

    setTimeout(function () {
      window.location.replace('https://www.piiink.org/')
    }, 2000)
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(eoiAction.eoiCreateMerchantGroupFailure(err))
  }
}

export function* watchCreateSalesAgentEoi() {
  yield takeLatest('eoi/createSalesAgentEOIStart', createSalesAgentEOIAsync)
}

export function* watchCreateCharityEoi() {
  yield takeLatest('eoi/createCharityEOIStart', createCharityEOIAsync)
}

export function* watchCreateClubEoi() {
  yield takeLatest('eoi/createClubEOIStart', createClubEOIAsync)
}

export function* watchEoiCreateStep1Draft() {
  yield takeLatest('eoi/eoiCreateStep1DraftStart', eoiCreateStep1DraftAsync)
}

export function* watchEoiCreateStep1General() {
  yield takeLatest('eoi/eoiCreateStep1GeneralStart', eoiCreateStep1GeneralAsync)
}

export function* watchEoiEditStep1() {
  yield takeLatest('eoi/eoiEditStep1Start', eoiEditStep1Async)
}

export function* watchEoiEditDraftStep1Start() {
  yield takeLatest('eoi/eoiEditDraftStep1Start', eoiEditDraftStep1Async)
}

export function* watchEoiEditStep2() {
  yield takeLatest('eoi/eoiEditStep2Start', eoiEditStep2Async)
}

export function* watchEoiEditDraftStep2() {
  yield takeLatest('eoi/eoiEditDraftStep2Start', eoiEditDraftStep2Async)
}

export function* watchEoiCreateStep2Draft() {
  yield takeLatest('eoi/eoiCreateStep2DraftStart', eoiCreateStep2DraftAsync)
}

export function* watchEoiCreateStep2General() {
  yield takeLatest('eoi/eoiCreateStep2GeneralStart', eoiCreateStep2GeneralAsync)
}

export function* watchEoiCreateStep3Draft() {
  yield takeLatest('eoi/eoiCreateStep3DraftStart', eoiCreateStep3DraftAsync)
}

export function* watchEoiCreateStep3General() {
  yield takeLatest('eoi/eoiCreateStep3GeneralStart', eoiCreateStep3GeneralAsync)
}

export function* watchEoiEditStep3() {
  yield takeLatest('eoi/eoiEditStep3Start', eoiEditStep3Async)
}

export function* watchEoiEditDraftStep3() {
  yield takeLatest('eoi/eoiEditDraftStep3Start', eoiEditDraftStep3Async)
}

export function* watchEoiCreateStep4Draft() {
  yield takeLatest('eoi/eoiCreateStep4DraftStart', eoiCreateStep4DraftAsync)
}

export function* watchEoiCreateStep4General() {
  yield takeLatest('eoi/eoiCreateStep4GeneralStart', eoiCreateStep4GeneralAsync)
}

export function* watchEoiEditStep4() {
  yield takeLatest('eoi/eoiEditStep4Start', eoiEditStep4Async)
}

export function* watchEoiEditDraftStep4() {
  yield takeLatest('eoi/eoiEditDraftStep4Start', eoiEditDraftStep4Async)
}

export function* watchEoiFetchMerchantBasicInfo() {
  yield takeLatest(
    'eoi/eoiFetchMerchantBasicInfoStart',
    eoiFetchMerchantBasicInfoAsync
  )
}

export function* watchEoiFetchMerchantWebsiteInfo() {
  yield takeLatest(
    'eoi/eoiFetchMerchantWebsiteInfoStart',
    eoiFetchMerchantWebsiteInfoAsync
  )
}

export function* watchEoiFetchMerchantImage() {
  yield takeLatest('eoi/eoiFetchMerchantImageStart', eoiFetchMerchantImageAsync)
}

export function* watchEoiFetchMerchantBankInfoStart() {
  yield takeLatest(
    'eoi/eoiFetchMerchantBankInfoStart',
    eoiFetchMerchantBankInfoAsync
  )
}

export function* watchEoiFetchMerchantDiscountInfo() {
  yield takeLatest(
    'eoi/eoiFetchMerchantDiscountInfoStart',
    eoiFetchMerchantDiscountInfoAsync
  )
}

export function* watchEoiCheckApplyMerchantPremiumCode() {
  yield takeLatest(
    'eoi/eoiCheckApplyMerchantPremiumCodeStart',
    eoiCheckApplyMerchantPremiumCodeAsync
  )
}

export function* watchEoiCreateBankInfo() {
  yield takeLatest('eoi/eoiCreateBankInfoStart', eoiCreateBankInfoAsync)
}

export function* watchEoiFetchReferralFromToken() {
  yield takeLatest(
    'eoi/eoiFetchReferralFromTokenStart',
    eoiFetchReferralFromTokenAsync
  )
}

export function* watchEoiCreateMerchantGroup() {
  yield takeLatest(
    'eoi/eoiCreateMerchantGroupStart',
    eoiCreateMerchantGroupAsync
  )
}

export function* watchApplyMerchantCouponCodeAsync() {
  yield takeLatest(
    'eoi/eoiApplyMerchantCouponCodeStart',
    applyMerchantCouponCodeAsync
  )
}

export function* eoiSagas() {
  yield all([
    call(watchCreateSalesAgentEoi),
    call(watchCreateCharityEoi),
    call(watchCreateClubEoi),
    call(watchEoiCreateStep1Draft),
    call(watchEoiCreateStep1General),
    call(watchEoiEditStep1),
    call(watchEoiCreateStep2Draft),
    call(watchEoiCreateStep2General),
    call(watchEoiCreateStep3Draft),
    call(watchEoiCreateStep3General),
    call(watchEoiCreateStep4Draft),
    call(watchEoiCreateStep4General),
    call(watchEoiFetchMerchantBasicInfo),
    call(watchEoiFetchMerchantWebsiteInfo),
    call(watchEoiFetchMerchantImage),
    call(watchEoiFetchMerchantBankInfoStart),
    call(watchEoiFetchMerchantDiscountInfo),
    call(watchEoiEditDraftStep1Start),
    call(watchEoiEditStep2),
    call(watchEoiEditDraftStep2),
    call(watchEoiEditStep3),
    call(watchEoiEditDraftStep3),
    call(watchEoiEditStep4),
    call(watchEoiEditDraftStep4),
    call(watchEoiCreateBankInfo),
    call(watchEoiCheckApplyMerchantPremiumCode),
    call(watchEoiFetchReferralFromToken),
    call(watchEoiCreateMerchantGroup),
    call(watchApplyMerchantCouponCodeAsync),
  ])
}
