import React, { useEffect, useState } from 'react'
import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, CircularProgress, Typography, Button } from '@mui/material'
import AppComponentHeader from '@crema/core/AppComponentHeader'
import AppCard from '@crema/core/AppCard'

import container from './Agreement.container'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

function handleClick(event) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

const CustomizedBreadcrumbs = () => {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb label="Home" icon={<HomeIcon fontSize="small" />} />
        <StyledBreadcrumb label="agreement" />
      </Breadcrumbs>
    </div>
  )
}

const Agreement = ({
  onFetchStateOwnerProfileInfoStart,

  onFetchAreaOwnerStart,
  onFetchPostStart,
  onFetchCountryStart,
  onCreateCountryStart,
  onDeleteAreaOwnerStart,
  onFetchStateOwnerStart,
  countrySetup: { countryList },
  stateOwner: { stateOwnerProfileInfo: data, isFetching },
  auth: { currentUser },

  siteCoordinator: { globalCountry },
  ...rest
}) => {
  useEffect(() => {
    onFetchStateOwnerProfileInfoStart()
  }, [])

  return (
    <Box style={{ color: '#000000' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <AppComponentHeader title="Agreement" />
        <CustomizedBreadcrumbs />
      </Box>
      <AppCard>
        <Typography variant="h4">
          To view your agreement document click on the button below
        </Typography>
        <Button
          sx={{
            color: '#ffffff',
            display: 'flex',
            background: '#00bfcd',
            '&:hover': {
              background: '#ec4785',
            },
            px: 5,
            my: 4,
          }}
          disabled={isFetching || !data?.data?.agreementUrl}
          type="button"
          onClick={() => window.open(data?.data?.agreementUrl, '_blank')}
        >
          Click Here
        </Button>
      </AppCard>
    </Box>
  )
}

export default container(Agreement)
