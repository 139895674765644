import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchMerchantAgreementTemplateStart,
  fetchMerchantAgreementTemplateSuccess,
  fetchMerchantAgreementTemplateFailure,
  createMerchantAgreementTemplateSuccess,
  createMerchantAgreementTemplateFailure,
  deleteMerchantAgreementTemplateSuccess,
  deleteMerchantAgreementTemplateFailure,
  fetchMerchantAgreementTemplateByIdSuccess,
  fetchMerchantAgreementTemplateByIdFailure,
  editMerchantAgreementTemplateSuccess,
  editMerchantAgreementTemplateFailure,
} from './merchantAgreementTemplateSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchMerchantAgreementTemplateList,
  createMerchantAgreementTemplate,
  deleteMerchantAgreementTemplate,
  fetchMerchantAgreementTemplateById,
  editMerchantAgreementTemplate,
} from '../../api/merchantAgreementTemplate'

import _ from 'lodash'

export function* fetchMerchantAgreementTemplateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantAgreementTemplateList(payload))

    yield put(fetchMerchantAgreementTemplateSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantAgreementTemplateFailure(err))
  }
}

export function* fetchMerchantAgreementTemplateByIdAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMerchantAgreementTemplateById(payload))

    yield put(fetchMerchantAgreementTemplateByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantAgreementTemplateByIdFailure(err))
  }
}

export function* editMerchantAgreementTemplateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editMerchantAgreementTemplate(payload.params.id, payload.params)
    )

    yield put(editMerchantAgreementTemplateSuccess(data.data))
    yield put(openAlert({ message: 'Succesfully edited', severity: 'success' }))
    payload.navigate()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editMerchantAgreementTemplateFailure(err))
  }
}

export function* createMerchantAgreementTemplateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      createMerchantAgreementTemplate({ ...payload.params })
    )

    yield put(createMerchantAgreementTemplateSuccess(data?.data))
    yield put(
      fetchMerchantAgreementTemplateStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Merchant Agreement Template succesfully created',
        severity: 'success',
      })
    )

    yield payload.navigate()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createMerchantAgreementTemplateFailure(err))
  }
}

export function* deleteMerchantAgreementTemplateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteMerchantAgreementTemplate(payload))

    if (data) {
      yield put(deleteMerchantAgreementTemplateSuccess(payload))
      yield put(
        fetchMerchantAgreementTemplateStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'MerchantAgreementTemplate deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteMerchantAgreementTemplateFailure(err))
  }
}

export function* watchFetchMerchantAgreementTemplate() {
  yield takeLatest(
    'merchantAgreementTemplateSetup/fetchMerchantAgreementTemplateStart',
    fetchMerchantAgreementTemplateAsync
  )
}

export function* watchCreateMerchantAgreementTemplate() {
  yield takeLatest(
    'merchantAgreementTemplateSetup/createMerchantAgreementTemplateStart',
    createMerchantAgreementTemplateAsync
  )
}

export function* watchDeleteMerchantAgreementTemplate() {
  yield takeLatest(
    'merchantAgreementTemplateSetup/deleteMerchantAgreementTemplateStart',
    deleteMerchantAgreementTemplateAsync
  )
}

export function* watchFetchMerchantAgreementTemplateById() {
  yield takeLatest(
    'merchantAgreementTemplateSetup/fetchMerchantAgreementTemplateByIdStart',
    fetchMerchantAgreementTemplateByIdAsync
  )
}

export function* watchEditMerchantAgreementTemplate() {
  yield takeLatest(
    'merchantAgreementTemplateSetup/editMerchantAgreementTemplateStart',
    editMerchantAgreementTemplateAsync
  )
}

export function* merchantAgreementTemplateSetupSagas() {
  yield all([
    call(watchFetchMerchantAgreementTemplate),
    call(watchCreateMerchantAgreementTemplate),
    call(watchDeleteMerchantAgreementTemplate),
    call(watchFetchMerchantAgreementTemplateById),
    call(watchEditMerchantAgreementTemplate),
  ])
}
