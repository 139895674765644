import { axiosRequest, removeEmptyFields } from 'utils'

// fetchEarning,
export const fetchEarning = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/merchantPackageFees/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
// fetchPayable,
export const fetchPayable = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/merchantPackageFees/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPayableSetupFee = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/merchantSetupFees/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantPackageList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPackage/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantPackageListForDropDown = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPackage/getAllForDropDown`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantPackageById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPackage/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMerchantPackage = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchantPackage/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteMerchantPackage = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/merchantPackage/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMerchantPackage = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchantPackage/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const upgradeMerchantPackage = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/upgradePackage/`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const upgradeMerchantPackageByMerchantId = (id, params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/general/upgradePackage/${id}`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getLastMerchantPackageLogByMerchantId = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPackageLog/getLastLog/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchUpgradeAmount = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/upgradeAmount`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantPackageForPremiumCode = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPackage/getAllForPremiumCode`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
