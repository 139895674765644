import { createSlice } from '@reduxjs/toolkit'

import { IStateOwnerState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IStateOwnerState = {
  stateOwnerList: listObj,
  soleStateOwner: {},
  stateOwnerProfileInfo: listObj,
  pendingStateOwnerList: listObj,
  error: null,
  loading: false,
  isEditing: false,
  isDeleting: false,
  isCreating: false,
  isUploading: false,
  hasMoreData: false,
}

export const stateOwnerSlice = createSlice({
  name: 'stateOwner',
  initialState: INITIAL_STATE,
  reducers: {
    fetchStateOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchStateOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.stateOwnerList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchStateOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPendingStateOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchPendingStateOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pendingStateOwnerList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPendingStateOwnerFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchStateOwnerByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchStateOwnerByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleStateOwner = action.payload

      state.isFetching = false
    },
    fetchStateOwnerByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteStateOwnerStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteStateOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
      state.stateOwnerList.data = state.stateOwnerList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteStateOwnerFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    deletePendingStateOwnerStart: (state: any, action) => {
      state.isDeleting = true
    },
    deletePendingStateOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
      state.pendingStateOwnerList.data =
        state.pendingStateOwnerList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    deletePendingStateOwnerFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    createStateOwnerStart: (state: any, action) => {
      state.isCreating = true
    },
    createStateOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isCreating = false
      // state.stateOwnerList.data = [...state.stateOwnerList.data, action.payload]
    },
    createStateOwnerFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editStateOwnerStart: (state: any, action) => {
      state.isEditing = true
    },
    editStateOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      const result = state.stateOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.stateOwnerList.data = result

      state.isEditing = false
    },
    editStateOwnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
    verifyStateOwnerStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifyStateOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isVerifing = false
      state.pendingStateOwnerList.data =
        state.pendingStateOwnerList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    verifyStateOwnerFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },
    rejectStateOwnerStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectStateOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.pendingStateOwnerList.data =
        state.pendingStateOwnerList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    rejectStateOwnerFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },
    updatePendingStateOwnerStart: (state: any, action) => {
      state.isEditing = true
    },
    updatePendingStateOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;

      const result = state.pendingStateOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.pendingStateOwnerList.data = result
      state.isEditing = false
    },
    updatePendingStateOwnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    deleteStateOwnerContactStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteStateOwnerContactSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
    },
    deleteStateOwnerContactFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },
    fetchStateOwnerProfileInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchStateOwnerProfileInfoSuccess: (state: any, action) => {
      state.stateOwnerProfileInfo = action.payload
      state.isFetching = false
    },
    fetchStateOwnerProfileInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    uploadAgreementStart: (state: any, action) => {
      state.isUploading = true
    },
    uploadAgreementSuccess: (state: any) => {
      state.isUploading = false
    },
    uploadAgreementFailure: (state: any, action) => {
      state.isUploading = false
      state.error = action.payload
    },

    editStateOwnerOwnProfileInfoStart: (state: any, action) => {
      state.isEditing = true
    },
    editStateOwnerOwnProfileInfoSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editStateOwnerOwnProfileInfoFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
  },
})

export const {
  fetchStateOwnerStart,
  fetchStateOwnerSuccess,
  fetchStateOwnerFailure,
  deleteStateOwnerStart,
  deleteStateOwnerSuccess,
  deleteStateOwnerFailure,
  fetchStateOwnerByIdStart,
  fetchStateOwnerByIdSuccess,
  fetchStateOwnerByIdFailure,
  createStateOwnerStart,
  createStateOwnerSuccess,
  createStateOwnerFailure,
  editStateOwnerStart,
  editStateOwnerSuccess,
  editStateOwnerFailure,
  fetchPendingStateOwnerStart,
  fetchPendingStateOwnerSuccess,
  fetchPendingStateOwnerFailure,
  deletePendingStateOwnerStart,
  deletePendingStateOwnerSuccess,
  deletePendingStateOwnerFailure,
  verifyStateOwnerStart,
  verifyStateOwnerSuccess,
  verifyStateOwnerFailure,
  rejectStateOwnerStart,
  rejectStateOwnerSuccess,
  rejectStateOwnerFailure,
  updatePendingStateOwnerStart,
  updatePendingStateOwnerSuccess,
  updatePendingStateOwnerFailure,
  deleteStateOwnerContactStart,
  deleteStateOwnerContactSuccess,
  deleteStateOwnerContactFailure,
  fetchStateOwnerProfileInfoStart,
  fetchStateOwnerProfileInfoSuccess,
  fetchStateOwnerProfileInfoFailure,

  uploadAgreementStart,
  uploadAgreementSuccess,
  uploadAgreementFailure,

  editStateOwnerOwnProfileInfoStart,
  editStateOwnerOwnProfileInfoSuccess,
  editStateOwnerOwnProfileInfoFailure,
} = stateOwnerSlice.actions

export default stateOwnerSlice.reducer
