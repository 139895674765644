import { axiosRequestCMS } from 'utils'

export const fetchConstantList = (params) => {
  return () =>
  axiosRequestCMS
      .get(`/api/constantValue/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

