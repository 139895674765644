import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Edit, Visibility } from '@mui/icons-material'
import { Tooltip, IconButton, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import RejectReasonViewer from 'components/RejectReasonViewer'
import MerchantDetails from '../MerchantDetails'
import AddLanguage from '../AddLanguage'
import EditMerchantInfo from '../EditMerchantInfo'
import moment from 'moment-timezone'
import { splitByCamelCase } from 'utils'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import MerchantUpgrade from 'components/MerchantUpgrade'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignCodeForm from 'components/ReusableModalComponents/AssignCodeModal'
import AssignedIssuerCodeModal from 'components/ReusableModalComponents/AssignedIssuerCodeModal'
import AssignedTransactionCodeModal from 'components/ReusableModalComponents/AssignedTransactionCodeModal'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  lineHeight: 1.43,
  paddingRight: 20,
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  count: number
  pageNumber?: number
  rowsPerPage?: number
  adminRoute?: any
  currentUser?: any
  onDeleteClubStart?: (payload: any) => void
  refreshPageData?: () => void
}

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  pageNumber,
  rowsPerPage,
  onDeleteClubStart,
  adminRoute,
  refreshPageData,
  currentUser,
}) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [isAddCountryOpen, setAddCountryOpen] = useState<boolean>(false)
  const [isAddLanguageOpen, setAddLanguageOpen] = useState<boolean>(false)
  const [isEditInfoOpen, setIsEditInfoOpen] = useState<boolean>(false)
  const [isViewDialogOpen, setViewDialogOpen] = useState<boolean>(false)
  const [selectedStateOwnerId, setSelectedStateOwnerId] = useState<number>(null)
  const [toDeleteId, setToDeleteId] = useState<number>(null)
  const [reason, setReason] = useState<string>('')

  const [openReasonModal, setOpenReasonModal] = useState<boolean>(false)

  const [openUpgradeModal, setOpenUpgradeModal] = useState<boolean>(false)

  const [rejectReason, setRejectReason] = useState<string>('')

  const [openAssignmentModal, setOpenAssignmentModal] = useState<boolean>(false)

  const [selectedMerchantObj, setSelectedMerchantObj] = useState<any>({})
  const [openAssignedIssuerCodeModal, setOpenAssignedIssuerCodeModal] =
    useState<boolean>(false)
  const [
    openAssignedTransactionCodeModal,
    setOpenAssignedTransactionCodeModal,
  ] = useState<boolean>(false)

  const navigate = useNavigate()

  const onCloseAddCountry = () => {
    setAddCountryOpen(false)
  }

  const onCloseAddLanguage = () => {
    setAddLanguageOpen(false)
  }

  const onCloseEditMerchantInfo = () => {
    setIsEditInfoOpen(false)
  }

  const onDeleteSelectedContacts = () => {
    onDeleteClubStart({
      id: toDeleteId,
      rejectReason: reason,
      closeDeleteModal,
    })
    // setDeleteDialogOpen(false)
  }

  const closeDeleteModal = () => {
    setDeleteDialogOpen(false)
  }

  const onCloseViewDialog = () => {
    setViewDialogOpen(false)
  }

  const onSelectContactsForDelete = (id) => {
    setToDeleteId(id)
    setDeleteDialogOpen(true)
  }

  const reasonModalOpen = (data) => {
    setRejectReason(data)
    setOpenReasonModal(true)
  }

  const closeUpgradeModal = () => {
    setOpenUpgradeModal(false)
  }

  const closeAssignedIssuerCodeModal = () => {
    setOpenAssignedIssuerCodeModal(false)
  }

  const closeAssignedTransactionCodeModal = () => {
    setOpenAssignedTransactionCodeModal(false)
  }

  const onDeleteUpgradeModal = () => {
    setOpenUpgradeModal(false)
  }
  const closeAssignmentModal = () => {
    setOpenAssignmentModal(false)
  }

  const checkSentViaEmail = (mailSentBySigner) => {
    if (mailSentBySigner == true) {
      return 'Yes'
    } else if (mailSentBySigner == false) {
      return 'No'
    } else {
      return '-'
    }
  }

  const displayAssignIcon = (data) => {
    if (data?.registrationStatus !== 'rejected') {
      return (
        <Tooltip
          placement="top"
          title="Assign Transaction Code and Issuer Code"
        >
          <IconButton
            onClick={() => {
              setSelectedMerchantObj(data)
              setOpenAssignmentModal(true)
            }}
          >
            <AssignmentIcon sx={{ fontSize: '1.3rem' }} />
          </IconButton>
        </Tooltip>
      )
    }
  }

  return (
    <>
      {isAddCountryOpen && (
        <MerchantDetails
          isAddCountryOpen={isAddCountryOpen}
          onCloseAddCountry={onCloseAddCountry}
          merchantId={selectedStateOwnerId}
          adminRoute={adminRoute}
        />
      )}

      {isAddLanguageOpen && (
        <AddLanguage
          isAddCountryOpen={isAddLanguageOpen}
          onCloseAddCountry={onCloseAddLanguage}
          merchantId={selectedStateOwnerId}
          adminRoute={adminRoute}
        />
      )}

      {isEditInfoOpen && (
        <EditMerchantInfo
          isAddCountryOpen={isEditInfoOpen}
          onCloseAddCountry={onCloseEditMerchantInfo}
          merchantId={selectedStateOwnerId}
          adminRoute={adminRoute}
          refreshPageData={refreshPageData}
        />
      )}

      {openReasonModal && (
        <RejectReasonViewer
          open={openReasonModal}
          onDeny={setOpenReasonModal}
          defaultValue={rejectReason}
          title="Rejection Reason"
          dialogTitle="Rejection Details"
        />
      )}

      {openAssignmentModal && (
        <ModalRenderer
          open={openAssignmentModal}
          onDeny={closeAssignmentModal}
          onConfirm={onDeleteSelectedContacts}
          title="Assign Transaction Code and Issuer Code"
          dialogTitle="Assign Codes"
          size={'sm'}
        >
          <AssignCodeForm
            selectedStateOwnerId={selectedStateOwnerId}
            closeMarkAsPopularModal={closeAssignmentModal}
            refreshPageData={refreshPageData}
            selectedMerchantObj={selectedMerchantObj}
            setSelectedMerchantObj={setSelectedMerchantObj}
          />
        </ModalRenderer>
      )}

      {openAssignedIssuerCodeModal && (
        <ModalRenderer
          open={openAssignedIssuerCodeModal}
          onDeny={closeAssignedIssuerCodeModal}
          onConfirm={onDeleteSelectedContacts}
          title="Issuer Codes List"
          dialogTitle="Issuer Codes List"
          size={'sm'}
          showCloseIcon={true}
        >
          <AssignedIssuerCodeModal
            selectedStateOwnerId={selectedStateOwnerId}
            closeMarkAsPopularModal={closeAssignedIssuerCodeModal}
            refreshPageData={refreshPageData}
            selectedMerchantObj={selectedMerchantObj}
          />
        </ModalRenderer>
      )}

      {openAssignedTransactionCodeModal && (
        <ModalRenderer
          open={openAssignedTransactionCodeModal}
          onDeny={closeAssignedTransactionCodeModal}
          onConfirm={onDeleteSelectedContacts}
          title="Transaction Codes List"
          dialogTitle="Transaction Codes List"
          size={'sm'}
          showCloseIcon={true}
        >
          <AssignedTransactionCodeModal
            selectedStateOwnerId={selectedStateOwnerId}
            closeMarkAsPopularModal={closeAssignedTransactionCodeModal}
            refreshPageData={refreshPageData}
            selectedMerchantObj={selectedMerchantObj}
          />
        </ModalRenderer>
      )}
      <TableRow key={data.sn} className="item-hover">
        <StyledTableCell
          align="left"
          sx={{ position: 'sticky', left: 0, zIndex: 1, background: '#fff' }}
        >
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{ position: 'sticky', left: 50, zIndex: 1, background: '#fff' }}
        >
          {data?.id}
        </StyledTableCell>

        <StyledTableCell
          align="left"
          sx={{ position: 'sticky', left: 100, zIndex: 2, background: '#fff' }}
        >
          {data?.merchantName}
        </StyledTableCell>

        <StyledTableCell
          align="left"
          sx={{ position: 'sticky', left: 230, zIndex: 2, background: '#fff' }}
        >
          {(currentUser?.role?.toLowerCase() === 'salesagent' ||
            currentUser?.role?.toLowerCase() === 'collaborationpartner' ||
            currentUser?.role?.toLowerCase() === 'club' ||
            currentUser?.role?.toLowerCase() === 'charity' ||
            currentUser?.role?.toLowerCase() === 'whitelabeluser' ||
            currentUser?.role?.toLowerCase() === 'areaowner' ||
            currentUser?.role?.toLowerCase() === 'stateowner' ||
            currentUser?.role?.toLowerCase() === 'regionowner') && (
            <Tooltip placement="top" title="Edit">
              <IconButton
                onClick={() => {
                  setSelectedStateOwnerId(data?.id)
                  // setIsEditInfoOpen(true)
                  navigate(
                    `/${adminRoute}/merchantList/editMerchant/${data?.id}`
                  )
                }}
              >
                <Edit sx={{ fontSize: '1.3rem' }} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip placement="top" title="View">
            <IconButton
              onClick={() => {
                setSelectedStateOwnerId(data?.id)
                setAddCountryOpen(true)
              }}
            >
              <Visibility sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>

          {data?.registrationStatus?.toLowerCase() === 'verified' &&
            data?.isActive && (
              <Tooltip placement="top" title="Upgrade/Renew Package">
                <IconButton
                  onClick={() => {
                    setSelectedStateOwnerId(data?.id)
                    setOpenUpgradeModal(true)
                  }}
                >
                  <UpgradeIcon sx={{ fontSize: '1.3rem' }} />
                </IconButton>
              </Tooltip>
            )}
          {displayAssignIcon(data)}

          <Tooltip placement="top" title="Add Language">
            <IconButton
              onClick={() => {
                setSelectedStateOwnerId(data?.id)
                setAddLanguageOpen(true)
              }}
            >
              <AddIcon sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>
        </StyledTableCell>

        {currentUser?.role?.toLowerCase() === 'collaborationpartner' &&
          currentUser?.userCountry?.partnerType?.toLowerCase() === 'global' && (
            <StyledTableCell align="left">
              {data?.__country__?.countryName ?? '-'}
            </StyledTableCell>
          )}

        <StyledTableCell align="left">{data?.merchantEmail}</StyledTableCell>
        <StyledTableCell align="left">{data?.username}</StyledTableCell>
        <StyledTableCell align="left">
          {data?.__country__?.phonePrefix} {data?.merchantPhoneNumber}
        </StyledTableCell>
        <StyledTableCell align="left">
          {` ${data?.contactPersonFirstName}
          ${data?.contactPersonLastName}`}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.phoneNumber ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">{data?.email ?? '-'}</StyledTableCell>
        <StyledTableCell align="left">
          {data?.streetInfo ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">{data?.city ?? '-'}</StyledTableCell>
        <StyledTableCell align="left">
          {data?.bulkEmailSend ? 'Yes' : 'No'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.__state__?.stateName ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.__region__?.regionName ?? '-'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.__area__?.areaName ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {checkSentViaEmail(data?.mailSentBySigner)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.memberAsRefererId ? 'Yes' : 'No'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.memberAsRefererId ? data?.memberAsRefererId : '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.stripeSubscriptionId || data?.isPreviouslySubscribed
            ? 'Activated'
            : 'Not Started'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {/* {data?.isAgreementComplete ? 'Approved' : 'Pending'} */}
          {/* {data?.registrationStatus === 'incomplete'
            ? 'pending'
            : data?.registrationStatus} */}

          {splitByCamelCase(data?.registrationStatus)?.length
            ? splitByCamelCase(data?.registrationStatus)?.map((item, index) => {
                return (
                  <span style={{ textTransform: 'capitalize' }} key={index}>
                    {item?.toLowerCase() === 'incomplete' ? 'pending' : item}
                  </span>
                )
              })
            : ''}
          <br />
          {data?.registrationStatus === 'rejected' ? (
            <Button
              variant="text"
              sx={{ padding: 0 }}
              onClick={() => reasonModalOpen(data?.rejectReason)}
            >
              view reason
            </Button>
          ) : (
            ''
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.createdAt == null
            ? '-'
            : moment(data?.createdAt).format('DD-MM-YYYY')}
        </StyledTableCell>

        <StyledTableCell align="left">
          {/* {data?.issuerCode ?? '-'} */}

          <Button
            variant="text"
            sx={{ padding: 0 }}
            onClick={() => {
              setSelectedMerchantObj(data)
              setOpenAssignedIssuerCodeModal(true)
            }}
          >
            view issuer Codes
          </Button>
        </StyledTableCell>

        <StyledTableCell align="left">
          {/* {data?.transactionCode ?? '-'} */}

          <Button
            variant="text"
            sx={{ padding: 0 }}
            onClick={() => {
              setSelectedMerchantObj(data)
              setOpenAssignedTransactionCodeModal(true)
            }}
          >
            view transaction Codes
          </Button>
        </StyledTableCell>
      </TableRow>

      {openUpgradeModal && (
        <ModalRenderer
          open={openUpgradeModal}
          onDeny={closeUpgradeModal}
          onConfirm={onDeleteUpgradeModal}
          title=""
          dialogTitle="Upgrade Merchant Package"
          size="xl"
        >
          <MerchantUpgrade
            merchantData={data}
            closeUpgradeModal={closeUpgradeModal}
            refreshPageData={refreshPageData}
          />
        </ModalRenderer>
      )}
    </>
  )
}

export default TableItem
