import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  passwordResetStart,
  passwordResetSuccess,
  passwordResetFailure,
  checkResetTokenValidityStart,
  checkResetTokenValiditySuccess,
  checkResetTokenValidityFailure,
} from './resetPasswordSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import { resetPassword, checkResetTokenValidity } from '../../api/resetPassword'

import _ from 'lodash'
import { removeParam } from 'utils'
import { redirect } from 'react-router-dom'

export function* resetPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(resetPassword(payload.params))
    yield put(passwordResetSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Password reset successfully',
        severity: 'success',
      })
    )
    payload.navigate(`${payload.redirect}`)
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(passwordResetFailure(err))
  }
}

export function* checkResetTokenValidityAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(checkResetTokenValidity(payload))
    yield put(checkResetTokenValiditySuccess(data))
    if (data.status === 200) {
    }
  } catch (err) {
    const status = err.response.status

    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(checkResetTokenValidityFailure(status))
  }
}

export function* watchResetPassword() {
  yield takeLatest('passwordReset/passwordResetStart', resetPasswordAsync)
}

export function* watchCheckResetTokenValidity() {
  yield takeLatest(
    'passwordReset/checkResetTokenValidityStart',
    checkResetTokenValidityAsync
  )
}

export function* resetPasswordSagas() {
  yield all([call(watchResetPassword), call(watchCheckResetTokenValidity)])
}
