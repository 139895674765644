import { takeLatest, call, all, put, select } from 'redux-saga/effects'
import {
  fetchClubStart,
  fetchClubSuccess,
  fetchClubFailure,
  deleteClubSuccess,
  deleteClubFailure,
  fetchClubByIdSuccess,
  fetchClubByIdFailure,
  createClubSuccess,
  createClubFailure,
  editClubSuccess,
  editClubFailure,
  fetchPendingClubSuccess,
  fetchPendingClubFailure,
  deletePendingClubStart,
  deletePendingClubSuccess,
  deletePendingClubFailure,
  verifyClubSuccess,
  verifyClubFailure,
  rejectClubSuccess,
  rejectClubFailure,
  updatePendingClubFailure,
  updatePendingClubSuccess,
  sendClubReqStart,
  sendClubReqSuccess,
  sendClubReqFailure,
  fetchClubProfileInfoStart,
  fetchClubProfileInfoSuccess,
  fetchClubProfileInfoFailure,
  fetchClubByPanelSuccess,
  fetchClubByPanelFailure,
  fetchAllClubSuccess,
  fetchAllClubFailure,
  editOwnClubProfileSuccess,
  editOwnClubProfileFailure,
  getClubReferralTokenSuccess,
  getClubReferralTokenFailure,
  fetchAllClubByLocationSuccess,
  fetchAllClubByLocationFailure,
} from './clubSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchClubList,
  createClub,
  fetchClubById,
  editClub,
  rejectClub,
  verifyClub,
  deleteClub,
  saveUpdatePendingClub,
  sendClubReq,
  fetchClubProfileInfo,
  fetchClubListByPanel,
  fetchAllClub,
  editOwnClubProfile,
  getClubReferralToken,
  fetchAllClubByLocation,
} from '../../api/club'
import { deleteImage, getImageUrl, uploadImage } from 'api/imageUpload'
import axios from 'axios'
import { getImageIdFromUrl, removeParam } from 'utils'

import _ from 'lodash'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchPendingClubAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchClubList(payload))

    yield put(fetchPendingClubSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchPendingClubFailure(err))
    console.error(err)
  }
}

export function* fetchClubAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchClubList(payload))

    yield put(fetchClubSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchClubFailure(err))
    console.error(err)
  }
}

export function* fetchClubByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchClubListByPanel(payload))

    yield put(fetchClubByPanelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchClubByPanelFailure(err))
    console.error(err)
  }
}

export function* fetchClubProfileInfoInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchClubProfileInfo())
    yield put(fetchClubProfileInfoSuccess(data))
  } catch (err) {
    yield put(fetchClubProfileInfoFailure(err))
  }
}

export function* createClubAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    let imageResponse

    if (!_.isEmpty(payload?.params?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createClub({ ...payload.params, logoUrl: logoUrl })
      )

      yield put(createClubSuccess(data?.data?.club))

      yield put(
        fetchClubStart({
          page: 1,
          limit: 10,
          offset: 0,
          isPending: false,
          countryId: siteCoordinator?.globalCountry?.id,
        })
      )

      yield payload.closeModal()
    } else {
      const { data } = yield call(createClub(payload?.params))
      yield put(createClubSuccess(data?.data?.club))
      yield put(
        fetchClubStart({
          page: 1,
          limit: 10,
          offset: 0,
          isPending: false,
          countryId: siteCoordinator?.globalCountry?.id,
        })
      )

      yield payload.closeModal()
    }

    yield put(
      openAlert({ message: 'Club Successfully Created', severity: 'success' })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createClubFailure(err))
    console.error(err)
  }
}

export function* deleteClubAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      deleteClub(payload?.id, { rejectReason: payload?.rejectReason })
    )

    if (data) {
      yield put(deleteClubSuccess(payload?.id))
      yield payload.closeDeleteModal()
      yield put(
        openAlert({
          message: 'Successfully deleted',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteClubFailure(err))
  }
}

export function* deletePendingClubAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      rejectClub(payload?.id, { rejectReason: payload?.rejectReason })
    )

    if (data) {
      yield put(deleteClubSuccess(payload?.id))
      yield payload.closeDeleteModal()
      yield put(
        openAlert({
          message: 'Rejected Region Licensee',
          severity: 'error',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteClubFailure(err))
    console.error(err)
  }
}

export function* fetchClubByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchClubById(payload))

    yield put(
      fetchClubByIdSuccess({
        ...data?.data,
        issuerCode: data?.issuerCode,
        allowMerchantCreate: data?.allowMerchantCreate,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchClubByIdFailure(err))
    console.error(err)
  }
}

export function* editClubAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        editClub(payload?.params?.id, { ...payload?.params, logoUrl })
      )

      yield put(editClubSuccess(data?.data))

      yield payload.closeModal()
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(editClub(payload?.params?.id, uploadData))
      yield put(editClubSuccess(data?.data))
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    yield put(
      openAlert({ message: 'Club Succesfully Edited', severity: 'success' })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editClubFailure(err))
    console.error(err)
  }
}

export function* verifyClubAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        verifyClub(payload?.params?.id, { ...payload?.params, logoUrl })
      )

      yield put(verifyClubSuccess(data?.data))

      if (payload?.closeModal) {
        yield payload.closeModal()
      }
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(verifyClub(payload?.params?.id, uploadData))

      yield put(verifyClubSuccess(data?.data))

      if (payload?.closeModal) {
        yield payload.closeModal()
      }
    }

    // const { data } = yield call(
    //   verifyClub(payload?.params?.id, payload?.params)
    // )

    // yield put(verifyClubSuccess(data?.data))
    // yield payload?.closeModal()

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({ message: 'succesfully verified', severity: 'success' })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(verifyClubFailure(err))
    console.error(err)
  }
}

export function* rejectClubAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    const { data } = yield call(rejectClub(payload?.id, payload))

    yield put(rejectClubSuccess(payload?.id))

    yield put(
      fetchClubStart({
        page: 1,
        limit: 10,
        offset: 0,
        isPending: false,
        countryId: siteCoordinator?.globalCountry?.id,
      })
    )

    yield payload.closeModal()

    yield put(
      openAlert({ message: 'succesfully rejected', severity: 'success' })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(rejectClubFailure(err))
    console.error(err)
  }
}

export function* updatePendingClubAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        saveUpdatePendingClub(payload?.params?.id, {
          ...payload?.params,
          logoUrl,
        })
      )

      yield put(updatePendingClubSuccess(data?.data))

      yield payload.closeModal()
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(
        saveUpdatePendingClub(payload?.params?.id, uploadData)
      )

      yield put(updatePendingClubSuccess(data?.data))
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    // const { data } = yield call(
    //   saveUpdatePendingClub(payload?.params?.id, payload?.params)
    // )
    // yield put(updatePendingClubSuccess(payload?.params?.id))
    // yield payload?.closeModal()

    yield put(
      openAlert({
        message: 'Club Request Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(updatePendingClubFailure(err))
    console.error(err)
  }
}

export function* sendClubReqAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        sendClubReq({ ...payload.params, logoUrl: logoUrl })
      )

      yield put(sendClubReqSuccess(data?.data.club))

      yield payload.closeModal()
    } else {
      const { data } = yield call(sendClubReq(payload?.params))
      yield put(sendClubReqSuccess(data?.data.club))
    }

    yield put(
      openAlert({
        message: 'Club Request Sent Successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchClubStart({
          page: 1,
          limit: 10,
          offset: 0,
          signerId: payload?.params?.signerId,
          countryId: payload?.params?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(sendClubReqFailure(err))

    console.error(err)
  }
}

export function* fetchAllClubAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllClub(payload))

    yield put(fetchAllClubSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllClubFailure(err))
    console.error(err)
  }
}

export function* fetchAllClubByLocationAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllClubByLocation(payload))

    yield put(fetchAllClubByLocationSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllClubByLocationFailure(err))
    console.error(err)
  }
}

export function* editOwnClubProfileAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        editOwnClubProfile({ ...payload?.params, logoUrl })
      )

      yield put(editOwnClubProfileSuccess(data?.data))
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(editOwnClubProfile(uploadData))
      yield put(editOwnClubProfileSuccess(data?.data))
    }
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    yield put(
      openAlert({
        message: 'Club Profile Succesfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editOwnClubProfileFailure(err))
    console.error(err)
  }
}

export function* getClubReferralTokenAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getClubReferralToken())
    yield put(getClubReferralTokenSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(getClubReferralTokenFailure(err))
  }
}

export function* watchPendingClub() {
  yield takeLatest('club/fetchPendingClubStart', fetchPendingClubAsync)
}

export function* watchFetchClub() {
  yield takeLatest('club/fetchClubStart', fetchClubAsync)
}

export function* watchFetchClubProfileInfo() {
  yield takeLatest(
    'club/fetchClubProfileInfoStart',
    fetchClubProfileInfoInfoAsync
  )
}

export function* watchCreateClub() {
  yield takeLatest('club/createClubStart', createClubAsync)
}

export function* watchVerifyClub() {
  yield takeLatest('club/verifyClubStart', verifyClubAsync)
}

export function* watchRejectClubAsync() {
  yield takeLatest('club/rejectClubStart', rejectClubAsync)
}

export function* watchUpdatePendingClub() {
  yield takeLatest('club/updatePendingClubStart', updatePendingClubAsync)
}

export function* watchDeletePendingClub() {
  yield takeLatest('club/deletePendingClubStart', deletePendingClubAsync)
}

export function* watchDeleteClub() {
  yield takeLatest('club/deleteClubStart', deleteClubAsync)
}

export function* watchFetchPendingClubById() {
  yield takeLatest('club/fetchClubByIdStart', fetchClubByIdAsync)
}

export function* watchEditClub() {
  yield takeLatest('club/editClubStart', editClubAsync)
}
export function* watchSendClubReq() {
  yield takeLatest('club/sendClubReqStart', sendClubReqAsync)
}

export function* watchFetchClubByPanel() {
  yield takeLatest('club/fetchClubByPanelStart', fetchClubByPanelAsync)
}

export function* watchFetchAllClub() {
  yield takeLatest('club/fetchAllClubStart', fetchAllClubAsync)
}

export function* watchFetchAllClubByLocation() {
  yield takeLatest(
    'club/fetchAllClubByLocationStart',
    fetchAllClubByLocationAsync
  )
}

export function* watchEditOwnClubProfile() {
  yield takeLatest('club/editOwnClubProfileStart', editOwnClubProfileAsync)
}
export function* watchGetClubReferralToken() {
  yield takeLatest('club/getClubReferralTokenStart', getClubReferralTokenAsync)
}

export function* clubSagas() {
  yield all([
    call(watchPendingClub),
    call(watchFetchClub),
    call(watchFetchClubProfileInfo),
    call(watchCreateClub),
    call(watchDeletePendingClub),
    call(watchFetchPendingClubById),
    call(watchEditClub),
    call(watchVerifyClub),
    call(watchUpdatePendingClub),
    call(watchDeleteClub),
    call(watchSendClubReq),
    call(watchRejectClubAsync),
    call(watchFetchClubByPanel),
    call(watchFetchAllClub),
    call(watchEditOwnClubProfile),
    call(watchGetClubReferralToken),
    call(watchFetchAllClubByLocation),
    // call(watchEditCountry),
  ])
}
