import React, { useState } from 'react'
import AppCard from '@crema/core/AppCard'
import { Box, Grid, Stack, TextField, Button, Skeleton } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import AddSlider from '../AddSection'
import { removeEmptyFields } from 'utils'
import LanguageSelect from 'components/DropdownComponents/LanguageSingleSelect'

const ListTop = ({ setFilterFormData, clearFilterForm, ...props }) => {
  const [isAddSliderOpen, setAddSliderOpen] = useState<boolean>(false)

  const onOpenAddSlider = () => {
    setAddSliderOpen(true)
  }

  const onCloseAddSlider = () => {
    setAddSliderOpen(false)
  }

  const validationSchema = yup.object({})

  const onSubmitSearch = (data) => {
    setFilterFormData(
      removeEmptyFields({
        ...data,
        lang: data?.lang?.lang?.toLowerCase(),
        title__substring: data?.title__substring,
      })
    )
  }

  return (
    <>
      {/* <Button
        onClick={onOpenAddSlider}
        sx={{
          color: '#ffffff',
          display: 'flex',
          marginTop: '-1rem',
          marginLeft: 'auto',
          background: '#00bfcd',
          '&:hover': {
            background: '#ec4785',
          },
          px: 5,
          mb: 5,
        }}
      >
        Add About
      </Button> */}
      <AppCard sx={{ padding: '1rem 0' }}>
        <Box>
          <Formik
            validateOnChange={true}
            initialValues={{
              lang: '',
              title__substring: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)
              onSubmitSearch(data)
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, resetForm }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      label="Title"
                      name="title__substring"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      name="lang"
                      label="Select language"
                      component={LanguageSelect}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Stack direction="row" spacing={4} sx={{ height: 1 }}>
                      <Button
                        size="small"
                        sx={{
                          px: 5,
                          color: '#ffffff',
                          backgroundColor: '#ec4785',
                          '&:hover': {
                            background: '#ec4785',
                          },
                        }}
                        type="submit"
                      >
                        Search
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          px: 5,
                        }}
                        type="submit"
                        variant="outlined"
                        onClick={() => {
                          clearFilterForm()
                          resetForm()
                        }}
                      >
                        Clear
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <AddSlider
            isAddSectionOpen={isAddSliderOpen}
            onCloseAddSection={onCloseAddSlider}
          />
        </Box>
      </AppCard>
      <Box sx={{ height: 30 }} />
    </>
  )
}

export default ListTop
