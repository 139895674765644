import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'

import container from './BasicInfo.container'
import { Form, Formik } from 'formik'

import * as yup from 'yup'
import { removeEmptyFields } from 'utils'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onCreateClubStart?: (params) => void
  onCreateStep1DraftStart?: (params) => void
  onCreateStep1GeneralStart?: (params) => void
  onFetchMerchantBasicInfoPanelUserStart?: (params) => void
  handleChangeTab?: any
  onClearRecentMerchant?: () => void
  siteCoordinator?: any
  club?: any
  merchant?: any
  auth?: any
  merchantId?: number
  adminRoute?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  isAddCountryOpen,
  onOpenCountryTask,
  onCloseAddCountry,
  onCreateClubStart,
  onCreateStep1DraftStart,
  onCreateStep1GeneralStart,
  onFetchMerchantBasicInfoPanelUserStart,
  onClearRecentMerchant,
  handleChangeTab,
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  merchant: { generalBasicInfo },
  merchantId,
}) => {
  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  useEffect(() => {
    onFetchMerchantBasicInfoPanelUserStart(merchantId)
  }, [merchantId])

  const closeModal = () => {
    onCloseAddCountry()
    setMultiplePersonApply(false)
  }
  const validationSchema = yup.object({
    merchantPhoneNumber: yup
      .string()
      .min(7, 'Phone number should be at least 7 digits'),
    phoneNumber: yup
      .string()
      .min(7, 'Phone number should be at least 7 digits'),
  })

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        merchantName: '',
        merchantEmail: '',
        phonePrefix: '+1',
        phoneNumber: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        merchantPhonePrefix: '',
        merchantPhoneNumber: '',
        email: '',
        businessRegistrationNumber: '',
        buildingNo: '',
        streetInfo: '',
        charityId: null,
        categoryIds: [],
        latitude: '',
        longitude: '',

        countryId: globalCountry?.id,
        regionId: null,
        stateId: null,
        areaId: null,
      }}
      validationSchema={validationSchema}
      onSubmit={(data: any, { setSubmitting }) => {
        setSubmitting(true)
        onClearRecentMerchant()
        if (data?.action === 'draft') {
          onCreateStep1DraftStart({
            params: removeEmptyFields({
              ...data,
              countryId: globalCountry?.id,
              stateId: data?.stateId?.id,
              regionId: data?.regionId?.id,
              areaId: data?.areaId?.id,
              charityId: data?.charityId?.id,
              postalCodeId: null,
              latlon: [data?.latitude ?? null, data?.longitude ?? null],

              categoryIds: data?.categoryIds?.length
                ? data?.categoryIds?.map((item) => item?.id)
                : null,
            }),
            closeModal,
            handleChangeTab,
          })
        } else if (data?.action === 'general') {
          onCreateStep1GeneralStart({
            params: removeEmptyFields({
              ...data,
              countryId: globalCountry?.id,
              stateId: data?.stateId?.id,
              regionId: data?.regionId?.id,
              areaId: data?.areaId?.id,
              charityId: data?.charityId?.id,
              postalCodeId: null,
              latlon: [data?.latitude ?? null, data?.longitude ?? null],

              categoryIds: data?.categoryIds?.length
                ? data?.categoryIds?.map((item) => item?.id)
                : null,
            }),
            closeModal,
            handleChangeTab,
          })
        }

        setSubmitting(false)
      }}
      render={({ values, setFieldValue, handleBlur, handleChange }) => (
        <Form>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Main Information</Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Merchant’s Trading Name</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.merchantName ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Merchant User Name</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.username ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Merchant Email For App</Typography>
                <Typography variant="body2">
                  {' '}
                  {generalBasicInfo?.merchantEmail ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Phone Number</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.merchantPhoneNumber ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography variant="h5">
                  Business Registration Number
                </Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.businessRegistrationNumber ?? '-'}
                </Typography>
              </Grid>

              {/* <Grid item xs={12} lg={3}>
                <Typography variant="h5">Charity</Typography>
                <Typography variant="body2">
                  {' '}
                  {generalBasicInfo?.__charity__?.charityName ?? '-'}
                </Typography>
              </Grid> */}
            </Grid>

            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <Typography variant="h4" component="h2">
                  Contact Person Information
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography variant="h5">First Name</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.contactPersonFirstName ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Last Name</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.contactPersonLastName ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Email</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.email ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Phone Number</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.phoneNumber ?? '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <Typography variant="h3" component="h2">
                  Location Information
                </Typography>
              </Grid>

              {currentUser?.role?.toLowerCase() === 'collaborationpartner' &&
                currentUser?.userCountry?.partnerType?.toLowerCase() ===
                  'global' && (
                  <Grid item xs={12} lg={3}>
                    <Typography variant="h5">Country</Typography>
                    <Typography variant="body2">
                      {generalBasicInfo?.__country__?.countryName ?? '-'}
                    </Typography>
                  </Grid>
                )}

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">State</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.__state__?.stateName ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Region</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.__region__?.regionName ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Area</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.__area__?.areaName ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography variant="h5">
                  Unit/Apartment/Villa Number
                </Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.buildingNo ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">
                  Street Name & Street Number{' '}
                </Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.streetInfo ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Postal/Zip Code</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.postalCodeUser ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">City</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.city ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Latitude</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.latlon?.length
                    ? generalBasicInfo?.latlon[0]
                    : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Longitude</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.latlon?.length
                    ? generalBasicInfo?.latlon[1]
                    : '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <Typography variant="h3" component="h2">
                  Category Information
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Merchant Category</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.__merchantCategories__?.length
                    ? generalBasicInfo?.__merchantCategories__?.map(
                        (item, index) => {
                          if (!item?.parentId) {
                            return (
                              <Typography variant="body1" key={index}>
                                {item?.name}
                              </Typography>
                            )
                          }
                        }
                      )
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography variant="h5">Merchant Sub Category</Typography>
                <Typography variant="body2">
                  {generalBasicInfo?.__merchantCategories__?.filter(
                    (item) => item?.parentId
                  )?.length
                    ? generalBasicInfo?.__merchantCategories__?.map(
                        (item, index) => {
                          if (item?.parentId) {
                            return (
                              <Typography variant="body1" key={index}>
                                {item?.name}
                              </Typography>
                            )
                          }
                        }
                      )
                    : '-'}
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ height: 20 }} />
            <Box sx={{ height: 30 }} />
          </Box>
        </Form>
      )}
    />
  )
}

export default container(AddNewClub)
