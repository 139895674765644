import { axiosRequest } from 'utils'

export const fetchAreaList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/area/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchAreaById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/area/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createArea = (params) => {
  return () =>
    axiosRequest
      .post(`/api/area/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteArea = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/area/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editArea = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/area/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
