import React, { ReactNode } from 'react'
import Button from '@mui/material/Button'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Divider,
  Grid,
  TextField,
} from '@mui/material'

import { Fonts } from 'shared/constants/AppEnums'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  // eslint-disable-next-line no-undef
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface AppConfirmDialogProps {
  dialogTitle: string | ReactNode
  open: boolean
  hideOkButton?: boolean
  onDeny: (isOpen: boolean) => void
  deleteFunction?: (payload: any) => void
  title: string | ReactNode
  onChangeReason?: any
  toDeleteId?: number
  defaultValue?: string
  placeholderName?: string
}

const AppConfirmDialog: React.FC<AppConfirmDialogProps> = ({
  open,
  onDeny,
  defaultValue,
  title,
  dialogTitle,
  hideOkButton,
  placeholderName,
}) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={() => onDeny(false)}
      fullWidth
    >
      <DialogTitle>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            mb: 3,
            fontWeight: Fonts.SEMI_BOLD,
          }}
          id="alert-dialog-title"
        >
          {dialogTitle}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent
        sx={{ color: 'text.secondary', fontSize: 14 }}
        id="alert-dialog-description"
      >
        <Grid container>
          <Grid item xs={12}>
            {title}
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              name="rejectReason"
              multiline={true}
              defaultValue={defaultValue ?? ''}
              rows={10}
              id="outlined-basic"
              label={placeholderName ? placeholderName : 'Reason To Reject'}
              variant="outlined"
              disabled
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  fontSize: 14,
                },
              }}
              size="small"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          pb: 5,
          px: 6,
        }}
      >
        {hideOkButton ? (
          ''
        ) : (
          <Button
            variant="outlined"
            sx={{
              fontWeight: Fonts.MEDIUM,
            }}
            type="submit"
            color="primary"
            autoFocus
            onClick={() => onDeny(false)}
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AppConfirmDialog
