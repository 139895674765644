/* eslint-disable implicit-arrow-linebreak */
import { StarOutlined } from '@mui/icons-material'
import { connect } from 'react-redux'

import {
  createLuckyDrawStart,
  clearLuckyDrawStart,
  fetchLuckyDrawStart,
  fetchPrizeDrawByIdStart,
} from 'store/prizeDraw/prizeDrawSlice'

import { fetchPrizeDrawStart } from 'store/globalAdminSettings/globalAdminSettingsSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  globalSettings: state.globalSettings,
  prizeDraw: state.prizeDraw,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchPrizeDrawSettingsStart: (payload) =>
    dispatch(fetchPrizeDrawStart(payload)),
  onCreateLuckDrawStart: (payload) => dispatch(createLuckyDrawStart(payload)),

  onFetchPrizeDrawByIdStart: (payload) =>
    dispatch(fetchPrizeDrawByIdStart(payload)),

  // onClearGetWinner: () => dispatch(clearLuckyDrawStart()),

  onClearGetWinner: () => dispatch(clearLuckyDrawStart()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
