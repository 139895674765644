import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchMerchantPackageStart,
  fetchMerchantPackageSuccess,
  fetchMerchantPackageFailure,
  createMerchantPackageSuccess,
  createMerchantPackageFailure,
  deleteMerchantPackageSuccess,
  deleteMerchantPackageFailure,
  fetchMerchantPackageByIdSuccess,
  fetchMerchantPackageByIdFailure,
  editMerchantPackageSuccess,
  editMerchantPackageFailure,
  upgradeMerchantPackageSuccess,
  upgradeMerchantPackageFailure,
  upgradeMerchantPackageByMerchantIdSuccess,
  upgradeMerchantPackageByMerchantIdFailure,
  fetchEarningFailure,
  fetchEarningStart,
  fetchEarningSuccess,
  fetchPayableFailure,
  fetchPayableStart,
  fetchPayableSuccess,
  fetchLastMerchantPackageLogByMerchantIdStart,
  fetchLastMerchantPackageLogByMerchantIdFailure,
  fetchLastMerchantPackageLogByMerchantIdSuccess,
  fetchUpgradeAmountStart,
  fetchUpgradeAmountSuccess,
  fetchUpgradeAmountFailure,
  fetchPayableSetupFeeSuccess,
  fetchPayableSetupFeeFailure,
  fetchMerchantPackageDropDownSuccess,
  fetchMerchantPackageDropDownFailure,
} from './merchantPackageSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchEarning,
  fetchPayable,
  fetchMerchantPackageList,
  createMerchantPackage,
  deleteMerchantPackage,
  fetchMerchantPackageById,
  editMerchantPackage,
  upgradeMerchantPackage,
  upgradeMerchantPackageByMerchantId,
  getLastMerchantPackageLogByMerchantId,
  fetchUpgradeAmount,
  fetchPayableSetupFee,
  fetchMerchantPackageListForDropDown,
} from '../../api/merchantPackage'

import _ from 'lodash'

export function* fetchEarningAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchEarning(payload))
    yield put(fetchEarningSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchEarningFailure(err))
    console.error(err)
  }
}

export function* fetchPayableAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPayable(payload))
    yield put(fetchPayableSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPayableFailure(err))
    console.error(err)
  }
}

export function* fetchPayableSetupFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPayableSetupFee(payload))
    yield put(fetchPayableSetupFeeSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPayableSetupFeeFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantPackageList(payload))
    yield put(fetchMerchantPackageSuccess(data?.results))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantPackageFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantPackageByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantPackageById(payload))

    yield put(fetchMerchantPackageByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantPackageByIdFailure(err))
    console.error(err)
  }
}

export function* editMerchantPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editMerchantPackage(payload?.params?.id, payload?.params)
    )

    yield put(editMerchantPackageSuccess(data?.updateResult))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchMerchantPackageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }

    yield put(
      openAlert({
        message: 'Merchant package successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editMerchantPackageFailure(err))
    console.error(err)
  }
}

export function* createMerchantPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createMerchantPackage(payload?.params))
    yield put(createMerchantPackageSuccess(data?.data))
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchMerchantPackageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: 'Merchant package successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createMerchantPackageFailure(err))
    console.error(err)
  }
}

export function* deleteMerchantPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteMerchantPackage(payload))

    if (data) {
      yield put(deleteMerchantPackageSuccess(payload))

      if (payload?.refreshPageData) {
        yield call(payload?.refreshPageData)
      } else {
        yield put(
          fetchMerchantPackageStart({
            page: 1,
            limit: 10,
            offset: 0,
          })
        )
      }

      yield put(
        openAlert({
          message: 'Merchant package deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteMerchantPackageFailure(err))
    console.error(err)
  }
}

export function* upgradeMerchantPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(upgradeMerchantPackage(payload?.params))

    yield put(upgradeMerchantPackageSuccess(data))

    if (payload?.route) {
      payload?.navigate(payload?.route)
    }

    yield put(
      openAlert({
        message: 'Merchant package upgraded/renewed successfully ',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(upgradeMerchantPackageFailure(err))
    console.error(err)
  }
}

export function* upgradeMerchantPackageByMerchantIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      upgradeMerchantPackageByMerchantId(
        payload?.params?.merchantId,
        payload?.params
      )
    )

    yield put(upgradeMerchantPackageByMerchantIdSuccess(data))

    if (payload?.route) {
      payload?.navigate(payload?.route)
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: 'Merchant package upgraded/renewed successfully ',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(upgradeMerchantPackageByMerchantIdFailure(err))
    console.error(err)
  }
}

export function* fetchLastMerchantPackageLogByMerchantIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(getLastMerchantPackageLogByMerchantId(payload))

    yield put(fetchLastMerchantPackageLogByMerchantIdSuccess(data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchLastMerchantPackageLogByMerchantIdFailure(err))
    console.error(err)
  }
}

export function* fetchUpgradeAmountAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchUpgradeAmount(payload))

    yield put(fetchUpgradeAmountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchUpgradeAmountFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantPackageDropDownAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantPackageListForDropDown(payload))
    yield put(fetchMerchantPackageDropDownSuccess(data?.results))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantPackageDropDownFailure(err))
    console.error(err)
  }
}

export function* watchFetchMerchantPackage() {
  yield takeLatest(
    'merchantPackage/fetchMerchantPackageStart',
    fetchMerchantPackageAsync
  )
}

export function* watchCreateMerchantPackage() {
  yield takeLatest(
    'merchantPackage/createMerchantPackageStart',
    createMerchantPackageAsync
  )
}

export function* watchDeleteMerchantPackage() {
  yield takeLatest(
    'merchantPackage/deleteMerchantPackageStart',
    deleteMerchantPackageAsync
  )
}

export function* watchFetchMerchantPackageById() {
  yield takeLatest(
    'merchantPackage/fetchMerchantPackageByIdStart',
    fetchMerchantPackageByIdAsync
  )
}

export function* watchEditMerchantPackage() {
  yield takeLatest(
    'merchantPackage/editMerchantPackageStart',
    editMerchantPackageAsync
  )
}
export function* watchUpgradeMerchantPackageAsync() {
  yield takeLatest(
    'merchantPackage/upgradeMerchantPackageStart',
    upgradeMerchantPackageAsync
  )
}

export function* watchUpgradeMerchantPackageByMerchantIdAsync() {
  yield takeLatest(
    'merchantPackage/upgradeMerchantPackageByMerchantIdStart',
    upgradeMerchantPackageByMerchantIdAsync
  )
}

export function* watchFetchEarningAsync() {
  yield takeLatest('merchantPackage/fetchEarningStart', fetchEarningAsync)
}

export function* watchFetchPayableAsync() {
  yield takeLatest('merchantPackage/fetchPayableStart', fetchPayableAsync)
}

export function* watchFetchPayableSetupFeeAsync() {
  yield takeLatest(
    'merchantPackage/fetchPayableSetupFeeStart',
    fetchPayableSetupFeeAsync
  )
}

export function* watchFetchLastMerchantPackageLogByMerchantIdAsync() {
  yield takeLatest(
    'merchantPackage/fetchLastMerchantPackageLogByMerchantIdStart',
    fetchLastMerchantPackageLogByMerchantIdAsync
  )
}

export function* watchFetchUpgradeAmountAsync() {
  yield takeLatest(
    'merchantPackage/fetchUpgradeAmountStart',
    fetchUpgradeAmountAsync
  )
}

export function* watchFetchMerchantPackageDropDown() {
  yield takeLatest(
    'merchantPackage/fetchMerchantPackageDropDownStart',
    fetchMerchantPackageDropDownAsync
  )
}

export function* merchantPackageSagas() {
  yield all([
    call(watchFetchEarningAsync),
    call(watchFetchPayableAsync),
    call(watchFetchMerchantPackage),
    call(watchCreateMerchantPackage),
    call(watchDeleteMerchantPackage),
    call(watchFetchMerchantPackageById),
    call(watchEditMerchantPackage),
    call(watchUpgradeMerchantPackageAsync),
    call(watchUpgradeMerchantPackageByMerchantIdAsync),
    call(watchFetchLastMerchantPackageLogByMerchantIdAsync),
    call(watchFetchUpgradeAmountAsync),
    call(watchFetchPayableSetupFeeAsync),
    call(watchFetchMerchantPackageDropDown),
  ])
}
