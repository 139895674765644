import { createSlice } from '@reduxjs/toolkit'

import { IAreaSetup } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IAreaSetup = {
  areaList: listObj,
  soleArea: {},
  error: null,
  isFetching: false,
  loading: false,
  hasMoreData: false,
  selectedArea: {},
}

export const areaSetupSlice = createSlice({
  name: 'areaSetup',
  initialState: INITIAL_STATE,
  reducers: {
    fetchAreaStart: (state: any, action) => {
      state.loading = true
    },
    fetchAreaSuccess: (state: any, action) => {
      state.areaList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchAreaFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchAreaByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAreaByIdSuccess: (state: any, action) => {
      state.soleArea = action.payload.data
      state.isFetching = false
    },
    fetchAreaByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createAreaStart: (state: any, action) => {
      state.loading = true
    },
    createAreaSuccess: (state: any, action) => {
      state.loading = false
      state.areaList = [...state.areaList.data, action.payload]
    },
    createAreaFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteAreaStart: (state: any, action) => {
      state.loading = true
    },
    deleteAreaSuccess: (state: any, action) => {
      state.loading = false
      state.areaList = state.areaList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteAreaFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editAreaStart: (state: any, action) => {
      state.loading = true
    },
    editAreaSuccess: (state: any, action) => {
      const result = state?.areaList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.areaList.data = result

      state.loading = false
    },
    editAreaFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    selectArea: (state: any, action) => {
      state.selectedArea = action.payload
    },
  },
})

export const {
  fetchAreaStart,
  fetchAreaSuccess,
  fetchAreaFailure,
  createAreaStart,
  createAreaSuccess,
  createAreaFailure,
  deleteAreaStart,
  deleteAreaSuccess,
  deleteAreaFailure,
  fetchAreaByIdStart,
  fetchAreaByIdSuccess,
  fetchAreaByIdFailure,
  editAreaStart,
  editAreaSuccess,
  editAreaFailure,
  selectArea,
} = areaSetupSlice.actions

export default areaSetupSlice.reducer
