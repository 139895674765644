import React, { useEffect, useState } from 'react'
import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import { Box } from '@mui/material'
import AppComponentHeader from '@crema/core/AppComponentHeader'
import AppCard from '@crema/core/AppCard'
import ListTop from './components/ListTop/ListTop'
import CountryDemo from './components/MerchantTable'
import container from './MerchantList.container'
import { useNavigate } from 'react-router-dom'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

function handleClick(event) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

const CustomizedBreadcrumbs = () => {
  const navigate = useNavigate()

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          label="Merchants"
          icon={<HomeIcon fontSize="small" />}
          onClick={() => navigate('/dashboard')}
        />
        <StyledBreadcrumb label="State Merchant List" />
      </Breadcrumbs>
    </div>
  )
}

const AreaAdmin = ({
  onFetchMemberStart,
  onFetchMerchantGeneralStart,
  onFetchCPProfileInfoStart,
  countrySetup: { countryList },
  stateOwners: { hasMoreData },
  merchant: {
    merchantListStateWise: {
      data,
      hasMore,
      totalCount,
      count,
      page: pageNumber,
    },
    loading,
  },
  collaborationPartner: { cpProfileInfo, isFetching },
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  adminRoute,
  ...rest
}) => {
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterFormData, setFilterFormData] = useState({})

  useEffect(() => {
    if (currentUser?.role?.toLowerCase() === 'collaborationpartner') {
      onFetchCPProfileInfoStart()
      // eslint-disable-next-line
    }
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setOffset(offset + rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const clearFilterForm = () => {
    setFilterFormData({})
  }
  // useEffect(() => {
  //   onFetchMerchantGeneralStart({
  //     page: page + 1,
  //     limit: rowsPerPage,
  //     offset,
  //     countryId: globalCountry?.id,
  //     registrationIsComplete: true,
  //     ...filterFormData,
  //   })
  // }, [page, rowsPerPage, filterFormData])

  useEffect(() => {
    if (currentUser?.role?.toLowerCase() === 'collaborationpartner') {
      if (cpProfileInfo?.data?.partnerType === 'global') {
        onFetchMerchantGeneralStart({
          page: page + 1,
          limit: rowsPerPage,
          offset,
          ...filterFormData,
        })
      } else {
        onFetchMerchantGeneralStart({
          page: page + 1,
          limit: rowsPerPage,
          offset,
          countryId: globalCountry?.id,
          registrationIsComplete: true,
          ...filterFormData,
        })
      }
    }
  }, [page, rowsPerPage, filterFormData, currentUser, cpProfileInfo])

  useEffect(() => {
    if (currentUser?.role?.toLowerCase() !== 'collaborationpartner') {
      onFetchMerchantGeneralStart({
        page: page + 1,
        limit: rowsPerPage,
        offset,
        countryId: globalCountry?.id,
        registrationIsComplete: true,
        ...filterFormData,
      })
    }
  }, [page, rowsPerPage, filterFormData, currentUser])

  const refreshPageData = () => {
    if (currentUser?.role?.toLowerCase() === 'collaborationpartner') {
      return onFetchMerchantGeneralStart({
        page: page + 1,
        limit: rowsPerPage,
        offset,
        ...filterFormData,
      })
    } else {
      return onFetchMerchantGeneralStart({
        page: page + 1,
        limit: rowsPerPage,
        offset,
        countryId: globalCountry?.id,
        registrationIsComplete: true,
        ...filterFormData,
      })
    }
  }

  return (
    <Box style={{ color: '#000000' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <AppComponentHeader title="State Merchants" />
        <CustomizedBreadcrumbs />
      </Box>
      <ListTop
        {...rest}
        setFilterFormData={(data) => {
          setFilterFormData(data)
          setPage(0)
        }}
        clearFilterForm={() => {}}
        globalCountry={globalCountry}
        currentUser={currentUser}
      />
      <AppCard>
        <CountryDemo
          countryData={data}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          hasMoreData={hasMore}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refreshPageData={refreshPageData}
          currentUser={currentUser}
          //   onDeleteClubStart={onDeleteClubStart}
          totalCount={totalCount}
          loading={loading}
          pageNumber={pageNumber}
          adminRoute={adminRoute}
        />
        <Box style={{ height: 30 }} />
      </AppCard>
    </Box>
  )
}

export default container(AreaAdmin)
