import React, { useEffect, useState } from 'react'
import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Grid } from '@mui/material'
import AppComponentHeader from '@crema/core/AppComponentHeader'
import AppCard from '@crema/core/AppCard'
import ListTop from './components/ListTop/ListTop'
import GeneralSettingsTable from './components/Table'
import container from './TemporaryPassword.container'
import CountrySelect from 'components/DropdownComponents/CountrySelect'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

function handleClick(event) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

const CustomizedBreadcrumbs = () => {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          label="Settings"
          icon={<HomeIcon fontSize="small" />}
        />
        <StyledBreadcrumb label="Temporary Password" />
      </Breadcrumbs>
    </div>
  )
}

const Group = ({
  onFetchGeneralSettingStart,
  onFetchGroupInformationListStart,
  onFetchEmailListStart,
  onFetchContactListStart,
  onDeleteDocumentStart,
  onFetchTemporaryPasswordListStart,
  globalSettings: {
    temporaryPasswordList: {
      data,
      hasMore,
      totalCount,
      count,
      page: pageNumber,
    },
    isDeleting,
    loading,
  },
  auth: { currentUser },
  onOpenAlert,
  ...rest
}) => {
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterFormData, setFilterFormData] = useState({})

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setOffset(offset + rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const clearFilterForm = () => {
    setFilterFormData({})
  }

  useEffect(() => {
    onFetchTemporaryPasswordListStart({
      page: page + 1,
      limit: rowsPerPage,
      offset,
      countryId:
        currentUser?.role?.toLowerCase() === 'countryowner'
          ? currentUser?.userCountry?.countryId
          : null,
      ...filterFormData,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, filterFormData])

  const refreshPageData = () => {
    onFetchTemporaryPasswordListStart({
      page: page + 1,
      limit: rowsPerPage,
      offset,
      countryId:
        currentUser?.role?.toLowerCase() === 'countryowner'
          ? currentUser?.userCountry?.countryId
          : null,
      ...filterFormData,
    })
  }

  return (
    <Box style={{ color: '#000000' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <AppComponentHeader title="Temporary Password" />
        <CustomizedBreadcrumbs />
      </Box>
      <ListTop
        setFilterFormData={(data) => {
          setFilterFormData(data)
          setPage(0)
        }}
        refreshPageData={refreshPageData}
        clearFilterForm={() => {}}
        currentUser={currentUser}
        {...rest}
      />
      <AppCard>
        <GeneralSettingsTable
          generalSettingsData={data}
          page={page}
          pageNumber={pageNumber}
          totalCount={totalCount}
          loadingTable={loading}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          hasMoreData={hasMore}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refreshPageData={refreshPageData}
          onDeleteDocumentStart={onDeleteDocumentStart}
          onOpenAlert={onOpenAlert}
          currentUser={currentUser}
        />
        <Box style={{ height: 30 }} />
      </AppCard>
    </Box>
  )
}

export default container(Group)
