import React from 'react'
import QrCodeComponent from 'qrcode.react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import downloadjs from 'downloadjs'
import html2canvas from 'html2canvas'

type QrProps = {
  value: string
  size?: number
  qrCodeToDisplay?: number
  qrType?: string
  selectedMerchantObj?: any
}

const downloadQRCodeAsSVG = async (value, qrType, selectedMerchantObj) => {
  const canvas = await html2canvas(document.getElementById(`qrBox${value}`))
  const dataURL = canvas.toDataURL('image/png')
  downloadjs(
    dataURL,
    `${selectedMerchantObj?.id ? selectedMerchantObj?.id + '_' : ''}${
      qrType ? qrType + '_' : ''
    }QR_Code_${value}.png`,
    'image/png'
  )
}

const downloadQRCode = () => {
  const qrCodeURL = (document as any)
    .getElementById('qrCodeEl')
    ?.toDataURL('image/png')
    ?.replace('image/png', 'image/octet-stream')

  let aEl = document.createElement('a')
  aEl.href = qrCodeURL
  aEl.download = `QR_Code.png`
  document.body.appendChild(aEl)
  aEl.click()
  document.body.removeChild(aEl)
}

const QrCode = ({
  value,
  qrCodeToDisplay,
  size = 200,
  qrType,
  selectedMerchantObj,
}: QrProps) => {
  return (
    <Box sx={{ textAlign: 'center', maxWidth: size }}>
      <Box id={`qrBox${value}`} sx={{ background: 'white' }}>
        <QrCodeComponent
          id="qrCodeEl"
          renderAs="svg"
          includeMargin
          size={size}
          // fgColor={theme.palette.secondary.main}
          value={value}
        />

        <Typography
          sx={{ color: (theme) => theme.palette.grey[700] }}
          fontSize="small"
        >
          {qrCodeToDisplay ? qrCodeToDisplay : value}
        </Typography>
      </Box>

      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        onClick={() => downloadQRCodeAsSVG(value, qrType, selectedMerchantObj)}
        size="small"
      >
        Download QR
      </Button>
    </Box>
  )
}

export default QrCode
