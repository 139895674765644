/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { fetchPostStart } from 'store/post/postSlice'
import {
  deleteAboutUsBySlugStart,
  editAboutUsStart,
  fetchAboutUsByIdStart,
  fetchAboutUsStart,
} from 'store/websiteAdmin/websiteAdminSlice'

import { openAlert } from 'store/alert/alertSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  websiteAdmin: state.websiteAdmin,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchSectionStart: (payload) => dispatch(fetchAboutUsStart(payload)),
  onEditSectionStart: (payload) => dispatch(editAboutUsStart(payload)),
  onDeleteAboutUsBySlugStart: (payload) =>
    dispatch(deleteAboutUsBySlugStart(payload)),
  onFetchSectionByIdStart: (payload) =>
    dispatch(fetchAboutUsByIdStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
