import React, { useEffect } from 'react'
import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import { Box, Typography } from '@mui/material'
import AppComponentHeader from '@crema/core/AppComponentHeader'
import AppCard from '@crema/core/AppCard'

import container from './SendMerchantEmail.container'
import EmailForm from './components/EmailForm'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function handleClick(event) {
  event.preventDefault()
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const CustomizedBreadcrumbs = () => {
  const navigate = useNavigate()

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          label="Merchants"
          icon={<HomeIcon fontSize="small" />}
          onClick={() => navigate('/dashboard')}
        />
        <StyledBreadcrumb label="Send Merchant Email" />
      </Breadcrumbs>
    </div>
  )
}

const SendMerchantRequest = ({
  onFetchMemberStart,
  onCreateStep1DraftStart,
  countrySetup: { countryList },
  stateOwners: { hasMoreData },
  members: {
    memberList: { data, hasMore, totalCount, count, page: pageNumber },
    loading,
  },
  merchant: { isCreatingDraft, isCreating, isCreatingGeneral, draftBasicInfo },
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  onFetchMerchantDraftBasicInfoPanelUserStart,
  onClearDraftData,
  onClearGeneralData,
  adminRoute,
  ...rest
}) => {
  const { id } = useParams()
  const location: any = useLocation()

  const [value, setValue] = React.useState(0)

  useEffect(() => {
    if (id) {
      onFetchMerchantDraftBasicInfoPanelUserStart(id)
    } else {
      onClearDraftData()
      onClearGeneralData()
    }
  }, [id])

  useEffect(() => {
    if (!_.isEmpty(location?.state)) {
      setValue(location?.state?.step)
    }
  }, [location])

  useEffect(() => {
    if (id && !_.isEmpty(draftBasicInfo)) {
      setValue(draftBasicInfo?.registrationCompletedStep)
    }
  }, [id, draftBasicInfo])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box style={{ color: '#000000' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <AppComponentHeader title="Send Merchant Email" />
        <CustomizedBreadcrumbs />
      </Box>

      <AppCard>
        <Box sx={{ width: '100%' }}>
          <EmailForm adminRoute={adminRoute} />
        </Box>
        <Box style={{ height: 30 }} />
      </AppCard>
    </Box>
  )
}

export default container(SendMerchantRequest)
