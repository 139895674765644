/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { sendClubReqStart } from 'store/club/clubSlice'
import { fetchMerchantPackageStart } from 'store/merchantPackage/merchantPackageSlice'
import {
  createBankInfoStart,
  fetchMerchantGeneralBankInfoStart,
  checkApplyMerchantPremiumCodeStart,
  clearMerchantPremiumCodeData,
  fetchMerchantDraftBasicInfoPanelUserStart,
  clearMerchantCodeData,
  applyMerchantCouponCodeStart,
  clearCouponCodeData,
  clearMerchantCouponCodeData,
} from 'store/merchant/merchantSlice'
import { openAlert } from 'store/alert/alertSlice'
import { fetchPiiinkInformationGetByCountryStart } from 'store/globalAdminSettings/globalAdminSettingsSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
  merchantPackage: state.merchantPackage,
  merchant: state.merchant,
  globalSettings: state.globalSettings,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  onCreateBankInfoStart: (payload) => dispatch(createBankInfoStart(payload)),
  onFetchMerchantPackageStart: (payload) =>
    dispatch(fetchMerchantPackageStart(payload)),
  onFetchMerchantGeneralBankInfoStart: (payload) =>
    dispatch(fetchMerchantGeneralBankInfoStart(payload)),
  onCheckApplyMerchantPremiumCodeStart: (payload) =>
    dispatch(checkApplyMerchantPremiumCodeStart(payload)),
  clearMerchantPremiumCodeData: () => dispatch(clearMerchantPremiumCodeData()),
  onFetchMerchantDraftBasicInfoPanelUserStart: (payload) =>
    dispatch(fetchMerchantDraftBasicInfoPanelUserStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
  onClearMerchantCodeData: () => dispatch(clearMerchantCodeData()),
  onApplyMerchantCouponCodeStart: (payload) =>
    dispatch(applyMerchantCouponCodeStart(payload)),
  clearMerchantCouponCodeData: () => dispatch(clearMerchantCouponCodeData()),
  onClearCouponCodeData: () => dispatch(clearCouponCodeData()),
  onFetchPiiinkInformationGetByCountryStart: (payload) =>
    dispatch(fetchPiiinkInformationGetByCountryStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
