import { createSlice } from '@reduxjs/toolkit';

import { IPostState } from '../interfaces';

const INITIAL_STATE: IPostState = {
  postList: [],
  error: null,
  loading: false
};

export const postSlice = createSlice({
  name: 'post',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPostStart: (state: any, action) => {
      state.loading = true;
    },
    fetchPostSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.postList = action.payload;
      state.loading = false;
    },
    fetchPostFailure: (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { fetchPostStart, fetchPostSuccess, fetchPostFailure } =
  postSlice.actions;

export default postSlice.reducer;
