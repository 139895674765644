import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material'

import container from './BasicInfo.container'
import { Form, Formik, Field } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import TestCountrySelect from 'components/DropdownComponents/CountrySelect'
import TestRegionSelect from 'components/DropdownComponents/RegionSelect'
import TestStateSelect from 'components/DropdownComponents/StateSelect'
import MerchantCategorySelect from 'components/DropdownComponents/MerchantCategoryParentSelect'
import MerchantSubCategorySelect from 'components/DropdownComponents/MerchantSubCategorySelect'

import * as yup from 'yup'
import TestAreaSelect from 'components/DropdownComponents/AreaSelect'
import { fetchMerchantSubCategoryListDrop } from 'api/merchantCategory'
import { useNavigate, useParams } from 'react-router-dom'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import { checkIfEmptyString } from 'utils'
import GlobalLocation from 'components/GlobalLocation'
import _ from 'lodash'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onClearMerchantPremiumCode?: () => void
  onCloseAddCountry?: () => void
  onCreateClubStart?: (params) => void
  onCreateStep1DraftStart?: (params) => void
  onCreateStep1GeneralStart?: (params) => void
  onFetchMerchantDraftBasicInfoPanelUserStart?: (params) => void
  onFetchMerchantGeneralBasicInfoPanelUserStart?: (params) => void
  onEditStep1GeneralStart?: (params) => void
  onFetchOneBySlugStart?: (params) => void
  onFetchCountryStripeKeyStart?: (params) => void
  onClearCountryStripeKey?: () => void
  onEditStep1Start?: (params) => void
  onClearDraftData?: () => void
  handleChangeTab?: any
  refreshPageData?: any
  onClearRecentMerchant?: () => void
  siteCoordinator?: any
  club?: any
  merchant?: any
  auth?: any
  adminRoute?: any
  page?: any
}

const AddNewClub: React.FC<AddNewCountryProps & any> = ({
  onCloseAddCountry,
  onCreateStep1DraftStart,
  onCreateStep1GeneralStart,
  onEditStep1Start,
  onClearRecentMerchant,
  handleChangeTab,
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  merchant: { isCreatingDraft, isCreatingGeneral, draftBasicInfo, isEditing },
  page: { pageData },
  onClearMerchantPremiumCode,
  refreshPageData,
  onEditStep1GeneralStart,
  onFetchCountryStripeKeyStart,
  onClearCountryStripeKey,

  adminRoute,
}) => {
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      if (!_.isEmpty(draftBasicInfo?.__country__)) {
        onClearCountryStripeKey()
        onFetchCountryStripeKeyStart(draftBasicInfo?.__country__?.id)
      }
    }
  }, [draftBasicInfo])

  const navigate = useNavigate()
  const [fieldIsRequired, setFieldIsRequired] = useState<boolean>(true)
  const [subCategory, setSubCategory] = useState<any>({})
  const [openLearnModal, setOpenLearnModal] = React.useState(false)

  // useEffect(() => {
  //   if (id) {
  //     onFetchMerchantDraftBasicInfoPanelUserStart(id)
  //   }
  // }, [id])

  useEffect(() => {
    onClearMerchantPremiumCode()
  }, [])

  const closeModal = () => {
    onCloseAddCountry()
  }
  const validationSchema = yup.object({
    merchantPhoneNumber: yup
      .string()
      .min(7, 'Phone number should be at least 7 digits'),
    phoneNumber: yup
      .string()
      .min(7, 'Phone number should be at least 7 digits'),
    username: yup
      .string()
      .min(5, 'Username should be atleast 5 character long'),
    merchantEmail: yup.string().email(),
    email: yup.string().email(),
  })

  const changedMerchantCategory = async (value) => {
    const recentId = value[value?.length - 1]?.id

    const selectedCategory = value.map((item) => item.id)

    if (!selectedCategory.length) {
      return setSubCategory({})
    }

    if (recentId && recentId !== undefined) {
      fetchMerchantSubCategoryListDrop({ parentId: recentId }).then((res) => {
        const newObj = { ...subCategory, [recentId]: res }
        const lastSubCategory = selectedCategory.map((item) => {
          return { [item]: newObj[item] }
        })
        const subCategoryObj = lastSubCategory.reduce(
          (a, b) => Object.assign(a, b),
          {}
        )

        setSubCategory(subCategoryObj)
      })
    }
  }

  const closeLearnModal = () => {
    setOpenLearnModal(false)
  }

  const onDeleteLearnModal = () => {
    setOpenLearnModal(false)
  }

  return (
    <>
      <Formik
        validateOnChange={true}
        enableReinitialize
        initialValues={{
          merchantName: draftBasicInfo?.merchantName ?? '',
          merchantEmail: draftBasicInfo?.merchantEmail ?? '',
          username: checkIfEmptyString(draftBasicInfo?.username) ?? '',
          phoneNumber: checkIfEmptyString(draftBasicInfo?.phoneNumber) ?? '',
          contactPersonFirstName:
            checkIfEmptyString(draftBasicInfo?.contactPersonFirstName) ?? '',
          contactPersonLastName:
            checkIfEmptyString(draftBasicInfo?.contactPersonLastName) ?? '',
          merchantPhoneNumber: draftBasicInfo?.merchantPhoneNumber ?? '',
          email: checkIfEmptyString(draftBasicInfo?.email) ?? '',
          postalCodeUser: draftBasicInfo?.postalCodeUser ?? '',
          city: checkIfEmptyString(draftBasicInfo?.city) ?? null,
          businessRegistrationNumber:
            draftBasicInfo?.businessRegistrationNumber ?? '',
          buildingNo: draftBasicInfo?.buildingNo ?? null,
          streetInfo: draftBasicInfo?.streetInfo ?? '',
          charityId: draftBasicInfo?.__charity__
            ? {
                ...draftBasicInfo?.__charity__,
                label: draftBasicInfo?.__charity__?.charityName,
              }
            : null,
          categoryIds: draftBasicInfo?.__merchantCategories__?.length
            ? draftBasicInfo?.__merchantCategories__
                ?.filter((item) => !item?.parentId)
                .map((item) => {
                  return {
                    ...item,
                    label: item?.name,
                  }
                })
            : [],

          categorySubIds: draftBasicInfo?.__merchantCategories__?.length
            ? draftBasicInfo?.__merchantCategories__
                ?.filter((item) => item?.parentId)
                .map((item) => {
                  return {
                    ...item,
                    label: item?.name,
                  }
                })
            : [],
          latitude: draftBasicInfo?.latlon?.length
            ? draftBasicInfo?.latlon[0]
            : '',
          longitude: draftBasicInfo?.latlon?.length
            ? draftBasicInfo?.latlon[1]
            : '',

          countryId: draftBasicInfo?.__country__
            ? {
                ...draftBasicInfo?.__country__,
                label: draftBasicInfo?.__country__?.countryName,
              }
            : null,
          regionId: draftBasicInfo?.__region__
            ? {
                ...draftBasicInfo?.__region__,
                label: draftBasicInfo?.__region__?.regionName,
              }
            : null,
          stateId: draftBasicInfo?.__state__
            ? {
                ...draftBasicInfo?.__state__,
                label: draftBasicInfo?.__state__?.stateName,
              }
            : null,
          areaId: draftBasicInfo?.__area__
            ? {
                ...draftBasicInfo?.__area__,
                label: draftBasicInfo?.__area__?.areaName,
              }
            : null,
        }}
        validationSchema={validationSchema}
        onSubmit={(data: any, { setSubmitting }) => {
          setSubmitting(true)
          onClearRecentMerchant()
          const mergedWithSubCategory = [
            ...data?.categoryIds,
            ...data?.categorySubIds,
          ]
          if (data?.action === 'draft') {
            if (id) {
              onEditStep1Start({
                params: {
                  ...data,
                  id,
                  countryId: data?.countryId?.id
                    ? data?.countryId?.id
                    : globalCountry?.id,
                  stateId: data?.stateId?.id,
                  regionId: data?.regionId?.id,
                  areaId: data?.areaId?.id,
                  charityId: data?.charityId?.id,
                  postalCodeId: null,
                  latlon: [data?.latitude ?? null, data?.longitude ?? null],
                  buildingNo: data?.buildingNo === '' ? null : data?.buildingNo,
                  city: data?.city === '' ? null : data?.city,

                  categoryIds: mergedWithSubCategory?.length
                    ? mergedWithSubCategory?.map((item) => item?.id)
                    : null,
                },
                closeModal,
                handleChangeTab,
                fromDraft: data?.action === 'draft' ? true : false,
                navigate,
                route: `/${adminRoute}/draftList`,
                refreshPageData,
              })
            } else {
              onCreateStep1DraftStart({
                params: {
                  ...data,
                  countryId: data?.countryId?.id
                    ? data?.countryId?.id
                    : globalCountry?.id,
                  stateId: data?.stateId?.id,
                  regionId: data?.regionId?.id,
                  areaId: data?.areaId?.id,
                  charityId: data?.charityId?.id,
                  postalCodeId: null,
                  latlon: [data?.latitude ?? null, data?.longitude ?? null],
                  buildingNo: data?.buildingNo === '' ? null : data?.buildingNo,
                  city: data?.city === '' ? null : data?.city,

                  categoryIds: mergedWithSubCategory?.length
                    ? mergedWithSubCategory?.map((item) => item?.id)
                    : null,
                },
                closeModal,
                handleChangeTab,
                navigate,
                route: `/${adminRoute}/draftList`,
              })
            }
          } else if (data?.action === 'general') {
            if (id) {
              onEditStep1GeneralStart({
                params: {
                  ...data,
                  id,
                  countryId: data?.countryId?.id
                    ? data?.countryId?.id
                    : globalCountry?.id,
                  stateId: data?.stateId?.id,
                  regionId: data?.regionId?.id,
                  areaId: data?.areaId?.id,
                  charityId: data?.charityId?.id,
                  postalCodeId: null,
                  city: data?.city === '' ? null : data?.city,

                  latlon: [data?.latitude ?? null, data?.longitude ?? null],
                  categoryIds: mergedWithSubCategory?.length
                    ? mergedWithSubCategory?.map((item) => item?.id)
                    : null,
                  buildingNo: data?.buildingNo === '' ? null : data?.buildingNo,
                },
                closeModal,
                handleChangeTab,
                navigate,
                fromDraft: data?.action === 'draft' ? true : false,
                route: '/country-licensee/draftList',
                refreshPageData,
              })
            } else {
              onCreateStep1GeneralStart({
                params: {
                  ...data,
                  countryId: data?.countryId?.id
                    ? data?.countryId?.id
                    : globalCountry?.id,
                  stateId: data?.stateId?.id,
                  regionId: data?.regionId?.id,
                  areaId: data?.areaId?.id,
                  charityId: data?.charityId?.id,
                  postalCodeId: null,
                  latlon: [data?.latitude ?? null, data?.longitude ?? null],
                  buildingNo: data?.buildingNo === '' ? null : data?.buildingNo,
                  city: data?.city === '' ? null : data?.city,

                  categoryIds: mergedWithSubCategory?.length
                    ? mergedWithSubCategory?.map((item) => item?.id)
                    : null,
                  isPopularFlag: false,
                },
                closeModal,
                handleChangeTab,
                navigate,
              })
            }
          }

          setSubmitting(false)
        }}
        render={({ values, setFieldValue, handleBlur, handleChange }) => {
          const recentParentCategory =
            values?.categoryIds?.length &&
            values?.categoryIds[values?.categoryIds?.length - 1]
          return (
            <Form>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Main Information</Typography>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Merchant’s Trading Name'}
                      label={'Merchant’s Trading Name'}
                      name="merchantName"
                      variant="outlined"
                      required={fieldIsRequired}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Merchant User Name'}
                      label={'Merchant User Name'}
                      name="username"
                      variant="outlined"
                      required
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Merchant Email For App'}
                      label={'Merchant Email For App'}
                      name="merchantEmail"
                      variant="outlined"
                      required
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    lg={3}
                    spacing={1}
                  >
                    <AppTextField
                      placeholder={'Phone Number'}
                      label={'Phone Number'}
                      type="number"
                      name="merchantPhoneNumber"
                      required
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Business Registration Number'}
                      label={'Business Registration Number'}
                      name="businessRegistrationNumber"
                      required={fieldIsRequired}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  {/* 
                  <Grid item xs={12} lg={3}>
                    <Field
                      name="charityId"
                      component={CharitySelect}
                      filterByCountryId={currentUser?.userCountry}
                      label="Select Charity"
                    />
                  </Grid> */}
                </Grid>

                <Grid container spacing={4} sx={{ mt: 3 }}>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="h2">
                      Category Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      name="categoryIds"
                      required={fieldIsRequired}
                      component={MerchantCategorySelect}
                      changedMerchantCategory={changedMerchantCategory}
                      multiple={true}
                      // filterByCountryId={currentUser?.userCountry}
                      label="Select Merchant Category"
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      name="categorySubIds"
                      component={MerchantSubCategorySelect}
                      recentParentCategory={recentParentCategory}
                      multiple={true}
                      subCategoryList={Object.values(subCategory)?.flat()}
                      // filterByCountryId={currentUser?.userCountry}
                      label="Select Merchant Sub-Category"
                      // required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} sx={{ mt: 3 }}>
                  <Grid item xs={12}>
                    <Typography variant="h4" component="h2">
                      Contact Person Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'First Name'}
                      label={'First Name'}
                      name="contactPersonFirstName"
                      required={fieldIsRequired}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Last Name'}
                      label={'Last Name'}
                      name="contactPersonLastName"
                      required={fieldIsRequired}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Email'}
                      label={'Email'}
                      name="email"
                      required={fieldIsRequired}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    lg={3}
                    spacing={1}
                  >
                    <AppTextField
                      placeholder={'Phone Number'}
                      label={'Phone Number'}
                      type="number"
                      name="phoneNumber"
                      required={fieldIsRequired}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4} sx={{ mt: 3 }}>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="h2">
                      Location Information
                    </Typography>
                  </Grid>
                  {currentUser?.role?.toLowerCase() ===
                    'collaborationpartner' &&
                    currentUser?.userCountry?.partnerType?.toLowerCase() ===
                      'global' && (
                      <Grid item xs={12} lg={3}>
                        <Field
                          name="countryId"
                          component={TestCountrySelect}
                          disabled={id}
                          changeStripeKey={true}
                        />
                      </Grid>
                    )}

                  <Grid item xs={12} lg={3}>
                    <Field
                      name="stateId"
                      component={TestStateSelect}
                      defaultSelected={globalCountry}
                      makeDefaultEmpty={true}
                      required={fieldIsRequired}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      name="regionId"
                      component={TestRegionSelect}
                      defaultSelected={values?.stateId}
                      makeDefaultEmpty={true}
                      label={'Select Region (If Known)'}
                      // required={fieldIsRequired}
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <Field
                      name="areaId"
                      component={TestAreaSelect}
                      defaultSelected={values?.regionId}
                      makeDefaultEmpty={true}
                      label={'Select Area (If Known)'}

                      // required={fieldIsRequired}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Unit/Apartment/Villa Number'}
                      name="buildingNo"
                      variant="outlined"
                      label={'Unit/Apartment/Villa Number'}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Street Name & Street Number'}
                      name="streetInfo"
                      label={'Street Name & Street Number'}
                      variant="outlined"
                      required={fieldIsRequired}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Postal/Zip Code'}
                      required={fieldIsRequired}
                      label={'Postal/Zip Code'}
                      name="postalCodeUser"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'City'}
                      label={'City'}
                      name="city"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  {/* <Grid item xs={12} lg={3}>
                  <AppTextField
                    placeholder={'Latitude'}
                      disabled
                    required={fieldIsRequired}
                    label={'Latitude'}
                    type="number"
                    name="latitude"
                    variant="outlined"
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <AppTextField
                    placeholder={'Longitude'}
                      disabled
                    required={fieldIsRequired}
                    label={'Longitude'}
                    type="number"
                    name="longitude"
                    variant="outlined"
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                    size="small"
                  />
                </Grid> */}
                </Grid>

                <Grid container spacing={3} sx={{ mt: 3 }}>
                  {/* <Grid item xs={12}>
                    <Typography variant="body1">
                      Learn{' '}
                      <Link
                        sx={{ cursor: 'pointer', fontStyle: 'italic' }}
                        onClick={() => {
                          setOpenLearnModal(true)

                          onFetchOneBySlugStart({
                            slug: 'instructions-for-entering-latitude-and-longitude',
                          })
                        }}
                      >
                        how to enter longitude & latitude
                      </Link>
                    </Typography>
                  </Grid> */}
                  <Grid item container>
                    <GlobalLocation
                      defaultObject={draftBasicInfo}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Latitude'}
                      disabled
                      label={'Latitude'}
                      name="latitude"
                      type="number"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Longitude'}
                      disabled
                      label={'Longitude'}
                      name="longitude"
                      type="number"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Box sx={{ height: 20 }} />
                <Box sx={{ height: 30 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isCreatingDraft || isEditing}
                    onClick={() => {
                      setFieldIsRequired(false)

                      setFieldValue('action', 'draft')
                    }}
                  >
                    {isCreatingDraft ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Save as Draft'
                    )}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isCreatingGeneral || isEditing}
                    onClick={() => {
                      setFieldIsRequired(true)

                      setFieldValue('action', 'general')
                    }}
                  >
                    {isCreatingGeneral ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Save & Continue'
                    )}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    onClick={() => navigate(`/${adminRoute}/draftList`)}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      />
      {openLearnModal && (
        <ModalRenderer
          open={openLearnModal}
          onDeny={closeLearnModal}
          onConfirm={onDeleteLearnModal}
          title=""
          dialogTitle="Learn how to enter longitude & latitude"
        >
          <Typography
            dangerouslySetInnerHTML={{ __html: pageData?.description }}
            sx={{ ml: 4, p: 3 }}
            variant="body1"
          />
        </ModalRenderer>
      )}
    </>
  )
}

export default container(AddNewClub)
