import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Edit, Visibility } from '@mui/icons-material'
import {
  Tooltip,
  IconButton,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import RejectReasonViewer from 'components/RejectReasonViewer'
import AcceptModal from '@crema/core/AppConfirmDialog'

import MerchantUpgrade from 'components/MerchantUpgrade'
import TransferModal from '../Transfer'
import ReferredMerchantDetails from '../ReferredMerchantDetails'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CloseIcon from '@mui/icons-material/Close'
import ConfirmDialogWithReason from 'components/ReusableModalComponents/ConfirmDialogWithReason'
import EditReferredMerchantInfo from '../EditReferredMerchantInfo'
import { useNavigate } from 'react-router-dom'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  lineHeight: 1.43,
  paddingRight: 20,
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  count: number
  pageNumber?: number
  rowsPerPage?: number
  adminRoute?: any
  currentUser?: any
  isApproving?: boolean
  isRejecting?: boolean
  isSetting?: boolean
  onDeleteClubStart?: (payload: any) => void
  onRejectReferredMerchantStart?: (payload: any) => void
  onApproveReferredMerchantStart?: (payload: any) => void
  onAcceptOrRejectreferredMerchantStart?: (payload: any) => void
  refreshPageData?: () => void
}

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  pageNumber,
  rowsPerPage,
  adminRoute,
  refreshPageData,
  currentUser,
  onRejectReferredMerchantStart,
  onApproveReferredMerchantStart,
  onAcceptOrRejectreferredMerchantStart,
  isApproving,
  isRejecting,
}) => {
  const navigate = useNavigate()
  const [isAddCountryOpen, setAddCountryOpen] = useState<boolean>(false)
  const [isMerchantDetailOpen, setIsMerchantDetailOpen] =
    useState<boolean>(false)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false)
  const [openAcceptRequestModal, setOpenAcceptRequestModal] =
    useState<boolean>(false)
  const [openRejectRequestModal, setOpenRejectRequestModal] =
    useState<boolean>(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false)
  const [isTransferModalOpen, setIsTransferModalOpen] = useState<boolean>(false)

  const [selectedItem, setSelectedItem] = useState<any>(null)

  const [openReasonModal, setOpenReasonModal] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)

  const [openUpgradeModal, setOpenUpgradeModal] = useState<boolean>(false)

  const [rejectReason, setRejectReason] = useState<string>('')

  const onCloseAddCountry = () => {
    setAddCountryOpen(false)
  }

  const onCloseApproveModal = () => {
    setIsApproveModalOpen(false)
  }

  const onCloseRejectModal = () => {
    setIsRejectModalOpen(false)
  }

  const reasonModalOpen = (data) => {
    setRejectReason(data)
    setOpenReasonModal(true)
  }

  const closeUpgradeModal = () => {
    setOpenUpgradeModal(false)
  }

  const onDeleteUpgradeModal = () => {
    setOpenUpgradeModal(false)
  }

  const closeAcceptModal = () => {
    setOpenAcceptRequestModal(false)
  }

  const confirmAcceptRequest = () => {
    onAcceptOrRejectreferredMerchantStart({
      params: { id: selectedItem?.id, isAccepted: true },
      navigate,
      closeModal: closeAcceptModal,
      refreshPageData,
      acceptMerchantRequest: true,
    })
  }

  const closeRejectRequestModal = () => {
    setOpenRejectRequestModal(false)
  }

  const checkStatusReferralStatus = (data) => {
    if (data?.isApproved && !data?.isRejected && !data?.isVerified) {
      return 'Referral Sent'
    } else if (!data?.isApproved && data?.isRejected && !data?.isVerified) {
      return 'Rejected'
    } else if (!data?.isApproved && !data?.isRejected && !data?.isVerified) {
      return 'Pending'
    } else if (data?.isApproved && !data?.isRejected && data?.isVerified) {
      return 'Verified'
    } else {
      return '-'
    }
  }

  const checkMerchantStatus = (data) => {
    if (!data?.isApproved && !data?.isRejected && !data?.isVerified) {
      return 'Referral Pending'
    } else if (data?.isVerified && data?.isApproved && !data?.isRejected) {
      return 'Merchant Approved'
    } else if (data?.isApproved && !data?.isRejected && !data?.isVerified) {
      return 'Merchant Pending'
    } else {
      return '-'
    }
  }

  const checkIconVisibility = (data) => {
    if (
      data?.isApproved ||
      data?.isRejected ||
      data?.transferRequestStatus === 'rejected' ||
      data?.transferRequestStatus === 'accepted'
    ) {
      return ''
    } else {
      return (
        <>
          <Button
            variant="text"
            onClick={() => {
              setSelectedItem(data)
              setOpenAcceptRequestModal(true)
            }}
          >
            <Typography variant="h5" color="#39945f9e">
              Accept Request
            </Typography>
          </Button>

          <Button
            variant="text"
            onClick={() => {
              setSelectedItem(data)
              setOpenRejectRequestModal(true)
            }}
          >
            <Typography variant="h5" color="#d32f2fbd">
              Reject Request
            </Typography>
          </Button>
        </>
      )
    }
  }

  return (
    <>
      {openAcceptRequestModal && (
        <AcceptModal
          open={openAcceptRequestModal}
          onDeny={setOpenAcceptRequestModal}
          onConfirm={confirmAcceptRequest}
          title="Are you sure you want to accept this request?"
          dialogTitle="Confirm Accept ?"
        />
      )}

      {openRejectRequestModal && (
        <ConfirmDialogWithReason
          open={openRejectRequestModal}
          onDeny={closeRejectRequestModal}
          onConfirm={closeRejectRequestModal}
          toDeleteId={selectedItem?.id}
          onCloseEditDialog={closeRejectRequestModal}
          deleteFunction={onAcceptOrRejectreferredMerchantStart}
          refreshPageData={refreshPageData}
          title="Are you sure you want to reject this request?"
          dialogTitle="Reject Request?"
          isDeleting={isRejecting}
        />
      )}

      {isTransferModalOpen && (
        <TransferModal
          isAddPageOpen={isTransferModalOpen}
          onCloseAddPage={() => setIsTransferModalOpen(false)}
          batchId={parseInt(selectedItem?.id)}
          selectedMerchant={selectedItem}
          refreshPageData={refreshPageData}
        />
      )}

      {openEditModal && (
        <EditReferredMerchantInfo
          isAddCountryOpen={openEditModal}
          onCloseAddCountry={() => setOpenEditModal(false)}
          selectedMerchant={selectedItem}
          refreshPageData={refreshPageData}
        />
      )}

      {isRejectModalOpen && (
        <ConfirmDialogWithReason
          open={isRejectModalOpen}
          onDeny={onCloseRejectModal}
          onConfirm={onCloseRejectModal}
          toDeleteId={selectedItem?.id}
          onCloseEditDialog={onCloseRejectModal}
          deleteFunction={onRejectReferredMerchantStart}
          refreshPageData={refreshPageData}
          title="Are you sure you want to reject this merchant?"
          dialogTitle="Reject Merchant"
          isDeleting={isRejecting}
        />
      )}

      {openReasonModal && (
        <RejectReasonViewer
          open={openReasonModal}
          onDeny={setOpenReasonModal}
          defaultValue={rejectReason}
          title="Rejection Reason"
          dialogTitle="Rejection Details"
        />
      )}

      {isApproveModalOpen && (
        <ModalRenderer
          open={isApproveModalOpen}
          onDeny={onCloseApproveModal}
          onConfirm={onCloseApproveModal}
          title=""
          dialogTitle="Are you sure want to approve?"
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">
                Approving this merchant will register this merchant details in
                to the system and will redirect you to the draft form .You can
                then complete the registration of Merchant from there .Do you
                want to continue?
                <br /> <br />
                If you get any errors, first click edit button(pencil icon) in
                the list and fill all the information before approving this
                merchant.
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              spacing={3}
              sx={{ mt: 4 }}
              justifyContent={'right'}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  type="submit"
                  color="primary"
                  autoFocus
                  disabled={isApproving}
                  onClick={() =>
                    onApproveReferredMerchantStart({
                      params: { id: selectedItem?.id, ...selectedItem },
                      closeModal: onCloseApproveModal,
                      refreshPageData,
                      route: `/${adminRoute}/editMerchantRequest/`,
                      navigate,
                    })
                  }
                >
                  {isApproving ? <CircularProgress size={20} /> : 'Yes'}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={onCloseApproveModal}
                >
                  No
                </Button>{' '}
              </Grid>
            </Grid>
          </Grid>
        </ModalRenderer>
      )}

      <TableRow key={data.sn} className="item-hover">
        <StyledTableCell align="left">
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.merchantName ?? '-'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.merchantEmail ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.merchantPhoneNumber ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.contactPersonFirstName
            ? `${data?.contactPersonFirstName} ${data?.contactPersonLastName}`
            : '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.phoneNumber ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">{data?.email ?? '-'}</StyledTableCell>
        <StyledTableCell align="left">
          {data?.transferRequestStatus === 'rejected' ? (
            <>
              <Typography>Request Rejected</Typography>
              <Button
                variant="text"
                sx={{ padding: 0 }}
                onClick={() =>
                  reasonModalOpen(data?.transferRequestRejectReason)
                }
              >
                view reason
              </Button>
            </>
          ) : (
            <>
              {checkStatusReferralStatus(data)} <br />
              {data?.isRejected ? (
                <Button
                  variant="text"
                  sx={{ padding: 0 }}
                  onClick={() => reasonModalOpen(data?.rejectReason)}
                >
                  view reason
                </Button>
              ) : (
                ''
              )}
            </>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {checkMerchantStatus(data)}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.isTransferred ? 'Yes' : 'No'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {currentUser?.role?.toLowerCase() !== 'globaladmin' && (
            <>
              {data?.transferRequestStatus === 'accepted' && (
                <>
                  {!data?.isApproved && !data?.isRejected && (
                    <Tooltip placement="top" title="Approve">
                      <IconButton
                        onClick={() => {
                          setSelectedItem(data)
                          setIsApproveModalOpen(true)
                        }}
                      >
                        <CheckBoxIcon sx={{ fontSize: '1.3rem' }} />
                      </IconButton>
                    </Tooltip>
                  )}

                  {!data?.isApproved && !data?.isRejected && (
                    <Tooltip placement="top" title="Reject">
                      <IconButton
                        onClick={() => {
                          setSelectedItem(data)
                          setIsRejectModalOpen(true)
                        }}
                      >
                        <CloseIcon sx={{ fontSize: '1.3rem' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          )}

          {currentUser?.role?.toLowerCase() !== 'globaladmin' && (
            <> {checkIconVisibility(data)}</>
          )}

          <Tooltip placement="top" title="View">
            <IconButton
              onClick={() => {
                setSelectedItem(data)
                setIsMerchantDetailOpen(true)
              }}
            >
              <Visibility sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>

          {currentUser?.role?.toLowerCase() !== 'globaladmin' && (
            <>
              {data?.isApproved ||
              data?.isRejected ||
              data?.transferRequestStatus === 'rejected' ||
              data?.transferRequestStatus === 'requestSent' ? (
                ''
              ) : (
                <Tooltip placement="top" title="Edit">
                  <IconButton
                    onClick={() => {
                      setSelectedItem(data)
                      setOpenEditModal(true)
                    }}
                  >
                    <Edit sx={{ fontSize: '1.3rem' }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </StyledTableCell>
      </TableRow>

      {openUpgradeModal && (
        <ModalRenderer
          open={openUpgradeModal}
          onDeny={closeUpgradeModal}
          onConfirm={onDeleteUpgradeModal}
          title=""
          dialogTitle="Upgrade Merchant Package"
          size="xl"
        >
          <MerchantUpgrade
            merchantData={data}
            closeUpgradeModal={closeUpgradeModal}
            refreshPageData={refreshPageData}
          />
        </ModalRenderer>
      )}

      {isMerchantDetailOpen && (
        <ReferredMerchantDetails
          isAddCountryOpen={isMerchantDetailOpen}
          onCloseAddCountry={() => setIsMerchantDetailOpen(false)}
          selectedMerchant={selectedItem}
          refreshPageData={refreshPageData}
        />
      )}
    </>
  )
}

export default TableItem
