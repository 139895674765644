import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  Grid,
  Autocomplete,
  Typography,
  CircularProgress,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Tooltip,
} from '@mui/material'

import container from './PackagesAndBankInfo.container'
import { Form, Formik, Field, FieldArray } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import { useParams, useNavigate, Link } from 'react-router-dom'

import * as yup from 'yup'
import _ from 'lodash'
import TransactionQrCodeSelect from 'components/DropdownComponents/TransactionQrCodeSelect'
import IssuerQrCodeSelect from 'components/DropdownComponents/IssuerQrCodeSelect'
import CheckoutForm from 'components/CheckoutForm/CheckoutForm.component'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import InfoIcon from '@mui/icons-material/Info'
import { fixed2Decimal } from 'utils'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onClearMerchantCodeData?: () => void
  onCreateClubStart?: (params) => void
  onFetchMerchantPackageStart?: (params) => void
  onCreateBankInfoStart?: (params) => void
  onFetchMerchantGeneralBankInfoStart?: (params) => void
  onCheckApplyMerchantPremiumCodeStart?: (params) => void
  onOpenAlert?: (params) => void
  clearMerchantPremiumCodeData?: () => void
  siteCoordinator?: any
  club?: any
  auth?: any
  merchantPackage?: any
  merchant?: any
  handleChangeTab?: any
  adminRoute?: any
  clearMerchantCouponCodeData?: () => void
  onClearCouponCodeData?: () => void
  onApplyMerchantCouponCodeStart?: (params) => void
  globalSettings?: any
  onFetchPiiinkInformationGetByCountryStart?: (params) => void
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  onCreateBankInfoStart,
  onFetchMerchantGeneralBankInfoStart,
  onCloseAddCountry,
  onCreateClubStart,
  onFetchMerchantPackageStart,
  onOpenAlert,
  auth: { currentUser },
  siteCoordinator: { globalCountry, recentMerchant },
  merchantPackage: {
    merchantPackageList: { data, hasMore, totalCount, count, page: pageNumber },
    loading,
  },
  merchant: {
    isCreating,
    isApplyingCode,
    merchantCodeResponse,
    merchantCodeData,
    merchantCodeError,
    draftBasicInfo,
    couponCodeResponse,
    couponCodeData,
    couponCodeError,
    isApplyingCouponCode,
  },
  globalSettings: {
    piiinkInformationGetByCountry,
    isFetching: isFetchingSettings,
  },
  handleChangeTab,
  adminRoute,
  clearMerchantPremiumCodeData,
  onCheckApplyMerchantPremiumCodeStart,
  onClearMerchantCodeData,
  onApplyMerchantCouponCodeStart,
  clearMerchantCouponCodeData,
  onClearCouponCodeData,
  onFetchPiiinkInformationGetByCountryStart,
}) => {
  const { id } = useParams()

  useEffect(() => {
    onFetchPiiinkInformationGetByCountryStart(
      currentUser?.userCountry?.countryId
    )
  }, [currentUser])

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      onFetchMerchantGeneralBankInfoStart(id)
    }
  }, [id])

  const stripe = useStripe()
  const elements = useElements()
  const [submittingStripe, setSubmittingStripe] = useState<boolean>(false)

  const [packageType, setPackageType] = useState<any>('')
  const [packageObj, setPackageObj] = useState<any>({})
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>({})

  const [couponCodeSubmitted, setCouponCodeSubmitted] = useState<boolean>(false)
  const [premiumCodeSubmitted, setPremiumCodeSubmitted] =
    useState<boolean>(false)

  const closeDescriptionModal = () => {
    setOpenDescriptionModal(false)
  }

  useEffect(() => {
    if (!_.isEmpty(data)) {
      // const filteredPackage = data?.find(
      //   (item) => item?.name?.toLowerCase() === 'premium'
      // )

      const highestValue = data?.reduce((a, b) =>
        b?.packageFee + b?.setupFee < a?.packageFee + a?.setupFee ? a : b
      )
      setPackageType(highestValue?.name?.toLowerCase() ?? '')
      setPackageObj(highestValue)
    }
  }, [data])

  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  const [tempLoader, setTempLoader] = useState(true)

  useEffect(() => {
    clearMerchantCouponCodeData()
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  useEffect(() => {
    onFetchMerchantPackageStart({
      countryId: globalCountry?.id,
      isFree: false,
      merchantId: id,
    })
  }, [])

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  const closeModal = () => {
    onCloseAddCountry()
    setMultiplePersonApply(false)
  }

  const submitPremiumCode = (data) => {
    setPremiumCodeSubmitted(true)
    onCheckApplyMerchantPremiumCodeStart({
      params: { merchantId: id, premiumCode: data, packageId: packageObj?.id },
    })
  }

  const validationSchema = yup.object({})

  const submitCouponCode = (data) => {
    setCouponCodeSubmitted(true)
    onApplyMerchantCouponCodeStart({
      params: {
        merchantId: id,
        packageId: packageObj?.id,
        couponCode: data,
      },
    })
  }

  const showTotal = () => {
    if (merchantCodeData && !_.isEmpty(merchantCodeData)) {
      return 0
    } else if (couponCodeData && !_.isEmpty(couponCodeData)) {
      return couponCodeData?.totalAmount
    } else {
      return fixed2Decimal(
        packageObj?.packageFee +
          packageObj?.setupFee +
          packageObj?.packageFeeGST +
          packageObj?.setupFeeGST
      )
    }
  }

  const resetCouponApplied = () => {
    clearMerchantCouponCodeData()
    setCouponCodeSubmitted(false)
    setPremiumCodeSubmitted(false)
  }

  return (
    <>
      {openDescriptionModal && (
        <ModalRenderer
          open={openDescriptionModal}
          onDeny={closeDescriptionModal}
          onConfirm={closeDescriptionModal}
          title=""
          dialogTitle="Description"
        >
          <Typography
            dangerouslySetInnerHTML={{ __html: selectedItem?.description }}
            variant="body1"
          />
        </ModalRenderer>
      )}
      <Formik
        validateOnChange={true}
        initialValues={{
          countryId: globalCountry?.id,
          businessNumber: '',
          accountName: '',
          bankName: '',
          bankCode: '',
          branchCode: '',
          accountNumber: '',
          swiftCode: '',
          comments: '',
          reference: '',
          transactionCode: null,
          issuerCode: null,
          agreedTermAndCondition: false,
          agreedDirectDebitFee: false,
          couponCode: null,
          premiumCode: null,
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true)

          setSubmittingStripe(true)
          if (
            packageObj?.canApplyPremiumCode &&
            merchantCodeData?.status?.toLowerCase() === 'success'
          ) {
            onCreateBankInfoStart({
              params: {
                ...data,
                merchantId: id,
                merchantPackageId: packageObj?.id,
                stripeTokenId: null,
                // transactionCode: data?.transactionCode?.label,
                // issuerCode: data?.issuerCode?.label,
                couponCode: data?.couponCode === '' ? null : data?.couponCode,
              },
              closeModal,
              navigate,
              route: `/globaladmin/merchantList`,
            })
          } else {
            const card = elements.getElement(CardElement)

            await stripe.createToken(card).then(function (result) {
              // Handle result.error or result.token

              if (!_.isEmpty(result?.error)) {
                return onOpenAlert({
                  message: result?.error?.message,
                  severity: 'error',
                })
              }

              onCreateBankInfoStart({
                params: {
                  ...data,
                  merchantId: id,
                  merchantPackageId: packageObj?.id,
                  stripeTokenId: result?.token?.id,
                  couponCode: data?.couponCode === '' ? null : data?.couponCode,

                  // transactionCode: data?.transactionCode?.label,
                  // issuerCode: data?.issuerCode?.label,
                },
                closeModal,
                navigate,
                route: `/globaladmin/merchantList`,
              })
            })
          }

          setSubmittingStripe(false)

          setSubmitting(false)
        }}
        render={({ values, setFieldValue, handleBlur, handleChange }) => {
          if (values?.premiumCode?.length < 1) {
            clearMerchantPremiumCodeData()
            setPremiumCodeSubmitted(false)
          }

          if (values?.couponCode?.length < 1) {
            clearMerchantCouponCodeData()
            setCouponCodeSubmitted(false)
          }
          return (
            <Form>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Choose Package</Typography>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    {tempLoader || loading ? (
                      <Grid container justifyContent={'center'}>
                        <CircularProgress size={20} />
                      </Grid>
                    ) : (
                      <>
                        {data?.length ? (
                          data.map((item, index) => (
                            <Grid
                              item
                              md={4}
                              sm={6}
                              xs={12}
                              key={index + 1}
                              sx={{ position: 'relative' }}
                            >
                              {item?.description ? (
                                <Tooltip
                                  title={''}
                                  placement="top"
                                  sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 1,
                                    color: '#11182791',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setOpenDescriptionModal(true)
                                    setSelectedItem(item)
                                  }}
                                >
                                  <InfoIcon />
                                </Tooltip>
                              ) : (
                                ''
                              )}
                              <Card
                                sx={{
                                  minWidth: 275,
                                  cursor: 'pointer',
                                  border: item?.name?.toLowerCase() ===
                                    packageType && {
                                    border: '2px solid #00B59C',
                                    backgroundColor: '#00B59C',
                                  },
                                  '&:hover': {
                                    border: '2px solid #00B59C',
                                  },
                                }}
                                onClick={() => {
                                  setPackageType(item?.name?.toLowerCase())
                                  setPackageObj(item)
                                  onClearMerchantCodeData()
                                  setFieldValue('premiumCode', '')
                                  resetCouponApplied()
                                  setFieldValue('couponCode', '')
                                }}
                              >
                                <CardContent>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: item?.name?.toLowerCase() ===
                                        packageType && {
                                        color: '#ffffff',
                                      },
                                    }}
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    {item?.name}
                                  </Typography>
                                  {item?.setupFee ? (
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: item?.name?.toLowerCase() ===
                                          packageType && {
                                          color: '#ffffff',
                                        },
                                      }}
                                    >
                                      Setup Fee -{' '}
                                      {item?.__country__?.currencySymbol}
                                      {item?.setupFee
                                        ? fixed2Decimal(item?.setupFee)
                                        : ''}
                                    </Typography>
                                  ) : (
                                    ''
                                  )}

                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: item?.name?.toLowerCase() ===
                                        packageType && {
                                        color: '#ffffff',
                                      },
                                    }}
                                  >
                                    Package Fee -{' '}
                                    {item?.__country__?.currencySymbol}
                                    {item?.packageFee
                                      ? fixed2Decimal(item?.packageFee)
                                      : ''}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: item?.name?.toLowerCase() ===
                                        packageType && {
                                        color: '#ffffff',
                                      },
                                    }}
                                  >
                                    <strong>
                                      Total Fee -{' '}
                                      {item?.__country__?.currencySymbol}
                                      {fixed2Decimal(
                                        item?.setupFee + item?.packageFee
                                      )}
                                    </strong>
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))
                        ) : (
                          <Grid container justifyContent={'center'}>
                            <Typography variant="h4">
                              No Package Found{' '}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>

                  {piiinkInformationGetByCountry?.allowCouponInMerchant && (
                    <Grid item container spacing={3}>
                      <Grid item xs={12} sx={{ position: 'relative' }}>
                        <Typography variant="h5">Coupon Code Info</Typography>
                        <Tooltip
                          title={
                            'Discount will be applied to amount excluding GST'
                          }
                          placement="top"
                          sx={{
                            position: 'absolute',
                            left: 143,
                            top: -3,
                            color: '#11182791',
                            cursor: 'pointer',
                          }}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Enter Coupon Code'}
                          label={'Coupon Code'}
                          name="couponCode"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                          disabled={premiumCodeSubmitted}
                        />
                        {couponCodeResponse && (
                          <Typography
                            variant="body2"
                            color={couponCodeError ? 'error' : '#2e7d32'}
                            align="center"
                          >
                            {couponCodeResponse}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'inline-flex' }}>
                          <Box>
                            <Button
                              sx={{
                                color: '#ffffff',
                                display: 'flex',
                                background: '#00bfcd',
                                '&:hover': {
                                  background: '#ec4785',
                                },
                                px: 5,
                                mr: 3,
                              }}
                              type="button"
                              onClick={() =>
                                submitCouponCode(values?.couponCode)
                              }
                              disabled={
                                isApplyingCouponCode || premiumCodeSubmitted
                              }
                            >
                              {isApplyingCouponCode ? (
                                <CircularProgress size={20} />
                              ) : (
                                'Apply'
                              )}
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              sx={{
                                color: '#ffffff',
                                background: '#ec4785',
                                '&:hover': {
                                  background: '#ec4785',
                                },
                                px: 5,
                              }}
                              type="button"
                              onClick={() => setFieldValue('couponCode', '')}
                              disabled={
                                isApplyingCouponCode || premiumCodeSubmitted
                              }
                            >
                              Clear
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  {packageObj?.canApplyPremiumCode && (
                    <Grid item container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h5">Premium Code Info</Typography>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Enter Premium Code If you have any'}
                          label={'Enter Premium Code If you have any'}
                          name="premiumCode"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                          onKeyUp={() => {
                            if (values?.premiumCode?.length === 0) {
                              onClearMerchantCodeData()
                            }
                          }}
                          disabled={couponCodeSubmitted}
                        />
                        {merchantCodeResponse && (
                          <Typography
                            variant="body2"
                            color={merchantCodeError ? 'error' : '#2e7d32'}
                            align="center"
                          >
                            {merchantCodeResponse}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        {/* <Button
                          sx={{
                            color: '#ffffff',
                            display: 'flex',
                            background: '#00bfcd',
                            '&:hover': {
                              background: '#ec4785',
                            },
                            px: 5,
                          }}
                          type="button"
                          onClick={() => submitPremiumCode(values?.premiumCode)}
                          disabled={isApplyingCode || couponCodeSubmitted}
                        >
                          {isApplyingCode ? (
                            <CircularProgress size={20} />
                          ) : (
                            'Apply'
                          )}
                        </Button> */}

                        <Box sx={{ display: 'inline-flex' }}>
                          <Button
                            sx={{
                              color: '#ffffff',
                              display: 'flex',
                              background: '#00bfcd',
                              '&:hover': {
                                background: '#ec4785',
                              },
                              px: 5,
                              mr: 3,
                            }}
                            type="button"
                            onClick={() =>
                              submitPremiumCode(values?.premiumCode)
                            }
                            disabled={isApplyingCode}
                          >
                            {isApplyingCode ? (
                              <CircularProgress size={20} />
                            ) : (
                              'Apply'
                            )}
                          </Button>

                          <Box>
                            <Button
                              sx={{
                                color: '#ffffff',
                                background: '#ec4785',
                                '&:hover': {
                                  background: '#ec4785',
                                },
                                px: 5,
                              }}
                              type="button"
                              onClick={() => {
                                setFieldValue('premiumCode', '')

                                setCouponCodeSubmitted(false)
                              }}
                              disabled={isApplyingCode || couponCodeSubmitted}
                            >
                              Clear
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Total:{' '}
                        {merchantCodeData?.totalAmount ||
                        packageObj?.packageFee ||
                        packageObj?.setupFee
                          ? packageObj?.__country__?.currencySymbol
                          : ''}
                        {showTotal()}
                        {/* {!_.isEmpty(merchantCodeData)
                          ? 0
                          : fixed2Decimal(
                              packageObj?.packageFee + packageObj?.setupFee
                            )} */}
                      </Typography>
                    </Grid>
                    {/* {packageType === 'premium' || packageType === 'partner' ? (
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          Note*: Selected package requires filling up the bank
                          information.
                        </Typography>
                      </Grid>
                    ) : (
                      ''
                    )} */}
                  </Grid>

                  <Grid container spacing={3} sx={{ mt: 4, ml: 2 }}>
                    <Grid item container>
                      <Typography variant="h5">QR Codes Information</Typography>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      {/* <Field
                        name="transactionCode"
                        component={TransactionQrCodeSelect}
                      /> */}

                      <AppTextField
                        placeholder={'Transaction Code'}
                        label={'Transaction Code'}
                        name="transactionCode"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                      {/* <Field name="issuerCode" component={IssuerQrCodeSelect} /> */}
                      <AppTextField
                        placeholder={'Issuer Code'}
                        label={'Issuer Code'}
                        name="issuerCode"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                      />
                    </Grid>
                  </Grid>

                  {packageObj?.getsIssuer ? (
                    <Grid container spacing={3} sx={{ mt: 4, ml: 2 }}>
                      <Grid item container>
                        <Typography variant="h4">Bank Information </Typography>
                      </Grid>

                      <Grid item container>
                        <Typography variant="h5">
                          Receiving Bank Account
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body1" color="#43bfcd">
                          <strong>
                            Note*: Piink will use this bank account details to
                            credit the earnings this merchant is entitled to
                          </strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Bank Name'}
                          label={'Bank Name'}
                          name="bankName"
                          required
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'BSB'}
                          label={'BSB'}
                          name="BSB"
                          required={
                            globalCountry?.countryName?.toLowerCase() ===
                            'australia'
                              ? true
                              : false
                          }
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Bank Code'}
                          label={'Bank Code'}
                          name="bankCode"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Branch Code'}
                          label={'Branch Code'}
                          name="branchCode"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Account Name'}
                          label={'Account Name'}
                          name="accountName"
                          required
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Account Number'}
                          label={'Account Number'}
                          name="accountNumber"
                          required
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <AppTextField
                          placeholder={'Swift Code'}
                          label={'Swift Code'}
                          name="swiftCode"
                          required={
                            globalCountry?.countryName?.toLowerCase() !==
                            'australia'
                              ? true
                              : false
                          }
                          variant="outlined"
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              fontSize: 14,
                            },
                          }}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>

                {packageObj?.canApplyPremiumCode &&
                merchantCodeData?.status?.toLowerCase() === 'success' ? (
                  ''
                ) : (
                  <>
                    <Grid container spacing={3} sx={{ mt: 4 }}>
                      <Grid item container>
                        <Typography variant="h5">
                          Paying Credit/Debit Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          color="#43bfcd"
                          fontSize={13}
                        >
                          <strong>
                            Note*: We don't store bank credit card details due
                            to privacy issues, Stripe(Third party software)
                            stores the credit card details to protect your own
                            privacy information. We will debit your Friendship
                            fee every month from this card.
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} lg={4}>
                        <CheckoutForm />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        mt: 4,
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <label>
                            <Field
                              type="checkbox"
                              name="agreedDirectDebitFee"
                              checked={values?.agreedDirectDebitFee}
                              component={Checkbox}
                              size="small"
                              onChange={() =>
                                setFieldValue(
                                  'agreedDirectDebitFee',
                                  !values.agreedDirectDebitFee
                                )
                              }
                              required
                            />
                          </label>
                        </Grid>
                        <Grid item>
                          <Box sx={{ marginLeft: 2, mt: 2 }}>
                            I agree to direct debit fee{' '}
                            <Link
                              target="_blank"
                              to={`/document/direct-debit-fee-terms-and-conditions/${draftBasicInfo?.__country__?.id}`}
                            >
                              terms and conditions
                            </Link>
                            .
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    mt: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Checkbox color="primary" sx={{ ml: -3 }} /> */}
                    <Box component="span">
                      {packageObj?.getsIssuer ? (
                        <Grid container>
                          <Grid item>
                            <label>
                              <Field
                                type="checkbox"
                                name="agreedTermAndCondition"
                                checked={values?.agreedTermAndCondition}
                                component={Checkbox}
                                size="small"
                                onChange={() =>
                                  setFieldValue(
                                    'agreedTermAndCondition',
                                    !values.agreedTermAndCondition
                                  )
                                }
                                required
                              />
                            </label>
                          </Grid>
                          <Grid item>
                            <Box sx={{ marginLeft: 2, mt: 2 }}>
                              I agree to the{' '}
                              <Link
                                target="_blank"
                                to={`/document/terms-and-conditions/${draftBasicInfo?.__country__?.id}`}
                              >
                                Merchant
                              </Link>{' '}
                              and{' '}
                              <Link
                                target="_blank"
                                to={`/document/issuer-terms-and-conditions/${draftBasicInfo?.__country__?.id}`}
                              >
                                Issuer Agreement
                              </Link>{' '}
                              terms and conditions.
                            </Box>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container>
                          <Grid item>
                            <label>
                              <Field
                                type="checkbox"
                                name="agreedTermAndCondition"
                                checked={values?.agreedTermAndCondition}
                                component={Checkbox}
                                size="small"
                                onChange={() =>
                                  setFieldValue(
                                    'agreedTermAndCondition',
                                    !values.agreedTermAndCondition
                                  )
                                }
                                required
                              />
                            </label>
                          </Grid>
                          <Grid item>
                            <Box sx={{ marginLeft: 2, mt: 2 }}>
                              I agree to the{' '}
                              <Link
                                target="_blank"
                                to={`/document/terms-and-conditions/${draftBasicInfo?.__country__?.id}`}
                              >
                                Merchant
                              </Link>{' '}
                              Agreement terms and conditions.
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ height: 20 }} />
                <Box sx={{ height: 30 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isCreating}
                  >
                    {isCreating ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        {(packageObj?.canApplyPremiumCode &&
                          merchantCodeData?.status?.toLowerCase() ===
                            'success') ||
                        packageObj?.setupFee +
                          packageObj?.packageFee +
                          packageObj?.setupFeeGST +
                          packageObj?.packageFeeGST ===
                          0
                          ? 'Register'
                          : 'Pay'}
                      </>
                    )}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    onClick={() => navigate('/globaladmin/merchantList')}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      />
    </>
  )
}

export default container(AddNewClub)
