// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
export const fetchPendingAreaOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/areaOwner/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// export const fetchCountryById = (id) => {
//   return () =>
//     axiosRequest
//       .get(`/api/country/getOne/${id}`)
//       .then((res) => {
//         console.log('fetchCountryList>>>', res)
//         return Promise.resolve(res)
//       })
//       .catch((err) => {
//         return Promise.reject(err)
//       })
// }

// export const createCountry = (params) => {
//   console.log('create country', params)
//   return () =>
//     axiosRequest
//       .post(`/api/country/create`, params)
//       .then((res) => {
//         return Promise.resolve(res)
//       })
//       .catch((err) => {
//         return Promise.reject(err)
//       })
// }

export const deletePendingAreaOwner = (id, rejectReason) => {
  return () =>
    axiosRequest
      .put(`/api/areaOwner/rejectValue/${id}`, {
        rejectReason,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// export const editCountry = (id, params) => {
//   return () =>
//     axiosRequest
//       .put(`/api/country/edit/${id}`, params)
//       .then((res) => {
//         return Promise.resolve(res)
//       })
//       .catch((err) => {
//         return Promise.reject(err)
//       })
// }
