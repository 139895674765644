import { createSlice } from '@reduxjs/toolkit'

import { ICollaborationPackage } from '../interfaces'
import { listObj } from 'utils'
const INITIAL_STATE: ICollaborationPackage = {
  collaborationPackageList: listObj,
  soleCollaborationPackage: {},
  error: null,
  loading: false,
  isFetching: false,
  hasMoreData: false,
}

export const collaborationPackageSlice = createSlice({
  name: 'collaborationPackage',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCollaborationPackageStart: (state: any, action) => {
      state.loading = true
    },
    fetchCollaborationPackageSuccess: (state: any, action) => {
      state.collaborationPackageList = action.payload
      state.loading = false
    },
    fetchCollaborationPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCollaborationPackageByIdStart: (state: any, action) => {
      state.soleCollaborationPackage = {}
      state.isFetching = true
    },
    fetchCollaborationPackageByIdSuccess: (state: any, action) => {
      state.soleCollaborationPackage = action.payload.data
      state.isFetching = false
    },
    fetchCollaborationPackageByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createCollaborationPackageStart: (state: any, action) => {
      state.loading = true
    },
    createCollaborationPackageSuccess: (state: any, action) => {
      state.loading = false
    },
    createCollaborationPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteCollaborationPackageStart: (state: any, action) => {
      state.loading = true
    },
    deleteCollaborationPackageSuccess: (state: any, action) => {
      state.loading = false
      state.collaborationPackageList.data =
        state.collaborationPackageList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    deleteCollaborationPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editCollaborationPackageStart: (state: any, action) => {
      state.loading = true
    },
    editCollaborationPackageSuccess: (state: any, action) => {
      state.loading = false
    },
    editCollaborationPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchCollaborationPackageStart,
  fetchCollaborationPackageSuccess,
  fetchCollaborationPackageFailure,
  createCollaborationPackageStart,
  createCollaborationPackageSuccess,
  createCollaborationPackageFailure,
  deleteCollaborationPackageStart,
  deleteCollaborationPackageSuccess,
  deleteCollaborationPackageFailure,
  fetchCollaborationPackageByIdStart,
  fetchCollaborationPackageByIdSuccess,
  fetchCollaborationPackageByIdFailure,
  editCollaborationPackageStart,
  editCollaborationPackageSuccess,
  editCollaborationPackageFailure,
} = collaborationPackageSlice.actions

export default collaborationPackageSlice.reducer
