// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'
export const fetchEoiCountryOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/countryOwner/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteEoiCountryOwner = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/countryOwner/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchEoiCountryOwnerById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/countryOwner/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectEoiCountryOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/countryOwner/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editEoiCountryOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/countryOwner/edit/${id}`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyEoiCountryOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(
        `/api/countryOwner/updateAndVerifyEOI/${id}`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveUpdateEoiCountryOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/countryOwner/saveUpdate/${id}`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCountryOwnerContact = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/countryOwner/deleteCountryOwnerContact/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
