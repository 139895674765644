/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { createPrizeDrawByPanelStart } from 'store/prizeDraw/prizeDrawSlice'
import { openAlert } from 'store/alert/alertSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  prizeDraw: state.prizeDraw,
})

const mapDispatchToProps = (dispatch) => ({
  onCreatePrizeDrawByPanelStart: (payload) =>
    dispatch(createPrizeDrawByPanelStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
