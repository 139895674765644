import { createSlice } from '@reduxjs/toolkit'

import { IAuthPasswordReset } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IAuthPasswordReset = {
  isAuthenticated: false,
  currentUser: {},
  newUser: {},
  error: null,
  loading: false,
}

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: INITIAL_STATE,
  reducers: {
    changePasswordStart: (state: any, action) => {
      state.loading = true
    },
    changePasswordSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    changePasswordFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
} = changePasswordSlice.actions

export default changePasswordSlice.reducer
