import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
const GlobalCollaborationPartnersList = React.lazy(
  () => import('./CollaborationPartnersList')
)

export const globalCPConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/collaborationpartnerlist',
    element: <GlobalCollaborationPartnersList />,
  },
]
