import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material'
import { useParams } from 'react-router-dom'

import container from './PackagesAndBankInfo.container'
import { Form, Formik } from 'formik'

import * as yup from 'yup'
import _ from 'lodash'
import { fixed2Decimal } from 'utils'
interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onCreateClubStart?: (params) => void
  onFetchMerchantPackageStart?: (params) => void
  onFetchMerchantGeneralBankInfoStart?: (params) => void
  onCheckApplyMerchantPremiumCodeStart?: (params) => void
  onFetchLastMerchantPackageLogByMerchantIdStart?: (params) => void
  clearMerchantPremiumCodeData?: () => void
  siteCoordinator?: any
  club?: any
  auth?: any
  merchantPackage?: any
  merchant?: any
  merchantId?: number
  adminRoute?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  merchantId,
  onCloseAddCountry,
  onFetchMerchantPackageStart,
  onFetchMerchantGeneralBankInfoStart,
  siteCoordinator: { globalCountry },
  merchantPackage: { loading, lastMerchantPackageLog },
  merchant: {
    isFetching,
    generalBankInfo: { result, merchantInfo, issuerCode },
  },
  clearMerchantPremiumCodeData,
  onCheckApplyMerchantPremiumCodeStart,
  onFetchLastMerchantPackageLogByMerchantIdStart,
}) => {
  const { id } = useParams()

  const [packageType, setPackageType] = useState<any>('premium')

  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  const [tempLoader, setTempLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  useEffect(() => {
    onFetchMerchantPackageStart({
      countryId: globalCountry?.id,
      isFree: false,
      merchantId,
    })
  }, [])

  useEffect(() => {
    onFetchMerchantGeneralBankInfoStart(merchantId)
    onFetchLastMerchantPackageLogByMerchantIdStart(merchantId)
  }, [merchantId])

  const closeModal = () => {
    onCloseAddCountry()
    setMultiplePersonApply(false)
  }
  const validationSchema = yup.object({
    stateId: yup.object().nullable().required('state required'),
    regionId: yup.object().nullable().required('region required'),
  })

  const submitPremiumCode = (data) => {
    onCheckApplyMerchantPremiumCodeStart({
      params: { merchantId: id, premiumCode: data },
    })
  }

  const checkForLastMerchantPackageLog = () => {
    if (!_.isEmpty(lastMerchantPackageLog)) {
      return (
        <Grid item xs={4}>
          <Card
            sx={{
              minWidth: 275,
              cursor: 'pointer',
              border: {
                border: '2px solid #00B59C',
                backgroundColor: '#00B59C',
                color: '#ffffff',
              },
              '&:hover': {
                border: '2px solid #00B59C',
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14, color: '#ffffff' }}
                color="text.secondary"
                gutterBottom
              >
                {lastMerchantPackageLog?.__merchantPackage__?.name}
              </Typography>
              {lastMerchantPackageLog?.discountedSetupFee ? (
                <Typography variant="body2">
                  Setup Fee -
                  {lastMerchantPackageLog?.__country__?.currencySymbol}
                  {lastMerchantPackageLog?.discountedSetupFee}
                  {(lastMerchantPackageLog?.taxationUnitValue / 100) *
                  lastMerchantPackageLog?.discountedSetupFee
                    ? `+ ${
                        lastMerchantPackageLog?.__country__?.currencySymbol
                      }${fixed2Decimal(
                        (lastMerchantPackageLog?.taxationUnitValue / 100) *
                          lastMerchantPackageLog?.discountedSetupFee
                      )} ${
                        lastMerchantPackageLog?.__country__?.gstDisplayText ??
                        ''
                      }`
                    : ''}
                </Typography>
              ) : (
                ''
              )}

              <Typography variant="body2">
                Package Fee -{' '}
                {lastMerchantPackageLog?.__country__?.currencySymbol}
                {lastMerchantPackageLog?.discountedPackageFee}
                {(lastMerchantPackageLog?.taxationUnitValue / 100) *
                lastMerchantPackageLog?.discountedPackageFee
                  ? `+ ${
                      lastMerchantPackageLog?.__country__?.currencySymbol
                    }${fixed2Decimal(
                      (lastMerchantPackageLog?.taxationUnitValue / 100) *
                        lastMerchantPackageLog?.discountedPackageFee
                    )} ${
                      lastMerchantPackageLog?.__country__?.gstDisplayText ?? ''
                    }`
                  : ''}
              </Typography>

              <Typography variant="body2">
                <strong>
                  Total Fee -{' '}
                  {lastMerchantPackageLog?.__country__?.currencySymbol}
                  {lastMerchantPackageLog?.discountedPackageFee +
                    (lastMerchantPackageLog?.taxationUnitValue / 100) *
                      lastMerchantPackageLog?.discountedPackageFee +
                    lastMerchantPackageLog?.discountedSetupFee +
                    (lastMerchantPackageLog?.taxationUnitValue / 100) *
                      lastMerchantPackageLog?.discountedSetupFee}{' '}
                </strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    } else if (
      _.isEmpty(lastMerchantPackageLog) &&
      !_.isEmpty(merchantInfo?.__merchantPackage__)
    ) {
      return (
        <Grid item xs={4}>
          <Card
            sx={{
              minWidth: 275,
              cursor: 'pointer',
              border: {
                border: '2px solid #00B59C',
                backgroundColor: '#00B59C',
                color: '#ffffff',
              },
              '&:hover': {
                border: '2px solid #00B59C',
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14, color: '#ffffff' }}
                color="text.secondary"
                gutterBottom
              >
                {merchantInfo?.__merchantPackage__?.name}
              </Typography>
              {merchantInfo?.__merchantPackage__?.setupFee ? (
                <Typography variant="body2">
                  Setup Fee -{merchantInfo?.__country__?.currencySymbol}
                  {merchantInfo?.__merchantPackage__?.setupFee}
                  {merchantInfo?.__merchantPackage__?.setupFeeGST
                    ? `+ ${merchantInfo?.__country__?.currencySymbol}${
                        merchantInfo?.__merchantPackage__?.setupFeeGST
                      } ${merchantInfo?.__country__?.gstDisplayText ?? ''}`
                    : ''}
                </Typography>
              ) : (
                ''
              )}

              <Typography variant="body2">
                Package Fee - {merchantInfo?.__country__?.currencySymbol}
                {merchantInfo?.__merchantPackage__?.packageFee}
                {merchantInfo?.__merchantPackage__?.packageFeeGST
                  ? `+ ${merchantInfo?.__country__?.currencySymbol}${
                      merchantInfo?.__merchantPackage__?.packageFeeGST
                    } ${merchantInfo?.__country__?.gstDisplayText ?? ''}`
                  : ''}
              </Typography>

              <Typography variant="body2">
                <strong>
                  Total Fee - {merchantInfo?.__country__?.currencySymbol}
                  {merchantInfo?.__merchantPackage__?.setupFee +
                    merchantInfo?.__merchantPackage__?.packageFee +
                    merchantInfo?.__merchantPackage__?.packageFeeGST +
                    merchantInfo?.__merchantPackage__?.setupFeeGST}
                </strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    } else {
      return (
        <Grid container justifyContent={'center'}>
          <Typography variant="h4">No Package Found </Typography>
        </Grid>
      )
    }
  }

  const checkTotal = () => {
    if (!_.isEmpty(lastMerchantPackageLog)) {
      return (
        <Typography variant="h5">
          Total: {lastMerchantPackageLog?.__country__?.currencySymbol}
          {lastMerchantPackageLog?.discountedPackageFee +
            (lastMerchantPackageLog?.taxationUnitValue / 100) *
              lastMerchantPackageLog?.discountedPackageFee +
            lastMerchantPackageLog?.discountedSetupFee +
            (lastMerchantPackageLog?.taxationUnitValue / 100) *
              lastMerchantPackageLog?.discountedSetupFee ===
            0 && lastMerchantPackageLog?.premiumCodeIsApplied
            ? `0 ${
                packageType?.toLowerCase() !== 'supporter' &&
                `(Premium Code Used)`
              }`
            : lastMerchantPackageLog?.discountedPackageFee +
              (lastMerchantPackageLog?.taxationUnitValue / 100) *
                lastMerchantPackageLog?.discountedPackageFee +
              lastMerchantPackageLog?.discountedSetupFee +
              (lastMerchantPackageLog?.taxationUnitValue / 100) *
                lastMerchantPackageLog?.discountedSetupFee}
        </Typography>
      )
    } else {
      return (
        <Typography variant="h5">
          Total: {merchantInfo?.__country__?.currencySymbol}
          {merchantInfo?.__merchantPackage__?.packageFee +
            merchantInfo?.__merchantPackage__?.setupFee +
            merchantInfo?.__merchantPackage__?.packageFeeGST +
            merchantInfo?.__merchantPackage__?.setupFeeGST}
        </Typography>
      )
    }
  }

  return (
    <>
      {tempLoader || isFetching ? (
        <CircularProgress size={16} />
      ) : (
        <Formik
          validateOnChange={true}
          initialValues={{
            countryId: globalCountry?.id,
            businessNumber: '',
            accountName: '',
            bankName: '',
            bankCode: '',
            branchCode: '',
            accountNumber: '',
            swiftCode: '',
            comments: '',
            reference: '',
            premiumCode: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true)

            setSubmitting(false)
          }}
          render={({ values }) => {
            if (values?.premiumCode?.length < 1) {
              clearMerchantPremiumCodeData()
            }
            return (
              <Form>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Selected Package</Typography>
                    </Grid>

                    <Grid item container xs={12} spacing={3}>
                      {tempLoader || loading ? (
                        <Grid container justifyContent={'center'}>
                          <CircularProgress size={20} />
                        </Grid>
                      ) : (
                        <>{checkForLastMerchantPackageLog()}</>
                      )}
                    </Grid>

                    {/* {packageType === 'premium' && (
                      <Grid item container spacing={3}>
                        <Grid item xs={12} lg={3}>
                          <AppTextField
                            placeholder={'Enter Premium Code If you have any'}
                            label={'Enter Premium Code If you have any'}
                            name="premiumCode"
                            variant="outlined"
                            required
                            sx={{
                              width: '100%',
                              '& .MuiInputBase-input': {
                                fontSize: 14,
                              },
                            }}
                            size="small"
                          />
                          {merchantCodeResponse && (
                            <Typography
                              variant="body2"
                              color={merchantCodeError ? 'error' : '#2e7d32'}
                              align="center"
                            >
                              {merchantCodeResponse}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Button
                            sx={{
                              color: '#ffffff',
                              display: 'flex',
                              background: '#00bfcd',
                              '&:hover': {
                                background: '#ec4785',
                              },
                              px: 5,
                            }}
                            type="button"
                            onClick={() =>
                              submitPremiumCode(values?.premiumCode)
                            }
                            disabled={isApplyingCode}
                          >
                            {isApplyingCode ? (
                              <CircularProgress size={20} />
                            ) : (
                              'Apply'
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    )} */}

                    <Grid item container spacing={3}>
                      <Grid item xs={12}>
                        {checkTotal()}
                      </Grid>
                      {/* {packageType === 'premium' ||
                      packageType === 'partner' ? (
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            Note*: Selected package requires filling up the bank
                            information.
                          </Typography>
                        </Grid>
                      ) : (
                        ''
                      )} */}
                    </Grid>

                    {merchantInfo?.__merchantPackage__?.getsIssuer ? (
                      <Grid container spacing={3} sx={{ mt: 4, ml: 2 }}>
                        <Grid item container>
                          <Typography variant="h4">
                            <strong>Bank Information</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Typography variant="h5">Bank Name</Typography>
                          <Typography variant="body2">
                            {result?.bankName ?? '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Typography variant="h5">BSB</Typography>
                          <Typography variant="body2">
                            {result?.BSB ?? '-'}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Typography variant="h5">Bank Code</Typography>
                          <Typography variant="body2">
                            {result?.bankCode ?? '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Typography variant="h5">Branch Code</Typography>
                          <Typography variant="body2">
                            {result?.branchCode ?? '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Typography variant="h5">Account Name</Typography>
                          <Typography variant="body2">
                            {result?.accountName ?? '-'}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Typography variant="h5">Account Number</Typography>
                          <Typography variant="body2">
                            {result?.accountNumber ?? '-'}
                          </Typography>
                        </Grid>

                        {/* <Grid item xs={12} lg={3}>
                          <Typography variant="h5">Business Number</Typography>
                          <Typography variant="body2">
                            {result?.businessNumber ?? '-'}
                          </Typography>
                        </Grid> */}

                        <Grid item xs={12} lg={3}>
                          <Typography variant="h5">Swift Code</Typography>
                          <Typography variant="body2">
                            {result?.swiftCode ?? '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>

                  <Grid container spacing={3} sx={{ mt: 4 }}>
                    <Grid item container>
                      <Typography variant="h4">QR Codes Information</Typography>
                    </Grid>
                    <Grid item container xs={12} lg={3} spacing={2}>
                      <Grid item container>
                        <Typography variant="h5">
                          Transaction QR Code
                        </Typography>
                      </Grid>
                      <Grid item container>
                        <Typography variant="body2">
                          {merchantInfo?.transactionCode ?? '-'}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} lg={3} spacing={2}>
                      <Grid item container>
                        <Typography variant="h5">Issuer QR Code</Typography>
                      </Grid>
                      <Grid item container>
                        <Typography variant="body2">
                          {issuerCode ?? '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid container spacing={3} sx={{ mt: 4 }}>
                    <Grid item container>
                      <Typography variant="h5">Card Number</Typography>
                    </Grid>
                    <Grid item container xs={12} lg={3}>
                      <AppTextField
                        placeholder={'Card Number'}
                        label={'Card Number'}
                        name="cardNumber"
                        required
                        variant="outlined"
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                      />
                    </Grid>
                  </Grid> */}

                  <Box sx={{ height: 20 }} />
                  <Box sx={{ height: 30 }} />
                </Box>
              </Form>
            )
          }}
        />
      )}
    </>
  )
}

export default container(AddNewClub)
