import React, { useEffect } from 'react'
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material'

import container from './EmailForm.container'
import { Form, Formik } from 'formik'

import * as yup from 'yup'
import { removeEmptyFields } from 'utils'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import { useNavigate } from 'react-router-dom'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onCreateClubStart?: (params) => void
  onCreateStep1DraftStart?: (params) => void
  onCreateStep1GeneralStart?: (params) => void
  onFetchMerchantBasicInfoPanelUserStart?: (params) => void
  onSendMerchantEmailStart?: (params) => void
  handleChangeTab?: any
  onClearRecentMerchant?: () => void
  siteCoordinator?: any
  club?: any
  merchant?: any
  auth?: any
  merchantId?: number
  adminRoute?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  onCloseAddCountry,

  onFetchMerchantBasicInfoPanelUserStart,
  onClearRecentMerchant,

  siteCoordinator: { globalCountry },
  merchant: { isCreating },
  merchantId,
  adminRoute,
  onSendMerchantEmailStart,
}) => {
  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  useEffect(() => {
    onFetchMerchantBasicInfoPanelUserStart(merchantId)
  }, [merchantId])

  const closeModal = () => {
    onCloseAddCountry()
    setMultiplePersonApply(false)
  }
  const validationSchema = yup.object({
    merchantPhoneNumber: yup
      .string()
      .min(7, 'Phone number should be at least 7 digits'),
    phoneNumber: yup
      .string()
      .min(7, 'Phone number should be at least 7 digits'),
  })

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        merchantName: '',
        merchantEmail: '',
        username: '',
        phonePrefix: '+1',
        phoneNumber: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        merchantPhonePrefix: '',
        merchantPhoneNumber: '',
        email: '',

        countryId: globalCountry?.id,
      }}
      validationSchema={validationSchema}
      onSubmit={(data: any, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onClearRecentMerchant()
        onSendMerchantEmailStart({
          params: removeEmptyFields({
            ...data,
          }),
          resetForm,
          navigate,
          route: `/${adminRoute}/draftList`,
        })

        setSubmitting(false)
      }}
      render={() => (
        <Form>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Main Information</Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <AppTextField
                  placeholder={'Merchant’s Trading Name'}
                  label={'Merchant’s Trading Name'}
                  name="merchantName"
                  variant="outlined"
                  required
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <AppTextField
                  placeholder={'User Name'}
                  label={'User Name'}
                  name="username"
                  variant="outlined"
                  required
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>
              <Grid item container direction="row" xs={12} lg={3} spacing={1}>
                <AppTextField
                  placeholder={'Phone Number'}
                  label={'Phone Number'}
                  type="number"
                  name="merchantPhoneNumber"
                  required
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <AppTextField
                  placeholder={'Merchant Email'}
                  label={'Merchant Email'}
                  name="merchantEmail"
                  variant="outlined"
                  required
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <Typography variant="h4" component="h2">
                  Contact Person Information
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <AppTextField
                  placeholder={'First Name'}
                  label={'First Name'}
                  name="contactPersonFirstName"
                  required
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <AppTextField
                  placeholder={'Last Name'}
                  label={'Last Name'}
                  name="contactPersonLastName"
                  required
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <AppTextField
                  placeholder={'Email'}
                  label={'Email'}
                  name="email"
                  required
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>

              <Grid item container direction="row" xs={12} lg={3} spacing={1}>
                <AppTextField
                  placeholder={'Phone Number'}
                  label={'Phone Number'}
                  type="number"
                  name="phoneNumber"
                  required
                  variant="outlined"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                  size="small"
                />
              </Grid>
            </Grid>

            <Box sx={{ height: 20 }} />
            <Box sx={{ height: 30 }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  color: '#ffffff',
                  display: 'flex',
                  background: '#00bfcd',
                  '&:hover': {
                    background: '#ec4785',
                  },
                  px: 5,
                }}
                type="submit"
                disabled={isCreating}
              >
                {isCreating ? <CircularProgress size={20} /> : 'Send'}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    />
  )
}

export default container(AddNewClub)
