import { createSlice } from '@reduxjs/toolkit'

import { IIssuerCodesState, IqrCodesState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IIssuerCodesState = {
  issuerBatchList: listObj,
  gmcIssuerCodeBatchList: listObj,
  gmcCodesIssuerByBatch: listObj,
  soleIssuerBatch: {},
  issuerQrCodesList: listObj,
  issuerQrCodesAssignableCountObj: {},
  issuerQrCodesAssignableCountGMCObj: {},
  soleIssuerQrCode: {},
  solePreviewIssuerCode: {},
  error: null,
  loading: false,
  isFetching: false,
  isDeleting: false,
  isAssigning: false,
  isGenerating: false,
  isFetchingIssuerCode: false,
  downloadStatus: 'start',
}

export const qrCodesSlice = createSlice({
  name: 'issuerQrCodes',
  initialState: INITIAL_STATE,
  reducers: {
    // issuer batch
    createIssuerBatchStart: (state: any, action) => {
      state.isGenerating = true
    },
    createIssuerBatchSuccess: (state: any, action) => {
      state.isGenerating = false
    },
    createIssuerBatchFailure: (state: any, action) => {
      state.isGenerating = false
      state.error = action.payload
    },

    editIssuerBatchStart: (state: any, action) => {
      state.isFetching = true
    },
    editIssuerBatchSuccess: (state: any, action) => {
      state.isFetching = true
    },
    editIssuerBatchFailure: (state: any, action) => {
      state.isFetching = true
      state.error = action.payload
    },

    createIssuerBatchCSVStart: (state: any, action) => {
      state.isFetching = true
    },
    createIssuerBatchCSVSuccess: (state: any, action) => {
      state.isFetching = false
    },
    createIssuerBatchCSVFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchIssuerBatchListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchIssuerBatchListSuccess: (state: any, action) => {
      state.issuerBatchList = action.payload
      state.isFetching = false
    },
    fetchIssuerBatchListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    // issuer qr codes
    fetchIssuerQrCodesListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchIssuerQrCodesListSuccess: (state: any, action) => {
      state.issuerQrCodesList = action.payload
      state.isFetching = false
    },
    fetchIssuerQrCodesListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchIssuerQrCodesListAllStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchIssuerQrCodesListAllSuccess: (state: any, action) => {
      state.issuerQrCodesList = action.payload
      state.isFetching = false
    },
    fetchIssuerQrCodesListAllFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchIssuerCodeAssignableCountForOthersStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchIssuerCodeAssignableCountForOthersSuccess: (state: any, action) => {
      state.issuerQrCodesAssignableCountObj = action.payload
      state.isFetching = false
    },
    fetchIssuerCodeAssignableCountForOthersFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchIssuerCodeAssignableCountForGMCStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchIssuerCodeAssignableCountForGMCSuccess: (state: any, action) => {
      state.issuerQrCodesAssignableCountGMCObj = action.payload
      state.isFetching = false
    },
    fetchIssuerCodeAssignableCountForGMCFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAssignerStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAssignerSuccess: (state: any, action) => {
      state.isFetching = false
      state.assignerList = action.payload
    },
    fetchAssignerFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createAssignerStart: (state: any, action) => {
      state.isAssigning = true
    },
    createAssignerSuccess: (state: any, action) => {
      state.isAssigning = false
    },
    createAssignerFailure: (state: any, action) => {
      state.isAssigning = false
      state.error = action.payload
    },
    createGMCAssignerStart: (state: any, action) => {
      state.isFetching = true
    },
    createGMCAssignerSuccess: (state: any, action) => {
      state.isFetching = false
    },
    createGMCAssignerFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchGmcCodesByBatchIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchGmcCodesByBatchIdSuccess: (state: any, action) => {
      state.gmcCodesByBatchIdList = action.payload
      state.isFetching = false
    },
    fetchGmcCodesByBatchIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchGmcIssuerCodeBatchStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchGmcIssuerCodeBatchSuccess: (state: any, action) => {
      state.gmcIssuerCodeBatchList = action.payload
      state.isFetching = false
    },
    fetchGmcIssuerCodeBatchFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAllGmcIssuerCodeByBatchStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllGmcIssuerCodeByBatchSuccess: (state: any, action) => {
      state.gmcCodesIssuerByBatch = action.payload
      state.isFetching = false
    },
    fetchAllGmcIssuerCodeByBatchFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createWhiteLabelIssuerCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    createWhiteLabelIssuerCodeSuccess: (state: any, action) => {
      state.isFetching = false
    },
    createWhiteLabelIssuerCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    deleteIssuerCodeBatchStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteIssuerCodeBatchSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteIssuerCodeBatchFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    deleteIssuerCodeStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteIssuerCodeSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteIssuerCodeFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    downloadIssuerCodeBatchStart: (state: any, action) => {
      state.downloadStatus = 'start'

      state.isDownloading = true
    },
    downloadIssuerCodeBatchSuccess: (state: any, action?: any) => {
      state.downloadStatus = action.payload ? action.payload : 'end'

      state.isDownloading = false
    },
    downloadIssuerCodeBatchFailure: (state: any, action) => {
      state.downloadStatus = 'fail'

      state.isDownloading = false
      state.error = action.payload
    },

    changeDownloadStatus: (state: any, action) => {
      state.downloadStatus = action.payload
    },

    fetchOneIssuerCodeBatchStart: (state: any, action) => {
      state.soleIssuerBatch = {}

      state.isFetching = true
    },
    fetchOneIssuerCodeBatchSuccess: (state: any, action) => {
      state.soleIssuerBatch = action.payload
      state.isFetching = false
    },
    fetchOneIssuerCodeBatchFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchOneIssuerQrCodePreviewStart: (state: any, action) => {
      state.solePreviewIssuerCode = {}

      state.isFetchingIssuerCode = true
    },
    fetchOneIssuerQrCodePreviewSuccess: (state: any, action) => {
      state.solePreviewIssuerCode = action.payload
      state.isFetchingIssuerCode = false
    },
    fetchOneIssuerQrCodePreviewFailure: (state: any, action) => {
      state.isFetchingIssuerCode = false
      state.error = action.payload
    },
  },
})

export const {
  // issuer batch
  fetchIssuerBatchListStart,
  fetchIssuerBatchListSuccess,
  fetchIssuerBatchListFailure,
  createIssuerBatchFailure,
  createIssuerBatchStart,
  createIssuerBatchSuccess,
  createIssuerBatchCSVStart,
  createIssuerBatchCSVSuccess,
  createIssuerBatchCSVFailure,
  editIssuerBatchStart,
  editIssuerBatchSuccess,
  editIssuerBatchFailure,

  // issuer qr codes
  fetchIssuerQrCodesListAllStart,
  fetchIssuerQrCodesListAllSuccess,
  fetchIssuerQrCodesListAllFailure,

  fetchIssuerCodeAssignableCountForOthersStart,
  fetchIssuerCodeAssignableCountForOthersSuccess,
  fetchIssuerCodeAssignableCountForOthersFailure,

  fetchIssuerCodeAssignableCountForGMCStart,
  fetchIssuerCodeAssignableCountForGMCSuccess,
  fetchIssuerCodeAssignableCountForGMCFailure,

  fetchAssignerFailure,
  fetchAssignerStart,
  fetchAssignerSuccess,
  createAssignerStart,
  createAssignerSuccess,
  createAssignerFailure,
  fetchIssuerQrCodesListStart,
  fetchIssuerQrCodesListSuccess,
  fetchIssuerQrCodesListFailure,
  createGMCAssignerFailure,
  createGMCAssignerStart,
  createGMCAssignerSuccess,

  createWhiteLabelIssuerCodeStart,
  createWhiteLabelIssuerCodeSuccess,
  createWhiteLabelIssuerCodeFailure,

  fetchGmcIssuerCodeBatchStart,
  fetchGmcIssuerCodeBatchSuccess,
  fetchGmcIssuerCodeBatchFailure,

  fetchAllGmcIssuerCodeByBatchStart,
  fetchAllGmcIssuerCodeByBatchSuccess,
  fetchAllGmcIssuerCodeByBatchFailure,

  deleteIssuerCodeBatchStart,
  deleteIssuerCodeBatchSuccess,
  deleteIssuerCodeBatchFailure,

  deleteIssuerCodeStart,
  deleteIssuerCodeSuccess,
  deleteIssuerCodeFailure,

  downloadIssuerCodeBatchStart,
  downloadIssuerCodeBatchSuccess,
  downloadIssuerCodeBatchFailure,

  fetchOneIssuerQrCodePreviewStart,
  fetchOneIssuerQrCodePreviewSuccess,
  fetchOneIssuerQrCodePreviewFailure,

  fetchOneIssuerCodeBatchStart,
  fetchOneIssuerCodeBatchSuccess,
  fetchOneIssuerCodeBatchFailure,

  changeDownloadStatus,
} = qrCodesSlice.actions

export default qrCodesSlice.reducer
