import React from 'react'
import AppCard from '@crema/core/AppCard'

import { Box, Grid, TextField, Button } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import StateSelect from 'components/DropdownComponents/StateSelect'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { removeEmptyFields } from 'utils'
import StatusSelect from 'components/DropdownComponents/StatusSelect'
import moment from 'moment-timezone'

const ListTop = ({ setFilterFormData, clearFilterForm, globalCountry }) => {
  const validationSchema = yup.object({
    name: yup.string(),
    areaId: yup.object().nullable(),
  })

  const onSubmitSearch = (data) => {
    const modifiedPremiumDate = data?.referredDate
      ? moment(new Date(data?.referredDate))
      : null

    setFilterFormData(
      removeEmptyFields({
        id: data?.id,
        stateId: data?.stateId?.id,
        merchantName__substring: data?.merchantName__substring,
        merchantEmail: data?.merchantEmail,
        merchantPhoneNumber: data?.merchantPhoneNumber,
        ...checkStatus(data?.registrationStatus?.name),
        ...checkMerchantStatus(data?.merchantStatus?.name),
        createdAt__between: modifiedPremiumDate
          ? `${modifiedPremiumDate.format('DD-MM-YYYY')}:${modifiedPremiumDate
              .add(1, 'days')
              .format('DD-MM-YYYY')}`
          : null,
        isTransferred: data?.isTransferred?.value,
      })
    )
  }

  const selectOption: any = [
    { label: 'Referral Sent', name: 'verified' },
    { label: 'Rejected', name: 'rejected' },
    { label: 'Pending', name: 'pending' },
    { label: 'Verified', name: 'verified' },
    // { label: 'Transferred', name: 'transferred' },
  ]

  const merchantSelectOption: any = [
    { label: 'Merchant Approved', name: 'merchantApproved' },
    { label: 'Merchant Pending', name: 'merchantPending' },
    { label: 'Referral Pending', name: 'referralPending' },
    // { label: 'Transferred', name: 'transferred' },
  ]

  const checkStatus = (status) => {
    if (status === 'verified') {
      return { isApproved: true, isRejected: false, isVerified: true }
    } else if (status === 'approved') {
      return { isApproved: true }
    } else if (status === 'rejected') {
      return { isRejected: true, isApproved: false, isVerified: false }
    } else if (status === 'transferred') {
      return { isTransferred: true }
    } else if (status === 'pending') {
      return { isApproved: false, isRejected: false, isVerified: false }
    }
  }

  const checkMerchantStatus = (status) => {
    if (status === 'referralPending') {
      return { isVerified: false, isApproved: false, isRejected: false }
    } else if (status === 'merchantApproved') {
      return { isVerified: true, isApproved: true, isRejected: false }
    } else if (status === 'merchantPending') {
      return { isRejected: false, isApproved: true, isVerified: false }
    }
  }

  const booleanOption: any = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  return (
    <>
      <AppCard>
        <Box>
          <Formik
            validateOnChange={true}
            initialValues={{
              stateId: '',
              merchantName__substring: '',
              merchantEmail: '',
              merchantPhoneNumber: '',
              createdAt: '',
              status: '',
              registrationStatus: null,
              merchantStatus: null,
              isTransferred: null,

              referredDate: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)
              onSubmitSearch(data)

              setSubmitting(false)
            }}
          >
            {({ resetForm, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={2}>
                    <Field
                      name="stateId"
                      component={StateSelect}
                      defaultSelected={globalCountry}
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'Name'}
                      label="Name"
                      name="merchantName__substring"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'Email'}
                      label="Email"
                      name="merchantEmail"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'Phone'}
                      label="Phone"
                      name="merchantPhoneNumber"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <Field
                      name="registrationStatus"
                      component={StatusSelect}
                      statusOption={selectOption}
                      label="Select Referral Status"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <Field
                      name="merchantStatus"
                      component={StatusSelect}
                      statusOption={merchantSelectOption}
                      label="Select Merchant Status"
                    />
                  </Grid>
                  {/* <Grid item xs={12} lg={2}>
                    <Field
                      name="agreementStatus"
                      component={AgreementStatusSelect}
                    />
                  </Grid> */}
                  <Grid item xs={12} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD-MM-YYYY"
                        label="Referred Date"
                        value={values?.referredDate}
                        onChange={(value: any) => {
                          setFieldValue('referredDate', value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            name="referredDate"
                            size="small"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <Field
                      name="isTransferred"
                      component={StatusSelect}
                      statusOption={booleanOption}
                      label="Is Transferred?"
                    />
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item>
                      <Button
                        size="small"
                        sx={{
                          px: 5,
                          color: '#ffffff',
                          backgroundColor: '#ec4785',
                          '&:hover': {
                            background: '#ec4785',
                          },
                        }}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        sx={{
                          px: 5,
                        }}
                        type="submit"
                        variant="outlined"
                        onClick={() => {
                          clearFilterForm()
                          resetForm()
                        }}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </AppCard>

      <Box sx={{ height: 30 }} />
    </>
  )
}

export default ListTop
