import { createSlice } from '@reduxjs/toolkit'

import { IMembershipPackage } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IMembershipPackage = {
  membershipPackageList: listObj,
  soleMembershipPackage: {},
  error: null,
  loading: false,
  isFetching: false,
  hasMoreData: false,
}

export const mermbershipPackageSlice = createSlice({
  name: 'membershipPackage',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMembershipPackageStart: (state: any, action) => {
      state.loading = true
    },
    fetchMembershipPackageSuccess: (state: any, action) => {
      state.membershipPackageList = action.payload
      state.loading = false
    },
    fetchMembershipPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMembershipPackageByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMembershipPackageByIdSuccess: (state: any, action) => {
      state.soleMembershipPackage = action.payload
      state.isFetching = false
    },
    fetchMembershipPackageByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createMembershipPackageStart: (state: any, action) => {
      state.loading = true
    },
    createMembershipPackageSuccess: (state: any, action) => {
      state.loading = false
      state.membershipPackageList.data = [
        ...state.membershipPackageList.data,
        action.payload,
      ]
    },
    createMembershipPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteMembershipPackageStart: (state: any, action) => {
      state.loading = true
    },
    deleteMembershipPackageSuccess: (state: any, action) => {
      state.loading = false
      state.membershipPackageList = state.membershipPackageList.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteMembershipPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editMembershipPackageStart: (state: any, action) => {
      state.loading = true
    },
    editMembershipPackageSuccess: (state: any, action) => {
      const result = state.membershipPackageList?.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.membershipPackageList.data = result

      state.loading = false
    },
    editMembershipPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchMembershipPackageStart,
  fetchMembershipPackageSuccess,
  fetchMembershipPackageFailure,
  createMembershipPackageStart,
  createMembershipPackageSuccess,
  createMembershipPackageFailure,
  deleteMembershipPackageStart,
  deleteMembershipPackageSuccess,
  deleteMembershipPackageFailure,
  fetchMembershipPackageByIdStart,
  fetchMembershipPackageByIdSuccess,
  fetchMembershipPackageByIdFailure,
  editMembershipPackageStart,
  editMembershipPackageSuccess,
  editMembershipPackageFailure,
} = mermbershipPackageSlice.actions

export default mermbershipPackageSlice.reducer
