import React, { useEffect } from 'react'
import { TextField, Autocomplete } from '@mui/material'
import { FieldProps, getIn } from 'formik'

import container from './StateSelect.container'
import _ from 'lodash'
import { usePrevious } from 'utils'

interface AddNewCountryProps {
  onFetchStateStart: (payload: any) => void
  dropDown: any
  setSelectedCountry: any
  defaultValue?: number
  selectedState?: any
  onChangeSelect?: any
  filterByCountryId?: any
  defaultSelected?: any
  isAssignedToOwner?: boolean
  makeDefaultEmpty?: boolean
  isModalOpen?: boolean
  onClearStateDropDown?: () => void
}

const RegionSelect: React.FC<FieldProps & AddNewCountryProps> = ({
  field,
  form,
  onFetchStateStart,
  setSelectedCountry,
  dropDown: { stateList },
  defaultValue,
  selectedState,
  defaultSelected,
  onChangeSelect,
  isAssignedToOwner,
  makeDefaultEmpty,
  filterByCountryId,
  onClearStateDropDown,
  isModalOpen,
  ...props
}) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  const useHasChanged = (val: any) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }

  const countryHasChanged = useHasChanged(form?.values?.countryId?.id)

  useEffect(() => {
    if (makeDefaultEmpty && !_.isEmpty(defaultSelected)) {
      onClearStateDropDown()
      if (isAssignedToOwner === false) {
        onFetchStateStart({
          limit: 100,
          countryId: defaultSelected?.id,
          isAssignedToOwner: false,
        })
      } else {
        onFetchStateStart({ limit: 100, countryId: defaultSelected?.id })
      }
    } else {
      onClearStateDropDown()
    }
  }, [defaultSelected, makeDefaultEmpty])

  useEffect(() => {
    if (!makeDefaultEmpty && !isModalOpen) {
      if (!_.isEmpty(filterByCountryId)) {
        onFetchStateStart({ limit: 100, countryId: filterByCountryId?.id })
      } else {
        onFetchStateStart({ limit: 100, countryId: defaultSelected?.id })
        // onFetchStateStart({ limit: 100 })
      }
    }
  }, [makeDefaultEmpty, filterByCountryId, isModalOpen])

  useEffect(() => {
    const { initialValues, values } = form
    if (initialValues?.countryId) {
      if (
        initialValues?.countryId?.id != values?.countryId?.id &&
        countryHasChanged
      ) {
        form.setFieldValue(field.name, null)
      }
    } else {
      if (countryHasChanged) {
        form.setFieldValue(field.name, null)
      }
    }
  }, [form?.values])

  return (
    <Autocomplete
      {...props}
      {...field}
      // disablePortal
      id="combo-box-demo"
      size="small"
      value={field.value}
      getOptionLabel={(option) => (option ? option.label : '')}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, value) => {
        form.setFieldValue(field.name, value)
      }}
      options={stateList}
      defaultValue={defaultValue ? defaultValue : null}
      // defaultValue={defaultCountry ? defaultCountry : {}}
      // isOptionEqualToValue={defaultCountry ? defaultCountry : null}
      // value={selectedState ? selectedState : null}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={errorText?.value || errorText}
          error={!!errorText}
          label="Select State / Province"
        />
      )}
    />
  )
}

export default container(RegionSelect)
