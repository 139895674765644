import React from 'react'
import { Box, Typography } from '@mui/material'

import AppDialog from '@crema/core/AppDialog'
import container from './ViewNotification.container'

interface AddGeneralSettingsProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onCreateGeneralSettingsStart?: (payload: any) => void
  onCreateGroupInformationListStart?: (payload: any) => void
  onFetchGroupInformationByIdStart?: (payload: any) => void
  onFetchNotificationByIdStart?: (payload: any) => void
  onEditGroupInformationListStart?: (payload: any) => void
  onEditNotificationStart?: (payload: any) => void
  onDeleteNotificationStart?: (payload: any) => void
  onNotificationCloseStart?: (payload: any) => void
  onCloseModal?: () => void
  globalSettings?: any
  refreshPageData?: any
  selectedState?: any
  auth?: any
  selectedObj?: any
  isEditStateOpen?: boolean
  openModal?: boolean
  selectedId?: number
  value?: string
  onCloseEditState?: () => void
}

const AddGeneralSettings: React.FC<AddGeneralSettingsProps> = ({
  onCloseAddCountry,
  refreshPageData,
  openModal,
  onCloseModal,
  onNotificationCloseStart,
  auth: {
    currentUser: { userId },
  },
  selectedObj,
  value,
}) => {
  // useEffect(() => {
  //   onNotificationCloseStart({
  //     notificationForPanelId: selectedObj?.notificationForPanelId,
  //     userType: selectedObj?.userType,
  //     id: selectedObj?.id,
  //     userId,
  //   })
  // }, [selectedObj])

  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={openModal}
      onClose={() => {
        onCloseModal()
        if (value !== '1') {
          onNotificationCloseStart({
            notificationForPanelId: selectedObj?.notificationForPanelId,
            userType: selectedObj?.userType,
            id: selectedObj?.id,
            userId,
            refreshPageData,
          })
        }
      }}
      title="View Notification"
    >
      <>
        <Box
          bgcolor={selectedObj?.__notificationForPanel__?.backgroundColor}
          sx={{ borderRadius: '7px', padding: 4 }}
        >
          <Typography color={selectedObj?.__notificationForPanel__?.textColor}>
            {selectedObj?.__notificationForPanel__?.title ?? ''}
          </Typography>
          <Typography
            color={selectedObj?.__notificationForPanel__?.textColor}
            dangerouslySetInnerHTML={{
              __html: selectedObj?.__notificationForPanel__?.content,
            }}
            sx={{ ml: 4, p: 3 }}
            variant="body1"
          />
        </Box>
      </>
    </AppDialog>
  )
}

export default container(AddGeneralSettings)
