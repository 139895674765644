import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Visibility } from '@mui/icons-material'
import { Tooltip, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment-timezone'
import MarkedAsModal from '@crema/core/AppConfirmDialog'

import ViewNotification from '../ViewNotification'
import _ from 'lodash'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: '10px 20px 10px 10px',
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  count: number
  page: any
  pageNumber: number
  value?: string
  rowsPerPage: any
  refreshPageData?: any
  currentUser?: any
  setSelectedObj?: any
  selectedObj?: any
  onDeleteGroupInformationListStart?: (payload?: any) => void
  onChangeNotificationOnOrOffStart?: (payload?: any) => void
  onDeleteNotificationStart?: (payload?: any) => void
  OnMarkNotificationAsUnseenStart?: (payload?: any) => void
}

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  pageNumber,
  rowsPerPage,
  refreshPageData,

  selectedObj,
  setSelectedObj,
  value,
  OnMarkNotificationAsUnseenStart,
}) => {
  const [isViewNotificationModalOpen, setIsViewNotificationModalOpen] =
    useState<boolean>(false)
  const [openMarkedAsModal, setIsOpenMarkedAsModal] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<number>()

  const onCloseNotificationModal = () => {
    setIsViewNotificationModalOpen(false)
  }

  const onMarkNotificationAsUnseen = () => {
    setIsOpenMarkedAsModal(false)
  }

  const onMarkSelectedNotification = () => {
    OnMarkNotificationAsUnseenStart({
      id: selectedObj?.id,
      closeModal: onMarkNotificationAsUnseen,
    })
  }

  return (
    <>
      {isViewNotificationModalOpen && (
        <ViewNotification
          openModal={isViewNotificationModalOpen}
          onCloseModal={onCloseNotificationModal}
          selectedId={deleteId}
          selectedObj={selectedObj}
          refreshPageData={refreshPageData}
          value={value}
        />
      )}

      {openMarkedAsModal && (
        <MarkedAsModal
          open={openMarkedAsModal}
          onDeny={setIsOpenMarkedAsModal}
          onConfirm={onMarkSelectedNotification}
          title="Are you sure you want to mark this notification as unseen?"
          dialogTitle="Mark As Unseen"
        />
      )}

      <TableRow key={data.sn} className="item-hover">
        <StyledTableCell align="left">
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.__notificationForPanel__?.title ?? '-'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.__notificationForPanel__?.createdAt
            ? moment(data?.__notificationForPanel__?.createdAt).format(
                'DD-MM-YYYY'
              )
            : '-'}
        </StyledTableCell>

        <StyledTableCell
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          align="left"
        >
          <Tooltip placement="top" title="View">
            <IconButton
              sx={{ fontWeight: '15px' }}
              onClick={() => {
                setSelectedObj(data)
                setIsViewNotificationModalOpen(true)
              }}
            >
              <Visibility sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>
          {/* {value === '1' && (
            <Tooltip placement="top" title="Mark As Unseen">
              <IconButton
                sx={{ fontWeight: '15px' }}
                onClick={() => {
                  setSelectedObj(data)
                  setIsOpenMarkedAsModal(true)
                }}
              >
                <HighlightOffIcon sx={{ fontSize: '1.3rem' }} />
              </IconButton>
            </Tooltip>
          )} */}
        </StyledTableCell>
      </TableRow>
    </>
  )
}

export default TableItem
