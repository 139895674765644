import { axiosRequestCMS } from 'utils'

export const fetchCountryList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/country/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/country/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCountry = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/country/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCountry = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/country/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCountry = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/country/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const changeStatusCountry = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/country/isPublished/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const translateIntoCountryLanguage = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/country/translatelang`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
