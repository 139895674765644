import { createSlice } from '@reduxjs/toolkit'

import { IDropDownState } from '../interfaces'

const INITIAL_STATE: IDropDownState = {
  countryList: [],
  regionList: [],
  stateList: [],
  charitiesList: [],
  whiteLabelList: [],
  terminalListByPanel: [],
  whiteLabelPostionList: [],
  whiteLabelPostionListById: [],
  whiteLabelPostionIntroducerList: [],
  whiteLabelPromotionalLevelList: [],
  unFilteredWhiteLabelPromotionalLevelList: [],
  whiteLabelUserByIdList: [],
  merchantCategoryList: [],
  merchantSubCategoryList: [],
  merchantCategoryParentList: [],
  merchantPackageList: [],
  transactionQrCodeList: [],
  unUsedTransactionQrCodeList: [],
  issuerQrCodeList: [],
  unUsedIssuerQrCodeList: [],
  unUsedPremiumCodeByAdminList: [],
  unUsedPremiumCodeByPanelList: [],
  countryPrefixList: [],
  countryCurrencySymbolList: [],
  areaList: [],
  notUsedIssuerQrCodeForAdmin: [],
  issuerQrCodesList: [],
  constantList: [],
  merchantTerminalList: [],
  merchantFranchisorList: [],
  languageCodeList: [],
  trainingFolderList: [],
  groupList: [],
  documentList: [],
  creatorList: [],
  contactList: [],
  languageList: [],
  merchantPackageForPremiumCodeList: [],
  error: null,
  loading: false,
  hasMoreData: false,
}

export const dropDownSlice = createSlice({
  name: 'dropDown',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCountryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountrySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.countryList = action.payload.map((item) => {
        return {
          ...item,
          label: item.countryName,
        }
      })
      state.loading = false
    },
    fetchCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearCountryDropDown: (state: any) => {
      state.countryList = []
    },

    clearMerchantPackageDropDown: (state: any) => {
      state.merchantPackageList = []
    },

    fetchRegionStart: (state: any, action) => {
      state.loading = true
    },
    fetchRegionSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.regionList = action.payload.map((item) => {
        return {
          ...item,
          label: item.regionName,
        }
      })
      state.loading = false
    },
    fetchRegionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearRegionDropDown: (state: any) => {
      state.regionList = []
    },

    fetchStateStart: (state: any, action) => {
      state.loading = true
    },
    fetchStateSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.stateList = action.payload.map((item) => {
        return {
          ...item,
          label: item.stateName,
        }
      })
      state.loading = false
    },
    fetchStateFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearStateDropDown: (state: any) => {
      state.stateList = []
    },

    fetchAreaStart: (state: any, action) => {
      state.loading = true
    },
    fetchAreaSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.areaList = action.payload.map((item) => {
        return {
          ...item,
          label: item.areaName,
        }
      })
      state.loading = false
    },
    fetchAreaFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearAreaDropDown: (state: any) => {
      state.areaList = []
    },

    fetchWhiteLabelStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.whiteLabelList = action.payload.map((item) => {
        return {
          ...item,
          label: item.companyName,
        }
      })
      state.loading = false
    },
    fetchWhiteLabelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearWhiteLabelDropDown: (state: any) => {
      state.whiteLabelList = []
    },

    fetchAllUniqueWhiteLabelPositionStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllUniqueWhiteLabelPositionSuccess: (state: any, action) => {
      // state.currentUser= action.payload;

      const orderedData = action?.payload?.sort(
        (a, b) => a?.position - b?.position
      )

      state.whiteLabelPostionList = orderedData.map((item) => {
        return {
          ...item,
          label: `Level ${item?.position} (${item?.positionName})`,
        }
      })
      state.loading = false
    },
    fetchAllUniqueWhiteLabelPositionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllUniqueWhiteLabelPositionByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllUniqueWhiteLabelPositionByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;

      const orderedData = action?.payload?.sort(
        (a, b) => a?.position - b?.position
      )

      state.whiteLabelPostionListById = orderedData.map((item) => {
        return {
          ...item,
          label: `Level ${item?.position} (${item?.positionName})`,
        }
      })
      state.loading = false
    },
    fetchAllUniqueWhiteLabelPositionByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllUniqueWhiteLabelPositionGAStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllUniqueWhiteLabelPositionGASuccess: (state: any, action) => {
      // state.currentUser= action.payload;

      const orderedData = action?.payload?.sort(
        (a, b) => a?.position - b?.position
      )

      state.whiteLabelPostionIntroducerList = orderedData
        .filter((item) => item.isIntroducer)
        .map((item) => {
          return {
            ...item,
            label: `Level ${item?.position} (${item?.positionName})`,
          }
        })
      state.loading = false
    },
    fetchAllUniqueWhiteLabelPositionGAFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelUserByParentIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelUserByParentIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.whiteLabelUserByIdList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.username,
        }
      })
      state.loading = false
    },
    fetchWhiteLabelUserByParentIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelPromotionalLevelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelPromotionalLevelSuccess: (state: any, action) => {
      state.isFetching = false
      const filteredWhiteLabelPosition = action.payload.filter(
        (item) => item?.promotionLevel !== 0
      )
      state.unFilteredWhiteLabelPromotionalLevelList = action.payload.map(
        (item) => {
          return {
            ...item,
            label:
              'Level ' +
              item?.promotionLevel +
              ` (Allocated % - ${item?.sharePercentage})`,
          }
        }
      )
      state.whiteLabelPromotionalLevelList = filteredWhiteLabelPosition.map(
        (item) => {
          return {
            ...item,
            label:
              'Level ' +
              item?.promotionLevel +
              ` (Allocated % - ${item?.sharePercentage})`,
          }
        }
      )
    },
    fetchWhiteLabelPromotionalLevelFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchCharityStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.charitiesList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.charityName,
        }
      })
      state.isFetching = false
    },
    fetchCharityFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantCategoryStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantCategorySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      const sortedCategoryList = action?.payload?.sort((a, b) =>
        a?.name?.localeCompare(b?.name)
      )

      state.merchantCategoryList = sortedCategoryList?.map((item) => {
        return {
          ...item,
          label: item?.name,
        }
      })
      state.isFetching = false
    },
    fetchMerchantCategoryFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantSubCategoryStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantSubCategorySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.merchantSubCategoryList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.name,
        }
      })
      state.isFetching = false
    },
    fetchMerchantSubCategoryFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    clearMerchantSubCategoryDropDown: (state: any) => {
      state.merchantSubCategoryList = []
    },
    fetchMerchantCategoryParentStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantCategoryParentSuccess: (state: any, action) => {
      state.merchantCategoryParentList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.name,
        }
      })
      state.isFetching = false
    },
    fetchMerchantCategoryParentFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantPackageStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantPackageSuccess: (state: any, action) => {
      state.merchantPackageList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.name,
        }
      })
      state.loading = false
    },
    fetchMerchantPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantPackageDropDownStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantPackageDropDownSuccess: (state: any, action) => {
      state.merchantPackageList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.name,
        }
      })
      state.loading = false
    },
    fetchMerchantPackageDropDownFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryPrefixStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryPrefixSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.countryPrefixList = action.payload.map((item) => {
        return {
          ...item,
          label: item.phonePrefix,
        }
      })
      state.loading = false
    },
    fetchCountryPrefixFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchTransactionQrCodeStart: (state: any, action) => {
      state.loading = true
    },
    fetchTransactionQrCodeSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.transactionQrCodeList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.transactionCode,
        }
      })
      state.loading = false
    },
    fetchTransactionQrCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchIssuerQrCodeStart: (state: any, action) => {
      state.loading = true
    },
    fetchIssuerQrCodeSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.issuerQrCodeList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.issuerCode,
        }
      })
      state.loading = false
    },
    fetchIssuerQrCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchIssuerQrCodesListAllStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchIssuerQrCodesListAllSuccess: (state: any, action) => {
      state.issuerQrCodesList = action.payload
      state.isFetching = false
    },
    fetchIssuerQrCodesListAllFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchUnusedTransactionQrCodeStart: (state: any, action) => {
      state.loading = true
    },
    fetchUnusedTransactionQrCodeSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.unUsedTransactionQrCodeList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.transactionCode,
        }
      })
      state.loading = false
    },
    fetchUnusedTransactionQrCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchUnusedIssuerQrCodeStart: (state: any, action) => {
      state.loading = true
    },
    fetchUnusedIssuerQrCodeSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.unUsedIssuerQrCodeList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.issuerCode,
        }
      })
      state.loading = false
    },
    fetchUnusedIssuerQrCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchUnusedPremiumCodeByAdminStart: (state: any, action) => {
      state.loading = true
    },
    fetchUnusedPremiumCodeByAdminSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.unUsedPremiumCodeByAdminList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.merchantPremiumCode,
        }
      })
      state.loading = false
    },
    fetchUnusedPremiumCodeByAdminFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchUnusedPremiumCodeByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchUnusedPremiumCodeByPanelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.unUsedPremiumCodeByPanelList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.merchantPremiumCode,
        }
      })
      state.loading = false
    },
    fetchUnusedPremiumCodeByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchNotUsedIssuerQrCodeForAdminStart: (state: any, action) => {
      state.loading = true
    },
    fetchNotUsedIssuerQrCodeForAdminSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.notUsedIssuerQrCodeForAdmin = action.payload.map((item) => {
        return {
          ...item,
          label: item?.issuerCode,
        }
      })
      state.loading = false
    },
    fetchNotUsedIssuerQrCodeForAdminFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllTerminalListByPanelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllTerminalListByPanelSuccess: (state: any, action) => {
      state.terminalListByPanel = action.payload.map((item) => {
        return {
          ...item,
          label: item?.terminalName,
        }
      })
      state.isFetching = false
    },
    fetchAllTerminalListByPanelFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchPageStart: (state: any, action) => {
      state.loading = true
    },
    fetchPageSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pageList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item.title}`,
        }
      })
      state.loading = false
    },
    fetchPageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchParentStart: (state: any, action) => {
      state.loading = true
    },
    fetchParentSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.parentList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item.name}`,
        }
      })
      state.loading = false
    },
    fetchParentFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchLanguageStart: (state: any, action) => {
      state.loading = true
    },
    fetchLanguageSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.languageList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item.countryName} (${item.lang})`,
        }
      })
      state.loading = false
    },
    fetchLanguageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchLanguageForOtherPanelsStart: (state: any, action) => {
      state.loading = true
    },

    fetchLanguageForOtherPanelsSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.languageList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item.countryName} (${item.lang})`,
        }
      })
      state.loading = false
    },

    fetchLanguageForOtherPanelsFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllMerchantTerminalListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllMerchantTerminalListSuccess: (state: any, action) => {
      state.merchantTerminalList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item?.firstName} (${item?.lastName})`,
        }
      })
      // state.merchantTerminalList = action.payload
      state.isFetching = false
    },
    fetchAllMerchantTerminalListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAllMerchantGroupStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllMerchantGroupSuccess: (state: any, action) => {
      state.merchantFranchisorList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item?.merchantName}`,
        }
      })
      state.isFetching = false
    },
    fetchAllMerchantGroupFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    clearLanguageDropDown: (state: any) => {
      state.languageList = []
    },

    fetchConstantStart: (state: any, action) => {
      state.loading = true
    },
    fetchConstantSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.constantList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.name,
        }
      })
      state.loading = false
    },
    fetchConstantFailure: (state: any, action) => {
      state.constant = false
      state.error = action.payload
    },

    fetchLanguageCodeStart: (state: any, action) => {
      state.loading = true
    },
    fetchLanguageCodeSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.languageCodeList = action.payload.map((item) => {
        return {
          ...item,

          label: `${item.name} (${item.code})`,
        }
      })
      state.loading = false
    },
    fetchLanguageCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchTrainingFolderStart: (state: any, action) => {
      state.loading = true
    },
    fetchTrainingFolderSuccess: (state: any, action) => {
      state.loading = false

      state.trainingFolderList = action.payload.map((item) => {
        return {
          ...item,

          label: `${item.name}`,
        }
      })
    },
    fetchTrainingFolderFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTrainingFolderForOtherPanelsStart: (state: any, action) => {
      state.loading = true
    },
    fetchTrainingFolderForOtherPanelsSuccess: (state: any, action) => {
      state.loading = false

      state.trainingFolderList = action.payload.map((item) => {
        return {
          ...item,

          label: `${item.name}`,
        }
      })
    },
    fetchTrainingFolderForOtherPanelsFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAllGroupListStart: (state: any, action?: any) => {
      state.isFetching = true
    },
    fetchAllGroupListSuccess: (state: any, action) => {
      state.groupList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.name,
          selected: false,
        }
      })
      state.isFetching = false
    },
    fetchAllGroupListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    clearGroupListDropDown: (state: any) => {
      state.groupList = []
    },

    fetchAllGroupListByPanelStart: (state: any, action?: any) => {
      state.isFetching = true
    },
    fetchAllGroupListByPanelSuccess: (state: any, action) => {
      state.groupList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.name,
        }
      })
      state.isFetching = false
    },
    fetchAllGroupListByPanelFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchContactListStart: (state: any, action?: any) => {
      state.loading = true
    },
    fetchContactListSuccess: (state: any, action) => {
      state.contactList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item?.firstName} ${item?.lastName}`,
        }
      })
      state.loading = false
    },
    fetchContactListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGrouplessContactListStart: (state: any, action?: any) => {
      state.loading = true
    },
    fetchGrouplessContactListSuccess: (state: any, action) => {
      state.contactList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item?.firstName} ${item?.lastName}`,
        }
      })
      state.loading = false
    },
    fetchGrouplessContactListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearContactListDropDown: (state: any) => {
      state.contactList = []
    },

    fetchGrouplessContactListByPanelStart: (state: any, action?: any) => {
      state.loading = true
    },
    fetchGrouplessContactListByPanelSuccess: (state: any, action) => {
      state.contactList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item?.firstName} ${item?.lastName}`,
        }
      })
      state.loading = false
    },
    fetchGrouplessContactListByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchDocumentListStart: (state: any, action) => {
      state.loading = true
    },
    fetchDocumentListSuccess: (state: any, action) => {
      state.documentList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.documentTitle,
        }
      })
      state.loading = false
    },
    fetchDocumentListFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearDocumentListDropDown: (state: any) => {
      state.documentList = []
    },

    fetchDocumentListByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchDocumentListByPanelSuccess: (state: any, action) => {
      state.documentList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.documentTitle,
        }
      })
      state.loading = false
    },
    fetchDocumentListByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCreatorNameStart: (state: any, action) => {
      state.loading = true
    },
    fetchCreatorNameSuccess: (state: any, action) => {
      state.creatorList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.name,
        }
      })
      state.loading = false
    },
    fetchCreatorNameFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPayoutContactNameStart: (state: any, action) => {
      state.loading = true
    },
    fetchPayoutContactNameSuccess: (state: any, action) => {
      state.contactList = action.payload.map((item) => {
        return {
          ...item,
          label: item?.name,
        }
      })
      state.loading = false
    },
    fetchPayoutContactNameFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantPackageForPremiumCodeStart: (state: any, action?: any) => {
      state.merchantPackageForPremiumCodeList = []
      state.loading = true
    },
    fetchMerchantPackageForPremiumCodeSuccess: (state: any, action) => {
      state.merchantPackageForPremiumCodeList = action.payload.map((item) => {
        return {
          ...item,
          label: `${item?.name}`,
        }
      })
      state.loading = false
    },
    fetchMerchantPackageForPremiumCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearMerchantPackageForPremiumCode: (state: any) => {
      state.merchantPackageForPremiumCodeList = []
    },
    fetchCountryCurrencySymbolStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryCurrencySymbolSuccess: (state: any, action) => {
      state.countryCurrencySymbolList = action.payload.map((item) => {
        return {
          ...item,
          label: item.currencySymbol,
        }
      })
      state.loading = false
    },
    fetchCountryCurrencySymbolFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearCreatorList: (state: any, action) => {
      state.creatorList = []
    },

    clearConstantDropDown: (state: any) => {
      state.constantList = []
    },
  },
})

export const {
  fetchCountryStart,
  fetchCountrySuccess,
  fetchCountryFailure,
  fetchRegionStart,
  fetchRegionSuccess,
  fetchRegionFailure,
  fetchStateStart,
  fetchStateSuccess,
  fetchStateFailure,
  fetchAreaStart,
  fetchAreaSuccess,
  fetchAreaFailure,
  clearCountryDropDown,
  clearRegionDropDown,
  clearStateDropDown,
  clearAreaDropDown,
  fetchWhiteLabelStart,
  fetchWhiteLabelSuccess,
  fetchWhiteLabelFailure,
  fetchAllUniqueWhiteLabelPositionStart,
  fetchAllUniqueWhiteLabelPositionSuccess,
  fetchAllUniqueWhiteLabelPositionFailure,
  fetchWhiteLabelUserByParentIdStart,
  fetchWhiteLabelUserByParentIdSuccess,
  fetchWhiteLabelUserByParentIdFailure,
  fetchWhiteLabelPromotionalLevelStart,
  fetchWhiteLabelPromotionalLevelSuccess,
  fetchWhiteLabelPromotionalLevelFailure,
  fetchAllUniqueWhiteLabelPositionGAStart,
  fetchAllUniqueWhiteLabelPositionGASuccess,
  fetchAllUniqueWhiteLabelPositionGAFailure,
  fetchCharityStart,
  fetchCharitySuccess,
  fetchCharityFailure,
  fetchMerchantCategoryStart,
  fetchMerchantCategorySuccess,
  fetchMerchantCategoryFailure,
  fetchMerchantSubCategoryStart,
  fetchMerchantSubCategorySuccess,
  fetchMerchantSubCategoryFailure,
  clearMerchantSubCategoryDropDown,
  fetchMerchantCategoryParentStart,
  fetchMerchantCategoryParentSuccess,
  fetchMerchantCategoryParentFailure,
  fetchMerchantPackageStart,
  fetchMerchantPackageSuccess,
  fetchMerchantPackageFailure,

  clearMerchantPackageDropDown,

  fetchAllUniqueWhiteLabelPositionByIdStart,
  fetchAllUniqueWhiteLabelPositionByIdSuccess,
  fetchAllUniqueWhiteLabelPositionByIdFailure,

  fetchCountryPrefixStart,
  fetchCountryPrefixSuccess,
  fetchCountryPrefixFailure,

  fetchTransactionQrCodeStart,
  fetchTransactionQrCodeSuccess,
  fetchTransactionQrCodeFailure,

  fetchUnusedTransactionQrCodeStart,
  fetchUnusedTransactionQrCodeSuccess,
  fetchUnusedTransactionQrCodeFailure,

  fetchUnusedIssuerQrCodeStart,
  fetchUnusedIssuerQrCodeSuccess,
  fetchUnusedIssuerQrCodeFailure,

  fetchUnusedPremiumCodeByAdminStart,
  fetchUnusedPremiumCodeByAdminSuccess,
  fetchUnusedPremiumCodeByAdminFailure,

  fetchUnusedPremiumCodeByPanelStart,
  fetchUnusedPremiumCodeByPanelSuccess,
  fetchUnusedPremiumCodeByPanelFailure,

  fetchIssuerQrCodeStart,
  fetchIssuerQrCodeSuccess,
  fetchIssuerQrCodeFailure,

  fetchNotUsedIssuerQrCodeForAdminStart,
  fetchNotUsedIssuerQrCodeForAdminSuccess,
  fetchNotUsedIssuerQrCodeForAdminFailure,

  fetchAllTerminalListByPanelStart,
  fetchAllTerminalListByPanelSuccess,
  fetchAllTerminalListByPanelFailure,

  fetchAllMerchantTerminalListStart,
  fetchAllMerchantTerminalListSuccess,
  fetchAllMerchantTerminalListFailure,

  fetchIssuerQrCodesListAllStart,
  fetchIssuerQrCodesListAllSuccess,
  fetchIssuerQrCodesListAllFailure,

  fetchPageStart,
  fetchPageSuccess,
  fetchPageFailure,

  fetchParentStart,
  fetchParentSuccess,
  fetchParentFailure,

  fetchLanguageStart,
  fetchLanguageSuccess,
  fetchLanguageFailure,

  fetchLanguageForOtherPanelsStart,
  fetchLanguageForOtherPanelsSuccess,
  fetchLanguageForOtherPanelsFailure,

  fetchConstantStart,
  fetchConstantSuccess,
  fetchConstantFailure,

  fetchAllMerchantGroupStart,
  fetchAllMerchantGroupSuccess,
  fetchAllMerchantGroupFailure,

  fetchLanguageCodeStart,
  fetchLanguageCodeSuccess,
  fetchLanguageCodeFailure,

  fetchTrainingFolderStart,
  fetchTrainingFolderSuccess,
  fetchTrainingFolderFailure,

  fetchTrainingFolderForOtherPanelsStart,
  fetchTrainingFolderForOtherPanelsSuccess,
  fetchTrainingFolderForOtherPanelsFailure,

  fetchAllGroupListStart,
  fetchAllGroupListSuccess,
  fetchAllGroupListFailure,

  clearGroupListDropDown,

  fetchAllGroupListByPanelStart,
  fetchAllGroupListByPanelSuccess,
  fetchAllGroupListByPanelFailure,

  fetchContactListStart,
  fetchContactListSuccess,
  fetchContactListFailure,

  fetchDocumentListStart,
  fetchDocumentListSuccess,
  fetchDocumentListFailure,

  clearDocumentListDropDown,

  fetchMerchantPackageDropDownStart,
  fetchMerchantPackageDropDownSuccess,
  fetchMerchantPackageDropDownFailure,

  fetchGrouplessContactListStart,
  fetchGrouplessContactListSuccess,
  fetchGrouplessContactListFailure,

  clearContactListDropDown,

  fetchGrouplessContactListByPanelStart,
  fetchGrouplessContactListByPanelSuccess,
  fetchGrouplessContactListByPanelFailure,

  clearWhiteLabelDropDown,

  fetchDocumentListByPanelStart,
  fetchDocumentListByPanelSuccess,
  fetchDocumentListByPanelFailure,

  fetchPayoutContactNameStart,
  fetchPayoutContactNameSuccess,
  fetchPayoutContactNameFailure,

  fetchCreatorNameStart,
  fetchCreatorNameSuccess,
  fetchCreatorNameFailure,

  fetchMerchantPackageForPremiumCodeStart,
  fetchMerchantPackageForPremiumCodeSuccess,
  fetchMerchantPackageForPremiumCodeFailure,

  clearMerchantPackageForPremiumCode,
  fetchCountryCurrencySymbolStart,
  fetchCountryCurrencySymbolSuccess,
  fetchCountryCurrencySymbolFailure,

  clearCreatorList,
} = dropDownSlice.actions

export default dropDownSlice.reducer
