import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableHeading from './Heading'
import TableItem from './Item'
import {
  TablePagination,
  Box,
  Button,
  TableRow,
  TableCell,
  CircularProgress,
} from '@mui/material'
import AppTableContainer from '@crema/core/AppTableContainer'
import TableDataNotFound from 'components/TableDataNotFound'

import { StateSetup } from 'types/models/StateSetup'
import { Payload } from 'recharts/types/component/DefaultLegendContent'

interface prizeDrawTableProps {
  generalSettingsData: any[]
  page: any
  pageNumber: number
  totalCount: any
  loadingTable: any
  setPage: any
  rowsPerPage: any
  setRowsPerPage: any
  handleChangePage: any
  handleChangeRowsPerPage: any
  refreshPageData?: any
  currentUser?: any
  onDeleteDocumentStart?: (payload?: any) => void
  onOpenAlert?: (payload?: any) => void
  hasMoreData: boolean
}

const PrizeDrawTable: React.FC<prizeDrawTableProps> = ({
  generalSettingsData,
  page,
  pageNumber,
  totalCount,
  loadingTable,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  hasMoreData,
  handleChangePage,
  handleChangeRowsPerPage,
  refreshPageData,
  onDeleteDocumentStart,
  onOpenAlert,
  currentUser,
}) => {
  return (
    <AppTableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          nextIconButtonProps={{ disabled: !hasMoreData }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Table stickyHeader>
        <TableHead>
          <TableHeading currentUser={currentUser} />
        </TableHead>
        <TableBody>
          {loadingTable ? (
            <TableRow className="item-hover">
              <TableCell align="center" colSpan={8}>
                <CircularProgress size={20} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {generalSettingsData?.length ? (
                generalSettingsData.map((data, index) => (
                  <TableItem
                    data={data}
                    key={data.sn}
                    count={index + 1}
                    page={page}
                    pageNumber={pageNumber}
                    rowsPerPage={rowsPerPage}
                    refreshPageData={refreshPageData}
                    onDeleteDocumentStart={onDeleteDocumentStart}
                    onOpenAlert={onOpenAlert}
                    currentUser={currentUser}
                  />
                ))
              ) : (
                <TableDataNotFound colSpan={11} />
              )}
            </>
          )}
        </TableBody>
      </Table>
    </AppTableContainer>
  )
}

export default PrizeDrawTable
