import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'

const MerchantTemplatesSetup = React.lazy(
  () => import('./MerchantAgreementTemplate/')
)
const AddNewMerchantTemplate = React.lazy(
  () => import('./components/AddMerchantTemplatePage')
)
const EditMerchantTemplate = React.lazy(
  () => import('./components/EditMerchantTemplatePage')
)

export const globalAgreementTemplateConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/merchantagreement',
    element: <MerchantTemplatesSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/merchantagreement/createtemplate',
    element: <AddNewMerchantTemplate />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/merchantagreement/edittemplate',
    element: <EditMerchantTemplate />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/charityagreement',
    element: <MerchantTemplatesSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/clubagreement',
    element: <MerchantTemplatesSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/issueragreement',
    element: <MerchantTemplatesSetup />,
  },
]
