import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Tooltip,
} from '@mui/material'
import { Form, Formik, Field } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'

import container from './MerchantUpgrade.container'

import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import InfoIcon from '@mui/icons-material/Info'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import { fixed2Decimal } from 'utils'
import PremiumCodeByPanelSelect from 'components/DropdownComponents/PremiumCodeByPanelSelect'

interface AddNewCountryProps {
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  clearGeneralData?: () => void
  onCreateClubStart?: (params) => void
  siteCoordinator?: any
  merchantId?: number
  club?: any
  auth?: any
  adminRoute?: any
  merchantData?: any
  merchantPackage?: any
  merchant?: any
  closeUpgradeModal?: any
  onCheckApplyMerchantPremiumCodeStart?: (params) => void
  clearMerchantPremiumCodeData?: () => void
  onClearMerchantCodeData?: () => void
  onUpgradeMerchantPackageStart?: (params) => void
  onFetchMerchantGeneralBankInfoStart?: (params) => void
  onFetchMerchantPackageStart?: (params) => void
  onUpgradeMerchantPackageByMerchantIdStart?: (params) => void
  onFetchLastMerchantPackageLogByMerchantIdStart?: (params) => void
  onFetchUpgradeAmountStart?: (params) => void
  onOpenAlert?: (params) => void
  refreshPageData?: () => void
  onClearMerchantPremiumCode?: () => void
  onClearUpgradeAmount?: () => void
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  merchantData,
  merchantPackage: {
    merchantPackageList: { data },
    upgradeAmount,
    lastMerchantPackageLog,
    loading,
    isCreating,
  },
  merchant: {
    generalBankInfo: { result, merchantInfo, issuerCode },
    isApplyingCode,
    merchantCodeResponse,
    merchantCodeData,
    merchantCodeError,
  },
  clearMerchantPremiumCodeData,
  onCheckApplyMerchantPremiumCodeStart,
  onFetchMerchantGeneralBankInfoStart,
  onFetchMerchantPackageStart,
  onUpgradeMerchantPackageByMerchantIdStart,
  onOpenAlert,
  closeUpgradeModal,
  refreshPageData,
  onFetchLastMerchantPackageLogByMerchantIdStart,
  onClearMerchantPremiumCode,
  onFetchUpgradeAmountStart,
  onClearUpgradeAmount,
  onClearMerchantCodeData,
}) => {
  const navigate = useNavigate()

  const [packageType, setPackageType] = useState<any>()
  const [packageObj, setPackageObj] = useState<any>({})
  const [displayPackage, setDisplayPackage] = useState<any>([])

  const [tempLoader, setTempLoader] = useState(true)
  const [showBankInfo, setShowBankInfo] = useState(false)
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>({})

  const closeDescriptionModal = () => {
    setOpenDescriptionModal(false)
  }

  useEffect(() => {
    if (merchantData?.id) {
      onFetchMerchantGeneralBankInfoStart(merchantData?.id)
      onFetchLastMerchantPackageLogByMerchantIdStart(merchantData?.id)
    }
  }, [merchantData?.id])

  useEffect(() => {
    onClearMerchantPremiumCode()
    onClearUpgradeAmount()
  }, [])

  useEffect(() => {
    if (!_.isEmpty(merchantInfo?.__merchantPackage__)) {
      const filteredData = data?.filter(
        (item) =>
          item?.packageFee >= merchantInfo?.__merchantPackage__?.packageFee
      )
      const ascOrder = filteredData?.sort(function (a, b) {
        return a?.packageFee - b?.packageFee
      })
      setDisplayPackage(ascOrder)
    }
  }, [data, merchantInfo?.__merchantPackage__])

  // useEffect(() => {
  //   if (!_.isEmpty(data)) {
  //     const filteredPackage = data?.find(
  //       (item) => item?.name?.toLowerCase() === 'premium'
  //     )

  //     setPackageObj(filteredPackage)
  //   }
  // }, [data])

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  useEffect(() => {
    onFetchMerchantPackageStart({
      countryId: currentUser?.userCountry?.countryId,
      isFree: false,
      merchantId: merchantData?.id,
    })
  }, [currentUser?.userCountry])

  const submitPremiumCode = (data) => {
    onCheckApplyMerchantPremiumCodeStart({
      params: {
        merchantId: merchantData?.id,
        premiumCode: data,
        packageId: packageObj?.id,
      },
      fromUpgradeMerchant: true,
    })
  }

  const closeModal = () => {}
  const validationSchema = yup.object({})

  const checkShowBankInfo = (item) => {
    if (
      merchantInfo?.__merchantPackage__?.getsIssuer == false &&
      item?.getsIssuer === true
    ) {
      setShowBankInfo(true)
    } else if (
      merchantInfo?.__merchantPackage__?.getsIssuer == false &&
      item?.getsIssuer === false
    ) {
      setShowBankInfo(false)
    }
  }

  const checkForLastMerchantPackageLog = () => {
    if (!_.isEmpty(lastMerchantPackageLog)) {
      return (
        <Grid item xs={4}>
          <Card
            sx={{
              minWidth: 275,
              cursor: 'pointer',
              border: {
                border: '2px solid #00B59C',
                backgroundColor: '#00B59C',
                color: '#ffffff',
              },
              '&:hover': {
                border: '2px solid #00B59C',
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14, color: '#ffffff' }}
                color="text.secondary"
                gutterBottom
              >
                {lastMerchantPackageLog?.__merchantPackage__?.name}
              </Typography>
              {lastMerchantPackageLog?.discountedSetupFee ? (
                <Typography variant="body2">
                  Setup Fee -
                  {lastMerchantPackageLog?.__country__?.currencySymbol}
                  {fixed2Decimal(lastMerchantPackageLog?.discountedSetupFee)}
                  {lastMerchantPackageLog?.taxationUnitValue
                    ? `+ ${
                        lastMerchantPackageLog?.__country__?.currencySymbol
                      }${fixed2Decimal(
                        (lastMerchantPackageLog?.taxationUnitValue / 100) *
                          lastMerchantPackageLog?.discountedSetupFee
                      )} ${
                        lastMerchantPackageLog?.__country__?.gstDisplayText ??
                        ''
                      }`
                    : ''}
                </Typography>
              ) : (
                ''
              )}

              <Typography variant="body2">
                Package Fee -{' '}
                {lastMerchantPackageLog?.__country__?.currencySymbol}
                {fixed2Decimal(lastMerchantPackageLog?.discountedPackageFee)}
                {lastMerchantPackageLog?.discountedPackageFee
                  ? `+ ${
                      lastMerchantPackageLog?.__country__?.currencySymbol
                    }${fixed2Decimal(
                      (lastMerchantPackageLog?.taxationUnitValue / 100) *
                        lastMerchantPackageLog?.discountedPackageFee
                    )} ${
                      lastMerchantPackageLog?.__country__?.gstDisplayText ?? ''
                    }`
                  : ''}
              </Typography>

              <Typography variant="body2">
                <strong>
                  Total Fee -{' '}
                  {lastMerchantPackageLog?.__country__?.currencySymbol}
                  {lastMerchantPackageLog?.discountedPackageFee +
                    (lastMerchantPackageLog?.taxationUnitValue / 100) *
                      lastMerchantPackageLog?.discountedPackageFee +
                    lastMerchantPackageLog?.discountedSetupFee +
                    (lastMerchantPackageLog?.taxationUnitValue / 100) *
                      lastMerchantPackageLog?.discountedSetupFee ===
                    0 && lastMerchantPackageLog?.premiumCodeIsApplied
                    ? `0 ${
                        packageType?.toLowerCase() !== 'supporter' &&
                        `(Premium Code Used)`
                      }`
                    : fixed2Decimal(
                        lastMerchantPackageLog?.discountedPackageFee +
                          (lastMerchantPackageLog?.taxationUnitValue / 100) *
                            lastMerchantPackageLog?.discountedPackageFee +
                          lastMerchantPackageLog?.discountedSetupFee +
                          (lastMerchantPackageLog?.taxationUnitValue / 100) *
                            lastMerchantPackageLog?.discountedSetupFee
                      )}
                </strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    } else if (
      _.isEmpty(lastMerchantPackageLog) &&
      !_.isEmpty(merchantInfo?.__merchantPackage__)
    ) {
      return (
        <Grid item xs={4}>
          <Card
            sx={{
              minWidth: 275,
              cursor: 'pointer',
              border: {
                border: '2px solid #00B59C',
                backgroundColor: '#00B59C',
                color: '#ffffff',
              },
              '&:hover': {
                border: '2px solid #00B59C',
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14, color: '#ffffff' }}
                color="text.secondary"
                gutterBottom
              >
                {merchantInfo?.__merchantPackage__?.name}
              </Typography>
              {merchantInfo?.__merchantPackage__?.setupFee ? (
                <Typography variant="body2">
                  Setup Fee -{merchantInfo?.__country__?.currencySymbol}
                  {fixed2Decimal(merchantInfo?.__merchantPackage__?.setupFee)}
                  {merchantInfo?.__merchantPackage__?.setupFeeGST
                    ? `+ ${
                        merchantInfo?.__country__?.currencySymbol
                      }${fixed2Decimal(
                        merchantInfo?.__merchantPackage__?.setupFeeGST
                      )} ${merchantInfo?.__country__?.gstDisplayText ?? ''}`
                    : ''}
                </Typography>
              ) : (
                ''
              )}

              <Typography variant="body2">
                Package Fee - {merchantInfo?.__country__?.currencySymbol}
                {fixed2Decimal(merchantInfo?.__merchantPackage__?.packageFee)}
                {merchantInfo?.__merchantPackage__?.packageFeeGST
                  ? `+ ${
                      merchantInfo?.__country__?.currencySymbol
                    }${fixed2Decimal(
                      merchantInfo?.__merchantPackage__?.packageFeeGST
                    )} ${merchantInfo?.__country__?.gstDisplayText ?? ''}`
                  : ''}
              </Typography>

              <Typography variant="body2">
                <strong>
                  Total Fee - {merchantInfo?.__country__?.currencySymbol}
                  {fixed2Decimal(
                    merchantInfo?.__merchantPackage__?.setupFee +
                      merchantInfo?.__merchantPackage__?.packageFee +
                      merchantInfo?.__merchantPackage__?.packageFeeGST +
                      merchantInfo?.__merchantPackage__?.setupFeeGST
                  )}
                </strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    } else {
      return (
        <Grid container justifyContent={'center'}>
          <Typography variant="h4">No Package Found </Typography>
        </Grid>
      )
    }
  }

  const premiumCodeValue = (data) => {
    if (
      currentUser?.role?.toLowerCase() === 'countryowner' ||
      (currentUser?.role?.toLowerCase() === 'globaladmin' &&
        !_.isEmpty(currentUser?.userCountry))
    ) {
      if (data?.premiumCode) {
        return data?.premiumCode
      } else {
        return null
      }
    } else {
      if (data?.premiumCode?.label) {
        return data?.premiumCode?.label
      } else {
        return null
      }
    }
  }

  return (
    <>
      {openDescriptionModal && (
        <ModalRenderer
          open={openDescriptionModal}
          onDeny={closeDescriptionModal}
          onConfirm={closeDescriptionModal}
          title=""
          dialogTitle="Description"
        >
          <Typography
            dangerouslySetInnerHTML={{ __html: selectedItem?.description }}
            variant="body1"
          />
        </ModalRenderer>
      )}
      <Box sx={{ width: '100%', p: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Merchant Information</Typography>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Typography variant="h5">Merchant’s Trading Name</Typography>
            <Typography variant="body2">
              {merchantData?.merchantName ?? '-'}
            </Typography>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Typography variant="h5">Merchant User Name</Typography>
            <Typography variant="body2">
              {merchantData?.username ?? '-'}
            </Typography>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Typography variant="h5">Merchant Email For App</Typography>
            <Typography variant="body2">
              {merchantData?.merchantEmail ?? '-'}
            </Typography>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Typography variant="h5">Phone Number</Typography>
            <Typography variant="body2">
              {merchantData?.phoneNumber
                ? `${merchantData?.__country__?.phonePrefix} ${merchantData?.phoneNumber} `
                : '-'}
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={3}>
          <Formik
            validateOnChange={true}
            enableReinitialize
            initialValues={{
              merchantName: '',
              phonePrefix: '+1',
              phoneNumber: '',

              countryId: globalCountry?.id,
              premiumCode: null,
              issuerCode: null,

              regionId: null,
              stateId: null,
              areaId: null,
            }}
            validationSchema={validationSchema}
            onSubmit={async (data: any, { setSubmitting }) => {
              setSubmitting(true)

              // Handle result.error or result.token

              if (_.isEmpty(packageObj)) {
                return onOpenAlert({
                  message: 'Please select the package to upgrade or renew',
                  severity: 'error',
                })
              }

              let omittedResult = data

              if (!packageObj?.getsIssuer) {
                omittedResult = _.omit(data, [
                  'bankName',
                  'BSB',
                  'bankCode',
                  'branchCode',
                  'accountName',
                  'accountNumber',
                  'swiftCode',
                  'premiumCode',
                  'transactionCode',
                ])
              }

              onUpgradeMerchantPackageByMerchantIdStart({
                params: {
                  ...omittedResult,
                  merchantId: merchantData?.id,
                  merchantPackageId: packageObj?.id,
                  stripeTokenId: result?.token?.id,
                  containsBankInfo: showBankInfo ? true : false,
                  previous_package_id:
                    lastMerchantPackageLog?.merchantPackageId,
                  new_package_id: packageObj?.id,
                  // premiumCode: data?.premiumCode ?? null,
                  previous_getsIssuer_value:
                    merchantInfo?.__merchantPackage__?.getsIssuer,
                  new_getsIssuer_value: packageObj?.getsIssuer,
                  premiumCode: premiumCodeValue(data),
                  // issuerCode: data?.issuerCode?.label,
                },
                closeModal: closeUpgradeModal,
                refreshPageData,
                navigate,
              })

              setSubmitting(false)
            }}
            render={({ values, setFieldValue, handleBlur, handleChange }) => {
              if (values?.premiumCode?.length < 1) {
                clearMerchantPremiumCodeData()
              }
              return (
                <Form>
                  <Box mt={2}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="h5">Selected Package</Typography>
                      </Grid>

                      <Grid item container xs={12} spacing={3}>
                        {tempLoader || loading ? (
                          <Grid container justifyContent={'center'}>
                            <CircularProgress size={20} />
                          </Grid>
                        ) : (
                          <>{checkForLastMerchantPackageLog()}</>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">Choose Package</Typography>
                      </Grid>

                      <Grid item container xs={12} spacing={3}>
                        {tempLoader || loading ? (
                          <Grid container justifyContent={'center'}>
                            <CircularProgress size={20} />
                          </Grid>
                        ) : (
                          <>
                            {displayPackage?.length ? (
                              displayPackage.map((item, index) => (
                                <Grid
                                  item
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  key={index + 1}
                                  sx={{ position: 'relative' }}
                                >
                                  {item?.description ? (
                                    <Tooltip
                                      title={''}
                                      placement="top"
                                      sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: 1,
                                        color: '#11182791',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setOpenDescriptionModal(true)
                                        setSelectedItem(item)
                                      }}
                                    >
                                      <InfoIcon />
                                    </Tooltip>
                                  ) : (
                                    ''
                                  )}
                                  <Card
                                    sx={{
                                      minWidth: 275,
                                      cursor: 'pointer',
                                      border: item?.name?.toLowerCase() ===
                                        packageType && {
                                        border: '2px solid #44cdba',
                                        backgroundColor: '#44cdba',
                                      },
                                      '&:hover': {
                                        border: '2px solid #44cdba',
                                      },
                                    }}
                                    onClick={() => {
                                      setPackageType(item?.name?.toLowerCase())
                                      checkShowBankInfo(item)
                                      setPackageObj(item)
                                      onFetchUpgradeAmountStart({
                                        merchantId: merchantData?.id,
                                        packageId: item?.id,
                                      })
                                      onClearMerchantCodeData()
                                      setFieldValue('premiumCode', '')
                                    }}
                                  >
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 14,
                                          color: item?.name?.toLowerCase() ===
                                            packageType && {
                                            color: '#ffffff',
                                          },
                                        }}
                                        color="text.secondary"
                                        gutterBottom
                                      >
                                        {item?.name}
                                      </Typography>
                                      {item?.setupFee ? (
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: item?.name?.toLowerCase() ===
                                              packageType && {
                                              color: '#ffffff',
                                            },
                                          }}
                                        >
                                          Setup Fee -{' '}
                                          {item?.__country__?.currencySymbol}
                                          {fixed2Decimal(item?.setupFee)}{' '}
                                          {item?.setupFeeGST
                                            ? `+ ${
                                                item?.__country__
                                                  ?.currencySymbol
                                              }${fixed2Decimal(
                                                item?.setupFeeGST
                                              )} ${
                                                item?.__country__
                                                  ?.gstDisplayText ?? ''
                                              }`
                                            : ''}
                                        </Typography>
                                      ) : (
                                        ''
                                      )}

                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: item?.name?.toLowerCase() ===
                                            packageType && {
                                            color: '#ffffff',
                                          },
                                        }}
                                      >
                                        Package Fee -{' '}
                                        {item?.__country__?.currencySymbol}
                                        {fixed2Decimal(item?.packageFee)}{' '}
                                        {item?.packageFeeGST
                                          ? `+ ${
                                              item?.__country__?.currencySymbol
                                            }${fixed2Decimal(
                                              item?.packageFeeGST
                                            )} ${
                                              item?.__country__
                                                ?.gstDisplayText ?? ''
                                            }`
                                          : ''}
                                      </Typography>

                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: item?.name?.toLowerCase() ===
                                            packageType && {
                                            color: '#ffffff',
                                          },
                                        }}
                                      >
                                        Total Fee -{' '}
                                        {item?.__country__?.currencySymbol}
                                        {fixed2Decimal(
                                          item?.setupFee +
                                            item?.packageFee +
                                            item?.setupFeeGST +
                                            item?.packageFeeGST
                                        )}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))
                            ) : (
                              <Grid container justifyContent={'center'}>
                                <Typography variant="h4">
                                  No Package Found{' '}
                                </Typography>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>

                      {packageObj?.canApplyPremiumCode && (
                        <Grid item container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h5">Premium Code</Typography>
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            {currentUser?.role?.toLowerCase() ===
                              'countryowner' ||
                            (currentUser?.role?.toLowerCase() ===
                              'globaladmin' &&
                              !_.isEmpty(currentUser?.userCountry)) ? (
                              <AppTextField
                                placeholder={
                                  'Enter Premium Code If you have any'
                                }
                                label={'Enter Premium Code If you have any'}
                                name="premiumCode"
                                variant="outlined"
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-input': {
                                    fontSize: 14,
                                  },
                                }}
                                size="small"
                                onKeyUp={() => {
                                  if (values?.premiumCode?.length === 0) {
                                    onClearMerchantCodeData()
                                  }
                                }}
                              />
                            ) : (
                              <Field
                                // required
                                name="premiumCode"
                                component={PremiumCodeByPanelSelect}
                                merchantId={merchantData?.id}
                                onClearMerchantCodeData={
                                  onClearMerchantCodeData
                                }
                                clearMerchantPremiumCodeData={
                                  clearMerchantPremiumCodeData
                                }
                                merchantPackageId={packageObj?.id}
                              />
                            )}

                            {merchantCodeResponse && (
                              <Typography
                                variant="body2"
                                color={merchantCodeError ? 'error' : '#2e7d32'}
                                align="center"
                              >
                                {merchantCodeResponse}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <Button
                              sx={{
                                color: '#ffffff',
                                display: 'flex',
                                background: '#00bfcd',
                                '&:hover': {
                                  background: '#ec4785',
                                },
                                px: 5,
                              }}
                              type="button"
                              onClick={() => {
                                if (
                                  currentUser?.role?.toLowerCase() ===
                                    'countryowner' ||
                                  (currentUser?.role?.toLowerCase() ===
                                    'globaladmin' &&
                                    !_.isEmpty(currentUser?.userCountry))
                                ) {
                                  submitPremiumCode(values?.premiumCode)
                                } else {
                                  submitPremiumCode(values?.premiumCode?.label)
                                }
                              }}
                              disabled={isApplyingCode}
                            >
                              {isApplyingCode ? (
                                <CircularProgress size={20} />
                              ) : (
                                'Apply'
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item container spacing={3}>
                        {!_.isEmpty(packageObj) ? (
                          <Grid item xs={12}>
                            <Typography variant="h5">
                              Total:{' '}
                              {/* {!_.isEmpty(packageObj)
                            ? `${packageObj?.__country__?.currencySymbol}${
                                packageObj?.packageFee +
                                packageObj?.setupFee +
                                packageObj?.setupFeeGST +
                                packageObj?.packageFeeGST
                              }`
                            : 0} */}
                              {merchantCodeData?.totalAmount ||
                              packageObj?.packageFee ||
                              packageObj?.setupFee
                                ? packageObj?.__country__?.currencySymbol
                                : ''}
                              {!_.isEmpty(merchantCodeData)
                                ? 0
                                : fixed2Decimal(
                                    upgradeAmount?.totalPackageFee +
                                      upgradeAmount?.totalSetupFee +
                                      upgradeAmount?.totalPackageFeeGST +
                                      upgradeAmount?.totalSetupFeeGST
                                  )}
                              {/* {upgradeAmount?.totalPackageFee +
                              upgradeAmount?.totalSetupFee +
                              upgradeAmount?.totalPackageFeeGST +
                              upgradeAmount?.totalSetupFeeGST ?? 0} */}
                            </Typography>
                          </Grid>
                        ) : (
                          ''
                        )}

                        {showBankInfo && (
                          <>
                            {packageType === 'premium' ||
                            packageType === 'partner' ? (
                              <Grid item xs={12}>
                                <Typography variant="body2">
                                  Note*: Selected package requires filling up
                                  the bank information.
                                </Typography>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </Grid>

                      {showBankInfo ? (
                        <Grid container spacing={3} sx={{ mt: 4, ml: 2 }}>
                          <Grid item container>
                            <Typography variant="h4">
                              Bank Information
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Bank Name'}
                              label={'Bank Name'}
                              name="bankName"
                              required
                              variant="outlined"
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'BSB'}
                              label={'BSB'}
                              name="BSB"
                              required={
                                globalCountry?.countryName?.toLowerCase() ===
                                'australia'
                                  ? true
                                  : false
                              }
                              variant="outlined"
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Bank Code'}
                              label={'Bank Code'}
                              name="bankCode"
                              variant="outlined"
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Branch Code'}
                              label={'Branch Code'}
                              name="branchCode"
                              variant="outlined"
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Account Name'}
                              label={'Account Name'}
                              name="accountName"
                              required
                              variant="outlined"
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Account Number'}
                              label={'Account Number'}
                              name="accountNumber"
                              required
                              variant="outlined"
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Swift Code'}
                              label={'Swift Code'}
                              name="swiftCode"
                              required={
                                globalCountry?.countryName?.toLowerCase() !==
                                'australia'
                                  ? true
                                  : false
                              }
                              variant="outlined"
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="body2" color="#43bfcd">
                              <strong>
                                Note*: Piiink will credit this merchant's
                                earnings to this nominated bank account.
                              </strong>
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>

                    {!issuerCode && (
                      <Grid item container spacing={3} sx={{ mt: 4 }}>
                        <Grid item container>
                          <Typography variant="h5">
                            QR Codes Information
                          </Typography>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          {currentUser?.role?.toLowerCase() === 'globaladmin' &&
                          !_.isEmpty(currentUser?.userCountry) ? (
                            <>
                              {/* <Field
                              name="issuerCode"
                              component={GetAllIssuerQrCodeSelect}
                              assignedToId={merchantInfo?.signerId}
                              assignedToType={merchantInfo?.signerType}
                            /> */}

                              <AppTextField
                                placeholder={'Issuer Code'}
                                label={'Issuer Code'}
                                name="issuerCode"
                                variant="outlined"
                                required
                                sx={{
                                  width: '100%',
                                  '& .MuiInputBase-input': {
                                    fontSize: 14,
                                  },
                                }}
                                size="small"
                              />
                            </>
                          ) : (
                            <>
                              {currentUser?.role?.toLowerCase() ===
                              'countryowner' ? (
                                <>
                                  <AppTextField
                                    placeholder={'Issuer Code'}
                                    label={'Issuer Code'}
                                    name="issuerCode"
                                    variant="outlined"
                                    required
                                    sx={{
                                      width: '100%',
                                      '& .MuiInputBase-input': {
                                        fontSize: 14,
                                      },
                                    }}
                                    size="small"
                                  />
                                  {/* <Field
                                    name="issuerCode"
                                    component={CountryIssuerQrCodeSelect}
                                    assignedToId={merchantInfo?.signerId}
                                    assignedToType={merchantInfo?.signerType}
                                    required
                                  /> */}
                                </>
                              ) : (
                                <>
                                  <AppTextField
                                    placeholder={'Issuer Code'}
                                    label={'Issuer Code'}
                                    name="issuerCode"
                                    variant="outlined"
                                    required
                                    sx={{
                                      width: '100%',
                                      '& .MuiInputBase-input': {
                                        fontSize: 14,
                                      },
                                    }}
                                    size="small"
                                  />

                                  {/* <Field
                                    name="issuerCode"
                                    component={IssuerQrCodeSelect}
                                    required={packageObj?.getsIssuer}
                                    filterByCountryId={
                                      merchantData?.__country__
                                    }
                                    includeFilterByCountry={true}
                                  /> */}
                                </>
                              )}
                            </>
                          )}

                          {/* <Field
                                name="issuerCode"
                                component={IssuerQrCodeSelect}
                                required={packageObj?.getsIssuer}
                              /> */}
                        </Grid>
                      </Grid>
                    )}

                    <Box sx={{ height: 20 }} />
                    <Box sx={{ height: 30 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        sx={{
                          color: '#ffffff',
                          display: 'flex',
                          background: '#00bfcd',
                          '&:hover': {
                            background: '#ec4785',
                          },
                          px: 5,
                        }}
                        type="submit"
                        disabled={isCreating}
                      >
                        {isCreating ? (
                          <CircularProgress size={20} />
                        ) : (
                          'upgrade/renew'
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )
            }}
          />
        </Grid>
      </Box>
    </>
  )
}

export default container(AddNewClub)
