import { createSlice } from '@reduxjs/toolkit'

import { IAppSlider as IWebsite } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IWebsite = {
  appSliderList: listObj,
  soleAppSlider: {},
  webSliderList: listObj,
  soleWebSlider: {},
  pageList: listObj,
  solePage: {},
  soleContentSection: {},
  teamList: listObj,
  mediaList: listObj,
  soleTeam: {},
  testamonialList: listObj,
  soleTestamonial: {},
  soleMultilingual: {},
  partnerList: listObj,
  solePartner: {},
  clientList: listObj,
  soleClient: {},
  faqList: listObj,
  contentSectionList: listObj,
  soleFaq: {},
  soleTestimonial: {},
  soleMedia: {},
  soleSection: {},
  isFetching: false,
  isCreating: false,
  isEditing: false,
  error: null,
  loading: false,
  hasMoreData: false,
  countryList: listObj,
  constantList: listObj,
  multilingualList: listObj,
  sectionList: listObj,
  aboutUsList: listObj,
  socialMediaList: listObj,
  homeVideoList: listObj,
  menuItemList: listObj,
}

export const websiteAdmin = createSlice({
  name: 'websiteAdmin',
  initialState: INITIAL_STATE,

  reducers: {
    // slider ----------------------------------------------------------
    fetchAppSliderStart: (state: any, action) => {
      state.loading = true
    },
    fetchAppSliderSuccess: (state: any, action) => {
      state.appSliderList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchAppSliderFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchAppSliderByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAppSliderByIdSuccess: (state: any, action) => {
      state.soleAppSlider = action.payload.data
      state.isFetching = false
    },
    fetchAppSliderByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createAppSliderStart: (state: any, action) => {
      state.isCreating = true
    },
    createAppSliderSuccess: (state: any, action) => {
      state.isCreating = false
      state.appSliderList.data = [...state.appSliderList.data, action.payload]
    },
    createAppSliderFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },
    deleteAppSliderStart: (state: any, action) => {
      state.loading = true
    },
    deleteAppSliderSuccess: (state: any, action) => {
      state.loading = false
      state.appSliderList.data = state.appSliderList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteAppSliderFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editAppSliderStart: (state: any, action) => {
      state.isEditing = true
    },
    editAppSliderSuccess: (state: any, action) => {
      const result = state.appSliderList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.appSliderList.data = result

      state.isEditing = false
    },
    editAppSliderFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    // web slider ----------------------------------------------------------
    fetchWebSliderStart: (state: any, action) => {
      state.loading = true
    },
    fetchWebSliderSuccess: (state: any, action) => {
      state.webSliderList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchWebSliderFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchWebSliderByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWebSliderByIdSuccess: (state: any, action) => {
      state.soleWebSlider = action.payload.data
      state.isFetching = false
    },
    fetchWebSliderByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createWebSliderStart: (state: any, action) => {
      state.loading = true
    },
    createWebSliderSuccess: (state: any, action) => {
      state.loading = false
      state.webSliderList.data = [...state.webSliderList.data, action.payload]
    },
    createWebSliderFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteWebSliderStart: (state: any, action) => {
      state.loading = true
    },
    deleteWebSliderSuccess: (state: any, action) => {
      state.loading = false
      state.webSliderList.data = state.webSliderList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteWebSliderFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editWebSliderStart: (state: any, action) => {
      state.loading = true
    },
    editWebSliderSuccess: (state: any, action) => {
      const result = state.webSliderList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.webSliderList.data = result

      state.loading = false
    },
    editWebSliderFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    //HeaderMenuItem --------------------------------------------------------
    fetchHeaderMenuStart: (state: any, action) => {
      state.loading = true
    },
    fetchHeaderMenuSuccess: (state: any, action) => {
      state.menuItemList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchHeaderMenuFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createHeaderMenuStart: (state: any, action) => {
      state.loading = true
    },
    createHeaderMenuSuccess: (state: any, action) => {
      state.loading = false
    },
    createHeaderMenuFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    activeMenuItemStart: (state: any, action) => {
      state.loading = true
    },
    activeMenuItemSuccess: (state: any, action) => {
      state.loading = false
    },
    activeMenuItemFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteMenuItemStart: (state: any, action) => {
      state.loading = true
    },
    deleteMenuItemSuccess: (state: any, action) => {
      state.loading = false
    },
    deleteMenuItemFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // page ----------------------------------------------------------------

    fetchPageStart: (state: any, action) => {
      state.loading = true
    },
    fetchPageSuccess: (state: any, action) => {
      state.pageList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchPageByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPageByIdSuccess: (state: any, action) => {
      state.solePage = action.payload.data
      state.isFetching = false
    },
    fetchPageByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createPageStart: (state: any, action) => {
      state.loading = true
    },
    createPageSuccess: (state: any, action) => {
      state.loading = false
      state.pageList.data = [...state.pageList.data, action.payload]
    },
    createPageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deletePageStart: (state: any, action) => {
      state.loading = true
    },
    deletePageSuccess: (state: any, action) => {
      state.loading = false
      state.pageList.data = state.pageList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editPageStart: (state: any, action) => {
      state.loading = true
    },
    editPageSuccess: (state: any, action) => {
      const result = state.pageList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.pageList.data = result

      state.loading = false
    },
    editPageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    //ContentSection Start--------------------------------------------
    fetchContentSectionStart: (state: any, action) => {
      state.loading = true
    },
    fetchContentSectionSuccess: (state: any, action) => {
      state.contentSectionList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchContentSectionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchContentSectionByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchContentSectionByIdSuccess: (state: any, action) => {
      state.soleContentSection = action.payload.data
      state.isFetching = false
    },
    fetchContentSectionByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createContentSectionStart: (state: any, action) => {
      state.loading = true
    },
    createContentSectionSuccess: (state: any, action) => {
      state.loading = false
      state.contentSectionList.data = [
        ...state.contentSectionList.data,
        action.payload,
      ]
    },
    createContentSectionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteContentSectionStart: (state: any, action) => {
      state.loading = true
    },
    deleteContentSectionSuccess: (state: any, action) => {
      state.loading = false
      state.contentSectionList.data = state.contentSectionList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteContentSectionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editContentSectionStart: (state: any, action) => {
      state.loading = true
    },
    editContentSectionSuccess: (state: any, action) => {
      const result = state.contentSectionList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.contentSectionList.data = result

      state.loading = false
    },
    editContentSectionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    //ContentSection End--------------------------------------------

    // faq ----------------------------------------------------------------

    fetchFaqStart: (state: any, action) => {
      state.loading = true
    },
    fetchFaqSuccess: (state: any, action) => {
      state.faqList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchFaqFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchFaqByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchFaqByIdSuccess: (state: any, action) => {
      state.soleFaq = action.payload.data
      state.isFetching = false
    },
    fetchFaqByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createFaqStart: (state: any, action) => {
      state.loading = true
    },
    createFaqSuccess: (state: any, action) => {
      state.loading = false
      state.faqList.data = [...state.faqList.data, action.payload]
    },
    createFaqFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteFaqStart: (state: any, action) => {
      state.loading = true
    },
    deleteFaqSuccess: (state: any, action) => {
      state.loading = false
      state.faqList.data = state.faqList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteFaqFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editFaqStart: (state: any, action) => {
      state.loading = true
    },
    editFaqSuccess: (state: any, action) => {
      const result = state.faqList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.faqList.data = result

      state.loading = false
    },
    editFaqFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // team
    fetchTeamStart: (state: any, action) => {
      state.loading = true
    },
    fetchTeamSuccess: (state: any, action) => {
      state.teamList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchTeamFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchTeamByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTeamByIdSuccess: (state: any, action) => {
      state.soleTeam = action.payload.data
      state.isFetching = false
    },
    fetchTeamByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createTeamStart: (state: any, action) => {
      state.loading = true
    },
    createTeamSuccess: (state: any, action) => {
      state.loading = false
      state.teamList.data = [...state.teamList.data, action.payload]
    },
    createTeamFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteTeamStart: (state: any, action) => {
      state.loading = true
    },
    deleteTeamSuccess: (state: any, action) => {
      state.loading = false
      state.teamList.data = state.teamList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteTeamFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editTeamStart: (state: any, action) => {
      state.loading = true
    },
    editTeamSuccess: (state: any, action) => {
      const result = state.teamList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.teamList.data = result

      state.loading = false
    },
    editTeamFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // partners
    fetchPartnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchPartnerSuccess: (state: any, action) => {
      state.partnerList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchPartnerByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPartnerByIdSuccess: (state: any, action) => {
      state.solePartner = action.payload.data
      state.isFetching = false
    },
    fetchPartnerByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createPartnerStart: (state: any, action) => {
      state.loading = true
    },
    createPartnerSuccess: (state: any, action) => {
      state.loading = false
      state.partnerList.data = [...state.partnerList.data, action.payload]
    },
    createPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deletePartnerStart: (state: any, action) => {
      state.loading = true
    },
    deletePartnerSuccess: (state: any, action) => {
      state.loading = false
      state.partnerList.data = state.partnerList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editPartnerStart: (state: any, action) => {
      state.loading = true
    },
    editPartnerSuccess: (state: any, action) => {
      const result = state.partnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.partnerList.data = result

      state.loading = false
    },
    editPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // clients
    fetchClientStart: (state: any, action) => {
      state.loading = true
    },
    fetchClientSuccess: (state: any, action) => {
      state.clientList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchClientFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchClientByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchClientByIdSuccess: (state: any, action) => {
      state.soleClient = action.payload.data
      state.isFetching = false
    },
    fetchClientByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createClientStart: (state: any, action) => {
      state.loading = true
    },
    createClientSuccess: (state: any, action) => {
      state.loading = false
      state.clientList.data = [...state.clientList.data, action.payload]
    },
    createClientFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteClientStart: (state: any, action) => {
      state.loading = true
    },
    deleteClientSuccess: (state: any, action) => {
      state.loading = false
      state.clientList.data = state.clientList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteClientFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editClientStart: (state: any, action) => {
      state.loading = true
    },
    editClientSuccess: (state: any, action) => {
      const result = state.clientList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.clientList.data = result

      state.loading = false
    },
    editClientFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // testamonial
    fetchTestamonialStart: (state: any, action) => {
      state.loading = true
    },
    fetchTestamonialSuccess: (state: any, action) => {
      state.testamonialList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchTestamonialFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchTestamonialByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTestamonialByIdSuccess: (state: any, action) => {
      state.soleTestimonial = action.payload.data
      state.isFetching = false
    },
    fetchTestamonialByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createTestamonialStart: (state: any, action) => {
      state.loading = true
    },
    createTestamonialSuccess: (state: any, action) => {
      state.loading = false
      state.testamonialList.data = [
        ...state.testamonialList.data,
        action.payload,
      ]
    },
    createTestamonialFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteTestamonialStart: (state: any, action) => {
      state.loading = true
    },
    deleteTestamonialSuccess: (state: any, action) => {
      state.loading = false
      state.testamonialList.data = state.testamonialList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteTestamonialFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editTestamonialStart: (state: any, action) => {
      state.loading = true
    },
    editTestamonialSuccess: (state: any, action) => {
      const result = state.testamonialList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.testamonialList.data = result

      state.loading = false
    },
    editTestamonialFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    //country
    //country
    fetchCountryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountrySuccess: (state: any, action) => {
      state.countryList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountryByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCountryByIdSuccess: (state: any, action) => {
      state.soleTeam = action.payload.data
      state.isFetching = false
    },
    fetchCountryByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createCountryStart: (state: any, action) => {
      state.loading = true
    },
    createCountrySuccess: (state: any, action) => {
      state.loading = false
    },
    createCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteCountryStart: (state: any, action) => {
      state.loading = true
    },
    deleteCountrySuccess: (state: any, action) => {
      state.loading = false
      state.countryList.data = state.countryList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    changeStatusCountryStart: (state: any, action) => {
      state.loading = true
    },
    changeStatusCountrySuccess: (state: any, action) => {
      state.loading = false
      state.countryList.data = state.countryList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    changeStatusCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editCountryStart: (state: any, action) => {
      state.loading = true
    },
    editCountrySuccess: (state: any, action) => {
      const result = state.countryList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.countryList.data = result

      state.loading = false
    },
    editCountryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    //constant
    fetchConstantStart: (state: any, action) => {
      state.loading = true
    },
    fetchConstantSuccess: (state: any, action) => {
      state.constantList = action.payload
      state.loading = false
    },
    fetchConstantFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchConstantByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchConstantByIdSuccess: (state: any, action) => {
      state.soleTeam = action.payload.data
      state.isFetching = false
    },
    fetchConstantByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createConstantStart: (state: any, action) => {
      state.loading = true
    },
    createConstantSuccess: (state: any, action) => {
      state.loading = false
      state.constantList.data = [...state.constantList.data, action.payload]
    },
    createConstantFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteConstantStart: (state: any, action) => {
      state.loading = true
    },
    deleteConstantSuccess: (state: any, action) => {
      state.loading = false
      state.constantList.data = state.constantList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteConstantFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editConstantStart: (state: any, action) => {
      state.loading = true
    },
    editConstantSuccess: (state: any, action) => {
      const result = state.constantList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.constantList.data = result

      state.loading = false
    },
    editConstantFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    //constant

    //multilang
    fetchMultilingualStart: (state: any, action) => {
      state.loading = true
    },
    fetchMultilingualSuccess: (state: any, action) => {
      state.multilingualList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchMultilingualFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMultilingualByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMultilingualByIdSuccess: (state: any, action) => {
      state.soleMultilingual = action.payload.data
      state.isFetching = false
    },
    fetchMultilingualByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createMultilingualStart: (state: any, action) => {
      state.loading = true
    },
    createMultilingualSuccess: (state: any, action) => {
      state.loading = false
      state.multilingualList.data = [
        ...state.multilingualList.data,
        action.payload,
      ]
    },
    createMultilingualFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteMultilingualStart: (state: any, action) => {
      state.loading = true
    },
    deleteMultilingualSuccess: (state: any, action) => {
      state.loading = false
      state.multilingualList.data = state.multilingualList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteMultilingualFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editMultilingualStart: (state: any, action) => {
      state.loading = true
    },
    editMultilingualSuccess: (state: any, action) => {
      const result = state.multilingualList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.multilingualList.data = result

      state.loading = false
    },
    editMultilingualFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    //Multilingual
    //multilang
    //section
    fetchSectionStart: (state: any, action) => {
      state.loading = true
    },
    fetchSectionSuccess: (state: any, action) => {
      state.testamonialList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchSectionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchSectionByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchSectionByIdSuccess: (state: any, action) => {
      state.soleSection = action.payload.data
      state.isFetching = false
    },
    fetchSectionByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createSectionStart: (state: any, action) => {
      state.loading = true
    },
    createSectionSuccess: (state: any, action) => {
      state.loading = false
      state.testamonialList.data = [
        ...state.testamonialList.data,
        action.payload,
      ]
    },
    createSectionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteSectionStart: (state: any, action) => {
      state.loading = true
    },
    deleteSectionSuccess: (state: any, action) => {
      state.loading = false
      state.testamonialList.data = state.testamonialList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteSectionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editSectionStart: (state: any, action) => {
      state.loading = true
    },
    editSectionSuccess: (state: any, action) => {
      const result = state.testamonialList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.testamonialList.data = result

      state.loading = false
    },
    editSectionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    //section

    //anoutUs
    fetchAboutUsStart: (state: any, action) => {
      state.loading = true
    },
    fetchAboutUsSuccess: (state: any, action) => {
      state.testamonialList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchAboutUsFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchAboutUsByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAboutUsByIdSuccess: (state: any, action) => {
      const orderedData = {
        ...action?.payload?.data,
        __aboutUsContentlists__:
          action?.payload?.data?.__aboutUsContentlists__?.sort(
            (a, b) => a?.order - b?.order
          ),
      }

      state.soleTestamonial = orderedData
      state.isFetching = false
    },
    fetchAboutUsByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createAboutUsStart: (state: any, action) => {
      state.isCreating = true
    },
    createAboutUsSuccess: (state: any, action) => {
      state.isCreating = false
      state.testamonialList.data = [
        ...state.testamonialList.data,
        action.payload,
      ]
    },
    createAboutUsFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },
    deleteAboutUsStart: (state: any, action) => {
      state.loading = true
    },
    deleteAboutUsSuccess: (state: any, action) => {
      state.loading = false
      state.testamonialList.data = state.testamonialList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteAboutUsFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editAboutUsStart: (state: any, action) => {
      state.isEditing = true
    },
    editAboutUsSuccess: (state: any, action) => {
      const result = state.testamonialList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.testamonialList.data = result

      state.isEditing = false
    },
    editAboutUsFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    //section

    fetchSocialMediaListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchSocialMediaListSuccess: (state: any, action) => {
      state.socialMediaList = action.payload
      state.isFetching = false
    },
    fetchSocialMediaListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createSocialMediaStart: (state: any, action) => {
      state.isCreating = true
    },
    createSocialMediaSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createSocialMediaFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editSocialMediaStart: (state: any, action) => {
      state.isEditing = true
    },
    editSocialMediaSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editSocialMediaFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    deleteAboutUsBySlugStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteAboutUsBySlugSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteAboutUsBySlugFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    translateIntoCountryLanguageStart: (state: any, action) => {
      state.isCreating = true
    },
    translateIntoCountryLanguageSuccess: (state: any, action) => {
      state.isCreating = false
    },
    translateIntoCountryLanguageFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    createHomeVideoStart: (state: any, action) => {
      state.isCreating = true
    },
    createHomeVideoSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createHomeVideoFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchAllHomeVideoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllHomeVideoSuccess: (state: any, action) => {
      state.homeVideoList = action.payload
      state.isFetching = false
    },
    fetchAllHomeVideoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editHomeVideoStart: (state: any, action) => {
      state.isEditing = true
    },
    editHomeVideoSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editHomeVideoFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchAllFileRepositoryStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllFileRepositorySuccess: (state: any, action) => {
      state.mediaList = action.payload
      state.loading = false
    },
    fetchAllFileRepositoryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchFileRepositoryByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchFileRepositoryByIdSuccess: (state: any, action) => {
      state.soleMedia = action.payload.data
      state.isFetching = false
    },
    fetchFileRepositoryByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createFileRepositoryStart: (state: any, action) => {
      state.isCreating = true
    },
    createFileRepositorySuccess: (state: any, action) => {
      state.isCreating = false
    },
    createFileRepositoryFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editFileRepositoryStart: (state: any, action) => {
      state.isEditing = true
    },
    editFileRepositorySuccess: (state: any, action) => {
      state.isEditing = false
    },
    editFileRepositoryFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
  },
})

export const {
  // slider
  fetchAppSliderStart,
  fetchAppSliderSuccess,
  fetchAppSliderFailure,
  createAppSliderStart,
  createAppSliderSuccess,
  createAppSliderFailure,
  deleteAppSliderStart,
  deleteAppSliderSuccess,
  deleteAppSliderFailure,
  fetchAppSliderByIdStart,
  fetchAppSliderByIdSuccess,
  fetchAppSliderByIdFailure,
  editAppSliderStart,
  editAppSliderSuccess,
  editAppSliderFailure,

  // web slider
  fetchWebSliderStart,
  fetchWebSliderSuccess,
  fetchWebSliderFailure,
  createWebSliderStart,
  createWebSliderSuccess,
  createWebSliderFailure,
  deleteWebSliderStart,
  deleteWebSliderSuccess,
  deleteWebSliderFailure,
  fetchWebSliderByIdStart,
  fetchWebSliderByIdSuccess,
  fetchWebSliderByIdFailure,
  editWebSliderStart,
  editWebSliderSuccess,
  editWebSliderFailure,

  //menuItem --headerMenu
  fetchHeaderMenuStart,
  fetchHeaderMenuSuccess,
  fetchHeaderMenuFailure,
  createHeaderMenuStart,
  createHeaderMenuSuccess,
  createHeaderMenuFailure,
  activeMenuItemStart,
  activeMenuItemSuccess,
  activeMenuItemFailure,
  deleteMenuItemStart,
  deleteMenuItemSuccess,
  deleteMenuItemFailure,
  // page
  fetchPageStart,
  fetchPageSuccess,
  fetchPageFailure,
  fetchPageByIdStart,
  fetchPageByIdSuccess,
  fetchPageByIdFailure,
  createPageStart,
  createPageSuccess,
  createPageFailure,
  deletePageStart,
  deletePageSuccess,
  deletePageFailure,
  editPageStart,
  editPageSuccess,
  editPageFailure,

  // faq
  fetchFaqStart,
  fetchFaqSuccess,
  fetchFaqFailure,
  fetchFaqByIdStart,
  fetchFaqByIdSuccess,
  fetchFaqByIdFailure,
  createFaqStart,
  createFaqSuccess,
  createFaqFailure,
  deleteFaqStart,
  deleteFaqSuccess,
  deleteFaqFailure,
  editFaqStart,
  editFaqSuccess,
  editFaqFailure,

  // team
  fetchTeamStart,
  fetchTeamSuccess,
  fetchTeamFailure,
  fetchTeamByIdStart,
  fetchTeamByIdSuccess,
  fetchTeamByIdFailure,
  createTeamStart,
  createTeamSuccess,
  createTeamFailure,
  deleteTeamStart,
  deleteTeamSuccess,
  deleteTeamFailure,
  editTeamStart,
  editTeamSuccess,
  editTeamFailure,

  // partners
  fetchPartnerStart,
  fetchPartnerSuccess,
  fetchPartnerFailure,
  fetchPartnerByIdStart,
  fetchPartnerByIdSuccess,
  fetchPartnerByIdFailure,
  createPartnerStart,
  createPartnerSuccess,
  createPartnerFailure,
  deletePartnerStart,
  deletePartnerSuccess,
  deletePartnerFailure,
  editPartnerStart,
  editPartnerSuccess,
  editPartnerFailure,

  // clients
  fetchClientStart,
  fetchClientSuccess,
  fetchClientFailure,
  fetchClientByIdStart,
  fetchClientByIdSuccess,
  fetchClientByIdFailure,
  createClientStart,
  createClientSuccess,
  createClientFailure,
  deleteClientStart,
  deleteClientSuccess,
  deleteClientFailure,
  editClientStart,
  editClientSuccess,
  editClientFailure,

  // testamonial
  fetchTestamonialStart,
  fetchTestamonialSuccess,
  fetchTestamonialFailure,
  fetchTestamonialByIdStart,
  fetchTestamonialByIdSuccess,
  fetchTestamonialByIdFailure,
  createTestamonialStart,
  createTestamonialSuccess,
  createTestamonialFailure,
  deleteTestamonialStart,
  deleteTestamonialSuccess,
  deleteTestamonialFailure,
  editTestamonialStart,
  editTestamonialSuccess,
  editTestamonialFailure,

  // country
  fetchCountryStart,
  fetchCountrySuccess,
  fetchCountryFailure,
  fetchCountryByIdStart,
  fetchCountryByIdSuccess,
  fetchCountryByIdFailure,
  createCountryStart,
  createCountrySuccess,
  createCountryFailure,
  deleteCountryStart,
  deleteCountrySuccess,
  deleteCountryFailure,
  changeStatusCountryStart,
  changeStatusCountrySuccess,
  changeStatusCountryFailure,
  editCountryStart,
  editCountrySuccess,
  editCountryFailure,

  //constant
  fetchConstantStart,
  fetchConstantSuccess,
  fetchConstantFailure,
  fetchConstantByIdStart,
  fetchConstantByIdSuccess,
  fetchConstantByIdFailure,
  createConstantStart,
  createConstantSuccess,
  createConstantFailure,
  deleteConstantStart,
  deleteConstantSuccess,
  deleteConstantFailure,
  editConstantStart,
  editConstantSuccess,
  editConstantFailure,
  //constant

  // Multilingual
  fetchMultilingualStart,
  fetchMultilingualSuccess,
  fetchMultilingualFailure,
  fetchMultilingualByIdStart,
  fetchMultilingualByIdSuccess,
  fetchMultilingualByIdFailure,
  createMultilingualStart,
  createMultilingualSuccess,
  createMultilingualFailure,
  deleteMultilingualStart,
  deleteMultilingualSuccess,
  deleteMultilingualFailure,
  editMultilingualStart,
  editMultilingualSuccess,
  editMultilingualFailure,
  // Multilingual

  // ContentSection start
  fetchContentSectionStart,
  fetchContentSectionSuccess,
  fetchContentSectionFailure,
  fetchContentSectionByIdStart,
  fetchContentSectionByIdSuccess,
  fetchContentSectionByIdFailure,
  createContentSectionStart,
  createContentSectionSuccess,
  createContentSectionFailure,
  deleteContentSectionStart,
  deleteContentSectionSuccess,
  deleteContentSectionFailure,
  editContentSectionStart,
  editContentSectionSuccess,
  editContentSectionFailure,
  // ContentSection end

  // section
  fetchSectionStart,
  fetchSectionSuccess,
  fetchSectionFailure,
  fetchSectionByIdStart,
  fetchSectionByIdSuccess,
  fetchSectionByIdFailure,
  createSectionStart,
  createSectionSuccess,
  createSectionFailure,
  deleteSectionStart,
  deleteSectionSuccess,
  deleteSectionFailure,
  editSectionStart,
  editSectionSuccess,
  editSectionFailure,
  // section

  // section
  fetchAboutUsStart,
  fetchAboutUsSuccess,
  fetchAboutUsFailure,
  fetchAboutUsByIdStart,
  fetchAboutUsByIdSuccess,
  fetchAboutUsByIdFailure,
  createAboutUsStart,
  createAboutUsSuccess,
  createAboutUsFailure,
  deleteAboutUsStart,
  deleteAboutUsSuccess,
  deleteAboutUsFailure,
  editAboutUsStart,
  editAboutUsSuccess,
  editAboutUsFailure,
  // section
  fetchSocialMediaListStart,
  fetchSocialMediaListSuccess,
  fetchSocialMediaListFailure,

  createSocialMediaStart,
  createSocialMediaSuccess,
  createSocialMediaFailure,

  editSocialMediaStart,
  editSocialMediaSuccess,
  editSocialMediaFailure,

  deleteAboutUsBySlugStart,
  deleteAboutUsBySlugSuccess,
  deleteAboutUsBySlugFailure,

  translateIntoCountryLanguageStart,
  translateIntoCountryLanguageSuccess,
  translateIntoCountryLanguageFailure,

  createHomeVideoStart,
  createHomeVideoSuccess,
  createHomeVideoFailure,

  fetchAllHomeVideoStart,
  fetchAllHomeVideoSuccess,
  fetchAllHomeVideoFailure,

  editHomeVideoStart,
  editHomeVideoSuccess,
  editHomeVideoFailure,

  fetchAllFileRepositoryStart,
  fetchAllFileRepositorySuccess,
  fetchAllFileRepositoryFailure,

  createFileRepositoryStart,
  createFileRepositorySuccess,
  createFileRepositoryFailure,

  fetchFileRepositoryByIdStart,
  fetchFileRepositoryByIdSuccess,
  fetchFileRepositoryByIdFailure,

  editFileRepositoryStart,
  editFileRepositorySuccess,
  editFileRepositoryFailure,
} = websiteAdmin.actions

export default websiteAdmin.reducer
