import { createSlice } from '@reduxjs/toolkit'

import { IMerchantPackage } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IMerchantPackage = {
  merchantPackageList: listObj,
  soleMerchantPackage: {},
  lastMerchantPackageLog: {},
  upgradeAmount: {},
  earning: listObj,
  payable: listObj,
  error: null,
  loading: false,
  isFetching: false,
  isFetchingAmount: false,
  isCreating: false,
  hasMoreData: false,
}

export const merchantPackageSlice = createSlice({
  name: 'merchantPackage',
  initialState: INITIAL_STATE,
  reducers: {
    fetchEarningStart: (state: any, action) => {
      state.loading = true
    },

    fetchEarningSuccess: (state: any, action) => {
      state.earning = action.payload
      state.loading = false
    },
    fetchEarningFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchPayableStart: (state: any, action) => {
      state.loading = true
    },

    fetchPayableSuccess: (state: any, action) => {
      state.payable = action.payload
      state.loading = false
    },
    fetchPayableFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPayableSetupFeeStart: (state: any, action) => {
      state.loading = true
    },

    fetchPayableSetupFeeSuccess: (state: any, action) => {
      state.payable = action.payload
      state.loading = false
    },
    fetchPayableSetupFeeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantPackageStart: (state: any, action) => {
      state.loading = true
    },

    fetchMerchantPackageSuccess: (state: any, action) => {
      state.merchantPackageList = {
        ...action.payload,
        data: action?.payload?.data?.sort(
          (a, b) => a?.packageFee + a?.setupFee - (b?.packageFee + b?.setupFee)
        ),
      }
      state.loading = false
    },
    fetchMerchantPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMerchantPackageByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantPackageByIdSuccess: (state: any, action) => {
      state.soleMerchantPackage = action.payload.data
      state.isFetching = false
    },
    fetchMerchantPackageByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createMerchantPackageStart: (state: any, action) => {
      state.loading = true
    },
    createMerchantPackageSuccess: (state: any, action) => {
      state.loading = false
      state.merchantPackageList.data = [
        ...state.merchantPackageList.data,
        action.payload,
      ]
    },
    createMerchantPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteMerchantPackageStart: (state: any, action) => {
      state.loading = true
    },
    deleteMerchantPackageSuccess: (state: any, action) => {
      state.loading = false
      state.merchantPackageList.data = state.merchantPackageList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteMerchantPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editMerchantPackageStart: (state: any, action) => {
      state.loading = true
    },
    editMerchantPackageSuccess: (state: any, action) => {
      const result = state.merchantPackageList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.merchantPackageList.data = result

      state.loading = false
    },
    editMerchantPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    upgradeMerchantPackageStart: (state: any, action) => {
      state.isCreating = true
    },
    upgradeMerchantPackageSuccess: (state: any, action) => {
      state.isCreating = false
    },
    upgradeMerchantPackageFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    upgradeMerchantPackageByMerchantIdStart: (state: any, action) => {
      state.isCreating = true
    },
    upgradeMerchantPackageByMerchantIdSuccess: (state: any, action) => {
      state.isCreating = false
    },
    upgradeMerchantPackageByMerchantIdFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchLastMerchantPackageLogByMerchantIdStart: (state: any, action) => {
      state.isFetching = true
    },

    fetchLastMerchantPackageLogByMerchantIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchLastMerchantPackageLogByMerchantIdSuccess: (state: any, action) => {
      state.lastMerchantPackageLog = action.payload.data
      state.isFetching = false
    },

    fetchUpgradeAmountStart: (state: any, action) => {
      state.isFetchingAmount = true
    },

    fetchUpgradeAmountFailure: (state: any, action) => {
      state.isFetchingAmount = false
      state.error = action.payload
    },

    fetchUpgradeAmountSuccess: (state: any, action) => {
      state.upgradeAmount = action.payload.data
      state.isFetchingAmount = false
    },

    fetchMerchantPackageDropDownStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantPackageDropDownSuccess: (state: any, action) => {
      state.merchantPackageList = {
        ...action.payload,
        data: action?.payload?.data?.sort(
          (a, b) => a?.packageFee + a?.setupFee - (b?.packageFee + b?.setupFee)
        ),
      }
      state.loading = false
    },
    fetchMerchantPackageDropDownFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearUpgradeAmount: (state: any) => {
      state.upgradeAmount = {}
    },
  },
})

export const {
  fetchEarningStart,
  fetchEarningFailure,
  fetchEarningSuccess,

  fetchPayableStart,
  fetchPayableFailure,
  fetchPayableSuccess,

  fetchPayableSetupFeeStart,
  fetchPayableSetupFeeFailure,
  fetchPayableSetupFeeSuccess,

  fetchMerchantPackageStart,
  fetchMerchantPackageSuccess,
  fetchMerchantPackageFailure,

  fetchMerchantPackageDropDownStart,
  fetchMerchantPackageDropDownSuccess,
  fetchMerchantPackageDropDownFailure,

  createMerchantPackageStart,
  createMerchantPackageSuccess,
  createMerchantPackageFailure,
  deleteMerchantPackageStart,
  deleteMerchantPackageSuccess,
  deleteMerchantPackageFailure,
  fetchMerchantPackageByIdStart,
  fetchMerchantPackageByIdSuccess,
  fetchMerchantPackageByIdFailure,
  editMerchantPackageStart,
  editMerchantPackageSuccess,
  editMerchantPackageFailure,

  upgradeMerchantPackageStart,
  upgradeMerchantPackageSuccess,
  upgradeMerchantPackageFailure,

  upgradeMerchantPackageByMerchantIdStart,
  upgradeMerchantPackageByMerchantIdSuccess,
  upgradeMerchantPackageByMerchantIdFailure,

  fetchLastMerchantPackageLogByMerchantIdStart,
  fetchLastMerchantPackageLogByMerchantIdFailure,
  fetchLastMerchantPackageLogByMerchantIdSuccess,

  fetchUpgradeAmountStart,
  fetchUpgradeAmountSuccess,
  fetchUpgradeAmountFailure,

  clearUpgradeAmount,
} = merchantPackageSlice.actions

export default merchantPackageSlice.reducer
