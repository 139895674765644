import { takeLatest, call, all, put, select } from 'redux-saga/effects'
import {
  fetchCharityStart,
  fetchCharitySuccess,
  fetchCharityFailure,
  deleteCharitySuccess,
  deleteCharityFailure,
  fetchCharityByIdSuccess,
  fetchCharityByIdFailure,
  createCharitySuccess,
  createCharityFailure,
  editCharitySuccess,
  editCharityFailure,
  fetchPendingCharityStart,
  fetchPendingCharitySuccess,
  fetchPendingCharityFailure,
  deletePendingCharityStart,
  deletePendingCharitySuccess,
  deletePendingCharityFailure,
  verifyCharitySuccess,
  verifyCharityFailure,
  rejectCharitySuccess,
  rejectCharityFailure,
  updatePendingCharityFailure,
  updatePendingCharitySuccess,
  sendCharityReqSuccess,
  sendCharityReqFailure,
  fetchCharityProfileInfoStart,
  fetchCharityProfileInfoSuccess,
  fetchCharityProfileInfoFailure,
  fetchCharityByPanelSuccess,
  fetchCharityByPanelFailure,
  fetchAllCharitySuccess,
  fetchAllCharityFailure,
  editOwnCharityProfileSuccess,
  editOwnCharityProfileFailure,
  getCharityReferralTokenSuccess,
  getCharityReferralTokenFailure,
  fetchAllCharityByLocationFailure,
  fetchAllCharityByLocationSuccess,
} from './chartiesSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchCharityList,
  createCharity,
  fetchCharityById,
  editCharity,
  rejectCharity,
  verifyCharity,
  deleteCharity,
  saveUpdatePendingCharity,
  sendCharityReq,
  fetchCharityProfileInfo,
  fetchCharityListByPanel,
  fetchAllCharity,
  editOwnCharityProfile,
  getCharityReferralToken,
  fetchAllCharityByLocation,
} from '../../api/charity'
import { deleteImage, getImageUrl, uploadImage } from 'api/imageUpload'
import axios from 'axios'
import { getImageIdFromUrl, removeParam } from 'utils'

import _ from 'lodash'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchPendingCharityAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCharityList(payload))

    yield put(fetchPendingCharitySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchPendingCharityFailure(err))
    console.error(err)
  }
}

export function* fetchCharityAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCharityList(payload))

    yield put(fetchCharitySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCharityFailure(err))
    console.error(err)
  }
}

export function* fetchCharityByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCharityListByPanel(payload))

    yield put(fetchCharityByPanelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCharityByPanelFailure(err))
    console.error(err)
  }
}

export function* fetchCharityProfileInfoInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCharityProfileInfo())
    yield put(fetchCharityProfileInfoSuccess(data))
  } catch (err) {
    yield put(fetchCharityProfileInfoFailure(err))
  }
}

export function* createCharityAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    let imageResponse

    if (!_.isEmpty(payload?.params?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createCharity({ ...payload.params, logoUrl: logoUrl })
      )

      yield put(createCharitySuccess(data?.results))

      yield put(
        fetchCharityStart({
          page: 1,
          limit: 10,
          offset: 0,
          isPending: false,
          countryId: siteCoordinator?.globalCountry?.id,
        })
      )

      yield payload.closeModal()
    } else {
      const { data } = yield call(createCharity(payload?.params))
      yield put(createCharitySuccess(data?.results))
      yield put(
        fetchCharityStart({
          page: 1,
          limit: 10,
          offset: 0,
          isPending: false,
          countryId: siteCoordinator?.globalCountry?.id,
        })
      )
    }

    yield put(
      openAlert({
        message: 'Charity Successfully Created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createCharityFailure(err))

    console.error(err)
  }
}

export function* deleteCharityAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      deleteCharity(payload?.id, { rejectReason: payload?.rejectReason })
    )

    yield put(deleteCharitySuccess(payload?.id))
    yield payload.closeDeleteModal()
    yield put(
      openAlert({
        message: 'Charity Successfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteCharityFailure(err))
  }
}

export function* deletePendingCharityAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      rejectCharity(payload?.id, { rejectReason: payload?.rejectReason })
    )

    yield put(deleteCharitySuccess(payload?.id))
    yield payload.closeDeleteModal()
    yield put(
      openAlert({
        message: 'Pending Charity Successfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteCharityFailure(err))
    console.error(err)
  }
}

export function* fetchCharityByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCharityById(payload))

    yield put(
      fetchCharityByIdSuccess({
        ...data?.data,
        issuerCode: data?.issuerCode,
        allowMerchantCreate: data?.allowMerchantCreate,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchCharityByIdFailure(err))
    console.error(err)
  }
}

export function* editCharityAsync({ payload }: AnyAction) {
  try {
    // const { data } = yield call(
    //   editCharity(payload?.params?.id, payload?.params)
    // )

    // yield put(editCharitySuccess(data?.data))
    // yield payload?.closeModal()
    // yield put(
    //   openAlert({ message: 'Charity Successfully Edited', severity: 'success' })
    // )

    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        editCharity(payload?.params?.id, { ...payload?.params, logoUrl })
      )

      yield put(editCharitySuccess(data?.data))

      yield payload.closeModal()
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(editCharity(payload?.params?.id, uploadData))
      yield put(editCharitySuccess(data?.results))
      yield payload.closeModal()
    }

    yield put(
      fetchCharityStart({
        page: 1,
        limit: 10,
        offset: 0,
        isPending: false,
        countryId: payload?.params?.countryId,
      })
    )

    yield put(
      openAlert({ message: 'Charity Successfully Edited', severity: 'success' })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editCharityFailure(err))
    console.error(err)
  }
}

export function* verifyCharityAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''

      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        verifyCharity(payload?.params?.id, {
          ...payload?.params,
          logoUrl,
        })
      )

      yield put(verifyCharitySuccess(data?.data))

      yield payload.closeModal()
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(
        verifyCharity(payload?.params?.id, uploadData)
      )
      yield put(verifyCharitySuccess(data?.data))
      yield payload.closeModal()
    }

    // const { data } = yield call(
    //   verifyCharity(payload?.params?.id, payload?.params)
    // )

    // yield put(verifyCharitySuccess(data?.data))
    // yield payload?.closeModal()

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({ message: 'succesfully verified', severity: 'success' })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(verifyCharityFailure(err))
    console.error(err)
  }
}

export function* rejectCharityAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(rejectCharity(payload?.id, payload))

    yield put(rejectCharitySuccess(payload?.id))
    yield payload.closeModal()
    yield put(
      openAlert({ message: 'succesfully rejected', severity: 'success' })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(rejectCharityFailure(err))
    console.error(err)
  }
}

export function* updatePendingCharityAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        saveUpdatePendingCharity(payload?.params?.id, {
          ...payload?.params,
          logoUrl,
        })
      )

      yield put(updatePendingCharitySuccess(data?.data))

      yield payload.closeModal()
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(
        saveUpdatePendingCharity(payload?.params?.id, uploadData)
      )
      yield put(updatePendingCharitySuccess(data?.data))
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Charity Request Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(updatePendingCharityFailure(err))
    console.error(err)
  }
}

export function* sendCharityReqAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        sendCharityReq({ ...payload.params, logoUrl: logoUrl })
      )

      yield put(sendCharityReqSuccess(data?.data))

      if (payload?.refreshPageData) {
        yield call(payload?.refreshPageData)
      }
      yield payload.closeModal()
    } else {
      const { data } = yield call(sendCharityReq(payload?.params))
      yield put(sendCharityReqSuccess(data?.data))
    }

    yield put(
      openAlert({
        message: 'Charity Request Sent Successfully',
        severity: 'success',
      })
    )
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchCharityStart({
          page: 1,
          limit: 10,
          offset: 0,
          signerId: payload?.params?.signerId,
          countryId: payload?.params?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(sendCharityReqFailure(err))

    console.error(err)
  }
}

export function* fetchAllCharityAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllCharity(payload))

    yield put(fetchAllCharitySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllCharityFailure(err))
    console.error(err)
  }
}

export function* fetchAllCharityByLocationAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllCharityByLocation(payload))

    yield put(fetchAllCharityByLocationSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllCharityByLocationFailure(err))
    console.error(err)
  }
}

export function* editOwnCharityProfileAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        editOwnCharityProfile({ ...payload?.params, logoUrl })
      )

      yield put(editOwnCharityProfileSuccess(data?.data))
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(editOwnCharityProfile(uploadData))
      yield put(editOwnCharityProfileSuccess(data?.data))
    }
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    yield put(
      openAlert({
        message: 'Charity Profile Succesfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editOwnCharityProfileFailure(err))
    console.error(err)
  }
}

export function* getCharityReferralTokenAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getCharityReferralToken())
    yield put(getCharityReferralTokenSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(getCharityReferralTokenFailure(err))
  }
}

export function* watchPendingCharity() {
  yield takeLatest('charity/fetchPendingCharityStart', fetchPendingCharityAsync)
}

export function* watchFetchCharity() {
  yield takeLatest('charity/fetchCharityStart', fetchCharityAsync)
}

export function* watchFetchCharityProfileInfo() {
  yield takeLatest(
    'charity/fetchCharityProfileInfoStart',
    fetchCharityProfileInfoInfoAsync
  )
}

export function* watchCreateCharity() {
  yield takeLatest('charity/createCharityStart', createCharityAsync)
}

export function* watchVerifyCharity() {
  yield takeLatest('charity/verifyCharityStart', verifyCharityAsync)
}

export function* watchUpdatePendingCharity() {
  yield takeLatest(
    'charity/updatePendingCharityStart',
    updatePendingCharityAsync
  )
}

export function* watchDeletePendingCharity() {
  yield takeLatest(
    'charity/deletePendingCharityStart',
    deletePendingCharityAsync
  )
}

export function* watchRejectCharity() {
  yield takeLatest('charity/rejectCharityStart', rejectCharityAsync)
}

export function* watchDeleteCharity() {
  yield takeLatest('charity/deleteCharityStart', deleteCharityAsync)
}

export function* watchFetchPendingCharityById() {
  yield takeLatest('charity/fetchCharityByIdStart', fetchCharityByIdAsync)
}

export function* watchEditCharity() {
  yield takeLatest('charity/editCharityStart', editCharityAsync)
}

export function* watchSendCharityReq() {
  yield takeLatest('charity/sendCharityReqStart', sendCharityReqAsync)
}

export function* watchFetchCharityByPanel() {
  yield takeLatest('charity/fetchCharityByPanelStart', fetchCharityByPanelAsync)
}

export function* watchFetchAllCharity() {
  yield takeLatest('charity/fetchAllCharityStart', fetchAllCharityAsync)
}

export function* watchFetchAllCharityByLocation() {
  yield takeLatest(
    'charity/fetchAllCharityByLocationStart',
    fetchAllCharityByLocationAsync
  )
}

export function* watchEditOwnCharityProfile() {
  yield takeLatest(
    'charity/editOwnCharityProfileStart',
    editOwnCharityProfileAsync
  )
}

export function* watchGetCharityReferralToken() {
  yield takeLatest(
    'charity/getCharityReferralTokenStart',
    getCharityReferralTokenAsync
  )
}

export function* charitiesSagas() {
  yield all([
    call(watchPendingCharity),
    call(watchFetchCharity),
    call(watchFetchCharityProfileInfo),
    call(watchCreateCharity),
    call(watchDeletePendingCharity),
    call(watchFetchPendingCharityById),
    call(watchEditCharity),
    call(watchVerifyCharity),
    call(watchUpdatePendingCharity),
    call(watchDeleteCharity),
    call(watchRejectCharity),
    call(watchSendCharityReq),
    call(watchFetchCharityByPanel),
    call(watchFetchAllCharity),
    call(watchFetchAllCharityByLocation),
    call(watchEditOwnCharityProfile),
    call(watchGetCharityReferralToken),
    // call(watchEditCountry),
  ])
}
