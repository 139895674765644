/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { sendClubReqStart } from 'store/club/clubSlice'
import {
  createMerchantDiscountStart,
  fetchMerchantDraftDiscountInfoPanelUserStart,
  createStep4GeneralStart,
  createStep4DraftStart,
  clearMerchantPremiumCode,
} from 'store/merchant/merchantSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
  merchant: state.merchant,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  onCreateMerchantDiscountStart: (payload) =>
    dispatch(createMerchantDiscountStart(payload)),
  onFetchMerchantDraftDiscountInfoPanelUserStart: (payload) =>
    dispatch(fetchMerchantDraftDiscountInfoPanelUserStart(payload)),
  onCreateStep4GeneralStart: (payload) =>
    dispatch(createStep4GeneralStart(payload)),
  onCreateStep4DraftStart: (payload) =>
    dispatch(createStep4DraftStart(payload)),
  onClearMerchantPremiumCode: () => dispatch(clearMerchantPremiumCode()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
