// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const getPremiumCodes = (params) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPackageLog/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getPremiumCodesByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPackageLog/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getMerchantPremiumCodes = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPackageLog/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
// getMerchantPremiumCodesByPanel,
export const getMerchantPremiumCodesByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPremiumCode/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
// getMemberPremiumCodesByPanel,
export const getMemberPremiumCodesByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/memberPremiumCode/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getPremiumMemberCodesList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/memberPremiumCode/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getPremiumMerchantCodesList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPremiumCode/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getPremiumMerchantCodesAll = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPremiumCode/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getPremiumMemberCodesAll = (params) => {
  return () =>
    axiosRequest
      .get(`/api/memberPremiumCode/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const updatePremiumMerchantCode = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchantPremiumCode/updateGiveaway/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const updatePremiumMemberCode = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/memberPremiumCode/updateGiveaway/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPremiumMemberCode = (params) => {
  return () =>
    axiosRequest
      .post(`/api/memberPremiumCode/bulkCreateCode/`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPremiumMerchantCode = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchantPremiumCode/bulkCreateCode/`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const assignPremiumMemberCode = (params) => {
  return () =>
    axiosRequest
      .post(`/api/memberPremiumCode/addOwnerToMultipleCodes/`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const assignPremiumMerchantCode = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchantPremiumCode/addOwnerToMultipleCodes/`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAssignablePremiumMerchantCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPremiumCode/assignable/count`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAssignablePremiumMemberCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/memberPremiumCode/assignable/count`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchUnusedPremiumCodeByAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPremiumCode/getAllUnUsedCodesByAdmin`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchUnusedPremiumCodeByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPremiumCode/getAllUnUsedCodesByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
