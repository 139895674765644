import { createContactUs } from 'api/contactUs'
import { connect } from 'react-redux'

import { createContactUsStart } from 'store/contactUs/contactUsSlices'
import { openAlert } from 'store/alert/alertSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateContactUsMessage: (payload) =>
    dispatch(createContactUsStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
