import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import Terminal from './Terminal'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import AllNotificationList from 'components/AllNotificationList'
import ConnectStripe from 'components/Payments/ConnectStripe'
const MembersList = React.lazy(() => import('./Members/MembersList'))
// const MemberCodes = React.lazy(() => import('./PremiumCodes/MemberCodes'))
// const MemberCodesList = React.lazy(
//   () => import('./PremiumCodes/MemberCodesList')
// )
const FriendshipEarningAsMerchant = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchant')
)
const FriendshipEarningAsMerchantDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchant')
)

const FriendshipEarningAsMerchantTerminalDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantForTerminal')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const SupposedFriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/supposedToEarnAsIssuer')
)

const SupposedMembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/DetailsPages/supposedToEarnAsIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)
const PaymentMethod = React.lazy(() => import('./MyAccount/PaymentMethod'))

const UpgradePaymentMethod = React.lazy(
  () => import('./MyAccount/UpgradePayment')
)

const MerchantWallet = React.lazy(() => import('./Wallet/'))
const TerminalMerchantWallet = React.lazy(() => import('./TerminalUserWallet'))

const BillingInfo = React.lazy(() => import('./MyAccount/BillingInfo'))
const Users = React.lazy(() => import('./Users'))

// const MerchantCodesList = React.lazy(
//   () => import('components/PremiumCodes/MerchantCodesList')
// )
// const MemberCodesList = React.lazy(
//   () => import('components/PremiumCodes/MemberCodesList')
// )
const MerchantCodes = React.lazy(() => import('./PremiumCodes/MerchantCodes'))
const MemberCodes = React.lazy(() => import('./PremiumCodes/MemberCodes'))
const CodesHistory = React.lazy(() => import('./PremiumCodes/CodesHistory'))

const MerchantCodesList = React.lazy(
  () => import('./PremiumCodes/MerchantCodesList')
)
const MemberCodesList = React.lazy(
  () => import('./PremiumCodes/MemberCodesList')
)

const Aggrements = React.lazy(
  () => import('./Agreements/NewAggrements.component')
)
const IntegrationGuide = React.lazy(() => import('./IntegrationGuide'))

const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)

const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))

const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)

const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)

export const merchantAdminConfig = [
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/memberslist',
    element: <MembersList />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/premium-merchant-codes',
    element: <MerchantCodesList />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/premium-member-codes',
    element: <MemberCodesList />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/premium-codes-history',
    element: <CodesHistory />,
  },

  // {
  //   permittedRole: RoutePermittedRole.merchant,
  //   path: '/merchant/premium-member-codes-list/:id',
  //   element: <MemberCodesList />,
  // },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/friendship-earning/asmerchant',
    element: <FriendshipEarningAsMerchant />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/friendship-earning/asmerchant/detail',
    element: <FriendshipEarningAsMerchantDetail />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/friendship-earning/asmerchantTerminal/detail',
    element: <FriendshipEarningAsMerchantTerminalDetail />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/friendship-earning/supposed-earning-as-issuer',
    element: <SupposedFriendshipEarningAsIssuer />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/membership-earning/supposed-earning-as-issuer',
    element: <SupposedMembershipEarningAsIssuer />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/merchant-wallet',
    element: <MerchantWallet />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/terminal-merchant-wallet',
    element: <TerminalMerchantWallet />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/payment-method',
    element: <PaymentMethod />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/upgrade-payment',
    element: <UpgradePaymentMethod />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/billing-info',
    element: <BillingInfo />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/agreements',
    element: <Aggrements />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/integrationGuide',
    element: <IntegrationGuide />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/users',
    element: <Users />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/terminal',
    element: <Terminal />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/coupons',
    element: <Coupons adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/coupon-codes/:id',
    element: <CouponCodes />,
  },
  // {
  //   permittedRole: RoutePermittedRole.merchant,
  //   path: '/merchant/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/setting/smtp',
    element: <SMTP adminRoute={'merchant'} />,
  },
  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/setting/group',
    element: <Group adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/setting/contacts',
    element: <Contacts adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/setting/documents',
    element: <Documents adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/setting/emails',
    element: <Emails adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/setting/recipients',
    element: <Recipients adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/setting/reports/:id',
    element: <Reports adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/all-notification',
    element: <AllNotificationList adminRoute={'merchant'} />,
  },

  {
    permittedRole: RoutePermittedRole.merchant,
    path: '/merchant/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'merchant'} />,
  },
]
