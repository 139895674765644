import { axiosRequestCMS, removeEmptyFields } from 'utils'

export const fetchPageList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/page/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPageById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/page/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPage = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/page/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deletePage = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/page/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editPage = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(
        `/api/page/edit/${id}?isTranslateToAlllang=${params.isTranslateToAlllang}`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
