import React, { useEffect, useState } from 'react'
import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'
import AppComponentHeader from '@crema/core/AppComponentHeader'
import AppCard from '@crema/core/AppCard'
import ListTop from './components/ListTop/ListTop'
import PrizeDrawTable from './components/PrizeDrawTable'
import GlobalAdminPrizeDrawTable from './components/GlobalAdminPrizeDrawTable'

import container from './PrizeDraw.container'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

function handleClick(event) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

const CustomizedBreadcrumbs = () => {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {/* <StyledBreadcrumb label="Setup" icon={<HomeIcon fontSize="small" />} /> */}
        <StyledBreadcrumb label="Prize Draw" />
      </Breadcrumbs>
    </div>
  )
}

const PrizeDrawList = ({
  onFetchPrizeDrawStart,
  onFetchGlobalAdminPrizeDrawStart,
  onDeleteTrainingVideoStart,
  prizeDraw: {
    globalAdminPrizeDrawList: {
      data: globalPrizeDrawData,
      hasMore: globalAdminHasMore,
      totalCount: globalAdminTotalCount,
      count: globalAdminCount,
      page: globalAdminPageNumber,
    },
    prizeDrawListData: { data, hasMore, totalCount, count, page: pageNumber },
    isDeleting,
    loading,
  },
  auth,
  ...rest
}) => {
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterFormData, setFilterFormData] = useState({})

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setOffset(offset + rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const clearFilterForm = () => {
    setFilterFormData({})
  }

  useEffect(() => {
    if (auth?.currentUser?.role?.toLowerCase() === 'globaladmin') {
      onFetchGlobalAdminPrizeDrawStart({
        page: page + 1,
        limit: rowsPerPage,
        offset,
        ...filterFormData,
      })
    } else {
      onFetchPrizeDrawStart({
        page: page + 1,
        limit: rowsPerPage,
        offset,
        ...filterFormData,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, filterFormData])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <AppComponentHeader title="Prize Draw" />
        <CustomizedBreadcrumbs />
      </Box>
      <ListTop
        setFilterFormData={(data) => {
          setFilterFormData(data)
          setPage(0)
        }}
        currentUser={auth?.currentUser}
        clearFilterForm={() => {}}
        {...rest}
      />

      {auth?.currentUser?.role?.toLowerCase() === 'globaladmin' ? (
        <AppCard>
          <GlobalAdminPrizeDrawTable
            prizeDrawData={globalPrizeDrawData}
            page={globalAdminPageNumber}
            pageNumber={globalAdminPageNumber}
            totalCount={globalAdminTotalCount}
            loadingTable={loading}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            hasMoreData={globalAdminHasMore}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onDeleteTrainingVideoStart={onDeleteTrainingVideoStart}
            auth={auth}
          />
          <Box style={{ height: 30 }} />
        </AppCard>
      ) : (
        <AppCard>
          <PrizeDrawTable
            prizeDrawData={data}
            page={page}
            pageNumber={pageNumber}
            totalCount={totalCount}
            loadingTable={loading}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            hasMoreData={hasMore}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onDeleteTrainingVideoStart={onDeleteTrainingVideoStart}
          />
          <Box style={{ height: 30 }} />
        </AppCard>
      )}
    </Box>
  )
}

export default container(PrizeDrawList)
