// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { bulkDownloadAxiosRequest, removeEmptyFields } from 'utils'

//all issuercode without filter
export const fetchIssuerCodeListAll = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getAllByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIssuerCodeListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getAllUnUsedIssuerCodesByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

//used for countryPanel
export const fetchNotUsedIssuerCodeListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getAllNotUsedCodes`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIssuerCodeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIssuerCodeAssignableCountForOthers = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/assignable/count`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIssuerCodeAssignableCountForGMC = (id) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/assignable/byGMC/count/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAssigner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getMultipleAssignerByRole`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createIssuerBatch = (params) => {
  return () =>
    bulkDownloadAxiosRequest
      .post(`/api/issuerCodeBatch/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editIssuerBatch = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/issuerCodeBatch/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const addAssigner = (params) => {
  return () =>
    axiosRequest
      .post(`/api/issuerCode/addAssignerToCodes`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const addGMCAssigner = (params) => {
  return () =>
    axiosRequest
      .post(`/api/issuerCode/assignMultipleCodesToGMC`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createIssuerBatchCSV = (params) => {
  return () =>
    axiosRequest
      .post(`/api/issuerCode/printStatusChangeCSV`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIssuerIssuerCodeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getMultipleAssigner`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIssuerBatch = (params) => {
  return () =>
    bulkDownloadAxiosRequest
      .get(`/api/issuerCodeBatch/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIssuerGmcBatch = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCodeBatch/getAllGMCBatch`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllGmcIssuerCodeByBatch = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getAllGMCCodesbybatch/${params.batchId}`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const addWhiteLabelIssuerAssigner = (params) => {
  return () =>
    axiosRequest
      .post(`/api/issuerCode/addWhiteLabelAssignerToCodes`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllNotUsedForAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getAllNotUsedForAdmin`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIssuerQrCodesListByMerchantId = (id) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getAllIssuerCode/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchStateIssuerQrCodesListByMerchantId = (id) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getStateIssuerCode/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchRegionIssuerQrCodesListByMerchantId = (id) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getRegionIssuerCode/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAreaIssuerQrCodesListByMerchantId = (id) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getAreaIssuerCode/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryIssuerQrCodesListByMerchantId = (id) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getCountryIssuerCode/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOwnIssuerCode = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getOwnIssuerCode`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteIssuerCodeById = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/issuerCode/deleteMerchantAssignedCode/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteIssuerCodeBatch = (id) => {
  return () =>
    axiosRequest
      .delete(`api/issuerCodeBatch/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteIssuerCode = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/issuerCode/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const downloadPreBatchIssuerCode = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCodeBatch/downloadPreBatch/${params?.batchName}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const downloadOnBatchIssuerCode = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCodeBatch/downloadBatch/${params?.zipFileName}`, {
        responseType: 'blob',
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res?.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${params?.zipFileName}`)
        document.body.appendChild(link)
        link.click()
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOneIssuerCodeBatch = (id) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCodeBatch/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOneIssuerQrCodePreview = (id) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCodeBatch/getQrCode/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
