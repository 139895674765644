import React from 'react'
import AppCard from '@crema/core/AppCard'

import { Box, Grid, TextField, Button } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import AreaSelect from 'components/DropdownComponents/AreaSelect'
import RegionSelect from 'components/DropdownComponents/RegionSelect'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { removeEmptyFields } from 'utils'
import StatusSelect from 'components/DropdownComponents/StatusSelect'
import moment from 'moment-timezone'

const ListTop = ({
  setFilterFormData,
  clearFilterForm,
  globalCountry,
  currentUser,
  ...rest
}) => {
  const validationSchema = yup.object({
    name: yup.string(),
    areaId: yup.object().nullable(),
  })

  const checkSubscriptionStatus = (data) => {
    if (data === 'notStarted') {
      return 'null'
    } else if (data === 'activated') {
      return 'sub'
    } else {
      return ''
    }
  }

  const onSubmitSearch = (data) => {
    const modifiedPremiumDate = data?.registrationDate
      ? moment(new Date(data?.registrationDate))
      : null

    setFilterFormData(
      removeEmptyFields({
        id: data?.id,
        countryId: data?.countryId?.id ?? null,
        stateId: data?.stateId?.id,
        regionId: data?.regionId?.id,
        areaId: data?.areaId?.id,
        merchantName__substring: data?.merchantName__substring,
        merchantEmail: data?.merchantEmail,
        merchantPhoneNumber: data?.merchantPhoneNumber,
        signerType: data?.signerType?.name,
        bulkEmailSend: data?.bulkEmailSend?.name,
        isPremium: data?.isPremium?.name,
        username__substring: data?.username__substring,
        registrationStatus: data?.registrationStatus?.name,
        agreementStatus: data?.agreementStatus?.name,
        createdAt__between: modifiedPremiumDate
          ? `${modifiedPremiumDate.format('DD-MM-YYYY')}:${modifiedPremiumDate
              .add(1, 'days')
              .format('DD-MM-YYYY')}`
          : null,
        stripeSubscriptionId__substring: checkSubscriptionStatus(
          data?.stripeSubscriptionId__substring?.name
        ),
        mailSentBySigner: data?.mailSentBySigner?.name,
        isReferredByMember: data?.isReferredByMember?.name,
      })
    )
  }

  const selectOption: any = [
    { label: 'Verified', name: 'verified' },
    { label: 'Rejected', name: 'rejected' },
    { label: 'Pending', name: 'pending' },
  ]

  const emailOption: any = [
    { label: 'Yes', name: true },
    { label: 'No', name: false },
  ]

  const premiumOption: any = [
    { label: 'Yes', name: true },
    { label: 'No', name: false },
  ]

  const subscriptionSelectOption: any = [
    { label: 'Activated', name: 'activated' },
    { label: 'Not started', name: 'notStarted' },
  ]

  const merchantEmailOption: any = [
    { label: 'Yes', name: true },
    { label: 'No', name: false },
  ]

  const referredByMembeOption: any = [
    { label: 'Yes', name: true },
    { label: 'No', name: false },
  ]

  return (
    <>
      <AppCard>
        <Box>
          <Formik
            validateOnChange={true}
            initialValues={{
              id: '',
              countryId: '',
              stateId: '',
              regionId: '',
              areaId: '',
              merchantName__substring: '',
              merchantEmail: '',
              merchantPhoneNumber: '',
              createdAt: '',
              agreementStatus: '',
              registrationStatus: '',
              status: '',
              signerName: '',
              signerType: '',
              bulkEmailSend: '',

              username__substring: '',
              isPremium: '',
              registrationDate: null,
              mailSentBySigner: null,

              stripeSubscriptionId__substring: '',
              isReferredByMember: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)
              onSubmitSearch(data)

              setSubmitting(false)
            }}
          >
            {({ resetForm, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  {/* {currentUser?.role?.toLowerCase() ===
                    'collaborationpartner' &&
                    currentUser?.userCountry?.partnerType === 'global' && (
                      <Grid item xs={12} lg={2}>
                        <Field name="countryId" component={CountrySelect} />
                      </Grid>
                    )} */}

                  {/* {currentUser?.role?.toLowerCase() ===
                    'collaborationpartner' &&
                  currentUser?.userCountry?.partnerType === 'global' ? (
                    <Grid item xs={12} lg={2}>
                      <Field
                        name="stateId"
                        component={StateSelect}
                        filterByCountryId={values?.countryId}
                        defaultSelected={values?.countryId}
                        makeDefaultEmpty={true}
                        includeFilterByCountry={true}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} lg={2}>
                      <Field
                        name="stateId"
                        component={StateSelect}
                        defaultSelected={globalCountry}
                      />
                    </Grid>
                  )} */}

                  {currentUser?.role?.toLowerCase() ===
                    'collaborationpartner' &&
                  currentUser?.userCountry?.partnerType === 'global' ? (
                    <Grid item xs={12} lg={2}>
                      <Field
                        name="regionId"
                        component={RegionSelect}
                        defaultSelected={{
                          id: currentUser?.userCountry?.stateId,
                        }}
                        makeDefaultEmpty={true}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} lg={2}>
                      <Field
                        name="regionId"
                        component={RegionSelect}
                        defaultSelected={{
                          id: currentUser?.userCountry?.stateId,
                        }}
                        makeDefaultEmpty={true}
                      />
                    </Grid>
                  )}

                  {currentUser?.role?.toLowerCase() ===
                    'collaborationpartner' &&
                  currentUser?.userCountry?.partnerType === 'global' ? (
                    <Grid item xs={12} lg={2}>
                      <Field
                        name="areaId"
                        component={AreaSelect}
                        defaultSelected={values?.regionId}
                        makeDefaultEmpty={true}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} lg={2}>
                      <Field
                        name="areaId"
                        component={AreaSelect}
                        defaultSelected={values?.regionId}
                        makeDefaultEmpty={true}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'Merchant Name'}
                      label="Merchant Name"
                      name="merchantName__substring"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'Merchant Email'}
                      label="Merchant Email"
                      name="merchantEmail"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'Username'}
                      label="Username"
                      name="username__substring"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'Merchant Phone'}
                      label="Merchant Phone"
                      name="merchantPhoneNumber"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <Field
                      name="isPremium"
                      component={StatusSelect}
                      statusOption={premiumOption}
                      label="Is Premium?"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <Field
                      name="stripeSubscriptionId__substring"
                      component={StatusSelect}
                      statusOption={subscriptionSelectOption}
                      label="Subscription Status"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <Field
                      name="bulkEmailSend"
                      component={StatusSelect}
                      statusOption={emailOption}
                      label="Email Sent?"
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Field
                      name="registrationStatus"
                      component={StatusSelect}
                      statusOption={selectOption}
                      label="Select Status"
                    />
                  </Grid>
                  {/* <Grid item xs={12} lg={2}>
                    <Field
                      name="agreementStatus"
                      component={AgreementStatusSelect}
                    />
                  </Grid> */}
                  <Grid item xs={12} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD-MM-YYYY"
                        label="Registration Date"
                        value={values?.registrationDate}
                        onChange={(value: any) => {
                          setFieldValue('registrationDate', value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            name="registrationDate"
                            size="small"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <Field
                      name="mailSentBySigner"
                      component={StatusSelect}
                      statusOption={merchantEmailOption}
                      label="Saved via send merchant email?"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <Field
                      name="isReferredByMember"
                      component={StatusSelect}
                      statusOption={referredByMembeOption}
                      label="Referred By Member?"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      placeholder={'ID'}
                      label="ID"
                      name="id"
                      variant="outlined"
                      type="number"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item>
                      <Button
                        size="small"
                        sx={{
                          px: 5,
                          color: '#ffffff',
                          backgroundColor: '#ec4785',
                          '&:hover': {
                            background: '#ec4785',
                          },
                        }}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        sx={{
                          px: 5,
                        }}
                        type="submit"
                        variant="outlined"
                        onClick={() => {
                          clearFilterForm()
                          resetForm()
                        }}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </AppCard>

      <Box sx={{ height: 30 }} />
    </>
  )
}

export default ListTop
