import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableHeading from './TableHeading'
import TableItem from './TableItem'
import {
  TablePagination,
  Box,
  Button,
  TableRow,
  CircularProgress,
  TableCell,
} from '@mui/material'
import AppTableContainer from '@crema/core/AppTableContainer'
import TableDataNotFound from 'components/TableDataNotFound'
import { CSVLink } from 'react-csv'

// import { RecentOrders } from "types/models/ecommerce/EcommerceApp";
import { CountrySetup } from 'types/models/CountrySetup'
import moment from 'moment-timezone'

interface countySetupTableProps {
  countryData: CountrySetup[]
  page: any
  setPage: any
  rowsPerPage: any
  setRowsPerPage: any
  handleChangePage: any
  handleChangeRowsPerPage: any
  hasMoreData?: boolean
  loading?: boolean
  totalCount?: number
  pageNumber?: number
  adminRoute?: any
  refreshPageData?: any
  currentUser?: any
  onDeleteClubStart?: (payload: any) => void
}

const CountryTable: React.FC<countySetupTableProps> = ({
  countryData,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  hasMoreData,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  pageNumber,
  onDeleteClubStart,
  adminRoute,
  refreshPageData,
  currentUser,
}) => {
  const [tempLoader, setTempLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  const toExportData = countryData?.length
    ? countryData?.map((item: any) => {
        return {
          Id: item?.id,
          'Merchant Name': item?.merchantName,
          Email: item?.merchantEmail,
          Username: item?.username,
          Phone: item?.merchantPhoneNumber
            ? ` ${item?.__country__?.phonePrefix} ${item?.merchantPhoneNumber}`
            : '-',
          State: item?.__state__?.stateName,

          'Saved Date':
            item?.createdAt == null
              ? '-'
              : moment(item?.createdAt).format('DD-MM-YYYY'),
        }
      })
    : []

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, 1000, 2000]}
          component="div"
          count={totalCount}
          page={page}
          nextIconButtonProps={{ disabled: !hasMoreData }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <CSVLink
          data={toExportData}
          // enclosingCharacter={``}
          enclosingCharacter={`"`}
          style={{ textDecoration: 'none' }}
          filename={'Draft List.csv'}
        >
          <Button
            sx={{
              color: '#ffffff',
              display: 'flex',
              marginLeft: 'auto',
              background: '#00bfcd',
              '&:hover': {
                background: '#ec4785',
              },
              px: 5,
              mb: 5,
            }}
          >
            Download CSV
          </Button>
        </CSVLink>
      </Box>
      <AppTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableHeading
              adminRoute={adminRoute}
              currentUser={currentUser}
              dataLength={countryData?.length ? countryData?.length : 0}
            />
          </TableHead>
          <TableBody>
            {tempLoader || loading ? (
              <TableRow className="item-hover">
                <TableCell align="center" colSpan={12}>
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {countryData?.length ? (
                  countryData.map((data, index) => (
                    <TableItem
                      data={data}
                      key={data.sn}
                      count={index + 1}
                      onDeleteClubStart={onDeleteClubStart}
                      pageNumber={pageNumber}
                      rowsPerPage={rowsPerPage}
                      adminRoute={adminRoute}
                      refreshPageData={refreshPageData}
                      currentUser={currentUser}
                    />
                  ))
                ) : (
                  <TableDataNotFound colSpan={12} />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </AppTableContainer>
    </>
  )
}

export default CountryTable
