import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Form, Formik } from 'formik'

import AppTextField from '@crema/core/AppFormComponents/AppTextField'

import { TransitionProps } from '@mui/material/transitions'
import { Fonts } from 'shared/constants/AppEnums'
import { removeEmptyFields } from 'utils'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  // eslint-disable-next-line no-undef
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface AppConfirmDialogProps {
  dialogTitle: string | ReactNode
  open: boolean
  onDeny: (isOpen: boolean) => void
  deleteFunction?: (payload: any) => void
  onCloseEditDialog?: () => void
  title: string | ReactNode
  onConfirm: () => void
  onChangeReason?: any
  redirectRoute?: any
  toDeleteId?: number
  isDeleting?: boolean
  hideReasonBox?: boolean
  placeHolderName?: string
  refreshPageData?: any
}

const AppConfirmDialog: React.FC<AppConfirmDialogProps> = ({
  open,
  onDeny,
  title,
  dialogTitle,
  toDeleteId,
  deleteFunction,
  isDeleting,
  onCloseEditDialog,
  placeHolderName,
  hideReasonBox,
  redirectRoute,
  refreshPageData,
}) => {
  const validationSchema = yup.object({
    rejectReason: yup.string().required('Reason is required!'),
  })

  const navigate = useNavigate()

  const closeModal = () => {
    onDeny(false)
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={() => onDeny(false)}
      fullWidth
    >
      <Formik
        validateOnChange={true}
        initialValues={{
          rejectReason: '',
        }}
        validationSchema={!hideReasonBox && validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true)
          deleteFunction(
            removeEmptyFields({
              ...data,
              id: toDeleteId,
              navigate,
              route: redirectRoute,
              closeModal: onCloseEditDialog,
              refreshPageData,
              rejectReason: data?.rejectReason ?? null,
            })
          )

          setSubmitting(false)
        }}
      >
        {() => (
          <Form>
            <DialogTitle>
              <Typography
                component="h4"
                variant="h4"
                sx={{
                  mb: 3,
                  fontWeight: Fonts.SEMI_BOLD,
                }}
                id="alert-dialog-title"
              >
                {dialogTitle}
              </Typography>
              <Divider />
            </DialogTitle>
            <DialogContent
              sx={{ color: 'text.secondary', fontSize: 14 }}
              id="alert-dialog-description"
            >
              <Grid container>
                <Grid item xs={12}>
                  {title}
                </Grid>
                {!hideReasonBox && (
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <AppTextField
                      label={
                        placeHolderName ? placeHolderName : 'Reason To Reject'
                      }
                      name="rejectReason"
                      variant="outlined"
                      multiline={true}
                      rows={10}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            <DialogActions
              sx={{
                pb: 5,
                px: 6,
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  fontWeight: Fonts.MEDIUM,
                }}
                type="submit"
                color="primary"
                autoFocus
                disabled={isDeleting}
              >
                {isDeleting ? <CircularProgress size={20} /> : 'Yes'}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  fontWeight: Fonts.MEDIUM,
                }}
                onClick={() => onDeny(false)}
                color="secondary"
              >
                No
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default AppConfirmDialog
