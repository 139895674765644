import { axiosRequest, removeEmptyFields } from 'utils'

export const fetchStateList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/state/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchStateById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/state/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createState = (params) => {
  return () =>
    axiosRequest
      .post(`/api/state/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteState = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/state/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editState = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/state/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
