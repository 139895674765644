import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableHeader from '@crema/core/AppTable/TableHeader'

const TableHeading = ({ auth }) => {
  return (
    <TableHeader>
      <TableCell align="left">SN</TableCell>
      {auth?.currentUser?.role?.toLowerCase() === 'globaladmin' ? (
        <TableCell align="left">Organizer Id</TableCell>
      ) : (
        ''
      )}
      <TableCell align="left">Country</TableCell>
      <TableCell align="left">Prize Draw Name</TableCell>
      <TableCell align="left">Description</TableCell>
      <TableCell align="left">Organizer Name</TableCell>
      <TableCell align="left">Organizer Type</TableCell>
      <TableCell align="left">Total Attempts</TableCell>
      <TableCell align="left">Created Date</TableCell>
      <TableCell align="left">Winner Name</TableCell>
      <TableCell align="left">Winner Email</TableCell>
      <TableCell align="left">Winner Phone</TableCell>
      <TableCell align="left">Actions</TableCell>
    </TableHeader>
  )
}

export default TableHeading
