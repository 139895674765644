import TableCell from '@mui/material/TableCell'
import TableHeader from '@crema/core/AppTable/TableHeader'

const TableHeading = ({ adminRoute, currentUser, dataLength }) => {
  return (
    <TableHeader>
      <TableCell
        align="left"
        sx={dataLength ? { position: 'sticky', left: 0, zIndex: 7 } : null}
      >
        SN
      </TableCell>
      <TableCell
        align="left"
        sx={
          dataLength
            ? {
                position: 'sticky',
                left: 50,
                zIndex: 7,
              }
            : null
        }
      >
        ID
      </TableCell>
      <TableCell
        align="left"
        sx={
          dataLength
            ? {
                position: 'sticky',
                left: 90,

                zIndex: 7,
              }
            : null
        }
      >
        Merchant Name
      </TableCell>

      {currentUser?.role?.toLowerCase() === 'collaborationpartner' &&
        currentUser?.userCountry?.partnerType?.toLowerCase() === 'global' && (
          <TableCell align="left">Country Id</TableCell>
        )}
      <TableCell align="left">Email</TableCell>
      <TableCell align="left">Username</TableCell>

      <TableCell align="left">Phone</TableCell>
      <TableCell align="left">State / Province</TableCell>
      <TableCell align="left">Saved via send merchant email?</TableCell>

      {/* <TableCell align="left">Referred By Member?</TableCell>

      <TableCell align="left">Member ID</TableCell> */}

      <TableCell align="left">Saved Date</TableCell>
      <TableCell align="left">Actions</TableCell>
    </TableHeader>
  )
}

export default TableHeading
