import { takeLatest, call, all, put, select } from 'redux-saga/effects'
import {
  fetchMemberStart,
  fetchMemberSuccess,
  fetchMemberFailure,
  createMemberStart,
  createMemberSuccess,
  createMemberFailure,
  fetchMemberByPanelStart,
  fetchMemberByPanelSuccess,
  fetchMemberByPanelFailure,
  fetchMemberByIdSuccess,
  fetchMemberByIdFailure,
  editMemberStart,
  editMemberSuccess,
  editMemberFailure,
  fetchAllMemberByOwnersSuccess,
  fetchAllMemberByOwnersFailure,
  fetchAllMemberByWhiteLabelSuccess,
  fetchAllMemberByWhiteLabelFailure,
  fetchMemberByMerchantIdSuccess,
  fetchMemberByMerchantIdFailure,
} from './membersSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchMembersList,
  fetchMembersByPanel,
  createMembers,
  createMemberByPanel,
  fetchMembersById,
  editMember,
  fetchAllMemberByOwners,
  fetchAllMemberByWhiteLabel,
  fetchMemberByMerchantId,
} from '../../api/members'

import _ from 'lodash'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchMembersAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMembersList(payload))

    yield put(fetchMemberSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMemberFailure(err))
    console.error(err)
  }
}

export function* createMemberAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    const { data } = yield call(createMemberByPanel(payload.params))
    yield put(createMemberSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      openAlert({ message: 'succesfully created', severity: 'success' })
    )
    yield put(
      fetchMemberStart({
        page: 1,
        limit: 10,
        offset: 0,
        countryId: siteCoordinator?.globalCountry?.id,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createMemberFailure(err))
  }
}

export function* fetchMembersByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMembersByPanel(payload))

    yield put(fetchMemberByPanelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMemberByPanelFailure(err))
    console.error(err)
  }
}

export function* fetchMemberByMerchantIdAsync({ payload }: AnyAction) {
  try {
    const payloadWithoutId = { ...payload, merchantId: null }
    const { data } = yield call(
      fetchMemberByMerchantId(payload?.merchantId, payloadWithoutId)
    )

    yield put(fetchMemberByMerchantIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMemberByMerchantIdFailure(err))
    console.error(err)
  }
}

export function* fetchAllMemberByOwnersAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllMemberByOwners(payload))

    yield put(fetchAllMemberByOwnersSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllMemberByOwnersFailure(err))
    console.error(err)
  }
}

export function* fetchAllMemberByWhiteLabelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllMemberByWhiteLabel(payload))

    yield put(fetchAllMemberByWhiteLabelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllMemberByWhiteLabelFailure(err))
    console.error(err)
  }
}

export function* fetchMemberByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMembersById({ id: payload }))

    yield put(fetchMemberByIdSuccess(data?.data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchMemberByIdFailure(err))
    console.error(err)
  }
}

export function* editMemberAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editMember(payload?.params?.id, payload?.params)
    )
    yield put(editMemberSuccess(data?.data))

    yield put(
      openAlert({ message: 'succesfully updated', severity: 'success' })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editMemberFailure(err))
  }
}

export function* watchFetchMember() {
  yield takeLatest('members/fetchMemberStart', fetchMembersAsync)
}

export function* watchFetchMemberByPanel() {
  yield takeLatest('members/fetchMemberByPanelStart', fetchMembersByPanelAsync)
}

export function* watchFetchMemberByMerchantId() {
  yield takeLatest(
    'members/fetchMemberByMerchantIdStart',
    fetchMemberByMerchantIdAsync
  )
}

export function* watchFetchAllMemberByOwners() {
  yield takeLatest(
    'members/fetchAllMemberByOwnersStart',
    fetchAllMemberByOwnersAsync
  )
}
export function* watchfetchAllMemberByWhiteLabel() {
  yield takeLatest(
    'members/fetchAllMemberByWhiteLabelStart',
    fetchAllMemberByWhiteLabelAsync
  )
}

export function* watchCreateMember() {
  yield takeLatest('members/createMemberStart', createMemberAsync)
}

export function* watchFetchMemberById() {
  yield takeLatest('members/fetchMemberByIdStart', fetchMemberByIdAsync)
}

export function* watchEditMember() {
  yield takeLatest('members/editMemberStart', editMemberAsync)
}

export function* membersSagas() {
  yield all([
    call(watchFetchMember),
    call(watchCreateMember),
    call(watchFetchMemberByPanel),
    call(watchFetchMemberById),
    call(watchEditMember),
    call(watchFetchAllMemberByOwners),
    call(watchfetchAllMemberByWhiteLabel),
    call(watchFetchMemberByMerchantId),
  ])
}
