import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchMerchantCategoryList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantCategory/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantSubCategoryList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantCategory/getSubCategories`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantSubCategoryListDrop = async (params) => {
  return await axiosRequest
    .get(`/api/merchant/merchantCategory/getSubCategories`, { params })
    .then((res) => {
      return Promise.resolve(res?.data?.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const fetchMerchantCategoryParentList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantCategory/getParentCategories`, { params })
      // .get(`/api/merchant/merchantCategory/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantCategoryById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantCategory/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMerchantCategory = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/merchantCategory/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteMerchantCategory = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/merchant/merchantCategory/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMerchantCategory = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/merchantCategory/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
