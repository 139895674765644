import React, { useEffect, useState } from 'react'
import UploadModern from '../UploadModern'
import { useDropzone } from 'react-dropzone'
import PreviewThumb from '../PreviewThumb/index'
import AppGrid from '@crema/core/AppGrid'
import AppList from '@crema/core/AppList'
import FileRow from '../FileRow'
import _ from 'lodash'

const Previews = ({ uploadedFiles, setUploadedFiles, onOpenAlert }) => {
  const dropzone = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length < 5) {
        setUploadedFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      } else {
        setUploadedFiles([])
      }
    },
  })
  useEffect(() => {
    if (dropzone?.acceptedFiles?.length > 6) {
      setUploadedFiles([])

      onOpenAlert({
        message: 'Cannot upload more than 6 Images',
        severity: 'error',
      })
    } else {
      setUploadedFiles(dropzone.acceptedFiles)
    }
  }, [dropzone.acceptedFiles])

  const onDeleteUploadFile = (file: any) => {
    if (file?.preview) {
      const filteredImages = uploadedFiles?.filter(
        (item) => item?.preview !== file?.preview
      )
      setUploadedFiles(filteredImages)
      return
    }
    dropzone.acceptedFiles.splice(dropzone.acceptedFiles.indexOf(file), 1)
    setUploadedFiles([...dropzone.acceptedFiles])
  }

  return (
    <section className="container" style={{ cursor: 'pointer' }}>
      <UploadModern
        uploadText="Drag & drop atleast 2 slider images here or click to
        select files"
        dropzone={dropzone}
      />
      {/* <AppGrid
        sx={{
          maxWidth: 500,
        }}
        data={uploadedFiles}
        column={4}
        itemPadding={5}
        renderRow={(file, index) => (
          <PreviewThumb
            file={file}
            onDeleteUploadFile={onDeleteUploadFile}
            key={index + file.path}
          />
        )}
      /> */}

      <aside>
        <AppList
          data={uploadedFiles}
          renderRow={(file: any, index: number) => {
            if (!_.isEmpty(file)) {
              return (
                <FileRow
                  key={index + file.path}
                  file={file}
                  onDeleteUploadFile={onDeleteUploadFile}
                />
              )
            }
          }}
        />
      </aside>
    </section>
  )
}

export default Previews
