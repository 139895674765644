import { createSlice } from '@reduxjs/toolkit'

import { IEOI } from '../interfaces'
import { listObj, sortWeek } from 'utils'

const INITIAL_STATE: IEOI = {
  error: null,
  isCreating: false,
  isEditing: false,
  isEditingDraft: false,
  hasMoreData: false,
  isCreatingDraft: false,
  isCreatingGeneral: false,
  isApplyingCode: false,

  generalBasicInfo: {},
  generalWebsiteInfo: {},
  generalImageInfo: {},
  generalDiscountInfo: {},
  generalBankInfo: {},

  draftBasicInfo: {},
  draftWebsiteInfo: {},
  draftImageInfo: {},
  draftDiscountInfo: {},
  draftBankInfo: {},
  referralInfo: {},

  merchantCodeResponse: null,
  merchantCodeData: {},
  merchantCodeError: false,
  errorDataNotFound: null,

  isApplyingCouponCode: false,

  couponCodeResponse: null,
  couponCodeData: {},
  couponCodeError: false,
}

export const eoiSlice = createSlice({
  name: 'eoi',
  initialState: INITIAL_STATE,
  reducers: {
    createSalesAgentEOIStart: (state: any, action) => {
      state.loading = true
    },
    createSalesAgentEOISuccess: (state: any, action) => {
      state.loading = false
    },
    createSalesAgentEOIFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createCharityEOIStart: (state: any, action) => {
      state.loading = true
    },
    createCharityEOISuccess: (state: any, action) => {
      state.loading = false
    },
    createCharityEOIFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createClubEOIStart: (state: any, action) => {
      state.loading = true
    },
    createClubEOISuccess: (state: any, action) => {
      state.loading = false
    },
    createClubEOIFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    eoiCreateStep1DraftStart: (state: any, action) => {
      state.isCreatingDraft = true
    },
    eoiCreateStep1DraftSuccess: (state: any, action) => {
      state.isCreatingDraft = false
    },
    eoiCreateStep1DraftFailure: (state: any, action) => {
      state.isCreatingDraft = false
      state.error = action.payload
    },

    eoiCreateStep1GeneralStart: (state: any, action) => {
      state.isCreatingGeneral = true
    },
    eoiCreateStep1GeneralSuccess: (state: any, action) => {
      state.isCreatingGeneral = false
      state.generalBasicInfo = action.payload
    },
    eoiCreateStep1GeneralFailure: (state: any, action) => {
      state.isCreatingGeneral = false
      state.error = action.payload
    },

    eoiEditDraftStep1Start: (state: any, action) => {
      state.isEditingDraft = true
    },
    eoiEditDraftStep1Success: (state: any, action) => {
      state.isEditingDraft = false
    },
    eoiEditDraftStep1Failure: (state: any, action) => {
      state.isEditingDraft = false
      state.error = action.payload
    },

    eoiEditStep1Start: (state: any, action) => {
      state.isEditing = true
    },
    eoiEditStep1Success: (state: any, action) => {
      state.isEditing = false
    },
    eoiEditStep1Failure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    eoiCreateStep2DraftStart: (state: any, action) => {
      state.isCreatingDraft = true
    },
    eoiCreateStep2DraftSuccess: (state: any, action) => {
      state.isCreatingDraft = false
    },
    eoiCreateStep2DraftFailure: (state: any, action) => {
      state.isCreatingDraft = false
      state.error = action.payload
    },

    eoiCreateStep2GeneralStart: (state: any, action) => {
      state.isCreatingGeneral = true
    },
    eoiCreateStep2GeneralSuccess: (state: any, action) => {
      state.isCreatingGeneral = false
    },
    eoiCreateStep2GeneralFailure: (state: any, action) => {
      state.isCreatingGeneral = false
      state.error = action.payload
    },

    eoiEditStep2Start: (state: any, action) => {
      state.isEditing = true
    },
    eoiEditStep2Success: (state: any, action) => {
      state.isEditing = false
    },
    eoiEditStep2Failure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    eoiEditDraftStep2Start: (state: any, action) => {
      state.isEditingDraft = true
    },
    eoiEditDraftStep2Success: (state: any, action) => {
      state.isEditingDraft = false
    },
    eoiEditDraftStep2Failure: (state: any, action) => {
      state.isEditingDraft = false
      state.error = action.payload
    },

    eoiCreateStep3DraftStart: (state: any, action) => {
      state.isCreatingDraft = true
    },
    eoiCreateStep3DraftSuccess: (state: any) => {
      state.isCreatingDraft = false
    },
    eoiCreateStep3DraftFailure: (state: any, action) => {
      state.isCreatingDraft = false
      state.error = action.payload
    },

    eoiCreateStep3GeneralStart: (state: any, action) => {
      state.isCreatingGeneral = true
    },
    eoiCreateStep3GeneralSuccess: (state: any) => {
      state.isCreatingGeneral = false
    },
    eoiCreateStep3GeneralFailure: (state: any, action) => {
      state.isCreatingGeneral = false
      state.error = action.payload
    },

    eoiEditStep3Start: (state: any, action) => {
      state.isEditing = true
    },
    eoiEditStep3Success: (state: any) => {
      state.isEditing = false
    },
    eoiEditStep3Failure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    eoiEditDraftStep3Start: (state: any, action) => {
      state.isEditingDraft = true
    },
    eoiEditDraftStep3Success: (state: any) => {
      state.isEditingDraft = false
    },
    eoiEditDraftStep3Failure: (state: any, action) => {
      state.isEditingDraft = false
      state.error = action.payload
    },

    eoiCreateStep4DraftStart: (state: any, action) => {
      state.isCreatingDraft = true
    },
    eoiCreateStep4DraftSuccess: (state: any, action) => {
      state.isCreatingDraft = false
    },
    eoiCreateStep4DraftFailure: (state: any, action) => {
      state.isCreatingDraft = false
      state.error = action.payload
    },

    eoiCreateStep4GeneralStart: (state: any, action) => {
      state.isCreatingGeneral = true
    },
    eoiCreateStep4GeneralSuccess: (state: any, action) => {
      state.isCreatingGeneral = false
    },
    eoiCreateStep4GeneralFailure: (state: any, action) => {
      state.isCreatingGeneral = false
      state.error = action.payload
    },

    eoiEditStep4Start: (state: any, action) => {
      state.isEditing = true
    },
    eoiEditStep4Success: (state: any, action) => {
      state.isEditing = false
    },
    eoiEditStep4Failure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    eoiEditDraftStep4Start: (state: any, action) => {
      state.isEditingDraft = true
    },
    eoiEditDraftStep4Success: (state: any, action) => {
      state.isEditingDraft = false
    },
    eoiEditDraftStep4Failure: (state: any, action) => {
      state.isEditingDraft = false
      state.error = action.payload
    },

    eoiFetchMerchantBasicInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    eoiFetchMerchantBasicInfoSuccess: (state: any, action) => {
      state.generalBasicInfo = action.payload
      state.isFetching = false
    },
    eoiFetchMerchantBasicInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    eoiFetchMerchantWebsiteInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    eoiFetchMerchantWebsiteInfoSuccess: (state: any, action) => {
      state.generalWebsiteInfo = action.payload
      state.isFetching = false
    },
    eoiFetchMerchantWebsiteInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    eoiFetchMerchantImageStart: (state: any, action) => {
      state.isFetching = true
    },
    eoiFetchMerchantImageSuccess: (state: any, action) => {
      state.generalImageInfo = action.payload
      state.isFetching = false
    },
    eoiFetchMerchantImageFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    eoiFetchMerchantDiscountInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    eoiFetchMerchantDiscountInfoSuccess: (state: any, action) => {
      state.generalDiscountInfo = {
        ...action?.payload,
        discountRates: sortWeek(action?.payload?.discountRates),
      }
      state.errorDataNotFound = null

      state.isFetching = false
    },
    eoiFetchMerchantDiscountInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload

      state.errorDataNotFound =
        action.payload.message.replace(/\s/g, '')?.toLowerCase() ===
        'datanotfound'
          ? action.payload.message
          : null
    },

    eoiFetchMerchantBankInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    eoiFetchMerchantBankInfoSuccess: (state: any, action) => {
      state.generalBankInfo = action.payload
      state.isFetching = false
    },
    eoiFetchMerchantBankInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    eoiCreateBankInfoStart: (state: any, action) => {
      state.isCreating = true
    },
    eoiCreateBankInfoSuccess: (state: any, action) => {
      state.isCreating = false
    },
    eoiCreateBankInfoFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    eoiCheckApplyMerchantPremiumCodeStart: (state: any, action) => {
      state.isApplyingCode = true
    },
    eoiCheckApplyMerchantPremiumCodeSuccess: (state: any, action) => {
      state.isApplyingCode = false
      state.merchantCodeResponse = action.payload.successMessage
      state.merchantCodeData = action.payload.data
      state.merchantCodeError = false
    },
    eoiCheckApplyMerchantPremiumCodeFailure: (state: any, action) => {
      state.isApplyingCode = false
      state.merchantCodeResponse = action.payload
      state.merchantCodeError = true
    },

    clearDraftData: (state: any) => {
      state.draftBasicInfo = {}
      state.draftWebsiteInfo = {}
      state.draftImageInfo = {}
      state.draftDiscountInfo = {}
      state.draftBankInfo = {}
    },

    clearGeneralData: (state: any) => {
      state.generalBasicInfo = {}
      state.generalWebsiteInfo = {}
      state.generalImageInfo = {}
      state.generalDiscountInfo = {}
      state.generalBankInfo = {}
    },

    clearMerchantPremiumCodeData: (state: any) => {
      state.merchantCodeResponse = null
    },

    clearMerchantPremiumCode: (state: any) => {
      state.isApplyingCode = false
      state.merchantCodeResponse = null
      state.merchantCodeData = {}
      state.merchantCodeError = false
    },

    clearMerchantCodeData: (state: any) => {
      state.merchantCodeData = {}
    },

    clearCouponCodeData: (state: any) => {
      state.couponCodeData = null
    },
    clearMerchantCouponCodeData: (state: any) => {
      state.couponCodeResponse = null
      state.couponCodeData = null
    },

    eoiApplyMerchantCouponCodeStart: (state: any, action) => {
      state.isApplyingCouponCode = true
    },
    eoiApplyMerchantCouponCodeSuccess: (state: any, action) => {
      state.isApplyingCouponCode = false
      state.couponCodeResponse = action.payload.successMessage
      state.couponCodeData = action.payload.data
      state.couponCodeError = false
    },
    eoiApplyMerchantCouponCodeFailure: (state: any, action) => {
      state.isApplyingCouponCode = false
      state.couponCodeResponse = action.payload
      state.couponCodeError = true
    },

    clearError: (state: any) => {
      state.error = null
    },

    eoiFetchReferralFromTokenStart: (state: any, action) => {
      state.isFetching = true
    },
    eoiFetchReferralFromTokenSuccess: (state: any, action) => {
      state.isFetching = false
      state.referralInfo = action.payload
    },
    eoiFetchReferralFromTokenFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    eoiCreateMerchantGroupStart: (state: any, action) => {
      state.isCreating = true
    },
    eoiCreateMerchantGroupSuccess: (state: any, action) => {
      state.isCreating = false
    },
    eoiCreateMerchantGroupFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },
  },
})

export const {
  createSalesAgentEOIStart,
  createSalesAgentEOISuccess,
  createSalesAgentEOIFailure,

  createCharityEOIStart,
  createCharityEOISuccess,
  createCharityEOIFailure,

  createClubEOIStart,
  createClubEOISuccess,
  createClubEOIFailure,

  eoiCreateStep1DraftStart,
  eoiCreateStep1DraftSuccess,
  eoiCreateStep1DraftFailure,

  eoiCreateStep1GeneralStart,
  eoiCreateStep1GeneralSuccess,
  eoiCreateStep1GeneralFailure,

  eoiEditDraftStep1Start,
  eoiEditDraftStep1Success,
  eoiEditDraftStep1Failure,

  eoiEditStep1Start,
  eoiEditStep1Success,
  eoiEditStep1Failure,

  eoiCreateStep2DraftStart,
  eoiCreateStep2DraftSuccess,
  eoiCreateStep2DraftFailure,

  eoiCreateStep2GeneralStart,
  eoiCreateStep2GeneralSuccess,
  eoiCreateStep2GeneralFailure,

  eoiEditDraftStep2Start,
  eoiEditDraftStep2Success,
  eoiEditDraftStep2Failure,

  eoiEditStep2Start,
  eoiEditStep2Success,
  eoiEditStep2Failure,

  eoiCreateStep3DraftStart,
  eoiCreateStep3DraftSuccess,
  eoiCreateStep3DraftFailure,

  eoiCreateStep3GeneralStart,
  eoiCreateStep3GeneralSuccess,
  eoiCreateStep3GeneralFailure,

  eoiEditDraftStep3Start,
  eoiEditDraftStep3Success,
  eoiEditDraftStep3Failure,

  eoiEditStep3Start,
  eoiEditStep3Success,
  eoiEditStep3Failure,

  eoiCreateStep4DraftStart,
  eoiCreateStep4DraftSuccess,
  eoiCreateStep4DraftFailure,

  eoiCreateStep4GeneralStart,
  eoiCreateStep4GeneralSuccess,
  eoiCreateStep4GeneralFailure,

  eoiEditDraftStep4Start,
  eoiEditDraftStep4Success,
  eoiEditDraftStep4Failure,

  eoiEditStep4Start,
  eoiEditStep4Success,
  eoiEditStep4Failure,

  eoiFetchMerchantBasicInfoStart,
  eoiFetchMerchantBasicInfoSuccess,
  eoiFetchMerchantBasicInfoFailure,

  eoiFetchMerchantWebsiteInfoStart,
  eoiFetchMerchantWebsiteInfoSuccess,
  eoiFetchMerchantWebsiteInfoFailure,

  eoiFetchMerchantImageStart,
  eoiFetchMerchantImageSuccess,
  eoiFetchMerchantImageFailure,

  eoiFetchMerchantBankInfoStart,
  eoiFetchMerchantBankInfoSuccess,
  eoiFetchMerchantBankInfoFailure,

  eoiFetchMerchantDiscountInfoStart,
  eoiFetchMerchantDiscountInfoSuccess,
  eoiFetchMerchantDiscountInfoFailure,

  eoiCreateBankInfoStart,
  eoiCreateBankInfoSuccess,
  eoiCreateBankInfoFailure,

  clearDraftData,

  clearGeneralData,

  clearMerchantPremiumCodeData,

  clearMerchantPremiumCode,

  clearMerchantCodeData,

  eoiCheckApplyMerchantPremiumCodeStart,
  eoiCheckApplyMerchantPremiumCodeSuccess,
  eoiCheckApplyMerchantPremiumCodeFailure,

  eoiFetchReferralFromTokenStart,
  eoiFetchReferralFromTokenSuccess,
  eoiFetchReferralFromTokenFailure,

  eoiCreateMerchantGroupStart,
  eoiCreateMerchantGroupSuccess,
  eoiCreateMerchantGroupFailure,

  clearCouponCodeData,
  clearMerchantCouponCodeData,

  eoiApplyMerchantCouponCodeStart,
  eoiApplyMerchantCouponCodeSuccess,
  eoiApplyMerchantCouponCodeFailure,

  clearError,
} = eoiSlice.actions

export default eoiSlice.reducer
