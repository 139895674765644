import {
  takeLatest,
  call,
  all,
  put,
  select,
  delay,
  race,
} from 'redux-saga/effects'
import {
  fetchIssuerQrCodesListStart,
  fetchIssuerQrCodesListFailure,
  fetchIssuerQrCodesListSuccess,
  fetchIssuerBatchListFailure,
  fetchIssuerBatchListStart,
  fetchIssuerBatchListSuccess,
  createIssuerBatchFailure,
  createIssuerBatchStart,
  createIssuerBatchSuccess,
  createIssuerBatchCSVStart,
  createIssuerBatchCSVSuccess,
  createIssuerBatchCSVFailure,
  fetchAssignerStart,
  fetchAssignerFailure,
  fetchAssignerSuccess,
  fetchIssuerQrCodesListAllStart,
  fetchIssuerQrCodesListAllSuccess,
  fetchIssuerQrCodesListAllFailure,
  createAssignerStart,
  createAssignerSuccess,
  createAssignerFailure,
  editIssuerBatchStart,
  editIssuerBatchSuccess,
  editIssuerBatchFailure,
  createGMCAssignerFailure,
  createGMCAssignerStart,
  createGMCAssignerSuccess,
  fetchGmcIssuerCodeBatchSuccess,
  fetchGmcIssuerCodeBatchFailure,
  fetchAllGmcIssuerCodeByBatchSuccess,
  fetchAllGmcIssuerCodeByBatchFailure,
  createWhiteLabelIssuerCodeSuccess,
  createWhiteLabelIssuerCodeFailure,
  fetchIssuerCodeAssignableCountForOthersFailure,
  fetchIssuerCodeAssignableCountForOthersSuccess,
  fetchIssuerCodeAssignableCountForGMCSuccess,
  fetchIssuerCodeAssignableCountForGMCFailure,
  deleteIssuerCodeBatchFailure,
  deleteIssuerCodeBatchSuccess,
  deleteIssuerCodeFailure,
  deleteIssuerCodeSuccess,
  downloadIssuerCodeBatchFailure,
  downloadIssuerCodeBatchSuccess,
  fetchOneIssuerQrCodePreviewSuccess,
  fetchOneIssuerQrCodePreviewFailure,
  changeDownloadStatus,
  fetchOneIssuerCodeBatchFailure,
  fetchOneIssuerCodeBatchSuccess,
  fetchOneIssuerCodeBatchStart,
} from './issuerCodesSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchIssuerCodeListByPanel,
  fetchIssuerCodeList,
  fetchIssuerIssuerCodeList,
  fetchIssuerBatch,
  createIssuerBatch,
  createIssuerBatchCSV,
  fetchAssigner,
  addAssigner,
  addGMCAssigner,
  editIssuerBatch,
  fetchIssuerGmcBatch,
  fetchAllGmcIssuerCodeByBatch,
  addWhiteLabelIssuerAssigner,
  fetchIssuerCodeAssignableCountForOthers,
  fetchIssuerCodeAssignableCountForGMC,
  deleteIssuerCodeBatch,
  deleteIssuerCode,
  downloadPreBatchIssuerCode,
  fetchOneIssuerQrCodePreview,
  downloadOnBatchIssuerCode,
  fetchOneIssuerCodeBatch,
} from '../../api/issuerCodes'

import _ from 'lodash'
import { fetchIssuerQrCodesListWithoutFilterStart } from './qrCodesSlice'
import { yellow } from '@mui/material/colors'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchIssuerQrCodesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchIssuerCodeListByPanel(payload))
    yield put(fetchIssuerQrCodesListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerQrCodesListFailure(err))
  }
}

export function* fetchIssuerQrCodesAllAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchIssuerCodeList(payload))
    yield put(fetchIssuerQrCodesListAllSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerQrCodesListAllFailure(err))
  }
}

export function* fetchIssuerCodeAssignableCountForOthersAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchIssuerCodeAssignableCountForOthers(payload)
    )
    yield put(fetchIssuerCodeAssignableCountForOthersSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerCodeAssignableCountForOthersFailure(err))
  }
}

export function* fetchIssuerCodeAssignableCountForGMCAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchIssuerCodeAssignableCountForGMC(payload?.batchId)
    )
    yield put(fetchIssuerCodeAssignableCountForGMCSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerCodeAssignableCountForGMCFailure(err))
  }
}

export function* fetchAssignerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAssigner(payload?.params))

    yield put(fetchAssignerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAssignerFailure(err))
  }
}

export function* createIssuerQrCodesAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)
    const { data } = yield call(createIssuerBatch(payload.params))
    yield put(createIssuerBatchSuccess(data))
    if (payload?.openPreparingModal) {
      yield payload?.openPreparingModal()
    }
    yield put(
      fetchIssuerBatchListStart({
        page: 1,
        limit: 10,
        countryId: siteCoordinator?.globalCountry?.id,
        offset: 0,
      })
    )

    // yield put(
    //   openAlert({
    //     message: 'Generated Successfully',
    //     severity: 'success',
    //   })
    // )

    // payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createIssuerBatchFailure(err))
  }
}

export function* editIssuerQrCodesAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)
    const { data } = yield call(editIssuerBatch(payload?.id, payload.params))

    yield put(editIssuerBatchSuccess(data))

    yield put(
      fetchIssuerBatchListStart({
        page: 1,
        limit: 10,
        countryId: siteCoordinator?.globalCountry?.id,
        offset: 0,
      })
    )

    yield put(
      openAlert({
        message: 'Edited Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editIssuerBatchFailure(err))
  }
}

export function* createIssuerAssignerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(addAssigner(payload.params))
    yield put(createAssignerSuccess(data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchIssuerQrCodesListAllStart({
          page: 1,
          limit: 10,
          offset: 0,
          batchId: payload?.batchId,
          // batchId: 14,
        })
      )
    }

    yield put(
      openAlert({
        message: 'Assigned Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createAssignerFailure(err))
  }
}

export function* createIssuerGMCAssignerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(addGMCAssigner(payload.params))
    yield put(createGMCAssignerSuccess(data))
    yield put(
      fetchIssuerQrCodesListAllStart({
        page: 1,
        limit: 10,
        offset: 0,
        batchId: payload?.params?.batchId,
        // batchId: 14,
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Assigned Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createGMCAssignerFailure(err))
  }
}

export function* createIssuerQrCodesCSVAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createIssuerBatchCSV(payload.params))
    yield put(createIssuerBatchCSVSuccess(data))

    yield put(
      fetchIssuerQrCodesListAllStart({
        page: 1,
        limit: 10,
        offset: 0,
        batchId: payload?.batchId,
      })
    )
    yield put(
      openAlert({
        message: 'Uploaded Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createIssuerBatchCSVFailure(err))
  }
}

export function* fetchIssuerBatchAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchIssuerBatch(payload))
    yield put(fetchIssuerBatchListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerBatchListFailure(err))
  }
}

export function* fetchGmcIssuerCodeBatchAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchIssuerGmcBatch(payload))
    yield put(fetchGmcIssuerCodeBatchSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchGmcIssuerCodeBatchFailure(err))
  }
}

export function* fetchAllGmcIssuerCodeByBatchAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllGmcIssuerCodeByBatch(payload))
    yield put(fetchAllGmcIssuerCodeByBatchSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllGmcIssuerCodeByBatchFailure(err))
  }
}

export function* createWhiteLabelAssignerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(addWhiteLabelIssuerAssigner(payload.params))
    yield put(createWhiteLabelIssuerCodeSuccess(data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      fetchIssuerQrCodesListWithoutFilterStart({
        page: 1,
        limit: 10,
        offset: 0,
        batchId: payload?.batchId,
        // batchId: 14,
      })
    )

    yield put(
      openAlert({
        message: 'Assigned Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createWhiteLabelIssuerCodeFailure(err))
  }
}

export function* deleteIssuerCodeBatchAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteIssuerCodeBatch(payload.id))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Issuer Code Batch Deleted Successfully',
        severity: 'success',
      })
    )

    if (payload?.closeModal) {
      payload?.closeModal()
    }

    yield put(deleteIssuerCodeBatchSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteIssuerCodeBatchFailure(err))
  }
}

export function* deleteIssuerCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteIssuerCode(payload.id))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Issuer Code Deleted Successfully',
        severity: 'success',
      })
    )

    if (payload?.closeModal) {
      payload?.closeModal()
    }

    yield put(deleteIssuerCodeSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteIssuerCodeFailure(err))
  }
}

function* repeat(payload) {
  yield delay(3000)
  const { data } = yield call(fetchOneIssuerCodeBatch(payload))

  if (
    data?.data?.zipFileDownloadStatus === 2 &&
    (data?.data?.zipFileName !== 'NULL' || data?.data?.zipFileName !== null)
  ) {
    yield call(downloadOnBatchIssuerCode(data?.data))
  } else {
    yield* repeat(payload)
  }
}

export function* downloadIssuerCodeBatchAsync({ payload }: AnyAction) {
  try {
    const responseData = yield call(fetchOneIssuerCodeBatch(payload?.id))

    if (
      responseData?.data?.data?.s3FilesUploaded &&
      responseData?.data?.data?.zipFileDownloadStatus === 1
    ) {
      yield put(
        openAlert({
          message: 'Downloadable File is Preparing',
          severity: 'warning',
        })
      )

      yield put(downloadIssuerCodeBatchSuccess('preparing'))
    } else {
      yield put(changeDownloadStatus('processing'))

      const { data } =
        responseData?.data?.data?.s3FilesUploaded &&
        responseData?.data?.data?.zipFileDownloadStatus === 0
          ? yield call(downloadPreBatchIssuerCode(responseData?.data?.data))
          : yield call(downloadOnBatchIssuerCode(responseData?.data?.data))

      if (
        data &&
        responseData?.data?.data?.s3FilesUploaded &&
        responseData?.data?.data?.zipFileDownloadStatus === 0
      ) {
        yield repeat(payload?.id)
      }
      yield put(downloadIssuerCodeBatchSuccess('end'))
    }

    //  yield put(
    //   openAlert({
    //     message: 'Download File Preparing',
    //     severity: 'warning',
    //   })
    // )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    // yield put(
    //   openAlert({
    //     message: 'Batch Issuer Code Downloaded Successfully',
    //     severity: 'success',
    //   })
    // )

    if (payload?.closeModal) {
      payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(downloadIssuerCodeBatchFailure(err))
  }
}

export function* fetchOneIssuerCodeBatchAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchOneIssuerCodeBatch(payload))

    yield put(fetchOneIssuerCodeBatchSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchOneIssuerCodeBatchFailure(err))
  }
}

export function* fetchOneIssuerQrCodePreviewAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchOneIssuerQrCodePreview(payload))

    yield put(fetchOneIssuerQrCodePreviewSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchOneIssuerQrCodePreviewFailure(err))
  }
}

export function* watchCreateIssuerQrCodes() {
  yield takeLatest(
    'issuerQrCodes/createIssuerBatchStart',
    createIssuerQrCodesAsync
  )
}
export function* watchEditIssuerQrCodes() {
  yield takeLatest('issuerQrCodes/editIssuerBatchStart', editIssuerQrCodesAsync)
}

export function* watchFetchAssigner() {
  yield takeLatest('issuerQrCodes/fetchAssignerStart', fetchAssignerAsync)
}

export function* watchCreateIssuerQrCodesCSV() {
  yield takeLatest(
    'issuerQrCodes/createIssuerBatchCSVStart',
    createIssuerQrCodesCSVAsync
  )
}
export function* watchCreateIssuerAssignerAsync() {
  yield takeLatest(
    'issuerQrCodes/createAssignerStart',
    createIssuerAssignerAsync
  )
}
export function* watchCreateIssuerGMCAssignerAsync() {
  yield takeLatest(
    'issuerQrCodes/createGMCAssignerStart',
    createIssuerGMCAssignerAsync
  )
}

export function* watchFetchIssuerQrCodes() {
  yield takeLatest(
    'issuerQrCodes/fetchIssuerQrCodesListStart',
    fetchIssuerQrCodesAsync
  )
}
export function* watchFetchIssuerQrCodesAll() {
  yield takeLatest(
    'issuerQrCodes/fetchIssuerQrCodesListAllStart',
    fetchIssuerQrCodesAllAsync
  )
}

export function* watchFetchIssuerBatch() {
  yield takeLatest(
    'issuerQrCodes/fetchIssuerBatchListStart',
    fetchIssuerBatchAsync
  )
}
export function* watchFetchGmcIssuerCodeBatch() {
  yield takeLatest(
    'issuerQrCodes/fetchGmcIssuerCodeBatchStart',
    fetchGmcIssuerCodeBatchAsync
  )
}

export function* watchFetchAllGmcIssuerCodeByBatch() {
  yield takeLatest(
    'issuerQrCodes/fetchAllGmcIssuerCodeByBatchStart',
    fetchAllGmcIssuerCodeByBatchAsync
  )
}

export function* watchCreateWhiteLabelIssuerAssignerAsync() {
  yield takeLatest(
    'issuerQrCodes/createWhiteLabelIssuerCodeStart',
    createWhiteLabelAssignerAsync
  )
}

export function* watchFetchIssuerCodeAssignableCountForOthersAsync() {
  yield takeLatest(
    'issuerQrCodes/fetchIssuerCodeAssignableCountForOthersStart',
    fetchIssuerCodeAssignableCountForOthersAsync
  )
}

export function* watchFetchIssuerCodeAssignableCountForGMCAsync() {
  yield takeLatest(
    'issuerQrCodes/fetchIssuerCodeAssignableCountForGMCStart',
    fetchIssuerCodeAssignableCountForGMCAsync
  )
}
export function* watchDeleteIssuerCodeBatchAsync() {
  yield takeLatest(
    'issuerQrCodes/deleteIssuerCodeBatchStart',
    deleteIssuerCodeBatchAsync
  )
}

export function* watchDeleteIssuerCodeAsync() {
  yield takeLatest('issuerQrCodes/deleteIssuerCodeStart', deleteIssuerCodeAsync)
}

export function* watchDownloadIssuerCodeBatchAsync() {
  yield takeLatest(
    'issuerQrCodes/downloadIssuerCodeBatchStart',
    downloadIssuerCodeBatchAsync
  )
}

export function* watchfetchOneIssuerQrCodePreviewAsync() {
  yield takeLatest(
    'issuerQrCodes/fetchOneIssuerQrCodePreviewStart',
    fetchOneIssuerQrCodePreviewAsync
  )
}

export function* watchFetchOneIssuerCodeBatchAsync() {
  yield takeLatest(
    'issuerQrCodes/fetchOneIssuerCodeBatchStart',
    fetchOneIssuerCodeBatchAsync
  )
}

export function* issuerCodesSagas() {
  yield all([
    call(watchFetchAssigner),
    call(watchEditIssuerQrCodes),
    call(watchCreateIssuerAssignerAsync),
    call(watchFetchIssuerQrCodesAll),
    call(watchCreateIssuerQrCodesCSV),
    call(watchCreateIssuerQrCodes),
    call(watchFetchIssuerQrCodes),
    call(watchFetchIssuerBatch),
    call(watchCreateIssuerGMCAssignerAsync),
    call(watchFetchGmcIssuerCodeBatch),
    call(watchFetchAllGmcIssuerCodeByBatch),
    call(watchCreateWhiteLabelIssuerAssignerAsync),
    call(watchFetchIssuerCodeAssignableCountForOthersAsync),
    call(watchFetchIssuerCodeAssignableCountForGMCAsync),
    call(watchDeleteIssuerCodeBatchAsync),
    call(watchDeleteIssuerCodeAsync),
    call(watchDownloadIssuerCodeBatchAsync),
    call(watchfetchOneIssuerQrCodePreviewAsync),
    call(watchFetchOneIssuerCodeBatchAsync),
  ])
}
