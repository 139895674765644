import { axiosRequest } from 'utils'

export const fetchAllMemberPremiumCode = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/memberPremiumCode/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
