import { createSlice } from '@reduxjs/toolkit'

import { IAreaOwnerState, IListObj } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IAreaOwnerState = {
  areaOwnerList: listObj,
  pendingAreaOwnerList: listObj,
  soleAreaOwner: {},
  areaOwnerProfileInfo: listObj,
  error: null,
  loading: false,
  isFetching: false,
  isEditing: false,
  isVerifing: false,
  isRejecting: false,
  isDeleting: false,
  hasMoreData: false,
  isUploading: false,
}

export const areaOwnerSlice = createSlice({
  name: 'areaOwner',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPendingAreaOwnerStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPendingAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pendingAreaOwnerList = action.payload
      state.isFetching = false
    },
    fetchPendingAreaOwnerFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchAreaOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.areaOwnerList = action.payload
      state.loading = false
    },
    fetchAreaOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchAreaOwnerByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchAreaOwnerByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleAreaOwner = action.payload

      state.loading = false
    },
    fetchAreaOwnerByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deletePendingAreaOwnerStart: (state: any, action) => {
      state.isDeleting = true
    },
    deletePendingAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
      state.pendingAreaOwnerList.data = state.pendingAreaOwnerList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePendingAreaOwnerFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    deleteAreaOwnerStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
      state.areaOwnerList.data = state.areaOwnerList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteAreaOwnerFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    createAreaOwnerStart: (state: any, action) => {
      state.loading = true
    },
    createAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      // state.areaOwnerList.data = [...state.areaOwnerList.data, action.payload]
    },
    createAreaOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editAreaOwnerStart: (state: any, action) => {
      state.isEditing = true
    },
    editAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      const result = state.areaOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.areaOwnerList.data = result
      state.isEditing = false
    },
    editAreaOwnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    verifyAreaOwnerStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifyAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isVerifing = false
      state.pendingAreaOwnerList.data = state.pendingAreaOwnerList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    verifyAreaOwnerFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },
    rejectAreaOwnerStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.pendingAreaOwnerList.data = state.pendingAreaOwnerList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    rejectAreaOwnerFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },
    updatePendingAreaOwnerStart: (state: any, action) => {
      state.isEditing = true
    },
    updatePendingAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;

      const result = state.pendingAreaOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.pendingAreaOwnerList.data = result
      state.isEditing = false
    },
    updatePendingAreaOwnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    deleteAreaOwnerContactStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteAreaOwnerContactSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
    },
    deleteAreaOwnerContactFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },
    fetchAreaOwnerProfileInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAreaOwnerProfileInfoSuccess: (state: any, action) => {
      state.areaOwnerProfileInfo = action.payload
      state.isFetching = false
    },
    fetchAreaOwnerProfileInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editAreaOwnerOwnProfileInfoStart: (state: any, action) => {
      state.isEditing = true
    },
    editAreaOwnerOwnProfileInfoSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editAreaOwnerOwnProfileInfoFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    uploadAgreementStart: (state: any, action) => {
      state.isUploading = true
    },
    uploadAgreementSuccess: (state: any) => {
      state.isUploading = false
    },
    uploadAgreementFailure: (state: any, action) => {
      state.isUploading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchAreaOwnerStart,
  fetchAreaOwnerSuccess,
  fetchAreaOwnerFailure,
  deleteAreaOwnerStart,
  deleteAreaOwnerSuccess,
  deleteAreaOwnerFailure,
  fetchAreaOwnerByIdStart,
  fetchAreaOwnerByIdSuccess,
  fetchAreaOwnerByIdFailure,
  createAreaOwnerStart,
  createAreaOwnerSuccess,
  createAreaOwnerFailure,
  editAreaOwnerStart,
  editAreaOwnerSuccess,
  editAreaOwnerFailure,
  fetchPendingAreaOwnerStart,
  fetchPendingAreaOwnerSuccess,
  fetchPendingAreaOwnerFailure,
  deletePendingAreaOwnerStart,
  deletePendingAreaOwnerSuccess,
  deletePendingAreaOwnerFailure,
  verifyAreaOwnerStart,
  verifyAreaOwnerSuccess,
  verifyAreaOwnerFailure,
  rejectAreaOwnerStart,
  rejectAreaOwnerSuccess,
  rejectAreaOwnerFailure,
  updatePendingAreaOwnerStart,
  updatePendingAreaOwnerSuccess,
  updatePendingAreaOwnerFailure,
  deleteAreaOwnerContactStart,
  deleteAreaOwnerContactSuccess,
  deleteAreaOwnerContactFailure,
  fetchAreaOwnerProfileInfoStart,
  fetchAreaOwnerProfileInfoSuccess,
  fetchAreaOwnerProfileInfoFailure,
  uploadAgreementStart,
  uploadAgreementSuccess,
  uploadAgreementFailure,

  editAreaOwnerOwnProfileInfoStart,
  editAreaOwnerOwnProfileInfoSuccess,
  editAreaOwnerOwnProfileInfoFailure,
} = areaOwnerSlice.actions

export default areaOwnerSlice.reducer
