import { AuthUser } from '../../types/models/AuthUser'

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
}

export enum RoutePermittedRole {
  globalAdmin = 'globalAdmin',
  member = 'member',
  merchant = 'merchant',
  merchantFranchisor = 'merchantFranchisor',
  countryOwner = 'countryOwner',
  stateOwner = 'stateOwner',
  regionOwner = 'regionOwner',
  areaOwner = 'areaOwner',
  charity = 'charity',
  club = 'club',
  salesAgent = 'salesAgent',
  company = 'company',
  collaborationPartner = 'collaborationPartner',
  whiteLabelUser = 'whiteLabelUser',
  gmcUser = 'gmcUser',
  User = 'user',
  websiteAdmin = 'websiteAdmin',
}

export const defaultUser: AuthUser = {
  uid: 'john-alex',
  displayName: 'John Alex',
  email: 'demot@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: '/assets/images/avatar/A11.jpg',
}
export const initialUrl = '/dashboard' // this url will open after login
export const subDomainInitialUrl = '/sendMerchantRequest' // this url will open after login
