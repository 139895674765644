import React from 'react'
import { StrictMode } from 'react'

import '@crema/services'

// for rich text editor in add email template
import 'react-quill/dist/quill.snow.css'

import { createRoot } from 'react-dom/client'

import App from './App'
import EoiApp from './EoiApp'
import reportWebVitals from './reportWebVitals'
import moment from 'moment-timezone'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

const parsedData = window.location.host.split('.')

moment.tz.setDefault('UTC')

if (parsedData.length >= 2 && parsedData[0] === 'eoi') {
  root.render(
    <StrictMode>
      <EoiApp />
    </StrictMode>
  )
} else {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
