import React from 'react'
import AppCard from '@crema/core/AppCard'
import { Box, Grid, Button, CircularProgress } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import LanguageSelect from 'components/DropdownComponents/LanguageSingleSelect'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { trimNewLine } from 'utils'

const ListTop = ({
  onAddMerchantMultilingualStart,
  onEditMerchantMultilingualStart,
  setSelectedDataToEdit,
  refreshPageData,
  merchantId,
  selectedDataToEdit,
  isEditing,
  isCreating,
}) => {
  const { languageList } = useSelector((state: RootState) => state.dropDown)

  const validationSchema = yup.object({
    // name: yup.string(),
    // areaId: yup.object().nullable(),
    // turnedToPremiumDate: yup.date().nullable(),
  })

  const onSubmitSearch = (data) => {
    if (data?.data?.action === 'add') {
      onAddMerchantMultilingualStart({
        params: {
          ...data?.data,
          lang: data?.data?.lang?.lang,
          merchantId,
          merchantDesciption: data?.data?.merchantDesciption
            ? trimNewLine(data?.data?.merchantDesciption)
            : '',
          countryLanguage: data?.data?.lang?.countryLanguage,
        },
        refreshPageData,
        resetForm: data?.resetForm,
      })
    } else {
      onEditMerchantMultilingualStart({
        params: {
          ...data?.data,
          lang: data?.data?.lang?.lang,
          merchantId,
          id: selectedDataToEdit?.id,
          merchantDesciption: data?.data?.merchantDesciption
            ? trimNewLine(data?.data?.merchantDesciption)
            : '',
          countryLanguage: data?.data?.lang?.countryLanguage,
        },
        refreshPageData,
        resetForm: data?.resetForm,
      })
    }
  }

  return (
    <>
      <AppCard>
        <Box>
          <Formik
            validateOnChange={true}
            enableReinitialize
            initialValues={{
              merchantDesciption: selectedDataToEdit?.merchantDesciption ?? '',
              merchantName: selectedDataToEdit?.merchantName ?? '',
              merchantOpeningHours:
                selectedDataToEdit?.merchantOpeningHours ?? '',
              lang: selectedDataToEdit?.lang
                ? languageList?.find(
                    (item) => item?.lang === selectedDataToEdit?.lang
                  )
                : null,
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              onSubmitSearch({ data, resetForm })

              setSubmitting(false)
            }}
          >
            {({ resetForm, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={3}>
                    <Field
                      name="lang"
                      label="Select language"
                      component={LanguageSelect}
                      forOtherAdminPanels={true}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <AppTextField
                      placeholder={'Merchant Name'}
                      label={'Merchant Name'}
                      name="merchantName"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <AppTextField
                      placeholder={'Merchant Description'}
                      label={'Merchant Description'}
                      multiline={true}
                      rows={5}
                      name="merchantDesciption"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <AppTextField
                      placeholder={'Opening Hours'}
                      label="Opening Hours"
                      name="merchantOpeningHours"
                      variant="outlined"
                      multiline={true}
                      rows={5}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item>
                      {_.isEmpty(selectedDataToEdit) ? (
                        <Button
                          size="small"
                          sx={{
                            px: 5,
                            color: '#ffffff',
                            backgroundColor: '#ec4785',
                            '&:hover': {
                              background: '#ec4785',
                            },
                          }}
                          type="submit"
                          onClick={() => {
                            setFieldValue('action', 'add')
                          }}
                        >
                          {isCreating ? <CircularProgress size={20} /> : 'Add'}
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          sx={{
                            px: 5,
                            color: '#ffffff',
                            backgroundColor: '#ec4785',
                            '&:hover': {
                              background: '#ec4785',
                            },
                          }}
                          type="submit"
                          onClick={() => {
                            setFieldValue('action', 'edit')
                          }}
                        >
                          {isEditing ? <CircularProgress size={20} /> : 'Edit'}
                        </Button>
                      )}
                    </Grid>
                    {!_.isEmpty(selectedDataToEdit) && (
                      <Grid item>
                        <Button
                          size="small"
                          sx={{
                            px: 5,
                          }}
                          type="button"
                          variant="outlined"
                          onClick={() => {
                            resetForm()
                            setSelectedDataToEdit({})
                          }}
                        >
                          Clear
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </AppCard>

      <Box sx={{ height: 30 }} />
    </>
  )
}

export default ListTop
