import {
  AttachMoney,
  HeadsetMic,
  PlayArrow,
  Policy,
  MonetizationOn,
} from '@mui/icons-material'
import React, { ReactNode } from 'react'
import { FaUsers } from 'react-icons/fa'
import { GrHome } from 'react-icons/gr'
import { MdOutlinePayment } from 'react-icons/md'
import AccountBoxIcon from '@mui/icons-material/AccountBox'

import { RoutePermittedRole } from '../../shared/constants/AppConst'
import LogoutIcon from '@mui/icons-material/Logout'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import PaymentIcon from '@mui/icons-material/Payment'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const merchantAdminRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
      },

      {
        icon: <AccountBoxIcon />,
        id: 'myAccount',
        title: 'myAccount',
        messageId: 'My Account',
        type: 'collapse',
        children: [
          {
            id: 'myAccount',
            title: 'myAccount',
            messageId: 'My Profile',
            type: 'item',
            // icon: <FiUser />,
            url: '/my-account',
          },
          {
            id: 'paymentMethod',
            title: 'paymentMethod',
            messageId: 'Payment Methods',
            type: 'item',
            url: '/merchant/payment-method',
          },
          {
            id: 'upgradePaymentMethod',
            title: 'upgradePaymentMethod',
            messageId: 'Upgrade/Renew Package',
            type: 'item',
            url: '/merchant/upgrade-payment',
          },
          {
            id: 'billingInfo',
            title: 'billingInfo',
            messageId: 'Billing Info',
            type: 'item',
            url: '/merchant/billing-info',
          },
        ],
      },

      //copy object to display sidebar without billing
      {
        icon: <AccountBoxIcon />,
        id: 'myAccountWithOutBill',
        title: 'myAccountWithOutBill',
        messageId: 'My Account',
        type: 'collapse',
        children: [
          {
            id: 'myAccount',
            title: 'myAccount',
            messageId: 'My Profile',
            type: 'item',
            // icon: <FiUser />,
            url: '/my-account',
          },
          {
            id: 'paymentMethod',
            title: 'paymentMethod',
            messageId: 'Payment Methods',
            type: 'item',
            url: '/merchant/payment-method',
          },
          {
            id: 'upgradePaymentMethod',
            title: 'upgradePaymentMethod',
            messageId: 'Upgrade/Renew Package',
            type: 'item',
            url: '/merchant/upgrade-payment',
          },
        ],
      },

      {
        id: 'memberslist',
        title: 'Members',
        messageId: 'Members',
        icon: <FaUsers />,
        type: 'item',
        url: '/merchant/memberslist',
      },

      // {
      //   icon: <MdOutlinePayment />,
      //   id: 'premiummembercodes',
      //   title: 'Premium Member Codes',
      //   messageId: 'Premium Member Codes',
      //   type: 'item',
      //   url: '/merchant/premium-member-codes',
      // },

      {
        id: 'terminal',
        title: 'Terminal',
        messageId: 'Terminal',
        icon: <FaUsers />,
        type: 'item',
        url: '/merchant/terminal',
      },
      {
        id: 'users',
        title: 'Users',
        messageId: 'Users',
        icon: <FaUsers />,
        type: 'item',
        url: '/merchant/users',
      },
      {
        id: 'PremiumCodes',
        title: 'Codes',
        messageId: 'Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'premiummerchantcodes',
            title: 'Premium Member Codes',
            messageId: 'Premium Member Codes',
            type: 'item',
            url: '/merchant/premium-member-codes',
          },
        ],
      },

      {
        id: 'merchantTransactions',
        title: 'merchantTransactions',
        messageId: 'Merchant Transactions',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'PremiumCodesHistory',
            title: 'Premium Codes History',
            messageId: 'Package Purchase History',
            type: 'item',
            url: '/merchant/premium-codes-history',
          },
          {
            id: 'MerchantReport',
            title: 'Merchant Report',
            messageId: 'Merchant Report',
            type: 'item',
            url: '/merchant/friendship-earning/asmerchant',
          },
          {
            // icon: <Wallet />,
            id: 'merchantWallet',
            title: 'merchantWallet',
            messageId: 'Merchant Rebate',
            type: 'item',
            url: '/merchant/merchant-wallet',
          },
        ],
      },

      // {
      //   id: 'PremiumCodesHistory',
      //   title: 'Premium Codes History',
      //   messageId: 'Package Purchase History',
      //   type: 'item',
      //   icon: <MdOutlinePayment />,
      //   url: '/merchant/premium-codes-history',
      // },

      // {
      //   icon: <AttachMoney />,
      //   id: 'friendshipearning',
      //   title: 'FriendshipEarning',
      //   messageId: 'Friendship Earning',
      //   type: 'collapse',
      //   children: [
      //     // {
      //     //   id: 'asMerchant',
      //     //   title: 'AsMerchant',
      //     //   messageId: 'As a Merchant',
      //     //   type: 'item',
      //     //   url: '/merchant/friendship-earning/asmerchant',
      //     // },
      //     {
      //       id: 'asIssuer',
      //       title: 'AsIssuer',
      //       messageId: 'As an Issuer',
      //       type: 'item',
      //       url: '/merchant/friendship-earning/asissuer',
      //     },
      //     {
      //       id: 'supposedToEarnAsAnIssuer',
      //       title: 'supposedToEarnAsAnIssuer',
      //       messageId: 'Supposed Earning As Issuer',
      //       type: 'item',
      //       url: '/merchant/friendship-earning/supposed-earning-as-issuer',
      //     },
      //   ],
      // },

      //copy of frienshipearning without AsIssuer

      {
        icon: <AttachMoney />,
        id: 'FriendshipEarningWithoutAsAnIssuer',
        title: 'FriendshipEarningWithoutAsAnIssuer',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          {
            id: 'supposedToEarnAsAnIssuer',
            title: 'supposedToEarnAsAnIssuer',
            messageId: 'Supposed Earning As Issuer',
            type: 'item',
            url: '/merchant/friendship-earning/supposed-earning-as-issuer',
          },
        ],
      },

      //copy of frienshipearning without supposedToEarnAsAnIssuer
      {
        icon: <AttachMoney />,
        id: 'FriendshipEarningWithoutSupposedIssuer',
        title: 'FriendshipEarningWithoutSupposedIssuer',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/merchant/friendship-earning/asissuer',
          },
        ],
      },

      // {
      //   icon: <AttachMoney />,
      //   id: 'memberearning',
      //   title: 'membershipEarning',
      //   messageId: 'Membership Earning',
      //   type: 'collapse',
      //   children: [
      //     {
      //       id: 'asIssuer',
      //       title: 'AsIssuer',
      //       messageId: 'As an Issuer',
      //       type: 'item',
      //       url: '/merchant/membership-earning/asissuer',
      //     },
      //     {
      //       id: 'supposedToEarnAsAnIssuer',
      //       title: 'supposedToEarnAsAnIssuer',
      //       messageId: 'Supposed Earning As Issuer',
      //       type: 'item',
      //       url: '/merchant/membership-earning/supposed-earning-as-issuer',
      //     },
      //   ],
      // },

      //copy of memberhipEarning without AsAnIssuer

      {
        icon: <AttachMoney />,
        id: 'memberEarningWithoutAsAnIssuer',
        title: 'membershipEarningWithoutAsAnIssuer',
        messageId: 'Membership Earning',
        type: 'collapse',
        children: [
          {
            id: 'supposedToEarnAsAnIssuer',
            title: 'supposedToEarnAsAnIssuer',
            messageId: 'Supposed Earning As Issuer',
            type: 'item',
            url: '/merchant/membership-earning/supposed-earning-as-issuer',
          },
        ],
      },

      //copy of memberhipEarning without supposedToEarnAsAnIssuer

      {
        icon: <AttachMoney />,
        id: 'memberEarningWithoutSupposedIssuer',
        title: 'membershipEarningWithoutSupposedIssuer',
        messageId: 'Membership Earning',
        type: 'collapse',
        children: [
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/merchant/membership-earning/asissuer',
          },
        ],
      },

      {
        icon: <PlayArrow />,
        id: 'trainingVideos',
        title: 'trainingVideos',
        messageId: 'Trainings ',
        type: 'item',
        url: '/common/trainings',
        // url: '/training-videos',
      },
      {
        icon: <HeadsetMic />,
        id: 'support',
        title: 'support',
        messageId: 'Contact Piiink',
        type: 'item',
        url: '/support',
      },
      //panda docs section starts
      {
        id: 'documentManagement',
        title: 'document Management',
        messageId: 'Document Management',
        type: 'collapse',
        icon: <ContentPasteIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/merchant/setting/smtp',
          // },
          {
            id: 'group',
            title: 'Group',
            messageId: 'Group',
            type: 'item',
            url: '/merchant/setting/group',
          },

          {
            id: 'contacts',
            title: 'Contacts',
            messageId: 'Contacts',
            type: 'item',
            url: '/merchant/setting/contacts',
          },

          {
            id: 'documents',
            title: 'Documents',
            messageId: 'Documents',
            type: 'item',
            url: '/merchant/setting/documents',
          },

          {
            id: 'emails',
            title: 'Emails',
            messageId: 'Emails',
            type: 'item',
            url: '/merchant/setting/emails',
          },
        ],
      },

      //panda docs section ends
      {
        id: 'payments',
        title: 'payments',
        messageId: 'Payments',
        type: 'collapse',
        icon: <PaymentIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/salesagent/setting/smtp',
          // },
          {
            id: 'connect stripe',
            title: 'connect stripe',
            messageId: 'Connect Stripe',
            type: 'item',
            url: '/merchant/payment/connect-stripe',
          },
        ],
      },
      {
        icon: <MonetizationOn />,
        id: 'prizedraw',
        title: 'prizedraw',
        messageId: 'Prize Draw',
        type: 'item',
        url: '/prizedraw',
      },
      // {
      //   icon: <GrDatabase />,
      //   id: 'agreements',
      //   title: 'agreements',
      //   messageId: 'Agreements',
      //   type: 'item',
      //   url: '/merchant/agreements',
      // },
      {
        icon: <Policy />,
        id: 'terms',
        title: 'terms',
        messageId: 'Terms & Conditions',
        type: 'item',
        url: '/terms',
      },
      {
        icon: <ReceiptIcon />,
        id: 'coupons',
        title: 'coupons',
        messageId: 'Coupons',
        type: 'item',
        url: '/merchant/coupons',
      },
      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },

      // {
      //   icon: <AccountBoxIcon />,
      //   id: 'pinkEcommerceCheckout',
      //   title: 'pinkEcommerceCheckout',
      //   messageId: 'Ecommerce Checkout',
      //   type: 'collapse',
      //   children: [
      //     {
      //       id: 'integrationGuide',
      //       title: 'integrationGuide',
      //       messageId: 'Integration Guide',
      //       type: 'item',
      //       // icon: <FiUser />,
      //       url: '/merchant/integrationGuide',
      //     },
      //   ],
      // },
    ],
  },
]

export default merchantAdminRoutesConfig
