import { createSlice } from '@reduxjs/toolkit'

import { ITrainingVideoSetup } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: ITrainingVideoSetup = {
  trainingVideoList: listObj,
  trainingVideoCategoryList: listObj,
  soleTrainingVideo: {},
  soleTrainingVideoCategory: {},
  error: null,
  loading: false,
  isFetching: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  hasMoreData: false,
}

export const trainingVideoSlice = createSlice({
  name: 'trainingVideoSetup',
  initialState: INITIAL_STATE,
  reducers: {
    fetchTrainingVideoStart: (state: any, action) => {
      state.loading = true
    },
    fetchTrainingVideoSuccess: (state: any, action) => {
      state.trainingVideoList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchTrainingVideoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTrainingVideoByPanelStart: (state: any, action) => {
      state.loading = true
    },

    fetchTrainingVideoByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTrainingVideoByIdSuccess: (state: any, action) => {
      state.soleTrainingVideo = action.payload.data
      state.isFetching = false
    },
    fetchTrainingVideoByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createTrainingVideoStart: (state: any, action) => {
      state.loading = true
      state.isCreating = true
    },
    createTrainingVideoSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.isCreating = false
      state.trainingVideoList.data = [
        ...state.trainingVideoList.data,
        action.payload,
      ]
    },
    createTrainingVideoFailure: (state: any, action) => {
      state.loading = false
      state.isCreating = false
      state.error = action.payload
    },
    deleteTrainingVideoStart: (state: any, action) => {
      state.loading = true
    },
    deleteTrainingVideoSuccess: (state: any, action) => {
      state.loading = false
      state.trainingVideoList.data = state.trainingVideoList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteTrainingVideoFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editTrainingVideoStart: (state: any, action) => {
      state.loading = true
      state.isEditing = true
    },
    editTrainingVideoSuccess: (state: any, action) => {
      const result = state.trainingVideoList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.trainingVideoList.data = result
      state.loading = false
      state.sEditing = false
    },
    editTrainingVideoFailure: (state: any, action) => {
      state.loading = false
      state.isEditing = false
      state.error = action.payload
    },

    createTrainingVideoCategoryStart: (state: any, action) => {
      state.isCreating = true
    },
    createTrainingVideoCategorySuccess: (state: any, action) => {
      state.isCreating = false
    },
    createTrainingVideoCategoryFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editTrainingVideoCategoryStart: (state: any, action) => {
      state.isEditing = true
    },
    editTrainingVideoCategorySuccess: (state: any, action) => {
      state.isEditing = false
    },
    editTrainingVideoCategoryFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    deleteTrainingVideoCategoryStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteTrainingVideoCategorySuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteTrainingVideoCategoryFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    fetchTrainingVideoCategoryStart: (state: any, action) => {
      state.loading = true
    },
    fetchTrainingVideoCategorySuccess: (state: any, action) => {
      state.trainingVideoCategoryList = action.payload
      state.loading = false
    },
    fetchTrainingVideoCategoryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchTrainingVideoCategoryByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTrainingVideoCategoryByIdSuccess: (state: any, action) => {
      state.soleTrainingVideoCategory = action.payload.data
      state.isFetching = false
    },
    fetchTrainingVideoCategoryByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTrainingVideoCategoryAllByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchTrainingVideoCategoryAllByPanelSuccess: (state: any, action) => {
      state.trainingVideoCategoryList = action.payload
      state.loading = false
    },
    fetchTrainingVideoCategoryAllByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchTrainingVideoStart,
  fetchTrainingVideoSuccess,
  fetchTrainingVideoFailure,
  createTrainingVideoStart,
  createTrainingVideoSuccess,
  createTrainingVideoFailure,
  deleteTrainingVideoStart,
  deleteTrainingVideoSuccess,
  deleteTrainingVideoFailure,
  fetchTrainingVideoByIdStart,
  fetchTrainingVideoByIdSuccess,
  fetchTrainingVideoByIdFailure,
  editTrainingVideoStart,
  editTrainingVideoSuccess,
  editTrainingVideoFailure,
  fetchTrainingVideoByPanelStart,

  createTrainingVideoCategoryStart,
  createTrainingVideoCategorySuccess,
  createTrainingVideoCategoryFailure,

  fetchTrainingVideoCategoryStart,
  fetchTrainingVideoCategorySuccess,
  fetchTrainingVideoCategoryFailure,

  fetchTrainingVideoCategoryByIdStart,
  fetchTrainingVideoCategoryByIdSuccess,
  fetchTrainingVideoCategoryByIdFailure,

  editTrainingVideoCategoryStart,
  editTrainingVideoCategorySuccess,
  editTrainingVideoCategoryFailure,

  deleteTrainingVideoCategoryStart,
  deleteTrainingVideoCategorySuccess,
  deleteTrainingVideoCategoryFailure,

  fetchTrainingVideoCategoryAllByPanelStart,
  fetchTrainingVideoCategoryAllByPanelSuccess,
  fetchTrainingVideoCategoryAllByPanelFailure,
} = trainingVideoSlice.actions

export default trainingVideoSlice.reducer
