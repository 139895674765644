import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

interface BreadCrumbProps {
  list: { label: string; icon?: any; action?: () => void }[]
}

const BreadCrumb = (props: BreadCrumbProps) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props.list.map((item) => (
        <StyledBreadcrumb
          label={item.label}
          onClick={item?.action}
          icon={item?.icon}
        />
      ))}
    </Breadcrumbs>
  )
}

export default BreadCrumb
