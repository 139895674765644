import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import * as whiteLabelAction from './whiteLabelSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchWhiteLabelList,
  createWhiteLabel,
  fetchWhiteLabelById,
  editWhiteLabel,
  deleteWhiteLabel,
  createWhiteLabelPosition,
  fetchWhiteLabelPositionList,
  getWhiteLabelPositionById,
  createWhiteLabelSubposition,
  editWhiteLabelPosition,
  createWhiteLabelFriendShip,
  fetchWhiteLabelFriendShip,
  fetchWhiteLabelFriendShipById,
  editWhiteLabelFriendShip,
  createWhiteLabelMemberShip,
  fetchWhiteLabelMemberShip,
  fetchWhiteLabelMemberShipById,
  editWhiteLabelMemberShip,
  deleteWhiteLabelFriendShip,
  deleteWhiteLabelMemberShip,
  getWhiteLabelPromotionalLevel,
  createWhiteLabelCPFriendshipExternalAllocation,
  createWhiteLabelCPFriendshipScenariosAllocation,
  editWhiteLabelCPFriendshipScenariosAllocation,
  createWhiteLabelCPMembershipScenariosAllocation,
  editWhiteLabelCPMembershipScenariosAllocation,
  createWhiteLabelCPMembershipExternalAllocation,
  fetchWhiteLabelCPFriendshipScenarioList,
  fetchWhiteLabelCPFriendshipScenarioById,
  fetchWhiteLabelCPMembershipScenarioList,
  fetchWhiteLabelCPMembershipScenarioById,
  fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelId,
  fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelId,
  editWhiteLabelPositionBasePromotional,
  editWhiteLabelPositionNonZeroPromotionalLevel,
} from '../../api/whiteLabel'

import {
  editWhiteLabelUser,
  deleteWhiteLabelUser,
  fetchWhiteLabelUserById,
  createWhiteLabelUser,
  fetchWhiteLabelUserList,
  fetchWhiteLabelUserByWhiteLabel,
  checkActivationEligibility,
  activateWhiteLabel,
  fetchWhiteLabelProfileInfo,
  fetchWhiteLabelUserListByPanel,
  editOwnWhiteLabelProfile,
} from '../../api/whiteLabelUser'

import _ from 'lodash'

export function* fetchPendingWhiteLabelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelList(payload))

    yield put(whiteLabelAction.fetchPendingWhiteLabelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.fetchPendingWhiteLabelFailure(err))
    console.error(err)
  }
}

export function* fetchWhiteLabelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelList(payload))

    yield put(whiteLabelAction.fetchWhiteLabelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelFailure(err))
    console.error(err)
  }
}

export function* fetchWhiteLabelProfileInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelProfileInfo(payload))
    yield put(whiteLabelAction.fetchWhiteLabelProfileInfoSuccess(data))
  } catch (err) {
    yield put(whiteLabelAction.fetchWhiteLabelProfileInfoFailure(err))
  }
}

export function* createWhiteLabelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createWhiteLabel(payload.params))
    yield put(whiteLabelAction.createWhiteLabelSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'White Label successfully created',
        severity: 'success',
      })
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelStart({ page: 1, limit: 10, offset: 0 })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.createWhiteLabelFailure(err))

    console.error(err)
  }
}

export function* deleteWhiteLabelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      deleteWhiteLabel(payload?.id, { rejectReason: payload?.rejectReason })
    )

    if (data) {
      yield put(whiteLabelAction.deleteWhiteLabelSuccess(payload?.id))
      yield payload.closeDeleteModal()
      yield put(
        openAlert({
          message: 'Successfully deleted',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.deleteWhiteLabelFailure(err))
  }
}

export function* fetchWhiteLabelByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelById(payload))

    yield put(whiteLabelAction.fetchWhiteLabelByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.fetchWhiteLabelByIdFailure(err))
    console.error(err)
  }
}

export function* editWhiteLabelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editWhiteLabel(payload?.params?.id, payload?.params)
    )
    yield put(whiteLabelAction.editWhiteLabelSuccess(data?.data))
    yield put(
      whiteLabelAction.fetchWhiteLabelStart({ page: 1, limit: 10, offset: 0 })
    )
    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'White Label successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.editWhiteLabelFailure(err))
    console.error(err)
  }
}

export function* createWhiteLabelPositionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createWhiteLabelPosition(payload.params))
    yield put(whiteLabelAction.createWhiteLabelPositionSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      openAlert({ message: 'Levels successfully created', severity: 'success' })
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelStart({ page: 1, limit: 10, offset: 0 })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.createWhiteLabelPositionFailure(err))
  }
}

export function* fetchWhiteLabelPositionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelPositionList(payload))

    yield put(whiteLabelAction.fetchWhiteLabelPositionSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelPositionFailure(err))
    console.error(err)
  }
}

export function* fetchAllUniqueWhiteLabelPositionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getWhiteLabelPositionById(payload?.id))

    yield put(whiteLabelAction.fetchAllUniqueWhiteLabelPositionSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchAllUniqueWhiteLabelPositionFailure(err))
    console.error(err)
  }
}

// fetchAllUniqueWhiteLabelPositionStart

export function* createWhiteLabelSubpositionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createWhiteLabelSubposition(payload.params))
    yield put(whiteLabelAction.createWhiteLabelSubpositionSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      openAlert({ message: 'succesfully created', severity: 'success' })
    )

    yield put(
      whiteLabelAction.fetchAllUniqueWhiteLabelPositionStart({
        id: payload?.id,
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.createWhiteLabelSubpositionFailure(err))
  }
}

export function* editWhiteLabelSubpositionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editWhiteLabelPosition(payload?.params?.id, payload?.params)
    )
    yield put(whiteLabelAction.editWhiteLabelSubpositionSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      whiteLabelAction.fetchAllUniqueWhiteLabelPositionStart({
        id: payload?.id,
        page: 1,
        limit: 10,
        offset: 0,
      })
    )

    yield put(openAlert({ message: 'succesfully edited', severity: 'success' }))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.editWhiteLabelSubpositionFailure(err))
    console.error(err)
  }
}

export function* editWhiteLabelPositionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editWhiteLabelPositionBasePromotional(
        payload?.params?.id,
        payload?.params
      )
    )
    yield payload.closeModal()

    yield put(
      openAlert({ message: 'Levels successfully edited', severity: 'success' })
    )

    yield put(
      whiteLabelAction.fetchAllUniqueWhiteLabelPositionStart({
        id: payload?.id,
        page: 1,
        limit: 10,
        offset: 0,
      })
    )

    yield put(whiteLabelAction.editWhiteLabelPositionSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.editWhiteLabelPositionFailure(err))
    console.error(err)
  }
}

export function* editWhiteLabelNonZeroPromotionalLevelAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      editWhiteLabelPositionNonZeroPromotionalLevel(
        payload?.params?.id,
        payload?.params
      )
    )
    yield payload.closeModal()

    yield put(
      openAlert({ message: 'Levels successfully edited', severity: 'success' })
    )

    yield put(
      whiteLabelAction.fetchAllUniqueWhiteLabelPositionStart({
        id: payload?.id,
        page: 1,
        limit: 10,
        offset: 0,
      })
    )

    yield put(
      whiteLabelAction.editWhiteLabelNonZeroPromotionalLevelSuccess(data?.data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.editWhiteLabelNonZeroPromotionalLevelFailure(err)
    )
    console.error(err)
  }
}

export function* createWhiteLabelSubPositionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createWhiteLabelSubposition(payload.params))
    yield put(whiteLabelAction.createWhiteLabelSubpositionSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      openAlert({ message: 'succesfully created', severity: 'success' })
    )
    yield put(
      whiteLabelAction.fetchWhiteLabelStart({ page: 1, limit: 10, offset: 0 })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.createWhiteLabelSubpositionFailure(err))
  }
}

export function* createWhiteLabelFriendShipAsync({ payload }: AnyAction) {
  try {
    // const { data } = yield call(createWhiteLabelFriendShip(payload.params))
    const { data } = yield all(
      payload?.params?.scenario?.map((item) =>
        call(createWhiteLabelFriendShip(item))
      )
    )
    yield put(whiteLabelAction.createWhiteLabelFriendShipSuccess(data?.data))
    yield put(
      whiteLabelAction.fetchWhiteLabelFriendShipStart({
        page: 1,
        limit: 10,
        offset: 0,
        friendshipScenario: 'Scenario1',
      })
    )
    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'White label friendship beneficiaries successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.createWhiteLabelFriendShipFailure(err))
  }
}

export function* fetchWhiteLabelFriendShipAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelFriendShip(payload))

    yield put(whiteLabelAction.fetchWhiteLabelFriendShipSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelFriendShipFailure(err))
    console.error(err)
  }
}

export function* fetchWhiteLabelFriendShipByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelFriendShipById(payload))

    yield put(whiteLabelAction.fetchWhiteLabelFriendShipByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelFriendShipByIdFailure(err))
    console.error(err)
  }
}

export function* editWhiteLabelFriendShipAsync({ payload }: AnyAction) {
  try {
    const { data } = yield all(
      payload?.params?.scenario?.map((item) =>
        call(editWhiteLabelFriendShip(item?.id, item))
      )
    )

    yield put(whiteLabelAction.editWhiteLabelFriendShipSuccess(data?.data))

    yield put(
      openAlert({
        message: 'Sucessfully edited',
        severity: 'success',
      })
    )

    yield payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.editWhiteLabelFriendShipFailure(err))
    console.error(err)
  }
}

export function* editWhiteLabelMemberShipAsync({ payload }: AnyAction) {
  try {
    const { data } = yield all(
      payload?.params?.scenario?.map((item) =>
        call(editWhiteLabelMemberShip(item?.id, item))
      )
    )

    yield put(whiteLabelAction.editWhiteLabelMemberShipSuccess(data?.data))

    yield put(
      openAlert({
        message: 'Sucessfully edited',
        severity: 'success',
      })
    )

    yield payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.editWhiteLabelMemberShipFailure(err))
    console.error(err)
  }
}

export function* deleteWhiteLabelFriendShipAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteWhiteLabelFriendShip(payload?.id))

    yield put(whiteLabelAction.deleteWhiteLabelFriendShipSuccess(payload?.id))
    yield payload.closeDeleteModal()
    yield put(
      openAlert({
        message: 'Successfully deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.deleteWhiteLabelFriendShipFailure(err))
  }
}

export function* createWhiteLabelMemberShipAsync({ payload }: AnyAction) {
  try {
    // const { data } = yield call(createWhiteLabelFriendShip(payload.params))
    const { data } = yield all(
      payload?.params?.scenario?.map((item) =>
        call(createWhiteLabelMemberShip(item))
      )
    )
    yield put(whiteLabelAction.createWhiteLabelMemberShipSuccess(data?.data))
    yield put(
      whiteLabelAction.fetchWhiteLabelMemberShipStart({
        page: 1,
        limit: 10,
        membershipScenario: 'Scenario1',
        offset: 0,
      })
    )
    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'White label membership beneficiaries successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.createWhiteLabelMemberShipFailure(err))
  }
}

export function* fetchWhiteLabelMemberShipAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelMemberShip(payload))

    yield put(whiteLabelAction.fetchWhiteLabelMemberShipSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelMemberShipFailure(err))
    console.error(err)
  }
}

export function* fetchWhiteLabelMemberShipByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelMemberShipById(payload))

    yield put(whiteLabelAction.fetchWhiteLabelMemberShipByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelMemberShipByIdFailure(err))
    console.error(err)
  }
}

export function* deleteWhiteLabelMemberShipAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteWhiteLabelMemberShip(payload?.id))

    yield put(whiteLabelAction.deleteWhiteLabelMemberShipSuccess(payload?.id))
    yield payload.closeDeleteModal()
    yield put(
      openAlert({
        message: 'Successfully deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.deleteWhiteLabelMemberShipFailure(err))
  }
}

export function* fetchWhiteLabelUserAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelUserList(payload))

    yield put(whiteLabelAction.fetchWhiteLabelUserSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelUserFailure(err))
    console.error(err)
  }
}

export function* fetchAllWhiteLabelUserByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelUserListByPanel(payload))

    yield put(whiteLabelAction.fetchAllWhiteLabelUserByPanelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchAllWhiteLabelUserByPanelFailure(err))
    console.error(err)
  }
}

export function* fetchWhiteLabelUserByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelUserById(payload))

    yield put(
      whiteLabelAction.fetchWhiteLabelUserByIdSuccess({
        ...data?.data,
        issuerCode: data?.issuerCode,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelUserByIdFailure(err))
    console.error(err)
  }
}

export function* deleteWhiteLabelUserAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteWhiteLabelMemberShip(payload?.id))

    yield put(whiteLabelAction.deleteWhiteLabelMemberShipSuccess(payload?.id))
    yield payload.closeDeleteModal()
    yield put(
      openAlert({
        message: 'Successfully deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.deleteWhiteLabelMemberShipFailure(err))
  }
}

export function* createWhiteLabelUserAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createWhiteLabelUser(payload.params))
    yield put(whiteLabelAction.createWhiteLabelUserSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'User successfully created',
        severity: 'success',
      })
    )

    if (payload?.params?.isBalancingLevel) {
      yield put(
        whiteLabelAction.fetchWhiteLabelUserStart({
          page: 1,
          limit: 10,
          offset: 0,
          whiteLabelId: payload?.params?.whiteLabelId,
        })
      )
    } else {
      yield put(
        whiteLabelAction.fetchWhiteLabelUserStart({
          page: 1,
          limit: 10,
          offset: 0,
          userSignerId: payload?.params?.userSignerId,
        })
      )
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.createWhiteLabelUserFailure(err))
  }
}

export function* fetchWhiteLabelPromotionalLevelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getWhiteLabelPromotionalLevel(payload))

    yield put(whiteLabelAction.fetchWhiteLabelPromotionalLevelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelPromotionalLevelFailure(err))
    console.error(err)
  }
}

export function* editWhiteLabelUserAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editWhiteLabelUser(payload?.params?.id, payload?.params)
    )
    yield put(whiteLabelAction.editWhiteLabelUserSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      if (payload?.params?.isBalancingLevel) {
        whiteLabelAction.fetchWhiteLabelUserStart({
          page: 1,
          limit: 10,
          offset: 0,
          whiteLabelId: payload?.params?.whiteLabelId,
        })
      } else {
        yield put(
          whiteLabelAction.fetchWhiteLabelUserStart({
            page: 1,
            limit: 10,
            offset: 0,
            userSignerId: payload?.params?.userSignerId,
          })
        )
      }
    }

    yield put(
      openAlert({
        message: 'User succesfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.editWhiteLabelUserFailure(err))
  }
}

export function* fetchWhiteLabelUserByWhiteLabelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelUserByWhiteLabel(payload?.params)
    )

    yield put(whiteLabelAction.fetchWhiteLabelUserByWhiteLabelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(whiteLabelAction.fetchWhiteLabelUserByWhiteLabelFailure(err))
    console.error(err)
  }
}

export function* checkWhiteLabelActivationEligibilityAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(checkActivationEligibility(payload))

    yield put(
      whiteLabelAction.checkWhiteLabelActivationEligibilitySuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.checkWhiteLabelActivationEligibilityFailure(err))
    console.error(err)
  }
}

export function* whiteLabelActivateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      activateWhiteLabel(payload?.id, payload?.params)
    )

    yield put(whiteLabelAction.whiteLabelActivateSuccess(data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'White label is activated succesfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(whiteLabelAction.whiteLabelActivateFailure(err))
    console.error(err)
  }
}

export function* fetchWhiteLabelCPFriendshipScenarioListAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelCPFriendshipScenarioList(payload?.id, payload)
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPFriendshipScenariosListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPFriendshipScenariosListFailure(err)
    )
  }
}

export function* createWhiteLabelCPFriendshipExternalAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      createWhiteLabelCPFriendshipExternalAllocation(payload?.params?.data)
    )
    yield put(
      whiteLabelAction.createWhiteLabelCPFriendshipExternalAllocationSuccess(
        data?.data
      )
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: 'Settings successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.createWhiteLabelCPFriendshipExternalAllocationFailure(
        err
      )
    )
  }
}

export function* createWhiteLabelCPFriendshipScenariosAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      createWhiteLabelCPFriendshipScenariosAllocation(payload?.params)
    )
    yield put(
      whiteLabelAction.createWhiteLabelCPFriendshipScenariosAllocationSuccess(
        data?.data
      )
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: ` ${payload?.scenarioName ?? 'scenario'} successfully  ${
          payload?.fromEdit ? 'edited' : 'created'
        }`,

        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.createWhiteLabelCPFriendshipScenariosAllocationFailure(
        err
      )
    )
  }
}

export function* createWhiteLabelCPMembershipExternalAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      createWhiteLabelCPMembershipExternalAllocation(payload?.params?.data)
    )
    yield put(
      whiteLabelAction.createWhiteLabelCPMembershipExternalAllocationSuccess(
        data?.data
      )
    )

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: `Settings successfully created`,
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.createWhiteLabelCPMembershipExternalAllocationFailure(
        err
      )
    )
  }
}

export function* editWhiteLabelCPFriendshipScenariosAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      editWhiteLabelCPFriendshipScenariosAllocation(
        payload?.params?.id,
        payload?.params
      )
    )
    yield put(
      whiteLabelAction.editWhiteLabelCPFriendshipScenariosAllocationSuccess(
        data?.data
      )
    )

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: `${payload?.scenarioName ?? 'scenario'} successfully edited`,
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.editWhiteLabelCPFriendshipScenariosAllocationFailure(err)
    )
  }
}

export function* createWhiteLabelCPMembershipScenariosAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      createWhiteLabelCPMembershipScenariosAllocation(payload?.params)
    )
    yield put(
      whiteLabelAction.createWhiteLabelCPMembershipScenariosAllocationSuccess(
        data?.data
      )
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: `${payload?.scenarioName ?? 'scenario'} successfully ${
          payload?.fromEdit ? 'edited' : 'created'
        }`,
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.createWhiteLabelCPMembershipScenariosAllocationFailure(
        err
      )
    )
  }
}

export function* editWhiteLabelCPMembershipScenariosAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      editWhiteLabelCPMembershipScenariosAllocation(
        payload?.params?.id,
        payload?.params
      )
    )
    yield put(
      whiteLabelAction.editWhiteLabelCPMembershipScenariosAllocationSuccess(
        data?.data
      )
    )

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: `${payload?.scenarioName ?? 'scenario'} successfully edited`,
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.editWhiteLabelCPMembershipScenariosAllocationFailure(err)
    )
  }
}

export function* fetchWhiteLabelCPFriendshipScenarioByIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelCPFriendshipScenarioById(payload)
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPFriendshipScenarioByIdSuccess(
        data?.data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPFriendshipScenarioByIdFailure(err)
    )
  }
}

export function* fetchWhiteLabelCPMembershipScenarioListAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelCPMembershipScenarioList(payload?.id, payload)
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPMembershipScenariosListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPMembershipScenariosListFailure(err)
    )
  }
}

export function* fetchWhiteLabelCPMembershipScenarioByIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelCPMembershipScenarioById(payload)
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPMembershipScenarioByIdSuccess(
        data?.data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPMembershipScenarioByIdFailure(err)
    )
  }
}

export function* fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelId(payload?.id)
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdSuccess(
        data?.data
      )
    )
  } catch (err) {
    // yield put(
    //   openAlert({
    //     message: err.message,
    //     severity: 'error',
    //   })
    // )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdFailure(
        err
      )
    )
  }
}

export function* fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelId(payload?.id)
    )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdSuccess(
        data?.data
      )
    )
  } catch (err) {
    // yield put(
    //   openAlert({
    //     message: err.message,
    //     severity: 'error',
    //   })
    // )

    yield put(
      whiteLabelAction.fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdFailure(
        err
      )
    )
  }
}

export function* editOwnWhiteLabelProfileAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editOwnWhiteLabelProfile(payload?.params))

    yield put(whiteLabelAction.editOwnWhiteLabelProfileSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Profile Edited Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(whiteLabelAction.editOwnWhiteLabelProfileFailure(err))
  }
}

export function* watchPendingWhiteLabel() {
  yield takeLatest(
    'whiteLabel/fetchPendingWhiteLabelStart',
    fetchPendingWhiteLabelAsync
  )
}

export function* watchFetchWhiteLabelProfileInfo() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelProfileInfoStart',
    fetchWhiteLabelProfileInfoAsync
  )
}

export function* watchFetchWhiteLabel() {
  yield takeLatest('whiteLabel/fetchWhiteLabelStart', fetchWhiteLabelAsync)
}

export function* watchCreateWhiteLabel() {
  yield takeLatest('whiteLabel/createWhiteLabelStart', createWhiteLabelAsync)
}

export function* watchDeleteWhiteLabel() {
  yield takeLatest('whiteLabel/deleteWhiteLabelStart', deleteWhiteLabelAsync)
}

export function* watchFetchPendingWhiteLabelById() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelByIdStart',
    fetchWhiteLabelByIdAsync
  )
}

export function* watchEditWhiteLabel() {
  yield takeLatest('whiteLabel/editWhiteLabelStart', editWhiteLabelAsync)
}

export function* watchCreateWhiteLabelPosition() {
  yield takeLatest(
    'whiteLabel/createWhiteLabelPositionStart',
    createWhiteLabelPositionAsync
  )
}

export function* watchFetchWhiteLabelPosition() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelPositionStart',
    fetchWhiteLabelPositionAsync
  )
}

export function* watchFetchAllUniqueWhiteLabelPosition() {
  yield takeLatest(
    'whiteLabel/fetchAllUniqueWhiteLabelPositionStart',
    fetchAllUniqueWhiteLabelPositionAsync
  )
}

export function* watchEditWhiteLabelPosition() {
  yield takeLatest(
    'whiteLabel/editWhiteLabelPositionStart',
    editWhiteLabelPositionAsync
  )
}

export function* watchEditWhiteLabelNonZeroPromotionalLevel() {
  yield takeLatest(
    'whiteLabel/editWhiteLabelNonZeroPromotionalLevelStart',
    editWhiteLabelNonZeroPromotionalLevelAsync
  )
}

export function* watchCreateWhiteLabelSubPosition() {
  yield takeLatest(
    'whiteLabel/createWhiteLabelSubpositionStart',
    createWhiteLabelSubpositionAsync
  )
}

export function* watchCreateWhiteLabelFriendShip() {
  yield takeLatest(
    'whiteLabel/createWhiteLabelFriendShipStart',
    createWhiteLabelFriendShipAsync
  )
}

export function* watchFetchWhiteLabelFriendShip() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelFriendShipStart',
    fetchWhiteLabelFriendShipAsync
  )
}

export function* watchFetchWhiteLabelFriendShipById() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelFriendShipByIdStart',
    fetchWhiteLabelFriendShipByIdAsync
  )
}

export function* watchEditWhiteLabelFriendShip() {
  yield takeLatest(
    'whiteLabel/editWhiteLabelFriendShipStart',
    editWhiteLabelFriendShipAsync
  )
}

export function* watchEditWhiteLabelMemberShip() {
  yield takeLatest(
    'whiteLabel/editWhiteLabelMemberShipStart',
    editWhiteLabelMemberShipAsync
  )
}

export function* watchCreateWhiteLabelMemberShip() {
  yield takeLatest(
    'whiteLabel/createWhiteLabelMemberShipStart',
    createWhiteLabelMemberShipAsync
  )
}

export function* watchFetchWhiteLabelMemberShip() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelMemberShipStart',
    fetchWhiteLabelMemberShipAsync
  )
}

export function* watchFetchWhiteLabelMemberShipById() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelMemberShipByIdStart',
    fetchWhiteLabelMemberShipByIdAsync
  )
}

export function* watchDeleteWhiteLabelFriendShip() {
  yield takeLatest(
    'whiteLabel/deleteWhiteLabelFriendShipStart',
    deleteWhiteLabelFriendShipAsync
  )
}

export function* watchDeleteWhiteLabelMemberShip() {
  yield takeLatest(
    'whiteLabel/deleteWhiteLabelMemberShipStart',
    deleteWhiteLabelMemberShipAsync
  )
}

export function* watchFetchWhiteLabelUser() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelUserStart',
    fetchWhiteLabelUserAsync
  )
}

export function* watchCreateWhiteLabelUser() {
  yield takeLatest(
    'whiteLabel/createWhiteLabelUserStart',
    createWhiteLabelUserAsync
  )
}

export function* watchFetchWhiteLabelUserById() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelUserByIdStart',
    fetchWhiteLabelUserByIdAsync
  )
}

export function* watchFetchWhiteLabelPromotionalLevel() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelPromotionalLevelStart',
    fetchWhiteLabelPromotionalLevelAsync
  )
}

export function* watchEditWhiteLabelUser() {
  yield takeLatest(
    'whiteLabel/editWhiteLabelUserStart',
    editWhiteLabelUserAsync
  )
}

export function* watchFetchWhiteLabelUserByWhiteLabel() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelUserByWhiteLabelStart',
    fetchWhiteLabelUserByWhiteLabelAsync
  )
}

export function* watchCheckWhiteLabelActivationEligibilityAsync() {
  yield takeLatest(
    'whiteLabel/checkWhiteLabelActivationEligibilityStart',
    checkWhiteLabelActivationEligibilityAsync
  )
}
export function* watchWhiteLabelActivateStart() {
  yield takeLatest(
    'whiteLabel/whiteLabelActivateStart',
    whiteLabelActivateAsync
  )
}

export function* watchCreateWhiteLabelCPFriendshipExternalAllocationStart() {
  yield takeLatest(
    'whiteLabel/createWhiteLabelCPFriendshipExternalAllocationStart',
    createWhiteLabelCPFriendshipExternalAllocationAsync
  )
}

export function* watchcreateWhiteLabelCPFriendshipScenariosAllocationStart() {
  yield takeLatest(
    'whiteLabel/createWhiteLabelCPFriendshipScenariosAllocationStart',
    createWhiteLabelCPFriendshipScenariosAllocationAsync
  )
}

export function* watchEditWhiteLabelCPFriendshipScenariosAllocationStart() {
  yield takeLatest(
    'whiteLabel/editWhiteLabelCPFriendshipScenariosAllocationStart',
    editWhiteLabelCPFriendshipScenariosAllocationAsync
  )
}

export function* watchcreateWhiteLabelCPMembershipScenariosAllocationStart() {
  yield takeLatest(
    'whiteLabel/createWhiteLabelCPMembershipScenariosAllocationStart',
    createWhiteLabelCPMembershipScenariosAllocationAsync
  )
}

export function* watchEditWhiteLabelCPMembershipScenariosAllocationStart() {
  yield takeLatest(
    'whiteLabel/editWhiteLabelCPMembershipScenariosAllocationStart',
    editWhiteLabelCPMembershipScenariosAllocationAsync
  )
}

export function* watchCreateWhiteLabelCPMembershipExternalAllocationStart() {
  yield takeLatest(
    'whiteLabel/createWhiteLabelCPMembershipExternalAllocationStart',
    createWhiteLabelCPMembershipExternalAllocationAsync
  )
}

export function* watchFetchWhiteLabelCPFriendshipScenariosListStart() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelCPFriendshipScenariosListStart',
    fetchWhiteLabelCPFriendshipScenarioListAsync
  )
}

export function* watchFetchWhiteLabelCPFriendshipScenarioByIdStart() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelCPFriendshipScenarioByIdStart',
    fetchWhiteLabelCPFriendshipScenarioByIdAsync
  )
}

export function* watchFetchWhiteLabelCPMembershipScenariosListStart() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelCPMembershipScenariosListStart',
    fetchWhiteLabelCPMembershipScenarioListAsync
  )
}

export function* watchFetchWhiteLabelCPMembershipScenarioByIdStart() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelCPMembershipScenarioByIdStart',
    fetchWhiteLabelCPMembershipScenarioByIdAsync
  )
}

export function* watchFetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdStart() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdStart',
    fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdAsync
  )
}

export function* watchFetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdStart() {
  yield takeLatest(
    'whiteLabel/fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdStart',
    fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdAsync
  )
}

export function* watchFetchAllWhiteLabelUserByPanelAsync() {
  yield takeLatest(
    'whiteLabel/fetchAllWhiteLabelUserByPanelStart',
    fetchAllWhiteLabelUserByPanelAsync
  )
}

export function* watchEditOwnWhiteLabelProfileAsync() {
  yield takeLatest(
    'whiteLabel/editOwnWhiteLabelProfileStart',
    editOwnWhiteLabelProfileAsync
  )
}

export function* whiteLabelSagas() {
  yield all([
    call(watchPendingWhiteLabel),
    call(watchFetchWhiteLabel),
    call(watchFetchWhiteLabelProfileInfo),
    call(watchCreateWhiteLabel),
    call(watchFetchPendingWhiteLabelById),
    call(watchEditWhiteLabel),
    call(watchDeleteWhiteLabel),
    call(watchCreateWhiteLabelPosition),
    call(watchFetchWhiteLabelPosition),
    call(watchFetchAllUniqueWhiteLabelPosition),
    call(watchEditWhiteLabelPosition),
    call(watchCreateWhiteLabelSubPosition),
    call(watchCreateWhiteLabelFriendShip),
    call(watchFetchWhiteLabelFriendShip),
    call(watchFetchWhiteLabelFriendShipById),
    call(watchEditWhiteLabelFriendShip),
    call(watchCreateWhiteLabelMemberShip),
    call(watchFetchWhiteLabelMemberShip),
    call(watchFetchWhiteLabelMemberShipById),
    call(watchDeleteWhiteLabelFriendShip),
    call(watchDeleteWhiteLabelMemberShip),
    call(watchFetchWhiteLabelUser),
    call(watchCreateWhiteLabelUser),
    call(watchFetchWhiteLabelUserById),
    call(watchFetchWhiteLabelPromotionalLevel),
    call(watchEditWhiteLabelUser),
    call(watchEditWhiteLabelMemberShip),
    call(watchFetchWhiteLabelUserByWhiteLabel),
    call(watchCheckWhiteLabelActivationEligibilityAsync),
    call(watchWhiteLabelActivateStart),
    call(watchCreateWhiteLabelCPFriendshipExternalAllocationStart),
    call(watchcreateWhiteLabelCPFriendshipScenariosAllocationStart),
    call(watchEditWhiteLabelCPFriendshipScenariosAllocationStart),
    call(watchcreateWhiteLabelCPMembershipScenariosAllocationStart),
    call(watchEditWhiteLabelCPMembershipScenariosAllocationStart),
    call(watchCreateWhiteLabelCPMembershipExternalAllocationStart),
    call(watchFetchWhiteLabelCPFriendshipScenariosListStart),
    call(watchFetchWhiteLabelCPFriendshipScenarioByIdStart),
    call(watchFetchWhiteLabelCPMembershipScenariosListStart),
    call(watchFetchWhiteLabelCPMembershipScenarioByIdStart),
    call(watchFetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdStart),
    call(watchFetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdStart),
    call(watchFetchAllWhiteLabelUserByPanelAsync),
    call(watchEditOwnWhiteLabelProfileAsync),
    call(watchEditWhiteLabelNonZeroPromotionalLevel),
    // call(watchEditCountry),
  ])
}
