import { takeLatest, call, all, put, delay, select } from 'redux-saga/effects'
import { earningActions } from './earningSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  // friendship
  fetchFriendshipEarning,
  fetchFriendshipEarningList,
  fetchFriendshipEarningAsIssuer,
  fetchFriendshipEarningAsIssuerList,
  fetchFriendshipEarningAsMerchantSigner,
  fetchFriendshipEarningAsMerchantSignerList,
  fetchFriendshipEarningAsCpSigner,
  fetchFriendshipEarningAsCpSignerList,
  fetchFriendshipEarningAsStateOwner,
  fetchFriendshipEarningAsStateOwnerList,
  fetchFriendshipEarningAsRegionOwner,
  fetchFriendshipEarningAsRegionOwnerList,
  fetchFriendshipEarningAsAreaOwner,
  fetchFriendshipEarningAsAreaOwnerList,
  fetchFriendshipEarningAsCharity,
  fetchFriendshipEarningAsCharityList,
  fetchFriendshipEarningAsPiiinkIssuer,
  fetchFriendshipEarningAsPiiinkIssuerList,
  fetchFriendshipEarningAsPiiinkRegionOwner,
  fetchFriendshipEarningAsPiiinkRegionOwnerList,
  fetchFriendshipEarningAsPiiinkAreaOwner,
  fetchFriendshipEarningAsPiiinkAreaOwnerList,
  fetchFriendshipEarningAsPiiinkCharity,
  fetchFriendshipEarningAsPiiinkCharityList,
  fetchFriendshipEarningAsIssuerForGlobalAdmin,
  fetchFriendshipEarningAsIssuerListForGlobalAdmin,
  fetchFriendshipEarningAsMerchantSignerForGlobalAdmin,
  fetchFriendshipEarningAsMerchantSignerListForGlobalAdmin,
  fetchFriendshipEarningAsCpSignerForGlobalAdmin,
  fetchFriendshipEarningAsCpSignerListForGlobalAdmin,
  fetchFriendshipEarningAsIssuerForGlobalAdminWithCountry,
  fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountry,
  fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountry,
  fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountry,
  fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountry,
  fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountry,
  fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry,
  fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry,
  fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry,
  fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry,
  fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountry,
  fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountry,
  fetchMembershipEarningAsIssuerListForGlobalAdminWithCountry,
  fetchCountrySpecificFriendshipEarningTotal,
  fetchCountrySpecificFriendshipEarningTotalList,
  fetchCountrySpecificFriendshipEarningAsCpSignerAsMerchantSigner,
  fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerDetail,
  fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdmin,
  fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdmin,
  fetchWhitelabelSpecificFriendshipEarningTotal,
  fetchWhitelabelSpecificFriendshipEarningTotalList,
  fetchWhitelabelSpecificFriendshipEarningAsIssuer,
  fetchWhitelabelSpecificFriendshipEarningAsIssuerList,
  fetchWhitelabelSpecificFriendshipEarningAsMerchantSigner,
  fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerList,
  fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuer,
  fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList,
  fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSigner,
  fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerList,
  fetchFriendshipEarningAsIssuerForWhitelabel,
  fetchFriendshipEarningAsIssuerListForWhitelabel,
  fetchFriendshipEarningAsMerchantSignerForWhitelabel,
  fetchFriendshipEarningAsMerchantSignerListForWhitelabel,

  // membership
  fetchMembershipEarning,
  fetchMembershipEarningList,
  fetchMembershipEarningAsIssuer,
  fetchMembershipEarningAsIssuerList,
  fetchMembershipEarningAsClubCharity,
  fetchMembershipEarningFromStateAllocation,
  fetchMembershipEarningFromStateAllocationList,
  fetchMembershipEarningFromRegionAllocation,
  fetchMembershipEarningFromRegionAllocationList,
  fetchMembershipEarningFromAreaAllocation,
  fetchMembershipEarningFromAreaAllocationList,
  fetchMembershipEarningFromCharityAllocation,
  fetchMembershipEarningFromCharityAllocationList,
  fetchMembershipEarningFromCpRefer,
  fetchMembershipEarningFromCpReferList,
  fetchMembershipEarningAsPiiinkIssuer,
  fetchMembershipEarningAsPiiinkIssuerList,
  fetchMembershipEarningAsPiiinkRegionOwner,
  fetchMembershipEarningAsPiiinkRegionOwnerList,
  fetchMembershipEarningAsPiiinkAreaOwner,
  fetchMembershipEarningAsPiiinkAreaOwnerList,
  fetchMembershipEarningAsPiiinkCharity,
  fetchMembershipEarningAsPiiinkCharityList,
  fetchFriendshipEarningFromCountryPoolAsCountryOwnerList,
  fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetail,
  fetchMembershipEarningFromCountryPoolAsCountryOwnerList,
  fetchMembershipEarningFromCountryPoolAsCountryOwnerDetail,
  fetchCountrySpecificMembershipEarningFromCountryPoolAsCountryOwnerList,
  fetchCountrySpecificMembershipEarningFromCountryPoolAsCountryOwnerDetail,
  fetchCountrySpecificFriendshipEarningAsCpSignerList,
  fetchCountrySpecificFriendshipEarningAsCpSigner,
  fetchCountrySpecificFriendshipEarningAsIssuer,
  fetchCountrySpecificFriendshipEarningAsIssuerList,
  fetchCountrySpecificMembershipEarningAsIssuer,
  fetchCountrySpecificMembershipEarningAsIssuerList,
  fetchCountrySpecificMembershipEarningAsClubCharity,
  fetchCountrySpecificMembershipEarningAsClubCharityList,
  fetchCountrySpecificMembershipEarningAsCpSigner,
  fetchCountrySpecificMembershipEarningAsCpSignerList,
  fetchMembershipEarningAsIssuerForGlobalAdminWithCountry,
  fetchMembershipEarningAsIssuerForGlobalAdmin,
  fetchMembershipEarningAsIssuerForWhitelabel,
  fetchMembershipEarningAsIssuerListForWhitelabel,
  fetchMembershipEarningAsIssuerListForGlobalAdmin,
  fetchMembershipEarningAsClubCharitySignerForGlobalAdmin,
  fetchMembershipEarningAsClubCharitySignerListForGlobalAdmin,
  fetchMembershipEarningAsCpSignerForGlobalAdmin,
  fetchMembershipEarningAsCpSignerListForGlobalAdmin,
  fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountry,
  fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountry,
  fetchMembershipEarningAsCpSignerForGlobalAdminWithCountry,
  fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountry,
  fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry,
  fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry,
  fetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry,
  fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry,
  fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountry,
  fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountry,
  fetchCountrySpecificFriendshipEarningAsMerchantSigner,
  fetchCountrySpecificFriendshipEarningSummaryAsMerchantSigner,
  fetchMembershipEarningAsCpSigner,
  fetchMembershipEarningAsCpSignerList,
  fetchFriendshipEarningAsCpSignerAsSigner,
  fetchFriendshipEarningAsCpSignerAsSignerDetail,
  fetchCountrySpecificMembershipEarningTotal,
  fetchCountrySpecificMembershipEarningTotalList,
  fetchWhitelabelSpecificMembershipEarningTotal,
  fetchWhitelabelSpecificMembershipEarningTotalList,
  fetchWhitelabelSpecificMembershipEarningAsIssuer,
  fetchWhitelabelSpecificMembershipEarningAsIssuerList,
  fetchWhitelabelSpecificMembershipEarningAsClubCharitySigner,
  fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerList,
  fetchWhitelabelSpecificMembershipEarningAsCpSigner,
  fetchWhitelabelSpecificMembershipEarningAsCpSignerList,
  fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetail,
  fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetail,
  fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetail,
  fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer,
  fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerSummaryAsMerchant,
  fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer,
  fetchMemberPremiumCodeTransaction,
  fetchMemberPremiumCodeTransactionDetail,
  fetchTransactionFriendshipByTerminalUser,
  fetchSupposedFriendshipEarningAsIssuerList,
  fetchSupposedMembershipEarningAsIssuerList,
  fetchFriendshipEarningAsIssuerTypeBF,
  fetchFriendshipEarningAsIssuerTypeNormal,
  fetchFriendshipEarningAsIssuerListTypeNormal,
  fetchFriendshipEarningAsIssuerListTypeBF,
  fetchFriendshipEarningAsMerchantSignerTypeBF,
  fetchFriendshipEarningAsMerchantSignerTypeNormal,
  fetchFriendshipEarningAsMerchantSignerListTypeBF,
  fetchFriendshipEarningAsMerchantSignerListTypeNormal,
  fetchFriendshipEarningAsCpSignerTypeNormal,
  fetchFriendshipEarningAsCpSignerTypeBF,
  fetchFriendshipEarningAsCpSignerAsSignerTypeNormal,
  fetchFriendshipEarningAsCpSignerAsSignerTypeBF,
  fetchFriendshipEarningAsCpSignerListTypeBF,
  fetchFriendshipEarningAsCpSignerListTypeNormal,
  fetchFriendshipEarningAsCpSignerAsSignerDetailTypeBF,
  fetchFriendshipEarningAsCpSignerAsSignerDetailTypeNormal,
  fetchMembershipEarningAsIssuerTypeBF,
  fetchMembershipEarningAsIssuerTypeNormal,
  fetchMembershipEarningAsIssuerListTypeBF,
  fetchMembershipEarningAsIssuerListTypeNormal,
  fetchMembershipEarningAsClubCharityListTypeBF,
  fetchMembershipEarningAsClubCharityTypeNormal,
  fetchMembershipEarningAsClubCharityDetails,
  fetchMembershipEarningAsClubCharityDetailsTypeBF,
  fetchMembershipEarningAsClubCharityDetailsTypeNormal,
  fetchMembershipEarningFromCpReferTypeBF,
  fetchMembershipEarningFromCpReferTypeNormal,
  fetchMembershipEarningFromCpReferListTypeBF,
  fetchMembershipEarningFromCpReferListTypeNormal,
  fetchFriendshipEarningAsMerchantSignerForWhitelabelSummary,
  fetchFriendshipEarningAsPiiinkEntities,
  fetchFriendshipEarningAsPiiinkEntitiesDetail,
  fetchMembershipEarningAsPiiinkEntities,
  fetchMembershipEarningAsPiiinkEntitiesDetail,
  fetchCountryRevenueFromFriendshipSummary,
  fetchCountryRevenueFromMembershipSummary,
  fetchCountryRevenueFromFriendshipDetail,
  fetchCountryRevenueFromMembershipDetail,
  fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharity,
  fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharity,
  fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwner,
  fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwner,
  fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwner,
  fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwner,
  fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwner,
  fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwner,
  fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwner,
  fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwner,
  fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwner,
  fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwner,
  fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwner,
  fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwner,
  fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwner,
  fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwner,
  fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwner,
  fetchGlobalAdminWithCountryDetailMembershipEarningAsCharity,
  fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharity,
  fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwner,
  cancelTransaction,
} from 'api/earning'

import _ from 'lodash'

export const getSiteCoordinator = (state) => state.siteCoordinator
export const getUserData = (state: any) => state.auth.currentUser

// friendship sagas ================================================
// as merchant
export function* fetchFriendshipEarningAsync({ payload }: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarning(payload))
    yield put(earningActions.fetchFriendshipEarningSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningFailure(err))
  }
}

export function* fetchFriendshipEarningListAsync({ payload }: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningList(payload))

    yield put(earningActions.fetchFriendshipEarningListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningListFailure(err))
  }
}
// as issuer
export function* fetchFriendshipEarningAsIssuerAsync({ payload }: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsIssuer(payload))
    yield put(earningActions.fetchFriendshipEarningAsIssuerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsIssuerFailure(err))
  }
}

export function* fetchFriendshipEarningAsIssuerTypeBFAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsIssuerTypeBF(payload))
    yield put(earningActions.fetchFriendshipEarningAsIssuerTypeBFSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsIssuerTypeBFFailure(err))
  }
}

export function* fetchFriendshipEarningAsIssuerTypeNormalAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsIssuerTypeNormal(payload))
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerTypeNormalSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerTypeNormalFailure(err)
    )
  }
}

export function* fetchCountrySpecificFriendshipEarningAsIssuerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificFriendshipEarningAsIssuer(payload)
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsIssuerSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsIssuerFailure(err)
    )
  }
}

export function* fetchFriendshipEarningAsIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsIssuerList(payload))

    yield put(earningActions.fetchFriendshipEarningAsIssuerListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsIssuerListFailure(err))
  }
}

export function* fetchFriendshipEarningAsIssuerListTypeNormalAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsIssuerListTypeNormal(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListTypeNormalSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListTypeNormalFailure(err)
    )
  }
}

export function* fetchFriendshipEarningAsIssuerDetailsTypeBFAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsIssuerListTypeBF(payload))

    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListTypeBFSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListTypeBFFailure(err)
    )
  }
}

export function* fetchFriendshipEarningAsIssuerListTypeBFAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsIssuerList(payload))

    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListTypeBFSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListTypeBFFailure(err)
    )
  }
}

export function* fetchSupposedFriendshipEarningAsIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchSupposedFriendshipEarningAsIssuerList(payload))

    yield put(
      earningActions.fetchSupposedFriendshipEarningAsIssuerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchSupposedFriendshipEarningAsIssuerListFailure(err)
    )
  }
}

export function* fetchSupposedMembershipEarningAsIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchSupposedMembershipEarningAsIssuerList(payload))

    yield put(
      earningActions.fetchSupposedMembershipEarningAsIssuerListSuccess(
        data?.data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchSupposedMembershipEarningAsIssuerListFailure(err)
    )
  }
}

export function* fetchCountrySpecificFriendshipEarningAsIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificFriendshipEarningAsIssuerList(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsIssuerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsIssuerListFailure(
        err
      )
    )
  }
}

// as merchant Signer
export function* fetchFriendshipEarningAsMerchantSignerAsync({
  payload,
}: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition

  try {
    const apiCallFunction = () => {
      if (whiteLabelPosition?.isIntroducer) {
        return fetchFriendshipEarningAsMerchantSigner(payload)
      } else if (whiteLabelPosition?.isBalancingLevel) {
        return fetchFriendshipEarningAsMerchantSignerTypeBF(payload)
      } else {
        return fetchFriendshipEarningAsMerchantSignerTypeNormal(payload)
      }
    }

    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchFriendshipEarningAsMerchantSigner(payload)
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsMerchantSignerFailure(err))
  }
}

export function* fetchFriendshipEarningAsMerchantSignerListAsync({
  payload,
}: AnyAction) {
  try {
    const currentUser = yield select(getUserData)
    const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition

    const apiCallFunction = () => {
      if (whiteLabelPosition?.isIntroducer) {
        return fetchFriendshipEarningAsMerchantSignerList(payload)
      } else if (whiteLabelPosition?.isBalancingLevel) {
        return fetchFriendshipEarningAsMerchantSignerListTypeBF(payload)
      } else {
        return fetchFriendshipEarningAsMerchantSignerListTypeNormal(payload)
      }
    }

    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchFriendshipEarningAsMerchantSignerList(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerListFailure(err)
    )
  }
}

export function* fetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsListAsync({
  payload,
}: AnyAction) {
  try {
    const apiCallFunction = () => {
      return fetchFriendshipEarningAsMerchantSignerListTypeBF(payload)
    }

    const data = yield call(apiCallFunction())

    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsListFailure(
        err
      )
    )
  }
}

// as  cp signer
export function* fetchFriendshipEarningAsCpSignerAsync({ payload }: AnyAction) {
  try {
    const currentUser = yield select(getUserData)
    const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition

    const apiCallFunction = () => {
      if (whiteLabelPosition?.isIntroducer) {
        return fetchFriendshipEarningAsCpSigner(payload)
      } else if (whiteLabelPosition?.isBalancingLevel) {
        return fetchFriendshipEarningAsCpSignerTypeBF(payload)
      } else {
        return fetchFriendshipEarningAsCpSignerTypeNormal(payload)
      }
    }

    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchFriendshipEarningAsCpSigner(payload)
    )
    yield put(earningActions.fetchFriendshipEarningAsCpSignerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsCpSignerFailure(err))
  }
}

export function* fetchFriendshipEarningAsCpSignerListAsync({
  payload,
}: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition
  const apiCallFunction = () => {
    if (whiteLabelPosition?.isIntroducer) {
      return fetchFriendshipEarningAsCpSignerList(payload)
    } else if (whiteLabelPosition?.isBalancingLevel) {
      return fetchFriendshipEarningAsCpSignerListTypeBF(payload)
    } else {
      return fetchFriendshipEarningAsCpSignerListTypeNormal(payload)
    }
  }
  try {
    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchFriendshipEarningAsCpSignerList(payload)
    )

    yield put(earningActions.fetchFriendshipEarningAsCpSignerListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsCpSignerListFailure(err))
  }
}

// as  state owner
export function* fetchFriendshipEarningAsStateOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsStateOwner(payload))
    yield put(earningActions.fetchFriendshipEarningAsStateOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsStateOwnerFailure(err))
  }
}

export function* fetchFriendshipEarningAsStateOwnerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsStateOwnerList(payload))

    yield put(
      earningActions.fetchFriendshipEarningAsStateOwnerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsStateOwnerListFailure(err))
  }
}

// as region owner
export function* fetchFriendshipEarningAsRegionOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsRegionOwner(payload))
    yield put(earningActions.fetchFriendshipEarningAsRegionOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsRegionOwnerFailure(err))
  }
}

export function* fetchFriendshipEarningAsRegionOwnerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsRegionOwnerList(payload))

    yield put(
      earningActions.fetchFriendshipEarningAsRegionOwnerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsRegionOwnerListFailure(err)
    )
  }
}

// as area owner
export function* fetchFriendshipEarningAsAreaOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsAreaOwner(payload))
    yield put(earningActions.fetchFriendshipEarningAsAreaOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsAreaOwnerFailure(err))
  }
}

export function* fetchFriendshipEarningAsAreaOwnerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsAreaOwnerList(payload))

    yield put(earningActions.fetchFriendshipEarningAsAreaOwnerListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsAreaOwnerListFailure(err))
  }
}

// as charity
export function* fetchFriendshipEarningAsCharityAsync({ payload }: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsCharity(payload))
    yield put(earningActions.fetchFriendshipEarningAsCharitySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsCharityFailure(err))
  }
}

export function* fetchFriendshipEarningAsCharityListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsCharityList(payload))

    yield put(earningActions.fetchFriendshipEarningAsCharityListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsCharityListFailure(err))
  }
}

// As PiiinkIssuer
export function* fetchFriendshipEarningAsPiiinkIssuerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsPiiinkIssuer(payload))
    yield put(earningActions.fetchFriendshipEarningAsPiiinkIssuerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsPiiinkIssuerFailure(err))
  }
}

export function* fetchFriendshipEarningAsPiiinkEntitiesAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsPiiinkEntities(payload))

    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkEntitiesSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsPiiinkEntitiesFailure(err))
  }
}

export function* fetchMembershipEarningAsPiiinkEntitiesAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningAsPiiinkEntities(payload))

    yield put(
      earningActions.fetchMembershipEarningAsPiiinkEntitiesSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningAsPiiinkEntitiesFailure(err))
  }
}

export function* fetchFriendshipEarningAsPiiinkEntitiesDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsPiiinkEntitiesDetail(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkEntitiesDetailSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkEntitiesDetailFailure(err)
    )
  }
}

export function* fetchMembershipEarningAsPiiinkEntitiesDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsPiiinkEntitiesDetail(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningAsPiiinkEntitiesDetailSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkEntitiesDetailFailure(err)
    )
  }
}

export function* fetchFriendshipEarningAsPiiinkIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsPiiinkIssuerList(payload))

    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkIssuerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkIssuerListFailure(err)
    )
  }
}

//As PiiinkRegionOwner
export function* fetchFriendshipEarningAsPiiinkRegionOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsPiiinkRegionOwner(payload))
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkRegionOwnerSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkRegionOwnerFailure(err)
    )
  }
}

export function* fetchFriendshipEarningAsPiiinkRegionOwnerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsPiiinkRegionOwnerList(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkRegionOwnerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkRegionOwnerListFailure(err)
    )
  }
}

//As PiiinkAreaOwner
export function* fetchFriendshipEarningAsPiiinkAreaOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsPiiinkAreaOwner(payload))
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkAreaOwnerSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkAreaOwnerFailure(err)
    )
  }
}

export function* fetchFriendshipEarningAsPiiinkAreaOwnerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsPiiinkAreaOwnerList(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkAreaOwnerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkAreaOwnerListFailure(err)
    )
  }
}

//As PiiinkCharity
export function* fetchFriendshipEarningAsPiiinkCharityAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsPiiinkCharity(payload))
    yield put(earningActions.fetchFriendshipEarningAsPiiinkCharitySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchFriendshipEarningAsPiiinkCharityFailure(err))
  }
}

export function* fetchFriendshipEarningAsPiiinkCharityListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsPiiinkCharityList(payload))

    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkCharityListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkCharityListFailure(err)
    )
  }
}

// white label specific=============================================================

// fetchFriendshipEarningAsMerchantSignerForWhitelabelStart
export function* fetchFriendshipEarningAsMerchantSignerForWhitelabelAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsMerchantSignerForWhitelabelSummary(payload)
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerForWhitelabelSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningTotalFailure(err)
    )
  }
}

// fetchFriendshipEarningAsMerchantSignerListForWhitelabelStart
export function* fetchFriendshipEarningAsMerchantSignerListForWhitelabelAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsMerchantSignerListForWhitelabel(payload)
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerListForWhitelabelSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerListForWhitelabelFailure(
        err
      )
    )
  }
}

// fetchFriendshipEarningAsIssuerForWhitelabelStart
export function* fetchFriendshipEarningAsIssuerForWhitelabelAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsIssuerListForWhitelabel(payload)
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerForWhitelabelSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerForWhitelabelFailure(err)
    )
  }
}

// fetchFriendshipEarningAsIssuerListForWhitelabelStart
export function* fetchFriendshipEarningAsIssuerListForWhitelabelAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsIssuerForWhitelabel(payload)
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListForWhitelabelSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListForWhitelabelFailure(err)
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningTotalAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningTotal(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningTotalSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningTotalFailure(err)
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningTotalListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningTotalList(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningTotalListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningTotalListFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningAsIssuerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningAsIssuer(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsIssuerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsIssuerFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningAsIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningAsIssuerList(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsIssuerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsIssuerListFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningAsMerchantSigner(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerList(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerListFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuer(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerListFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSigner(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerList(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerListFailure(
        err
      )
    )
  }
}

// for global admin =================================================================
// as issuer
export function* fetchFriendshipEarningAsIssuerForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsIssuerForGlobalAdmin(payload)
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerForGlobalAdminSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerForGlobalAdminFailure(err)
    )
  }
}

export function* fetchFriendshipEarningAsIssuerListForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsIssuerListForGlobalAdmin(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListForGlobalAdminFailure(
        err
      )
    )
  }
}
// as merchant Signer
export function* fetchFriendshipEarningAsMerchantSignerForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsMerchantSignerForGlobalAdmin(payload)
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerForGlobalAdminFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningAsMerchantSignerListForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsMerchantSignerListForGlobalAdmin(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerListForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerListForGlobalAdminFailure(
        err
      )
    )
  }
}

// as  cp signer
export function* fetchFriendshipEarningAsCpSignerForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsCpSignerForGlobalAdmin(payload)
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerForGlobalAdminSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerForGlobalAdminFailure(err)
    )
  }
}

export function* fetchFriendshipEarningAsCpSignerListForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsCpSignerListForGlobalAdmin(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerListForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerListForGlobalAdminFailure(
        err
      )
    )
  }
}
// as  cp signer as signer
export function* fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdmin(payload)
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdminFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdmin(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdminFailure(
        err
      )
    )
  }
}

// for global admin with country=================================================================

//As PiiinkCharity
export function* fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

//As PiiinkRegionOwner
export function* fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

//As PiiinkAreaOwner
export function* fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}
// as issuer
export function* fetchFriendshipEarningAsIssuerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsIssuerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

// as merchant Signer
export function* fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

// as  cp signer
export function* fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

// membership sagas ================================================
export function* fetchMembershipEarningAsync({ payload }: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarning(payload))
    yield put(earningActions.fetchMembershipEarningSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningFailure(err))
  }
}

export function* fetchMembershipEarningListAsync({ payload }: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningList(payload))

    yield put(earningActions.fetchMembershipEarningListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningListFailure(err))
  }
}
// as issuer
export function* fetchMembershipEarningAsIssuerAsync({ payload }: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition
  const apiCallFunction = () => {
    if (whiteLabelPosition?.isIntroducer) {
      return fetchMembershipEarningAsIssuer(payload)
    } else if (whiteLabelPosition?.isBalancingLevel) {
      return fetchMembershipEarningAsIssuerTypeBF(payload)
    } else {
      return fetchMembershipEarningAsIssuerTypeNormal(payload)
    }
  }

  try {
    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchMembershipEarningAsIssuer(payload)
    )
    yield put(earningActions.fetchMembershipEarningAsIssuerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningAsIssuerFailure(err))
  }
}

export function* fetchMembershipEarningAsIssuerListAsync({
  payload,
}: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition
  const apiCallFunction = () => {
    if (whiteLabelPosition?.isIntroducer) {
      return fetchMembershipEarningAsIssuerList(payload)
    } else if (whiteLabelPosition?.isBalancingLevel) {
      return fetchMembershipEarningAsIssuerListTypeBF(payload)
    } else {
      return fetchMembershipEarningAsIssuerListTypeNormal(payload)
    }
  }
  try {
    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchMembershipEarningAsIssuerList(payload)
    )

    yield put(earningActions.fetchMembershipEarningAsIssuerListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningAsIssuerListFailure(err))
  }
}

// as club charity
export function* fetchMembershipEarningAsClubCharityAsync({
  payload,
}: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition
  const apiCallFunction = () => {
    if (whiteLabelPosition?.isIntroducer) {
      return fetchMembershipEarningAsClubCharity(payload)
    } else if (whiteLabelPosition?.isBalancingLevel) {
      return fetchMembershipEarningAsClubCharityListTypeBF(payload)
    } else {
      return fetchMembershipEarningAsClubCharityTypeNormal(payload)
    }
  }
  try {
    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchMembershipEarningAsClubCharity(payload)
    )
    yield put(earningActions.fetchMembershipEarningAsClubCharitySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningAsClubCharityFailure(err))
  }
}

export function* fetchMembershipEarningAsClubCharityListAsync({
  payload,
}: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition
  const apiCallFunction = () => {
    if (whiteLabelPosition?.isIntroducer) {
      return fetchMembershipEarningAsClubCharityDetails(payload)
    } else if (whiteLabelPosition?.isBalancingLevel) {
      return fetchMembershipEarningAsClubCharityDetailsTypeBF(payload)
    } else {
      return fetchMembershipEarningAsClubCharityDetailsTypeNormal(payload)
    }
  }
  try {
    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchMembershipEarningAsClubCharityDetails(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningAsClubCharityListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsClubCharityListFailure(err)
    )
  }
}

// from state allocation
export function* fetchMembershipEarningFromStateAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningFromStateAllocation(payload))
    yield put(
      earningActions.fetchMembershipEarningFromStateAllocationSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromStateAllocationFailure(err)
    )
  }
}

export function* fetchMembershipEarningFromStateAllocationListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningFromStateAllocationList(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningFromStateAllocationListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromStateAllocationListFailure(err)
    )
  }
}

// from region allocation
export function* fetchMembershipEarningFromRegionAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningFromRegionAllocation(payload))
    yield put(
      earningActions.fetchMembershipEarningFromRegionAllocationSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromRegionAllocationFailure(err)
    )
  }
}

export function* fetchMembershipEarningFromRegionAllocationListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningFromRegionAllocationList(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningFromRegionAllocationListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromRegionAllocationListFailure(err)
    )
  }
}

//  from area allocation
export function* fetchMembershipEarningFromAreaAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningFromAreaAllocation(payload))
    yield put(
      earningActions.fetchMembershipEarningFromAreaAllocationSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromAreaAllocationFailure(err)
    )
  }
}

export function* fetchMembershipEarningFromAreaAllocationListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningFromAreaAllocationList(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningFromAreaAllocationListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromAreaAllocationListFailure(err)
    )
  }
}

//  from charity allocation
export function* fetchMembershipEarningFromCharityAllocationAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningFromCharityAllocation(payload)
    )
    yield put(
      earningActions.fetchMembershipEarningFromCharityAllocationSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromCharityAllocationFailure(err)
    )
  }
}

export function* fetchMembershipEarningFromCharityAllocationListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningFromCharityAllocationList(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningFromCharityAllocationListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromCharityAllocationListFailure(err)
    )
  }
}
// from cp refer
export function* fetchMembershipEarningFromCpReferAsync({
  payload,
}: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition
  const apiCallFunction = () => {
    if (whiteLabelPosition?.isIntroducer) {
      return fetchMembershipEarningFromCpRefer(payload)
    } else if (whiteLabelPosition?.isBalancingLevel) {
      return fetchMembershipEarningFromCpReferTypeBF(payload)
    } else {
      return fetchMembershipEarningFromCpReferTypeNormal(payload)
    }
  }

  try {
    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchMembershipEarningFromCpRefer(payload)
    )
    yield put(earningActions.fetchMembershipEarningFromCpReferSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningFromCpReferFailure(err))
  }
}

export function* fetchMembershipEarningFromCpReferListAsync({
  payload,
}: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition
  const apiCallFunction = () => {
    if (whiteLabelPosition?.isIntroducer) {
      return fetchMembershipEarningFromCpReferList(payload)
    } else if (whiteLabelPosition?.isBalancingLevel) {
      return fetchMembershipEarningFromCpReferListTypeBF(payload)
    } else {
      return fetchMembershipEarningFromCpReferListTypeNormal(payload)
    }
  }

  try {
    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchMembershipEarningFromCpReferList(payload)
    )

    yield put(earningActions.fetchMembershipEarningFromCpReferListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningFromCpReferListFailure(err))
  }
}
// As PiiinkIssuer
export function* fetchMembershipEarningAsPiiinkIssuerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningAsPiiinkIssuer(payload))
    yield put(earningActions.fetchMembershipEarningAsPiiinkIssuerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningAsPiiinkIssuerFailure(err))
  }
}

export function* fetchMembershipEarningAsPiiinkIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningAsPiiinkIssuerList(payload))

    yield put(
      earningActions.fetchMembershipEarningAsPiiinkIssuerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkIssuerListFailure(err)
    )
  }
}

//As PiiinkRegionOwner
export function* fetchMembershipEarningAsPiiinkRegionOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningAsPiiinkRegionOwner(payload))
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkRegionOwnerSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkRegionOwnerFailure(err)
    )
  }
}

export function* fetchMembershipEarningAsPiiinkRegionOwnerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsPiiinkRegionOwnerList(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningAsPiiinkRegionOwnerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkRegionOwnerListFailure(err)
    )
  }
}

//As PiiinkAreaOwner
export function* fetchMembershipEarningAsPiiinkAreaOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningAsPiiinkAreaOwner(payload))
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkAreaOwnerSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkAreaOwnerFailure(err)
    )
  }
}

export function* fetchMembershipEarningAsPiiinkAreaOwnerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsPiiinkAreaOwnerList(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningAsPiiinkAreaOwnerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkAreaOwnerListFailure(err)
    )
  }
}

//As PiiinkCharity
export function* fetchMembershipEarningAsPiiinkCharityAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningAsPiiinkCharity(payload))
    yield put(earningActions.fetchMembershipEarningAsPiiinkCharitySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningAsPiiinkCharityFailure(err))
  }
}

export function* fetchMembershipEarningAsPiiinkCharityListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningAsPiiinkCharityList(payload))

    yield put(
      earningActions.fetchMembershipEarningAsPiiinkCharityListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkCharityListFailure(err)
    )
  }
}

export function* fetchFriendshipEarningFromCountryPoolAsCountryOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningFromCountryPoolAsCountryOwnerList(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningFromCountryPoolAsCountryOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryPoolAsCountryOwnerFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetail(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningFromCountryPoolAsCountryOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningFromCountryPoolAsCountryOwnerList(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningFromCountryPoolAsCountryOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryPoolAsCountryOwnerFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningFromCountryPoolAsCountryOwnerDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningFromCountryPoolAsCountryOwnerDetail(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningFromCountryPoolAsCountryOwnerDetailSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryPoolAsCountryOwnerDetailFailure(
        err
      )
    )
  }
}
// countrySpecificFriendshipEarningTotal
export function* fetchCountrySpecificFriendshipEarningTotalAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchCountrySpecificFriendshipEarningTotal(payload))

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningTotalSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningTotalFailure(err)
    )
  }
}

// countrySpecificFriendshipEarningTotalList
export function* fetchCountrySpecificFriendshipEarningTotalListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificFriendshipEarningTotalList(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningTotalListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningTotalListFailure(err)
    )
  }
}

// countrySpecificMembershipEarningTotal
export function* fetchCountrySpecificMembershipEarningTotalAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchCountrySpecificMembershipEarningTotal(payload))

    yield put(
      earningActions.fetchCountrySpecificMembershipEarningTotalSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificMembershipEarningTotalFailure(err)
    )
  }
}

// countrySpecificMembershipEarningTotalList
export function* fetchCountrySpecificMembershipEarningTotalListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificMembershipEarningTotalList(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificMembershipEarningTotalListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificMembershipEarningTotalListFailure(err)
    )
  }
}

export function* fetchCountrySpecificFriendshipEarningAsMerchantSignerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificFriendshipEarningAsMerchantSigner(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsMerchantSignerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsMerchantSignerListFailure(
        err
      )
    )
  }
}

export function* fetchCountrySpecificFriendshipEarningAsMerchantSignerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificFriendshipEarningSummaryAsMerchantSigner(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsMerchantSignerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsMerchantSignerFailure(
        err
      )
    )
  }
}

export function* fetchCountrySpecificFriendshipEarningAsCpSignerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchFriendshipEarningAsCpSignerList(payload))

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerListFailure(
        err
      )
    )
  }
}

export function* fetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificFriendshipEarningAsCpSignerList(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelFailure(
        err
      )
    )
  }
}

export function* fetchCountrySpecificFriendshipEarningAsCpSignerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificFriendshipEarningAsCpSigner(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerFailure(err)
    )
  }
}

export function* fetchCountrySpecificMembershipEarningAsIssuerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificMembershipEarningAsIssuer(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsIssuerSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsIssuerFailure(err)
    )
  }
}

export function* fetchCountrySpecificMembershipEarningAsIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificMembershipEarningAsIssuerList(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsIssuerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsIssuerListFailure(
        err
      )
    )
  }
}

export function* fetchCountrySpecificMembershipEarningAsCpSignerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificMembershipEarningAsCpSigner(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsCpSignerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsCpSignerFailure(err)
    )
  }
}

export function* fetchCountrySpecificMembershipEarningAsCpSignerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificMembershipEarningAsCpSignerList(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsCpSignerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsClubCharityListFailure(
        err
      )
    )
  }
}

export function* fetchCountrySpecificMembershipEarningAsClubCharityAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificMembershipEarningAsClubCharity(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsClubCharitySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsClubCharityFailure(
        err
      )
    )
  }
}

export function* fetchCountrySpecificMembershipEarningAsClubCharityListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificMembershipEarningAsClubCharityList(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsClubCharityListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificMembershipEarningAsClubCharityListFailure(
        err
      )
    )
  }
}

// for global admin ========================

// as issuer
export function* fetchMembershipEarningAsIssuerForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsIssuerForGlobalAdmin(payload)
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerForGlobalAdminSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerForGlobalAdminFailure(err)
    )
  }
}

export function* fetchMembershipEarningAsIssuerListForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsIssuerListForGlobalAdmin(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningAsIssuerListForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerListForGlobalAdminFailure(
        err
      )
    )
  }
}

// as  club charity
export function* fetchMembershipEarningAsClubCharitySignerForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsClubCharitySignerForGlobalAdmin(payload)
    )
    yield put(
      earningActions.fetchMembershipEarningAsClubCharitySignerForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsClubCharitySignerForGlobalAdminFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningAsClubCharitySignerListForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsClubCharitySignerListForGlobalAdmin(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningAsClubCharitySignerListForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsClubCharitySignerListForGlobalAdminFailure(
        err
      )
    )
  }
}

// as  cp signer
export function* fetchMembershipEarningAsCpSignerForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsCpSignerForGlobalAdmin(payload)
    )
    yield put(
      earningActions.fetchMembershipEarningAsCpSignerForGlobalAdminSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsCpSignerForGlobalAdminFailure(err)
    )
  }
}

export function* fetchMembershipEarningAsCpSignerListForGlobalAdminAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsCpSignerListForGlobalAdmin(payload)
    )

    yield put(
      earningActions.fetchMembershipEarningAsCpSignerListForGlobalAdminSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsCpSignerListForGlobalAdminFailure(
        err
      )
    )
  }
}

// for global admin with country=================================================================

//As PiiinkCharity
export function* fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

//As PiiinkRegionOwner
export function* fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

//As PiiinkAreaOwner
export function* fetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

// as issuer
export function* fetchMembershipEarningAsIssuerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsIssuerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningAsIssuerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsIssuerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchMembershipEarningAsIssuerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

// as merchant Signer
export function* fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

// as  cp signer
export function* fetchMembershipEarningAsCpSignerForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsCpSignerForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )
    yield put(
      earningActions.fetchMembershipEarningAsCpSignerForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsCpSignerForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountryAsync({
  payload,
}: AnyAction) {
  try {
    const siteCoordinator = yield select(getSiteCoordinator)
    const countryId = siteCoordinator?.globalCountry?.id

    const data = yield call(
      fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountry(
        countryId,
        payload
      )
    )

    yield put(
      earningActions.fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountrySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountryFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningAsCpSignerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningAsCpSignerList(payload))

    yield put(earningActions.fetchMembershipEarningAsCpSignerListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningAsCpSignerListFailure(err))
  }
}

export function* fetchMembershipEarningAsCpSignerAsync({ payload }: AnyAction) {
  try {
    const data = yield call(fetchMembershipEarningAsCpSigner(payload))

    yield put(earningActions.fetchMembershipEarningAsCpSignerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMembershipEarningAsCpSignerFailure(err))
  }
}

export function* fetchFriendshipEarningAsCpSignerAsSignerAsync({
  payload,
}: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition

  const apiCallFunction = () => {
    if (whiteLabelPosition?.isIntroducer) {
      return fetchFriendshipEarningAsCpSignerAsSigner(payload)
    } else if (whiteLabelPosition?.isBalancingLevel) {
      return fetchFriendshipEarningAsCpSignerAsSignerTypeBF(payload)
    } else {
      return fetchFriendshipEarningAsCpSignerAsSignerTypeNormal(payload)
    }
  }
  try {
    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchFriendshipEarningAsCpSignerAsSigner(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerAsSignerSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerAsSignerFailure(err)
    )
  }
}
export function* fetchFriendshipEarningAsCpSignerAsSignerListAsync({
  payload,
}: AnyAction) {
  const currentUser = yield select(getUserData)
  const whiteLabelPosition = currentUser?.userCountry?.whiteLabelPosition
  const apiCallFunction = () => {
    if (whiteLabelPosition?.isIntroducer) {
      return fetchFriendshipEarningAsCpSignerAsSignerDetail(payload)
    } else if (whiteLabelPosition?.isBalancingLevel) {
      return fetchFriendshipEarningAsCpSignerAsSignerDetailTypeBF(payload)
    } else {
      return fetchFriendshipEarningAsCpSignerAsSignerDetailTypeNormal(payload)
    }
  }

  try {
    const data = yield call(
      currentUser?.role?.toLowerCase() === 'whitelabeluser'
        ? apiCallFunction()
        : fetchFriendshipEarningAsCpSignerAsSignerDetail(payload)
    )

    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerAsSignerListSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningAsCpSignerAsSignerListFailure(err)
    )
  }
}

export function* fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificFriendshipEarningAsCpSignerAsMerchantSigner(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerFailure(
        err
      )
    )
  }
}
export function* fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningAsCpSignerAsSignerDetail(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListFailure(
        err
      )
    )
  }
}

export function* fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerDetail(payload)
    )

    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelFailure(
        err
      )
    )
  }
}

// white label specific ==========================================================
// fetchMembershipEarningAsIssuerForWhitelabelStart
export function* fetchMembershipEarningAsIssuerForWhitelabelAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsIssuerListForWhitelabel(payload)
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerForWhitelabelSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerForWhitelabelFailure(err)
    )
  }
}
// fetchMembershipEarningAsIssuerListForWhitelabelStart
export function* fetchMembershipEarningAsIssuerListForWhitelabelAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningAsIssuerForWhitelabel(payload)
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerListForWhitelabelSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningAsIssuerListForWhitelabelFailure(err)
    )
  }
}

export function* fetchWhitelabelSpecificMembershipEarningTotalAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificMembershipEarningTotal(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningTotalSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningTotalFailure(err)
    )
  }
}

export function* fetchWhitelabelSpecificMembershipEarningTotalListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificMembershipEarningTotalList(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningTotalListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningTotalListFailure(
        err
      )
    )
  }
}
export function* fetchWhitelabelSpecificMembershipEarningAsIssuerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificMembershipEarningAsIssuer(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsIssuerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsIssuerFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificMembershipEarningAsIssuerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificMembershipEarningAsIssuerList(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsIssuerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsIssuerListFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificMembershipEarningAsClubCharitySigner(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerList(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerListFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificMembershipEarningAsCpSignerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificMembershipEarningAsCpSigner(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsCpSignerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsCpSignerFailure(
        err
      )
    )
  }
}

export function* fetchWhitelabelSpecificMembershipEarningAsCpSignerListAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificMembershipEarningAsCpSignerList(payload)
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsCpSignerListSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchWhitelabelSpecificMembershipEarningAsCpSignerListFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer(
        payload
      )
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerStart({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer(
        payload
      )
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwner({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer(
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchant({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerSummaryAsMerchant(
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwner({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchWhitelabelSpecificMembershipEarningAsCpSignerList(payload)
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetail(
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetailSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetailFailure(
        err
      )
    )
  }
}

export function* fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetail(
        payload
      )
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetailSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetailFailure(
        err
      )
    )
  }
}

export function* fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetail(payload)
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetailSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetailFailure(
        err
      )
    )
  }
}

export function* fetchMemberPremiumCodeTransactionAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMemberPremiumCodeTransaction(payload))
    yield put(earningActions.fetchMemberPremiumCodeTransactionSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.fetchMemberPremiumCodeTransactionFailure(err))
  }
}

export function* fetchMemberPremiumCodeTransactionDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchMemberPremiumCodeTransactionDetail(payload))
    yield put(
      earningActions.fetchMemberPremiumCodeTransactionDetailSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchMemberPremiumCodeTransactionDetailFailure(err)
    )
  }
}

export function* fetchFriendshipTransactionByTerminalUserAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchTransactionFriendshipByTerminalUser(payload))
    yield put(
      earningActions.fetchFriendshipTransactionByTerminalUserSuccess(data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchFriendshipTransactionByTerminalUserFailure(err)
    )
  }
}

export function* fetchCountryRevenueFromFriendshipSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchCountryRevenueFromFriendshipSummary(payload))

    yield put(
      earningActions.fetchCountryRevenueFromFriendshipSummarySuccess(data?.data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountryRevenueFromFriendshipSummaryFailure(err)
    )
  }
}

export function* fetchCountryRevenueFromFriendshipDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchCountryRevenueFromFriendshipDetail(payload))

    yield put(
      earningActions.fetchCountryRevenueFromFriendshipDetailSuccess(data?.data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountryRevenueFromFriendshipDetailFailure(err)
    )
  }
}

export function* fetchCountryRevenueFromMembershipSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchCountryRevenueFromMembershipSummary(payload))

    yield put(
      earningActions.fetchCountryRevenueFromMembershipSummarySuccess(data?.data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountryRevenueFromMembershipSummaryFailure(err)
    )
  }
}

export function* fetchCountryRevenueFromMembershipDetailAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(fetchCountryRevenueFromMembershipDetail(payload))

    yield put(
      earningActions.fetchCountryRevenueFromMembershipDetailSuccess(data?.data)
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchCountryRevenueFromMembershipDetailFailure(err)
    )
  }
}

export function* fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharityAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharity(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharitySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharityFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharityAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharity(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharitySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharityFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwnerFailure(
        err
      )
    )
  }
}
//membership section starts

export function* fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharityAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharity(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharitySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharityFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailMembershipEarningAsCharityAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailMembershipEarningAsCharity(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsCharitySuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsCharityFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwnerFailure(
        err
      )
    )
  }
}

export function* fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwnerAsync({
  payload,
}: AnyAction) {
  try {
    const data = yield call(
      fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwner(payload)
    )

    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwnerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(
      earningActions.fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwnerFailure(
        err
      )
    )
  }
}

export function* cancelTransactionAsync({ payload }: AnyAction) {
  try {
    const data = yield call(
      cancelTransaction({
        transactionId: payload?.id,
        refundReason: payload?.rejectReason,
      })
    )

    yield put(earningActions.cancelTransactionSuccess(data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: 'Transaction Successfully Cancelled',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(earningActions.cancelTransactionFailure(err))
  }
}
// friendship watchers =======================================

// as merchant
export function* watchFetchFriendshipEarning() {
  yield takeLatest(
    'earning/fetchFriendshipEarningStart',
    fetchFriendshipEarningAsync
  )
}
export function* watchFetchFriendshipEarningList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningListStart',
    fetchFriendshipEarningListAsync
  )
}

// as issuer
export function* watchFetchFriendshipEarningAsIssuer() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerStart',
    fetchFriendshipEarningAsIssuerAsync
  )
}

export function* watchFetchFriendshipEarningAsIssuerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerListStart',
    fetchFriendshipEarningAsIssuerListAsync
  )
}

export function* watchFetchFriendshipEarningAsIssuerListTypeNormal() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerListStartTypeNormal',
    fetchFriendshipEarningAsIssuerListTypeNormalAsync
  )
}

export function* watchFetchSupposedFriendshipEarningAsIssuerList() {
  yield takeLatest(
    'earning/fetchSupposedFriendshipEarningAsIssuerListStart',
    fetchSupposedFriendshipEarningAsIssuerListAsync
  )
}

export function* watchFetchSupposedMembershipEarningAsIssuerList() {
  yield takeLatest(
    'earning/fetchSupposedMembershipEarningAsIssuerListStart',
    fetchSupposedMembershipEarningAsIssuerListAsync
  )
}

// as merchant signer
export function* watchFetchFriendshipEarningAsMerchantSigner() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsMerchantSignerStart',
    fetchFriendshipEarningAsMerchantSignerAsync
  )
}
export function* watchFetchFriendshipEarningAsMerchantSignerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsMerchantSignerListStart',
    fetchFriendshipEarningAsMerchantSignerListAsync
  )
}

// as cp signer
export function* watchFetchFriendshipEarningAsCpSigner() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerStart',
    fetchFriendshipEarningAsCpSignerAsync
  )
}
export function* watchFetchFriendshipEarningAsCpSignerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerListStart',
    fetchFriendshipEarningAsCpSignerListAsync
  )
}

// as state owner
export function* watchFetchFriendshipEarningAsStateOwner() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsStateOwnerStart',
    fetchFriendshipEarningAsStateOwnerAsync
  )
}
export function* watchFetchFriendshipEarningAsStateOwnerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsStateOwnerListStart',
    fetchFriendshipEarningAsStateOwnerListAsync
  )
}

// as region owner
export function* watchFetchFriendshipEarningAsRegionOwner() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsRegionOwnerStart',
    fetchFriendshipEarningAsRegionOwnerAsync
  )
}

export function* watchFetchFriendshipEarningAsRegionOwnerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsRegionOwnerListStart',
    fetchFriendshipEarningAsRegionOwnerListAsync
  )
}

//  as area owner
export function* watchFetchFriendshipEarningAsAreaOwner() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsAreaOwnerStart',
    fetchFriendshipEarningAsAreaOwnerAsync
  )
}

export function* watchFetchFriendshipEarningAsAreaOwnerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsAreaOwnerListStart',
    fetchFriendshipEarningAsAreaOwnerListAsync
  )
}

// as  charity owner
export function* watchFetchFriendshipEarningAsCharity() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCharityStart',
    fetchFriendshipEarningAsCharityAsync
  )
}

export function* watchFetchFriendshipEarningAsCharityList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCharityListStart',
    fetchFriendshipEarningAsCharityListAsync
  )
}

//  as PiiinkIssuer
export function* watchFetchFriendshipEarningAsPiiinkIssuer() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkIssuerStart',
    fetchFriendshipEarningAsPiiinkIssuerAsync
  )
}

export function* watchFetchFriendshipEarningAsPiiinkEntitiesStart() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkEntitiesStart',
    fetchFriendshipEarningAsPiiinkEntitiesAsync
  )
}

export function* watchFetchMembershipEarningAsPiiinkEntitiesStart() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkEntitiesStart',
    fetchMembershipEarningAsPiiinkEntitiesAsync
  )
}

export function* watchFetchFriendshipEarningAsPiiinkEntitiesDetailAsync() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkEntitiesDetailStart',
    fetchFriendshipEarningAsPiiinkEntitiesDetailAsync
  )
}
export function* watchFetchMembershipEarningAsPiiinkEntitiesDetailAsync() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkEntitiesDetailStart',
    fetchMembershipEarningAsPiiinkEntitiesDetailAsync
  )
}

export function* watchFetchFriendshipEarningAsPiiinkIssuerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkIssuerListStart',
    fetchFriendshipEarningAsPiiinkIssuerListAsync
  )
}
//  as PiiinkRegionOwner
export function* watchFetchFriendshipEarningAsPiiinkRegionOwner() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkRegionOwnerStart',
    fetchFriendshipEarningAsPiiinkRegionOwnerAsync
  )
}

export function* watchFetchFriendshipEarningAsPiiinkRegionOwnerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkRegionOwnerListStart',
    fetchFriendshipEarningAsPiiinkRegionOwnerListAsync
  )
}
//  as PiiinkAreaOwner
export function* watchFetchFriendshipEarningAsPiiinkAreaOwner() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkAreaOwnerStart',
    fetchFriendshipEarningAsPiiinkAreaOwnerAsync
  )
}
export function* watchFetchFriendshipEarningAsPiiinkAreaOwnerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkAreaOwnerListStart',
    fetchFriendshipEarningAsPiiinkAreaOwnerListAsync
  )
}
//  as PiiinkCharity
export function* watchFetchFriendshipEarningAsPiiinkCharity() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkCharityStart',
    fetchFriendshipEarningAsPiiinkCharityAsync
  )
}
export function* watchFetchFriendshipEarningAsPiiinkCharityList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkCharityListStart',
    fetchFriendshipEarningAsPiiinkCharityListAsync
  )
}

// for global admin =============================================
// as issuer
export function* watchFetchFriendshipEarningAsIssuerForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerForGlobalAdminStart',
    fetchFriendshipEarningAsIssuerForGlobalAdminAsync
  )
}

export function* watchFetchFriendshipEarningAsIssuerListForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerListForGlobalAdminStart',
    fetchFriendshipEarningAsIssuerListForGlobalAdminAsync
  )
}

// as merchant signer
export function* watchFetchFriendshipEarningAsMerchantSignerForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsMerchantSignerForGlobalAdminStart',
    fetchFriendshipEarningAsMerchantSignerForGlobalAdminAsync
  )
}
export function* watchFetchFriendshipEarningAsMerchantSignerListForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsMerchantSignerListForGlobalAdminStart',
    fetchFriendshipEarningAsMerchantSignerListForGlobalAdminAsync
  )
}

// as cp signer
export function* watchFetchFriendshipEarningAsCpSignerForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerForGlobalAdminStart',
    fetchFriendshipEarningAsCpSignerForGlobalAdminAsync
  )
}
export function* watchFetchFriendshipEarningAsCpSignerListForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerListForGlobalAdminStart',
    fetchFriendshipEarningAsCpSignerListForGlobalAdminAsync
  )
}
// as cp signer as signe r
export function* watchFetchFriendshipEarningAsCpSignerAsSignerForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdminStart',
    fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdminAsync
  )
}
export function* watchFetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdminStart',
    fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdminAsync
  )
}
// white lable specific=============================================================

// whitelabelSpecificFriendshipEarningTotal
export function* watchFetchWhitelabelSpecificFriendshipEarningTotal() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningTotalStart',
    fetchWhitelabelSpecificFriendshipEarningTotalAsync
  )
}

// whitelabelSpecificFriendshipEarningTotalList
export function* watchFetchWhitelabelSpecificFriendshipEarningTotalList() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningTotalListStart',
    fetchWhitelabelSpecificFriendshipEarningTotalListAsync
  )
}

// whitelabelSpecificFriendshipEarningAsIssuer
export function* watchFetchWhitelabelSpecificFriendshipEarningAsIssuer() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningAsIssuerStart',
    fetchWhitelabelSpecificFriendshipEarningAsIssuerAsync
  )
}

// whitelabelSpecificFriendshipEarningAsIssuerList
export function* watchFetchWhitelabelSpecificFriendshipEarningAsIssuerList() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningAsIssuerListStart',
    fetchWhitelabelSpecificFriendshipEarningAsIssuerListAsync
  )
}
// whitelabelSpecificFriendshipEarningAsMerchantSigner
export function* watchFetchWhitelabelSpecificFriendshipEarningAsMerchantSigner() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerStart',
    fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerAsync
  )
}

// whitelabelSpecificFriendshipEarningAsMerchantSignerList
export function* watchFetchWhitelabelSpecificFriendshipEarningAsMerchantSignerList() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerListStart',
    fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerListAsync
  )
}

// whitelabelSpecificFriendshipEarningAsCpSignerAsIssuer
export function* watchFetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuer() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerStart',
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerAsync
  )
}

// whitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList
export function* watchFetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerListStart',
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerListAsync
  )
}

// whitelabelSpecificFriendshipEarningAsCpSignerAsSigner
export function* watchFetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSigner() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerStart',
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerAsync
  )
}

// whitelabelSpecificFriendshipEarningAsCpSignerAsSignerList
export function* watchFetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerList() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerListStart',
    fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerListAsync
  )
}
// for global admin with country =============================================

// as issuer
export function* watchFetchFriendshipEarningAsIssuerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsIssuerForGlobalAdminWithCountryAsync
  )
}

export function* watchFetchFriendshipEarningAsIssuerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountryAsync
  )
}

// as merchant signer
export function* watchFetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountryAsync
  )
}
export function* watchFetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountryAsync
  )
}

// as cp signer
export function* watchFetchFriendshipEarningAsCpSignerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountryAsync
  )
}
export function* watchFetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountryAsync
  )
}

//  as PiiinkRegionOwner
export function* watchFetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryAsync
  )
}

export function* watchFetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryAsync
  )
}
//  as PiiinkAreaOwner
export function* watchFetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkAreaOwnerStartForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountryAsync
  )
}
export function* watchFetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryAsync
  )
}
//  as PiiinkCharity
export function* watchFetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountryAsync
  )
}
export function* watchFetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountryStart',
    fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountryAsync
  )
}

// membership watchers==================================================

// as issuer
export function* watchFetchMembershipEarningAsIssuer() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsIssuerStart',
    fetchMembershipEarningAsIssuerAsync
  )
}

export function* watchFetchMembershipEarningAsIssuerList() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsIssuerListStart',
    fetchMembershipEarningAsIssuerListAsync
  )
}

// as club charity signer
export function* watchFetchMembershipEarningAsClubCharity() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsClubCharityStart',
    fetchMembershipEarningAsClubCharityAsync
  )
}

export function* watchFetchMembershipEarningAsClubCharityList() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsClubCharityListStart',
    fetchMembershipEarningAsClubCharityListAsync
  )
}

// from state allocation
export function* watchFetchMembershipEarningFromStateAllocation() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromStateAllocationStart',
    fetchMembershipEarningFromStateAllocationAsync
  )
}

export function* watchFetchMembershipEarningFromStateAllocationList() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromStateAllocationListStart',
    fetchMembershipEarningFromStateAllocationListAsync
  )
}

// from region allocation
export function* watchFetchMembershipEarningFromRegionAllocation() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromRegionAllocationStart',
    fetchMembershipEarningFromRegionAllocationAsync
  )
}

export function* watchFetchMembershipEarningFromRegionAllocationList() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromRegionAllocationListStart',
    fetchMembershipEarningFromRegionAllocationListAsync
  )
}

// from area allocation
export function* watchFetchMembershipEarningFromAreaAllocation() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromAreaAllocationStart',
    fetchMembershipEarningFromAreaAllocationAsync
  )
}

export function* watchFetchMembershipEarningFromAreaAllocationList() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromAreaAllocationListStart',
    fetchMembershipEarningFromAreaAllocationListAsync
  )
}

// from charity allocation
export function* watchFetchMembershipEarningFromCharityAllocation() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromCharityAllocationStart',
    fetchMembershipEarningFromCharityAllocationAsync
  )
}

export function* watchFetchMembershipEarningFromCharityAllocationList() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromCharityAllocationListStart',
    fetchMembershipEarningFromCharityAllocationListAsync
  )
}

// from cp refer
export function* watchFetchMembershipEarningFromCpRefer() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromCpReferStart',
    fetchMembershipEarningFromCpReferAsync
  )
}

export function* watchFetchMembershipEarningFromCpReferList() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromCpReferListStart',
    fetchMembershipEarningFromCpReferListAsync
  )
}

// as piiink issuer
export function* watchFetchMembershipEarningAsPiiinkIssuer() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkIssuerStart',
    fetchMembershipEarningAsPiiinkIssuerAsync
  )
}
export function* watchFetchMembershipEarningAsPiiinkIssuerList() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkIssuerListStart',
    fetchMembershipEarningAsPiiinkIssuerListAsync
  )
}
// as piiink region
export function* watchFetchMembershipEarningAsPiiinkRegionOwner() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkRegionOwnerStart',
    fetchMembershipEarningAsPiiinkRegionOwnerAsync
  )
}
export function* watchFetchMembershipEarningAsPiiinkRegionOwnerList() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkRegionOwnerListStart',
    fetchMembershipEarningAsPiiinkRegionOwnerListAsync
  )
}

// as piiink area owner
export function* watchFetchMembershipEarningAsPiiinkAreaOwner() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkAreaOwnerStart',
    fetchMembershipEarningAsPiiinkAreaOwnerAsync
  )
}
export function* watchFetchMembershipEarningAsPiiinkAreaOwnerList() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkAreaOwnerListStart',
    fetchMembershipEarningAsPiiinkAreaOwnerListAsync
  )
}

// as piiink charity
export function* watchFetchMembershipEarningAsPiiinkCharity() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkCharityStart',
    fetchMembershipEarningAsPiiinkCharityAsync
  )
}
export function* watchFetchMembershipEarningAsPiiinkCharityList() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkCharityListStart',
    fetchMembershipEarningAsPiiinkCharityListAsync
  )
}
export function* watchFetchFriendshipEarningFromCountryPoolAsCountryOwnerAsync() {
  yield takeLatest(
    'earning/fetchFriendshipEarningFromCountryPoolAsCountryOwnerStart',
    fetchFriendshipEarningFromCountryPoolAsCountryOwnerAsync
  )
}

export function* watchFetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailAsync() {
  yield takeLatest(
    'earning/fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailStart',
    fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailAsync
  )
}

export function* watchFetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerDetailAsync() {
  yield takeLatest(
    'earning/fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailStart',
    fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailAsync
  )
}

export function* watchFetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchant() {
  yield takeLatest(
    'earning/fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantStart',
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchant
  )
}

export function* watchFetchMembershipEarningFromCountryPoolAsCountryOwnerAsync() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromCountryPoolAsCountryOwnerStart',
    fetchMembershipEarningFromCountryPoolAsCountryOwnerAsync
  )
}

export function* watchFetchMembershipEarningFromCountryPoolAsCountryOwnerDetailAsync() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromCountryPoolAsCountryOwnerDetailStart',
    fetchMembershipEarningFromCountryPoolAsCountryOwnerDetailAsync
  )
}

export function* watchFetchCountrySpecificFriendshipEarningAsIssuerAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsIssuerStart',
    fetchCountrySpecificFriendshipEarningAsIssuerAsync
  )
}

export function* watchFetchCountrySpecificFriendshipEarningAsIssuerListAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsIssuerListStart',
    fetchCountrySpecificFriendshipEarningAsIssuerListAsync
  )
}

export function* watchFetchCountrySpecificFriendshipEarningAsMerchantSignerAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsMerchantSignerStart',
    fetchCountrySpecificFriendshipEarningAsMerchantSignerAsync
  )
}

export function* watchFetchCountrySpecificFriendshipEarningAsMerchantSignerListAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsMerchantSignerListStart',
    fetchCountrySpecificFriendshipEarningAsMerchantSignerListAsync
  )
}

export function* watchFetchCountrySpecificFriendshipEarningAsCpSignerAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsCpSignerStart',
    fetchCountrySpecificFriendshipEarningAsCpSignerAsync
  )
}

export function* watchFetchCountrySpecificFriendshipEarningAsCpSignerListAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsCpSignerListStart',
    fetchCountrySpecificFriendshipEarningAsCpSignerListAsync
  )
}

export function* watchFetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelStart',
    fetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelAsync
  )
}

export function* watchFetchCountrySpecificMembershipEarningAsIssuerAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificMembershipEarningAsIssuerStart',
    fetchCountrySpecificMembershipEarningAsIssuerAsync
  )
}

export function* watchFetchCountrySpecificMembershipEarningAsIssuerListAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificMembershipEarningAsIssuerListStart',
    fetchCountrySpecificMembershipEarningAsIssuerListAsync
  )
}

export function* watchFetchCountrySpecificMembershipEarningAsClubCharityAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificMembershipEarningAsClubCharityStart',
    fetchCountrySpecificMembershipEarningAsClubCharityAsync
  )
}

export function* watchFetchCountrySpecificMembershipEarningAsClubCharityList() {
  yield takeLatest(
    'earning/fetchCountrySpecificMembershipEarningAsClubCharityListStart',
    fetchCountrySpecificMembershipEarningAsClubCharityListAsync
  )
}

export function* watchFetchCountrySpecificMembershipEarningAsCpSigner() {
  yield takeLatest(
    'earning/fetchCountrySpecificMembershipEarningAsCpSignerStart',
    fetchCountrySpecificMembershipEarningAsCpSignerAsync
  )
}

export function* watchFetchCountrySpecificMembershipEarningAsCpSignerList() {
  yield takeLatest(
    'earning/fetchCountrySpecificMembershipEarningAsCpSignerListStart',
    fetchCountrySpecificMembershipEarningAsCpSignerListAsync
  )
}

//  fetchCountrySpecificFriendshipEarningTotalAsync
export function* watchFetchCountrySpecificFriendshipEarningTotalAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningTotalStart',
    fetchCountrySpecificFriendshipEarningTotalAsync
  )
}

//  fetchCountrySpecificFriendshipEarningTotalListAsync
export function* watchFetchCountrySpecificFriendshipEarningTotalListAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningTotalListStart',
    fetchCountrySpecificFriendshipEarningTotalListAsync
  )
}

//  fetchCountrySpecificMembershipEarningTotalAsync
export function* watchFetchCountrySpecificMembershipEarningTotalAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificMembershipEarningTotalStart',
    fetchCountrySpecificMembershipEarningTotalAsync
  )
}

//  fetchCountrySpecificMembershipEarningTotalListAsync
export function* watchFetchCountrySpecificMembershipEarningTotalListAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificMembershipEarningTotalListStart',
    fetchCountrySpecificMembershipEarningTotalListAsync
  )
}

// for white label friendship
//  fetchFriendshipEarningAsMerchantSignerForWhitelabelAsync
export function* watchFetchFriendshipEarningAsMerchantSignerForWhitelabel() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsMerchantSignerForWhitelabelStart',
    fetchFriendshipEarningAsMerchantSignerForWhitelabelAsync
  )
}

//  fetchFriendshipEarningAsMerchantSignerListForWhitelabelAsync
export function* watchFetchFriendshipEarningAsMerchantSignerListForWhitelabel() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsMerchantSignerListForWhitelabelcStart',
    fetchFriendshipEarningAsMerchantSignerListForWhitelabelAsync
  )
}
//  fetchFriendshipEarningAsIssuerForWhitelabelAsync
export function* watchFetchFriendshipEarningAsIssuerForWhitelabel() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerForWhitelabelStart',
    fetchFriendshipEarningAsIssuerForWhitelabelAsync
  )
}

//  fetchFriendshipEarningAsIssuerListForWhitelabelAsync
export function* watchFetchFriendshipEarningAsIssuerListForWhitelabel() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerListForWhitelabelStart',
    fetchFriendshipEarningAsIssuerListForWhitelabelAsync
  )
}

// for global admin =============================================
// as issuer
export function* watchFetchMembershipEarningAsIssuerForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsIssuerForGlobalAdminStart',
    fetchMembershipEarningAsIssuerForGlobalAdminAsync
  )
}

export function* watchFetchMembershipEarningAsIssuerListForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsIssuerListForGlobalAdminStart',
    fetchMembershipEarningAsIssuerListForGlobalAdminAsync
  )
}

// as club charity
export function* watchFetchMembershipEarningAsClubCharitySignerForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsClubCharitySignerForGlobalAdminStart',
    fetchMembershipEarningAsClubCharitySignerForGlobalAdminAsync
  )
}
export function* watchFetchMembershipEarningAsClubCharitySignerListForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsClubCharitySignerListForGlobalAdminStart',
    fetchMembershipEarningAsClubCharitySignerListForGlobalAdminAsync
  )
}

// as cp signer
export function* watchFetchMembershipEarningAsCpSignerForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsCpSignerForGlobalAdminStart',
    fetchMembershipEarningAsCpSignerForGlobalAdminAsync
  )
}
export function* watchFetchMembershipEarningAsCpSignerListForGlobalAdmin() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsCpSignerListForGlobalAdminStart',
    fetchMembershipEarningAsCpSignerListForGlobalAdminAsync
  )
}
// for global admin with country =============================================
// as issuer
export function* watchFetchMembershipEarningAsIssuerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsIssuerForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsIssuerForGlobalAdminWithCountryAsync
  )
}

export function* watchFetchMembershipEarningAsIssuerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsIssuerListForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsIssuerListForGlobalAdminWithCountryAsync
  )
}

// as merchant signer
export function* watchFetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountryAsync
  )
}
export function* watchFetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountryAsync
  )
}

// as cp signer
export function* watchFetchMembershipEarningAsCpSignerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsCpSignerForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsCpSignerForGlobalAdminWithCountryAsync
  )
}
export function* watchFetchMembershipEarningAsCpSignerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountryAsync
  )
}

//  as PiiinkRegionOwner
export function* watchFetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountryAsync
  )
}

export function* watchFetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountryAsync
  )
}
//  as PiiinkAreaOwner
export function* watchFetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkAreaOwnerStartForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountryAsync
  )
}
export function* watchFetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountryAsync
  )
}
//  as PiiinkCharity
export function* watchFetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountryAsync
  )
}
export function* watchFetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountry() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountryStart',
    fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountryAsync
  )
}

export function* watchFetchMembershipEarningAsCpSignerList() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsCpSignerListStart',
    fetchMembershipEarningAsCpSignerListAsync
  )
}

export function* watchFetchMembershipEarningAsCpSigner() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsCpSignerStart',
    fetchMembershipEarningAsCpSignerAsync
  )
}

export function* watchFetchFriendshipEarningAsCpSignerAsSigner() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerAsSignerStart',
    fetchFriendshipEarningAsCpSignerAsSignerAsync
  )
}

export function* watchFetchFriendshipEarningAsCpSignerAsSignerList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsCpSignerAsSignerListStart',
    fetchFriendshipEarningAsCpSignerAsSignerListAsync
  )
}

export function* watchFetchCountrySpecificFriendshipEarningAsCpSignerAsSignerAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerStart',
    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerAsync
  )
}
export function* watchFetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListStart',
    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListAsync
  )
}

export function* watchFetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelAsync() {
  yield takeLatest(
    'earning/fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelStart',
    fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelAsync
  )
}
// white label specific ==========================================================

// whitelabelSpecificMembershipEarningTotal
export function* watchFetchWhitelabelSpecificMembershipEarningTotal() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificMembershipEarningTotalStart',
    fetchWhitelabelSpecificMembershipEarningTotalAsync
  )
}

// whitelabelSpecificMembershipEarningTotalList
export function* watchFetchWhitelabelSpecificMembershipEarningTotalList() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificMembershipEarningTotalListStart',
    fetchWhitelabelSpecificMembershipEarningTotalListAsync
  )
}

// whitelabelSpecificMembershipEarningAsIssuer
export function* watchFetchWhitelabelSpecificMembershipEarningAsIssuer() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificMembershipEarningAsIssuerStart',
    fetchWhitelabelSpecificMembershipEarningAsIssuerAsync
  )
}

// whitelabelSpecificMembershipEarningAsIssuerList
export function* watchFetchWhitelabelSpecificMembershipEarningAsIssuerList() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificMembershipEarningAsIssuerListStart',
    fetchWhitelabelSpecificMembershipEarningAsIssuerListAsync
  )
}

// whitelabelSpecificMembershipEarningAsClubCharitySigner
export function* watchFetchWhitelabelSpecificMembershipEarningAsClubCharitySigner() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerStart',
    fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerAsync
  )
}

// whitelabelSpecificMembershipEarningAsClubCharitySignerList
export function* watchFetchWhitelabelSpecificMembershipEarningAsClubCharitySignerList() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerListStart',
    fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerListAsync
  )
}

// whitelabelSpecificMembershipEarningAsCpSigner
export function* watchFetchWhitelabelSpecificMembershipEarningAsCpSigner() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificMembershipEarningAsCpSignerStart',
    fetchWhitelabelSpecificMembershipEarningAsCpSignerAsync
  )
}

// whitelabelSpecificMembershipEarningAsCpSignerList
export function* watchFetchWhitelabelSpecificMembershipEarningAsCpSignerList() {
  yield takeLatest(
    'earning/fetchWhitelabelSpecificMembershipEarningAsCpSignerListStart',
    fetchWhitelabelSpecificMembershipEarningAsCpSignerListAsync
  )
}

// fetchMembershipEarningAsIssuerForWhitelabel
export function* watchFetchMembershipEarningAsIssuerForWhitelabel() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsIssuerForWhitelabelStart',
    fetchMembershipEarningAsIssuerForWhitelabelAsync
  )
}

//  fetchMembershipEarningAsIssuerListForWhitelabel
export function* watchFetchMembershipEarningAsIssuerListForWhitelabel() {
  yield takeLatest(
    'earning/fetchMembershipEarningAsIssuerListForWhitelabelStart',
    fetchMembershipEarningAsIssuerListForWhitelabelAsync
  )
}

export function* watchFetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwner() {
  yield takeLatest(
    'earning/fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerStart',
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwner
  )
}

export function* watchFetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetail() {
  yield takeLatest(
    'earning/fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetailStart',
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetailAsync
  )
}

export function* watchFetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetail() {
  yield takeLatest(
    'earning/fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetailStart',
    fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetailAsync
  )
}

export function* watchFetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetail() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetailStart',
    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetailAsync
  )
}

export function* watchFetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwner() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerStart',
    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwner
  )
}

export function* watchFetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerStart() {
  yield takeLatest(
    'earning/fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerStart',
    fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerStart
  )
}

export function* watchFetchMemberPremiumCodeTransactionAsync() {
  yield takeLatest(
    'earning/fetchMemberPremiumCodeTransactionStart',
    fetchMemberPremiumCodeTransactionAsync
  )
}

export function* watchFetchMemberPremiumCodeTransactionDetailAsync() {
  yield takeLatest(
    'earning/fetchMemberPremiumCodeTransactionDetailStart',
    fetchMemberPremiumCodeTransactionDetailAsync
  )
}

export function* watchFetchFriendshipTransactionByTerminalUserAsync() {
  yield takeLatest(
    'earning/fetchFriendshipTransactionByTerminalUserStart',
    fetchFriendshipTransactionByTerminalUserAsync
  )
}
export function* watchFetchFriendshipEarningAsIssuerTypeBFAsync() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerTypeBFStart',
    fetchFriendshipEarningAsIssuerTypeBFAsync
  )
}

export function* watchFetchFriendshipEarningAsIssuerTypeNormalAsync() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerTypeNormalStart',
    fetchFriendshipEarningAsIssuerTypeNormalAsync
  )
}

export function* watchFetchFriendshipEarningAsIssuerDetailsTypeNormal() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerListTypeNormalStart',
    fetchFriendshipEarningAsIssuerListTypeNormalAsync
  )
}

export function* watchFetchFriendshipEarningAsIssuerDetailsTypeBFAsync() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsIssuerListTypeBFStart',
    fetchFriendshipEarningAsIssuerDetailsTypeBFAsync
  )
}

export function* watchFetchCountryRevenueFromFriendshipSummaryAsync() {
  yield takeLatest(
    'earning/fetchCountryRevenueFromFriendshipSummaryStart',
    fetchCountryRevenueFromFriendshipSummaryAsync
  )
}

export function* watchFetchCountryRevenueFromFriendshipDetailAsync() {
  yield takeLatest(
    'earning/fetchCountryRevenueFromFriendshipDetailStart',
    fetchCountryRevenueFromFriendshipDetailAsync
  )
}

export function* watchFetchCountryRevenueFromMembershipSummaryAsync() {
  yield takeLatest(
    'earning/fetchCountryRevenueFromMembershipSummaryStart',
    fetchCountryRevenueFromMembershipSummaryAsync
  )
}

export function* watchFetchCountryRevenueFromMembershipDetailAsync() {
  yield takeLatest(
    'earning/fetchCountryRevenueFromMembershipDetailStart',
    fetchCountryRevenueFromMembershipDetailAsync
  )
}

export function* watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharity() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharityStart',
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharityAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsCharity() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharityStart',
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharityAsync
  )
}

export function* watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwnerStart',
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwnerStart',
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwnerStart',
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwnerStart',
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwnerStart',
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwnerStart',
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwnerStart',
    fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwnerStart',
    fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwnerAsync
  )
}

///membership section starts

export function* watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsCharity() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharityStart',
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharityAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailMembershipEarningAsCharity() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailMembershipEarningAsCharityStart',
    fetchGlobalAdminWithCountryDetailMembershipEarningAsCharityAsync
  )
}

export function* watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwnerStart',
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwnerStart',
    fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwnerStart',
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwnerStart',
    fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwnerStart',
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwnerStart',
    fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwnerStart',
    fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwnerAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwner() {
  yield takeLatest(
    'earning/fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwnerStart',
    fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwnerAsync
  )
}

export function* watchFetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsList() {
  yield takeLatest(
    'earning/fetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsListStart',
    fetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsListAsync
  )
}

export function* watchCancelTransaction() {
  yield takeLatest('earning/cancelTransactionStart', cancelTransactionAsync)
}

export function* earningSagas() {
  yield all([
    // friendship
    call(watchFetchFriendshipEarning),
    call(watchFetchFriendshipEarningList),
    call(watchFetchFriendshipEarningAsIssuer),
    call(watchFetchFriendshipEarningAsIssuerList),
    call(watchFetchFriendshipEarningAsMerchantSigner),
    call(watchFetchFriendshipEarningAsMerchantSignerList),
    call(watchFetchFriendshipEarningAsCpSigner),
    call(watchFetchFriendshipEarningAsCpSignerList),
    call(watchFetchFriendshipEarningAsStateOwner),
    call(watchFetchFriendshipEarningAsStateOwnerList),
    call(watchFetchFriendshipEarningAsRegionOwner),
    call(watchFetchFriendshipEarningAsRegionOwnerList),
    call(watchFetchFriendshipEarningAsAreaOwner),
    call(watchFetchFriendshipEarningAsAreaOwnerList),
    call(watchFetchFriendshipEarningAsCharity),
    call(watchFetchFriendshipEarningAsCharityList),
    call(watchFetchFriendshipEarningAsPiiinkIssuer),
    call(watchFetchFriendshipEarningAsPiiinkIssuerList),
    call(watchFetchFriendshipEarningAsPiiinkRegionOwner),
    call(watchFetchFriendshipEarningAsPiiinkRegionOwnerList),
    call(watchFetchFriendshipEarningAsPiiinkAreaOwner),
    call(watchFetchFriendshipEarningAsPiiinkAreaOwnerList),
    call(watchFetchFriendshipEarningAsPiiinkCharity),
    call(watchFetchFriendshipEarningAsPiiinkCharityList),
    call(watchFetchFriendshipEarningFromCountryPoolAsCountryOwnerAsync),
    call(watchFetchFriendshipEarningFromCountryPoolAsCountryOwnerDetailAsync),
    call(watchFetchFriendshipEarningAsCpSignerAsSignerList),

    call(watchFetchFriendshipEarningAsIssuerForGlobalAdminWithCountry),
    call(watchFetchFriendshipEarningAsIssuerListForGlobalAdminWithCountry),
    call(watchFetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountry),
    call(
      watchFetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountry
    ),
    call(watchFetchFriendshipEarningAsCpSignerForGlobalAdminWithCountry),
    call(watchFetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountry),
    call(
      watchFetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry
    ),
    call(
      watchFetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry
    ),
    call(watchFetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry),
    call(
      watchFetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry
    ),
    call(watchFetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountry),
    call(
      watchFetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountry
    ),
    call(watchFetchFriendshipEarningAsIssuerForGlobalAdmin),
    call(watchFetchFriendshipEarningAsIssuerListForGlobalAdmin),
    call(watchFetchFriendshipEarningAsMerchantSignerForGlobalAdmin),
    call(watchFetchFriendshipEarningAsMerchantSignerListForGlobalAdmin),
    call(watchFetchFriendshipEarningAsCpSignerForGlobalAdmin),
    call(watchFetchFriendshipEarningAsCpSignerListForGlobalAdmin),
    call(watchFetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListAsync),
    call(watchFetchFriendshipEarningAsCpSignerAsSignerForGlobalAdmin),
    call(watchFetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdmin),

    call(watchFetchWhitelabelSpecificFriendshipEarningTotal),
    call(watchFetchWhitelabelSpecificFriendshipEarningTotalList),
    call(watchFetchWhitelabelSpecificFriendshipEarningAsIssuer),
    call(watchFetchWhitelabelSpecificFriendshipEarningAsIssuerList),
    call(watchFetchWhitelabelSpecificFriendshipEarningAsMerchantSigner),
    call(watchFetchWhitelabelSpecificFriendshipEarningAsMerchantSignerList),
    call(watchFetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuer),
    call(watchFetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList),
    call(watchFetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSigner),
    call(watchFetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerList),

    call(watchFetchFriendshipEarningAsMerchantSignerForWhitelabel),
    call(watchFetchFriendshipEarningAsMerchantSignerListForWhitelabel),
    call(watchFetchFriendshipEarningAsIssuerForWhitelabel),
    call(watchFetchFriendshipEarningAsIssuerListForWhitelabel),

    // membership
    call(watchFetchMembershipEarningAsIssuer),
    call(watchFetchMembershipEarningAsIssuerList),
    call(watchFetchMembershipEarningAsIssuerList),
    call(watchFetchMembershipEarningAsClubCharity),
    call(watchFetchMembershipEarningAsClubCharityList),
    call(watchFetchMembershipEarningFromStateAllocation),
    call(watchFetchMembershipEarningFromStateAllocationList),
    call(watchFetchMembershipEarningFromRegionAllocation),
    call(watchFetchMembershipEarningFromRegionAllocationList),
    call(watchFetchMembershipEarningFromAreaAllocation),
    call(watchFetchMembershipEarningFromAreaAllocationList),
    call(watchFetchMembershipEarningFromCharityAllocation),
    call(watchFetchMembershipEarningFromCharityAllocationList),
    call(watchFetchMembershipEarningFromCpRefer),
    call(watchFetchMembershipEarningFromCpReferList),
    call(watchFetchMembershipEarningAsPiiinkIssuer),
    call(watchFetchMembershipEarningAsPiiinkIssuerList),
    call(watchFetchMembershipEarningAsPiiinkRegionOwner),
    call(watchFetchMembershipEarningAsPiiinkRegionOwnerList),
    call(watchFetchMembershipEarningAsPiiinkAreaOwner),
    call(watchFetchMembershipEarningAsPiiinkAreaOwnerList),
    call(watchFetchMembershipEarningAsPiiinkCharity),
    call(watchFetchMembershipEarningAsPiiinkCharityList),

    call(watchFetchMembershipEarningFromCountryPoolAsCountryOwnerAsync),
    call(watchFetchMembershipEarningFromCountryPoolAsCountryOwnerDetailAsync),
    call(watchFetchCountrySpecificFriendshipEarningAsIssuerAsync),
    call(watchFetchCountrySpecificFriendshipEarningAsIssuerListAsync),

    call(watchFetchCountrySpecificFriendshipEarningAsCpSignerAsync),
    call(watchFetchCountrySpecificFriendshipEarningAsCpSignerListAsync),

    call(watchFetchCountrySpecificMembershipEarningAsIssuerAsync),
    call(watchFetchCountrySpecificMembershipEarningAsIssuerListAsync),

    call(watchFetchCountrySpecificMembershipEarningAsClubCharityList),
    call(watchFetchCountrySpecificMembershipEarningAsClubCharityAsync),

    call(watchFetchCountrySpecificMembershipEarningAsCpSigner),
    call(watchFetchCountrySpecificMembershipEarningAsCpSignerList),

    call(watchFetchMembershipEarningAsIssuerForGlobalAdminWithCountry),
    call(watchFetchMembershipEarningAsIssuerListForGlobalAdminWithCountry),
    call(
      watchFetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountry
    ),
    call(
      watchFetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountry
    ),
    call(watchFetchMembershipEarningAsCpSignerForGlobalAdminWithCountry),
    call(watchFetchMembershipEarningAsCpSignerListForGlobalAdminWithCountry),
    call(
      watchFetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry
    ),
    call(
      watchFetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry
    ),
    call(watchFetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry),
    call(
      watchFetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry
    ),
    call(watchFetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountry),
    call(
      watchFetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountry
    ),
    call(watchFetchCountrySpecificFriendshipEarningAsMerchantSignerAsync),
    call(watchFetchCountrySpecificFriendshipEarningAsMerchantSignerListAsync),

    call(watchFetchMembershipEarningAsCpSignerList),
    call(watchFetchMembershipEarningAsCpSigner),

    call(watchFetchMembershipEarningAsIssuerForGlobalAdmin),
    call(watchFetchMembershipEarningAsIssuerListForGlobalAdmin),
    call(watchFetchMembershipEarningAsClubCharitySignerForGlobalAdmin),
    call(watchFetchMembershipEarningAsClubCharitySignerListForGlobalAdmin),
    call(watchFetchMembershipEarningAsCpSignerForGlobalAdmin),
    call(watchFetchMembershipEarningAsCpSignerListForGlobalAdmin),

    call(watchFetchFriendshipEarningAsCpSignerAsSigner),
    call(watchFetchCountrySpecificFriendshipEarningAsCpSignerAsSignerAsync),

    call(watchFetchCountrySpecificFriendshipEarningTotalAsync),
    call(watchFetchCountrySpecificFriendshipEarningTotalListAsync),
    call(watchFetchCountrySpecificMembershipEarningTotalAsync),
    call(watchFetchCountrySpecificMembershipEarningTotalListAsync),
    call(watchFetchWhitelabelSpecificMembershipEarningTotal),
    call(watchFetchWhitelabelSpecificMembershipEarningTotalList),
    call(watchFetchWhitelabelSpecificMembershipEarningAsIssuer),
    call(watchFetchWhitelabelSpecificMembershipEarningAsIssuerList),
    call(watchFetchWhitelabelSpecificMembershipEarningAsClubCharitySigner),
    call(watchFetchWhitelabelSpecificMembershipEarningAsClubCharitySignerList),
    call(watchFetchWhitelabelSpecificMembershipEarningAsCpSigner),
    call(watchFetchWhitelabelSpecificMembershipEarningAsCpSignerList),
    call(watchFetchMembershipEarningAsIssuerForWhitelabel),
    call(watchFetchMembershipEarningAsIssuerListForWhitelabel),
    call(watchFetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwner),
    call(watchFetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwner),
    call(
      watchFetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetail
    ),
    call(
      watchFetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetail
    ),
    call(
      watchFetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetail
    ),
    call(
      watchFetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchant
    ),
    call(
      watchFetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerStart
    ),
    call(watchFetchMemberPremiumCodeTransactionAsync),
    call(watchFetchMemberPremiumCodeTransactionDetailAsync),
    call(watchFetchFriendshipTransactionByTerminalUserAsync),
    call(watchFetchSupposedFriendshipEarningAsIssuerList),
    call(watchFetchSupposedMembershipEarningAsIssuerList),
    call(watchFetchFriendshipEarningAsIssuerTypeBFAsync),
    call(watchFetchFriendshipEarningAsIssuerTypeNormalAsync),
    call(watchFetchFriendshipEarningAsIssuerDetailsTypeNormal),
    call(watchFetchFriendshipEarningAsIssuerDetailsTypeBFAsync),
    call(watchFetchFriendshipEarningAsPiiinkEntitiesStart),
    call(watchFetchFriendshipEarningAsPiiinkEntitiesDetailAsync),
    call(watchFetchMembershipEarningAsPiiinkEntitiesStart),
    call(watchFetchMembershipEarningAsPiiinkEntitiesStart),
    call(watchFetchMembershipEarningAsPiiinkEntitiesDetailAsync),
    call(watchFetchCountryRevenueFromFriendshipSummaryAsync),
    call(watchFetchCountryRevenueFromMembershipSummaryAsync),
    call(watchFetchCountryRevenueFromFriendshipDetailAsync),
    call(watchFetchCountryRevenueFromMembershipDetailAsync),
    call(
      watchFetchCountrySpecificFriendshipEarningAsCpSignerListForCountryPanelAsync
    ),
    call(
      watchFetchCountrySpecificFriendshipEarningAsCpSignerAsSignerListForCountryPanelAsync
    ),
    call(watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharity),
    call(watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsCharity),
    call(watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwner),
    call(watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwner),
    call(watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwner),
    call(watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwner),
    call(watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwner),
    call(watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwner),
    call(
      watchFetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwner
    ),
    call(watchFetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwner),

    ///membership section starts

    call(watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsCharity),
    call(watchFetchGlobalAdminWithCountryDetailMembershipEarningAsCharity),
    call(watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwner),
    call(watchFetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwner),
    call(watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwner),
    call(watchFetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwner),
    call(watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwner),
    call(watchFetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwner),
    call(
      watchFetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwner
    ),
    call(watchFetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwner),
    call(watchCancelTransaction),
    call(watchFetchFriendshipEarningAsMerchantSignerBranchOfWLDetailsList),
  ])
}
