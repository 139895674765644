import { createSlice } from '@reduxjs/toolkit'

import { IPostalCodeState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IPostalCodeState = {
  postalCodeList: listObj,
  error: null,
  loading: false,
  hasMoreData: false,
}

export const postalCodeSlice = createSlice({
  name: 'postalCode',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPostalCodeStart: (state: any, action) => {
      state.loading = true
    },
    fetchPostalCodeSuccess: (state: any, action) => {
      state.postalCodeList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPostalCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchPostalCodeByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchPostalCodeByIdSuccess: (state: any, action) => {
      state.postalCodeList = action.payload.data
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPostalCodeByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createPostalCodeStart: (state: any, action) => {
      state.loading = true
    },
    createPostalCodeSuccess: (state: any, action) => {
      state.loading = false
      state.postalCodeList.data = [...state.postalCodeList.data, action.payload]
    },
    createPostalCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createPostalCodeByCsvStart: (state: any, action) => {
      state.loading = true
    },
    createPostalCodeByCsvSuccess: (state: any, action) => {
      state.loading = false
      // state.postalCodeList.data = [...state.postalCodeList.data, action.payload]
    },
    createPostalCodeByCsvFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deletePostalCodeStart: (state: any, action) => {
      state.loading = true
    },
    deletePostalCodeSuccess: (state: any, action) => {
      state.loading = false
      state.postalCodeList.data = state.postalCodeList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePostalCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editPostalCodeStart: (state: any, action) => {
      state.loading = true
    },
    editPostalCodeSuccess: (state: any, action) => {
      const result = state.postalCodeList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.postalCodeList.data = result

      state.loading = false
    },
    editPostalCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchPostalCodeStart,
  fetchPostalCodeSuccess,
  fetchPostalCodeFailure,
  createPostalCodeStart,
  createPostalCodeSuccess,
  createPostalCodeFailure,
  createPostalCodeByCsvStart,
  createPostalCodeByCsvSuccess,
  createPostalCodeByCsvFailure,
  deletePostalCodeStart,
  deletePostalCodeSuccess,
  deletePostalCodeFailure,
  fetchPostalCodeByIdStart,
  fetchPostalCodeByIdSuccess,
  fetchPostalCodeByIdFailure,
  editPostalCodeStart,
  editPostalCodeSuccess,
  editPostalCodeFailure,
} = postalCodeSlice.actions

export default postalCodeSlice.reducer
