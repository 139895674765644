import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import DraftList from 'components/GlobalMerchantForm/MerchantFranchisorDraftList'
import EditMerchantRequest from 'components/GlobalMerchantForm/DraftList/components/EditMerchantRequest/EditMerchantRequest.component'
import MerchantList from 'components/GlobalMerchantForm/MerchantFranchisorList'
import SendMerchantRequest from 'components/GlobalMerchantForm/SendMerchantRequest'

export const merchantFranchiseAdminConfig = [
  {
    permittedRole: RoutePermittedRole.merchantFranchisor,
    path: '/merchantgroup/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'merchantgroup'} />,
  },
  {
    permittedRole: RoutePermittedRole.merchantFranchisor,
    path: '/merchantgroup/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'merchantgroup'} />,
  },
  {
    permittedRole: RoutePermittedRole.merchantFranchisor,
    path: '/merchantgroup/editMerchantRequest/:id',
    element: <EditMerchantRequest adminRoute={'merchantgroup'} />,
  },
  {
    permittedRole: RoutePermittedRole.merchantFranchisor,
    path: '/merchantgroup/merchantList',
    element: <MerchantList adminRoute={'merchantgroup'} />,
  },
  {
    permittedRole: RoutePermittedRole.merchantFranchisor,
    path: '/merchantgroup/draftList',
    element: <DraftList adminRoute={'merchantgroup'} />,
  },
]
