import { createSlice } from '@reduxjs/toolkit'

import { ISalesAgentState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: ISalesAgentState = {
  salesAgentList: listObj,
  soleSalesAgent: {},
  stripeAccountInfo: {},
  pendingSalesAgentList: listObj,
  error: null,
  loading: false,
  isEditing: false,
  isVerifing: false,
  isRejecting: false,
  isCreating: false,
  isGenerating: false,
  hasMoreData: false,
}

export const salesAgentSlice = createSlice({
  name: 'salesAgent',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSalesAgentStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchSalesAgentSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.salesAgentList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.isFetching = false
    },
    fetchSalesAgentFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchPendingSalesAgentStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPendingSalesAgentSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pendingSalesAgentList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.isFetching = false
    },
    fetchPendingSalesAgentFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchSalesAgentByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchSalesAgentByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleSalesAgent = action.payload

      state.loading = false
    },
    fetchSalesAgentByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deletePendingSalesAgentStart: (state: any, action) => {
      state.loading = true
    },
    deletePendingSalesAgentSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.pendingSalesAgentList = state.pendingSalesAgentList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePendingSalesAgentFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteSalesAgentStart: (state: any, action) => {
      state.loading = true
    },
    deleteSalesAgentSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.salesAgentList.data = state.salesAgentList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteSalesAgentFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createSalesAgentStart: (state: any, action) => {
      state.isCreating = true
    },
    createSalesAgentSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isCreating = false
      // state.salesAgentList.data = [...state.salesAgentList.data, action.payload]
    },
    createSalesAgentFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editSalesAgentStart: (state: any, action) => {
      state.isEditing = true
    },
    editSalesAgentSuccess: (state: any) => {
      // state.currentUser= action.payload;

      state.isEditing = false
    },
    editSalesAgentFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    verifySalesAgentStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifySalesAgentSuccess: (state: any, action) => {
      state.isVerifing = false
      state.pendingSalesAgentList.data =
        state.pendingSalesAgentList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    verifySalesAgentFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },
    rejectSalesAgentStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectSalesAgentSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.pendingSalesAgentList.data =
        state.pendingSalesAgentList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    rejectSalesAgentFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },

    updatePendingSalesAgentStart: (state: any, action) => {
      state.isEditing = true
    },
    updatePendingSalesAgentSuccess: (state: any, action) => {
      // state.currentUser= action.payload;

      const result = state.pendingSalesAgentList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.pendingSalesAgentList.data = result

      state.isEditing = false
    },
    updatePendingSalesAgentFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    editSalesAgentOwnerOwnProfileInfoStart: (state: any, action) => {
      state.isEditing = true
    },
    editSalesAgentOwnerOwnProfileInfoSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editSalesAgentOwnerOwnProfileInfoFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    createStripePayoutAccountStart: (state: any, action) => {
      state.isCreating = true
    },
    createStripePayoutAccountSuccess: (state: any, action) => {
      state.isCreating = false
      state.stripeAccountInfo = action.payload
    },
    createStripePayoutAccountFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    generateConnectAccountLinkStart: (state: any, action) => {
      state.isGenerating = true
    },
    generateConnectAccountLinkSuccess: (state: any, action) => {
      state.isGenerating = false
    },
    generateConnectAccountLinkFailure: (state: any, action) => {
      state.isGenerating = false
      state.error = action.payload
    },

    stripeCheckOnboardStatusStart: (state: any, action) => {
      state.isChecking = true
    },
    stripeCheckOnboardStatusSuccess: (state: any, action) => {
      state.isChecking = false
    },
    stripeCheckOnboardStatusFailure: (state: any, action) => {
      state.isChecking = false
      state.error = action.payload
    },

    stripeLoginUrlStart: (state: any, action) => {
      state.isVerifing = true
    },
    stripeLoginUrlSuccess: (state: any, action) => {
      state.isVerifing = false
    },
    stripeLoginUrlFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },
  },
})

export const {
  fetchSalesAgentStart,
  fetchSalesAgentSuccess,
  fetchSalesAgentFailure,
  deleteSalesAgentStart,
  deleteSalesAgentSuccess,
  deleteSalesAgentFailure,
  fetchSalesAgentByIdStart,
  fetchSalesAgentByIdSuccess,
  fetchSalesAgentByIdFailure,
  createSalesAgentStart,
  createSalesAgentSuccess,
  createSalesAgentFailure,
  editSalesAgentStart,
  editSalesAgentSuccess,
  editSalesAgentFailure,
  fetchPendingSalesAgentStart,
  fetchPendingSalesAgentSuccess,
  fetchPendingSalesAgentFailure,
  deletePendingSalesAgentStart,
  deletePendingSalesAgentSuccess,
  deletePendingSalesAgentFailure,
  verifySalesAgentStart,
  verifySalesAgentSuccess,
  verifySalesAgentFailure,
  rejectSalesAgentStart,
  rejectSalesAgentSuccess,
  rejectSalesAgentFailure,
  updatePendingSalesAgentStart,
  updatePendingSalesAgentFailure,
  updatePendingSalesAgentSuccess,
  editSalesAgentOwnerOwnProfileInfoStart,
  editSalesAgentOwnerOwnProfileInfoSuccess,
  editSalesAgentOwnerOwnProfileInfoFailure,

  createStripePayoutAccountStart,
  createStripePayoutAccountSuccess,
  createStripePayoutAccountFailure,

  generateConnectAccountLinkStart,
  generateConnectAccountLinkSuccess,
  generateConnectAccountLinkFailure,

  stripeCheckOnboardStatusStart,
  stripeCheckOnboardStatusSuccess,
  stripeCheckOnboardStatusFailure,

  stripeLoginUrlStart,
  stripeLoginUrlSuccess,
  stripeLoginUrlFailure,
} = salesAgentSlice.actions

export default salesAgentSlice.reducer
