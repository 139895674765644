import { axiosRequest } from 'utils'

export const fetchPostalCodeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/postalCode/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPostalCodeById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/postalCode/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPostalCode = (params) => {
  return () =>
    axiosRequest
      .post(`/api/postalCode/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPostalCodeByCsv = (params) => {
  return () =>
    axiosRequest
      .post(`/api/postalCode/createCSV`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deletePostalCode = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/postalCode/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editPostalCode = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/postalCode/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
