/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { fetchMemberByPanelStart } from 'store/members/membersSlice'
import { fetchTerminalListByPanelStart } from 'store/merchant/merchantSlice'
// import {
//   fetchCountryStart,
//   createCountryStart,
// } from '../../../store/countrySetup/countrySetupSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  areaOwner: state.areaOwner,
  stateOwners: state.stateOwner,
  charities: state.charities,
  club: state.club,
  members: state.members,
  merchant: state.merchant,
  siteCoordinator: state.siteCoordinator,
})
const mapDispatchToProps = (dispatch) => ({
  onFetchMemberStart: (payload) => dispatch(fetchMemberByPanelStart(payload)),
  onFetchAllTerminalListStart: (payload) =>
    dispatch(fetchTerminalListByPanelStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
