import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
const WhiteLabelSetup = React.lazy(() => import('./WhiteLabelSetup'))
const WhiteLabelLevelsSetup = React.lazy(
  () => import('./WhiteLabelLevelsSetup')
)

const WhiteLabelFriendshipBeneficiaries = React.lazy(
  () => import('./WhiteLabelFriendshipBeneficiaries')
)

const WhiteLabelMembershipBeneficiaries = React.lazy(
  () => import('./WhiteLabelMembershipBeneficiaries')
)

const FriendshipScenarioSettings = React.lazy(
  () => import('./FriendshipScenarioList')
)
const MembershipScenarioSettings = React.lazy(
  () => import('./MembershipScenarioList')
)

export const whiteLabelSetupConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/whiteLabelSetup',
    element: <WhiteLabelSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/whiteLabelLevelsSetup/:id',
    element: <WhiteLabelLevelsSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/whiteLabelFriendshipBeneficiaries',
    element: <WhiteLabelFriendshipBeneficiaries />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/whiteLabelMembershipBeneficiaries',
    element: <WhiteLabelMembershipBeneficiaries />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/friendship/scenarioList/:id',
    element: <FriendshipScenarioSettings />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/membership/scenarioList/:id',
    element: <MembershipScenarioSettings />,
  },
]
