import { ReactNode, useEffect } from 'react'
import { RoutePermittedRole } from '../shared/constants/AppConst'
import setupRoutesConfig from './SetupRoutes/SetUpRoutes'
import globalSetUpWithCountryConfig from './GlobalAdminWithinCountryRoutes/GlobalAdminWithinCountryRoutes'
import countryAdminRoutesConfig from './CountryAdminRoutes/countryAdminRoutes'
import gmcAdminRoutesConfig from './GmcAdminRoutes/gmcAdminRoutes'
import websiteAdminRoutesConfig from './WebsiteAdminRoutes/websiteAdminRoutes'
import areaAdminRoutesConfig from './AreaAdminRoutes/areaAdminRoutes'
import stateAdminRoutesConfig from './StateAdminRoutes/stateAdminRoutes'
import regionAdminRoutesConfig from './RegionAdminRoutes/regionAdminRoutes'
import charityAdminRoutesConfig from './CharityAdminRoutes/charityAdminRoutes'
import clubAdminRoutesConfig from './ClubAdminRoutes/clubAdminRoutes'
import whiteLabelAdminRoutesConfig from './WhiteLabelAdminRoutes/whiteLabelAdminRoutes'
import whiteLabelAdminIntroducerRoutesConfig from './WhiteLabelAdminRoutes/whiteLabelAdminIntroducerRoutes'
import whiteLabelAdminBalancingFigureRoutesConfig from './WhiteLabelAdminRoutes/whiteLabelAdminBalancingFigureRoutes'
import salesAgentRoutesConfig from './SalesAgentRoutes/salesAgentRoutes'
import salesAgentCPRoutesConfig from './SalesAgentRoutes/salesAgentCPRoutes'
import paymentAwaitingCPRoutesConfig from './SalesAgentRoutes/paymentAwaitingCPRoutes'
import companyRoutesConfig from './CompanyAdminRoutes/companyRoutes'
import collaborationParnterRoutesConfig from './CollaborationPartnerRoutes/collaborationPartnerRoutes'
import merchantAdminRoutesConfig from './MerchantAdminRoutes/merchantAdminRoutes'
import MerchantFranchiseRoutesConfig from './MerchantFranchiseRoutes/merchantFranchiseRoutes'
import terminalMerchantAdminRoutesConfig from './MerchantAdminRoutes/terminalMerchantAdminRoutes'
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux'
import { fetchMerchantProfileInfoStart } from 'store/merchant/merchantSlice'
import _ from 'lodash'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}
const useRoutes = () => {
  const {
    currentUser: { role, userCountry, agreedTermAndCondition, whiteLabel },
  } = useSelector((state: RootStateOrAny) => state.auth)

  const { profileInfo } = useSelector((state: RootStateOrAny) => state.merchant)

  const dispatch = useDispatch()

  useEffect(() => {
    if (role?.toLowerCase() === 'merchant' && !userCountry?.isTerminalUser) {
      dispatch(fetchMerchantProfileInfoStart({}))
    }
  }, [])

  const { stateOwnerProfileInfo } = useSelector(
    (state: RootStateOrAny) => state.stateOwner
  )

  const { regionOwnerProfileInfo } = useSelector(
    (state: RootStateOrAny) => state.regionOwner
  )

  const { areaOwnerProfileInfo } = useSelector(
    (state: RootStateOrAny) => state.areaOwner
  )

  const { countryOwnerProfileInfo } = useSelector(
    (state: RootStateOrAny) => state.countryOwner
  )

  const orgRole = role?.toLowerCase()

  const filterNames = []

  const filterCountryAdmin = []

  if (!_.isEmpty(whiteLabel)) {
    for (const key of Object.keys(whiteLabel?.whiteLabelUser)) {
      if (
        key === 'canIssueMember' &&
        whiteLabel?.whiteLabelUser['canIssueMember'] == true
      ) {
        filterNames.push('members')
      } else if (
        key === 'canSignMerchant' &&
        whiteLabel?.whiteLabelUser['canSignMerchant'] == true
      ) {
        filterNames.push('merchants')
      } else if (
        (key === 'prize draw' && whiteLabel?.allowPrizeDraw === true) ||
        whiteLabel?.allowPrizeDraw === true
      ) {
        filterNames.push('prize draw')
      } else if (
        key === 'canReferCollaborationPartner' &&
        whiteLabel?.whiteLabelUser['canReferCollaborationPartner'] == true
      ) {
        filterNames.push('collaboration partners')
      }
    }
  }

  if (!_.isEmpty(userCountry)) {
    for (const key of Object.keys(userCountry)) {
      if (
        key === 'allowPrizeDraw' &&
        userCountry?.allowPrizeDraw === true &&
        role?.toLowerCase() !== 'collaborationpartner'
      ) {
        filterCountryAdmin.push('prize draw')
      }

      if (key === 'isTerminalUser' && userCountry?.isTerminalUser === false) {
        filterCountryAdmin.push('users')
      }
    }
  }

  if (!_.isEmpty(stateOwnerProfileInfo?.data)) {
    for (const key of Object.keys(stateOwnerProfileInfo?.data)) {
      if (key === 'agreementUrl' && stateOwnerProfileInfo?.data?.agreementUrl) {
        filterCountryAdmin.push('agreement')
      }
    }
  }

  if (!_.isEmpty(regionOwnerProfileInfo?.data)) {
    for (const key of Object.keys(regionOwnerProfileInfo?.data)) {
      if (
        key === 'agreementUrl' &&
        regionOwnerProfileInfo?.data?.agreementUrl
      ) {
        filterCountryAdmin.push('agreement')
      }
    }
  }

  if (!_.isEmpty(areaOwnerProfileInfo?.data)) {
    for (const key of Object.keys(areaOwnerProfileInfo?.data)) {
      if (key === 'agreementUrl' && areaOwnerProfileInfo?.data?.agreementUrl) {
        filterCountryAdmin.push('agreement')
      }
    }
  }

  if (!_.isEmpty(countryOwnerProfileInfo?.data)) {
    for (const key of Object.keys(countryOwnerProfileInfo?.data)) {
      if (
        key === 'agreementUrl' &&
        countryOwnerProfileInfo?.data?.agreementUrl
      ) {
        filterCountryAdmin.push('agreement')
      }
    }
  }

  // if (
  //   userCountry?.role?.toLowerCase() === 'collaborationpartner' &&
  //   userCountry?.paymentAwaiting == false
  // ) {
  //   for (var key of Object.keys(userCountry)) {
  //     if (key === 'paymentAwaiting' && userCountry?.paymentAwaiting == false) {
  //       filterCountryAdmin.push('merchants', 'members', 'charities')
  //     }
  //   }
  // }

  const checkWhiteLabelSideBarMenu = (sideBarArray) => {
    return Object.assign([], sideBarArray, {
      0: {
        ...sideBarArray[0],
        children: sideBarArray[0]?.children.filter((item) => {
          if (
            item?.messageId?.toLowerCase() === 'members' ||
            item?.messageId?.toLowerCase() === 'merchants' ||
            item?.messageId?.toLowerCase() === 'prize draw'
          ) {
            return filterNames.includes(item?.messageId?.toLowerCase())
          } else {
            return item
          }
        }),
      },
    })
  }

  const checkAsIssuerInWhiteLabelSideBarMenu = (sideBarArray) => {
    return Object.assign([], sideBarArray, {
      0: {
        ...sideBarArray[0],
        children: sideBarArray[0]?.children.filter((item) => {
          if (
            !userCountry?.whiteLabelUser?.canIssueMember &&
            (item?.title?.toLowerCase() === 'friendshipearning' ||
              item?.title?.toLowerCase() === 'memberhipearning')
          ) {
            return ''
          } else if (
            userCountry?.whiteLabelUser?.canIssueMember &&
            (item?.title?.toLowerCase() === 'friendshipearningwithoutissuer' ||
              item?.title?.toLowerCase() === 'memberhipearningwithoutissuer')
          ) {
            return ''
          } else {
            return item
          }
        }),
      },
    })
  }

  // const checkPrizeDrawSideBarMenu = (sideBarArray) => {
  //   return Object.assign([], sideBarArray, {
  //     0: {
  //       ...sideBarArray[0],
  //       children: sideBarArray[0]?.children.filter((item) => {
  //         if (item?.messageId?.toLowerCase() === 'prize draw') {
  //           return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
  //         } else {
  //           return item
  //         }
  //       }),
  //     },
  //   })
  // }

  const checkItemsInSideBarMenu = (sideBarArray, filterArray) => {
    const toFilterMenus = filterArray?.length ? filterArray : []
    return Object.assign([], sideBarArray, {
      0: {
        ...sideBarArray[0],
        children: sideBarArray[0]?.children.filter((item) => {
          if (
            toFilterMenus.some((filterItem) =>
              item?.messageId?.toLowerCase().includes(filterItem)
            )
          ) {
            return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
          } else {
            return item
          }
        }),
      },
    })
  }

  const checkMerchantInSideBarMenu = (sideBarArray) => {
    return Object.assign([], sideBarArray, {
      0: {
        ...sideBarArray[0],
        children: sideBarArray[0]?.children.filter((item) => {
          if (item?.messageId?.toLowerCase() === 'merchants') {
            return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
          } else {
            return item
          }
        }),
      },
    })
  }

  const checkSubcriptionId = (sideBarArray) => {
    return Object.assign([], sideBarArray, {
      0: {
        ...sideBarArray[0],
        children: sideBarArray[0]?.children.filter((item) => {
          if (
            (!userCountry?.stripeSubscriptionId ||
              userCountry?.stripeSubscriptionId === '') &&
            item?.title?.toLowerCase() === 'myaccountwithoutbill'
          ) {
            return item
          } else if (
            (!userCountry?.stripeSubscriptionId ||
              userCountry?.stripeSubscriptionId === '') &&
            item?.title?.toLowerCase() === 'myaccount'
          ) {
            return ''
          } else if (
            userCountry?.stripeSubscriptionId &&
            item?.title?.toLowerCase() === 'myaccountwithoutbill'
          ) {
            return ''
          } else {
            return item
          }
        }),
      },
    })
  }

  //to check supposed earning as issuer in sidebar
  const checkSupposedEarningIssuer = (sideBarArray) => {
    return Object.assign([], sideBarArray, {
      0: {
        ...sideBarArray[0],
        children: sideBarArray[0]?.children.filter((item) => {
          if (profileInfo?.isPremium) {
            if (item?.title?.toLowerCase() === 'friendshipearning') {
              return ''
            } else if (item?.title?.toLowerCase() === 'membershipearning') {
              return ''
            } else if (
              item?.title?.toLowerCase() ===
              'friendshipearningwithoutasanissuer'
            ) {
              return ''
            } else if (
              item?.title?.toLowerCase() ===
              'membershipearningwithoutasanissuer'
            ) {
              return ''
            } else if (
              item?.title?.toLowerCase() ===
              'friendshipearningwithoutsupposedissuer'
            ) {
              return item
            } else if (
              item?.title?.toLowerCase() ===
              'membershipearningwithoutsupposedissuer'
            ) {
              return item
            } else {
              return item
            }
          } else if (!profileInfo?.isPremium) {
            if (item?.title?.toLowerCase() === 'friendshipearning') {
              return ''
            } else if (item?.title?.toLowerCase() === 'membershipearning') {
              return ''
            } else if (
              item?.title?.toLowerCase() ===
              'friendshipearningwithoutasanissuer'
            ) {
              return item
            } else if (
              item?.title?.toLowerCase() ===
              'membershipearningwithoutasanissuer'
            ) {
              return item
            } else if (
              item?.title?.toLowerCase() ===
              'friendshipearningwithoutsupposedissuer'
            ) {
              return ''
            } else if (
              item?.title?.toLowerCase() ===
              'membershipearningwithoutsupposedissuer'
            ) {
              return ''
            } else {
              return item
            }
          } else {
            return item
          }
        }),
      },
    })
  }

  const checkPaymentAwaitingInSideBarMenu = (sideBarArray) => {
    return Object.assign([], sideBarArray, {
      0: {
        ...sideBarArray[0],
        children: sideBarArray[0]?.children.filter((item) => {
          if (userCountry?.paymentAwaiting == true) {
            if (item?.messageId?.toLowerCase() === 'merchants') {
              return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            } else if (item?.messageId?.toLowerCase() === 'members') {
              return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            } else if (item?.messageId?.toLowerCase() === 'charities') {
              return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            } else if (item?.messageId?.toLowerCase() === 'clubs') {
              return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            } else if (item?.messageId?.toLowerCase() === 'codes') {
              return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            }
            //  else if (
            //   item?.messageId?.toLowerCase() === 'friendship earning'
            // ) {
            //   return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            // }
            // else if (item?.messageId?.toLowerCase() === 'membership earning') {
            //   return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            // }
            else if (item?.messageId?.toLowerCase() === 'prize draw') {
              return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            } else if (
              item?.messageId?.toLowerCase() === 'package purchase history'
            ) {
              return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            } else if (item?.messageId?.toLowerCase() === 'merchant package') {
              return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
            } else {
              return item
            }
          } else {
            return item
          }
        }),
      },
    })
  }

  const checkAgreementSideBarMenu = (sideBarArray) => {
    return Object.assign([], sideBarArray, {
      0: {
        ...sideBarArray[0],
        children: sideBarArray[0]?.children.filter((item) => {
          if (item?.messageId?.toLowerCase() === 'agreement') {
            return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
          } else {
            return item
          }
        }),
      },
    })
  }

  const checkUserRoute = (data) => {
    if (data?.whiteLabelPosition?.isIntroducer) {
      return checkWhiteLabelSideBarMenu(
        checkAsIssuerInWhiteLabelSideBarMenu([
          ...whiteLabelAdminIntroducerRoutesConfig,
        ])
      )
    } else if (data?.whiteLabelPosition?.isBalancingLevel) {
      return checkWhiteLabelSideBarMenu(
        checkAsIssuerInWhiteLabelSideBarMenu([
          ...whiteLabelAdminBalancingFigureRoutesConfig,
        ])
      )
    } else {
      // check and remove user if isEndPositionUser is true
      if (data?.isEndPositionUser === true || userCountry?.allowPrizeDraw) {
        return checkWhiteLabelSideBarMenu(
          checkAsIssuerInWhiteLabelSideBarMenu(
            Object.assign([], whiteLabelAdminRoutesConfig, {
              0: {
                ...whiteLabelAdminRoutesConfig[0],
                children: whiteLabelAdminRoutesConfig[0]?.children.filter(
                  (item) => item?.title !== 'Users'
                ),
              },
            })
          )
        )
      } else {
        return checkWhiteLabelSideBarMenu(
          checkAsIssuerInWhiteLabelSideBarMenu([...whiteLabelAdminRoutesConfig])
        )
      }
    }
  }

  const checkUserRouteCountryAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }

    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkItemsInSideBarMenu(
      checkAgreementSideBarMenu([...countryAdminRoutesConfig]),
      filterArray
    )
  }

  const checkUserRouteAreaAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }

    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkItemsInSideBarMenu(
      checkAgreementSideBarMenu([...areaAdminRoutesConfig]),
      filterArray
    )
  }

  const checkUserRouteStateAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }

    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkItemsInSideBarMenu(
      checkAgreementSideBarMenu([...stateAdminRoutesConfig]),
      filterArray
    )
  }

  const checkUserRouteRegionAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }

    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkItemsInSideBarMenu(
      checkAgreementSideBarMenu([...regionAdminRoutesConfig]),
      filterArray
    )
  }

  const checkUserRouteCharityAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }

    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkItemsInSideBarMenu(
      checkMerchantCharityRouteAdmin([...charityAdminRoutesConfig]),
      filterArray
    )
  }

  const checkMerchantCharityRouteAdmin = (charityAdminRoutesConfig) => {
    if (userCountry?.canCreateMerchant) {
      return [...charityAdminRoutesConfig]
    } else {
      return checkMerchantInSideBarMenu([...charityAdminRoutesConfig])
    }
  }

  const checkMerchantClubRouteAdmin = (clubAdminRoutesConfig) => {
    if (userCountry?.canCreateMerchant) {
      return [...clubAdminRoutesConfig]
    } else {
      return checkMerchantInSideBarMenu([...clubAdminRoutesConfig])
    }
  }

  const checkUserRouteGMCAdmin = (userCountry) => {
    const filterArray = []

    if (!_.isEmpty(userCountry)) {
      if (!userCountry.allowPrizeDraw) {
        filterArray.push('prize draw')
      }
      if (!userCountry.allowDocumentCreate) {
        filterArray.push('document management')
      }
      if (!userCountry.allowCouponCreate) {
        filterArray.push('coupons')
      }
    }

    return checkItemsInSideBarMenu(
      checkAgreementSideBarMenu([...gmcAdminRoutesConfig]),
      filterArray
    )
  }

  const checkUserRouteClubAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }
    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkItemsInSideBarMenu(
      checkMerchantClubRouteAdmin([...clubAdminRoutesConfig]),
      filterArray
    )
  }

  const checkAsAnIssuerEarning = (sideBarArray) => {
    return Object.assign([], sideBarArray, {
      0: {
        ...sideBarArray[0],
        children: sideBarArray[0]?.children.filter((item) => {
          if (userCountry?.isIssuer) {
            if (item?.title?.toLowerCase() === 'friendshipearning') {
              return item
            } else if (
              item?.title?.toLowerCase() === 'friendshipearningwithoutissuer'
            ) {
              return ''
            } else if (item?.title?.toLowerCase() === 'membershipearning') {
              return item
            } else if (
              item?.title?.toLowerCase() === 'memberearningwithoutissuer'
            ) {
              return ''
            } else {
              return item
            }
          } else if (!userCountry?.isIssuer) {
            if (
              item?.title?.toLowerCase() === 'friendshipearningwithoutissuer'
            ) {
              return item
            } else if (
              item?.title?.toLowerCase() === 'memberearningwithoutissuer'
            ) {
              return item
            } else if (item?.title?.toLowerCase() === 'friendshipearning') {
              return ''
            } else if (item?.title?.toLowerCase() === 'membershipearning') {
              return ''
            } else {
              return item
            }
          } else {
            return item
          }
        }),
      },
    })
  }

  const checkUserRouteSalesAgentAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }
    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkAsAnIssuerEarning(
      checkItemsInSideBarMenu([...salesAgentRoutesConfig], filterArray)
    )
  }

  const checkUserRouteCompanyAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }
    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkAsAnIssuerEarning(
      checkItemsInSideBarMenu([...companyRoutesConfig], filterArray)
    )
  }

  const checkUserRouteSalesAgentCPAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }
    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }
    return checkItemsInSideBarMenu(
      checkPaymentAwaitingInSideBarMenu(
        userCountry?.paymentAwaiting == true
          ? [...paymentAwaitingCPRoutesConfig]
          : [...salesAgentCPRoutesConfig]
      ),
      filterArray
    )
  }

  const checkUserCollaborationParnterAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }

    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkItemsInSideBarMenu(
      [...collaborationParnterRoutesConfig],
      filterArray
    )
  }

  const checkUserMerchantAdmin = (userCountry) => {
    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }

    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkItemsInSideBarMenu(
      userCountry?.isTerminalUser
        ? [...terminalMerchantAdminRoutesConfig]
        : checkSupposedEarningIssuer(
            checkSubcriptionId([...merchantAdminRoutesConfig])
          ),
      filterArray
    )
  }

  // const checkTerminalUserInSideBarMenu = (sideBarArray) => {
  //   return Object.assign([], sideBarArray, {
  //     0: {
  //       ...sideBarArray[0],
  //       children: sideBarArray[0]?.children.filter((item) => {
  //         if (item?.messageId?.toLowerCase() === 'users') {
  //           return filterCountryAdmin.includes(item?.messageId?.toLowerCase())
  //         } else {
  //           return item
  //         }
  //       }),
  //     },
  //   })
  // }

  const checkUserWebsiteAdmin = (userCountry) => {
    // if (userCountry?.allowPrizeDraw) {
    //   return [...websiteAdminRoutesConfig]
    // } else {
    //   return checkPrizeDrawSideBarMenu([...websiteAdminRoutesConfig])
    // }

    const filterArray = []

    if (!userCountry.allowPrizeDraw) {
      filterArray.push('prize draw')
    }
    if (!userCountry.allowDocumentCreate) {
      filterArray.push('document management')
    }

    if (!userCountry.allowCouponCreate) {
      filterArray.push('coupons')
    }

    return checkItemsInSideBarMenu([...websiteAdminRoutesConfig], filterArray)
  }

  //makes sidebar empty
  // if (
  //   !(
  //     orgRole === 'admin' ||
  //     orgRole === 'globaladmin' ||
  //     orgRole === 'gmcuser' ||
  //     orgRole === 'websiteadmin'
  //   ) &&
  //   !agreedTermAndCondition &&
  //   role?.toLowerCase() === 'merchant' &&
  //   userCountry?.isTerminalUser == false
  // ) {
  //   return []
  // }

  if (
    !(
      orgRole === 'admin' ||
      orgRole === 'globaladmin' ||
      orgRole === 'gmcuser' ||
      orgRole === 'websiteadmin'
    ) &&
    !agreedTermAndCondition
    //!currentUser?.userCountry?.agreedTermAndCondition
  ) {
    if (orgRole === 'merchant' && userCountry?.isTerminalUser == false) {
      return []
    } else if (orgRole === 'merchant' && userCountry?.isTerminalUser == true) {
      return [...terminalMerchantAdminRoutesConfig]
    } else {
      return []
    }
  }

  switch (orgRole) {
    case 'admin':
      return [...setupRoutesConfig]
    case 'countryowner': {
      return checkUserRouteCountryAdmin(userCountry)
    }
    case 'gmcuser': {
      return checkUserRouteGMCAdmin(userCountry)
    }
    case 'areaowner': {
      return checkUserRouteAreaAdmin(userCountry)
    }
    case 'stateowner': {
      return checkUserRouteStateAdmin(userCountry)
    }
    case 'regionowner': {
      return checkUserRouteRegionAdmin(userCountry)
    }
    case 'charity': {
      return checkUserRouteCharityAdmin(userCountry)
    }
    case 'club': {
      return checkUserRouteClubAdmin(userCountry)
    }
    case 'salesagent': {
      return checkUserRouteSalesAgentAdmin(userCountry)
    }
    case 'collaborationpartner': {
      return checkUserRouteSalesAgentCPAdmin(userCountry)
    }
    // eslint-disable-next-line
    case 'collaborationpartner': {
      return checkUserCollaborationParnterAdmin(userCountry)
    }
    case 'whitelabeluser': {
      return checkUserRoute(whiteLabel)
    }
    case 'merchant': {
      return checkUserMerchantAdmin(userCountry)
    }
    //chek role
    case 'websiteadmin': {
      return checkUserWebsiteAdmin(userCountry)
    }

    case 'company': {
      return checkUserRouteCompanyAdmin(userCountry)
    }

    case 'globaladmin': {
      return _.isUndefined(userCountry?.id)
        ? [...setupRoutesConfig]
        : [...globalSetUpWithCountryConfig]
    }

    case 'merchantfranchisor':
      return [...MerchantFranchiseRoutesConfig]
    default:
      return []
    // return navigate('/error-pages/error-404')
  }
}

export { useRoutes }
