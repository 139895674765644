/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { fetchMemberStart } from 'store/members/membersSlice'
import {
  createStep1DraftStart,
  fetchMerchantDraftBasicInfoPanelUserStart,
  clearDraftData,
  clearGeneralData,
  sendMerchantEmailStart,
} from 'store/merchant/merchantSlice'
// import {
//   fetchCountryStart,
//   createCountryStart,
// } from '../../../store/countrySetup/countrySetupSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  areaOwner: state.areaOwner,
  stateOwners: state.stateOwner,
  charities: state.charities,
  club: state.club,
  members: state.members,
  merchant: state.merchant,
  siteCoordinator: state.siteCoordinator,
})
const mapDispatchToProps = (dispatch) => ({
  onFetchMemberStart: (payload) => dispatch(fetchMemberStart(payload)),
  onCreateStep1DraftStart: (payload) =>
    dispatch(createStep1DraftStart(payload)),
  onFetchMerchantDraftBasicInfoPanelUserStart: (payload) =>
    dispatch(fetchMerchantDraftBasicInfoPanelUserStart(payload)),

  onSendMerchantEmailStart: (payload) =>
    dispatch(sendMerchantEmailStart(payload)),
  onClearDraftData: () => dispatch(clearDraftData()),
  onClearGeneralData: () => dispatch(clearGeneralData()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
