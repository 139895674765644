/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import {
  fetchRegionStart,
  clearRegionDropDown,
} from 'store/dropDown/dropDownSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  dropDown: state.dropDown,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchRegionStart: (payload) => dispatch(fetchRegionStart(payload)),
  onClearRegionDropDown: () => dispatch(clearRegionDropDown()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
