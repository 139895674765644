import { axiosRequest } from 'utils'

export const fetchMembershipPackageList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/membershipPackage/getAllByAdmin`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipPackageById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/membershipPackage/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMembershipPackage = (params) => {
  return () =>
    axiosRequest
      .post(`/api/membershipPackage/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteMembershipPackage = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/membershipPackage/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMembershipPackage = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/membershipPackage/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
