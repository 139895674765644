import { createSlice } from '@reduxjs/toolkit'

import { ISettingsState } from '../interfaces'

const INITIAL_STATE: ISettingsState = {
  navCollapsed: false,
  initialPath: '/',
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
}

export const authSlice = createSlice({
  name: 'settings',
  initialState: INITIAL_STATE,
  reducers: {
    fetchStart: (state: any, action) => {
      state.loading = true
      state.error = ''
      state.message = ''
    },
    fetchSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.error = ''
      state.message = ''
      state.loading = false
      state.updatingContent = false
    },
    fetchError: (state: any, action) => {
      state.loading = false
      state.error = ''
      state.message = ''
      state.updatingContent = false
    },
    showMessage: (state: any, action) => {
      state.error = ''
      state.message = ''
      state.loading = action.payload
      state.updatingContent = action.payload
    },
    onToggleAppDrawer: (state: any) => {
      state.isAppDrawerOpen = !state.isAppDrawerOpen
    },
    hideMessage: (state: any) => {
      state.loading = false
      state.error = ''
      state.message = ''
      state.updatingContent = false
    },
    toggleNavCollapsed: (state: any) => {
      state.navCollapsed = !state.navCollapsed
    },
    onNavCollapsed: (state: any) => {
      state.navCollapsed = false
    },
    setInitialPath: (state: any) => {
      state.initialPath = ''
    },
  },
})

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  showMessage,
  onToggleAppDrawer,
  hideMessage,
  toggleNavCollapsed,
  onNavCollapsed,
  setInitialPath,
} = authSlice.actions

export default authSlice.reducer
