import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import AppDialog from 'components/DialogCustom'
import container from './Transfer.container'
import { Form, Formik, Field } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import * as yup from 'yup'
import RoleSelect from 'components/DropdownComponents/AssignCodesRoleSelect'
import { removeEmptyFields } from 'utils'
import UserCard from './UserCard'
import { useDispatch } from 'react-redux'
import { fetchMultipleAssignerByRoleSuccess } from 'store/merchant/merchantSlice'

interface AddPageProps {
  isAddPageOpen: boolean
  onCloseAddPage: () => void
  onCreatePageStart: (params) => void
  onOpenAlert: (params) => void
  siteCoordinator: any
  qrCodes: any
  merchant?: any
  batchId: number
  auth?: any
  selectedMerchant?: any
  refreshPageData?: any
}

const AssignDialog: React.FC<AddPageProps> = ({
  isAddPageOpen,
  onCloseAddPage,
  onCreatePageStart,
  merchant: { assignerListByRole },
  batchId,
  auth,
  selectedMerchant,
  refreshPageData,
}) => {
  const validationSchema = yup.object({})
  const [searchClicked, setSearchClicked] = useState(false)

  const closeModal = () => {
    onCloseAddPage()
  }
  const roleOptions = [
    { label: 'Country Licensee', value: 'countryOwner' },
    { label: 'State Licensee', value: 'stateOwner' },
    { label: 'Region Licensee', value: 'regionOwner' },
    { label: 'Area Licensee', value: 'areaOwner' },
    { label: 'Collaboration Partner', value: 'collaborationPartner' },
    { label: 'Sales Agent', value: 'salesAgent' },
    { label: 'Charity', value: 'charity' },
    { label: 'Club', value: 'club' },
    { label: 'WhiteLabelUser', value: 'whiteLabelUser' },
  ]
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchMultipleAssignerByRoleSuccess([]))
  }, [])

  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isAddPageOpen}
      onClose={() => onCloseAddPage()}
      title="Transfer Referred Merchant Request"
    >
      <Formik
        validateOnChange={true}
        initialValues={{
          email: '',
          role: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true)

          let formValues = removeEmptyFields({
            ...data,
            role: data?.role?.value,
            countryId: auth?.currentUser?.userCountry?.countryId,
          })

          if (
            data?.role?.value === 'collaborationPartner' ||
            data?.role?.value === 'salesAgent'
          ) {
            formValues.salesAgentType = data?.role?.value
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          onCreatePageStart({
            params: formValues,
            closeModal,
            batchId: batchId,
          }),
            setSearchClicked(true)
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, values, resetForm }) => (
          <Form>
            <Box sx={{ padding: 6 }}>
              <Grid container spacing={5}>
                {/* <Grid item xs={12} md={4}>
                  <Field
                    name="country"
                    label="Select Country"
                    required
                    component={CountrySelect}
                  />
                </Grid> */}
                <Grid item xs={12} md={4}>
                  <Field
                    name="role"
                    component={RoleSelect}
                    statusOption={roleOptions}
                    required
                    label="Select Role"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <AppTextField
                    label="Email"
                    name="email"
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      sx={{
                        color: '#ffffff',
                        backgroundColor: '#ec4785',
                        '&:hover': {
                          background: '#ec4785',
                        },
                        px: 5,
                      }}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Search
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      onClick={() => {
                        resetForm()
                        dispatch(fetchMultipleAssignerByRoleSuccess([]))
                        setSearchClicked(false)
                      }}
                      variant="outlined"
                    >
                      Clear
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mt: 8 }}>
                {assignerListByRole?.length !== 0 ? (
                  <Typography>Search Results </Typography>
                ) : (
                  searchClicked && (
                    <Typography textAlign="center">No results found</Typography>
                  )
                )}
                {assignerListByRole?.length
                  ? assignerListByRole?.map((user, ind) => {
                      return (
                        <UserCard
                          key={ind}
                          user={user}
                          selectedRole={values?.role?.value}
                          closeModal={closeModal}
                          selectedMerchant={selectedMerchant}
                          refreshPageData={refreshPageData}
                        />
                      )
                    })
                  : ''}
              </Box>

              {/* <Box>
                {qrCodes?.assignerList?.data?.map((user, ind) => {
                  return <Box>{JSON.stringify(user, null, 2)}</Box>
                })}
              </Box> */}

              <Box sx={{ height: 50 }} />
            </Box>
          </Form>
        )}
      </Formik>

      <Box sx={{ height: 200 }} />
    </AppDialog>
  )
}

export default container(AssignDialog)
