import { connect } from 'react-redux'

import {
  fetchPageByRoleAndCountryStart,
  eoiFetchPageByRoleAndCountryStart,
} from 'store/pageSetup/pageSetupSlice'
import { agreeTermAndConditionStart } from 'store/auth/authSlice'

const mapStateToProps = (state) => ({
  page: state.page,
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchPageByRoleStart: (payload) =>
    dispatch(fetchPageByRoleAndCountryStart(payload)),
  onEoiFetchPageByRoleAndCountryStart: (payload) =>
    dispatch(eoiFetchPageByRoleAndCountryStart(payload)),
  onAgreeTermAndConditionStart: (payload) =>
    dispatch(agreeTermAndConditionStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
