import React, { ReactNode } from 'react'
import { GrHome } from 'react-icons/gr'
import { FaUsers } from 'react-icons/fa'
// import { RoutePermittedRole } from '../shared/constants/AppConst';
import { RoutePermittedRole } from '../../shared/constants/AppConst'
import { MdOutlinePayment } from 'react-icons/md'
import { HeadsetMic, PlayArrow, Policy } from '@mui/icons-material'
import { FiUser } from 'react-icons/fi'
import LogoutIcon from '@mui/icons-material/Logout'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const gmcAdminRoutesConfig: RouterConfigData[] = [
  {
    id: 'gmcsetup',
    title: 'Setup',
    messageId: 'Gmc Panel',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            messageId: 'Dashboard',
            type: 'item',
            url: '/dashboard',
          },
        ],
      },

      {
        id: 'myAccount',
        title: 'myAccount',
        messageId: 'My Account',
        type: 'item',
        // icon: <PersonOutline />,
        icon: <FiUser />,
        url: '/my-account',
      },
      {
        id: 'collaborationpartner',
        title: 'Collaboration Partner',
        messageId: 'Collaboration Partner',
        type: 'collapse',
        icon: <FaUsers />,
        children: [
          {
            id: 'addcollaborationpartner',
            title: 'Add collaboration partner',
            messageId: 'Add collaboration partner',
            type: 'item',
            url: '/gmc/addcollaborationpartner',
          },
          {
            id: 'upgradecp',
            title: 'Upgrade to Collaboration Partner',
            messageId: 'Upgrade to CP',
            type: 'item',
            url: '/gmc/upgradetocp',
          },
          {
            id: 'cplist',
            title: 'Collaboration Partner List',
            messageId: 'Collaboration Partner List',
            type: 'item',
            url: '/gmc/collaborationpartnerslist',
          },
          {
            id: 'cpPurchaseHistory',
            title: 'CP Purchase History',
            messageId: 'CP Purchase History',
            type: 'item',
            url: '/gmc/cp-purchase-history',
          },
        ],
      },
      {
        id: 'gmcreferredcplist',
        title: 'Referred Collaboration Partners List',
        messageId: 'Referred CP',
        type: 'item',
        icon: <FaUsers />,
        url: '/gmc/referredcplist',
      },
      {
        id: 'QrCodes',
        title: 'QrCodes',
        messageId: 'QR Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'transactionQrCodes',
            title: 'Transaction QR Codes',
            messageId: 'Transaction QR Codes',
            type: 'item',
            url: '/gmc/transaction-qr-codes',
          },
          {
            id: 'issuerQrCodes',
            title: 'Issuer QR Codes',
            messageId: 'Issuer QR Codes',
            type: 'item',
            url: '/gmc/issuer-qr-codes',
          },
        ],
      },
      {
        icon: <PlayArrow />,
        id: 'training',
        title: 'training',
        messageId: 'Trainings',
        type: 'item',
        url: '/common/trainings',
        // url: '/training-videos',
      },
      {
        icon: <HeadsetMic />,
        id: 'support',
        title: 'support',
        messageId: 'Contact Piiink',
        type: 'item',
        url: '/support',
      },
      //panda docs section starts
      // {
      //   id: 'documentManagement',
      //   title: 'document Management',
      //   messageId: 'Document Management',
      //   type: 'collapse',
      //   icon: <ContentPasteIcon />,
      //   children: [
      //     // {
      //     //   id: 'smtp',
      //     //   title: 'SMTP',
      //     //   messageId: 'SMTP',
      //     //   type: 'item',
      //     //   url: '/gmc/setting/smtp',
      //     // },
      //     {
      //       id: 'group',
      //       title: 'Group',
      //       messageId: 'Group',
      //       type: 'item',
      //       url: '/gmc/setting/group',
      //     },

      //     {
      //       id: 'contacts',
      //       title: 'Contacts',
      //       messageId: 'Contacts',
      //       type: 'item',
      //       url: '/gmc/setting/contacts',
      //     },

      //     {
      //       id: 'documents',
      //       title: 'Documents',
      //       messageId: 'Documents',
      //       type: 'item',
      //       url: '/gmc/setting/documents',
      //     },

      //     {
      //       id: 'emails',
      //       title: 'Emails',
      //       messageId: 'Emails',
      //       type: 'item',
      //       url: '/gmc/setting/emails',
      //     },
      //   ],
      // },

      //panda docs section ends
      // {
      //   icon: <MonetizationOn />,
      //   id: 'prizedraw',
      //   title: 'prizedraw',
      //   messageId: 'Prize Draw',
      //   type: 'item',
      //   url: '/prizedraw',
      // },
      {
        icon: <Policy />,
        id: 'terms',
        title: 'terms',
        messageId: 'Terms & Conditions',
        type: 'item',
        url: '/terms',
      },
      // {
      //   icon: <ReceiptIcon />,
      //   id: 'coupons',
      //   title: 'coupons',
      //   messageId: 'Coupons',
      //   type: 'item',
      //   url: '/gmc/coupons',
      // },
      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },
    ],
  },
]
export default gmcAdminRoutesConfig
