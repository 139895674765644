import React from 'react'
import { Navigate, Route, Routes, useRoutes } from 'react-router-dom'
import AppSuspense from '@crema/core/AppSuspense'
import AppFooter from '../AppLayout/components/AppFooter'
import AppErrorBoundary from '../AppErrorBoundary'
import Box from '@mui/material/Box'
import AppContentViewWrapper from './AppContentViewWrapper'
import { SxProps } from '@mui/system'
// import { useAuthUser } from "../../utility/AuthHooks";
// import {
//   anonymousStructure,
//   authorizedStructure,
//   unAuthorizedStructure,
// } from '../../../routes'

import generateRoutes from '../../utility/RouteGenerator'
import { subDomainInitialUrl } from '../../../shared/constants/AppConst'
import { connect } from 'react-redux'
import useRoutesStructure from 'routes/routesStructure'

interface AppContentViewProps {
  sxStyle?: SxProps
  auth?: any
}

const AppContentView: React.FC<AppContentViewProps> = ({
  sxStyle,
  auth: { isAuthenticated, currentUser },
}) => {
  // const { user, isAuthenticated } = useAuthUser();

  const { subDominStructure } = useRoutesStructure()

  const routes = useRoutes(
    generateRoutes({
      isAuthenticated,
      userRole: currentUser?.role || '',
      anonymousStructure: subDominStructure,
    })
  )

  // const routes = useRoutes(
  //   generateRoutes({
  //     isAuthenticated,
  //     userRole: currentUser?.role || '',
  //     unAuthorizedStructure,
  //     authorizedStructure,
  //     anonymousStructure,
  //   })
  // )

  return (
    <AppContentViewWrapper>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          p: { xs: 5, md: 7.5, xl: 12.5 },
          ...sxStyle,
        }}
        className="app-content"
      >
        <AppSuspense>
          <AppErrorBoundary>
            {routes}
            <Routes>
              <Route path="/" element={<Navigate to={subDomainInitialUrl} />} />
            </Routes>
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
      <AppFooter />
    </AppContentViewWrapper>
  )
}
const mapStateToProps = (state, props) => ({
  settings: state.settings,
  auth: state.auth,
})

const container = connect(mapStateToProps, null)

export default container(AppContentView)
