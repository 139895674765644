/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { sendClubReqStart } from 'store/club/clubSlice'
import {
  clearGeneralData,
  fetchMerchantMultilingualListByPanelStart,
  addMerchantMultilingualStart,
  editMerchantMultilingualStart,
} from 'store/merchant/merchantSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
  merchant: state.merchant,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  clearGeneralData: () => dispatch(clearGeneralData()),
  onFetchMerchantMultilingualListByPanelStart: (payload) =>
    dispatch(fetchMerchantMultilingualListByPanelStart(payload)),
  onAddMerchantMultilingualStart: (payload) =>
    dispatch(addMerchantMultilingualStart(payload)),
  onEditMerchantMultilingualStart: (payload) =>
    dispatch(editMerchantMultilingualStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
