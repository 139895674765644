import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Edit } from '@mui/icons-material'
import DeleteModal from '@crema/core/AppConfirmDialog'
import { Tooltip, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import RejectReasonViewer from 'components/RejectReasonViewer'
import { truncateString } from 'utils'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  paddingLeft: 0,
  paddingTop: 9,
  paddingBottom: 9,
  lineHeight: 1.43,
  paddingRight: 20,
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  count: number
  pageNumber?: number
  rowsPerPage?: number
  onDeleteClubStart?: (payload: any) => void
  selectedDataToEdit?: any
  setSelectedDataToEdit?: any
}

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  pageNumber,
  rowsPerPage,
  onDeleteClubStart,
  setSelectedDataToEdit,
  selectedDataToEdit,
}) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [toDeleteId, setToDeleteId] = useState<number>(null)
  const [reason, setReason] = useState<string>('')

  const [openReasonModal, setOpenReasonModal] = useState<boolean>(false)

  const [rejectReason, setRejectReason] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState('')
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false)

  const [isDescriptionClicked, setDescriptionClicked] = useState<boolean>(false)

  const closeDescriptionModal = () => {
    setSelectedItem('')
    setOpenDescriptionModal(false)
  }

  const onDeleteSelectedContacts = () => {
    onDeleteClubStart({
      id: toDeleteId,
      rejectReason: reason,
      closeDeleteModal,
    })
    // setDeleteDialogOpen(false)
  }

  const closeDeleteModal = () => {
    setDeleteDialogOpen(false)
  }

  return (
    <>
      <DeleteModal
        open={isDeleteDialogOpen}
        onDeny={setDeleteDialogOpen}
        onConfirm={onDeleteSelectedContacts}
        title="Are you sure you want to delete this club?"
        dialogTitle="Delete Club"
      />
      {openDescriptionModal && (
        <ModalRenderer
          open={openDescriptionModal}
          onDeny={closeDescriptionModal}
          onConfirm={closeDescriptionModal}
          title=""
          dialogTitle={
            isDescriptionClicked ? 'Merchant Description' : 'Opening Hours'
          }
        >
          <Typography variant="body1">{selectedItem}</Typography>
        </ModalRenderer>
      )}

      {openReasonModal && (
        <RejectReasonViewer
          open={openReasonModal}
          onDeny={setOpenReasonModal}
          defaultValue={rejectReason}
          title="Rejection Reason"
          dialogTitle="Rejection Details"
        />
      )}
      <TableRow key={data.sn} className="item-hover">
        <StyledTableCell align="left">
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.countryLanguage
            ? `${data?.countryLanguage} (${data?.lang})`
            : '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.merchantName ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography
            variant="h5"
            color="#128ab3"
            sx={{ cursor: 'pointer' }}
            my={2}
            onClick={() => {
              setOpenDescriptionModal(true)
              setSelectedItem(data?.merchantDesciption)
              setDescriptionClicked(true)
            }}
          >
            {truncateString(data?.merchantDesciption, 20) ?? '-'}
          </Typography>{' '}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography
            variant="h5"
            color="#128ab3"
            sx={{ cursor: 'pointer' }}
            my={2}
            onClick={() => {
              setOpenDescriptionModal(true)
              setSelectedItem(data?.merchantOpeningHours)
              setDescriptionClicked(false)
            }}
          >
            {truncateString(data?.merchantOpeningHours, 20) ?? '-'}
          </Typography>{' '}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Tooltip placement="top" title="Edit">
            <IconButton>
              <Edit
                sx={{ fontSize: '1.3rem' }}
                onClick={() => setSelectedDataToEdit(data)}
              />
            </IconButton>
          </Tooltip>
        </StyledTableCell>
      </TableRow>
    </>
  )
}

export default TableItem
