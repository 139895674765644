import { axiosRequestCMS, axiosRequest } from 'utils'

export const fetchParentList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/menu/getAll?isActive=true`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
