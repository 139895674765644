import { createSlice } from '@reduxjs/toolkit'

import { IAuthPasswordReset } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IAuthPasswordReset = {
  isAuthenticated: false,
  tokenValidity: {},
  currentUser: {},
  newUser: {},
  error: null,
  loading: false,
}

export const passwordResetSlice = createSlice({
  name: 'passwordReset',
  initialState: INITIAL_STATE,
  reducers: {
    passwordResetStart: (state: any, action) => {
      state.loading = true
    },
    passwordResetSuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.currentUser = action.payload
      state.loading = false
    },
    passwordResetFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    checkResetTokenValidityStart: (state: any, action) => {
      state.loading = true
    },
    checkResetTokenValiditySuccess: (state: any, action) => {
      state.isAuthenticated = true
      state.tokenValidity = action.payload

      state.loading = false
    },
    checkResetTokenValidityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  passwordResetStart,
  passwordResetSuccess,
  passwordResetFailure,
  checkResetTokenValidityStart,
  checkResetTokenValiditySuccess,
  checkResetTokenValidityFailure,
} = passwordResetSlice.actions

export default passwordResetSlice.reducer
