import { Autocomplete, TextField } from '@mui/material'
import { FieldProps, getIn } from 'formik'
import React, { useEffect, useState } from 'react'

import container from './CountrySelect.container'

interface AddNewCountryProps {
  onFetchCountryStart: (payload: any) => void
  onFetchCountryStripeKeyStart?: (payload: any) => void
  onClearCountryStripeKey?: () => void
  onClearData?: () => void
  dropDown: any
  setSelectedCountry: any
  selectedCountry?: any
  defaultValue?: any
  clearFunction?: any
  isAssignedToOwner?: boolean
  allowClear?: boolean
  changeStripeKey?: boolean
  setFilterCountry?: any
  setFilterDates?: any
  // defaultSelected?: any
  type?: string
}

const CountrySelect: React.FC<FieldProps & AddNewCountryProps> = ({
  field,
  form,
  onFetchCountryStart,
  setSelectedCountry,
  selectedCountry,
  dropDown: { countryList },
  defaultValue,
  isAssignedToOwner,
  // defaultSelected,
  allowClear,
  clearFunction,
  type,
  changeStripeKey,
  onClearCountryStripeKey,
  onFetchCountryStripeKeyStart,
  setFilterCountry,
  setFilterDates,
  onClearData,
  ...props
}) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  useEffect(() => {
    if (isAssignedToOwner === false) {
      onFetchCountryStart({
        limit: 100,
        // countryId: defaultSelected?.id,
        isAssignedToOwner: false,
        type: type,
      })
    } else {
      onFetchCountryStart({
        limit: 100,
        type: type,
      })
    }
  }, [])

  if (allowClear) {
    setTimeout(async () => {
      const close = await document.getElementsByClassName(
        'MuiAutocomplete-clearIndicator'
      )[0]
      close.addEventListener('click', () => {
        clearFunction()
      })
    }, 100)
  }

  return (
    <Autocomplete
      {...props}
      {...field}
      // disablePortal
      id="combo-box-demo"
      size="small"
      value={field.value}
      getOptionLabel={(option) => (option ? option.label : '')}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(e, value) => {
        form.setFieldValue(field.name, value)
        if (changeStripeKey) {
          onClearCountryStripeKey()
          onFetchCountryStripeKeyStart(value?.id)
        }

        if (onClearData) {
          onClearData()
        }

        if (setFilterDates) {
          setFilterDates?.((val) => ({
            ...val,
            // to: moment(value).format('DD-MM-YYYY'),
            // to: value,
            filterCountry: value,
          }))
        }
      }}
      options={countryList}
      defaultValue={defaultValue ? defaultValue : null}
      // isOptionEqualToValue={defaultCountry ? defaultCountry : null}
      // value={selectedCountry ? selectedCountry : null}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={errorText?.value || errorText}
          error={!!errorText}
          label="Select Country"
        />
      )}
    />
  )
}

export default container(CountrySelect)
