import { createSlice } from '@reduxjs/toolkit'

import { IPrizeDraw } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IPrizeDraw = {
  prizeDrawListData: listObj,
  globalAdminPrizeDrawList: listObj,
  luckyDrawList: listObj,
  solePrizeDrawData: {},
  error: null,
  loading: false,
  isFetching: false,
  isCreating: false,
  isEditing: false,
  hasMoreData: false,
}

export const prizeDrawSlice = createSlice({
  name: 'prizeDraw',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPrizeDrawByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchPrizeDrawByPanelSuccess: (state: any, action) => {
      state.prizeDrawListData = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPrizeDrawByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminPrizeDrawStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminPrizeDrawSuccess: (state: any, action) => {
      state.globalAdminPrizeDrawList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchGlobalAdminPrizeDrawFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPrizeDrawByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPrizeDrawByIdSuccess: (state: any, action) => {
      state.solePrizeDrawData = action.payload.data
      state.isFetching = false
    },
    fetchPrizeDrawByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createPrizeDrawByPanelStart: (state: any, action) => {
      state.loading = true
      state.isCreating = true
    },
    createPrizeDrawByPanelSuccess: (state: any, action) => {
      state.loading = false
      state.isCreating = false
      state.prizeDrawListData.data = [
        ...state.prizeDrawListData.data,
        action.payload,
      ]
    },
    createPrizeDrawByPanelFailure: (state: any, action) => {
      state.loading = false
      state.isCreating = false
      state.error = action.payload
    },

    fetchLuckyDrawStart: (state: any, action) => {
      state.loading = true
    },

    fetchLuckyDrawSuccess: (state: any, action) => {
      state.luckyDrawList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchLuckyDrawFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    clearLuckyDrawStart: (state?: any) => {
      state.luckyDrawList = []
    },

    createLuckyDrawStart: (state: any, action) => {
      state.loading = true
      state.isCreating = true
    },
    createLuckyDrawSuccess: (state: any, action) => {
      state.loading = false
      state.luckyDrawList = action.payload
      state.isCreating = false
    },
    createLuckyDrawFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchPrizeDrawByPanelStart,
  fetchPrizeDrawByPanelSuccess,
  fetchPrizeDrawByPanelFailure,
  createPrizeDrawByPanelStart,
  createPrizeDrawByPanelSuccess,
  createPrizeDrawByPanelFailure,

  fetchGlobalAdminPrizeDrawStart,
  fetchGlobalAdminPrizeDrawSuccess,
  fetchGlobalAdminPrizeDrawFailure,

  fetchPrizeDrawByIdStart,
  fetchPrizeDrawByIdSuccess,
  fetchPrizeDrawByIdFailure,

  fetchLuckyDrawStart,
  fetchLuckyDrawSuccess,
  fetchLuckyDrawFailure,

  createLuckyDrawStart,
  createLuckyDrawSuccess,
  createLuckyDrawFailure,

  clearLuckyDrawStart,
} = prizeDrawSlice.actions

export default prizeDrawSlice.reducer
