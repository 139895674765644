import React, { useState } from 'react'
import {
  Stack,
  Button,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { transferReferredMerchantStart } from 'store/merchant/merchantSlice'
import { useDispatch } from 'react-redux'

const UserCard = ({
  user,
  selectedRole,
  closeModal,
  selectedMerchant,
  refreshPageData,
}: {
  user: any
  selectedRole: string
  selectedMerchant?: any
  refreshPageData?: any

  closeModal: () => void
}) => {
  const dispatch = useDispatch()

  return (
    <Card sx={{ mt: 5 }}>
      <List sx={{ width: '100%' }}>
        <ListItem>
          {/* <ListItemAvatar>
            <Avatar />
          </ListItemAvatar> */}
          <ListItemText
            primary={
              user?.salesAgentName ||
              user?.clubName ||
              user?.charityName ||
              user?.primaryContactName ||
              user?.username ||
              user?.firstName + user?.lastName
            }
            secondary={
              <>
                <Typography gutterBottom>{user?.email}</Typography>
                <Typography>{user?.phoneNumber}</Typography>
              </>
            }
          ></ListItemText>
          {/* <ListItemButton> */}
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={5}
            sx={{ width: 1 }}
          >
            <Button
              variant="contained"
              onClick={() => {
                dispatch(
                  transferReferredMerchantStart({
                    params: {
                      id: selectedMerchant?.id,
                      transferredToId: user?.id,
                      transferredToType: selectedRole,
                    },
                    closeModal,
                    refreshPageData,
                  })
                )
              }}
            >
              Transfer
            </Button>
          </Stack>
          {/* </ListItemButton> */}
        </ListItem>
      </List>
    </Card>
  )
}

export default UserCard
