import { axiosRequest } from 'utils'

export const fetchEmailTemplatesList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/emailTemplate/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchEmailTemplatesById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/emailTemplate/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createEmailTemplates = (params) => {
  return () =>
    axiosRequest
      .post(`/api/emailTemplate/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteEmailTemplates = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/emailTemplate/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editEmailTemplates = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/emailTemplate/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
