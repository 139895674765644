import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Divider,
} from '@mui/material'

import container from './Images.container'
import { useNavigate, useParams } from 'react-router-dom'

import _ from 'lodash'
import ImagesPreview from 'components/ImagePreview'
import PreviewSingle from 'components/ImagePreview/PreviewSingle'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onClearMerchantPremiumCode?: () => void
  onCreateClubStart?: (params) => void
  onCreateStep3DraftStart?: (params) => void
  onCreateStep3GeneralStart?: (params) => void
  onFetchMerchantDraftImagePanelUserStart?: (params) => void
  onOpenAlert?: (params) => void
  handleChangeTab?: any
  siteCoordinator?: any
  club?: any
  auth?: any
  merchant?: any
  adminRoute?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  onCloseAddCountry,
  onCreateStep3GeneralStart,
  onFetchMerchantDraftImagePanelUserStart,
  handleChangeTab,
  siteCoordinator: { recentMerchant },
  merchant: { isCreatingGeneral, draftImageInfo: generalImageInfo },
  adminRoute,
  onClearMerchantPremiumCode,
}) => {
  let { id } = useParams()
  const navigate = useNavigate()

  const [tempLoader, setTempLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  useEffect(() => {
    onFetchMerchantDraftImagePanelUserStart(id)
  }, [id])

  useEffect(() => {
    onClearMerchantPremiumCode()
  }, [])

  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [logoImage, setLogoImage] = useState(null)
  const [oldImages, setOldImages] = useState<any>({})
  const [loadingImages, setLoadingImages] = useState(false)
  const [isLoadingSaveAsDraft, setIsLoadingSaveAsDraft] = useState(false)
  const [isLoadingSaveAndContinue, setIsLoadingSaveAndContinue] =
    useState(false)

  const closeModal = () => {
    onCloseAddCountry()
  }

  useEffect(() => {
    if (!_.isEmpty(generalImageInfo) && id) {
      setLoadingImages(true)
      setTimeout(() => {
        setUploadedFiles(
          [
            generalImageInfo?.slider1,
            generalImageInfo?.slider2,
            generalImageInfo?.slider3,
            generalImageInfo?.slider4,
            generalImageInfo?.slider5,
            generalImageInfo?.slider6,
          ].filter((item) => item)
        )
        setLogoImage(generalImageInfo?.logoUrl)

        // for deleting from aws bucket
        setOldImages({
          sliderImage: [
            generalImageInfo?.slider1,
            generalImageInfo?.slider2,
            generalImageInfo?.slider3,
            generalImageInfo?.slider4,
            generalImageInfo?.slider5,
            generalImageInfo?.slider6,
          ],

          logoImage: generalImageInfo?.logoUrl,
        })
        setLoadingImages(false)
        // setLogoImage(generalImageInfo?.slider1)
      }, 2000)
    }
  }, [generalImageInfo])

  const handleSaveAsDraft = () => {
    setIsLoadingSaveAsDraft(true)
    onCreateStep3GeneralStart({
      sliderImage: uploadedFiles,
      logoImage,
      changeTab: () => navigate(`/${adminRoute}/draftList`),
      params: { merchantId: id ? id : recentMerchant?.id },
      oldImages,
      isDraft: true,
    })
    setTimeout(() => {
      setIsLoadingSaveAsDraft(false)
    }, 2000)
  }

  const handleSaveAndContinue = () => {
    // if (!logoImage) {
    //   return onOpenAlert({
    //     message: 'Logo required',
    //     severity: 'error',
    //   })
    // }

    // if (uploadedFiles?.filter((val) => val)?.length < 2) {
    //   return onOpenAlert({
    //     message: 'Upload at least two slider images',
    //     severity: 'error',
    //   })
    // }

    setIsLoadingSaveAndContinue(true)
    onCreateStep3GeneralStart({
      sliderImage: uploadedFiles,
      logoImage,
      changeTab: () => handleChangeTab(null, 3),
      params: { merchantId: id ? id : recentMerchant?.id },
      oldImages,
      refreshTab: true,
    })

    setTimeout(() => {
      setIsLoadingSaveAndContinue(false)
    }, 2000)
  }

  return (
    <>
      <Box sx={{ mt: 6 }}>
        <Box>
          <Typography display="inline" variant="h5">
            Upload Slider Images{' '}
          </Typography>
          <Typography display="inline" variant="h5">
            (Maximum 6)
          </Typography>
        </Box>

        {loadingImages && (
          <Box sx={{ mt: 12, textAlign: 'center' }}>
            {' '}
            <CircularProgress />{' '}
          </Box>
        )}

        <ImagesPreview files={uploadedFiles} setFiles={setUploadedFiles} />
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography display="inline" variant="h5" color="#57c5eb">
          Note: Images should be 2:1 ratio. Mininum pixels size should be 480px
          * 240px and maximum pixel size should be 1024px * 512px. Image size
          should be between 500KB and 1MB
        </Typography>
      </Box>
      {/* upload  slider image button  */}
      {uploadedFiles?.length < 6 && (
        <Box sx={{ mt: 12 }}>
          <Button variant="outlined" component="label" size="small">
            {uploadedFiles?.length ? 'Add More' : 'Upload'}
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => {
                setUploadedFiles((files) => [...files, e.target.files[0]])
              }}
            />
          </Button>
        </Box>
      )}

      <Divider sx={{ mt: 12 }} />

      {/* logo image preview */}
      <Box sx={{ mt: 12 }}>
        <Box>
          <Typography display="inline" variant="h5">
            Upload Logo{' '}
          </Typography>
        </Box>

        {loadingImages && (
          <Box sx={{ mt: 12, textAlign: 'center' }}>
            {' '}
            <CircularProgress />{' '}
          </Box>
        )}

        {logoImage && (
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <PreviewSingle
                file={logoImage}
                // file={uploadedFiles[0]}
                fileLabel="Logo Image "
                onChange={(e) => setLogoImage(e.target.files[0])}
                // onDelete={() => setLogoImage(null)}
              />
            </Grid>
          </Grid>
        )}
      </Box>

      <Box sx={{ mt: 5 }}>
        <Typography display="inline" variant="h5" color="#57c5eb">
          Note: Images should be 1:1 ratio. Image size should be between 500KB
          and 1MB
        </Typography>
      </Box>

      {/*upload logo image button */}
      <Box sx={{ mt: 6 }}>
        {!logoImage && (
          <Button variant="outlined" component="label" size="small">
            {'Upload'}
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => {
                setLogoImage(e.target.files[0])
              }}
            />
          </Button>
        )}
      </Box>

      {/* submit buttons */}
      <Box sx={{ height: 50 }} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{
            color: '#ffffff',
            display: 'flex',
            background: '#00bfcd',
            '&:hover': {
              background: '#ec4785',
            },
            px: 5,
          }}
          type="submit"
          disabled={isCreatingGeneral}
          onClick={handleSaveAsDraft}
        >
          {isCreatingGeneral ? <CircularProgress size={20} /> : 'Save as Draft'}
        </Button>
        &nbsp; &nbsp;
        <Button
          sx={{
            color: '#ffffff',
            display: 'flex',
            background: '#00bfcd',
            '&:hover': {
              background: '#ec4785',
            },
            px: 5,
          }}
          type="submit"
          onClick={handleSaveAndContinue}
          disabled={isCreatingGeneral}
        >
          {isCreatingGeneral ? (
            <CircularProgress size={20} />
          ) : (
            'Save & Continue'
          )}
        </Button>
        &nbsp; &nbsp;
        <Button
          onClick={() => navigate(`/${adminRoute}/draftList`)}
          variant="outlined"
        >
          Cancel
        </Button>
      </Box>
    </>
  )
}

export default container(AddNewClub)
