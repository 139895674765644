import { takeLatest, call, all, put, select } from 'redux-saga/effects'
import {
  fetchReviewTextListStart,
  fetchReviewTextListSuccess,
  fetchReviewTextListFailure,
  createReviewTextSuccess,
  createReviewTextFailure,
  fetchOneReviewTextByIdSuccess,
  fetchOneReviewTextByIdFailure,
  deleteReviewTextByIdSuccess,
  deleteReviewTextByIdFailure,
  activateDeactivateReviewTextByIdSuccess,
  activateDeactivateReviewTextByIdFailure,
  editReviewTextByIdSuccess,
  editReviewTextByIdFailure,
  fetchMerchantReviewListSuccess,
  fetchMerchantReviewListFailure,
  fetchMerchantReviewListByAdminSuccess,
  fetchMerchantReviewListByAdminFailure,
} from './reviewSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  activeDeactiveReviewText,
  createReviewText,
  deleteReviewText,
  editReviewText,
  fetchMerchantReviewList,
  fetchMerchantReviewListByAdmin,
  fetchOneReviewTextById,
  fetchReviewTextList,
} from '../../api/review'

import _ from 'lodash'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchReviewTextListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchReviewTextList(payload))

    yield put(fetchReviewTextListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchReviewTextListFailure(err))
    console.error(err)
  }
}

export function* createReviewTextAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createReviewText(payload?.params))

    if (payload?.closeModal) {
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(createReviewTextSuccess(data?.data))

    yield put(
      openAlert({
        message: 'Review Text Succesfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createReviewTextFailure(err))

    console.error(err)
  }
}

export function* fetchOneReviewTextByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchOneReviewTextById(payload))

    yield put(fetchOneReviewTextByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchOneReviewTextByIdFailure(err))
    console.error(err)
  }
}

export function* deleteReviewTextByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteReviewText(payload?.id))

    yield put(deleteReviewTextByIdSuccess(payload?.id))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield payload?.closeModal()
    yield put(
      openAlert({
        message: 'AutoText Successfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteReviewTextByIdFailure(err))
    console.error(err)
  }
}

export function* activateDeactivateReviewTextByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      activeDeactiveReviewText(payload?.id, { isActive: payload?.activeStatus })
    )

    yield put(activateDeactivateReviewTextByIdSuccess(payload?.id))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield payload?.closeModal()
    yield put(
      openAlert({
        message: `AutoText Successfully ${
          payload?.activeStatus ? 'Activated' : 'Deactivated'
        }`,
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(activateDeactivateReviewTextByIdFailure(err))
    console.error(err)
  }
}

export function* editReviewTextAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editReviewText(payload?.params?.id, payload?.params)
    )

    yield put(editReviewTextByIdSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Review Text Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editReviewTextByIdFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantReviewListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantReviewList(payload))

    yield put(fetchMerchantReviewListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchMerchantReviewListFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantReviewListByAdminAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantReviewListByAdmin(payload))

    yield put(fetchMerchantReviewListByAdminSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchMerchantReviewListByAdminFailure(err))
    console.error(err)
  }
}

export function* watchFetchReviewTextList() {
  yield takeLatest('review/fetchReviewTextListStart', fetchReviewTextListAsync)
}

export function* watchCreateReviewText() {
  yield takeLatest('review/createReviewTextStart', createReviewTextAsync)
}

export function* watchFetchOneReviewTextById() {
  yield takeLatest(
    'review/fetchOneReviewTextByIdStart',
    fetchOneReviewTextByIdAsync
  )
}

export function* watchDeleteReviewTextById() {
  yield takeLatest(
    'review/deleteReviewTextByIdStart',
    deleteReviewTextByIdAsync
  )
}

export function* watchActivateDeactivateReviewTextById() {
  yield takeLatest(
    'review/activateDeactivateReviewTextByIdStart',
    activateDeactivateReviewTextByIdAsync
  )
}

export function* watchEditReviewText() {
  yield takeLatest('review/editReviewTextByIdStart', editReviewTextAsync)
}

export function* watchFetchMerchantReviewList() {
  yield takeLatest(
    'review/fetchMerchantReviewListStart',
    fetchMerchantReviewListAsync
  )
}
export function* watchFetchMerchantReviewListByAdmin() {
  yield takeLatest(
    'review/fetchMerchantReviewListByAdminStart',
    fetchMerchantReviewListByAdminAsync
  )
}

export function* reviewSagas() {
  yield all([
    call(watchFetchReviewTextList),
    call(watchCreateReviewText),
    call(watchFetchOneReviewTextById),
    call(watchDeleteReviewTextById),
    call(watchActivateDeactivateReviewTextById),
    call(watchEditReviewText),
    call(watchFetchMerchantReviewList),
    call(watchFetchMerchantReviewListByAdmin),
    // call(watchEditCountry),
  ])
}
