import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchAppSliderStart,
  fetchAppSliderSuccess,
  fetchAppSliderFailure,
  createAppSliderSuccess,
  createAppSliderFailure,
  deleteAppSliderSuccess,
  deleteAppSliderFailure,
  fetchAppSliderByIdStart,
  fetchAppSliderByIdSuccess,
  fetchAppSliderByIdFailure,
  editAppSliderSuccess,
  editAppSliderFailure,
  fetchWebSliderStart,
  fetchWebSliderSuccess,
  fetchWebSliderFailure,
  createWebSliderStart,
  createWebSliderSuccess,
  createWebSliderFailure,
  deleteWebSliderStart,
  deleteWebSliderSuccess,
  deleteWebSliderFailure,
  fetchWebSliderByIdStart,
  fetchWebSliderByIdSuccess,
  fetchWebSliderByIdFailure,
  editWebSliderStart,
  editWebSliderSuccess,
  editWebSliderFailure,
  fetchHeaderMenuStart,
  fetchHeaderMenuSuccess,
  fetchHeaderMenuFailure,
  createHeaderMenuStart,
  createHeaderMenuSuccess,
  createHeaderMenuFailure,
  activeMenuItemStart,
  activeMenuItemSuccess,
  activeMenuItemFailure,
  deleteMenuItemStart,
  deleteMenuItemSuccess,
  deleteMenuItemFailure,
  fetchPageStart,
  fetchPageSuccess,
  fetchPageFailure,
  fetchPageByIdStart,
  fetchPageByIdSuccess,
  fetchPageByIdFailure,
  createPageStart,
  createPageSuccess,
  createPageFailure,
  deletePageStart,
  deletePageSuccess,
  deletePageFailure,
  editPageStart,
  editPageSuccess,
  editPageFailure,
  fetchTeamStart,
  fetchTeamSuccess,
  fetchTeamFailure,
  fetchTeamByIdStart,
  fetchTeamByIdSuccess,
  fetchTeamByIdFailure,
  createTeamStart,
  createTeamSuccess,
  createTeamFailure,
  deleteTeamStart,
  deleteTeamSuccess,
  deleteTeamFailure,
  editTeamStart,
  editTeamSuccess,
  editTeamFailure,
  fetchPartnerStart,
  fetchPartnerSuccess,
  fetchPartnerFailure,
  fetchPartnerByIdStart,
  fetchPartnerByIdSuccess,
  fetchPartnerByIdFailure,
  createPartnerStart,
  createPartnerSuccess,
  createPartnerFailure,
  deletePartnerStart,
  deletePartnerSuccess,
  deletePartnerFailure,
  editPartnerStart,
  editPartnerSuccess,
  editPartnerFailure,
  fetchClientStart,
  fetchClientSuccess,
  fetchClientFailure,
  fetchClientByIdStart,
  fetchClientByIdSuccess,
  fetchClientByIdFailure,
  createClientStart,
  createClientSuccess,
  createClientFailure,
  deleteClientStart,
  deleteClientSuccess,
  deleteClientFailure,
  editClientStart,
  editClientSuccess,
  editClientFailure,
  fetchTestamonialStart,
  fetchTestamonialSuccess,
  fetchTestamonialFailure,
  fetchTestamonialByIdStart,
  fetchTestamonialByIdSuccess,
  fetchTestamonialByIdFailure,
  createTestamonialStart,
  createTestamonialSuccess,
  createTestamonialFailure,
  deleteTestamonialStart,
  deleteTestamonialSuccess,
  deleteTestamonialFailure,
  editTestamonialStart,
  editTestamonialSuccess,
  editTestamonialFailure,
  fetchAboutUsStart,
  fetchAboutUsSuccess,
  fetchAboutUsFailure,
  fetchAboutUsByIdStart,
  fetchAboutUsByIdSuccess,
  fetchAboutUsByIdFailure,
  createAboutUsStart,
  createAboutUsSuccess,
  createAboutUsFailure,
  deleteAboutUsStart,
  deleteAboutUsSuccess,
  deleteAboutUsFailure,
  editAboutUsStart,
  editAboutUsSuccess,
  editAboutUsFailure,
  fetchSectionStart,
  fetchSectionSuccess,
  fetchSectionFailure,
  fetchSectionByIdStart,
  fetchSectionByIdSuccess,
  fetchSectionByIdFailure,
  createSectionStart,
  createSectionSuccess,
  createSectionFailure,
  deleteSectionStart,
  deleteSectionSuccess,
  deleteSectionFailure,
  editSectionStart,
  editSectionSuccess,
  editSectionFailure,
  fetchFaqStart,
  fetchFaqSuccess,
  fetchFaqFailure,
  fetchFaqByIdStart,
  fetchFaqByIdSuccess,
  fetchFaqByIdFailure,
  createFaqStart,
  createFaqSuccess,
  createFaqFailure,
  deleteFaqStart,
  deleteFaqSuccess,
  deleteFaqFailure,
  editFaqStart,
  editFaqSuccess,
  editFaqFailure,
  fetchCountryStart,
  fetchCountrySuccess,
  fetchCountryFailure,
  fetchCountryByIdStart,
  fetchCountryByIdSuccess,
  fetchCountryByIdFailure,
  createCountryStart,
  createCountrySuccess,
  createCountryFailure,
  deleteCountryStart,
  deleteCountrySuccess,
  deleteCountryFailure,
  changeStatusCountryStart,
  changeStatusCountrySuccess,
  changeStatusCountryFailure,
  editCountryStart,
  editCountrySuccess,
  editCountryFailure,
  fetchConstantStart,
  fetchConstantSuccess,
  fetchConstantFailure,
  fetchConstantByIdStart,
  fetchConstantByIdSuccess,
  fetchConstantByIdFailure,
  createConstantStart,
  createConstantSuccess,
  createConstantFailure,
  deleteConstantStart,
  deleteConstantSuccess,
  deleteConstantFailure,
  editConstantStart,
  editConstantSuccess,
  editConstantFailure,
  fetchMultilingualStart,
  fetchMultilingualSuccess,
  fetchMultilingualFailure,
  fetchMultilingualByIdStart,
  fetchMultilingualByIdSuccess,
  fetchMultilingualByIdFailure,
  createMultilingualStart,
  createMultilingualSuccess,
  createMultilingualFailure,
  deleteMultilingualStart,
  deleteMultilingualSuccess,
  deleteMultilingualFailure,
  editMultilingualStart,
  editMultilingualSuccess,
  editMultilingualFailure,
  fetchContentSectionStart,
  fetchContentSectionSuccess,
  fetchContentSectionFailure,
  fetchContentSectionByIdStart,
  fetchContentSectionByIdSuccess,
  fetchContentSectionByIdFailure,
  createContentSectionStart,
  createContentSectionSuccess,
  createContentSectionFailure,
  deleteContentSectionStart,
  deleteContentSectionSuccess,
  deleteContentSectionFailure,
  editContentSectionStart,
  editContentSectionSuccess,
  editContentSectionFailure,
  createSocialMediaSuccess,
  createSocialMediaFailure,
  fetchSocialMediaListSuccess,
  fetchSocialMediaListFailure,
  editSocialMediaSuccess,
  editSocialMediaFailure,
  deleteAboutUsBySlugSuccess,
  deleteAboutUsBySlugFailure,
  translateIntoCountryLanguageSuccess,
  translateIntoCountryLanguageFailure,
  createHomeVideoSuccess,
  createHomeVideoFailure,
  fetchAllHomeVideoSuccess,
  fetchAllHomeVideoFailure,
  editHomeVideoFailure,
  editHomeVideoSuccess,
  fetchAllFileRepositoryFailure,
  fetchAllFileRepositorySuccess,
  createFileRepositoryFailure,
  createFileRepositorySuccess,
  fetchFileRepositoryByIdSuccess,
  fetchFileRepositoryByIdFailure,
  editFileRepositorySuccess,
  editFileRepositoryFailure,
} from './websiteAdminSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchAppSliderList,
  createAppSlider,
  deleteAppSlider,
  editAppSlider,
  fetchAppSliderById,
} from 'api/websiteAdmin/slider'

import {
  fetchWebSliderList,
  createWebSlider,
  deleteWebSlider,
  editWebSlider,
  fetchWebSliderById,
  createHomeVideo,
  fetchAllHomeVideo,
  editHomeVideo,
  fetchAllFileRepository,
  createFileRepository,
  fetchFileRepositoryById,
  editFileRepository,
} from 'api/websiteAdmin/webSlider'

import {
  fetchHeaderMenuList,
  createHeaderMenu,
  activeMenuItem,
  deleteMenuItem,
} from 'api/websiteAdmin/HeaderMenu'
import {
  fetchPageList,
  createPage,
  deletePage,
  editPage,
  fetchPageById,
} from 'api/websiteAdmin/pages'

import {
  fetchContentSectionList,
  createContentSection,
  deleteContentSection,
  editContentSection,
  fetchContentSectionById,
} from 'api/websiteAdmin/contentSection'

import {
  fetchFaqList,
  createFaq,
  deleteFaq,
  editFaq,
  fetchFaqById,
} from 'api/websiteAdmin/faq'

import {
  fetchTeamList,
  createTeam,
  deleteTeam,
  editTeam,
  fetchTeamById,
} from 'api/websiteAdmin/team'

import {
  fetchPartnerList,
  createPartner,
  deletePartner,
  editPartner,
  fetchPartnerById,
} from 'api/websiteAdmin/partners'

import {
  fetchClientList,
  createClient,
  deleteClient,
  editClient,
  fetchClientById,
} from 'api/websiteAdmin/clients'

import {
  fetchTestamonialList,
  createTestamonial,
  deleteTestamonial,
  editTestamonial,
  fetchTestamonialById,
} from 'api/websiteAdmin/testamonial'

import {
  fetchSectionList,
  createSection,
  deleteSection,
  editSection,
  fetchSectionById,
} from 'api/websiteAdmin/section'

import {
  fetchAboutUsList,
  createAboutUs,
  deleteAboutUs,
  editAboutUs,
  fetchAboutUsById,
  deleteAboutUsBySlug,
} from 'api/websiteAdmin/aboutUs'

import {
  fetchCountryList,
  createCountry,
  deleteCountry,
  editCountry,
  changeStatusCountry,
  fetchCountryById,
  translateIntoCountryLanguage,
} from 'api/websiteAdmin/country'

import {
  fetchConstantList,
  deleteConstant,
  createConstant,
  editConstant,
  fetchConstantById,
} from 'api/websiteAdmin/constant'

import {
  fetchMultilingualList,
  deleteMultilingual,
  createMultilingual,
  editMultilingual,
  fetchMultilingualById,
} from 'api/websiteAdmin/multilingual'

import { getImageUrl, uploadImage, deleteImage } from 'api/imageUpload'
import _ from 'lodash'
import { removeParam, removeEmptyFields, getImageIdFromUrl } from 'utils'
import {
  createSocialMedia,
  editSocialMedia,
  fetchSocialMediaList,
} from 'api/websiteAdmin/socialMedia'

// slider sagas-----------------------------------------------------
export function* fetchAppSliderAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAppSliderList(payload))

    yield put(fetchAppSliderSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAppSliderFailure(err))
  }
}

export function* fetchAppSliderByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAppSliderById(payload))

    yield put(fetchAppSliderByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAppSliderByIdFailure(err))
  }
}

///app slider
export function* editAppSliderAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let url = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        url = removeParam(imageResponse.config.url)

        // console.log('check image url for edit app slider', url)
      }
      const { data } = yield call(
        editAppSlider(payload.params.id, { ...payload.params, url })
      )
      yield put(editAppSliderSuccess(data?.data))

      yield put(
        fetchAppSliderStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      if (_.isEmpty(payload?.params?.url)) {
        delete payload?.params.url
      }
      const { data } = yield call(
        editAppSlider(payload.params.id, payload?.params)
      )

      yield put(editAppSliderSuccess(data?.data))
      yield payload.closeModal()
      yield put(
        fetchAppSliderStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'App Slider successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editAppSliderFailure(err))
  }
}

export function* createAppSliderAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createAppSlider({ ...payload.params, url: imgUrl })
      )
      yield put(createAppSliderSuccess(data?.data))

      yield put(
        fetchAppSliderStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(createAppSlider(payload.params))

      yield put(createAppSliderSuccess(data?.data))
      yield put(
        fetchAppSliderStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'App Slider successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createAppSliderFailure(err))
  }
}

export function* deleteAppSliderAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteAppSlider(payload))

    if (data) {
      yield put(deleteAppSliderSuccess(payload))
      yield put(
        fetchAppSliderStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'App Slider deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteAppSliderFailure(err))
  }
}

// web slider sagas-----------------------------------------------------
export function* fetchWebSliderAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWebSliderList(payload))

    yield put(fetchWebSliderSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchWebSliderFailure(err))
  }
}

export function* fetchWebSliderByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWebSliderById(payload))

    yield put(fetchWebSliderByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchWebSliderByIdFailure(err))
  }
}

///
export function* editWebSliderAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let url = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        url = removeParam(imageResponse.config.url)

        // console.log('check image url for edit app slider', url)
      }
      const { data } = yield call(
        editWebSlider(
          payload.params.id,
          removeEmptyFields({ ...payload.params, sliderImage: url })
        )
      )
      yield put(editWebSliderSuccess(data?.data))

      yield put(
        fetchWebSliderStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        editWebSlider(payload.params.id, removeEmptyFields(payload?.params))
      )
      yield put(editWebSliderSuccess(data?.data))
      yield payload.closeModal()
      yield put(
        fetchWebSliderStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Slider successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editWebSliderFailure(err))
  }
}

export function* createWebSliderAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createWebSlider(
          removeEmptyFields({ ...payload.params, sliderImage: imgUrl })
        )
      )
      yield put(createWebSliderSuccess(data?.data))

      yield put(
        fetchWebSliderStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        removeEmptyFields(createWebSlider(payload.params))
      )

      yield put(createWebSliderSuccess(data?.data))
      // yield put(
      //   fetchWebSliderStart({
      //     page: 1,
      //     limit: 10,
      //     offset: 0,
      //   })
      // )
      if (payload?.refreshPageData) {
        yield payload?.refreshPageData()
      }
    }
    yield put(
      openAlert({
        message: 'Slider successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createWebSliderFailure(err))
  }
}

export function* deleteWebSliderAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteWebSlider(payload))

    if (data) {
      yield put(deleteWebSliderSuccess(payload))
      // yield put(
      //   fetchWebSliderStart({
      //     page: 1,
      //     limit: 10,
      //     offset: 0,
      //   }
      // )
      if (payload?.refreshPageData) {
        yield payload?.refreshPageData()
      }

      yield put(
        openAlert({
          message: 'Web Slider deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteWebSliderFailure(err))
  }
}
// menu sagas-----------------------------------------------------
export function* fetchHeaderMenuAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchHeaderMenuList(payload))
    yield put(fetchHeaderMenuSuccess(data))
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchHeaderMenuFailure(err))
  }
}

export function* createHeaderMenuAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createHeaderMenu(payload.params))

    yield put(createHeaderMenuSuccess(data?.data))
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    const inputString = data?.data?.menuSection
    const filtermenuSection = inputString.split(/(?=[A-Z])/)
    const displayMenuSection = filtermenuSection
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
    yield put(
      openAlert({
        message: `${displayMenuSection} successfully created`,
        severity: 'success',
      })
    )
    payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createHeaderMenuFailure(err))
  }
}

// export function* activeMenuItemAsync({ payload }: AnyAction) {
//   try {
//     const { data } = yield call(activeMenuItem(payload))
//     if (data) {
//       yield put(activeMenuItemSuccess(payload))
//       if (payload?.refreshPageData) {
//         yield payload?.refreshPageData()
//       }

//       yield put(
//         openAlert({
//           message: 'Menu item changed successfully ',
//           severity: 'success',
//         })
//       )
//     }
//   } catch (err) {
//     yield put(
//       openAlert({
//         message: err.message,
//         severity: 'error',
//       })
//     )
//     yield put(activeMenuItemFailure(err))
//   }
// }

export function* activeMenuItemAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(activeMenuItem(payload))
    if (data) {
      yield put(activeMenuItemSuccess(payload))
      if (payload?.refreshPageData) {
        yield payload?.refreshPageData()
      }
      yield put(
        openAlert({
          message: `MenuItem  ${
            payload.isActive ? 'Activated ' : 'Deactivated '
          } Successfully`,
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: `${err.message}`,
        severity: 'error',
      })
    )
    yield put(activeMenuItemFailure(err))
  }
}
export function* deleteMenuItemAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteMenuItem(payload?.toDeleteId))

    yield put(deleteMenuItemSuccess(payload))
    console.log('refreshData', payload?.refreshPageData)
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    // yield put(
    //   fetchHeaderMenuStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //   })
    // )
    yield put(
      openAlert({
        message: 'Menu Item deleted successfully ',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteMenuItemFailure(err))
  }
}
// page sagas-----------------------------------------------------
export function* fetchPageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPageList(payload))

    yield put(fetchPageSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPageFailure(err))
  }
}

export function* fetchPageByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPageById(payload))

    yield put(fetchPageByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPageByIdFailure(err))
  }
}

export function* editPageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editPage(payload?.id, payload?.params))
    yield put(editPageSuccess(data?.data))

    yield payload.closeModal()

    yield put(
      fetchPageStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Page successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editPageFailure(err))
  }
}

export function* createPageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createPage(payload.params))

    yield put(createPageSuccess(data?.data))

    yield put(
      fetchPageStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Page successfully created',
        severity: 'success',
      })
    )

    payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createPageFailure(err))
  }
}

export function* deletePageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deletePage(payload))

    if (data) {
      yield put(deletePageSuccess(payload))
      yield put(
        fetchPageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Page deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deletePageFailure(err))
  }
}

// faq sagas-----------------------------------------------------
export function* fetchFaqAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchFaqList(payload))

    yield put(fetchFaqSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchFaqFailure(err))
  }
}

export function* fetchFaqByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchFaqById(payload))

    yield put(fetchFaqByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchFaqByIdFailure(err))
  }
}

export function* editFaqAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editFaq(payload?.id, payload?.params))
    yield put(editFaqSuccess(data?.data))

    yield payload.closeModal()

    yield put(
      fetchFaqStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Faq successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editFaqFailure(err))
  }
}

export function* createFaqAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createFaq(payload.params))

    yield put(createFaqSuccess(data?.data))

    yield put(
      fetchFaqStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Faq successfully created',
        severity: 'success',
      })
    )

    payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createFaqFailure(err))
  }
}

export function* deleteFaqAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteFaq(payload))

    if (data) {
      yield put(deleteFaqSuccess(payload))
      yield put(
        fetchFaqStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Faq deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteFaqFailure(err))
  }
}

// team sagas-----------------------------------------------------
export function* fetchTeamAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTeamList(payload))

    yield put(fetchTeamSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTeamFailure(err))
  }
}

export function* fetchTeamByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTeamById(payload))

    yield put(fetchTeamByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTeamByIdFailure(err))
  }
}

export function* editTeamAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let url = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        url = removeParam(imageResponse.config.url)

        // console.log('check image url for edit app slider', url)
      }
      const { data } = yield call(
        editTeam(payload.params.id, { ...payload.params, teamImage: url })
      )
      yield put(editTeamSuccess(data?.data))

      yield put(
        fetchTeamStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(editTeam(payload.params.id, payload?.params))
      yield put(editTeamSuccess(data?.data))
      yield payload.closeModal()
      yield put(
        fetchTeamStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Team successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editTeamFailure(err))
  }
}

export function* createTeamAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createTeam(removeEmptyFields({ ...payload.params, teamImage: imgUrl }))
      )
      yield put(createTeamSuccess(data?.data))

      yield put(
        fetchTeamStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(createTeam(removeEmptyFields(payload.params)))

      yield put(createTeamSuccess(data?.data))
      yield put(
        fetchTeamStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Team successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createTeamFailure(err))
  }
}

export function* deleteTeamAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteTeam(payload))

    if (data) {
      yield put(deleteTeamSuccess(payload))
      yield put(
        fetchTeamStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Team deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteTeamFailure(err))
  }
}

// partners sagas-----------------------------------------------------
export function* fetchPartnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPartnerList(payload))

    yield put(fetchPartnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPartnerFailure(err))
  }
}

export function* fetchPartnerByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPartnerById(payload))

    yield put(fetchPartnerByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPartnerByIdFailure(err))
  }
}

export function* editPartnerAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let url = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        url = removeParam(imageResponse.config.url)

        // console.log('check image url for edit app slider', url)
      }
      const { data } = yield call(
        editPartner(payload.params.id, { ...payload.params, imageUrl: url })
      )
      yield put(editPartnerSuccess(data?.data))

      yield put(
        fetchPartnerStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        editPartner(payload.params.id, payload?.params)
      )
      yield put(editPartnerSuccess(data?.data))
      yield payload.closeModal()
      yield put(
        fetchPartnerStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Partner successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editPartnerFailure(err))
  }
}

export function* createPartnerAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createPartner(
          removeEmptyFields({ ...payload.params, imageUrl: imgUrl })
        )
      )
      yield put(createPartnerSuccess(data?.data))

      yield put(
        fetchPartnerStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        createPartner(removeEmptyFields(payload.params))
      )

      yield put(createPartnerSuccess(data?.data))
      yield put(
        fetchPartnerStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Parted successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createPartnerFailure(err))
  }
}

export function* deletePartnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deletePartner(payload))

    if (data) {
      yield put(deletePartnerSuccess(payload))
      yield put(
        fetchPartnerStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Partner deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deletePartnerFailure(err))
  }
}

// clients sagas-----------------------------------------------------
export function* fetchClientAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchClientList(payload))

    yield put(fetchClientSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchClientFailure(err))
  }
}

export function* fetchClientByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchClientById(payload))

    yield put(fetchClientByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchClientByIdFailure(err))
  }
}

export function* editClientAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let url = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        url = removeParam(imageResponse.config.url)

        // console.log('check image url for edit app slider', url)
      }
      const { data } = yield call(
        editClient(payload.params.id, { ...payload.params, imageUrl: url })
      )
      yield put(editClientSuccess(data?.data))

      yield put(
        fetchClientStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        editClient(payload.params.id, payload?.params)
      )
      yield put(editClientSuccess(data?.data))
      yield payload.closeModal()
      yield put(
        fetchClientStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Client successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editClientFailure(err))
  }
}

export function* createClientAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createClient(removeEmptyFields({ ...payload.params, imageUrl: imgUrl }))
      )
      yield put(createClientSuccess(data?.data))

      yield put(
        fetchClientStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        createClient(removeEmptyFields(payload.params))
      )

      yield put(createClientSuccess(data?.data))
      yield put(
        fetchClientStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Client successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createClientFailure(err))
  }
}

export function* deleteClientAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteClient(payload))

    if (data) {
      yield put(deleteClientSuccess(payload))
      yield put(
        fetchClientStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Client deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteClientFailure(err))
  }
}

// ContentSection sagas-----------------------------------------------------
export function* fetchContentSectionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchContentSectionList(payload))

    yield put(fetchContentSectionSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchContentSectionFailure(err))
  }
}

export function* fetchContentSectionByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchContentSectionById(payload))

    yield put(fetchContentSectionByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchContentSectionByIdFailure(err))
  }
}

export function* editContentSectionAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (payload?.params?.imageUrl?.size) {
      let url = ''
      const initialImageUrl = yield call(getImageUrl())

      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.imageUrl)
        )

        url = removeParam(imageResponse.config.url)

        // console.log('check image url for edit app slider', url)
      }

      if (payload?.oldImage) {
        yield call(deleteImage(getImageIdFromUrl(payload?.oldImage)))
      }

      const { data } = yield call(
        editContentSection(payload?.id, {
          ...payload.params,
          imageUrl: url,
        })
      )

      yield put(editContentSectionSuccess(data?.data))
    } else {
      const { data } = yield call(
        editContentSection(payload?.id, {
          ...payload?.params,
          imageUrl: payload?.params?.imageUrl?.preview ?? '',
        })
      )
      yield put(editContentSectionSuccess(data?.data))
    }

    if (payload?.closeModal) {
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    } else {
      yield put(
        fetchContentSectionStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }

    yield put(
      openAlert({
        message: 'Content Section successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editContentSectionFailure(err))
  }
}

export function* createContentSectionAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.imageUrl)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.imageUrl)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createContentSection(
          removeEmptyFields({ ...payload.params, imageUrl: imgUrl })
        )
      )

      yield put(createContentSectionSuccess(data?.data))
    } else {
      const { data } = yield call(createContentSection(payload?.params))
      yield put(createContentSectionSuccess(data?.data))
    }

    yield put(
      fetchPageStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Content Section successfully created',
        severity: 'success',
      })
    )
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createContentSectionFailure(err))
  }
}

export function* deleteContentSectionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteContentSection(payload))

    if (data) {
      yield put(deleteContentSectionSuccess(payload))
      yield put(
        fetchContentSectionStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Content Section deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteContentSectionFailure(err))
  }
}

// testamonial sagas-----------------------------------------------------
export function* fetchTestamonialAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTestamonialList(payload))

    yield put(fetchTestamonialSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTestamonialFailure(err))
  }
}

export function* fetchTestamonialByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTestamonialById(payload))

    yield put(fetchTestamonialByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTestamonialByIdFailure(err))
  }
}

export function* editTestamonialAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let url = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        url = removeParam(imageResponse.config.url)

        // console.log('check image url for edit app slider', url)
      }
      const { data } = yield call(
        editTestamonial(
          payload.params.id,
          removeEmptyFields({ ...payload.params, imageUrl: url })
        )
      )
      yield put(editTestamonialSuccess(data?.data))

      yield put(
        fetchTestamonialStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        editTestamonial(payload.params.id, removeEmptyFields(payload?.params))
      )
      yield put(editTestamonialSuccess(data?.data))
      yield payload.closeModal()
      yield put(
        fetchTestamonialStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Testimonial successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editTestamonialFailure(err))
  }
}

export function* createTestamonialAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createTestamonial(
          removeEmptyFields({ ...payload.params, imageUrl: imgUrl })
        )
      )
      yield put(createTestamonialSuccess(data?.data))

      yield put(
        fetchTestamonialStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        createTestamonial(removeEmptyFields(payload.params))
      )

      yield put(createTestamonialSuccess(data?.data))
      yield put(
        fetchTestamonialStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Testimonial successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createTestamonialFailure(err))
  }
}

export function* deleteTestamonialAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteTestamonial(payload))

    if (data) {
      yield put(deleteTestamonialSuccess(payload))
      yield put(
        fetchTestamonialStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Testamonial deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteTestamonialFailure(err))
  }
}

//section sagas-----------------------------

export function* fetchSectionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSectionList(payload))

    yield put(fetchSectionSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchSectionFailure(err))
  }
}

export function* fetchSectionByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSectionById(payload))

    yield put(fetchSectionByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchSectionByIdFailure(err))
  }
}

export function* editSectionAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    let imgUrl = payload?.params?.imgUrl?.preview
    let sectionOneImageUrl = payload?.params?.sectionOneImg?.preview
    let sectionTwoImageUrl =
      Object.keys(payload?.params?.sectionTwoImg).length === 0
        ? null
        : payload?.params?.sectionTwoImg?.preview
    let sectionThreeImageUrl =
      Object.keys(payload?.params?.sectionThreeImg).length === 0
        ? null
        : payload?.params?.sectionThreeImg?.preview

    if (!_.isEmpty(payload?.params?.imgUrl) && payload?.params?.imgUrl?.path) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload?.params?.imgUrl)
      )
      if (!_.isEmpty(payload?.params?.oldImgUrl)) {
        yield call(deleteImage(getImageIdFromUrl(payload?.params?.oldImgUrl)))
      }

      imgUrl = removeParam(imageResponse.config.url)
    }

    if (
      !_.isEmpty(payload?.params?.sectionOneImg) &&
      payload?.params?.sectionOneImg?.path
    ) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload?.params?.sectionOneImg)
      )
      if (!_.isEmpty(payload?.params?.oldSectionOneImg)) {
        yield call(
          deleteImage(getImageIdFromUrl(payload?.params?.oldSectionOneImg))
        )
      }
      sectionOneImageUrl = removeParam(imageResponse.config.url)
    }

    if (
      !_.isEmpty(payload?.params?.sectionTwoImg) &&
      payload?.params?.sectionTwoImg?.path
    ) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload?.params?.sectionTwoImg)
      )
      if (!_.isEmpty(payload?.params?.oldSectionTwoImg)) {
        yield call(
          deleteImage(getImageIdFromUrl(payload?.params?.oldSectionTwoImg))
        )
      }
      sectionTwoImageUrl = removeParam(imageResponse.config.url)
    }

    if (
      !_.isEmpty(payload?.params?.sectionThreeImg) &&
      payload?.params?.sectionThreeImg?.path
    ) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(
          initialImageUrl?.data?.url,
          payload?.params?.sectionThreeImg
        )
      )
      if (!_.isEmpty(payload?.params?.oldSectionThreeImg)) {
        yield call(
          deleteImage(getImageIdFromUrl(payload?.params?.oldSectionThreeImg))
        )
      }
      sectionThreeImageUrl = removeParam(imageResponse.config.url)
    }

    const { data } = yield call(
      editSection(payload.id, {
        ...payload.params,
        imageUrl: imgUrl,
        sectionOneImage: sectionOneImageUrl,
        sectionTwoImage: sectionTwoImageUrl,
        sectionThreeImage: sectionThreeImageUrl,
      })
    )
    yield put(editSectionSuccess(data?.data))
    yield payload.closeModal()
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    yield put(
      openAlert({
        message: 'Section successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editSectionFailure(err))
  }
}

export function* createSectionAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    let imgUrl = null
    let sectionOneImageUrl = null
    let sectionTwoImageUrl = null
    let sectionThreeImageUrl = null

    if (!_.isEmpty(payload?.params?.imgUrl)) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload?.params?.imgUrl)
      )
      imgUrl = removeParam(imageResponse.config.url)
    }

    if (!_.isEmpty(payload?.params?.sectionOneImg)) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload?.params?.sectionOneImg)
      )
      sectionOneImageUrl = removeParam(imageResponse.config.url)
    }

    if (!_.isEmpty(payload?.params?.sectionTwoImg)) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload?.params?.sectionTwoImg)
      )
      sectionTwoImageUrl = removeParam(imageResponse.config.url)
    }

    if (!_.isEmpty(payload?.params?.sectionThreeImg)) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(
          initialImageUrl?.data?.url,
          payload?.params?.sectionThreeImg
        )
      )
      sectionThreeImageUrl = removeParam(imageResponse.config.url)
    }

    const { data } = yield call(
      createSection(
        removeEmptyFields({
          ...payload.params,
          imageUrl: imgUrl,
          sectionOneImage: sectionOneImageUrl,
          sectionTwoImage: sectionTwoImageUrl,
          sectionThreeImage: sectionThreeImageUrl,
        })
      )
    )
    yield put(createSectionSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      fetchSectionStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Section successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createSectionFailure(err))
  }
}

export function* deleteSectionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteSection(payload))

    if (data) {
      yield put(deleteSectionSuccess(payload))
      yield put(
        fetchSectionStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Testamonial deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteSectionFailure(err))
  }
}

//section sagas end-----------------------------

//abount Us sagas-----------------------------

export function* fetchAboutUsAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAboutUsList(payload))

    yield put(fetchAboutUsSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAboutUsFailure(err))
  }
}

export function* fetchAboutUsByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAboutUsById(payload))

    yield put(fetchAboutUsByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAboutUsByIdFailure(err))
  }
}

export function* editAboutUsAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    let imgUrl = payload?.params?.imgUrl?.preview
    let sliderUrls = []
    let sliderUrlsObj = {}
    let toDeleteIds = {}

    if (!_.isEmpty(payload?.params?.imgUrl) && payload?.params?.imgUrl?.path) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload?.params?.imgUrl)
      )
      if (!_.isEmpty(payload?.params?.oldImgUrl)) {
        yield call(deleteImage(getImageIdFromUrl(payload?.params?.oldImgUrl)))
      }
      imgUrl = removeParam(imageResponse.config.url)
    }

    delete payload?.params?.imgUrl

    for (let i = 0; i < payload?.params?.list?.length; i++) {
      if (
        payload.params?.list[i]?.aboutlisImageUrl !== undefined &&
        payload.params?.list[i]?.aboutlisImageUrl[0]?.size
      ) {
        const initialImageUrl = yield call(getImageUrl())

        if (!_.isEmpty(initialImageUrl?.data)) {
          const imageResponse = yield call(
            uploadImage(
              initialImageUrl?.data?.url,
              payload.params?.list[i]?.aboutlisImageUrl[0]
            )
          )

          sliderUrls[i] = removeParam(imageResponse.config.url)

          if (payload?.oldListData?.[i]?.aboutlisImageUrl) {
            yield call(
              deleteImage(
                getImageIdFromUrl(payload?.oldListData?.[i]?.aboutlisImageUrl)
              )
            )
          }
        }
      } else if (payload.params?.list[i]?.aboutlisImageUrl === undefined) {
        toDeleteIds = { ...toDeleteIds, ...payload.params?.list[i]?.id }
        Object.assign(toDeleteIds, {
          [payload.params?.list[i]?.id]: null,
        })
      }
    }

    sliderUrlsObj = Object.assign({}, sliderUrls)

    const { data } = yield call(
      editAboutUs(payload.id, {
        ...payload.params,
        imageUrl: imgUrl,
        list: payload?.params?.list?.map((item, index) => {
          return {
            ...item,
            aboutlisImageUrl: toDeleteIds[item?.id]
              ? ''
              : item?.aboutlisImageUrl?.[0]?.size
              ? sliderUrlsObj[index]
              : item?.aboutlisImageUrl,
          }
        }),
      })
    )
    yield payload.closeModal()
    yield put(editAboutUsSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    yield put(
      openAlert({
        message: 'About Us successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editAboutUsFailure(err))
  }
}

export function* createAboutUsAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    let imgUrl = null
    let sliderUrls = []
    let sliderUrlsObj = {}

    if (!_.isEmpty(payload?.params?.imgUrl)) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload?.params?.imgUrl)
      )
      imgUrl = removeParam(imageResponse.config.url)
    }

    delete payload?.params?.imgUrl

    for (let i = 0; i < payload?.params?.list?.length; i++) {
      if (payload.params?.list[i]?.aboutlisImageUrl[0]?.size) {
        const initialImageUrl = yield call(getImageUrl())

        if (!_.isEmpty(initialImageUrl?.data)) {
          const imageResponse = yield call(
            uploadImage(
              initialImageUrl?.data?.url,
              payload.params?.list[i]?.aboutlisImageUrl[0]
            )
          )

          sliderUrls[i] = removeParam(imageResponse.config.url)

          // if (payload?.oldImages?.[i]) {
          //   yield call(deleteImage(getImageIdFromUrl(payload?.oldImages[i])))
          // }
        }
      }
    }
    sliderUrlsObj = Object.assign({}, sliderUrls)

    const { data } = yield call(
      createAboutUs(
        removeEmptyFields({
          ...payload.params,
          imageUrl: imgUrl,
          list: payload?.params?.list?.map((item, index) => {
            return { ...item, aboutlisImageUrl: sliderUrlsObj[index] }
          }),
        })
      )
    )
    yield put(createAboutUsSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    yield put(
      fetchAboutUsStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'About Us successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createAboutUsFailure(err))
  }
}

export function* deleteAboutUsAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteAboutUs(payload))

    if (data) {
      yield put(deleteSectionSuccess(payload))
      yield put(
        fetchAboutUsStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'About Us deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteAboutUsFailure(err))
  }
}

export function* createHomeVideoAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )

        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createHomeVideo({ ...payload.params, videoLink: imgUrl })
      )
      yield put(createHomeVideoSuccess(data?.data))
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    yield put(
      openAlert({
        message: 'Home video successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createHomeVideoFailure(err))
  }
}

export function* editHomeVideoAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.url)
        )

        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        editHomeVideo(payload?.params?.id, {
          ...payload.params,
          videoLink: imgUrl,
        })
      )
      yield call(deleteImage(getImageIdFromUrl(payload?.oldLink)))

      yield put(editHomeVideoSuccess(data?.data))
    } else {
      const uploadData = _.isObject(payload?.params?.url)
        ? { ...payload.params }
        : { ...payload.params, videoLink: '' }

      const { data } = yield call(
        editHomeVideo(payload?.params?.id, uploadData)
      )
      yield put(editHomeVideoSuccess(data?.data))
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    yield put(
      openAlert({
        message: 'Home video successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editHomeVideoFailure(err))
  }
}

//section sagas end-----------------------------

// slider watchers
export function* watchFetchAppSlider() {
  yield takeLatest('websiteAdmin/fetchAppSliderStart', fetchAppSliderAsync)
}

export function* watchCreateAppSlider() {
  yield takeLatest('websiteAdmin/createAppSliderStart', createAppSliderAsync)
}

export function* watchDeleteAppSlider() {
  yield takeLatest('websiteAdmin/deleteAppSliderStart', deleteAppSliderAsync)
}

export function* watchFetchAppSliderById() {
  yield takeLatest(
    'websiteAdmin/fetchAppSliderByIdStart',
    fetchAppSliderByIdAsync
  )
}

export function* watchEditAppSlider() {
  yield takeLatest('websiteAdmin/editAppSliderStart', editAppSliderAsync)
}

// web slider watchers
export function* watchFetchWebSlider() {
  yield takeLatest('websiteAdmin/fetchWebSliderStart', fetchWebSliderAsync)
}

export function* watchCreateWebSlider() {
  yield takeLatest('websiteAdmin/createWebSliderStart', createWebSliderAsync)
}

export function* watchDeleteWebSlider() {
  yield takeLatest('websiteAdmin/deleteWebSliderStart', deleteWebSliderAsync)
}

export function* watchFetchWebSliderById() {
  yield takeLatest(
    'websiteAdmin/fetchWebSliderByIdStart',
    fetchWebSliderByIdAsync
  )
}

export function* watchEditWebSlider() {
  yield takeLatest('websiteAdmin/editWebSliderStart', editWebSliderAsync)
}

//HeaderMenu watchers
export function* watchFetchHeaderMenu() {
  yield takeLatest('websiteAdmin/fetchHeaderMenuStart', fetchHeaderMenuAsync)
}
export function* watchCreateHeaderMenu() {
  yield takeLatest('websiteAdmin/createHeaderMenuStart', createHeaderMenuAsync)
}
export function* watchActiveMenuItem() {
  yield takeLatest('websiteAdmin/activeMenuItemStart', activeMenuItemAsync)
}
export function* watchDeleteMenuItem() {
  yield takeLatest('websiteAdmin/deleteMenuItemStart', deleteMenuItemAsync)
}
// page watchers
export function* watchFetchPage() {
  yield takeLatest('websiteAdmin/fetchPageStart', fetchPageAsync)
}

export function* watchCreatePage() {
  yield takeLatest('websiteAdmin/createPageStart', createPageAsync)
}

export function* watchDeletePage() {
  yield takeLatest('websiteAdmin/deletePageStart', deletePageAsync)
}

export function* watchFetchPageById() {
  yield takeLatest('websiteAdmin/fetchPageByIdStart', fetchPageByIdAsync)
}

export function* watchEditPage() {
  yield takeLatest('websiteAdmin/editPageStart', editPageAsync)
}

// content Section watchers
export function* watchFetchContentSection() {
  yield takeLatest(
    'websiteAdmin/fetchContentSectionStart',
    fetchContentSectionAsync
  )
}

export function* watchCreateContentSection() {
  yield takeLatest(
    'websiteAdmin/createContentSectionStart',
    createContentSectionAsync
  )
}

export function* watchDeleteContentSection() {
  yield takeLatest(
    'websiteAdmin/deleteContentSectionStart',
    deleteContentSectionAsync
  )
}

export function* watchFetchContentSectionById() {
  yield takeLatest(
    'websiteAdmin/fetchContentSectionByIdStart',
    fetchContentSectionByIdAsync
  )
}

export function* watchEditContentSection() {
  yield takeLatest(
    'websiteAdmin/editContentSectionStart',
    editContentSectionAsync
  )
}

// faq watchers
export function* watchFetchFaq() {
  yield takeLatest('websiteAdmin/fetchFaqStart', fetchFaqAsync)
}

export function* watchCreateFaq() {
  yield takeLatest('websiteAdmin/createFaqStart', createFaqAsync)
}

export function* watchDeleteFaq() {
  yield takeLatest('websiteAdmin/deleteFaqStart', deleteFaqAsync)
}

export function* watchFetchFaqById() {
  yield takeLatest('websiteAdmin/fetchFaqByIdStart', fetchFaqByIdAsync)
}

export function* watchEditFaq() {
  yield takeLatest('websiteAdmin/editFaqStart', editFaqAsync)
}

// team watchers
export function* watchFetchTeam() {
  yield takeLatest('websiteAdmin/fetchTeamStart', fetchTeamAsync)
}

export function* watchCreateTeam() {
  yield takeLatest('websiteAdmin/createTeamStart', createTeamAsync)
}

export function* watchDeleteTeam() {
  yield takeLatest('websiteAdmin/deleteTeamStart', deleteTeamAsync)
}

export function* watchFetchTeamById() {
  yield takeLatest('websiteAdmin/fetchTeamByIdStart', fetchTeamByIdAsync)
}

export function* watchEditTeam() {
  yield takeLatest('websiteAdmin/editTeamStart', editTeamAsync)
}

// partners watchers
export function* watchFetchPartner() {
  yield takeLatest('websiteAdmin/fetchPartnerStart', fetchPartnerAsync)
}

export function* watchCreatePartner() {
  yield takeLatest('websiteAdmin/createPartnerStart', createPartnerAsync)
}

export function* watchDeletePartner() {
  yield takeLatest('websiteAdmin/deletePartnerStart', deletePartnerAsync)
}

export function* watchFetchPartnerById() {
  yield takeLatest('websiteAdmin/fetchPartnerByIdStart', fetchPartnerByIdAsync)
}

export function* watchEditPartner() {
  yield takeLatest('websiteAdmin/editPartnerStart', editPartnerAsync)
}

// clients watchers
export function* watchFetchClient() {
  yield takeLatest('websiteAdmin/fetchClientStart', fetchClientAsync)
}

export function* watchCreateClient() {
  yield takeLatest('websiteAdmin/createClientStart', createClientAsync)
}

export function* watchDeleteClient() {
  yield takeLatest('websiteAdmin/deleteClientStart', deleteClientAsync)
}

export function* watchFetchClientById() {
  yield takeLatest('websiteAdmin/fetchClientByIdStart', fetchClientByIdAsync)
}

export function* watchEditClient() {
  yield takeLatest('websiteAdmin/editClientStart', editClientAsync)
}

// country
export function* fetchCountryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryList(payload))

    yield put(fetchCountrySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCountryFailure(err))
  }
}

export function* fetchCountryByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryById(payload))

    yield put(fetchCountryByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCountryByIdFailure(err))
  }
}

export function* editCountryAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let url = ''
      const { data } = yield call(
        editTeam(payload.params.id, { ...payload.params, teamImage: url })
      )
      yield put(editCountrySuccess(data?.data))

      yield put(
        fetchCountryStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        editCountry(payload.params.id, payload?.params)
      )
      yield put(editCountrySuccess(data?.data))
      yield payload.closeModal()
      yield put(
        fetchCountryStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Country successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editTeamFailure(err))
  }
}

export function* createCountryAsync({ payload }: AnyAction) {
  try {
    if (!_.isEmpty(payload?.params?.url)) {
      const { data } = yield call(
        createCountry(removeEmptyFields({ ...payload.params }))
      )
      yield put(createCountrySuccess(data?.data))
    } else {
      const { data } = yield call(
        createCountry(removeEmptyFields(payload.params))
      )

      yield put(createCountrySuccess(data?.data))
      // yield put(
      //   fetchTeamStart({
      //     page: 1,
      //     limit: 10,
      //     offset: 0,
      //   })
      // )
    }

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    yield put(
      openAlert({
        message: 'Country successfully created',
        severity: 'success',
      })
    )
    yield payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createCountryFailure(err))
  }
}

export function* deleteCountryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteCountry(payload))

    if (data) {
      yield put(deleteCountrySuccess(payload))
      yield put(
        fetchCountryStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Country deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteCountryFailure(err))
  }
}

export function* changeStatusCountryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(changeStatusCountry(payload.id, payload))

    if (data) {
      yield put(changeStatusCountrySuccess(payload))
      yield put(
        fetchCountryStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Country Status changed successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(changeStatusCountryFailure(err))
  }
}

//constant
export function* fetchConstantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchConstantList(payload))

    yield put(fetchConstantSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchConstantFailure(err))
  }
}

export function* fetchConstantByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchConstantById(payload))

    yield put(fetchConstantByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchConstantByIdFailure(err))
  }
}

export function* editConstantAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      let url = ''
      const { data } = yield call(
        editTeam(payload.params.id, { ...payload.params, teamImage: url })
      )
      yield put(editConstantSuccess(data?.data))

      yield put(
        fetchConstantStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(
        editConstant(payload.params.id, payload?.params)
      )
      yield put(editConstantSuccess(data?.data))
      yield payload.closeModal()
      yield put(
        fetchConstantStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Constant successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editConstantFailure(err))
  }
}

export function* createConstantAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      const { data } = yield call(
        createConstant(removeEmptyFields({ ...payload.params }))
      )
      yield put(createConstantSuccess(data?.data))

      yield put(
        fetchConstantStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    } else {
      const { data } = yield call(
        createConstant(removeEmptyFields(payload.params))
      )

      yield put(createConstantSuccess(data?.data))
      yield put(
        fetchTeamStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Constant successfully created',
        severity: 'success',
      })
    )
    yield payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createConstantFailure(err))
  }
}

export function* deleteConstantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteConstant(payload))

    if (data) {
      yield put(deleteConstantSuccess(payload))
      yield put(
        fetchConstantStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Constant deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteConstantFailure(err))
  }
}
//constant

//multilingual
export function* fetchMultilingualAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMultilingualList(payload))

    yield put(fetchMultilingualSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMultilingualFailure(err))
  }
}

export function* fetchMultilingualByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMultilingualById(payload))

    yield put(fetchMultilingualByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMultilingualByIdFailure(err))
  }
}

export function* editMultilingualAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editMultilingual(payload.params.id, payload?.params)
    )
    yield put(editMultilingualSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      fetchMultilingualStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Multilingual successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editMultilingualFailure(err))
  }
}

export function* createMultilingualAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.url)) {
      const { data } = yield call(
        createMultilingual(removeEmptyFields({ ...payload.params }))
      )
      yield put(createMultilingualSuccess(data?.data))

      yield put(
        fetchMultilingualStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    } else {
      const { data } = yield call(
        createMultilingual(removeEmptyFields(payload.params))
      )

      yield put(createMultilingualSuccess(data?.data))
      yield put(
        fetchTeamStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Multilingual successfully created',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    yield payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createMultilingualFailure(err))
  }
}

export function* deleteMultilingualAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteMultilingual(payload))

    if (data) {
      yield put(deleteMultilingualSuccess(payload))
      yield put(
        fetchMultilingualStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Multilingual deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteMultilingualFailure(err))
  }
}

export function* createSocialMediaAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    let imgUrl = null

    if (!_.isEmpty(payload?.params?.image)) {
      const initialImageUrl = yield call(getImageUrl())
      imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload?.params?.image)
      )
      imgUrl = removeParam(imageResponse.config.url)
    }

    const { data } = yield call(
      createSocialMedia(
        removeEmptyFields({
          ...payload.params,
          image: imgUrl,
        })
      )
    )
    yield put(createSocialMediaSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    yield put(
      openAlert({
        message: 'Social Media Link successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createSocialMediaFailure(err))
  }
}

export function* fetchSocialMediaListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSocialMediaList(payload))

    yield put(fetchSocialMediaListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchSocialMediaListFailure(err))
  }
}

export function* editSocialMediaAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.image)) {
      let url = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.image)
        )
        url = removeParam(imageResponse.config.url)

        // console.log('check image url for edit app slider', url)
      }
      const { data } = yield call(
        editSocialMedia(
          payload.id,
          removeEmptyFields({ ...payload.params, image: url })
        )
      )

      if (payload?.oldImage) {
        yield call(deleteImage(getImageIdFromUrl(payload?.oldImage)))
      }
      yield put(editSocialMediaSuccess(data))
    } else {
      if (_.isEmpty(payload?.params?.image)) {
        delete payload?.params.image
      }
      const { data } = yield call(
        editSocialMedia(payload.id, removeEmptyFields(payload?.params))
      )
      yield put(editSocialMediaSuccess(data))
    }

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Slider successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editSocialMediaFailure(err))
  }
}

export function* deleteAboutUsBySlugAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteAboutUsBySlug({ slug: payload?.slug }))

    yield put(deleteAboutUsBySlugSuccess(data))
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    if (payload?.arrayHelpers) {
      yield payload?.arrayHelpers?.remove(payload?.index)
    }
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.item?.aboutlisImageUrl?.[0]?.preview) {
      yield call(
        deleteImage(
          getImageIdFromUrl(payload?.item?.aboutlisImageUrl?.[0]?.preview)
        )
      )
    }
    yield put(
      openAlert({
        message: 'About Us section deleted successfully ',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteAboutUsBySlugFailure(err))
  }
}

export function* translateIntoCountryLanguageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      translateIntoCountryLanguage({
        lang: payload?.lang,
        id: `${payload?.id}`,
      })
    )

    yield put(translateIntoCountryLanguageSuccess(data))
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (data?.status?.toLowerCase() === 'incompleted') {
      yield put(
        openAlert({
          message:
            'Translation is partially completed. Click the translate button again',
          severity: 'warning',
        })
      )
    } else {
      yield put(
        openAlert({
          message: 'Translated to country language successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(translateIntoCountryLanguageFailure(err))
  }
}

export function* fetchAllHomeVideoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllHomeVideo(payload))

    yield put(fetchAllHomeVideoSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllHomeVideoFailure(err))
  }
}

export function* fetchAllFileRepositoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllFileRepository(payload))

    yield put(fetchAllFileRepositorySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllFileRepositoryFailure(err))
  }
}

export function* createFileRepositoryAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.file)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.file)
        )

        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createFileRepository({ ...payload.params, fileUrl: imgUrl })
      )
      yield put(createFileRepositorySuccess(data?.data))
    } else {
      if (_.isEmpty(payload?.params?.file)) {
        delete payload?.params.file
      }
      const { data } = yield call(
        createFileRepository(removeEmptyFields(payload?.params))
      )
      yield put(createFileRepositorySuccess(data))
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    yield put(
      openAlert({
        message: 'File Repository successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createFileRepositoryFailure(err))
  }
}

export function* fetchFileRepositoryByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchFileRepositoryById(payload))

    yield put(fetchFileRepositoryByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchFileRepositoryByIdFailure(err))
  }
}

export function* editFileRepositoryAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.file) && payload?.params?.file?.size) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.file)
        )

        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        editFileRepository(payload?.params?.id, {
          ...payload.params,
          fileUrl: imgUrl,
        })
      )

      yield call(deleteImage(getImageIdFromUrl(payload?.oldImage)))

      yield put(editFileRepositorySuccess(data?.data))
    } else {
      if (_.isEmpty(payload?.params?.file)) {
        delete payload?.params?.file
      }
      const { data } = yield call(
        editFileRepository(
          payload?.params?.id,
          removeEmptyFields(payload?.params)
        )
      )
      yield put(editFileRepositorySuccess(data))
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }
    yield put(
      openAlert({
        message: 'File Repository successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editFileRepositoryFailure(err))
  }
}

// testamonial watchers
export function* watchFetchTestamonial() {
  yield takeLatest('websiteAdmin/fetchTestamonialStart', fetchTestamonialAsync)
}

export function* watchCreateTestamonial() {
  yield takeLatest(
    'websiteAdmin/createTestamonialStart',
    createTestamonialAsync
  )
}

export function* watchDeleteTestamonial() {
  yield takeLatest(
    'websiteAdmin/deleteTestamonialStart',
    deleteTestamonialAsync
  )
}

export function* watchFetchTestamonialById() {
  yield takeLatest(
    'websiteAdmin/fetchTestamonialByIdStart',
    fetchTestamonialByIdAsync
  )
}

export function* watchEditTestamonial() {
  yield takeLatest('websiteAdmin/editTestamonialStart', editTestamonialAsync)
}
// section watchers start
export function* watchFetchSection() {
  yield takeLatest('websiteAdmin/fetchSectionStart', fetchSectionAsync)
}

export function* watchCreateSection() {
  yield takeLatest('websiteAdmin/createSectionStart', createSectionAsync)
}

export function* watchDeleteSection() {
  yield takeLatest('websiteAdmin/deleteSectionStart', deleteSectionAsync)
}

export function* watchFetchSectionById() {
  yield takeLatest('websiteAdmin/fetchSectionByIdStart', fetchSectionByIdAsync)
}

export function* watchEditSection() {
  yield takeLatest('websiteAdmin/editSectionStart', editSectionAsync)
}

// aboutUs watchers start
export function* watchFetchAboutUs() {
  yield takeLatest('websiteAdmin/fetchAboutUsStart', fetchAboutUsAsync)
}

export function* watchCreateAboutUs() {
  yield takeLatest('websiteAdmin/createAboutUsStart', createAboutUsAsync)
}

export function* watchDeleteAboutUs() {
  yield takeLatest('websiteAdmin/deleteAboutUsStart', deleteAboutUsAsync)
}

export function* watchFetchAboutUsById() {
  yield takeLatest('websiteAdmin/fetchAboutUsByIdStart', fetchAboutUsByIdAsync)
}

export function* watchEditAboutUs() {
  yield takeLatest('websiteAdmin/editAboutUsStart', editAboutUsAsync)
}

//country watcher
export function* watchFetchCountry() {
  yield takeLatest('websiteAdmin/fetchCountryStart', fetchCountryAsync)
}

export function* watchCreateCountry() {
  yield takeLatest('websiteAdmin/createCountryStart', createCountryAsync)
}

export function* watchFetchCountryById() {
  yield takeLatest('websiteAdmin/fetchCountryByIdStart', fetchCountryByIdAsync)
}

export function* watchEditCountry() {
  yield takeLatest('websiteAdmin/editCountryStart', editCountryAsync)
}

export function* watchDeleteCountry() {
  yield takeLatest('websiteAdmin/deleteCountryStart', deleteCountryAsync)
}

export function* watchChangeStatusCountry() {
  yield takeLatest(
    'websiteAdmin/changeStatusCountryStart',
    changeStatusCountryAsync
  )
}

//constant watcher

export function* watchFetchConstant() {
  yield takeLatest('websiteAdmin/fetchConstantStart', fetchConstantAsync)
}

export function* watchCreateConstant() {
  yield takeLatest('websiteAdmin/createConstantStart', createConstantAsync)
}

export function* watchFetchConstantById() {
  yield takeLatest(
    'websiteAdmin/fetchConstantByIdStart',
    fetchConstantByIdAsync
  )
}

export function* watchEditConstant() {
  yield takeLatest('websiteAdmin/editConstantStart', editConstantAsync)
}

export function* watchDeleteConstant() {
  yield takeLatest('websiteAdmin/deleteConstantStart', deleteConstantAsync)
}

//Multilingual watcher

export function* watchFetchMultilingual() {
  yield takeLatest(
    'websiteAdmin/fetchMultilingualStart',
    fetchMultilingualAsync
  )
}

export function* watchCreateMultilingual() {
  yield takeLatest(
    'websiteAdmin/createMultilingualStart',
    createMultilingualAsync
  )
}

export function* watchFetchMultilingualById() {
  yield takeLatest(
    'websiteAdmin/fetchMultilingualByIdStart',
    fetchMultilingualByIdAsync
  )
}

export function* watchEditMultilingual() {
  yield takeLatest('websiteAdmin/editMultilingualStart', editMultilingualAsync)
}

export function* watchDeleteMultilingual() {
  yield takeLatest(
    'websiteAdmin/deleteMultilingualStart',
    deleteMultilingualAsync
  )
}

export function* watchCreateSocialMedia() {
  yield takeLatest(
    'websiteAdmin/createSocialMediaStart',
    createSocialMediaAsync
  )
}
export function* watchFetchSocialMediaList() {
  yield takeLatest(
    'websiteAdmin/fetchSocialMediaListStart',
    fetchSocialMediaListAsync
  )
}

export function* watchEditSocialMedia() {
  yield takeLatest('websiteAdmin/editSocialMediaStart', editSocialMediaAsync)
}

export function* watchDeleteAboutUsBySlug() {
  yield takeLatest(
    'websiteAdmin/deleteAboutUsBySlugStart',
    deleteAboutUsBySlugAsync
  )
}

export function* watchTranslateIntoCountryLanguage() {
  yield takeLatest(
    'websiteAdmin/translateIntoCountryLanguageStart',
    translateIntoCountryLanguageAsync
  )
}

export function* watchCreateHomeVideo() {
  yield takeLatest('websiteAdmin/createHomeVideoStart', createHomeVideoAsync)
}

export function* watchEditHomeVideo() {
  yield takeLatest('websiteAdmin/editHomeVideoStart', editHomeVideoAsync)
}

export function* watchFetchAllHomeVideoStart() {
  yield takeLatest(
    'websiteAdmin/fetchAllHomeVideoStart',
    fetchAllHomeVideoAsync
  )
}

export function* watchFetchAllFileRepository() {
  yield takeLatest(
    'websiteAdmin/fetchAllFileRepositoryStart',
    fetchAllFileRepositoryAsync
  )
}

export function* watchCreateFileRepository() {
  yield takeLatest(
    'websiteAdmin/createFileRepositoryStart',
    createFileRepositoryAsync
  )
}

export function* watchFetchFileRepositoryById() {
  yield takeLatest(
    'websiteAdmin/fetchFileRepositoryByIdStart',
    fetchFileRepositoryByIdAsync
  )
}

export function* watchEditFileRepository() {
  yield takeLatest(
    'websiteAdmin/editFileRepositoryStart',
    editFileRepositoryAsync
  )
}

export function* websiteAdminSagas() {
  yield all([
    call(watchFetchAppSlider),
    call(watchCreateAppSlider),
    call(watchDeleteAppSlider),
    call(watchFetchAppSliderById),
    call(watchEditAppSlider),

    call(watchFetchWebSlider),
    call(watchCreateWebSlider),
    call(watchDeleteWebSlider),
    call(watchFetchWebSliderById),
    call(watchEditWebSlider),

    call(watchFetchHeaderMenu),
    call(watchCreateHeaderMenu),

    call(watchActiveMenuItem),
    call(watchDeleteMenuItem),

    call(watchFetchPage),
    call(watchCreatePage),
    call(watchDeletePage),
    call(watchFetchPageById),
    call(watchEditPage),

    call(watchFetchFaq),
    call(watchCreateFaq),
    call(watchDeleteFaq),
    call(watchFetchFaqById),
    call(watchEditFaq),

    call(watchFetchTeam),
    call(watchCreateTeam),
    call(watchDeleteTeam),
    call(watchFetchTeamById),
    call(watchEditTeam),

    call(watchFetchPartner),
    call(watchCreatePartner),
    call(watchDeletePartner),
    call(watchFetchPartnerById),
    call(watchEditPartner),

    call(watchFetchClient),
    call(watchCreateClient),
    call(watchDeleteClient),
    call(watchFetchClientById),
    call(watchEditClient),

    call(watchFetchTestamonial),
    call(watchCreateTestamonial),
    call(watchDeleteTestamonial),
    call(watchFetchTestamonialById),
    call(watchEditTestamonial),

    call(watchFetchSection),
    call(watchCreateSection),
    call(watchDeleteSection),
    call(watchFetchSectionById),
    call(watchEditSection),

    call(watchFetchCountry),
    call(watchCreateCountry),
    call(watchDeleteCountry),
    call(watchChangeStatusCountry),
    call(watchFetchCountryById),
    call(watchEditCountry),

    call(watchFetchConstant),
    call(watchCreateConstant),
    call(watchDeleteConstant),
    call(watchFetchConstantById),
    call(watchEditConstant),

    call(watchFetchMultilingual),
    call(watchCreateMultilingual),
    call(watchDeleteMultilingual),
    call(watchFetchMultilingualById),
    call(watchEditMultilingual),

    call(watchFetchContentSection),
    call(watchCreateContentSection),
    call(watchDeleteContentSection),
    call(watchFetchContentSectionById),
    call(watchEditContentSection),

    call(watchFetchAboutUs),
    call(watchCreateAboutUs),
    call(watchDeleteAboutUs),
    call(watchFetchAboutUsById),
    call(watchEditAboutUs),

    call(watchCreateSocialMedia),
    call(watchFetchSocialMediaList),
    call(watchEditSocialMedia),
    call(watchDeleteAboutUsBySlug),
    call(watchTranslateIntoCountryLanguage),
    call(watchCreateHomeVideo),
    call(watchFetchAllHomeVideoStart),
    call(watchEditHomeVideo),
    call(watchFetchAllFileRepository),
    call(watchCreateFileRepository),
    call(watchFetchFileRepositoryById),
    call(watchEditFileRepository),
  ])
}
