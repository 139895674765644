import { axiosRequest, removeEmptyFields } from 'utils'

export const fetchCompanyList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/company/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCompany = (params) => {
  return () =>
    axiosRequest
      .post(`/api/company/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCompanyById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/company/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCompany = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/company/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCompany = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/company/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyCompany = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/company/updateAndVerifyEOI/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveUpdatePendingCompany = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/company/saveUpdate/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCompanyOwnProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/company/editCompanyOwnProfile`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectCompany = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/company/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
