import { axiosRequest } from 'utils'

export const fetchAppSliderList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/appSlider/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAppSliderById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/appSlider/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createAppSlider = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/appSlider/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteAppSlider = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/appSlider/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editAppSlider = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/appSlider/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
