import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'

// to delete later
const Temp = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

// friendship
const FriendshipEarningAsPiiinkIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsPiiinkIssuer')
)
const FriendshipEarningAsPiiinkIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsPiiinkIssuer')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)
const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)
const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)

const FriendshipEarningAsPiiinkEntities = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsPiiinkEntities')
)

const FriendshipEarningAsPiiinkEntitiesDetails = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsPiiinkEntities')
)

const MembershipEarningAsPiiinkEntities = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsPiiinkEntities')
)

const MembershipEarningAsPiiinkEntitiesDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsPiiinkEntities')
)

const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const FriendshipEarningAsCpSignerAsSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgnerAsSigner')
)

const FriendshipEarningAsPiiinkCharity = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsPiiinkCharity')
)
const FriendshipEarningAsPiiinkCharityDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsPiiinkCharity')
)

const FriendshipEarningAsPiiinkRegion = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsPiiinkRegion')
)
const FriendshipEarningAsPiiinkRegionDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsPiiinkRegion')
)
const FriendshipEarningAsPiiinkArea = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsPiiinkArea')
)
const FriendshipEarningAsPiiinkAreaDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsPiiinkArea')
)

// membership
const MembershipEarningAsPiiinkIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsPiiinkIssuer')
)
const MembershipEarningAsPiiinkIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsPiiinkIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const MembershipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsCpSigner')
)
const MembershipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsCpSigner')
)
const MembershipEarningAsClubCharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsClubCharitySigner')
)
const MembershipEarningAsClubCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsClubCharitySigner')
)

const MembershipEarningAsPiiinkArea = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsPiiinkArea')
)
const MembershipEarningAsPiiinkAreaDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsPiiinkArea')
)
const MembershipEarningAsPiiinkRegion = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsPiiinkRegion')
)
const MembershipEarningAsPiiinkRegionDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsPiiinkRegion')
)
const MembershipEarningAsPiiinkcharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsPiiinkCharity')
)
const MembershipEarningAsPiiinkCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsPiiinkCharity')
)

const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)
const MerchantPackagePayable = React.lazy(
  () => import('./MerchantPackage/Payable')
)

const GlobalMemberPremiumCode = React.lazy(
  () => import('components/GlobalMemberPremiumCodeEarning')
)

const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)

const CountryRevenueFromFriendship = React.lazy(
  () => import('./CountryRevenue/FromFriendship/Summary')
)

const CountryRevenueFromFriendshipDetails = React.lazy(
  () => import('./CountryRevenue/FromFriendship/Details')
)

const CountryRevenueFromMembership = React.lazy(
  () => import('./CountryRevenue/FromMembership/Summary')
)

const CountryRevenueFromMembershipDetails = React.lazy(
  () => import('./CountryRevenue/FromMembership/Details')
)

const CouponCodes = React.lazy(() => import('./CouponCodes'))

const Coupons = React.lazy(() => import('./Coupons'))

const Autotexts = React.lazy(() => import('./Review/Autotexts'))
const TemporaryPassword = React.lazy(
  () => import('./Settings/TemporaryPassword')
)
const Company = React.lazy(() => import('./Company'))
const Payout = React.lazy(() => import('./Payment/Payout'))
const PaymentLogHistory = React.lazy(
  () => import('./Payment/PaymentLogHistory')
)

export const globalAdminConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/merchant-package/payable',
    element: <MerchantPackagePayable />,
  },

  // aspiiinkissuer
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkissuer',
    element: <FriendshipEarningAsPiiinkIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkissuer/detail',
    element: <FriendshipEarningAsPiiinkIssuerDetail />,
  },

  // aspiiinkcharity
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkcharity',
    element: <FriendshipEarningAsPiiinkCharity />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkcharity/detail',
    element: <FriendshipEarningAsPiiinkCharityDetail />,
  },

  // aspiiinkregion
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkregion',
    element: <FriendshipEarningAsPiiinkRegion />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkregion/detail',
    element: <FriendshipEarningAsPiiinkRegionDetail />,
  },

  // aspiiinkarea
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkarea',
    element: <FriendshipEarningAsPiiinkArea />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkarea/detail',
    element: <FriendshipEarningAsPiiinkAreaDetail />,
  },

  // asissuer
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },

  // asmerchantsigner
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },

  // ascpsigner
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/ascpsigner/detail',
    element: <FriendshipEarningAsCpSignerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/ascpsigner/asmerchantsigner/detail',
    element: <FriendshipEarningAsCpSignerAsSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkentities',
    element: <FriendshipEarningAsPiiinkEntities />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/aspiiinkentities/detail',
    element: <FriendshipEarningAsPiiinkEntitiesDetails />,
  },

  // membership ===========================================================================
  // aspiiinkissuer
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkissuer',
    element: <MembershipEarningAsPiiinkIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkissuer/detail',
    element: <MembershipEarningAsPiiinkIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkentities',
    element: <MembershipEarningAsPiiinkEntities />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkentities/detail',
    element: <MembershipEarningAsPiiinkEntitiesDetails />,
  },

  // aspiiinkcharity
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkcharity',
    element: <MembershipEarningAsPiiinkcharity />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkcharity/detail',

    element: <MembershipEarningAsPiiinkCharityDetail />,
  },

  // aspiiinkregion
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkregion',
    element: <MembershipEarningAsPiiinkRegion />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkregion/detail',
    element: <MembershipEarningAsPiiinkRegionDetail />,
  },

  // aspiiinkarea
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkarea',
    element: <MembershipEarningAsPiiinkArea />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/aspiiinkarea/detail',
    element: <MembershipEarningAsPiiinkAreaDetail />,
  },

  // asissuer
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },

  // ascpsigner
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/ascpsigner',
    element: <MembershipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/ascpsigner/detail',
    element: <MembershipEarningAsCpSignerDetail />,
  },

  // asclubcharity
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/asclubcharity',
    element: <MembershipEarningAsClubCharity />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/asclubcharity/detail',
    element: <MembershipEarningAsClubCharityDetail />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/global-member-premium-code',
    element: <GlobalMemberPremiumCode />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'globaladmin'} />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/country-revenue/fromFriendship',
    element: <CountryRevenueFromFriendship />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/country-revenue/fromFriendship/detail',
    element: <CountryRevenueFromFriendshipDetails />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/country-revenue/fromMembership',
    element: <CountryRevenueFromMembership />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/country-revenue/fromMembership/detail',
    element: <CountryRevenueFromMembershipDetails />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/review/autotext',
    element: <Autotexts />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/coupons',
    element: <Coupons />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/coupon-codes/:id',
    element: <CouponCodes />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/temporary-password',
    element: <TemporaryPassword />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/company',
    element: <Company />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/payment/payout',
    element: <Payout />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/payment/payment-log-history',
    element: <PaymentLogHistory />,
  },

  // {
  //   permittedRole: RoutePermittedRole.globalAdmin,
  //   path: '/globaladmin/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },
]
