import { axiosRequestCMS } from 'utils'

export const fetchSocialMediaList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/socialMedia/getall`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createSocialMedia = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/socialMedia/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editSocialMedia = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/socialMedia/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
