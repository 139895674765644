import { takeLatest, call, all, put, select } from 'redux-saga/effects'
import {
  fetchRegionOwnerStart,
  fetchRegionOwnerSuccess,
  fetchRegionOwnerFailure,
  deleteRegionOwnerStart,
  deleteRegionOwnerSuccess,
  deleteRegionOwnerFailure,
  fetchRegionOwnerByIdStart,
  fetchRegionOwnerByIdSuccess,
  fetchRegionOwnerByIdFailure,
  createRegionOwnerStart,
  createRegionOwnerSuccess,
  createRegionOwnerFailure,
  editRegionOwnerStart,
  editRegionOwnerSuccess,
  editRegionOwnerFailure,
  fetchPendingRegionOwnerStart,
  fetchPendingRegionOwnerSuccess,
  fetchPendingRegionOwnerFailure,
  deletePendingRegionOwnerSuccess,
  deletePendingRegionOwnerFailure,
  verifyRegionOwnerStart,
  verifyRegionOwnerSuccess,
  verifyRegionOwnerFailure,
  rejectRegionOwnerStart,
  rejectRegionOwnerSuccess,
  rejectRegionOwnerFailure,
  updatePendingRegionOwnerStart,
  updatePendingRegionOwnerSuccess,
  updatePendingRegionOwnerFailure,
  deleteRegionOwnerContactSuccess,
  deleteRegionOwnerContactFailure,
  fetchRegionOwnerProfileInfoFailure,
  fetchRegionOwnerProfileInfoSuccess,
  fetchRegionOwnerProfileInfoStart,
  uploadAgreementSuccess,
  uploadAgreementFailure,
  editRegionOwnerOwnProfileInfoFailure,
  editRegionOwnerOwnProfileInfoSuccess,
} from './regionOwnerSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchRegionOwnerList,
  createRegionOwner,
  fetchRegionOwnerById,
  editRegionOwner,
  rejectRegionOwner,
  deleteRegionOwner,
  rejectPendingRegionOwner,
  verifyPendingRegionOwner,
  saveUpdatePendingRegionOwner,
  deleteRegionOwnerContact,
  fetchRegionOwnerProfileInfo,
  createRegionOwnerAgreement,
  editRegionOwnerOwnProfile,
} from '../../api/regionOwner'

import _ from 'lodash'
import { getImageUrl, uploadImage } from 'api/imageUpload'
import { removeParam } from 'utils'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchPendingRegionOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchRegionOwnerList(payload))

    yield put(fetchPendingRegionOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchPendingRegionOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchRegionOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchRegionOwnerList(payload))

    yield put(fetchRegionOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchRegionOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchRegionOwnerProfileInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchRegionOwnerProfileInfo())
    yield put(fetchRegionOwnerProfileInfoSuccess(data))
  } catch (err) {
    yield put(fetchRegionOwnerProfileInfoFailure(err))
  }
}

export function* createRegionOwnerAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    const { data } = yield call(createRegionOwner(payload?.params))

    yield put(createRegionOwnerSuccess(data?.results))
    yield payload?.closeModal()
    yield put(
      openAlert({
        message: 'Region Licensee Succesfully Created',
        severity: 'success',
      })
    )
    yield put(
      fetchRegionOwnerStart({
        page: 1,
        limit: 10,
        offset: 0,
        isPending: false,
        countryId: siteCoordinator?.globalCountry?.id,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createRegionOwnerFailure(err))

    console.error(err)
  }
}

export function* deleteRegionOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteRegionOwner(payload?.id))

    if (data) {
      yield put(deleteRegionOwnerSuccess(payload?.id))
      yield payload.closeDeleteModal()
      yield put(
        openAlert({
          message: 'Region Licensee Successfully Deleted',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteRegionOwnerFailure(err))
    console.error(err)
  }
}

export function* deletePendingRegionOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteRegionOwner(payload?.id))

    yield put(deletePendingRegionOwnerSuccess(payload?.id))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Region Licensee Request Successfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deletePendingRegionOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchRegionOwnerByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchRegionOwnerById(payload))

    yield put(
      fetchRegionOwnerByIdSuccess({
        ...data?.data,
        issuerCode: data?.issuerCode,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchRegionOwnerByIdFailure(err))
    console.error(err)
  }
}

export function* editRegionOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editRegionOwner(payload?.params?.id, payload?.params)
    )

    yield put(editRegionOwnerSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      openAlert({
        message: 'Region Licensee Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editRegionOwnerFailure(err))
    console.error(err)
  }
}

export function* verifyRegionOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      verifyPendingRegionOwner(payload?.params?.id, payload?.params)
    )

    yield put(verifyRegionOwnerSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield payload?.closeModal()
    yield put(
      openAlert({
        message: 'Region Licensee Succesfully Verified',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(verifyRegionOwnerFailure(err))
    console.error(err)
  }
}

export function* rejectRegionOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(rejectPendingRegionOwner(payload?.id, payload))

    yield put(rejectRegionOwnerSuccess(payload?.id))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Region Licensee Succesfully Rejected',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(rejectRegionOwnerFailure(err))
    console.error(err)
  }
}

export function* updatePendingRegionOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      saveUpdatePendingRegionOwner(payload?.params?.id, payload?.params)
    )

    yield put(updatePendingRegionOwnerSuccess(data?.data))
    yield payload?.closeModal()
    yield put(
      openAlert({
        message: 'Region Licensee Request Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(updatePendingRegionOwnerFailure(err))
    console.error(err)
  }
}

export function* deleteRegionOwnerContactAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteRegionOwnerContact(payload?.id))

    yield put(deleteRegionOwnerContactSuccess(payload?.id))

    yield payload.closeModal()
    yield payload?.arrayHelpers?.remove(payload?.index)
    yield put(
      openAlert({
        message: 'Region Licensee Successfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteRegionOwnerContactFailure(err))
    console.error(err)
  }
}

export function* uploadAgreementAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.type)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createRegionOwnerAgreement(payload?.id, {
          agreementUrl: logoUrl,
        })
      )

      yield put(uploadAgreementSuccess())

      yield payload?.closeModal()

      if (payload?.refreshPageData) {
        yield call(payload?.refreshPageData)
      }

      yield put(
        openAlert({
          message: 'Agreement Document Uploaded Successfully',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(uploadAgreementFailure(err))
  }
}

export function* editRegionOwnerOwnProfileInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editRegionOwnerOwnProfile(payload?.params))

    yield put(editRegionOwnerOwnProfileInfoSuccess(data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Region Licensee Profile Successfully Edited',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editRegionOwnerOwnProfileInfoFailure(err))
    console.error(err)
  }
}

export function* watchPendingRegionOwner() {
  yield takeLatest(
    'regionOwner/fetchPendingRegionOwnerStart',
    fetchPendingRegionOwnerAsync
  )
}

export function* watchFetchRegionOwner() {
  yield takeLatest('regionOwner/fetchRegionOwnerStart', fetchRegionOwnerAsync)
}

export function* watchFetchRegionOwnerProfileInfo() {
  yield takeLatest(
    'regionOwner/fetchRegionOwnerProfileInfoStart',
    fetchRegionOwnerProfileInfoAsync
  )
}

export function* watchCreateRegionOwner() {
  yield takeLatest('regionOwner/createRegionOwnerStart', createRegionOwnerAsync)
}

export function* watchDeletePendingRegionOwner() {
  yield takeLatest(
    'regionOwner/deletePendingRegionOwnerStart',
    deletePendingRegionOwnerAsync
  )
}

export function* watchFetchPendingRegionOwnerById() {
  yield takeLatest(
    'regionOwner/fetchRegionOwnerByIdStart',
    fetchRegionOwnerByIdAsync
  )
}

export function* watchEditRegionOwner() {
  yield takeLatest('regionOwner/editRegionOwnerStart', editRegionOwnerAsync)
}

export function* watchDeleteRegionOwner() {
  yield takeLatest('regionOwner/deleteRegionOwnerStart', deleteRegionOwnerAsync)
}

export function* watchVerifyRegionOwner() {
  yield takeLatest('regionOwner/verifyRegionOwnerStart', verifyRegionOwnerAsync)
}

export function* watchRejectRegionOwner() {
  yield takeLatest('regionOwner/rejectRegionOwnerStart', rejectRegionOwnerAsync)
}

export function* watchUpdatePendingRegionOwner() {
  yield takeLatest(
    'regionOwner/updatePendingRegionOwnerStart',
    updatePendingRegionOwnerAsync
  )
}

export function* watchDeleteRegionOwnerContact() {
  yield takeLatest(
    'regionOwner/deleteRegionOwnerContactStart',
    deleteRegionOwnerContactAsync
  )
}

export function* watchUploadAgreementAsync() {
  yield takeLatest('regionOwner/uploadAgreementStart', uploadAgreementAsync)
}

export function* watchEditStateOwnerOwnProfileInfoAsync() {
  yield takeLatest(
    'regionOwner/editRegionOwnerOwnProfileInfoStart',
    editRegionOwnerOwnProfileInfoAsync
  )
}

export function* regionOwnerSagas() {
  yield all([
    call(watchPendingRegionOwner),
    call(watchFetchRegionOwner),
    call(watchFetchRegionOwnerProfileInfo),
    call(watchCreateRegionOwner),
    call(watchDeletePendingRegionOwner),
    call(watchFetchPendingRegionOwnerById),
    call(watchEditRegionOwner),
    call(watchDeleteRegionOwner),
    call(watchUpdatePendingRegionOwner),
    call(watchVerifyRegionOwner),
    call(watchRejectRegionOwner),
    call(watchDeleteRegionOwnerContact),
    call(watchUploadAgreementAsync),
    call(watchEditStateOwnerOwnProfileInfoAsync),
  ])
}
