import { createSlice } from '@reduxjs/toolkit'

import { IqrCodesState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IqrCodesState = {
  transactionBatchList: listObj,
  transactionGmcBatchList: listObj,
  gmcCodesByBatchIdList: listObj,
  soleTransactionBatch: {},
  transactionQrCodesList: listObj,
  transactionQrCodesListWithoutFilter: listObj,
  soleTransactionQrCode: {},
  transactionQrCodesAssignableCountObj: {},
  transactionQrCodesAssignableCountForGMCObj: {},
  solePreviewTransactionCode: {},

  issuerQrCodesList: listObj,
  issuerQrCodesListWithoutFilter: listObj,
  issuerQrCodesListForMerchant: listObj,
  ownIssuerQrCodesList: listObj,
  transactionQrCodesListForMerchant: listObj,
  ownTransactionQrCodesList: listObj,
  error: null,
  loading: false,
  isFetching: false,
  isDeleting: false,
  isDownloading: false,
  isFetchingTransactionCode: false,
  downloadStatus: 'start',
}

export const qrCodesSlice = createSlice({
  name: 'qrCodes',
  initialState: INITIAL_STATE,
  reducers: {
    // transaction batch
    createTransactionBatchStart: (state: any, action) => {
      state.isGenerating = true
    },
    createTransactionBatchSuccess: (state: any, action) => {
      state.isGenerating = false
    },
    createTransactionBatchFailure: (state: any, action) => {
      state.isGenerating = false
      state.error = action.payload
    },

    editTransactionBatchStart: (state: any, action) => {
      state.isFetching = true
    },
    editTransactionBatchSuccess: (state: any, action) => {
      state.isFetching = true
    },
    editTransactionBatchFailure: (state: any, action) => {
      state.isFetching = true
      state.error = action.payload
    },

    createTransactionBatchCSVStart: (state: any, action) => {
      state.isFetching = true
    },
    createTransactionBatchCSVSuccess: (state: any, action) => {
      state.isFetching = false
    },
    createTransactionBatchCSVFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTransactionBatchListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTransactionBatchListSuccess: (state: any, action) => {
      state.transactionBatchList = action.payload
      state.isFetching = false
    },
    fetchTransactionBatchListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    // transaction qr codes
    fetchTransactionQrCodesListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTransactionQrCodesListSuccess: (state: any, action) => {
      state.transactionQrCodesList = action.payload
      state.isFetching = false
    },
    fetchTransactionQrCodesListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTransactionQrCodesListWithoutFilterStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTransactionQrCodesListWithoutFilterSuccess: (state: any, action) => {
      state.transactionQrCodesListWithoutFilter = action.payload
      state.isFetching = false
    },
    fetchTransactionQrCodesListWithoutFilterFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTransactionQrCodesListAllStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTransactionQrCodesListAllSuccess: (state: any, action) => {
      state.transactionQrCodesList = action.payload
      state.isFetching = false
    },
    fetchTransactionQrCodesListAllFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchAssignerStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAssignerSuccess: (state: any, action) => {
      state.isFetching = false
      state.assignerList = action.payload
    },
    fetchAssignerFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createAssignerStart: (state: any, action) => {
      state.isAssigning = true
    },
    createAssignerSuccess: (state: any, action) => {
      state.isAssigning = false
    },
    createAssignerFailure: (state: any, action) => {
      state.isAssigning = false
      state.error = action.payload
    },

    createAssignerMerchantPremiumCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    createAssignerMerchantPremiumCodeSuccess: (state: any, action) => {
      state.isFetching = false
    },
    createAssignerMerchantPremiumCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createAssignerMemberPremiumCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    createAssignerMemberPremiumCodeSuccess: (state: any, action) => {
      state.isFetching = false
    },
    createAssignerMemberPremiumCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createWhiteLabelAssignerStart: (state: any, action) => {
      state.isFetching = true
    },
    createWhiteLabelAssignerSuccess: (state: any, action) => {
      state.isFetching = false
    },
    createWhiteLabelAssignerFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createGMCAssignerStart: (state: any, action) => {
      state.isFetching = true
    },
    createGMCAssignerSuccess: (state: any, action) => {
      state.isFetching = false
    },
    createGMCAssignerFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    // issuer qr codes
    fetchIssuerQrCodesListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchIssuerQrCodesListSuccess: (state: any, action) => {
      state.issuerQrCodesList = action.payload
      state.isFetching = false
    },
    fetchIssuerQrCodesListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchIssuerQrCodesListWithoutFilterStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchIssuerQrCodesListWithoutFilterSuccess: (state: any, action) => {
      state.issuerQrCodesListWithoutFilter = action.payload
      state.isFetching = false
    },
    fetchIssuerQrCodesListWithoutFilterFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTransactionGmcBatchListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTransactionGmcBatchListSuccess: (state: any, action) => {
      state.transactionGmcBatchList = action.payload
      state.isFetching = false
    },
    fetchTransactionGmcBatchListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchGmcCodesByBatchIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchGmcCodesByBatchIdSuccess: (state: any, action) => {
      state.gmcCodesByBatchIdList = action.payload
      state.isFetching = false
    },
    fetchGmcCodesByBatchIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTransactionCodeAssignableCountForOthersStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTransactionCodeAssignableCountForOthersSuccess: (
      state: any,
      action
    ) => {
      state.transactionQrCodesAssignableCountObj = action.payload
      state.isFetching = false
    },
    fetchTransactionCodeAssignableCountForOthersFailure: (
      state: any,
      action
    ) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTransactionCodeAssignableCountForGMCStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTransactionCodeAssignableCountForGMCSuccess: (state: any, action) => {
      state.transactionQrCodesAssignableCountForGMCObj = action.payload
      state.isFetching = false
    },
    fetchTransactionCodeAssignableCountForGMCFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchIssuerQrCodesListByMerchantIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchIssuerQrCodesListByMerchantIdSuccess: (state: any, action) => {
      state.issuerQrCodesListForMerchant = action.payload
      state.isFetching = false
    },
    fetchIssuerQrCodesListByMerchantIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchStateIssuerQrCodesListByMerchantIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchStateIssuerQrCodesListByMerchantIdSuccess: (state: any, action) => {
      state.issuerQrCodesListForMerchant = action.payload
      state.isFetching = false
    },
    fetchStateIssuerQrCodesListByMerchantIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchRegionIssuerQrCodesListByMerchantIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchRegionIssuerQrCodesListByMerchantIdSuccess: (state: any, action) => {
      state.issuerQrCodesListForMerchant = action.payload
      state.isFetching = false
    },
    fetchRegionIssuerQrCodesListByMerchantIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAreaIssuerQrCodesListByMerchantIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAreaIssuerQrCodesListByMerchantIdSuccess: (state: any, action) => {
      state.issuerQrCodesListForMerchant = action.payload
      state.isFetching = false
    },
    fetchAreaIssuerQrCodesListByMerchantIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchCountryIssuerQrCodesListByMerchantIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCountryIssuerQrCodesListByMerchantIdSuccess: (state: any, action) => {
      state.issuerQrCodesListForMerchant = action.payload
      state.isFetching = false
    },
    fetchCountryIssuerQrCodesListByMerchantIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchOwnIssuerCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchOwnIssuerCodeSuccess: (state: any, action) => {
      state.ownIssuerQrCodesList = action.payload
      state.isFetching = false
    },
    fetchOwnIssuerCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTransactionQrCodesListByMerchantIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTransactionQrCodesListByMerchantIdSuccess: (state: any, action) => {
      state.transactionQrCodesListForMerchant = action.payload
      state.isFetching = false
    },
    fetchTransactionQrCodesListByMerchantIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchOwnTransactionCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchOwnTransactionCodeSuccess: (state: any, action) => {
      state.ownTransactionQrCodesList = action.payload
      state.isFetching = false
    },
    fetchOwnTransactionCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    deleteTransactionCodeByIdStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteTransactionCodeByIdSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteTransactionCodeByIdFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    deleteIssuerCodeByIdStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteIssuerCodeByIdSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteIssuerCodeByIdFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    deleteTransactionCodeBatchStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteTransactionCodeBatchSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteTransactionCodeBatchFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    deleteTransactionCodeStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteTransactionCodeSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteTransactionCodeFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    downloadTransactionCodeBatchStart: (state: any, action) => {
      state.downloadStatus = 'start'
      state.isDownloading = true
    },
    downloadTransactionCodeBatchSuccess: (state: any, action?: any) => {
      state.downloadStatus = action.payload ? action.payload : 'end'

      state.isDownloading = false
    },
    downloadTransactionCodeBatchFailure: (state: any, action) => {
      state.isDownloading = false
      state.downloadStatus = 'fail'

      state.error = action.payload
    },

    changeDownloadStatus: (state: any, action) => {
      state.downloadStatus = action.payload
    },

    fetchOneTransactionQrCodePreviewStart: (state: any, action) => {
      state.solePreviewTransactionCode = {}
      state.isFetchingTransactionCode = true
    },
    fetchOneTransactionQrCodePreviewSuccess: (state: any, action) => {
      state.solePreviewTransactionCode = action.payload
      state.isFetchingTransactionCode = false
    },
    fetchOneTransactionQrCodePreviewFailure: (state: any, action) => {
      state.isFetchingTransactionCode = false
      state.error = action.payload
    },
  },
})

export const {
  // trasaction batch
  fetchTransactionBatchListStart,
  fetchTransactionBatchListSuccess,
  fetchTransactionBatchListFailure,
  createTransactionBatchFailure,
  createTransactionBatchStart,
  createTransactionBatchSuccess,
  createTransactionBatchCSVStart,
  createTransactionBatchCSVSuccess,
  createTransactionBatchCSVFailure,
  editTransactionBatchStart,
  editTransactionBatchSuccess,
  editTransactionBatchFailure,

  // transaction qr codes
  fetchTransactionQrCodesListStart,
  fetchTransactionQrCodesListSuccess,
  fetchTransactionQrCodesListFailure,
  fetchTransactionQrCodesListAllStart,
  fetchTransactionQrCodesListAllSuccess,
  fetchTransactionQrCodesListAllFailure,
  fetchAssignerFailure,
  fetchAssignerStart,
  fetchAssignerSuccess,
  createAssignerStart,
  createAssignerSuccess,
  createAssignerFailure,
  createGMCAssignerFailure,
  createGMCAssignerStart,
  createGMCAssignerSuccess,

  fetchTransactionQrCodesListWithoutFilterStart,
  fetchTransactionQrCodesListWithoutFilterSuccess,
  fetchTransactionQrCodesListWithoutFilterFailure,

  // issuer qr codes
  fetchIssuerQrCodesListStart,
  fetchIssuerQrCodesListSuccess,
  fetchIssuerQrCodesListFailure,

  fetchIssuerQrCodesListWithoutFilterStart,
  fetchIssuerQrCodesListWithoutFilterSuccess,
  fetchIssuerQrCodesListWithoutFilterFailure,

  createWhiteLabelAssignerStart,
  createWhiteLabelAssignerSuccess,
  createWhiteLabelAssignerFailure,

  fetchTransactionGmcBatchListStart,
  fetchTransactionGmcBatchListSuccess,
  fetchTransactionGmcBatchListFailure,

  fetchGmcCodesByBatchIdStart,
  fetchGmcCodesByBatchIdSuccess,
  fetchGmcCodesByBatchIdFailure,

  createAssignerMerchantPremiumCodeFailure,
  createAssignerMerchantPremiumCodeStart,
  createAssignerMerchantPremiumCodeSuccess,

  createAssignerMemberPremiumCodeFailure,
  createAssignerMemberPremiumCodeStart,
  createAssignerMemberPremiumCodeSuccess,

  fetchTransactionCodeAssignableCountForOthersStart,
  fetchTransactionCodeAssignableCountForOthersSuccess,
  fetchTransactionCodeAssignableCountForOthersFailure,

  fetchTransactionCodeAssignableCountForGMCStart,
  fetchTransactionCodeAssignableCountForGMCSuccess,
  fetchTransactionCodeAssignableCountForGMCFailure,

  fetchIssuerQrCodesListByMerchantIdStart,
  fetchIssuerQrCodesListByMerchantIdSuccess,
  fetchIssuerQrCodesListByMerchantIdFailure,

  fetchTransactionQrCodesListByMerchantIdStart,
  fetchTransactionQrCodesListByMerchantIdSuccess,
  fetchTransactionQrCodesListByMerchantIdFailure,

  fetchOwnIssuerCodeStart,
  fetchOwnIssuerCodeSuccess,
  fetchOwnIssuerCodeFailure,

  fetchOwnTransactionCodeStart,
  fetchOwnTransactionCodeSuccess,
  fetchOwnTransactionCodeFailure,

  deleteTransactionCodeByIdStart,
  deleteTransactionCodeByIdSuccess,
  deleteTransactionCodeByIdFailure,

  deleteIssuerCodeByIdStart,
  deleteIssuerCodeByIdSuccess,
  deleteIssuerCodeByIdFailure,

  fetchStateIssuerQrCodesListByMerchantIdStart,
  fetchStateIssuerQrCodesListByMerchantIdSuccess,
  fetchStateIssuerQrCodesListByMerchantIdFailure,

  fetchRegionIssuerQrCodesListByMerchantIdStart,
  fetchRegionIssuerQrCodesListByMerchantIdSuccess,
  fetchRegionIssuerQrCodesListByMerchantIdFailure,

  fetchAreaIssuerQrCodesListByMerchantIdStart,
  fetchAreaIssuerQrCodesListByMerchantIdSuccess,
  fetchAreaIssuerQrCodesListByMerchantIdFailure,

  fetchCountryIssuerQrCodesListByMerchantIdStart,
  fetchCountryIssuerQrCodesListByMerchantIdSuccess,
  fetchCountryIssuerQrCodesListByMerchantIdFailure,

  deleteTransactionCodeBatchStart,
  deleteTransactionCodeBatchSuccess,
  deleteTransactionCodeBatchFailure,

  deleteTransactionCodeStart,
  deleteTransactionCodeSuccess,
  deleteTransactionCodeFailure,

  downloadTransactionCodeBatchStart,
  downloadTransactionCodeBatchSuccess,
  downloadTransactionCodeBatchFailure,

  fetchOneTransactionQrCodePreviewStart,
  fetchOneTransactionQrCodePreviewSuccess,
  fetchOneTransactionQrCodePreviewFailure,

  changeDownloadStatus,
} = qrCodesSlice.actions

export default qrCodesSlice.reducer
