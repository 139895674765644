import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
const CollaborationPackage = React.lazy(
  () => import('../../pages/PackageSetup/CollaborationPackage')
)
const MerchantPackage = React.lazy(
  () => import('../../pages/PackageSetup/MerchantPackage')
)

export const packageSetupConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/collaborationpackage',
    element: <CollaborationPackage />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/merchantpackage',
    element: <MerchantPackage />,
  },
]
