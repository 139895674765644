/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  createGeneralSettingsStart,
  deleteDocumentStart,
  fetchContactListStart,
  fetchDocumentListStart,
  fetchEmailListStart,
  fetchGeneralSettingStart,
  fetchGroupInformationListStart,
  fetchTemporaryPasswordListStart,
} from 'store/globalAdminSettings/globalAdminSettingsSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  globalSettings: state.globalSettings,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchGeneralSettingStart: (payload) =>
    dispatch(fetchGeneralSettingStart(payload)),

  onFetchGroupInformationListStart: (payload) =>
    dispatch(fetchGroupInformationListStart(payload)),

  onFetchContactListStart: (payload) =>
    dispatch(fetchContactListStart(payload)),

  onFetchEmailListStart: (payload) => dispatch(fetchEmailListStart(payload)),
  onFetchTemporaryPasswordListStart: (payload) =>
    dispatch(fetchTemporaryPasswordListStart(payload)),

  onCreateGeneralSettingsStart: (payload) =>
    dispatch(createGeneralSettingsStart(payload)),

  onDeleteDocumentStart: (payload) => dispatch(deleteDocumentStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
