import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import AboutUs from './AboutUs'
import AllNotificationList from 'components/AllNotificationList'

const Constant = React.lazy(() => import('./Constant'))
const Section = React.lazy(() => import('./Section'))
const Multilingual = React.lazy(() => import('./Multilingual'))
const ContentSection = React.lazy(() => import('./ContentSection'))
const AppSlider = React.lazy(() => import('./AppSlider'))
const WebSlider = React.lazy(() => import('./WebSlider'))
const Team = React.lazy(() => import('./Team'))
const Partner = React.lazy(() => import('./Partner'))
const Client = React.lazy(() => import('./Client'))
const Testamonial = React.lazy(() => import('./Testamonial'))
const MerchantList = React.lazy(() => import('./Merchants/MerchantList'))
const PendingMerchantList = React.lazy(
  () => import('./Merchants/PendingMerchantList')
)
const MenuItem = React.lazy(() => import('./MenuItem'))
const MenuList = React.lazy(() => import('./MenuList'))
const Page = React.lazy(() => import('./Page'))
const Faq = React.lazy(() => import('./Faq'))
const FaqView = React.lazy(() => import('./Faq'))

const SocialMedia = React.lazy(() => import('./SocialMedia'))

const EditCountryAdminMerchant = React.lazy(
  () => import('./Merchants/MerchantList/components/EditMerchantRequest')
)

const EditCountryAdminPendingMerchant = React.lazy(
  () => import('./Merchants/PendingMerchantList/components/EditMerchantRequest')
)

const AddCountryAdminMerchant = React.lazy(
  () => import('./Merchants/MerchantList/components/AddNewMerchant')
)
const TransactionQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/TransactionQrCode')
)

const IssuerQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/IssuerQrCode')
)

const Country = React.lazy(() => import('./Country'))
const HomeVideo = React.lazy(() => import('./HomeVideo'))
const Media = React.lazy(() => import('./Media'))
const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))
const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

export const websiteAdminConfig = [
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/appslider',
    element: <AppSlider />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/webslider',
    element: <WebSlider />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteadmin/merchantList',
    element: <MerchantList />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteadmin/pendingMerchantList',
    element: <PendingMerchantList />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/menuItem',
    element: <MenuItem />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/menuItem/:menuSection',
    element: <MenuList />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/page',
    element: <Page />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/team',
    element: <Team />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/partners',
    element: <Partner />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/clients',
    element: <Client />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/faq',
    element: <Faq />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/testimonial',
    element: <Testamonial />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteadmin/merchantList/editMerchant/:id',
    element: <EditCountryAdminMerchant />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteadmin/pendingList/editMerchant/:id',
    element: <EditCountryAdminPendingMerchant />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteadmin/addMerchant',
    element: <AddCountryAdminMerchant />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteadmin/addMerchant/:id',
    element: <AddCountryAdminMerchant />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteAdmin/transaction-qr-codes',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteAdmin/issuer-qr-codes',
    element: <IssuerQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteAdmin/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'websiteAdmin'} />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteAdmin/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'websiteAdmin'} />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/country',
    element: <Country />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/constant',
    element: <Constant />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/multilingual',
    element: <Multilingual />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/contentsection',
    element: <ContentSection />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/section',
    element: <Section />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/aboutus',
    element: <AboutUs />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/homevideo',
    element: <HomeVideo />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/faq',
    element: <FaqView />,
  },
  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/socialmedia',
    element: <SocialMedia />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteadmin/coupons',
    element: <Coupons adminRoute={'websiteadmin'} />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteadmin/coupon-codes/:id',
    element: <CouponCodes />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: 'websiteadmin/media',
    element: <Media />,
  },

  {
    permittedRole: RoutePermittedRole.websiteAdmin,
    path: '/websiteadmin/all-notification',
    element: <AllNotificationList adminRoute={'websiteAdmin'} />,
  },
]
