import React, { ReactNode } from 'react'
import { RoutePermittedRole } from '../../shared/constants/AppConst'
import { GrHome } from 'react-icons/gr'
import { MdOutlineStorefront, MdOutlinePayment } from 'react-icons/md'
import { AttachMoney, HeadsetMic, PlayArrow, Policy } from '@mui/icons-material'
import { FiUser } from 'react-icons/fi'
import LogoutIcon from '@mui/icons-material/Logout'
import PaymentIcon from '@mui/icons-material/Payment'

export interface RouterConfigData {
  id: string
  title: string
  messageId?: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const companyRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            messageId: 'Dashboard',
            type: 'item',
            url: '/dashboard',
          },
        ],
      },
      {
        id: 'myAccount',
        title: 'myAccount',
        messageId: 'My Account',
        type: 'item',
        // icon: <PersonOutline />,
        icon: <FiUser />,
        url: '/my-account',
      },
      {
        id: 'Merchant',
        title: 'Merchants',
        messageId: 'Merchants',
        type: 'collapse',
        icon: <MdOutlineStorefront />,
        children: [
          {
            id: 'sendMerchantRequest',
            title: 'Send Merchant Request',
            messageId: 'Send Merchant Request',
            type: 'item',
            url: '/company/sendMerchantRequest',
          },
          {
            id: 'draftList',
            title: 'Draft List',
            messageId: 'Draft List',
            type: 'item',
            url: '/company/draftList',
          },
          {
            id: 'merchantList',
            title: 'Merchants List',
            messageId: 'Merchants List',
            type: 'item',
            url: '/company/merchantList',
          },

          {
            id: 'referredMerchantList',
            title: 'Referred Merchant List',
            messageId: 'Referred Merchant List',
            type: 'item',
            url: '/company/referredMerchantList',
          },
          {
            id: 'merchantActivityReports',
            title: 'Merchant Activity Reports',
            messageId: 'Merchant Activity Reports',
            type: 'item',
            url: '/company/merchant-activity-reports',
          },
          // {
          //   id: 'sendMerchantEmail',
          //   title: 'Send Merchant Email',
          //   messageId: 'Send Merchant Email',
          //   type: 'item',
          //   url: '/company/sendMerchantEmail',
          // },
        ],
      },

      {
        id: 'PremiumCodes',
        title: 'Codes',
        messageId: 'Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'premiummerchantcodes',
            title: 'Merchant Codes',
            messageId: 'Merchant Codes',
            type: 'item',
            url: '/company/premium-merchant-codes',
          },
          // {
          //   id: 'premiummerchantcodes',
          //   title: 'Premium Member Codes',
          //   messageId: 'Premium Member Codes',
          //   type: 'item',
          //   url: '/company/premium-member-codes',
          // },
        ],
      },
      {
        id: 'QrCodes',
        title: 'QrCodes',
        messageId: 'QR Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'transactionQrCodes',
            title: 'Transaction QR Codes',
            messageId: 'Transaction QR Codes',
            type: 'item',
            url: '/company/transaction-qr-codes',
          },
          {
            id: 'issuerQrCodes',
            title: 'Issuer QR Codes',
            messageId: 'Issuer QR Codes',
            type: 'item',
            url: '/company/issuer-qr-codes',
          },
        ],
      },

      // {
      //   id: 'Members',
      //   title: 'Members',
      //   messageId: 'Members',
      //   type: 'collapse',
      //   icon: 'input',
      //   children: [
      //     {
      //       id: 'memberslist',
      //       title: 'Members List',
      //       messageId: 'Members List',
      //       type: 'item',
      //       url: '/company/memberslist',
      //     },
      //   ],
      // },
      // {
      //   id: 'PremiumCodes',
      //   title: 'Premium Codes',
      //   messageId: 'Premium Codes',
      //   type: 'collapse',
      //   icon: 'input',
      //   children: [
      //     {
      //       id: 'premiummerchantcodes',
      //       title: 'Premium Merchant Codes',
      //       messageId: 'Premium Merchant Codes',
      //       type: 'item',
      //       url: '/company/premium-merchant-codes',
      //     },
      //     {
      //       id: 'premiummerchantcodes',
      //       title: 'Premium Member Codes',
      //       messageId: 'Premium Member Codes',
      //       type: 'item',
      //       url: '/company/premium-member-codes',
      //     },
      //   ],
      // },
      {
        icon: <AttachMoney />,
        id: 'friendshipearningwithoutissuer',
        title: 'FriendshipEarningWithoutIssuer',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          {
            id: 'asMerchantSigner',
            title: 'AsMerchantSigner',
            messageId: 'As Merchant Signer',
            type: 'item',
            url: '/company/friendship-earning/asmerchantsigner',
          },
          // {
          //   id: 'asCpSigner',
          //   title: 'AsCpSigner',
          //   messageId: 'As CP Signer',
          //   type: 'item',
          //   url: '/company/friendship-earning/ascpsigner',
          // },
        ],
      },

      {
        icon: <AttachMoney />,
        id: 'friendshipearning',
        title: 'FriendshipEarning',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          // {
          //   id: 'asIssuer',
          //   title: 'AsIssuer',
          //   messageId: 'As an Issuer',
          //   type: 'item',
          //   url: '/company/friendship-earning/asissuer',
          // },
          {
            id: 'asMerchantSigner',
            title: 'AsMerchantSigner',
            messageId: 'As Merchant Signer',
            type: 'item',
            url: '/company/friendship-earning/asmerchantsigner',
          },
          // {
          //   id: 'asCpSigner',
          //   title: 'AsCpSigner',
          //   messageId: 'As CP Signer',
          //   type: 'item',
          //   url: '/company/friendship-earning/ascpsigner',
          // },
        ],
      },

      {
        icon: <AttachMoney />,
        id: 'merchantPackage',
        title: 'merchantPackage',

        messageId: 'Merchant Package Earning',
        type: 'item',

        url: '/company/merchant-package/earning',
      },

      {
        id: 'payments',
        title: 'payments',
        messageId: 'Payments',
        type: 'collapse',
        icon: <PaymentIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/salesagent/setting/smtp',
          // },
          {
            id: 'connect stripe',
            title: 'connect stripe',
            messageId: 'Connect Stripe',
            type: 'item',
            url: '/company/payment/connect-stripe',
          },
        ],
      },
      {
        icon: <PlayArrow />,
        id: 'training',
        title: 'training',
        messageId: 'Trainings',
        type: 'item',
        url: '/common/trainings',
        // url: '/training-videos',
      },
      {
        icon: <HeadsetMic />,
        id: 'support',
        title: 'support',
        messageId: 'Contact Piiink',
        type: 'item',
        url: '/support',
      },

      {
        icon: <Policy />,
        id: 'terms',
        title: 'terms',
        messageId: 'Terms & Conditions',
        type: 'item',
        url: '/terms',
      },
      // {
      //   icon: <ReceiptIcon />,
      //   id: 'coupons',
      //   title: 'coupons',
      //   messageId: 'Coupons',
      //   type: 'item',
      //   url: '/company/coupons',
      // },
      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },
    ],
  },
]
export default companyRoutesConfig
