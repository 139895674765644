import { configureStore, combineReducers } from '@reduxjs/toolkit'

import { persistStore, persistReducer } from 'redux-persist'
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'

import authReducer from 'store/auth/authSlice'
import settingsReducer from 'store/settings/settingsSlice'
import postsReducer from 'store/post/postSlice'
import countryReducer from 'store/countrySetup/countrySetupSlice'
import regionReducer from 'store/regionSetup/regionSetupSlice'
import areaReducer from 'store/areaSetup/areaSetupSlice'
import postalCodeReducer from 'store/postalCode/postalCodeSlice'
import countryWisePrefixReducer from 'store/countryWisePrefix/countryWisePrefixSlice'
import collaborationPackageReducer from 'store/collaborationPackage/collaborationPackageSlice'
import merchantPackageReducer from 'store/merchantPackage/merchantPackageSlice'
import countryAdminReducer from 'store/countryAdmin/countryAdminSlice'
import stateReducer from 'store/stateSetup/stateSetupSlice'
import alertReducer from 'store/alert/alertSlice'
import dropDownReducer from 'store/dropDown/dropDownSlice'
import areaOwnerReducer from 'store/areaOwner/areaOwnerSlice'
import stateOwnerReducer from 'store/stateOwner/stateOwnerSlice'
import regionOwnerReducer from 'store/regionOwner/regionOwnerSlice'
import collaborationPartnerReducer from 'store/collaborationPartner/collaborationPartnerSlice'
import friendshipFeeReducer from 'store/friendshipFee/friendshipFeeSlice'
import membershipFeeReducer from 'store/membershipFee/membershipFeeSlice'
import merchantFeeReducer from 'store/merchantFee/merchantFeeSlice'
import membershipPackageReducer from 'store/membershipPackage/membershipPackageSlice'
import gmcCollaborationPartnerReducer from 'store/gmcCollaborationPartner/gmcCollaborationPartnerSlice'
import gmcReferredCpReducer from 'store/gmcReferredCp/gmcReferredCpSlice'
import referredCpReducer from 'store/referredCP/referredCpSlice'
import charitiesReducer from 'store/charities/chartiesSlice'
import siteCoordinatorReducer from 'store/siteCoordinator/siteCoordinatorSlice'
import clubReducer from 'store/club/clubSlice'
import salesAgentReducer from 'store/salesAgent/salesAgentSlice'
import whiteLabelReducer from 'store/whiteLabel/whiteLabelSlice'
import eoiCountryOwnerReducer from 'store/eoiCountryOwner/eoiCountryOwnerSlice'
import countryOwnerReducer from 'store/countryOwner/countryOwnerSlice'
import membersReducer from 'store/members/membersSlice'
import whiteLabelReferedReducer from 'store/whiteLabelAdmin/ReferredCP/whiteLabelReferredCpSlice'
import merchantReducer from 'store/merchant/merchantSlice'
import merchantCategoryReducer from 'store/merchantCategorySetup/merchantCategorySetupSlice'
import emailTemplatesReducer from 'store/emailTemplatesSetup/emailTemplatesSetupSlice'
import merchantAgreementTemplateReducer from 'store/agreementTemplateSetup/merchantAgreementTemplateSlice'
import pageSetupReducer from 'store/pageSetup/pageSetupSlice'
import cpGlobalMembershipBeneficiaryReducer from 'store/cpGlobalMembershipBeneficiary/CPGlobalMembershipBeneficiarySlice'
import cpGlobalFriendshipBeneficiaryReducer from 'store/cpGlobalFriendshipBeneficiary/CPGlobalFriendshipBeneficiarySlice'

import forgotPasswordReducer from 'store/forgotPassword/forgotPasswordSlice'
import resetPasswordReducer from 'store/resetPassword/resetPasswordSlice'
import changePasswordReducer from 'store/changePassword/changePasswordSlice'
import dashboardReducer from 'store/dashboard/dashboardSlice'
import websiteAdminReducer from 'store/websiteAdmin/websiteAdminSlice'
import qrCodesReducer from 'store/qrCodes/qrCodesSlice'
import issuerCodesReducer from 'store/qrCodes/issuerCodesSlice'
import earningReducer from 'store/earning/earningSlice'
import trainingVideoReducer from 'store/trainingVideos/trainingVideosSlice'
import eoiReducer from 'store/eoi/eoiSlice'
import globalAdminSettingsReducer from 'store/globalAdminSettings/globalAdminSettingsSlice'
import prizeDrawReducer from 'store/prizeDraw/prizeDrawSlice'
import contactUsReducer from 'store/contactUs/contactUsSlices'
import premiumCodesReducer from 'store/premiumCode/premiumCodeSlice'
import reviewReducer from 'store/review/reviewSlice'
import reportsReducer from 'store/reports/reportsSlice'

import rootSaga from './root-saga'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'siteCoordinator'],
}

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  post: postsReducer,
  countrySetup: countryReducer,
  regions: regionReducer,
  area: areaReducer,
  postalCode: postalCodeReducer,
  countryWisePrefix: countryWisePrefixReducer,
  merchantCategory: merchantCategoryReducer,
  collaborationPackage: collaborationPackageReducer,
  merchantPackage: merchantPackageReducer,
  pendingAreaOwner: countryAdminReducer,
  stateSetup: stateReducer,
  alert: alertReducer,
  dropDown: dropDownReducer,
  areaOwner: areaOwnerReducer,
  stateOwner: stateOwnerReducer,
  regionOwner: regionOwnerReducer,
  collaborationPartner: collaborationPartnerReducer,
  friendshipFee: friendshipFeeReducer,
  membershipFee: membershipFeeReducer,
  merchantFee: merchantFeeReducer,
  membershipPackage: membershipPackageReducer,
  gmcCollaborationPartner: gmcCollaborationPartnerReducer,
  gmcReferredCP: gmcReferredCpReducer,
  referredCP: referredCpReducer,
  charities: charitiesReducer,
  siteCoordinator: siteCoordinatorReducer,
  club: clubReducer,
  salesAgent: salesAgentReducer,
  whiteLabel: whiteLabelReducer,
  whiteLabelReferedCP: whiteLabelReferedReducer,
  eoiCountryOwner: eoiCountryOwnerReducer,
  countryOwner: countryOwnerReducer,
  members: membersReducer,
  merchant: merchantReducer,
  emailTemplates: emailTemplatesReducer,
  page: pageSetupReducer,
  cpGlobalMembershipBeneficiary: cpGlobalMembershipBeneficiaryReducer,
  cpGlobalFriendshipBeneficiary: cpGlobalFriendshipBeneficiaryReducer,
  merchantAgreementTemplate: merchantAgreementTemplateReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  changePassword: changePasswordReducer,
  dashboard: dashboardReducer,
  websiteAdmin: websiteAdminReducer,
  qrCodes: qrCodesReducer,
  issuerQrCodes: issuerCodesReducer,
  earning: earningReducer,
  trainingVideos: trainingVideoReducer,
  eoi: eoiReducer,
  globalSettings: globalAdminSettingsReducer,
  prizeDraw: prizeDrawReducer,
  contactUs: contactUsReducer,
  premiumCodes: premiumCodesReducer,
  review: reviewReducer,
  reports: reportsReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export default { store, persistStore }

export type RootState = ReturnType<typeof rootReducer>
