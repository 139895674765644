import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'

// const GeneralSetting = React.lazy(() => import('./GeneralSetting'))
const PrizeDraw = React.lazy(() => import('./PrizeDraw/prizeDrawNew.component'))
const GeneralSetting = React.lazy(() => import('./GeneralSetting'))
const SMTP = React.lazy(() => import('./SMTP'))
const Group = React.lazy(() => import('./Group'))
const Contacts = React.lazy(() => import('./Contacts'))
const Documents = React.lazy(() => import('./Documents'))
const Emails = React.lazy(() => import('./Emails'))
const Recipients = React.lazy(() => import('./Recipients'))
const Reports = React.lazy(() => import('./Emails/components/Reports'))
const Coupon = React.lazy(() => import('./Coupon'))
const AppRange = React.lazy(() => import('./AppRange'))
const DocumentManagement = React.lazy(() => import('./DocumentManagement'))
const Notifications = React.lazy(() => import('./Notifications'))

export const globalSettingsConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/prizedraw',
    element: <PrizeDraw />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/generalsetting',
    element: <GeneralSetting />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/smtp',
    element: <SMTP />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/group',
    element: <Group />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/contacts',
    element: <Contacts />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/documents',
    element: <Documents />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/emails',
    element: <Emails />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/recipients',
    element: <Recipients />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/reports/:id',
    element: <Reports />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/coupon',
    element: <Coupon />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/app-range',
    element: <AppRange />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/document-management-setting',
    element: <DocumentManagement />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setting/notifications',
    element: <Notifications />,
  },
]
