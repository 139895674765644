import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import { Grid, Typography } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import AppLngSwitcher from '@crema/core/AppLngSwitcher'
import Box from '@mui/material/Box'
import AppSearchBar from '@crema/core/AppSearchBar'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import { toggleNavCollapsed } from '../../../../../store/settings/settingsSlice'
import MenuIcon from '@mui/icons-material/Menu'
import { useDispatch } from 'react-redux'
import AppMessages from '../../../AppMessages'
import AppNotifications from '../../../AppNotifications'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AppTooltip from '../../../AppTooltip'
import { alpha } from '@mui/material/styles'
import AppLogo from '../../components/AppLogo'
import Logo from '../../../../../assets/icon/piiinklogo-final-2.png'
import CountrySelect from 'components/DropdownComponents/TempCountrySelect'
import { Form, Formik, Field, FieldArray } from 'formik'
import { connect } from 'react-redux'
import { setGlobalCountry } from 'store/siteCoordinator/siteCoordinatorSlice'

const AppHeader = ({
  dropDown: { countryList },
  auth: { currentUser, isAuthenticated },
  onSetGlobalCountry,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedCountry, setSelectedCountry] = React.useState<any>(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const dispatch = useDispatch()

  return (
    <AppBar
      position="relative"
      color="inherit"
      sx={{
        boxShadow: 'none',
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        backgroundColor: 'background.paper',
        width: {
          xs: '100%',
        },
      }}
      className="app-bar"
    >
      <Toolbar
        sx={{
          boxSizing: 'border-box',
          minHeight: { xs: 56, sm: 70 },
          paddingLeft: { xs: 5 },
          paddingRight: { xs: 5, md: 7.5, xl: 12.5 },
        }}
      >
        <Hidden lgUp>
          <IconButton
            sx={{
              color: 'text.secondary',
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleNavCollapsed())}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <img
            style={{ padding: '0.5rem' }}
            src={Logo}
            width="100"
            height="100"
            alt="logo"
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        />
        {isAuthenticated && (
          <Grid container justifyContent="flex-end">
            <Grid item lg={2} md={4} sm={6} xs={8}>
              <Typography variant="h4" component="h2">
                {currentUser?.userCountry?.countryName}
              </Typography>
            </Grid>
          </Grid>
        )}

        {/* {isAuthenticated && currentUser?.role?.toLowerCase() === 'globaladmin' && (
          <Grid container justifyContent="flex-end">
            <Grid item lg={2} md={4} sm={6} xs={8}>
              <Typography variant="h4" component="h2">
                {currentUser?.userCountry?.countryName}
              </Typography>
            </Grid>
          </Grid>
        )} */}

        <AppLngSwitcher iconOnly={true} tooltipPosition="bottom" />
        <Box sx={{ ml: 4 }}>
          <Hidden smDown>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              {/* <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppNotifications />
              </Box> */}
              {/* <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppMessages />
              </Box> */}
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppTooltip title="More">
                  <IconButton
                    sx={{
                      borderRadius: '50%',
                      width: 40,
                      height: 40,
                      color: (theme) => theme.palette.text.secondary,
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                      border: 1,
                      borderColor: 'transparent',
                      '&:hover, &:focus': {
                        color: (theme) => theme.palette.text.primary,
                        backgroundColor: (theme) =>
                          alpha(theme.palette.background.default, 0.9),
                        borderColor: (theme) =>
                          alpha(theme.palette.text.secondary, 0.25),
                      },
                    }}
                    onClick={handleClick}
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                </AppTooltip>
              </Box>
            </Box>
          </Hidden>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <AppNotifications isMenu />
            </MenuItem>
            <MenuItem>
              <AppMessages isMenu />
            </MenuItem>
            <MenuItem>Setting</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  dropDown: state.dropDown,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  alert: state.alert,
})

const mapDispatchToProps = (dispatch) => ({
  onSetGlobalCountry: (payload) => dispatch(setGlobalCountry(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container(AppHeader)
