import React, { ReactNode } from 'react'
import { GrHome } from 'react-icons/gr'
import { HiOutlineGlobe } from 'react-icons/hi'
import { FaUsers, FaGlobe, FaGlobeAmericas, FaHandshake } from 'react-icons/fa'
import {
  MdOutlineStorefront,
  MdOutlineHomeWork,
  MdOutlinePayment,
} from 'react-icons/md'
import { RoutePermittedRole } from '../../shared/constants/AppConst'
import {
  AttachMoney,
  HeadsetMic,
  PlayArrow,
  Policy,
  MonetizationOn,
} from '@mui/icons-material'
import { FiUser } from 'react-icons/fi'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import LogoutIcon from '@mui/icons-material/Logout'
import ArticleIcon from '@mui/icons-material/Article'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import LockClockIcon from '@mui/icons-material/LockClock'
import PaymentIcon from '@mui/icons-material/Payment'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const countryAdminRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            messageId: 'Dashboard',
            type: 'item',
            url: '/dashboard',
          },
        ],
      },
      {
        id: 'myAccount',
        title: 'myAccount',
        messageId: 'My Account',
        type: 'item',
        // icon: <PersonOutline />,
        icon: <FiUser />,
        url: '/my-account',
      },
      {
        id: 'members',
        title: 'Members',
        messageId: 'Members',
        type: 'item',
        icon: <FaUsers />,
        url: '/country-licensee/membersList',
      },
      {
        id: 'temporaryPassword',
        title: 'Temporary Password',
        messageId: 'Temporary Password',
        type: 'item',
        icon: <LockClockIcon />,
        url: '/country-licensee/setting/temporary-password',
      },
      {
        id: 'Merchants',
        title: 'Merchants',
        messageId: 'Merchants',
        type: 'collapse',
        icon: <MdOutlineStorefront />,
        children: [
          {
            id: 'pendingMerchants',
            title: 'Pending Merchant Requests',
            messageId: 'Pending Merchant Requests',
            type: 'item',
            url: '/country-licensee/pendingMerchantList',
          },

          {
            id: 'Add Merchant',
            title: 'Add Merchant',
            messageId: 'Add Merchant',
            type: 'item',
            url: '/country-licensee/addMerchant',
          },
          {
            id: 'draftList',
            title: 'Draft List',
            messageId: 'Draft List',
            type: 'item',
            url: '/country-licensee/draftList',
          },
          {
            id: 'merchantList',
            title: 'Merchant List',
            messageId: 'Merchant List',
            type: 'item',
            url: '/country-licensee/merchantList',
          },
          {
            id: 'referredMerchantList',
            title: 'Referred Merchant List',
            messageId: 'Referred Merchant List',
            type: 'item',
            url: '/country-licensee/referredMerchantList',
          },
          {
            id: 'merchantActivityReports',
            title: 'Merchant Activity Reports',
            messageId: 'Merchant Activity Reports',
            type: 'item',
            url: '/country-licensee/merchant-activity-reports',
          },
          // {
          //   id: 'sendMerchantEmail',
          //   title: 'Send Merchant Email',
          //   messageId: 'Send Merchant Email',
          //   type: 'item',
          //   url: '/country-licensee/sendMerchantEmail',
          // },
          // {
          //   id: 'expiringSubscriptions',
          //   title: 'Expiring Subscriptions',
          //   messageId: 'Expiring Subscriptions',
          //   type: 'item',
          //   url: '/country-licensee/expiringSubscriptions',
          // },
        ],
      },

      {
        id: 'GroupMerchants',
        title: 'Group Merchants',
        messageId: 'Group Merchants',
        type: 'collapse',
        icon: <FaUsers />,
        children: [
          {
            id: 'PendingMerchantsRequest',
            title: 'Pending  Requests',
            messageId: 'Pending  Requests',
            type: 'item',
            url: '/country-licensee/merchantgroup/pendingrequest',
          },

          {
            id: 'AddGroupMerchant',
            title: 'Add Group Merchant',
            messageId: 'Add Group Merchant',
            type: 'item',
            url: '/country-licensee/merchantgroup/addnewmerchantgroup',
          },

          {
            id: 'GroupMerchantList',
            title: 'Group Merchant List',
            messageId: 'Group Merchant List',
            type: 'item',
            url: '/country-licensee/merchantgroup/groupmerchantlist',
          },
        ],
      },

      {
        id: 'stateowners',
        title: 'State Licensee',
        messageId: 'State Licensee',
        type: 'collapse',
        icon: <FaGlobe />,
        children: [
          {
            id: 'pendingstateowner',
            title: 'Pending State Licensee',
            messageId: 'Pending Requests',
            type: 'item',
            url: '/country-licensee/pendingstateowner',
          },
          {
            id: 'stateownerlist',
            title: 'State Licensee List',
            messageId: 'State Licensee List',
            type: 'item',
            url: '/country-licensee/stateownerlist',
          },
        ],
      },
      {
        id: 'regionowners',
        title: 'Region Licensee',
        messageId: 'Region Licensee',
        type: 'collapse',
        icon: <HiOutlineGlobe />,
        children: [
          {
            id: 'pendingregionowner',
            title: 'Pending Region Licensee',
            messageId: 'Pending Requests',
            type: 'item',
            url: '/country-licensee/pendingregionowner',
          },
          {
            id: 'regionownerlist',
            title: 'Region Licensee List',
            messageId: 'Region Licensee List',
            type: 'item',
            url: '/country-licensee/regionownerlist',
          },
        ],
      },
      {
        id: 'areaowners',
        title: 'Area Licensee',
        messageId: 'Area Licensee',
        type: 'collapse',
        icon: <FaGlobeAmericas />,
        children: [
          {
            id: 'pendingareaowner',
            title: 'Pending Area Licensee',
            messageId: 'Pending Requests',
            type: 'item',
            url: '/country-licensee/pendingareaowner',
          },
          {
            id: 'areaownerlist',
            title: 'Area Licensee List',
            messageId: 'Area Licensee List',
            type: 'item',
            url: '/country-licensee/areaownerlist',
          },
        ],
      },
      {
        id: 'Sales Agents',
        title: 'Sales Agents',
        messageId: 'Sales Agents',
        type: 'collapse',
        icon: <FaUsers />,
        children: [
          {
            id: 'pendingSalesAgent',
            title: 'Pending Sales Agent',
            messageId: 'Pending Requests',
            type: 'item',
            url: '/country-licensee/pendingSalesAgent',
          },
          {
            id: 'salesAgentList',
            title: 'Sales Agent List',
            messageId: 'Sales Agent List',
            type: 'item',
            url: '/country-licensee/salesAgentList',
          },
        ],
      },
      {
        id: 'collaborationpartners',
        title: 'Collaboration Partners',
        messageId: 'Collaboration Partners',
        type: 'collapse',
        icon: <FaHandshake />,
        children: [
          {
            id: 'collaborationpartnerlist',
            title: 'Collaboration Partner List',
            messageId: 'Collaboration Partner List',
            type: 'item',
            url: '/country-licensee/collaborationpartnerlist',
          },
          {
            id: 'referredpartnerlist',
            title: 'Referred Partner List',
            messageId: 'Referred Partner List',
            type: 'item',
            url: '/country-licensee/referredpartnerlist',
          },
        ],
      },
      {
        id: 'Charities',
        title: 'Charities',
        messageId: 'Charities',
        type: 'collapse',
        icon: <MdOutlineHomeWork />,
        children: [
          {
            id: 'pendingCharities',
            title: 'Pending Charities',
            messageId: 'Pending Requests',
            type: 'item',
            url: '/country-licensee/pendingcharities',
          },
          {
            id: 'charitiesList',
            title: 'Charities List',
            messageId: 'Charities List',
            type: 'item',
            url: '/country-licensee/charitieslist',
          },
        ],
      },

      {
        id: 'Club',
        title: 'Club',
        messageId: 'Club',
        type: 'collapse',
        icon: <MdOutlineHomeWork />,
        children: [
          {
            id: 'pendingClub',
            title: 'Pending Club',
            messageId: 'Pending Requests',
            type: 'item',
            url: '/country-licensee/pendingClub',
          },
          {
            id: 'clubList',
            title: 'Club List',
            messageId: 'Club List',
            type: 'item',
            url: '/country-licensee/clubList',
          },
        ],
      },
      {
        id: 'PremiumCodes',
        title: 'Codes',
        messageId: 'Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'premiummerchantcodes',
            title: 'Merchant Codes',
            messageId: 'Merchant Codes',
            type: 'item',
            url: '/country-licensee/premium-merchant-codes',
          },
          {
            id: 'premiummembercodes',
            title: 'Premium Member Codes',
            messageId: 'Premium Member Codes',
            type: 'item',
            url: '/country-licensee/premium-member-codes',
          },
        ],
      },
      {
        id: 'QrCodes',
        title: 'QrCodes',
        messageId: 'QR Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'transactionQrCodes',
            title: 'Transaction QR Codes',
            messageId: 'Transaction QR Codes',
            type: 'item',
            url: '/country-licensee/transaction-qr-codes',
          },
          {
            id: 'issuerQrCodes',
            title: 'Issuer QR Codes',
            messageId: 'Issuer QR Codes',
            type: 'item',
            url: '/country-licensee/issuer-qr-codes',
          },
        ],
      },

      {
        icon: <AttachMoney />,
        id: 'friendshipearning',
        title: 'FriendshipEarning',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          {
            id: 'fromCountryPool',
            title: 'fromCountryPool',
            messageId: 'From Country Pool',
            type: 'item',
            url: '/country-licensee/friendship-earning/fromCountryPool',
          },
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/country-licensee/friendship-earning/asissuer',
          },
          {
            id: 'asMerchantSigner',
            title: 'AsMerchantSigner',
            messageId: 'As Merchant Signer',
            type: 'item',
            url: '/country-licensee/friendship-earning/asmerchantsigner',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/country-licensee/friendship-earning/ascpsigner',
          },
        ],
      },

      {
        icon: <AttachMoney />,
        id: 'countryfriendshipearning',
        title: 'CountryFriendshipEarning',
        messageId: 'Country Friendship Earning',
        type: 'collapse',
        children: [
          {
            id: 'totalTransactions',
            title: 'Total Transactions',
            messageId: 'Total Transactions',
            type: 'item',
            url: '/country-licensee/friendship-earning/country/total-transactions',
          },
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/country-licensee/friendship-earning/country/asissuer',
          },
          {
            id: 'asMerchantSigner',
            title: 'AsMerchantSigner',
            messageId: 'As Merchant Signer',
            type: 'item',
            url: '/country-licensee/friendship-earning/country/asmerchantsigner',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/country-licensee/friendship-earning/country/ascpsigner',
          },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'memberearning',
        title: 'memberhipEarning',
        messageId: 'Membership Earning',
        type: 'collapse',
        children: [
          {
            id: 'fromCountryPool',
            title: 'fromCountryPool',
            messageId: 'From Country Pool',
            type: 'item',
            url: '/country-licensee/membership-earning/fromCountryPool',
          },
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/country-licensee/membership-earning/asissuer',
          },

          {
            id: 'asCharityClub',
            title: 'AsCharityClub',
            messageId: 'As Club/Charity Signer',
            type: 'item',
            url: '/country-licensee/membership-earning/asclubcharity',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/country-licensee/membership-earning/ascpsigner',
          },
          // {
          //   id: 'fromMemberPremiumCode',
          //   title: 'fromMemberPremiumCode',
          //   messageId: 'From Member Premium Code',
          //   type: 'item',
          //   url: '/country-licensee/membership-earning/fromMemberPremiumCode',
          // },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'countrymemberearning',
        title: 'countrymembershipEarning',
        messageId: 'Country Membership Earning',
        type: 'collapse',
        children: [
          {
            id: 'totalTransactions',
            title: 'totalTransactions',
            messageId: 'Total Transactions',
            type: 'item',
            url: '/country-licensee/membership-earning/country/total-transactions',
          },
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/country-licensee/membership-earning/country/asissuer',
          },

          {
            id: 'asCharityClub',
            title: 'AsCharityClub',
            messageId: 'As Club/Charity Signer',
            type: 'item',
            url: '/country-licensee/membership-earning/country/asclubcharity',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/country-licensee/membership-earning/country/ascpsigner',
          },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'merchantPackage',
        title: 'merchantPackage',
        messageId: 'Merchant Package',
        type: 'collapse',
        children: [
          {
            id: 'earning',
            title: 'earning',
            messageId: 'Earning',
            type: 'item',
            url: '/country-licensee/merchant-package/earning',
          },
          {
            id: 'payable',
            title: 'payable',
            messageId: 'Payable',
            type: 'item',
            url: '/country-licensee/merchant-package/payable',
          },
        ],
      },
      {
        icon: <PlayArrow />,
        id: 'training',
        title: 'training',
        messageId: 'Trainings',
        type: 'item',
        url: '/common/trainings',
        // url: '/training-videos',
      },
      {
        icon: <HeadsetMic />,
        id: 'support',
        title: 'support',
        messageId: 'Contact Piiink',
        type: 'item',
        url: '/support',
      },

      //panda docs section starts
      {
        id: 'documentManagement',
        title: 'document Management',
        messageId: 'Document Management',
        type: 'collapse',
        icon: <ContentPasteIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/countryadmin/setting/smtp',
          // },
          {
            id: 'group',
            title: 'Group',
            messageId: 'Group',
            type: 'item',
            url: '/country-licensee/setting/group',
          },

          {
            id: 'contacts',
            title: 'Contacts',
            messageId: 'Contacts',
            type: 'item',
            url: '/country-licensee/setting/contacts',
          },

          {
            id: 'documents',
            title: 'Documents',
            messageId: 'Documents',
            type: 'item',
            url: '/country-licensee/setting/documents',
          },

          {
            id: 'emails',
            title: 'Emails',
            messageId: 'Emails',
            type: 'item',
            url: '/country-licensee/setting/emails',
          },
        ],
      },

      //panda docs section ends
      {
        id: 'payments',
        title: 'payments',
        messageId: 'Payments',
        type: 'collapse',
        icon: <PaymentIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/salesagent/setting/smtp',
          // },
          {
            id: 'connect stripe',
            title: 'connect stripe',
            messageId: 'Connect Stripe',
            type: 'item',
            url: '/country-licensee/payment/connect-stripe',
          },
        ],
      },

      {
        icon: <MonetizationOn />,
        id: 'prizedraw',
        title: 'prizedraw',
        messageId: 'Prize Draw',
        type: 'item',
        url: '/prizedraw',
      },
      {
        icon: <FileOpenIcon />,
        id: 'agreement',
        title: 'agreement',
        messageId: 'Agreement',
        type: 'item',
        url: '/country-licensee/agreement',
      },

      {
        icon: <Policy />,
        id: 'terms',
        title: 'terms',
        messageId: 'Terms & Conditions',
        type: 'item',
        url: '/terms',
      },

      {
        icon: <ArticleIcon />,
        id: 'reviews',
        title: 'reviews',
        messageId: 'Reviews',
        type: 'item',
        url: '/country-licensee/reviews',
      },

      {
        icon: <ReceiptIcon />,
        id: 'coupons',
        title: 'coupons',
        messageId: 'Coupons',
        type: 'item',
        url: '/country-licensee/coupons',
      },

      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },
    ],
  },
]
export default countryAdminRoutesConfig
