import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
} from './changePasswordSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import { changePassword } from '../../api/changePassword'

import _ from 'lodash'
import { removeParam } from 'utils'

export function* changePasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(changePassword(payload.params))

    yield put(changePasswordSuccess(data?.data))
    yield payload.resetForm()
    yield put(
      openAlert({
        message: 'Password changed successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(changePasswordFailure(err))
  }
}

export function* watchChangePassword() {
  yield takeLatest('changePassword/changePasswordStart', changePasswordAsync)
}

export function* changePasswordSagas() {
  yield all([call(watchChangePassword)])
}
