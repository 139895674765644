import { createSlice } from '@reduxjs/toolkit'

import { IWhiteLabelState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IWhiteLabelState = {
  whiteLabelList: listObj,
  soleWhiteLabel: {},
  whiteLabelProfileInfo: listObj,
  soleWhiteLabelFriendShip: {},
  soleWhiteLabelMemberShip: {},
  soleWhiteLabelUser: {},
  whiteLabelCPFriendshipExternalAllocationByWhiteLabelId: {},
  whiteLabelCPMembershipExternalAllocationByWhiteLabelId: {},
  pendingWhiteLabelList: listObj,
  whiteLabelUserByWhiteLabel: listObj,
  whiteLabelEligibilityList: listObj,
  whiteLabelPositionList: listObj,
  whiteLabelSubpositionList: listObj,
  whiteLabelFriendShipList: listObj,
  whiteLabelMemberShipList: listObj,
  whiteLabelUserList: listObj,
  whiteLabelUserListByPanel: listObj,
  whiteLabelPromotionalLevelList: listObj,
  whiteLabelCPFriendshipScenariosList: listObj,
  whiteLabelCPMembershipScenariosList: listObj,
  soleWhiteLabelCPFriendshipScenario: listObj,
  soleWhiteLabelCPMembershipScenario: listObj,
  error: null,
  loading: false,
  isEditing: false,
  isApproving: false,
  isFetching: false,
  isFetchingSole: false,
  isVerifing: false,
  isRejecting: false,
  isCreating: false,
  hasMoreData: false,
}

export const whiteLabelSlice = createSlice({
  name: 'whiteLabel',
  initialState: INITIAL_STATE,
  reducers: {
    /*------white Label actions starts----*/

    fetchWhiteLabelStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.whiteLabelList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchWhiteLabelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPendingWhiteLabelStart: (state: any, action) => {
      state.loading = true
    },
    fetchPendingWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pendingWhiteLabelList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPendingWhiteLabelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchWhiteLabelByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleWhiteLabel = action.payload

      state.loading = false
    },
    fetchWhiteLabelByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deletePendingWhiteLabelStart: (state: any, action) => {
      state.loading = true
    },
    deletePendingWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.pendingWhiteLabelList = state.pendingWhiteLabelList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePendingWhiteLabelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteWhiteLabelStart: (state: any, action) => {
      state.loading = true
    },
    deleteWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.whiteLabelList.data = state.whiteLabelList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteWhiteLabelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createWhiteLabelStart: (state: any, action) => {
      state.isCreating = true
    },
    createWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isCreating = false
      // state.whiteLabelList.data = [...state.whiteLabelList.data, action.payload]
    },
    createWhiteLabelFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editWhiteLabelStart: (state: any, action) => {
      state.isEditing = true
    },
    editWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      const result = state.whiteLabelList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.whiteLabelList.data = result

      state.isEditing = false
    },
    editWhiteLabelFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    verifyWhiteLabelStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifyWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isVerifing = false
      state.whiteLabelList.data = [...state.whiteLabelList.data, action.payload]
    },
    verifyWhiteLabelFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },
    rejectWhiteLabelStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.pendingWhiteLabelList.data =
        state.pendingWhiteLabelList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    rejectWhiteLabelFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },

    updatePendingWhiteLabelStart: (state: any, action) => {
      state.isEditing = true
    },
    updatePendingWhiteLabelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isEditing = false
      state.whiteLabelList.data = [...state.whiteLabelList.data, action.payload]
    },
    updatePendingWhiteLabelFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    /*------white Label actions ends----*/

    /*------white Label Postion actions starts----*/

    createWhiteLabelPositionStart: (state: any, action) => {
      state.isCreating = true
    },
    createWhiteLabelPositionSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isCreating = false
      // state.whiteLabelPositionList.data = [
      //   ...state.whiteLabelPositionList.data,
      //   action.payload,
      // ]
    },
    createWhiteLabelPositionFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchWhiteLabelPositionStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelPositionSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.whiteLabelPositionList = action.payload
      state.loading = false
    },
    fetchWhiteLabelPositionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllUniqueWhiteLabelPositionStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllUniqueWhiteLabelPositionSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.whiteLabelPositionList = {
        ...action.payload,
        ...action?.payload?.data?.sort((a, b) => a?.position - b?.position),
      }
      state.loading = false
    },
    fetchAllUniqueWhiteLabelPositionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editWhiteLabelPositionStart: (state: any, action) => {
      state.isEditing = true
    },
    editWhiteLabelPositionSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editWhiteLabelPositionFailure: (state: any, action) => {
      state.isEditing = false
    },

    editWhiteLabelNonZeroPromotionalLevelStart: (state: any, action) => {
      state.isEditing = true
    },
    editWhiteLabelNonZeroPromotionalLevelSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editWhiteLabelNonZeroPromotionalLevelFailure: (state: any, action) => {
      state.isEditing = false
    },
    /*------white Label Postion actions ends----*/

    /*------white Label Sub - Postion actions starts----*/

    createWhiteLabelSubpositionStart: (state: any, action) => {
      state.isCreating = true
    },
    createWhiteLabelSubpositionSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isCreating = false
      // state.whiteLabelSubpositionList.data = [
      //   ...state.whiteLabelSubpositionList.data,
      //   action.payload,
      // ]
    },
    createWhiteLabelSubpositionFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchWhiteLabelSubpositionStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelSubpositionSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.whiteLabelSubpositionList = action.payload
      state.loading = false
    },
    fetchWhiteLabelSubpositionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editWhiteLabelSubpositionStart: (state: any, action) => {
      state.isEditing = true
    },
    editWhiteLabelSubpositionSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isEditing = false
      const result = state.whiteLabelSubpositionList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.whiteLabelSubpositionList.data = result
    },
    editWhiteLabelSubpositionFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    /*------white Label Sub - Postion actions ends----*/

    createWhiteLabelFriendShipStart: (state: any, action) => {
      state.isCreating = true
    },
    createWhiteLabelFriendShipSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isCreating = false
      // state.whiteLabelFriendShipList.data = [
      //   ...state.whiteLabelFriendShipList.data,
      //   action.payload,
      // ]
    },
    createWhiteLabelFriendShipFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchWhiteLabelFriendShipStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelFriendShipSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.whiteLabelFriendShipList = action.payload
    },
    fetchWhiteLabelFriendShipFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelFriendShipByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelFriendShipByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isFetching = false
      state.soleWhiteLabelFriendShip = action.payload.sort(function (a, b) {
        return ('' + a.friendshipScenario).localeCompare(b.friendshipScenario)
      })
    },
    fetchWhiteLabelFriendShipByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editWhiteLabelFriendShipStart: (state: any, action) => {
      state.isEditing = true
    },
    editWhiteLabelFriendShipSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editWhiteLabelFriendShipFailure: (state: any, action) => {
      state.isEditing = false
    },

    deleteWhiteLabelFriendShipStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteWhiteLabelFriendShipSuccess: (state: any, action) => {
      state.isDeleting = false
      state.whiteLabelFriendShipList.data =
        state.whiteLabelFriendShipList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    deleteWhiteLabelFriendShipFailure: (state: any, action) => {
      state.isDeleting = false
    },

    createWhiteLabelMemberShipStart: (state: any, action) => {
      state.isCreating = true
    },
    createWhiteLabelMemberShipSuccess: (state: any, action) => {
      state.isCreating = false
      // state.whiteLabelMemberShipList.data = [
      //   ...state.whiteLabelMemberShipList.data,
      //   action.payload,
      // ]
    },
    createWhiteLabelMemberShipFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchWhiteLabelMemberShipStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelMemberShipSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.whiteLabelMemberShipList = action.payload
    },
    fetchWhiteLabelMemberShipFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editWhiteLabelMemberShipStart: (state: any, action) => {
      state.isEditing = true
    },
    editWhiteLabelMemberShipSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editWhiteLabelMemberShipFailure: (state: any, action) => {
      state.isEditing = false
    },

    fetchWhiteLabelMemberShipByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelMemberShipByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isFetching = false
      state.soleWhiteLabelMemberShip = action.payload
    },
    fetchWhiteLabelMemberShipByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    deleteWhiteLabelMemberShipStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteWhiteLabelMemberShipSuccess: (state: any, action) => {
      state.isDeleting = false
      state.whiteLabelMemberShipList.data =
        state.whiteLabelMemberShipList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    deleteWhiteLabelMemberShipFailure: (state: any, action) => {
      state.isDeleting = false
    },

    /* white label Users actions starts*/

    createWhiteLabelUserStart: (state: any, action) => {
      state.isCreating = true
    },
    createWhiteLabelUserSuccess: (state: any, action) => {
      state.isCreating = false
      state.whiteLabelUserList.data = [
        ...state.whiteLabelUserList.data,
        action.payload,
      ]
    },
    createWhiteLabelUserFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchWhiteLabelUserStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelUserSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelUserList = action.payload
    },
    fetchWhiteLabelUserFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllWhiteLabelUserByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllWhiteLabelUserByPanelSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelUserListByPanel = action.payload
    },
    fetchAllWhiteLabelUserByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelUserByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelUserByIdSuccess: (state: any, action) => {
      state.isFetching = false
      state.soleWhiteLabelUser = action.payload
    },
    fetchWhiteLabelUserByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editWhiteLabelUserStart: (state: any, action) => {
      state.isEditing = true
    },
    editWhiteLabelUserSuccess: (state: any, action?: any) => {
      // const result = state.whiteLabelUserList.data.map((obj) =>
      //   obj.id === action.payload.id ? action.payload : obj
      // )
      // state.whiteLabelUserList.data = result
      state.isEditing = false
    },
    editWhiteLabelUserFailure: (state: any, action) => {
      state.isEditing = false
    },

    fetchWhiteLabelPromotionalLevelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelPromotionalLevelSuccess: (state: any, action) => {
      state.isFetching = false
      const filteredWhiteLabelPosition = action?.payload?.data
        ?.filter((item) => item?.promotionLevel !== 0)
        .sort((a, b) => a?.promotionLevel - b?.promotionLevel)
      state.whiteLabelPromotionalLevelList = {
        ...action.payload,
        data: filteredWhiteLabelPosition,
      }
    },
    fetchWhiteLabelPromotionalLevelFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchWhiteLabelUserByWhiteLabelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelUserByWhiteLabelSuccess: (state: any, action) => {
      state.isFetching = false
      state.whiteLabelUserByWhiteLabel = action.payload
    },
    fetchWhiteLabelUserByWhiteLabelFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    checkWhiteLabelActivationEligibilityStart: (state: any, action) => {
      state.isFetching = true
    },
    checkWhiteLabelActivationEligibilitySuccess: (state: any, action) => {
      state.isFetching = false
      state.whiteLabelEligibilityList = action.payload
    },
    checkWhiteLabelActivationEligibilityFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    whiteLabelActivateStart: (state: any, action) => {
      state.isApproving = true
    },
    whiteLabelActivateSuccess: (state: any, action) => {
      state.isApproving = false
    },
    whiteLabelActivateFailure: (state: any, action) => {
      state.isApproving = false
      state.error = action.payload
    },

    fetchWhiteLabelProfileInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelProfileInfoSuccess: (state: any, action) => {
      state.whiteLabelProfileInfo = action.payload
      state.isFetching = false
    },
    fetchWhiteLabelProfileInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createWhiteLabelCPFriendshipExternalAllocationStart: (
      state: any,
      action
    ) => {
      state.isCreating = true
    },
    createWhiteLabelCPFriendshipExternalAllocationSuccess: (
      state: any,
      action
    ) => {
      state.isCreating = false
      state.whiteLabelCPFriendshipExternalAllocationByWhiteLabelId =
        action.payload
    },
    createWhiteLabelCPFriendshipExternalAllocationFailure: (
      state: any,
      action
    ) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchWhiteLabelCPFriendshipScenariosListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelCPFriendshipScenariosListSuccess: (state: any, action) => {
      state.isFetching = false
      state.whiteLabelCPFriendshipScenariosList = action.payload
    },
    fetchWhiteLabelCPFriendshipScenariosListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdStart: (
      state: any,
      action
    ) => {
      state.whiteLabelCPFriendshipExternalAllocationByWhiteLabelId = {}
      state.isFetching = true
    },
    fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdSuccess: (
      state: any,
      action
    ) => {
      state.isFetching = false
      state.whiteLabelCPFriendshipExternalAllocationByWhiteLabelId =
        action.payload
    },
    fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdFailure: (
      state: any,
      action
    ) => {
      state.isFetching = false
      state.error = action.payload
    },

    createWhiteLabelCPFriendshipScenariosAllocationStart: (
      state: any,
      action
    ) => {
      state.isCreating = true
    },
    createWhiteLabelCPFriendshipScenariosAllocationSuccess: (
      state: any,
      action
    ) => {
      state.isCreating = false
    },
    createWhiteLabelCPFriendshipScenariosAllocationFailure: (
      state: any,
      action
    ) => {
      state.isCreating = false
      state.error = action.payload
    },

    editWhiteLabelCPFriendshipScenariosAllocationStart: (
      state: any,
      action
    ) => {
      state.isEditing = true
    },
    editWhiteLabelCPFriendshipScenariosAllocationSuccess: (
      state: any,
      action
    ) => {
      state.isEditing = false
    },
    editWhiteLabelCPFriendshipScenariosAllocationFailure: (
      state: any,
      action
    ) => {
      state.isEditing = false
    },

    createWhiteLabelCPMembershipExternalAllocationStart: (
      state: any,
      action
    ) => {
      state.isCreating = true
    },
    createWhiteLabelCPMembershipExternalAllocationSuccess: (
      state: any,
      action
    ) => {
      state.isCreating = false
      state.whiteLabelCPMembershipExternalAllocationByWhiteLabelId =
        action.payload
    },
    createWhiteLabelCPMembershipExternalAllocationFailure: (
      state: any,
      action
    ) => {
      state.isCreating = false
      state.error = action.payload
    },

    createWhiteLabelCPMembershipScenariosAllocationStart: (
      state: any,
      action
    ) => {
      state.isCreating = true
    },
    createWhiteLabelCPMembershipScenariosAllocationSuccess: (
      state: any,
      action
    ) => {
      state.isCreating = false
    },
    createWhiteLabelCPMembershipScenariosAllocationFailure: (
      state: any,
      action
    ) => {
      state.isCreating = false
      state.error = action.payload
    },

    editWhiteLabelCPMembershipScenariosAllocationStart: (
      state: any,
      action
    ) => {
      state.isEditing = true
    },
    editWhiteLabelCPMembershipScenariosAllocationSuccess: (
      state: any,
      action
    ) => {
      state.isEditing = false
    },
    editWhiteLabelCPMembershipScenariosAllocationFailure: (
      state: any,
      action
    ) => {
      state.isEditing = false
    },

    fetchWhiteLabelCPFriendshipScenarioByIdStart: (state: any, action) => {
      state.isFetchingSole = true
    },
    fetchWhiteLabelCPFriendshipScenarioByIdSuccess: (state: any, action) => {
      let orderedData =
        action?.payload?.whiteLabelUserCPFriendshipAllocations.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.whiteLabelPositionId]: item }),
          {}
        )

      state.isFetchingSole = false
      state.soleWhiteLabelCPFriendshipScenario = {
        ...action.payload,
        whiteLabelUserCPFriendshipAllocations: orderedData,
      }
    },
    fetchWhiteLabelCPFriendshipScenarioByIdFailure: (state: any, action) => {
      state.isFetchingSole = false
      state.error = action.payload
    },

    clearWhiteLabelCPFriendshipScenarioById: (state: any, action) => {
      state.soleWhiteLabelCPFriendshipScenario = {}
    },

    fetchWhiteLabelCPMembershipScenariosListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelCPMembershipScenariosListSuccess: (state: any, action) => {
      state.isFetching = false
      state.whiteLabelCPMembershipScenariosList = action.payload
    },
    fetchWhiteLabelCPMembershipScenariosListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchWhiteLabelCPMembershipScenarioByIdStart: (state: any, action) => {
      state.isFetchingSole = true
    },
    fetchWhiteLabelCPMembershipScenarioByIdSuccess: (state: any, action) => {
      let orderedData =
        action?.payload?.whiteLabelUserCPMembershipAllocations.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.whiteLabelPositionId]: item }),
          {}
        )

      state.isFetchingSole = false
      state.soleWhiteLabelCPMembershipScenario = {
        ...action.payload,
        whiteLabelUserCPMembershipAllocations: orderedData,
      }
    },
    fetchWhiteLabelCPMembershipScenarioByIdFailure: (state: any, action) => {
      state.isFetchingSole = false
      state.error = action.payload
    },

    fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdStart: (
      state: any,
      action
    ) => {
      state.whiteLabelCPMembershipExternalAllocationByWhiteLabelId = {}

      state.isFetching = true
    },
    fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdSuccess: (
      state: any,
      action
    ) => {
      state.isFetching = false
      state.whiteLabelCPMembershipExternalAllocationByWhiteLabelId =
        action.payload
    },
    fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdFailure: (
      state: any,
      action
    ) => {
      state.isFetching = false
      state.error = action.payload
    },

    clearWhiteLabelCPMembershipExternalAllocationByWhiteLabelId: (
      state: any
    ) => {
      state.whiteLabelCPMembershipExternalAllocationByWhiteLabelId = {}
    },

    editOwnWhiteLabelProfileStart: (state: any, action) => {
      state.isEditing = true
    },
    editOwnWhiteLabelProfileSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editOwnWhiteLabelProfileFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
  },
})

export const {
  /* white label actions starts*/
  fetchWhiteLabelStart,
  fetchWhiteLabelSuccess,
  fetchWhiteLabelFailure,
  deleteWhiteLabelStart,
  deleteWhiteLabelSuccess,
  deleteWhiteLabelFailure,
  fetchWhiteLabelByIdStart,
  fetchWhiteLabelByIdSuccess,
  fetchWhiteLabelByIdFailure,
  createWhiteLabelStart,
  createWhiteLabelSuccess,
  createWhiteLabelFailure,
  editWhiteLabelStart,
  editWhiteLabelSuccess,
  editWhiteLabelFailure,
  fetchPendingWhiteLabelStart,
  fetchPendingWhiteLabelSuccess,
  fetchPendingWhiteLabelFailure,
  deletePendingWhiteLabelStart,
  deletePendingWhiteLabelSuccess,
  deletePendingWhiteLabelFailure,
  verifyWhiteLabelStart,
  verifyWhiteLabelSuccess,
  verifyWhiteLabelFailure,
  rejectWhiteLabelStart,
  rejectWhiteLabelSuccess,
  rejectWhiteLabelFailure,
  updatePendingWhiteLabelStart,
  updatePendingWhiteLabelFailure,
  updatePendingWhiteLabelSuccess,
  /* white label actions ends*/

  /* white label position actions starts*/
  createWhiteLabelPositionStart,
  createWhiteLabelPositionSuccess,
  createWhiteLabelPositionFailure,
  fetchWhiteLabelPositionStart,
  fetchWhiteLabelPositionSuccess,
  fetchWhiteLabelPositionFailure,
  fetchAllUniqueWhiteLabelPositionStart,
  fetchAllUniqueWhiteLabelPositionSuccess,
  fetchAllUniqueWhiteLabelPositionFailure,
  editWhiteLabelPositionStart,
  editWhiteLabelPositionSuccess,
  editWhiteLabelPositionFailure,

  editWhiteLabelNonZeroPromotionalLevelStart,
  editWhiteLabelNonZeroPromotionalLevelSuccess,
  editWhiteLabelNonZeroPromotionalLevelFailure,

  /* white label position actions ends*/

  /* white label sub-position actions starts*/
  createWhiteLabelSubpositionStart,
  createWhiteLabelSubpositionSuccess,
  createWhiteLabelSubpositionFailure,
  fetchWhiteLabelSubpositionStart,
  fetchWhiteLabelSubpositionSuccess,
  fetchWhiteLabelSubpositionFailure,
  editWhiteLabelSubpositionStart,
  editWhiteLabelSubpositionSuccess,
  editWhiteLabelSubpositionFailure,

  /* white label sub-position actions ends*/
  createWhiteLabelFriendShipStart,
  createWhiteLabelFriendShipSuccess,
  createWhiteLabelFriendShipFailure,
  fetchWhiteLabelFriendShipStart,
  fetchWhiteLabelFriendShipSuccess,
  fetchWhiteLabelFriendShipFailure,

  fetchWhiteLabelFriendShipByIdStart,
  fetchWhiteLabelFriendShipByIdSuccess,
  fetchWhiteLabelFriendShipByIdFailure,

  editWhiteLabelFriendShipStart,
  editWhiteLabelFriendShipSuccess,
  editWhiteLabelFriendShipFailure,

  createWhiteLabelMemberShipStart,
  createWhiteLabelMemberShipSuccess,
  createWhiteLabelMemberShipFailure,

  fetchWhiteLabelMemberShipStart,
  fetchWhiteLabelMemberShipSuccess,
  fetchWhiteLabelMemberShipFailure,

  editWhiteLabelMemberShipStart,
  editWhiteLabelMemberShipSuccess,
  editWhiteLabelMemberShipFailure,

  fetchWhiteLabelMemberShipByIdStart,
  fetchWhiteLabelMemberShipByIdSuccess,
  fetchWhiteLabelMemberShipByIdFailure,

  deleteWhiteLabelFriendShipStart,
  deleteWhiteLabelFriendShipSuccess,
  deleteWhiteLabelFriendShipFailure,

  deleteWhiteLabelMemberShipStart,
  deleteWhiteLabelMemberShipSuccess,
  deleteWhiteLabelMemberShipFailure,

  /* white label Users actions starts*/
  createWhiteLabelUserStart,
  createWhiteLabelUserSuccess,
  createWhiteLabelUserFailure,
  fetchWhiteLabelUserStart,
  fetchWhiteLabelUserSuccess,
  fetchWhiteLabelUserFailure,
  fetchWhiteLabelUserByIdStart,
  fetchWhiteLabelUserByIdSuccess,
  fetchWhiteLabelUserByIdFailure,
  editWhiteLabelUserStart,
  editWhiteLabelUserSuccess,
  editWhiteLabelUserFailure,
  fetchWhiteLabelPromotionalLevelStart,
  fetchWhiteLabelPromotionalLevelSuccess,
  fetchWhiteLabelPromotionalLevelFailure,

  fetchWhiteLabelUserByWhiteLabelStart,
  fetchWhiteLabelUserByWhiteLabelSuccess,
  fetchWhiteLabelUserByWhiteLabelFailure,

  checkWhiteLabelActivationEligibilityStart,
  checkWhiteLabelActivationEligibilitySuccess,
  checkWhiteLabelActivationEligibilityFailure,

  whiteLabelActivateStart,
  whiteLabelActivateSuccess,
  whiteLabelActivateFailure,

  fetchWhiteLabelProfileInfoStart,
  fetchWhiteLabelProfileInfoSuccess,
  fetchWhiteLabelProfileInfoFailure,

  createWhiteLabelCPFriendshipExternalAllocationStart,
  createWhiteLabelCPFriendshipExternalAllocationSuccess,
  createWhiteLabelCPFriendshipExternalAllocationFailure,

  createWhiteLabelCPFriendshipScenariosAllocationStart,
  createWhiteLabelCPFriendshipScenariosAllocationSuccess,
  createWhiteLabelCPFriendshipScenariosAllocationFailure,

  editWhiteLabelCPFriendshipScenariosAllocationStart,
  editWhiteLabelCPFriendshipScenariosAllocationSuccess,
  editWhiteLabelCPFriendshipScenariosAllocationFailure,

  createWhiteLabelCPMembershipScenariosAllocationStart,
  createWhiteLabelCPMembershipScenariosAllocationSuccess,
  createWhiteLabelCPMembershipScenariosAllocationFailure,

  editWhiteLabelCPMembershipScenariosAllocationStart,
  editWhiteLabelCPMembershipScenariosAllocationSuccess,
  editWhiteLabelCPMembershipScenariosAllocationFailure,

  createWhiteLabelCPMembershipExternalAllocationStart,
  createWhiteLabelCPMembershipExternalAllocationSuccess,
  createWhiteLabelCPMembershipExternalAllocationFailure,

  fetchWhiteLabelCPFriendshipScenariosListStart,
  fetchWhiteLabelCPFriendshipScenariosListSuccess,
  fetchWhiteLabelCPFriendshipScenariosListFailure,

  fetchWhiteLabelCPFriendshipScenarioByIdStart,
  fetchWhiteLabelCPFriendshipScenarioByIdSuccess,
  fetchWhiteLabelCPFriendshipScenarioByIdFailure,

  clearWhiteLabelCPFriendshipScenarioById,

  fetchWhiteLabelCPMembershipScenariosListStart,
  fetchWhiteLabelCPMembershipScenariosListSuccess,
  fetchWhiteLabelCPMembershipScenariosListFailure,

  fetchWhiteLabelCPMembershipScenarioByIdStart,
  fetchWhiteLabelCPMembershipScenarioByIdSuccess,
  fetchWhiteLabelCPMembershipScenarioByIdFailure,

  fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdStart,
  fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdSuccess,
  fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelIdFailure,

  fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdStart,
  fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdSuccess,
  fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelIdFailure,

  fetchAllWhiteLabelUserByPanelStart,
  fetchAllWhiteLabelUserByPanelSuccess,
  fetchAllWhiteLabelUserByPanelFailure,

  clearWhiteLabelCPMembershipExternalAllocationByWhiteLabelId,

  editOwnWhiteLabelProfileStart,
  editOwnWhiteLabelProfileSuccess,
  editOwnWhiteLabelProfileFailure,
} = whiteLabelSlice.actions

export default whiteLabelSlice.reducer
