import { createSlice } from '@reduxjs/toolkit'

import { IGmcCollaborationPartner } from '../interfaces'

import { listObj } from 'utils'

const INITIAL_STATE: IGmcCollaborationPartner = {
  gmcCollaborationPartnerList: listObj,
  gmcAssignIssuerQRCodeList: listObj,
  gmcPurchaseHistoryList: listObj,
  cpPurchaseHistoryList: listObj,
  soleGmcCollaborationPartner: {},
  gmcCollaborationPartnerByEmail: {},
  gmcCollaborationPackageList: listObj,
  collaborationPackageList: listObj,
  error: {},
  loading: false,
  isEditing: false,
  hasMoreData: false,
  isUpgrading: false,
}

export const gmcCollaborationPartnerSlice = createSlice({
  name: 'gmcCollaborationPartner',
  initialState: INITIAL_STATE,
  reducers: {
    fetchGmcCollaborationPartnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchGmcCollaborationPartnerSuccess: (state: any, action) => {
      state.gmcCollaborationPartnerList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchGmcCollaborationPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchGmcCollaborationPackageStart: (state: any, action) => {
      state.loading = true
    },
    clearGmcCollaborationPackageStart: (state: any) => {
      state.collaborationPackageList = []
    },
    fetchGmcCollaborationPackageSuccess: (state: any, action) => {
      state.collaborationPackageList = action.payload.data.map((item) => {
        return { ...item, label: item.name }
      })
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchGmcCollaborationPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGmcAssignIssuerQRCodeStart: (state: any, action) => {
      state.loading = true
    },
    clearGmcAssignIssuerQRCodeStart: (state: any) => {
      state.gmcAssignIssuerQRCodeList = []
    },
    fetchGmcAssignIssuerQRCodeSuccess: (state: any, action) => {
      state.gmcAssignIssuerQRCodeList = action.payload.data.map((item) => {
        return { ...item, label: item?.issuerCode }
      })
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchGmcAssignIssuerQRCodeFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCollaborationPackageStart: (state: any, action) => {
      state.loading = true
    },
    fetchCollaborationPackageSuccess: (state: any, action) => {
      state.collaborationPackageList = action.payload.data.map((item) => {
        return { ...item, label: item.name }
      })
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCollaborationPackageFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGmcCollaborationPartnerByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchGmcCollaborationPartnerByIdSuccess: (state: any, action) => {
      state.soleGmcCollaborationPartner = action.payload
      state.loading = false
    },
    fetchGmcCollaborationPartnerByEmailStart: (state: any, action) => {
      state.loading = true
    },
    fetchGmcCollaborationPartnerByEmailSuccess: (state: any, action) => {
      state.gmcCollaborationPartnerByEmail = action.payload
      state.loading = false
    },
    fetchGmcCollaborationPartnerByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchGmcCollaborationPartnerByEmailFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteGmcCollaborationPartnerStart: (state: any, action) => {
      state.loading = true
    },
    deleteGmcCollaborationPartnerSuccess: (state: any, action) => {
      state.loading = false
      state.gmcCollaborationPartnerList =
        state.gmcCollaborationPartnerList.filter(
          (item) => item.id !== action.payload
        )
    },
    deleteGmcCollaborationPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createGmcCollaborationPartnerStart: (state: any, action) => {
      state.loading = true
    },
    createGmcCollaborationPartnerSuccess: (state: any, action) => {
      state.loading = false
      state.gmcCollaborationPartnerList.data = [
        ...state.gmcCollaborationPartnerList.data,
        action.payload,
      ]
    },
    createGmcCollaborationPartnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editGmcCollaborationPartnerStart: (state: any, action) => {
      state.isEditing = true
    },
    editGmcCollaborationPartnerSuccess: (state: any, action) => {
      state.isEditing = false
      state.gmcCollaborationPartnerList = [
        ...state.gmcCollaborationPartnerList,
        action.payload,
      ]
    },
    gmcUpgradeToCPStart: (state: any, action) => {
      state.isEditing = true
    },
    gmcUpgradeToCPSuccess: (state: any, action) => {
      state.isEditing = false
      // state.gmcCollaborationPartnerList = [
      //   ...state.gmcCollaborationPartnerList,
      //   action.payload,
      // ]
    },

    gmcUpgradeToCPFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    gmcUpgradeCPPackageStart: (state: any, action) => {
      state.isEditing = true
    },
    gmcUpgradeCPPackageSuccess: (state: any, action) => {
      state.isEditing = false
      // state.gmcCollaborationPartnerList = [
      //   ...state.gmcCollaborationPartnerList,
      //   action.payload,
      // ]
    },
    gmcUpgradeCPPackageFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    gmcUpdateCPPaymentStatusStart: (state: any, action) => {
      state.isEditing = true
    },
    gmcUpdateCPPaymentStatusSuccess: (state: any, action) => {
      state.isEditing = false
    },
    gmcUpdateCPPaymentStatusFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    editGmcCollaborationPartnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
    resetUpgradeCPStart: (state: any, action) => {
      state.gmcCollaborationPartnerByEmail = null
    },
    fetchGmcPurchaseHistoryStart: (state: any, action) => {
      state.loading = true
    },
    fetchGmcPurchaseHistorySuccess: (state: any, action) => {
      state.gmcPurchaseHistoryList.data = [action.payload]
      state.loading = false
    },
    fetchGmcPurchaseHistoryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCpPurchaseHistoryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCpPurchaseHistorySuccess: (state: any, action) => {
      state.cpPurchaseHistoryList = action.payload
      state.loading = false
    },
    fetchCpPurchaseHistoryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    upgradeCPPackageRepurchaseStart: (state: any, action) => {
      state.isUpgrading = true
    },
    upgradeCPPackageRepurchaseSuccess: (state: any, action) => {
      state.isUpgrading = false
    },
    upgradeCPPackageRepurchaseFailure: (state: any, action) => {
      state.isUpgrading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchGmcCollaborationPartnerStart,
  fetchGmcCollaborationPartnerSuccess,
  fetchGmcCollaborationPartnerFailure,
  deleteGmcCollaborationPartnerStart,
  deleteGmcCollaborationPartnerSuccess,
  deleteGmcCollaborationPartnerFailure,
  fetchGmcCollaborationPartnerByIdStart,
  fetchGmcCollaborationPartnerByIdSuccess,
  fetchGmcCollaborationPartnerByEmailStart,
  fetchGmcCollaborationPartnerByEmailSuccess,
  fetchGmcCollaborationPackageStart,
  fetchGmcCollaborationPackageSuccess,
  fetchGmcCollaborationPartnerByIdFailure,
  fetchGmcCollaborationPartnerByEmailFailure,
  fetchGmcCollaborationPackageFailure,
  createGmcCollaborationPartnerStart,
  createGmcCollaborationPartnerSuccess,
  createGmcCollaborationPartnerFailure,
  editGmcCollaborationPartnerStart,
  editGmcCollaborationPartnerSuccess,
  editGmcCollaborationPartnerFailure,
  gmcUpgradeToCPStart,
  gmcUpgradeToCPSuccess,
  gmcUpgradeToCPFailure,
  fetchCollaborationPackageStart,
  fetchCollaborationPackageSuccess,
  fetchCollaborationPackageFailure,
  resetUpgradeCPStart,
  gmcUpgradeCPPackageStart,
  gmcUpgradeCPPackageSuccess,
  gmcUpgradeCPPackageFailure,
  fetchGmcPurchaseHistoryStart,
  fetchGmcPurchaseHistorySuccess,
  fetchGmcPurchaseHistoryFailure,
  clearGmcCollaborationPackageStart,
  fetchGmcAssignIssuerQRCodeStart,
  fetchGmcAssignIssuerQRCodeSuccess,
  fetchGmcAssignIssuerQRCodeFailure,
  clearGmcAssignIssuerQRCodeStart,
  gmcUpdateCPPaymentStatusStart,
  gmcUpdateCPPaymentStatusSuccess,
  gmcUpdateCPPaymentStatusFailure,

  fetchCpPurchaseHistoryStart,
  fetchCpPurchaseHistorySuccess,
  fetchCpPurchaseHistoryFailure,

  upgradeCPPackageRepurchaseStart,
  upgradeCPPackageRepurchaseSuccess,
  upgradeCPPackageRepurchaseFailure,
} = gmcCollaborationPartnerSlice.actions

export default gmcCollaborationPartnerSlice.reducer
