/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'
import {
  createAboutUsStart,
} from 'store/websiteAdmin/websiteAdminSlice'
import { openAlert } from 'store/alert/alertSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  websiteAdmin: state.websiteAdmin,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateSectionStart: (payload) => dispatch(createAboutUsStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
