import React from 'react'
import { TableRow, TableCell, Typography } from '@mui/material'

interface AddNewCountryProps {
  colSpan?: number
}

const TableNotFound: React.FC<AddNewCountryProps> = ({ colSpan, ...props }) => {
  return (
    <TableRow className="item-hover">
      <TableCell colSpan={colSpan ? colSpan : 7} align="center">
        <Typography variant="h4">----- No Data Found ----- </Typography>
      </TableCell>
    </TableRow>
  )
}

export default TableNotFound
