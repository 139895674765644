// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields, bulkDownloadAxiosRequest } from 'utils'

//all transactioncode without filter
export const fetchTransactionCodeListAll = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCode/getAllByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTransactionCodeListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCode/getAllUnUsedCodesByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

//used for countryPanel
export const fetchNotUsedTransactionCodeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCode/getAllNotUsedCodes`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTransactionCodeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCode/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAssigner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCode/getMultipleAssigner`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createTransactionBatch = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/transactionCodeBatch/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editTransactionBatch = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/transactionCodeBatch/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const addAssigner = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/transactionCode/addAssigner`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const addWhiteLabelAssigner = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/globalSetting/transactionCode/addWhiteLabelAssignerToCodes`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const addGMCAssigner = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/globalSetting/transactionCode/assignMultipleCodesToGMC`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createTransactionBatchCSV = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/transactionCode/printedStatusChangeCSV`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIssuerTransactionCodeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCode/getMultipleAssigner`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTransactionBatch = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCodeBatch/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGmcTransactionCodeBatchList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCodeBatch/getAllGMCBatch`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTransactionCodeAssignableCountForOthers = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCode/assignable/count`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTransactionCodeAssignableCountForGMC = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCode/assignable/byGMC/count/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getAllGMCCodesbybatch = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/globalSetting/transactionCode/getAllGMCCodesbybatch/${params?.batchId}`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTransactionQrCodesListByMerchantId = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCode/getAllTransactionCode/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOwnTransactionCode = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getOwnTransactionCode`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteTransactionCodeById = (id) => {
  return () =>
    axiosRequest
      .delete(
        `/api/globalSetting/transactionCode/deleteMerchantAssignedCode/${id}`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const updateContactInformationCSV = (params) => {
  return () =>
    axiosRequest
      .post(`/api/documents/contactInformation/updateCodesFromCSV`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createaDocumentInformationCSV = (params) => {
  return () =>
    axiosRequest
      .post(`/api/documents/uploadDocument/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const updateDocumentInformationCSV = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/documents/uploadDocument/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteTransactionCodeBatch = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/transactionCodeBatch/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteTransactionCode = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/transactionCode/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const downloadPreBatchTransactionCode = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/globalSetting/transactionCodeBatch/downloadPreBatch/${params?.batchName}`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const downloadBatchTransactionCode = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/globalSetting/transactionCodeBatch/downloadBatch/${params?.zipFileName}`,
        {
          responseType: 'blob',
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res?.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${params?.zipFileName}`)
        document.body.appendChild(link)
        link.click()
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOneTransactionCodeBatch = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCodeBatch/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOneTransactionQrCodePreview = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/transactionCodeBatch/getOneQrCode/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
