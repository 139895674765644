import { takeLatest, call, all, put, delay, select } from 'redux-saga/effects'
import {
  fetchMerchantCategoryStart,
  fetchMerchantCategorySuccess,
  fetchMerchantCategoryFailure,
  createMerchantCategorySuccess,
  createMerchantCategoryFailure,
  deleteMerchantCategorySuccess,
  deleteMerchantCategoryFailure,
  fetchMerchantCategoryByIdSuccess,
  fetchMerchantCategoryByIdFailure,
  editMerchantCategorySuccess,
  editMerchantCategoryFailure,
} from './merchantCategorySetupSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import { getImageUrl, uploadImage } from 'api/imageUpload'

import {
  fetchMerchantCategoryList,
  fetchMerchantSubCategoryList,
  createMerchantCategory,
  deleteMerchantCategory,
  fetchMerchantCategoryById,
  editMerchantCategory,
} from '../../api/merchantCategory'

import _ from 'lodash'
import { removeEmptyFields, removeParam } from 'utils'

export const getSiteCoordinator = (state) => state.siteCoordinator

// get all merchant categories
export function* fetchMerchantCategoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantCategoryList(payload))

    yield put(fetchMerchantCategorySuccess(data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantCategoryFailure(err))
  }
}

// get single merchant category
export function* fetchMerchantCategoryByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantCategoryById(payload))

    yield put(fetchMerchantCategoryByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantCategoryByIdFailure(err))
  }
}

// edits merchant category
export function* editMerchantCategoryAsync({ payload }: AnyAction) {
  try {
    // if (_.isEmpty(payload.image) && !payload.params.parentId ) {
    if (!payload.params.parentId && !payload.hasImage) {
      throw new Error('Image is required to edit category')
    }

    let imageUrl = ''

    if (!_.isEmpty(payload.image)) {
      const initialImageUrl = yield call(getImageUrl())
      const imageResponse = yield call(
        uploadImage(initialImageUrl?.data?.url, payload.image)
      )
      imageUrl = removeParam(imageResponse.config.url)
    }

    const uploadData = { ...payload.params }
    if (imageUrl) uploadData.imageName = imageUrl

    const { data } = yield call(
      editMerchantCategory(
        payload.params.id,
        uploadData
        // removeEmptyFields({
        //   ...payload.params,
        //   imageName: imageUrl,
        // })
      )
    )

    yield put(editMerchantCategorySuccess(data?.data))

    yield put(
      fetchMerchantCategoryStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Category successfully edited',
        severity: 'success',
      })
    )

    yield payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editMerchantCategoryFailure(err))
  }
}

// creates merchant category
export function* createMerchantCategoryAsync({ payload }: AnyAction) {
  try {
    let imageUrl = ''
    if (_.isEmpty(payload.image) && !payload.params.parentId) {
      throw new Error('Image is required to create category')
    }

    if (!_.isEmpty(payload.image)) {
      const initialImageUrl = yield call(getImageUrl())
      const imageResponse = yield call(
        uploadImage(initialImageUrl.data.url, payload.image)
      )
      imageUrl = removeParam(imageResponse.config.url)
    }

    const { data } = yield call(
      createMerchantCategory(
        removeEmptyFields({
          ...payload.params,
          imageName: imageUrl,
        })
      )
    )

    yield put(createMerchantCategorySuccess(data?.data))

    yield put(
      fetchMerchantCategoryStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )

    yield put(
      openAlert({ message: 'succesfully created', severity: 'success' })
    )

    yield payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createMerchantCategoryFailure(err))
    console.error(err)
  }
}

// deletes merchant category
export function* deleteMerchantCategoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteMerchantCategory(payload))

    if (data) {
      yield put(deleteMerchantCategorySuccess(payload))
      yield put(
        fetchMerchantCategoryStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'MerchantCategory deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteMerchantCategoryFailure(err))
  }
}

export function* watchFetchMerchantCategory() {
  yield takeLatest(
    'merchantCategorySetup/fetchMerchantCategoryStart',
    fetchMerchantCategoryAsync
  )
}

export function* watchCreateMerchantCategory() {
  yield takeLatest(
    'merchantCategorySetup/createMerchantCategoryStart',
    createMerchantCategoryAsync
  )
}

export function* watchDeleteMerchantCategory() {
  yield takeLatest(
    'merchantCategorySetup/deleteMerchantCategoryStart',
    deleteMerchantCategoryAsync
  )
}

export function* watchFetchMerchantCategoryById() {
  yield takeLatest(
    'merchantCategorySetup/fetchMerchantCategoryByIdStart',
    fetchMerchantCategoryByIdAsync
  )
}

export function* watchEditMerchantCategory() {
  yield takeLatest(
    'merchantCategorySetup/editMerchantCategoryStart',
    editMerchantCategoryAsync
  )
}

export function* merchantCategorySetupSagas() {
  yield all([
    call(watchFetchMerchantCategory),
    call(watchCreateMerchantCategory),
    call(watchDeleteMerchantCategory),
    call(watchFetchMerchantCategoryById),
    call(watchEditMerchantCategory),
  ])
}
