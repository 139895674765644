import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
const MemberRoutesSetup = React.lazy(() => import('./Members.component'))

export const memberSetupConfig = [
  {
    permittedRole: RoutePermittedRole.member,
    path: '/admin/membersetup',
    element: <MemberRoutesSetup />,
  },
]
