import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchRegionStart,
  fetchRegionSuccess,
  fetchRegionFailure,
  createRegionSuccess,
  createRegionFailure,
  deleteRegionSuccess,
  deleteRegionFailure,
  fetchRegionByIdSuccess,
  fetchRegionByIdFailure,
  editRegionSuccess,
  editRegionFailure,
} from './regionSetupSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchRegionList,
  createRegion,
  deleteRegion,
  fetchRegionById,
  editRegion,
} from '../../api/region'

import _ from 'lodash'

export function* fetchRegionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchRegionList(payload))

    yield put(fetchRegionSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchRegionFailure(err))
    console.error(err)
  }
}

export function* fetchRegionByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchRegionById(payload))

    yield put(fetchRegionByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchRegionByIdFailure(err))
    console.error(err)
  }
}

export function* editRegionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editRegion(payload.params.id, payload.params))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(editRegionSuccess(data?.data))
    yield put(
      openAlert({ message: 'Region successfully edited', severity: 'success' })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editRegionFailure(err))
    console.error(err)
  }
}

export function* createRegionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createRegion(payload.params))
    yield put(createRegionSuccess(data?.data))
    yield put(
      fetchRegionStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({ message: 'Region successfully created', severity: 'success' })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createRegionFailure(err))
    console.error(err)
  }
}

export function* deleteRegionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteRegion(payload))
    if (data) {
      yield put(deleteRegionSuccess(payload))
      yield put(
        fetchRegionStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Region deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteRegionFailure(err))
    console.error(err)
  }
}

export function* watchFetchRegion() {
  yield takeLatest('regionSetup/fetchRegionStart', fetchRegionAsync)
}

export function* watchCreateRegion() {
  yield takeLatest('regionSetup/createRegionStart', createRegionAsync)
}

export function* watchDeleteRegion() {
  yield takeLatest('regionSetup/deleteRegionStart', deleteRegionAsync)
}

export function* watchFetchRegionById() {
  yield takeLatest('regionSetup/fetchRegionByIdStart', fetchRegionByIdAsync)
}

export function* watchEditRegion() {
  yield takeLatest('regionSetup/editRegionStart', editRegionAsync)
}

export function* regionSetupSagas() {
  yield all([
    call(watchFetchRegion),
    call(watchCreateRegion),
    call(watchDeleteRegion),
    call(watchFetchRegionById),
    call(watchEditRegion),
  ])
}
