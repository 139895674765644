import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableHeading from './TableHeading'
import TableItem from './TableItem'
import {
  TablePagination,
  Box,
  Button,
  TableRow,
  CircularProgress,
  TableCell,
} from '@mui/material'
import AppTableContainer from '@crema/core/AppTableContainer'
import TableDataNotFound from 'components/TableDataNotFound'
import { CSVLink } from 'react-csv'

// import { RecentOrders } from "types/models/ecommerce/EcommerceApp";
import { CountrySetup } from 'types/models/CountrySetup'
import MerchantFormLinkModal from '../MerchantFormLinkModal'
import moment from 'moment-timezone'

interface countySetupTableProps {
  countryData: CountrySetup[]
  page: any
  setPage: any
  rowsPerPage: any
  setRowsPerPage: any
  handleChangePage: any
  handleChangeRowsPerPage: any
  hasMoreData?: boolean
  loading?: boolean
  totalCount?: number
  pageNumber?: number
  adminRoute?: any
  currentUser?: any
  onDeleteClubStart?: (payload: any) => void
  refreshPageData?: () => void
}

const CountryTable: React.FC<countySetupTableProps> = ({
  countryData,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  hasMoreData,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  pageNumber,
  onDeleteClubStart,
  refreshPageData,
  adminRoute,
  currentUser,
}) => {
  const [tempLoader, setTempLoader] = useState(true)
  const [openMerchantFormLink, setOpenMerchantFormLink] = useState(false)

  const closeMerchantFormLink = () => {
    setOpenMerchantFormLink(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  const checkSentViaEmail = (mailSentBySigner) => {
    if (mailSentBySigner == true) {
      return 'Yes'
    } else if (mailSentBySigner == false) {
      return 'No'
    } else {
      return '-'
    }
  }

  const toExportData = countryData?.length
    ? countryData?.map((item: any) => {
        return {
          Id: item?.id,
          'Merchant Name': item?.merchantName,
          'Country Name': item?.__country__?.countryName ?? '-',
          'Merchant Email': item?.merchantEmail,
          Username: item?.username,
          'Merchant Phone': item?.merchantPhoneNumber
            ? ` ${item?.__country__?.phonePrefix} ${item?.merchantPhoneNumber}`
            : '-',
          'Contact Person Name': item?.contactPersonFirstName
            ? `${item?.contactPersonFirstName} ${item?.contactPersonLastName}`
            : '-',
          'Contact Person Phone': item?.phoneNumber ?? '-',
          'Contact Person Email': item?.email ?? '-',
          'Street Info': item?.streetInfo ?? '-',
          City: item?.city ?? '-',
          'Email Sent?': item?.bulkEmailSend ? 'Yes' : 'No',
          'State/Province': item?.__state__?.stateName,
          Region: item?.__region__?.regionName,
          Area: item?.__area__?.areaName,
          'Saved via send merchant email?': checkSentViaEmail(
            item?.mailSentBySigner
          ),
          'Referred By Member?': item?.memberAsRefererId ? 'Yes' : 'No',
          'Member ID': item?.memberAsRefererId ? item?.memberAsRefererId : '-',
          Status: item?.registrationStatus,
          'Issuer Code': item?.issuerCode?.length
            ? item?.issuerCode?.map((itm, index) => itm)?.join(', ')
            : '-',
          'Transaction Code': item?.transactionCode?.length
            ? item?.transactionCode?.map((itm, index) => itm)?.join(', ')
            : '-',
          'Subscription Status':
            item?.stripeSubscriptionId || item?.isPreviouslySubscribed
              ? 'Activated'
              : 'Not Started',
          'Registration Date':
            item?.createdAt == null
              ? '-'
              : moment(item?.createdAt).format('DD-MM-YYYY'),
        }
      })
    : []

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, 1000, 2000]}
          component="div"
          count={totalCount}
          page={page}
          nextIconButtonProps={{ disabled: !hasMoreData }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button
          sx={{
            color: '#ffffff',
            display: 'flex',
            marginLeft: 'auto',
            background: '#00bfcd',
            '&:hover': {
              background: '#ec4785',
            },
            px: 5,
            mb: 5,
            mr: 5,
          }}
          onClick={() => setOpenMerchantFormLink(true)}
        >
          View sharable merchant form link
        </Button>
        <CSVLink
          data={toExportData}
          // enclosingCharacter={``}
          enclosingCharacter={`"`}
          style={{ textDecoration: 'none' }}
          filename={'Merchant List.csv'}
        >
          <Button
            sx={{
              color: '#ffffff',
              display: 'flex',
              marginLeft: 'auto',
              background: '#00bfcd',
              '&:hover': {
                background: '#ec4785',
              },
              px: 5,
              mb: 5,
            }}
          >
            Download CSV
          </Button>
        </CSVLink>
      </Box>
      <AppTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableHeading
              currentUser={currentUser}
              dataLength={countryData?.length ? countryData?.length : null}
            />
          </TableHead>
          <TableBody>
            {tempLoader || loading ? (
              <TableRow className="item-hover">
                <TableCell align="center" colSpan={12}>
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {countryData?.length ? (
                  countryData.map((data, index) => (
                    <TableItem
                      data={data}
                      key={data.sn}
                      count={index + 1}
                      onDeleteClubStart={onDeleteClubStart}
                      refreshPageData={refreshPageData}
                      pageNumber={pageNumber}
                      rowsPerPage={rowsPerPage}
                      adminRoute={adminRoute}
                      currentUser={currentUser}
                    />
                  ))
                ) : (
                  <TableDataNotFound colSpan={12} />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </AppTableContainer>
      {openMerchantFormLink && (
        <MerchantFormLinkModal
          isAddCountryOpen={openMerchantFormLink}
          onCloseAddCountry={closeMerchantFormLink}
        />
      )}
    </>
  )
}

export default CountryTable
