import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
const UserList = React.lazy(() => import('./UserList'))

export const globalUserListSetupConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/usersList',
    element: <UserList />,
  },
]
