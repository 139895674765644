import { createSlice } from '@reduxjs/toolkit'

import { IRegionOwnerState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IRegionOwnerState = {
  regionOwnerList: listObj,
  soleRegionOwner: {},
  regionOwnerProfileInfo: listObj,
  pendingRegionOwnerList: listObj,
  error: null,
  loading: false,
  isEditing: false,
  isDeleting: false,
  isVerifing: false,
  isUploading: false,
  isRejecting: false,
  hasMoreData: false,
}

export const regionOwnerSlice = createSlice({
  name: 'regionOwner',
  initialState: INITIAL_STATE,
  reducers: {
    fetchRegionOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchRegionOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.regionOwnerList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchRegionOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPendingRegionOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchPendingRegionOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pendingRegionOwnerList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPendingRegionOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchRegionOwnerByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchRegionOwnerByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleRegionOwner = action.payload

      state.loading = false
    },
    fetchRegionOwnerByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deletePendingRegionOwnerStart: (state: any, action) => {
      state.isDeleting = true
    },
    deletePendingRegionOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
      state.pendingRegionOwnerList.data =
        state.pendingRegionOwnerList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    deletePendingRegionOwnerFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    deleteRegionOwnerStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteRegionOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
      state.regionOwnerList.data = state.regionOwnerList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteRegionOwnerFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    createRegionOwnerStart: (state: any, action) => {
      state.loading = true
    },
    createRegionOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      // state.regionOwnerList.data = [
      //   ...state.regionOwnerList.data,
      //   action.payload,
      // ]
    },
    createRegionOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editRegionOwnerStart: (state: any, action) => {
      state.isEditing = true
    },
    editRegionOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;

      const result = state.regionOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.regionOwnerList.data = result
      state.isEditing = false
    },
    editRegionOwnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    verifyRegionOwnerStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifyRegionOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isVerifing = false
      state.pendingRegionOwnerList.data =
        state.pendingRegionOwnerList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    verifyRegionOwnerFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },
    rejectRegionOwnerStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectRegionOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.pendingRegionOwnerList.data =
        state.pendingRegionOwnerList.data.filter(
          (item) => item.id !== action.payload
        )
    },
    rejectRegionOwnerFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },
    updatePendingRegionOwnerStart: (state: any, action) => {
      state.isEditing = true
    },
    updatePendingRegionOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;

      const result = state.pendingRegionOwnerList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.pendingRegionOwnerList.data = result
      state.isEditing = false
    },
    updatePendingRegionOwnerFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    deleteRegionOwnerContactStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteRegionOwnerContactSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isDeleting = false
    },
    deleteRegionOwnerContactFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },
    fetchRegionOwnerProfileInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchRegionOwnerProfileInfoSuccess: (state: any, action) => {
      state.regionOwnerProfileInfo = action.payload
      state.isFetching = false
    },
    fetchRegionOwnerProfileInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    uploadAgreementStart: (state: any, action) => {
      state.isUploading = true
    },
    uploadAgreementSuccess: (state: any) => {
      state.isUploading = false
    },
    uploadAgreementFailure: (state: any, action) => {
      state.isUploading = false
      state.error = action.payload
    },

    editRegionOwnerOwnProfileInfoStart: (state: any, action) => {
      state.isEditing = true
    },
    editRegionOwnerOwnProfileInfoSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editRegionOwnerOwnProfileInfoFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
  },
})

export const {
  fetchRegionOwnerStart,
  fetchRegionOwnerSuccess,
  fetchRegionOwnerFailure,
  deleteRegionOwnerStart,
  deleteRegionOwnerSuccess,
  deleteRegionOwnerFailure,
  fetchRegionOwnerByIdStart,
  fetchRegionOwnerByIdSuccess,
  fetchRegionOwnerByIdFailure,
  createRegionOwnerStart,
  createRegionOwnerSuccess,
  createRegionOwnerFailure,
  editRegionOwnerStart,
  editRegionOwnerSuccess,
  editRegionOwnerFailure,
  fetchPendingRegionOwnerStart,
  fetchPendingRegionOwnerSuccess,
  fetchPendingRegionOwnerFailure,
  deletePendingRegionOwnerStart,
  deletePendingRegionOwnerSuccess,
  deletePendingRegionOwnerFailure,
  verifyRegionOwnerStart,
  verifyRegionOwnerSuccess,
  verifyRegionOwnerFailure,
  rejectRegionOwnerStart,
  rejectRegionOwnerSuccess,
  rejectRegionOwnerFailure,
  updatePendingRegionOwnerStart,
  updatePendingRegionOwnerSuccess,
  updatePendingRegionOwnerFailure,
  deleteRegionOwnerContactStart,
  deleteRegionOwnerContactSuccess,
  deleteRegionOwnerContactFailure,
  fetchRegionOwnerProfileInfoFailure,
  fetchRegionOwnerProfileInfoSuccess,
  fetchRegionOwnerProfileInfoStart,
  uploadAgreementStart,
  uploadAgreementSuccess,
  uploadAgreementFailure,

  editRegionOwnerOwnProfileInfoStart,
  editRegionOwnerOwnProfileInfoSuccess,
  editRegionOwnerOwnProfileInfoFailure,
} = regionOwnerSlice.actions

export default regionOwnerSlice.reducer
