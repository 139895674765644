import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import DraftList from 'components/GlobalMerchantForm/DraftList/DraftList.component'
import EditMerchantRequest from 'components/GlobalMerchantForm/DraftList/components/EditMerchantRequest/EditMerchantRequest.component'
import MerchantList from 'components/GlobalMerchantForm/MerchantList'
import SendMerchantRequest from 'components/GlobalMerchantForm/SendMerchantRequest'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import AllNotificationList from 'components/AllNotificationList'
import ConnectStripe from 'components/Payments/ConnectStripe'

const MembersList = React.lazy(() => import('./Members/MembersList'))
const TransactionQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/TransactionQrCode/QrCodes')
)

const IssuerQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/IssuerQrCode')
)

const FriendshipEarningAsCharity = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCharity')
)
const FriendshipEarningAsCharityDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCharity')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)
const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)
const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const MembershipEarningFromCharityAllocation = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromCharityAllocation')
)
const MembershipEarningFromCharityAllocationDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromCharityAllocation')
)
const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)
const MerchantCodesList = React.lazy(
  () => import('components/PremiumCodes/MerchantCodesList')
)
const MemberCodesList = React.lazy(
  () => import('components/PremiumCodes/MemberCodesList')
)

const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)

const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))
const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)

const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)

const EditMerchantFromMerchantList = React.lazy(
  () => import('components/GlobalMerchantForm/EditMerchantRequest')
)

const MerchantActivityReports = React.lazy(
  () => import('components/GlobalMerchantForm/MerchantActivityReports')
)

const MembersInfoList = React.lazy(
  () => import('components/GlobalMerchantForm/MembersList')
)

export const charityAdminConfig = [
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/premium-merchant-codes',
    element: <MerchantCodesList />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/premium-member-codes',
    element: <MemberCodesList />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/memberslist',
    element: <MembersList />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'charity'} />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'charity'} />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/editMerchantRequest/:id',
    element: <EditMerchantRequest adminRoute={'charity'} />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/merchantList',
    element: <MerchantList adminRoute={'charity'} />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/draftList',
    element: <DraftList adminRoute={'charity'} />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/transaction-qr-codes',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/issuer-qr-codes',
    element: <IssuerQrCodes />,
  },
  // charity
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/friendship-earning/ascharity',
    element: <FriendshipEarningAsCharity />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/friendship-earning/ascharity/detail',
    element: <FriendshipEarningAsCharityDetail />,
  },
  // issuer
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },
  // merchant signer
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },
  // cp signer
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/friendship-earning/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/friendship-earning/ascpsigner/detail',
    element: <FriendshipEarningAsCpSignerDetail />,
  },

  // issuer
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },
  // charity allocation
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/membership-earning/fromcharityallocation',
    element: <MembershipEarningFromCharityAllocation />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/membership-earning/fromcharityallocation/detail',
    element: <MembershipEarningFromCharityAllocationDetail />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/coupons',
    element: <Coupons adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/coupon-codes/:id',
    element: <CouponCodes />,
  },
  // {
  //   permittedRole: RoutePermittedRole.charity,
  //   path: '/charity/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/setting/smtp',
    element: <SMTP adminRoute={'charity'} />,
  },
  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/setting/group',
    element: <Group adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/setting/contacts',
    element: <Contacts adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/setting/documents',
    element: <Documents adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/setting/emails',
    element: <Emails adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/setting/reports/:id',
    element: <Reports adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/setting/recipients',
    element: <Recipients adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/merchantList/editMerchant/:id',
    element: <EditMerchantFromMerchantList adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/merchant-activity-reports',
    element: <MerchantActivityReports adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/member-list/:id',
    element: <MembersInfoList adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/all-notification',
    element: <AllNotificationList adminRoute={'charity'} />,
  },

  {
    permittedRole: RoutePermittedRole.charity,
    path: '/charity/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'charity'} />,
  },
]
