import { takeLatest, call, all, put, delay, select } from 'redux-saga/effects'
import * as merchantAction from './merchantSlice'
import { setRecentMerchant } from '../siteCoordinator/siteCoordinatorSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'
import { deleteImage, getImageUrl, uploadImage } from 'api/imageUpload'
import { removeParam, removeEmptyFields, getImageIdFromUrl } from 'utils'
import {
  createStep1Draft,
  createStep1General,
  createStep2Draft,
  createStep2General,
  createStep3Draft,
  createStep3General,
  fetchMerchantDiscountById,
  fetchMerchantGeneralList,
  fetchMerchantDraftList,
  createMerchantDiscount,
  fetchMerchantGeneralBasicInfoByPanel,
  fetchMerchantDraftBasicInfoByPanel,
  fetchMerchantDraftWebsiteInfo,
  fetchMerchantGeneralWebsiteInfo,
  fetchMerchantDraftImage,
  fetchMerchantDraftDiscount,
  fetchMerchantGeneralImage,
  fetchMerchantGeneralDiscount,
  fetchWalletInfo,
  fetchWalletInfoDetail,
  createBankInfo,
  fetchMerchantGeneralBankInfo,
  fetchAllMerchantList,
  fetchMerchantGeneralBasicInfoByAdmin,
  createStep4Draft,
  createStep4General,
  editStep1Draft,
  editStep1General,
  fetchMerchantDraftBasicInfoByAdmin,
  rejectMerchantGeneral,
  checkApplyMerchantPremiumCode,
  setMerchantPopular,
  verifyMerchantById,
  activeMerchantById,
  activateMerchantSubscription,
  fetchMerchantProfileInfo,
  fetchMerchantProfileDiscountInfo,
  fetchMerchantProfileImagesInfo,
  fetchMerchantProfileWebsiteAndBankingInfo,
  fetchBillingInfo,
  updateBankInfo,
  getMerchantCardInfo,
  merchantAddCardSource,
  merchantSetCardAsDefault,
  updateWebsite,
  updateImage,
  merchantAddCardSourceWithId,
  checkApplyMerchantPremiumCodeUpgradePackage,
  checkApplyMerchantCodeUponUpgrade,
  generateEcommerceKey,
  editOwnMerchantProfile,
  editMerchantInfo,
  sendMerchantEmail,
  createMerchantTerminal,
  fetchMerchantTerminalList,
  editMerchantTerminal,
  fetchOneMerchantTerminal,
  deactivateMerchantTerminal,
  updateMerchantTerminalPassword,
  getReferralToken,
  updateStep4General,
  updateStep2General,
  fetchReferredMerchantList,
  createTerminal,
  fetchAllTerminalList,
  fetchTerminalById,
  editTerminal,
  fetchMultipleAssignerByRole,
  transferMerchantRequest,
  fetchReferredMerchantById,
  approveReferredMerchantById,
  rejectReferredMerchantById,
  editOwnMerchantTerminalProfile,
  editReferredMerchant,
  referredMailFromDraft,
  acceptOrRejectreferredMerchant,
  fetchTerminalListByPanel,
  createMerchantGroup,
  fetchAllMerchantGroup,
  fetchMerchantGroupById,
  editMerchantGroup,
  fetchMerchantFranchisorGeneralList,
  fetchMerchantFranchiseGeneralBasicInfoPanel,
  verifyPendingMerchantGroup,
  fetchMerchantFranchisorDraftList,
  activateMerchantTerminal,
  assignIssuerTransactionCode,
  fetchMerchantListStateWiseData,
  checkApplyCouponCode,
  couponCodeLogUpdate,
  activateOrDeactivateMerchant,
  deleteMerchant,
  fetchActivityListReportByPanel,
  fetchAllMerchantMultilingual,
  addMerchantMultilingual,
  editMerchantMultilingual,
  fetchMerchantMultilingualById,
  fetchMerchantGeneralBasicInfoForStateMerchant,
  fetchAllCharityMultilingual,
  addCharityMultilingual,
  editCharityMultilingual,
  editMerchantCategoryMultilingual,
  addMerchantCategoryMultilingual,
  fetchAllMerchantCategoryMultilingual,
  fetchMerchantGeneralByLocationList,
} from 'api/merchant'

import {
  stripeCustomerIdIdUpdate,
  subscriptionIdUpdate,
} from 'store/auth/authSlice'

import _ from 'lodash'
import { Navigate } from 'react-router-dom'

export const getUserData = (state: any) => state.auth.currentUser

export function* createStep1DraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createStep1Draft(payload?.params))
    yield put(merchantAction.createStep1DraftSuccess(data?.data))

    yield put(setRecentMerchant(data?.data?.merchantDraft))
    // yield payload?.handleChangeTab(null, 1)

    yield put(
      openAlert({
        message: 'Merchant data successfully saved to draft',
        severity: 'success',
      })
    )
    if (payload?.route) {
      payload?.navigate(payload?.route)
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createStep1DraftFailure(err))
  }
}

export function* fetchBillingInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchBillingInfo(payload?.params))

    yield put(merchantAction.fetchBillingInfoSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchBillingInfoFailure(err))
    console.error(err)
  }
}

export function* fetchWalletInfoAsync({ payload }: AnyAction) {
  try {
    const { data: detail } = yield call(fetchWalletInfoDetail(payload?.params))
    const { data: info } = yield call(fetchWalletInfo(payload?.params))

    yield put(
      merchantAction.fetchWalletInfoSuccess({
        info: info?.data,
        detail: detail,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchWalletInfoFailure(err))
    console.error(err)
  }
}

export function* createStep1GeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createStep1General(payload?.params))
    yield put(merchantAction.createStep1GeneralSuccess(data?.data))

    yield put(setRecentMerchant(data?.data?.merchant))
    if (payload?.route) {
      payload?.navigate(`${payload?.route}/${data?.data?.merchant?.id}`)

      yield payload?.handleChangeTab(null, 1)
    } else {
      yield payload?.handleChangeTab(null, 1)
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant data successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createStep1GeneralFailure(err))
  }
}

export function* editStep1Async({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editStep1Draft(payload?.params?.id, payload?.params)
    )
    yield put(merchantAction.editStep1Success(data?.data))

    if (!payload?.fromDraft) {
      yield payload?.handleChangeTab(null, 1)
    } else {
      payload?.navigate(payload?.route)
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Merchant data successfully updated',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editStep1Failure(err))
  }
}

export function* editStep1GeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editStep1General(payload?.params?.id, payload?.params)
    )
    yield put(merchantAction.editStep1GeneralSuccess(data?.data))

    if (!payload?.fromDraft) {
      yield payload?.handleChangeTab(null, 1)
    } else {
      payload?.navigate(payload?.route)
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant data successfully updated',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editStep1GeneralFailure(err))
  }
}

export function* createStep2DraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createStep2Draft(payload?.params))
    yield put(merchantAction.createStep2DraftSuccess(data?.data))
    // yield payload?.handleChangeTab(null, 2)
    if (payload?.route) {
      payload?.navigate(payload?.route)
    }

    if (payload?.saveOnly) {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved',
          severity: 'success',
        })
      )
    } else {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved to draft',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createStep2DraftFailure(err))
  }
}

export function* createStep2GeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createStep2General(payload?.params))
    yield put(merchantAction.createStep2GeneralSuccess(data?.data))
    yield payload?.handleChangeTab(null, 2)

    yield put(
      openAlert({
        message: 'Merchant data successfully saved',
        severity: 'success',
      })
    )
    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          merchantAction?.fetchMerchantGeneralBasicInfoPanelAdminStart(
            payload?.params?.merchantId
          )
        )
      } else {
        yield put(
          merchantAction?.fetchMerchantDraftBasicInfoPanelUserStart(
            payload?.params?.merchantId
          )
        )
      }
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createStep2GeneralFailure(err))
  }
}

export function* createStep3DraftAsync({ payload }: AnyAction) {
  try {
    let logoUrl = ''
    let silder1Url = ''
    let silder2Url = ''
    let silder3Url = ''
    let silder4Url = ''
    let silder5Url = ''
    let silder6Url = ''

    if (!_.isEmpty(payload?.params) && payload?.params?.logoUrl?.size) {
      const initialImageUrl = yield call(getImageUrl())

      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logoUrl)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider1) &&
      payload?.params?.slider1?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider1)
        )

        silder1Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider2) &&
      payload?.params?.slider2?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider2)
        )

        silder2Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider3) &&
      payload?.params?.slider3?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider3)
        )

        silder3Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider4) &&
      payload?.params?.slider4?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider4)
        )

        silder4Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider5) &&
      payload?.params?.slider5?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider5)
        )

        silder5Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider6) &&
      payload?.params?.slider6?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider6)
        )

        silder6Url = removeParam(imageResponse.config.url)
      }
    }

    const shouldSend = [
      silder1Url,
      silder2Url,
      silder3Url,
      silder4Url,
      silder5Url,
      silder6Url,
      logoUrl,
    ].some((item) => item)

    if (shouldSend) {
      const { data } = yield call(
        createStep3Draft(
          removeEmptyFields({
            ...payload?.params,
            logoUrl,
            slider1: silder1Url,
            slider2: silder2Url,
            slider3: silder3Url,
            slider4: silder4Url,
            slider5: silder5Url,
            slider6: silder6Url,
          })
        )
      )
    }

    yield put(merchantAction.createStep3DraftSuccess())
    if (payload?.route) {
      payload?.navigate(payload?.route)
    }
    // yield payload?.handleChangeTab(null, 3)

    yield put(
      openAlert({
        message: 'Merchant data successfully saved to draft',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createStep3DraftFailure(err))
  }
}

export function* createStep3GeneralAsync({ payload }: AnyAction) {
  try {
    let sliderUrls = []
    let logoUrl = ''

    // for (let i = 0; i < payload?.sliderImage.length || 0; i++) {
    for (let i = 0; i < 6; i++) {
      if (payload.sliderImage[i]?.size) {
        const initialImageUrl = yield call(getImageUrl())

        if (!_.isEmpty(initialImageUrl?.data)) {
          const imageResponse = yield call(
            uploadImage(initialImageUrl?.data?.url, payload.sliderImage[i])
          )

          sliderUrls[i] = removeParam(imageResponse.config.url)

          if (payload?.oldImages?.sliderImage?.[i]) {
            yield call(
              deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
            )
          }
        }
      } else {
        sliderUrls[i] = payload.sliderImage[i] || null

        if (
          payload?.oldImages?.sliderImage?.[i] &&
          !payload.sliderImage.some(
            (item) => payload?.oldImages?.sliderImage?.[i] === item
          )
        ) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
          )
        }
      }
    }

    if (payload?.logoImage?.size) {
      const initialImageUrl = yield call(getImageUrl())

      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logoImage)
        )

        logoUrl = removeParam(imageResponse.config.url)

        if (payload?.oldImages?.logoImage) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.logoImage))
          )
        }
      }
    } else {
      logoUrl = payload?.logoImage
    }

    const sliderUrlObj = sliderUrls.reduce(
      (acc, val, ind) => ({ ...acc, [`slider${ind + 1}`]: val }),
      {}
    )

    if (payload?.isDraft) {
      yield call(
        createStep3Draft({
          ...payload?.params,
          ...sliderUrlObj,
          logoUrl,
        })
      )
    } else {
      yield call(
        createStep3General({
          ...payload?.params,
          ...sliderUrlObj,
          logoUrl,
        })
      )
    }

    yield put(merchantAction.createStep3GeneralSuccess())

    yield put(
      merchantAction?.fetchMerchantGeneralImagePanelUserStart(
        payload?.params?.merchantId
      )
    )

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          merchantAction?.fetchMerchantGeneralBasicInfoPanelAdminStart(
            payload?.params?.merchantId
          )
        )
        yield put(
          merchantAction?.fetchMerchantDraftBasicInfoPanelUserStart(
            payload?.params?.merchantId
          )
        )
      } else {
        yield put(
          merchantAction?.fetchMerchantDraftBasicInfoPanelUserStart(
            payload?.params?.merchantId
          )
        )
      }
    }

    if (payload?.changeTab) {
      yield payload?.changeTab()
    }

    if (payload?.isDraft) {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved to draft',
          severity: 'success',
        })
      )
    } else {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createStep3GeneralFailure(err))
  }
}

export function* createStep3GeneralAsync_old({ payload }: AnyAction) {
  try {
    let logoUrl = ''
    let silder1Url = ''
    let silder2Url = ''
    let silder3Url = ''
    let silder4Url = ''
    let silder5Url = ''
    let silder6Url = ''

    if (!_.isEmpty(payload?.params) && payload?.params?.logoUrl?.size) {
      const initialImageUrl = yield call(getImageUrl())

      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logoUrl)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider1) &&
      payload?.params?.slider1?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider1)
        )

        silder1Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider2) &&
      payload?.params?.slider2?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider2)
        )

        silder2Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider3) &&
      payload?.params?.slider3?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider3)
        )

        silder3Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider4) &&
      payload?.params?.slider4?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider4)
        )

        silder4Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider5) &&
      payload?.params?.slider5?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider5)
        )

        silder5Url = removeParam(imageResponse.config.url)
      }
    }

    if (
      !_.isEmpty(payload?.params?.slider6) &&
      payload?.params?.slider6?.size
    ) {
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.slider6)
        )

        silder6Url = removeParam(imageResponse.config.url)
      }
    }

    const shouldSend = [
      silder1Url,
      silder2Url,
      silder3Url,
      silder4Url,
      silder5Url,
      silder6Url,
      logoUrl,
    ].some((item) => item)

    if (shouldSend) {
      const { data } = yield call(
        createStep3General(
          removeEmptyFields({
            ...payload?.params,
            logoUrl,
            slider1: silder1Url,
            slider2: silder2Url,
            slider3: silder3Url,
            slider4: silder4Url,
            slider5: silder5Url,
            slider6: silder6Url,
          })
        )
      )
    }
    yield put(merchantAction.createStep3GeneralSuccess())

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          merchantAction?.fetchMerchantGeneralBasicInfoPanelAdminStart(
            payload?.params?.merchantId
          )
        )
      } else {
        yield put(
          merchantAction?.fetchMerchantDraftBasicInfoPanelUserStart(
            payload?.params?.merchantId
          )
        )
      }
    }

    yield payload?.handleChangeTab(null, 3)

    yield put(
      openAlert({
        message: 'Merchant data successfully saved',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createStep3GeneralFailure(err))
  }
}

export function* createStep4DraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createStep4Draft(payload?.params))
    yield put(merchantAction.createStep4DraftSuccess(data?.data))
    // yield payload?.handleChangeTab(null, 4)
    if (payload?.route) {
      payload?.navigate(payload?.route)
    }
    ///

    if (payload?.saveOnly) {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved',
          severity: 'success',
        })
      )
    } else {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved to draft',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createStep4DraftFailure(err))
  }
}

export function* createStep4GeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createStep4General(payload?.params))
    yield put(merchantAction.createStep4GeneralSuccess(data?.data))

    if (payload?.refreshTab) {
      if (payload?.byAdmin) {
        yield put(
          merchantAction?.fetchMerchantGeneralBasicInfoPanelAdminStart(
            payload?.params?.merchantId
          )
        )
      } else {
        yield put(
          merchantAction?.fetchMerchantDraftBasicInfoPanelUserStart(
            payload?.params?.merchantId
          )
        )
      }
    }

    yield payload?.handleChangeTab(null, 4)

    yield put(
      openAlert({
        message: 'Merchant data successfully saved',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createStep4GeneralFailure(err))
  }
}

export function* fetchMerchantDiscountByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantDiscountById(payload?.id))

    yield put(merchantAction.fetchMerchantDiscountByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchMerchantDiscountByIdFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantDraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantDraftList(payload))

    yield put(merchantAction.fetchMerchantDraftSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchMerchantDraftFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantFranchisorDraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantFranchisorDraftList(payload))

    yield put(merchantAction.fetchMerchantFranchisorDraftSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchMerchantFranchisorDraftFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantGeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantGeneralList(payload))

    yield put(merchantAction.fetchMerchantGeneralSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchMerchantGeneralFailure(err))
    console.error(err)
  }
}

export function* fetchAreaMerchantGeneralByLocationAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantGeneralByLocationList(payload))

    yield put(merchantAction.fetchAreaMerchantGeneralByLocationSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchAreaMerchantGeneralByLocationFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantStateWiseDataAsync({ payload }: AnyAction) {
  try {
    const currentUser = yield select(getUserData)

    const { data } = yield call(
      fetchMerchantListStateWiseData({
        ...payload,
        stateId: currentUser?.userCountry?.stateId,
      })
    )

    yield put(merchantAction.fetchMerchantStateWiseDataSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchMerchantStateWiseDataFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantFranchisorGeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantFranchisorGeneralList(payload))

    yield put(merchantAction.fetchMerchantFranchisorGeneralSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchMerchantFranchisorGeneralFailure(err))
    console.error(err)
  }
}

export function* createMerchantDiscountAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createMerchantDiscount(payload?.params))
    yield put(merchantAction.createMerchantDiscountSuccess(data?.data))
    yield payload?.handleChangeTab(null, 4)

    yield put(
      openAlert({
        message: 'Merchant Discount successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createMerchantDiscountFailure(err))
  }
}

export function* fetchMerchantGeneralBasicInfoPanelUserAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantGeneralBasicInfoByPanel(payload))

    yield put(
      merchantAction.fetchMerchantGeneralBasicInfoPanelUserSuccess(data?.result)
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantGeneralBasicInfoPanelUserFailure(err))
  }
}

export function* fetchMerchantGeneralBasicInfoForStateMerchantAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchMerchantGeneralBasicInfoForStateMerchant(payload)
    )

    yield put(
      merchantAction.fetchMerchantGeneralBasicInfoForStateMerchantSuccess(
        data?.data
      )
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(
      merchantAction.fetchMerchantGeneralBasicInfoForStateMerchantFailure(err)
    )
  }
}

export function* fetchMerchantFranchiseGeneralBasicInfoPanelUserAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchMerchantFranchiseGeneralBasicInfoPanel(payload)
    )

    yield put(
      merchantAction.fetchMerchantFranchiseGeneralBasicInfoPanelUserSuccess(
        data?.result
      )
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(
      merchantAction.fetchMerchantFranchiseGeneralBasicInfoPanelUserFailure(err)
    )
  }
}

export function* fetchMerchantGeneralBasicInfoPanelAdminAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantGeneralBasicInfoByAdmin(payload))

    yield put(
      merchantAction.fetchMerchantGeneralBasicInfoPanelAdminSuccess(
        data?.result
      )
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(
      merchantAction.fetchMerchantGeneralBasicInfoPanelAdminFailure(err)
    )
  }
}

export function* fetchMerchantDraftBasicInfoPanelAdminAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantDraftBasicInfoByAdmin(payload))

    yield put(
      merchantAction.fetchMerchantDraftBasicInfoPanelAdminSuccess(data?.result)
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantDraftBasicInfoPanelAdminFailure(err))
  }
}

export function* rejectMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      rejectMerchantGeneral(payload?.id, {
        rejectReason: payload?.rejectReason,
      })
    )

    yield put(merchantAction.rejectMerchantSuccess(data?.result))

    if (payload?.route) {
      payload?.navigate(payload?.route)
    }
    yield put(
      openAlert({
        message: 'Merchant Rejected successfully',
        severity: 'success',
      })
    )

    yield payload?.closeModal()
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.rejectMerchantFailure(err))
  }
}

export function* verifyMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      verifyMerchantById(payload?.params?.id, payload?.params)
    )

    yield put(merchantAction.verifyMerchantSuccess(data?.result))

    if (payload?.route) {
      payload?.navigate(payload?.route)
    }
    yield put(
      openAlert({
        message: 'Merchant successfully verified',
        severity: 'success',
      })
    )

    yield payload?.closeModal()
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.verifyMerchantFailure(err))
  }
}

export function* activateMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(activeMerchantById(payload?.params?.id))

    yield put(merchantAction.activateMerchantSuccess(data?.result))

    if (payload?.route) {
      payload?.navigate(payload?.route)
    }
    yield put(
      openAlert({
        message: 'Merchant successfully verified',
        severity: 'success',
      })
    )

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.activateMerchantFailure(err))
  }
}

export function* activateSubscriptionOfMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      activateMerchantSubscription(payload?.params?.id)
    )

    yield put(
      merchantAction.activateSubscriptionOfMerchantSuccess(data?.result)
    )

    yield put(subscriptionIdUpdate(data?.data?.subscriptionId))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.route) {
      payload?.navigate(payload?.route)
    }
    yield put(
      openAlert({
        message: 'Merchant subscription activated successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.activateSubscriptionOfMerchantFailure(err))
  }
}

export function* fetchMerchantDraftBasicInfoPanelUserAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantDraftBasicInfoByPanel(payload))

    yield put(
      merchantAction.fetchMerchantDraftBasicInfoPanelUserSuccess(data?.result)
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantDraftBasicInfoPanelUserFailure(err))
  }
}

export function* fetchMerchantDraftWebsiteInfoPanelUserAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantDraftWebsiteInfo(payload))

    yield put(
      merchantAction.fetchMerchantDraftWebsiteInfoPanelUserSuccess(data?.result)
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantDraftWebsiteInfoPanelUserFailure(err))
  }
}

export function* fetchMerchantDraftImagePanelPanelUserAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantDraftImage(payload))

    yield put(
      merchantAction.fetchMerchantDraftImagePanelUserSuccess(data?.result)
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantDraftImagePanelUserFailure(err))
  }
}

export function* fetchMerchantDraftDiscountInfoPanelUserAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantDraftDiscount(payload))

    yield put(
      merchantAction.fetchMerchantDraftDiscountInfoPanelUserSuccess(
        data?.result
      )
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(
      merchantAction.fetchMerchantDraftDiscountInfoPanelUserFailure(err)
    )
  }
}

export function* fetchMerchantGeneralWebsiteInfoPanelUserAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantGeneralWebsiteInfo(payload))

    yield put(
      merchantAction.fetchMerchantGeneralWebsiteInfoPanelUserSuccess(
        data?.result
      )
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(
      merchantAction.fetchMerchantGeneralWebsiteInfoPanelUserFailure(err)
    )
  }
}

export function* fetchMerchantGeneralImageInfoPanelUserAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantGeneralImage(payload))

    yield put(
      merchantAction.fetchMerchantGeneralImagePanelUserSuccess(data?.result)
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantGeneralImagePanelUserFailure(err))
  }
}

export function* fetchMerchantGeneralBankInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantGeneralBankInfo(payload))

    yield put(merchantAction.fetchMerchantGeneralBankInfoSuccess(data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantGeneralBankInfoFailure(err))
  }
}

export function* fetchMerchantGeneralDiscountInfoPanelUserAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantGeneralDiscount(payload))

    yield put(
      merchantAction.fetchMerchantGeneralDiscountInfoPanelUserSuccess(
        data?.result
      )
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(
      merchantAction.fetchMerchantGeneralDiscountInfoPanelUserFailure(err)
    )
  }
}

export function* createBankInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createBankInfo(payload?.params))

    yield put(merchantAction.createBankInfoSuccess(data?.result))

    // if (!_.isEmpty(data?._couponLog)) {
    //   yield call(
    //     couponCodeLogUpdate({
    //       transactionId: payload?.params?.stripeTokenId,
    //       couponLogId: data?._couponLog?.id,
    //     })
    //   )
    // }

    if (payload?.route) {
      payload?.navigate(payload?.route)
    }

    yield put(
      openAlert({
        message: 'Merchant Request send successfully',
        severity: 'success',
      })
    )

    yield put(merchantAction.clearGeneralData())
    yield put(merchantAction.clearDraftData())
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createBankInfoFailure(err))
  }
}

export function* checkApplyMerchantPremiumCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = payload?.fromUpgradeMerchant
      ? yield call(checkApplyMerchantCodeUponUpgrade(payload?.params))
      : yield call(checkApplyMerchantPremiumCode(payload?.params))

    const successMessage = 'Premium Code Applied Successfully'
    yield put(
      merchantAction.checkApplyMerchantPremiumCodeSuccess({
        successMessage,
        data: data,
      })
    )
    yield put(
      openAlert({
        message: 'Applied Premium Code Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    // yield put(
    //   openAlert({ message: err?.response?.data?.message, severity: 'error' })
    // )
    yield put(
      merchantAction.checkApplyMerchantPremiumCodeFailure(
        err?.response?.data?.message
      )
    )
    yield put(merchantAction.clearMerchantCodeData())
  }
}

export function* applyMerchantCouponCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(checkApplyCouponCode(payload?.params))
    const successMessage = 'Coupon Code Applied Successfully'
    yield put(
      merchantAction.applyMerchantCouponCodeSuccess({
        successMessage,
        data: data,
      })
    )
    yield put(
      openAlert({
        message: 'Applied Coupon Code Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      merchantAction.applyMerchantCouponCodeFailure(
        err?.response?.data?.message
      )
    )
    yield put(merchantAction.clearMerchantCodeData())
  }
}

export function* checkApplyMerchantPremiumCodeUpgradePackageAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      checkApplyMerchantPremiumCodeUpgradePackage(
        payload?.params?.premiumCode,
        payload?.params
      )
    )

    const successMessage = 'Premium Code Applied Successfully'
    yield put(
      merchantAction.checkApplyMerchantPremiumCodeUpgradePackageSuccess({
        successMessage,
        data: data,
      })
    )
    yield put(
      openAlert({
        message: 'Applied Premium Code Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    // yield put(
    //   openAlert({ message: err?.response?.data?.message, severity: 'error' })
    // )
    yield put(
      merchantAction.checkApplyMerchantPremiumCodeUpgradePackageFailure(
        err?.response?.data?.message
      )
    )
  }
}

export function* fetchAllMerchantListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllMerchantList(payload))

    yield put(merchantAction.fetchAllMerchantListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchAllMerchantListFailure(err))
  }
}

export function* setMerchantPopularAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      setMerchantPopular(payload?.params?.id, payload?.params)
    )

    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'Merchant Popularity Order Set Successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(merchantAction.setMerchantPopularSuccess(data?.result))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.setMerchantPopularFailure(err))
  }
}

export function* fetchMerchantProfileInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantProfileInfo())

    yield put(merchantAction.fetchMerchantProfileInfoSuccess(data?.data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantProfileInfoFailure(err))
  }
}

export function* fetchMerchantProfileDiscountInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantProfileDiscountInfo())

    yield put(
      merchantAction.fetchMerchantProfileDiscountInfoSuccess(data?.data)
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantProfileDiscountInfoFailure(err))
  }
}

export function* fetchMerchantProfileImagesInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantProfileImagesInfo())

    yield put(merchantAction.fetchMerchantProfileImagesInfoSuccess(data?.data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantProfileImagesInfoFailure(err))
  }
}

export function* fetchMerchantProfileWebsiteAndBankInfoAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantProfileWebsiteAndBankingInfo())

    yield put(
      merchantAction.fetchMerchantProfileWebsiteAndBankInfoSuccess(data?.data)
    )
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantProfileWebsiteAndBankInfoFailure(err))
  }
}

export function* updateBankInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      updateBankInfo(payload?.params?.merchantId, payload?.params)
    )

    yield put(merchantAction.updateBankInfoSuccess(data?.data))

    yield put(
      openAlert({
        message: 'Merchant data updated successfully',
        severity: 'success',
      })
    )

    if (payload?.navigate) {
      payload?.navigate(payload?.route)
    }

    yield put(merchantAction.clearGeneralData())
    yield put(merchantAction.clearDraftData())
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.updateBankInfoFailure(err))
  }
}

export function* fetchMerchantCardInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getMerchantCardInfo(payload))

    yield put(merchantAction.fetchMerchantCardInfoSuccess(data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantCardInfoFailure(err))
  }
}

export function* addMerchantCardSourceAsync({ payload }: AnyAction) {
  try {
    const { data } = payload?.merchantId
      ? yield call(
          merchantAddCardSourceWithId(payload?.merchantId, payload?.params)
        )
      : yield call(merchantAddCardSource(payload?.params))

    yield put(merchantAction.addMerchantCardSourceSuccess(data?.data))

    if (payload?.onActivateSubscription) {
      yield call(payload?.onActivateSubscription)
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.updateAuth) {
      yield put(stripeCustomerIdIdUpdate(data?.data?.stripeCustomerId))
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: data?.message,
        severity: 'success',
      })
    )

    // if (payload?.navigate) {
    //   payload?.navigate(payload?.route)
    // }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.addMerchantCardSourceFailure(err))
  }
}

export function* setCardAsDefaultAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(merchantSetCardAsDefault(payload?.params))

    yield put(merchantAction.setCardAsDefaultSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Card Set As Default Successfully',
        severity: 'success',
      })
    )

    // if (payload?.navigate) {
    //   payload?.navigate(payload?.route)
    // }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.setCardAsDefaultFailure(err))
  }
}

export function* generateEcommerceKeyAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(generateEcommerceKey())

    yield put(merchantAction.generateEcommerceKeySuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Key Generated Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.generateEcommerceKeyFailure(err))
  }
}

export function* editOwnMerchantProfileAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editOwnMerchantProfile(payload?.params))

    yield put(merchantAction.editOwnMerchantProfileSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Profile Edited Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editOwnMerchantProfileFailure(err))
  }
}

export function* editMerchantInfoStartAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editMerchantInfo(payload?.params?.id, payload?.params)
    )

    yield put(merchantAction.editMerchantInfoSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant Inforamtion Edited Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editMerchantInfoFailure(err))
  }
}

export function* sendMerchantEmailAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(sendMerchantEmail(payload?.params))

    yield put(merchantAction.sendMerchantEmailSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.resetForm) {
      yield payload?.resetForm()
    }

    if (payload?.navigate) {
      yield payload?.navigate(payload?.route)
    }

    yield put(
      openAlert({
        message: 'An email has been sent successfully to this merchant',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.sendMerchantEmailFailure(err))
  }
}

export function* fetchMerchantTerminalListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantTerminalList(payload))

    yield put(merchantAction.fetchAllMerchantTerminalListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchAllMerchantTerminalListFailure(err))
    console.error(err)
  }
}

export function* createMerchantTerminalAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createMerchantTerminal(payload?.params))
    yield put(merchantAction.createMerchantTerminalSuccess())
    if (payload?.route) {
      payload?.navigate(payload?.route)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant Terminal successfully saved',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createMerchantTerminalFailure(err))
  }
}

export function* editMerchantTerminalAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editMerchantTerminal(payload?.params?.id, payload?.params)
    )
    yield put(merchantAction.editMerchantTerminalSuccess())

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant Terminal successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editMerchantTerminalFailure(err))
  }
}

export function* editMerchantTerminalOwnProfileAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editOwnMerchantTerminalProfile(payload?.params))
    yield put(merchantAction.editMerchantTerminalOwnProfileSuccess())

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Profile updated successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editMerchantTerminalOwnProfileFailure(err))
  }
}

export function* fetchOneMerchantTerminalAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchOneMerchantTerminal(payload))

    yield put(merchantAction.fetchOneMerchantTerminalSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchOneMerchantTerminalFailure(err))
    console.error(err)
  }
}

export function* deactivateMerchantTerminalAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      payload?.activate
        ? activateMerchantTerminal(payload?.params?.id)
        : deactivateMerchantTerminal(payload?.params?.id)
    )
    yield put(merchantAction.deactivateMerchantTerminalSuccess())

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant Terminal Deactivated successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.deactivateMerchantTerminalFailure(err))
  }
}

export function* updateMerchantTerminalPasswordAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      updateMerchantTerminalPassword(payload?.params?.id, payload?.params)
    )
    yield put(merchantAction.updateMerchantTerminalPasswordSuccess())

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant Terminal Password Changed successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.updateMerchantTerminalPasswordFailure(err))
  }
}

export function* getReferralTokenAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getReferralToken())
    yield put(merchantAction.getReferralTokenSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.getReferralTokenFailure(err))
  }
}

export function* updateStep4GeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      updateStep4General(payload?.params?.merchantId, payload?.params)
    )
    yield put(merchantAction.updateStep4GeneralSuccess(data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant Discount Updated Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.updateStep4GeneralFailure(err))
  }
}

export function* updateStep2GeneralAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      updateStep2General(payload?.params?.merchantId, payload?.params)
    )
    yield put(merchantAction.updateStep2GeneralSuccess(data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Specifics updated successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.updateStep2GeneralFailure(err))
  }
}

export function* updateStep3GeneralAsync({ payload }: AnyAction) {
  try {
    let sliderUrls = []
    let logoUrl = ''

    // for (let i = 0; i < payload?.sliderImage.length || 0; i++) {
    for (let i = 0; i < 6; i++) {
      if (payload.sliderImage[i]?.size) {
        const initialImageUrl = yield call(getImageUrl())

        if (!_.isEmpty(initialImageUrl?.data)) {
          const imageResponse = yield call(
            uploadImage(initialImageUrl?.data?.url, payload.sliderImage[i])
          )

          sliderUrls[i] = removeParam(imageResponse.config.url)

          if (payload?.oldImages?.sliderImage?.[i]) {
            yield call(
              deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
            )
          }
        }
      } else {
        sliderUrls[i] = payload.sliderImage[i] || null

        if (
          payload?.oldImages?.sliderImage?.[i] &&
          !payload.sliderImage.some(
            (item) => payload?.oldImages?.sliderImage?.[i] === item
          )
        ) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.sliderImage[i]))
          )
        }
      }
    }

    if (payload?.logoImage?.size) {
      const initialImageUrl = yield call(getImageUrl())

      if (!_.isEmpty(initialImageUrl?.data)) {
        const imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logoImage)
        )

        logoUrl = removeParam(imageResponse.config.url)

        if (payload?.oldImages?.logoImage) {
          yield call(
            deleteImage(getImageIdFromUrl(payload?.oldImages?.logoImage))
          )
        }
      }
    } else {
      logoUrl = payload?.logoImage
    }

    const sliderUrlObj = sliderUrls.reduce(
      (acc, val, ind) => ({ ...acc, [`slider${ind + 1}`]: val }),
      {}
    )

    yield call(
      updateImage(payload?.params?.merchantId, {
        ...payload?.params,
        ...sliderUrlObj,
        logoUrl,
      })
    )

    yield put(merchantAction.updateStep3GeneralSuccess())

    if (!payload?.fromMerchantProfile) {
      yield put(
        merchantAction?.fetchMerchantGeneralImagePanelUserStart(
          payload?.params?.merchantId
        )
      )
    }

    if (payload?.refreshTab) {
      yield put(merchantAction?.fetchMerchantProfileImagesInfoStart(''))
    }

    if (payload?.changeTab) {
      yield payload?.changeTab()
    }

    if (payload?.isDraft) {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved to draft',
          severity: 'success',
        })
      )
    } else {
      yield put(
        openAlert({
          message: 'Merchant data successfully saved',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.updateStep3GeneralFailure(err))
  }
}

export function* fetchAllReferredMerchantListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchReferredMerchantList(payload))

    yield put(merchantAction.fetchAllReferredMerchantListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchAllReferredMerchantListFailure(err))
    console.error(err)
  }
}

export function* createTerminalAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createTerminal(payload?.params))
    yield put(merchantAction.createTerminalSuccess())

    yield put(
      openAlert({
        message: 'Terminal successfully created',
        severity: 'success',
      })
    )

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createTerminalFailure(err))
  }
}

export function* fetchTerminalListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllTerminalList(payload))

    yield put(merchantAction.fetchAllTerminalListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchAllTerminalListFailure(err))
    console.error(err)
  }
}

export function* fetchTerminalListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTerminalListByPanel(payload))

    yield put(merchantAction.fetchTerminalListByPanelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchTerminalListByPanelFailure(err))
    console.error(err)
  }
}

export function* fetchTerminalByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTerminalById(payload))

    yield put(merchantAction.fetchTerminalByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchTerminalByIdFailure(err))
  }
}

export function* editTerminalAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editTerminal(payload?.params?.id, payload?.params)
    )
    yield put(merchantAction.editTerminalSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Terminal successfully updated',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editTerminalFailure(err))
  }
}

export function* fetchMultipleAssignerByRoleAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMultipleAssignerByRole(payload.params))

    yield put(merchantAction.fetchMultipleAssignerByRoleSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchMultipleAssignerByRoleFailure(err))
    console.error(err)
  }
}

export function* transferReferredMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      transferMerchantRequest(payload?.params?.id, payload?.params)
    )

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Referred merchant request successfully transferred',
        severity: 'success',
      })
    )

    yield put(merchantAction.transferReferredMerchantSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.transferReferredMerchantFailure(err))
    console.error(err)
  }
}

export function* fetchReferredMerchantByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchReferredMerchantById(payload))

    yield put(merchantAction.fetchReferredMerchantByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchReferredMerchantByIdFailure(err))
  }
}

export function* rejectReferredMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      rejectReferredMerchantById(payload?.id, {
        rejectReason: payload?.rejectReason,
      })
    )

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(merchantAction.rejectReferredMerchantSuccess())
    yield put(
      openAlert({
        message: 'Merchant request successfully rejected',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.rejectReferredMerchantFailure(err))
  }
}

export function* approveReferredMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      approveReferredMerchantById(payload?.params?.id, payload?.params)
    )

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant request successfully approved',
        severity: 'success',
      })
    )

    if (payload?.route && !_.isEmpty(data)) {
      payload?.navigate(`${payload?.route}${data?.merchantId}`)
    }

    yield put(merchantAction.approveReferredMerchantSuccess())
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.approveReferredMerchantFailure(err))
  }
}

export function* editReferredMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editReferredMerchant(payload?.params?.id, payload?.params)
    )
    yield put(merchantAction.editReferredMerchantSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Referred Merchant successfully updated',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editReferredMerchantFailure(err))
  }
}

export function* referMerchantMailFromDraftAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(referredMailFromDraft(payload?.params?.id))
    yield put(merchantAction.referMerchantMailFromDraftSuccess())

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Referred Merchant successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.referMerchantMailFromDraftFailure(err))
  }
}

export function* acceptOrRejectreferredMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = payload?.acceptMerchantRequest
      ? yield call(
          acceptOrRejectreferredMerchant(payload?.params?.id, payload?.params)
        )
      : yield call(
          acceptOrRejectreferredMerchant(payload?.id, {
            isAccepted: false,
            rejectReason: payload?.rejectReason,
          })
        )
    yield put(merchantAction.acceptOrRejectreferredMerchantSuccess())

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: `${
          payload?.acceptMerchantRequest ? 'Accepted' : 'Rejected'
        } Request successfully`,
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.acceptOrRejectreferredMerchantFailure(err))
  }
}

export function* createMerchantGroupAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createMerchantGroup({ ...payload.params, logoUrl: logoUrl })
      )

      yield put(merchantAction.createMerchantGroupSuccess(data?.data))
    } else {
      const { data } = yield call(createMerchantGroup({ ...payload.params }))

      yield put(merchantAction.createMerchantGroupSuccess(data?.data))
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Group Merchant successfully created',
        severity: 'success',
      })
    )

    if (payload?.route) {
      payload?.navigate(`${payload?.route}`)
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createMerchantGroupFailure(err))
  }
}

export function* fetchAllMerchantGroupAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllMerchantGroup(payload))

    yield put(merchantAction.fetchAllMerchantGroupSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchAllMerchantGroupFailure(err))
  }
}

export function* fetchMerchantGroupByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantGroupById(payload))

    yield put(
      merchantAction.fetchMerchantGroupByIdSuccess({
        ...data?.data,
        issuerCode: data?.issuerCode,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchMerchantGroupByIdFailure(err))
    console.error(err)
  }
}

export function* editPendingMerchantGroupAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        editMerchantGroup(payload?.params?.id, { ...payload?.params, logoUrl })
      )

      yield put(merchantAction.editPendingMerchantGroupSuccess(data?.data))

      yield payload.closeModal()
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(
        editMerchantGroup(payload?.params?.id, uploadData)
      )
      yield put(merchantAction.editPendingMerchantGroupSuccess(data?.data))
      yield payload.closeModal()
    }

    yield put(
      openAlert({
        message: 'Pending Group Merchant Succesfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.editPendingMerchantGroupFailure(err))
    console.error(err)
  }
}

export function* verifyPendingMerchantGroupAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        verifyPendingMerchantGroup(payload?.params.id, {
          ...payload?.params,
          logoUrl,
        })
      )

      yield put(merchantAction.verifyPendingMerchantGroupSuccess(data?.data))

      if (payload?.closeModal) {
        yield payload?.closeModal()
      }
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(
        verifyPendingMerchantGroup(payload?.params.id, uploadData)
      )
      yield put(merchantAction.verifyPendingMerchantGroupSuccess(data?.data))

      if (payload?.closeModal) {
        yield payload?.closeModal()
      }
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Group Merchant Verified Succesfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.verifyPendingMerchantGroupFailure(err))
    console.error(err)
  }
}

export function* editMerchantGroupAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.logo)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      yield call(deleteImage(getImageIdFromUrl(payload.params.logoUrl)))

      const { data } = yield call(
        editMerchantGroup(payload?.params?.id, { ...payload?.params, logoUrl })
      )

      yield put(merchantAction.editMerchantGroupSuccess(data?.data))

      yield payload.closeModal()
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, logoUrl: '' }

      const { data } = yield call(
        editMerchantGroup(payload?.params?.id, uploadData)
      )
      yield put(merchantAction.editMerchantGroupSuccess(data?.data))
      yield payload.closeModal()
    }

    yield put(
      openAlert({
        message: 'Group Merchant Succesfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.editMerchantGroupFailure(err))
    console.error(err)
  }
}

export function* assignIssuerTransactionCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      assignIssuerTransactionCode(payload?.params?.id, payload?.params)
    )

    yield put(
      merchantAction.assignIssuerTransactionCodeSuccess({
        ...data?.data,
      })
    )

    yield put(
      openAlert({
        message: 'Code Assigned to Merchant Successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload.closeModal) {
      yield payload.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.assignIssuerTransactionCodeFailure(err))
    console.error(err)
  }
}

export function* activateOrDeactivateMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      activateOrDeactivateMerchant(
        payload?.id,
        payload?.deactivate ? { deactivate: true } : { deactivate: false }
      )
    )

    yield put(merchantAction.activateOrDeactivateMerchantSuccess(data?.result))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.route) {
      payload?.navigate(payload?.route)
    }
    yield put(
      openAlert({
        message: `Merchant ${
          payload?.deactivate ? 'deactivated' : 'activated'
        } successfully`,
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.activateOrDeactivateMerchantFailure(err))
  }
}

export function* deleteMerchantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteMerchant(payload?.id))

    yield put(merchantAction.deleteMerchantSuccess(data?.result))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    if (payload?.route) {
      payload?.navigate(payload?.route)
    }
    yield put(
      openAlert({
        message: `Merchant deleted successfully`,
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.deleteMerchantFailure(err))
  }
}

export function* fetchActivityListReportByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchActivityListReportByPanel(payload))

    yield put(merchantAction.fetchActivityListReportByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchActivityListReportByPanelFailure(err))
  }
}

export function* fetchMerchantMultilingualListByPanelAsync({
  payload,
}: AnyAction) {
  try {
    yield put(merchantAction.clearMerchantMultilingualData())

    const { data } = yield call(fetchAllMerchantMultilingual(payload))

    yield put(merchantAction.fetchMerchantMultilingualListByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantMultilingualListByPanelFailure(err))
  }
}

export function* addMerchantMultilingualAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(addMerchantMultilingual(payload?.params))

    yield put(merchantAction.addMerchantMultilingualSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.resetForm) {
      yield call(payload?.resetForm)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Merchant Language succesfully added',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.addMerchantMultilingualFailure(err))
  }
}

export function* editMerchantMultilingualAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editMerchantMultilingual(payload?.params?.id, payload?.params)
    )
    yield put(merchantAction.editMerchantMultilingualSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant Language succesfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editMerchantMultilingualFailure(err))
  }
}

export function* fetchMerchantMultilingualByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantMultilingualById(payload))

    yield put(merchantAction.fetchtMerchantMultilingualByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(merchantAction.fetchtMerchantMultilingualByIdFailure(err))
  }
}

export function* fetchCharityMultilingualListAsync({ payload }: AnyAction) {
  try {
    yield put(merchantAction.clearCharityMultilingualData())

    const { data } = yield call(fetchAllCharityMultilingual(payload))

    yield put(merchantAction.fetchCharityMultilingualListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchCharityMultilingualListFailure(err))
  }
}

export function* createCharityMultilingualAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(addCharityMultilingual(payload?.params))

    yield put(merchantAction.createCharityMultilingualSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.resetForm) {
      yield call(payload?.resetForm)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Charity Language succesfully added',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createCharityMultilingualFailure(err))
  }
}

export function* editCharitytMultilingualAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editCharityMultilingual(payload?.params?.id, payload?.params)
    )
    yield put(merchantAction.editCharityMultilingualSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Charity Language succesfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editCharityMultilingualFailure(err))
  }
}

export function* fetchMerchantCategoryMultilingualListAsync({
  payload,
}: AnyAction) {
  try {
    yield put(merchantAction.clearMerchantCategoryMultilingualData())

    const { data } = yield call(fetchAllMerchantCategoryMultilingual(payload))

    yield put(merchantAction.fetchMerchantCategoryMultilingualListSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.fetchMerchantCategoryMultilingualListFailure(err))
  }
}

export function* createMerchantCategoryMultilingualAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      addMerchantCategoryMultilingual(payload?.params)
    )

    yield put(
      merchantAction.createMerchantCategoryMultilingualSuccess(data?.data)
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.resetForm) {
      yield call(payload?.resetForm)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Merchant Category Language succesfully added',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.createMerchantCategoryMultilingualFailure(err))
  }
}

export function* editMerchantCategoryMultilingualAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editMerchantCategoryMultilingual(payload?.params?.id, payload?.params)
    )
    yield put(
      merchantAction.editMerchantCategoryMultilingualSuccess(data?.data)
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Merchant Category Language succesfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(merchantAction.editMerchantCategoryMultilingualFailure(err))
  }
}

export function* watchCreateStep1Draft() {
  yield takeLatest('merchant/createStep1DraftStart', createStep1DraftAsync)
}

export function* watchCreateStep1General() {
  yield takeLatest('merchant/createStep1GeneralStart', createStep1GeneralAsync)
}

export function* watchCreateStep2Draft() {
  yield takeLatest('merchant/createStep2DraftStart', createStep2DraftAsync)
}

export function* watchCreateStep2General() {
  yield takeLatest('merchant/createStep2GeneralStart', createStep2GeneralAsync)
}

export function* watchCreateStep3Draft() {
  yield takeLatest('merchant/createStep3DraftStart', createStep3DraftAsync)
}

export function* watchCreateStep3General() {
  yield takeLatest('merchant/createStep3GeneralStart', createStep3GeneralAsync)
}

export function* watchFetchMerchantDiscountById() {
  yield takeLatest(
    'merchant/fetchMerchantDiscountByIdStart',
    fetchMerchantDiscountByIdAsync
  )
}

export function* watchFetchMerchantDraft() {
  yield takeLatest('merchant/fetchMerchantDraftStart', fetchMerchantDraftAsync)
}

export function* watchFetchMerchantFranchisorDraft() {
  yield takeLatest(
    'merchant/fetchMerchantFranchisorDraftStart',
    fetchMerchantFranchisorDraftAsync
  )
}

export function* watchFetchAllMerchantList() {
  yield takeLatest(
    'merchant/fetchAllMerchantListStart',
    fetchAllMerchantListAsync
  )
}

export function* watchFetchMerchantGeneral() {
  yield takeLatest(
    'merchant/fetchMerchantGeneralStart',
    fetchMerchantGeneralAsync
  )
}

export function* watchFetchMerchantFranchisorGeneral() {
  yield takeLatest(
    'merchant/fetchMerchantFranchisorGeneralStart',
    fetchMerchantFranchisorGeneralAsync
  )
}

export function* watchCreateMerchantDiscount() {
  yield takeLatest(
    'merchant/createMerchantDiscountStart',
    createMerchantDiscountAsync
  )
}

export function* watchFetchMerchantGeneralBasicInfoPanelUser() {
  yield takeLatest(
    'merchant/fetchMerchantGeneralBasicInfoPanelUserStart',
    fetchMerchantGeneralBasicInfoPanelUserAsync
  )
}

export function* watchFetchMerchantGeneralBasicInfoForStateMerchantStart() {
  yield takeLatest(
    'merchant/fetchMerchantGeneralBasicInfoForStateMerchantStart',
    fetchMerchantGeneralBasicInfoForStateMerchantAsync
  )
}

export function* watchFetchMerchantFranchiseGeneralBasicInfoPanelUser() {
  yield takeLatest(
    'merchant/fetchMerchantFranchiseGeneralBasicInfoPanelUserStart',
    fetchMerchantFranchiseGeneralBasicInfoPanelUserAsync
  )
}

export function* watchFetchMerchantGeneralWebsiteInfoPanelUser() {
  yield takeLatest(
    'merchant/fetchMerchantGeneralWebsiteInfoPanelUserStart',
    fetchMerchantGeneralWebsiteInfoPanelUserAsync
  )
}

export function* watchfetchMerchantGeneralDiscountInfoPanelUser() {
  yield takeLatest(
    'merchant/fetchMerchantGeneralDiscountInfoPanelUserStart',
    fetchMerchantGeneralDiscountInfoPanelUserAsync
  )
}

export function* watchFetchMerchantDraftBasicInfoPanelUser() {
  yield takeLatest(
    'merchant/fetchMerchantDraftBasicInfoPanelUserStart',
    fetchMerchantDraftBasicInfoPanelUserAsync
  )
}
export function* watchFetchMerchantDraftWebsiteInfoPanelUser() {
  yield takeLatest(
    'merchant/fetchMerchantDraftWebsiteInfoPanelUserStart',
    fetchMerchantDraftWebsiteInfoPanelUserAsync
  )
}

export function* watchFetchMerchantDraftImagePanelUser() {
  yield takeLatest(
    'merchant/fetchMerchantDraftImagePanelUserStart',
    fetchMerchantDraftImagePanelPanelUserAsync
  )
}

export function* fetchMerchantDraftDiscountInfoPanelUser() {
  yield takeLatest(
    'merchant/fetchMerchantDraftDiscountInfoPanelUserStart',
    fetchMerchantDraftDiscountInfoPanelUserAsync
  )
}

export function* watchFetchMerchantGeneralImageInfoPanelUser() {
  yield takeLatest(
    'merchant/fetchMerchantGeneralImagePanelUserStart',
    fetchMerchantGeneralImageInfoPanelUserAsync
  )
}

export function* watchCreateBankInfo() {
  yield takeLatest('merchant/createBankInfoStart', createBankInfoAsync)
}

export function* watchFetchMerchantGeneralBankInfo() {
  yield takeLatest(
    'merchant/fetchMerchantGeneralBankInfoStart',
    fetchMerchantGeneralBankInfoAsync
  )
}

export function* watchFetchMerchantGeneralBasicInfoPanelAdmin() {
  yield takeLatest(
    'merchant/fetchMerchantGeneralBasicInfoPanelAdminStart',
    fetchMerchantGeneralBasicInfoPanelAdminAsync
  )
}

export function* watchFetchMerchantDraftBasicInfoPanelAdmin() {
  yield takeLatest(
    'merchant/fetchMerchantDraftBasicInfoPanelAdminStart',
    fetchMerchantDraftBasicInfoPanelAdminAsync
  )
}

export function* watchRejectMerchantAsync() {
  yield takeLatest('merchant/rejectMerchantStart', rejectMerchantAsync)
}

export function* watchVerifyMerchantAsync() {
  yield takeLatest('merchant/verifyMerchantStart', verifyMerchantAsync)
}

export function* watchActivateMerchantAsync() {
  yield takeLatest('merchant/activateMerchantStart', activateMerchantAsync)
}

export function* watchActivateSubscriptionOfMerchantAsync() {
  yield takeLatest(
    'merchant/activateSubscriptionOfMerchantStart',
    activateSubscriptionOfMerchantAsync
  )
}

export function* watchCreateStep4Draft() {
  yield takeLatest('merchant/createStep4DraftStart', createStep4DraftAsync)
}

export function* watchCreateStep4General() {
  yield takeLatest('merchant/createStep4GeneralStart', createStep4GeneralAsync)
}

export function* watchEditStep1() {
  yield takeLatest('merchant/editStep1Start', editStep1Async)
}

export function* watchEditStep1GeneralStart1() {
  yield takeLatest('merchant/editStep1GeneralStart', editStep1GeneralAsync)
}

export function* watchCheckApplyMerchantPremiumCode() {
  yield takeLatest(
    'merchant/checkApplyMerchantPremiumCodeStart',
    checkApplyMerchantPremiumCodeAsync
  )
}

export function* watchSetMerchantPopular() {
  yield takeLatest('merchant/setMerchantPopularStart', setMerchantPopularAsync)
}

export function* watchFetchMerchantProfileInfoAsync() {
  yield takeLatest(
    'merchant/fetchMerchantProfileInfoStart',
    fetchMerchantProfileInfoAsync
  )
}

export function* watchFetchMerchantProfileDiscountInfoAsync() {
  yield takeLatest(
    'merchant/fetchMerchantProfileDiscountInfoStart',
    fetchMerchantProfileDiscountInfoAsync
  )
}

export function* watchFetchMerchantProfileImagesInfoAsync() {
  yield takeLatest(
    'merchant/fetchMerchantProfileImagesInfoStart',
    fetchMerchantProfileImagesInfoAsync
  )
}

export function* watchFetchMerchantProfileWebsiteAndBankInfoAsync() {
  yield takeLatest(
    'merchant/fetchMerchantProfileWebsiteAndBankInfoStart',
    fetchMerchantProfileWebsiteAndBankInfoAsync
  )
}

export function* watchUpdateBankInfoAsync() {
  yield takeLatest('merchant/updateBankInfoStart', updateBankInfoAsync)
}

export function* watchFetchMerchantCardInfoAsync() {
  yield takeLatest(
    'merchant/fetchMerchantCardInfoStart',
    fetchMerchantCardInfoAsync
  )
}

export function* watchAddMerchantCardSourceAsync() {
  yield takeLatest(
    'merchant/addMerchantCardSourceStart',
    addMerchantCardSourceAsync
  )
}

export function* watchSetCardAsDefaultAsync() {
  yield takeLatest('merchant/setCardAsDefaultStart', setCardAsDefaultAsync)
}

export function* watchFetchBillingInfo() {
  yield takeLatest('merchant/fetchBillingInfoStart', fetchBillingInfoAsync)
}

// fetchWalletInfoSuccess
export function* watchFetchWalletInfo() {
  yield takeLatest('merchant/fetchWalletInfoStart', fetchWalletInfoAsync)
}

export function* watchCheckApplyMerchantPremiumCodeUpgradePackageAsync() {
  yield takeLatest(
    'merchant/checkApplyMerchantPremiumCodeUpgradePackageStart',
    checkApplyMerchantPremiumCodeUpgradePackageAsync
  )
}

export function* watchGenerateEcommerceKeyAsync() {
  yield takeLatest(
    'merchant/generateEcommerceKeyStart',
    generateEcommerceKeyAsync
  )
}

export function* watchEditOwnMerchantProfileAsync() {
  yield takeLatest(
    'merchant/editOwnMerchantProfileStart',
    editOwnMerchantProfileAsync
  )
}

export function* watchEditMerchantInfoStart() {
  yield takeLatest('merchant/editMerchantInfoStart', editMerchantInfoStartAsync)
}

export function* watchSendMerchantEmailStart() {
  yield takeLatest('merchant/sendMerchantEmailStart', sendMerchantEmailAsync)
}

export function* watchFetchMerchantTerminalList() {
  yield takeLatest(
    'merchant/fetchAllMerchantTerminalListStart',
    fetchMerchantTerminalListAsync
  )
}

export function* watchCreateMerchantTerminal() {
  yield takeLatest(
    'merchant/createMerchantTerminalStart',
    createMerchantTerminalAsync
  )
}

export function* watchEditMerchantTerminalAsync() {
  yield takeLatest(
    'merchant/editMerchantTerminalStart',
    editMerchantTerminalAsync
  )
}

export function* watchEditMerchantTerminalOwnProfileAsync() {
  yield takeLatest(
    'merchant/editMerchantTerminalOwnProfileStart',
    editMerchantTerminalOwnProfileAsync
  )
}

export function* watchFetchOneMerchantTerminalAsync() {
  yield takeLatest(
    'merchant/fetchOneMerchantTerminalStart',
    fetchOneMerchantTerminalAsync
  )
}
export function* watchDeactivateMerchantTerminalAsync() {
  yield takeLatest(
    'merchant/deactivateMerchantTerminalStart',
    deactivateMerchantTerminalAsync
  )
}

export function* watchUpdateMerchantTerminalPasswordAsync() {
  yield takeLatest(
    'merchant/updateMerchantTerminalPasswordStart',
    updateMerchantTerminalPasswordAsync
  )
}

export function* watchGetReferralTokenAsync() {
  yield takeLatest('merchant/getReferralTokenStart', getReferralTokenAsync)
}

export function* watchUpdateStep4GeneralAsync() {
  yield takeLatest('merchant/updateStep4GeneralStart', updateStep4GeneralAsync)
}

export function* watchUpdateStep2GeneralAsync() {
  yield takeLatest('merchant/updateStep2GeneralStart', updateStep2GeneralAsync)
}

export function* watchFetchAllReferredMerchantList() {
  yield takeLatest(
    'merchant/fetchAllReferredMerchantListStart',
    fetchAllReferredMerchantListAsync
  )
}

export function* watchCreateTerminalAsync() {
  yield takeLatest('merchant/createTerminalStart', createTerminalAsync)
}

export function* watchFetchTerminalListAsync() {
  yield takeLatest('merchant/fetchAllTerminalListStart', fetchTerminalListAsync)
}

export function* watchFetchTerminalListByPanelAsync() {
  yield takeLatest(
    'merchant/fetchTerminalListByPanelStart',
    fetchTerminalListByPanelAsync
  )
}

export function* watchFetchTerminalByIdAsync() {
  yield takeLatest('merchant/fetchTerminalByIdStart', fetchTerminalByIdAsync)
}

export function* watchEditTerminalAsync() {
  yield takeLatest('merchant/editTerminalStart', editTerminalAsync)
}

export function* watchFetchMultipleAssignerByRoleAsync() {
  yield takeLatest(
    'merchant/fetchMultipleAssignerByRoleStart',
    fetchMultipleAssignerByRoleAsync
  )
}

export function* watchTransferReferredMerchantAsync() {
  yield takeLatest(
    'merchant/transferReferredMerchantStart',
    transferReferredMerchantAsync
  )
}

export function* watchFetchReferredMerchantByIdAsync() {
  yield takeLatest(
    'merchant/fetchReferredMerchantByIdStart',
    fetchReferredMerchantByIdAsync
  )
}

export function* watchRejectReferredMerchantAsync() {
  yield takeLatest(
    'merchant/rejectReferredMerchantStart',
    rejectReferredMerchantAsync
  )
}

export function* watchApproveReferredMerchant() {
  yield takeLatest(
    'merchant/approveReferredMerchantStart',
    approveReferredMerchantAsync
  )
}

export function* watchEditReferredMerchantAsync() {
  yield takeLatest(
    'merchant/editReferredMerchantStart',
    editReferredMerchantAsync
  )
}

export function* watchReferMerchantMailFromDraftAsync() {
  yield takeLatest(
    'merchant/referMerchantMailFromDraftStart',
    referMerchantMailFromDraftAsync
  )
}

export function* watchAcceptOrRejectreferredMerchantAsync() {
  yield takeLatest(
    'merchant/acceptOrRejectreferredMerchantStart',
    acceptOrRejectreferredMerchantAsync
  )
}

export function* watchUpdateStep3GeneralAsync() {
  yield takeLatest('merchant/updateStep3GeneralStart', updateStep3GeneralAsync)
}

export function* watchCreateMerchantGroupAsync() {
  yield takeLatest(
    'merchant/createMerchantGroupStart',
    createMerchantGroupAsync
  )
}

export function* watchFetchAllMerchantGroup() {
  yield takeLatest(
    'merchant/fetchAllMerchantGroupStart',
    fetchAllMerchantGroupAsync
  )
}

export function* watchFetchMerchantGroupByIdAsync() {
  yield takeLatest(
    'merchant/fetchMerchantGroupByIdStart',
    fetchMerchantGroupByIdAsync
  )
}

export function* watchEditPendingMerchantGroup() {
  yield takeLatest(
    'merchant/editPendingMerchantGroupStart',
    editPendingMerchantGroupAsync
  )
}

export function* watchVerifyPendingMerchantGroup() {
  yield takeLatest(
    'merchant/verifyPendingMerchantGroupStart',
    verifyPendingMerchantGroupAsync
  )
}

export function* watchAssignIssuerTransactionCode() {
  yield takeLatest(
    'merchant/assignIssuerTransactionCodeStart',
    assignIssuerTransactionCodeAsync
  )
}

export function* watchEditMerchantGroup() {
  yield takeLatest('merchant/editMerchantGroupStart', editMerchantGroupAsync)
}

export function* watchFetchMerchantStateWiseDataAsync() {
  yield takeLatest(
    'merchant/fetchMerchantStateWiseDataStart',
    fetchMerchantStateWiseDataAsync
  )
}

export function* watchApplyMerchantCouponCodeAsync() {
  yield takeLatest(
    'merchant/applyMerchantCouponCodeStart',
    applyMerchantCouponCodeAsync
  )
}

export function* watchActivateOrDeactivateMerchant() {
  yield takeLatest(
    'merchant/activateOrDeactivateMerchantStart',
    activateOrDeactivateMerchantAsync
  )
}

export function* watchDeleteMerchant() {
  yield takeLatest('merchant/deleteMerchantStart', deleteMerchantAsync)
}

export function* watchFetchActivityListReportByPanel() {
  yield takeLatest(
    'merchant/fetchActivityListReportByPanelStart',
    fetchActivityListReportByPanelAsync
  )
}

export function* watchFetchMerchantMultilingualListByPanel() {
  yield takeLatest(
    'merchant/fetchMerchantMultilingualListByPanelStart',
    fetchMerchantMultilingualListByPanelAsync
  )
}

export function* watchAddMerchantMultilingual() {
  yield takeLatest(
    'merchant/addMerchantMultilingualStart',
    addMerchantMultilingualAsync
  )
}

export function* watchEditMerchantMultilingual() {
  yield takeLatest(
    'merchant/editMerchantMultilingualStart',
    editMerchantMultilingualAsync
  )
}

export function* watchFetchMerchantMultilingualById() {
  yield takeLatest(
    'merchant/fetchtMerchantMultilingualByIdStart',
    fetchMerchantMultilingualByIdAsync
  )
}

export function* watchFetchCharityMultilingualList() {
  yield takeLatest(
    'merchant/fetchCharityMultilingualListStart',
    fetchCharityMultilingualListAsync
  )
}

export function* watchCreateCharityMultilingualStart() {
  yield takeLatest(
    'merchant/createCharityMultilingualStart',
    createCharityMultilingualAsync
  )
}

export function* watchEditCharitytMultilingualStart() {
  yield takeLatest(
    'merchant/editCharitytMultilingualStart',
    editCharitytMultilingualAsync
  )
}

export function* watchFetchMerchantCategoryMultilingualList() {
  yield takeLatest(
    'merchant/fetchMerchantCategoryMultilingualListStart',
    fetchMerchantCategoryMultilingualListAsync
  )
}

export function* watchCreateMerchantCategoryMultilingualStart() {
  yield takeLatest(
    'merchant/createMerchantCategoryMultilingualStart',
    createMerchantCategoryMultilingualAsync
  )
}

export function* watchEditMerchantCategoryMultilingualStart() {
  yield takeLatest(
    'merchant/editMerchantCategoryMultilingualStart',
    editMerchantCategoryMultilingualAsync
  )
}

export function* watchFetchAreaMerchantGeneralByLocationStart() {
  yield takeLatest(
    'merchant/fetchAreaMerchantGeneralByLocationStart',
    fetchAreaMerchantGeneralByLocationAsync
  )
}

export function* merchantSagas() {
  yield all([
    call(watchCreateStep1Draft),
    call(watchFetchBillingInfo),
    call(watchCreateStep1General),
    call(watchCreateStep2Draft),
    call(watchCreateStep2General),
    call(watchCreateStep3Draft),
    call(watchCreateStep3General),
    call(watchFetchMerchantDiscountById),
    call(watchFetchMerchantDraft),
    call(watchFetchMerchantGeneral),
    call(watchCreateMerchantDiscount),
    call(watchFetchMerchantGeneralBasicInfoPanelUser),
    call(watchFetchMerchantDraftBasicInfoPanelUser),
    call(watchFetchMerchantDraftWebsiteInfoPanelUser),
    call(watchFetchMerchantDraftImagePanelUser),
    call(fetchMerchantDraftDiscountInfoPanelUser),
    call(watchFetchMerchantGeneralWebsiteInfoPanelUser),
    call(watchFetchMerchantGeneralImageInfoPanelUser),
    call(watchfetchMerchantGeneralDiscountInfoPanelUser),
    call(watchCreateBankInfo),
    call(watchFetchMerchantGeneralBankInfo),
    call(watchFetchAllMerchantList),
    call(watchFetchMerchantGeneralBasicInfoPanelAdmin),
    call(watchCreateStep4Draft),
    call(watchCreateStep4General),
    call(watchEditStep1),
    call(watchFetchMerchantDraftBasicInfoPanelAdmin),
    call(watchRejectMerchantAsync),
    call(watchCheckApplyMerchantPremiumCode),
    call(watchSetMerchantPopular),
    call(watchEditStep1GeneralStart1),
    call(watchVerifyMerchantAsync),
    call(watchActivateMerchantAsync),
    call(watchActivateSubscriptionOfMerchantAsync),
    call(watchFetchMerchantProfileInfoAsync),
    call(watchFetchMerchantProfileDiscountInfoAsync),
    call(watchFetchMerchantProfileImagesInfoAsync),
    call(watchFetchMerchantProfileWebsiteAndBankInfoAsync),
    call(watchUpdateBankInfoAsync),
    call(watchFetchMerchantCardInfoAsync),
    call(watchAddMerchantCardSourceAsync),
    call(watchSetCardAsDefaultAsync),
    call(watchFetchWalletInfo),
    call(watchCheckApplyMerchantPremiumCodeUpgradePackageAsync),
    call(watchGenerateEcommerceKeyAsync),
    call(watchEditOwnMerchantProfileAsync),
    call(watchEditMerchantInfoStart),
    call(watchSendMerchantEmailStart),
    call(watchFetchMerchantTerminalList),
    call(watchCreateMerchantTerminal),
    call(watchEditMerchantTerminalAsync),
    call(watchFetchOneMerchantTerminalAsync),
    call(watchDeactivateMerchantTerminalAsync),
    call(watchUpdateMerchantTerminalPasswordAsync),
    call(watchGetReferralTokenAsync),
    call(watchUpdateStep4GeneralAsync),
    call(watchUpdateStep2GeneralAsync),
    call(watchFetchAllReferredMerchantList),
    call(watchCreateTerminalAsync),
    call(watchFetchTerminalListAsync),
    call(watchFetchTerminalByIdAsync),
    call(watchEditTerminalAsync),
    call(watchFetchMultipleAssignerByRoleAsync),
    call(watchTransferReferredMerchantAsync),
    call(watchFetchReferredMerchantByIdAsync),
    call(watchRejectReferredMerchantAsync),
    call(watchApproveReferredMerchant),
    call(watchEditMerchantTerminalOwnProfileAsync),
    call(watchEditReferredMerchantAsync),
    call(watchReferMerchantMailFromDraftAsync),
    call(watchAcceptOrRejectreferredMerchantAsync),
    call(watchFetchTerminalListByPanelAsync),
    call(watchUpdateStep3GeneralAsync),
    call(watchCreateMerchantGroupAsync),
    call(watchFetchAllMerchantGroup),
    call(watchFetchMerchantGroupByIdAsync),
    call(watchEditPendingMerchantGroup),
    call(watchFetchMerchantFranchisorGeneral),
    call(watchFetchMerchantFranchiseGeneralBasicInfoPanelUser),
    call(watchEditMerchantGroup),
    call(watchVerifyPendingMerchantGroup),
    call(watchFetchMerchantFranchisorDraft),
    call(watchAssignIssuerTransactionCode),
    call(watchFetchMerchantStateWiseDataAsync),
    call(watchApplyMerchantCouponCodeAsync),
    call(watchActivateOrDeactivateMerchant),
    call(watchDeleteMerchant),
    call(watchFetchActivityListReportByPanel),
    call(watchFetchMerchantMultilingualListByPanel),
    call(watchAddMerchantMultilingual),
    call(watchEditMerchantMultilingual),
    call(watchFetchMerchantMultilingualById),
    call(watchFetchMerchantGeneralBasicInfoForStateMerchantStart),
    call(watchFetchCharityMultilingualList),
    call(watchCreateCharityMultilingualStart),
    call(watchEditCharitytMultilingualStart),
    call(watchFetchMerchantCategoryMultilingualList),
    call(watchCreateMerchantCategoryMultilingualStart),
    call(watchEditMerchantCategoryMultilingualStart),
    call(watchFetchAreaMerchantGeneralByLocationStart),
  ])
}
