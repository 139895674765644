import { axiosRequest, removeEmptyFields } from 'utils'

export const fetchPrizeDrawList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/prizeDraw/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminPrizeDrawList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/prizeDraw/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchPrizeDrawById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/prizeDraw/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPrizeDraw = (params) => {
  return () =>
    axiosRequest
      .post(`/api/prizeDraw/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// export const editTrainingVideo = (id, params) => {
//   return () =>
//     axiosRequest
//       .put(`/api/globalSetting/trainingVideo/edit/${id}`, params)
//       .then((res) => {
//         return Promise.resolve(res)
//       })
//       .catch((err) => {
//         return Promise.reject(err)
//       })
// }

//prize draw luck draw

export const fetchLuckDrawList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/prizeDraw/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createPrizeDrawLuckDraw = (id, params) => {
  return () =>
    axiosRequest
      .post(`/api/prizeDraw/luckydraw/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
