import { axiosRequestCMS, removeEmptyFields } from 'utils'

export const fetchHeaderMenuList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/menu/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const createHeaderMenu = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/menu/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const activeMenuItem = (params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/menu/isPublished/${params?.id}`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const deleteMenuItem = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/menu/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
