/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'
import {
  fetchCPProfileInfoStart,
  fetchStripeInfoStart,
} from 'store/collaborationPartner/collaborationPartnerSlice'

import { fetchMemberStart } from 'store/members/membersSlice'
import {
  createStripePayoutAccountStart,
  generateConnectAccountLinkStart,
  stripeCheckOnboardStatusStart,
  stripeLoginUrlStart,
} from 'store/salesAgent/salesAgentSlice'
// import {
//   fetchCountryStart,
//   createCountryStart,
// } from '../../../store/countrySetup/countrySetupSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  areaOwner: state.areaOwner,
  stateOwners: state.stateOwner,
  charities: state.charities,
  club: state.club,
  members: state.members,
  siteCoordinator: state.siteCoordinator,
  collaborationPartner: state.collaborationPartner,
  salesAgent: state.salesAgent,
})
const mapDispatchToProps = (dispatch) => ({
  onFetchMemberStart: (payload) => dispatch(fetchMemberStart(payload)),
  onFetchCPProfileInfoStart: (payload) =>
    dispatch(fetchCPProfileInfoStart(payload)),
  onFetchStripeInfoStart: (payload) => dispatch(fetchStripeInfoStart(payload)),
  onCreateStripePayoutAccountStart: (payload) =>
    dispatch(createStripePayoutAccountStart(payload)),
  onGenerateConnectAccountLinkStart: (payload) =>
    dispatch(generateConnectAccountLinkStart(payload)),
  onStripeCheckOnboardStatusStart: (payload) =>
    dispatch(stripeCheckOnboardStatusStart(payload)),
  onStripeLoginUrlStart: (payload) => dispatch(stripeLoginUrlStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
