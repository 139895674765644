import { createSlice } from '@reduxjs/toolkit'

import { ICountryAdminState } from '../interfaces'

const INITIAL_STATE: ICountryAdminState = {
  pendingAreaOwnerList: [],
  error: null,
  loading: false,
  hasMoreData: false,
}

export const countryAdminSlice = createSlice({
  name: 'countryAdmin',
  initialState: INITIAL_STATE,
  reducers: {
    fetchpendingAreaOwnerStart: (state: any, action) => {
      state.loading = true
    },
    fetchpendingAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pendingAreaOwnerList = action.payload.data
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchpendingAreaOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchpendingAreaOwnerByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchpendingAreaOwnerByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.countryList = action.payload.data
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchpendingAreaOwnerByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    // createCountryStart: (state: any, action) => {
    //   state.loading = true
    // },
    // createCountrySuccess: (state: any, action) => {
    //   // state.currentUser= action.payload;
    //   state.loading = false
    //   state.countryList = [...state.countryList, action.payload]
    // },
    // createCountryFailure: (state: any, action) => {
    //   state.loading = false
    //   state.error = action.payload
    // },
    deletePendingAreaOwnerStart: (state: any, action) => {
      state.loading = true
    },
    deletePendingAreaOwnerSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.pendingAreaOwnerList = state.pendingAreaOwnerList.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePendingAreaOwnerFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    // editCountryStart: (state: any, action) => {
    //   state.loading = true
    // },
    // editCountrySuccess: (state: any, action) => {
    //   // state.currentUser= action.payload;

    //   const result = state.countryList.map((obj) =>
    //     obj.id === action.payload.id ? action.payload : obj
    //   )

    //   state.countryList = result

    //   state.loading = false
    // },
    // editCountryFailure: (state: any, action) => {
    //   state.loading = false
    //   state.error = action.payload
    // },
  },
})

export const {
  fetchpendingAreaOwnerStart,
  fetchpendingAreaOwnerSuccess,
  fetchpendingAreaOwnerFailure,
  deletePendingAreaOwnerStart,
  deletePendingAreaOwnerSuccess,
  deletePendingAreaOwnerFailure,
  //   createCountryStart,
  //   createCountrySuccess,
  //   createCountryFailure,
  //   deleteCountryStart,
  //   deleteCountrySuccess,
  //   deleteCountryFailure,
  //   fetchCountryByIdStart,
  //   fetchCountryByIdSuccess,
  //   fetchCountryByIdFailure,
  //   editCountryStart,
  //   editCountrySuccess,
  //   editCountryFailure,
} = countryAdminSlice.actions

export default countryAdminSlice.reducer
