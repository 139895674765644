import React from 'react'
import { Box, Typography } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

interface PreviewThumbProps {
  file?: any
  onDeleteUploadFile: (file: any) => void
}

const PreviewThumb: React.FC<PreviewThumbProps> = ({
  file,
  onDeleteUploadFile,
}) => {
  const isPdf = file.type === 'application/pdf'

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          borderRadius: 2,
          border: '1px solid #eaeaea',
          marginBottom: 8,
          marginRight: 8,
          width: 80,
          height: 80,
          padding: 1,
          boxSizing: 'border-box',
          '& img': {
            display: 'block',
            width: 'auto',
            objectFit: 'cover',
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
          }}
        >
          <DeleteOutlineOutlinedIcon
            sx={{
              color: 'text.secondary',
              borderRadius: '50%',
              padding: 1,
              '&:hover, &:focus': {
                color: 'warning.main',
                backgroundColor: 'primary.contrastText',
              },
            }}
            onClick={() => onDeleteUploadFile(file)}
          />
        </Box>

        {isPdf ? (
          <>
            <img
              alt="preview"
              src={
                'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/121px-PDF_file_icon.svg.png'
              }
            />
          </>
        ) : (
          <>
            <img alt="preview" src={file.preview} />
          </>
        )}
      </Box>
      <Typography sx={{}}>{file?.name}</Typography>
    </Box>
  )
}

export default PreviewThumb
