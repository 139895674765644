/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'
import { logOut } from 'store/auth/authSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  dropDown: state.dropDown,
})

const mapDispatchToProps = (dispatch) => ({
  onLogOut: () => dispatch(logOut()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
