import { takeLatest, call, all, put, select, delay } from 'redux-saga/effects'
import {
  fetchPrizeDrawByPanelStart,
  fetchPrizeDrawByPanelSuccess,
  fetchPrizeDrawByPanelFailure,
  fetchGlobalAdminPrizeDrawStart,
  fetchGlobalAdminPrizeDrawSuccess,
  fetchGlobalAdminPrizeDrawFailure,
  fetchPrizeDrawByIdStart,
  fetchPrizeDrawByIdSuccess,
  fetchPrizeDrawByIdFailure,
  createPrizeDrawByPanelStart,
  createPrizeDrawByPanelSuccess,
  createPrizeDrawByPanelFailure,
  createLuckyDrawSuccess,
  createLuckyDrawFailure,
  fetchLuckyDrawStart,
  fetchLuckyDrawSuccess,
  fetchLuckyDrawFailure,
  clearLuckyDrawStart,
} from './prizeDrawSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchPrizeDrawList,
  fetchGlobalAdminPrizeDrawList,
  fetchPrizeDrawById,
  fetchLuckDrawList,
  createPrizeDraw,
  createPrizeDrawLuckDraw,
} from 'api/prizeDraw'

import { removeParam } from 'utils'
import _ from 'lodash'

export const getRole = (state) => state.auth

export function* fetchPrizeDrawByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPrizeDrawList(payload))

    yield put(fetchPrizeDrawByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPrizeDrawByPanelFailure(err))
  }
}

export function* fetchGlobalAdminPrizeDrawAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdminPrizeDrawList(payload))

    yield put(fetchGlobalAdminPrizeDrawSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminPrizeDrawFailure(err))
  }
}

export function* createPrizeDrawByPanelAsync({ payload }: AnyAction) {
  try {
    let currentRole = yield select(getRole)

    const { data } = yield call(createPrizeDraw(payload.params))
    yield put(createPrizeDrawByPanelSuccess(data?.data))
    yield payload.closeModal()

    if (currentRole?.currentUser?.role.toLowerCase() === 'globaladmin') {
      yield put(
        fetchGlobalAdminPrizeDrawStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    } else {
      yield put(
        fetchPrizeDrawByPanelStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }

    yield put(
      openAlert({
        message: 'Prize draw successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createPrizeDrawByPanelFailure(err))
  }
}

export function* fetchLuckyDrawAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchLuckDrawList(payload))

    yield put(fetchLuckyDrawSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchLuckyDrawFailure(err))
  }
}

export function* fetchPrizeDrawLuckDrawByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPrizeDrawById(payload))

    yield put(fetchPrizeDrawByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPrizeDrawByIdFailure(err))
  }
}

export function* createLuckyDrawAsync({ payload }: AnyAction) {
  try {
    let currentRole = yield select(getRole)

    const { data } = yield call(
      createPrizeDrawLuckDraw(payload?.params?.id, {
        ...payload?.params,
      })
    )

    yield put(createLuckyDrawSuccess(data))

    if (data.status === 204) {
      // Handle 204 No Content status code here
    }

    if (_.isEmpty(data)) {
      yield put(
        openAlert({
          message: 'No member found',
          severity: 'error',
        })
      )

      if (payload?.refreshPrizeDraw) {
        yield call(payload?.refreshPrizeDraw)
      }
      // yield put(clearLuckyDrawStart())
    } else {
      if (currentRole?.currentUser?.role.toLowerCase() === 'globaladmin') {
        yield put(
          fetchGlobalAdminPrizeDrawStart({
            page: 1,
            limit: 10,
            offset: 0,
          })
        )
      } else {
        yield put(
          fetchPrizeDrawByPanelStart({
            page: 1,
            limit: 10,
            offset: 0,
          })
        )
      }

      yield put(
        openAlert({
          message: 'Winner Found Successfully',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createLuckyDrawFailure(err))
  }
}

export function* watchFetchPrizeDrawByPanel() {
  yield takeLatest(
    'prizeDraw/fetchPrizeDrawByPanelStart',
    fetchPrizeDrawByPanelAsync
  )
}

export function* watchGlobalAdminFetchPrizeDraw() {
  yield takeLatest(
    'prizeDraw/fetchGlobalAdminPrizeDrawStart',
    fetchGlobalAdminPrizeDrawAsync
  )
}

export function* watchFetchPrizeDrawLuckDrawByPanel() {
  yield takeLatest(
    'prizeDraw/fetchPrizeDrawByIdStart',
    fetchPrizeDrawLuckDrawByIdAsync
  )
}

export function* watchCreatePrizeDrawByPanel() {
  yield takeLatest(
    'prizeDraw/createPrizeDrawByPanelStart',
    createPrizeDrawByPanelAsync
  )
}

export function* watchFetchLuckyDraw() {
  yield takeLatest('prizeDraw/fetchLuckyDrawStart', fetchLuckyDrawAsync)
}

export function* watchCreateLuckyDraw() {
  yield takeLatest('prizeDraw/createLuckyDrawStart', createLuckyDrawAsync)
}

export function* prizeDrawSagas() {
  yield all([
    call(watchFetchPrizeDrawByPanel),
    call(watchGlobalAdminFetchPrizeDraw),
    call(watchCreatePrizeDrawByPanel),
    call(watchFetchLuckyDraw),
    call(watchCreateLuckyDraw),
    call(watchFetchPrizeDrawLuckDrawByPanel),
  ])
}
