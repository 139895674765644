import React from 'react'
import { Box, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Fonts } from '../../../../../shared/constants/AppEnums'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { logOut } from 'store/auth/authSlice'

import { camelCaseToSpace } from 'utils'
import _ from 'lodash'

interface UserInfoProps {
  color?: string
  onLogOut?: Function
  auth?: any
}

const UserInfo: React.FC<UserInfoProps> = ({
  color = 'text.secondary',
  auth: { currentUser },
  onLogOut,
}) => {
  const navigate = useNavigate()
  const user = {
    displayName: '',
    email: '',
    photoURL: '',
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const role = currentUser?.role.toLowerCase()
  // const primaryContactName = currentUser?.primaryContactName
  // const name = currentUser?.name
  // const username = currentUser?.username

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase()
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase()
    }
  }

  const getDisplayName = () => {
    if (
      role === 'countryowner' ||
      role === 'stateowner' ||
      role === 'regionowner' ||
      role === 'areaowner'
    ) {
      return currentUser?.primaryContactName
    } else if (role === 'club' || role === 'charity') {
      return currentUser?.name
    } else if (
      role === 'salesagent' ||
      role === 'collaborationpartner' ||
      role === 'whitelabeluser' ||
      role === 'merchant'
    ) {
      return currentUser?.name ? currentUser?.name : currentUser?.username
    } else {
      return ''
    }
  }

  const getDisplayRole = (role) => {
    if (
      role === 'countryowner' ||
      role === 'stateowner' ||
      role === 'regionowner' ||
      role === 'areaowner'
    ) {
      return currentUser?.role.replace(/Owner/g, ' licensee')
    } else if (role === 'whitelabeluser')
      if (
        currentUser?.whiteLabel?.whiteLabelPosition?.isBalancingLevel === true
      ) {
        return currentUser?.whiteLabel?.whiteLabelPosition?.positionName
      } else if (
        currentUser?.whiteLabel?.whiteLabelPosition?.isIntroducer === true
      ) {
        return 'Introducer -'.concat(
          '',
          currentUser?.userCountry?.whiteLabelCompanyName.slice(0, 13) +
            (currentUser?.userCountry?.whiteLabelCompanyName.length > 13
              ? '...'
              : '')
        )
      } else {
        return 'User -'.concat(
          '',
          currentUser?.userCountry?.whiteLabelCompanyName.slice(0, 13) +
            (currentUser?.userCountry?.whiteLabelCompanyName.length > 13
              ? '...'
              : '')
        )
      }
    else return camelCaseToSpace(currentUser?.role)
  }

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          py: 3,
          px: 3,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        className="user-info-view"
      >
        <Box sx={{ py: 0.5 }}>
          {user.photoURL ? (
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 24,
                backgroundColor: '#ec4785',
              }}
              src={user.photoURL}
            />
          ) : (
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 24,
                backgroundColor: '#ec4785',
              }}
            >
              {getUserAvatar()}
            </Avatar>
          )}
        </Box>
        <Box
          sx={{
            width: { xs: 'calc(100% - 62px)', xl: 'calc(100% - 72px)' },
            ml: 4,
            color: color,
          }}
          className="user-info"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                mb: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 16,
                fontWeight: Fonts.MEDIUM,
                color: 'inherit',
              }}
              component="span"
            >
              {/* {user.displayName ? user.displayName : 'Admin Useriii '} */}
              {getDisplayName()}
            </Box>
            <Box
              sx={{
                ml: 3,
                color: 'inherit',
                display: 'flex',
              }}
            >
              <ExpandMoreIcon />
            </Box>
          </Box>

          <Box
            sx={{
              mt: `${_.isEmpty(getDisplayName()) ? '-1.2rem' : 0}`,
              color: 'inherit',
            }}
          >
            <Typography
              sx={{
                inlineSize: '210px',
                overflowWrap: 'break-word',
                textTransform: 'capitalize',
              }}
            >
              {/* {camelCaseToSpace(currentUser?.role)} */}
              {getDisplayRole(role)}
              {/* {currentUser?.whiteLabel?.whiteLabelPosition?.positionName
                ? ` - ${currentUser?.whiteLabel?.whiteLabelPosition?.positionName}`
                : ''} */}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            navigate('/my-account')
          }}
        >
          My Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            onLogOut()
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

const mapStateToProps = (state, props) => ({
  settings: state.settings,
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
  onLogOut: () => dispatch(logOut()),
})
const container = connect(mapStateToProps, mapDispatchToProps)

export default container(UserInfo)
