import React from 'react'

import AppMessageView from '@crema/core/AppMessageView'
import AppLoader from '@crema/core/AppLoader'
// import { AppState } from '../../../redux/store';
import { connect } from 'react-redux'

const AppInfoView = ({ settings }) => {
  const { error, loading, message } = settings
  const showMessage = () => {
    return <AppMessageView variant="success" message={message.toString()} />
  }

  const showError = () => {
    return <AppMessageView variant="error" message={error.toString()} />
  }

  return (
    <>
      {loading && <AppLoader />}

      {message && showMessage()}
      {error && showError()}
    </>
  )
}

const mapStateToProps = (state, props) => ({
  settings: state.settings,
})

const container = connect(mapStateToProps, null)

export default container(AppInfoView)
