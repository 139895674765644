import { axiosRequestCMS } from 'utils'

export const fetchAboutUsList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/aboutUs/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAboutUsById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/aboutUs/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createAboutUs = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/aboutUs/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteAboutUs = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/aboutUs/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editAboutUs = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(
        `/api/aboutUs/edit/${id}?isTranslateToAlllang=${params?.isTranslateToAlllang}`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteAboutUsBySlug = (params) => {
  return () =>
    axiosRequestCMS
      .delete(`api/aboutUs/deletelist`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
