import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Edit, Delete } from '@mui/icons-material'
import AppConfirmDialog from '@crema/core/AppConfirmDialog'
import { Tooltip, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { Visibility } from '@mui/icons-material'
import { camelCaseToSpace, getCreatorName } from 'utils'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: '10px 20px 10px 10px',
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  count: number
  page: any
  pageNumber: number
  rowsPerPage: any
  refreshPageData?: any
  onDeleteDocumentStart?: any
  onOpenAlert?: any
  currentUser?: any
}

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  page,
  pageNumber,
  rowsPerPage,
  refreshPageData,
  onDeleteDocumentStart,
  onOpenAlert,
  currentUser,
}) => {
  const [isEditStateOpen, setEditStateOpen] = useState<boolean>(false)
  const [selectedState, setSelectedState] = useState<Object>({})
  const [deleteId, setDeleteId] = useState<number>()
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  const onOpenEditState = (data) => {
    setEditStateOpen(true)
    setSelectedState(data)
  }

  const onCloseEditState = () => {
    setEditStateOpen(false)
  }

  const DeleteDialog = (contactIds: number) => {
    setDeleteDialogOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteDialogOpen(false)
  }

  const onDeleteSelectedData = () => {
    onDeleteDocumentStart({
      id: deleteId,
      closeModal: closeDeleteModal,
      refreshPageData,
    })
  }

  const copyToClipBoard = (data) => {
    onOpenAlert({
      message: 'Temporary Password copied to Clip board',
      severity: 'success',
    })
    navigator.clipboard.writeText(data?.temporaryPassword ?? '')
  }

  return (
    <>
      <TableRow key={data.sn} className="item-hover">
        <StyledTableCell align="left">
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.__country__?.countryName ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {camelCaseToSpace(data?.userType) ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">{data?.userId ?? '-'}</StyledTableCell>

        <StyledTableCell align="left">{data?.email ?? '-'}</StyledTableCell>
        <StyledTableCell align="left">{data?.username ?? '-'}</StyledTableCell>
        {currentUser?.role?.toLowerCase() !== 'countryowner' && (
          <>
            {' '}
            <StyledTableCell align="left">
              {camelCaseToSpace(data?.createdByType) ?? '-'}
            </StyledTableCell>
            <StyledTableCell align="left">
              {getCreatorName(data) ?? '-'}
            </StyledTableCell>
          </>
        )}

        <StyledTableCell align="left">
          {data?.createdAt == null
            ? '-'
            : moment(data?.createdAt).format('DD-MM-YYYY')}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.passwordExpiry == null
            ? '-'
            : moment(data?.passwordExpiry).format('DD-MM-YYYY')}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.temporaryPassword ?? '-'}
        </StyledTableCell>
        <StyledTableCell
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          align="left"
        >
          <Tooltip placement="top" title="Copy">
            <IconButton
              sx={{ fontWeight: '15px' }}
              onClick={() => {
                copyToClipBoard(data)
              }}
            >
              <ContentCopyIcon sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>
        </StyledTableCell>
      </TableRow>
      {isDeleteDialogOpen && (
        <AppConfirmDialog
          open={isDeleteDialogOpen}
          onDeny={setDeleteDialogOpen}
          onConfirm={onDeleteSelectedData}
          title="Are you sure you want to delete this document?"
          dialogTitle={`Delete Document`}
        />
      )}
    </>
  )
}

export default TableItem
