import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableHeader from '@crema/core/AppTable/TableHeader'

const TableHeading = () => {
  return (
    <TableHeader>
      <TableCell align="left">SN</TableCell>
      <TableCell align="left">Merchant Name</TableCell>
      <TableCell align="left">Email</TableCell>

      <TableCell align="left">Phone</TableCell>
      <TableCell align="left">Contact Person Name</TableCell>
      <TableCell align="left">Contact Person Phone</TableCell>
      <TableCell align="left">Contact Person Email</TableCell>

      <TableCell align="left">Referral Status</TableCell>
      <TableCell align="left">Merchant Status</TableCell>
      <TableCell align="left">Is Transferred ?</TableCell>

      <TableCell align="left">Actions</TableCell>
    </TableHeader>
  )
}

export default TableHeading
