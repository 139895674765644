import { takeLatest, call, all, put, delay, select } from 'redux-saga/effects'
import {
  signInStart,
  globalAdminLoginSuccess,
  globalAdminLoginFailure,
  countryAdminLoginSuccess,
  countryAdminLoginFailure,
  globalMarketingCompanyAdminLoginSuccess,
  globalMarketingCompanyAdminLoginFailure,
  areaAdminLoginSuccess,
  areaAdminLoginFailure,
  stateAdminLoginSuccess,
  stateAdminLoginFailure,
  regionAdminLoginSuccess,
  regionAdminLoginFailure,
  charityAdminLoginSuccess,
  charityAdminLoginFailure,
  whiteLabelAdminLoginStart,
  whiteLabelAdminLoginSuccess,
  whiteLabelAdminLoginFailure,
  clubAdminLoginSuccess,
  clubAdminLoginFailure,
  salesAgentLoginSuccess,
  salesAgentLoginFailure,
  collaborationPartnerLoginSuccess,
  collaborationPartnerLoginFailure,
  merchantAdminLoginStart,
  merchantAdminLoginSuccess,
  merchantAdminLoginFailure,
  websiteAdminLoginStart,
  websiteAdminLoginSuccess,
  websiteAdminLoginFailure,
  agreeTermAndConditionSuccess,
  agreeTermAndConditionFailure,
  merchantFranchisorLoginSuccess,
  merchantFranchisorLoginFailure,
  companyLoginFailure,
  companyLoginSuccess,
} from './authSlice'

import { clearSiteCoordinator } from 'store/siteCoordinator/siteCoordinatorSlice'

import { setGlobalCountry } from '../siteCoordinator/siteCoordinatorSlice'
import { AnyAction } from 'redux'

import {
  globalAdminLogin,
  countryAdminLogin,
  globalMarketingCompanyAdminLogin,
  areaAdminLogin,
  whiteLabelAdminLogin,
  stateAdminLogin,
  regionAdminLogin,
  charityAdminLogin,
  clubAdminLogin,
  salesAgentLogin,
  collaborationPartnerLogin,
  merchantAdminLogin,
  websiteAdminLogin,
  merchantFranchisorAdminLogin,
  companyAdminLogin,
} from 'api/login'
import { agreeTermAndCondition } from 'api/termsAndCondition'

import jwt from 'jwt-decode'
import { IDecodedState } from '../interfaces'
import { openAlert } from 'store/alert/alertSlice'

import _ from 'lodash'

// eslint-disable-next-line
export const getCountryList = (state) => state.dropDown

export function* onSigninAsync() {}

function setTokenToLocalStorage(data) {
  const decoded: IDecodedState = jwt(data?.data?.accessToken)

  localStorage.setItem('authToken', data?.data?.accessToken)
  localStorage.setItem('authRole', decoded.role)
  localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))
  return decoded
}

export function* globalAdminLoginAsync({ payload }: AnyAction) {
  try {
    let dropDown = yield select(getCountryList)

    const { data } = yield call(globalAdminLogin(payload.params))

    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    let filteredCountry: any = {}

    if (dropDown?.countryList?.length) {
      filteredCountry = dropDown?.countryList?.find(
        (item) => item.id === data?.data?.user?.countryId
      )
    }

    yield put(
      globalAdminLoginSuccess({
        ...decoded,
        userCountry: {
          ...data?.data?.user,
          currencyName: filteredCountry?.currencyName,
          currencySymbol: filteredCountry?.currencySymbol,
          id: data?.data?.user?.countryId,
        },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          currencyName: filteredCountry?.currencyName,
          currencySymbol: filteredCountry?.currencySymbol,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(globalAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))

    console.error(err)
  }
}

export function* countryAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(countryAdminLogin(payload?.params))

    const datatoken = {
      data: { accessToken: data?.data?.accessToken },
    }

    const decoded = setTokenToLocalStorage(datatoken)

    yield put(
      countryAdminLoginSuccess({
        ...decoded,
        accessToken: data?.data?.accessToken,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }

    // payload?.navigate('/countryadmin/pendingareaowner')
  } catch (err) {
    yield put(countryAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))

    console.error(err)
  }
}

export function* globalMarketingCompanyAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(globalMarketingCompanyAdminLogin(payload))

    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      globalMarketingCompanyAdminLoginSuccess({
        ...decoded,
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )

    // payload?.navigate('/countryadmin/pendingareaowner')
  } catch (err) {
    yield put(globalMarketingCompanyAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))

    console.error(err)
  }
}

export function* areaAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(areaAdminLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      areaAdminLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(areaAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

//state login async

export function* stateAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(stateAdminLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      stateAdminLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )
    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(stateAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

// region admin login async

export function* regionAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(regionAdminLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      regionAdminLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(regionAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

//charity login part
export function* charityAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(charityAdminLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      charityAdminLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(charityAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}
//club login part
export function* clubAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(clubAdminLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      clubAdminLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )
    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(clubAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

//sales agent login part
export function* salesAgentLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(salesAgentLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      salesAgentLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )
    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(salesAgentLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}
//collaboration partner login part
export function* collaborationPartnerLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(collaborationPartnerLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      collaborationPartnerLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(collaborationPartnerLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

export function* whiteLabelAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(whiteLabelAdminLogin(payload?.params))

    const datatoken = {
      data: {
        accessToken: data?.data?.accessToken,
        whiteLabel: data?.data?.whiteLabel,
      },
    }

    const decoded = setTokenToLocalStorage(datatoken)

    yield put(
      whiteLabelAdminLoginSuccess({
        ...decoded,
        accessToken: data?.data?.accessToken,
        userCountry: {
          ...data?.data?.user,
          countryName: data?.data?.user?.whiteLabelCountryName,
          id: data?.data?.user?.countryId,
        },
        whiteLabel: data?.data?.user,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          countryId: data?.data?.user?.countryId,
          id: data?.data?.user?.countryId,
          countryName: data?.data?.user?.whiteLabelCountryName,
        })
      )
    }

    // payload?.navigate('/whiteLabelAdmin/usersList')
  } catch (err) {
    yield put(countryAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))

    console.error(err)
  }
}

export function* merchantAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(merchantAdminLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      merchantAdminLoginSuccess({
        ...decoded,
        role:
          decoded?.role?.toLowerCase() === 'terminaluser'
            ? 'merchant'
            : decoded?.role,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
        currencyName: data?.data?.user?.currencyName,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(merchantAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

export function* websiteAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(websiteAdminLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      websiteAdminLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
      })
    )
  } catch (err) {
    yield put(websiteAdminLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

export function* merchantFranchisorAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(merchantFranchisorAdminLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      merchantFranchisorLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
        currencyName: data?.data?.user?.currencyName,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(merchantFranchisorLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

export function* companyAdminLoginAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(companyAdminLogin(payload.params))
    const decoded: IDecodedState = jwt(data?.data?.accessToken)

    localStorage.setItem('authToken', data?.data?.accessToken)
    localStorage.setItem('authRole', decoded.role)
    localStorage.setItem('expires_on', JSON.stringify(decoded.exp * 1000))

    yield put(
      companyLoginSuccess({
        ...decoded,
        userCountry: { ...data?.data?.user, id: data?.data?.user?.countryId },
        accessToken: data?.data?.accessToken,
        agreedTermAndCondition: data?.data?.user?.agreedTermAndCondition,
        currencyName: data?.data?.user?.currencyName,
      })
    )

    if (!_.isEmpty(data?.data?.user)) {
      yield put(
        setGlobalCountry({
          ...data?.data?.user,
          id: data?.data?.user?.countryId,
        })
      )
    }
  } catch (err) {
    yield put(companyLoginFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

export function* agreeTermAndConditionAsync({ payload }: AnyAction) {
  try {
    let apiRoute = payload.role

    if (payload.role === 'whiteLabelUser') {
      apiRoute = 'whiteLabel/whiteLabelUser'
    } else if (payload.role === 'merchant') {
      apiRoute = 'merchant/general'
    } else if (payload.role === 'collaborationPartner') {
      apiRoute = 'salesAgent'
    } else if (payload.role === 'merchantFranchisor') {
      apiRoute = 'merchant/fanchisor'
    }

    const response = yield call(
      agreeTermAndCondition(apiRoute, {
        agreedTermAndCondition: true,
      })
    )

    if (response.status === 200) {
      yield put(agreeTermAndConditionSuccess())
      yield put(
        openAlert({
          message: 'Agreed to Terms and Conditions Successfully',
          severity: 'success',
        })
      )
      payload.navigate('/')
    } else throw new Error('Request Failed')
  } catch (err) {
    yield put(agreeTermAndConditionFailure(err))
    yield put(openAlert({ message: err.message, severity: 'error' }))
  }
}

export function* logOutAsync({ payload }: AnyAction) {
  yield put(clearSiteCoordinator())
}

export function* watchSignin() {
  yield takeLatest('auth/logIn', onSigninAsync)
}

export function* watchGlobalAdminLogin() {
  yield takeLatest('auth/globalAdminLoginStart', globalAdminLoginAsync)
}

export function* watchCountryAdminLogin() {
  yield takeLatest('auth/countryAdminLoginStart', countryAdminLoginAsync)
}

export function* watchGlobalMarketingCompanyAdminLogin() {
  yield takeLatest(
    'auth/globalMarketingCompanyAdminLoginStart',
    globalMarketingCompanyAdminLoginAsync
  )
}

export function* watchAreaAdminLogin() {
  yield takeLatest('auth/areaAdminLoginStart', areaAdminLoginAsync)
}

export function* watchStateAdminLogin() {
  yield takeLatest('auth/stateAdminLoginStart', stateAdminLoginAsync)
}

export function* watchRegionAdminLogin() {
  yield takeLatest('auth/regionAdminLoginStart', regionAdminLoginAsync)
}
export function* watchCharityAdminLogin() {
  yield takeLatest('auth/charityAdminLoginStart', charityAdminLoginAsync)
}
export function* watchClubAdminLogin() {
  yield takeLatest('auth/clubAdminLoginStart', clubAdminLoginAsync)
}
export function* watchSalesAgentLogin() {
  yield takeLatest('auth/salesAgentLoginStart', salesAgentLoginAsync)
}
export function* watchCollaborationPartnerLogin() {
  yield takeLatest(
    'auth/collaborationPartnerLoginStart',
    collaborationPartnerLoginAsync
  )
}
export function* watchWhiteLabelAdminLogin() {
  yield takeLatest('auth/whiteLabelAdminLoginStart', whiteLabelAdminLoginAsync)
}

export function* watchMerchantAdminLogin() {
  yield takeLatest('auth/merchantAdminLoginStart', merchantAdminLoginAsync)
}

export function* watchMerchantFranchisorAdminLogin() {
  yield takeLatest(
    'auth/merchantFranchisorLoginStart',
    merchantFranchisorAdminLoginAsync
  )
}

export function* watchWebsiteAdminLogin() {
  yield takeLatest('auth/websiteAdminLoginStart', websiteAdminLoginAsync)
}

export function* watchCompanyAdminLoginAsync() {
  yield takeLatest('auth/companyLoginStart', companyAdminLoginAsync)
}

export function* watchLogOut() {
  yield takeLatest('auth/logOut', logOutAsync)
}

export function* watchAgreeTermAndCondition() {
  yield takeLatest(
    'auth/agreeTermAndConditionStart',
    agreeTermAndConditionAsync
  )
}

export function* authSagas() {
  yield all([
    call(watchSignin),
    call(watchGlobalAdminLogin),
    call(watchCountryAdminLogin),
    call(watchGlobalMarketingCompanyAdminLogin),
    call(watchAreaAdminLogin),
    call(watchStateAdminLogin),
    call(watchRegionAdminLogin),
    call(watchCharityAdminLogin),
    call(watchClubAdminLogin),
    call(watchSalesAgentLogin),
    call(watchCollaborationPartnerLogin),
    call(watchWhiteLabelAdminLogin),
    call(watchMerchantAdminLogin),
    call(watchWebsiteAdminLogin),
    call(watchMerchantFranchisorAdminLogin),
    call(watchCompanyAdminLoginAsync),
    call(watchLogOut),
    call(watchAgreeTermAndCondition),
  ])
}
