import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import AuthRoutes from '@crema/utility/AuthRoutes'
import AppContextProvider from '@crema/utility/AppContextProvider'
import AppThemeProvider from '@crema/utility/AppThemeProvider'
import AppStyleProvider from '@crema/utility/AppStyleProvider'
import AppLocaleProvider from '@crema/utility/AppLocaleProvider'
import EoiAppLayout from '@crema/core/EoiAppLayout'
import { BrowserRouter } from 'react-router-dom'
import { store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from 'store'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeCardElement } from '@stripe/stripe-js'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const options = {
  // passing the client secret obtained from the server
  clientSecret: '{{CLIENT_SECRET}}',
}

const App = () => {
  return (
    <AppContextProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppThemeProvider>
              <AppStyleProvider>
                <AppLocaleProvider>
                  <BrowserRouter>
                    <AuthRoutes>
                      <CssBaseline />
                      <EoiAppLayout />
                    </AuthRoutes>
                  </BrowserRouter>
                </AppLocaleProvider>
              </AppStyleProvider>
            </AppThemeProvider>
          </PersistGate>
        </Provider>
      </GoogleReCaptchaProvider>
    </AppContextProvider>
  )
}

export default App
