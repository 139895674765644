/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { sendClubReqStart } from 'store/club/clubSlice'
import {
  createStep1DraftStart,
  createStep1GeneralStart,
  fetchMerchantGeneralBasicInfoForStateMerchantStart,
} from 'store/merchant/merchantSlice'
import { clearRecentMerchant } from 'store/siteCoordinator/siteCoordinatorSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
  merchant: state.merchant,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  onCreateStep1DraftStart: (payload) =>
    dispatch(createStep1DraftStart(payload)),
  onCreateStep1GeneralStart: (payload) =>
    dispatch(createStep1GeneralStart(payload)),
  onClearRecentMerchant: () => dispatch(clearRecentMerchant()),
  onFetchMerchantBasicInfoPanelUserStart: (payload) =>
    dispatch(fetchMerchantGeneralBasicInfoForStateMerchantStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
