/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { sendClubReqStart } from 'store/club/clubSlice'
import {
  createStep1DraftStart,
  createStep1GeneralStart,
  fetchMerchantDraftBasicInfoPanelUserStart,
  editStep1Start,
  clearMerchantPremiumCode,
  fetchMerchantGeneralBasicInfoPanelUserStart,
  editMerchantInfoStart,
  getReferralTokenStart,
  clearReferralToken,
} from 'store/merchant/merchantSlice'
import { clearRecentMerchant } from 'store/siteCoordinator/siteCoordinatorSlice'
import { fetchOneBySlugStart } from 'store/pageSetup/pageSetupSlice'
import { openAlert } from 'store/alert/alertSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
  merchant: state.merchant,
  page: state.page,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  onCreateStep1DraftStart: (payload) =>
    dispatch(createStep1DraftStart(payload)),
  onCreateStep1GeneralStart: (payload) =>
    dispatch(createStep1GeneralStart(payload)),
  onFetchMerchantDraftBasicInfoPanelUserStart: (payload) =>
    dispatch(fetchMerchantDraftBasicInfoPanelUserStart(payload)),
  onClearRecentMerchant: () => dispatch(clearRecentMerchant()),
  onEditStep1Start: (payload) => dispatch(editStep1Start(payload)),
  onFetchOneBySlugStart: (payload) => dispatch(fetchOneBySlugStart(payload)),
  onClearMerchantPremiumCode: () => dispatch(clearMerchantPremiumCode()),
  onFetchMerchantBasicInfoPanelUserStart: (payload) =>
    dispatch(fetchMerchantGeneralBasicInfoPanelUserStart(payload)),
  onEditMerchantInfoStart: (payload) =>
    dispatch(editMerchantInfoStart(payload)),
  onGetReferralTokenStart: () => dispatch(getReferralTokenStart()),
  onClearReferralToken: () => dispatch(clearReferralToken()),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
