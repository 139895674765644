import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, Grid, CircularProgress } from '@mui/material'

import AppDialog from '@crema/core/AppDialog'
import container from './EditTerminal.container'
import { Form, Formik, Field, FieldArray } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'

import * as yup from 'yup'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onCloseEditDialog?: () => void
  refreshPageData?: any
  onCreateClubStart?: (params) => void
  onCreateMerchantTerminalStart?: (params) => void
  onFetchTerminalByIdStart?: (params) => void
  onEditTerminalStart?: (params) => void
  siteCoordinator?: any
  club?: any
  auth?: any
  merchant?: any
  selectedStateOwnerId?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  isAddCountryOpen,
  onOpenCountryTask,
  onCloseAddCountry,
  onCreateClubStart,
  onCreateMerchantTerminalStart,
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  merchant: { isCreating, terminalInfo, loading, isUpdating },
  refreshPageData,
  selectedStateOwnerId,
  onFetchTerminalByIdStart,
  onEditTerminalStart,
  onCloseEditDialog,
}) => {
  useEffect(() => {
    onFetchTerminalByIdStart(selectedStateOwnerId)
  }, [currentUser])

  const closeModal = () => {
    onCloseAddCountry()
  }
  const validationSchema = yup.object({})

  return (
    <>
      {loading ? (
        '...loading'
      ) : (
        <Box sx={{ minWidth: '400px' }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              terminalName: terminalInfo?.terminalName ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)

              onEditTerminalStart({
                params: {
                  ...data,
                  id: terminalInfo?.id,
                  countryId: globalCountry?.id,
                  merchantId: currentUser?.userId,
                },
                closeModal: onCloseEditDialog,
                refreshPageData,
              })

              setSubmitting(false)
            }}
            render={({ values, setFieldValue, handleBlur, handleChange }) => (
              <Form>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <AppTextField
                        placeholder={'Name'}
                        label={'Name'}
                        name="terminalName"
                        variant="outlined"
                        required
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                      />
                    </Grid>
                  </Grid>

                  <Box sx={{ height: 20 }} />
                  <Box sx={{ height: 30 }} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      sx={{
                        color: '#ffffff',
                        display: 'flex',
                        background: '#00bfcd',
                        '&:hover': {
                          background: '#ec4785',
                        },
                        px: 5,
                      }}
                      type="submit"
                      disabled={isUpdating}
                    >
                      {isUpdating ? <CircularProgress size={20} /> : 'Edit'}
                    </Button>
                    &nbsp; &nbsp;
                    <Button onClick={onCloseEditDialog} variant="outlined">
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          />
        </Box>
      )}
    </>
  )
}

export default container(AddNewClub)
