/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import {
  createGeneralSettingsStart,
  createGroupInformationListStart,
  editNotificationStart,
  fetchNotificationByIdStart,
  deleteNotificationStart,
  notificationCloseStart,
} from 'store/globalAdminSettings/globalAdminSettingsSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  globalSettings: state.globalSettings,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateGeneralSettingsStart: (payload) =>
    dispatch(createGeneralSettingsStart(payload)),
  onCreateGroupInformationListStart: (payload) =>
    dispatch(createGroupInformationListStart(payload)),
  onFetchNotificationByIdStart: (payload) =>
    dispatch(fetchNotificationByIdStart(payload)),
  onEditNotificationStart: (payload) =>
    dispatch(editNotificationStart(payload)),
  onDeleteNotificationStart: (payload) =>
    dispatch(deleteNotificationStart(payload)),
  onNotificationCloseStart: (payload) =>
    dispatch(notificationCloseStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
