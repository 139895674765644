import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchFriendshipFeeStart,
  fetchFriendshipFeeSuccess,
  fetchFriendshipFeeFailure,
  createFriendshipFeeSuccess,
  createFriendshipFeeFailure,
  deleteFriendshipFeeSuccess,
  deleteFriendshipFeeFailure,
  fetchFriendshipFeeByIdSuccess,
  fetchFriendshipFeeByIdFailure,
  editFriendshipFeeSuccess,
  editFriendshipFeeFailure,
  createCountryInternalBeneficiariesFailure,
  createCountryInternalBeneficiariesSuccess,
  fetchCountryInternalBeneficiariesSuccess,
  fetchCountryInternalBeneficiariesFailure,
  fetchCountryInternalBeneficiariesByIdSuccess,
  fetchCountryInternalBeneficiariesByIdFailure,
  editCountryInternalBeneficiariesSuccess,
  editCountryInternalBeneficiariesFailure,
  deleteCountryInternalBeneficiariesFailure,
  deleteCountryInternalBeneficiariesSuccess,
  fetchCountryInternalBeneficiariesStart,
} from './friendshipFeeSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchFriendshipFeeList,
  createFriendshipFee,
  deleteFriendshipFee,
  fetchFriendshipFeeById,
  editFriendshipFee,
  createCountryInternalBeneficiaries,
  fetchCountryInternalBeneficiariesList,
  fetchCountryInternalBeneficiariesById,
  editCountryInternalBeneficiaries,
  deleteCountryInternalBeneficiaries,
} from '../../api/GlobalAdmin/friendshipFee'

import _ from 'lodash'

export function* fetchFriendshipFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchFriendshipFeeList(payload))

    yield put(fetchFriendshipFeeSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchFriendshipFeeFailure(err))
  }
}

export function* fetchFriendshipFeeByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchFriendshipFeeById(payload))

    yield put(fetchFriendshipFeeByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchFriendshipFeeByIdFailure(err))
  }
}

export function* editFriendshipFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editFriendshipFee(payload.params.id, payload.params)
    )

    yield put(editFriendshipFeeSuccess(data))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Friendship beneficiaries successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editFriendshipFeeFailure(err))
  }
}

export function* createFriendshipFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createFriendshipFee(payload.params))
    yield put(createFriendshipFeeSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      fetchFriendshipFeeStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Friendship beneficiaries  successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createFriendshipFeeFailure(err))
  }
}

export function* deleteFriendshipFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteFriendshipFee(payload))

    if (data) {
      yield put(deleteFriendshipFeeSuccess(payload))
      yield put(
        fetchFriendshipFeeStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Friendship beneficiaries deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteFriendshipFeeFailure(err))
  }
}

export function* createCountryInternalBeneficiariesAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      createCountryInternalBeneficiaries(payload.params)
    )
    yield put(createCountryInternalBeneficiariesSuccess(data?.data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      fetchCountryInternalBeneficiariesStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Country Internal beneficiaries successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createCountryInternalBeneficiariesFailure(err))
  }
}

export function* fetcCountryInternalBeneficiariesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryInternalBeneficiariesList(payload))

    yield put(fetchCountryInternalBeneficiariesSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCountryInternalBeneficiariesFailure(err))
  }
}

export function* fetchCountryInternalBeneficiariesByIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCountryInternalBeneficiariesById(payload))

    yield put(fetchCountryInternalBeneficiariesByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCountryInternalBeneficiariesByIdFailure(err))
  }
}

export function* editCountryInternalBeneficiariesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editCountryInternalBeneficiaries(payload.params.id, payload.params)
    )

    yield put(editCountryInternalBeneficiariesSuccess(data))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    yield put(
      fetchCountryInternalBeneficiariesStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Country Internal beneficiaries successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editCountryInternalBeneficiariesFailure(err))
  }
}

export function* deleteCountryInternalBeneficiariesAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(deleteCountryInternalBeneficiaries(payload))

    if (data) {
      yield put(deleteCountryInternalBeneficiariesSuccess(payload))
      yield put(
        fetchCountryInternalBeneficiariesStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Country Internal beneficiaries deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteCountryInternalBeneficiariesFailure(err))
  }
}

export function* watchFetchFriendshipFee() {
  yield takeLatest(
    'friendshipFee/fetchFriendshipFeeStart',
    fetchFriendshipFeeAsync
  )
}

export function* watchCreateFriendshipFee() {
  yield takeLatest(
    'friendshipFee/createFriendshipFeeStart',
    createFriendshipFeeAsync
  )
}

export function* watchCreateCountryInternalBeneficiariesStart() {
  yield takeLatest(
    'friendshipFee/createCountryInternalBeneficiariesStart',
    createCountryInternalBeneficiariesAsync
  )
}

export function* watchFetchCountryInternalBeneficiaries() {
  yield takeLatest(
    'friendshipFee/fetchCountryInternalBeneficiariesStart',
    fetcCountryInternalBeneficiariesAsync
  )
}
export function* watchFetchCountryInternalBeneficiariesById() {
  yield takeLatest(
    'friendshipFee/fetchCountryInternalBeneficiariesByIdStart',
    fetchCountryInternalBeneficiariesByIdAsync
  )
}

export function* watchEditCountryInternalBeneficiaries() {
  yield takeLatest(
    'friendshipFee/editCountryInternalBeneficiariesStart',
    editCountryInternalBeneficiariesAsync
  )
}

export function* watchDeleteCountryInternalBeneficiaries() {
  yield takeLatest(
    'friendshipFee/deleteCountryInternalBeneficiariesStart',
    deleteCountryInternalBeneficiariesAsync
  )
}

export function* watchDeleteFriendshipFee() {
  yield takeLatest(
    'friendshipFee/deleteFriendshipFeeStart',
    deleteFriendshipFeeAsync
  )
}

export function* watchFetchFriendshipFeeById() {
  yield takeLatest(
    'friendshipFee/fetchFriendshipFeeByIdStart',
    fetchFriendshipFeeByIdAsync
  )
}

export function* watchEditFriendshipFee() {
  yield takeLatest(
    'friendshipFee/editFriendshipFeeStart',
    editFriendshipFeeAsync
  )
}

export function* friendshipFeeSagas() {
  yield all([
    call(watchFetchFriendshipFee),
    call(watchCreateFriendshipFee),
    call(watchDeleteFriendshipFee),
    call(watchFetchFriendshipFeeById),
    call(watchEditFriendshipFee),
    call(watchCreateCountryInternalBeneficiariesStart),
    call(watchFetchCountryInternalBeneficiaries),
    call(watchFetchCountryInternalBeneficiariesById),
    call(watchEditCountryInternalBeneficiaries),
    call(watchDeleteCountryInternalBeneficiaries),
  ])
}
