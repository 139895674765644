import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableHeading from './TableHeading'
import TableItem from './TableItem'
import {
  TablePagination,
  Box,
  TableRow,
  CircularProgress,
  TableCell,
} from '@mui/material'
import AppTableContainer from '@crema/core/AppTableContainer'
import TableDataNotFound from 'components/TableDataNotFound'

// import { RecentOrders } from "types/models/ecommerce/EcommerceApp";
import { CountrySetup } from 'types/models/CountrySetup'

interface countySetupTableProps {
  countryData: CountrySetup[]
  page: any
  setPage: any
  rowsPerPage: any
  setRowsPerPage: any
  handleChangePage: any
  handleChangeRowsPerPage: any
  hasMoreData?: boolean
  loading?: boolean
  totalCount?: number
  pageNumber?: number
  selectedDataToEdit?: any
  setSelectedDataToEdit?: any
  onDeleteClubStart?: (payload: any) => void
}

const CountryTable: React.FC<countySetupTableProps> = ({
  countryData,
  page,
  totalCount,
  rowsPerPage,
  hasMoreData,
  handleChangePage,
  handleChangeRowsPerPage,
  pageNumber,
  onDeleteClubStart,
  setSelectedDataToEdit,
  selectedDataToEdit,
}) => {
  const [tempLoader, setTempLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  return (
    <AppTableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          nextIconButtonProps={{ disabled: !hasMoreData }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* <Button
          sx={{
            color: '#ffffff',
            display: 'flex',
            marginLeft: 'auto',
            background: '#00bfcd',
            '&:hover': {
              background: '#ec4785',
            },
            px: 5,
            mb: 5,
          }}
        >
          Download CSV
        </Button> */}
      </Box>
      <Table stickyHeader>
        <TableHead>
          <TableHeading />
        </TableHead>
        <TableBody>
          {tempLoader ? (
            <TableRow className="item-hover">
              <TableCell align="center" colSpan={12}>
                <CircularProgress size={20} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {countryData?.length ? (
                countryData.map((data, index) => (
                  <TableItem
                    data={data}
                    key={data.sn}
                    count={index + 1}
                    onDeleteClubStart={onDeleteClubStart}
                    pageNumber={pageNumber}
                    rowsPerPage={rowsPerPage}
                    setSelectedDataToEdit={setSelectedDataToEdit}
                    selectedDataToEdit={selectedDataToEdit}
                  />
                ))
              ) : (
                <TableDataNotFound colSpan={12} />
              )}
            </>
          )}
        </TableBody>
      </Table>
    </AppTableContainer>
  )
}

export default CountryTable
