import { createSlice } from '@reduxjs/toolkit'

import { ICpGlobalFriendshipBeneficiaryState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: ICpGlobalFriendshipBeneficiaryState = {
  cpGlobalFriendshipBeneficiaryList: listObj,
  soleCpGlobalFriendshipBeneficiary: null,
  error: null,
  loading: false,
  hasMoreData: false,
}

export const cpGlobalFriendshipBeneficiarySlice = createSlice({
  name: 'cpGlobalFriendshipBeneficiary',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCpGlobalFriendshipBeneficiaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCpGlobalFriendshipBeneficiarySuccess: (state: any, action) => {
      state.cpGlobalFriendshipBeneficiaryList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCpGlobalFriendshipBeneficiaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCpGlobalFriendshipBeneficiaryByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchCpGlobalFriendshipBeneficiaryByIdSuccess: (state: any, action) => {
      state.soleCpGlobalFriendshipBeneficiary = action.payload.data
      state.loading = false
    },
    fetchCpGlobalFriendshipBeneficiaryByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createCpGlobalFriendshipBeneficiaryStart: (state: any, action) => {
      state.loading = true
    },
    createCpGlobalFriendshipBeneficiarySuccess: (state: any, action) => {
      state.loading = false
      state.cpGlobalFriendshipBeneficiaryList.data = [
        ...state.cpGlobalFriendshipBeneficiaryList.data,
        action.payload,
      ]
    },
    createCpGlobalFriendshipBeneficiaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteCpGlobalFriendshipBeneficiaryStart: (state: any, action) => {
      state.loading = true
    },
    deleteCpGlobalFriendshipBeneficiarySuccess: (state: any, action) => {
      state.loading = false
      state.cpGlobalFriendshipBeneficiaryList.data =
        state.cpGlobalFriendshipBeneficiaryList?.data.filter(
          (item) => item.id !== action.payload
        )
    },
    deleteCpGlobalFriendshipBeneficiaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editCpGlobalFriendshipBeneficiaryStart: (state: any, action) => {
      state.loading = true
    },
    editCpGlobalFriendshipBeneficiarySuccess: (state: any, action) => {
      const result = state.cpGlobalFriendshipBeneficiaryList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.cpGlobalFriendshipBeneficiaryList.data = result

      state.loading = false
    },
    editCpGlobalFriendshipBeneficiaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchCpGlobalFriendshipBeneficiaryStart,
  fetchCpGlobalFriendshipBeneficiarySuccess,
  fetchCpGlobalFriendshipBeneficiaryFailure,
  createCpGlobalFriendshipBeneficiaryStart,
  createCpGlobalFriendshipBeneficiarySuccess,
  createCpGlobalFriendshipBeneficiaryFailure,
  deleteCpGlobalFriendshipBeneficiaryStart,
  deleteCpGlobalFriendshipBeneficiarySuccess,
  deleteCpGlobalFriendshipBeneficiaryFailure,
  fetchCpGlobalFriendshipBeneficiaryByIdStart,
  fetchCpGlobalFriendshipBeneficiaryByIdSuccess,
  fetchCpGlobalFriendshipBeneficiaryByIdFailure,
  editCpGlobalFriendshipBeneficiaryStart,
  editCpGlobalFriendshipBeneficiarySuccess,
  editCpGlobalFriendshipBeneficiaryFailure,
} = cpGlobalFriendshipBeneficiarySlice.actions

export default cpGlobalFriendshipBeneficiarySlice.reducer
