import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'

import container from './AssignedTransactionCodeModal.container'
import MerchantQrCode from 'components/MerchantQrCodeDownload'

interface EditProps {
  isAddCountryOpen: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry: () => void
  onCreateStateOwnerStart?: (params) => void
  onFetchClubByIdStart: (params) => void
  onEditClubStart: (params) => void
  onSetMerchantPopularStart: (params) => void
  onAssignIssuerTransactionCodeStart: (params) => void
  onCloseEditDialog?: () => void
  onDeny?: () => void
  closeMarkAsPopularModal?: () => void
  refreshPageData?: () => void
  selectedStateOwnerId: number
  club: any
  selectedMerchantObj?: any
  qrCodes?: any
}

const AssignCodeModal: React.FC<EditProps & any> = ({
  qrCodes: {
    isFetching,
    transactionQrCodesListForMerchant: { data },
  },
  selectedMerchantObj,
  onFetchTransactionQrCodesListByMerchantIdStart,
}) => {
  useEffect(() => {
    onFetchTransactionQrCodesListByMerchantIdStart(selectedMerchantObj?.id)
  }, [selectedMerchantObj])

  return (
    <>
      {isFetching ? (
        '...isLoading'
      ) : (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Grid item container spacing={3}>
              {data?.length ? (
                data?.map((item) => (
                  <>
                    <Box sx={{ textAlign: 'center' }} ml={4}>
                      <MerchantQrCode
                        value={item?.transactionCode}
                        size={150}
                        qrType={'Transaction'}
                        selectedMerchantObj={selectedMerchantObj}
                      />
                    </Box>
                  </>
                ))
              ) : (
                <Box sx={{ m: 3 }}>
                  <Typography variant="h5" sx={{ ml: 3 }}>
                    Transaction Codes Not Found
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default container(AssignCodeModal)
