import { createSlice } from '@reduxjs/toolkit'

import { ICpGlobalMembershipBeneficiaryState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: ICpGlobalMembershipBeneficiaryState = {
  cpGlobalMembershipBeneficiaryList: listObj,
  soleCpGlobalMembershipBeneficiary: null,
  error: null,
  loading: false,
  hasMoreData: false,
}

export const cpGlobalMembershipBeneficiarySlice = createSlice({
  name: 'cpGlobalMembershipBeneficiary',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCpGlobalMembershipBeneficiaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCpGlobalMembershipBeneficiarySuccess: (state: any, action) => {
      state.cpGlobalMembershipBeneficiaryList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCpGlobalMembershipBeneficiaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCpGlobalMembershipBeneficiaryByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchCpGlobalMembershipBeneficiaryByIdSuccess: (state: any, action) => {
      state.soleCpGlobalMembershipBeneficiary = action.payload.data
      state.loading = false
      state.error = null
    },
    fetchCpGlobalMembershipBeneficiaryByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createCpGlobalMembershipBeneficiaryStart: (state: any, action) => {
      state.loading = true
    },
    createCpGlobalMembershipBeneficiarySuccess: (state: any, action) => {
      state.loading = false
      state.cpGlobalMembershipBeneficiaryList.data = [
        ...state.cpGlobalMembershipBeneficiaryList.data,
        action.payload,
      ]
    },
    createCpGlobalMembershipBeneficiaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteCpGlobalMembershipBeneficiaryStart: (state: any, action) => {
      state.loading = true
    },
    deleteCpGlobalMembershipBeneficiarySuccess: (state: any, action) => {
      state.loading = false
      state.cpGlobalMembershipBeneficiaryList.data =
        state.cpGlobalMembershipBeneficiaryList?.data.filter(
          (item) => item.id !== action.payload
        )
    },
    deleteCpGlobalMembershipBeneficiaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editCpGlobalMembershipBeneficiaryStart: (state: any, action) => {
      state.loading = true
    },
    editCpGlobalMembershipBeneficiarySuccess: (state: any, action) => {
      const result = state.cpGlobalMembershipBeneficiaryList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.cpGlobalMembershipBeneficiaryList.data = result

      state.loading = false
    },
    editCpGlobalMembershipBeneficiaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchCpGlobalMembershipBeneficiaryStart,
  fetchCpGlobalMembershipBeneficiarySuccess,
  fetchCpGlobalMembershipBeneficiaryFailure,
  createCpGlobalMembershipBeneficiaryStart,
  createCpGlobalMembershipBeneficiarySuccess,
  createCpGlobalMembershipBeneficiaryFailure,
  deleteCpGlobalMembershipBeneficiaryStart,
  deleteCpGlobalMembershipBeneficiarySuccess,
  deleteCpGlobalMembershipBeneficiaryFailure,
  fetchCpGlobalMembershipBeneficiaryByIdStart,
  fetchCpGlobalMembershipBeneficiaryByIdSuccess,
  fetchCpGlobalMembershipBeneficiaryByIdFailure,
  editCpGlobalMembershipBeneficiaryStart,
  editCpGlobalMembershipBeneficiarySuccess,
  editCpGlobalMembershipBeneficiaryFailure,
} = cpGlobalMembershipBeneficiarySlice.actions

export default cpGlobalMembershipBeneficiarySlice.reducer
