import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material'

import container from './DiscountOffered.container'

import _ from 'lodash'

import * as yup from 'yup'

const AddNewClub: React.FC<any> = ({
  onCloseAddCountry,
  merchantId,
  merchant: { isFetching, generalDiscountInfo },
  onFetchMerchantGeneralDiscountInfoPanelUserStart,
}) => {
  const [tempLoader, setTempLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setTempLoader(false)
    }, 1000)
  }, [])

  useEffect(() => {
    if (!_.isEmpty(generalDiscountInfo)) {
      setDateWiseTime(generalDiscountInfo?.discountRates)
    }
  }, [generalDiscountInfo])

  useEffect(() => {
    onFetchMerchantGeneralDiscountInfoPanelUserStart(merchantId)
  }, [merchantId])

  const [dateWiseTime, setDateWiseTime] = useState<any | null>({
    Monday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Tuesday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Wednesday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Thursday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Friday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Saturday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    Sunday: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
  })

  const closeModal = () => {
    onCloseAddCountry()
  }
  const validationSchema = yup.object({
    stateId: yup.object().nullable().required('state required'),
    regionId: yup.object().nullable().required('region required'),
  })

  const changedTimeByDay = (values) => {
    if (
      !_.isEmpty(values?.day) &&
      values?.discountForAllHours >= 0 &&
      values?.discountForAllHours !== ''
    ) {
      //changed all the time on the object list
      const duplicateObject = { ...dateWiseTime }
      const modifiedWeekTime = Object.keys(
        duplicateObject[values?.day?.label]
      ).map(
        (name: any) =>
          (duplicateObject[name?.label] = values?.discountForAllHours)
      )

      const convertToObject = Object.assign({}, modifiedWeekTime)

      const lastObject = { [values?.day?.label]: convertToObject }

      const newObj = Object.entries({
        ...duplicateObject,
        ...lastObject,
      }).reduce((acc, [key, val]) => {
        if (key !== 'undefined') {
          return { ...acc, [key]: val }
        } else return acc
      }, {})

      setDateWiseTime(newObj)
    }
  }

  const changedTimeByHour = (day, hour, event) => {
    const dayChanged = {
      [day]: {
        ...dateWiseTime[day],
        [hour]: event.target.value ? parseFloat(event.target.value) : 0,
      },
    }

    setDateWiseTime({ ...dateWiseTime, ...dayChanged })
  }

  const objectIterate = (): any => {
    return Object.keys(dateWiseTime).map(function (key) {
      let day = key
      return (
        <>
          <Grid container sx={{ mt: 3 }}>
            <Grid item container>
              <Typography variant="h4" key={key}>
                {key}
              </Typography>
            </Grid>
            <Grid item container>
              {Object.entries(dateWiseTime[key]).map((key: any, value: any) => {
                return (
                  <Typography variant="h5" key={key} sx={{ mr: 4, mt: 3 }}>
                    <span style={{ marginLeft: '10px' }}>{`${key[0]}:00`}</span>
                    <br />
                    <TextField
                      id="outlined-basic"
                      label=""
                      type="number"
                      variant="outlined"
                      disabled
                      size="small"
                      onChange={(e) => changedTimeByHour(day, key[0], e)}
                      defaultValue={key[1]}
                    />
                  </Typography>
                )
              })}
            </Grid>
          </Grid>
        </>
      )
    })
  }

  return (
    <>
      {tempLoader || isFetching ? (
        <CircularProgress size={16} />
      ) : (
        <Box sx={{ p: 2 }}>
          <Grid container>{objectIterate()}</Grid>

          <Box sx={{ height: 20 }} />
          <Box sx={{ height: 30 }} />
        </Box>
      )}
    </>
  )
}

export default container(AddNewClub)
