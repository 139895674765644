import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  Autocomplete,
} from '@mui/material'
import { FieldProps, getIn } from 'formik'

import AppDialog from '@crema/core/AppDialog'

interface AddNewCountryProps {
  dropDown: any
  setSelectedCountry: any
  selectedArea?: any
  defaultValue?: number
  referredRoles?: any[]
}

const OrganizerTypeSelect: React.FC<FieldProps & AddNewCountryProps> = ({
  field,
  form,
  setSelectedCountry,
  defaultValue,
  selectedArea,
  referredRoles,
  ...props
}) => {
  const alternateOption: any = [
    { label: 'Pending', name: 'pending' },
    { label: 'Rejected', name: 'rejected' },
  ]

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <Autocomplete
      {...props}
      {...field}
      disablePortal
      id="combo-box-demo"
      size="small"
      value={field.value}
      getOptionLabel={(option) => (option ? option.label : '')}
      onChange={(e, value) => {
        form.setFieldValue(field.name, value)
        //
      }}
      // onInputChange={(event, newInputValue) => {
      //   console.log('onInputChange>>>', newInputValue)
      // }}
      options={referredRoles?.length ? referredRoles : alternateOption}
      defaultValue={defaultValue ? defaultValue : null}
      // isOptionEqualToValue={defaultCountry ? defaultCountry : null}
      // value={selectedArea ? selectedArea : null}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={errorText?.value || errorText}
          error={!!errorText}
          label="Select Organizer Type"
        />
      )}
    />
  )
}

export default OrganizerTypeSelect
