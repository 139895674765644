import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchEmailTemplatesStart,
  fetchEmailTemplatesSuccess,
  fetchEmailTemplatesFailure,
  createEmailTemplatesStart,
  createEmailTemplatesSuccess,
  createEmailTemplatesFailure,
  deleteEmailTemplatesSuccess,
  deleteEmailTemplatesFailure,
  fetchEmailTemplatesByIdSuccess,
  fetchEmailTemplatesByIdFailure,
  editEmailTemplatesSuccess,
  editEmailTemplatesFailure,
} from './emailTemplatesSetupSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchEmailTemplatesList,
  createEmailTemplates,
  deleteEmailTemplates,
  fetchEmailTemplatesById,
  editEmailTemplates,
} from '../../api/emailTemplates'

import _ from 'lodash'

export function* fetchEmailTemplatesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchEmailTemplatesList(payload))

    yield put(fetchEmailTemplatesSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchEmailTemplatesFailure(err))
  }
}

export function* fetchEmailTemplatesByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchEmailTemplatesById(payload))

    yield put(fetchEmailTemplatesByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchEmailTemplatesByIdFailure(err))
  }
}

export function* editEmailTemplatesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editEmailTemplates(payload.params.id, payload.params)
    )

    yield put(editEmailTemplatesSuccess(data.data))
    yield put(openAlert({ message: 'succesfully edited', severity: 'success' }))
    payload.closeModal()
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editEmailTemplatesFailure(err))
  }
}

export function* createEmailTemplatesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createEmailTemplates({ ...payload.params }))

    yield put(createEmailTemplatesSuccess(data?.data))
    yield put(
      fetchEmailTemplatesStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Email Template succesfully created',
        severity: 'success',
      })
    )

    yield payload.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createEmailTemplatesFailure(err))
  }
}

export function* deleteEmailTemplatesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteEmailTemplates(payload))

    if (data) {
      yield put(deleteEmailTemplatesSuccess(payload))
      yield put(
        fetchEmailTemplatesStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'EmailTemplates deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteEmailTemplatesFailure(err))
  }
}

export function* watchFetchEmailTemplates() {
  yield takeLatest(
    'emailTemplatesSetup/fetchEmailTemplatesStart',
    fetchEmailTemplatesAsync
  )
}

export function* watchCreateEmailTemplates() {
  yield takeLatest(
    'emailTemplatesSetup/createEmailTemplatesStart',
    createEmailTemplatesAsync
  )
}

export function* watchDeleteEmailTemplates() {
  yield takeLatest(
    'emailTemplatesSetup/deleteEmailTemplatesStart',
    deleteEmailTemplatesAsync
  )
}

export function* watchFetchEmailTemplatesById() {
  yield takeLatest(
    'emailTemplatesSetup/fetchEmailTemplatesByIdStart',
    fetchEmailTemplatesByIdAsync
  )
}

export function* watchEditEmailTemplates() {
  yield takeLatest(
    'emailTemplatesSetup/editEmailTemplatesStart',
    editEmailTemplatesAsync
  )
}

export function* emailTemplatesSetupSagas() {
  yield all([
    call(watchFetchEmailTemplates),
    call(watchCreateEmailTemplates),
    call(watchDeleteEmailTemplates),
    call(watchFetchEmailTemplatesById),
    call(watchEditEmailTemplates),
  ])
}
