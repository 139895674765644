import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'

import container from './AssignedIssuerCodeModal.container'

import MerchantQrCode from 'components/MerchantQrCodeDownload'

interface EditProps {
  isAddCountryOpen: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry: () => void
  onCreateStateOwnerStart?: (params) => void
  onFetchClubByIdStart: (params) => void
  onEditClubStart: (params) => void
  onSetMerchantPopularStart: (params) => void
  onAssignIssuerTransactionCodeStart: (params) => void
  onFetchIssuerQrCodesListByMerchantIdStart?: (params) => void
  onFetchStateIssuerQrCodesListByMerchantIdStart?: (params) => void
  onFetchRegionIssuerQrCodesListByMerchantIdStart?: (params) => void
  onFetchAreaIssuerQrCodesListByMerchantIdStart?: (params) => void
  onFetchCountryIssuerQrCodesListByMerchantIdStart?: (params) => void
  onCloseEditDialog?: () => void
  onDeny?: () => void
  closeMarkAsPopularModal?: () => void
  refreshPageData?: () => void
  selectedStateOwnerId: number
  club: any
  selectedMerchantObj?: any
  qrCodes?: any
  isRoleWiseFilter?: boolean
  role?: string
}

const AssignCodeModal: React.FC<EditProps & any> = ({
  qrCodes: {
    isFetching,
    issuerQrCodesListForMerchant: { data },
  },
  selectedMerchantObj,
  onFetchIssuerQrCodesListByMerchantIdStart,
  onFetchStateIssuerQrCodesListByMerchantIdStart,
  onFetchRegionIssuerQrCodesListByMerchantIdStart,
  onFetchAreaIssuerQrCodesListByMerchantIdStart,
  onFetchCountryIssuerQrCodesListByMerchantIdStart,
  isRoleWiseFilter,
  role,
}) => {
  useEffect(() => {
    if (isRoleWiseFilter) {
      if (role === 'stateowner') {
        onFetchStateIssuerQrCodesListByMerchantIdStart(selectedMerchantObj?.id)
      } else if (role === 'regionowner') {
        onFetchRegionIssuerQrCodesListByMerchantIdStart(selectedMerchantObj?.id)
      } else if (role === 'areaowner') {
        onFetchAreaIssuerQrCodesListByMerchantIdStart(selectedMerchantObj?.id)
      } else if (role === 'countryowner') {
        onFetchCountryIssuerQrCodesListByMerchantIdStart(
          selectedMerchantObj?.id
        )
      }
    } else {
      onFetchIssuerQrCodesListByMerchantIdStart(selectedMerchantObj?.id)
    }
  }, [selectedMerchantObj, isRoleWiseFilter])

  return (
    <>
      {isFetching ? (
        '...isLoading'
      ) : (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Grid item container spacing={3}>
              {data?.length ? (
                data?.map((item) => (
                  <>
                    <Box sx={{ textAlign: 'center' }} ml={4} mt={3}>
                      <MerchantQrCode
                        value={
                          item?.issuerCodeQRLink
                            ? item?.issuerCodeQRLink
                            : item?.issuerCode
                        }
                        qrCodeToDisplay={item?.issuerCode}
                        size={150}
                        qrType={'Issuer'}
                        selectedMerchantObj={selectedMerchantObj}
                      />
                    </Box>
                  </>
                ))
              ) : (
                <Box sx={{ m: 3 }}>
                  <Typography variant="h5" sx={{ ml: 3 }}>
                    Issuer Codes Not Found
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default container(AssignCodeModal)
