import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE: any = {
  globalCountry: {},
  recentMerchant: {},
  selectedDateInterval: 0,
  error: null,
  loading: false,
  hasMoreData: false,
}

export const siteCoordinatorSlice = createSlice({
  name: 'siteCoordinator',
  initialState: INITIAL_STATE,
  reducers: {
    setGlobalCountry: (state: any, action) => {
      state.globalCountry = action.payload
    },
    setRecentMerchant: (state: any, action) => {
      state.recentMerchant = action.payload
    },

    setDateIntervalFilter: (state: any, action) => {
      state.selectedDateInterval = action.payload
    },

    clearDateIntervalFilter: (state: any) => {
      state.selectedDateInterval = 100
    },

    clearRecentMerchant: (state: any) => {
      state.recentMerchant = {}
    },
    clearSiteCoordinator: (state: any) => {
      state.globalCountry = {}
      state.recentMerchant = {}
    },
  },
})

export const {
  setGlobalCountry,
  setRecentMerchant,
  clearRecentMerchant,
  clearSiteCoordinator,
  setDateIntervalFilter,
  clearDateIntervalFilter,
} = siteCoordinatorSlice.actions

export default siteCoordinatorSlice.reducer
