import React, { useState } from 'react'
import {
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material'
import Previews from '../Previews'
import AppDialog from '@crema/core/AppDialog'
import AppComponentCard from '@crema/core/AppComponentCard'
import container from './AddSection.container'
import { Form, Formik, Field, FieldArray } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import CountrySelect from 'components/DropdownComponents/CountrySelect'
import * as yup from 'yup'
import StatusSelect from 'components/DropdownComponents/StatusSelect'
import RichTextEditor from 'components/RichTextEditor'
import LanguageSelect from 'components/DropdownComponents/LanguageSelect'
import ListDetails from '../ListDetails'

interface AddSectionProps {
  isAddSectionOpen: boolean
  onCloseAddSection: () => void
  onCreateSectionStart?: (params) => void
  onOpenAlert: (params) => void
  websiteAdmin?: any
}

const AddSection: React.FC<AddSectionProps> = ({
  isAddSectionOpen,
  onCloseAddSection,
  onCreateSectionStart,
  onOpenAlert,
  websiteAdmin: { isCreating },
}) => {
  const [pageMarkupContent, setPageMarkupContent] = useState('')
  const [shortDescriptionContent, setShortDescriptionContent] = useState('')
  const [imagesExistInList, setImagesExistInList] = useState(true)

  const [uploadedFiles, setUploadedFiles] = useState<any>([])

  const validationSchema = yup.object({})

  const closeModal = () => {
    onCloseAddSection()
  }

  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isAddSectionOpen}
      onClose={() => onCloseAddSection()}
      title="Add About Us"
    >
      <Formik
        validateOnChange={true}
        initialValues={{
          title: '',
          shortDescription: '',
          briefDescription: '',
          imageDescription: '',

          langlist: [],
          list: [
            {
              order: 0,
              aboutlistTitle: null,
              aboutlistDescription: null,
              aboutlisImageUrl: [],
            },
          ],
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true)

          if (!uploadedFiles?.length) {
            return onOpenAlert({
              message: 'Please upload Section image',
              severity: 'error',
            })
          }

          if (!pageMarkupContent?.length) {
            return onOpenAlert({
              message: 'Long Description is required',
              severity: 'error',
            })
          }

          if (!shortDescriptionContent?.length) {
            return onOpenAlert({
              message: 'Short Description is required',
              severity: 'error',
            })
          }

          if (data?.list?.length) {
            data?.list?.map((item) => {
              if (!item?.aboutlisImageUrl?.length) {
                return onOpenAlert({
                  message: `Please upload image of ${item?.aboutlistTitle}`,
                  severity: 'error',
                })
              }
            })
          }

          if (data?.list?.length) {
            data?.list?.map((item) => {
              if (!item?.aboutlisImageUrl?.length) {
                setImagesExistInList(false)
                return onOpenAlert({
                  message: `Please upload image of ${item?.aboutlistTitle}`,
                  severity: 'error',
                })
              }
            })
          }

          if (imagesExistInList == false) {
            return
          }

          onCreateSectionStart({
            params: {
              lang: 'en',
              ...data,
              imgUrl: uploadedFiles.length ? uploadedFiles[0] : {},
              briefDescription: pageMarkupContent?.length
                ? pageMarkupContent
                : null,
              shortDescription: shortDescriptionContent?.length
                ? shortDescriptionContent
                : null,

              langlist: [],
            },
            oldImages: data?.list?.length
              ? data?.list?.map((item) => item?.aboutlisImageUrl)
              : [],
            closeModal,
          })
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Box sx={{ padding: 6 }}>
              <Grid container spacing={5}>
                <Grid item xs={12} lg={12}>
                  <AppTextField
                    label="Title"
                    name="title"
                    required
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                    size="small"
                  />
                </Grid>

                {/* <Grid item xs={12} lg={12}>
                  <AppTextField
                    label="Short Description"
                    name="shortDescription"
                    multiline={true}
                    rows={3}
                    required
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                    size="small"
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ padding: 2 }}>
                    Short Description *
                  </Typography>
                  <RichTextEditor
                    required
                    // markup={values.briefDescription}
                    // setMarkup={(val) => setFieldValue('briefDescription', val)}

                    markup={shortDescriptionContent}
                    setMarkup={setShortDescriptionContent}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ padding: 2 }}>
                    Brief Description *
                  </Typography>
                  <RichTextEditor
                    required
                    // markup={values.briefDescription}
                    // setMarkup={(val) => setFieldValue('briefDescription', val)}

                    markup={pageMarkupContent}
                    setMarkup={setPageMarkupContent}
                  />
                </Grid>

                <Grid item xs={12} sx={{}}>
                  <Typography variant="h5" component="h2" sx={{ mt: 2, mb: 5 }}>
                    Upload Section Image *
                  </Typography>
                  <Previews
                    setUploadedFiles={setUploadedFiles} /* eslint-disable */
                    uploadedFiles={uploadedFiles}
                    defaultImage={[{ preview: '', type: 'image/png' }]}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <AppTextField
                    label="Image Description"
                    name="imageDescription"
                    multiline={true}
                    rows={3}
                    required
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <FieldArray
                    name="list"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.list?.map((item, index) => (
                          <>
                            <ListDetails
                              arrayHelpers={arrayHelpers}
                              key={index}
                              values={values}
                              index={index}
                              item={item}
                              setFieldValue={setFieldValue}
                              isAddForm={true}
                            />
                          </>
                        ))}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>

              {/* <Grid container spacing={4} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                  <Field
                    name="langlist"
                    component={LanguageSelect}
                  />
                </Grid>

              </Grid> */}

              <Box
                sx={{ display: 'flex', justifyContent: 'flex-end', mt: '2rem' }}
              >
                <Button
                  sx={{
                    color: '#ffffff',
                    display: 'flex',
                    background: '#00bfcd',
                    '&:hover': {
                      background: '#ec4785',
                    },
                    px: 5,
                  }}
                  type="submit"
                  disabled={isCreating}
                >
                  {isCreating ? <CircularProgress size={20} /> : 'Save'}
                </Button>
                &nbsp; &nbsp;
                <Button onClick={() => onCloseAddSection()} variant="outlined">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </AppDialog>
  )
}

export default container(AddSection)
