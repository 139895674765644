import { axiosRequest } from 'utils'

export const fetchMerchantAgreementTemplateList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/agreement/merchant/version/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantAgreementTemplateById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/agreement/merchant/version/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMerchantAgreementTemplate = (params) => {
  return () =>
    axiosRequest
      .post(`/api/agreement/merchant/createBulk`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteMerchantAgreementTemplate = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/merchantAgreementTemplate/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMerchantAgreementTemplate = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/agreement/merchant/version/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
