// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchClubList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/club/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchClubListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/club/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createClub = (params) => {
  return () =>
    axiosRequest
      .post(`/api/club/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchClubById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/club/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectClub = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/club/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editClub = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/club/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editOwnClubProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/club/editOwnProfile`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyClub = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/club/updateAndVerifyEOI/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveUpdatePendingClub = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/club/saveUpdate/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteClub = (id, params) => {
  return () =>
    axiosRequest
      .delete(`/api/club/delete/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const sendClubReq = (params) => {
  return () =>
    axiosRequest
      .post(`/api/club/suggest/`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchClubProfileInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/club/getOwnProfile`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllClub = (params) => {
  return () =>
    axiosRequest
      .get(`/api/club/getAllClub`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllClubByLocation = (params) => {
  return () =>
    axiosRequest
      .get(`/api/club/getAllByLocation`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getClubReferralToken = () => {
  return () =>
    axiosRequest
      .get(`/api/club/referralToken`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
