import React, { useEffect, useState } from 'react'
import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Tabs, Tab, Typography } from '@mui/material'
import AppComponentHeader from '@crema/core/AppComponentHeader'
import AppCard from '@crema/core/AppCard'
import BasicInfo from './components/BasicInfo'
import WebsiteDetails from './components/WebsiteDetails'
import Images from './components/Images'
import DiscountOffered from './components/DiscountOffered'
import PackagesAndBankInfo from './components/PackagesAndBankInfo'

import container from './SendMerchantRequest.container'
import { useLocation, useParams } from 'react-router-dom'
import _ from 'lodash'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function handleClick(event) {
  event.preventDefault()
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  if (index == 0) {
    return
  }
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CustomizedBreadcrumbs = () => {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          label="Merchants"
          icon={<HomeIcon fontSize="small" />}
        />
        <StyledBreadcrumb label="Add Merchant" />
      </Breadcrumbs>
    </div>
  )
}

const SendMerchantRequest = ({
  onFetchMemberStart,
  onCreateStep1DraftStart,
  countrySetup: { countryList },
  stateOwners: { hasMoreData },
  members: {
    memberList: { data, hasMore, totalCount, count, page: pageNumber },
    loading,
  },
  merchant: { isCreatingDraft, isCreating, isCreatingGeneral, draftBasicInfo },
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  onFetchMerchantDraftBasicInfoPanelUserStart,
  onClearDraftData,
  adminRoute,
  onFetchCountryStripeKeyStart,
  onClearCountryStripeKey,
  ...rest
}) => {
  const { id } = useParams()
  const location: any = useLocation()

  const [value, setValue] = React.useState(0)

  useEffect(() => {
    if (globalCountry) {
      onClearCountryStripeKey()
      onFetchCountryStripeKeyStart(globalCountry?.id)
    }
  }, [globalCountry])

  useEffect(() => {
    if (id) {
      onFetchMerchantDraftBasicInfoPanelUserStart(id)
    } else {
      onClearDraftData()
    }
  }, [id])

  useEffect(() => {
    if (!_.isEmpty(location?.state)) {
      setValue(location?.state?.step)
    }
  }, [location])

  useEffect(() => {
    if (id && !_.isEmpty(draftBasicInfo)) {
      setValue(draftBasicInfo?.registrationCompletedStep)
    }
  }, [id, draftBasicInfo])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box style={{ color: '#000000' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <AppComponentHeader title="Add Merchant" />
        <CustomizedBreadcrumbs />
      </Box>

      <AppCard>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Basics" {...a11yProps(0)} />
              <Tab
                label="Specifics"
                {...a11yProps(1)}
                disabled={
                  (id && 1 <= draftBasicInfo?.registrationCompletedStep - 1) ||
                  (id && 1 === draftBasicInfo?.registrationCompletedStep)
                    ? false
                    : true
                }
              />
              <Tab
                label="Images"
                {...a11yProps(2)}
                disabled={
                  (id && 2 <= draftBasicInfo?.registrationCompletedStep - 1) ||
                  (id && 2 === draftBasicInfo?.registrationCompletedStep)
                    ? false
                    : true
                }
              />
              <Tab
                label="Discounts"
                {...a11yProps(3)}
                disabled={
                  (id && 3 <= draftBasicInfo?.registrationCompletedStep - 1) ||
                  (id && 3 === draftBasicInfo?.registrationCompletedStep)
                    ? false
                    : true
                }
              />
              <Tab
                label="Packages & Bank Info"
                {...a11yProps(4)}
                disabled={
                  (id && 4 <= draftBasicInfo?.registrationCompletedStep - 1) ||
                  (id && 4 === draftBasicInfo?.registrationCompletedStep)
                    ? false
                    : true
                }
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BasicInfo handleChangeTab={handleChange} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <WebsiteDetails handleChangeTab={handleChange} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Images handleChangeTab={handleChange} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DiscountOffered handleChangeTab={handleChange} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <PackagesAndBankInfo handleChangeTab={handleChange} />
          </TabPanel>
        </Box>
        <Box style={{ height: 30 }} />
      </AppCard>
    </Box>
  )
}

export default container(SendMerchantRequest)
