import { createSlice } from '@reduxjs/toolkit'

import { IMerchantAgreementTemplateSetupState } from '../interfaces'

import { listObj } from 'utils'

const INITIAL_STATE: IMerchantAgreementTemplateSetupState = {
  merchantAgreementTemplateList: listObj,
  soleMerchantAgreementTemplate: null,
  error: null,
  loading: false,
  hasMoreData: false,
}

export const merchantAgreementTemplateSetupSlice = createSlice({
  name: 'merchantAgreementTemplateSetup',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMerchantAgreementTemplateStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantAgreementTemplateSuccess: (state: any, action) => {
      state.merchantAgreementTemplateList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchMerchantAgreementTemplateFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMerchantAgreementTemplateByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantAgreementTemplateByIdSuccess: (state: any, action) => {
      state.soleMerchantAgreementTemplate = action.payload.data
      state.loading = false
    },
    fetchMerchantAgreementTemplateByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    createMerchantAgreementTemplateStart: (state: any, action) => {
      state.loading = true
    },
    createMerchantAgreementTemplateSuccess: (state: any, action) => {
      state.loading = false
      state.merchantAgreementTemplateList.data = [
        ...state.merchantAgreementTemplateList.data,
        action.payload,
      ]
    },
    createMerchantAgreementTemplateFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteMerchantAgreementTemplateStart: (state: any, action) => {
      state.loading = true
    },
    deleteMerchantAgreementTemplateSuccess: (state: any, action) => {
      state.loading = false
      state.merchantAgreementTemplateList.data =
        state.merchantAgreementTemplateList?.data.filter(
          (item) => item.id !== action.payload
        )
    },
    deleteMerchantAgreementTemplateFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editMerchantAgreementTemplateStart: (state: any, action) => {
      state.loading = true
    },
    editMerchantAgreementTemplateSuccess: (state: any, action) => {
      const result = state.merchantAgreementTemplateList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )

      state.merchantAgreementTemplateList.data = result

      state.loading = false
    },
    editMerchantAgreementTemplateFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchMerchantAgreementTemplateStart,
  fetchMerchantAgreementTemplateSuccess,
  fetchMerchantAgreementTemplateFailure,
  createMerchantAgreementTemplateStart,
  createMerchantAgreementTemplateSuccess,
  createMerchantAgreementTemplateFailure,
  deleteMerchantAgreementTemplateStart,
  deleteMerchantAgreementTemplateSuccess,
  deleteMerchantAgreementTemplateFailure,
  fetchMerchantAgreementTemplateByIdStart,
  fetchMerchantAgreementTemplateByIdSuccess,
  fetchMerchantAgreementTemplateByIdFailure,
  editMerchantAgreementTemplateStart,
  editMerchantAgreementTemplateSuccess,
  editMerchantAgreementTemplateFailure,
} = merchantAgreementTemplateSetupSlice.actions

export default merchantAgreementTemplateSetupSlice.reducer
