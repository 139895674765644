import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import DraftList from 'components/GlobalMerchantForm/DraftList/DraftList.component'
import EditMerchantRequest from 'components/GlobalMerchantForm/DraftList/components/EditMerchantRequest/EditMerchantRequest.component'
import MerchantList from 'components/GlobalMerchantForm/MerchantList'
import SendMerchantRequest from 'components/GlobalMerchantForm/SendMerchantRequest'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import Agreement from './Agreement'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import AllNotificationList from 'components/AllNotificationList'
import ConnectStripe from 'components/Payments/ConnectStripe'

const CharitiesList = React.lazy(() => import('./Charities/CharitiesList'))
const ClubList = React.lazy(() => import('./Club/ClubList'))
const MembersList = React.lazy(() => import('./Members/MembersList'))
const RegionMembersList = React.lazy(
  () => import('./Members/RegionMembersList')
)

const ReferredCPList = React.lazy(
  () => import('./CollaborationPartners/ReferredCPList')
)
const TransactionQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/TransactionQrCode/QrCodes')
)

const IssuerQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/IssuerQrCode')
)

const FriendshipEarningAsRegionOwner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsRegionOwner')
)
const FriendshipEarningAsRegionOwnerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsRegionOwner')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)
const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)
const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const FriendshipEarningAsCpSignerAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgnerAsSigner')
)

const MembershipEarningFromRegionAllocation = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromRegionAllocation')
)
const MembershipEarningFromRegionAllocationDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromRegionAllocation')
)
const MembershipEarningAsClubCharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsClubCharitySigner')
)
const MembershipEarningAsClubCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsClubCharitySigner')
)

const MembershipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsCpSigner')
)
const MembershipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsCpSigner')
)
const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)
const MerchantCodesList = React.lazy(
  () => import('components/PremiumCodes/MerchantCodesList')
)
const MemberCodesList = React.lazy(
  () => import('components/PremiumCodes/MemberCodesList')
)
const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)

const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))

const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)

const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)

const EditMerchantFromMerchantList = React.lazy(
  () => import('components/GlobalMerchantForm/EditMerchantRequest')
)

const MerchantActivityReports = React.lazy(
  () => import('components/GlobalMerchantForm/MerchantActivityReports')
)

const MembersInfoList = React.lazy(
  () => import('components/GlobalMerchantForm/MembersList')
)

const RegionCharitiesList = React.lazy(
  () =>
    import('components/ReusableLocationBasedUsers/LocationBasedCharitiesList')
)

const RegionClubList = React.lazy(
  () => import('components/ReusableLocationBasedUsers/LocationBasedClubList')
)

const RegionCollaborationList = React.lazy(
  () =>
    import(
      'components/ReusableLocationBasedUsers/LocationBasedCollaborationList'
    )
)

const RegionMerchantsList = React.lazy(
  () => import('./LocationBasedUsers/RegionMerchantsList')
)

export const regionAdminConfig = [
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/premium-merchant-codes',
    element: <MerchantCodesList />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/premium-member-codes',
    element: <MemberCodesList />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/charitieslist',
    element: <CharitiesList />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/clublist',
    element: <ClubList />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/memberslist',
    element: <MembersList />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/region-members-list',
    element: <RegionMembersList />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/referredcplist',
    element: <ReferredCPList />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'region-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'region-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/editMerchantRequest/:id',
    element: <EditMerchantRequest adminRoute={'region-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/merchantList',
    element: <MerchantList adminRoute={'region-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/draftList',
    element: <DraftList adminRoute={'region-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/transaction-qr-codes',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/issuer-qr-codes',
    element: <IssuerQrCodes />,
  },
  // region owner
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/friendship-earning/asregionowner',
    element: <FriendshipEarningAsRegionOwner />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/friendship-earning/asregionowner/detail',
    element: <FriendshipEarningAsRegionOwnerDetail />,
  },
  // issuer
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },
  // merchant signer
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },
  // cp signer
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/friendship-earning/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/friendship-earning/ascpsigner/detail',
    element: <FriendshipEarningAsCpSignerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/friendship-earning/ascpsigner/asmerchantsigner/detail',
    element: <FriendshipEarningAsCpSignerAsMerchantSignerDetail />,
  },

  // issuer
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },
  // region allocation
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/membership-earning/fromregionallocation',
    element: <MembershipEarningFromRegionAllocation />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/membership-earning/fromregionallocation/detail',
    element: <MembershipEarningFromRegionAllocationDetail />,
  },
  // club/charity
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/membership-earning/asclubcharity',
    element: <MembershipEarningAsClubCharity />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/membership-earning/asclubcharity/detail',
    element: <MembershipEarningAsClubCharityDetail />,
  },
  // cp signer
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/membership-earning/ascpsigner',
    element: <MembershipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/membership-earning/ascpsigner/detail',
    element: <MembershipEarningAsCpSignerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'region-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },
  // {
  //   permittedRole: RoutePermittedRole.regionOwner,
  //   path: '/regionowner/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/agreement',
    element: <Agreement />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/coupons',
    element: <Coupons adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/coupon-codes/:id',
    element: <CouponCodes />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/setting/smtp',
    element: <SMTP adminRoute={'region-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/setting/group',
    element: <Group adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/setting/contacts',
    element: <Contacts adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/setting/documents',
    element: <Documents adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/setting/emails',
    element: <Emails adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/setting/recipients',
    element: <Recipients adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/setting/reports/:id',
    element: <Reports adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/merchantList/editMerchant/:id',
    element: <EditMerchantFromMerchantList adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/merchant-activity-reports',
    element: <MerchantActivityReports adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/member-list/:id',
    element: <MembersInfoList adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/all-notification',
    element: <AllNotificationList adminRoute={'region-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/region-merchants-list',
    element: <RegionMerchantsList adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/region-charities-list',
    element: <RegionCharitiesList adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/region-club-list',
    element: <RegionClubList adminRoute={'region-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.regionOwner,
    path: '/region-licensee/region-collaboration-list',
    element: <RegionCollaborationList adminRoute={'region-licensee'} />,
  },
]
