import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableHeading from './Heading'
import TableItem from './Item'
import {
  TablePagination,
  Box,
  Button,
  TableRow,
  TableCell,
  CircularProgress,
} from '@mui/material'
import AppTableContainer from '@crema/core/AppTableContainer'
import TableDataNotFound from 'components/TableDataNotFound'
import GetPrizeWinner from '../GetWinner'

// import { RecentOrders } from "types/models/ecommerce/EcommerceApp";
import { StateSetup } from 'types/models/StateSetup'

interface trainingVideoTableProps {
  prizeDrawData: any[]
  page: any
  pageNumber: number
  totalCount: any
  loadingTable: any
  setPage: any
  rowsPerPage: any
  setRowsPerPage: any
  handleChangePage: any
  handleChangeRowsPerPage: any
  hasMoreData: boolean
  onDeleteTrainingVideoStart: (id: number) => void
  auth?: any
}

const TrainingVideo: React.FC<trainingVideoTableProps> = ({
  prizeDrawData,
  page,
  pageNumber,
  totalCount,
  loadingTable,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  hasMoreData,
  handleChangePage,
  handleChangeRowsPerPage,
  onDeleteTrainingVideoStart,
  auth,
}) => {
  const [isLuckDrawOpen, setLuckyDrawOpen] = useState<boolean>(false)
  const [selectedPrizeDraw, setSelectedPrizeDraw] = useState<Object>({})

  const onOpenCreateLuckyDraw = (data) => {
    setLuckyDrawOpen(true)
    setSelectedPrizeDraw(data)
  }

  const onCloseLuckDraw = () => {
    setLuckyDrawOpen(false)
  }

  const onOpenLuckDraw = () => {
    setLuckyDrawOpen(true)
  }

  return (
    <AppTableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          nextIconButtonProps={{ disabled: !hasMoreData }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Table stickyHeader>
        <TableHead>
          <TableHeading auth={auth} />
        </TableHead>
        <TableBody>
          {!prizeDrawData?.length && loadingTable ? (
            <TableRow className="item-hover">
              <TableCell align="center" colSpan={8}>
                <CircularProgress size={20} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {prizeDrawData?.length ? (
                prizeDrawData.map((data, index) => (
                  <TableItem
                    data={data}
                    key={data.sn}
                    count={index + 1}
                    page={page}
                    pageNumber={pageNumber}
                    rowsPerPage={rowsPerPage}
                    onDeleteTrainingVideoStart={onDeleteTrainingVideoStart}
                    onOpenCreateLuckyDraw={onOpenCreateLuckyDraw}
                    onCloseLuckDraw={onCloseLuckDraw}
                    isLuckDrawOpen={isLuckDrawOpen}
                    setLuckyDrawOpen={setLuckyDrawOpen}
                    onOpenLuckDraw={onOpenLuckDraw}
                    selectedPrizeDraw={selectedPrizeDraw}
                    auth={auth}
                  />
                ))
              ) : (
                <TableDataNotFound colSpan={12} />
              )}
            </>
          )}
        </TableBody>
      </Table>
      {isLuckDrawOpen && (
        <GetPrizeWinner
          selectedPrizeDraw={selectedPrizeDraw}
          isLuckDrawOpen={isLuckDrawOpen}
          setLuckyDrawOpen={setLuckyDrawOpen}
          onOpenLuckDraw={onOpenLuckDraw}
          onCloseLuckDraw={onCloseLuckDraw}
        />
      )}
    </AppTableContainer>
  )
}

export default TrainingVideo
