import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Edit, Delete } from '@mui/icons-material'
import AppConfirmDialog from '@crema/core/AppConfirmDialog'
import { Tooltip, Button, IconButton, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import EditSection from '../EditSection'
import { truncateString } from 'utils'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 0,
  paddingRight: 20,
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  count: number
  page: number
  pageNumber: any
  rowsPerPage?: number
  refreshPageData?: any
  onDeleteSectionStart: (id: number) => void
}

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  page,
  pageNumber,
  rowsPerPage,
  onDeleteSectionStart,
  refreshPageData,
}) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [toDeleteId, setDeleteToDeleteId] = useState<number>()
  const [isEditSectionOpen, setEditSectionOpen] = useState<boolean>(false)
  const [selectedSection, setSelectedSection] = useState<Object>({})

  const onOpenEditSection = (data) => {
    setEditSectionOpen(true)
    setSelectedSection(data)
  }

  const onCloseEditSection = () => {
    setEditSectionOpen(false)
  }

  const onDeleteSelectedContacts = () => {
    onDeleteSectionStart(toDeleteId)
    setDeleteDialogOpen(false)
  }

  const onSelectContactsForDelete = (contactIds: number[]) => {
    setDeleteDialogOpen(true)
  }

  const stripHtmlTags = (html) => {
    const temp = document.createElement('div')
    temp.innerHTML = html
    return temp.textContent || temp.innerText || ''
  }

  return (
    <>
      <TableRow key={data?.sn} className="item-hover">
        <AppConfirmDialog
          open={isDeleteDialogOpen}
          onDeny={setDeleteDialogOpen}
          onConfirm={onDeleteSelectedContacts}
          title="Are you sure you want to delete this about us page ? This action will also delete this page in all languages."
          dialogTitle="Delete About Us"
        />
        <StyledTableCell align="left">
          {' '}
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>

        <StyledTableCell align="left">{data?.title}</StyledTableCell>

        <StyledTableCell align="left">
          {stripHtmlTags(truncateString(data?.shortDescription, 40))}
        </StyledTableCell>
        <StyledTableCell align="left">
          {stripHtmlTags(truncateString(data?.briefDescription, 40))}
        </StyledTableCell>
        <StyledTableCell align="left">{data?.lang}</StyledTableCell>
        <StyledTableCell
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          align="left"
        >
          <Tooltip placement="top" title="Edit">
            <IconButton onClick={() => onOpenEditSection(data)}>
              <Edit sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>
          {/* {data?.lang.includes('(en)') && (
            <Tooltip placement="top" title="Delete">
              <IconButton
                sx={{ fontWeight: '15px' }}
                onClick={() => {
                  onSelectContactsForDelete([data.sn])
                  setDeleteToDeleteId(data?.id)
                }}
              >
                <Delete sx={{ fontSize: '1.3rem' }} />
              </IconButton>
            </Tooltip>
          )} */}
        </StyledTableCell>
      </TableRow>
      {isEditSectionOpen && (
        <EditSection
          selectedSection={selectedSection}
          isEditSectionOpen={isEditSectionOpen}
          onCloseEditSection={onCloseEditSection}
          refreshPageData={refreshPageData}
        />
      )}
    </>
  )
}

export default TableItem
