import React, { useEffect } from 'react'
import EoiAppContentView from '@crema/core/EoiAppContentView'
import { useLayoutContext } from '../../utility/AppContextProvider/LayoutContextProvider'
import Layouts from './Layouts'
import AuthWrapper from './AuthWrapper'

import { connect } from 'react-redux'
import CustomAlert from '@crema/core/AppMessageView'
import moment from 'moment-timezone'

const AppLayout = ({
  auth: { isAuthenticated },
  alert,
  countrySetup: { countryStripeKeyInfo },
}) => {
  useEffect(() => {
    moment.tz.setDefault()
  }, [])

  const { navStyle } = useLayoutContext()
  // const { isAuthenticated } = useAuthUser();
  const AppLayout = Layouts[navStyle]

  // useEffect(() => {
  //   if (params.layout) updateNavStyle(params.layout as string)
  //   if (params.menuStyle) updateMenuStyle(params.menuStyle as string)
  //   if (params.sidebarImage) setSidebarBgImage(true)
  // }, [params, setSidebarBgImage, updateNavStyle, updateMenuStyle])

  // useEffect(() => {
  //   dispatch(onNavCollapsed())
  // }, [dispatch, pathname])

  return (
    <>
      {isAuthenticated ? (
        <>
          <AppLayout />
          <CustomAlert {...alert} />
        </>
      ) : (
        <AuthWrapper>
          <EoiAppContentView />
          <CustomAlert {...alert} />
        </AuthWrapper>
      )}
    </>
  )
}

const mapStateToProps = (state, props) => ({
  settings: state.settings,
  auth: state.auth,
  alert: state.alert,
  countrySetup: state.countrySetup,
})

const container = connect(mapStateToProps, null)

export default container(React.memo(AppLayout))
