import { axiosRequest } from 'utils'

export const fetchFriendshipReportList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/friendship/getAllReport`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipReportList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/membership/getAllReport`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchhWlIssuerReportList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/issuer/getAllReport`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWlMerchantSignerReportList = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/friendship/merchantSigner/getAllReport`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWlIssuerMembershipReportList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/membership/issuer/getAllReport`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCpSignerIssuerFriendshipReportList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/getAllReport`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCpMerchantSignerFriendshipReportList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/getAllReport`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCpSignerIssuerMembershipReportList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/getAllReport`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
