/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { fetchAreaStart, clearAreaDropDown } from 'store/dropDown/dropDownSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  dropDown: state.dropDown,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchAreaStart: (payload) => dispatch(fetchAreaStart(payload)),
  onClearAreaDropDown: () => dispatch(clearAreaDropDown()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
