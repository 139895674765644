import { createSlice } from '@reduxjs/toolkit'

import { IWhiteLabelReferredCP } from '../../interfaces'

import { listObj } from 'utils'

const INITIAL_STATE: IWhiteLabelReferredCP = {
  whiteLabelReferredCPList: listObj,
  soleWhiteLabelReferredCP: {},
  error: null,
  loading: false,
  isFetching: false,
  isRejecting: false,
  isVerifing: false,
  hasMoreData: false,
}

export const whiteLabelReferredCPSlice = createSlice({
  name: 'whiteLabelReferredCPList',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWhiteLabelReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelReferredCPSuccess: (state: any, action) => {
      state.whiteLabelReferredCPList = action.payload
      state.loading = false
    },
    fetchWhiteLabelReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchWhiteLabelReferredCPByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchWhiteLabelReferredCPByIdSuccess: (state: any, action) => {
      state.soleGmcReferredCP = action.payload.data
      state.isFetching = false
    },
    fetchWhiteLabelReferredCPByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const {
  fetchWhiteLabelReferredCPStart,
  fetchWhiteLabelReferredCPSuccess,
  fetchWhiteLabelReferredCPFailure,
  fetchWhiteLabelReferredCPByIdStart,
  fetchWhiteLabelReferredCPByIdSuccess,
  fetchWhiteLabelReferredCPByIdFailure,
} = whiteLabelReferredCPSlice.actions

export default whiteLabelReferredCPSlice.reducer
