/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { fetchClubByIdStart, editClubStart } from 'store/club/clubSlice'
import {
  setMerchantPopularStart,
  assignIssuerTransactionCodeStart,
} from 'store/merchant/merchantSlice'
import {
  fetchAreaIssuerQrCodesListByMerchantIdStart,
  fetchCountryIssuerQrCodesListByMerchantIdStart,
  fetchIssuerQrCodesListByMerchantIdStart,
  fetchRegionIssuerQrCodesListByMerchantIdStart,
  fetchStateIssuerQrCodesListByMerchantIdStart,
} from 'store/qrCodes/qrCodesSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  dropDown: state.dropDown,
  areaOwner: state.areaOwner,
  stateOwner: state.stateOwner,
  charities: state.charities,
  siteCoordinator: state.siteCoordinator,
  merchant: state.merchant,
  club: state.club,
  qrCodes: state.qrCodes,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchClubByIdStart: (payload) => dispatch(fetchClubByIdStart(payload)),
  onFetchIssuerQrCodesListByMerchantIdStart: (payload) =>
    dispatch(fetchIssuerQrCodesListByMerchantIdStart(payload)),

  onFetchStateIssuerQrCodesListByMerchantIdStart: (payload) =>
    dispatch(fetchStateIssuerQrCodesListByMerchantIdStart(payload)),

  onFetchRegionIssuerQrCodesListByMerchantIdStart: (payload) =>
    dispatch(fetchRegionIssuerQrCodesListByMerchantIdStart(payload)),

  onFetchAreaIssuerQrCodesListByMerchantIdStart: (payload) =>
    dispatch(fetchAreaIssuerQrCodesListByMerchantIdStart(payload)),

  onFetchCountryIssuerQrCodesListByMerchantIdStart: (payload) =>
    dispatch(fetchCountryIssuerQrCodesListByMerchantIdStart(payload)),

  onEditClubStart: (payload) => dispatch(editClubStart(payload)),
  onSetMerchantPopularStart: (payload) =>
    dispatch(setMerchantPopularStart(payload)),
  onAssignIssuerTransactionCodeStart: (payload) =>
    dispatch(assignIssuerTransactionCodeStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
