import { axiosRequest, removeEmptyFields } from 'utils'

export const fetchCollaborationPackageList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPackage/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchCollaborationPackageById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPackage/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCollaborationPackage = (params) => {
  return () =>
    axiosRequest
      .post(`/api/collaborationPackage/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCollaborationPackage = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/collaborationPackage/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCollaborationPackage = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/collaborationPackage/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
