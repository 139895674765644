import axiosRequest, { axiosRequestCMS } from './axiosRequest'
import unauthorizedAxiosRequest from './unauthorizedAxiosRequest'
import bulkDownloadAxiosRequest from './bulkDownloadAxiosRequest'
import { useEffect, useRef } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { number } from 'prop-types'

const isUserLogged = () => {
  const isLogged = localStorage.getItem('authToken')
  if (
    isLogged === null ||
    isLogged === undefined ||
    isLogged === '' ||
    isLogged === 'null'
  ) {
    return false
  }
  return true
}

const fixed2Decimal = (number) => {
  if (number) {
    return Math.floor(parseFloat(number) * 100) / 100
  } else {
    return 0
  }
}

const merchantPackageName = (value) => {
  if (value?.toLowerCase() === 'premium') {
    return 'Premium Merchant Type'
  } else if (value?.toLowerCase() === 'support') {
    return 'Supporter Merchant Type'
  } else {
    return value
  }
}

const truncateString = (str, num) => {
  if (str?.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

const removeEmptyFields = (data) => {
  const temp = { ...data }

  for (var key in data) {
    if (
      temp[key] === '' ||
      temp[key] === null ||
      temp[key] === undefined ||
      Object.is(NaN, temp[key])
    )
      delete temp[key]
  }
  return { ...temp }
}

const removeParam = (uri) => {
  // return uri.split(/[?#]/)[0]
  //  `https://dhjwuu8sert2w.cloudfront.net/${imgId}`

  const imgId = getImageIdFromUrl(uri)

  return process.env.REACT_APP_FILE_UPLOAD_URL
    ? `${process.env.REACT_APP_FILE_UPLOAD_URL}${imgId}`?.split(/[?#]/)?.[0]
    : uri.split(/[?#]/)[0]
}

const listObj = {
  data: [],
  hasMore: false,
  page: 0,
  totalCount: 0,
  count: 0,
}

const decimalRoundUp = (argValue: any) => {
  const e = parseFloat(argValue) + 0.009
  return parseFloat((Math.floor(e * 100) / 100).toFixed(2))
}

const businessType = [
  { label: 'Company', value: 'company' },
  { label: 'Sole Proprietor', value: 'soleProprietor' },
]

const packageType = [
  { label: 'Global', value: 'global' },
  { label: 'Country', value: 'country' },
]

const paymentStatus = [
  { label: 'Received', value: 'received' },
  { label: 'Awaiting Payment', value: 'awaiting' },
]

const documentType = [
  { label: 'Video', value: 'video' },
  { label: 'PDF', value: 'file' },
]

const PrizeDrawMemberFeeType = [
  { label: 'All', value: 'all' },
  { label: 'Free Member', value: 'freeMember' },
  { label: 'Premium Member', value: 'premiumMember' },
]

const paymentMethod = [
  { label: 'Cash', value: 'cash' },
  { label: 'Card', value: 'card' },
  { label: 'Credit', value: 'credit' },
  { label: 'Crypto', value: 'crypto' },
  { label: 'Others', value: 'others' },
]

const referredRoles = [
  { label: 'Country Licensee', value: 'countryOwner' },
  { label: 'State Licensee', value: 'stateOwner' },
  { label: 'Region Licensee', value: 'regionOwner' },
  { label: 'Area Licensee', value: 'areaOwner' },
  { label: 'Collaboration Partner', value: 'collaborationPartner' },
  { label: 'Sales Agent', value: 'salesAgent' },
  { label: 'White Label User', value: 'whiteLabelUser' },
]

const trainingVideoTypeRoleOptions = [
  // { label: 'Global Admin', value: 'globalAdmin' },
  { label: 'Country Licensee', value: 'countryOwner' },
  { label: 'State Licensee', value: 'stateOwner' },
  { label: 'Region Licensee', value: 'regionOwner' },
  { label: 'Area Licensee', value: 'areaOwner' },
  { label: 'Sales Agent', value: 'salesAgent' },
  { label: 'Collaboration Partner', value: 'collaborationPartner' },
  { label: 'Charity', value: 'charity' },
  { label: 'Club', value: 'club' },
  { label: 'White Label User', value: 'whiteLabelUser' },
  { label: 'Member', value: 'member' },
  { label: 'Merchant', value: 'merchant' },
  // { label: 'White Label', value: 'whiteLabel' },
  { label: 'Global Marketing Company', value: 'gmcUser' },
  // { label: 'Website Admin', value: 'websiteAdmin' },
]

const packageNames = [
  { label: 'Country Starter Partners', value: 'countryStarterPartner' },
  { label: 'Country Advanced Partners', value: 'countryAdvancedPartner' },
  {
    label: 'Country Professional Partners',
    value: 'countryProfessionalPartner',
  },
  { label: 'Global Starter Partners', value: 'globalStarterPartners' },
  { label: 'Global Professional Partners', value: 'globalProfessionalPartner' },
]

const findObjFromList = (dataList: any[], itemId: number) => {
  const result = dataList?.find((item) => item?.id === itemId)
  return result
}

const splitByCamelCase = (str: string): any => {
  const result = str ? str.split(/(?=[A-Z])/) : []
  if (result?.length) {
    return result
  }
  return []
}

const camelCaseToSpace = (str: string) => {
  const newStr = str?.length ? str[0].toUpperCase() + str.slice(1) : ''
  return newStr.replace(/([A-Z]+)/g, ' $1')
}

const totalOfArrayItem = (dataArray, sumKey) => {
  const sum = dataArray?.reduce(
    (acc, array) => acc + parseFloat(array?.[`${sumKey}`]),
    0
  )
  return sum ? decimalRoundUp(sum) : 0
}

const formatDate = (dateString: string | null | undefined): string => {
  const formattedDate = dateString
    ? moment(dateString).format('DD-MM-YYYY')
    : '-'
  return formattedDate
}

const formatDateRange = (dateString: string | null | undefined): string => {
  if (!dateString) {
    return null
  }

  const modifiedDate = moment(dateString)
  return `${modifiedDate.format('DD-MM-YYYY')}:${modifiedDate
    .add(1, 'days')
    .format('DD-MM-YYYY')}`
}

const formatDateIntoFromTo = (dateString: string | null | undefined): any => {
  if (!dateString) {
    return null
  }

  const modifiedDate = moment(dateString)
  return {
    from: modifiedDate.format('DD-MM-YYYY').toString(),
    to: modifiedDate.add(1, 'days').format('DD-MM-YYYY').toString(),
  }
}

const camelCaseToHyphen = (str: string) => {
  const arrSplit = str.split('-')
  const newArray = arrSplit?.length
    ? arrSplit?.map((item) => {
        return item?.charAt(0).toUpperCase() + item.slice(1)
      })
    : []

  return newArray?.length ? camelCaseToSpace(newArray?.join('')) : ''
}

const stripDecimals = (n) => {
  return n | 0
}

const sumFromArray = (propertyName, array) => {
  let sum = 0
  array.forEach((item) => {
    sum += parseFloat(item[propertyName]) ?? 0
  })
  return sum
}

const sumFromArrayForPercentage = (propertyName, array) => {
  let sum = 0
  array.forEach((item) => {
    sum += parseFloat(item[propertyName]) ?? 0
  })
  return sum
}

const getGreatestOrder = (arrayValue) => {
  const greatestOrder = Math.max(...arrayValue.map((o) => o?.order))
  return greatestOrder
}

const getTrueCountInObject = (data) => {
  return Object.values(data).filter((v) => v === true).length
}

const sortWeek = (obj) => {
  const week = {
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: null,
  }

  for (const [key, value] of Object.entries(week)) {
    if (obj[key]) week[key] = obj[key]
  }

  return week
}

const defaultValueCountryPrefix = (
  defaultPrefix,
  countryPrefixList,
  globalCountry
) => {
  let contactPrefix = countryPrefixList?.find(
    (item) => item?.countryName === globalCountry?.countryName
  )
  if (defaultPrefix) {
    return { defaultPrefix, label: defaultPrefix }
  } else if (
    !_.isEmpty(contactPrefix) &&
    (!defaultPrefix || defaultPrefix === undefined)
  ) {
    return contactPrefix
  } else {
    return null
  }
}

const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const regMatch =
  /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim

const getImageIdFromUrl = (url: string) => {
  const reg = /[^\/]*$/g

  const match = url ? url.match(reg) : ''

  if (match.length) {
    return match[0]
  }
  return null
}

const addCommas = (value) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const formatCash = (n) => {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

const formatAmount = (number: number) => {
  let format

  if (number < 0) {
    return (format = '-' + formatCash(-1 * number))
  } else {
    return (format = formatCash(number))
  }
}

const formatCurrency = (num: number | string) => {
  // let str = num.toString()
  // str = str.slice(0, str.indexOf('.') + 2 + 1)
  // return addCommas(Number(str)) || '-'
  // return Math.trunc(Number(num) * 100) / 100

  // const result = addCommas(fixed2Decimal(num))
  const result = addCommas(decimalRoundUp(num))

  // return result
  return result === 'NaN' ? '-' : result
}

const isEmpty = (value) => {
  return value == null || value.length === 0
}

const useQuery = () => {
  return new URLSearchParams(window.location.search)
}

const isQuillEmpty = (value) => {
  if (
    value.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
    !value.includes('<img')
  ) {
    return true
  }
  return false
}

const getCreatorName = (data) => {
  if (
    data?.createdByType === 'salesAgent' ||
    data?.createdByType === 'collaborationPartner'
  ) {
    return data?.__createdBy__?.salesAgentName
  } else if (data?.createdByType === 'club') {
    return data?.__createdBy__?.clubName
  } else if (data?.createdByType === 'charity') {
    return data?.__createdBy__?.charityName
  } else if (
    data?.createdByType === 'areaOwner' ||
    data?.createdByType === 'regionOwner' ||
    data?.createdByType === 'stateOwner' ||
    data?.createdByType === 'countryOwner'
  ) {
    return data?.__createdBy__?.primaryContactName
  } else if (
    data?.createdByType === 'whiteLabelUser' ||
    data?.createdByType === 'globalAdmin'
  ) {
    return data?.__createdBy__?.username
  } else {
    return '-'
  }
}

export const getReferrerName = (data) => {
  if (
    data?.refererType === 'salesAgent' ||
    data?.refererType === 'collaborationPartner'
  ) {
    return data?.__referrer__?.salesAgentName
  } else if (data?.refererType === 'club') {
    return data?.__referrer__?.clubName
  } else if (data?.refererType === 'charity') {
    return data?.__referrer__?.charityName
  } else if (
    data?.refererType === 'areaOwner' ||
    data?.refererType === 'regionOwner' ||
    data?.refererType === 'stateOwner' ||
    data?.refererType === 'countryOwner'
  ) {
    return data?.__referrer__?.primaryContactName
  } else if (
    data?.refererType === 'whiteLabelUser' ||
    data?.refererType === 'globalAdmin'
  ) {
    return data?.__referrer__?.username
  } else {
    return '-'
  }
}

export const getSignerName = (data) => {
  if (
    data?.signerType === 'salesAgent' ||
    data?.signerType === 'collaborationPartner'
  ) {
    return data?.__signer__?.salesAgentName
  } else if (data?.signerType === 'club') {
    return data?.__signer__?.clubName
  } else if (data?.signerType === 'charity') {
    return data?.__signer__?.charityName
  } else if (
    data?.signerType === 'areaOwner' ||
    data?.signerType === 'regionOwner' ||
    data?.signerType === 'stateOwner' ||
    data?.signerType === 'countryOwner'
  ) {
    return data?.__signer__?.primaryContactName
  } else if (
    data?.signerType === 'whiteLabelUser' ||
    data?.signerType === 'globalAdmin'
  ) {
    return data?.__signer__?.username
  } else {
    return '-'
  }
}

const trimCodeFromColor = (data) => {
  const trimmedText = data.replace('0xff', '#')
  return trimmedText
}

const trimUppercaseCodeFromColor = (data) => {
  const trimmedText = '#' + data.substring(4)
  return trimmedText
}

const trimHashAddCode = (data) => {
  const trimmedText = data?.replace('#', '0xFF')
  return trimmedText
}

const checkIfEmptyString = (str) => {
  if (str?.trim()?.length === 0) {
    return ''
  } else {
    return str
  }
}

const trimNewLine = (string) => {
  return string?.replace(/\n|\r/g, '')
}

const getUrlWithRole = (orgRole) => {
  switch (orgRole) {
    case 'admin':
      return 'global-admin'
    case 'countryowner':
      return 'country-licensee'
    case 'gmcuser':
      return 'gmcuser'
    case 'areaowner':
      return 'area-licensee'
    case 'stateowner':
      return 'state-licensee'
    case 'regionowner':
      return 'region-licensee'
    case 'charity':
      return 'charity'
    case 'club':
      return 'club'
    case 'salesagent':
      return 'salesagent'
    case 'collaborationpartner':
      return 'collaborationpartner'
    case 'whitelabeluser':
      return 'whiteLabel'
    case 'merchant':
      return 'merchant'
    case 'websiteadmin':
      return 'websiteadmin'
    case 'globaladmin':
      return 'global-admin'
    default:
      return 'deafult'
  }
}

export const integerValidation = (value) => {
  if (!Number.isInteger(value) || String(value).includes('.')) {
    return false
  }
  return true
}

export {
  axiosRequest,
  unauthorizedAxiosRequest,
  formatCurrency,
  axiosRequestCMS,
  isUserLogged,
  removeEmptyFields,
  listObj,
  removeParam,
  businessType,
  packageType,
  packageNames,
  paymentStatus,
  paymentMethod,
  findObjFromList,
  splitByCamelCase,
  camelCaseToSpace,
  stripDecimals,
  sumFromArray,
  sumFromArrayForPercentage,
  getGreatestOrder,
  getTrueCountInObject,
  sortWeek,
  usePrevious,
  regMatch,
  defaultValueCountryPrefix,
  getImageIdFromUrl,
  camelCaseToHyphen,
  addCommas,
  formatAmount,
  trainingVideoTypeRoleOptions,
  referredRoles,
  formatDate,
  formatDateRange,
  documentType,
  PrizeDrawMemberFeeType,
  isEmpty,
  useQuery,
  fixed2Decimal,
  truncateString,
  isQuillEmpty,
  decimalRoundUp,
  getCreatorName,
  trimCodeFromColor,
  trimHashAddCode,
  trimUppercaseCodeFromColor,
  checkIfEmptyString,
  trimNewLine,
  totalOfArrayItem,
  getUrlWithRole,
  formatDateIntoFromTo,
  bulkDownloadAxiosRequest,
  merchantPackageName,
}
