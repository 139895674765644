import { axiosRequest } from 'utils'

export const fetchCountryAdminTotalTransactionAndTotalOwnerRequests = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/countryAdmin/totalTransactionAndTotalOwnerRequests`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryAdminTotalEarning = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/countryAdmin/totalEarning`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminTotalEarning = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/totalSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryAdminAllDataCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/countryAdmin/AllDataCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryAdminOwnerDataCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/countryAdmin/ownerDataCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryAdminRevenueFriendshipTransactionSummary = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/countryAdmin/revenues/friendshipTransactionSummary`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryAdminMembershipTransactionSummary = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/countryAdmin/revenues/membershipTransactionSummary`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryAdminDashboardRecentMerchants = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOwnerAdminDashboardRecentMerchants = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOwnerAdminDashboardRecentMerchantsForCountryDashboard = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getAllByAdmin`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchStateAdminDashboardRecentMembers = (params) => {
  return () =>
    axiosRequest
      .get(`/api/member/general/getAllIssuedByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminDashboardYearlyTransactionGraph = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/yearlyTransactionGraph`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminTotalSummaryRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/totalSummaryRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminByCountryTodaySummary = (id, params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/todaySummaryByCountry/${id}`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminByCountryTotalSummary = (id) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/selectCountry/totalSummary/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminByCountryFriendshipRevenueToday = (id, params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/selectCountry/friendshipRevenue/${id}`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminByCountryMembershipRevenueToday = (id, params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/selectCountry/membershipRevenue/${id}`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminByCountryTotalRevenueSummary = (id) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/totalSummarytotalInvolvedPartyCounts/${id}`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminByCountryTotalRevenueSummaryCount = (id) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/selectCountry/totalSummaryRevenue/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminAllDataCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/allDataCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminByCountryAllDataCount = (id) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/totalSummaryAllDataCount/${id}`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryAdminDashboardYearlyTransactionGraph = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/countryAdmin/yearlyTransactionGraph`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminTotalTransactionAndTotalOwnerRequests = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/totalTransactionAndTotalOwnerRequests`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdmintotalAndTodayUserCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/totalAndTodayUserCount`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdmintotalAndTodayUserCountOfWL = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/whiteLabelintroducer/todayFriendshipAndMembershipEarning`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdmintotalSummaryUserCountOfWL = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/whiteLabelintroducer/totalFriendshipAndMembershipEarning `,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminTodaySummaryTransaction = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/todaySummaryGlobalAdmin`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchStateAdminTotalTransctionOwnerRequest = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/ownerAdmin/totalTransctionOwnerRequest`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchStateAdminFriendshipRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/ownerAdmin/friendshipRevenueToday`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// fetchStateAdminMembershipRevenue

export const fetchOwnerAdminFriendshipRevenueToday = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/ownerAdmin/friendshipRevenueToday`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// export const fetchStateAdminMembershipRevenue = (params) => {
//   return () =>
//     axiosRequest
//       .get(`/api/dashboard/ownerAdmin/membershipRevenueToday`, { params })
//       .then((res) => {
//         console.log(
//           'fetch state admin dashboard membership transaction summary',
//           res
//         )
//         return Promise.resolve(res)
//       })
//       .catch((err) => {
//         return Promise.reject(err)
//       })
// }

export const fetchOwnerAdminMembershipRevenueToday = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/ownerAdmin/membershipRevenueToday`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOwnerAdminFriendshipRevenueSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/ownerAdmin/friendshipRevenueSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOwnerAdminMembershipRevenueSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/ownerAdmin/membershipRevenueSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOwnerAdminEarningGraphPerYear = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/ownerAdmin/ownerEarningGraphPerYear`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchStateAdminTotalTransactionSummaryCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/ownerAdmin/totalTransactionSummaryCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminFriendshipRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/friendshipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminMembershipRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/membershipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantAdminTodaysSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/todaySummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSupporterMerchantAdminTodaysSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/supporterMerchant/todaySummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTerminalMerchantAdminTodaysSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/terminal/todayAndTotalSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantAdminTotalSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/totalSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSupporterMerchantAdminTotalSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/supporterMerchant/totalSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSupporterMerchantDetail = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/supporterMerchant/getDetail`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTerminalMerchantAdminTotalSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/terminal/totalSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantAdminYearlyTransactionGraph = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/yearlyTransactionGraph`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantAdminFriendshipRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/friendshipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantAdminMembershipRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/membershipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantAdminTotalFriendshipRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/totalFriendshipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantAdminTotalMembershipRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/totalMembershipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSalesAgentTotalSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/salesAgent/totalSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSalesAgentDailySummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/salesAgent/dailySummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSalesAgentTotalUserCounts = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/salesAgent/totalUserCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCollaborationPartnerTotalSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/collaborationPartner/totalSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCollaborationPartnerDailySummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/collaborationPartner/dailySummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCollaborationPartnerTotalUserCounts = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/collaborationPartner/totalUserCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCharityTodaySummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/charityAdmin/charityTodaySummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTotalCharitySummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/charityAdmin/totalCharitySummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantAndMemberCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/charityAdmin/merchantAndMemberCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCharityTotalRevenueSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/charityAdmin/totalRevenueSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchClubTodaySummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/clubAdmin/todayClubSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTotalClubSummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/clubAdmin/totalClubSummary`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTodayClubRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/clubAdmin/todayClubRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTotalClubRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/clubAdmin/totalClubRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whiteLabel IsBalancingLabel

export const fetchWhiteLabelIsBalancingTodaySummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabel/todayDataCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsBalancingFriendshipRevenueToday = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabel/todayFriendshipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsBalancingMembershipRevenueToday = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabel/todayMembershipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsBalancingTodayRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabel/todayRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsBalancingTotalRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabel/totalRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsBalancingAllDataCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabel/allDataCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsBalancingYearlyTransactionGraph = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabel/yearlyTransactionGraph`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
//whiteLabel IsIntroducer

export const fetchWhiteLabelIsIntroducerTodaySummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelintroducer/introducerTodayDataCount`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsIntroducerFriendshipRevenueToday = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelintroducer/todayFriendshipRevenue`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsIntroducerMembershipRevenueToday = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelintroducer/todayMembershipRevenue`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsIntroducerTodayRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelintroducer/todayRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsIntroducerTotalRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelintroducer/totalRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsIntroducerAllDataCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelintroducer/allDataCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelIsIntroducerYearlyTransactionGraph = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelintroducer/yearlyTransactionGraph`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

//whiteLabel General

export const fetchWhiteLabelTodaySummary = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelLevels/todayDataCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelFriendshipRevenueToday = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelLevels/todayFriendshipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelMembershipRevenueToday = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelLevels/todayMembershipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelTodayRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelLevels/todayMembershipRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelTotalRevenue = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelLevels/totalRevenue`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelAllDataCount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelLevels/allDataCount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelYearlyTransactionGraph = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelLevels/yearlyTransactionGraph`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelYearlyTransactionGraphAsCPSigner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/cpSigner/yearlyGraph`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSigner = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/cpSigner/balancingFigure/yearlyTransactionGraph`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantRebateWallet = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantRebatewallet/getOneByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantTransactionFeeDebit = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/amount_paid`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

//overall dashboard api  section starts

export const fetchCharityDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/charityAdmin/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchClubDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/clubAdmin/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchRegionDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/ownerAdmin/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/countryAdmin/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSalesAgentDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/salesAgent/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCpDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/collaborationPartner/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountryDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/todayAndTotalSummary/${params?.countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/merchant/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSupporterMerchantDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/supporterMerchant/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchIntroducerDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelintroducer/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchBalancingLevelDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabel/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchNormalWhiteLabelDashboardData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/whiteLabelLevels/todayAndTotalSummary`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
