import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import AppLngSwitcher from '@crema/core/AppLngSwitcher'
import Box from '@mui/material/Box'
import AppSearchBar from '@crema/core/AppSearchBar'
import Hidden from '@mui/material/Hidden'
import { toggleNavCollapsed } from '../../../../../store/settings/settingsSlice'
import MenuIcon from '@mui/icons-material/Menu'
import { useDispatch, useSelector } from 'react-redux'

import Logo from '../../../../../assets/icon/piiinklogo-final-2.png'

import { connect } from 'react-redux'
import { setGlobalCountry } from 'store/siteCoordinator/siteCoordinatorSlice'
import LogoutIcon from '@mui/icons-material/Logout'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { Tooltip, IconButton, Button } from '@mui/material'
import { logOut } from 'store/auth/authSlice'
import { fetchNotificationByUserStart } from 'store/globalAdminSettings/globalAdminSettingsSlice'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Badge from '@mui/material/Badge'
import { RootState } from 'store'
import { useNavigate } from 'react-router-dom'
import { getUrlWithRole } from 'utils'
import _ from 'lodash'

const AppHeader = ({
  dropDown: { countryList },
  auth: { currentUser, isAuthenticated },
  onSetGlobalCountry,
  onLogOut,
  toggleSideBarMenu,
}) => {
  const { notificationListByUser } = useSelector(
    (state: RootState) => state.globalSettings
  )

  const theme = useTheme()

  const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    if (
      isAuthenticated &&
      currentUser?.role?.toLowerCase() !== 'globaladmin' &&
      currentUser?.role?.toLowerCase() !== 'gmcuser'
    ) {
      dispatch(fetchNotificationByUserStart({}))
    }
  }, [])

  const orgRole = currentUser?.role?.toLowerCase()

  const helpLinkByRole = () => {
    if (orgRole === 'countryowner') {
      return 'https://piiink-cms.s3.ap-south-1.amazonaws.com/f067b0c866564e015cd4dc716e81246c'
    } else if (orgRole === 'areaowner') {
      return 'https://piiink-cms.s3.ap-south-1.amazonaws.com/cbe91731d31b4e52c59b228a4115dffb'
    } else if (orgRole === 'stateowner') {
      return 'https://piiink-cms.s3.ap-south-1.amazonaws.com/e6a100e397d5a7a5d5eb0400d424f59e'
    } else if (orgRole === 'regionowner') {
      return 'https://piiink-cms.s3.ap-south-1.amazonaws.com/2e37a08662ac948bb78f50f8232e2632'
    }
  }

  return (
    <AppBar
      position="relative"
      color="inherit"
      sx={{
        boxShadow: 'none',
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        backgroundColor: 'background.paper',
        width: {
          xs: '100%',
        },
      }}
      className="app-bar"
    >
      <Toolbar
        sx={{
          boxSizing: 'border-box',
          minHeight: { xs: 56, sm: 70 },
          paddingLeft: { xs: 5 },
          paddingRight: { xs: 5, md: 7.5, xl: 12.5 },
        }}
      >
        <Hidden lgUp>
          <IconButton
            sx={{
              color: 'text.secondary',
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleNavCollapsed())}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <IconButton
            sx={{
              color: 'text.secondary',
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSideBarMenu}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <img
            style={{ padding: matchesLgDown ? '0.5rem' : null }}
            src={Logo}
            width={matchesLgDown ? '200' : '100'}
            height={matchesLgDown ? '200' : '100'}
            alt="logo"
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        />
        {isAuthenticated && (
          <Grid container justifyContent="flex-end">
            <Grid item lg={2} md={4} sm={6} xs={8}>
              <Typography variant="h4" component="h2">
                {currentUser?.userCountry?.countryName}
              </Typography>
            </Grid>
          </Grid>
        )}

        {currentUser?.role?.toLowerCase() === 'globaladmin' && (
          <Grid
            item
            container
            lg={1}
            md={4}
            sm={6}
            xs={8}
            justifyContent={'flex-end'}
          >
            <Tooltip placement="top" title="Help">
              <IconButton
                onClick={() =>
                  window.open(
                    currentUser?.userCountry?.id
                      ? 'https://piiink-cms.s3.ap-south-1.amazonaws.com/4cc4f9d3b0f7d253d5c6418d733f149a'
                      : 'https://piiink-cms.s3.ap-south-1.amazonaws.com/cdb31eaa75e658f5c6b62c79f864bd98',
                    '_blank'
                  )
                }
              >
                <HelpOutlineIcon sx={{ fontSize: '1.7rem' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        {(currentUser?.role?.toLowerCase() === 'countryowner' ||
          currentUser?.role?.toLowerCase() === 'areaowner' ||
          currentUser?.role?.toLowerCase() === 'stateowner' ||
          currentUser?.role?.toLowerCase() === 'regionowner') && (
          <Grid
            item
            container
            lg={1}
            md={4}
            sm={6}
            xs={8}
            justifyContent={'flex-end'}
          >
            <Tooltip placement="top" title="Help">
              <IconButton
                onClick={() => window.open(helpLinkByRole(), '_blank')}
              >
                <HelpOutlineIcon sx={{ fontSize: '1.7rem' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        {isAuthenticated &&
          currentUser?.role?.toLowerCase() !== 'globaladmin' && (
            <Grid
              item
              container
              lg={1}
              md={4}
              sm={6}
              xs={8}
              justifyContent={'flex-end'}
            >
              <Tooltip placement="top" title="Notifications">
                <IconButton
                  onClick={() => {
                    navigate(`/${getUrlWithRole(orgRole)}/all-notification`, {
                      state: { defaultTab: '2' },
                    })
                  }}
                >
                  <Badge
                    color="secondary"
                    badgeContent={
                      notificationListByUser?.totalNotificationNotCloses ?? 0
                    }
                  >
                    <NotificationsNoneIcon sx={{ fontSize: '1.7rem' }} />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
          )}

        {isAuthenticated && (
          <Grid
            item
            container
            lg={1}
            md={4}
            sm={6}
            xs={8}
            justifyContent={'flex-end'}
          >
            <Tooltip placement="top" title="Log Out">
              <IconButton
                onClick={() => {
                  onLogOut()
                }}
              >
                <LogoutIcon sx={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        {/* {isAuthenticated && currentUser?.role?.toLowerCase() === 'globaladmin' && (
          <Grid container justifyContent="flex-end">
            <Grid item lg={2} md={4} sm={6} xs={8}>
              <Typography variant="h4" component="h2">
                {currentUser?.userCountry?.countryName}
              </Typography>
            </Grid>
          </Grid>
        )} */}

        <AppLngSwitcher iconOnly={true} tooltipPosition="bottom" />
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  dropDown: state.dropDown,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  alert: state.alert,
})

const mapDispatchToProps = (dispatch) => ({
  onSetGlobalCountry: (payload) => dispatch(setGlobalCountry(payload)),
  onFetchNotificationByUserStart: (payload) =>
    dispatch(fetchNotificationByUserStart(payload)),
  onLogOut: () => dispatch(logOut()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container(AppHeader)
