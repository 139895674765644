import React, { useEffect, useState } from 'react'
import { emphasize, styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import { Box, CircularProgress, Grid } from '@mui/material'
import AppComponentHeader from '@crema/core/AppComponentHeader'
import AppCard from '@crema/core/AppCard'
import ListTop from './components/ListTop/ListTop'
import GeneralSettingsTable from './components/Table'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import container from './AllNotificationList.container'
import { useLocation } from 'react-router-dom'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

function handleClick(event) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

const CustomizedBreadcrumbs = () => {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          icon={<HomeIcon fontSize="small" />}
          label="Notifications"
        />
      </Breadcrumbs>
    </div>
  )
}

const Group = ({
  onFetchGeneralSettingStart,
  onFetchNotificationListStart,
  onDeleteGroupInformationListStart,
  onChangeNotificationOnOrOffStart,
  onFetchNotificationByUserTypeSeenStart,
  onFetchNotificationByUserTypeUnSeenStart,
  onFetchNotificationByUserStart,
  onDeleteNotificationStart,
  OnMarkNotificationAsUnseenStart,
  globalSettings: {
    allNotificationList: { data, hasMore, totalCount, count, page: pageNumber },
    loading,
    isFetchingNotification,
  },
  auth: { currentUser },
  ...rest
}) => {
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterFormData, setFilterFormData] = useState({})
  const [selectedObj, setSelectedObj] = useState({})

  const location = useLocation()
  const [value, setValue] = useState(
    location?.state?.defaultTab ? location?.state?.defaultTab : '1'
  )

  // useEffect(() => {
  //   if (location?.state?.defaultTab) {
  //     setValue(location?.state?.defaultTab)
  //   }
  // }, [location?.state])

  // const clearFilterForm = () => {
  //   setFilterFormData({})
  // }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setOffset(offset + rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  useEffect(() => {
    if (value === '1') {
      onFetchNotificationByUserTypeSeenStart({
        page: page + 1,
        limit: rowsPerPage,
        offset,
        ...filterFormData,
      })
    } else {
      onFetchNotificationByUserTypeUnSeenStart({
        page: page + 1,
        limit: rowsPerPage,
        offset,
        ...filterFormData,
      })
    }
  }, [page, rowsPerPage, filterFormData, value])

  const refreshPageData = () => {
    if (value === '1') {
      onFetchNotificationByUserTypeSeenStart({
        page: page + 1,
        limit: rowsPerPage,
        offset,
        ...filterFormData,
      })
    } else {
      onFetchNotificationByUserTypeUnSeenStart({
        page: page + 1,
        limit: rowsPerPage,
        offset,
        ...filterFormData,
      })
      onFetchNotificationByUserStart({})
    }
  }

  return (
    <Box style={{ color: '#000000' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <AppComponentHeader title="Notifications" />
        <CustomizedBreadcrumbs />
      </Box>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Seen Notifications" value="1" />
            <Tab label="Unseen Notifications" value="2" />
          </TabList>
        </Box>
        <Box p={3}>
          <ListTop
            setFilterFormData={(data) => {
              setFilterFormData(data)
              setPage(0)
            }}
            refreshPageData={refreshPageData}
            clearFilterForm={() => {}}
            currentUser={currentUser}
            {...rest}
          />
        </Box>
        <TabPanel value="1">
          {isFetchingNotification ? (
            <CircularProgress size={25} />
          ) : (
            <>
              <AppCard>
                <GeneralSettingsTable
                  generalSettingsData={data}
                  page={page}
                  pageNumber={pageNumber}
                  totalCount={totalCount}
                  loadingTable={loading}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  hasMoreData={hasMore}
                  setRowsPerPage={setRowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  refreshPageData={refreshPageData}
                  onDeleteGroupInformationListStart={
                    onDeleteGroupInformationListStart
                  }
                  currentUser={currentUser}
                  onChangeNotificationOnOrOffStart={
                    onChangeNotificationOnOrOffStart
                  }
                  onDeleteNotificationStart={onDeleteNotificationStart}
                  setSelectedObj={setSelectedObj}
                  selectedObj={selectedObj}
                  OnMarkNotificationAsUnseenStart={
                    OnMarkNotificationAsUnseenStart
                  }
                  value={'1'}
                />
                <Box style={{ height: 30 }} />
              </AppCard>
            </>
          )}
        </TabPanel>
        <TabPanel value="2">
          {isFetchingNotification ? (
            <CircularProgress size={25} />
          ) : (
            <>
              <AppCard>
                <GeneralSettingsTable
                  generalSettingsData={data}
                  page={page}
                  pageNumber={pageNumber}
                  totalCount={totalCount}
                  loadingTable={loading}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  hasMoreData={hasMore}
                  setRowsPerPage={setRowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  refreshPageData={refreshPageData}
                  onDeleteGroupInformationListStart={
                    onDeleteGroupInformationListStart
                  }
                  currentUser={currentUser}
                  onChangeNotificationOnOrOffStart={
                    onChangeNotificationOnOrOffStart
                  }
                  onDeleteNotificationStart={onDeleteNotificationStart}
                  setSelectedObj={setSelectedObj}
                  selectedObj={selectedObj}
                  value={'2'}
                />
                <Box style={{ height: 30 }} />
              </AppCard>
            </>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default container(Group)
