import { axiosRequestCMS } from 'utils'

export const fetchConstantList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/constantValue/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchConstantById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/constantValue/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createConstant = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/constantValue/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteConstant = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/constantValue/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editConstant = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/constantValue/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
