import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
const CountrySetup = React.lazy(() => import('./CountrySetup'))
const CountryWisePrefix = React.lazy(() => import('./CountryWisePrefix'))
const StateSetup = React.lazy(() => import('./StateSetup'))
const RegionSetup = React.lazy(() => import('./RegionSetup'))
const AreaSetup = React.lazy(() => import('./AreaSetup'))
const MerchantCategorySetup = React.lazy(
  () => import('./MerchantCategorySetup/')
)
const PostalCodes = React.lazy(
  () => import('./AreaSetup/components/PostalCode/')
)
const EmailTemplatesSetup = React.lazy(() => import('./EmailTemplatesSetup'))
const PageSetup = React.lazy(() => import('./PageSetup'))
const FriendshipFee = React.lazy(() => import('./FriendshipFee'))
const MembershipFee = React.lazy(() => import('./MembershipFee'))
const CountryInternalBeneficiaries = React.lazy(
  () => import('./CountryInternalBeneficiaries')
)

const MerchantFee = React.lazy(() => import('./MerchantFee'))
const MembershipPackage = React.lazy(() => import('./MembershipPackage'))

const TrainingVideosSetup = React.lazy(() => import('./TrainingVideosSetup'))
const TrainingSetup = React.lazy(() => import('./TrainingSetup'))
const CurrencySetup = React.lazy(() => import('./CurrencySetup'))

export const setupConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/countryprefix',
    element: <CountryWisePrefix />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/countrysetup',
    element: <CountrySetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/statesetup',
    element: <StateSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/regionsetup',
    element: <RegionSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/areasetup',
    element: <AreaSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/areasetup/postalcode/:id',
    element: <PostalCodes />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/merchantcategorysetup',
    element: <MerchantCategorySetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/emailtemplatessetup',
    element: <EmailTemplatesSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/pageSetup',
    element: <PageSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/friendshipbeneficiaries',
    element: <FriendshipFee />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/membershipbeneficiaries',
    element: <MembershipFee />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/countryinternalbeneficiaries',
    element: <CountryInternalBeneficiaries />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/merchanttransactionfee',
    element: <MerchantFee />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/piiinksandfee',
    element: <MembershipPackage />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/training-setup/:id',
    element: <TrainingVideosSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/training-setup/all',
    element: <TrainingVideosSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/training-setup',
    element: <TrainingSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/setup/currency-setup',
    element: <CurrencySetup />,
  },
]
