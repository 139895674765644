import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import DraftList from 'components/GlobalMerchantForm/DraftList/DraftList.component'
import EditMerchantRequest from 'components/GlobalMerchantForm/DraftList/components/EditMerchantRequest/EditMerchantRequest.component'
import MerchantList from 'components/GlobalMerchantForm/MerchantList'
import SendMerchantRequest from 'components/GlobalMerchantForm/SendMerchantRequest'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import AllNotificationList from 'components/AllNotificationList'
import ConnectStripe from 'components/Payments/ConnectStripe'

const CharitiesList = React.lazy(() => import('./Charities/CharitiesList'))
const ClubList = React.lazy(() => import('./Club/ClubList'))
const MembersList = React.lazy(() => import('./Members/MembersList'))

const MerchantCodes = React.lazy(
  () => import('components/PremiumCodes/MerchantCodesList')
)
const MemberCodes = React.lazy(
  () => import('components/PremiumCodes/MemberCodesList')
)

const ReferredCPList = React.lazy(
  () => import('./CollaborationPartners/ReferredCPList')
)
const TransactionQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/TransactionQrCode/QrCodes')
)

const IssuerQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/IssuerQrCode')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)
const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)
const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const FriendshipEarningAsCpSignerAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgnerAsSigner')
)
const MembershipEarningFromCpRefer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsCpSigner')
)
const MembershipEarningFromCpReferDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsCpSigner')
)
const MembershipEarningAsClubCharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsClubCharitySigner')
)
const MembershipEarningAsClubCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsClubCharitySigner')
)
const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)

const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)
const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))
const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)
const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)

const EditMerchantFromMerchantList = React.lazy(
  () => import('components/GlobalMerchantForm/EditMerchantRequest')
)

const MerchantActivityReports = React.lazy(
  () => import('components/GlobalMerchantForm/MerchantActivityReports')
)

const MembersInfoList = React.lazy(
  () => import('components/GlobalMerchantForm/MembersList')
)

export const salesAgentConfig = [
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ],
    path: '/salesAgent/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/charitieslist',
    element: <CharitiesList />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/clublist',
    element: <ClubList />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/referredcplist',
    element: <ReferredCPList />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/memberslist',
    element: <MembersList />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/premium-merchant-codes',
    element: <MerchantCodes />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/premium-member-codes',
    element: <MemberCodes />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/referredcplist',
    element: <ReferredCPList />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'salesAgent'} />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'salesAgent'} />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/editMerchantRequest/:id',
    element: <EditMerchantRequest adminRoute={'salesAgent'} />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/merchantList',
    element: <MerchantList adminRoute={'salesAgent'} />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/draftList',
    element: <DraftList adminRoute={'salesAgent'} />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/transaction-qr-codes',
    element: <TransactionQrCodes />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesAgent/issuer-qr-codes',
    element: <IssuerQrCodes />,
  },
  // issuer
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),

    path: '/salesagent/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },
  // merchant signer
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },
  // cp signer
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/friendship-earning/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),

    path: '/salesagent/friendship-earning/ascpsigner/detail',
    element: <FriendshipEarningAsCpSignerDetail />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/friendship-earning/ascpsigner/asmerchantsigner/detail',
    element: <FriendshipEarningAsCpSignerAsMerchantSignerDetail />,
  },

  // issuer
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),

    path: '/salesagent/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),

    path: '/salesagent/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },
  // area allocation
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),

    path: '/salesagent/membership-earning/fromcprefer',
    element: <MembershipEarningFromCpRefer />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),

    path: '/salesagent/membership-earning/fromcprefer/detail',
    element: <MembershipEarningFromCpReferDetail />,
  },
  // club/charity
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),

    path: '/salesagent/membership-earning/asclubcharity',
    element: <MembershipEarningAsClubCharity />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),

    path: '/salesagent/membership-earning/asclubcharity/detail',
    element: <MembershipEarningAsClubCharityDetail />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'salesAgent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'salesAgent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/coupons',
    element: <Coupons adminRoute={'salesagent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/coupon-codes/:id',
    element: <CouponCodes />,
  },
  // {
  //   permittedRole: [
  //     RoutePermittedRole.collaborationPartner,
  //     RoutePermittedRole.salesAgent,
  //   ].join(', '),
  //   path: '/salesagent/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/setting/smtp',
    element: <SMTP adminRoute={'salesagent'} />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/setting/group',
    element: <Group adminRoute={'salesagent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/setting/contacts',
    element: <Contacts adminRoute={'salesagent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/setting/documents',
    element: <Documents adminRoute={'salesagent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/setting/emails',
    element: <Emails adminRoute={'salesagent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/setting/recipients',
    element: <Recipients adminRoute={'salesagent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/setting/reports/:id',
    element: <Reports adminRoute={'salesagent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/merchantList/editMerchant/:id',
    element: <EditMerchantFromMerchantList adminRoute={'salesagent'} />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/merchant-activity-reports',
    element: <MerchantActivityReports adminRoute={'salesagent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/member-list/:id',
    element: <MembersInfoList adminRoute={'salesagent'} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/all-notification',
    element: <AllNotificationList adminRoute={'salesagent'} />,
  },
  {
    permittedRole: [
      RoutePermittedRole.collaborationPartner,
      RoutePermittedRole.salesAgent,
    ].join(', '),
    path: '/salesagent/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'salesagent'} />,
  },
]
