/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { sendClubReqStart } from 'store/club/clubSlice'
import {
  fetchMerchantPackageStart,
  fetchLastMerchantPackageLogByMerchantIdStart,
} from 'store/merchantPackage/merchantPackageSlice'
import {
  fetchMerchantGeneralBankInfoStart,
  checkApplyMerchantPremiumCodeStart,
  clearMerchantPremiumCodeData,
} from 'store/merchant/merchantSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
  merchantPackage: state.merchantPackage,
  merchant: state.merchant,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  onFetchMerchantPackageStart: (payload) =>
    dispatch(fetchMerchantPackageStart(payload)),
  onFetchMerchantGeneralBankInfoStart: (payload) =>
    dispatch(fetchMerchantGeneralBankInfoStart(payload)),
  onCheckApplyMerchantPremiumCodeStart: (payload) =>
    dispatch(checkApplyMerchantPremiumCodeStart(payload)),
  clearMerchantPremiumCodeData: () => dispatch(clearMerchantPremiumCodeData()),
  onFetchLastMerchantPackageLogByMerchantIdStart: (payload) =>
    dispatch(fetchLastMerchantPackageLogByMerchantIdStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
