import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'

import container from './WebsiteDetails.container'

interface AddNewCountryProps {
  isAddCountryOpen?: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry?: () => void
  onCreateClubStart?: (params) => void
  onCreateStep2DraftStart?: (params) => void
  onCreateStep2GeneralStart?: (params) => void
  onFetchMerchantGeneralWebsiteInfoPanelUserStart?: (params) => void
  siteCoordinator?: any
  handleChangeTab?: any
  merchant?: any
  club?: any
  auth?: any
  merchantId?: number
  adminRoute?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  onFetchMerchantGeneralWebsiteInfoPanelUserStart,
  merchantId,
  merchant: { generalWebsiteInfo },
}) => {
  useEffect(() => {
    onFetchMerchantGeneralWebsiteInfoPanelUserStart(merchantId)
  }, [merchantId])

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4}>
        {/* <Grid item xs={12}>
          <Typography variant="h4">Website Specific Details</Typography>
        </Grid> */}

        <Grid item xs={12} lg={3}>
          <Typography variant="h5">Website Link</Typography>
          <Typography variant="body2">
            {generalWebsiteInfo?.websiteLink ?? '-'}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Typography variant="h5">Instagram Link</Typography>
          <Typography variant="body2">
            {generalWebsiteInfo?.instagramLink ?? '-'}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Typography variant="h5">Facebook Link</Typography>
          <Typography variant="body2">
            {generalWebsiteInfo?.facebookLink ?? '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={4} sx={{ mt: 3 }}>
        <Grid item xs={12} lg={3}>
          <Typography variant="h5">Business Opening Hours</Typography>
          <Typography variant="body2">
            {generalWebsiteInfo?.openingHourInfo ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Merchant Bio</Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: generalWebsiteInfo?.merchantDescription ?? '-',
            }}
          />
        </Grid>
      </Grid>

      <Box sx={{ height: 20 }} />
      <Box sx={{ height: 30 }} />
    </Box>
  )
}

export default container(AddNewClub)
