import { axiosRequestCMS } from 'utils'

export const fetchTestamonialList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/testimonial/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTestamonialById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/testimonial/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createTestamonial = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/testimonial/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteTestamonial = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/testimonial/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editTestamonial = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/testimonial/edit/${id}?isTranslateToAlllang=${params.isTranslateToAlllang}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
