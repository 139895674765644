import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import DraftList from 'components/GlobalMerchantForm/DraftList/DraftList.component'
import EditMerchantRequest from 'components/GlobalMerchantForm/DraftList/components/EditMerchantRequest/EditMerchantRequest.component'
import MerchantList from 'components/GlobalMerchantForm/MerchantList'
import StateMerchantList from 'components/GlobalMerchantForm/StateMerchantList'
import SendMerchantRequest from 'components/GlobalMerchantForm/SendMerchantRequest'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import Agreement from './Agreement'
import ConnectStripe from 'components/Payments/ConnectStripe'

const CharitiesList = React.lazy(() => import('./Charities/CharitiesList'))
const ClubList = React.lazy(() => import('./Club/ClubList'))
const MembersList = React.lazy(() => import('./Members/MembersList'))
const StateMembersList = React.lazy(
  () => import('./LocationBasedUsers/StateMembersList')
)
const ReferredCPList = React.lazy(
  () => import('./CollaborationPartners/ReferredCPList')
)
const TransactionQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/TransactionQrCode/QrCodes')
)

const IssuerQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/IssuerQrCode')
)

const FriendshipEarningAsStateOwner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsStateOwner')
)
const FriendshipEarningAsStateOwnerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsStateOwner')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)
const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)
const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const FriendshipEarningAsCpSignerAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgnerAsSigner')
)
const MembershipEarningFromStateAllocation = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromStateAllocation')
)
const MembershipEarningFromStateAllocationDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromStateAllocation')
)
const MembershipEarningAsClubCharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsClubCharitySigner')
)
const MembershipEarningAsClubCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsClubCharitySigner')
)
const MembershipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsCpSigner')
)
const MembershipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsCpSigner')
)
const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)
const MerchantPackagePayable = React.lazy(
  () => import('./MerchantPackage/Payable')
)

const MerchantCodesList = React.lazy(
  () => import('components/PremiumCodes/MerchantCodesList')
)
const MemberCodesList = React.lazy(
  () => import('components/PremiumCodes/MemberCodesList')
)

const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)

const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))
const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)

const StateCharitiesList = React.lazy(
  () => import('./LocationBasedUsers/StateCharitiesList')
)

const StateClubList = React.lazy(
  () => import('./LocationBasedUsers/StateClubList')
)

const StateCollaborationList = React.lazy(
  () => import('./LocationBasedUsers/StateCollaborationList')
)

const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)

const EditMerchantFromMerchantList = React.lazy(
  () => import('components/GlobalMerchantForm/EditMerchantRequest')
)

const MerchantActivityReports = React.lazy(
  () => import('components/GlobalMerchantForm/MerchantActivityReports')
)

const MembersInfoList = React.lazy(
  () => import('components/GlobalMerchantForm/MembersList')
)

const AllNotificationList = React.lazy(
  () => import('components/AllNotificationList')
)

export const stateAdminConfig = [
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/premium-merchant-codes',
    element: <MerchantCodesList />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/premium-member-codes',
    element: <MemberCodesList />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/merchant-package/payable',
    element: <MerchantPackagePayable />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/charitieslist',
    element: <CharitiesList />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/clublist',
    element: <ClubList />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/memberslist',
    element: <MembersList />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/state-members-list',
    element: <StateMembersList />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/referredcplist',
    element: <ReferredCPList />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/editMerchantRequest/:id',
    element: <EditMerchantRequest adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/merchantList',
    element: <MerchantList adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/stateMerchantList',
    element: <StateMerchantList adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/draftList',
    element: <DraftList adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/transaction-qr-codes',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/issuer-qr-codes',
    element: <IssuerQrCodes />,
  },
  // state owner
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/friendship-earning/asstateowner',
    element: <FriendshipEarningAsStateOwner />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/friendship-earning/asstateowner/detail',
    element: <FriendshipEarningAsStateOwnerDetail />,
  },
  // issuer
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },
  // merchant signer
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },
  // cp signer
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/friendship-earning/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/friendship-earning/ascpsigner/detail',
    element: <FriendshipEarningAsCpSignerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/friendship-earning/ascpsigner/asmerchantsigner/detail',
    element: <FriendshipEarningAsCpSignerAsMerchantSignerDetail />,
  },

  // issuer
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },
  // state allocation
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/membership-earning/fromstateallocation',
    element: <MembershipEarningFromStateAllocation />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/membership-earning/fromstateallocation/detail',
    element: <MembershipEarningFromStateAllocationDetail />,
  },
  // club/charity
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/membership-earning/asclubcharity',
    element: <MembershipEarningAsClubCharity />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/membership-earning/asclubcharity/detail',
    element: <MembershipEarningAsClubCharityDetail />,
  },
  // cp signer
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/membership-earning/ascpsigner',
    element: <MembershipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/membership-earning/ascpsigner/detail',
    element: <MembershipEarningAsCpSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },
  // {
  //   permittedRole: RoutePermittedRole.stateOwner,
  //   path: '/state-licensee/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/coupons',
    element: <Coupons adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/coupon-codes/:id',
    element: <CouponCodes />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/agreement',
    element: <Agreement />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/setting/smtp',
    element: <SMTP adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/setting/group',
    element: <Group adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/setting/contacts',
    element: <Contacts adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/setting/documents',
    element: <Documents adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/setting/emails',
    element: <Emails adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/setting/recipients',
    element: <Recipients adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/state-charities-list',
    element: <StateCharitiesList adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/state-club-list',
    element: <StateClubList adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/state-collaboration-list',
    element: <StateCollaborationList adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/setting/reports/:id',
    element: <Reports adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/merchantList/editMerchant/:id',
    element: <EditMerchantFromMerchantList adminRoute={'state-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/merchant-activity-reports',
    element: <MerchantActivityReports adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/member-list/:id',
    element: <MembersInfoList adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/all-notification',
    element: <AllNotificationList adminRoute={'state-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.stateOwner,
    path: '/state-licensee/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'state-licensee'} />,
  },
]
