import { axiosRequest } from 'utils'

// friendship earnings
export const fetchFriendshipEarning = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/friendship/summary/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsIssuer = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsIssuerTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/issuer/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsIssuerTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/issuer/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsIssuerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsIssuerListTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/issuer/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsIssuerListTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/issuer/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSupposedFriendshipEarningAsIssuerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/supporterMerchant/getDetail`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSupposedMembershipEarningAsIssuerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/supporterMerchant/getMembershipDetail`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryRevenueFromFriendshipSummary = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/countryInternalAllocationFriendshipSummary`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryRevenueFromFriendshipDetail = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/countryInternalAllocationFriendship`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryRevenueFromMembershipSummary = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/countryInternalAllocationMembershipSummary`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryRevenueFromMembershipDetail = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/countryInternalAllocationMembership`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

//for country

export const fetchCountrySpecificFriendshipEarningAsIssuer = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/friendship/summary/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificFriendshipEarningAsIssuerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/friendship/getByCountry`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsMerchantSigner = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkMerchantSigner/friendship/summary/getByPanel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsMerchantSignerTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/friendship/merchantSigner/summary/getByPanel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsMerchantSignerTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/friendship/merchantSigner/summary/getByPanel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsMerchantSignerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkMerchantSigner/friendship/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsMerchantSignerListTypeNormal = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/merchantSigner/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsMerchantSignerListTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/merchantSigner/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSigner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerAsSigner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerAsSignerTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerAsSignerTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerAsSignerDetail = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerAsSignerDetailTypeNormal = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerAsSignerDetailTypeBF = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerListTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerListTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsStateOwner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsStateOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsRegionOwner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsRegionOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsAreaOwner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsAreaOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsCharity = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charity/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsCharityList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charity/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsPiiinkIssuer = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsPiiinkEntities = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/friendshipPiiinkPartiesSummary`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsPiiinkEntities = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/dashboard/globalAdmin/selectCountry/membershipPiiinkPartiesSummary`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsPiiinkEntitiesDetail = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/selectCountry/friendshipPiiinkParties`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsPiiinkEntitiesDetail = (params) => {
  return () =>
    axiosRequest
      .get(`/api/dashboard/globalAdmin/selectCountry/membershipPiiinkParties`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsPiiinkIssuerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/friendship/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsPiiinkRegionOwner = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/friendship/summary/getByPanel/asPiiinkRegion`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsPiiinkRegionOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/friendship/getByPanel/asPiiinkRegion`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningFromCountryPoolAsCountryOwnerList = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/countryOwner/friendship/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsPiiinkAreaOwner = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/friendship/summary/getByPanel/asPiiinkArea`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsPiiinkAreaOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/friendship/getByPanel/asPiiinkArea`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsPiiinkCharity = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charity/friendship/summary/getByPanel/asPiiinkCharity`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsPiiinkCharityList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charity/friendship/getByPanel/asPiiinkCharity`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsPiiinkCharityForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charity/friendship/summary/getByCountry/asPiiinkCharity/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsPiiinkCharityListForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/charity/friendship/getByCountry/asPiiinkCharity/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
export const fetchFriendshipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/friendship/summary/getByCountry/asPiiinkRegion/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchFriendshipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/friendship/getByCountry/asPiiinkRegion/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
export const fetchFriendshipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/friendship/summary/getByCountry/asPiiinkArea/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
export const fetchFriendshipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/friendship/getByCountry/asPiiinkArea/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchFriendshipEarningAsIssuerForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkIssuer/friendship/summary/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsIssuerListForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkIssuer/friendship/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsIssuerForGlobalAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/friendship/summary/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsIssuerListForGlobalAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/friendship/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsMerchantSignerForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkMerchantSigner/friendship/summary/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsMerchantSignerListForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkMerchantSigner/friendship/getByCountry/asGlobalAdmin/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchFriendshipEarningAsMerchantSignerForGlobalAdmin = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkMerchantSigner/friendship/summary/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsMerchantSignerListForGlobalAdmin = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkMerchantSigner/friendship/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/cpSigner/signer/friendship/summary/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerForGlobalAdminWithCountry2 = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/cpSigner/issuer/friendship/summary/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerForGlobalAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/summary/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerAsSignerForGlobalAdmin = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/summary/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/cpSigner/signer/friendship/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerListForGlobalAdminWithCountry2 = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/cpSigner/issuer/friendship/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsCpSignerListForGlobalAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsCpSignerAsSignerListForGlobalAdmin = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningFromCountryPoolAsCountryOwnerDetail = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/countryOwner/friendship/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// membership earnings
export const fetchMembershipEarning = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningAsIssuer = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsIssuerTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/membership/issuer/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsIssuerTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/membership/issuer/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsIssuerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsIssuerListTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/membership/issuer/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsIssuerListTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/membership/issuer/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsClubCharity = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charityOrClubSigner/membership/summary/getByPanel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsClubCharityTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charityOrClubSigner/membership/summary/getByPanel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsClubCharityListTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charityOrClubSigner/membership/summary/getByPanel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningFromStateAllocation = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsClubCharityDetails = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charityOrClubSigner/membership/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsClubCharityDetailsTypeNormal = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charityOrClubSigner/membership/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsClubCharityDetailsTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charityOrClubSigner/membership/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromStateAllocationList = (params) => {
  return () =>
    axiosRequest
      .get(`api/transaction/piiinkOwner/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromRegionAllocation = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromRegionAllocationList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromAreaAllocation = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningFromAreaAllocationList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningFromCharityAllocation = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charity/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningFromCharityAllocationList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charity/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningFromCpRefer = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromCpReferTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromCpReferTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromCpReferList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromCpReferListTypeNormal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromCpReferListTypeBF = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsPiiinkIssuer = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsPiiinkIssuerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/membership/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsPiiinkRegionOwner = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/membership/summary/getByPanel/asPiiinkRegion`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningAsPiiinkRegionOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/membership/getByPanel/asPiiinkRegion`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromCountryPoolAsCountryOwnerList = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/countryOwner/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningAsPiiinkAreaOwner = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/membership/summary/getByPanel/asPiiinkArea`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningAsPiiinkAreaOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkOwner/membership/getByPanel/asPiiinkArea`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningAsPiiinkCharity = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charity/membership/summary/getByPanel/asPiiinkCharity`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsPiiinkCharityList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charity/membership/getByPanel/asPiiinkCharity`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningFromCountryPoolAsCountryOwnerDetail = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/countryOwner/membership/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// couintry specific friendship
export const fetchCountrySpecificFriendshipEarningTotal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/friendship/summary/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificFriendshipEarningTotalList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/friendship/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// coiuntry specific membership
export const fetchCountrySpecificMembershipEarningTotal = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/membership/summary/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificMembershipEarningTotalList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/membership/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificMembershipEarningFromCountryPoolAsCountryOwnerList =
  (params) => {
    return () =>
      axiosRequest
        .get(`/api/transaction/countryOwner/membership/getByCountry`, {
          params,
        })
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchCountrySpecificMembershipEarningFromCountryPoolAsCountryOwnerDetail =
  (params) => {
    return () =>
      axiosRequest
        .get(`/api/transaction/countryOwner/membership/summary/getByCountry`, {
          params,
        })
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchCountrySpecificFriendshipEarningAsCpSignerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
//afasdaf
export const fetchCountrySpecificFriendshipEarningAsCpSigner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/summary/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchCountrySpecificFriendshipEarningAsCpSignerAsSignerDetail = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
//afasdaf
export const fetchCountrySpecificFriendshipEarningAsCpSignerAsMerchantSigner = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/summary/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificMembershipEarningAsIssuer = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/membership/summary/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificMembershipEarningAsIssuerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/membership/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificMembershipEarningAsClubCharity = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charityOrClubSigner/membership/summary/getByCountry`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificMembershipEarningAsClubCharityList = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charityOrClubSigner/membership/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificMembershipEarningAsCpSigner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/summary/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificMembershipEarningAsCpSignerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsPiiinkCharityForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charity/membership/summary/getByCountry/asPiiinkCharity/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificFriendshipEarningAsMerchantSigner = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkMerchantSigner/friendship/getByCountry`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsPiiinkCharityListForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/charity/membership/getByCountry/asPiiinkCharity/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
export const fetchMembershipEarningAsPiiinkRegionOwnerForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/membership/summary/getByCountry/asPiiinkRegion/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
export const fetchMembershipEarningAsPiiinkRegionOwnerListForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/membership/getByCountry/asPiiinkRegion/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
export const fetchMembershipEarningAsPiiinkAreaOwnerForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/membership/summary/getByCountry/asPiiinkArea/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
export const fetchMembershipEarningAsPiiinkAreaOwnerListForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/membership/getByCountry/asPiiinkArea/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchMembershipEarningAsIssuerForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkIssuer/membership/summary/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsIssuerListForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkIssuer/membership/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningAsIssuerForGlobalAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/membership/summary/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsIssuerListForGlobalAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/piiinkIssuer/membership/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountrySpecificFriendshipEarningSummaryAsMerchantSigner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkMerchantSigner/friendship/summary/getByCountry`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsClubCharitySignerForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/charityOrClubSigner/membership/summary/getByCountry/asGlobalAdmin/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
export const fetchMembershipEarningAsClubCharitySignerListForGlobalAdminWithCountry =
  (countryId, params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/charityOrClubSigner/membership/getByCountry/asGlobalAdmin/${countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
export const fetchMembershipEarningAsClubCharitySignerForGlobalAdmin = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charityOrClubSigner/membership/summary/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningAsClubCharitySignerListForGlobalAdmin = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/charityOrClubSigner/membership/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsCpSignerForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/cpSigner/membership/summary/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsCpSignerForGlobalAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/summary/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsCpSignerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsCpSignerListForGlobalAdminWithCountry = (
  countryId,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/cpSigner/membership/getByCountry/asGlobalAdmin/${countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsCpSignerListForGlobalAdmin = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembershipEarningAsCpSigner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// white label specific===============================================
// friendship------------------------------------------------------------

export const fetchFriendshipEarningAsIssuerForWhitelabel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/issuer/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsIssuerListForWhitelabel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/issuer/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsMerchantSignerForWhitelabel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/merchantSigner/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningAsMerchantSignerForWhitelabelSummary = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/friendship/merchantSigner/summary/getByPanel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchFriendshipEarningAsMerchantSignerListForWhitelabel = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/friendship/merchantSigner/summary/getByPanel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningTotal
export const fetchWhitelabelSpecificFriendshipEarningTotal = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/friendship/total/summary/getByWhiteLabel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningTotalList
export const fetchWhitelabelSpecificFriendshipEarningTotalList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/total/getByWhiteLabel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningAsIssuer
export const fetchWhitelabelSpecificFriendshipEarningAsIssuer = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/friendship/issuer/summary/getByWhiteLabel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningAsIssuerList
export const fetchWhitelabelSpecificFriendshipEarningAsIssuerList = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/friendship/issuer/getByWhiteLabel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningAsMerchantSigner
export const fetchWhitelabelSpecificFriendshipEarningAsMerchantSigner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/friendship/merchantSigner/summary/getByWhiteLabel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningAsMerchantSignerList
export const fetchWhitelabelSpecificFriendshipEarningAsMerchantSignerList = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/friendship/merchantSigner/getByWhiteLabel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningAsCpSignerAsIssuer
export const fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuer = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/cpSigner/issuer/friendship/summary/getByWhiteLabel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList
export const fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsIssuerList = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/issuer/friendship/getByWhiteLabel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningAsCpSignerAsSigner
export const fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSigner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/cpSigner/signer/friendship/summary/getByWhiteLabel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificFriendshipEarningAsCpSignerAsSignerList
export const fetchWhitelabelSpecificFriendshipEarningAsCpSignerAsSignerList = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/signer/friendship/getByWhiteLabel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// membership------------------------------------------------------------
export const fetchMembershipEarningAsIssuerForWhitelabel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/membership/issuer/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipEarningAsIssuerListForWhitelabel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/membership/issuer/summary/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificMembershipEarningTotal
export const fetchWhitelabelSpecificMembershipEarningTotal = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/membership/total/summary/getByWhiteLabel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificMembershipEarningTotalList
export const fetchWhitelabelSpecificMembershipEarningTotalList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/membership/total/getByWhiteLabel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificMembershipEarningAsIssuer
export const fetchWhitelabelSpecificMembershipEarningAsIssuer = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/whiteLabel/membership/issuer/summary/getByWhiteLabel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificMembershipEarningAsIssuerList
export const fetchWhitelabelSpecificMembershipEarningAsIssuerList = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/whiteLabel/membership/issuer/getByWhiteLabel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificMembershipEarningAsClubCharitySigner
export const fetchWhitelabelSpecificMembershipEarningAsClubCharitySigner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        // `/api/transaction/whiteLabel/membership/clubCharity/summary/getByWhiteLabel`,
        `/api/transaction/charityOrClubSigner/membership/summary/getByWhiteLabel`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificMembershipEarningAsClubCharitySignerList
export const fetchWhitelabelSpecificMembershipEarningAsClubCharitySignerList = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        // `/api/transaction/whiteLabel/membership/clubCharity/getByWhiteLabel`,
        `/api/transaction/charityOrClubSigner/membership/getByWhiteLabel`,

        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificMembershipEarningAsCpSigner
export const fetchWhitelabelSpecificMembershipEarningAsCpSigner = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/summary/getByWhiteLabel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

// whitelabelSpecificMembershipEarningAsCpSignerList
export const fetchWhitelabelSpecificMembershipEarningAsCpSignerList = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/getByWhiteLabel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerApi = (
  params
) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/cpSigner/membership/getByWhiteLabel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuerDetail =
  (params) => {
    return () =>
      axiosRequest
        .get(`/api/transaction/cpCountryPool/issuer/friendship/getByPanel`, {
          params,
        })
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsMerchantSignerDetail =
  (params) => {
    return () =>
      axiosRequest
        .get(`/api/transaction/cpCountryPool/signer/friendship/getByPanel`, {
          params,
        })
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerDetail =
  (params) => {
    return () =>
      axiosRequest
        .get(`/api/transaction/cpCountryPool/membership/getByPanel`, {
          params,
        })
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer =
  (params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/cpCountryPool/issuer/friendship/summary/getByPanel`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchFriendshipEarningFromCountryOrGlobalPoolAsCountryOwnerSummaryAsMerchant =
  (params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/cpCountryPool/signer/friendship/summary/getByPanel`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchMembershipEarningFromCountryOrGlobalPoolAsCountryOwnerAsIssuer =
  (params) => {
    return () =>
      axiosRequest
        .get(`/api/transaction/cpCountryPool/membership/summary/getByPanel`, {
          params,
        })
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchMemberPremiumCodeTransaction = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/memberPremiumCode/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMemberPremiumCodeTransactionDetail = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/memberPremiumCode/getByPanel`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTransactionFriendshipByTerminalUser = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/friendship/getByTerminalUSer`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCharity = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charity/friendship/summary/getByCountry/${params?.countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountryDetailFriendshipEarningAsCharity = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charity/Friendship/getByCountry/${params?.countryId}`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountrySummaryFriendshipEarningAsAreaOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/friendship/summary/getByCountry/areaOwner/${params?.countryId}`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountryDetailFriendshipEarningAsAreaOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/friendship/getByCountry/areaOwner/${params?.countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountrySummaryFriendshipEarningAsRegionOwner =
  (params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/friendship/summary/getByCountry/regionOwner/${params?.countryId}`,
          { params }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchGlobalAdminWithCountryDetailFriendshipEarningAsRegionOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/friendship/getByCountry/regionOwner/${params?.countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountrySummaryFriendshipEarningAsStateOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/friendship/summary/getByCountry/stateOwner/${params?.countryId}`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountryDetailFriendshipEarningAsStateOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/friendship/getByCountry/stateOwner/${params?.countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountrySummaryFriendshipEarningAsCountryOwner =
  (params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/countryOwner/friendship/summary/getByCountry/${params?.countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchGlobalAdminWithCountryDetailFriendshipEarningAsCountryOwner =
  (params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/countryOwner/friendship/getByCountry/${params?.countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

///membership section begins

export const fetchGlobalAdminWithCountrySummaryMembershipEarningAsCharity = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charity/membership/summary/getByCountry/${params?.countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountryDetailMembershipEarningAsCharity = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/charity/membership/getByCountry/${params?.countryId}`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountrySummaryMembershipEarningAsAreaOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/membership/summary/getByCountry/areaOwner/${params?.countryId}`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountryDetailMembershipEarningAsAreaOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/membership/getByCountry/areaOwner/${params?.countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountrySummaryMembershipEarningAsRegionOwner =
  (params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/piiinkOwner/membership/summary/getByCountry/regionOwner/${params?.countryId}`,
          { params }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchGlobalAdminWithCountryDetailMembershipEarningAsRegionOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/membership/getByCountry/regionOwner/${params?.countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountrySummaryMembershipEarningAsStateOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/membership/summary/getByCountry/stateOwner/${params?.countryId}`,
        { params }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountryDetailMembershipEarningAsStateOwner = (
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/transaction/piiinkOwner/membership/getByCountry/stateOwner/${params?.countryId}`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalAdminWithCountrySummaryMembershipEarningAsCountryOwner =
  (params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/countryOwner/membership/summary/getByCountry/${params?.countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const fetchGlobalAdminWithCountryDetailMembershipEarningAsCountryOwner =
  (params) => {
    return () =>
      axiosRequest
        .get(
          `/api/transaction/countryOwner/membership/getByCountry/${params?.countryId}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }

export const cancelTransaction = (params) => {
  return () =>
    axiosRequest
      .post(`/api/transaction/refund/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
