import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'

const CPGlobalMembershipBeneficiary = React.lazy(
  () => import('./CPGlobalMembershipBeneficiary/')
)
const CPGlobalFriendshipBeneficiary = React.lazy(
  () => import('./CPGlobalFriendshipBeneficiary/')
)

export const CPBeneficiariesConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/cpglobalfriendshipbeneficiaries',
    element: <CPGlobalFriendshipBeneficiary />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/cpandwlfriendshipbeneficiaries',
    // element:
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/cpglobalmembershipbeneficiaries',
    element: <CPGlobalMembershipBeneficiary />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/cpandwlmembershipbeneficiaries',
    // element:
  },
]
