import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchTrainingVideoStart,
  fetchTrainingVideoSuccess,
  fetchTrainingVideoFailure,
  createTrainingVideoSuccess,
  createTrainingVideoFailure,
  deleteTrainingVideoSuccess,
  deleteTrainingVideoFailure,
  fetchTrainingVideoByIdSuccess,
  fetchTrainingVideoByIdFailure,
  editTrainingVideoSuccess,
  editTrainingVideoFailure,
  createTrainingVideoCategoryFailure,
  createTrainingVideoCategorySuccess,
  fetchTrainingVideoCategoryFailure,
  fetchTrainingVideoCategorySuccess,
  editTrainingVideoCategorySuccess,
  editTrainingVideoCategoryFailure,
  deleteTrainingVideoCategoryFailure,
  deleteTrainingVideoCategorySuccess,
  fetchTrainingVideoCategoryByIdSuccess,
  fetchTrainingVideoCategoryByIdFailure,
  fetchTrainingVideoCategoryAllByPanelSuccess,
  fetchTrainingVideoCategoryAllByPanelFailure,
} from './trainingVideosSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchTrainingVideoList,
  createTrainingVideo,
  fetchTrainingVideoById,
  editTrainingVideo,
  deleteTrainingVideo,
  fetchTrainingVideoListByPanel,
  createTrainingVideoCategory,
  fetchTrainingVideoCategoryList,
  editTrainingVideoCategory,
  deleteTrainingVideoCategory,
  fetchTrainingVideoCategoryById,
  fetchTrainingVideoCategoryListByPanel,
} from '../../api/trainingVideo'

import {
  deleteImage,
  getImageUrl,
  uploadImage,
  uploadFile,
  uploadPDF,
} from 'api/imageUpload'

import { getImageIdFromUrl, removeParam } from 'utils'
import _ from 'lodash'

export function* fetchTrainingVideoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTrainingVideoList(payload))

    yield put(fetchTrainingVideoSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTrainingVideoFailure(err))
  }
}

export function* fetchTrainingVideoByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTrainingVideoListByPanel(payload))

    yield put(fetchTrainingVideoSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTrainingVideoFailure(err))
  }
}

export function* fetchTrainingVideoByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTrainingVideoById(payload))

    yield put(fetchTrainingVideoByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTrainingVideoByIdFailure(err))
  }
}

export function* editTrainingVideoAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (payload?.params?.documentType === 'file') {
      if (!_.isEmpty(payload?.params?.documentUrl)) {
        let videoUrl = ''
        const initialImageUrl = yield call(getImageUrl())
        if (
          !_.isEmpty(initialImageUrl?.data) &&
          payload?.params?.documentUrl?.size
        ) {
          imageResponse = yield call(
            uploadPDF(initialImageUrl?.data?.url, payload?.params?.documentUrl)
          )

          videoUrl = removeParam(imageResponse.config.url)
        }

        const imageAwsId = getImageIdFromUrl(payload?.oldImage)

        const { data } = yield call(
          editTrainingVideo(payload?.params?.id, {
            ...payload?.params,
            videoUrl: payload?.params?.documentUrl?.size
              ? videoUrl
              : payload?.oldImage,
          })
        )

        if (imageAwsId && payload?.params?.documentUrl?.size) {
          yield call(deleteImage(getImageIdFromUrl(payload?.oldImage)))
        }

        yield put(editTrainingVideoSuccess(data?.data))

        yield payload.closeModal()
      }
    } else {
      const imageAwsId = getImageIdFromUrl(payload?.oldImage)

      if (imageAwsId && payload?.params?.documentUrl?.size) {
        yield call(deleteImage(getImageIdFromUrl(payload?.oldImage)))
      }

      const uploadData = _.isObject(payload?.params)
        ? { ...payload.params }
        : { ...payload.params, videoUrl: '' }

      const { data } = yield call(
        editTrainingVideo(payload?.params?.id, uploadData)
      )

      yield put(editTrainingVideoSuccess(data?.data))
      yield payload.closeModal()
    }
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    yield put(
      openAlert({
        message: 'Training document successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editTrainingVideoFailure(err))
    console.error(err)
  }
}

export function* createTrainingVideoAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (payload?.params?.documentType === 'file') {
      if (!_.isEmpty(payload?.params?.videoUrl)) {
        let videoUrl = ''

        const initialImageUrl = yield call(getImageUrl())
        if (!_.isEmpty(initialImageUrl?.data)) {
          imageResponse = yield call(
            uploadPDF(initialImageUrl?.data?.url, payload?.params?.videoUrl)
          )

          videoUrl = removeParam(imageResponse.config.url)
        }

        const { data } = yield call(
          createTrainingVideo({ ...payload.params, videoUrl: videoUrl })
        )

        yield put(createTrainingVideoSuccess(data?.data))

        yield payload.closeModal()
      }
    } else {
      const { data } = yield call(createTrainingVideo(payload?.params))
      yield put(createTrainingVideoSuccess(data?.data))

      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    yield put(
      openAlert({
        message: 'Training Document Successfully Created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createTrainingVideoFailure(err))
    console.error(err)
  }
}

export function* deleteTrainingVideoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteTrainingVideo(payload?.params?.id))

    if (payload?.documentType === 'file') {
      if (!_.isEmpty(payload?.oldImage)) {
        yield call(deleteImage(getImageIdFromUrl(payload?.oldImage)))
      }
    }

    yield put(deleteTrainingVideoSuccess(payload?.params?.id))

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Training video successfully deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteTrainingVideoFailure(err))
  }
}

export function* createTrainingVideoCategoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createTrainingVideoCategory(payload?.params))
    yield put(createTrainingVideoCategorySuccess(data?.data))
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'Folder Successfully Created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createTrainingVideoCategoryFailure(err))
    console.error(err)
  }
}

export function* fetchTrainingVideoCategoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTrainingVideoCategoryList(payload))

    yield put(fetchTrainingVideoCategorySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTrainingVideoCategoryFailure(err))
  }
}

export function* editTrainingVideoCategoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editTrainingVideoCategory(payload?.params?.id, payload?.params)
    )

    yield put(editTrainingVideoCategorySuccess(data?.data))
    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'Folder successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editTrainingVideoCategoryFailure(err))
    console.error(err)
  }
}

export function* deleteTrainingVideoCategoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      deleteTrainingVideoCategory(payload?.params?.id)
    )

    if (payload?.refreshPageData) {
      yield payload?.refreshPageData()
    }

    yield payload.closeModal()

    yield put(deleteTrainingVideoCategorySuccess(payload))

    yield put(
      openAlert({
        message: 'Folder successfully deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteTrainingVideoCategoryFailure(err))
  }
}

export function* fetchTrainingVideoCategoryByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTrainingVideoCategoryById(payload))

    yield put(fetchTrainingVideoCategoryByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTrainingVideoCategoryByIdFailure(err))
  }
}

export function* fetchTrainingVideoCategoryAllByPanelAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchTrainingVideoCategoryListByPanel(payload))

    yield put(fetchTrainingVideoCategoryAllByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTrainingVideoCategoryAllByPanelFailure(err))
  }
}

export function* watchFetchTrainingVideo() {
  yield takeLatest(
    'trainingVideoSetup/fetchTrainingVideoStart',
    fetchTrainingVideoAsync
  )
}

export function* watchFetchTrainingVideoByPanel() {
  yield takeLatest(
    'trainingVideoSetup/fetchTrainingVideoByPanelStart',
    fetchTrainingVideoByPanelAsync
  )
}

export function* watchCreateTrainingVideo() {
  yield takeLatest(
    'trainingVideoSetup/createTrainingVideoStart',
    createTrainingVideoAsync
  )
}

export function* watchDeleteTrainingVideo() {
  yield takeLatest(
    'trainingVideoSetup/deleteTrainingVideoStart',
    deleteTrainingVideoAsync
  )
}

export function* watchFetchTrainingVideoById() {
  yield takeLatest(
    'trainingVideoSetup/fetchTrainingVideoByIdStart',
    fetchTrainingVideoByIdAsync
  )
}

export function* watchEditTrainingVideo() {
  yield takeLatest(
    'trainingVideoSetup/editTrainingVideoStart',
    editTrainingVideoAsync
  )
}

export function* watchCreateTrainingVideoCategory() {
  yield takeLatest(
    'trainingVideoSetup/createTrainingVideoCategoryStart',
    createTrainingVideoCategoryAsync
  )
}

export function* watchFetchTrainingVideoCategory() {
  yield takeLatest(
    'trainingVideoSetup/fetchTrainingVideoCategoryStart',
    fetchTrainingVideoCategoryAsync
  )
}

export function* watchFetchTrainingVideoCategoryAllByPanel() {
  yield takeLatest(
    'trainingVideoSetup/fetchTrainingVideoCategoryAllByPanelStart',
    fetchTrainingVideoCategoryAllByPanelAsync
  )
}

export function* watchEditTrainingVideoCategory() {
  yield takeLatest(
    'trainingVideoSetup/editTrainingVideoCategoryStart',
    editTrainingVideoCategoryAsync
  )
}
export function* watchDeleteTrainingVideoCategory() {
  yield takeLatest(
    'trainingVideoSetup/deleteTrainingVideoCategoryStart',
    deleteTrainingVideoCategoryAsync
  )
}

export function* watchFetchTrainingVideoCategoryById() {
  yield takeLatest(
    'trainingVideoSetup/fetchTrainingVideoCategoryByIdStart',
    fetchTrainingVideoCategoryByIdAsync
  )
}

export function* trainingVideoSetupSagas() {
  yield all([
    call(watchFetchTrainingVideo),
    call(watchFetchTrainingVideoByPanel),
    call(watchCreateTrainingVideo),
    call(watchDeleteTrainingVideo),
    call(watchFetchTrainingVideoById),
    call(watchEditTrainingVideo),
    call(watchCreateTrainingVideoCategory),
    call(watchFetchTrainingVideoCategory),
    call(watchEditTrainingVideoCategory),
    call(watchDeleteTrainingVideoCategory),
    call(watchFetchTrainingVideoCategoryById),
    call(watchFetchTrainingVideoCategoryAllByPanel),
  ])
}
