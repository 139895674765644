import { fabClasses } from '@mui/material'
import { createSlice } from '@reduxjs/toolkit'
import { IMemberState } from 'store/interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IMemberState = {
  memberList: listObj,
  memberListByOwners: listObj,
  memberListByWhiteLabel: listObj,
  pendingMemberList: listObj,
  soleMember: {},
  error: null,
  loading: false,
  isEditing: false,
  isFetching: false,
  isCreating: false,
  isVerifing: false,
  isRejecting: false,
  hasMoreData: false,
}
export const memberSlice = createSlice({
  name: 'members',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMemberStart: (state: any, action) => {
      state.loading = true
    },
    fetchMemberSuccess: (state: any, action) => {
      state.memberList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchMemberFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMemberByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchMemberByPanelSuccess: (state: any, action) => {
      state.memberList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchMemberByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllMemberByOwnersStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllMemberByOwnersSuccess: (state: any, action) => {
      state.memberListByOwners = action.payload
      state.loading = false
    },
    fetchAllMemberByOwnersFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllMemberByWhiteLabelStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllMemberByWhiteLabelSuccess: (state: any, action) => {
      state.memberListByWhiteLabel = action.payload
      state.loading = false
    },
    fetchAllMemberByWhiteLabelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createMemberStart: (state: any, action) => {
      state.isCreating = true
    },
    createMemberSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createMemberFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchMemberByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMemberByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleMember = action.payload

      state.isFetching = false
    },
    fetchMemberByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editMemberStart: (state: any, action) => {
      state.isEditing = true
    },
    editMemberSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editMemberFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchMemberByMerchantIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchMemberByMerchantIdSuccess: (state: any, action) => {
      state.memberList = action.payload
      state.loading = false
    },
    fetchMemberByMerchantIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchMemberStart,
  fetchMemberSuccess,
  fetchMemberFailure,

  fetchMemberByPanelStart,
  fetchMemberByPanelSuccess,
  fetchMemberByPanelFailure,

  fetchMemberByMerchantIdStart,
  fetchMemberByMerchantIdSuccess,
  fetchMemberByMerchantIdFailure,

  createMemberStart,
  createMemberSuccess,
  createMemberFailure,
  fetchMemberByIdStart,
  fetchMemberByIdSuccess,
  fetchMemberByIdFailure,
  editMemberStart,
  editMemberSuccess,
  editMemberFailure,

  fetchAllMemberByOwnersStart,
  fetchAllMemberByOwnersSuccess,
  fetchAllMemberByOwnersFailure,

  fetchAllMemberByWhiteLabelStart,
  fetchAllMemberByWhiteLabelSuccess,
  fetchAllMemberByWhiteLabelFailure,
} = memberSlice.actions

export default memberSlice.reducer
