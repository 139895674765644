import React from 'react'
import { Delete, Edit } from '@mui/icons-material'
import {
  Box,
  Card,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

const PreviewSingle = ({
  file,
  onDelete,
  fileLabel,
  onChange,
}: {
  file: any
  onDelete?: () => void
  onChange?: (param) => void
  fileLabel?: string
}) => {
  return (
    <Card sx={{ p: 2, mt: 4, height: 1 }}>
      <Stack justifyContent="space-between" direction="row" sx={{ height: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={5}
          sx={{ height: 1 }}
        >
          <Box sx={{ maxWidth: 200, height: 1 }}>
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={file?.size ? URL.createObjectURL(file) : file}
            />
          </Box>
          <Box>
            <Typography variant="body1">{`${fileLabel}   ${
              file?.name ? '- ' + file?.name : ''
            }`}</Typography>
            <Typography variant="body2">
              {file?.size && `${Math.floor(file?.size / 1024)} KB`}
            </Typography>
          </Box>
        </Stack>
        <Box>
          {onChange && (
            <Tooltip title="Change">
              <IconButton component="label" size="small">
                <Edit />
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={onChange}
                />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title="Delete">
              <IconButton onClick={onDelete}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Stack>
    </Card>
  )
}

export default PreviewSingle
