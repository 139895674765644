/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { fetchPostStart } from 'store/post/postSlice'
import { sendClubReqStart } from 'store/club/clubSlice'
import {
  createMerchantTerminalStart,
  editMerchantTerminalStart,
  editTerminalStart,
  fetchOneMerchantTerminalStart,
  fetchTerminalByIdStart,
} from 'store/merchant/merchantSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
  merchant: state.merchant,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  onCreateMerchantTerminalStart: (payload) =>
    dispatch(createMerchantTerminalStart(payload)),
  onFetchTerminalByIdStart: (payload) =>
    dispatch(fetchTerminalByIdStart(payload)),
  onEditTerminalStart: (payload) => dispatch(editTerminalStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
