import React from 'react'
import { Grid } from '@mui/material'
import PreviewSingle from './PreviewSingle'

const ImagesPreview = ({
  files,
  setFiles,
}: {
  files: any[]
  setFiles: (param) => void
}) => {
  return (
    <Grid container sx={{}} spacing={5}>
      {files?.map((file, ind) => {
        return (
          <Grid item xs={12} md={6} lg={4} key={ind}>
            <PreviewSingle
              file={file}
              fileLabel={`Slider ${ind + 1} `}
              onDelete={
                ind >= 2
                  ? () =>
                      setFiles((fileList) =>
                        // fileList.filter((f) => f.name !== file.name)
                        fileList.filter((f) => f !== file)
                      )
                  : null
              }
              onChange={(e) => {
                setFiles((fileList) =>
                  fileList.map((f) =>
                    // f === file || f?.name === file?.name ? e.target.files[0] : f
                    f === file ? e.target.files[0] : f
                  )
                )
              }}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ImagesPreview
