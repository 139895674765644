import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchMembershipPackageStart,
  fetchMembershipPackageSuccess,
  fetchMembershipPackageFailure,
  createMembershipPackageSuccess,
  createMembershipPackageFailure,
  deleteMembershipPackageSuccess,
  deleteMembershipPackageFailure,
  fetchMembershipPackageByIdSuccess,
  fetchMembershipPackageByIdFailure,
  editMembershipPackageSuccess,
  editMembershipPackageFailure,
} from './membershipPackageSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchMembershipPackageList,
  createMembershipPackage,
  deleteMembershipPackage,
  fetchMembershipPackageById,
  editMembershipPackage,
} from '../../api/GlobalAdmin/membershipPackage'

import _ from 'lodash'
import { deleteImage, getImageUrl, uploadImage } from 'api/imageUpload'
import { getImageIdFromUrl, removeParam } from 'utils'

export function* fetchMembershipPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMembershipPackageList(payload))

    yield put(fetchMembershipPackageSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMembershipPackageFailure(err))
  }
}

export function* fetchMembershipPackageByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMembershipPackageById(payload))

    yield put(fetchMembershipPackageByIdSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMembershipPackageByIdFailure(err))
  }
}

export function* editMembershipPackageAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.imageUrl)) {
      let imageUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.imageUrl)
        )
        imageUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        editMembershipPackage(payload.params.id, {
          ...payload.params,
          boxBackgroundImageUrl: imageUrl,
        })
      )

      yield put(editMembershipPackageSuccess(data?.data))
    } else {
      const uploadData = _.isObject(payload?.imageUrl)
        ? { ...payload.params }
        : { ...payload.params, boxBackgroundImageUrl: null }
      const { data } = yield call(
        editMembershipPackage(payload.params.id, uploadData)
      )
      yield put(editMembershipPackageSuccess(data?.data))
    }

    if (payload?.oldImage) {
      yield call(deleteImage(getImageIdFromUrl(payload?.oldImage)))
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    // const { data } = yield call(editMembershipPackage(payload.id, payload))

    if (payload?.closeModal) {
      yield payload.closeModal()
    }
    yield put(
      openAlert({
        message: ' Piiinks and Fee succesfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editMembershipPackageFailure(err))
  }
}

export function* createMembershipPackageAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.imageUrl)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.imageUrl)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createMembershipPackage({
          ...payload.params,
          boxBackgroundImageUrl: logoUrl,
        })
      )
      yield put(createMembershipPackageSuccess(data?.data))
    } else {
      const { data } = yield call(createMembershipPackage(payload?.params))
      yield put(createMembershipPackageSuccess(data?.data?.club))
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchMembershipPackageStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }

    if (payload?.closeModal) {
      yield payload.closeModal()
    }

    yield put(
      openAlert({
        message: 'Piiinks and fee succesfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createMembershipPackageFailure(err))
  }
}

export function* deleteMembershipPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteMembershipPackage(payload))

    if (data) {
      yield put(deleteMembershipPackageSuccess(payload))
      yield put(
        openAlert({
          message: 'MembershipPackage deleted succesfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteMembershipPackageFailure(err))
  }
}

export function* watchFetchMembershipPackage() {
  yield takeLatest(
    'membershipPackage/fetchMembershipPackageStart',
    fetchMembershipPackageAsync
  )
}

export function* watchCreateMembershipPackage() {
  yield takeLatest(
    'membershipPackage/createMembershipPackageStart',
    createMembershipPackageAsync
  )
}

export function* watchDeleteMembershipPackage() {
  yield takeLatest(
    'membershipPackage/deleteMembershipPackageStart',
    deleteMembershipPackageAsync
  )
}

export function* watchFetchMembershipPackageById() {
  yield takeLatest(
    'membershipPackage/fetchMembershipPackageByIdStart',
    fetchMembershipPackageByIdAsync
  )
}

export function* watchEditMembershipPackage() {
  yield takeLatest(
    'membershipPackage/editMembershipPackageStart',
    editMembershipPackageAsync
  )
}

export function* membershipPackageSagas() {
  yield all([
    call(watchFetchMembershipPackage),
    call(watchCreateMembershipPackage),
    call(watchDeleteMembershipPackage),
    call(watchFetchMembershipPackageById),
    call(watchEditMembershipPackage),
  ])
}
