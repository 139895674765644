import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import Agreement from './Agreement'
import ReferredMerchantList from './Merchants/ReferredMerchantList'
import TemporaryPassword from 'pages/GlobalAdminDashboard/Settings/TemporaryPassword'
import AllNotificationList from 'components/AllNotificationList'
import ConnectStripe from 'components/Payments/ConnectStripe'

// const CountryAdmin = React.lazy(() => import("../../pages/CountryAdmin"));
const PendingAreaOwner = React.lazy(
  () => import('./AreaOwners/PendingAreaOwner')
)

const PendingStateOwner = React.lazy(
  () => import('./StateOwners/PendingStateOwner')
)

const PendingRegionOwner = React.lazy(
  () => import('./RegionOwners/PendingRegionOwner')
)

const PendingCharities = React.lazy(
  () => import('./Charities/PendingCharities')
)

const PendingSalesAgent = React.lazy(
  () => import('./SalesAgents/PendingSalesAgent')
)

const AddMerchantsGroup = React.lazy(
  () => import('./MerchantGroup/AddNewMerchantGroup')
)

const GroupMerchantList = React.lazy(
  () => import('./MerchantGroup/GroupMerchantList')
)

const PendingMerchantsRequest = React.lazy(
  () => import('./MerchantGroup/PendingRequest')
)

const Reviews = React.lazy(() => import('./Reviews'))

const PendingClub = React.lazy(() => import('./Club/PendingClub'))
const AreaOwnersList = React.lazy(() => import('./AreaOwners/AreaOwner'))
const StateOwnersList = React.lazy(() => import('./StateOwners/StateOwner'))
const RegionOwnersList = React.lazy(() => import('./RegionOwners/RegionOwner'))
const CollaborationPartnersList = React.lazy(
  () => import('./CollaborationPartners/CollaborationPartnersList')
)

const ReferredCollaborationPartnersList = React.lazy(
  () => import('./CollaborationPartners/ReferredPartnersList')
)

const ClubList = React.lazy(() => import('./Club/ClubList'))
const SalesAgentList = React.lazy(() => import('./SalesAgents/SalesAgentList'))

const CharitiesList = React.lazy(() => import('./Charities/CharitiesList'))
const MembersList = React.lazy(() => import('./Members/MembersList'))
const MerchantList = React.lazy(() => import('./Merchants/MerchantList'))
const PendingMerchantList = React.lazy(
  () => import('./Merchants/PendingMerchantList')
)

const MerchantActivityReports = React.lazy(
  () => import('components/GlobalMerchantForm/MerchantActivityReports')
)

const EditCountryAdminMerchant = React.lazy(
  () => import('./Merchants/MerchantList/components/EditMerchantRequest')
)

const EditCountryAdminPendingMerchant = React.lazy(
  () => import('./Merchants/PendingMerchantList/components/EditMerchantRequest')
)

const AddCountryAdminMerchant = React.lazy(
  () => import('./Merchants/MerchantList/components/AddNewMerchant')
)

const DraftMerchantList = React.lazy(() => import('./Merchants/DraftList'))

const EditCountryAdminDraftMerchant = React.lazy(
  () => import('./Merchants/DraftList/components/EditMerchantRequest')
)

const MerchantCodes = React.lazy(() => import('./PremiumCodes/MerchantCodes'))
const MemberCodes = React.lazy(() => import('./PremiumCodes/MemberCodes'))

const TransactionBatch = React.lazy(() => import('./QrCodes/TransactionQrCode'))
const TransactionQrCodes = React.lazy(
  () => import('./QrCodes/TransactionQrCode/QrCodes')
)
const TransactionQrCodesAll = React.lazy(
  () => import('./QrCodes/TransactionQrCode/components/SearchCodeView')
)
const IssuerQrBatch = React.lazy(() => import('./QrCodes/IssuerQrCode'))
const IssuerQrCodes = React.lazy(() => import('./QrCodes/IssuerQrCode/QrCodes'))
const IssuerQrCodesAll = React.lazy(
  () => import('./QrCodes/IssuerQrCode/components/SearchCodeView')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

const CountrySpecificFriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/CountrySpecificFriendship/SummaryPages/AsIssuer')
)

const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const CountrySpecificFriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/CountrySpecificFriendship/DetailsPages/AsIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)

const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const CountrySpecificMembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/CountrySpecificMembership/SummaryPages/AsIssuer')
)

const CountrySpecificMembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/CountrySpecificMembership/DetailsPages/AsIssuer')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)

const CountrySpecificFriendshipEarningAsMerchantSignerDetail = React.lazy(
  () =>
    import('./Earning/CountrySpecificFriendship/DetailsPages/AsMerchantSigner')
)

const CountrySpecificFriendshipEarningAsMerchantSigner = React.lazy(
  () =>
    import('./Earning/CountrySpecificFriendship/SummaryPages/AsMerchantSigner')
)

const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)
const FriendshipEarningAsCpSignerAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)

const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const FriendshipEarningAsCpSignerAsSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSignerAsSigner')
)

// CountrySpecificFriendshipEarningAsCpSignerSummary
const CountrySpecificFriendshipEarningAsCpSignerSummary = React.lazy(
  () => import('./Earning/CountrySpecificFriendship/SummaryPages/AsCpSigner')
)

const CountrySpecificFriendshipEarningAsCpSignerAsIssuerDetail = React.lazy(
  () => import('./Earning/CountrySpecificFriendship/DetailsPages/AsCpSIgner')
)

const CountrySpecificFriendshipEarningAsCpSignerAsSignerDetail = React.lazy(
  () =>
    import(
      './Earning/CountrySpecificFriendship/DetailsPages/AsCpSignerAsSigner'
    )
)

const MembershipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsCpSigner')
)
const MembershipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsCpSIgner')
)

const CountrySpecificMembershipEarningAsCpSigner = React.lazy(
  () => import('./Earning/CountrySpecificMembership/SummaryPages/AsCpSigner')
)
const CountrySpecificMembershipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/CountrySpecificMembership/DetailsPages/AsCpSIgner')
)

const FriendshipEarningFromCountryPool = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/FromCountryPool')
)

const FriendshipEarningFromCountryPoolDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/FromCountryPool')
)

const MembershipEarningFromCountryPool = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromCountryPool')
)

const MembershipEarningFromCountryPoolDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromCountryPool')
)

const MembershipEarningFromRegionAllocation = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromRegionAllocation')
)
const MembershipEarningFromRegionAllocationDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromRegionAllocation')
)
const MembershipEarningAsClubCharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsClubCharitySigner')
)
const MembershipEarningAsClubCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsClubCharitySigner')
)

const CountrySpecificMembershipEarningAsClubCharity = React.lazy(
  () =>
    import(
      './Earning/CountrySpecificMembership/SummaryPages/AsClubCharitySigner'
    )
)
const CountrySpecificMembershipEarningAsClubCharityDetail = React.lazy(
  () =>
    import(
      './Earning/CountrySpecificMembership/DetailsPages/AsClubCharitySigner'
    )
)

const CountrySpecificFriendshipEarningTotalTransactions = React.lazy(
  () =>
    import('./Earning/CountrySpecificFriendship/SummaryPages/TotalTransactions')
)

const CountrySpecificFriendshipEarningTotalTransactionsDetail = React.lazy(
  () =>
    import('./Earning/CountrySpecificFriendship/DetailsPages/TotalTransactions')
)

const CountrySpecificMembershipEarningTotalTransactions = React.lazy(
  () =>
    import('./Earning/CountrySpecificMembership/SummaryPages/TotalTransactions')
)

const CountrySpecificMembershipEarningTotalTransactionsDetail = React.lazy(
  () =>
    import('./Earning/CountrySpecificMembership/DetailsPages/TotalTransactions')
)

const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)
const MerchantPackagePayable = React.lazy(
  () => import('./MerchantPackage/Payable')
)

const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)

const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))
const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)

const MembersInfoList = React.lazy(
  () => import('components/GlobalMerchantForm/MembersList')
)

const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)

export const countryAdminConfig = [
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/merchant-package/payable',
    element: <MerchantPackagePayable />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/pendingareaowner',
    element: <PendingAreaOwner />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/areaownerlist',
    element: <AreaOwnersList />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/pendingstateowner',
    element: <PendingStateOwner />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/stateownerlist',
    element: <StateOwnersList />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/MerchantGroup/AddNewMerchantGroup',
    element: <AddMerchantsGroup />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/MerchantGroup/groupmerchantlist',
    element: <GroupMerchantList />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/MerchantGroup/PendingRequest',
    element: <PendingMerchantsRequest />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/regionownerlist',
    element: <RegionOwnersList />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/pendingregionowner',
    element: <PendingRegionOwner />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/collaborationpartnerlist',
    element: <CollaborationPartnersList />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/referredpartnerlist',
    element: <ReferredCollaborationPartnersList />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/pendingcharities',
    element: <PendingCharities />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/charitieslist',
    element: <CharitiesList />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/pendingClub',
    element: <PendingClub />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/clubList',
    element: <ClubList />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/pendingSalesAgent',
    element: <PendingSalesAgent />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/salesAgentList',
    element: <SalesAgentList />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membersList',
    element: <MembersList />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/merchantList',
    element: <MerchantList />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/pendingMerchantList',
    element: <PendingMerchantList />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/draftList',
    element: <DraftMerchantList />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/draftList/editMerchant/:id',
    element: <EditCountryAdminDraftMerchant />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/merchantList/editMerchant/:id',
    element: <EditCountryAdminMerchant />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/pendingList/editMerchant/:id',
    element: <EditCountryAdminPendingMerchant />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/addMerchant',
    element: <AddCountryAdminMerchant />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/addMerchant/:id',
    element: <AddCountryAdminMerchant />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/premium-merchant-codes',
    element: <MerchantCodes />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/premium-member-codes',
    element: <MemberCodes />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/transaction-qr-codes',
    element: <TransactionBatch />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/transaction-qr-codes/:id',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/transaction-qr-codes/all',
    element: <TransactionQrCodesAll />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/issuer-qr-codes',
    element: <IssuerQrBatch />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/issuer-qr-codes/:id',
    element: <IssuerQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/issuer-qr-codes/all',
    element: <IssuerQrCodesAll />,
  },

  // issuer
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/country/asissuer',
    element: <CountrySpecificFriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/country/asissuer/detail',
    element: <CountrySpecificFriendshipEarningAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },

  // {
  //   permittedRole: RoutePermittedRole.countryOwner,
  //   path: '/country-licensee/membership-earning/country/asissuer',
  //   element: <CountrySpecificFriendshipEarningAsIssuer />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.countryOwner,
  //   path: '/country-licensee/membership-earning/country/asissuer/detail',
  //   element: <CountrySpecificFriendshipEarningAsIssuerDetail />,
  // },
  // merchant signer
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/country/asmerchantsigner',
    element: <CountrySpecificFriendshipEarningAsMerchantSigner />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/asmerchantsigner',
    element: <CountrySpecificFriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/country/asmerchantsigner/detail',
    element: <CountrySpecificFriendshipEarningAsMerchantSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },
  // cp signer
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/ascpsigner/asissuer/detail',
    element: <FriendshipEarningAsCpSignerAsIssuerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/ascpsigner/asmerchantsigner/detail',
    element: <FriendshipEarningAsCpSignerAsSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/country/ascpsigner',
    element: <CountrySpecificFriendshipEarningAsCpSignerSummary />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/country/ascpsigner/asissuer/detail',
    element: <CountrySpecificFriendshipEarningAsCpSignerAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/country/ascpsigner/assigner/detail',
    element: <CountrySpecificFriendshipEarningAsCpSignerAsSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/ascpsigner',
    element: <MembershipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/ascpsigner/detail',
    element: <MembershipEarningAsCpSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/ascpsigner',
    element: <CountrySpecificMembershipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/ascpsigner/detail',
    element: <CountrySpecificMembershipEarningAsCpSignerDetail />,
  },

  // from Country Pool
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/fromCountryPool',
    element: <FriendshipEarningFromCountryPool />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/fromCountryPool/detail',
    element: <FriendshipEarningFromCountryPoolDetail />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/fromCountryPool',
    element: <MembershipEarningFromCountryPool />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/fromCountryPool/detail',
    element: <MembershipEarningFromCountryPoolDetail />,
  },

  // {
  //   permittedRole: RoutePermittedRole.countryOwner,
  //   path: '/country-licensee/friendship-earning/ascpsigner/detail',
  //   element: <FriendshipEarningAsCpSignerDetail />,
  // },

  // issuer
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/asissuer',
    element: <CountrySpecificMembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/asissuer/detail',
    element: <CountrySpecificMembershipEarningAsIssuerDetail />,
  },
  // region allocation
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/fromregionallocation',
    element: <MembershipEarningFromRegionAllocation />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/fromregionallocation/detail',
    element: <MembershipEarningFromRegionAllocationDetail />,
  },
  // club/charity
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/asclubcharity',
    element: <MembershipEarningAsClubCharity />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/asclubcharity/detail',
    element: <MembershipEarningAsClubCharityDetail />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/asclubcharity',
    element: <CountrySpecificMembershipEarningAsClubCharity />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/asclubcharity/detail',
    element: <CountrySpecificMembershipEarningAsClubCharityDetail />,
  },

  // from Country Pool
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/country/total-transactions',
    element: <CountrySpecificFriendshipEarningTotalTransactions />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/friendship-earning/country/total-transactions/detail',
    element: <CountrySpecificFriendshipEarningTotalTransactionsDetail />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/total-transactions',
    element: <CountrySpecificMembershipEarningTotalTransactions />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/country/total-transactions/detail',
    element: <CountrySpecificMembershipEarningTotalTransactionsDetail />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'country-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'country-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },
  // {
  //   permittedRole: RoutePermittedRole.countryOwner,
  //   path: '/country-licensee/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/agreement',
    element: <Agreement />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/coupons',
    element: <Coupons adminRoute={'country-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/coupon-codes/:id',
    element: <CouponCodes />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/reviews',
    element: <Reviews />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/setting/smtp',
    element: <SMTP adminRoute={'country-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/setting/group',
    element: <Group adminRoute={'country-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/setting/contacts',
    element: <Contacts adminRoute={'country-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/setting/documents',
    element: <Documents adminRoute={'country-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/setting/emails',
    element: <Emails adminRoute={'country-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/setting/reports/:id',
    element: <Reports adminRoute={'country-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/setting/recipients',
    element: <Recipients adminRoute={'country-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/setting/temporary-password',
    element: <TemporaryPassword />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/merchant-activity-reports',
    element: <MerchantActivityReports adminRoute={'country-licensee'} />,
  },
  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/member-list/:id',
    element: <MembersInfoList adminRoute={'country-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/all-notification',
    element: <AllNotificationList adminRoute={'country-licensee'} />,
  },

  {
    permittedRole: RoutePermittedRole.countryOwner,
    path: '/country-licensee/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'country-licensee'} />,
  },
]
