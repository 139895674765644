import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import AppCard from '@crema/core/AppCard'
import container from './TermAndConditionDescription.container'
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { useLocation, useNavigate, useRouteError } from 'react-router-dom'
import { AppLoader } from '@crema'
import { Link, useParams } from 'react-router-dom'
import { Close } from '@mui/icons-material'

const TermsAndCondition = ({
  page: { solePage, loading },
  onFetchPageByRoleStart,
  onAgreeTermAndConditionStart,
  auth: { currentUser },
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [agreedTermAndCondition, setAgreedTermAndCondition] = useState(true)

  const handleAgreeTermAndCondition = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAgreedTermAndCondition(event.target.checked)
  }

  useEffect(() => {
    onFetchPageByRoleStart({ pageType: 'terms-and-conditions' })
  }, [])

  const handleAgree = () => {
    onAgreeTermAndConditionStart({
      role: currentUser?.role,
      navigate,
    })
  }

  // if (loading) {
  //   return <AppLoader />
  // }

  if (loading) {
    return (
      <Stack
        direction="row"
        sx={{ minHeight: 500 }}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Stack>
    )
  }

  if (!solePage?.title) {
    return (
      <Box>
        <Typography sx={{}}>
          Couldn't load the terms and conditions.{' '}
        </Typography>{' '}
        <Button
          variant="outlined"
          size="small"
          sx={{ mt: 5 }}
          onClick={() => {
            onFetchPageByRoleStart({ pageType: 'terms-and-conditions' })
          }}
        >
          {' '}
          Try Again{' '}
        </Button>
      </Box>
    )
  }

  const {
    // state: { },
    state,
  } = location

  return (
    <Box style={{ color: '#000000' }}>
      <AppCard sx={{ p: 0 }}>
        <Stack direction="row">
          <Box sx={{ flex: 1 }}>
            <Typography
              align="center"
              variant="h2"
              sx={{ fontWeight: 'bold', mt: 5 }}
            >
              {solePage?.title}
            </Typography>
          </Box>

          {state?.showCloseButton && (
            <IconButton component={Link} to="/termsandcondition">
              <Close />
            </IconButton>
          )}
        </Stack>
        <Box style={{ height: 40 }} />

        <Box sx={{ p: 5 }}>{parse(solePage?.description || '')}</Box>
        <Typography sx={{ mt: 5 }}></Typography>

        {/* <Box>
          <Checkbox
            checked={agreedTermAndCondition}
            onChange={handleAgreeTermAndCondition}
          />
          <Typography display="inline">I Agree with the </Typography>
          <Typography
            display="inline"
            component={Link}
            to="/terms"
            target="_blank"
            sx={{ fontWeight: '500' }}
          >
            {' '}
            Terms and conditions
          </Typography>
        </Box> */}

        <Box style={{ height: 30 }} />

        {/* <Stack direction="row" justifyContent="flex-end">
          <Button
            onClick={handleAgree}
            disabled={!agreedTermAndCondition}
            sx={{
              color: '#ffffff',
              display: 'flex',
              background: '#00bfcd',
              '&:hover': {
                background: '#ec4785',
              },
              px: 5,
            }}
          >
            I agree with the terms and conditions
          </Button>
        </Stack> */}
      </AppCard>
    </Box>
  )
}

export default container(TermsAndCondition)
