import React, { ReactNode } from 'react'

import { RoutePermittedRole } from '../../shared/constants/AppConst'
import { GrHome } from 'react-icons/gr'

import { FaUsers } from 'react-icons/fa'
import {
  MdOutlineStorefront,
  MdOutlineHomeWork,
  MdOutlinePayment,
} from 'react-icons/md'
import {
  AttachMoney,
  HeadsetMic,
  PlayArrow,
  Policy,
  MonetizationOn,
} from '@mui/icons-material'
import { FiUser } from 'react-icons/fi'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import LogoutIcon from '@mui/icons-material/Logout'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import PaymentIcon from '@mui/icons-material/Payment'

export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: 'item' | 'group' | 'collapse' | 'divider'
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const stateAdminRoutesConfig: RouterConfigData[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'home',
        messageId: 'Home',
        type: 'item',
        icon: <GrHome />,
        url: '/dashboard',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            messageId: 'Dashboard',
            type: 'item',
            url: '/dashboard',
          },
        ],
      },
      {
        id: 'myAccount',
        title: 'myAccount',
        messageId: 'My Account',
        type: 'item',
        // icon: <PersonOutline />,
        icon: <FiUser />,
        url: '/my-account',
      },
      {
        id: 'Members',
        title: 'Members',
        messageId: 'Members',
        type: 'collapse',
        icon: <FaUsers />,
        children: [
          {
            id: 'memberslist',
            title: 'Members List',
            messageId: 'Members List',
            type: 'item',
            url: '/state-licensee/memberslist',
          },
        ],
      },
      {
        id: 'Merchant',
        title: 'Merchants',
        messageId: 'Merchants',
        type: 'collapse',
        icon: <MdOutlineStorefront />,
        children: [
          {
            id: 'sendMerchantRequest',
            title: 'Send Merchant Request',
            messageId: 'Send Merchant Request',
            type: 'item',
            url: '/state-licensee/sendMerchantRequest',
          },
          {
            id: 'draftList',
            title: 'Draft List',
            messageId: 'Draft List',
            type: 'item',
            url: '/state-licensee/draftList',
          },
          {
            id: 'merchantList',
            title: 'Merchants List',
            messageId: 'Merchants List',
            type: 'item',
            url: '/state-licensee/merchantList',
          },

          // {
          //   id: 'stateMerchantList',
          //   title: 'State Merchants List',
          //   messageId: 'State Merchants List',
          //   type: 'item',
          //   url: '/state-licensee/stateMerchantList',
          // },

          {
            id: 'referredMerchantList',
            title: 'Referred Merchant List',
            messageId: 'Referred Merchant List',
            type: 'item',
            url: '/state-licensee/referredMerchantList',
          },
          {
            id: 'merchantActivityReports',
            title: 'Merchant Activity Reports',
            messageId: 'Merchant Activity Reports',
            type: 'item',
            url: '/state-licensee/merchant-activity-reports',
          },
          // {
          //   id: 'sendMerchantEmail',
          //   title: 'Send Merchant Email',
          //   messageId: 'Send Merchant Email',
          //   type: 'item',
          //   url: '/state-licensee/sendMerchantEmail',
          // },
        ],
      },
      {
        id: 'Charities',
        title: 'Charities',
        messageId: 'Charities',
        type: 'item',
        icon: <MdOutlineHomeWork />,
        url: '/state-licensee/charitieslist',
      },

      {
        id: 'Clubs',
        title: 'Clubs',
        messageId: 'Clubs',
        type: 'item',
        icon: <MdOutlineHomeWork />,
        url: '/state-licensee/clublist',
      },

      {
        id: 'collaborationpartners',
        title: 'Collaboration Partners',
        messageId: 'Collaboration Partners',
        type: 'item',
        icon: <MdOutlinePayment />,
        url: '/state-licensee/referredcplist',
      },
      {
        id: 'PremiumCodes',
        title: 'Codes',
        messageId: 'Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'premiummerchantcodes',
            title: 'Merchant Codes',
            messageId: 'Merchant Codes',
            type: 'item',
            url: '/state-licensee/premium-merchant-codes',
          },
          {
            id: 'premiummerchantcodes',
            title: 'Premium Member Codes',
            messageId: 'Premium Member Codes',
            type: 'item',
            url: '/state-licensee/premium-member-codes',
          },
        ],
      },

      {
        id: 'LocationBasedUsers',
        title: 'LocationBasedUsers',
        messageId: 'Location Based Users',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'stateMemberslist',
            title: 'State Members List',
            messageId: 'State Members List',
            type: 'item',
            url: '/state-licensee/state-members-list',
          },
          {
            id: 'stateMerchantList',
            title: 'State Merchants List',
            messageId: 'State Merchants List',
            type: 'item',
            url: '/state-licensee/stateMerchantList',
          },
          {
            id: 'stateCharityList',
            title: 'State Charity List',
            messageId: 'State Charity List',
            type: 'item',
            url: '/state-licensee/state-charities-list',
          },
          {
            id: 'stateClubList',
            title: 'State Club List',
            messageId: 'State Club List',
            type: 'item',
            url: '/state-licensee/state-club-list',
          },
          {
            id: 'stateCollaborationList',
            title: 'State Collaboration List',
            messageId: 'State Collaboration List',
            type: 'item',
            url: '/state-licensee/state-collaboration-list',
          },
        ],
      },

      {
        id: 'QrCodes',
        title: 'QrCodes',
        messageId: 'QR Codes',
        type: 'collapse',
        icon: <MdOutlinePayment />,
        children: [
          {
            id: 'transactionQrCodes',
            title: 'Transaction QR Codes',
            messageId: 'Transaction QR Codes',
            type: 'item',
            url: '/state-licensee/transaction-qr-codes',
          },
          {
            id: 'issuerQrCodes',
            title: 'Issuer QR Codes',
            messageId: 'Issuer QR Codes',
            type: 'item',
            url: '/state-licensee/issuer-qr-codes',
          },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'friendshipearning',
        title: 'FriendshipEarning',
        messageId: 'Friendship Earning',
        type: 'collapse',
        children: [
          {
            id: 'asStateOwner',
            title: 'AsStateOwner',
            messageId: 'As State Licensee',
            type: 'item',
            url: '/state-licensee/friendship-earning/asstateowner',
          },
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/state-licensee/friendship-earning/asissuer',
          },
          {
            id: 'asMerchantSigner',
            title: 'AsMerchantSigner',
            messageId: 'As Merchant Signer',
            type: 'item',
            url: '/state-licensee/friendship-earning/asmerchantsigner',
          },
          {
            id: 'asCpSigner',
            title: 'AsCpSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/state-licensee/friendship-earning/ascpsigner',
          },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'memberearning',
        title: 'memberhipEarning',
        messageId: 'Membership Earning',
        type: 'collapse',
        children: [
          {
            id: 'asIssuer',
            title: 'AsIssuer',
            messageId: 'As an Issuer',
            type: 'item',
            url: '/state-licensee/membership-earning/asissuer',
          },
          {
            id: 'stateAllocation',
            title: 'StateAllocation',
            messageId: 'From State Allocation',
            type: 'item',
            url: '/state-licensee/membership-earning/fromstateallocation',
          },
          {
            id: 'asCharityClub',
            title: 'AsCharityClub',
            messageId: 'As Club/Charity Signer',
            type: 'item',
            url: '/state-licensee/membership-earning/asclubcharity',
          },
          {
            id: 'cpSigner',
            title: 'CPSigner',
            messageId: 'As CP Signer',
            type: 'item',
            url: '/state-licensee/membership-earning/ascpsigner',
          },
          // {
          //   id: 'fromMemberPremiumCode',
          //   title: 'fromMemberPremiumCode',
          //   messageId: 'From Member Premium Code',
          //   type: 'item',
          //   url: '/state-licensee/membership-earning/fromMemberPremiumCode',
          // },
        ],
      },
      {
        icon: <AttachMoney />,
        id: 'merchantPackage',
        title: 'merchantPackage',

        messageId: 'Merchant Package Earning',
        type: 'item',

        url: '/state-licensee/merchant-package/earning',
      },
      {
        icon: <PlayArrow />,
        id: 'training',
        title: 'training',
        messageId: 'Trainings',
        type: 'item',
        url: '/common/trainings',
        // url: '/training-videos',
      },
      {
        icon: <HeadsetMic />,
        id: 'support',
        title: 'support',
        messageId: 'Contact Piiink',
        type: 'item',
        url: '/support',
      },

      //panda docs section starts
      {
        id: 'documentManagement',
        title: 'document Management',
        messageId: 'Document Management',
        type: 'collapse',
        icon: <ContentPasteIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/stateowner/setting/smtp',
          // },
          {
            id: 'group',
            title: 'Group',
            messageId: 'Group',
            type: 'item',
            url: '/state-licensee/setting/group',
          },

          {
            id: 'contacts',
            title: 'Contacts',
            messageId: 'Contacts',
            type: 'item',
            url: '/state-licensee/setting/contacts',
          },

          {
            id: 'documents',
            title: 'Documents',
            messageId: 'Documents',
            type: 'item',
            url: '/state-licensee/setting/documents',
          },

          {
            id: 'emails',
            title: 'Emails',
            messageId: 'Emails',
            type: 'item',
            url: '/state-licensee/setting/emails',
          },
        ],
      },

      //panda docs section ends
      {
        id: 'payments',
        title: 'payments',
        messageId: 'Payments',
        type: 'collapse',
        icon: <PaymentIcon />,
        children: [
          // {
          //   id: 'smtp',
          //   title: 'SMTP',
          //   messageId: 'SMTP',
          //   type: 'item',
          //   url: '/salesagent/setting/smtp',
          // },
          {
            id: 'connect stripe',
            title: 'connect stripe',
            messageId: 'Connect Stripe',
            type: 'item',
            url: '/state-licensee/payment/connect-stripe',
          },
        ],
      },

      {
        icon: <MonetizationOn />,
        id: 'prizedraw',
        title: 'prizedraw',
        messageId: 'Prize Draw',
        type: 'item',
        url: '/prizedraw',
      },

      {
        icon: <FileOpenIcon />,
        id: 'agreement',
        title: 'agreement',
        messageId: 'Agreement',
        type: 'item',
        url: '/state-licensee/agreement',
      },

      {
        icon: <Policy />,
        id: 'terms',
        title: 'terms',
        messageId: 'Terms & Conditions',
        type: 'item',
        url: '/terms',
      },

      {
        icon: <ReceiptIcon />,
        id: 'coupons',
        title: 'coupons',
        messageId: 'Coupons',
        type: 'item',
        url: '/state-licensee/coupons',
      },

      {
        icon: <LogoutIcon />,
        id: 'logout',
        title: 'logout',
        messageId: 'Log Out',
        type: 'item',
        url: '/logout',
      },
    ],
  },
]
export default stateAdminRoutesConfig
