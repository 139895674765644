import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import DraftList from 'components/GlobalMerchantForm/DraftList/DraftList.component'
import EditMerchantRequest from 'components/GlobalMerchantForm/DraftList/components/EditMerchantRequest/EditMerchantRequest.component'
import MerchantList from 'components/GlobalMerchantForm/MerchantList'
import SendMerchantRequest from 'components/GlobalMerchantForm/SendMerchantRequest'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import AllNotificationList from 'components/AllNotificationList'
import ConnectStripe from 'components/Payments/ConnectStripe'

// const MembersList = React.lazy(() => import('./Members/MembersList'))

const MerchantCodes = React.lazy(
  () => import('components/PremiumCodes/MerchantCodesList')
)
const MemberCodes = React.lazy(
  () => import('components/PremiumCodes/MemberCodesList')
)

const TransactionQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/TransactionQrCode/QrCodes')
)

const IssuerQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/IssuerQrCode')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)

const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)

const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))
const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)
const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)

const EditMerchantFromMerchantList = React.lazy(
  () => import('components/GlobalMerchantForm/EditMerchantRequest')
)

const MerchantActivityReports = React.lazy(
  () => import('components/GlobalMerchantForm/MerchantActivityReports')
)

const MembersInfoList = React.lazy(
  () => import('components/GlobalMerchantForm/MembersList')
)

export const companyAdminConfig = [
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },

  // {
  //   permittedRole: RoutePermittedRole.company,
  //   path: '/company/memberslist',
  //   element: <MembersList />,
  // },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/premium-merchant-codes',
    element: <MerchantCodes />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/premium-member-codes',
    element: <MemberCodes />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'company'} />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'company'} />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/editMerchantRequest/:id',
    element: <EditMerchantRequest adminRoute={'company'} />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/merchantList',
    element: <MerchantList adminRoute={'company'} />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/draftList',
    element: <DraftList adminRoute={'company'} />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/transaction-qr-codes',
    element: <TransactionQrCodes />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/issuer-qr-codes',
    element: <IssuerQrCodes />,
  },

  // merchant signer
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/coupons',
    element: <Coupons adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/coupon-codes/:id',
    element: <CouponCodes />,
  },
  // {
  //   permittedRole: [
  //     RoutePermittedRole.collaborationPartner,
  //     RoutePermittedRole.company,
  //   ].join(', '),
  //   path: '/company/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/setting/smtp',
    element: <SMTP adminRoute={'company'} />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/setting/group',
    element: <Group adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/setting/contacts',
    element: <Contacts adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/setting/documents',
    element: <Documents adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/setting/emails',
    element: <Emails adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/setting/recipients',
    element: <Recipients adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/setting/reports/:id',
    element: <Reports adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/merchantList/editMerchant/:id',
    element: <EditMerchantFromMerchantList adminRoute={'company'} />,
  },
  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/merchant-activity-reports',
    element: <MerchantActivityReports adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/member-list/:id',
    element: <MembersInfoList adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/all-notification',
    element: <AllNotificationList adminRoute={'company'} />,
  },

  {
    permittedRole: RoutePermittedRole.company,
    path: '/company/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'company'} />,
  },
]
