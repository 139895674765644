import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchCountryAdminTotalTransactionAndTotalOwnerRequestsStart,
  fetchCountryAdminTotalTransactionAndTotalOwnerRequestsSuccess,
  fetchCountryAdminTotalTransactionAndTotalOwnerRequestsFailure,
  fetchTotalEarningStart,
  fetchTotalEarningSuccess,
  fetchTotalEarningFailure,
  fetchTotalUsersCountStart,
  fetchTotalUsersCountSuccess,
  fetchTotalUsersCountFailure,
  fetchTotalOwnerDataCountStart,
  fetchTotalOwnerDataCountSuccess,
  fetchTotalOwnerDataCountFailure,
  fetchCountryAdminFriendshipTransactionSummaryStart,
  fetchCountryAdminFriendshipTransactionSummarySuccess,
  fetchCountryAdminFriendshipTransactionSummaryFailure,
  fetchCountryAdminMembershipTransactionSummaryStart,
  fetchCountryAdminMembershipTransactionSummarySuccess,
  fetchCountryAdminMembershipTransactionSummaryFailure,
  fetchRecentMerchantsStart,
  fetchRecentMerchantsSuccess,
  fetchRecentMerchantsFailure,
  fetchCountryAdminYearlyTransactionGraphStart,
  fetchCountryAdminYearlyTransactionGraphSuccess,
  fetchCountryAdminYearlyTransactionGraphFailure,
  fetchGlobalAdminTodaySummaryStart,
  fetchGlobalAdminTodaySummarySuccess,
  fetchGlobalAdminTodaySummaryFailure,
  fetchStateAdminTodaySummaryStart,
  fetchStateAdminTodaySummarySuccess,
  fetchStateAdminTodaySummaryFailure,
  fetchOwnerAdminFriendshipRevenueTodayStart,
  fetchOwnerAdminFriendshipRevenueTodaySuccess,
  fetchOwnerAdminFriendshipRevenueTodayFailure,
  fetchOwnerAdminMembershipRevenueTodayStart,
  fetchOwnerAdminMembershipRevenueTodaySuccess,
  fetchOwnerAdminMembershipRevenueTodayFailure,
  fetchOwnerAdminFriendshipRevenueSummaryStart,
  fetchOwnerAdminFriendshipRevenueSummarySuccess,
  fetchOwnerAdminFriendshipRevenueSummaryFailure,
  fetchOwnerAdminMembershipRevenueSummaryStart,
  fetchOwnerAdminMembershipRevenueSummarySuccess,
  fetchOwnerAdminMembershipRevenueSummaryFailure,
  fetchOwnerAdminRecentMerchantsStart,
  fetchOwnerAdminRecentMerchantsSuccess,
  fetchOwnerAdminRecentMerchantsFailure,
  fetchOwnerAdminYearlyEarningStart,
  fetchOwnerAdminYearlyEarningSuccess,
  fetchOwnerAdminYearlyEarningFailure,
  fetchStateAdminMembershipRevenueStart,
  fetchStateAdminMembershipRevenueSuccess,
  fetchStateAdminMembershipRevenueFailure,
  fetchStateAdminTotalEarningStart,
  fetchStateAdminTotalEarningSuccess,
  fetchStateAdminTotalEarningFailure,
  fetchStateAdminRecentMembersStart,
  fetchStateAdminRecentMembersSuccess,
  fetchStateAdminRecentMembersFailure,
  fetchGlobalAdminFriendshipRevenueStart,
  fetchGlobalAdminFriendshipRevenueSuccess,
  fetchGlobalAdminFriendshipRevenueFailure,
  fetchGlobalAdminMembershipRevenueStart,
  fetchGlobalAdminMembershipRevenueSuccess,
  fetchGlobalAdminMembershipRevenueFailure,
  fetchGlobalAdminTotalEarningStart,
  fetchGlobalAdminTotalEarningSuccess,
  fetchGlobalAdminTotalEarningFailure,
  fetchGlobalAdminYearlyTransactionGraphStart,
  fetchGlobalAdminYearlyTransactionGraphSuccess,
  fetchGlobalAdminYearlyTransactionGraphFailure,
  fetchGlobalAdminTotalSummaryRevenueStart,
  fetchGlobalAdminTotalSummaryRevenueSuccess,
  fetchGlobalAdminTotalSummaryRevenueFailure,
  fetchGlobalAdminAllDataCountStart,
  fetchGlobalAdminAllDataCountSuccess,
  fetchGlobalAdminAllDataCountFailure,
  fetchMerchantAdminTodaySummarySuccess,
  fetchMerchantAdminTodaySummaryFailure,
  fetchMerchantAdminTotalSummarySuccess,
  fetchMerchantAdminTotalSummaryFailure,
  fetchMerchantAdminYearlyTransactionGraphSuccess,
  fetchMerchantAdminYearlyTransactionGraphFailure,
  fetchGlobalAdminByCountryTodaySummaryStart,
  fetchGlobalAdminByCountryTodaySummarySuccess,
  fetchGlobalAdminByCountryTodaySummaryFailure,
  fetchGlobalAdminByCountryTotalSummaryStart,
  fetchGlobalAdminByCountryTotalSummarySuccess,
  fetchGlobalAdminByCountryTotalSummaryFailure,
  fetchGlobalAdminByCountryTotalRevenueSummaryStart,
  fetchGlobalAdminByCountryTotalRevenueSummarySuccess,
  fetchGlobalAdminByCountryTotalRevenueSummaryFailure,
  fetchGlobalAdminByCountryTotalRevenueSummaryCountStart,
  fetchGlobalAdminByCountryTotalRevenueSummaryCountSuccess,
  fetchGlobalAdminByCountryTotalRevenueSummaryCountFailure,
  fetchGlobalAdminByCountryTodayFriendshipRevenueStart,
  fetchGlobalAdminByCountryTodayFriendshipRevenueSuccess,
  fetchGlobalAdminByCountryTodayFriendshipRevenueFailure,
  fetchGlobalAdminByCountryTodayMembershipRevenueStart,
  fetchGlobalAdminByCountryTodayMembershipRevenueSuccess,
  fetchGlobalAdminByCountryTodayMembershipRevenueFailure,
  fetchGlobalAdminByCountryAllDataCountStart,
  fetchGlobalAdminByCountryAllDataCountSuccess,
  fetchGlobalAdminByCountryAllDataCountFailure,

  // sales agent
  fetchSalesAgentTotalSummaryStart,
  fetchSalesAgentTotalSummarySuccess,
  fetchSalesAgentTotalSummaryFailure,
  fetchSalesAgentDailySummaryStart,
  fetchSalesAgentDailySummarySuccess,
  fetchSalesAgentDailySummaryFailure,

  // collaboration partner
  fetchCollaborationPartnerTotalSummaryStart,
  fetchCollaborationPartnerTotalSummarySuccess,
  fetchCollaborationPartnerTotalSummaryFailure,
  fetchCollaborationPartnerDailySummaryStart,
  fetchCollaborationPartnerDailySummarySuccess,
  fetchCollaborationPartnerDailySummaryFailure,
  fetchMerchantAdminFriendshipRevenueSuccess,
  fetchMerchantAdminFriendshipRevenueFailure,
  fetchMerchantAdminMembershipRevenueSuccess,
  fetchMerchantAdminMembershipRevenueFailure,
  fetchCharityTodaySummarySuccess,
  fetchCharityTodaySummaryFailure,
  fetchCharityMerchantAndMemberSuccess,
  fetchCharityMerchantAndMemberFailure,
  fetchCharityTotalSummarySuccess,
  fetchCharityTotalSummaryFailure,
  fetchClubTodaySummarySuccess,
  fetchClubTodaySummaryFailure,
  fetchTotalClubSummarySuccess,
  fetchTotalClubSummaryFailure,
  fetchCharityTotalRevenueSummarySuccess,
  fetchCharityTotalRevenueSummaryFailure,
  fetchTodayClubRevenueSuccess,
  fetchTodayClubRevenueFailure,
  fetchTotalClubRevenueSuccess,
  fetchTotalClubRevenueFailure,
  //whiteLabelGeneral
  fetchWhiteLabelTodaySummaryStart,
  fetchWhiteLabelTodaySummarySuccess,
  fetchWhiteLabelTodaySummaryFailure,
  fetchWhiteLabelFriendshipRevenueTodayStart,
  fetchWhiteLabelFriendshipRevenueTodaySuccess,
  fetchWhiteLabelFriendshipRevenueTodayFailure,
  fetchWhiteLabelMembershipRevenueTodayStart,
  fetchWhiteLabelMembershipRevenueTodaySuccess,
  fetchWhiteLabelMembershipRevenueTodayFailure,
  fetchWhiteLabelTodayRevenueStart,
  fetchWhiteLabelTodayRevenueSuccess,
  fetchWhiteLabelTodayRevenueFailure,
  fetchWhiteLabelTotalRevenueStart,
  fetchWhiteLabelTotalRevenueSuccess,
  fetchWhiteLabelTotalRevenueFailure,
  fetchWhiteLabelAllDataCountStart,
  fetchWhiteLabelAllDataCountSuccess,
  fetchWhiteLabelAllDataCountFailure,
  fetchWhiteLabelYearlyTransactionGraphStart,
  fetchWhiteLabelYearlyTransactionGraphSuccess,
  fetchWhiteLabelYearlyTransactionGraphFailure,
  fetchWhiteLabelYearlyTransactionGraphAsCPSignerStart,
  fetchWhiteLabelYearlyTransactionGraphAsCPSignerSuccess,
  fetchWhiteLabelYearlyTransactionGraphAsCPSignerFailure,

  //whiteLabel isBalancing
  fetchWhiteLabelIsBalancingTodaySummaryStart,
  fetchWhiteLabelIsBalancingTodaySummarySuccess,
  fetchWhiteLabelIsBalancingTodaySummaryFailure,
  fetchWhiteLabelIsBalancingFriendshipRevenueTodayStart,
  fetchWhiteLabelIsBalancingFriendshipRevenueTodaySuccess,
  fetchWhiteLabelIsBalancingFriendshipRevenueTodayFailure,
  fetchWhiteLabelIsBalancingMembershipRevenueTodayStart,
  fetchWhiteLabelIsBalancingMembershipRevenueTodaySuccess,
  fetchWhiteLabelIsBalancingMembershipRevenueTodayFailure,
  fetchWhiteLabelIsBalancingTodayRevenueStart,
  fetchWhiteLabelIsBalancingTodayRevenueSuccess,
  fetchWhiteLabelIsBalancingTodayRevenueFailure,
  fetchWhiteLabelIsBalancingTotalRevenueStart,
  fetchWhiteLabelIsBalancingTotalRevenueSuccess,
  fetchWhiteLabelIsBalancingTotalRevenueFailure,
  fetchWhiteLabelIsBalancingAllDataCountStart,
  fetchWhiteLabelIsBalancingAllDataCountSuccess,
  fetchWhiteLabelIsBalancingAllDataCountFailure,
  fetchWhiteLabelIsBalancingYearlyTransactionGraphStart,
  fetchWhiteLabelIsBalancingYearlyTransactionGraphSuccess,
  fetchWhiteLabelIsBalancingYearlyTransactionGraphFailure,
  fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerStart,
  fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerSuccess,
  fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerFailure,

  //whiteLabel isIntroducer
  fetchWhiteLabelIsIntroducerTodaySummaryStart,
  fetchWhiteLabelIsIntroducerTodaySummarySuccess,
  fetchWhiteLabelIsIntroducerTodaySummaryFailure,
  fetchWhiteLabelIsIntroducerFriendshipRevenueTodayStart,
  fetchWhiteLabelIsIntroducerFriendshipRevenueTodaySuccess,
  fetchWhiteLabelIsIntroducerFriendshipRevenueTodayFailure,
  fetchWhiteLabelIsIntroducerMembershipRevenueTodayStart,
  fetchWhiteLabelIsIntroducerMembershipRevenueTodaySuccess,
  fetchWhiteLabelIsIntroducerMembershipRevenueTodayFailure,
  fetchWhiteLabelIsIntroducerTodayRevenueStart,
  fetchWhiteLabelIsIntroducerTodayRevenueSuccess,
  fetchWhiteLabelIsIntroducerTodayRevenueFailure,
  fetchWhiteLabelIsIntroducerTotalRevenueStart,
  fetchWhiteLabelIsIntroducerTotalRevenueSuccess,
  fetchWhiteLabelIsIntroducerTotalRevenueFailure,
  fetchWhiteLabelIsIntroducerAllDataCountStart,
  fetchWhiteLabelIsIntroducerAllDataCountSuccess,
  fetchWhiteLabelIsIntroducerAllDataCountFailure,
  fetchSalesAgentTotalUserCountSuccess,
  fetchSalesAgentTotalUserCountFailure,
  fetchCollaborationPartnerTotalUserCountStart,
  fetchCollaborationPartnerTotalUserCountSuccess,
  fetchCollaborationPartnerTotalUserCountFailure,
  fetchWhiteLabelIsIntroducerYearlyTransactionGraphStart,
  fetchWhiteLabelIsIntroducerYearlyTransactionGraphSuccess,
  fetchWhiteLabelIsIntroducerYearlyTransactionGraphFailure,
  fetchMerchantRebateWalletSuccess,
  fetchMerchantRebateWalletFailure,
  fetchTerminalMerchantAdminTodaySummarySuccess,
  fetchTerminalMerchantAdminTodaySummaryFailure,
  fetchTerminalMerchantAdminTotalSummarySuccess,
  fetchTerminalMerchantAdminTotalSummaryFailure,
  fetchSupporterMerchantAdminTodaySummarySuccess,
  fetchSupporterMerchantAdminTodaySummaryFailure,
  fetchSupporterMerchantAdminTotalSummarySuccess,
  fetchSupporterMerchantAdminTotalSummaryFailure,
  fetchGlobalAdminTodaySummaryTransactionSuccess,
  fetchGlobalAdminTodaySummaryTransactionFailure,
  fetchGlobalAdminTodaySummaryOfWLSuccess,
  fetchGlobalAdminTodaySummaryOfWLFailure,
  fetchGlobalAdminTotalSummaryOfWLSuccess,
  fetchGlobalAdminTotalSummaryOfWLFailure,
  fetchMerchantTransactionFeeDebitSuccess,
  fetchMerchantTransactionFeeDebitFailure,
  fetchCharityDashboardDataFailure,
  fetchCharityDashboardDataSuccess,
  fetchClubDashboardDataSuccess,
  fetchClubDashboardDataFailure,
  fetchRegionDashboardDataSuccess,
  fetchRegionDashboardDataFailure,
  fetchCountryDashboardDataSuccess,
  fetchCountryDashboardDataFailure,
  fetchGlobalAdminDashboardDataSuccess,
  fetchGlobalAdminDashboardDataFailure,
  fetchSalesAgentDashboardDataSuccess,
  fetchSalesAgentDashboardDataFailure,
  fetchCpDashboardDataSuccess,
  fetchCpDashboardDataFailure,
  fetchGlobalAdminWithCountryDashboardDataSuccess,
  fetchGlobalAdminWithCountryDashboardDataFailure,
  fetchMerchantDashboardDataFailure,
  fetchMerchantDashboardDataSuccess,
  fetchIntroducerDashboardDataSuccess,
  fetchIntroducerDashboardDataFailure,
  fetchBalancingLevelDashboardDataSuccess,
  fetchBalancingLevelDashboardDataFailure,
  fetchNormalWhiteLabelDashboardDataSuccess,
  fetchNormalWhiteLabelDashboardDataFailure,
  fetchOwnerAdminRecentMerchantsForCountryDashboardFailure,
  fetchOwnerAdminRecentMerchantsForCountryDashboardSuccess,
  fetchSupporterMerchantDashboardDataSuccess,
  fetchSupporterMerchantDashboardDataFailure,
} from './dashboardSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchCountryAdminTotalTransactionAndTotalOwnerRequests,
  fetchCountryAdminTotalEarning,
  fetchCountryAdminAllDataCount,
  fetchCountryAdminOwnerDataCount,
  fetchCountryAdminRevenueFriendshipTransactionSummary,
  fetchCountryAdminMembershipTransactionSummary,
  fetchCountryAdminDashboardRecentMerchants,
  fetchCountryAdminDashboardYearlyTransactionGraph,
  fetchGlobalAdmintotalAndTodayUserCount,
  fetchStateAdminTotalTransctionOwnerRequest,
  fetchStateAdminFriendshipRevenue,
  fetchStateAdminTotalTransactionSummaryCount,
  fetchStateAdminDashboardRecentMembers,
  fetchGlobalAdminFriendshipRevenue,
  fetchGlobalAdminMembershipRevenue,
  fetchGlobalAdminTotalEarning,
  fetchGlobalAdminDashboardYearlyTransactionGraph,
  fetchGlobalAdminTotalSummaryRevenue,
  fetchGlobalAdminAllDataCount,
  fetchOwnerAdminFriendshipRevenueToday,
  fetchOwnerAdminMembershipRevenueToday,
  fetchOwnerAdminFriendshipRevenueSummary,
  fetchOwnerAdminMembershipRevenueSummary,
  fetchOwnerAdminDashboardRecentMerchants,
  fetchOwnerAdminEarningGraphPerYear,
  fetchMerchantAdminTodaysSummary,
  fetchMerchantAdminTotalSummary,
  fetchMerchantAdminYearlyTransactionGraph,
  fetchGlobalAdminByCountryTodaySummary,
  fetchGlobalAdminByCountryTotalSummary,
  fetchGlobalAdminByCountryFriendshipRevenueToday,
  fetchGlobalAdminByCountryMembershipRevenueToday,
  fetchGlobalAdminByCountryTotalRevenueSummary,
  fetchGlobalAdminByCountryTotalRevenueSummaryCount,
  fetchGlobalAdminByCountryAllDataCount,
  fetchSalesAgentTotalSummary,
  fetchSalesAgentDailySummary,
  fetchCollaborationPartnerTotalSummary,
  fetchCollaborationPartnerDailySummary,
  fetchMerchantAdminFriendshipRevenue,
  fetchMerchantAdminMembershipRevenue,
  fetchCharityTodaySummary,
  fetchTotalCharitySummary,
  fetchMerchantAndMemberCount,
  fetchClubTodaySummary,
  fetchTotalClubSummary,
  fetchCharityTotalRevenueSummary,
  fetchTodayClubRevenue,
  fetchTotalClubRevenue,
  //whiteLabel General
  fetchWhiteLabelTodaySummary,
  fetchWhiteLabelFriendshipRevenueToday,
  fetchWhiteLabelMembershipRevenueToday,
  fetchWhiteLabelTodayRevenue,
  fetchWhiteLabelTotalRevenue,
  fetchWhiteLabelAllDataCount,
  fetchWhiteLabelYearlyTransactionGraph,
  fetchWhiteLabelYearlyTransactionGraphAsCPSigner,
  //whiteLabel isBalancing
  fetchWhiteLabelIsBalancingTodaySummary,
  fetchWhiteLabelIsBalancingFriendshipRevenueToday,
  fetchWhiteLabelIsBalancingMembershipRevenueToday,
  fetchWhiteLabelIsBalancingTodayRevenue,
  fetchWhiteLabelIsBalancingTotalRevenue,
  fetchWhiteLabelIsBalancingAllDataCount,
  fetchWhiteLabelIsBalancingYearlyTransactionGraph,
  fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSigner,
  //whiteLabel isIntroducer
  fetchWhiteLabelIsIntroducerTodaySummary,
  fetchWhiteLabelIsIntroducerFriendshipRevenueToday,
  fetchWhiteLabelIsIntroducerMembershipRevenueToday,
  fetchWhiteLabelIsIntroducerTodayRevenue,
  fetchWhiteLabelIsIntroducerTotalRevenue,
  fetchWhiteLabelIsIntroducerAllDataCount,
  fetchWhiteLabelIsIntroducerYearlyTransactionGraph,
  //cp
  fetchSalesAgentTotalUserCounts,
  fetchCollaborationPartnerTotalUserCounts,
  fetchMerchantRebateWallet,
  fetchTerminalMerchantAdminTotalSummary,
  fetchTerminalMerchantAdminTodaysSummary,
  fetchSupporterMerchantAdminTodaysSummary,
  fetchSupporterMerchantAdminTotalSummary,
  fetchGlobalAdminTodaySummaryTransaction,
  fetchGlobalAdmintotalAndTodayUserCountOfWL,
  fetchGlobalAdmintotalSummaryUserCountOfWL,
  fetchMerchantTransactionFeeDebit,
  fetchCharityDashboardData,
  fetchClubDashboardData,
  fetchRegionDashboardData,
  fetchCountryDashboardData,
  fetchGlobalAdminDashboardData,
  fetchSalesAgentDashboardData,
  fetchCpDashboardData,
  fetchGlobalAdminWithCountryDashboardData,
  fetchMerchantDashboardData,
  fetchIntroducerDashboardData,
  fetchBalancingLevelDashboardData,
  fetchNormalWhiteLabelDashboardData,
  fetchOwnerAdminDashboardRecentMerchantsForCountryDashboard,
  fetchSupporterMerchantDashboardData,
} from '../../api/dashboard'

import _ from 'lodash'

export function* fetchCountryAdminTodayTransactionSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchCountryAdminTotalTransactionAndTotalOwnerRequests(payload)
    )

    yield put(
      fetchCountryAdminTotalTransactionAndTotalOwnerRequestsSuccess(data)
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(
      fetchCountryAdminTotalTransactionAndTotalOwnerRequestsFailure(err)
    )
  }
}

export function* fetchGlobalAdmintotalAndTodayUserCountAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdmintotalAndTodayUserCount(payload))

    yield put(fetchGlobalAdminTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminTodaySummaryFailure(err))
  }
}

export function* fetchCountryAdminTotalEarningAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryAdminTotalEarning(payload))
    yield put(fetchTotalEarningSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTotalEarningFailure(err))
  }
}

export function* fetchStateAdminTotalEarningAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      fetchStateAdminTotalTransactionSummaryCount(payload)
    )
    yield put(fetchStateAdminTotalEarningSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchStateAdminTotalEarningFailure(err))
  }
}

export function* fetchGlobalAdminTotalEarningAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdminTotalEarning(payload))
    yield put(fetchGlobalAdminTotalEarningSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminTotalEarningFailure(err))
  }
}

export function* fetchCountryAdminAllDataCountAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryAdminAllDataCount(payload))
    yield put(fetchTotalUsersCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTotalUsersCountFailure(err))
  }
}

export function* fetchCountryAdminOwnerDataCountAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryAdminOwnerDataCount(payload))
    yield put(fetchTotalOwnerDataCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTotalOwnerDataCountFailure(err))
  }
}

export function* fetchCountryAdminFriendshipTransactionSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchCountryAdminRevenueFriendshipTransactionSummary(payload)
    )
    yield put(fetchCountryAdminFriendshipTransactionSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCountryAdminFriendshipTransactionSummaryFailure(err))
  }
}

// export function* fetchAdminFriendshipTransactionSummaryAsync({
//   payload,
// }: AnyAction) {
//   try {
//     const { data } = yield call(
//       fetchCountryAdminRevenueFriendshipTransactionSummary(payload)
//     )
//     yield put(fetchFriendshipTransactionSummarySuccess(data))
//   } catch (err) {
//     yield put(openAlert({ message: err.message, severity: 'error' }))
//     yield put(fetchFriendshipTransactionSummaryFailure(err))
//   }
// }

export function* fetchCountryAdminMembershipTransactionSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchCountryAdminMembershipTransactionSummary(payload)
    )

    yield put(fetchCountryAdminMembershipTransactionSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCountryAdminMembershipTransactionSummaryFailure(err))
  }
}

export function* fetchOwnerAdminFriendshipRevenueTodayAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchOwnerAdminFriendshipRevenueToday(payload))
    yield put(fetchOwnerAdminFriendshipRevenueTodaySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchOwnerAdminFriendshipRevenueTodayFailure(err))
  }
}

export function* fetchOwnerAdminMembershipRevenueTodayAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchOwnerAdminMembershipRevenueToday(payload))
    yield put(fetchOwnerAdminMembershipRevenueTodaySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchOwnerAdminMembershipRevenueTodayFailure(err))
  }
}

export function* fetchOwnerAdminFriendshipRevenueSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchOwnerAdminFriendshipRevenueSummary(payload)
    )
    yield put(fetchOwnerAdminFriendshipRevenueSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchOwnerAdminFriendshipRevenueSummaryFailure(err))
  }
}

export function* fetchOwnerAdminMembershipRevenueSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchOwnerAdminMembershipRevenueSummary(payload)
    )
    yield put(fetchOwnerAdminMembershipRevenueSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchOwnerAdminMembershipRevenueSummaryFailure(err))
  }
}

export function* fetchOwnerAdminRecentMerchantsAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      fetchOwnerAdminDashboardRecentMerchants(payload)
    )

    yield put(fetchOwnerAdminRecentMerchantsSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchOwnerAdminRecentMerchantsFailure(err))
  }
}

export function* fetchOwnerAdminRecentMerchantsForCountryDashboardAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchOwnerAdminDashboardRecentMerchantsForCountryDashboard(payload)
    )

    yield put(fetchOwnerAdminRecentMerchantsForCountryDashboardSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchOwnerAdminRecentMerchantsForCountryDashboardFailure(err))
  }
}

export function* fetchOwnerAdminEarningGraphPerYearAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchOwnerAdminEarningGraphPerYear(payload))
    yield put(fetchOwnerAdminYearlyEarningSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchOwnerAdminYearlyEarningFailure(err))
  }
}

export function* fetchGloblAdminFriendshipRevenueAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdminFriendshipRevenue(payload))
    yield put(fetchGlobalAdminFriendshipRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminFriendshipRevenueFailure(err))
  }
}

export function* fetchGloblAdminByCountryFriendshipRevenueTodayAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdminFriendshipRevenue(payload))
    yield put(fetchGlobalAdminFriendshipRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminFriendshipRevenueFailure(err))
  }
}

export function* fetchGloblAdminByCountryMembershipRevenueTodayAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdminFriendshipRevenue(payload))
    yield put(fetchGlobalAdminFriendshipRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminFriendshipRevenueFailure(err))
  }
}

export function* fetchGloblAdminMembershipRevenueAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdminMembershipRevenue(payload))
    yield put(fetchGlobalAdminMembershipRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminMembershipRevenueFailure(err))
  }
}

export function* fetchCountryAdminRecentMerchantsAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      fetchCountryAdminDashboardRecentMerchants(payload)
    )

    yield put(fetchRecentMerchantsSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchRecentMerchantsFailure(err))
  }
}

export function* fetchStateAdminRecentMembersAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchStateAdminDashboardRecentMembers(payload))

    yield put(fetchStateAdminRecentMembersSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchStateAdminRecentMembersFailure(err))
  }
}

export function* fetchGlobalAdminYearlyTransactionGraphAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminDashboardYearlyTransactionGraph(payload)
    )

    yield put(fetchGlobalAdminYearlyTransactionGraphSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminYearlyTransactionGraphFailure(err))
  }
}

export function* fetchGlobalAdminTotalSummaryRevenueAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdminTotalSummaryRevenue(payload))
    yield put(fetchGlobalAdminTotalSummaryRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminTotalSummaryRevenueFailure(err))
  }
}

export function* fetchGlobalAdminAllDataCountAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdminAllDataCount(payload))
    yield put(fetchGlobalAdminAllDataCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminAllDataCountFailure(err))
  }
}

export function* fetchCountryAdminYearlyTransactionGraphAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchCountryAdminDashboardYearlyTransactionGraph(payload)
    )

    yield put(fetchCountryAdminYearlyTransactionGraphSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCountryAdminYearlyTransactionGraphFailure(err))
  }
}

export function* fetchGlobalAdminTotalTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdmintotalAndTodayUserCount(payload))

    yield put(fetchGlobalAdminTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminTodaySummaryFailure(err))
  }
}

export function* fetchGlobalAdminTotalTodaySummaryOfWLAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdmintotalAndTodayUserCountOfWL(payload)
    )

    yield put(fetchGlobalAdminTodaySummaryOfWLSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminTodaySummaryOfWLFailure(err))
  }
}

export function* fetchGlobalAdminTotalSummaryUserCountOfWLAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdmintotalSummaryUserCountOfWL(payload)
    )

    yield put(fetchGlobalAdminTotalSummaryOfWLSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminTotalSummaryOfWLFailure(err))
  }
}

export function* fetchGlobalAdminTodaySummaryTransactionAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminTodaySummaryTransaction(payload)
    )

    yield put(fetchGlobalAdminTodaySummaryTransactionSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminTodaySummaryTransactionFailure(err))
  }
}

export function* fetchGlobalAdminByCountryTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminByCountryTodaySummary(
        payload.countryId,
        payload.params?.currentDate
      )
    )
    yield put(fetchGlobalAdminByCountryTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminByCountryTodaySummaryFailure(err))
  }
}

export function* fetchGlobalAdminByCountryTotalSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminByCountryTotalSummary(payload.countryId)
    )

    yield put(fetchGlobalAdminByCountryTotalSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminByCountryTotalSummaryFailure(err))
  }
}

export function* fetchGlobalAdminByCountryTotalRevenueSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminByCountryTotalRevenueSummary(payload.countryId)
    )

    yield put(fetchGlobalAdminByCountryTotalRevenueSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminByCountryTotalRevenueSummaryFailure(err))
  }
}

export function* fetchGlobalAdminByCountryTotalRevenueSummaryCountAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminByCountryTotalRevenueSummaryCount(payload.countryId)
    )

    yield put(fetchGlobalAdminByCountryTotalRevenueSummaryCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminByCountryTotalRevenueSummaryCountFailure(err))
  }
}

export function* fetchGlobalAdminByCountryFriendshipRevenueTodayAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminByCountryFriendshipRevenueToday(
        payload.countryId,
        payload.params?.date
      )
    )
    yield put(fetchGlobalAdminByCountryTodayFriendshipRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminByCountryTodayFriendshipRevenueFailure(err))
  }
}

export function* fetchGlobalAdminByCountryMembershipRevenueTodayAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminByCountryMembershipRevenueToday(
        payload.countryId,
        payload.params?.date
      )
    )
    yield put(fetchGlobalAdminByCountryTodayMembershipRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminByCountryTodayMembershipRevenueFailure(err))
  }
}

export function* fetchGlobalAdminByCountryAllDataCountAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminByCountryAllDataCount(payload.countryId)
    )
    yield put(fetchGlobalAdminByCountryAllDataCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminByCountryAllDataCountFailure(err))
  }
}

export function* fetchStateAdminTotalTodaySummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      fetchStateAdminTotalTransctionOwnerRequest(payload)
    )

    yield put(fetchStateAdminTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchStateAdminTodaySummaryFailure(err))
  }
}

export function* fetchMerchantAdminTotalTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantAdminTodaysSummary(payload))

    yield put(fetchMerchantAdminTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantAdminTodaySummaryFailure(err))
  }
}

export function* fetchSupporterMerchantAdminTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchSupporterMerchantAdminTodaysSummary(payload)
    )

    yield put(fetchSupporterMerchantAdminTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchSupporterMerchantAdminTodaySummaryFailure(err))
  }
}

export function* fetchTerminalMerchantAdminTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchTerminalMerchantAdminTodaysSummary(payload)
    )

    yield put(fetchTerminalMerchantAdminTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTerminalMerchantAdminTodaySummaryFailure(err))
  }
}

export function* fetchMerchantAdminTotalummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantAdminTotalSummary(payload))

    yield put(fetchMerchantAdminTotalSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantAdminTotalSummaryFailure(err))
  }
}

export function* fetchSupporterMerchantAdminTotalSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchSupporterMerchantAdminTotalSummary(payload)
    )

    yield put(fetchSupporterMerchantAdminTotalSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchSupporterMerchantAdminTotalSummaryFailure(err))
  }
}

export function* fetchTerminalMerchantAdminTotalSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchTerminalMerchantAdminTotalSummary(payload))

    yield put(fetchTerminalMerchantAdminTotalSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTerminalMerchantAdminTotalSummaryFailure(err))
  }
}

export function* fetchMerchantAdminYearlyTransactionGraphAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchMerchantAdminYearlyTransactionGraph(payload)
    )

    yield put(fetchMerchantAdminYearlyTransactionGraphSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantAdminYearlyTransactionGraphFailure(err))
  }
}

// sales agent sagas
export function* fetchSalesAgentTotalSummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSalesAgentTotalSummary(payload))

    yield put(fetchSalesAgentTotalSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchSalesAgentTotalSummaryFailure(err))
  }
}

export function* fetchSalesAgentDailySummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSalesAgentDailySummary(payload))

    yield put(fetchSalesAgentDailySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchSalesAgentDailySummaryFailure(err))
  }
}
// collaboration partner sagas
export function* fetchCollaborationPartnerTotalSummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCollaborationPartnerTotalSummary(payload))
    yield put(fetchCollaborationPartnerTotalSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCollaborationPartnerTotalSummaryFailure(err))
  }
}

export function* fetchCollaborationPartnerDailySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCollaborationPartnerDailySummary(payload))
    yield put(fetchCollaborationPartnerDailySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCollaborationPartnerDailySummaryFailure(err))
  }
}

export function* fetchMerchantAdminFriendshipRevenueAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantAdminFriendshipRevenue(payload))
    yield put(fetchMerchantAdminFriendshipRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantAdminFriendshipRevenueFailure(err))
  }
}

export function* fetchMerchantAdminMembershipRevenueAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantAdminMembershipRevenue(payload))
    yield put(fetchMerchantAdminMembershipRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantAdminMembershipRevenueFailure(err))
  }
}

export function* fetchCharityTodaySummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCharityTodaySummary(payload))
    yield put(fetchCharityTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCharityTodaySummaryFailure(err))
  }
}

export function* fetchCharityTotalSummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTotalCharitySummary(payload))
    yield put(fetchCharityTotalSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCharityTotalSummaryFailure(err))
  }
}

export function* fetchCharityMerchantAndMemberAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantAndMemberCount(payload))
    yield put(fetchCharityMerchantAndMemberSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCharityMerchantAndMemberFailure(err))
  }
}

export function* fetchCharityTotalRevenueSummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCharityTotalRevenueSummary(payload))
    yield put(fetchCharityTotalRevenueSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCharityTotalRevenueSummaryFailure(err))
  }
}

export function* fetchClubTodaySummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchClubTodaySummary(payload))
    yield put(fetchClubTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchClubTodaySummaryFailure(err))
  }
}

export function* fetchClubTotalSummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTotalClubSummary(payload))
    yield put(fetchTotalClubSummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTotalClubSummaryFailure(err))
  }
}

export function* fetchTodayClubRevenueAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTodayClubRevenue(payload))
    yield put(fetchTodayClubRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTodayClubRevenueFailure(err))
  }
}

export function* fetchTotalClubRevenueAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTotalClubRevenue(payload))
    yield put(fetchTotalClubRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchTotalClubRevenueFailure(err))
  }
}

//whiteLabel General

export function* fetchWhiteLabelTodaySummaryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelTodaySummary(payload))

    yield put(fetchWhiteLabelTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelTodaySummaryFailure(err))
  }
}

export function* fetchWhiteLabelFriendshipRevenueTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelFriendshipRevenueToday(payload))

    yield put(fetchWhiteLabelFriendshipRevenueTodaySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelFriendshipRevenueTodayFailure(err))
  }
}

export function* fetchWhiteLabelMembershipRevenueTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelMembershipRevenueToday(payload))

    yield put(fetchWhiteLabelMembershipRevenueTodaySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelMembershipRevenueTodayFailure(err))
  }
}

export function* fetchWhiteLabelTodayRevenueAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelTodayRevenue(payload))

    yield put(fetchWhiteLabelTodayRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelTodayRevenueFailure(err))
  }
}

export function* fetchWhiteLabelTotalRevenueAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelTotalRevenue(payload))

    yield put(fetchWhiteLabelTotalRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelTotalRevenueFailure(err))
  }
}

export function* fetchWhiteLabelAllDataCountAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelAllDataCount(payload))

    yield put(fetchWhiteLabelAllDataCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelAllDataCountFailure(err))
  }
}

export function* fetchWhiteLabelYearlyTransactionGraphAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelYearlyTransactionGraph(payload))

    yield put(fetchWhiteLabelYearlyTransactionGraphSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelYearlyTransactionGraphFailure(err))
  }
}

export function* fetchWhiteLabelYearlyTransactionGraphAsCPSignerAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelYearlyTransactionGraphAsCPSigner(payload)
    )

    yield put(fetchWhiteLabelYearlyTransactionGraphAsCPSignerSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelYearlyTransactionGraphAsCPSignerFailure(err))
  }
}

//whiteLabel isBalancing

export function* fetchWhiteLabelIsBalancingTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelIsBalancingTodaySummary(payload))

    yield put(fetchWhiteLabelIsBalancingTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsBalancingTodaySummaryFailure(err))
  }
}

export function* fetchWhiteLabelIsBalancingFriendshipRevenueTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsBalancingFriendshipRevenueToday(payload)
    )

    yield put(fetchWhiteLabelIsBalancingFriendshipRevenueTodaySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsBalancingFriendshipRevenueTodayFailure(err))
  }
}

export function* fetchWhiteLabelIsBalancingMembershipRevenueTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsBalancingMembershipRevenueToday(payload)
    )

    yield put(fetchWhiteLabelIsBalancingMembershipRevenueTodaySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsBalancingMembershipRevenueTodayFailure(err))
  }
}

export function* fetchWhiteLabelIsBalancingTodayRevenueAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelIsBalancingTodayRevenue(payload))

    yield put(fetchWhiteLabelIsBalancingTodayRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsBalancingTodayRevenueFailure(err))
  }
}

export function* fetchWhiteLabelIsBalancingTotalRevenueAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelIsBalancingTotalRevenue(payload))

    yield put(fetchWhiteLabelIsBalancingTotalRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsBalancingTotalRevenueFailure(err))
  }
}

export function* fetchWhiteLabelIsBalancingAllDataCountAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelIsBalancingAllDataCount(payload))

    yield put(fetchWhiteLabelIsBalancingAllDataCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsBalancingAllDataCountFailure(err))
  }
}

export function* fetchWhiteLabelIsBalancingYearlyTransactionGraphAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsBalancingYearlyTransactionGraph(payload)
    )

    yield put(fetchWhiteLabelIsBalancingYearlyTransactionGraphSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsBalancingYearlyTransactionGraphFailure(err))
  }
}

export function* fetchWhiteLabelIsBalancingYearlyTransactionGraphAsCPSignerAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSigner(payload)
    )

    yield put(
      fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerSuccess(
        data
      )
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(
      fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerFailure(err)
    )
  }
}

//whiteLabel isIntroducer

export function* fetchWhiteLabelIsIntroducerTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsIntroducerTodaySummary(payload)
    )

    yield put(fetchWhiteLabelIsIntroducerTodaySummarySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsIntroducerTodaySummaryFailure(err))
  }
}

export function* fetchWhiteLabelIsIntroducerFriendshipRevenueTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsIntroducerFriendshipRevenueToday(payload)
    )

    yield put(fetchWhiteLabelIsIntroducerFriendshipRevenueTodaySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsIntroducerFriendshipRevenueTodayFailure(err))
  }
}

export function* fetchWhiteLabelIsIntroducerMembershipRevenueTodaySummaryAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsIntroducerMembershipRevenueToday(payload)
    )

    yield put(fetchWhiteLabelIsIntroducerMembershipRevenueTodaySuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsIntroducerMembershipRevenueTodayFailure(err))
  }
}

export function* fetchSalesAgentTotalUserCountAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSalesAgentTotalUserCounts(payload))
    yield put(fetchSalesAgentTotalUserCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchSalesAgentTotalUserCountFailure(err))
  }
}

export function* fetchCollaborationPartnerTotalUserCountAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchCollaborationPartnerTotalUserCounts(payload)
    )

    yield put(fetchCollaborationPartnerTotalUserCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCollaborationPartnerTotalUserCountFailure(err))
  }
}

export function* fetchWhiteLabelIsIntroducerTodayRevenueAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsIntroducerTodayRevenue(payload)
    )

    yield put(fetchWhiteLabelIsIntroducerTodayRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsIntroducerTodayRevenueFailure(err))
  }
}

export function* fetchWhiteLabelIsIntroducerTotalRevenueAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsIntroducerTotalRevenue(payload)
    )

    yield put(fetchWhiteLabelIsIntroducerTotalRevenueSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsIntroducerTotalRevenueFailure(err))
  }
}

export function* fetchWhiteLabelIsIntroducerAllDataCountAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsIntroducerAllDataCount(payload)
    )

    yield put(fetchWhiteLabelIsIntroducerAllDataCountSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsIntroducerAllDataCountFailure(err))
  }
}

export function* fetchWhiteLabelIsIntroducerYearlyTransactionGraphAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchWhiteLabelIsIntroducerYearlyTransactionGraph(payload)
    )

    yield put(fetchWhiteLabelIsIntroducerYearlyTransactionGraphSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelIsIntroducerYearlyTransactionGraphFailure(err))
  }
}

export function* fetchMerchantRebateWalletAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantRebateWallet(payload))

    yield put(fetchMerchantRebateWalletSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantRebateWalletFailure(err))
  }
}

export function* fetchMerchantTransactionFeeDebitAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantTransactionFeeDebit(payload))

    yield put(fetchMerchantTransactionFeeDebitSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantTransactionFeeDebitFailure(err))
  }
}

export function* fetchCharityDashboardDataAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCharityDashboardData(payload))

    yield put(fetchCharityDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCharityDashboardDataFailure(err))
  }
}

export function* fetchClubDashboardDataAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchClubDashboardData(payload))

    yield put(fetchClubDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchClubDashboardDataFailure(err))
  }
}

export function* fetchRegionDashboardDataAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchRegionDashboardData(payload))

    yield put(fetchRegionDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchRegionDashboardDataFailure(err))
  }
}

export function* fetchCountryDashboardDataAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryDashboardData(payload))

    yield put(fetchCountryDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCountryDashboardDataFailure(err))
  }
}

export function* fetchGlobalAdminDashboardDataAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalAdminDashboardData(payload))

    yield put(fetchGlobalAdminDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminDashboardDataFailure(err))
  }
}

export function* fetchSalesAgentDashboardDataAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchSalesAgentDashboardData(payload))

    yield put(fetchSalesAgentDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchSalesAgentDashboardDataFailure(err))
  }
}

export function* fetchCpDashboardDataStartAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCpDashboardData(payload))

    yield put(fetchCpDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCpDashboardDataFailure(err))
  }
}

export function* fetchGlobalAdminWithCountryDashboardDataAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalAdminWithCountryDashboardData(payload)
    )

    yield put(fetchGlobalAdminWithCountryDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGlobalAdminWithCountryDashboardDataFailure(err))
  }
}

export function* fetchMerchantDashboardDataAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantDashboardData(payload))

    yield put(fetchMerchantDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantDashboardDataFailure(err))
  }
}

export function* fetchSupporterMerchantDashboardDataAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchSupporterMerchantDashboardData(payload))

    yield put(fetchSupporterMerchantDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchSupporterMerchantDashboardDataFailure(err))
  }
}

export function* fetchIntroducerDashboardDataAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchIntroducerDashboardData(payload))

    yield put(fetchIntroducerDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchIntroducerDashboardDataFailure(err))
  }
}

export function* fetchBalancingLevelDashboardDataAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchBalancingLevelDashboardData(payload))

    yield put(fetchBalancingLevelDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchBalancingLevelDashboardDataFailure(err))
  }
}

export function* fetchNormalWhiteLabelDashboardDataAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchNormalWhiteLabelDashboardData(payload))

    yield put(fetchNormalWhiteLabelDashboardDataSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchNormalWhiteLabelDashboardDataFailure(err))
  }
}

export function* watchCountryAdminTodayTransactionSummary() {
  yield takeLatest(
    'dashboard/fetchCountryAdminTotalTransactionAndTotalOwnerRequestsStart',
    fetchCountryAdminTodayTransactionSummaryAsync
  )
}

export function* watchGlobalAdmintotalAndTodayUserCount() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminTodaySummaryStart',
    fetchGlobalAdminTotalTodaySummaryAsync
  )
}

export function* watchGlobalAdmintotalAndTodayUserCountOfWL() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminTodaySummaryOfWLStart',
    fetchGlobalAdminTotalTodaySummaryOfWLAsync
  )
}

export function* watchGlobalAdminTotalSummaryUserCountOfWL() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminTotalSummaryOfWLStart',
    fetchGlobalAdminTotalSummaryUserCountOfWLAsync
  )
}

export function* watchFetchGlobalAdminTodaySummaryTransactionAsync() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminTodaySummaryTransactionStart',
    fetchGlobalAdminTodaySummaryTransactionAsync
  )
}

export function* watchGlobalAdminByCountryTodaySummary() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminByCountryTodaySummaryStart',
    fetchGlobalAdminByCountryTodaySummaryAsync
  )
}

export function* watchGlobalAdminByCountryTotalSummary() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminByCountryTotalSummaryStart',
    fetchGlobalAdminByCountryTotalSummaryAsync
  )
}

export function* watchGlobalAdminByCountryAllDataCount() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminByCountryAllDataCountStart',
    fetchGlobalAdminByCountryAllDataCountAsync
  )
}

export function* watchGlobalAdminByCountryTotalRevenueSummary() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminByCountryTotalRevenueSummaryStart',
    fetchGlobalAdminByCountryTotalRevenueSummaryAsync
  )
}

export function* watchGlobalAdminByCountryTotalRevenueSummaryCount() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminByCountryTotalRevenueSummaryCountStart',
    fetchGlobalAdminByCountryTotalRevenueSummaryCountAsync
  )
}

export function* watchGlobalAdminByCountryFriendshipRevenueToday() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminByCountryTodayFriendshipRevenueStart',
    fetchGlobalAdminByCountryFriendshipRevenueTodayAsync
  )
}

export function* watchGlobalAdminByCountryMembershipRevenueToday() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminByCountryTodayMembershipRevenueStart',
    fetchGlobalAdminByCountryMembershipRevenueTodayAsync
  )
}

export function* watchFetchStateAdminTotalTransactionAndTotalOwnerRequests() {
  yield takeLatest(
    'dashboard/fetchStateAdminTodaySummaryStart',
    fetchStateAdminTotalTodaySummaryAsync
  )
}

export function* watchFetchGlobalAdminTotalEarning() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminTotalEarningStart',
    fetchGlobalAdminTotalEarningAsync
  )
}

export function* watchFetchGlobalAdminYearlyTransactionGraph() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminYearlyTransactionGraphStart',
    fetchGlobalAdminYearlyTransactionGraphAsync
  )
}

export function* watchFetchGlobalAdminTotalSummaryRevenue() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminTotalSummaryRevenueStart',
    fetchGlobalAdminTotalSummaryRevenueAsync
  )
}

export function* watchFetchGlobalAdminAllDataCount() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminAllDataCountStart',
    fetchGlobalAdminAllDataCountAsync
  )
}

export function* watchFetchCountryAdminTotalEarning() {
  yield takeLatest(
    'dashboard/fetchTotalEarningStart',
    fetchCountryAdminTotalEarningAsync
  )
}

export function* watchFetchStateAdminTotalEarning() {
  yield takeLatest(
    'dashboard/fetchStateAdminTotalEarningStart',
    fetchStateAdminTotalEarningAsync
  )
}

export function* watchFetchCountryAdminAllDataCount() {
  yield takeLatest(
    'dashboard/fetchTotalUsersCountStart',
    fetchCountryAdminAllDataCountAsync
  )
}

export function* watchFetchCountryAdminOwnerDataCount() {
  yield takeLatest(
    'dashboard/fetchTotalOwnerDataCountStart',
    fetchCountryAdminOwnerDataCountAsync
  )
}

export function* watchFetchCountryAdminFriendshipTransactionSummary() {
  yield takeLatest(
    'dashboard/fetchCountryAdminFriendshipTransactionSummaryStart',
    fetchCountryAdminFriendshipTransactionSummaryAsync
  )
}

export function* watchFetchOwnerAdminFriendshipRevenueToday() {
  yield takeLatest(
    'dashboard/fetchOwnerAdminFriendshipRevenueTodayStart',
    fetchOwnerAdminFriendshipRevenueTodayAsync
  )
}

export function* watchFetchOwnerAdminMembershipRevenueToday() {
  yield takeLatest(
    'dashboard/fetchOwnerAdminMembershipRevenueTodayStart',
    fetchOwnerAdminMembershipRevenueTodayAsync
  )
}

export function* watchFetchOwnerAdminFriendshipRevenueSummary() {
  yield takeLatest(
    'dashboard/fetchOwnerAdminFriendshipRevenueSummaryStart',
    fetchOwnerAdminFriendshipRevenueSummaryAsync
  )
}

export function* watchFetchOwnerAdminMembershipRevenueSummary() {
  yield takeLatest(
    'dashboard/fetchOwnerAdminMembershipRevenueSummaryStart',
    fetchOwnerAdminMembershipRevenueSummaryAsync
  )
}

export function* watchFetchOwnerAdminRecentMerchantsSummary() {
  yield takeLatest(
    'dashboard/fetchOwnerAdminRecentMerchantsStart',
    fetchOwnerAdminRecentMerchantsAsync
  )
}

export function* watchFetchOwnerAdminRecentMerchantsForCountryDashboard() {
  yield takeLatest(
    'dashboard/fetchOwnerAdminRecentMerchantsForCountryDashboardStart',
    fetchOwnerAdminRecentMerchantsForCountryDashboardAsync
  )
}

export function* watchFetchOwnerAdminEarningGraphPerYear() {
  yield takeLatest(
    'dashboard/fetchOwnerAdminYearlyEarningStart',
    fetchOwnerAdminEarningGraphPerYearAsync
  )
}

export function* watchFetchGlobalAdminFriendshipRevenue() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminFriendshipRevenueStart',
    fetchGloblAdminFriendshipRevenueAsync
  )
}

export function* watchFetchGlobalAdminMembershipRevenue() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminMembershipRevenueStart',
    fetchGloblAdminMembershipRevenueAsync
  )
}

export function* watchFetchCountryAdminMembershipTransactionSummary() {
  yield takeLatest(
    'dashboard/fetchCountryAdminMembershipTransactionSummaryStart',
    fetchCountryAdminMembershipTransactionSummaryAsync
  )
}

export function* watchFetchCountryAdminRecentMerchants() {
  yield takeLatest(
    'dashboard/fetchRecentMerchantsStart',
    fetchCountryAdminRecentMerchantsAsync
  )
}

export function* watchFetchStateAdminRecentMembers() {
  yield takeLatest(
    'dashboard/fetchStateAdminRecentMembersStart',
    fetchStateAdminRecentMembersAsync
  )
}

export function* watchFetchCountryAdminYearlyTransactionGraph() {
  yield takeLatest(
    'dashboard/fetchCountryAdminYearlyTransactionGraphStart',
    fetchCountryAdminYearlyTransactionGraphAsync
  )
}

// export function* watchFetchGlobalAdminTodaySummary() {
//   yield takeLatest(
//     'dashboard/fetchGlobalAdminTodaySummaryStart',
//     fetchGlobalAdmintotalAndTodayUserCountAsync
//   )
// }

export function* watchMerchantAdminTodaySummary() {
  yield takeLatest(
    'dashboard/fetchMerchantAdminTodaySummaryStart',
    fetchMerchantAdminTotalTodaySummaryAsync
  )
}

export function* watchFetchSupporterMerchantAdminTodaySummary() {
  yield takeLatest(
    'dashboard/fetchSupporterMerchantAdminTodaySummaryStart',
    fetchSupporterMerchantAdminTodaySummaryAsync
  )
}

export function* watchFetchTerminalMerchantAdminTodaySummary() {
  yield takeLatest(
    'dashboard/fetchTerminalMerchantAdminTodaySummaryStart',
    fetchTerminalMerchantAdminTodaySummaryAsync
  )
}

export function* watchFetchTerminalMerchantAdminTotalSummary() {
  yield takeLatest(
    'dashboard/fetchTerminalMerchantAdminTotalSummaryStart',
    fetchTerminalMerchantAdminTotalSummaryAsync
  )
}

export function* watchMerchantAdminTotalSummary() {
  yield takeLatest(
    'dashboard/fetchMerchantAdminTotalSummaryStart',
    fetchMerchantAdminTotalummaryAsync
  )
}

export function* watchFetchSupporterMerchantAdminTotalSummary() {
  yield takeLatest(
    'dashboard/fetchSupporterMerchantAdminTotalSummaryStart',
    fetchSupporterMerchantAdminTotalSummaryAsync
  )
}

export function* watchfetchMerchantAdminYearlyTransactionGraph() {
  yield takeLatest(
    'dashboard/fetchMerchantAdminYearlyTransactionGraphStart',
    fetchMerchantAdminYearlyTransactionGraphAsync
  )
}

// sales agent watchers
export function* watchFetchSalesAgentTotalSummary() {
  yield takeLatest(
    'dashboard/fetchSalesAgentTotalSummaryStart',
    fetchSalesAgentTotalSummaryAsync
  )
}
export function* watchFetchSalesAgentDailySummary() {
  yield takeLatest(
    'dashboard/fetchSalesAgentDailySummaryStart',
    fetchSalesAgentDailySummaryAsync
  )
}

// collaboration paratner watchers
export function* watchFetchCollaborationPartnerTotalSummary() {
  yield takeLatest(
    'dashboard/fetchCollaborationPartnerTotalSummaryStart',
    fetchCollaborationPartnerTotalSummaryAsync
  )
}

export function* watchFetchCollaborationPartnerDailySummary() {
  yield takeLatest(
    'dashboard/fetchCollaborationPartnerDailySummaryStart',
    fetchCollaborationPartnerDailySummaryAsync
  )
}
export function* watchFetchMerchantAdminFriendshipRevenue() {
  yield takeLatest(
    'dashboard/fetchMerchantAdminFriendshipRevenueStart',
    fetchMerchantAdminFriendshipRevenueAsync
  )
}

export function* watchFetchMerchantAdminMembershipRevenue() {
  yield takeLatest(
    'dashboard/fetchMerchantAdminMembershipRevenueStart',
    fetchMerchantAdminMembershipRevenueAsync
  )
}

export function* watchFetchCharityTodaySummary() {
  yield takeLatest(
    'dashboard/fetchCharityTodaySummaryStart',
    fetchCharityTodaySummaryAsync
  )
}

export function* fetchCharityTotalSummary() {
  yield takeLatest(
    'dashboard/fetchCharityTotalSummaryStart',
    fetchCharityTotalSummaryAsync
  )
}

export function* fetchCharityMerchantAndMember() {
  yield takeLatest(
    'dashboard/fetchCharityMerchantAndMemberStart',
    fetchCharityMerchantAndMemberAsync
  )
}
export function* watchFetchCharityTotalRevenueSummary() {
  yield takeLatest(
    'dashboard/fetchCharityTotalRevenueSummaryStart',
    fetchCharityTotalRevenueSummaryAsync
  )
}

export function* watchFetchClubTodaySummary() {
  yield takeLatest(
    'dashboard/fetchClubTodaySummaryStart',
    fetchClubTodaySummaryAsync
  )
}

export function* watchFetchClubTotalSummary() {
  yield takeLatest(
    'dashboard/fetchTotalClubSummaryStart',
    fetchClubTotalSummaryAsync
  )
}

export function* watchFetchClubTodayRevenue() {
  yield takeLatest(
    'dashboard/fetchTodayClubRevenueStart',
    fetchTodayClubRevenueAsync
  )
}

export function* watchFetchClubTotalRevenue() {
  yield takeLatest(
    'dashboard/fetchTotalClubRevenueStart',
    fetchTotalClubRevenueAsync
  )
}

// whiteLabel General
export function* watchFetchWhiteLabelTodaySummary() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelTodaySummaryStart',
    fetchWhiteLabelTodaySummaryAsync
  )
}

export function* watchWhiteLabelFriendshipRevenueTodaySummary() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelFriendshipRevenueTodayStart',
    fetchWhiteLabelFriendshipRevenueTodaySummaryAsync
  )
}

export function* watchWhiteLabelMembershipRevenueTodaySummary() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelMembershipRevenueTodayStart',
    fetchWhiteLabelMembershipRevenueTodaySummaryAsync
  )
}

export function* watchFetchWhiteLabelTodayRevenue() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelTodayRevenueStart',
    fetchWhiteLabelTodayRevenueAsync
  )
}

export function* watchFetchWhiteLabelTotalRevenue() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelTotalRevenueStart',
    fetchWhiteLabelTotalRevenueAsync
  )
}

export function* watchFetchWhiteLabelAllDataCount() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelAllDataCountStart',
    fetchWhiteLabelAllDataCountAsync
  )
}

export function* watchWhiteLabelYearlyTransactionGraph() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelYearlyTransactionGraphStart',
    fetchWhiteLabelYearlyTransactionGraphAsync
  )
}

export function* watchWhiteLabelYearlyTransactionGraphAsCPSigner() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelYearlyTransactionGraphAsCPSignerStart',
    fetchWhiteLabelYearlyTransactionGraphAsCPSignerAsync
  )
}

// whiteLabel isBalancing
export function* watchFetchWhiteLabelIsBalancingTodaySummary() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsBalancingTodaySummaryStart',
    fetchWhiteLabelIsBalancingTodaySummaryAsync
  )
}

export function* watchWhiteLabelIsBalancingFriendshipRevenueTodaySummary() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsBalancingFriendshipRevenueTodayStart',
    fetchWhiteLabelIsBalancingFriendshipRevenueTodaySummaryAsync
  )
}

export function* watchWhiteLabelIsBalancingMembershipRevenueTodaySummary() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsBalancingMembershipRevenueTodayStart',
    fetchWhiteLabelIsBalancingMembershipRevenueTodaySummaryAsync
  )
}

export function* watchFetchWhiteLabelIsBalancingTodayRevenue() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsBalancingTodayRevenueStart',
    fetchWhiteLabelIsBalancingTodayRevenueAsync
  )
}

export function* watchFetchWhiteLabelIsBalancingTotalRevenue() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsBalancingTotalRevenueStart',
    fetchWhiteLabelIsBalancingTotalRevenueAsync
  )
}

export function* watchFetchWhiteLabelIsBalancingAllDataCount() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsBalancingAllDataCountStart',
    fetchWhiteLabelIsBalancingAllDataCountAsync
  )
}

export function* watchWhiteLabelIsBalancingYearlyTransactionGraph() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsBalancingYearlyTransactionGraphStart',
    fetchWhiteLabelIsBalancingYearlyTransactionGraphAsync
  )
}

export function* watchWhiteLabelIsBalancingYearlyTransactionGraphAsCPSigner() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerStart',
    fetchWhiteLabelIsBalancingYearlyTransactionGraphAsCPSignerAsync
  )
}

// whiteLabel isIntroducer
export function* watchFetchWhiteLabelIsIntroducerTodaySummary() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsIntroducerTodaySummaryStart',
    fetchWhiteLabelIsIntroducerTodaySummaryAsync
  )
}

export function* watchWhiteLabelIsIntroducerFriendshipRevenueTodaySummary() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsIntroducerFriendshipRevenueTodayStart',
    fetchWhiteLabelIsIntroducerFriendshipRevenueTodaySummaryAsync
  )
}

export function* watchWhiteLabelIsIntroducerMembershipRevenueTodaySummary() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsIntroducerMembershipRevenueTodayStart',
    fetchWhiteLabelIsIntroducerMembershipRevenueTodaySummaryAsync
  )
}

export function* watchFetchWhiteLabelIsIntroducerTodayRevenue() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsIntroducerTodayRevenueStart',
    fetchWhiteLabelIsIntroducerTodayRevenueAsync
  )
}

export function* watchFetchWhiteLabelIsIntroducerTotalRevenue() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsIntroducerTotalRevenueStart',
    fetchWhiteLabelIsIntroducerTotalRevenueAsync
  )
}

export function* watchFetchWhiteLabelIsIntroducerAllDataCount() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsIntroducerAllDataCountStart',
    fetchWhiteLabelIsIntroducerAllDataCountAsync
  )
}

export function* watchWhiteLabelIsIntroducerYearlyTransactionGraph() {
  yield takeLatest(
    'dashboard/fetchWhiteLabelIsIntroducerYearlyTransactionGraphStart',
    fetchWhiteLabelIsIntroducerYearlyTransactionGraphAsync
  )
}

export function* watchFetchSalesAgentTotalUserCountAsync() {
  yield takeLatest(
    'dashboard/fetchSalesAgentTotalUserCountStart',
    fetchSalesAgentTotalUserCountAsync
  )
}

export function* watchFetchCollaborationPartnerTotalUserCount() {
  yield takeLatest(
    'dashboard/fetchCollaborationPartnerTotalUserCountStart',
    fetchCollaborationPartnerTotalUserCountAsync
  )
}

export function* watchFetchMerchantRebateWallet() {
  yield takeLatest(
    'dashboard/fetchMerchantRebateWalletStart',
    fetchMerchantRebateWalletAsync
  )
}

export function* watchFetchMerchantTransactionFeeDebitAsync() {
  yield takeLatest(
    'dashboard/fetchMerchantTransactionFeeDebitStart',
    fetchMerchantTransactionFeeDebitAsync
  )
}

export function* watchFetchCharityDashboardData() {
  yield takeLatest(
    'dashboard/fetchCharityDashboardDataStart',
    fetchCharityDashboardDataAsync
  )
}

export function* watchFetchClubDashboardData() {
  yield takeLatest(
    'dashboard/fetchClubDashboardDataStart',
    fetchClubDashboardDataAsync
  )
}

export function* watchFetchRegionDashboardData() {
  yield takeLatest(
    'dashboard/fetchRegionDashboardDataStart',
    fetchRegionDashboardDataAsync
  )
}

export function* watchFetchCountryDashboardData() {
  yield takeLatest(
    'dashboard/fetchCountryDashboardDataStart',
    fetchCountryDashboardDataAsync
  )
}

export function* watchFetchGlobalAdminDashboardData() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminDashboardDataStart',
    fetchGlobalAdminDashboardDataAsync
  )
}

export function* watchFetchSalesAgentDashboardData() {
  yield takeLatest(
    'dashboard/fetchSalesAgentDashboardDataStart',
    fetchSalesAgentDashboardDataAsync
  )
}

export function* watchFetchCpDashboardData() {
  yield takeLatest(
    'dashboard/fetchCpDashboardDataStart',
    fetchCpDashboardDataStartAsync
  )
}

export function* watchFetchGlobalAdminWithCountryDashboardData() {
  yield takeLatest(
    'dashboard/fetchGlobalAdminWithCountryDashboardDataStart',
    fetchGlobalAdminWithCountryDashboardDataAsync
  )
}

export function* watchFetchMerchantDashboardDataAsync() {
  yield takeLatest(
    'dashboard/fetchMerchantDashboardDataStart',
    fetchMerchantDashboardDataAsync
  )
}

export function* watchSupporterFetchMerchantDashboardDataAsync() {
  yield takeLatest(
    'dashboard/fetchSupporterMerchantDashboardDataStart',
    fetchSupporterMerchantDashboardDataAsync
  )
}

export function* watchFetchIntroducerDashboardDataAsync() {
  yield takeLatest(
    'dashboard/fetchIntroducerDashboardDataStart',
    fetchIntroducerDashboardDataAsync
  )
}

export function* watchFetchBalancingLevelDashboardDataAsync() {
  yield takeLatest(
    'dashboard/fetchBalancingLevelDashboardDataStart',
    fetchBalancingLevelDashboardDataAsync
  )
}

export function* watchFetchNormalWhiteLabelDashboardData() {
  yield takeLatest(
    'dashboard/fetchNormalWhiteLabelDashboardDataStart',
    fetchNormalWhiteLabelDashboardDataAsync
  )
}

export function* dashboardSagas() {
  yield all([
    call(watchGlobalAdmintotalAndTodayUserCount),
    call(watchFetchCountryAdminTotalEarning),
    call(watchFetchCountryAdminAllDataCount),
    call(watchFetchCountryAdminOwnerDataCount),
    call(watchFetchCountryAdminFriendshipTransactionSummary),
    call(watchFetchCountryAdminMembershipTransactionSummary),
    call(watchFetchCountryAdminRecentMerchants),
    call(watchFetchCountryAdminYearlyTransactionGraph),
    call(watchFetchStateAdminTotalTransactionAndTotalOwnerRequests),
    call(watchFetchOwnerAdminFriendshipRevenueToday),
    call(watchFetchOwnerAdminMembershipRevenueToday),
    call(watchFetchOwnerAdminFriendshipRevenueSummary),
    call(watchFetchOwnerAdminMembershipRevenueSummary),
    call(watchFetchOwnerAdminEarningGraphPerYear),
    call(watchFetchOwnerAdminRecentMerchantsSummary),
    call(watchFetchGlobalAdminFriendshipRevenue),
    call(watchFetchGlobalAdminMembershipRevenue),
    call(watchFetchStateAdminTotalEarning),
    call(watchFetchStateAdminRecentMembers),
    call(watchFetchGlobalAdminTotalEarning),
    call(watchFetchGlobalAdminYearlyTransactionGraph),
    call(watchFetchGlobalAdminTotalSummaryRevenue),
    call(watchFetchGlobalAdminAllDataCount),
    call(watchCountryAdminTodayTransactionSummary),
    call(watchMerchantAdminTodaySummary),
    call(watchfetchMerchantAdminYearlyTransactionGraph),
    call(watchMerchantAdminTotalSummary),
    call(watchGlobalAdminByCountryTodaySummary),
    call(watchGlobalAdminByCountryTotalSummary),
    call(watchGlobalAdminByCountryTotalRevenueSummary),
    call(watchGlobalAdminByCountryTotalRevenueSummaryCount),
    call(watchGlobalAdminByCountryFriendshipRevenueToday),
    call(watchGlobalAdminByCountryMembershipRevenueToday),
    call(watchGlobalAdminByCountryAllDataCount),
    call(watchFetchSalesAgentTotalSummary),
    call(watchFetchSalesAgentDailySummary),
    call(watchFetchCollaborationPartnerTotalSummary),
    call(watchFetchCollaborationPartnerDailySummary),
    call(watchFetchSalesAgentTotalUserCountAsync),
    call(watchFetchCollaborationPartnerTotalUserCount),
    call(watchFetchMerchantAdminMembershipRevenue),
    call(watchFetchMerchantAdminFriendshipRevenue),
    call(watchFetchCharityTodaySummary),
    call(fetchCharityTotalSummary),
    call(watchFetchCharityTotalRevenueSummary),
    call(fetchCharityMerchantAndMember),
    call(watchFetchClubTodaySummary),
    call(watchFetchClubTotalSummary),
    call(watchFetchClubTodayRevenue),
    call(watchFetchClubTotalRevenue),
    //whiteLabel General
    call(watchFetchWhiteLabelTodaySummary),
    call(watchWhiteLabelFriendshipRevenueTodaySummary),
    call(watchWhiteLabelMembershipRevenueTodaySummary),
    call(watchFetchWhiteLabelTodayRevenue),
    call(watchFetchWhiteLabelTotalRevenue),
    call(watchFetchWhiteLabelAllDataCount),
    call(watchWhiteLabelYearlyTransactionGraph),
    call(watchWhiteLabelYearlyTransactionGraphAsCPSigner),
    //whiteLabel isBalancing
    call(watchFetchWhiteLabelIsBalancingTodaySummary),
    call(watchWhiteLabelIsBalancingFriendshipRevenueTodaySummary),
    call(watchWhiteLabelIsBalancingMembershipRevenueTodaySummary),
    call(watchFetchWhiteLabelIsBalancingTodayRevenue),
    call(watchFetchWhiteLabelIsBalancingTotalRevenue),
    call(watchFetchWhiteLabelIsBalancingAllDataCount),
    call(watchWhiteLabelIsBalancingYearlyTransactionGraph),
    call(watchWhiteLabelIsBalancingYearlyTransactionGraphAsCPSigner),
    //whiteLabel isIntroducer
    call(watchFetchWhiteLabelIsIntroducerTodaySummary),
    call(watchWhiteLabelIsIntroducerFriendshipRevenueTodaySummary),
    call(watchWhiteLabelIsIntroducerMembershipRevenueTodaySummary),
    call(watchFetchWhiteLabelIsIntroducerTodayRevenue),
    call(watchFetchWhiteLabelIsIntroducerTotalRevenue),
    call(watchFetchWhiteLabelIsIntroducerAllDataCount),
    call(watchWhiteLabelIsIntroducerYearlyTransactionGraph),
    call(watchFetchMerchantRebateWallet),
    call(watchFetchTerminalMerchantAdminTodaySummary),
    call(watchFetchTerminalMerchantAdminTotalSummary),
    call(watchFetchSupporterMerchantAdminTodaySummary),
    call(watchFetchSupporterMerchantAdminTotalSummary),
    call(watchFetchGlobalAdminTodaySummaryTransactionAsync),
    call(watchGlobalAdmintotalAndTodayUserCountOfWL),
    call(watchGlobalAdminTotalSummaryUserCountOfWL),
    call(watchFetchMerchantTransactionFeeDebitAsync),
    call(watchFetchCharityDashboardData),
    call(watchFetchClubDashboardData),
    call(watchFetchRegionDashboardData),
    call(watchFetchCountryDashboardData),
    call(watchFetchGlobalAdminDashboardData),
    call(watchFetchSalesAgentDashboardData),
    call(watchFetchCpDashboardData),
    call(watchFetchGlobalAdminWithCountryDashboardData),

    call(watchFetchMerchantDashboardDataAsync),
    call(watchFetchIntroducerDashboardDataAsync),
    call(watchFetchBalancingLevelDashboardDataAsync),
    call(watchFetchNormalWhiteLabelDashboardData),
    call(watchFetchOwnerAdminRecentMerchantsForCountryDashboard),
    call(watchSupporterFetchMerchantDashboardDataAsync),
  ])
}
