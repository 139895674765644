import { createSlice } from '@reduxjs/toolkit'

import { IPremiumCodesState } from '../interfaces'

import { listObj } from 'utils'

const INITIAL_STATE: IPremiumCodesState = {
  premiumCodeList: listObj,
  memberPremiumCodeTransactionList: listObj,
  assignablePremiumMerchantCountObj: {},
  assignablePremiumMemberCountObj: {},
  error: null,
  loading: false,
  isFetching: false,
}

export const premiumCodesSlice = createSlice({
  name: 'premiumCodes',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPremiumCodesStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumCodesSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumCodesFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    // by panel
    fetchMerchantPremiumCodesByPanelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantPremiumCodesByPanelSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.loading = false
    },
    fetchMerchantPremiumCodesByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchMemberPremiumCodesByPanelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMemberPremiumCodesByPanelSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.loading = false
    },
    fetchMemberPremiumCodesByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantPremiumCodesStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantPremiumCodesSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchMerchantPremiumCodesFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchPremiumMerchantCodesListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumMerchantCodesListSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumMerchantCodesListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchPremiumMerchantCodesGetAllStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumMerchantCodesGetAllSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumMerchantCodesGetAllFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchPremiumMemberCodesGetAllStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumMemberCodesGetAllSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumMemberCodesGetAllFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchPremiumMemberCodesListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchPremiumMemberCodesListSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    fetchPremiumMemberCodesListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    updatePremiumMerchantCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    updatePremiumMerchantCodeSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    updatePremiumMerchantCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    updatePremiumMemberCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    updatePremiumMemberCodeSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    updatePremiumMemberCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createPremiumMemberCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    createPremiumMemberCodeSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    createPremiumMemberCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createPremiumMerchantCodeStart: (state: any, action) => {
      state.isFetching = true
    },
    createPremiumMerchantCodeSuccess: (state: any, action) => {
      state.premiumCodeList = action.payload
      state.isFetching = false
    },
    createPremiumMerchantCodeFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    fetchAllMemberPremiumCodeTransactionStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllMemberPremiumCodeTransactionSuccess: (state: any, action) => {
      state.memberPremiumCodeTransactionList = action.payload
      state.isFetching = false
    },
    fetchAllMemberPremiumCodeTransactionFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAssignablePremiumMerchantCountStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAssignablePremiumMerchantCountSuccess: (state: any, action) => {
      state.assignablePremiumMerchantCountObj = action.payload
      state.isFetching = false
    },
    fetchAssignablePremiumMerchantCountFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAssignablePremiumMemberCountStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAssignablePremiumMemberCountSuccess: (state: any, action) => {
      state.assignablePremiumMemberCountObj = action.payload
      state.isFetching = false
    },
    fetchAssignablePremiumMemberCountFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const {
  fetchPremiumCodesStart,
  fetchPremiumCodesSuccess,
  fetchPremiumCodesFailure,
  fetchMerchantPremiumCodesByPanelStart,
  fetchMerchantPremiumCodesByPanelSuccess,
  fetchMerchantPremiumCodesByPanelFailure,
  fetchMemberPremiumCodesByPanelStart,
  fetchMemberPremiumCodesByPanelSuccess,
  fetchMemberPremiumCodesByPanelFailure,
  fetchMerchantPremiumCodesStart,
  fetchMerchantPremiumCodesSuccess,
  fetchMerchantPremiumCodesFailure,
  fetchPremiumMerchantCodesListStart,
  fetchPremiumMerchantCodesListSuccess,
  fetchPremiumMerchantCodesListFailure,
  fetchPremiumMerchantCodesGetAllStart,
  fetchPremiumMerchantCodesGetAllSuccess,
  fetchPremiumMerchantCodesGetAllFailure,
  fetchPremiumMemberCodesGetAllStart,
  fetchPremiumMemberCodesGetAllSuccess,
  fetchPremiumMemberCodesGetAllFailure,
  fetchPremiumMemberCodesListStart,
  fetchPremiumMemberCodesListSuccess,
  fetchPremiumMemberCodesListFailure,
  updatePremiumMerchantCodeStart,
  updatePremiumMerchantCodeSuccess,
  updatePremiumMerchantCodeFailure,
  updatePremiumMemberCodeStart,
  updatePremiumMemberCodeSuccess,
  updatePremiumMemberCodeFailure,
  createPremiumMemberCodeStart,
  createPremiumMemberCodeSuccess,
  createPremiumMemberCodeFailure,
  createPremiumMerchantCodeStart,
  createPremiumMerchantCodeSuccess,
  createPremiumMerchantCodeFailure,
  fetchAllMemberPremiumCodeTransactionStart,
  fetchAllMemberPremiumCodeTransactionSuccess,
  fetchAllMemberPremiumCodeTransactionFailure,

  fetchAssignablePremiumMerchantCountStart,
  fetchAssignablePremiumMerchantCountSuccess,
  fetchAssignablePremiumMerchantCountFailure,

  fetchAssignablePremiumMemberCountStart,
  fetchAssignablePremiumMemberCountSuccess,
  fetchAssignablePremiumMemberCountFailure,
} = premiumCodesSlice.actions

export default premiumCodesSlice.reducer
