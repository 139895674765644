import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { isQuillEmpty } from 'utils'

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [
      {
        color: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          '#1BC5FF',
          '#00bfcd',
          '#ec4684',
          '#1BC5FF',
        ],
      },
      {
        background: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          '#1BC5FF',
          '#00bfcd',
          '#ec4684',
          '#1BC5FF',
        ],
      },
    ],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
  ],
}

const RichTextEditor = ({ markup, setMarkup, ...rest }) => {
  const theme = useTheme()

  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ReactQuill
      theme="snow"
      readOnly={rest?.disabled ? true : false}
      value={markup}
      modules={modules}
      style={{ height: '160px', marginBottom: matches ? '5rem' : '3rem' }}
      // onChange={setMarkup}
      onChange={(content, delta, source, editor) => {
        const data = editor.getHTML()
        setMarkup(isQuillEmpty(data) ? '' : data)
        // console.log({ event, editor, data })
      }}
      onBlur={(event, editor) => {
        console.log('Blur.', editor)
      }}
      onFocus={(event, editor) => {
        console.log('Focus.', editor)
      }}
    />
  )
}

export default RichTextEditor

// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

// const RichTextEditor = ({ markup, setMarkup, ...rest }) => {
//   return (
//     <CKEditor
//       editor={ClassicEditor}
//       data={markup}
//       disabled={rest?.disabled ? true : false}
//       onReady={(editor) => {
//         // You can store the "editor" and use when it is needed.
//         console.log('Editor is ready to use!', editor)
//       }}
//       onChange={(event, editor) => {
//         const data = editor.getData()
//         console.log('text editor>>>', data)
//         setMarkup(data)
//         console.log({ event, editor, data })
//       }}
//       onBlur={(event, editor) => {
//         console.log('Blur.', editor)
//       }}
//       onFocus={(event, editor) => {
//         console.log('Focus.', editor)
//       }}
//     />
//   )
// }

// export default RichTextEditor
