import React, { useState, useEffect } from 'react'
import {
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  Typography,
  Skeleton,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import AppDialog from 'components/DialogCustom'
import AppComponentCard from '@crema/core/AppComponentCard'
import Previews from '../Previews/index'
import container from './EditSection.container'
import { Form, Formik, Field, FieldArray } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'

import CountrySelect from 'components/DropdownComponents/CountrySelect'
import * as yup from 'yup'
import _ from 'lodash'
import RichTextEditor from 'components/RichTextEditor'
import ListDetails from '../ListDetails'

interface EditSectionProps {
  isEditSectionOpen: boolean
  onCloseEditSection: () => void
  onEditSectionStart: (params) => void
  onFetchSectionByIdStart: (params) => void
  selectedSection?: any
  onOpenAlert: (params) => void
  onDeleteAboutUsBySlugStart?: (params) => void
  websiteAdmin?: any
  refreshPageData?: any
}

const EditSection: React.FC<EditSectionProps> = ({
  isEditSectionOpen,
  onCloseEditSection,
  onEditSectionStart,
  onFetchSectionByIdStart,
  selectedSection,
  websiteAdmin: { SectionList, soleSection, isFetching, isEditing },
  onOpenAlert,
  onDeleteAboutUsBySlugStart,
  refreshPageData,
}) => {
  const [description, setDescriptionMarkupContent] = useState('')
  const [imagesExistInList, setImagesExistInList] = useState(true)
  const [newContentAdded, setNewContentAdded] = useState<boolean>(false)
  const [shortDescriptionContent, setShortDescriptionContent] = useState('')

  useEffect(() => {
    if (selectedSection?.briefDescription) {
      setTimeout(() => {
        setShortDescriptionContent(selectedSection?.shortDescription || '')
        setDescriptionMarkupContent(selectedSection?.briefDescription || '')
      }, 2000)
    }
  }, [selectedSection])

  useEffect(() => {
    if (selectedSection.id) {
      onFetchSectionByIdStart(selectedSection.id)
    }
    // eslint-disable-next-line
  }, [selectedSection.id])
  const [uploadedFiles, setUploadedFiles] = useState<any>([])

  const validationSchema = yup.object({})

  const closeModal = () => {
    onCloseEditSection()
  }

  useEffect(() => {
    if (!_.isEmpty(selectedSection?.imageUrl)) {
      setTimeout(() => {
        setUploadedFiles([
          { preview: selectedSection?.imageUrl, type: 'image/png,video/mp4' },
        ])
      }, 2000)
    }
  }, [selectedSection?.imageUrl])

  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isEditSectionOpen}
      onClose={() => onCloseEditSection()}
      title="Edit About Us"
    >
      <>
        <Formik
          validateOnChange={true}
          enableReinitialize
          initialValues={{
            title: selectedSection?.title,
            shortDescription: selectedSection?.shortDescription,
            imageDescription: selectedSection?.imageDescription,
            briefDescription: selectedSection?.briefDescription,

            list: selectedSection?.__aboutUsContentlists__?.length
              ? selectedSection?.__aboutUsContentlists__
                  ?.map((item) => {
                    return {
                      ...item,
                      aboutlisImageUrl: [
                        { preview: item?.aboutlisImageUrl, type: 'image/png' },
                      ],
                    }
                  })
                  ?.sort((a, b) => a?.order - b?.order)
              : [
                  {
                    order: 0,
                    aboutlistTitle: null,
                    aboutlistDescription: null,
                    aboutlisImageUrl: [],
                  },
                ],

            isTranslateToAlllang: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true)
            if (!uploadedFiles?.length) {
              return onOpenAlert({
                message: 'Please upload Section image',
                severity: 'error',
              })
            }

            if (!shortDescriptionContent?.length) {
              return onOpenAlert({
                message: 'Short Description is required',
                severity: 'error',
              })
            }

            if (!description?.length) {
              return onOpenAlert({
                message: 'Long Description is required',
                severity: 'error',
              })
            }

            if (data?.list?.length) {
              data?.list?.map((item) => {
                if (!item?.aboutlisImageUrl?.length) {
                  setImagesExistInList(false)
                  return onOpenAlert({
                    message: `Please upload image of ${item?.aboutlistTitle}`,
                    severity: 'error',
                  })
                }
              })
            }

            if (imagesExistInList == false) {
              return
            }

            onEditSectionStart({
              params: {
                ...data,
                lang: selectedSection?.lang?.split(/[()]/)?.[1],
                imgUrl:
                  (uploadedFiles.length && uploadedFiles[0]?.path) ||
                  uploadedFiles[0]?.preview
                    ? uploadedFiles[0]
                    : {},
                list: data?.list?.length
                  ? data?.list?.map((item) => {
                      return {
                        ...item,
                        aboutlisImageUrl:
                          item?.aboutlisImageUrl?.length &&
                          item?.aboutlisImageUrl[0]?.size
                            ? item?.aboutlisImageUrl
                            : item?.aboutlisImageUrl[0]?.preview,
                      }
                    })
                  : [],
                shortDescription: shortDescriptionContent?.length
                  ? shortDescriptionContent
                  : null,

                briefDescription: description?.length ? description : null,
              },
              oldListData: selectedSection?.__aboutUsContentlists__?.length
                ? selectedSection?.__aboutUsContentlists__
                : [],
              id: selectedSection?.id,
              closeModal: onCloseEditSection,
              refreshPageData,
            })
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <Box sx={{ padding: 6 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12} lg={12}>
                    <AppTextField
                      label="Title"
                      name="title"
                      required
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  {/* <Grid item xs={12} lg={12}>
                    <AppTextField
                      label="Short Description"
                      name="shortDescription"
                      multiline={true}
                      rows={3}
                      required
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <Typography variant="h5" sx={{ padding: 2 }}>
                      Short Description *
                    </Typography>
                    <RichTextEditor
                      required
                      // markup={values.briefDescription}
                      // setMarkup={(val) => setFieldValue('briefDescription', val)}

                      markup={shortDescriptionContent}
                      setMarkup={setShortDescriptionContent}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" sx={{ padding: 2 }}>
                      Brief Description *
                    </Typography>
                    <RichTextEditor
                      markup={description}
                      setMarkup={setDescriptionMarkupContent}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{}}>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ mt: 2, mb: 5 }}
                    >
                      Upload Section Image/Video *
                    </Typography>
                    <Previews
                      setUploadedFiles={setUploadedFiles} /* eslint-disable */
                      uploadedFiles={uploadedFiles}
                      defaultImage={[
                        {
                          preview: selectedSection?.imageUrl,
                          type: 'image/png,video/mp4',
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <AppTextField
                      label="Image Description"
                      name="imageDescription"
                      multiline={true}
                      rows={3}
                      required
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <FieldArray
                      name="list"
                      render={(arrayHelpers) => (
                        <div>
                          {values?.list?.map((item, index) => (
                            <>
                              <ListDetails
                                arrayHelpers={arrayHelpers}
                                key={index}
                                values={values}
                                index={index}
                                item={item}
                                setFieldValue={setFieldValue}
                                onDeleteAboutUsBySlugStart={
                                  onDeleteAboutUsBySlugStart
                                }
                                refreshPageData={refreshPageData}
                                isEditForm={true}
                                selectedSectionLangugage={
                                  selectedSection?.lang?.split(/[()]/)?.[1]
                                }
                                setNewContentAdded={setNewContentAdded}
                              />
                            </>
                          ))}
                        </div>
                      )}
                    />
                  </Grid>

                  {selectedSection?.lang?.split(/[()]/)?.[1] === 'en' && (
                    <Grid item container xs={12}>
                      <Grid item>
                        <label>
                          <Field
                            type="checkbox"
                            name="isTranslateToAlllang"
                            component={Checkbox}
                            size="small"
                            required={newContentAdded}
                            onChange={() =>
                              setFieldValue(
                                'isTranslateToAlllang',
                                !values.isTranslateToAlllang
                              )
                            }
                          />
                        </label>
                      </Grid>
                      <Grid item>
                        <Box sx={{ mt: 2.3 }}>
                          <b>
                            Do you want to implement this change in all
                            languages?
                          </b>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Box sx={{ height: 20 }} />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: '2rem',
                  }}
                >
                  <Button
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      background: '#00bfcd',
                      '&:hover': {
                        background: '#ec4785',
                      },
                      px: 5,
                    }}
                    type="submit"
                    disabled={isEditing}
                  >
                    {isEditing ? <CircularProgress size={20} /> : 'Save'}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    onClick={() => onCloseEditSection()}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </>
    </AppDialog>
  )
}

export default container(EditSection)
