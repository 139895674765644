import { createSlice } from '@reduxjs/toolkit'

import { IDashboard } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IDashboard = {
  stateAdminTodaySummary: listObj,
  totalEarning: listObj,
  totalUsersCount: listObj,
  totalOwnerData: listObj,
  countryAdminFriendshipTransactionSummary: listObj,
  countryAdminMembershipTransactionSummary: listObj,
  recentMerchants: listObj,
  stateAdminRecentMembers: listObj,
  countryAdminyearlyTransactionGraph: listObj,
  globalAdminTodaySummary: listObj,
  globalAdminTodaySummaryOfWL: listObj,
  globalAdminTotalSummaryOfWL: listObj,
  globalAdminTodaySummaryTransaction: listObj,
  ownerAdminFriendshipRevenueToday: listObj,
  ownerAdminMemebershipRevenueToday: listObj,
  ownerAdminYearlyEarning: listObj,
  merchantAdminTodaySummary: listObj,
  supporterMerchantAdminTodaySummary: listObj,
  terminalmerchantAdminTodaySummary: listObj,
  terminalMerchantAdminTotalSummary: listObj,
  merchantAdminRebateWalletSummary: listObj,
  merchantAdminYearlyTransactionGraph: listObj,
  merchantAdminTotalSummary: listObj,
  supporterMerchantAdminTotalSummary: listObj,
  merchantAdminFriendshipRevenueToday: listObj,
  merchantAdminMembershipRevenueToday: listObj,

  ownerAdminFriendshipRevenueSummary: listObj,
  ownerAdminMembershipRevenueSummary: listObj,
  stateAdminTotalEarning: listObj,

  ownerAdminRecentMerchants: listObj,

  globalAdminFriendshipRevenue: listObj,
  globalAdminMembershipRevenue: listObj,
  globalAdminTotalEarning: listObj,

  globalAdminyearlyTransactionGraph: listObj,
  globalAdminTotalSummaryRevenue: listObj,
  globalAdminAllDataCount: listObj,

  globalAdminWithinCountryTodaySummary: listObj,
  globalAdminWithinCountryTotalSummary: listObj,
  globalAdminWithinCountryTotalRevenueSummary: listObj,
  globalAdminWithinCountryTotalRevenueSummaryCount: listObj,
  globalAdminWithinCountryTodayFriendshipRevenue: listObj,
  globalAdminWithinCountryTodayMembershipRevenue: listObj,
  globalAdminWithinCountryTotalSummaryAllDataCount: listObj,

  countryAdminTotalTransactionAndTotalOwnerRequests: listObj,

  salesAgentTotalSummary: listObj,
  salesAgentDailySummary: listObj,
  salesAgentTotalUserCount: listObj,

  collaborationPartnerTotalSummary: listObj,
  collaborationPartnerDailySummary: listObj,
  collaborationPartnerTotalUserCount: listObj,

  charityTodaySummary: listObj,
  totalCharitySummary: listObj,
  totalRevenueSummary: listObj,
  merchantAndMemberCount: listObj,

  clubTodaySummary: listObj,
  totalClubSummary: listObj,
  totalClubRevenue: listObj,
  todayClubRevenue: listObj,

  //  whiteLabel General
  whiteLabelTodaySummary: listObj,
  whiteLabelFriendshipRevenueToday: listObj,
  whiteLabelMembershipRevenueToday: listObj,
  whiteLabelTodayRevenue: listObj,
  whiteLabelTotalRevenue: listObj,
  whiteLabelAllDataCount: listObj,
  whiteLabelYearlyTransaction: listObj,
  whiteLabelYearlyTransactionAsCPSigner: listObj,

  //whiteLabel IsBalancing
  whiteLabelIsBalancingTodaySummary: listObj,
  whiteLabelIsBalancingFriendshipRevenueToday: listObj,
  whiteLabelIsBalancingMembershipRevenueToday: listObj,
  whiteLabelIsBalancingTodayRevenue: listObj,
  whiteLabelIsBalancingTotalRevenue: listObj,
  whiteLabelIsBalancingYearlyTransaction: listObj,
  whiteLabelIsBalancingYearlyTransactionAsCPSigner: listObj,
  whiteLabelIsBalancingAllDataCount: listObj,

  //whiteLabel IsIntroducer
  whiteLabelIsIntroducerTodaySummary: listObj,
  whiteLabelIsIntroducerFriendshipRevenueToday: listObj,
  whiteLabelIsIntroducerMembershipRevenueToday: listObj,
  whiteLabelIsIntroducerTodayRevenue: listObj,
  whiteLabelIsIntroducerTotalRevenue: listObj,
  whiteLabelIsIntroducerAllDataCount: listObj,
  whiteLabelIsIntroducerYearlyTransaction: listObj,

  merchantTransactionFeeDebit: listObj,

  charityDashboardData: listObj,
  clubDashboardData: listObj,
  dashboardData: listObj,

  error: null,
  isFetching: false,
  loading: false,
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCountryAdminTotalTransactionAndTotalOwnerRequestsStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountryAdminTotalTransactionAndTotalOwnerRequestsSuccess: (
      state: any,
      action
    ) => {
      state.countryAdminTotalTransactionAndTotalOwnerRequests = action.payload
      state.loading = false
    },
    fetchCountryAdminTotalTransactionAndTotalOwnerRequestsFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchTotalEarningStart: (state: any, action) => {
      state.loading = true
    },
    fetchTotalEarningSuccess: (state: any, action) => {
      state.totalEarning = action.payload
      state.loading = false
    },
    fetchTotalEarningFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchTotalUsersCountStart: (state: any, action) => {
      state.loading = true
    },
    fetchTotalUsersCountSuccess: (state: any, action) => {
      state.totalUsersCount = action.payload
      state.loading = false
    },
    fetchTotalUsersCountFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchTotalOwnerDataCountStart: (state: any, action) => {
      state.loading = true
    },
    fetchTotalOwnerDataCountSuccess: (state: any, action) => {
      state.totalOwnerData = action.payload
      state.loading = false
    },
    fetchTotalOwnerDataCountFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountryAdminFriendshipTransactionSummaryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountryAdminFriendshipTransactionSummarySuccess: (
      state: any,
      action
    ) => {
      state.countryAdminFriendshipTransactionSummary = action.payload
      state.loading = false
    },
    fetchCountryAdminFriendshipTransactionSummaryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryAdminMembershipTransactionSummaryStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchCountryAdminMembershipTransactionSummarySuccess: (
      state: any,
      action
    ) => {
      state.countryAdminMembershipTransactionSummary = action.payload
      state.loading = false
    },
    fetchCountryAdminMembershipTransactionSummaryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchRecentMerchantsStart: (state: any, action) => {
      state.loading = true
    },
    fetchRecentMerchantsSuccess: (state: any, action) => {
      state.recentMerchants = action.payload
      state.loading = false
    },
    fetchRecentMerchantsFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCountryAdminYearlyTransactionGraphStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryAdminYearlyTransactionGraphSuccess: (state: any, action) => {
      state.countryAdminyearlyTransactionGraph = action.payload
      state.loading = false
    },
    fetchCountryAdminYearlyTransactionGraphFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminYearlyTransactionGraphStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminYearlyTransactionGraphSuccess: (state: any, action) => {
      state.globalAdminyearlyTransactionGraph = action.payload
      state.loading = false
    },
    fetchGlobalAdminYearlyTransactionGraphFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchGlobalAdminTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminTodaySummarySuccess: (state: any, action) => {
      state.globalAdminTodaySummary = action.payload
      state.loading = false
    },
    fetchGlobalAdminTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminTodaySummaryOfWLStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminTodaySummaryOfWLSuccess: (state: any, action) => {
      state.globalAdminTodaySummaryOfWL = action.payload
      state.loading = false
    },
    fetchGlobalAdminTodaySummaryOfWLFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminTotalSummaryOfWLStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminTotalSummaryOfWLSuccess: (state: any, action) => {
      state.globalAdminTotalSummaryOfWL = action.payload
      state.loading = false
    },
    fetchGlobalAdminTotalSummaryOfWLFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminTodaySummaryTransactionStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminTodaySummaryTransactionSuccess: (state: any, action) => {
      state.globalAdminTodaySummaryTransaction = action.payload
      state.loading = false
    },
    fetchGlobalAdminTodaySummaryTransactionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminTotalSummaryRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminTotalSummaryRevenueSuccess: (state: any, action) => {
      state.globalAdminTotalSummaryRevenue = action.payload
      state.loading = false
    },
    fetchGlobalAdminTotalSummaryRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchStateAdminTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchStateAdminTodaySummarySuccess: (state: any, action) => {
      state.stateAdminTodaySummary = action.payload
      state.loading = false
    },
    fetchStateAdminTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // fetchStateAdminFriendshipRevenueStart: (state: any, action) => {
    //   state.loading = true
    // },
    // fetchStateAdminFriendshipRevenueSuccess: (state: any, action) => {
    //   state.stateAdminFriendshipRevenue = action.payload
    //   state.loading = false
    // },
    // fetchStateAdminFriendshipRevenueFailure: (state: any, action) => {
    //   state.loading = false
    //   state.error = action.payload
    // },

    // owernAdminFriendshipRevenueToday

    fetchOwnerAdminFriendshipRevenueTodayStart: (state: any, action) => {
      state.loading = true
    },
    fetchOwnerAdminFriendshipRevenueTodaySuccess: (state: any, action) => {
      state.ownerAdminFriendshipRevenueToday = action.payload
      state.loading = false
    },
    fetchOwnerAdminFriendshipRevenueTodayFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchOwnerAdminMembershipRevenueTodayStart: (state: any, action) => {
      state.loading = true
    },
    fetchOwnerAdminMembershipRevenueTodaySuccess: (state: any, action) => {
      state.ownerAdminMemebershipRevenueToday = action.payload
      state.loading = false
    },
    fetchOwnerAdminMembershipRevenueTodayFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchOwnerAdminFriendshipRevenueSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchOwnerAdminFriendshipRevenueSummarySuccess: (state: any, action) => {
      state.ownerAdminFriendshipRevenueSummary = action.payload
      state.loading = false
    },
    fetchOwnerAdminFriendshipRevenueSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchOwnerAdminMembershipRevenueSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchOwnerAdminMembershipRevenueSummarySuccess: (state: any, action) => {
      state.ownerAdminMembershipRevenueSummary = action.payload
      state.loading = false
    },
    fetchOwnerAdminMembershipRevenueSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchOwnerAdminRecentMerchantsStart: (state: any, action) => {
      state.loading = true
    },
    fetchOwnerAdminRecentMerchantsSuccess: (state: any, action) => {
      state.ownerAdminRecentMerchants = action.payload
      state.loading = false
    },
    fetchOwnerAdminRecentMerchantsFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchOwnerAdminRecentMerchantsForCountryDashboardStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchOwnerAdminRecentMerchantsForCountryDashboardSuccess: (
      state: any,
      action
    ) => {
      state.ownerAdminRecentMerchants = action.payload
      state.loading = false
    },
    fetchOwnerAdminRecentMerchantsForCountryDashboardFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchOwnerAdminYearlyEarningStart: (state: any, action) => {
      state.loading = true
    },
    fetchOwnerAdminYearlyEarningSuccess: (state: any, action) => {
      state.ownerAdminYearlyEarning = action.payload
      state.loading = false
    },
    fetchOwnerAdminYearlyEarningFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchStateAdminMembershipRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchStateAdminMembershipRevenueSuccess: (state: any, action) => {
      state.stateAdminMembershipRevenue = action.payload
      state.loading = false
    },
    fetchStateAdminMembershipRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchStateAdminTotalEarningStart: (state: any, action) => {
      state.loading = true
    },
    fetchStateAdminTotalEarningSuccess: (state: any, action) => {
      state.stateAdminTotalEarning = action.payload
      state.loading = false
    },
    fetchStateAdminTotalEarningFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminTotalEarningStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminTotalEarningSuccess: (state: any, action) => {
      state.globalAdminTotalEarning = action.payload
      state.loading = false
    },
    fetchGlobalAdminTotalEarningFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchStateAdminRecentMembersStart: (state: any, action) => {
      state.loading = true
    },
    fetchStateAdminRecentMembersSuccess: (state: any, action) => {
      state.stateAdminRecentMembers = action.payload
      state.loading = false
    },
    fetchStateAdminRecentMembersFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminFriendshipRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminFriendshipRevenueSuccess: (state: any, action) => {
      state.globalAdminFriendshipRevenue = action.payload
      state.loading = false
    },
    fetchGlobalAdminFriendshipRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminMembershipRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminMembershipRevenueSuccess: (state: any, action) => {
      state.globalAdminMembershipRevenue = action.payload
      state.loading = false
    },
    fetchGlobalAdminMembershipRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminAllDataCountStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminAllDataCountSuccess: (state: any, action) => {
      state.globalAdminAllDataCount = action.payload
      state.loading = false
    },
    fetchGlobalAdminAllDataCountFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminByCountryAllDataCountStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminByCountryAllDataCountSuccess: (state: any, action) => {
      state.globalAdminWithinCountryTotalSummaryAllDataCount = action.payload
      state.loading = false
    },
    fetchGlobalAdminByCountryAllDataCountFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantAdminTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantAdminTodaySummarySuccess: (state: any, action) => {
      state.merchantAdminTodaySummary = action.payload
      state.loading = false
    },
    fetchMerchantAdminTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchSupporterMerchantAdminTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchSupporterMerchantAdminTodaySummarySuccess: (state: any, action) => {
      state.supporterMerchantAdminTodaySummary = action.payload
      state.loading = false
    },
    fetchSupporterMerchantAdminTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchTerminalMerchantAdminTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchTerminalMerchantAdminTodaySummarySuccess: (state: any, action) => {
      state.terminalmerchantAdminTodaySummary = action.payload
      state.loading = false
    },
    fetchTerminalMerchantAdminTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantAdminTotalSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantAdminTotalSummarySuccess: (state: any, action) => {
      state.merchantAdminTotalSummary = action.payload
      state.loading = false
    },
    fetchMerchantAdminTotalSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchSupporterMerchantAdminTotalSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchSupporterMerchantAdminTotalSummarySuccess: (state: any, action) => {
      state.supporterMerchantAdminTotalSummary = action.payload
      state.loading = false
    },
    fetchSupporterMerchantAdminTotalSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchTerminalMerchantAdminTotalSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchTerminalMerchantAdminTotalSummarySuccess: (state: any, action) => {
      state.terminalMerchantAdminTotalSummary = action.payload
      state.loading = false
    },
    fetchTerminalMerchantAdminTotalSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantAdminYearlyTransactionGraphStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantAdminYearlyTransactionGraphSuccess: (state: any, action) => {
      state.merchantAdminYearlyTransactionGraph = action.payload
      state.loading = false
    },
    fetchMerchantAdminYearlyTransactionGraphFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminByCountryTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminByCountryTodaySummarySuccess: (state: any, action) => {
      state.globalAdminWithinCountryTodaySummary = action.payload
      state.loading = false
    },
    fetchGlobalAdminByCountryTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantAdminFriendshipRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantAdminFriendshipRevenueSuccess: (state: any, action) => {
      state.merchantAdminFriendshipRevenueToday = action.payload
      state.loading = false
    },
    fetchMerchantAdminFriendshipRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    // sales agent slices
    fetchSalesAgentTotalSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchSalesAgentTotalSummarySuccess: (state: any, action) => {
      state.loading = false
      state.salesAgentTotalSummary = action.payload
    },
    fetchSalesAgentTotalSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchSalesAgentTotalUserCountStart: (state: any, action) => {
      state.loading = true
    },
    fetchSalesAgentTotalUserCountSuccess: (state: any, action) => {
      state.loading = false
      state.salesAgentTotalUserCount = action.payload
    },
    fetchSalesAgentTotalUserCountFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminByCountryTotalSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminByCountryTotalSummarySuccess: (state: any, action) => {
      state.globalAdminWithinCountryTotalSummary = action.payload
      state.loading = false
    },
    fetchGlobalAdminByCountryTotalSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantAdminMembershipRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantAdminMembershipRevenueSuccess: (state: any, action) => {
      state.merchantAdminMembershipRevenueToday = action.payload
      state.loading = false
    },
    fetchMerchantAdminMembershipRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchSalesAgentDailySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchSalesAgentDailySummarySuccess: (state: any, action) => {
      state.loading = false
      state.salesAgentDailySummary = action.payload
    },
    fetchSalesAgentDailySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminByCountryTotalRevenueSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminByCountryTotalRevenueSummarySuccess: (
      state: any,
      action
    ) => {
      state.globalAdminWithinCountryTotalRevenueSummary = action.payload
      state.loading = false
    },
    fetchGlobalAdminByCountryTotalRevenueSummaryFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    // collaboration partner slices
    fetchCollaborationPartnerTotalSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCollaborationPartnerTotalSummarySuccess: (state: any, action) => {
      state.loading = false
      state.collaborationPartnerTotalSummary = action.payload
    },
    fetchCollaborationPartnerTotalSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCollaborationPartnerTotalUserCountStart: (state: any, action) => {
      state.loading = true
    },
    fetchCollaborationPartnerTotalUserCountSuccess: (state: any, action) => {
      state.loading = false
      state.collaborationPartnerTotalUserCount = action.payload
    },
    fetchCollaborationPartnerTotalUserCountFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminByCountryTotalRevenueSummaryCountStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminByCountryTotalRevenueSummaryCountSuccess: (
      state: any,
      action
    ) => {
      state.globalAdminWithinCountryTotalRevenueSummaryCount = action.payload
      state.loading = false
    },
    fetchGlobalAdminByCountryTotalRevenueSummaryCountFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCollaborationPartnerDailySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCollaborationPartnerDailySummarySuccess: (state: any, action) => {
      state.loading = false
      state.collaborationPartnerDailySummary = action.payload
    },

    fetchCollaborationPartnerDailySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminByCountryTotalSummaryCountAllStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminByCountryTotalSummaryCountAllSuccess: (
      state: any,
      action
    ) => {
      state.globalAdminWithinCountryTotalSummary = action.payload
      state.loading = false
    },
    fetchGlobalAdminByCountryTotalSummaryCountAllFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchGlobalAdminByCountryTodayFriendshipRevenueStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminByCountryTodayFriendshipRevenueSuccess: (
      state: any,
      action
    ) => {
      state.globalAdminWithinCountryTodayFriendshipRevenue = action.payload
      state.loading = false
    },
    fetchGlobalAdminByCountryTodayFriendshipRevenueFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },
    fetchGlobalAdminByCountryTodayMembershipRevenueStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchGlobalAdminByCountryTodayMembershipRevenueSuccess: (
      state: any,
      action
    ) => {
      state.globalAdminWithinCountryTodayMembershipRevenue = action.payload
      state.loading = false
    },

    fetchGlobalAdminByCountryTodayMembershipRevenueFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCharityTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCharityTodaySummarySuccess: (state: any, action) => {
      state.loading = false
      state.charityTodaySummary = action.payload
    },
    fetchCharityTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCharityTotalSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCharityTotalSummarySuccess: (state: any, action) => {
      state.loading = false
      state.totalCharitySummary = action.payload
    },
    fetchCharityTotalSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCharityMerchantAndMemberStart: (state: any, action) => {
      state.loading = true
    },
    fetchCharityMerchantAndMemberSuccess: (state: any, action) => {
      state.loading = false
      state.merchantAndMemberCount = action.payload
    },
    fetchCharityMerchantAndMemberFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCharityTotalRevenueSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchCharityTotalRevenueSummarySuccess: (state: any, action) => {
      state.loading = false
      state.totalRevenueSummary = action.payload
    },
    fetchCharityTotalRevenueSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchClubTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchClubTodaySummarySuccess: (state: any, action) => {
      state.loading = false
      state.clubTodaySummary = action.payload
    },
    fetchClubTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchTotalClubSummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchTotalClubSummarySuccess: (state: any, action) => {
      state.loading = false
      state.totalClubSummary = action.payload
    },
    fetchTotalClubSummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    //today
    fetchTodayClubRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchTodayClubRevenueSuccess: (state: any, action) => {
      state.loading = false
      state.todayClubRevenue = action.payload
    },
    fetchTodayClubRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    //total
    fetchTotalClubRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchTotalClubRevenueSuccess: (state: any, action) => {
      state.loading = false
      state.totalClubRevenue = action.payload
    },
    fetchTotalClubRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    // whiteLabel isBalancingLevel

    fetchWhiteLabelIsBalancingTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelIsBalancingTodaySummarySuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelIsBalancingTodaySummary = action.payload
    },
    fetchWhiteLabelIsBalancingTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsBalancingFriendshipRevenueTodayStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhiteLabelIsBalancingFriendshipRevenueTodaySuccess: (
      state: any,
      action
    ) => {
      state.loading = false
      state.whiteLabelIsBalancingFriendshipRevenueToday = action.payload
    },
    fetchWhiteLabelIsBalancingFriendshipRevenueTodayFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsBalancingMembershipRevenueTodayStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhiteLabelIsBalancingMembershipRevenueTodaySuccess: (
      state: any,
      action
    ) => {
      state.loading = false
      state.whiteLabelIsBalancingMembershipRevenueToday = action.payload
    },
    fetchWhiteLabelIsBalancingMembershipRevenueTodayFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsBalancingTodayRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelIsBalancingTodayRevenueSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelIsBalancingTodayRevenue = action.payload
    },
    fetchWhiteLabelIsBalancingTodayRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsBalancingTotalRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelIsBalancingTotalRevenueSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelIsBalancingTotalRevenue = action.payload
    },
    fetchWhiteLabelIsBalancingTotalRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchWhiteLabelIsBalancingAllDataCountStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelIsBalancingAllDataCountSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelIsBalancingAllDataCount = action.payload
    },
    fetchWhiteLabelIsBalancingAllDataCountFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsBalancingYearlyTransactionGraphStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhiteLabelIsBalancingYearlyTransactionGraphSuccess: (
      state: any,
      action
    ) => {
      state.loading = false
      state.whiteLabelIsBalancingYearlyTransaction = action.payload
    },
    fetchWhiteLabelIsBalancingYearlyTransactionGraphFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // whiteLabel isIntroducer

    fetchWhiteLabelIsIntroducerTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelIsIntroducerTodaySummarySuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelIsIntroducerTodaySummary = action.payload
    },
    fetchWhiteLabelIsIntroducerTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsIntroducerFriendshipRevenueTodayStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhiteLabelIsIntroducerFriendshipRevenueTodaySuccess: (
      state: any,
      action
    ) => {
      state.loading = false
      state.whiteLabelIsIntroducerFriendshipRevenueToday = action.payload
    },
    fetchWhiteLabelIsIntroducerFriendshipRevenueTodayFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsIntroducerMembershipRevenueTodayStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhiteLabelIsIntroducerMembershipRevenueTodaySuccess: (
      state: any,
      action
    ) => {
      state.loading = false
      state.whiteLabelIsIntroducerMembershipRevenueToday = action.payload
    },
    fetchWhiteLabelIsIntroducerMembershipRevenueTodayFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsIntroducerTodayRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelIsIntroducerTodayRevenueSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelIsIntroducerTodayRevenue = action.payload
    },
    fetchWhiteLabelIsIntroducerTodayRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsIntroducerTotalRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelIsIntroducerTotalRevenueSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelIsIntroducerTotalRevenue = action.payload
    },
    fetchWhiteLabelIsIntroducerTotalRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchWhiteLabelIsIntroducerAllDataCountStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelIsIntroducerAllDataCountSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelIsIntroducerAllDataCount = action.payload
    },
    fetchWhiteLabelIsIntroducerAllDataCountFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelIsIntroducerYearlyTransactionGraphStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhiteLabelIsIntroducerYearlyTransactionGraphSuccess: (
      state: any,
      action
    ) => {
      state.loading = false
      state.whiteLabelIsIntroducerYearlyTransaction = action.payload
    },
    fetchWhiteLabelIsIntroducerYearlyTransactionGraphFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    //whiteLabel General
    fetchWhiteLabelTodaySummaryStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelTodaySummarySuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelTodaySummary = action.payload
    },
    fetchWhiteLabelTodaySummaryFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelFriendshipRevenueTodayStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelFriendshipRevenueTodaySuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelFriendshipRevenueToday = action.payload
    },
    fetchWhiteLabelFriendshipRevenueTodayFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelMembershipRevenueTodayStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelMembershipRevenueTodaySuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelMembershipRevenueToday = action.payload
    },
    fetchWhiteLabelMembershipRevenueTodayFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelTodayRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelTodayRevenueSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelTodayRevenue = action.payload
    },
    fetchWhiteLabelTodayRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelTotalRevenueStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelTotalRevenueSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelTotalRevenue = action.payload
    },
    fetchWhiteLabelTotalRevenueFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchWhiteLabelAllDataCountStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelAllDataCountSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelAllDataCount = action.payload
    },
    fetchWhiteLabelAllDataCountFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchWhiteLabelYearlyTransactionGraphStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelYearlyTransactionGraphSuccess: (state: any, action) => {
      state.loading = false
      state.whiteLabelYearlyTransaction = action.payload
    },
    fetchWhiteLabelYearlyTransactionGraphFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelYearlyTransactionGraphAsCPSignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhiteLabelYearlyTransactionGraphAsCPSignerSuccess: (
      state: any,
      action
    ) => {
      state.loading = false
      state.whiteLabelYearlyTransactionAsCPSigner = action.payload
    },
    fetchWhiteLabelYearlyTransactionGraphAsCPSignerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    // yearly transaction graph balancing figure as cp signer

    fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerStart: (
      state: any,
      action
    ) => {
      state.loading = true
    },
    fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerSuccess: (
      state: any,
      action
    ) => {
      state.loading = false
      state.whiteLabelIsBalancingYearlyTransactionAsCPSigner = action.payload
    },
    fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerFailure: (
      state: any,
      action
    ) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantRebateWalletStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantRebateWalletSuccess: (state: any, action) => {
      state.merchantAdminRebateWalletSummary = action.payload
      state.loading = false
    },
    fetchMerchantRebateWalletFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantTransactionFeeDebitStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantTransactionFeeDebitSuccess: (state: any, action) => {
      state.merchantTransactionFeeDebit = action.payload
      state.loading = false
    },
    fetchMerchantTransactionFeeDebitFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCharityDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchCharityDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchCharityDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchClubDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchClubDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchClubDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchRegionDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchRegionDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchRegionDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchCountryDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchGlobalAdminDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchGlobalAdminWithCountryDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchGlobalAdminWithCountryDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchGlobalAdminWithCountryDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchSalesAgentDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchSalesAgentDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchSalesAgentDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCpDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchCpDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchCpDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchMerchantDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchSupporterMerchantDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchSupporterMerchantDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchSupporterMerchantDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchIntroducerDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchIntroducerDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchIntroducerDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchBalancingLevelDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchBalancingLevelDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchBalancingLevelDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchNormalWhiteLabelDashboardDataStart: (state: any, action) => {
      state.loading = true
    },
    fetchNormalWhiteLabelDashboardDataSuccess: (state: any, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    fetchNormalWhiteLabelDashboardDataFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchCountryAdminTotalTransactionAndTotalOwnerRequestsStart,
  fetchCountryAdminTotalTransactionAndTotalOwnerRequestsSuccess,
  fetchCountryAdminTotalTransactionAndTotalOwnerRequestsFailure,

  fetchTotalEarningStart,
  fetchTotalEarningSuccess,
  fetchTotalEarningFailure,

  fetchTotalUsersCountStart,
  fetchTotalUsersCountSuccess,
  fetchTotalUsersCountFailure,

  fetchTotalOwnerDataCountStart,
  fetchTotalOwnerDataCountSuccess,
  fetchTotalOwnerDataCountFailure,

  fetchCountryAdminFriendshipTransactionSummaryStart,
  fetchCountryAdminFriendshipTransactionSummarySuccess,
  fetchCountryAdminFriendshipTransactionSummaryFailure,

  fetchCountryAdminMembershipTransactionSummaryStart,
  fetchCountryAdminMembershipTransactionSummarySuccess,
  fetchCountryAdminMembershipTransactionSummaryFailure,

  fetchRecentMerchantsStart,
  fetchRecentMerchantsSuccess,
  fetchRecentMerchantsFailure,

  fetchCountryAdminYearlyTransactionGraphStart,
  fetchCountryAdminYearlyTransactionGraphSuccess,
  fetchCountryAdminYearlyTransactionGraphFailure,

  fetchGlobalAdminTodaySummaryStart,
  fetchGlobalAdminTodaySummarySuccess,
  fetchGlobalAdminTodaySummaryFailure,

  fetchGlobalAdminTodaySummaryOfWLStart,
  fetchGlobalAdminTodaySummaryOfWLSuccess,
  fetchGlobalAdminTodaySummaryOfWLFailure,

  fetchGlobalAdminTotalSummaryOfWLStart,
  fetchGlobalAdminTotalSummaryOfWLSuccess,
  fetchGlobalAdminTotalSummaryOfWLFailure,

  fetchGlobalAdminTodaySummaryTransactionStart,
  fetchGlobalAdminTodaySummaryTransactionSuccess,
  fetchGlobalAdminTodaySummaryTransactionFailure,

  fetchStateAdminTodaySummaryStart,
  fetchStateAdminTodaySummarySuccess,
  fetchStateAdminTodaySummaryFailure,

  fetchOwnerAdminFriendshipRevenueTodayStart,
  fetchOwnerAdminFriendshipRevenueTodaySuccess,
  fetchOwnerAdminFriendshipRevenueTodayFailure,

  fetchOwnerAdminMembershipRevenueTodayStart,
  fetchOwnerAdminMembershipRevenueTodaySuccess,
  fetchOwnerAdminMembershipRevenueTodayFailure,

  fetchOwnerAdminFriendshipRevenueSummaryStart,
  fetchOwnerAdminFriendshipRevenueSummarySuccess,
  fetchOwnerAdminFriendshipRevenueSummaryFailure,

  fetchOwnerAdminMembershipRevenueSummaryStart,
  fetchOwnerAdminMembershipRevenueSummarySuccess,
  fetchOwnerAdminMembershipRevenueSummaryFailure,

  fetchStateAdminMembershipRevenueStart,
  fetchStateAdminMembershipRevenueSuccess,
  fetchStateAdminMembershipRevenueFailure,

  fetchOwnerAdminRecentMerchantsStart,
  fetchOwnerAdminRecentMerchantsSuccess,
  fetchOwnerAdminRecentMerchantsFailure,

  fetchOwnerAdminYearlyEarningStart,
  fetchOwnerAdminYearlyEarningSuccess,
  fetchOwnerAdminYearlyEarningFailure,

  fetchStateAdminTotalEarningStart,
  fetchStateAdminTotalEarningSuccess,
  fetchStateAdminTotalEarningFailure,

  fetchStateAdminRecentMembersStart,
  fetchStateAdminRecentMembersSuccess,
  fetchStateAdminRecentMembersFailure,

  fetchGlobalAdminFriendshipRevenueStart,
  fetchGlobalAdminFriendshipRevenueSuccess,
  fetchGlobalAdminFriendshipRevenueFailure,

  fetchGlobalAdminMembershipRevenueStart,
  fetchGlobalAdminMembershipRevenueSuccess,
  fetchGlobalAdminMembershipRevenueFailure,

  fetchGlobalAdminTotalEarningStart,
  fetchGlobalAdminTotalEarningFailure,
  fetchGlobalAdminTotalEarningSuccess,

  fetchGlobalAdminYearlyTransactionGraphStart,
  fetchGlobalAdminYearlyTransactionGraphSuccess,
  fetchGlobalAdminYearlyTransactionGraphFailure,

  fetchGlobalAdminTotalSummaryRevenueStart,
  fetchGlobalAdminTotalSummaryRevenueSuccess,
  fetchGlobalAdminTotalSummaryRevenueFailure,

  fetchGlobalAdminAllDataCountStart,
  fetchGlobalAdminAllDataCountSuccess,
  fetchGlobalAdminAllDataCountFailure,

  fetchMerchantAdminTodaySummaryStart,
  fetchMerchantAdminTodaySummarySuccess,
  fetchMerchantAdminTodaySummaryFailure,

  fetchSupporterMerchantAdminTodaySummaryStart,
  fetchSupporterMerchantAdminTodaySummarySuccess,
  fetchSupporterMerchantAdminTodaySummaryFailure,

  fetchMerchantRebateWalletStart,
  fetchMerchantRebateWalletSuccess,
  fetchMerchantRebateWalletFailure,

  fetchMerchantTransactionFeeDebitStart,
  fetchMerchantTransactionFeeDebitSuccess,
  fetchMerchantTransactionFeeDebitFailure,

  fetchMerchantAdminTotalSummaryStart,
  fetchMerchantAdminTotalSummarySuccess,
  fetchMerchantAdminTotalSummaryFailure,

  fetchSupporterMerchantAdminTotalSummaryStart,
  fetchSupporterMerchantAdminTotalSummarySuccess,
  fetchSupporterMerchantAdminTotalSummaryFailure,

  fetchMerchantAdminYearlyTransactionGraphStart,
  fetchMerchantAdminYearlyTransactionGraphSuccess,
  fetchMerchantAdminYearlyTransactionGraphFailure,

  fetchGlobalAdminByCountryTodaySummaryStart,
  fetchGlobalAdminByCountryTodaySummarySuccess,
  fetchGlobalAdminByCountryTodaySummaryFailure,

  fetchGlobalAdminByCountryTotalSummaryStart,
  fetchGlobalAdminByCountryTotalSummarySuccess,
  fetchGlobalAdminByCountryTotalSummaryFailure,

  fetchGlobalAdminByCountryTotalRevenueSummaryStart,
  fetchGlobalAdminByCountryTotalRevenueSummarySuccess,
  fetchGlobalAdminByCountryTotalRevenueSummaryFailure,

  fetchGlobalAdminByCountryTotalRevenueSummaryCountStart,
  fetchGlobalAdminByCountryTotalRevenueSummaryCountSuccess,
  fetchGlobalAdminByCountryTotalRevenueSummaryCountFailure,

  fetchGlobalAdminByCountryTodayFriendshipRevenueStart,
  fetchGlobalAdminByCountryTodayFriendshipRevenueSuccess,
  fetchGlobalAdminByCountryTodayFriendshipRevenueFailure,

  fetchGlobalAdminByCountryTodayMembershipRevenueStart,
  fetchGlobalAdminByCountryTodayMembershipRevenueSuccess,
  fetchGlobalAdminByCountryTodayMembershipRevenueFailure,

  fetchGlobalAdminByCountryAllDataCountStart,
  fetchGlobalAdminByCountryAllDataCountSuccess,
  fetchGlobalAdminByCountryAllDataCountFailure,

  fetchMerchantAdminFriendshipRevenueStart,
  fetchMerchantAdminFriendshipRevenueSuccess,
  fetchMerchantAdminFriendshipRevenueFailure,

  fetchMerchantAdminMembershipRevenueStart,
  fetchMerchantAdminMembershipRevenueSuccess,
  fetchMerchantAdminMembershipRevenueFailure,

  fetchSalesAgentTotalSummaryStart,
  fetchSalesAgentTotalSummarySuccess,
  fetchSalesAgentTotalSummaryFailure,

  fetchSalesAgentDailySummaryStart,
  fetchSalesAgentDailySummarySuccess,
  fetchSalesAgentDailySummaryFailure,

  fetchCollaborationPartnerTotalSummaryStart,
  fetchCollaborationPartnerTotalSummarySuccess,
  fetchCollaborationPartnerTotalSummaryFailure,

  fetchCollaborationPartnerDailySummaryStart,
  fetchCollaborationPartnerDailySummarySuccess,
  fetchCollaborationPartnerDailySummaryFailure,

  fetchCharityTodaySummaryStart,
  fetchCharityTodaySummarySuccess,
  fetchCharityTodaySummaryFailure,

  fetchCharityMerchantAndMemberStart,
  fetchCharityMerchantAndMemberSuccess,
  fetchCharityMerchantAndMemberFailure,

  fetchCharityTotalSummaryStart,
  fetchCharityTotalSummarySuccess,
  fetchCharityTotalSummaryFailure,

  fetchCharityTotalRevenueSummaryStart,
  fetchCharityTotalRevenueSummarySuccess,
  fetchCharityTotalRevenueSummaryFailure,

  fetchClubTodaySummaryStart,
  fetchClubTodaySummarySuccess,
  fetchClubTodaySummaryFailure,

  fetchTotalClubSummaryStart,
  fetchTotalClubSummarySuccess,
  fetchTotalClubSummaryFailure,

  fetchTodayClubRevenueStart,
  fetchTodayClubRevenueSuccess,
  fetchTodayClubRevenueFailure,

  fetchTotalClubRevenueStart,
  fetchTotalClubRevenueSuccess,
  fetchTotalClubRevenueFailure,

  // whiteLable General

  fetchWhiteLabelTodaySummaryStart,
  fetchWhiteLabelTodaySummarySuccess,
  fetchWhiteLabelTodaySummaryFailure,

  fetchWhiteLabelFriendshipRevenueTodayStart,
  fetchWhiteLabelFriendshipRevenueTodaySuccess,
  fetchWhiteLabelFriendshipRevenueTodayFailure,

  fetchWhiteLabelMembershipRevenueTodayStart,
  fetchWhiteLabelMembershipRevenueTodaySuccess,
  fetchWhiteLabelMembershipRevenueTodayFailure,

  fetchWhiteLabelTodayRevenueStart,
  fetchWhiteLabelTodayRevenueSuccess,
  fetchWhiteLabelTodayRevenueFailure,

  fetchWhiteLabelTotalRevenueStart,
  fetchWhiteLabelTotalRevenueSuccess,
  fetchWhiteLabelTotalRevenueFailure,

  fetchWhiteLabelAllDataCountStart,
  fetchWhiteLabelAllDataCountSuccess,
  fetchWhiteLabelAllDataCountFailure,

  fetchWhiteLabelYearlyTransactionGraphStart,
  fetchWhiteLabelYearlyTransactionGraphSuccess,
  fetchWhiteLabelYearlyTransactionGraphFailure,

  //yearly transaction graph as CP signer
  fetchWhiteLabelYearlyTransactionGraphAsCPSignerStart,
  fetchWhiteLabelYearlyTransactionGraphAsCPSignerSuccess,
  fetchWhiteLabelYearlyTransactionGraphAsCPSignerFailure,

  // whiteLabel isBalancingLabel
  fetchWhiteLabelIsBalancingTodaySummaryStart,
  fetchWhiteLabelIsBalancingTodaySummarySuccess,
  fetchWhiteLabelIsBalancingTodaySummaryFailure,

  fetchWhiteLabelIsBalancingFriendshipRevenueTodayStart,
  fetchWhiteLabelIsBalancingFriendshipRevenueTodaySuccess,
  fetchWhiteLabelIsBalancingFriendshipRevenueTodayFailure,

  fetchWhiteLabelIsBalancingMembershipRevenueTodayStart,
  fetchWhiteLabelIsBalancingMembershipRevenueTodaySuccess,
  fetchWhiteLabelIsBalancingMembershipRevenueTodayFailure,

  fetchWhiteLabelIsBalancingTodayRevenueStart,
  fetchWhiteLabelIsBalancingTodayRevenueSuccess,
  fetchWhiteLabelIsBalancingTodayRevenueFailure,

  fetchWhiteLabelIsBalancingTotalRevenueStart,
  fetchWhiteLabelIsBalancingTotalRevenueSuccess,
  fetchWhiteLabelIsBalancingTotalRevenueFailure,

  fetchWhiteLabelIsBalancingAllDataCountStart,
  fetchWhiteLabelIsBalancingAllDataCountSuccess,
  fetchWhiteLabelIsBalancingAllDataCountFailure,

  fetchWhiteLabelIsBalancingYearlyTransactionGraphStart,
  fetchWhiteLabelIsBalancingYearlyTransactionGraphSuccess,
  fetchWhiteLabelIsBalancingYearlyTransactionGraphFailure,

  fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerStart,
  fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerSuccess,
  fetchWhiteLabelYearlyTransactionGraphBalancingFigureAsCPSignerFailure,

  // whiteLabel isIntroducer

  fetchWhiteLabelIsIntroducerTodaySummaryStart,
  fetchWhiteLabelIsIntroducerTodaySummarySuccess,
  fetchWhiteLabelIsIntroducerTodaySummaryFailure,

  fetchWhiteLabelIsIntroducerFriendshipRevenueTodayStart,
  fetchWhiteLabelIsIntroducerFriendshipRevenueTodaySuccess,
  fetchWhiteLabelIsIntroducerFriendshipRevenueTodayFailure,

  fetchWhiteLabelIsIntroducerMembershipRevenueTodayStart,
  fetchWhiteLabelIsIntroducerMembershipRevenueTodaySuccess,
  fetchWhiteLabelIsIntroducerMembershipRevenueTodayFailure,

  fetchWhiteLabelIsIntroducerTodayRevenueStart,
  fetchWhiteLabelIsIntroducerTodayRevenueSuccess,
  fetchWhiteLabelIsIntroducerTodayRevenueFailure,

  fetchWhiteLabelIsIntroducerTotalRevenueStart,
  fetchWhiteLabelIsIntroducerTotalRevenueSuccess,
  fetchWhiteLabelIsIntroducerTotalRevenueFailure,

  fetchWhiteLabelIsIntroducerAllDataCountStart,
  fetchWhiteLabelIsIntroducerAllDataCountSuccess,
  fetchWhiteLabelIsIntroducerAllDataCountFailure,

  fetchWhiteLabelIsIntroducerYearlyTransactionGraphStart,
  fetchWhiteLabelIsIntroducerYearlyTransactionGraphSuccess,
  fetchWhiteLabelIsIntroducerYearlyTransactionGraphFailure,

  fetchSalesAgentTotalUserCountStart,
  fetchSalesAgentTotalUserCountSuccess,
  fetchSalesAgentTotalUserCountFailure,

  fetchCollaborationPartnerTotalUserCountStart,
  fetchCollaborationPartnerTotalUserCountSuccess,
  fetchCollaborationPartnerTotalUserCountFailure,

  fetchTerminalMerchantAdminTodaySummaryStart,
  fetchTerminalMerchantAdminTodaySummarySuccess,
  fetchTerminalMerchantAdminTodaySummaryFailure,

  fetchTerminalMerchantAdminTotalSummaryStart,
  fetchTerminalMerchantAdminTotalSummarySuccess,
  fetchTerminalMerchantAdminTotalSummaryFailure,

  fetchCharityDashboardDataStart,
  fetchCharityDashboardDataSuccess,
  fetchCharityDashboardDataFailure,

  fetchClubDashboardDataStart,
  fetchClubDashboardDataSuccess,
  fetchClubDashboardDataFailure,

  fetchRegionDashboardDataStart,
  fetchRegionDashboardDataSuccess,
  fetchRegionDashboardDataFailure,

  fetchCountryDashboardDataStart,
  fetchCountryDashboardDataSuccess,
  fetchCountryDashboardDataFailure,

  fetchGlobalAdminDashboardDataStart,
  fetchGlobalAdminDashboardDataSuccess,
  fetchGlobalAdminDashboardDataFailure,

  fetchSalesAgentDashboardDataStart,
  fetchSalesAgentDashboardDataSuccess,
  fetchSalesAgentDashboardDataFailure,

  fetchCpDashboardDataStart,
  fetchCpDashboardDataSuccess,
  fetchCpDashboardDataFailure,

  fetchGlobalAdminWithCountryDashboardDataStart,
  fetchGlobalAdminWithCountryDashboardDataSuccess,
  fetchGlobalAdminWithCountryDashboardDataFailure,

  fetchMerchantDashboardDataStart,
  fetchMerchantDashboardDataSuccess,
  fetchMerchantDashboardDataFailure,

  fetchSupporterMerchantDashboardDataStart,
  fetchSupporterMerchantDashboardDataSuccess,
  fetchSupporterMerchantDashboardDataFailure,

  fetchIntroducerDashboardDataStart,
  fetchIntroducerDashboardDataSuccess,
  fetchIntroducerDashboardDataFailure,

  fetchBalancingLevelDashboardDataStart,
  fetchBalancingLevelDashboardDataSuccess,
  fetchBalancingLevelDashboardDataFailure,

  fetchNormalWhiteLabelDashboardDataStart,
  fetchNormalWhiteLabelDashboardDataSuccess,
  fetchNormalWhiteLabelDashboardDataFailure,

  fetchOwnerAdminRecentMerchantsForCountryDashboardStart,
  fetchOwnerAdminRecentMerchantsForCountryDashboardSuccess,
  fetchOwnerAdminRecentMerchantsForCountryDashboardFailure,
} = dashboardSlice.actions

export default dashboardSlice.reducer
