/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { fetchPostStart } from 'store/post/postSlice'
import { fetchCountryStart } from 'store/dropDown/dropDownSlice'
import {
  clearCountryStripeKey,
  fetchCountryStripeKeyStart,
} from 'store/countrySetup/countrySetupSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  dropDown: state.dropDown,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchPostStart: (payload) => dispatch(fetchPostStart(payload)),
  onFetchCountryStart: (payload) => dispatch(fetchCountryStart(payload)),
  onFetchCountryStripeKeyStart: (payload) =>
    dispatch(fetchCountryStripeKeyStart(payload)),
  onClearCountryStripeKey: () => dispatch(clearCountryStripeKey()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
