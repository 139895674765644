import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchMerchantFeeStart,
  fetchMerchantFeeSuccess,
  fetchMerchantFeeFailure,
  createMerchantFeeSuccess,
  createMerchantFeeFailure,
  deleteMerchantFeeSuccess,
  deleteMerchantFeeFailure,
  fetchMerchantFeeByIdSuccess,
  fetchMerchantFeeByIdFailure,
  editMerchantFeeSuccess,
  editMerchantFeeFailure,
} from './merchantFeeSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchMerchantFeeList,
  createMerchantFee,
  deleteMerchantFee,
  fetchMerchantFeeById,
  editMerchantFee,
} from '../../api/GlobalAdmin/merchantFee'

import _ from 'lodash'

export function* fetchMerchantFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantFeeList(payload))

    yield put(fetchMerchantFeeSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantFeeFailure(err))
  }
}

export function* fetchMerchantFeeByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantFeeById(payload))

    yield put(fetchMerchantFeeByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantFeeByIdFailure(err))
  }
}

export function* editMerchantFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editMerchantFee(payload.id, payload))

    yield put(editMerchantFeeSuccess(data?.data))
    yield put(
      openAlert({
        message: 'Merchant transaction fee successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editMerchantFeeFailure(err))
  }
}

export function* createMerchantFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createMerchantFee({ ...payload }))
    yield put(createMerchantFeeSuccess(data?.data))
    yield put(
      fetchMerchantFeeStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Merchant transaction fee successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createMerchantFeeFailure(err))
  }
}

export function* deleteMerchantFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteMerchantFee(payload))

    if (data) {
      yield put(deleteMerchantFeeSuccess(payload))
      yield put(
        fetchMerchantFeeStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Merchant transaction fee successfully deleted ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteMerchantFeeFailure(err))
  }
}

export function* watchFetchMerchantFee() {
  yield takeLatest('merchantFee/fetchMerchantFeeStart', fetchMerchantFeeAsync)
}

export function* watchCreateMerchantFee() {
  yield takeLatest('merchantFee/createMerchantFeeStart', createMerchantFeeAsync)
}

export function* watchDeleteMerchantFee() {
  yield takeLatest('merchantFee/deleteMerchantFeeStart', deleteMerchantFeeAsync)
}

export function* watchFetchMerchantFeeById() {
  yield takeLatest(
    'merchantFee/fetchMerchantFeeByIdStart',
    fetchMerchantFeeByIdAsync
  )
}

export function* watchEditMerchantFee() {
  yield takeLatest('merchantFee/editMerchantFeeStart', editMerchantFeeAsync)
}

export function* merchantFeeSagas() {
  yield all([
    call(watchFetchMerchantFee),
    call(watchCreateMerchantFee),
    call(watchDeleteMerchantFee),
    call(watchFetchMerchantFeeById),
    call(watchEditMerchantFee),
  ])
}
