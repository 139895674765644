import { takeLatest, call, all, put, delay, select } from 'redux-saga/effects'
import {
  fetchReferredCPStart,
  fetchReferredCPSuccess,
  fetchReferredCPFailure,
  deleteReferredCPSuccess,
  deleteReferredCPFailure,
  fetchReferredCPByIdStart,
  fetchReferredCPByIdSuccess,
  fetchReferredCPByIdFailure,
  editReferredCPSuccess,
  editReferredCPFailure,
  rejectReferredCPStart,
  rejectReferredCPSuccess,
  rejectReferredCPFailure,
  verifyReferredCPStart,
  verifyReferredCPSuccess,
  verifyReferredCPFailure,
  saveReferredCPStart,
  saveReferredCPSuccess,
  saveReferredCPFailure,
  createCollaborationPartnerReferStart,
  createCollaborationPartnerReferSuccess,
  createCollaborationPartnerReferFailure,
  fetchCountryAdminReferredCPStart,
  fetchCountryAdminReferredCPSuccess,
  fetchCountryAdminReferredCPFailure,
  fetchAreaAdminReferredCPStart,
  fetchAreaAdminReferredCPSuccess,
  fetchAreaAdminReferredCPFailure,
  fetchRegionAdminReferredCPStart,
  fetchRegionAdminReferredCPSuccess,
  fetchRegionAdminReferredCPFailure,
  fetchStateAdminReferredCPStart,
  fetchStateAdminReferredCPSuccess,
  fetchStateAdminReferredCPFailure,
  fetchCharityAdminReferredCPStart,
  fetchCharityAdminReferredCPSuccess,
  fetchCharityAdminReferredCPFailure,
  fetchClubAdminReferredCPStart,
  fetchClubAdminReferredCPSuccess,
  fetchClubAdminReferredCPFailure,
  fetchWhiteLabelAdminReferredCPStart,
  fetchWhiteLabelAdminReferredCPSuccess,
  fetchWhiteLabelAdminReferredCPFailure,
  fetchCPAdminReferredCPStart,
  fetchCPAdminReferredCPSuccess,
  fetchCPAdminReferredCPFailure,
  fetchSalesAgentAdminReferredCPStart,
  fetchSalesAgentAdminReferredCPSuccess,
  fetchSalesAgentAdminReferredCPFailure,
  fetchAreaAdminReferredCPByPanelSuccess,
  fetchAreaAdminReferredCPByPanelFailure,
  fetchAllSalesAgentByLocationSuccess,
  fetchAllSalesAgentByLocationFailure,
} from './referredCpSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'
import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchReferredCollaborationPartnerList,
  createCollaborationPartnerRefer,
} from '../../api/collaborationPartner'

import {
  fetchAllSalesAgentByLocationApi,
  fetchAreaAdminReferredCPList,
  fetchAreaAdminReferredCPListByPanel,
} from '../../api/areaAdmin'

import _ from 'lodash'

export const getUserData = (state: any) => state.auth.currentUser

export function* fetchReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchReferredCollaborationPartnerList(payload))
    yield put(fetchReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchReferredCPFailure(err))
  }
}

export function* createCollaborationPartnerReferAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createCollaborationPartnerRefer(payload.params))

    yield put(createCollaborationPartnerReferSuccess(data?.results))

    const currentUser = yield select(getUserData)

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    // yield put(
    //   fetchCountryAdminReferredCPStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //     referredBy: currentUser?.userId,
    //     refererType: currentUser?.role,
    //   })
    // )

    // yield put(
    //   fetchRegionAdminReferredCPStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //     referredBy: currentUser?.userId,
    //     refererType: currentUser?.role,
    //   })
    // )

    // yield put(
    //   fetchAreaAdminReferredCPStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //     referredBy: currentUser?.userId,
    //     refererType: currentUser?.role,
    //   })
    // )

    // yield put(
    //   fetchStateAdminReferredCPStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //     referredBy: currentUser?.userId,
    //     refererType: currentUser?.role,
    //   })
    // )

    // yield put(
    //   fetchCharityAdminReferredCPStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //     referredBy: currentUser?.userId,
    //     refererType: currentUser?.role,
    //   })
    // )

    // yield put(
    //   fetchClubAdminReferredCPStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //     referredBy: currentUser?.userId,
    //     refererType: currentUser?.role,
    //   })
    // )

    // yield put(
    //   fetchWhiteLabelAdminReferredCPStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //     referredBy: currentUser?.userId,
    //     refererType: currentUser?.role,
    //   })
    // )

    // yield put(
    //   fetchCPAdminReferredCPStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //     referredBy: currentUser?.userId,
    //     refererType: currentUser?.role,
    //   })
    // )
    // yield put(
    //   fetchSalesAgentAdminReferredCPStart({
    //     page: 1,
    //     limit: 10,
    //     offset: 0,
    //     referredBy: currentUser?.userId,
    //     refererType: currentUser?.role,
    //   })
    // )
    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'Collaboration Partner referred successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createCollaborationPartnerReferFailure(err))

    console.error(err)
  }
}

export function* fetchCountryAdminReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPList(payload))

    yield put(fetchCountryAdminReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCountryAdminReferredCPFailure(err))
  }
}

export function* fetchAreaAdminReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPList(payload))
    yield put(fetchAreaAdminReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAreaAdminReferredCPFailure(err))
  }
}

export function* fetchAreaAdminReferredCPByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPListByPanel(payload))
    yield put(fetchAreaAdminReferredCPByPanelSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAreaAdminReferredCPByPanelFailure(err))
  }
}

export function* fetchAllSalesAgentByLocationAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllSalesAgentByLocationApi(payload))
    yield put(fetchAllSalesAgentByLocationSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAllSalesAgentByLocationFailure(err))
  }
}

export function* fetchRegionAdminReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPList(payload))
    yield put(fetchRegionAdminReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchRegionAdminReferredCPFailure(err))
  }
}

export function* fetchStateAdminReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPList(payload))
    yield put(fetchStateAdminReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchStateAdminReferredCPFailure(err))
  }
}

export function* fetchCharityAdminReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPList(payload))
    yield put(fetchCharityAdminReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCharityAdminReferredCPFailure(err))
  }
}

export function* fetchClubAdminReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPList(payload))
    yield put(fetchClubAdminReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchClubAdminReferredCPFailure(err))
  }
}

export function* fetchWhiteLabelAdminReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPListByPanel(payload))
    yield put(fetchWhiteLabelAdminReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchWhiteLabelAdminReferredCPFailure(err))
  }
}

export function* fetchCollaborationPartnerAdminReferredCPAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPList(payload))
    yield put(fetchCPAdminReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCPAdminReferredCPFailure(err))
  }
}

export function* fetchSalesAgentAdminReferredCPAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaAdminReferredCPList(payload))
    yield put(fetchSalesAgentAdminReferredCPSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchSalesAgentAdminReferredCPFailure(err))
  }
}

export function* watchFetchReferredCPList() {
  yield takeLatest('referredCPList/fetchReferredCPStart', fetchReferredCPAsync)
}

export function* watchFetchCountryAdminReferredCPList() {
  yield takeLatest(
    'referredCPList/fetchCountryAdminReferredCPStart',
    fetchCountryAdminReferredCPAsync
  )
}

export function* watchFetchAreaAdminReferredCPList() {
  yield takeLatest(
    'referredCPList/fetchAreaAdminReferredCPStart',
    fetchAreaAdminReferredCPAsync
  )
}

export function* watchFetchAreaAdminReferredCPByPanelAsync() {
  yield takeLatest(
    'referredCPList/fetchAreaAdminReferredCPByPanelStart',
    fetchAreaAdminReferredCPByPanelAsync
  )
}

export function* watchFetchRegionAdminReferredCPList() {
  yield takeLatest(
    'referredCPList/fetchRegionAdminReferredCPStart',
    fetchRegionAdminReferredCPAsync
  )
}

export function* watchFetchStateAdminReferredCPList() {
  yield takeLatest(
    'referredCPList/fetchStateAdminReferredCPStart',
    fetchStateAdminReferredCPAsync
  )
}

export function* watchFetchCharityAdminReferredCPList() {
  yield takeLatest(
    'referredCPList/fetchCharityAdminReferredCPStart',
    fetchCharityAdminReferredCPAsync
  )
}

export function* watchFetchClubAdminReferredCPList() {
  yield takeLatest(
    'referredCPList/fetchClubAdminReferredCPStart',
    fetchClubAdminReferredCPAsync
  )
}

export function* watchFetchWhiteLabelAdminReferredCPList() {
  yield takeLatest(
    'referredCPList/fetchWhiteLabelAdminReferredCPStart',
    fetchWhiteLabelAdminReferredCPAsync
  )
}

export function* watchFetchCPAdminReferredCPList() {
  yield takeLatest(
    'referredCPList/fetchCPAdminReferredCPStart',
    fetchCollaborationPartnerAdminReferredCPAsync
  )
}

export function* watchFetchSalesAgentAdminReferredCPList() {
  yield takeLatest(
    'referredCPList/fetchSalesAgentAdminReferredCPStart',
    fetchSalesAgentAdminReferredCPAsync
  )
}

export function* watchCreateCollaborationPartnerRefer() {
  yield takeLatest(
    'referredCPList/createCollaborationPartnerReferStart',
    createCollaborationPartnerReferAsync
  )
}

export function* watchFetchAllSalesAgentByLocationAsync() {
  yield takeLatest(
    'referredCPList/fetchAllSalesAgentByLocationStart',
    fetchAllSalesAgentByLocationAsync
  )
}

export function* referredCPSagas() {
  yield all([
    call(watchFetchReferredCPList),
    call(watchFetchCountryAdminReferredCPList),
    call(watchFetchAreaAdminReferredCPList),
    call(watchFetchRegionAdminReferredCPList),
    call(watchFetchStateAdminReferredCPList),
    call(watchFetchCharityAdminReferredCPList),
    call(watchFetchClubAdminReferredCPList),
    call(watchFetchWhiteLabelAdminReferredCPList),
    call(watchFetchCPAdminReferredCPList),
    call(watchFetchSalesAgentAdminReferredCPList),
    call(watchCreateCollaborationPartnerRefer),
    call(watchFetchAreaAdminReferredCPByPanelAsync),
    call(watchFetchAllSalesAgentByLocationAsync),
  ])
}
