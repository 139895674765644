// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchWhiteLabelList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/withNumberofLevel/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createWhiteLabel = (params) => {
  return () =>
    axiosRequest
      .post(`/api/whiteLabel/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabel = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/whiteLabel/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteWhiteLabel = (id, params) => {
  return () =>
    axiosRequest
      .delete(`/api/whiteLabel/delete/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

/* white label position api starts*/

export const createWhiteLabelPosition = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/whiteLabel/whiteLabelPosition/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelPositionList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelPosition/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getWhiteLabelPositionById = (id) => {
  return () =>
    axiosRequest
      .get(
        `/api/whiteLabel/whiteLabelPosition/getAllUniqueWhiteLabelPosition/${id}`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getWhiteLabelPositionSelf = () => {
  return () =>
    axiosRequest
      .get(
        `/api/whiteLabel/whiteLabelPosition/getAllUniqueWhiteLabelPosition/self`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabelPosition = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/whiteLabel/whiteLabelPosition/edit`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabelPositionBasePromotional = (id, params) => {
  return () =>
    axiosRequest
      .put(
        `/api/whiteLabel/whiteLabelPosition/basePromotionalLevel/edit`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabelPositionNonZeroPromotionalLevel = (id, params) => {
  return () =>
    axiosRequest
      .put(
        `/api/whiteLabel/whiteLabelPosition/nonZeroPromotionalLevel/edit`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabelSubPosition = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/whiteLabel/whiteLabelSubPosition/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

/* white label sub-position api starts*/

export const createWhiteLabelSubposition = (params) => {
  return () =>
    axiosRequest
      .post(`/api/whiteLabel/whiteLabelPosition/createPositionLevel`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createWhiteLabelFriendShip = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/whiteLabel/whiteLabelFriendShip/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelFriendShip = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelFriendShip/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelFriendShipById = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelFriendShip/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabelFriendShip = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/whiteLabel/whiteLabelFriendShip/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteWhiteLabelFriendShip = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/whiteLabel/whiteLabelFriendShip/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createWhiteLabelMemberShip = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/whiteLabel/whiteLabelMemberShip/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelMemberShip = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelMemberShip/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelMemberShipById = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelMemberShip/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabelMemberShip = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/whiteLabel/whiteLabelMemberShip/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteWhiteLabelMemberShip = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/whiteLabel/whiteLabelMemberShip/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getWhiteLabelUserByParentId = (id) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelUser/getall?parentId=${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getWhiteLabelUserByParentIdAsync = async (id) => {
  return await axiosRequest
    .get(`/api/whiteLabel/whiteLabelUser/getall?parentId=${id}`)
    .then((res) => {
      const finalData = res?.data?.data?.map((item) => {
        return {
          ...item,
          label: item?.username,
        }
      })
      return Promise.resolve(finalData)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const createWhiteLabelUser = (params) => {
  return () =>
    axiosRequest
      .post(`/api/whiteLabel/whiteLabelUser/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getWhiteLabelUserById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelUser/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getWhiteLabelPromotionalLevel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelPosition/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createWhiteLabelCPFriendshipExternalAllocation = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/whiteLabel/whiteLabelCPFriendshipExternalAllocation/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createWhiteLabelCPMembershipExternalAllocation = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/whiteLabel/whiteLabelCPMembershipExternalAllocation/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createWhiteLabelCPFriendshipScenariosAllocation = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/whiteLabel/whiteLabelCPFriendshipScenariosAllocation/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabelCPFriendshipScenariosAllocation = (id, params) => {
  return () =>
    axiosRequest
      .put(
        `/api/whiteLabel/whiteLabelCPFriendshipScenariosAllocation/edit/${id}`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createWhiteLabelCPMembershipScenariosAllocation = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/whiteLabel/whiteLabelCPMembershipScenariosAllocation/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editWhiteLabelCPMembershipScenariosAllocation = (id, params) => {
  return () =>
    axiosRequest
      .put(
        `/api/whiteLabel/whiteLabelCPMembershipScenariosAllocation/edit/${id}`,
        params
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelCPFriendshipScenarioList = (id, params) => {
  return () =>
    axiosRequest
      .get(
        `/api/whiteLabel/whiteLabelCPFriendshipScenariosAllocation/getAll/${id}`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelCPFriendshipScenarioById = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelCPFriendshipScenariosAllocation/getOne`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelCPMembershipScenarioList = (id, params) => {
  return () =>
    axiosRequest
      .get(
        `/api/whiteLabel/whiteLabelCPMembershipScenariosAllocation/getAll/${id}`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelCPMembershipScenarioById = (params) => {
  return () =>
    axiosRequest
      .get(`/api/whiteLabel/whiteLabelCPMembershipScenariosAllocation/getOne`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelCPFriendshipExternalAllocationByWhiteLabelId = (
  id
) => {
  return () =>
    axiosRequest
      .get(
        `/api/whiteLabel/whiteLabelCPFriendshipExternalAllocation/getOneByWhiteLabelId/${id}`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWhiteLabelCPMembershipExternalAllocationByWhiteLabelId = (
  id
) => {
  return () =>
    axiosRequest
      .get(
        `/api/whiteLabel/whiteLabelCPMembershipExternalAllocation/getOneByWhiteLabelId/${id}`
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
