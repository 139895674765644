import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchMembershipFeeStart,
  fetchMembershipFeeSuccess,
  fetchMembershipFeeFailure,
  createMembershipFeeSuccess,
  createMembershipFeeFailure,
  deleteMembershipFeeSuccess,
  deleteMembershipFeeFailure,
  fetchMembershipFeeByIdSuccess,
  fetchMembershipFeeByIdFailure,
  editMembershipFeeSuccess,
  editMembershipFeeFailure,
} from './membershipFeeSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchMembershipFeeList,
  createMembershipFee,
  deleteMembershipFee,
  fetchMembershipFeeById,
  editMembershipFee,
} from '../../api/GlobalAdmin/membershipFee'

import _ from 'lodash'

export function* fetchMembershipFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMembershipFeeList(payload))

    yield put(fetchMembershipFeeSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMembershipFeeFailure(err))
  }
}

export function* fetchMembershipFeeByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMembershipFeeById(payload))

    yield put(fetchMembershipFeeByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMembershipFeeByIdFailure(err))
  }
}

export function* editMembershipFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editMembershipFee(payload.params.id, payload.params)
    )

    yield put(editMembershipFeeSuccess(data))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Membership beneficiaries successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editMembershipFeeFailure(err))
  }
}

export function* createMembershipFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createMembershipFee(payload.params))
    yield put(createMembershipFeeSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      fetchMembershipFeeStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield put(
      openAlert({
        message: 'Membership beneficiaries successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createMembershipFeeFailure(err))
  }
}

export function* deleteMembershipFeeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteMembershipFee(payload))

    if (data) {
      yield put(deleteMembershipFeeSuccess(payload))
      yield put(
        fetchMembershipFeeStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Membership beneficiaries deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteMembershipFeeFailure(err))
  }
}

export function* watchFetchMembershipFee() {
  yield takeLatest(
    'membershipFee/fetchMembershipFeeStart',
    fetchMembershipFeeAsync
  )
}

export function* watchCreateMembershipFee() {
  yield takeLatest(
    'membershipFee/createMembershipFeeStart',
    createMembershipFeeAsync
  )
}

export function* watchDeleteMembershipFee() {
  yield takeLatest(
    'membershipFee/deleteMembershipFeeStart',
    deleteMembershipFeeAsync
  )
}

export function* watchFetchMembershipFeeById() {
  yield takeLatest(
    'membershipFee/fetchMembershipFeeByIdStart',
    fetchMembershipFeeByIdAsync
  )
}

export function* watchEditMembershipFee() {
  yield takeLatest(
    'membershipFee/editMembershipFeeStart',
    editMembershipFeeAsync
  )
}

export function* membershipFeeSagas() {
  yield all([
    call(watchFetchMembershipFee),
    call(watchCreateMembershipFee),
    call(watchDeleteMembershipFee),
    call(watchFetchMembershipFeeById),
    call(watchEditMembershipFee),
  ])
}
