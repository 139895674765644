// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'

export const fetchWhiteLabelReferredCPList = (params) => {
  return () =>
    axiosRequest
      .get(
        `/api/salesAgent/getAllCP?salesAgentType=collaborationPartner&isReferredAsCP=true`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
