import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'

import AppDialog from '@crema/core/AppDialog'
import container from './AddLanguage.container'
import CountryDemo from './components/Table'
import ListTop from './components/ListTop/ListTop'

interface AddNewCountryProps {
  isAddCountryOpen: boolean
  onOpenCountryTask?: () => void
  onCloseAddCountry: () => void
  clearGeneralData?: () => void
  onCreateClubStart: (params) => void
  onFetchMerchantMultilingualListByPanelStart?: (params) => void
  onAddMerchantMultilingualStart?: (params) => void
  onEditMerchantMultilingualStart?: (params) => void
  siteCoordinator: any
  merchantId?: number
  club: any
  auth: any
  adminRoute?: any
  merchant?: any
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  isAddCountryOpen,
  clearGeneralData,
  onCloseAddCountry,
  merchantId,
  merchant: {
    merchantMultilingualList: { data, hasMore, totalCount, page: pageNumber },
    loading,
    isEditing,
    isCreating,
  },
  onFetchMerchantMultilingualListByPanelStart,
  onAddMerchantMultilingualStart,
  onEditMerchantMultilingualStart,
}) => {
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [selectedDataToEdit, setSelectedDataToEdit] = useState<any>({})

  useEffect(() => {
    onFetchMerchantMultilingualListByPanelStart({
      page: page + 1,
      limit: rowsPerPage,
      offset,
      merchantId,
    })
  }, [page, rowsPerPage])

  const refreshPageData = () => {
    onFetchMerchantMultilingualListByPanelStart({
      page: page + 1,
      limit: rowsPerPage,
      offset,
      merchantId,
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setOffset(offset + rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }
  const closeModal = () => {
    onCloseAddCountry()
    clearGeneralData()
    setSelectedDataToEdit({})
  }

  return (
    <AppDialog
      dividers
      maxWidth="lg"
      open={isAddCountryOpen}
      onClose={() => closeModal()}
      title="Add Language"
    >
      <ListTop
        onAddMerchantMultilingualStart={onAddMerchantMultilingualStart}
        onEditMerchantMultilingualStart={onEditMerchantMultilingualStart}
        refreshPageData={refreshPageData}
        merchantId={merchantId}
        selectedDataToEdit={selectedDataToEdit}
        setSelectedDataToEdit={setSelectedDataToEdit}
        isEditing={isEditing}
        isCreating={isCreating}
      />
      <Box sx={{ width: '100%', p: 1 }}>
        <CountryDemo
          countryData={data}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          hasMoreData={hasMore}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          //   onDeleteClubStart={onDeleteClubStart}
          totalCount={totalCount}
          loading={loading}
          pageNumber={pageNumber}
          setSelectedDataToEdit={setSelectedDataToEdit}
          selectedDataToEdit={selectedDataToEdit}
        />
      </Box>
    </AppDialog>
  )
}

export default container(AddNewClub)
