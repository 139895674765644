import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  Autocomplete,
  Typography,
  CircularProgress,
  Dialog,
} from '@mui/material'
import { Form, Formik, Field } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import * as yup from 'yup'
import { PrizeDrawMemberFeeType, removeEmptyFields } from 'utils'
import moment from 'moment-timezone'
import _ from 'lodash'
import AppDialog from '@crema/core/AppDialogNew'
import { FormikProps } from 'formik'
import AppCard from '@crema/core/AppCard'
import container from './GetWinner.container'
import TrainingType from '../RoleSelect'
import Previews from '../Previews'

interface GetWinnerProps {
  isLuckDrawOpen: boolean
  onOpenLuckDraw?: () => void
  onCloseLuckDraw?: () => void
  onClearGetWinner?: () => void
  onCreateLuckDrawStart: (payload: any) => void
  selectedPrizeDraw?: any
  selectedTempData?: any
  globalSettings?: any
  onFetchPrizeDrawSettingsStart: (parans) => void
  onFetchPrizeDrawByIdStart: (payload: any) => void
  auth?: any
  setLuckyDrawOpen?: any
  prizeDraw?: any
}

const GetWinner: React.FC<GetWinnerProps> = ({
  isLuckDrawOpen,
  onOpenLuckDraw,
  onCloseLuckDraw,
  onClearGetWinner,
  onCreateLuckDrawStart,
  selectedPrizeDraw,
  selectedTempData,
  onFetchPrizeDrawSettingsStart,
  globalSettings: { loading, prizeDrawList: data },
  auth,
  setLuckyDrawOpen,
  prizeDraw,
  onFetchPrizeDrawByIdStart,
  // onFetchStateByIdStart,
}) => {
  useEffect(() => {
    onFetchPrizeDrawSettingsStart({
      countryId: auth?.currentUser?.userCountry?.countryId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentUser?.userCountry?.countryId])

  // useEffect(() => {
  //   onFetchPrizeDrawByIdStart(selectedPrizeDraw?.id)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedPrizeDraw.id])

  useEffect(() => {
    if (selectedPrizeDraw && selectedPrizeDraw.id) {
      onFetchPrizeDrawByIdStart(selectedPrizeDraw.id)
    }
  }, [selectedPrizeDraw?.id])

  const refreshPrizeDraw = () => {
    onFetchPrizeDrawByIdStart(selectedPrizeDraw.id)
  }

  const [filterDates, setFilterDates] = useState<{ from: any; to: any }>({
    from: null,
    to: null,
  })

  const [clickCount, setClickCount] = useState(data?.data[0]?.drawLimit)

  const [iteration, setIteration] = useState(
    prizeDraw?.solePrizeDrawData?.iteration
  )

  const [prizeDrawListData, setPrizeDrawListData] = useState<any>(
    prizeDraw?.luckyDrawList?.member
  )

  useEffect(() => {
    setPrizeDrawListData(prizeDraw?.luckyDrawList?.member)
  }, [])

  useEffect(() => {
    if (prizeDraw?.luckyDrawList) {
      setIteration(prizeDraw?.luckyDrawList?.iteration)
    }
  }, [prizeDraw?.luckyDrawList?.iteration])

  useEffect(() => {
    if (!_.isEmpty(prizeDraw?.solePrizeDrawData)) {
      setIteration(prizeDraw?.solePrizeDrawData?.iteration)
    }
  }, [selectedPrizeDraw?.id])

  const closeAndClearDialog = () => {
    onClearGetWinner() // Call onClearGetWinner function
    onCloseLuckDraw() // close dialog
  }

  const validationSchema = yup.object().shape(
    {
      // max_spending: yup.number().required('maximum spending is required'),
      max_spending: yup
        .number()
        .nullable()
        .notRequired()
        .when(['min_spending'], (min_spending): any => {
          if (min_spending) {
            return yup
              .number()
              .moreThan(
                yup.ref('min_spending'),
                'Maximum spending must be greater than Minimum spending'
              )
          }
        }),
      // .moreThan(
      //   yup.ref('min_spending'),
      //   'Maximum spending must be greater than Minimum spending'
      // ),
      min_spending: yup
        .number()
        .nullable()
        .notRequired()
        .when(['max_spending'], (max_spending): any => {
          if (max_spending) {
            return yup
              .number()
              .lessThan(
                yup.ref('max_spending'),
                'Minimum spending must be less than Maximum spending'
              )
          }
        }),
    },
    [['min_spending', 'max_spending']]
  )

  const handleClick = () => {
    setClickCount((prevCount) => prevCount - 1)
  }

  let currentDate = moment()
  let date = currentDate.format('DD-MM-YYYY')

  const [myDate, setMyDate] = useState<string>(date)

  // const backdropStyle = { backgroundColor: 'blue !important' }

  const checkMemberType = (data) => {
    if (data === 'premiumMember') {
      return true
    } else if (data === 'freeMember') {
      return false
    } else {
      return null
    }
  }

  return (
    <AppDialog
      sxStyle={{ backgroundColor: 'transparent' }}
      dividers
      maxWidth="md"
      open={isLuckDrawOpen}
      onClose={closeAndClearDialog}
      title="Prize Draw Winner Search"
    >
      <Formik
        validateOnChange={true}
        initialValues={{
          date_from: filterDates?.from ? new Date(filterDates?.from) : null,
          date_to: filterDates?.to ? new Date(filterDates?.to) : null,
          max_spending: '',
          min_spending: '',
          is_user_premium: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          onCreateLuckDrawStart({
            params: removeEmptyFields({
              ...data,
              date_from: data?.date_from
                ? moment(new Date(data?.date_from))
                    .add(1, 'days')
                    .format('DD-MM-YYYY')
                : data?.date_from,
              date_to: data?.date_to
                ? moment(new Date(data?.date_to))
                    .add(2, 'days')
                    .format('DD-MM-YYYY')
                : null,
              id: selectedPrizeDraw?.id,
              max_spending:
                data?.max_spending === '' ? null : data?.max_spending,
              min_spending:
                data?.min_spending === '' ? null : data?.min_spending,
            }),
            refreshPrizeDraw,
          })
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Box sx={{ p: 5 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture
                        inputFormat="DD-MM-YYYY"
                        label="From"
                        value={values?.date_from}
                        onChange={(value) => {
                          setFieldValue('date_from', value)
                          setFilterDates?.((val) => ({
                            ...val,
                            // from: moment(value).format('DD-MM-YYYY'),
                            from: new Date(value).toDateString(),
                          }))
                        }}
                        renderInput={(params) => (
                          <TextField
                            name="appliedDate"
                            size="small"
                            required
                            sx={{ width: 1 }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture
                        inputFormat="DD-MM-YYYY"
                        // minDate={values?.date_from}
                        label="To"
                        value={values?.date_to}
                        onChange={(value) => {
                          setFieldValue('date_to', value)
                          setFilterDates?.((val) => ({
                            ...val,
                            to: new Date(value).toDateString(),
                          }))
                        }}
                        renderInput={(params) => (
                          <TextField
                            name="appliedDate"
                            size="small"
                            sx={{ width: 1 }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <AppTextField
                      placeholder={'Minimum spending'}
                      name="min_spending"
                      type="number"
                      label={'Minimum spending'}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <AppTextField
                      placeholder={'Maximum spending'}
                      name="max_spending"
                      type="number"
                      label={'Maximum spending'}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      options={PrizeDrawMemberFeeType}
                      onChange={(event, newValue: any) => {
                        setFieldValue(
                          'is_user_premium',
                          checkMemberType(newValue?.value)
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required label="Member Type" />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ height: 30 }} />
                <Grid item xs={12} lg={4}>
                  <Typography sx={{ color: '#000000' }}>
                    {/* {` You can retry the winner search for maximum (${data?.data[0]?.drawLimit}) times for
                    each prize draw`} */}

                    {` You can retry the winner search for maximum (${prizeDraw?.solePrizeDrawData?.prizeDrawLimit}) times for
                    each prize draw`}
                  </Typography>
                  <Box sx={{ height: 20 }} />
                  <Typography
                    sx={{
                      color: '#000000',
                      fontWeight: 500,
                    }}
                  >
                    {/* {`Attempts Remaining: (${
                      prizeDraw?.luckyDrawList?.iteration === undefined
                        ? data?.data[0]?.drawLimit -
                          prizeDraw?.solePrizeDrawData?.iteration
                        : data?.data[0]?.drawLimit -
                          prizeDraw?.luckyDrawList?.iteration
                    })`} */}

                    {`Attempts Remaining: (${
                      prizeDraw?.luckyDrawList?.iteration === undefined
                        ? prizeDraw?.solePrizeDrawData?.prizeDrawLimit -
                          prizeDraw?.solePrizeDrawData?.iteration
                        : data?.data[0]?.drawLimit -
                          prizeDraw?.luckyDrawList?.iteration
                    })`}
                  </Typography>
                </Grid>
                <Box sx={{ height: 30 }} />
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      sx={{
                        color: '#ffffff',
                        display: 'flex',
                        background: '#00bfcd',
                        '&:hover': {
                          background: '#ec4785',
                        },
                        px: 5,
                      }}
                      type="submit"
                      // disabled={clickCount === 0}
                    >
                      Find Winner
                    </Button>
                    <Button
                      sx={{ ml: '0.5rem' }}
                      onClick={() => {
                        onCloseLuckDraw()
                        onClearGetWinner()
                      }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
                <Box sx={{ height: 20 }} />
                {!_.isEmpty(prizeDrawListData) ||
                  (!_.isEmpty(prizeDraw?.luckyDrawList?.member) && (
                    <>
                      <AppCard
                        sx={{
                          padding: '1rem 0',
                          boxShadow: 'inset 0px 0px 5px 0px rgba(0,0,0,0.3)',
                        }}
                      >
                        <Typography variant="h2" sx={{ textAlign: 'center' }}>
                          Winner Details
                        </Typography>

                        <Box sx={{ height: 50 }} />

                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Typography variant="body1">
                              <b> Name: &nbsp;</b>{' '}
                              {prizeDraw?.luckyDrawList?.member?.firstname}{' '}
                              {prizeDraw?.luckyDrawList?.member?.lastname}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="body1">
                              <b>Email:&nbsp;</b>{' '}
                              {prizeDraw?.luckyDrawList?.member?.email}{' '}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="body1">
                              <b> Phone number: &nbsp;</b>
                              {
                                prizeDraw?.luckyDrawList?.member?.phoneNumber
                              }{' '}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AppCard>
                    </>
                  ))}
              </Box>
            </Form>
          )
        }}
      </Formik>
    </AppDialog>
  )
}

export default container(GetWinner)
