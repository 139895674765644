// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'
export const fetchReferredCollaborationPartnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getAll?salesAgentType=collaborationPartner`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllCpForBatchSuggestion = (params) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getAllCP`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCollaborationPartnerRefer = (params) => {
  return () =>
    axiosRequest
      .post(`/api/salesAgent/createCPRefer`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCollaborationPartnerById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCollaborationPartner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCollaborationPackageList = (
  countryId: number,
  partnerType: string,
  params
) => {
  return () =>
    axiosRequest
      .get(
        `/api/collaborationPackage/getAll?countryId=${countryId}&partnerType=country`,
        {
          params,
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalCollaborationPartnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getAll?salesAgentType=collaborationPartner`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchGlobalCPPackageList = (
  countryId: number,
  partnerType: string,
  params
) => {
  if (countryId === null || countryId === undefined) {
    return () =>
      axiosRequest
        .get(`/api/collaborationPackage/getAll?partnerType=${partnerType}`, {
          params,
        })
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  } else {
    return () =>
      axiosRequest
        .get(
          `/api/collaborationPackage/getAll?countryId=${countryId}&partnerType=${partnerType}`,
          {
            params,
          }
        )
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
  }
}

export const fetchCountryAdminCPPackageList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/collaborationPackage/getAll`, {
        params,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchStripeInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/payout/invoice/stripeInformation`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCollaborationPartnerProfileInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getOwnProfile`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editOwnCpProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/editOwnProfile`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
