/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import {
  fetchPrizeDrawByPanelStart,
  fetchGlobalAdminPrizeDrawStart,
} from 'store/prizeDraw/prizeDrawSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  prizeDraw: state.prizeDraw,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchPrizeDrawStart: (payload) =>
    dispatch(fetchPrizeDrawByPanelStart(payload)),
  onFetchGlobalAdminPrizeDrawStart: (payload) =>
    dispatch(fetchGlobalAdminPrizeDrawStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
