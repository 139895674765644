import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  Grid,
  Autocomplete,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
} from '@mui/material'

import AppDialog from '@crema/core/AppDialog'
import container from './EditReferredMerchantInfo.container'

import * as yup from 'yup'
import { Form, Formik, Field } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import TestStateSelect from 'components/DropdownComponents/StateSelect'

interface AddNewCountryProps {
  isAddCountryOpen: boolean
  merchant?: any
  selectedMerchant?: any
  onOpenCountryTask?: () => void
  refreshPageData?: () => void
  onCloseAddCountry: () => void
  clearGeneralData?: () => void
  onCreateClubStart: (params) => void
  onFetchMerchantBasicInfoPanelUserStart?: (params) => void
  onFetchReferredMerchantByIdStart?: (params) => void
  onEditMerchantInfoStart?: (params) => void
  onEditReferredMerchantStart?: (params) => void

  siteCoordinator: any
  merchantId?: number
  club: any
  auth: any
  adminRoute?: any
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function handleClick(event) {
  event.preventDefault()
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  if (index == 0) {
    return
  }
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  isAddCountryOpen,
  clearGeneralData,
  onCloseAddCountry,
  onEditReferredMerchantStart,
  onEditMerchantInfoStart,
  refreshPageData,
  onCreateClubStart,
  merchantId,
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  adminRoute,
  merchant: { generalBasicInfo, referredMerchantInfo, isEditing, loading },
  selectedMerchant,
  onFetchReferredMerchantByIdStart,
}) => {
  useEffect(() => {
    onFetchReferredMerchantByIdStart(selectedMerchant?.id)
  }, [selectedMerchant])

  const [value, setValue] = React.useState(0)

  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const validationSchema = yup.object({})

  return (
    <>
      {loading ? (
        '...loading'
      ) : (
        <AppDialog
          dividers
          maxWidth="lg"
          open={isAddCountryOpen}
          onClose={() => onCloseAddCountry()}
          title="Edit Referred Merchant"
        >
          <Box sx={{ width: '100%', p: 1 }}>
            <Formik
              validateOnChange={true}
              enableReinitialize
              initialValues={{
                merchantName: referredMerchantInfo?.merchantName ?? '',
                merchantEmail: referredMerchantInfo?.merchantEmail ?? '',
                merchantPhoneNumber:
                  referredMerchantInfo?.merchantPhoneNumber ?? '',
                contactPersonFirstName:
                  referredMerchantInfo?.contactPersonFirstName ?? '',
                contactPersonLastName:
                  referredMerchantInfo?.contactPersonLastName ?? '',
                email: referredMerchantInfo?.email ?? '',
                city: referredMerchantInfo?.city ?? '',
                username: referredMerchantInfo?.username ?? '',
                phoneNumber: referredMerchantInfo?.phoneNumber ?? '',
                // stateId: referredMerchantInfo?.__state__?.stateName,

                stateId: referredMerchantInfo?.__state__
                  ? {
                      ...referredMerchantInfo?.__state__,
                      label: referredMerchantInfo?.__state__?.stateName,
                    }
                  : null,
              }}
              validationSchema={validationSchema}
              onSubmit={(data: any, { setSubmitting }) => {
                setSubmitting(true)

                onEditReferredMerchantStart({
                  params: {
                    ...data,
                    id: selectedMerchant?.id,
                    stateId: data?.stateId?.id,
                  },
                  closeModal: onCloseAddCountry,
                  refreshPageData,
                })

                setSubmitting(false)
              }}
              render={({ values, setFieldValue, handleBlur, handleChange }) => {
                return (
                  <Form>
                    <Box sx={{ p: 2 }}>
                      <Grid container spacing={4}>
                        <Grid item container spacing={4}>
                          <Grid item xs={12}>
                            <Typography variant="h4">Merchant Info</Typography>
                          </Grid>

                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Merchant Name'}
                              label={'Merchant Name'}
                              name="merchantName"
                              variant="outlined"
                              required
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item container direction="row" xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Merchant Email'}
                              label={'Merchant Email'}
                              name="merchantEmail"
                              variant="outlined"
                              required
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item container direction="row" xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Merchant Phone Number'}
                              label={'Merchant Phone Number'}
                              name="merchantPhoneNumber"
                              type="number"
                              variant="outlined"
                              required
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>
                        </Grid>
                        <Grid item container spacing={4}>
                          <Grid item xs={12}>
                            <Typography variant="h4">Contact Info</Typography>
                          </Grid>

                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Contact Person First Name'}
                              label={'Contact Person First Name'}
                              name="contactPersonFirstName"
                              variant="outlined"
                              required
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item container direction="row" xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Contact Person Last Name'}
                              label={'Contact Person Last Name'}
                              name="contactPersonLastName"
                              variant="outlined"
                              required
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item container direction="row" xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Email'}
                              label={'Email'}
                              name="email"
                              variant="outlined"
                              required
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item container direction="row" xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Phone Number'}
                              label={'Phone Number'}
                              name="phoneNumber"
                              type="number"
                              variant="outlined"
                              required
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item container direction="row" xs={12} lg={3}>
                            <AppTextField
                              placeholder={'city'}
                              label={'city'}
                              name="city"
                              variant="outlined"
                              required
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'Username'}
                              label={'Username'}
                              name="username"
                              variant="outlined"
                              required
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid>

                          {/* <Grid item xs={12} lg={3}>
                            <AppTextField
                              placeholder={'State'}
                              label={'State'}
                              name="stateId"
                              required
                              variant="outlined"
                              sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                  fontSize: 14,
                                },
                              }}
                              size="small"
                            />
                          </Grid> */}

                          <Grid item xs={12} lg={3}>
                            <Field
                              name="stateId"
                              component={TestStateSelect}
                              defaultSelected={
                                values?.countryId
                                  ? values?.countryId
                                  : globalCountry
                              }
                              makeDefaultEmpty={true}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Box sx={{ height: 20 }} />
                      <Box sx={{ height: 30 }} />
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          sx={{
                            color: '#ffffff',
                            display: 'flex',
                            background: '#00bfcd',
                            '&:hover': {
                              background: '#ec4785',
                            },
                            px: 5,
                          }}
                          disabled={isEditing}
                          type="submit"
                        >
                          {isEditing ? <CircularProgress size={20} /> : 'Save'}
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                          variant="outlined"
                          onClick={() => onCloseAddCountry()}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )
              }}
            />
          </Box>
        </AppDialog>
      )}
    </>
  )
}

export default container(AddNewClub)
