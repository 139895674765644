import { axiosRequestCMS, axiosRequest } from 'utils'

export const fetchPageList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`api/page/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
// .get(`api/page/getAll?page=1&limit=10&offset=0&lang=en`, { params })
