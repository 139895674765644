import { createSlice } from '@reduxjs/toolkit'

import { IReviewState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IReviewState = {
  reviewTextList: listObj,
  merchantReviewList: listObj,
  soleReviewText: {},
  error: null,
  loading: false,
  isEditing: false,
  isVerifing: false,
  isRejecting: false,
  isDeleting: false,
  isActivating: false,
  isFetchingOne: false,
  isCreating: false,
  hasMoreData: false,
}

export const reviewSlice = createSlice({
  name: 'review',
  initialState: INITIAL_STATE,
  reducers: {
    fetchReviewTextListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchReviewTextListSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.reviewTextList = action.payload
      state.isFetching = false
    },
    fetchReviewTextListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createReviewTextStart: (state: any, action) => {
      state.isCreating = true
    },
    createReviewTextSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createReviewTextFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchOneReviewTextByIdStart: (state: any, action) => {
      state.isFetchingOne = true
    },
    fetchOneReviewTextByIdSuccess: (state: any, action) => {
      state.soleReviewText = action.payload
      state.isFetchingOne = false
    },
    fetchOneReviewTextByIdFailure: (state: any, action) => {
      state.isFetchingOne = false
      state.error = action.payload
    },

    deleteReviewTextByIdStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteReviewTextByIdSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteReviewTextByIdFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    activateDeactivateReviewTextByIdStart: (state: any, action) => {
      state.isActivating = true
    },
    activateDeactivateReviewTextByIdSuccess: (state: any, action) => {
      state.isActivating = false
    },
    activateDeactivateReviewTextByIdFailure: (state: any, action) => {
      state.isActivating = false
      state.error = action.payload
    },

    editReviewTextByIdStart: (state: any, action) => {
      state.isEditing = true
    },
    editReviewTextByIdSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editReviewTextByIdFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchMerchantReviewListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantReviewListSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.merchantReviewList = action.payload
      state.isFetching = false
    },
    fetchMerchantReviewListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantReviewListByAdminStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantReviewListByAdminSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.merchantReviewList = action.payload
      state.isFetching = false
    },
    fetchMerchantReviewListByAdminFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
  },
})

export const {
  fetchReviewTextListStart,
  fetchReviewTextListSuccess,
  fetchReviewTextListFailure,
  createReviewTextStart,
  createReviewTextSuccess,
  createReviewTextFailure,
  fetchOneReviewTextByIdStart,
  fetchOneReviewTextByIdSuccess,
  fetchOneReviewTextByIdFailure,
  deleteReviewTextByIdStart,
  deleteReviewTextByIdSuccess,
  deleteReviewTextByIdFailure,

  activateDeactivateReviewTextByIdStart,
  activateDeactivateReviewTextByIdSuccess,
  activateDeactivateReviewTextByIdFailure,

  editReviewTextByIdStart,
  editReviewTextByIdSuccess,
  editReviewTextByIdFailure,

  fetchMerchantReviewListStart,
  fetchMerchantReviewListSuccess,
  fetchMerchantReviewListFailure,

  fetchMerchantReviewListByAdminStart,
  fetchMerchantReviewListByAdminSuccess,
  fetchMerchantReviewListByAdminFailure,
} = reviewSlice.actions

export default reviewSlice.reducer
