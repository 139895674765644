import { createSlice } from '@reduxjs/toolkit'

import { IMerchant } from '../interfaces'
import { listObj, sortWeek } from 'utils'

const INITIAL_STATE: IMerchant = {
  merchantList: listObj,
  referredMerchantList: listObj,
  merchantDraftList: listObj,
  merchantGeneralList: listObj,
  merchantGeneralByLocationList: listObj,
  merchantListStateWise: listObj,
  merchantCardInfoList: listObj,
  merchantTerminalList: listObj,
  terminalList: listObj,
  terminalListByPanel: listObj,
  assignerListByRole: listObj,
  merchantFranchisorList: listObj,
  activityListReportByPanel: listObj,
  merchantMultilingualList: listObj,
  charityMultilingualList: listObj,
  merchantCategoryMultilingualList: listObj,

  generalBasicInfo: {},
  generalWebsiteInfo: {},
  generalImageInfo: {},
  generalDiscountInfo: {},
  generalBankInfo: {},

  draftBasicInfo: {},
  draftWebsiteInfo: {},
  draftImageInfo: {},
  draftDiscountInfo: {},
  draftBankInfo: {},

  profileInfo: {},
  profileDiscountInfo: {},
  profileImagesInfo: {},
  profileWebsiteAndBankInfo: {},
  merchantTerminalInfo: {},
  terminalInfo: {},
  referredMerchantInfo: {},

  billingInfo: {},

  soleMerchantGroup: {},
  soleMerchantMultilingual: {},

  error: null,
  merchantCodeResponse: null,
  couponCodeResponse: null,
  merchantCodeData: {},
  couponCodeData: {},
  merchantCodeError: false,
  couponCodeError: false,

  walletInfo: { info: {}, detail: listObj },
  sharableMerchantFormData: {},

  loading: false,
  isFetching: false,
  isUpdating: false,
  isVerifying: false,
  isEditing: false,
  isCreating: false,
  isRejecting: false,
  isGenerating: false,
  isActivating: false,
  isTransfering: false,
  isCreatingDraft: false,
  isCreatingGeneral: false,
  isDeactivating: false,
  isReferring: false,
  isSetting: false,
  isApplyingCode: false,
  isApplyingCouponCode: false,
  isApproving: false,
  isDeleting: false,
  discountRateList: [],
}

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState: INITIAL_STATE,
  reducers: {
    createStep1DraftStart: (state: any, action) => {
      state.isCreatingDraft = true
    },
    createStep1DraftSuccess: (state: any, action) => {
      state.isCreatingDraft = false
    },
    createStep1DraftFailure: (state: any, action) => {
      state.isCreatingDraft = false
      state.error = action.payload
    },

    createStep1GeneralStart: (state: any, action) => {
      state.isCreatingGeneral = true
    },
    createStep1GeneralSuccess: (state: any, action) => {
      state.isCreatingGeneral = false
    },
    createStep1GeneralFailure: (state: any, action) => {
      state.isCreatingGeneral = false
      state.error = action.payload
    },

    editStep1Start: (state: any, action) => {
      state.isEditing = true
    },
    editStep1Success: (state: any, action) => {
      state.isEditing = false
    },
    editStep1Failure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    editStep1GeneralStart: (state: any, action) => {
      state.isEditing = true
    },
    editStep1GeneralSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editStep1GeneralFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    createStep2DraftStart: (state: any, action) => {
      state.isCreatingDraft = true
    },
    createStep2DraftSuccess: (state: any, action) => {
      state.draftWebsiteInfo = {}

      state.isCreatingDraft = false
    },
    createStep2DraftFailure: (state: any, action) => {
      state.isCreatingDraft = false
      state.error = action.payload
    },

    clearStep2DraftData: (state: any) => {
      state.draftWebsiteInfo = {}
    },

    createStep2GeneralStart: (state: any, action) => {
      state.isCreatingGeneral = true
    },
    createStep2GeneralSuccess: (state: any, action) => {
      state.generalWebsiteInfo = {}

      state.isCreatingGeneral = false
    },
    createStep2GeneralFailure: (state: any, action) => {
      state.isCreatingGeneral = false
      state.error = action.payload
    },

    clearStep2GeneralData: (state: any) => {
      state.generalWebsiteInfo = {}
    },

    updateStep2GeneralStart: (state: any, action) => {
      state.isUpdating = true
    },
    updateStep2GeneralSuccess: (state: any, action) => {
      state.generalWebsiteInfo = {}
      state.isUpdating = false
    },
    updateStep2GeneralFailure: (state: any, action) => {
      state.isUpdating = false
      state.error = action.payload
    },

    updateStep3GeneralStart: (state: any, action) => {
      state.isUpdating = true
    },
    updateStep3GeneralSuccess: (state: any) => {
      state.isUpdating = false
    },
    updateStep3GeneralFailure: (state: any, action) => {
      state.isUpdating = false
      state.error = action.payload
    },

    createStep3DraftStart: (state: any, action) => {
      state.isCreatingDraft = true
    },
    createStep3DraftSuccess: (state: any) => {
      state.isCreatingDraft = false
    },
    createStep3DraftFailure: (state: any, action) => {
      state.isCreatingDraft = false
      state.error = action.payload
    },

    createStep3GeneralStart: (state: any, action) => {
      state.isCreatingGeneral = true
    },
    createStep3GeneralSuccess: (state: any) => {
      state.isCreatingGeneral = false
    },
    createStep3GeneralFailure: (state: any, action) => {
      state.isCreatingGeneral = false
      state.error = action.payload
    },

    createStep4DraftStart: (state: any, action) => {
      state.isCreatingDraft = true
    },
    createStep4DraftSuccess: (state: any, action) => {
      state.isCreatingDraft = false
    },
    createStep4DraftFailure: (state: any, action) => {
      state.isCreatingDraft = false
      state.error = action.payload
    },

    createStep4GeneralStart: (state: any, action) => {
      state.isCreatingGeneral = true
    },
    createStep4GeneralSuccess: (state: any, action) => {
      state.isCreatingGeneral = false
    },
    createStep4GeneralFailure: (state: any, action) => {
      state.isCreatingGeneral = false
      state.error = action.payload
    },

    updateStep4GeneralStart: (state: any, action) => {
      state.isUpdating = true
    },
    updateStep4GeneralSuccess: (state: any, action) => {
      state.isUpdating = false
    },
    updateStep4GeneralFailure: (state: any, action) => {
      state.isUpdating = false
      state.error = action.payload
    },

    fetchAllMerchantListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllMerchantListSuccess: (state: any, action) => {
      state.merchantList = action.payload
      state.isFetching = false
    },
    fetchAllMerchantListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantDiscountByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantDiscountByIdSuccess: (state: any, action) => {
      state.isFetching = false
    },
    fetchMerchantDiscountByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchWalletInfoStart: (state: any, action) => {
      state.loading = true
    },
    fetchWalletInfoSuccess: (state: any, action) => {
      state.loading = false
      state.walletInfo = action.payload
    },
    fetchWalletInfoFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantDraftStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantDraftSuccess: (state: any, action) => {
      state.merchantDraftList = action.payload
      state.isFetching = false
    },
    fetchMerchantDraftFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantFranchisorDraftStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantFranchisorDraftSuccess: (state: any, action) => {
      state.merchantDraftList = action.payload
      state.isFetching = false
    },
    fetchMerchantFranchisorDraftFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchBillingInfoStart: (state: any, action) => {
      state.loading = true
    },
    fetchBillingInfoSuccess: (state: any, action) => {
      state.billingInfo = action.payload
      state.loading = false
    },
    fetchBillingInfoFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchMerchantGeneralStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantGeneralSuccess: (state: any, action) => {
      state.merchantGeneralList = action.payload
      state.isFetching = false
    },
    fetchMerchantGeneralFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAreaMerchantGeneralByLocationStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAreaMerchantGeneralByLocationSuccess: (state: any, action) => {
      state.merchantGeneralByLocationList = action.payload
      state.isFetching = false
    },
    fetchAreaMerchantGeneralByLocationFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantStateWiseDataStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantStateWiseDataSuccess: (state: any, action) => {
      state.merchantListStateWise = action.payload
      state.isFetching = false
    },
    fetchMerchantStateWiseDataFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantFranchisorGeneralStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantFranchisorGeneralSuccess: (state: any, action) => {
      state.merchantGeneralList = action.payload
      state.isFetching = false
    },
    fetchMerchantFranchisorGeneralFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createMerchantDiscountStart: (state: any, action) => {
      state.isCreating = true
    },
    createMerchantDiscountSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createMerchantDiscountFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    createBankInfoStart: (state: any, action) => {
      state.isCreating = true
    },
    createBankInfoSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createBankInfoFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchMerchantGeneralBasicInfoPanelUserStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantGeneralBasicInfoPanelUserSuccess: (state: any, action) => {
      state.generalBasicInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantGeneralBasicInfoPanelUserFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantGeneralBasicInfoForStateMerchantStart: (
      state: any,
      action
    ) => {
      state.isFetching = true
    },
    fetchMerchantGeneralBasicInfoForStateMerchantSuccess: (
      state: any,
      action
    ) => {
      state.generalBasicInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantGeneralBasicInfoForStateMerchantFailure: (
      state: any,
      action
    ) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantFranchiseGeneralBasicInfoPanelUserStart: (
      state: any,
      action
    ) => {
      state.isFetching = true
    },
    fetchMerchantFranchiseGeneralBasicInfoPanelUserSuccess: (
      state: any,
      action
    ) => {
      state.generalBasicInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantFranchiseGeneralBasicInfoPanelUserFailure: (
      state: any,
      action
    ) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantGeneralBasicInfoPanelAdminStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantGeneralBasicInfoPanelAdminSuccess: (state: any, action) => {
      state.generalBasicInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantGeneralBasicInfoPanelAdminFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantDraftBasicInfoPanelAdminStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantDraftBasicInfoPanelAdminSuccess: (state: any, action) => {
      state.draftBasicInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantDraftBasicInfoPanelAdminFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    rejectMerchantStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectMerchantSuccess: (state: any, action) => {
      state.isRejecting = false
    },
    rejectMerchantFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },

    verifyMerchantStart: (state: any, action) => {
      state.isVerifying = true
    },
    verifyMerchantSuccess: (state: any, action) => {
      state.isVerifying = false
    },
    verifyMerchantFailure: (state: any, action) => {
      state.isVerifying = false
      state.error = action.payload
    },

    activateMerchantStart: (state: any, action) => {
      state.isVerifying = true
    },
    activateMerchantSuccess: (state: any, action) => {
      state.isVerifying = false
    },
    activateMerchantFailure: (state: any, action) => {
      state.isVerifying = false
      state.error = action.payload
    },

    activateSubscriptionOfMerchantStart: (state: any, action) => {
      state.isActivating = true
    },
    activateSubscriptionOfMerchantSuccess: (state: any, action) => {
      state.isActivating = false
    },
    activateSubscriptionOfMerchantFailure: (state: any, action) => {
      state.isActivating = false
      state.error = action.payload
    },

    fetchMerchantGeneralWebsiteInfoPanelUserStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantGeneralWebsiteInfoPanelUserSuccess: (state: any, action) => {
      state.generalWebsiteInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantGeneralWebsiteInfoPanelUserFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantGeneralImagePanelUserStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantGeneralImagePanelUserSuccess: (state: any, action) => {
      state.generalImageInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantGeneralImagePanelUserFailure: (state: any, action) => {
      state.generalImageInfo = {}
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantGeneralDiscountInfoPanelUserStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantGeneralDiscountInfoPanelUserSuccess: (state: any, action) => {
      state.generalDiscountInfo = {
        ...action?.payload,
        discountRates: sortWeek(action?.payload?.discountRates),
      }
      state.isFetching = false
    },
    fetchMerchantGeneralDiscountInfoPanelUserFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantGeneralBankInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantGeneralBankInfoSuccess: (state: any, action) => {
      state.generalBankInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantGeneralBankInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantDraftBasicInfoPanelUserStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantDraftBasicInfoPanelUserSuccess: (state: any, action) => {
      state.draftBasicInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantDraftBasicInfoPanelUserFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantDraftWebsiteInfoPanelUserStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantDraftWebsiteInfoPanelUserSuccess: (state: any, action) => {
      state.draftWebsiteInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantDraftWebsiteInfoPanelUserFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantDraftImagePanelUserStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantDraftImagePanelUserSuccess: (state: any, action) => {
      state.draftImageInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantDraftImagePanelUserFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantDraftDiscountInfoPanelUserStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantDraftDiscountInfoPanelUserSuccess: (state: any, action) => {
      state.draftDiscountInfo = {
        ...action?.payload,
        discountRates: sortWeek(action?.payload?.discountRates),
      }

      state.isFetching = false
    },
    fetchMerchantDraftDiscountInfoPanelUserFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    checkApplyMerchantPremiumCodeStart: (state: any, action) => {
      state.isApplyingCode = true
    },
    checkApplyMerchantPremiumCodeSuccess: (state: any, action) => {
      state.isApplyingCode = false
      state.merchantCodeResponse = action.payload.successMessage
      state.merchantCodeData = action.payload.data
      state.merchantCodeError = false
    },
    checkApplyMerchantPremiumCodeFailure: (state: any, action) => {
      state.isApplyingCode = false
      state.merchantCodeResponse = action.payload
      state.merchantCodeError = true
    },

    applyMerchantCouponCodeStart: (state: any, action) => {
      state.isApplyingCouponCode = true
    },
    applyMerchantCouponCodeSuccess: (state: any, action) => {
      state.isApplyingCouponCode = false
      state.couponCodeResponse = action.payload.successMessage
      state.couponCodeData = action.payload.data
      state.couponCodeError = false
    },
    applyMerchantCouponCodeFailure: (state: any, action) => {
      state.isApplyingCouponCode = false
      state.couponCodeResponse = action.payload
      state.couponCodeError = true
    },

    checkApplyMerchantPremiumCodeUpgradePackageStart: (state: any, action) => {
      state.isApplyingCode = true
    },
    checkApplyMerchantPremiumCodeUpgradePackageSuccess: (
      state: any,
      action
    ) => {
      state.isApplyingCode = false
      state.merchantCodeResponse = action.payload.successMessage
      state.merchantCodeData = action.payload.data
      state.merchantCodeError = false
    },
    checkApplyMerchantPremiumCodeUpgradePackageFailure: (
      state: any,
      action
    ) => {
      state.isApplyingCode = false
      state.merchantCodeResponse = action.payload
      state.merchantCodeError = true
    },

    clearMerchantPremiumCode: (state: any) => {
      state.isApplyingCode = false
      state.merchantCodeResponse = null
      state.merchantCodeData = {}
      state.merchantCodeError = false
    },

    setMerchantPopularStart: (state: any, action) => {
      state.isCreating = true
    },
    setMerchantPopularSuccess: (state: any, action) => {
      state.isCreating = false
    },
    setMerchantPopularFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    clearMerchantPremiumCodeData: (state: any) => {
      state.merchantCodeResponse = null
    },

    clearMerchantCouponCodeData: (state: any) => {
      state.couponCodeResponse = null
      state.couponCodeData = null
    },

    clearDraftData: (state: any) => {
      state.draftBasicInfo = {}
      state.draftWebsiteInfo = {}
      state.draftImageInfo = {}
      state.draftDiscountInfo = {}
      state.draftBankInfo = {}
    },
    clearGeneralData: (state: any) => {
      state.generalBasicInfo = {}
      state.generalWebsiteInfo = {}
      state.generalImageInfo = {}
      state.generalDiscountInfo = {}
      state.generalBankInfo = {}
    },

    clearMerchantCodeData: (state: any) => {
      state.merchantCodeData = null
    },

    clearCouponCodeData: (state: any) => {
      state.couponCodeData = null
    },

    fetchMerchantProfileInfoStart: (state: any, action?: any) => {
      state.isFetching = true
    },
    fetchMerchantProfileInfoSuccess: (state: any, action) => {
      state.profileInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantProfileInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantProfileDiscountInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantProfileDiscountInfoSuccess: (state: any, action) => {
      state.profileDiscountInfo = {
        ...action?.payload,
        discountRates: sortWeek(action?.payload?.discountRates),
      }
      state.isFetching = false
    },
    fetchMerchantProfileDiscountInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantProfileImagesInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantProfileImagesInfoSuccess: (state: any, action) => {
      state.profileImagesInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantProfileImagesInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantProfileWebsiteAndBankInfoStart: (state: any) => {
      state.isFetching = true
    },
    fetchMerchantProfileWebsiteAndBankInfoSuccess: (state: any, action) => {
      state.profileWebsiteAndBankInfo = action.payload
      state.isFetching = false
    },
    fetchMerchantProfileWebsiteAndBankInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    updateBankInfoStart: (state: any, action) => {
      state.isEditing = true
    },
    updateBankInfoSuccess: (state: any, action) => {
      state.isEditing = false
    },
    updateBankInfoFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchMerchantCardInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantCardInfoSuccess: (state: any, action) => {
      state.merchantCardInfoList = action.payload
      state.isFetching = false
    },
    fetchMerchantCardInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    addMerchantCardSourceStart: (state: any, action) => {
      state.isCreating = true
    },
    addMerchantCardSourceSuccess: (state: any, action) => {
      state.isCreating = false
    },
    addMerchantCardSourceFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    setCardAsDefaultStart: (state: any, action) => {
      state.isSetting = true
    },
    setCardAsDefaultSuccess: (state: any, action) => {
      state.isSetting = false
    },
    setCardAsDefaultFailure: (state: any, action) => {
      state.isSetting = false
      state.error = action.payload
    },

    generateEcommerceKeyStart: (state: any, action) => {
      state.isActivating = true
    },
    generateEcommerceKeySuccess: (state: any, action) => {
      state.isActivating = false
    },
    generateEcommerceKeyFailure: (state: any, action) => {
      state.isActivating = false
      state.error = action.payload
    },

    editOwnMerchantProfileStart: (state: any, action) => {
      state.isEditing = true
    },
    editOwnMerchantProfileSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editOwnMerchantProfileFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    editMerchantInfoStart: (state: any, action) => {
      state.isEditing = true
    },
    editMerchantInfoSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editMerchantInfoFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    sendMerchantEmailStart: (state: any, action) => {
      state.isCreating = true
    },
    sendMerchantEmailSuccess: (state: any, action) => {
      state.isCreating = false
    },
    sendMerchantEmailFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    createMerchantTerminalStart: (state: any, action) => {
      state.isCreating = true
    },
    createMerchantTerminalSuccess: (state: any) => {
      state.isCreating = false
    },
    createMerchantTerminalFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchAllMerchantTerminalListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllMerchantTerminalListSuccess: (state: any, action) => {
      state.merchantTerminalList = action.payload
      state.isFetching = false
    },
    fetchAllMerchantTerminalListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editMerchantTerminalStart: (state: any, action) => {
      state.isEditing = true
    },
    editMerchantTerminalSuccess: (state: any) => {
      state.isEditing = false
    },
    editMerchantTerminalFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    editMerchantTerminalOwnProfileStart: (state: any, action) => {
      state.isEditing = true
    },
    editMerchantTerminalOwnProfileSuccess: (state: any) => {
      state.isEditing = false
    },
    editMerchantTerminalOwnProfileFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchOneMerchantTerminalStart: (state: any, action) => {
      state.loading = true
    },
    fetchOneMerchantTerminalSuccess: (state: any, action) => {
      state.merchantTerminalInfo = action.payload
      state.loading = false
    },
    fetchOneMerchantTerminalFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deactivateMerchantTerminalStart: (state: any, action) => {
      state.isDeactivating = true
    },
    deactivateMerchantTerminalSuccess: (state: any) => {
      state.isDeactivating = false
    },
    deactivateMerchantTerminalFailure: (state: any, action) => {
      state.isDeactivating = false
      state.error = action.payload
    },

    updateMerchantTerminalPasswordStart: (state: any, action) => {
      state.isEditing = true
    },
    updateMerchantTerminalPasswordSuccess: (state: any) => {
      state.isEditing = false
    },
    updateMerchantTerminalPasswordFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    getReferralTokenStart: (state: any) => {
      state.isGenerating = true
    },
    getReferralTokenSuccess: (state: any, action) => {
      state.isGenerating = false
      state.sharableMerchantFormData = action.payload
    },
    getReferralTokenFailure: (state: any, action) => {
      state.isGenerating = false
      state.error = action.payload
    },
    clearReferralToken: (state: any) => {
      state.sharableMerchantFormData = {}
    },

    fetchAllReferredMerchantListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllReferredMerchantListSuccess: (state: any, action) => {
      state.referredMerchantList = action.payload
      state.isFetching = false
    },
    fetchAllReferredMerchantListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createTerminalStart: (state: any, action) => {
      state.isCreating = true
    },
    createTerminalSuccess: (state: any) => {
      state.isCreating = false
    },
    createTerminalFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchAllTerminalListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllTerminalListSuccess: (state: any, action) => {
      state.terminalList = action.payload
      state.isFetching = false
    },
    fetchAllTerminalListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTerminalListByPanelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchTerminalListByPanelSuccess: (state: any, action) => {
      state.terminalListByPanel = action.payload
      state.isFetching = false
    },
    fetchTerminalListByPanelFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchTerminalByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchTerminalByIdSuccess: (state: any, action) => {
      state.terminalInfo = action.payload
      state.loading = false
    },
    fetchTerminalByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editTerminalStart: (state: any, action) => {
      state.isUpdating = true
    },
    editTerminalSuccess: (state: any, action) => {
      state.isUpdating = false
    },
    editTerminalFailure: (state: any, action) => {
      state.isUpdating = false
      state.error = action.payload
    },

    fetchMultipleAssignerByRoleStart: (state: any, action) => {
      state.isGenerating = true
    },
    fetchMultipleAssignerByRoleSuccess: (state: any, action) => {
      state.isGenerating = false
      state.assignerListByRole = action.payload
    },
    fetchMultipleAssignerByRoleFailure: (state: any, action) => {
      state.isGenerating = false
      state.error = action.payload
    },

    transferReferredMerchantStart: (state: any, action) => {
      state.isTransfering = true
    },
    transferReferredMerchantSuccess: (state: any, action) => {
      state.isTransfering = false
      state.assignerListByRole = action.payload
    },
    transferReferredMerchantFailure: (state: any, action) => {
      state.isTransfering = false
      state.error = action.payload
    },

    fetchReferredMerchantByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchReferredMerchantByIdSuccess: (state: any, action) => {
      state.isFetching = false
      state.referredMerchantInfo = action.payload
    },
    fetchReferredMerchantByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editReferredMerchantStart: (state: any, action) => {
      state.isEditing = true
    },
    editReferredMerchantSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editReferredMerchantFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    rejectReferredMerchantStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectReferredMerchantSuccess: (state: any) => {
      state.isRejecting = false
    },
    rejectReferredMerchantFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },

    approveReferredMerchantStart: (state: any, action) => {
      state.isApproving = true
    },
    approveReferredMerchantSuccess: (state: any) => {
      state.isApproving = false
    },
    approveReferredMerchantFailure: (state: any, action) => {
      state.isApproving = false
      state.error = action.payload
    },

    referMerchantMailFromDraftStart: (state: any, action) => {
      state.isReferring = true
    },
    referMerchantMailFromDraftSuccess: (state: any) => {
      state.isReferring = false
    },
    referMerchantMailFromDraftFailure: (state: any, action) => {
      state.isReferring = false
      state.error = action.payload
    },

    acceptOrRejectreferredMerchantStart: (state: any, action) => {
      state.isSetting = true
    },
    acceptOrRejectreferredMerchantSuccess: (state: any) => {
      state.isSetting = false
    },
    acceptOrRejectreferredMerchantFailure: (state: any, action) => {
      state.isSetting = false
      state.error = action.payload
    },
    createMerchantGroupStart: (state: any, action) => {
      state.isCreating = true
    },
    createMerchantGroupSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createMerchantGroupFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    fetchAllMerchantGroupStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllMerchantGroupSuccess: (state: any, action) => {
      state.merchantFranchisorList = action.payload
      state.isFetching = false
    },
    fetchAllMerchantGroupFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantGroupByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchMerchantGroupByIdSuccess: (state: any, action) => {
      state.soleMerchantGroup = action.payload
      state.loading = false
    },
    fetchMerchantGroupByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editPendingMerchantGroupStart: (state: any, action) => {
      state.isEditing = true
    },
    editPendingMerchantGroupSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editPendingMerchantGroupFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    verifyPendingMerchantGroupStart: (state: any, action) => {
      state.isVerifying = true
    },
    verifyPendingMerchantGroupSuccess: (state: any, action) => {
      state.isVerifying = false
    },
    verifyPendingMerchantGroupFailure: (state: any, action) => {
      state.isVerifying = false
      state.error = action.payload
    },

    assignIssuerTransactionCodeStart: (state: any, action) => {
      state.isAssigning = true
    },
    assignIssuerTransactionCodeSuccess: (state: any, action) => {
      state.isAssigning = false
    },
    assignIssuerTransactionCodeFailure: (state: any, action) => {
      state.isAssigning = false
      state.error = action.payload
    },

    editMerchantGroupStart: (state: any, action) => {
      state.isEditing = true
    },
    editMerchantGroupSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editMerchantGroupFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    activateOrDeactivateMerchantStart: (state: any, action) => {
      state.loading = true
    },
    activateOrDeactivateMerchantSuccess: (state: any, action) => {
      state.loading = false
    },
    activateOrDeactivateMerchantFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteMerchantStart: (state: any, action) => {
      state.isDeleting = true
    },
    deleteMerchantSuccess: (state: any, action) => {
      state.isDeleting = false
    },
    deleteMerchantFailure: (state: any, action) => {
      state.isDeleting = false
      state.error = action.payload
    },

    fetchActivityListReportByPanelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchActivityListReportByPanelSuccess: (state: any, action) => {
      state.activityListReportByPanel = action.payload
      state.isFetching = false
    },
    fetchActivityListReportByPanelFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchMerchantMultilingualListByPanelStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantMultilingualListByPanelSuccess: (state: any, action) => {
      state.merchantMultilingualList = action.payload
      state.isFetching = false
    },
    fetchMerchantMultilingualListByPanelFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    addMerchantMultilingualStart: (state: any, action) => {
      state.isCreating = true
    },
    addMerchantMultilingualSuccess: (state: any, action) => {
      state.isCreating = false
    },
    addMerchantMultilingualFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editMerchantMultilingualStart: (state: any, action) => {
      state.isEditing = true
    },
    editMerchantMultilingualSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editMerchantMultilingualFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    fetchtMerchantMultilingualByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchtMerchantMultilingualByIdSuccess: (state: any, action) => {
      state.soleMerchantMultilingual = action.payload
      state.loading = false
    },
    fetchtMerchantMultilingualByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    clearMerchantMultilingualData: (state: any) => {
      state.merchantMultilingualList = []
    },

    fetchCharityMultilingualListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCharityMultilingualListSuccess: (state: any, action) => {
      state.charityMultilingualList = action.payload
      state.isFetching = false
    },
    fetchCharityMultilingualListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createCharityMultilingualStart: (state: any, action) => {
      state.isCreating = true
    },
    createCharityMultilingualSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createCharityMultilingualFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editCharitytMultilingualStart: (state: any, action) => {
      state.isEditing = true
    },
    editCharityMultilingualSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editCharityMultilingualFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    clearCharityMultilingualData: (state: any) => {
      state.charityMultilingualList = []
    },

    fetchMerchantCategoryMultilingualListStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchMerchantCategoryMultilingualListSuccess: (state: any, action) => {
      state.merchantCategoryMultilingualList = action.payload
      state.isFetching = false
    },
    fetchMerchantCategoryMultilingualListFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    createMerchantCategoryMultilingualStart: (state: any, action) => {
      state.isCreating = true
    },
    createMerchantCategoryMultilingualSuccess: (state: any, action) => {
      state.isCreating = false
    },
    createMerchantCategoryMultilingualFailure: (state: any, action) => {
      state.isCreating = false
      state.error = action.payload
    },

    editMerchantCategoryMultilingualStart: (state: any, action) => {
      state.isEditing = true
    },
    editMerchantCategoryMultilingualSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editMerchantCategoryMultilingualFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    clearMerchantCategoryMultilingualData: (state: any) => {
      state.merchantCategoryMultilingualList = []
    },
  },
})

export const {
  createStep1DraftStart,
  createStep1DraftSuccess,
  createStep1DraftFailure,
  createStep1GeneralStart,
  createStep1GeneralSuccess,
  createStep1GeneralFailure,
  editStep1Start,
  editStep1Success,
  editStep1Failure,

  createStep2DraftStart,
  createStep2DraftSuccess,
  createStep2DraftFailure,

  clearStep2DraftData,

  createStep2GeneralStart,
  createStep2GeneralSuccess,
  createStep2GeneralFailure,

  clearStep2GeneralData,

  fetchWalletInfoFailure,
  fetchWalletInfoStart,
  fetchWalletInfoSuccess,

  createStep3DraftStart,
  createStep3DraftSuccess,
  createStep3DraftFailure,

  createStep3GeneralStart,
  createStep3GeneralSuccess,
  createStep3GeneralFailure,

  createBankInfoStart,
  createBankInfoSuccess,
  createBankInfoFailure,

  fetchMerchantDiscountByIdStart,
  fetchMerchantDiscountByIdSuccess,
  fetchMerchantDiscountByIdFailure,

  fetchAllMerchantListStart,
  fetchAllMerchantListSuccess,
  fetchAllMerchantListFailure,

  fetchMerchantDraftStart,
  fetchMerchantDraftSuccess,
  fetchMerchantDraftFailure,

  fetchMerchantFranchisorDraftStart,
  fetchMerchantFranchisorDraftSuccess,
  fetchMerchantFranchisorDraftFailure,

  fetchMerchantGeneralStart,
  fetchMerchantGeneralSuccess,
  fetchMerchantGeneralFailure,

  fetchAreaMerchantGeneralByLocationStart,
  fetchAreaMerchantGeneralByLocationSuccess,
  fetchAreaMerchantGeneralByLocationFailure,

  fetchMerchantFranchisorGeneralStart,
  fetchMerchantFranchisorGeneralSuccess,
  fetchMerchantFranchisorGeneralFailure,

  createMerchantDiscountStart,
  createMerchantDiscountSuccess,
  createMerchantDiscountFailure,

  fetchMerchantGeneralBasicInfoPanelUserStart,
  fetchMerchantGeneralBasicInfoPanelUserSuccess,
  fetchMerchantGeneralBasicInfoPanelUserFailure,

  fetchMerchantGeneralBasicInfoForStateMerchantStart,
  fetchMerchantGeneralBasicInfoForStateMerchantSuccess,
  fetchMerchantGeneralBasicInfoForStateMerchantFailure,

  fetchMerchantFranchiseGeneralBasicInfoPanelUserStart,
  fetchMerchantFranchiseGeneralBasicInfoPanelUserSuccess,
  fetchMerchantFranchiseGeneralBasicInfoPanelUserFailure,

  fetchMerchantGeneralWebsiteInfoPanelUserStart,
  fetchMerchantGeneralWebsiteInfoPanelUserSuccess,
  fetchMerchantGeneralWebsiteInfoPanelUserFailure,

  fetchMerchantGeneralImagePanelUserStart,
  fetchMerchantGeneralImagePanelUserSuccess,
  fetchMerchantGeneralImagePanelUserFailure,

  fetchMerchantGeneralDiscountInfoPanelUserStart,
  fetchMerchantGeneralDiscountInfoPanelUserSuccess,
  fetchMerchantGeneralDiscountInfoPanelUserFailure,

  fetchMerchantDraftBasicInfoPanelUserStart,
  fetchMerchantDraftBasicInfoPanelUserSuccess,
  fetchMerchantDraftBasicInfoPanelUserFailure,

  fetchMerchantDraftWebsiteInfoPanelUserStart,
  fetchMerchantDraftWebsiteInfoPanelUserSuccess,
  fetchMerchantDraftWebsiteInfoPanelUserFailure,

  fetchMerchantDraftImagePanelUserStart,
  fetchMerchantDraftImagePanelUserSuccess,
  fetchMerchantDraftImagePanelUserFailure,

  fetchMerchantDraftDiscountInfoPanelUserStart,
  fetchMerchantDraftDiscountInfoPanelUserSuccess,
  fetchMerchantDraftDiscountInfoPanelUserFailure,

  clearGeneralData,
  clearDraftData,

  fetchMerchantGeneralBankInfoStart,
  fetchMerchantGeneralBankInfoSuccess,
  fetchMerchantGeneralBankInfoFailure,

  fetchMerchantGeneralBasicInfoPanelAdminStart,
  fetchMerchantGeneralBasicInfoPanelAdminSuccess,
  fetchMerchantGeneralBasicInfoPanelAdminFailure,

  fetchMerchantDraftBasicInfoPanelAdminStart,
  fetchMerchantDraftBasicInfoPanelAdminSuccess,
  fetchMerchantDraftBasicInfoPanelAdminFailure,

  createStep4DraftStart,
  createStep4DraftSuccess,
  createStep4DraftFailure,

  createStep4GeneralStart,
  createStep4GeneralSuccess,
  createStep4GeneralFailure,

  rejectMerchantStart,
  rejectMerchantSuccess,
  rejectMerchantFailure,

  verifyMerchantStart,
  verifyMerchantSuccess,
  verifyMerchantFailure,

  activateMerchantStart,
  activateMerchantSuccess,
  activateMerchantFailure,

  activateSubscriptionOfMerchantStart,
  activateSubscriptionOfMerchantSuccess,
  activateSubscriptionOfMerchantFailure,

  setMerchantPopularStart,
  setMerchantPopularSuccess,
  setMerchantPopularFailure,

  editStep1GeneralStart,
  editStep1GeneralSuccess,
  editStep1GeneralFailure,

  checkApplyMerchantPremiumCodeStart,
  checkApplyMerchantPremiumCodeSuccess,
  checkApplyMerchantPremiumCodeFailure,

  checkApplyMerchantPremiumCodeUpgradePackageStart,
  checkApplyMerchantPremiumCodeUpgradePackageSuccess,
  checkApplyMerchantPremiumCodeUpgradePackageFailure,

  clearMerchantPremiumCodeData,

  clearMerchantCouponCodeData,

  fetchMerchantProfileInfoStart,
  fetchMerchantProfileInfoSuccess,
  fetchMerchantProfileInfoFailure,

  fetchMerchantProfileDiscountInfoStart,
  fetchMerchantProfileDiscountInfoSuccess,
  fetchMerchantProfileDiscountInfoFailure,

  fetchMerchantProfileImagesInfoStart,
  fetchMerchantProfileImagesInfoSuccess,
  fetchMerchantProfileImagesInfoFailure,

  fetchMerchantProfileWebsiteAndBankInfoStart,
  fetchMerchantProfileWebsiteAndBankInfoSuccess,
  fetchMerchantProfileWebsiteAndBankInfoFailure,

  updateBankInfoStart,
  updateBankInfoSuccess,
  updateBankInfoFailure,

  fetchBillingInfoFailure,
  fetchBillingInfoStart,
  fetchBillingInfoSuccess,

  fetchMerchantCardInfoStart,
  fetchMerchantCardInfoSuccess,
  fetchMerchantCardInfoFailure,

  addMerchantCardSourceStart,
  addMerchantCardSourceFailure,
  addMerchantCardSourceSuccess,

  setCardAsDefaultStart,
  setCardAsDefaultFailure,
  setCardAsDefaultSuccess,

  clearMerchantPremiumCode,

  clearMerchantCodeData,

  generateEcommerceKeyStart,
  generateEcommerceKeySuccess,
  generateEcommerceKeyFailure,

  editOwnMerchantProfileStart,
  editOwnMerchantProfileSuccess,
  editOwnMerchantProfileFailure,

  editMerchantInfoStart,
  editMerchantInfoSuccess,
  editMerchantInfoFailure,

  sendMerchantEmailStart,
  sendMerchantEmailSuccess,
  sendMerchantEmailFailure,

  createMerchantTerminalStart,
  createMerchantTerminalSuccess,
  createMerchantTerminalFailure,

  fetchAllMerchantTerminalListStart,
  fetchAllMerchantTerminalListSuccess,
  fetchAllMerchantTerminalListFailure,

  editMerchantTerminalStart,
  editMerchantTerminalSuccess,
  editMerchantTerminalFailure,

  fetchOneMerchantTerminalStart,
  fetchOneMerchantTerminalSuccess,
  fetchOneMerchantTerminalFailure,

  deactivateMerchantTerminalStart,
  deactivateMerchantTerminalSuccess,
  deactivateMerchantTerminalFailure,

  updateMerchantTerminalPasswordStart,
  updateMerchantTerminalPasswordSuccess,
  updateMerchantTerminalPasswordFailure,

  getReferralTokenStart,
  getReferralTokenSuccess,
  getReferralTokenFailure,

  updateStep4GeneralStart,
  updateStep4GeneralSuccess,
  updateStep4GeneralFailure,

  updateStep2GeneralStart,
  updateStep2GeneralSuccess,
  updateStep2GeneralFailure,

  updateStep3GeneralStart,
  updateStep3GeneralSuccess,
  updateStep3GeneralFailure,

  fetchAllReferredMerchantListStart,
  fetchAllReferredMerchantListSuccess,
  fetchAllReferredMerchantListFailure,

  createTerminalStart,
  createTerminalSuccess,
  createTerminalFailure,

  fetchAllTerminalListStart,
  fetchAllTerminalListSuccess,
  fetchAllTerminalListFailure,

  fetchTerminalByIdStart,
  fetchTerminalByIdSuccess,
  fetchTerminalByIdFailure,

  editTerminalStart,
  editTerminalSuccess,
  editTerminalFailure,

  fetchMultipleAssignerByRoleStart,
  fetchMultipleAssignerByRoleSuccess,
  fetchMultipleAssignerByRoleFailure,

  transferReferredMerchantStart,
  transferReferredMerchantSuccess,
  transferReferredMerchantFailure,

  fetchReferredMerchantByIdStart,
  fetchReferredMerchantByIdSuccess,
  fetchReferredMerchantByIdFailure,

  rejectReferredMerchantStart,
  rejectReferredMerchantSuccess,
  rejectReferredMerchantFailure,

  approveReferredMerchantStart,
  approveReferredMerchantSuccess,
  approveReferredMerchantFailure,

  editMerchantTerminalOwnProfileStart,
  editMerchantTerminalOwnProfileSuccess,
  editMerchantTerminalOwnProfileFailure,

  editReferredMerchantStart,
  editReferredMerchantSuccess,
  editReferredMerchantFailure,

  clearReferralToken,

  referMerchantMailFromDraftStart,
  referMerchantMailFromDraftSuccess,
  referMerchantMailFromDraftFailure,

  acceptOrRejectreferredMerchantStart,
  acceptOrRejectreferredMerchantSuccess,
  acceptOrRejectreferredMerchantFailure,

  fetchTerminalListByPanelStart,
  fetchTerminalListByPanelSuccess,
  fetchTerminalListByPanelFailure,

  createMerchantGroupStart,
  createMerchantGroupSuccess,
  createMerchantGroupFailure,

  fetchAllMerchantGroupStart,
  fetchAllMerchantGroupSuccess,
  fetchAllMerchantGroupFailure,

  fetchMerchantGroupByIdStart,
  fetchMerchantGroupByIdSuccess,
  fetchMerchantGroupByIdFailure,

  editPendingMerchantGroupStart,
  editPendingMerchantGroupSuccess,
  editPendingMerchantGroupFailure,

  verifyPendingMerchantGroupStart,
  verifyPendingMerchantGroupSuccess,
  verifyPendingMerchantGroupFailure,

  assignIssuerTransactionCodeStart,
  assignIssuerTransactionCodeSuccess,
  assignIssuerTransactionCodeFailure,

  editMerchantGroupStart,
  editMerchantGroupSuccess,
  editMerchantGroupFailure,

  fetchMerchantStateWiseDataStart,
  fetchMerchantStateWiseDataSuccess,
  fetchMerchantStateWiseDataFailure,

  applyMerchantCouponCodeStart,
  applyMerchantCouponCodeSuccess,
  applyMerchantCouponCodeFailure,

  clearCouponCodeData,

  activateOrDeactivateMerchantStart,
  activateOrDeactivateMerchantFailure,
  activateOrDeactivateMerchantSuccess,

  deleteMerchantStart,
  deleteMerchantSuccess,
  deleteMerchantFailure,

  fetchActivityListReportByPanelStart,
  fetchActivityListReportByPanelSuccess,
  fetchActivityListReportByPanelFailure,

  fetchMerchantMultilingualListByPanelStart,
  fetchMerchantMultilingualListByPanelSuccess,
  fetchMerchantMultilingualListByPanelFailure,

  addMerchantMultilingualStart,
  addMerchantMultilingualSuccess,
  addMerchantMultilingualFailure,

  editMerchantMultilingualStart,
  editMerchantMultilingualSuccess,
  editMerchantMultilingualFailure,

  fetchtMerchantMultilingualByIdStart,
  fetchtMerchantMultilingualByIdSuccess,
  fetchtMerchantMultilingualByIdFailure,

  clearMerchantMultilingualData,

  fetchCharityMultilingualListStart,
  fetchCharityMultilingualListSuccess,
  fetchCharityMultilingualListFailure,

  createCharityMultilingualStart,
  createCharityMultilingualSuccess,
  createCharityMultilingualFailure,

  editCharitytMultilingualStart,
  editCharityMultilingualSuccess,
  editCharityMultilingualFailure,

  clearCharityMultilingualData,

  fetchMerchantCategoryMultilingualListStart,
  fetchMerchantCategoryMultilingualListSuccess,
  fetchMerchantCategoryMultilingualListFailure,

  createMerchantCategoryMultilingualStart,
  createMerchantCategoryMultilingualSuccess,
  createMerchantCategoryMultilingualFailure,

  editMerchantCategoryMultilingualStart,
  editMerchantCategoryMultilingualSuccess,
  editMerchantCategoryMultilingualFailure,

  clearMerchantCategoryMultilingualData,
} = merchantSlice.actions

export default merchantSlice.reducer
