import { takeLatest, call, all, put, select } from 'redux-saga/effects'
import {
  fetchAreaOwnerStart,
  fetchAreaOwnerSuccess,
  fetchAreaOwnerFailure,
  deleteAreaOwnerSuccess,
  deleteAreaOwnerFailure,
  createAreaOwnerFailure,
  createAreaOwnerSuccess,
  fetchAreaOwnerByIdStart,
  fetchAreaOwnerByIdSuccess,
  fetchAreaOwnerByIdFailure,
  editAreaOwnerStart,
  editAreaOwnerSuccess,
  editAreaOwnerFailure,
  fetchPendingAreaOwnerSuccess,
  fetchPendingAreaOwnerFailure,
  deletePendingAreaOwnerSuccess,
  deletePendingAreaOwnerFailure,
  verifyAreaOwnerStart,
  verifyAreaOwnerSuccess,
  verifyAreaOwnerFailure,
  rejectAreaOwnerStart,
  rejectAreaOwnerSuccess,
  rejectAreaOwnerFailure,
  updatePendingAreaOwnerSuccess,
  updatePendingAreaOwnerFailure,
  deleteAreaOwnerContactSuccess,
  deleteAreaOwnerContactFailure,
  fetchAreaOwnerProfileInfoStart,
  fetchAreaOwnerProfileInfoSuccess,
  fetchAreaOwnerProfileInfoFailure,
  uploadAgreementSuccess,
  uploadAgreementFailure,
  editAreaOwnerOwnProfileInfoSuccess,
  editAreaOwnerOwnProfileInfoFailure,
} from './areaOwnerSlice'

import {
  deletePendingAreaOwner,
  fetchPendingAreaOwnerList,
} from '../../api/pendingAreaOwner'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchAreaOwnerList,
  deleteAreaOwner,
  createAreaOwner,
  fetchAreaOwnerById,
  editAreaOwner,
  rejectAreaOwner,
  verifyPendingAreaOwner,
  rejectPendingAreaOwner,
  saveUpdatePendingAreaOwner,
  deleteAreaOwnerContact,
  fetchAreaOwnerProfileInfo,
  createAreaOwnerAgreement,
  editAreaOwnerOwnProfile,
} from '../../api/areaOwner'

import _ from 'lodash'
import { getImageUrl, uploadImage } from 'api/imageUpload'
import { removeParam } from 'utils'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchAreaOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaOwnerList(payload))

    yield put(fetchAreaOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchAreaOwnerProfileInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaOwnerProfileInfo())
    yield put(fetchAreaOwnerProfileInfoSuccess(data))
  } catch (err) {
    yield put(fetchAreaOwnerProfileInfoFailure(err))
  }
}

export function* fetchPendingAreaOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPendingAreaOwnerList(payload))

    yield put(fetchPendingAreaOwnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchPendingAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* createAreaOwnerAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    const { data } = yield call(createAreaOwner(payload?.params))

    yield put(createAreaOwnerSuccess(data?.results))
    yield put(
      openAlert({
        message: 'Area Licensee Succesfully Created',
        severity: 'success',
      })
    )
    yield payload.closeModal()
    yield put(
      fetchAreaOwnerStart({
        page: 1,
        limit: 10,
        offset: 0,
        isPending: false,
        countryId: siteCoordinator?.globalCountry?.id,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* deleteAreaOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteAreaOwner(payload?.id))

    yield put(deleteAreaOwnerSuccess(payload?.id))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Area Licensee Succesfully Rejected',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* fetchAreaOwnerByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaOwnerById(payload))

    yield put(
      fetchAreaOwnerByIdSuccess({
        ...data?.data,
        issuerCode: data?.issuerCode,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchAreaOwnerByIdFailure(err))
    console.error(err)
  }
}

export function* editAreaOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editAreaOwner(payload?.params?.id, payload?.params)
    )

    yield put(editAreaOwnerSuccess(data?.data))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Area Licensee Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* deletePendingAreaOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteAreaOwner(payload?.id))

    yield put(deletePendingAreaOwnerSuccess(payload?.id))
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Area Licensee Request Succesfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deletePendingAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* verifyAreaOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      verifyPendingAreaOwner(payload?.params?.id, payload?.params)
    )

    yield put(verifyAreaOwnerSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchAreaOwnerStart({
          page: 1,
          limit: 10,
          offset: 0,
          isPending: false,
          countryId: payload?.params?.countryId,
        })
      )
    }

    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'Area Licensee Succesfully Verified',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(verifyAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* rejectAreaOwnerAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)

    const { data } = yield call(rejectPendingAreaOwner(payload?.id, payload))

    yield put(rejectAreaOwnerSuccess(payload?.id))
    yield put(
      fetchAreaOwnerStart({
        page: 1,
        limit: 10,
        offset: 0,
        isPending: false,
        countryId: siteCoordinator?.globalCountry?.id,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({
        message: 'Area Licensee Succesfully Rejected',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(rejectAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* updatePendingAreaOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      saveUpdatePendingAreaOwner(payload?.params.id, payload?.params)
    )

    yield put(updatePendingAreaOwnerSuccess(data?.data))
    yield payload.closeModal()

    yield put(
      openAlert({
        message: 'Area Licensee Request Successfully Edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(updatePendingAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* deleteAreaOwnerContactAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteAreaOwnerContact(payload?.id))

    yield put(deleteAreaOwnerContactSuccess(payload?.id))

    yield payload.closeModal()
    yield payload?.arrayHelpers?.remove(payload?.index)
    yield put(
      openAlert({
        message: 'Area Licensee Contact Succesfully Deleted',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(deleteAreaOwnerContactFailure(err))
    console.error(err)
  }
}

export function* uploadAgreementAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.type)) {
      let logoUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params)
        )

        logoUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createAreaOwnerAgreement(payload?.id, {
          agreementUrl: logoUrl,
        })
      )

      yield put(uploadAgreementSuccess())

      yield payload?.closeModal()

      if (payload?.refreshPageData) {
        yield call(payload?.refreshPageData)
      }

      yield put(
        openAlert({
          message: 'Agreement Document Uploaded Successfully',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(uploadAgreementFailure(err))
  }
}

export function* editAreaOwnerOwnProfileInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editAreaOwnerOwnProfile(payload?.params))

    yield put(editAreaOwnerOwnProfileInfoSuccess(data?.data))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
    yield put(
      openAlert({
        message: 'Area Licensee Profile Successfully Edited',
        severity: 'success',
      })
    )
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(editAreaOwnerOwnProfileInfoFailure(err))
    console.error(err)
  }
}

export function* watchFetchAreaOwner() {
  yield takeLatest('areaOwner/fetchAreaOwnerStart', fetchAreaOwnerAsync)
}

export function* watchFetchAreaOwnerProfileInfo() {
  yield takeLatest(
    'areaOwner/fetchAreaOwnerProfileInfoStart',
    fetchAreaOwnerProfileInfoAsync
  )
}

export function* watchPendingAreaOwner() {
  yield takeLatest(
    'areaOwner/fetchPendingAreaOwnerStart',
    fetchPendingAreaOwnerAsync
  )
}

export function* watchCreateAreaOwner() {
  yield takeLatest('areaOwner/createAreaOwnerStart', createAreaOwnerAsync)
}

export function* watchDeleteAreaOwner() {
  yield takeLatest('areaOwner/deleteAreaOwnerStart', deleteAreaOwnerAsync)
}

export function* watchDeletePendingAreaOwner() {
  yield takeLatest(
    'areaOwner/deletePendingAreaOwnerStart',
    deletePendingAreaOwnerAsync
  )
}

export function* watchFetchPendingAreaOwnerById() {
  yield takeLatest('areaOwner/fetchAreaOwnerByIdStart', fetchAreaOwnerByIdAsync)
}

export function* watchEditAreaOwner() {
  yield takeLatest('areaOwner/editAreaOwnerStart', editAreaOwnerAsync)
}

export function* watchVerifyAreaOwner() {
  yield takeLatest('areaOwner/verifyAreaOwnerStart', verifyAreaOwnerAsync)
}

export function* watchRejectAreaOwner() {
  yield takeLatest('areaOwner/rejectAreaOwnerStart', rejectAreaOwnerAsync)
}

export function* watchUpdatePendingAreaOwner() {
  yield takeLatest(
    'areaOwner/updatePendingAreaOwnerStart',
    updatePendingAreaOwnerAsync
  )
}

export function* watchDeleteAreaOwnerContact() {
  yield takeLatest(
    'areaOwner/deleteAreaOwnerContactStart',
    deleteAreaOwnerContactAsync
  )
}

export function* watchUploadAgreementAsync() {
  yield takeLatest('areaOwner/uploadAgreementStart', uploadAgreementAsync)
}

export function* watchEditAreaOwnerOwnProfileInfoAsync() {
  yield takeLatest(
    'areaOwner/editAreaOwnerOwnProfileInfoStart',
    editAreaOwnerOwnProfileInfoAsync
  )
}

export function* areaOwnerSagas() {
  yield all([
    call(watchPendingAreaOwner),
    call(watchDeleteAreaOwner),
    call(watchFetchAreaOwner),
    call(watchFetchAreaOwnerProfileInfo),
    call(watchCreateAreaOwner),
    call(watchDeletePendingAreaOwner),
    call(watchFetchPendingAreaOwnerById),
    call(watchEditAreaOwner),
    call(watchVerifyAreaOwner),
    call(watchRejectAreaOwner),
    call(watchUpdatePendingAreaOwner),
    call(watchDeleteAreaOwnerContact),
    call(watchUploadAgreementAsync),
    call(watchEditAreaOwnerOwnProfileInfoAsync),
    // call(watchEditCountry),
  ])
}
