import { createSlice } from '@reduxjs/toolkit'

import { IreferredCP } from '../interfaces'

import { listObj } from 'utils'

const INITIAL_STATE: IreferredCP = {
  referredCPList: listObj,
  countryAdminReferredCPList: listObj,
  areaAdminReferredCPList: listObj,
  areaAdminReferredCPListByPanel: listObj,
  salesAgentListByLocation: listObj,
  regionAdminReferredCPList: listObj,
  stateAdminReferredCPList: listObj,
  charityAdminReferredCPList: listObj,
  clubAdminReferredCPList: listObj,
  whiteLabelReferredCPList: listObj,
  collaborationPartnerReferredCPList: listObj,
  salesAgentReferredCPList: listObj,
  soleReferredCP: {},
  error: null,
  loading: false,
  isFetching: false,
  isRejecting: false,
  isVerifing: false,
  hasMoreData: false,
}

export const referredCPSlice = createSlice({
  name: 'referredCPList',
  initialState: INITIAL_STATE,
  reducers: {
    fetchReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchReferredCPSuccess: (state: any, action) => {
      state.referredCPList = action.payload
      state.loading = false
    },
    fetchReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchReferredCPByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchReferredCPByIdSuccess: (state: any, action) => {
      state.soleReferredCP = action.payload.data
      state.isFetching = false
    },
    fetchReferredCPByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    rejectReferredCPStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectReferredCPSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.referredCPList.data = state.referredCPList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    rejectReferredCPFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },
    deleteReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    deleteReferredCPSuccess: (state: any, action) => {
      state.loading = false
      state.referredCPList = state.referredCPList.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    editReferredCPSuccess: (state: any, action) => {
      const result = state.referredCPList.results.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.referredCPList = result

      state.loading = false
    },
    editReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    verifyReferredCPStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifyReferredCPSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isVerifing = false
      state.referredCPSlice.data = state.referredCPSlice.data.filter(
        (item) => item.id !== action.payload
      )
    },
    verifyReferredCPFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },

    saveReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    saveReferredCPSuccess: (state: any, action) => {
      state.loading = false
      state.gmcReferredCPList.data = state.gmcReferredCPList.data.filter(
        (item) => item.id == action.payload
      )
    },
    saveReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createCollaborationPartnerReferStart: (state: any, action) => {
      state.loading = true
    },
    createCollaborationPartnerReferSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.referredCPList.data = [...state.referredCPList.data, action.payload]
      state.areaAdminReferredCPList.data = [
        ...state.areaAdminReferredCPList.data,

        action.payload,
      ]
      state.regionAdminReferredCPList.data = [
        ...state.regionAdminReferredCPList.data,

        action.payload,
      ]
      state.stateAdminReferredCPList.data = [
        ...state.stateAdminReferredCPList.data,

        action.payload,
      ]

      state.whiteLabelReferredCPList.data = [
        ...state.whiteLabelReferredCPList.data,

        action.payload,
      ]

      state.charityAdminReferredCPList.data = [
        ...state.charityAdminReferredCPList.data,

        action.payload,
      ]

      state.clubAdminReferredCPList.data = [
        ...state.clubAdminReferredCPList.data,

        action.payload,
      ]
    },
    createCollaborationPartnerReferFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCountryAdminReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchCountryAdminReferredCPSuccess: (state: any, action) => {
      state.countryAdminReferredCPList = action.payload
      state.loading = false
    },
    fetchCountryAdminReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAreaAdminReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchAreaAdminReferredCPSuccess: (state: any, action) => {
      state.areaAdminReferredCPList = action.payload
      state.loading = false
    },
    fetchAreaAdminReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAreaAdminReferredCPByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchAreaAdminReferredCPByPanelSuccess: (state: any, action) => {
      state.areaAdminReferredCPListByPanel = action.payload
      state.loading = false
    },
    fetchAreaAdminReferredCPByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchAllSalesAgentByLocationStart: (state: any, action) => {
      state.loading = true
    },
    fetchAllSalesAgentByLocationSuccess: (state: any, action) => {
      state.salesAgentListByLocation = action.payload
      state.loading = false
    },
    fetchAllSalesAgentByLocationFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchRegionAdminReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchRegionAdminReferredCPSuccess: (state: any, action) => {
      state.regionAdminReferredCPList = action.payload
      state.loading = false
    },
    fetchRegionAdminReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchStateAdminReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchStateAdminReferredCPSuccess: (state: any, action) => {
      state.stateAdminReferredCPList = action.payload
      state.loading = false
    },
    fetchStateAdminReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCharityAdminReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchCharityAdminReferredCPSuccess: (state: any, action) => {
      state.charityAdminReferredCPList = action.payload
      state.loading = false
    },
    fetchCharityAdminReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchClubAdminReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchClubAdminReferredCPSuccess: (state: any, action) => {
      state.clubAdminReferredCPList = action.payload
      state.loading = false
    },
    fetchClubAdminReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchWhiteLabelAdminReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchWhiteLabelAdminReferredCPSuccess: (state: any, action) => {
      state.whiteLabelReferredCPList = action.payload
      state.loading = false
    },
    fetchWhiteLabelAdminReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCPAdminReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchCPAdminReferredCPSuccess: (state: any, action) => {
      state.collaborationPartnerReferredCPList = action.payload
      state.loading = false
    },
    fetchCPAdminReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchSalesAgentAdminReferredCPStart: (state: any, action) => {
      state.loading = true
    },
    fetchSalesAgentAdminReferredCPSuccess: (state: any, action) => {
      state.salesAgentReferredCPList = action.payload
      state.loading = false
    },
    fetchSalesAgentAdminReferredCPFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchReferredCPStart,
  fetchReferredCPSuccess,
  fetchReferredCPFailure,
  rejectReferredCPStart,
  rejectReferredCPSuccess,
  rejectReferredCPFailure,
  deleteReferredCPStart,
  deleteReferredCPSuccess,
  deleteReferredCPFailure,
  fetchReferredCPByIdStart,
  fetchReferredCPByIdSuccess,
  fetchReferredCPByIdFailure,
  editReferredCPStart,
  editReferredCPSuccess,
  editReferredCPFailure,
  verifyReferredCPStart,
  verifyReferredCPSuccess,
  verifyReferredCPFailure,
  saveReferredCPStart,
  saveReferredCPSuccess,
  saveReferredCPFailure,
  createCollaborationPartnerReferStart,
  createCollaborationPartnerReferSuccess,
  createCollaborationPartnerReferFailure,
  fetchCountryAdminReferredCPStart,
  fetchCountryAdminReferredCPSuccess,
  fetchCountryAdminReferredCPFailure,
  fetchAreaAdminReferredCPStart,
  fetchAreaAdminReferredCPSuccess,
  fetchAreaAdminReferredCPFailure,
  fetchRegionAdminReferredCPStart,
  fetchRegionAdminReferredCPSuccess,
  fetchRegionAdminReferredCPFailure,
  fetchStateAdminReferredCPStart,
  fetchStateAdminReferredCPSuccess,
  fetchStateAdminReferredCPFailure,
  fetchCharityAdminReferredCPStart,
  fetchCharityAdminReferredCPSuccess,
  fetchCharityAdminReferredCPFailure,
  fetchClubAdminReferredCPStart,
  fetchClubAdminReferredCPSuccess,
  fetchClubAdminReferredCPFailure,
  fetchWhiteLabelAdminReferredCPStart,
  fetchWhiteLabelAdminReferredCPSuccess,
  fetchWhiteLabelAdminReferredCPFailure,
  fetchCPAdminReferredCPStart,
  fetchCPAdminReferredCPSuccess,
  fetchCPAdminReferredCPFailure,
  fetchSalesAgentAdminReferredCPStart,
  fetchSalesAgentAdminReferredCPSuccess,
  fetchSalesAgentAdminReferredCPFailure,

  fetchAreaAdminReferredCPByPanelStart,
  fetchAreaAdminReferredCPByPanelSuccess,
  fetchAreaAdminReferredCPByPanelFailure,

  fetchAllSalesAgentByLocationStart,
  fetchAllSalesAgentByLocationSuccess,
  fetchAllSalesAgentByLocationFailure,
} = referredCPSlice.actions

export default referredCPSlice.reducer
