import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Edit, Delete, Visibility } from '@mui/icons-material'
import {
  Tooltip,
  IconButton,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import RejectReasonViewer from 'components/RejectReasonViewer'
import moment from 'moment-timezone'
import { camelCaseToSpace, splitByCamelCase } from 'utils'
import MerchantUpgrade from 'components/MerchantUpgrade'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import TransferModal from '../Transfer'
import ReferredMerchantDetails from '../ReferredMerchantDetails'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CloseIcon from '@mui/icons-material/Close'
import ConfirmDialogWithReason from 'components/ReusableModalComponents/ConfirmDialogWithReason'
import { useNavigate } from 'react-router-dom'
import EditReferredMerchantInfo from '../EditReferredMerchantInfo'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  lineHeight: 1.43,
  paddingRight: 20,
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}))

interface TableItemProps {
  data: any
  count: number
  pageNumber?: number
  rowsPerPage?: number
  adminRoute?: any
  currentUser?: any
  onDeleteClubStart?: (payload: any) => void
  refreshPageData?: () => void
  onRejectReferredMerchantStart?: (payload: any) => void
  onApproveReferredMerchantStart?: (payload: any) => void
  isRejecting?: boolean
  isApproving?: boolean
}

const TableItem: React.FC<TableItemProps> = ({
  data,
  count,
  pageNumber,
  rowsPerPage,
  onDeleteClubStart,
  adminRoute,
  refreshPageData,
  currentUser,
  onRejectReferredMerchantStart,
  onApproveReferredMerchantStart,
  isRejecting,
  isApproving,
}) => {
  const navigate = useNavigate()
  const [isAddCountryOpen, setAddCountryOpen] = useState<boolean>(false)
  const [isMerchantDetailOpen, setIsMerchantDetailOpen] =
    useState<boolean>(false)
  const [isTransferModalOpen, setIsTransferModalOpen] = useState<boolean>(false)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false)

  const [selectedItem, setSelectedItem] = useState<any>(null)

  const [openReasonModal, setOpenReasonModal] = useState<boolean>(false)

  const [openUpgradeModal, setOpenUpgradeModal] = useState<boolean>(false)

  const [openEditModal, setOpenEditModal] = useState<boolean>(false)

  const [rejectReason, setRejectReason] = useState<string>('')

  const onCloseAddCountry = () => {
    setAddCountryOpen(false)
  }

  const reasonModalOpen = (data) => {
    setRejectReason(data)
    setOpenReasonModal(true)
  }

  const onCloseApproveModal = () => {
    setIsApproveModalOpen(false)
  }

  const onCloseRejectModal = () => {
    setIsRejectModalOpen(false)
  }

  const closeUpgradeModal = () => {
    setOpenUpgradeModal(false)
  }

  const onDeleteUpgradeModal = () => {
    setOpenUpgradeModal(false)
  }

  const checkStatus = (data) => {
    if (data?.isApproved) {
      return 'Approved'
    } else if (data?.isRejected) {
      return 'Rejected'
    } else if (data?.isTransferred) {
      return 'Transferred'
    } else if (data?.isApproved && data?.isTransferred) {
      return 'Approved'
    } else if (data?.isRejected && data?.isTransferred) {
      return 'Rejected'
    } else if (data?.isApproved && data?.isRejected && data?.isTransferred) {
      return 'Pending'
    } else {
      return '-'
    }
  }

  const checkStatusReferralStatus = (data) => {
    if (data?.isApproved && !data?.isRejected && !data?.isVerified) {
      return 'Referral Sent'
    } else if (!data?.isApproved && data?.isRejected && !data?.isVerified) {
      return 'Rejected'
    } else if (!data?.isApproved && !data?.isRejected && !data?.isVerified) {
      return 'Pending'
    } else if (data?.isApproved && !data?.isRejected && data?.isVerified) {
      return 'Verified'
    } else {
      return '-'
    }
  }

  const checkMerchantStatus = (data) => {
    if (!data?.isApproved && !data?.isRejected && !data?.isVerified) {
      return 'Referral Pending'
    } else if (data?.isVerified && data?.isApproved && !data?.isRejected) {
      return 'Merchant Approved'
    } else if (data?.isApproved && !data?.isRejected && !data?.isVerified) {
      return 'Merchant Pending'
    } else {
      return '-'
    }
  }

  const checkApproveIconVisibility = (data) => {
    if (!data?.isTransferred) {
      if (!data?.isApproved && !data?.isRejected) {
        return (
          <Tooltip placement="top" title="Approve">
            <IconButton
              onClick={() => {
                setSelectedItem(data)
                setIsApproveModalOpen(true)
              }}
            >
              <CheckBoxIcon sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>
        )
      } else {
        return ''
      }
    } else {
      if (
        !data?.isApproved &&
        !data?.isRejected &&
        data?.transferRequestStatus === 'rejected'
      ) {
        return (
          <Tooltip placement="top" title="Approve">
            <IconButton
              onClick={() => {
                setSelectedItem(data)
                setIsApproveModalOpen(true)
              }}
            >
              <CheckBoxIcon sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>
        )
      }
    }
  }

  const checkRejectIconVisibility = (data) => {
    if (!data?.isTransferred) {
      if (!data?.isApproved && !data?.isRejected) {
        return (
          <Tooltip placement="top" title="Reject">
            <IconButton
              onClick={() => {
                setSelectedItem(data)
                setIsRejectModalOpen(true)
              }}
            >
              <CloseIcon sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>
        )
      } else {
        return ''
      }
    } else {
      if (
        !data?.isApproved &&
        !data?.isRejected &&
        data?.transferRequestStatus === 'rejected'
      ) {
        return (
          <Tooltip placement="top" title="Reject">
            <IconButton
              onClick={() => {
                setSelectedItem(data)
                setIsRejectModalOpen(true)
              }}
            >
              <CloseIcon sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>
        )
      }
    }
  }

  const checkTransferIconVisibility = (data) => {
    if (!data?.isApproved) {
      if (!data?.isRejected) {
        if (data?.isTransferred && data?.transferRequestStatus === 'rejected') {
          return (
            <Tooltip placement="top" title="Transfer">
              <IconButton
                onClick={() => {
                  setSelectedItem(data)
                  setIsTransferModalOpen(true)
                }}
              >
                <DriveFileMoveIcon sx={{ fontSize: '1.3rem' }} />
              </IconButton>
            </Tooltip>
          )
        } else if (!data?.isTransferred) {
          return (
            <Tooltip placement="top" title="Transfer">
              <IconButton
                onClick={() => {
                  setSelectedItem(data)
                  setIsTransferModalOpen(true)
                }}
              >
                <DriveFileMoveIcon sx={{ fontSize: '1.3rem' }} />
              </IconButton>
            </Tooltip>
          )
        }
      }
    }
  }

  return (
    <>
      {isTransferModalOpen && (
        <TransferModal
          isAddPageOpen={isTransferModalOpen}
          onCloseAddPage={() => setIsTransferModalOpen(false)}
          batchId={parseInt(selectedItem?.id)}
          selectedMerchant={selectedItem}
          refreshPageData={refreshPageData}
        />
      )}

      {isRejectModalOpen && (
        <ConfirmDialogWithReason
          open={isRejectModalOpen}
          onDeny={onCloseRejectModal}
          onConfirm={onCloseRejectModal}
          toDeleteId={selectedItem?.id}
          onCloseEditDialog={onCloseRejectModal}
          deleteFunction={onRejectReferredMerchantStart}
          refreshPageData={refreshPageData}
          title="Are you sure you want to reject this merchant?"
          dialogTitle="Reject Merchant"
          isDeleting={isRejecting}
        />
      )}

      {openEditModal && (
        <EditReferredMerchantInfo
          isAddCountryOpen={openEditModal}
          onCloseAddCountry={() => setOpenEditModal(false)}
          selectedMerchant={selectedItem}
          refreshPageData={refreshPageData}
        />
      )}

      {openReasonModal && (
        <RejectReasonViewer
          open={openReasonModal}
          onDeny={setOpenReasonModal}
          defaultValue={rejectReason}
          title="Rejection Reason"
          dialogTitle="Rejection Details"
        />
      )}

      {isApproveModalOpen && (
        <ModalRenderer
          open={isApproveModalOpen}
          onDeny={onCloseApproveModal}
          onConfirm={onCloseApproveModal}
          title=""
          dialogTitle="Are you sure want to approve?"
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">
                Approving this merchant will register this merchant details in
                to the system and will redirect you to the draft form .You can
                then complete the registration of Merchant from there .Do you
                want to continue?
                <br />
                <br />
                If you get any errors, first click edit button(pencil icon) in
                the list and fill all the information before approving this
                merchant.
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              spacing={3}
              sx={{ mt: 4 }}
              justifyContent={'right'}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  type="submit"
                  color="primary"
                  autoFocus
                  disabled={isApproving}
                  onClick={() =>
                    onApproveReferredMerchantStart({
                      params: { id: selectedItem?.id, ...selectedItem },
                      closeModal: onCloseApproveModal,
                      refreshPageData,
                      route: `/country-licensee/draftList/editMerchant/`,
                      navigate,
                    })
                  }
                >
                  {isApproving ? <CircularProgress size={20} /> : 'Yes'}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={onCloseApproveModal}
                >
                  No
                </Button>{' '}
              </Grid>
            </Grid>
          </Grid>
        </ModalRenderer>
      )}

      {isMerchantDetailOpen && (
        <ReferredMerchantDetails
          isAddCountryOpen={isMerchantDetailOpen}
          onCloseAddCountry={() => setIsMerchantDetailOpen(false)}
          selectedMerchant={selectedItem}
          refreshPageData={refreshPageData}
        />
      )}
      <TableRow key={data.sn} className="item-hover">
        <StyledTableCell
          align="left"
          sx={{ position: 'sticky', left: 0, zIndex: 1, background: '#fff' }}
        >
          {count + (pageNumber - 1) * rowsPerPage}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{ position: 'sticky', left: 50, zIndex: 1, background: '#fff' }}
        >
          {data?.merchantName ?? '-'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.merchantEmail ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.__state__ ? data?.__state__?.stateName : '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.merchantPhoneNumber ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.contactPersonFirstName
            ? `${data?.contactPersonFirstName} ${data?.contactPersonLastName}`
            : '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.phoneNumber ?? '-'}
        </StyledTableCell>
        <StyledTableCell align="left">{data?.email ?? '-'}</StyledTableCell>
        <StyledTableCell align="left">{data?.memberId ?? '-'}</StyledTableCell>
        <StyledTableCell align="left">
          {checkStatusReferralStatus(data)} <br />
          {data?.isRejected ? (
            <Button
              variant="text"
              sx={{ padding: 0 }}
              onClick={() => reasonModalOpen(data?.rejectReason)}
            >
              view reason
            </Button>
          ) : (
            ''
          )}
        </StyledTableCell>

        <StyledTableCell align="left">
          {checkMerchantStatus(data)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.isTransferred ? 'Yes' : 'No'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.transferredToType
            ? camelCaseToSpace(data?.transferredToType)
            : '-'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data?.transferredToId ?? '-'}
        </StyledTableCell>

        <StyledTableCell align="left">
          {data?.transferRequestStatus
            ? camelCaseToSpace(data?.transferRequestStatus)
            : '-'}
          <br />

          {data?.transferRequestStatus === 'rejected' ? (
            <Button
              variant="text"
              sx={{ padding: 0 }}
              onClick={() => reasonModalOpen(data?.transferRequestRejectReason)}
            >
              view reason
            </Button>
          ) : (
            ''
          )}
        </StyledTableCell>

        <StyledTableCell align="left">
          {checkApproveIconVisibility(data)}

          {checkRejectIconVisibility(data)}

          {/* {(!data?.isApproved && !data?.isRejected && !data?.isTransferred) ||
          data?.transferRequestStatus === 'rejected' ? (
            <Tooltip placement="top" title="Reject">
              <IconButton
                onClick={() => {
                  setSelectedItem(data)
                  setIsRejectModalOpen(true)
                }}
              >
                <CloseIcon sx={{ fontSize: '1.3rem' }} />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )} */}

          <Tooltip placement="top" title="View">
            <IconButton
              onClick={() => {
                setSelectedItem(data)
                setIsMerchantDetailOpen(true)
              }}
            >
              <Visibility sx={{ fontSize: '1.3rem' }} />
            </IconButton>
          </Tooltip>
          {/* {(!data?.isApproved && !data?.isRejected && !data?.isTransferred) ||
          data?.transferRequestStatus === 'rejected' ? (
            <Tooltip placement="top" title="Transfer">
              <IconButton
                onClick={() => {
                  setSelectedItem(data)
                  setIsTransferModalOpen(true)
                }}
              >
                <DriveFileMoveIcon sx={{ fontSize: '1.3rem' }} />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )} */}
          {checkTransferIconVisibility(data)}

          {/* {data?.isApproved ||
          data?.isRejected ||
          (data?.isTransferred &&
            data?.transferRequestStatus !== 'rejected') ? (
            ''
          ) : (
            <Tooltip placement="top" title="Transfer">
              <IconButton
                onClick={() => {
                  setSelectedItem(data)
                  setIsTransferModalOpen(true)
                }}
              >
                <DriveFileMoveIcon sx={{ fontSize: '1.3rem' }} />
              </IconButton>
            </Tooltip>
          )} */}

          {data?.transferRequestStatus?.toLowerCase() === 'requestsent' ||
          data?.transferRequestStatus?.toLowerCase() === 'accepted' ? (
            ''
          ) : (
            <>
              {!data?.isApproved && !data?.isRejected && (
                <Tooltip placement="top" title="Edit">
                  <IconButton
                    onClick={() => {
                      setSelectedItem(data)
                      setOpenEditModal(true)
                    }}
                  >
                    <Edit sx={{ fontSize: '1.3rem' }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </StyledTableCell>
      </TableRow>

      {openUpgradeModal && (
        <ModalRenderer
          open={openUpgradeModal}
          onDeny={closeUpgradeModal}
          onConfirm={onDeleteUpgradeModal}
          title=""
          dialogTitle="Upgrade Merchant Package"
          size="xl"
        >
          <MerchantUpgrade
            merchantData={data}
            closeUpgradeModal={closeUpgradeModal}
            refreshPageData={refreshPageData}
          />
        </ModalRenderer>
      )}
    </>
  )
}

export default TableItem
