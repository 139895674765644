import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchMembersList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/member/general/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMembers = (params) => {
  return () =>
    axiosRequest
      .post(`/api/member/general/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMemberByPanel = (params) => {
  return () =>
    axiosRequest
      .post(`/api/member/general/createByPanel`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembersByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/member/general/getAllIssuedByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMemberByMerchantId = (id, params) => {
  return () =>
    axiosRequest
      .get(`/api/member/general/getByIssuerId/${id}`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllMemberByOwners = (params) => {
  return () =>
    axiosRequest
      .get(`/api/member/general/getAllMemberByOwners`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllMemberByWhiteLabel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/member/general/getByWhiteLabel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMembersById = (params) => {
  return () =>
    axiosRequest
      .get(`/api/member/general/getOne`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMember = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/member/general/edit`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
