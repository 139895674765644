// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchRegionOwnerList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/regionOwner/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createRegionOwner = (params) => {
  return () =>
    axiosRequest
      .post(`/api/regionOwner/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchRegionOwnerById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/regionOwner/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectRegionOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/regionOwner/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editRegionOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/regionOwner/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectPendingRegionOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/regionOwner/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteRegionOwner = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/regionOwner/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyPendingRegionOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/regionOwner/updateAndVerifyEOI/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveUpdatePendingRegionOwner = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/regionOwner/saveUpdate/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteRegionOwnerContact = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/regionOwner/deleteRegionOwnerContact/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchRegionOwnerProfileInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/regionOwner/getOwnProfile`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createRegionOwnerAgreement = (id, params) => {
  return () =>
    axiosRequest
      .patch(`/api/regionOwner/saveAgreement/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editRegionOwnerOwnProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/regionOwner/editOwnProfile/`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
