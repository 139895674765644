import { axiosRequest } from 'utils'

export const fetchCountryList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/country/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryListForSetup = (params) => {
  return () =>
    axiosRequest
      .get(`/api/country/getAllCountryForSetup`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/country/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryActivateStatusById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/country/activateStatus/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCountry = (params) => {
  return () =>
    axiosRequest
      .post(`/api/country/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const activateCountry = (id, params) => {
  return () =>
    axiosRequest
      .patch(`/api/country/activate/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCountry = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/country/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCountry = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/country/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCountryStripeKey = (params) => {
  return () =>
    axiosRequest
      .get(`/api/country/stripeKey/${params}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCurrency = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/countryCurrencySymbol/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllCountryCurrencySymbol = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/countryCurrencySymbol/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCurrencyById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/countryCurrencySymbol/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCurrency = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/countryCurrencySymbol/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCurrency = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/countryCurrencySymbol/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
