import { axiosRequestCMS } from 'utils'

export const fetchContentSectionList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/sectionContent/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchContentSectionById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/sectionContent/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createContentSection = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/sectionContent/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteContentSection = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/sectionContent/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editContentSection = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/sectionContent/edit/${id}?isTranslateToAlllang=${params.isTranslateToAlllang}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
