import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchAreaStart,
  fetchAreaSuccess,
  fetchAreaFailure,
  createAreaSuccess,
  createAreaFailure,
  deleteAreaSuccess,
  deleteAreaFailure,
  fetchAreaByIdSuccess,
  fetchAreaByIdFailure,
  editAreaSuccess,
  editAreaFailure,
} from './areaSetupSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchAreaList,
  createArea,
  deleteArea,
  fetchAreaById,
  editArea,
} from '../../api/area'

// import { fetchCountryList } from '../../api/country'
// import {fetchStateList} from '../../api/state'

import _ from 'lodash'

export function* fetchAreaAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaList(payload))

    yield put(fetchAreaSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAreaFailure(err))
  }
}

export function* fetchAreaByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaById(payload))

    yield put(fetchAreaByIdSuccess(data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAreaByIdFailure(err))
  }
}

export function* editAreaAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editArea(payload.params.id, payload.params))

    yield put(editAreaSuccess(data?.data))
    yield put(
      fetchAreaStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({ message: 'Area successfully edited', severity: 'success' })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editAreaFailure(err))
  }
}

export function* createAreaAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createArea(payload.params))

    yield put(createAreaSuccess(data?.data))
    yield put(
      fetchAreaStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )
    yield payload.closeModal()
    yield put(
      openAlert({ message: 'Area successfully created', severity: 'success' })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(createAreaFailure(err))
  }
}

export function* deleteAreaAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteArea(payload.id))

    if (data) {
      yield put(deleteAreaSuccess(payload))
      yield put(
        fetchAreaStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Area deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(deleteAreaFailure(err))
  }
}

export function* watchFetchArea() {
  yield takeLatest('areaSetup/fetchAreaStart', fetchAreaAsync)
}

export function* watchCreateArea() {
  yield takeLatest('areaSetup/createAreaStart', createAreaAsync)
}

export function* watchDeleteArea() {
  yield takeLatest('areaSetup/deleteAreaStart', deleteAreaAsync)
}

export function* watchFetchAreaById() {
  yield takeLatest('areaSetup/fetchAreaByIdStart', fetchAreaByIdAsync)
}

export function* watchEditArea() {
  yield takeLatest('areaSetup/editAreaStart', editAreaAsync)
}

export function* areaSetupSagas() {
  yield all([
    call(watchFetchArea),
    call(watchCreateArea),
    call(watchDeleteArea),
    call(watchFetchAreaById),
    call(watchEditArea),
  ])
}
