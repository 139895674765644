import { axiosRequestCMS } from 'utils'

export const fetchTeamList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/ourTeam/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTeamById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/ourTeam/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createTeam = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/ourTeam/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteTeam = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/ourTeam/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editTeam = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/ourTeam/edit/${id}?isTranslateToAlllang=${params.isTranslateToAlllang}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
