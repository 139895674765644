import React, { useEffect } from 'react'
import { TextField, Autocomplete } from '@mui/material'
import { FieldProps, getIn } from 'formik'
import { usePrevious } from 'utils'

import container from './RegionSelect.container'
import _ from 'lodash'

interface AddNewCountryProps {
  onFetchRegionStart: (payload: any) => void
  dropDown: any
  setSelectedCountry: any
  selectedRegion?: any
  defaultSelected?: any
  filterByCountryId?: any
  onClearRegionDropDown?: () => void
  defaultValue?: number
  isAssignedToOwner?: boolean
  makeDefaultEmpty?: boolean
  isModalOpen?: boolean
  includeFilterByCountry?: boolean
  label?: string
}

const RegionSelect: React.FC<FieldProps & AddNewCountryProps> = ({
  field,
  form,
  onFetchRegionStart,
  setSelectedCountry,
  selectedRegion,
  dropDown: { regionList },
  defaultValue,
  defaultSelected,
  isAssignedToOwner,
  makeDefaultEmpty,
  filterByCountryId,
  onClearRegionDropDown,
  label,
  includeFilterByCountry,
  isModalOpen,
  ...props
}) => {
  const useHasChanged = (val: any) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }

  const stateHasChanged = useHasChanged(form?.values?.stateId?.id)

  useEffect(() => {
    if (makeDefaultEmpty && !_.isEmpty(defaultSelected)) {
      onClearRegionDropDown()
      if (isAssignedToOwner === false) {
        onFetchRegionStart({
          limit: 100,
          stateId: defaultSelected?.id,
          isAssignedToOwner: false,
        })
      } else {
        if (includeFilterByCountry) {
          onFetchRegionStart({ limit: 100, countryId: filterByCountryId?.id })
        } else {
          onFetchRegionStart({ limit: 100, stateId: defaultSelected?.id })
        }
      }
    } else {
      onClearRegionDropDown()
    }
  }, [
    defaultSelected,
    makeDefaultEmpty,
    filterByCountryId,
    includeFilterByCountry,
  ])

  useEffect(() => {
    if (!makeDefaultEmpty && isModalOpen == false) {
      if (!_.isEmpty(filterByCountryId)) {
        onFetchRegionStart({ limit: 100, countryId: filterByCountryId?.id })
      } else {
        onFetchRegionStart({ limit: 100 })
      }
    }
  }, [makeDefaultEmpty, filterByCountryId, isModalOpen])

  useEffect(() => {
    const { initialValues, values } = form
    if (initialValues?.stateId) {
      if (
        initialValues?.stateId?.id != values?.stateId?.id &&
        stateHasChanged
      ) {
        form.setFieldValue(field.name, null)
      }
    } else {
      if (stateHasChanged) {
        form.setFieldValue(field.name, null)
      }
    }
  }, [form?.values])

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <Autocomplete
      {...props}
      {...field}
      // disablePortal
      id="combo-box-demo"
      size="small"
      value={field.value}
      getOptionLabel={(option) => (option ? option.label : '')}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, value) => {
        form.setFieldValue(field.name, value)
      }}
      // onInputChange={(event, newInputValue) => {
      //   console.log('onInputChange>>>', newInputValue)
      // }}
      defaultValue={defaultValue ? defaultValue : null}
      options={regionList}
      // defaultValue={defaultCountry ? defaultCountry : {}}
      // isOptionEqualToValue={defaultCountry ? defaultCountry : null}
      // value={selectedRegion ? selectedRegion : null}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={errorText?.value || errorText}
          error={!!errorText}
          label={label ? label : 'Select Region'}
        />
      )}
    />
  )
}

export default container(RegionSelect)
