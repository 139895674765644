import React, { useEffect } from 'react'
import { TextField, Autocomplete } from '@mui/material'
import { FieldProps, getIn } from 'formik'

import container from './LanguageSelect.container'
import _ from 'lodash'

interface AddNewCountryProps {
  dropDown: any

  defaultSelected?: any
  filterByCountryId?: any
  changeAgreedPercent?: () => void

  onFetchLanguageStart?: (params) => void
  onFetchLanguageForOtherPanelsStart?: (params) => void
  defaultValue?: number
  isAssignedToOwner?: boolean
  makeDefaultEmpty?: boolean
  label?: string
  setAgeedPercentage?: boolean
  forOtherAdminPanels?: boolean
  countryId?: any
}

const WhiteLabelSelect: React.FC<FieldProps & AddNewCountryProps> = ({
  field,
  form,
  onFetchLanguageStart,
  onFetchLanguageForOtherPanelsStart,
  dropDown: { languageList },
  defaultValue,
  defaultSelected,
  isAssignedToOwner,
  makeDefaultEmpty,
  filterByCountryId,
  setAgeedPercentage,
  changeAgreedPercent,
  label,
  countryId,
  forOtherAdminPanels,
  ...props
}) => {
  useEffect(() => {
    if (forOtherAdminPanels) {
      onFetchLanguageForOtherPanelsStart({})
    } else {
      onFetchLanguageStart({})
    }
  }, [])

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <Autocomplete
      {...props}
      {...field}
      disablePortal
      id="combo-box-demo"
      size="small"
      value={field.value}
      getOptionLabel={(option) => (option ? option?.countryLanguage : '')}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, value) => {
        form.setFieldValue(field.name, value)
      }}
      defaultValue={defaultValue ? defaultValue : null}
      options={languageList?.length ? languageList : []}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={errorText?.value || errorText}
          error={!!errorText}
          label={label ? label : 'Select Lanaguge'}
        />
      )}
    />
  )
}

export default container(WhiteLabelSelect)
