import React, { useEffect, useState } from 'react'
import UploadModern from '../UploadModern'
import { useDropzone } from 'react-dropzone'
import PreviewThumb from '../PreviewThumb/index'
import AppGrid from '@crema/core/AppGrid'

const Previews = ({ uploadedFiles, setUploadedFiles }) => {
  const [pdfPreview, setPdfPreview] = useState<string>('')
  const dropzone = useDropzone({
    accept: 'image/*, video/*, .pdf, .csv',
    onDrop: (acceptedFiles) => {
      setUploadedFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
      setPdfPreview(URL.createObjectURL(acceptedFiles[0]))
    },
  })
  useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles)
  }, [dropzone.acceptedFiles])

  const onDeleteUploadFile = (file: any) => {
    dropzone.acceptedFiles.splice(dropzone.acceptedFiles.indexOf(file), 1)
    setUploadedFiles([...dropzone.acceptedFiles])
    setPdfPreview('')
  }

  return (
    <section className="container" style={{ cursor: 'pointer' }}>
      <UploadModern
        uploadText="Drag n drop some files here, or click to select files"
        dropzone={dropzone}
      />

      {/* {pdfPreview && (
        <div style={{ width: '100%', height: '500px' }}>
          <iframe
            src={pdfPreview}
            title="PDF Preview"
            style={{ width: '100%', height: '100%', border: 'none' }}
          ></iframe>
        </div>
      )} */}
      <AppGrid
        sx={{
          maxWidth: 200,
        }}
        data={uploadedFiles}
        column={4}
        itemPadding={5}
        renderRow={(file, index) => (
          <PreviewThumb
            file={file}
            onDeleteUploadFile={onDeleteUploadFile}
            key={index + file.path}
          />
        )}
      />
    </section>
  )
}

export default Previews
