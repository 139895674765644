/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { sendClubReqStart } from 'store/club/clubSlice'
import { openAlert } from 'store/alert/alertSlice'
import {
  createStep3DraftStart,
  createStep3GeneralStart,
  fetchMerchantDraftImagePanelUserStart,
  clearMerchantPremiumCode,
} from 'store/merchant/merchantSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
  merchant: state.merchant,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  onCreateStep3DraftStart: (payload) =>
    dispatch(createStep3DraftStart(payload)),
  onCreateStep3GeneralStart: (payload) =>
    dispatch(createStep3GeneralStart(payload)),
  onOpenAlert: (payload) => dispatch(openAlert(payload)),
  onFetchMerchantDraftImagePanelUserStart: (payload) =>
    dispatch(fetchMerchantDraftImagePanelUserStart(payload)),
  onClearMerchantPremiumCode: () => dispatch(clearMerchantPremiumCode()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
