// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

// export const fetchCharityList = (params) => {
//   return () =>
//     axiosRequest
//       .get(`/api/charity/getAll`, { params })
//       .then((res) => {
//         console.log('fetch Area OwnerList >>>', res)
//         return Promise.resolve(res)
//       })
//       .catch((err) => {
//         return Promise.reject(err)
//       })
// }

export const fetchCharityList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/charity/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCharityListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/charity/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCharityListForPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/charity/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createCharity = (params) => {
  return () =>
    axiosRequest
      .post(`/api/charity/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCharityById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/charity/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectCharity = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/charity/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCharity = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/charity/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyCharity = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/charity/updateAndVerifyEOI/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveUpdatePendingCharity = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/charity/saveUpdate/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteCharity = (id, params) => {
  return () =>
    axiosRequest
      .delete(`/api/charity/delete/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const sendCharityReq = (params) => {
  return () =>
    axiosRequest
      .post(`/api/charity/suggest/`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchCharityProfileInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/charity/getOwnProfile`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllCharity = (params) => {
  return () =>
    axiosRequest
      .get(`/api/charity/getAllCharity`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllCharityByLocation = (params) => {
  return () =>
    axiosRequest
      .get(`/api/charity/getAllByLocation`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getCharityReferralToken = () => {
  return () =>
    axiosRequest
      .get(`/api/charity/referralToken`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editOwnCharityProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/charity/editOwnerProfile`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
