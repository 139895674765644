import { axiosRequest } from 'utils'

export const fetchMembershipFeeList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/membershipBeneficiary/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchMembershipFeeById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/globalSetting/membershipBeneficiary/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMembershipFee = (params) => {
  return () =>
    axiosRequest
      .post(`/api/globalSetting/membershipBeneficiary/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteMembershipFee = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/globalSetting/membershipBeneficiary/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMembershipFee = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/globalSetting/membershipBeneficiary/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
