import React, { useEffect } from 'react'
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material'

import AppDialog from '@crema/core/AppDialog'
import container from './EditMerchantInfo.container'

import * as yup from 'yup'
import { Form, Formik } from 'formik'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'

interface AddNewCountryProps {
  isAddCountryOpen: boolean
  merchant?: any
  onOpenCountryTask?: () => void
  refreshPageData?: () => void
  onCloseAddCountry: () => void
  clearGeneralData?: () => void
  onCreateClubStart: (params) => void
  onFetchMerchantBasicInfoPanelUserStart?: (params) => void
  onEditMerchantInfoStart?: (params) => void

  siteCoordinator: any
  merchantId?: number
  club: any
  auth: any
  adminRoute?: any
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function handleClick(event) {
  event.preventDefault()
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const AddNewClub: React.FC<AddNewCountryProps> = ({
  isAddCountryOpen,
  onCloseAddCountry,
  onFetchMerchantBasicInfoPanelUserStart,
  onEditMerchantInfoStart,
  refreshPageData,
  merchantId,
  merchant: { generalBasicInfo, isEditing },
}) => {
  useEffect(() => {
    onFetchMerchantBasicInfoPanelUserStart(merchantId)
  }, [merchantId])

  const [value, setValue] = React.useState(0)

  const [multiplePersonApply, setMultiplePersonApply] = React.useState(false)

  useEffect(() => {
    if (multiplePersonApply) {
      setMultiplePersonApply(false)
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const closeModal = () => {
    onCloseAddCountry()
  }
  const validationSchema = yup.object({})

  return (
    <AppDialog
      dividers
      maxWidth="lg"
      open={isAddCountryOpen}
      onClose={() => closeModal()}
      title="Merchant Edit"
    >
      <Box sx={{ width: '100%', p: 1 }}>
        <Formik
          validateOnChange={true}
          enableReinitialize
          initialValues={{
            email: generalBasicInfo?.email ?? '',
            phoneNumber: generalBasicInfo?.phoneNumber ?? '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data: any, { setSubmitting }) => {
            setSubmitting(true)

            onEditMerchantInfoStart({
              params: {
                ...data,
                id: merchantId,
                email: data?.email,
                phoneNumber: data?.phoneNumber,
              },
              closeModal,
              refreshPageData,
            })

            setSubmitting(false)
          }}
          render={() => {
            return (
              <Form>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Contact</Typography>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                      <AppTextField
                        placeholder={'Email'}
                        label={'Email'}
                        name="email"
                        variant="outlined"
                        required
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                      />
                    </Grid>

                    <Grid item container direction="row" xs={12} lg={3}>
                      <AppTextField
                        placeholder={'Phone Number'}
                        label={'Phone Number'}
                        type="number"
                        name="phoneNumber"
                        required
                        variant="outlined"
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                      />
                    </Grid>
                  </Grid>

                  <Box sx={{ height: 20 }} />
                  <Box sx={{ height: 30 }} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      sx={{
                        color: '#ffffff',
                        display: 'flex',
                        background: '#00bfcd',
                        '&:hover': {
                          background: '#ec4785',
                        },
                        px: 5,
                      }}
                      disabled={isEditing}
                      type="submit"
                    >
                      {isEditing ? <CircularProgress size={20} /> : 'Save'}
                    </Button>
                    &nbsp; &nbsp;
                    <Button variant="outlined" onClick={() => closeModal()}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Form>
            )
          }}
        />
      </Box>
    </AppDialog>
  )
}

export default container(AddNewClub)
