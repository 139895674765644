import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import Support from './Support/'
import PrizeDraw from './PrizeDraw'
import TermAndConditionDescription from './TermsAndConditions/components/TermAndConditionDescription.component'
import MerchantTermAndConditionDescription from './merchantTerms'
import LogOutComponent from 'components/LogOut/LogOut.component'

const TermsAndCondition = React.lazy(() => import('./TermsAndConditions/'))
const StripeSubscription = React.lazy(() => import('./AgreeStripeSubscription'))
const AgreeAddCardInfo = React.lazy(() => import('./AgreeAddCardInfo'))
const Dashboard = React.lazy(() => import('./Dashboard/'))
const TrainingVideos = React.lazy(() => import('./TrainingVideos/'))
const TrainingSetup = React.lazy(() => import('./TrainingSetup'))
const TrainingVideosSetup = React.lazy(() => import('./TrainingVideosSetup'))

export const commonRoutesConfig = [
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/logout',
    element: <LogOutComponent />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/termsandcondition',
    element: <TermsAndCondition />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/terms',
    element: <TermAndConditionDescription />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/document/:slug/:id',
    element: <MerchantTermAndConditionDescription />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/agreeStripeSubscription',
    element: <StripeSubscription />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/saveCardAndAgreeStripeSubscription',
    element: <AgreeAddCardInfo />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/support',
    element: <Support />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/prizedraw',
    element: <PrizeDraw />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/training-videos',
    element: <TrainingVideos />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/common/trainings',
    element: <TrainingSetup />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/common/trainings/:id',
    element: <TrainingVideosSetup />,
  },
  {
    permittedRole: Object.values(RoutePermittedRole).join(', '),
    path: '/common/trainings/all',
    element: <TrainingVideosSetup />,
  },
]

export const commonAnonymousRoutesConfig = [
  // {
  //   path: '/terms',
  //   element: <TermAndConditionDescription />,
  // },
]
