import { axiosRequest } from 'utils'

export const fetchRegionList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/region/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchRegionById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/region/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createRegion = (params) => {
  return () =>
    axiosRequest
      .post(`/api/region/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteRegion = (id) => {
  return () =>
    axiosRequest
      .delete(`/api/region/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editRegion = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/region/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
