import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchpendingAreaOwnerStart,
  fetchpendingAreaOwnerSuccess,
  fetchpendingAreaOwnerFailure,
  //   createCountrySuccess,
  //   createCountryFailure,
  deletePendingAreaOwnerSuccess,
  deletePendingAreaOwnerFailure,
  //   fetchCountryByIdSuccess,
  //   fetchCountryByIdFailure,
  //   editCountrySuccess,
  //   editCountryFailure,
} from './countryAdminSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchPendingAreaOwnerList,
  deletePendingAreaOwner,
  //   createCountry,
  //   deleteCountry,
  //   fetchCountryById,
  //   editCountry,
} from '../../api/pendingAreaOwner'

import _ from 'lodash'

export function* fetchPendingAreaOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPendingAreaOwnerList(payload))

    yield put(fetchpendingAreaOwnerSuccess(data))
  } catch (err) {
    yield put(fetchpendingAreaOwnerFailure(err))
    console.error(err)
  }
}

// export function* fetchCountryByIdAsync({ payload }: AnyAction) {
//   try {
//     const { data } = yield call(fetchCountryById(payload))

//     yield put(fetchCountryByIdSuccess(data))

//     console.log('post async saga', data)
//   } catch (err) {
//     yield put(fetchCountryByIdFailure(err))
//     console.error(err)
//   }
// }

// export function* editCountryAsync({ payload }: AnyAction) {
//   try {
//     console.log('editCountryAsync>>>>', payload)
//     const { data } = yield call(editCountry(payload.id, payload))

//     yield put(editCountrySuccess(payload))
//     yield put(openAlert({ message: 'succesfully edited', severity: 'success' }))
//   } catch (err) {
//     yield put(editCountryFailure(err))
//     console.error(err)
//   }
// }

// export function* createCountryAsync({ payload }: AnyAction) {
//   try {
//     console.log('createCountryAsync>>>>', payload)
//     const { data } = yield call(createCountry(payload))

//     yield put(createCountrySuccess(data?.data))
//   } catch (err) {
//     yield put(createCountryFailure(err))
//     console.error(err)
//   }
// }

export function* deletePendingAreaOwnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      deletePendingAreaOwner(payload.id, payload.rejectReason)
    )

    if (data) {
      yield put(deletePendingAreaOwnerSuccess(payload))
    }
  } catch (err) {
    yield put(deletePendingAreaOwnerFailure(err))
    console.error(err)
  }
}

export function* watchPendingAreaOwner() {
  yield takeLatest(
    'countryAdmin/fetchpendingAreaOwnerStart',
    fetchPendingAreaOwnerAsync
  )
}

// export function* watchCreateCountry() {
//   yield takeLatest('countrySetup/createCountryStart', createCountryAsync)
// }

export function* watchDeletePendingAreaOwner() {
  yield takeLatest(
    'countryAdmin/deletePendingAreaOwnerStart',
    deletePendingAreaOwnerAsync
  )
}

// export function* watchFetchCountryById() {
//   yield takeLatest('countrySetup/fetchCountryByIdStart', fetchCountryByIdAsync)
// }

// export function* watchEditCountry() {
//   yield takeLatest('countrySetup/editCountryStart', editCountryAsync)
// }

export function* pendingAreaOwnerSagas() {
  yield all([
    call(watchPendingAreaOwner),
    // call(watchCreateCountry),
    call(watchDeletePendingAreaOwner),
    // call(watchFetchCountryById),
    // call(watchEditCountry),
  ])
}
