import React, { useEffect } from 'react'
import { TextField, Autocomplete, Checkbox } from '@mui/material'
import { FieldProps, getIn } from 'formik'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import container from './MerchantSubCategorySelect.container'
import _ from 'lodash'

interface AddNewCountryProps {
  onFetchRegionStart: (payload: any) => void
  onFetchMerchantCategoryStart?: (payload: any) => void
  onFetchMerchantSubCategoryStart?: (payload: any) => void
  dropDown: any
  label?: string
  setSelectedCountry: any
  selectedRegion?: any
  filterByCountryId?: any
  recentParentCategory?: any
  defaultValue?: number
  multiple?: boolean
  required?: boolean
  subCategoryList?: any
}

const RegionSelect: React.FC<FieldProps & AddNewCountryProps> = ({
  field,
  form,
  onFetchRegionStart,
  onFetchMerchantSubCategoryStart,
  setSelectedCountry,
  selectedRegion,
  filterByCountryId,
  recentParentCategory,
  label,
  defaultValue,
  multiple,
  onFetchMerchantCategoryStart,
  subCategoryList,
  required,
  ...props
}) => {
  const [personName, setPersonName] = React.useState([])
  const [selectedArray, setSelectedArray] = React.useState([])

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    // const nameArray = value?.length && value.map((item) => item?.name)
    setSelectedArray(value)
    setPersonName(typeof value === 'string' ? value?.split(',') : value)
  }

  useEffect(() => {
    if (recentParentCategory?.id) {
      onFetchMerchantSubCategoryStart({
        limit: 100,
        parentId: recentParentCategory?.id,
      })
    }
  }, [recentParentCategory])

  const selectedValue = () => {
    return selectedArray?.map((val) => val.name).join(', ')
  }

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <>
      <Autocomplete
        multiple
        {...props}
        {...field}
        id="checkboxes-tags-demo"
        size="small"
        options={
          subCategoryList?.length
            ? subCategoryList
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                ?.filter((item) => item?.parentId)
            : []
        }
        disableCloseOnSelect
        getOptionLabel={(option: any) => option?.name}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(e: any, value) => {
          handleChange(e)
          form.setFieldValue(field.name, value)
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...props}
            {...params}
            required={field?.value?.length || !required ? false : true}
            helperText={errorText?.value || errorText}
            error={!!errorText}
            label="Merchant Sub-Category"
            // placeholder="Merchant Sub-Category"
          />
        )}
      />
    </>
  )
}

export default container(RegionSelect)
