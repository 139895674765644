import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'

const MembersList = React.lazy(() => import('./Members/MembersList'))
const SendMerchantRequest = React.lazy(
  () => import('./GlobalMerchantForm/SendMerchantRequest')
)

const SendCharityRequest = React.lazy(() => import('./SendCharityRequest'))

const SendClubRequest = React.lazy(() => import('./SendClubRequest'))
const MerchantTermAndConditionDescription = React.lazy(
  () => import('../Common/merchantTerms')
)

const Error403 = React.lazy(() => import('./Error403'))

const SendSalesAgentRequest = React.lazy(
  () => import('./SendSalesAgentRequest')
)
const SendMerchantGroupRequest = React.lazy(
  () => import('./SendMerchantGroupRequest')
)

export const unauthorizedConfig = [
  {
    permittedRole: RoutePermittedRole.areaOwner,
    path: '/unauthorized',
    element: <MembersList />,
  },
  {
    permittedRole: RoutePermittedRole.areaOwner,
    path: '/default',
    element: <MembersList />,
  },
  {
    permittedRole: RoutePermittedRole.areaOwner,
    path: '/sendMerchantRequest',
    element: <SendMerchantRequest />,
  },
  {
    permittedRole: RoutePermittedRole.areaOwner,
    path: '/document/:slug/:id',
    element: <MerchantTermAndConditionDescription />,
  },
  // {
  //   permittedRole: RoutePermittedRole.areaOwner,
  //   path: '/sendMerchantRequest/:id',
  //   element: <SendMerchantRequest />,
  // },
  {
    permittedRole: RoutePermittedRole.areaOwner,
    path: '/sendCharityRequest',
    element: <SendCharityRequest />,
  },
  {
    permittedRole: RoutePermittedRole.areaOwner,
    path: '/sendClubRequest',
    element: <SendClubRequest />,
  },
  {
    permittedRole: RoutePermittedRole.areaOwner,
    path: '/sendSalesAgentRequest',
    element: <SendSalesAgentRequest />,
  },

  {
    permittedRole: RoutePermittedRole.areaOwner,
    path: '/sendGroupMerchantRequest',
    element: <SendMerchantGroupRequest />,
  },

  {
    permittedRole: RoutePermittedRole.areaOwner,
    path: '/error-403',
    element: <Error403 />,
  },
]
