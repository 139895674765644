import { createSlice } from '@reduxjs/toolkit'

import { IRegionSetupRegion } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: IRegionSetupRegion = {
  regionList: listObj,
  soleRegion: {},
  error: null,
  loading: false,
  isFetching: false,
  hasMoreData: false,
}

export const regionSetupSlice = createSlice({
  name: 'regionSetup',
  initialState: INITIAL_STATE,
  reducers: {
    fetchRegionStart: (state: any, action) => {
      state.loading = true
    },
    fetchRegionSuccess: (state: any, action) => {
      state.regionList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchRegionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchRegionByIdStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchRegionByIdSuccess: (state: any, action) => {
      state.soleRegion = action.payload
      state.isFetching = false
    },
    fetchRegionByIdFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },
    createRegionStart: (state: any, action) => {
      state.loading = true
    },
    createRegionSuccess: (state: any, action) => {
      state.loading = false
      state.regionList.data = [...state.regionList.data, action.payload]
    },
    createRegionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    deleteRegionStart: (state: any, action) => {
      state.loading = true
    },
    deleteRegionSuccess: (state: any, action) => {
      state.loading = false
      state.regionList.data = state.regionList?.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteRegionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    editRegionStart: (state: any, action) => {
      state.loading = true
    },
    editRegionSuccess: (state: any, action) => {
      const result = state.regionList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.regionList.data = result
      state.loading = false
    },
    editRegionFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchRegionStart,
  fetchRegionSuccess,
  fetchRegionFailure,
  createRegionStart,
  createRegionSuccess,
  createRegionFailure,
  deleteRegionStart,
  deleteRegionSuccess,
  deleteRegionFailure,
  fetchRegionByIdStart,
  fetchRegionByIdSuccess,
  fetchRegionByIdFailure,
  editRegionStart,
  editRegionSuccess,
  editRegionFailure,
} = regionSetupSlice.actions

export default regionSetupSlice.reducer
