import React, { ReactNode } from "react";
import AppLoader from "@crema/core/AppLoader";
import PropTypes from "prop-types";
// import { useAuthUser } from "./AuthHooks";

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  const isLoading = false;
  return isLoading ? <AppLoader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
