import axios from 'axios'

import { logOut } from '../store/auth/authSlice'
import { useDispatch } from 'react-redux'

const token = localStorage.getItem('authToken')

const unauthorizedAxiosRequest = axios.create({
  baseURL: process.env.REACT_APP_URL,
  // baseURL: import.meta.env.VITE_APP_URL,
  timeout: 30000000,
  // headers: { Authorization: 'Bearer ' + token },
})

unauthorizedAxiosRequest.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('authToken')
    const expiresOn = JSON.parse(localStorage.getItem('expires_on'))
    const now = new Date()

    // if (token) {
    //   config.headers['Authorization'] = `Bearer ${token}`
    // }

    if (expiresOn && expiresOn < now.getTime()) {
      logOut()
    }

    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)

unauthorizedAxiosRequest.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      // performUserLogoutAction(store.dispatch);
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    if (error?.response?.status === 401) {
      // performUserLogoutAction(store.dispatch);
    }

    if (error?.response?.status === 403) {
      // toast.error('Unauthorized');
    }

    if (error?.response?.data?.validationErrors?.length) {
      const errRes = Object.values(
        error?.response?.data?.validationErrors?.[0]?.constraints
      )

      const errMsg = ((errRes?.[0] as string) || '')
        .replace('null', 'empty')
        .replace('undefined', 'empty')
      return Promise.reject({ ...error, message: errMsg })
    }

    return Promise.reject({ ...error, message: error?.response?.data?.message })
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
  }
)

const unauthorizedAxiosRequestCMS = axios.create({
  baseURL: process.env.REACT_APP_URL_CMS,
  // baseURL: import.meta.env.VITE_APP_URL_CMS,
  timeout: 30000000,
  // headers: { Authorization: 'Bearer ' + token },
})

unauthorizedAxiosRequestCMS.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('authToken')
    const expiresOn = JSON.parse(localStorage.getItem('expires_on'))
    const now = new Date()

    // if (token) {
    //   config.headers['Authorization'] = `Bearer ${token}`
    // }

    if (expiresOn && expiresOn < now.getTime()) {
      logOut()
    }

    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)

unauthorizedAxiosRequestCMS.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      // performUserLogoutAction(store.dispatch);
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    if (error?.response?.status === 401) {
      // performUserLogoutAction(store.dispatch);
    }

    if (error?.response?.status === 403) {
      // toast.error('Unauthorized');
    }

    if (error?.response?.data?.validationErrors?.length) {
      const errRes = Object.values(
        error?.response?.data?.validationErrors?.[0]?.constraints
      )

      const errMsg = ((errRes?.[0] as string) || '')
        .replace('null', 'empty')
        .replace('undefined', 'empty')
      return Promise.reject({ ...error, message: errMsg })
    }

    return Promise.reject({ ...error, message: error?.response?.data?.message })
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
  }
)

export default unauthorizedAxiosRequest
export { unauthorizedAxiosRequestCMS }
