import AllNotificationList from 'components/AllNotificationList'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
// const RegionOwnersList = React.lazy(() => import('./RegionOwners/RegionOwner'))
const AddCollaborationPartner = React.lazy(
  () => import('./CollaborationPartner/AddCollaborationPartner')
)

const AddCollaborationPartnerManual = React.lazy(
  () => import('./CollaborationPartner/AddCollaborationPartnerManual')
)
const CollaborationPartnerList = React.lazy(
  () =>
    import(
      './CollaborationPartner/CollaborationPartner/CollaborationPartner.component'
    )
)
const UpgradeToCP = React.lazy(
  () => import('./CollaborationPartner/UpgradeCP/UpgradeCP.component')
)
const ReferredCP = React.lazy(
  () =>
    import(
      './ReferredCollaborationPartner/ReferredCollaborationPartner.component'
    )
)
const EditReferredCP = React.lazy(
  () =>
    import(
      './ReferredCollaborationPartner/EditRefferredCP/EditReferredCP.component'
    )
)

const TransactionBatch = React.lazy(() => import('./QrCodes/TransactionQrCode'))
const TransactionQrCodes = React.lazy(
  () => import('./QrCodes/TransactionQrCode/QrCodes')
)
const IssuerQrBatch = React.lazy(() => import('./QrCodes/IssuerQrCode'))
const IssuerQrCodes = React.lazy(() => import('./QrCodes/IssuerQrCode/QrCodes'))

const ViewPurchaseHistory = React.lazy(() => import('./ViewPurchaseHistory'))
const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))

const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)

const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)

const CpPurchaseHistory = React.lazy(
  () => import('./CollaborationPartner/CpPurchaseHistory')
)

export const gmcAdminConfig = [
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: 'gmc/addcollaborationpartner',
    element: <AddCollaborationPartner />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: 'gmc/addcollaborationpartnermanual',
    element: <AddCollaborationPartnerManual />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: 'gmc/collaborationpartnerslist',
    element: <CollaborationPartnerList />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: 'gmc/upgradetocp',
    element: <UpgradeToCP />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: 'gmc/upgradetocp',
    element: <UpgradeToCP />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: 'gmc/referredcplist',
    element: <ReferredCP />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: 'gmc/referredcp/edit/:id',
    element: <EditReferredCP />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: 'gmc/purchasehistory/:id',
    element: <ViewPurchaseHistory />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/transaction-qr-codes',
    element: <TransactionBatch />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/transaction-qr-codes/:id',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/issuer-qr-codes',
    element: <IssuerQrBatch />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/issuer-qr-codes/:id',
    element: <IssuerQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/coupons',
    element: <Coupons adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/coupon-codes/:id',
    element: <CouponCodes />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/setting/smtp',
    element: <SMTP adminRoute={'gmc'} />,
  },
  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/setting/group',
    element: <Group adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/setting/contacts',
    element: <Contacts adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/setting/documents',
    element: <Documents adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/setting/emails',
    element: <Emails adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/setting/recipients',
    element: <Recipients adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/setting/reports/:id',
    element: <Reports adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/all-notification',
    element: <AllNotificationList adminRoute={'gmc'} />,
  },

  {
    permittedRole: RoutePermittedRole.gmcUser,
    path: '/gmc/cp-purchase-history',
    element: <CpPurchaseHistory adminRoute={'gmc'} />,
  },
]
