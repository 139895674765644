import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchCountrySuccess,
  fetchRegionSuccess,
  fetchConstantSuccess,
  fetchCountryFailure,
  fetchConstantFailure,
  fetchStateFailure,
  fetchStateSuccess,
  fetchAreaSuccess,
  fetchAreaFailure,
  fetchWhiteLabelSuccess,
  fetchWhiteLabelFailure,
  fetchAllUniqueWhiteLabelPositionSuccess,
  fetchAllUniqueWhiteLabelPositionFailure,
  fetchWhiteLabelUserByParentIdSuccess,
  fetchWhiteLabelUserByParentIdFailure,
  fetchWhiteLabelPromotionalLevelSuccess,
  fetchWhiteLabelPromotionalLevelFailure,
  fetchAllUniqueWhiteLabelPositionGASuccess,
  fetchAllUniqueWhiteLabelPositionGAFailure,
  fetchCharitySuccess,
  fetchCharityFailure,
  fetchMerchantCategorySuccess,
  fetchMerchantCategoryFailure,
  fetchMerchantSubCategorySuccess,
  fetchMerchantSubCategoryFailure,
  fetchMerchantCategoryParentSuccess,
  fetchMerchantCategoryParentFailure,
  fetchMerchantPackageSuccess,
  fetchMerchantPackageFailure,
  fetchAllUniqueWhiteLabelPositionByIdSuccess,
  fetchAllUniqueWhiteLabelPositionByIdFailure,
  fetchCountryPrefixSuccess,
  fetchCountryPrefixFailure,
  fetchTransactionQrCodeStart,
  fetchTransactionQrCodeSuccess,
  fetchTransactionQrCodeFailure,
  fetchIssuerQrCodeFailure,
  fetchIssuerQrCodeSuccess,
  fetchUnusedTransactionQrCodeSuccess,
  fetchUnusedTransactionQrCodeFailure,
  fetchUnusedIssuerQrCodeSuccess,
  fetchUnusedIssuerQrCodeFailure,
  fetchNotUsedIssuerQrCodeForAdminSuccess,
  fetchNotUsedIssuerQrCodeForAdminFailure,
  fetchAllTerminalListByPanelSuccess,
  fetchAllTerminalListByPanelFailure,
  fetchIssuerQrCodesListAllSuccess,
  fetchIssuerQrCodesListAllFailure,
  fetchUnusedPremiumCodeByAdminSuccess,
  fetchUnusedPremiumCodeByAdminFailure,
  fetchUnusedPremiumCodeByPanelSuccess,
  fetchUnusedPremiumCodeByPanelFailure,
  fetchPageSuccess,
  fetchPageFailure,
  fetchParentSuccess,
  fetchParentFailure,
  fetchLanguageSuccess,
  fetchLanguageFailure,
  fetchAllMerchantTerminalListSuccess,
  fetchAllMerchantTerminalListFailure,
  fetchAllMerchantGroupSuccess,
  fetchAllMerchantGroupFailure,
  fetchLanguageCodeFailure,
  fetchLanguageCodeSuccess,
  fetchTrainingFolderSuccess,
  fetchTrainingFolderFailure,
  fetchAllGroupListSuccess,
  fetchAllGroupListFailure,
  fetchContactListSuccess,
  fetchContactListFailure,
  fetchDocumentListSuccess,
  fetchDocumentListFailure,
  fetchMerchantPackageDropDownSuccess,
  fetchMerchantPackageDropDownFailure,
  fetchTrainingFolderForOtherPanelsSuccess,
  fetchTrainingFolderForOtherPanelsFailure,
  fetchAllGroupListByPanelSuccess,
  fetchAllGroupListByPanelFailure,
  fetchGrouplessContactListSuccess,
  fetchGrouplessContactListFailure,
  fetchDocumentListByPanelSuccess,
  fetchDocumentListByPanelFailure,
  fetchGrouplessContactListByPanelSuccess,
  fetchGrouplessContactListByPanelFailure,
  fetchCreatorNameSuccess,
  fetchCreatorNameFailure,
  fetchLanguageForOtherPanelsSuccess,
  fetchLanguageForOtherPanelsFailure,
  fetchMerchantPackageForPremiumCodeSuccess,
  fetchMerchantPackageForPremiumCodeFailure,
  fetchPayoutContactNameSuccess,
  fetchPayoutContactNameFailure,
  fetchCountryCurrencySymbolSuccess,
  fetchCountryCurrencySymbolFailure,
} from './dropDownSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import { fetchCharityList } from '../../api/charity'
import {
  fetchAllCountryCurrencySymbol,
  fetchCountryList,
  fetchCountryListForSetup,
} from '../../api/country'
import { fetchConstantList } from '../../api/constant'
import { fetchRegionList } from '../../api/regionDropDown'
import { fetchStateList } from '../../api/stateDropDown'
import { fetchAreaList } from '../../api/areaDropDown'
import { fetchAllCountryPrefix } from '../../api/countryPrefix'
import {
  fetchMerchantCategoryList,
  fetchMerchantSubCategoryList,
  fetchMerchantCategoryParentList,
} from '../../api/merchantCategory'
import {
  getWhiteLabelPositionSelf,
  getWhiteLabelPromotionalLevel,
  getWhiteLabelPositionById,
} from '../../api/whiteLabel'

import {
  fetchWhiteLabelList,
  getWhiteLabelUserByParentId,
} from '../../api/whiteLabel'

import {
  fetchMerchantPackageForPremiumCode,
  fetchMerchantPackageList,
  fetchMerchantPackageListForDropDown,
} from '../../api/merchantPackage'

import _ from 'lodash'
import { removeEmptyFields } from 'utils'
import {
  fetchTransactionCodeListByPanel,
  fetchNotUsedTransactionCodeList,
} from 'api/transactionCodes'
import {
  fetchAllNotUsedForAdmin,
  fetchIssuerCodeList,
  fetchIssuerCodeListByPanel,
  fetchNotUsedIssuerCodeListByPanel,
} from 'api/issuerCodes'
import {
  fetchAllMerchantGroup,
  fetchAllTerminalListByPanel,
  fetchMerchantTerminalList,
} from 'api/merchant'
import {
  fetchUnusedPremiumCodeByAdmin,
  fetchUnusedPremiumCodeByPanel,
} from 'api/premiumCodes'
import { fetchPageList } from 'api/PageSelect'
import { fetchParentList } from 'api/ParentList'
import {
  fetchLanguageList,
  fetchLanguageListForOtherAdminPanels,
} from '../../api/languageList'
import { fetchLanguageCodeList } from 'api/websiteAdmin/multilingual'
import {
  fetchTrainingVideoCategoryList,
  fetchTrainingVideoCategoryListForOtherPanels,
} from 'api/trainingVideo'
import {
  fetchContactList,
  fetchCreatorNameList,
  fetchGroupInformationList,
  fetchGroupInformationListByPanel,
  fetchGrouplessContactList,
  fetchGrouplessContactListByPanel,
  fetchPayoutContactNameList,
  fetchUploadDocumentList,
  fetchUploadDocumentListByPanel,
} from 'api/GlobalAdmin/settings'

export function* fetchCountryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield payload?.type === 'all'
      ? call(
          fetchCountryListForSetup(removeEmptyFields({ ...payload, type: '' }))
        )
      : call(fetchCountryList(removeEmptyFields(payload)))

    yield put(fetchCountrySuccess(data?.data))
  } catch (err) {
    yield put(fetchCountryFailure(err))
    console.error(err)
  }
}

export function* fetchConstantAsync({ payload }: AnyAction) {
  try {
    const { data } = yield payload?.type === 'all'
      ? call(fetchConstantList(removeEmptyFields({ ...payload, type: '' })))
      : call(fetchConstantList(removeEmptyFields(payload)))

    yield put(fetchConstantSuccess(data?.data))
  } catch (err) {
    yield put(fetchConstantFailure(err))
    console.error(err)
  }
}

export function* fetchRegionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchRegionList(payload))

    yield put(fetchRegionSuccess(data?.data))
  } catch (err) {
    yield put(fetchCountryFailure(err))
    console.error(err)
  }
}

export function* fetchStateAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchStateList(payload))

    yield put(fetchStateSuccess(data?.data))
  } catch (err) {
    yield put(fetchStateFailure(err))
    console.error(err)
  }
}

export function* fetchAreaAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAreaList(payload))

    yield put(fetchAreaSuccess(data?.data))
  } catch (err) {
    yield put(fetchAreaFailure(err))
    console.error(err)
  }
}

export function* fetchWhiteLabelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchWhiteLabelList(payload))

    yield put(fetchWhiteLabelSuccess(data?.data))
  } catch (err) {
    yield put(fetchWhiteLabelFailure(err))
    console.error(err)
  }
}

export function* fetchAllUniqueWhiteLabelPositionAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getWhiteLabelPositionSelf())

    yield put(fetchAllUniqueWhiteLabelPositionSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllUniqueWhiteLabelPositionFailure(err))
  }
}

export function* fetchAllUniqueWhiteLabelGAPositionAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(getWhiteLabelPositionById(payload?.id))

    yield put(fetchAllUniqueWhiteLabelPositionGASuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllUniqueWhiteLabelPositionGAFailure(err))
  }
}

export function* fetchAllUniqueWhiteLabelPositionByIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(getWhiteLabelPositionById(payload?.id))

    yield put(fetchAllUniqueWhiteLabelPositionByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllUniqueWhiteLabelPositionByIdFailure(err))
  }
}

export function* fetchWhiteLabelUserByParentIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getWhiteLabelUserByParentId(payload))

    yield put(fetchWhiteLabelUserByParentIdSuccess(data?.data))
  } catch (err) {
    // yield put(
    //   openAlert({
    //     message: err.message,
    //     severity: 'error',
    //   })
    // )
    yield put(fetchWhiteLabelUserByParentIdFailure(err))
  }
}

export function* fetchWhiteLabelPromotionalLevelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getWhiteLabelPromotionalLevel(payload))

    yield put(fetchWhiteLabelPromotionalLevelSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchWhiteLabelPromotionalLevelFailure(err))
    console.error(err)
  }
}

export function* fetchCharitiesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCharityList(payload))

    yield put(fetchCharitySuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCharityFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantCategoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantCategoryList(payload))

    yield put(fetchMerchantCategorySuccess(data?.results?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantCategoryFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantSubCategoryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantSubCategoryList(payload))

    yield put(fetchMerchantSubCategorySuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantSubCategoryFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantCategoryParentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantCategoryParentList(payload))
    yield put(fetchMerchantCategoryParentSuccess(data?.data?.data || []))
    // yield put(fetchMerchantCategoryParentSuccess(data?.results?.data || []))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantCategoryParentFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantPackageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantPackageList(payload))
    yield put(fetchMerchantPackageSuccess(data?.results?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantPackageFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantPackageDropDownAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantPackageListForDropDown(payload))
    yield put(fetchMerchantPackageDropDownSuccess(data?.results?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantPackageDropDownFailure(err))
    console.error(err)
  }
}

export function* fetchCountryPrefixListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllCountryPrefix(payload))
    yield put(fetchCountryPrefixSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCountryPrefixFailure(err))
    console.error(err)
  }
}

export function* fetchTransactionQrCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTransactionCodeListByPanel(payload))

    yield put(fetchTransactionQrCodeSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTransactionQrCodeFailure(err))
  }
}

export function* fetchUnusedTransactionQrCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchNotUsedTransactionCodeList(payload))

    yield put(fetchUnusedTransactionQrCodeSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchUnusedTransactionQrCodeFailure(err))
  }
}

export function* fetchIssuerQrCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchIssuerCodeListByPanel(payload))

    yield put(fetchIssuerQrCodeSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerQrCodeFailure(err))
  }
}

export function* fetchIssuerQrCodesListAllAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchNotUsedIssuerCodeListByPanel(payload))

    yield put(fetchIssuerQrCodesListAllSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerQrCodesListAllFailure(err))
  }
}

export function* fetchUnusedIssuerQrCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchNotUsedIssuerCodeListByPanel(payload))

    yield put(fetchUnusedIssuerQrCodeSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchUnusedIssuerQrCodeFailure(err))
  }
}

export function* fetchUnusedPremiumCodeByAdminAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchUnusedPremiumCodeByAdmin(payload))

    yield put(fetchUnusedPremiumCodeByAdminSuccess(data?.results?.data))
  } catch (err) {
    // yield put(
    //   openAlert({
    //     message: err.message,
    //     severity: 'error',
    //   })
    // )
    yield put(fetchUnusedPremiumCodeByAdminFailure(err))
  }
}

export function* fetchUnusedPremiumCodeByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchUnusedPremiumCodeByPanel(payload))

    yield put(fetchUnusedPremiumCodeByPanelSuccess(data?.results?.data))
  } catch (err) {
    // yield put(
    //   openAlert({
    //     message: err.message,
    //     severity: 'error',
    //   })
    // )
    yield put(fetchUnusedPremiumCodeByPanelFailure(err))
  }
}

export function* fetchNotUsedIssuerQrCodeForAdminAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllNotUsedForAdmin(payload))

    yield put(fetchNotUsedIssuerQrCodeForAdminSuccess(data?.data))
  } catch (err) {
    // yield put(
    //   openAlert({
    //     message: err.message,
    //     severity: 'error',
    //   })
    // )
    yield put(fetchNotUsedIssuerQrCodeForAdminFailure(err))
  }
}

export function* fetchAllTerminalListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllTerminalListByPanel(payload))

    yield put(fetchAllTerminalListByPanelSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllTerminalListByPanelFailure(err))
  }
}

export function* fetchPageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPageList(payload))
    yield put(fetchPageSuccess(data?.data))
  } catch (err) {
    yield put(fetchPageFailure(err))
    console.error(err)
  }
}

export function* fetchParentAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchParentList(payload))

    yield put(fetchParentSuccess(data?.data))
  } catch (err) {
    yield put(fetchParentFailure(err))
    console.error(err)
  }
}

export function* fetchLanguageAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchLanguageList(payload))

    yield put(fetchLanguageSuccess(data?.data))
  } catch (err) {
    yield put(fetchLanguageFailure(err))
    console.error(err)
  }
}

export function* fetchLanguageForOtherPanelsAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchLanguageListForOtherAdminPanels(payload))

    yield put(fetchLanguageForOtherPanelsSuccess(data?.data))
  } catch (err) {
    yield put(fetchLanguageForOtherPanelsFailure(err))
    console.error(err)
  }
}

export function* fetchAllMerchantGroupAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllMerchantGroup(payload))

    yield put(fetchAllMerchantGroupSuccess(data?.data))
  } catch (err) {
    yield put(fetchAllMerchantGroupFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantTerminalListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantTerminalList(payload))

    yield put(fetchAllMerchantTerminalListSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchAllMerchantTerminalListFailure(err))
    console.error(err)
  }
}

export function* fetchLanguageCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchLanguageCodeList(payload))

    yield put(fetchLanguageCodeSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchLanguageCodeFailure(err))
    console.error(err)
  }
}

export function* fetchTrainingFolderAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTrainingVideoCategoryList(payload))

    yield put(fetchTrainingFolderSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchTrainingFolderFailure(err))
    console.error(err)
  }
}

export function* fetchTrainingFolderForOtherPanelsAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchTrainingVideoCategoryListForOtherPanels(payload)
    )

    yield put(fetchTrainingFolderForOtherPanelsSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(fetchTrainingFolderForOtherPanelsFailure(err))
    console.error(err)
  }
}

export function* fetchGroupInformationListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGroupInformationList(payload))

    yield put(fetchAllGroupListSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAllGroupListFailure(err))
  }
}

export function* fetchGroupInformationListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGroupInformationListByPanel(payload))

    yield put(fetchAllGroupListByPanelSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchAllGroupListByPanelFailure(err))
  }
}

export function* fetchContactListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchContactList(payload))

    yield put(fetchContactListSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchContactListFailure(err))
  }
}

export function* fetchGrouplessContactListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGrouplessContactList(payload))

    yield put(fetchGrouplessContactListSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGrouplessContactListFailure(err))
  }
}

export function* fetchGrouplessContactListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGrouplessContactListByPanel(payload))

    yield put(fetchGrouplessContactListByPanelSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchGrouplessContactListByPanelFailure(err))
  }
}

export function* fetchDocumentListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchUploadDocumentList(payload))

    yield put(fetchDocumentListSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchDocumentListFailure(err))
  }
}

export function* fetchDocumentListByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchUploadDocumentListByPanel(payload))

    yield put(fetchDocumentListByPanelSuccess(data?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchDocumentListByPanelFailure(err))
  }
}

export function* fetchCreatorNameListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCreatorNameList(payload))

    yield put(fetchCreatorNameSuccess(data?.data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCreatorNameFailure(err))
  }
}

export function* fetchPayoutContactNameListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchPayoutContactNameList(payload))

    yield put(fetchPayoutContactNameSuccess(data?.data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchPayoutContactNameFailure(err))
  }
}

export function* fetchMerchantPackageForPremiumCodeAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchMerchantPackageForPremiumCode(payload))
    yield put(fetchMerchantPackageForPremiumCodeSuccess(data?.results?.data))
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchMerchantPackageForPremiumCodeFailure(err))
    console.error(err)
  }
}

export function* fetchCountryCurrencySymbolAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllCountryCurrencySymbol(payload))

    yield put(fetchCountryCurrencySymbolSuccess(data?.data))
  } catch (err) {
    // yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(fetchCountryCurrencySymbolFailure(err))
  }
}

export function* watchFetchCountry() {
  yield takeLatest('dropDown/fetchCountryStart', fetchCountryAsync)
}

export function* watchFetchConstant() {
  yield takeLatest('dropDown/fetchConstantStart', fetchConstantAsync)
}

export function* watchFetchRegion() {
  yield takeLatest('dropDown/fetchRegionStart', fetchRegionAsync)
}

export function* watchFetchState() {
  yield takeLatest('dropDown/fetchStateStart', fetchStateAsync)
}

export function* watchFetchArea() {
  yield takeLatest('dropDown/fetchAreaStart', fetchAreaAsync)
}

export function* watchFetchWhiteLabel() {
  yield takeLatest('dropDown/fetchWhiteLabelStart', fetchWhiteLabelAsync)
}

export function* watchFetchAllUniqueWhiteLabelPosition() {
  yield takeLatest(
    'dropDown/fetchAllUniqueWhiteLabelPositionStart',
    fetchAllUniqueWhiteLabelPositionAsync
  )
}

export function* watchFetchAllUniqueWhiteLabelGAPosition() {
  yield takeLatest(
    'dropDown/fetchAllUniqueWhiteLabelPositionGAStart',
    fetchAllUniqueWhiteLabelGAPositionAsync
  )
}

export function* watchFetchWhiteLabelUserByParentId() {
  yield takeLatest(
    'dropDown/fetchWhiteLabelUserByParentIdStart',
    fetchWhiteLabelUserByParentIdAsync
  )
}

export function* watchFetchCharities() {
  yield takeLatest('dropDown/fetchCharityStart', fetchCharitiesAsync)
}

export function* watchFetchWhiteLabelPromotionalLevel() {
  yield takeLatest(
    'dropDown/fetchWhiteLabelPromotionalLevelStart',
    fetchWhiteLabelPromotionalLevelAsync
  )
}

export function* watchFetchMerchantCategory() {
  yield takeLatest(
    'dropDown/fetchMerchantCategoryStart',
    fetchMerchantCategoryAsync
  )
}

export function* watchFetchMerchantSubCategory() {
  yield takeLatest(
    'dropDown/fetchMerchantSubCategoryStart',
    fetchMerchantSubCategoryAsync
  )
}

export function* watchFetchMerchantCategoryParent() {
  yield takeLatest(
    'dropDown/fetchMerchantCategoryParentStart',
    fetchMerchantCategoryParentAsync
  )
}

export function* watchFetchMerchantPackage() {
  yield takeLatest(
    'dropDown/fetchMerchantPackageStart',
    fetchMerchantPackageAsync
  )
}

export function* watchFetchMerchantPackageDropDown() {
  yield takeLatest(
    'dropDown/fetchMerchantPackageDropDownStart',
    fetchMerchantPackageDropDownAsync
  )
}

export function* watchfetchAllUniqueWhiteLabelPositionById() {
  yield takeLatest(
    'dropDown/fetchAllUniqueWhiteLabelPositionByIdStart',
    fetchAllUniqueWhiteLabelPositionByIdAsync
  )
}
export function* watchFetchCountryPrefixListAsync() {
  yield takeLatest(
    'dropDown/fetchCountryPrefixStart',
    fetchCountryPrefixListAsync
  )
}

export function* watchFetchTransactionQrCodeAsync() {
  yield takeLatest(
    'dropDown/fetchTransactionQrCodeStart',
    fetchTransactionQrCodeAsync
  )
}

export function* watchFetchUnusedTransactionQrCodeAsync() {
  yield takeLatest(
    'dropDown/fetchUnusedTransactionQrCodeStart',
    fetchUnusedTransactionQrCodeAsync
  )
}

export function* watchFetchIssuerQrCodeAsync() {
  yield takeLatest('dropDown/fetchIssuerQrCodeStart', fetchIssuerQrCodeAsync)
}

export function* watchFetchIssuerQrCodesListAllAsync() {
  yield takeLatest(
    'dropDown/fetchIssuerQrCodesListAllStart',
    fetchIssuerQrCodesListAllAsync
  )
}

export function* watchFetchUnusedIssuerQrCodeAsync() {
  yield takeLatest(
    'dropDown/fetchUnusedIssuerQrCodeStart',
    fetchUnusedIssuerQrCodeAsync
  )
}

export function* watchFetchNotUsedIssuerQrCodeForAdminAsync() {
  yield takeLatest(
    'dropDown/fetchNotUsedIssuerQrCodeForAdminStart',
    fetchNotUsedIssuerQrCodeForAdminAsync
  )
}

export function* watchFetchAllTerminalListByPanelAsync() {
  yield takeLatest(
    'dropDown/fetchAllTerminalListByPanelStart',
    fetchAllTerminalListByPanelAsync
  )
}

export function* watchFetchUnusedPremiumCodeByAdminAsync() {
  yield takeLatest(
    'dropDown/fetchUnusedPremiumCodeByAdminStart',
    fetchUnusedPremiumCodeByAdminAsync
  )
}

export function* watchFetchUnusedPremiumCodeByPanelAsync() {
  yield takeLatest(
    'dropDown/fetchUnusedPremiumCodeByPanelStart',
    fetchUnusedPremiumCodeByPanelAsync
  )
}

export function* watchFetchPage() {
  yield takeLatest('dropDown/fetchPageStart', fetchPageAsync)
}

export function* watchFetchParent() {
  yield takeLatest('dropDown/fetchParentStart', fetchParentAsync)
}

export function* watchFetchLanguage() {
  yield takeLatest('dropDown/fetchLanguageStart', fetchLanguageAsync)
}

export function* watchFetchLanguageForOtherPanels() {
  yield takeLatest(
    'dropDown/fetchLanguageForOtherPanelsStart',
    fetchLanguageForOtherPanelsAsync
  )
}

export function* watchFetchAllMerchantGroup() {
  yield takeLatest(
    'dropDown/fetchAllMerchantGroupStart',
    fetchAllMerchantGroupAsync
  )
}

export function* watchFetchMerchantTerminalList() {
  yield takeLatest(
    'dropDown/fetchAllMerchantTerminalListStart',
    fetchMerchantTerminalListAsync
  )
}

export function* watchFetchLanguageCodeAsync() {
  yield takeLatest('dropDown/fetchLanguageCodeStart', fetchLanguageCodeAsync)
}

export function* watchFetchTrainingFolder() {
  yield takeLatest(
    'dropDown/fetchTrainingFolderStart',
    fetchTrainingFolderAsync
  )
}

export function* watchFetchTrainingFolderForOtherPanels() {
  yield takeLatest(
    'dropDown/fetchTrainingFolderForOtherPanelsStart',
    fetchTrainingFolderForOtherPanelsAsync
  )
}

export function* watchFetchAllGroupList() {
  yield takeLatest(
    'dropDown/fetchAllGroupListStart',
    fetchGroupInformationListAsync
  )
}

export function* watchFetchAllGroupListByPanel() {
  yield takeLatest(
    'dropDown/fetchAllGroupListByPanelStart',
    fetchGroupInformationListByPanelAsync
  )
}

export function* watchFetchContactList() {
  yield takeLatest('dropDown/fetchContactListStart', fetchContactListAsync)
}

export function* watchFetchGrouplessContactList() {
  yield takeLatest(
    'dropDown/fetchGrouplessContactListStart',
    fetchGrouplessContactListAsync
  )
}

export function* watchFetchGrouplessContactListByPanel() {
  yield takeLatest(
    'dropDown/fetchGrouplessContactListByPanelStart',
    fetchGrouplessContactListByPanelAsync
  )
}

export function* watchFetchDocumentList() {
  yield takeLatest('dropDown/fetchDocumentListStart', fetchDocumentListAsync)
}

export function* watchFetchDocumentListByPanel() {
  yield takeLatest(
    'dropDown/fetchDocumentListByPanelStart',
    fetchDocumentListByPanelAsync
  )
}
export function* watchFetchCreatorName() {
  yield takeLatest('dropDown/fetchCreatorNameStart', fetchCreatorNameListAsync)
}

export function* watchFetchPayoutContactName() {
  yield takeLatest(
    'dropDown/fetchPayoutContactNameStart',
    fetchPayoutContactNameListAsync
  )
}

export function* watchFetchCountryCurrencySymbol() {
  yield takeLatest(
    'dropDown/fetchCountryCurrencySymbolStart',
    fetchCountryCurrencySymbolAsync
  )
}

export function* watchFetchMerchantPackageForPremiumCodeAsync() {
  yield takeLatest(
    'dropDown/fetchMerchantPackageForPremiumCodeStart',
    fetchMerchantPackageForPremiumCodeAsync
  )
}

export function* dropDownSagas() {
  yield all([
    call(watchFetchCountry),
    call(watchFetchRegion),
    call(watchFetchState),
    call(watchFetchArea),
    call(watchFetchWhiteLabel),
    call(watchFetchAllUniqueWhiteLabelPosition),
    call(watchFetchWhiteLabelUserByParentId),
    call(watchFetchWhiteLabelPromotionalLevel),
    call(watchFetchAllUniqueWhiteLabelGAPosition),
    call(watchFetchCharities),
    call(watchFetchMerchantCategory),
    call(watchFetchMerchantSubCategory),
    call(watchFetchMerchantCategoryParent),
    call(watchFetchMerchantPackage),
    call(watchfetchAllUniqueWhiteLabelPositionById),
    call(watchFetchCountryPrefixListAsync),
    call(watchFetchTransactionQrCodeAsync),
    call(watchFetchIssuerQrCodeAsync),
    call(watchFetchUnusedTransactionQrCodeAsync),
    call(watchFetchUnusedIssuerQrCodeAsync),
    call(watchFetchNotUsedIssuerQrCodeForAdminAsync),
    call(watchFetchAllTerminalListByPanelAsync),
    call(watchFetchIssuerQrCodesListAllAsync),
    call(watchFetchUnusedPremiumCodeByAdminAsync),
    call(watchFetchUnusedPremiumCodeByPanelAsync),
    call(watchFetchPage),
    call(watchFetchParent),
    call(watchFetchLanguage),
    call(watchFetchConstant),
    call(watchFetchMerchantTerminalList),
    call(watchFetchAllMerchantGroup),
    call(watchFetchLanguageCodeAsync),
    call(watchFetchTrainingFolder),
    call(watchFetchAllGroupList),
    call(watchFetchContactList),
    call(watchFetchDocumentList),
    call(watchFetchMerchantPackageDropDown),
    call(watchFetchTrainingFolderForOtherPanels),
    call(watchFetchAllGroupListByPanel),
    call(watchFetchGrouplessContactList),
    call(watchFetchDocumentListByPanel),
    call(watchFetchGrouplessContactListByPanel),
    call(watchFetchCreatorName),
    call(watchFetchLanguageForOtherPanels),
    call(watchFetchMerchantPackageForPremiumCodeAsync),
    call(watchFetchPayoutContactName),
    call(watchFetchCountryCurrencySymbol),
  ])
}
