/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import {
  changeNotificationOnOrOffStart,
  createGeneralSettingsStart,
  deleteGroupInformationListStart,
  deleteNotificationStart,
  fetchGeneralSettingStart,
  fetchNotificationListStart,
  fetchNotificationByUserTypeSeenStart,
  fetchNotificationByUserTypeUnSeenStart,
  fetchNotificationByUserStart,
  markNotificationAsUnseenStart,
} from 'store/globalAdminSettings/globalAdminSettingsSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  globalSettings: state.globalSettings,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchGeneralSettingStart: (payload) =>
    dispatch(fetchGeneralSettingStart(payload)),

  onFetchNotificationByUserTypeSeenStart: (payload) =>
    dispatch(fetchNotificationByUserTypeSeenStart(payload)),

  onFetchNotificationByUserTypeUnSeenStart: (payload) =>
    dispatch(fetchNotificationByUserTypeUnSeenStart(payload)),

  onFetchNotificationListStart: (payload) =>
    dispatch(fetchNotificationListStart(payload)),

  onCreateGeneralSettingsStart: (payload) =>
    dispatch(createGeneralSettingsStart(payload)),

  onDeleteGroupInformationListStart: (payload) =>
    dispatch(deleteGroupInformationListStart(payload)),

  onChangeNotificationOnOrOffStart: (payload) =>
    dispatch(changeNotificationOnOrOffStart(payload)),

  onDeleteNotificationStart: (payload) =>
    dispatch(deleteNotificationStart(payload)),

  onFetchNotificationByUserStart: (payload) =>
    dispatch(fetchNotificationByUserStart(payload)),
  OnMarkNotificationAsUnseenStart: (payload) =>
    dispatch(markNotificationAsUnseenStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
