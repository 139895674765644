import { takeLatest, call, all, put, select, delay } from 'redux-saga/effects'
import {
  fetchTransactionQrCodesListStart,
  fetchTransactionQrCodesListSuccess,
  fetchTransactionQrCodesListFailure,
  fetchIssuerQrCodesListSuccess,
  fetchIssuerQrCodesListFailure,
  fetchTransactionBatchListFailure,
  fetchTransactionBatchListStart,
  fetchTransactionBatchListSuccess,
  createTransactionBatchFailure,
  createTransactionBatchStart,
  createTransactionBatchSuccess,
  createTransactionBatchCSVStart,
  createTransactionBatchCSVSuccess,
  createTransactionBatchCSVFailure,
  fetchAssignerStart,
  fetchAssignerFailure,
  fetchAssignerSuccess,
  fetchTransactionQrCodesListAllStart,
  fetchTransactionQrCodesListAllSuccess,
  fetchTransactionQrCodesListAllFailure,
  createAssignerStart,
  createAssignerSuccess,
  createAssignerFailure,
  editTransactionBatchStart,
  editTransactionBatchSuccess,
  editTransactionBatchFailure,
  createGMCAssignerFailure,
  createGMCAssignerStart,
  createGMCAssignerSuccess,
  fetchTransactionQrCodesListWithoutFilterSuccess,
  fetchTransactionQrCodesListWithoutFilterFailure,
  fetchIssuerQrCodesListWithoutFilterSuccess,
  fetchIssuerQrCodesListWithoutFilterFailure,
  createWhiteLabelAssignerSuccess,
  createWhiteLabelAssignerFailure,
  fetchTransactionGmcBatchListSuccess,
  fetchTransactionGmcBatchListFailure,
  fetchGmcCodesByBatchIdSuccess,
  fetchGmcCodesByBatchIdFailure,
  createAssignerMemberPremiumCodeFailure,
  createAssignerMemberPremiumCodeStart,
  createAssignerMemberPremiumCodeSuccess,
  createAssignerMerchantPremiumCodeFailure,
  createAssignerMerchantPremiumCodeStart,
  createAssignerMerchantPremiumCodeSuccess,
  fetchTransactionCodeAssignableCountForOthersSuccess,
  fetchTransactionCodeAssignableCountForOthersFailure,
  fetchTransactionCodeAssignableCountForGMCSuccess,
  fetchTransactionCodeAssignableCountForGMCFailure,
  fetchIssuerQrCodesListByMerchantIdSuccess,
  fetchIssuerQrCodesListByMerchantIdFailure,
  fetchTransactionQrCodesListByMerchantIdSuccess,
  fetchTransactionQrCodesListByMerchantIdFailure,
  fetchOwnIssuerCodeSuccess,
  fetchOwnIssuerCodeFailure,
  fetchOwnTransactionCodeSuccess,
  fetchOwnTransactionCodeFailure,
  deleteTransactionCodeByIdSuccess,
  deleteTransactionCodeByIdFailure,
  deleteIssuerCodeByIdSuccess,
  deleteIssuerCodeByIdFailure,
  fetchStateIssuerQrCodesListByMerchantIdSuccess,
  fetchStateIssuerQrCodesListByMerchantIdFailure,
  fetchRegionIssuerQrCodesListByMerchantIdSuccess,
  fetchRegionIssuerQrCodesListByMerchantIdFailure,
  fetchAreaIssuerQrCodesListByMerchantIdSuccess,
  fetchAreaIssuerQrCodesListByMerchantIdFailure,
  fetchCountryIssuerQrCodesListByMerchantIdSuccess,
  fetchCountryIssuerQrCodesListByMerchantIdFailure,
  deleteTransactionCodeBatchSuccess,
  deleteTransactionCodeBatchFailure,
  deleteTransactionCodeSuccess,
  deleteTransactionCodeFailure,
  downloadTransactionCodeBatchSuccess,
  downloadTransactionCodeBatchFailure,
  fetchOneTransactionQrCodePreviewSuccess,
  fetchOneTransactionQrCodePreviewFailure,
  changeDownloadStatus,
} from './qrCodesSlice'

import {
  fetchPremiumMerchantCodesGetAllStart,
  fetchPremiumMemberCodesGetAllStart,
} from 'store/collaborationPartner/collaborationPartnerSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchTransactionCodeListByPanel,
  fetchTransactionCodeList,
  fetchIssuerTransactionCodeList,
  fetchTransactionBatch,
  createTransactionBatch,
  createTransactionBatchCSV,
  fetchAssigner,
  addAssigner,
  addGMCAssigner,
  editTransactionBatch,
  fetchTransactionCodeListAll,
  addWhiteLabelAssigner,
  fetchGmcTransactionCodeBatchList,
  getAllGMCCodesbybatch,
  fetchTransactionCodeAssignableCountForOthers,
  fetchTransactionCodeAssignableCountForGMC,
  fetchTransactionQrCodesListByMerchantId,
  fetchOwnTransactionCode,
  deleteTransactionCodeById,
  deleteTransactionCodeBatch,
  deleteTransactionCode,
  downloadBatchTransactionCode,
  fetchOneTransactionQrCodePreview,
  fetchOneTransactionCodeBatch,
  downloadPreBatchTransactionCode,
} from '../../api/transactionCodes'

import {
  deleteIssuerCodeById,
  fetchAreaIssuerQrCodesListByMerchantId,
  fetchCountryIssuerQrCodesListByMerchantId,
  fetchIssuerCodeListAll,
  fetchIssuerCodeListByPanel,
  fetchIssuerQrCodesListByMerchantId,
  fetchOwnIssuerCode,
  fetchRegionIssuerQrCodesListByMerchantId,
  fetchStateIssuerQrCodesListByMerchantId,
} from '../../api/issuerCodes'

import _ from 'lodash'
import {
  assignPremiumMemberCode,
  assignPremiumMerchantCode,
} from 'api/premiumCodes'

export const getSiteCoordinator = (state) => state.siteCoordinator

export function* fetchTransactionQrCodesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTransactionCodeListByPanel(payload))
    yield put(fetchTransactionQrCodesListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTransactionQrCodesListFailure(err))
  }
}

export function* fetchTransactionQrCodesListWithoutFilterAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchTransactionCodeListAll(payload))
    yield put(fetchTransactionQrCodesListWithoutFilterSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTransactionQrCodesListWithoutFilterFailure(err))
  }
}

export function* fetchIssuerQrCodesListWithoutFilterAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchIssuerCodeListAll(payload))
    yield put(fetchIssuerQrCodesListWithoutFilterSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerQrCodesListWithoutFilterFailure(err))
  }
}

export function* fetchTransactionQrCodesAllAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTransactionCodeList(payload))
    yield put(fetchTransactionQrCodesListAllSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTransactionQrCodesListAllFailure(err))
  }
}

export function* fetchAssignerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAssigner(payload?.params))
    yield put(fetchAssignerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAssignerFailure(err))
  }
}

export function* createTransactionQrCodesAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)
    const { data } = yield call(createTransactionBatch(payload.params))
    yield put(createTransactionBatchSuccess(data))

    if (payload?.openPreparingModal) {
      yield payload?.openPreparingModal()
    }
    yield put(
      fetchTransactionBatchListStart({
        page: 1,
        limit: 10,
        countryId: siteCoordinator?.globalCountry?.id,
        offset: 0,
      })
    )

    // yield put(
    //   openAlert({
    //     message: 'Codes are being generated',
    //     severity: 'success',
    //   })
    // )

    // payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createTransactionBatchFailure(err))
  }
}

export function* editTransactionQrCodesAsync({ payload }: AnyAction) {
  try {
    let siteCoordinator = yield select(getSiteCoordinator)
    const { data } = yield call(
      editTransactionBatch(payload?.id, payload.params)
    )

    yield put(editTransactionBatchSuccess(data))

    yield put(
      fetchTransactionBatchListStart({
        page: 1,
        countryId: siteCoordinator?.globalCountry?.id,
        limit: 10,
        offset: 0,
      })
    )

    yield put(
      openAlert({
        message: 'Edited Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editTransactionBatchFailure(err))
  }
}

export function* createTransactionAssignerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(addAssigner(payload.params))
    yield put(createAssignerSuccess(data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchTransactionQrCodesListAllStart({
          page: 1,
          limit: 10,
          offset: 0,
          batchId: payload?.batchId,
          // batchId: 14,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Assigned Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createAssignerFailure(err))
  }
}

export function* createAssignerMerchantPremiumCodeAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(assignPremiumMerchantCode(payload.params))
    yield put(createAssignerMerchantPremiumCodeSuccess(data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchPremiumMerchantCodesGetAllStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Assigned Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createAssignerMerchantPremiumCodeFailure(err))
  }
}

export function* createAssignerMemberPremiumCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(assignPremiumMemberCode(payload.params))
    yield put(createAssignerMemberPremiumCodeSuccess(data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchPremiumMemberCodesGetAllStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
    yield put(
      openAlert({
        message: 'Assigned Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createAssignerMemberPremiumCodeFailure(err))
  }
}

export function* createWhiteLabelAssignerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(addWhiteLabelAssigner(payload.params))
    yield put(createWhiteLabelAssignerSuccess(data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchTransactionQrCodesListAllStart({
          page: 1,
          limit: 10,
          offset: 0,
          batchId: payload?.batchId,
          // batchId: 14,
        })
      )
    }

    yield put(
      openAlert({
        message: 'Assigned Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createWhiteLabelAssignerFailure(err))
  }
}

export function* createTransactionGMCAssignerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(addGMCAssigner(payload.params))
    yield put(createGMCAssignerSuccess(data))
    yield put(
      fetchTransactionQrCodesListAllStart({
        page: 1,
        limit: 10,
        offset: 0,
        batchId: payload?.params?.batchId,
        // batchId: 14,
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Assigned Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createGMCAssignerFailure(err))
  }
}

export function* createTransactionQrCodesCSVAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createTransactionBatchCSV(payload.params))
    yield put(createTransactionBatchCSVSuccess(data))

    yield put(
      fetchTransactionQrCodesListAllStart({
        page: 1,
        limit: 10,
        offset: 0,
        batchId: payload?.batchId,
      })
    )
    yield put(
      openAlert({
        message: 'Uploaded Successfully',
        severity: 'success',
      })
    )

    payload?.closeModal()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createTransactionBatchCSVFailure(err))
  }
}

export function* fetchTransactionBatchAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchTransactionBatch(payload))
    yield put(fetchTransactionBatchListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTransactionBatchListFailure(err))
  }
}

export function* fetchIssuerQrCodesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchIssuerCodeListByPanel(payload))

    yield put(fetchIssuerQrCodesListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerQrCodesListFailure(err))
  }
}

export function* fetchTransactionGmcBatchListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGmcTransactionCodeBatchList(payload))
    yield put(fetchTransactionGmcBatchListSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTransactionGmcBatchListFailure(err))
  }
}

export function* fetchTransactionCodeAssignableCountForOthersAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchTransactionCodeAssignableCountForOthers(payload)
    )
    yield put(fetchTransactionCodeAssignableCountForOthersSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTransactionCodeAssignableCountForOthersFailure(err))
  }
}

export function* fetchTransactionCodeAssignableCountForGMCAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchTransactionCodeAssignableCountForGMC(payload?.batchId)
    )
    yield put(fetchTransactionCodeAssignableCountForGMCSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTransactionCodeAssignableCountForGMCFailure(err))
  }
}

export function* fetchGmcCodesByBatchIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getAllGMCCodesbybatch(payload))
    yield put(fetchGmcCodesByBatchIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchGmcCodesByBatchIdFailure(err))
  }
}
export function* fetchIssuerQrCodesListByMerchantIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchIssuerQrCodesListByMerchantId(payload))
    yield put(fetchIssuerQrCodesListByMerchantIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchIssuerQrCodesListByMerchantIdFailure(err))
  }
}

export function* fetchStateIssuerQrCodesListByMerchantIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchStateIssuerQrCodesListByMerchantId(payload)
    )
    yield put(fetchStateIssuerQrCodesListByMerchantIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchStateIssuerQrCodesListByMerchantIdFailure(err))
  }
}

export function* fetchRegionIssuerQrCodesListByMerchantIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchRegionIssuerQrCodesListByMerchantId(payload)
    )
    yield put(fetchRegionIssuerQrCodesListByMerchantIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchRegionIssuerQrCodesListByMerchantIdFailure(err))
  }
}

export function* fetchAreaIssuerQrCodesListByMerchantIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchAreaIssuerQrCodesListByMerchantId(payload))
    yield put(fetchAreaIssuerQrCodesListByMerchantIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAreaIssuerQrCodesListByMerchantIdFailure(err))
  }
}

export function* fetchCountryIssuerQrCodesListByMerchantIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchCountryIssuerQrCodesListByMerchantId(payload)
    )
    yield put(fetchCountryIssuerQrCodesListByMerchantIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCountryIssuerQrCodesListByMerchantIdFailure(err))
  }
}

export function* fetchOwnIssuerCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchOwnIssuerCode(payload))
    yield put(fetchOwnIssuerCodeSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchOwnIssuerCodeFailure(err))
  }
}

export function* fetchTransactionQrCodesListByMerchantIdAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchTransactionQrCodesListByMerchantId(payload)
    )
    yield put(fetchTransactionQrCodesListByMerchantIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchTransactionQrCodesListByMerchantIdFailure(err))
  }
}

export function* fetchOwnTransactionCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchOwnTransactionCode(payload))
    yield put(fetchOwnTransactionCodeSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchOwnTransactionCodeFailure(err))
  }
}

export function* deleteTransactionCodeByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteTransactionCodeById(payload?.params?.id))
    yield put(deleteTransactionCodeByIdSuccess(data))
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.setSelectedMerchantObj) {
      const editedObj = {
        ...payload?.selectedMerchantObj,
        transactionCode: payload?.selectedMerchantObj?.transactionCode?.filter(
          (item) => item !== payload?.params?.id
        ),
      }
      payload?.setSelectedMerchantObj(editedObj)
    }
    yield put(
      openAlert({
        message: 'Transaction Code Deleted Successfully',
        severity: 'success',
      })
    )

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteTransactionCodeByIdFailure(err))
  }
}

export function* deleteIssuerCodeByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteIssuerCodeById(payload?.params?.id))
    yield put(deleteIssuerCodeByIdSuccess(data))

    if (payload?.setSelectedMerchantObj) {
      const editedObj = {
        ...payload?.selectedMerchantObj,
        issuerCode: payload?.selectedMerchantObj?.issuerCode?.filter(
          (item) => item !== payload?.params?.id
        ),
      }
      payload?.setSelectedMerchantObj(editedObj)
    }
    yield put(
      openAlert({
        message: 'Issuer Code Deleted Successfully',
        severity: 'success',
      })
    )

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteIssuerCodeByIdFailure(err))
  }
}

export function* deleteTransactionCodeBatchAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteTransactionCodeBatch(payload.id))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Transaction Code Batch Deleted Successfully',
        severity: 'success',
      })
    )

    if (payload?.closeModal) {
      payload?.closeModal()
    }

    yield put(deleteTransactionCodeBatchSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteTransactionCodeBatchFailure(err))
  }
}

export function* deleteTransactionCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteTransactionCode(payload.id))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Transaction Code Deleted Successfully',
        severity: 'success',
      })
    )

    if (payload?.closeModal) {
      payload?.closeModal()
    }

    yield put(deleteTransactionCodeSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteTransactionCodeFailure(err))
  }
}

function* repeat(payload) {
  yield delay(3000)
  const { data } = yield call(fetchOneTransactionCodeBatch(payload))

  if (
    data?.data?.zipFileDownloadStatus === 2 &&
    (data?.data?.zipFileName !== 'NULL' || data?.data?.zipFileName !== null)
  ) {
    yield call(downloadBatchTransactionCode(data?.data))
  } else {
    yield* repeat(payload)
  }
}

export function* downloadTransactionCodeBatchAsync({ payload }: AnyAction) {
  try {
    const responseData = yield call(fetchOneTransactionCodeBatch(payload?.id))

    if (
      responseData?.data?.data?.s3FilesUploaded &&
      responseData?.data?.data?.zipFileDownloadStatus === 1
    ) {
      yield put(
        openAlert({
          message: 'Downloadable File is Preparing',
          severity: 'warning',
        })
      )

      yield put(downloadTransactionCodeBatchSuccess('preparing'))
    } else {
      yield put(changeDownloadStatus('processing'))

      const { data } =
        responseData?.data?.data?.s3FilesUploaded &&
        responseData?.data?.data?.zipFileDownloadStatus === 0
          ? yield call(
              downloadPreBatchTransactionCode(responseData?.data?.data)
            )
          : yield call(downloadBatchTransactionCode(responseData?.data?.data))

      if (
        data &&
        responseData?.data?.data?.s3FilesUploaded &&
        responseData?.data?.data?.zipFileDownloadStatus === 0
      ) {
        yield repeat(payload?.id)
      }
      yield put(downloadTransactionCodeBatchSuccess('end'))
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    // yield put(
    //   openAlert({
    //     message: 'Batch Transaction Code Downloaded Successfully',
    //     severity: 'success',
    //   })
    // )

    if (payload?.closeModal) {
      payload?.closeModal()
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(downloadTransactionCodeBatchFailure(err))
  }
}

export function* fetchOneTransactionQrCodePreviewAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchOneTransactionQrCodePreview(payload))

    yield put(fetchOneTransactionQrCodePreviewSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchOneTransactionQrCodePreviewFailure(err))
  }
}

export function* watchCreateTransactionQrCodes() {
  yield takeLatest(
    'qrCodes/createTransactionBatchStart',
    createTransactionQrCodesAsync
  )
}
export function* watchEditTransactionQrCodes() {
  yield takeLatest(
    'qrCodes/editTransactionBatchStart',
    editTransactionQrCodesAsync
  )
}

export function* watchFetchAssigner() {
  yield takeLatest('qrCodes/fetchAssignerStart', fetchAssignerAsync)
}

export function* watchCreateTransactionQrCodesCSV() {
  yield takeLatest(
    'qrCodes/createTransactionBatchCSVStart',
    createTransactionQrCodesCSVAsync
  )
}
export function* watchCreateTransactionAssignerAsync() {
  yield takeLatest(
    'qrCodes/createAssignerStart',
    createTransactionAssignerAsync
  )
}

export function* watchCreateWhiteLabelAssignerAsync() {
  yield takeLatest(
    'qrCodes/createWhiteLabelAssignerStart',
    createWhiteLabelAssignerAsync
  )
}

export function* watchCreateTransactionGMCAssignerAsync() {
  yield takeLatest(
    'qrCodes/createGMCAssignerStart',
    createTransactionGMCAssignerAsync
  )
}

export function* watchFetchTransactionQrCodes() {
  yield takeLatest(
    'qrCodes/fetchTransactionQrCodesListStart',
    fetchTransactionQrCodesAsync
  )
}
export function* watchFetchTransactionQrCodesAll() {
  yield takeLatest(
    'qrCodes/fetchTransactionQrCodesListAllStart',
    fetchTransactionQrCodesAllAsync
  )
}

export function* watchFetchIssuerQrCodes() {
  yield takeLatest(
    'qrCodes/fetchIssuerQrCodesListStart',
    fetchIssuerQrCodesAsync
  )
}

export function* watchFetchTransactionBatch() {
  yield takeLatest(
    'qrCodes/fetchTransactionBatchListStart',
    fetchTransactionBatchAsync
  )
}

export function* watchFetchTransactionQrCodesListWithoutFilterAsync() {
  yield takeLatest(
    'qrCodes/fetchTransactionQrCodesListWithoutFilterStart',
    fetchTransactionQrCodesListWithoutFilterAsync
  )
}

export function* watchFetchIssuerQrCodesListWithoutFilterAsync() {
  yield takeLatest(
    'qrCodes/fetchIssuerQrCodesListWithoutFilterStart',
    fetchIssuerQrCodesListWithoutFilterAsync
  )
}

export function* watchFetchTransactionGmcBatchList() {
  yield takeLatest(
    'qrCodes/fetchTransactionGmcBatchListStart',
    fetchTransactionGmcBatchListAsync
  )
}

export function* watchFetchGmcCodesByBatchId() {
  yield takeLatest(
    'qrCodes/fetchGmcCodesByBatchIdStart',
    fetchGmcCodesByBatchIdAsync
  )
}

export function* watchCreateAssignerMemberPremiumCode() {
  yield takeLatest(
    'qrCodes/createAssignerMemberPremiumCodeStart',
    createAssignerMemberPremiumCodeAsync
  )
}

export function* watchCreateAssignerMerchantPremiumCode() {
  yield takeLatest(
    'qrCodes/createAssignerMerchantPremiumCodeStart',
    createAssignerMerchantPremiumCodeAsync
  )
}

export function* watchFetchTransactionCodeAssignableCountForOthers() {
  yield takeLatest(
    'qrCodes/fetchTransactionCodeAssignableCountForOthersStart',
    fetchTransactionCodeAssignableCountForOthersAsync
  )
}

export function* watchFetchTransactionCodeAssignableCountForGMC() {
  yield takeLatest(
    'qrCodes/fetchTransactionCodeAssignableCountForGMCStart',
    fetchTransactionCodeAssignableCountForGMCAsync
  )
}

export function* watchFetchIssuerQrCodesListByMerchantIdAsync() {
  yield takeLatest(
    'qrCodes/fetchIssuerQrCodesListByMerchantIdStart',
    fetchIssuerQrCodesListByMerchantIdAsync
  )
}

export function* watchFetchStateIssuerQrCodesListByMerchantIdAsync() {
  yield takeLatest(
    'qrCodes/fetchStateIssuerQrCodesListByMerchantIdStart',
    fetchStateIssuerQrCodesListByMerchantIdAsync
  )
}

export function* watchFetchRegionIssuerQrCodesListByMerchantIdAsync() {
  yield takeLatest(
    'qrCodes/fetchRegionIssuerQrCodesListByMerchantIdStart',
    fetchRegionIssuerQrCodesListByMerchantIdAsync
  )
}

export function* watchFetchAreaIssuerQrCodesListByMerchantIdAsync() {
  yield takeLatest(
    'qrCodes/fetchAreaIssuerQrCodesListByMerchantIdStart',
    fetchAreaIssuerQrCodesListByMerchantIdAsync
  )
}

export function* watchFetchCountryIssuerQrCodesListByMerchantIdAsync() {
  yield takeLatest(
    'qrCodes/fetchCountryIssuerQrCodesListByMerchantIdStart',
    fetchCountryIssuerQrCodesListByMerchantIdAsync
  )
}

export function* watchFetchTransactionQrCodesListByMerchantIdAsync() {
  yield takeLatest(
    'qrCodes/fetchTransactionQrCodesListByMerchantIdStart',
    fetchTransactionQrCodesListByMerchantIdAsync
  )
}

export function* watchFetchOwnIssuerCodeAsync() {
  yield takeLatest('qrCodes/fetchOwnIssuerCodeStart', fetchOwnIssuerCodeAsync)
}

export function* watchFetchOwnTransactionCodeAsync() {
  yield takeLatest(
    'qrCodes/fetchOwnTransactionCodeStart',
    fetchOwnTransactionCodeAsync
  )
}

export function* watchDeleteTransactionCodeByIdAsync() {
  yield takeLatest(
    'qrCodes/deleteTransactionCodeByIdStart',
    deleteTransactionCodeByIdAsync
  )
}

export function* watchDeleteIssuerCodeByIdAsync() {
  yield takeLatest(
    'qrCodes/deleteIssuerCodeByIdStart',
    deleteIssuerCodeByIdAsync
  )
}

export function* watchDeleteTransactionCodeBatchAsync() {
  yield takeLatest(
    'qrCodes/deleteTransactionCodeBatchStart',
    deleteTransactionCodeBatchAsync
  )
}

export function* watchDeleteTransactionCodeAsync() {
  yield takeLatest(
    'qrCodes/deleteTransactionCodeStart',
    deleteTransactionCodeAsync
  )
}

export function* watchDownloadTransactionCodeBatchAsync() {
  yield takeLatest(
    'qrCodes/downloadTransactionCodeBatchStart',
    downloadTransactionCodeBatchAsync
  )
}

export function* watchFetchOneTransactionQrCodePreviewAsync() {
  yield takeLatest(
    'qrCodes/fetchOneTransactionQrCodePreviewStart',
    fetchOneTransactionQrCodePreviewAsync
  )
}

export function* qrCodesSagas() {
  yield all([
    call(watchFetchAssigner),
    call(watchCreateAssignerMemberPremiumCode),
    call(watchCreateAssignerMerchantPremiumCode),
    call(watchEditTransactionQrCodes),
    call(watchCreateTransactionAssignerAsync),
    call(watchFetchTransactionQrCodesAll),
    call(watchCreateTransactionQrCodesCSV),
    call(watchCreateTransactionQrCodes),
    call(watchFetchTransactionQrCodes),
    call(watchFetchIssuerQrCodes),
    call(watchFetchTransactionBatch),
    call(watchCreateTransactionGMCAssignerAsync),
    call(watchFetchTransactionQrCodesListWithoutFilterAsync),
    call(watchFetchIssuerQrCodesListWithoutFilterAsync),
    call(watchCreateWhiteLabelAssignerAsync),
    call(watchFetchTransactionGmcBatchList),
    call(watchFetchGmcCodesByBatchId),
    call(watchFetchTransactionCodeAssignableCountForOthers),
    call(watchFetchTransactionCodeAssignableCountForGMC),
    call(watchFetchIssuerQrCodesListByMerchantIdAsync),
    call(watchFetchTransactionQrCodesListByMerchantIdAsync),
    call(watchFetchOwnIssuerCodeAsync),
    call(watchFetchOwnTransactionCodeAsync),
    call(watchDeleteIssuerCodeByIdAsync),
    call(watchDeleteTransactionCodeByIdAsync),
    call(watchFetchStateIssuerQrCodesListByMerchantIdAsync),
    call(watchFetchRegionIssuerQrCodesListByMerchantIdAsync),
    call(watchFetchAreaIssuerQrCodesListByMerchantIdAsync),
    call(watchFetchCountryIssuerQrCodesListByMerchantIdAsync),
    call(watchDeleteTransactionCodeBatchAsync),
    call(watchDeleteTransactionCodeAsync),
    call(watchDownloadTransactionCodeBatchAsync),
    call(watchFetchOneTransactionQrCodePreviewAsync),
  ])
}
