import { axiosRequestCMS } from 'utils'

export const fetchSectionList = (params) => {
  return () =>
    axiosRequestCMS
      .get(`/api/section/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSectionById = (id) => {
  return () =>
    axiosRequestCMS
      .get(`/api/section/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createSection = (params) => {
  return () =>
    axiosRequestCMS
      .post(`/api/section/create`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteSection = (id) => {
  return () =>
    axiosRequestCMS
      .delete(`/api/section/delete/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editSection = (id, params) => {
  return () =>
    axiosRequestCMS
      .put(`/api/section/edit/${id}?isTranslateToAlllang=${params.isTranslateToAlllang}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
