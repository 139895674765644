import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
// import DraftList from 'components/GlobalMerchantForm/DraftList/DraftList.component'

import SendMerchantRequest from 'pages/GlobalAdminWithCountry/Merchants/MerchantList/components/AddNewMerchant/SendMerchantRequest.component'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'

// const AddCountryAdminMerchant = React.lazy(
//   () => import('./Merchants/MerchantList/components/AddNewMerchant')
// )

const MerchantList = React.lazy(
  () => import('./Merchants/MerchantList/MerchantList.component')
)

const AddGlobalAdminMerchant = React.lazy(
  () => import('./Merchants/MerchantList/components/AddNewMerchant')
)

const EditGlobalAdminMerchant = React.lazy(
  () => import('./Merchants/MerchantList/components/EditMerchantRequest')
)

const AreaOwnersList = React.lazy(() => import('./AreaOwners/AreaOwner'))
const StateOwnersList = React.lazy(() => import('./StateOwners/StateOwner'))
const RegionOwnersList = React.lazy(() => import('./RegionOwners/RegionOwner'))
const CollaborationPartnersList = React.lazy(
  () => import('./CollaborationPartners/CollaborationPartnersList')
)
const SalesAgentList = React.lazy(() => import('./SalesAgents/SalesAgentList'))
const CharitiesList = React.lazy(() => import('./Charities/CharitiesList'))
const ClubList = React.lazy(() => import('./ClubList'))
const MembersList = React.lazy(() => import('./Members/MembersList'))
const MerchantCodes = React.lazy(() => import('./PremiumCodes/MerchantCodes'))
const MemberCodes = React.lazy(() => import('./PremiumCodes/MemberCodes'))
const WhiteLabelList = React.lazy(
  () => import('./WhiteLabelSetup/WhiteLabelSetup')
)
const WhiteLabelLevelsSetup = React.lazy(
  () => import('./WhiteLabelSetup/WhiteLabelLevelsSetup')
)
const UserList = React.lazy(() => import('./UsersList/UserList'))

const TransactionBatch = React.lazy(() => import('./QrCodes/TransactionQrCode'))

const IssuerQrBatch = React.lazy(() => import('./QrCodes/IssuerQrCode'))

const TransactionQrCodes = React.lazy(
  () => import('./QrCodes/TransactionQrCode/QrCodes')
)
const TransactionQrCodesAll = React.lazy(
  () => import('./QrCodes/TransactionQrCode/components/SearchCodeView')
)

const IssuerQrCodes = React.lazy(() => import('./QrCodes/IssuerQrCode/QrCodes'))
const IssuerQrCodesAll = React.lazy(
  () => import('./QrCodes/IssuerQrCode/components/SearchCodeView')
)

// to delete later
const Temp = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)

// friendship
const FriendshipEarningAsPiiinkIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsPiiinkIssuer')
)
const FriendshipEarningAsPiiinkIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsPiiinkIssuer')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)
const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)
const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)
const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)
const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)
const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)

const FriendshipEarningAsCpSignerAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgnerAsSigner')
)

const FriendshipEarningAsPiiinkCharity = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsPiiinkCharity')
)
const FriendshipEarningAsPiiinkCharityDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsPiiinkCharity')
)
const FriendshipEarningAsCharity = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCharity')
)

const FriendshipEarningAsCharityDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCharity')
)

const FriendshipEarningAsAreaOwner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsAreaOwner')
)
const FriendshipEarningAsAreaOwnerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsAreaOwner')
)

const FriendshipEarningAsRegionOwner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsRegionOwner')
)
const FriendshipEarningAsRegionOwnerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsRegionOwner')
)

const FriendshipEarningAsStateOwner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsStateOwner')
)
const FriendshipEarningAsStateOwnerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsStateOwner')
)

const FriendshipEarningFromCountryPool = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/FromCountryPool')
)

const FriendshipEarningFromCountryPoolDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/FromCountryPool')
)

const FriendshipEarningAsPiiinkRegion = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsPiiinkRegion')
)
const FriendshipEarningAsPiiinkRegionDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsPiiinkRegion')
)
const FriendshipEarningAsPiiinkArea = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsPiiinkArea')
)
const FriendshipEarningAsPiiinkAreaDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsPiiinkArea')
)

// membership
const MembershipEarningAsPiiinkIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsPiiinkIssuer')
)
const MembershipEarningAsPiiinkIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsPiiinkIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const MembershipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsCpSigner')
)
const MembershipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsCpSigner')
)
const MembershipEarningAsClubCharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsClubCharitySigner')
)
const MembershipEarningAsClubCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsClubCharitySigner')
)

const MembershipEarningAsPiiinkArea = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsPiiinkArea')
)
const MembershipEarningAsPiiinkAreaDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsPiiinkArea')
)
const MembershipEarningAsPiiinkRegion = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsPiiinkRegion')
)
const MembershipEarningAsPiiinkRegionDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsPiiinkRegion')
)
const MembershipEarningAsPiiinkcharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsPiiinkCharity')
)
const MembershipEarningAsPiiinkCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsPiiinkCharity')
)

const Reviews = React.lazy(() => import('./Reviews'))
const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))

const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const MembershipEarningAsCharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsCharity')
)

const MembershipEarningAsCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsCharity')
)

const MembershipEarningAsAreaOwner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromAreaAllocation')
)

const MembershipEarningAsAreaOwnerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromAreaAllocation')
)

const MembershipEarningAsRegionOwner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromRegionAllocation')
)

const MembershipEarningAsRegionOwnerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromRegionAllocation')
)

const MembershipEarningAsStateOwner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromStateAllocation')
)

const MembershipEarningAsStateOwnerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromStateAllocation')
)

const MembershipEarningAsCountryOwner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromCountryPool')
)

const MembershipEarningAsCountryOwnerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromCountryPool')
)

const FriendshipReport = React.lazy(() => import('./Reports/FriendshipReport'))
const MembershipReport = React.lazy(() => import('./Reports/MembershipReport'))
const WLMerchantSignerReport = React.lazy(
  () => import('./Reports/WLMerchantSignerReport')
)

const WLIssuerReport = React.lazy(
  () => import('./Reports/WLFriendshipIssuerReport')
)
const CpSignerIssuerFriendshipReport = React.lazy(
  () => import('./Reports/CpSignerIssuerFriendshipReport')
)

const CpMerchantSignerFriendshipReport = React.lazy(
  () => import('./Reports/CpMerchantSignerFriendshipReport')
)

const CpSignerIssuerMembershipReport = React.lazy(
  () => import('./Reports/CpSignerIssuerMembershipReport')
)

const WLIssuerMembershipReport = React.lazy(
  () => import('./Reports/WLIssuerMembershipReport')
)

export const globalAdminWithinCountryConfig = [
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/areaownerlist',
    element: <AreaOwnersList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/stateownerlist',
    element: <StateOwnersList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/regionownerlist',
    element: <RegionOwnersList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/country/collaborationpartnerlist',
    element: <CollaborationPartnersList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/salesAgentList',
    element: <SalesAgentList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/charitieslist',
    element: <CharitiesList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/clublist',
    element: <ClubList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/memberslist',
    element: <MembersList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/premium-merchant-codes',
    element: <MerchantCodes />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/premium-member-codes',
    element: <MemberCodes />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'globalAdmin'} />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'globalAdmin'} />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/merchantList/editMerchant/:id',
    element: <EditGlobalAdminMerchant adminRoute={'globalAdmin'} />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'globalAdmin'} />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/merchantList',
    element: <MerchantList adminRoute={'globalAdmin'} />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/addMerchant',
    element: <AddGlobalAdminMerchant />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/addMerchant/:id',
    element: <AddGlobalAdminMerchant />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/merchantList',
    element: <MerchantList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setup/whiteLabels',
    element: <WhiteLabelList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/setup/whiteLabelLevelsSetup/:id',
    element: <WhiteLabelLevelsSetup />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/whitelabelusers',
    element: <UserList />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/transaction-qr-codes',
    element: <TransactionBatch />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/transaction-qr-codes/:id',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/transaction-qr-codes/all',
    element: <TransactionQrCodesAll />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/issuer-qr-codes',
    element: <IssuerQrBatch />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/issuer-qr-codes/:id',
    element: <IssuerQrCodes />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/issuer-qr-codes/all',
    element: <IssuerQrCodesAll />,
  },
  // friendship ===========================================================================
  // aspiiinkissuer
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/aspiiinkissuer',
    element: <FriendshipEarningAsPiiinkIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/aspiiinkissuer/detail',
    element: <FriendshipEarningAsPiiinkIssuerDetail />,
  },

  // aspiiinkcharity
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/aspiiinkcharity',
    element: <FriendshipEarningAsPiiinkCharity />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/aspiiinkcharity/detail',
    element: <FriendshipEarningAsPiiinkCharityDetail />,
  },

  // aspiiinkregion
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/aspiiinkregion',
    element: <FriendshipEarningAsPiiinkRegion />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/aspiiinkregion/detail',
    element: <FriendshipEarningAsPiiinkRegionDetail />,
  },

  // aspiiinkarea
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/aspiiinkarea',
    element: <FriendshipEarningAsPiiinkArea />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/aspiiinkarea/detail',
    element: <FriendshipEarningAsPiiinkAreaDetail />,
  },

  // asissuer
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },

  // asmerchantsigner
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },

  // ascpsigner
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/ascpsigner/detail',
    element: <FriendshipEarningAsCpSignerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/ascpsigner/asmerchantsigner/detail',
    element: <FriendshipEarningAsCpSignerAsMerchantSignerDetail />,
  },

  // membership ===========================================================================
  // aspiiinkissuer
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/aspiiinkissuer',
    element: <MembershipEarningAsPiiinkIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/aspiiinkissuer/detail',
    element: <MembershipEarningAsPiiinkIssuerDetail />,
  },

  // aspiiinkcharity
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/aspiiinkcharity',
    element: <MembershipEarningAsPiiinkcharity />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/aspiiinkcharity/detail',

    element: <MembershipEarningAsPiiinkCharityDetail />,
  },

  // aspiiinkregion
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/aspiiinkregion',
    element: <MembershipEarningAsPiiinkRegion />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/aspiiinkregion/detail',
    element: <MembershipEarningAsPiiinkRegionDetail />,
  },

  // aspiiinkarea
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/aspiiinkarea',
    element: <MembershipEarningAsPiiinkArea />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/aspiiinkarea/detail',
    element: <MembershipEarningAsPiiinkAreaDetail />,
  },

  // asissuer
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },

  // ascpsigner
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/ascpsigner',
    element: <MembershipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/ascpsigner/detail',
    element: <MembershipEarningAsCpSignerDetail />,
  },

  // asclubcharity
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asclubcharity',
    element: <MembershipEarningAsClubCharity />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asclubcharity/detail',
    element: <MembershipEarningAsClubCharityDetail />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/reviews',
    element: <Reviews />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/coupons',
    element: <Coupons adminRoute={'globaladmin'} />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/coupon-codes/:id',
    element: <CouponCodes />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'globaladmin'} />,
  },

  // ascharity
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/ascharity',
    element: <FriendshipEarningAsCharity />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/ascharity/detail',
    element: <FriendshipEarningAsCharityDetail />,
  },

  // asAreaOwner
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asareaowner',
    element: <FriendshipEarningAsAreaOwner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asareaowner/detail',
    element: <FriendshipEarningAsAreaOwnerDetail />,
  },

  // region owner
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asregionowner',
    element: <FriendshipEarningAsRegionOwner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asregionowner/detail',
    element: <FriendshipEarningAsRegionOwnerDetail />,
  },

  //state owner

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asstateowner',
    element: <FriendshipEarningAsStateOwner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/asstateowner/detail',
    element: <FriendshipEarningAsStateOwnerDetail />,
  },

  //As country owner

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/ascountryowner',
    element: <FriendshipEarningFromCountryPool />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/friendship-earning/country/ascountryowner/detail',
    element: <FriendshipEarningFromCountryPoolDetail />,
  },

  //membership

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/ascharity',
    element: <MembershipEarningAsCharity />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/ascharity/detail',
    element: <MembershipEarningAsCharityDetail />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asareaowner',
    element: <MembershipEarningAsAreaOwner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asareaowner/detail',
    element: <MembershipEarningAsAreaOwnerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asregionowner',
    element: <MembershipEarningAsRegionOwner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asregionowner/detail',
    element: <MembershipEarningAsRegionOwnerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asstateowner',
    element: <MembershipEarningAsStateOwner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/asstateowner/detail',
    element: <MembershipEarningAsStateOwnerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/ascountryowner',
    element: <MembershipEarningAsCountryOwner />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/membership-earning/country/ascountryowner/detail',
    element: <MembershipEarningAsCountryOwnerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/reports/friendship-report',
    element: <FriendshipReport />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/reports/membership-report',
    element: <MembershipReport />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/reports/wl-Friendship-issuer-report',
    element: <WLIssuerReport />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/reports/wl-merchant-signer-report',
    element: <WLMerchantSignerReport />,
  },
  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/reports/cp-signer-issuer-friendship-report',
    element: <CpSignerIssuerFriendshipReport />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/reports/cp-merchant-signer-friendship-report',
    element: <CpMerchantSignerFriendshipReport />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/reports/cp-signer-issuer-membership-report',
    element: <CpSignerIssuerMembershipReport />,
  },

  {
    permittedRole: RoutePermittedRole.globalAdmin,
    path: '/globaladmin/reports/wl-issuer-membership-report',
    element: <WLIssuerMembershipReport />,
  },
]
