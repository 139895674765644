import React, { useState } from 'react'
import AppCard from '@crema/core/AppCard'
import { Box, Grid, Stack, TextField, Button } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import AppTextField from '@crema/core/AppFormComponents/AppTextField'
import { removeEmptyFields } from 'utils'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment-timezone'
import SendEmailModal from 'components/SendEmailModal'
import ModalRenderer from 'components/ReusableModalComponents/ModalRenderer'
import CountrySelect from 'components/DropdownComponents/CountrySelect'
import StatusSelect from 'components/DropdownComponents/StatusSelect'
import GenerateTemporaryPasswordDialog from '../GenerateTemporaryPassword'

const ListTop = ({
  setFilterFormData,
  clearFilterForm,
  refreshPageData,
  currentUser,
  ...props
}) => {
  const [isSendEmailOpen, setIsSendEmailOpen] = useState<boolean>(false)
  const [
    isGenerateTemporaryPasswordDialogOpen,
    setIsGenerateTemporaryPasswordDialogOpen,
  ] = useState<boolean>(false)

  const onOpenSendEmail = () => {
    setIsSendEmailOpen(true)
  }

  const onCloseSendEmail = () => {
    setIsSendEmailOpen(false)
  }

  const onCloseAddPage = () => {
    setIsGenerateTemporaryPasswordDialogOpen(false)
  }

  const onDeleteSelectedContacts = () => {}

  const validationSchema = yup.object({})

  const onSubmitSearch = (data) => {
    setFilterFormData(
      removeEmptyFields({
        ...data,
        countryId: data?.countryId?.id,
        userType: data?.userType?.value,
        createdByType: data?.createdByType?.name,
      })
    )
  }

  const roleOptions = [
    { label: 'State Licensee', value: 'stateOwner' },
    { label: 'Region Licensee', value: 'regionOwner' },
    { label: 'Area Licensee', value: 'areaOwner' },
    { label: 'Collaboration Partner', value: 'collaborationPartner' },
    { label: 'Sales Agent', value: 'salesAgent' },
    { label: 'Charity', value: 'charity' },
    { label: 'Club', value: 'club' },

    { label: 'White Label User', value: 'whiteLabelUser' },
    { label: 'Merchant', value: 'merchant' },
  ]

  const signerRoleOption: any =
    currentUser?.role?.toLowerCase() === 'countryowner'
      ? [
          { label: 'State Licensee', value: 'stateOwner' },
          { label: 'Region Licensee', value: 'regionOwner' },
          { label: 'Area Licensee', value: 'areaOwner' },
          { label: 'Collaboration Partner', value: 'collaborationPartner' },
          { label: 'Sales Agent', value: 'salesAgent' },
          // { label: 'Merchant', value: 'merchant' },
          { label: 'Charity', value: 'charity' },
          { label: 'Club', value: 'club' },
          { label: 'White Label User', value: 'whiteLabelUser' },

          // { label: 'member', value: 'member' },
          // { label: 'globalAdmin', value: 'globalAdmin' },
          { label: 'Merchant', value: 'merchant' },
        ]
      : [
          { label: 'Global Admin', name: 'globalAdmin' },
          { label: 'Country Licensee', name: 'countryOwner' },
          // { label: 'State Licensee', name: 'stateOwner' },
          // { label: 'Region Licensee', name: 'regionOwner' },
          // { label: 'Area Licensee', name: 'areaOwner' },
          // { label: 'Charity', name: 'charity' },
          // { label: 'Club', name: 'club' },
          // { label: 'Sales Agents', name: 'salesAgent' },
          // { label: 'Collaboration Partners', name: 'collaborationPartner' },
          // { label: 'White Label Users', name: 'whiteLabelUser' },
        ]

  return (
    <>
      <Grid container spacing={4} justifyContent="flex-end">
        <Grid item>
          <Button
            onClick={() => {
              setIsGenerateTemporaryPasswordDialogOpen(true)
            }}
            sx={{
              color: '#ffffff',
              display: 'flex',
              marginTop: '-1rem',
              marginLeft: 'auto',
              background: '#00bfcd',
              '&:hover': {
                background: '#ec4785',
              },
              px: 5,
              mb: 5,
            }}
          >
            Generate Temporary Password
          </Button>
        </Grid>
      </Grid>

      <AppCard sx={{ padding: '1rem 0' }}>
        <Box>
          <Formik
            validateOnChange={true}
            initialValues={{
              countryId: null,
              userType: null,
              createdByType: null,
              username: '',
              email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)
              onSubmitSearch(data)
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, resetForm, setFieldValue, values }) => (
              <Form>
                <Grid container spacing={3}>
                  {currentUser?.role?.toLowerCase() !== 'countryowner' && (
                    <Grid item xs={12} lg={2}>
                      <Field
                        name="countryId"
                        label="Select Country"
                        component={CountrySelect}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} lg={2}>
                    <Field
                      name="userType"
                      component={StatusSelect}
                      statusOption={roleOptions}
                      label="Select Role"
                    />
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      label="Email"
                      name="email"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <AppTextField
                      label="Username"
                      name="username"
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                          fontSize: 14,
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Field
                      name="createdByType"
                      component={StatusSelect}
                      statusOption={signerRoleOption}
                      label="Creator Role"
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      size="small"
                      sx={{
                        px: 5,
                        color: '#ffffff',
                        backgroundColor: '#ec4785',
                        '&:hover': {
                          background: '#ec4785',
                        },
                      }}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      sx={{
                        px: 5,
                      }}
                      variant="outlined"
                      type="submit"
                      onClick={() => {
                        clearFilterForm()
                        resetForm()
                      }}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {/* 
          {isSendEmailOpen && (
            <ModalRenderer
              open={isSendEmailOpen}
              onDeny={onCloseSendEmail}
              onConfirm={onDeleteSelectedContacts}
              title="Are you sure you want to delete this country?"
              dialogTitle="Send Email"
            >
              <SendEmailModal
                isModalOpen={isSendEmailOpen}
                onCloseModal={() => setIsSendEmailOpen(false)}
                refreshPageData={refreshPageData}
              />
            </ModalRenderer>
          )} */}

          {isGenerateTemporaryPasswordDialogOpen && (
            <GenerateTemporaryPasswordDialog
              isAddPageOpen={isGenerateTemporaryPasswordDialogOpen}
              onCloseAddPage={onCloseAddPage}
              refreshPageData={refreshPageData}
              codeType=""
            />
          )}
        </Box>
      </AppCard>
      <Box sx={{ height: 30 }} />
    </>
  )
}

export default ListTop
