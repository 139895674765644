import React from 'react'
import { RoutePermittedRole } from 'shared/constants/AppConst'
import DraftList from 'components/GlobalMerchantForm/DraftList/DraftList.component'
import EditMerchantRequest from 'components/GlobalMerchantForm/DraftList/components/EditMerchantRequest/EditMerchantRequest.component'
import MerchantList from 'components/GlobalMerchantForm/MerchantList'
import SendMerchantEmail from 'components/GlobalMerchantForm/SendMerchantEmail'
import SendMerchantRequest from 'components/GlobalMerchantForm/SendMerchantRequest'
import ReferredMerchantList from 'components/GlobalMerchantForm/ReferredMerchantList'
import AllNotificationList from 'components/AllNotificationList'
import ConnectStripe from 'components/Payments/ConnectStripe'

const UsersList = React.lazy(() => import('./UsersList'))
const ReferredCPList = React.lazy(
  () => import('./CollaborationPartners/ReferredCPList')
)
const CharitiesList = React.lazy(() => import('./Charities/CharitiesList'))
const ClubList = React.lazy(() => import('./Club/ClubList'))
const MembersList = React.lazy(() => import('./Members/MembersList'))
const WhiteLabelMembersList = React.lazy(
  () => import('./Members/WhiteLabelMembersList')
)

const TransactionQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/TransactionQrCode/QrCodes')
)

const IssuerQrCodes = React.lazy(
  () => import('components/GlobalQrCodes/IssuerQrCode')
)

const FriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsIssuer')
)
const FriendshipEarningFromIssuerBranchOfWL = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/fromIssuerBranchs')
)

const CountrySpecificFriendshipEarningAsIssuer = React.lazy(
  () => import('./Earning/WLSpecificFriendship/SummaryPages/AsIssuer')
)

const FriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsIssuer')
)

const FriendshipFromIssuerBranchDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/fromIssuerBranchs')
)

const CountrySpecificFriendshipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/WLSpecificFriendship/DetailsPages/AsIssuer')
)

const MembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsIssuer')
)
const MembershipEarningFromIssuerBranchOfWL = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromIssuerBranch')
)

const MembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsIssuer')
)

const MembershipEarningFromIssuerBranchOfWLDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromIssuerBranch')
)

const CountrySpecificMembershipEarningAsIssuer = React.lazy(
  () => import('./Earning/WLSpecificMembership/SummaryPages/AsIssuer')
)

const CountrySpecificMembershipEarningAsIssuerDetail = React.lazy(
  () => import('./Earning/WLSpecificMembership/DetailsPages/AsIssuer')
)

const FriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsMerchantSigner')
)

const FriendshipEarningFromMerchantSignerBranchOfWL = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/fromMerchantSignerBranchs')
)

const FriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsMerchantSigner')
)

const FriendshipEarningFromMerchantSignerBranchDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/fromMerchantSignerBranchs')
)

const CountrySpecificFriendshipEarningAsMerchantSignerDetail = React.lazy(
  () => import('./Earning/WLSpecificFriendship/DetailsPages/AsMerchantSigner')
)

const CountrySpecificFriendshipEarningAsMerchantSigner = React.lazy(
  () => import('./Earning/WLSpecificFriendship/SummaryPages/AsMerchantSigner')
)

const FriendshipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/AsCpSigner')
)
const FriendshipEarningAsCpSignerFromIssuerBranchOfWL = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/fromCpSignerBranchs')
)
const FriendshipEarningAsCpSignerAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const FriendshipEarningFromCpSignerBranchAsIssuerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/fromCpSignerBranchs')
)

const FriendshipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSIgner')
)
const FriendshipEarningAsCpSignerAsSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/AsCpSignerAsSigner')
)

const FriendshipEarningFromCpSignerBranchAsSignerDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/fromCpSignerBranchAsSigners')
)

// CountrySpecificFriendshipEarningAsCpSignerSummary
const CountrySpecificFriendshipEarningAsCpSignerSummary = React.lazy(
  () => import('./Earning/WLSpecificFriendship/SummaryPages/AsCpSigner')
)

const CountrySpecificFriendshipEarningAsCpSignerAsIssuerDetail = React.lazy(
  () => import('./Earning/WLSpecificFriendship/DetailsPages/AsCpSIgner')
)

const CountrySpecificFriendshipEarningAsCpSignerAsSignerDetail = React.lazy(
  () => import('./Earning/WLSpecificFriendship/DetailsPages/AsCpSignerAsSigner')
)

const MembershipEarningAsCpSigner = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsCpSigner')
)

const MembershipEarningFromCpSignerBranchOfWL = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromCpSignerBranch')
)

const MembershipEarningFromCpSignerBranchOfWLDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromCpSignerBranch')
)

const MembershipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsCpSigners')
)

const CountrySpecificMembershipEarningAsCpSigner = React.lazy(
  () => import('./Earning/WLSpecificMembership/SummaryPages/AsCpSigner')
)
const CountrySpecificMembershipEarningAsCpSignerDetail = React.lazy(
  () => import('./Earning/WLSpecificMembership/DetailsPages/AsCpSIgner')
)

const FriendshipEarningFromCountryPool = React.lazy(
  () => import('./Earning/Friendship/SummaryPages/FromCountryPool')
)

const FriendshipEarningFromCountryPoolDetail = React.lazy(
  () => import('./Earning/Friendship/DetailsPages/FromCountryPool')
)

const MembershipEarningFromCountryPool = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromCountryPool')
)

const MembershipEarningFromCountryPoolDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromCountryPool')
)

const MembershipEarningFromRegionAllocation = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromRegionAllocation')
)
const MembershipEarningFromRegionAllocationDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromRegionAllocation')
)
const MembershipEarningAsClubCharity = React.lazy(
  () => import('./Earning/Membership/SummaryPages/AsClubCharitySigner')
)

const MembershipEarningFromClubCharityBranchOfWL = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromClubCharitySignerBranch')
)

const MembershipEarningAsClubCharityDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/AsClubCharitySigner')
)
const MembershipEarningFromClubCharityBranchDetail = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromClubCharitySignerBranch')
)

const CountrySpecificMembershipEarningAsClubCharity = React.lazy(
  () =>
    import('./Earning/WLSpecificMembership/SummaryPages/AsClubCharitySigner')
)
const CountrySpecificMembershipEarningAsClubCharityDetail = React.lazy(
  () =>
    import('./Earning/WLSpecificMembership/DetailsPages/AsClubCharitySigner')
)

const CountrySpecificFriendshipEarningTotalTransactions = React.lazy(
  () => import('./Earning/WLSpecificFriendship/SummaryPages/TotalTransactions')
)

const CountrySpecificFriendshipEarningTotalTransactionsDetail = React.lazy(
  () => import('./Earning/WLSpecificFriendship/DetailsPages/TotalTransactions')
)

const CountrySpecificMembershipEarningTotalTransactions = React.lazy(
  () => import('./Earning/WLSpecificMembership/SummaryPages/TotalTransactions')
)

const CountrySpecificMembershipEarningTotalTransactionsDetail = React.lazy(
  () => import('./Earning/WLSpecificMembership/DetailsPages/TotalTransactions')
)
const MerchantPackageEarning = React.lazy(
  () => import('./MerchantPackage/Earning')
)
const MerchantCodesList = React.lazy(
  () => import('components/PremiumCodes/MerchantCodesList')
)
const MemberCodesList = React.lazy(
  () => import('components/PremiumCodes/MemberCodesList')
)

const FromMemberPremiumCode = React.lazy(
  () => import('./Earning/Membership/SummaryPages/FromMemberPremiumCode')
)

const FromMemberPremiumCodeDetails = React.lazy(
  () => import('./Earning/Membership/DetailsPages/FromMemberPremiumCode')
)

const Coupons = React.lazy(() => import('components/ReusableCouponsComponent'))
const CouponCodes = React.lazy(
  () => import('components/ReusableCouponCodesComponent')
)

const SMTP = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/SMTP')
)
const Group = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Group')
)

const Contacts = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Contacts')
)

const Documents = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Documents')
)
const Emails = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Emails')
)
const Recipients = React.lazy(
  () => import('components/ReusableDocumentManagementComponents/Recipients')
)

const Reports = React.lazy(
  () =>
    import(
      'components/ReusableDocumentManagementComponents/Emails/components/Reports'
    )
)

const EditMerchantFromMerchantList = React.lazy(
  () => import('components/GlobalMerchantForm/EditMerchantRequest')
)

const MerchantActivityReports = React.lazy(
  () => import('components/GlobalMerchantForm/MerchantActivityReports')
)

const MembersInfoList = React.lazy(
  () => import('components/GlobalMerchantForm/MembersList')
)

export const whiteLabelAdminConfig = [
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/premium-merchant-codes',
    element: <MerchantCodesList />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/premium-member-codes',
    element: <MemberCodesList />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/merchant-package/earning',
    element: <MerchantPackageEarning />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/usersList',
    element: <UsersList />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: 'whiteLabel/referedcplist',
    element: <ReferredCPList />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/charitieslist',
    element: <CharitiesList />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/clublist',
    element: <ClubList />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/memberslist',
    element: <MembersList />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/whitelabel-members-list',
    element: <WhiteLabelMembersList />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/sendMerchantRequest',
    element: <SendMerchantRequest adminRoute={'whiteLabel'} />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/sendMerchantRequest/:id',
    element: <SendMerchantRequest adminRoute={'whiteLabel'} />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/editMerchantRequest/:id',
    element: <EditMerchantRequest adminRoute={'whiteLabel'} />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/merchantList',
    element: <MerchantList adminRoute={'whiteLabel'} />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'whiteLabel'} />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/draftList',
    element: <DraftList adminRoute={'whiteLabel'} />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/transaction-qr-codes',
    element: <TransactionQrCodes />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whiteLabel/issuer-qr-codes',
    element: <IssuerQrCodes />,
  },
  // earning =================================================
  // issuer
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/asissuer',
    element: <FriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/fromIssuerBranchOfWL',
    element: <FriendshipEarningFromIssuerBranchOfWL />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/asissuer/detail',
    element: <FriendshipEarningAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/fromIssuerBranchOfWL/detail',
    element: <FriendshipFromIssuerBranchDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/country/asissuer',
    element: <CountrySpecificFriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/country/asissuer/detail',
    element: <CountrySpecificFriendshipEarningAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromIssuerBranchOfWL',
    element: <MembershipEarningFromIssuerBranchOfWL />,
  },

  // merchant signer
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/asmerchantsigner',
    element: <FriendshipEarningAsMerchantSigner />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/fromMerchantSignerBranchOfWL',
    element: <FriendshipEarningFromMerchantSignerBranchOfWL />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/fromMerchantSignerBranchOfWL/detail',
    element: <FriendshipEarningFromMerchantSignerBranchDetail />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/country/asmerchantsigner',
    element: <CountrySpecificFriendshipEarningAsMerchantSigner />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/asmerchantsigner',
    element: <CountrySpecificFriendshipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/country/asmerchantsigner/detail',
    element: <CountrySpecificFriendshipEarningAsMerchantSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/asmerchantsigner/detail',
    element: <FriendshipEarningAsMerchantSignerDetail />,
  },
  // cp signer
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/ascpsigner',
    element: <FriendshipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/fromCpSignerBranchOfWL',
    element: <FriendshipEarningAsCpSignerFromIssuerBranchOfWL />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/ascpsigner/asissuer/detail',
    element: <FriendshipEarningAsCpSignerAsIssuerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/fromCpSignerBranch/asissuer/detail',
    element: <FriendshipEarningFromCpSignerBranchAsIssuerDetail />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/ascpsigner/asmerchantsigner/detail',
    element: <FriendshipEarningAsCpSignerAsSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/fromCpSignerBranch/asmerchantsigner/detail',
    element: <FriendshipEarningFromCpSignerBranchAsSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/country/ascpsigner',
    element: <CountrySpecificFriendshipEarningAsCpSignerSummary />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/country/ascpsigner/asissuer/detail',
    element: <CountrySpecificFriendshipEarningAsCpSignerAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/country/ascpsigner/assigner/detail',
    element: <CountrySpecificFriendshipEarningAsCpSignerAsSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/ascpsigner',
    element: <MembershipEarningAsCpSigner />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromCpSignerBranchOfWL',
    element: <MembershipEarningFromCpSignerBranchOfWL />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/ascpsigner/detail',
    element: <MembershipEarningAsCpSignerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromCpSignerBranchOfWL/detail',
    element: <MembershipEarningFromCpSignerBranchOfWLDetails />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/ascpsigner',
    element: <CountrySpecificMembershipEarningAsCpSigner />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/ascpsigner/detail',
    element: <CountrySpecificMembershipEarningAsCpSignerDetail />,
  },

  // from Country Pool
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/fromCountryPool',
    element: <FriendshipEarningFromCountryPool />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/fromCountryPool/detail',
    element: <FriendshipEarningFromCountryPoolDetail />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromCountryPool',
    element: <MembershipEarningFromCountryPool />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromCountryPool/detail',
    element: <MembershipEarningFromCountryPoolDetail />,
  },

  // issuer
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/asissuer',
    element: <MembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/asissuer/detail',
    element: <MembershipEarningAsIssuerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromIssuerBranchOfWL/detail',
    element: <MembershipEarningFromIssuerBranchOfWLDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/asissuer',
    element: <CountrySpecificMembershipEarningAsIssuer />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/asissuer/detail',
    element: <CountrySpecificMembershipEarningAsIssuerDetail />,
  },
  // region allocation
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromregionallocation',
    element: <MembershipEarningFromRegionAllocation />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromregionallocation/detail',
    element: <MembershipEarningFromRegionAllocationDetail />,
  },
  // club/charity
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/asclubcharity',
    element: <MembershipEarningAsClubCharity />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromCharityClubBranchOfWL',
    element: <MembershipEarningFromClubCharityBranchOfWL />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/asclubcharity/detail',
    element: <MembershipEarningAsClubCharityDetail />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromClubCharityOfWL/detail',
    element: <MembershipEarningFromClubCharityBranchDetail />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/asclubcharity',
    element: <CountrySpecificMembershipEarningAsClubCharity />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/asclubcharity/detail',
    element: <CountrySpecificMembershipEarningAsClubCharityDetail />,
  },

  // from Country Pool
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/country/total-transactions',
    element: <CountrySpecificFriendshipEarningTotalTransactions />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/friendship-earning/country/total-transactions/detail',
    element: <CountrySpecificFriendshipEarningTotalTransactionsDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/total-transactions',
    element: <CountrySpecificMembershipEarningTotalTransactions />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/country/total-transactions/detail',
    element: <CountrySpecificMembershipEarningTotalTransactionsDetail />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/sendMerchantEmail',
    element: <SendMerchantEmail adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/referredMerchantList',
    element: <ReferredMerchantList adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/membership-earning/fromMemberPremiumCode',
    element: <FromMemberPremiumCodeDetails />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/coupons',
    element: <Coupons adminRoute={'whitelabel'} />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/coupon-codes/:id',
    element: <CouponCodes />,
  },

  // {
  //   permittedRole: RoutePermittedRole.stateOwner,
  //   path: '/whitelabel/membership-earning/fromMemberPremiumCode/detail',
  //   element: <FromMemberPremiumCodeDetails />,
  // },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/setting/smtp',
    element: <SMTP adminRoute={'whitelabel'} />,
  },
  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/setting/group',
    element: <Group adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/setting/contacts',
    element: <Contacts adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/setting/documents',
    element: <Documents adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/setting/emails',
    element: <Emails adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/setting/recipients',
    element: <Recipients adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/setting/reports/:id',
    element: <Reports adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/merchantList/editMerchant/:id',
    element: <EditMerchantFromMerchantList adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/merchant-activity-reports',
    element: <MerchantActivityReports adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/member-list/:id',
    element: <MembersInfoList adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/all-notification',
    element: <AllNotificationList adminRoute={'whitelabel'} />,
  },

  {
    permittedRole: RoutePermittedRole.whiteLabelUser,
    path: '/whitelabel/payment/connect-stripe',
    element: <ConnectStripe adminRoute={'whitelabel'} />,
  },
]
