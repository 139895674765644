import React, { useEffect } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import AppCard from '@crema/core/AppCard'
import container from './ConnectStripe.container'
import _ from 'lodash'

const steps = [
  { position: 1, label: '1. Account Onboarding', description: `` },
  { position: 2, label: '2. Stripe Connection', description: '' },
  {
    position: 3,
    label: '3. Check Verification',
    description: ``,
  },
]

const ConnectStripe = ({
  onFetchMemberStart,
  countrySetup: { countryList },
  stateOwners: { hasMoreData },
  members: {
    memberList: { data, hasMore, totalCount, count, page: pageNumber },
    loading,
  },
  auth: { currentUser },
  siteCoordinator: { globalCountry },
  onFetchCPProfileInfoStart,
  collaborationPartner: { cpProfileInfo, isFetching, stripeInfo },
  salesAgent: { isCreating, isGenerating, stripeAccountInfo, isVerifing },
  onGenerateConnectAccountLinkStart,
  onCreateStripePayoutAccountStart,
  onStripeCheckOnboardStatusStart,
  onStripeLoginUrlStart,
  onFetchStripeInfoStart,
  ...rest
}) => {
  const [activeStep, setActiveStep] = React.useState(0)

  useEffect(() => {
    if (
      currentUser?.role?.toLowerCase() === 'salesagent' ||
      currentUser?.role?.toLowerCase() === 'collaborationpartner'
    ) {
      onFetchCPProfileInfoStart()
    }
    onFetchStripeInfoStart()
  }, [])

  useEffect(() => {
    if (
      stripeInfo?.data?.stripeOnBoardStatus?.toLowerCase() === 'started' &&
      stripeInfo?.data?.stripeConnectAccountId
    ) {
      setActiveStep(1)
    } else if (
      stripeInfo?.data?.stripeOnBoardStatus?.toLowerCase() === 'incomplete'
    ) {
      setActiveStep(2)
    } else if (
      stripeInfo?.data?.stripeOnBoardStatus?.toLowerCase() === 'complete'
    ) {
      setActiveStep(2)
    } else {
      setActiveStep(0)
    }
  }, [stripeInfo?.data, cpProfileInfo])

  const handleContinue = (step) => {
    if (step?.position == 1) {
      onCreateStripePayoutAccountStart({
        handleNext,
      })
    } else if (step?.position == 2) {
      onGenerateConnectAccountLinkStart({
        params: {
          accountId: stripeAccountInfo?.accountId
            ? stripeAccountInfo?.accountId
            : stripeInfo?.data?.stripeConnectAccountId,
        },
        handleNext,
      })
    } else if (step?.position == 3) {
      onStripeCheckOnboardStatusStart({
        params: {
          accountId: stripeAccountInfo?.accountId
            ? stripeAccountInfo?.accountId
            : stripeInfo?.data?.stripeConnectAccountId,
        },
        handleNext,
      })
    }
  }

  const handleNext = (step) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Box>
      <AppCard sx={{ py: 4 }}>
        <Grid container justifyContent={'center'} spacing={4}>
          <Paper square elevation={2} sx={{ p: 5, mt: 4 }}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                Steps To Connect Your Piiink Account With Stripe
              </Typography>
            </Grid>
            <Grid item container justifyContent={'center'} mt={3}>
              {isFetching ? (
                <CircularProgress size={40} />
              ) : (
                <Box mt={3} ml={3}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel optional={index === 2 ? <></> : null}>
                          <Typography variant="body1">{step.label}</Typography>
                        </StepLabel>
                        <StepContent>
                          <Typography>{step.description}</Typography>

                          {stripeInfo?.data?.stripeOnBoardStatus?.toLowerCase() !==
                            'complete' && (
                            <Box sx={{ mb: 2 }}>
                              <Button
                                variant="contained"
                                onClick={() => handleContinue(step)}
                                sx={{ mt: 1, mr: 1 }}
                                disabled={isCreating}
                              >
                                {index === steps.length - 1
                                  ? 'Finish'
                                  : 'Continue'}
                              </Button>
                              <Button
                                disabled={
                                  index === 0 ||
                                  (index === 1 &&
                                    stripeInfo?.data?.stripeOnBoardStatus?.toLowerCase() ===
                                      'started') ||
                                  (index === 2 &&
                                    stripeInfo?.data?.stripeOnBoardStatus?.toLowerCase() ===
                                      'incomplete')
                                }
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Back
                              </Button>
                            </Box>
                          )}
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  {(activeStep === steps.length ||
                    stripeInfo?.data?.stripeOnBoardStatus?.toLowerCase() ===
                      'complete') && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                      <Typography variant="body1" align="center">
                        Stripe connection process completed successfully !
                      </Typography>
                    </Paper>
                  )}
                </Box>
              )}
            </Grid>
          </Paper>

          {(stripeInfo?.data?.stripeOnBoardStatus?.toLowerCase() ===
            'incomplete' ||
            stripeInfo?.data?.stripeOnBoardStatus?.toLowerCase() ===
              'complete') && (
            <>
              <Grid item xs={12}>
                <Typography variant="h3">Stripe Login Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() =>
                    onStripeLoginUrlStart({
                      params: {
                        accountId: stripeAccountInfo?.accountId,
                      },
                    })
                  }
                >
                  {isVerifing ? (
                    <CircularProgress size={20} />
                  ) : (
                    'Click Here To Login'
                  )}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </AppCard>
    </Box>
  )
}

export default container(ConnectStripe)
