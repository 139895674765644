import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchCountryStart,
  fetchCountrySuccess,
  fetchCountryFailure,
  createCountrySuccess,
  createCountryFailure,
  deleteCountrySuccess,
  deleteCountryFailure,
  fetchCountryByIdSuccess,
  fetchCountryByIdFailure,
  editCountrySuccess,
  editCountryFailure,
  fetchActivateCountryStatusFailure,
  fetchActivateCountryStatusStart,
  fetchActivateCountryStatusSuccess,
  activateCountryFailure,
  activateCountryStart,
  activateCountrySuccess,
  fetchCountryStripeKeySuccess,
  fetchCountryStripeKeyFailure,
  clearCountryStripeKey,
  fetchAllCurrencySuccess,
  fetchAllCurrencyFailure,
  createCurrencySuccess,
  createCurrencyFailure,
  editCurrencySuccess,
  editCurrencyFailure,
  fetchCurrencyByIdSuccess,
  fetchCurrencyByIdFailure,
} from './countrySetupSlice'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import ToastMessage from '@crema/core/AppMessageView'

import {
  fetchCountryList,
  createCountry,
  deleteCountry,
  fetchCountryById,
  editCountry,
  fetchCountryActivateStatusById,
  activateCountry,
  fetchCountryListForSetup,
  fetchCountryStripeKey,
  fetchAllCountryCurrencySymbol,
  createCurrency,
  fetchCurrencyById,
  editCurrency,
  deleteCurrency,
} from '../../api/country'

import { deleteImage, getImageUrl, uploadImage } from 'api/imageUpload'
import _ from 'lodash'
import { removeEmptyFields, removeParam, getImageIdFromUrl } from 'utils'

export function* fetchCountryAsync({ payload }: AnyAction) {
  try {
    // const { data } = yield call(fetchCountryList(payload))

    const { data } = yield payload?.type === 'all'
      ? call(
          fetchCountryListForSetup(removeEmptyFields({ ...payload, type: '' }))
        )
      : call(fetchCountryList(removeEmptyFields(payload)))

    yield put(fetchCountrySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCountryFailure(err))
  }
}

export function* fetchCountryByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryById(payload))

    yield put(fetchCountryByIdSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCountryByIdFailure(err))
  }
}

export function* editCountryAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.logo)) {
      let imageUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.logo)
        )
        imageUrl = removeParam(imageResponse.config.url)
      }
      yield call(deleteImage(getImageIdFromUrl(payload.params.imageUrl)))

      const { data } = yield call(
        editCountry(payload.params.id, { ...payload.params, imageUrl })
      )
      yield put(editCountrySuccess(data?.data))

      yield payload.closeModal()
    } else {
      const uploadData = _.isObject(payload?.logo)
        ? { ...payload.params }
        : { ...payload.params, imageUrl: '' }
      const { data } = yield call(editCountry(payload.params.id, uploadData))
      yield put(editCountrySuccess(data?.data))
      yield payload.closeModal()
      yield put(
        fetchCountryStart({
          page: 1,
          limit: 10,
          offset: 0,
          type: 'all',
        })
      )
    }
    yield put(
      openAlert({ message: 'Country successfully edited', severity: 'success' })
    )
    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editCountryFailure(err))
  }
}

export function* createCountryAsync({ payload }: AnyAction) {
  try {
    let imageResponse
    if (!_.isEmpty(payload?.params?.imageUrl)) {
      let imgUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadImage(initialImageUrl?.data?.url, payload?.params?.imageUrl)
        )
        imgUrl = removeParam(imageResponse.config.url)
      }
      const { data } = yield call(
        createCountry({ ...payload.params, imageUrl: imgUrl })
      )
      yield put(createCountrySuccess(data?.data))

      yield put(
        fetchCountryStart({
          page: 1,
          limit: 10,
          offset: 0,
          type: 'all',
        })
      )
      yield payload.closeModal()
    } else {
      const { data } = yield call(createCountry(payload.params))

      yield put(createCountrySuccess(data?.data))
      yield put(
        fetchCountryStart({
          page: 1,
          limit: 10,
          offset: 0,
          type: 'all',
        })
      )
    }
    yield put(
      openAlert({
        message: 'Country successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createCountryFailure(err))
  }
}

export function* deleteCountryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteCountry(payload))

    if (data) {
      yield put(deleteCountrySuccess(payload))
      yield put(
        fetchCountryStart({
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
      yield put(
        openAlert({
          message: 'Country deleted successfully ',
          severity: 'success',
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteCountryFailure(err))
  }
}

export function* fetchActivateIdByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryActivateStatusById(payload))
    yield put(fetchActivateCountryStatusSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchActivateCountryStatusFailure(err))
  }
}

export function* activateCountryAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(activateCountry(payload.id, payload.params))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(fetchCountryStart({ page: 1, limit: 100, offset: 0 }))
    }

    yield put(activateCountrySuccess(data))

    yield put(
      openAlert({
        message: 'Country Activated Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(activateCountryFailure(err))
  }
}

export function* fetchCountryStripeKeyAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCountryStripeKey(payload))
    yield put(fetchCountryStripeKeySuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(clearCountryStripeKey())
    yield put(fetchCountryStripeKeyFailure(err))
  }
}

export function* fetchAllCurrencyAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchAllCountryCurrencySymbol(payload))

    yield put(fetchAllCurrencySuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchAllCurrencyFailure(err))
  }
}

export function* createCurrencyAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createCurrency(payload.params))

    yield put(createCurrencySuccess(data?.data))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Currency successfully created',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createCurrencyFailure(err))
  }
}

export function* fetchCurrencyByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCurrencyById(payload))

    yield put(fetchCurrencyByIdSuccess(data?.data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCurrencyByIdFailure(err))
  }
}

export function* editCurrencyAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editCurrency(payload?.params?.id, payload?.params)
    )
    yield put(editCurrencySuccess(data?.data))
    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Currency successfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(editCurrencyFailure(err))
  }
}

export function* deleteCurrencyAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(deleteCurrency(payload?.params?.id))

    yield put(deleteCountrySuccess(data))

    if (payload?.closeModal) {
      yield payload?.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }
    yield put(
      openAlert({
        message: 'Currency deleted successfully ',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(deleteCountryFailure(err))
  }
}

export function* watchFetchCountry() {
  yield takeLatest('countrySetup/fetchCountryStart', fetchCountryAsync)
}

export function* watchCreateCountry() {
  yield takeLatest('countrySetup/createCountryStart', createCountryAsync)
}

export function* watchDeleteCountry() {
  yield takeLatest('countrySetup/deleteCountryStart', deleteCountryAsync)
}

export function* watchFetchCountryById() {
  yield takeLatest('countrySetup/fetchCountryByIdStart', fetchCountryByIdAsync)
}

export function* watchEditCountry() {
  yield takeLatest('countrySetup/editCountryStart', editCountryAsync)
}

export function* watchFetchActivateStatus() {
  yield takeLatest(
    'countrySetup/fetchActivateCountryStatusStart',
    fetchActivateIdByIdAsync
  )
}

export function* watchFetchCountryStripeKey() {
  yield takeLatest(
    'countrySetup/fetchCountryStripeKeyStart',
    fetchCountryStripeKeyAsync
  )
}

export function* watchActivateCountry() {
  yield takeLatest('countrySetup/activateCountryStart', activateCountryAsync)
}

export function* watchFetchAllCurrency() {
  yield takeLatest('countrySetup/fetchAllCurrencyStart', fetchAllCurrencyAsync)
}

export function* watchCreateCurrency() {
  yield takeLatest('countrySetup/createCurrencyStart', createCurrencyAsync)
}

export function* watchFetchCurrencyById() {
  yield takeLatest(
    'countrySetup/fetchCurrencyByIdStart',
    fetchCurrencyByIdAsync
  )
}

export function* watchEditCurrency() {
  yield takeLatest('countrySetup/editCurrencyStart', editCurrencyAsync)
}

export function* watchDeleteCurrency() {
  yield takeLatest('countrySetup/deleteCurrencyStart', deleteCurrencyAsync)
}

export function* countrySetupSagas() {
  yield all([
    call(watchFetchCountry),
    call(watchCreateCountry),
    call(watchDeleteCountry),
    call(watchFetchCountryById),
    call(watchEditCountry),
    call(watchFetchActivateStatus),
    call(watchActivateCountry),
    call(watchFetchCountryStripeKey),
    call(watchFetchAllCurrency),
    call(watchCreateCurrency),
    call(watchEditCurrency),
    call(watchFetchCurrencyById),
    call(watchDeleteCurrency),
  ])
}
