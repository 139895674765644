/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import { sendClubReqStart } from 'store/club/clubSlice'
import { clearGeneralData } from 'store/merchant/merchantSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  siteCoordinator: state.siteCoordinator,
  club: state.club,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateClubStart: (payload) => dispatch(sendClubReqStart(payload)),
  clearGeneralData: () => dispatch(clearGeneralData()),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
