// import { axiosRequest } from '../../utils/axiosRequest'
import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const fetchSalesAgentList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createSalesAgent = (params) => {
  return () =>
    axiosRequest
      .post(`/api/salesAgent/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchSalesAgentById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/salesAgent/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectSalesAgent = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editSalesAgent = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifySalesAgent = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/updateAndVerifyEOI/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const saveUpdatePendingSalesAgent = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/saveUpdate/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteSalesAgent = (id, params) => {
  return () =>
    axiosRequest
      .delete(`/api/salesAgent/delete/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editSalesAgentOwnProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/salesAgent/editSalesAgentOwnProfile`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStripePayoutAccount = (params) => {
  return () =>
    axiosRequest
      .get(`/api/stripePayout/createAccount`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const stripeCheckOnboardStatus = (params) => {
  return () =>
    axiosRequest
      .get(`/api/stripePayout/checkOnboardStatus`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const stripeLoginUrl = (params) => {
  return () =>
    axiosRequest
      .get(`/api/stripePayout/stripeLoginURL`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const stripeGenerateConnectAccountLink = (params) => {
  return () =>
    axiosRequest
      .post(`/api/stripePayout/generateConnectAccountLink`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
