import axiosRequest from 'utils/axiosRequest'
import { removeEmptyFields } from 'utils'

export const createStep1Draft = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/draft/register/step1`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStep1General = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/register/step1`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editStep1Draft = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/draft/register/updateMerchant/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editStep1General = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/register/updateMerchant/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStep2Draft = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/draft/register/step2`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStep2General = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/register/step2`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const updateStep2General = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/register/updateWebsite/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStep3Draft = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/draft/register/step3`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStep3General = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/register/step3`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStep4Draft = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/draft/register/discount/step4`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createStep4General = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/general/register/discount/step4`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const updateStep4General = (id, params) => {
  return () =>
    axiosRequest
      .put(
        `/api/merchant/general/register/updateDiscount/${id}`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantDiscountById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantDiscountJSON/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllMerchantList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getAllByAdmin`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllMerchantGroup = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/fanchisor/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGroupById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/fanchisor/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const assignIssuerTransactionCode = (id, params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/general/assignMultipleIssuerTransactionCode/${id}`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyPendingMerchantGroup = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/fanchisor/approved/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGeneralList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getAllByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGeneralByLocationList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getAllByLocation`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantListStateWiseData = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getAllStateWiseData`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantFranchisorGeneralList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getAllByMerchantFranchisor`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantDraftList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getallDraft`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantFranchisorDraftList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getallFranchisorDraft`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMerchantDiscount = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/merchantDiscountJSON/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createBankInfo = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/general/register/bankInfo`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantDraftBasicInfoByPanel = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/draft/getOneByPanel/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantDraftBasicInfoByAdmin = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/draft/getOneByAdmin/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantDraftWebsiteInfo = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getWebsiteInfo/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantDraftImage = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getMerchantImage/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchBillingInfo = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/subscriptionDetail`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantDraftDiscount = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getMerchantDiscount/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantDraftBankInfo = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getMerchantBankInfo/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGeneralBasicInfoByPanel = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getOneByPanel/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGeneralBasicInfoForStateMerchant = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantFranchiseGeneralBasicInfoPanel = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getOneByMerchantFranchisor/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGeneralBasicInfoByAdmin = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getOneByAdmin/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGeneralWebsiteInfo = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getWebsiteInfo/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGeneralImage = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getMerchantImage/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGeneralDiscount = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getMerchantDiscount/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchWalletInfo = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantRebatewallet/getOneByPanel`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
export const fetchWalletInfoDetail = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/friendship/getByMerchant`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantGeneralBankInfo = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getMerchantBankInfo/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectMerchantGeneral = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/rejectValue/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const checkApplyMerchantPremiumCode = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/general/register/checkApplyMerchantCode`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const checkApplyCouponCode = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/general/register/bankInfo/CouponCode`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const couponCodeLogUpdate = (params) => {
  return () =>
    axiosRequest
      .put(
        `/api/merchant/general/register/bankInfo/CouponCodeLogUpdate/${params?.couponLogId}`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const checkApplyMerchantCodeUponUpgrade = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/general/register/checkApplyMerchantCodeUponUpgrade`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const checkApplyMerchantPremiumCodeUpgradePackage = (code, params) => {
  return () =>
    axiosRequest
      .get(`/api/merchantPremiumCode/getOneByCode/${code}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const checkApplyMerchantPremiumCodeAsync = async (params) => {
  return await axiosRequest
    .post(
      `/api/merchant/general/register/checkApplyMerchantCode`,
      removeEmptyFields(params)
    )
    .then((res) => {
      return Promise.resolve(res?.data)
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message
      return Promise.reject(error)
    })
}

export const setMerchantPopular = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/setMerchantPopular/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const verifyMerchantById = (id, params) => {
  return () =>
    axiosRequest
      .patch(`/api/merchant/general/approveMerchant/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const activeMerchantById = (id) => {
  return () =>
    axiosRequest
      .patch(`/api/merchant/general/activateMerchant/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const activateMerchantSubscription = (id) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/activateSubscription/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantProfileInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/merchant/panel/getOne`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantProfileDiscountInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/merchant/panel/getDiscount`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantProfileImagesInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/merchant/panel/getImageInfo`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantProfileWebsiteAndBankingInfo = () => {
  return () =>
    axiosRequest
      .get(`/api/merchant/panel/getWebsiteAndBankInfo`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const updateBankInfo = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/register/updatebankInfo/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const updateWebsite = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/register/updateWebsite/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const updateImage = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/register/updateImage/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getMerchantCardInfo = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/general/getCardInfo`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const merchantAddCardSource = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/addCardSource`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const merchantAddCardSourceWithId = (id, params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/addCardSource/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const merchantSetCardAsDefault = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/setCardAsDefault`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const generateEcommerceKey = () => {
  return () =>
    axiosRequest
      .post(`/api/merchant/general/generateEcommerceKey`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editOwnMerchantProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/register/editOwnProfile`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMerchantInfo = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/register/editOwnMerchant/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const sendMerchantEmail = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi/referredMail`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMerchantTerminal = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/merchantTerminal/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantTerminalList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantTerminal/getByMerchant`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMerchantTerminal = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/merchantTerminal/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editOwnMerchantTerminalProfile = (params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/merchantTerminal/editOwnProfile`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchOneMerchantTerminal = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantTerminal/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deactivateMerchantTerminal = (id) => {
  return () =>
    axiosRequest
      .patch(`/api/merchant/merchantTerminal/deactivateReactivate/${id}`, {
        deactivate: true,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const activateMerchantTerminal = (id) => {
  return () =>
    axiosRequest
      .patch(`/api/merchant/merchantTerminal/deactivateReactivate/${id}`, {
        deactivate: false,
      })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const updateMerchantTerminalPassword = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/merchantTerminal/updatePassword/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const getReferralToken = () => {
  return () =>
    axiosRequest
      .get(`/api/merchant/eoi/referralToken`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchReferredMerchantList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/referred/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchReferredMerchantListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/referred/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createTerminal = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/terminal/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllTerminalList = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/terminal/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTerminalListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/terminal/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchTerminalById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/terminal/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editTerminal = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/terminal/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllTerminalListByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/terminal/getByPanel`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMultipleAssignerByRole = (params) => {
  return () =>
    axiosRequest
      .get(`/api/issuerCode/getMultipleAssignerByRole`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const transferMerchantRequest = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/referred/transfer/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchReferredMerchantById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/referred/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editReferredMerchant = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/referred/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const approveReferredMerchantById = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/referred/approve/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const rejectReferredMerchantById = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/referred/reject/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const referredMailFromDraft = (id) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/eoi/referredMailFromDraft/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const acceptOrRejectreferredMerchant = (id, params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/referred/acceptOrReject/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const createMerchantGroup = (params) => {
  return () =>
    axiosRequest
      .post(`/api/merchant/fanchisor/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMerchantGroup = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/fanchisor/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const activateOrDeactivateMerchant = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/general/activeDeactive/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const deleteMerchant = (params) => {
  return () =>
    axiosRequest
      .delete(`/api/merchant/general/delete/${params}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchActivityListReportByPanel = (params) => {
  return () =>
    axiosRequest
      .get(`/api/transaction/friendship/getByPanelReport`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllMerchantMultilingual = (params) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantMultilingual/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const addMerchantMultilingual = (params) => {
  return () =>
    axiosRequest
      .post(
        `/api/merchant/merchantMultilingual/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMerchantMultilingual = (id, params) => {
  return () =>
    axiosRequest
      .put(`/api/merchant/merchantMultilingual/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchMerchantMultilingualById = (id) => {
  return () =>
    axiosRequest
      .get(`/api/merchant/merchantMultilingual/getOne/${id}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllCharityMultilingual = (params) => {
  return () =>
    axiosRequest
      .get(`api/charityMultilingual/getall`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const addCharityMultilingual = (params) => {
  return () =>
    axiosRequest
      .post(`api/charityMultilingual/create`, removeEmptyFields(params))
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editCharityMultilingual = (id, params) => {
  return () =>
    axiosRequest
      .put(`api/charityMultilingual/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const fetchAllMerchantCategoryMultilingual = (params) => {
  return () =>
    axiosRequest
      .get(`api/merchant/merchantCategoryMultilingual/getAll`, { params })
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const addMerchantCategoryMultilingual = (params) => {
  return () =>
    axiosRequest
      .post(
        `api/merchant/merchantCategoryMultilingual/create`,
        removeEmptyFields(params)
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}

export const editMerchantCategoryMultilingual = (id, params) => {
  return () =>
    axiosRequest
      .put(`api/merchant/merchantCategoryMultilingual/edit/${id}`, params)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
}
