import React, { useEffect } from 'react'
import AppContentView from '@crema/core/AppContentView'
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '../../utility/AppContextProvider/LayoutContextProvider'
import Layouts from './Layouts'
import AuthWrapper from './AuthWrapper'
import { useUrlSearchParams } from 'use-url-search-params'
import { useSidebarActionsContext } from '../../utility/AppContextProvider/SidebarContextProvider'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { onNavCollapsed } from '../../../store/settings/settingsSlice'
import { connect } from 'react-redux'
import CustomAlert from '@crema/core/AppMessageView'
import { fetchStateOwnerProfileInfoStart } from 'store/stateOwner/stateOwnerSlice'
import { fetchRegionOwnerProfileInfoStart } from 'store/regionOwner/regionOwnerSlice'
import { fetchAreaOwnerProfileInfoStart } from 'store/areaOwner/areaOwnerSlice'
import { fetchCountryOwnerProfileInfoStart } from 'store/countryOwner/countryOwnerSlice'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeCardElement } from '@stripe/stripe-js'
import moment from 'moment-timezone'

const AppLayout = ({
  auth: { isAuthenticated, currentUser },
  countrySetup: { countryStripeKeyInfo },
  alert,
  onFetchStateOwnerProfileInfoStart,
  onFetchAreaOwnerProfileInfoStart,
  onFetchRegionOwnerProfileInfoStart,
  onFetchCountryOwnerProfileInfoStart,
}) => {
  const stripePromise = loadStripe(
    countryStripeKeyInfo?.stripePublishableKey
      ? countryStripeKeyInfo?.stripePublishableKey
      : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  )

  useEffect(() => {
    moment.tz.setDefault()
  }, [])

  const { navStyle } = useLayoutContext()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  // const { isAuthenticated } = useAuthUser();
  const { updateNavStyle } = useLayoutActionsContext()
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext()
  const AppLayout = Layouts[navStyle]
  const [params] = useUrlSearchParams({}, {})

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout as string)
    if (params.menuStyle) updateMenuStyle(params.menuStyle as string)
    if (params.sidebarImage) setSidebarBgImage(true)
  }, [params, setSidebarBgImage, updateNavStyle, updateMenuStyle])

  useEffect(() => {
    dispatch(onNavCollapsed())
  }, [dispatch, pathname])

  useEffect(() => {
    //fetch owners profile for agreement sidebar menu
    if (currentUser?.role?.toLowerCase() === 'stateowner') {
      onFetchStateOwnerProfileInfoStart()
    } else if (currentUser?.role?.toLowerCase() === 'areaowner') {
      onFetchAreaOwnerProfileInfoStart()
    } else if (currentUser?.role?.toLowerCase() === 'regionowner') {
      onFetchRegionOwnerProfileInfoStart()
    } else if (currentUser?.role?.toLowerCase() === 'countryowner') {
      onFetchCountryOwnerProfileInfoStart()
    }
  }, [currentUser])

  return (
    <>
      {isAuthenticated ? (
        <>
          <AppLayout />
          <CustomAlert {...alert} />
        </>
      ) : (
        <AuthWrapper>
          <AppContentView />
          <CustomAlert {...alert} />
        </AuthWrapper>
      )}
    </>
  )
}

const mapStateToProps = (state, props) => ({
  settings: state.settings,
  auth: state.auth,
  alert: state.alert,
  countrySetup: state.countrySetup,
})
// asdfasdf

const mapDispatchToProps = (dispatch) => ({
  onFetchStateOwnerProfileInfoStart: (payload) =>
    dispatch(fetchStateOwnerProfileInfoStart(payload)),
  onFetchRegionOwnerProfileInfoStart: (payload) =>
    dispatch(fetchRegionOwnerProfileInfoStart(payload)),
  onFetchAreaOwnerProfileInfoStart: (payload) =>
    dispatch(fetchAreaOwnerProfileInfoStart(payload)),
  onFetchCountryOwnerProfileInfoStart: (payload) =>
    dispatch(fetchCountryOwnerProfileInfoStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container(React.memo(AppLayout))
