/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux'

import {
  fetchCharityStart,
  deleteCharityStart,
} from 'store/charities/chartiesSlice'

import { openAlert } from 'store/alert/alertSlice'
import { fetchRegionOwnerProfileInfoStart } from 'store/regionOwner/regionOwnerSlice'
import { fetchAreaOwnerProfileInfoStart } from 'store/areaOwner/areaOwnerSlice'

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant,
  checkout: state.checkout,
  countrySetup: state.countrySetup,
  areaOwner: state.areaOwner,
  stateOwner: state.stateOwner,
  charities: state.charities,
  siteCoordinator: state.siteCoordinator,
  regionOwner: state.regionOwner,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchStateOwnerStart: (payload) => dispatch(fetchCharityStart(payload)),
  onDeleteAreaOwnerStart: (payload) => dispatch(deleteCharityStart(payload)),
  onFetchRegionOwnerProfileInfoStart: (payload) =>
    dispatch(fetchRegionOwnerProfileInfoStart(payload)),
  OpenAlert: (payload) => dispatch(openAlert(payload)),
  onFetchAreaOwnerProfileInfoStart: (payload) =>
    dispatch(fetchAreaOwnerProfileInfoStart(payload)),
})

const container = connect(mapStateToProps, mapDispatchToProps)

export default container
