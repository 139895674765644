import { createSlice } from '@reduxjs/toolkit'

import { ICharitiesState } from '../interfaces'
import { listObj } from 'utils'

const INITIAL_STATE: ICharitiesState = {
  charitiesList: listObj,
  charitiesByPanelList: listObj,
  soleCharity: {},
  sharableCharityFormData: {},

  charityProfileInfo: listObj,
  pendingCharitiesList: listObj,
  allCharitiesList: listObj,
  allCharitiesListByLocation: listObj,
  error: null,
  loading: false,
  isEditing: false,
  isVerifing: false,
  isRejecting: false,
  hasMoreData: false,
  isGenerating: false,
}

export const charitySlice = createSlice({
  name: 'charity',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCharityStart: (state: any, action) => {
      state.loading = true
    },
    fetchCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.charitiesList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchCharityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCharityByPanelStart: (state: any, action) => {
      state.loading = true
    },
    fetchCharityByPanelSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.charitiesByPanelList = action.payload
      state.loading = false
    },
    fetchCharityByPanelFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchPendingCharityStart: (state: any, action) => {
      state.loading = true
    },
    fetchPendingCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.pendingCharitiesList = action.payload
      state.hasMoreData = action.payload.hasMore
      state.loading = false
    },
    fetchPendingCharityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },
    fetchCharityByIdStart: (state: any, action) => {
      state.loading = true
    },
    fetchCharityByIdSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.soleCharity = action.payload

      state.loading = false
    },
    fetchCharityByIdFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deletePendingCharityStart: (state: any, action) => {
      state.loading = true
    },
    deletePendingCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.pendingCharitiesList = state.pendingCharitiesList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deletePendingCharityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    deleteCharityStart: (state: any, action) => {
      state.loading = true
    },
    deleteCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.charitiesList.data = state.charitiesList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    deleteCharityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    createCharityStart: (state: any, action) => {
      state.loading = true
    },
    createCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      // state.charitiesList.data = [...state.charitiesList.data, action.payload]
    },
    createCharityFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    editCharityStart: (state: any, action) => {
      state.isEditing = true
    },
    editCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;

      state.isEditing = false
    },
    editCharityFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    verifyCharityStart: (state: any, action) => {
      state.isVerifing = true
    },
    verifyCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isVerifing = false
      state.pendingCharitiesList.data = state.pendingCharitiesList.data.filter(
        (item) => item.id !== action.payload.id
      )
    },
    verifyCharityFailure: (state: any, action) => {
      state.isVerifing = false
      state.error = action.payload
    },
    rejectCharityStart: (state: any, action) => {
      state.isRejecting = true
    },
    rejectCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isRejecting = false
      state.pendingCharitiesList.data = state.pendingCharitiesList.data.filter(
        (item) => item.id !== action.payload
      )
    },
    rejectCharityFailure: (state: any, action) => {
      state.isRejecting = false
      state.error = action.payload
    },

    updatePendingCharityStart: (state: any, action) => {
      state.isEditing = true
    },
    updatePendingCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.isEditing = false
      // state.charitiesList.data = [...state.charitiesList.data, action.payload]

      const result = state.pendingCharitiesList.data.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      )
      state.pendingCharitiesList.data = result
      state.isEditing = false
    },
    updatePendingCharityFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },
    sendCharityReqStart: (state: any, action) => {
      state.loading = true
    },

    sendCharityReqSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.loading = false
      state.charitiesList.data = [...state.charitiesList.data, action.payload]
    },
    sendCharityReqFailure: (state: any, action) => {
      state.loading = false
      state.error = action.payload
    },

    fetchCharityProfileInfoStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchCharityProfileInfoSuccess: (state: any, action) => {
      state.charityProfileInfo = action.payload
      state.isFetching = false
    },
    fetchCharityProfileInfoFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAllCharityStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllCharitySuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.allCharitiesList = action.payload
      state.isFetching = false
    },
    fetchAllCharityFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    fetchAllCharityByLocationStart: (state: any, action) => {
      state.isFetching = true
    },
    fetchAllCharityByLocationSuccess: (state: any, action) => {
      // state.currentUser= action.payload;
      state.allCharitiesListByLocation = action.payload
      state.isFetching = false
    },
    fetchAllCharityByLocationFailure: (state: any, action) => {
      state.isFetching = false
      state.error = action.payload
    },

    editOwnCharityProfileStart: (state: any, action) => {
      state.isEditing = true
    },
    editOwnCharityProfileSuccess: (state: any, action) => {
      state.isEditing = false
    },
    editOwnCharityProfileFailure: (state: any, action) => {
      state.isEditing = false
      state.error = action.payload
    },

    getCharityReferralTokenStart: (state: any) => {
      state.isGenerating = true
    },
    getCharityReferralTokenSuccess: (state: any, action) => {
      state.isGenerating = false
      state.sharableCharityFormData = action.payload
    },
    getCharityReferralTokenFailure: (state: any, action) => {
      state.isGenerating = false
      state.error = action.payload
    },
  },
})

export const {
  fetchCharityStart,
  fetchCharitySuccess,
  fetchCharityFailure,
  deleteCharityStart,
  deleteCharitySuccess,
  deleteCharityFailure,
  fetchCharityByIdStart,
  fetchCharityByIdSuccess,
  fetchCharityByIdFailure,
  createCharityStart,
  createCharitySuccess,
  createCharityFailure,
  editCharityStart,
  editCharitySuccess,
  editCharityFailure,
  fetchPendingCharityStart,
  fetchPendingCharitySuccess,
  fetchPendingCharityFailure,
  deletePendingCharityStart,
  deletePendingCharitySuccess,
  deletePendingCharityFailure,
  verifyCharityStart,
  verifyCharitySuccess,
  verifyCharityFailure,
  rejectCharityStart,
  rejectCharitySuccess,
  rejectCharityFailure,
  updatePendingCharityStart,
  updatePendingCharityFailure,
  updatePendingCharitySuccess,
  sendCharityReqStart,
  sendCharityReqSuccess,
  sendCharityReqFailure,
  fetchCharityProfileInfoStart,
  fetchCharityProfileInfoSuccess,
  fetchCharityProfileInfoFailure,

  fetchCharityByPanelStart,
  fetchCharityByPanelSuccess,
  fetchCharityByPanelFailure,

  fetchAllCharityStart,
  fetchAllCharitySuccess,
  fetchAllCharityFailure,

  fetchAllCharityByLocationStart,
  fetchAllCharityByLocationSuccess,
  fetchAllCharityByLocationFailure,

  editOwnCharityProfileStart,
  editOwnCharityProfileSuccess,
  editOwnCharityProfileFailure,

  getCharityReferralTokenStart,
  getCharityReferralTokenSuccess,
  getCharityReferralTokenFailure,
} = charitySlice.actions

export default charitySlice.reducer
