import React, { useCallback, useEffect, useState } from 'react'

import { GoogleMap, Marker, Autocomplete } from '@react-google-maps/api'
import './globalLocation.css'

const GlobalLocation = ({ defaultObject, setFieldValue, values }) => {
  const [map, setMap] = useState(null)
  const [searchBox, setSearchBox] = useState(null)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [tempLat, setTempLat] = useState(
    defaultObject?.latlon?.length ? defaultObject?.latlon[0] : null
  )
  const [tempLng, setTempLng] = useState(
    defaultObject?.latlon?.length ? defaultObject?.latlon[1] : null
  )

  useEffect(() => setMapLoaded(true), [])

  const options = {
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER, // 'right-center' ,
      // ...otherOptions
    },
  }

  const containerStyle = {
    width: '100%',
    height: '400px',
  }

  let center = {
    lat: defaultObject?.latlon?.length ? defaultObject?.latlon[0] : 0,
    lng: defaultObject?.latlon?.length ? defaultObject?.latlon[1] : 0,
  }

  // const { isLoaded, loadError } = useLoadScript({
  //   id: 'google-map-script',
  //   googleMapsApiKey: 'AIzaSyAIbkrctrmtMZfpnPrb4cfL76M7f7EHGoE', // ,
  //   // ...otherOptions
  //   libraries: ['places'],
  // })

  const RenderMap = (setFieldValue) => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    const onLoad = useCallback(function onLoad(map) {
      // do something with map Instance
      const bounds = new window.google.maps.LatLngBounds(center)

      map.fitBounds(bounds)

      setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
      setMap(null)
    }, [])

    const handleLoad = (ref) => {
      setSearchBox(ref)
    }

    const handlePlacesChanged = () => {
      const place = searchBox.getPlace()

      // const { long_name: postalCode = '' } =
      //   place.address_components.find((c) => c.types.includes('postal_code')) ||
      //   {}
      if (place) {
        setTempLat(place.geometry.location.lat())
        setTempLng(place.geometry.location.lng())
        setFieldValue('latitude', place.geometry.location.lat())
        setFieldValue('longitude', place.geometry.location.lng())
        // setFieldValue('postalCodeUser', postalCode ?? '')
        // setFieldValue('streetInfo', place?.formatted_address ?? '')
      }
    }

    return (
      <>
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={options}
          zoom={15}
          center={
            tempLat
              ? {
                  lat: tempLat,
                  lng: tempLng,
                }
              : center
          }
          onClick={(e) => {
            setFieldValue('latitude', e.latLng.lat())
            setFieldValue('longitude', e.latLng.lng())
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Autocomplete
            onPlaceChanged={handlePlacesChanged}
            onLoad={handleLoad}
          >
            <input
              type="text"
              placeholder="Enter your address here"
              className="pac-container"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `340px`,
                height: `200px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                zIndex: '99999999 !important',
                fontSize: `20px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: 'absolute',
                right: '5%',
                marginLeft: '-120px',
              }}
            />
          </Autocomplete>
          {mapLoaded ? (
            <Marker
              position={{
                lat: values?.latitude,
                lng: values?.longitude,
              }}
              draggable={true}
            />
          ) : (
            ''
          )}
        </GoogleMap>
      </>
    )
  }

  return RenderMap(setFieldValue)
}

export default GlobalLocation
