import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  createContactUsSuccess,
  createContactUsFailure,
} from './contactUsSlices'
import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import { createContactUs } from 'api/contactUs'

import { deleteImage, getImageUrl, uploadFile } from 'api/imageUpload'
import { getImageIdFromUrl, removeParam } from 'utils'

import _ from 'lodash'

export function* createContactUsAsync({ payload }: AnyAction) {
  try {
    let imageResponse

    if (!_.isEmpty(payload?.params?.file)) {
      let uploadFileUrl = ''
      const initialImageUrl = yield call(getImageUrl())
      if (!_.isEmpty(initialImageUrl?.data)) {
        imageResponse = yield call(
          uploadFile(initialImageUrl?.data?.url, payload?.params?.file)
        )

        uploadFileUrl = removeParam(imageResponse.config.url)
      }

      const { data } = yield call(
        createContactUs({ ...payload.params, uploadFileUrl: uploadFileUrl })
      )

      yield put(createContactUsSuccess(data?.data?.club))
    } else {
      const { data } = yield call(createContactUs(payload?.params))
      yield put(createContactUsSuccess(data?.data?.club))
    }

    yield put(
      openAlert({ message: 'Message succesfully sent', severity: 'success' })
    )
    payload?.onSuccess()
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createContactUsFailure(err))
    console.error(err)
  }
}

export function* watchCreateContactUs() {
  yield takeLatest('contactUs/createContactUsStart', createContactUsAsync)
}

export function* contactUsSagas() {
  yield all([call(watchCreateContactUs)])
}
