import { takeLatest, call, all, put, delay } from 'redux-saga/effects'
import {
  fetchCollaborationPartnerStart,
  fetchCollaborationPartnerSuccess,
  fetchCollaborationPartnerFailure,
  deleteCollaborationPartnerStart,
  deleteCollaborationPartnerSuccess,
  deleteCollaborationPartnerFailure,
  createCollaborationPartnerStart,
  createCollaborationPartnerSuccess,
  createCollaborationPartnerFailure,
  editCollaborationPartnerStart,
  editCollaborationPartnerSuccess,
  editCollaborationPartnerFailure,
  fetchPendingCollaborationPartnerStart,
  fetchPendingCollaborationPartnerSuccess,
  fetchPendingCollaborationPartnerFailure,
  fetchGlobalCollaborationPackageSuccess,
  fetchGlobalCollaborationPackageFailure,
  fetchCPByIdStart,
  fetchCPByIdSuccess,
  fetchCPByIdFailure,
  fetchGlobalCollaborationPartnerStart,
  fetchGlobalCollaborationPackageStart,
  fetchGlobalCollaborationPartnerSuccess,
  fetchGlobalCollaborationPartnerFailure,
  fetchCountryAdminCollaborationPackageStart,
  fetchCountryAdminCollaborationPackageSuccess,
  fetchCountryAdminCollaborationPackageFailure,
  fetchPremiumCodesStart,
  fetchPremiumCodesSuccess,
  fetchPremiumCodesFailure,
  fetchMerchantPremiumCodesStart,
  fetchMerchantPremiumCodesSuccess,
  fetchMerchantPremiumCodesFailure,
  fetchPremiumMerchantCodesListStart,
  fetchPremiumMerchantCodesListSuccess,
  fetchPremiumMerchantCodesListFailure,
  fetchPremiumMerchantCodesGetAllStart,
  fetchPremiumMerchantCodesGetAllSuccess,
  fetchPremiumMerchantCodesGetAllFailure,
  fetchPremiumMemberCodesGetAllStart,
  fetchPremiumMemberCodesGetAllSuccess,
  fetchPremiumMemberCodesGetAllFailure,
  fetchPremiumMemberCodesListStart,
  fetchPremiumMemberCodesListSuccess,
  fetchPremiumMemberCodesListFailure,
  updatePremiumMerchantCodeStart,
  updatePremiumMerchantCodeSuccess,
  updatePremiumMerchantCodeFailure,
  updatePremiumMemberCodeStart,
  updatePremiumMemberCodeSuccess,
  updatePremiumMemberCodeFailure,
  createPremiumMemberCodeStart,
  createPremiumMemberCodeSuccess,
  createPremiumMemberCodeFailure,
  createPremiumMerchantCodeStart,
  createPremiumMerchantCodeSuccess,
  createPremiumMerchantCodeFailure,
  fetchCPProfileInfoStart,
  fetchCPProfileInfoSuccess,
  fetchCPProfileInfoFailure,
  editOwnCpProfileFailure,
  editOwnCpProfileSuccess,
  fetchPremiumCodesByPanelSuccess,
  fetchPremiumCodesByPanelFailure,
  clearCPById,
  fetchStripeInfoFailure,
  fetchStripeInfoSuccess,
} from './collaborationPartnerSlice'

import { AnyAction } from 'redux'
import { openAlert } from 'store/alert/alertSlice'

import {
  fetchReferredCollaborationPartnerList,
  fetchGlobalCollaborationPartnerList,
  fetchCountryAdminCPPackageList,
  fetchGlobalCPPackageList,
  createCollaborationPartnerRefer,
  editCollaborationPartner,
  fetchCollaborationPartnerById,
  fetchCollaborationPartnerProfileInfo,
  editOwnCpProfile,
  fetchStripeInfo,
} from '../../api/collaborationPartner'

import {
  getPremiumCodes,
  getMerchantPremiumCodes,
  updatePremiumMerchantCode,
  updatePremiumMemberCode,
  getPremiumMerchantCodesList,
  getPremiumMemberCodesList,
  getPremiumMerchantCodesAll,
  getPremiumMemberCodesAll,
  createPremiumMemberCode,
  createPremiumMerchantCode,
  getPremiumCodesByPanel,
} from '../../api/premiumCodes'

import _ from 'lodash'

export function* fetchReferredCollaborationPartnerAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchReferredCollaborationPartnerList(payload))

    yield put(fetchCollaborationPartnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCollaborationPartnerFailure(err))
    console.error(err)
  }
}

export function* fetchGlobalCollaborationPartnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchGlobalCollaborationPartnerList(payload))

    yield put(fetchGlobalCollaborationPartnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchGlobalCollaborationPartnerFailure(err))
    console.error(err)
  }
}

export function* fetchCountryAdminCollaborationPartnerAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCountryAdminCPPackageList(payload))

    yield put(fetchGlobalCollaborationPartnerSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchGlobalCollaborationPartnerFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumCodesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumCodes(payload))

    yield put(fetchPremiumCodesSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumCodesFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumCodesByPanelAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumCodesByPanel(payload))

    yield put(fetchPremiumCodesByPanelSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumCodesByPanelFailure(err))
    console.error(err)
  }
}

export function* fetchMerchantPremiumCodesAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getMerchantPremiumCodes(payload))

    yield put(fetchMerchantPremiumCodesSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchMerchantPremiumCodesFailure(err))
    console.error(err)
  }
}

export function* updatePremiumMerchantCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      updatePremiumMerchantCode(payload?.id, { isGiveaway: true })
    )

    yield put(updatePremiumMerchantCodeSuccess(data))
    yield put(
      openAlert({
        message: 'Marked as Giveway Successfully',
        severity: 'success',
      })
    )
    if (payload?.role?.toLowerCase() === 'globaladmin') {
      if (payload?.refreshPageData) {
        yield call(payload?.refreshPageData)
      }
    } else {
      yield put(
        fetchPremiumMerchantCodesListStart({
          collaborationPackageId: payload?.params?.collaborationPackageId,
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(updatePremiumMerchantCodeFailure(err))
    console.error(err)
  }
}

export function* updatePremiumMemberCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      updatePremiumMemberCode(payload?.id, { isGiveaway: true })
    )

    yield put(updatePremiumMemberCodeSuccess(data))
    yield put(
      openAlert({
        message: 'Marked as Giveway Successfully',
        severity: 'success',
      })
    )

    if (payload?.role?.toLowerCase() === 'globaladmin') {
      if (payload?.refreshPageData) {
        yield call(payload?.refreshPageData)
      }
    } else {
      yield put(
        fetchPremiumMemberCodesListStart({
          collaborationPackageId: payload?.params?.collaborationPackageId,
          page: 1,
          limit: 10,
          offset: 0,
        })
      )
    }
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(updatePremiumMemberCodeFailure(err))
    console.error(err)
  }
}

export function* createPremiumMemberCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createPremiumMemberCode(payload.params))

    yield put(createPremiumMemberCodeSuccess(data))
    yield put(
      openAlert({
        message: 'Premium Member Codes Generated Successfully',
        severity: 'success',
      })
    )
    yield payload?.closeModal()
    yield put(
      fetchPremiumMemberCodesGetAllStart({
        id: payload?.params?.id,
        countryId: payload?.params?.countryId,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createPremiumMemberCodeFailure(err))
    console.error(err)
  }
}

export function* createPremiumMerchantCodeAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createPremiumMerchantCode(payload.params))

    yield put(createPremiumMerchantCodeSuccess(data))
    yield put(
      openAlert({
        message: 'Premium Merchant Codes Generated Successfully',
        severity: 'success',
      })
    )
    yield payload?.closeModal()
    yield put(
      fetchPremiumMerchantCodesGetAllStart({
        id: payload?.params?.id,
        countryId: payload?.params?.countryId,
      })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(createPremiumMerchantCodeFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumMerchantCodesListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumMerchantCodesList(payload))

    yield put(fetchPremiumMerchantCodesListSuccess(data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumMerchantCodesListFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumMerchantCodesGetAllAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumMerchantCodesAll(payload))

    yield put(fetchPremiumMerchantCodesGetAllSuccess(data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumMerchantCodesGetAllFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumMemberCodesGetAllAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumMemberCodesAll(payload))

    yield put(fetchPremiumMemberCodesGetAllSuccess(data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumMemberCodesGetAllFailure(err))
    console.error(err)
  }
}

export function* fetchPremiumMemberCodesListAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(getPremiumMemberCodesList(payload))

    yield put(fetchPremiumMemberCodesListSuccess(data?.results))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchPremiumMemberCodesListFailure(err))
    console.error(err)
  }
}

export function* createCollaborationPartnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(createCollaborationPartnerRefer(payload))

    yield put(createCollaborationPartnerSuccess(data?.data))

    yield put(
      fetchCollaborationPartnerStart({
        page: 1,
        limit: 10,
        offset: 0,
      })
    )

    yield put(
      openAlert({ message: 'succesfully created', severity: 'success' })
    )
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )

    yield put(createCollaborationPartnerFailure(err))

    console.error(err)
  }
}

export function* fetchGlobalCollaborationPackageAsync({
  countryId,
  partnerType,
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(
      fetchGlobalCPPackageList(payload?.countryId, payload?.partnerType, null)
    )

    yield put(fetchGlobalCollaborationPackageSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: 'Please select country first',
        severity: 'error',
      })
    )
    yield put(fetchGlobalCollaborationPartnerFailure(err))
    console.error(err)
  }
}

export function* fetchCountryAdminCollaborationPackageAsync({
  payload,
}: AnyAction) {
  try {
    const { data } = yield call(fetchCountryAdminCPPackageList(payload))

    yield put(fetchCountryAdminCollaborationPackageSuccess(data))
  } catch (err) {
    yield put(
      openAlert({
        message: err.message,
        severity: 'error',
      })
    )
    yield put(fetchCountryAdminCollaborationPackageFailure(err))
    console.error(err)
  }
}

export function* fetchCollaborationPartnerByIdAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCollaborationPartnerById(payload))

    yield put(fetchCPByIdSuccess(data))
  } catch (err) {
    yield put(fetchCPByIdFailure(err))
    yield put(clearCPById())
    console.error(err)
  }
}

export function* fetchCPProfileInfoInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchCollaborationPartnerProfileInfo())
    yield put(fetchCPProfileInfoSuccess(data))
  } catch (err) {
    yield put(fetchCPProfileInfoFailure(err))
  }
}

export function* fetchStripeInfoAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(fetchStripeInfo())
    yield put(fetchStripeInfoSuccess(data))
  } catch (err) {
    yield put(fetchStripeInfoFailure(err))
  }
}

export function* editCollaborationPartnerAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(
      editCollaborationPartner(payload?.params?.id, payload?.params)
    )

    yield put(editCollaborationPartnerSuccess(data?.updateResult))

    if (payload?.closeModal) {
      yield payload.closeModal()
    }

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    } else {
      yield put(
        fetchGlobalCollaborationPartnerStart({
          page: 1,
          limit: 10,
          offset: 0,
          isPending: false,
        })
      )
    }

    yield put(
      openAlert({
        message: 'Collaboration Partner succesfully edited',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(editCollaborationPartnerFailure(err))
    console.error(err)
  }
}

export function* editOwnCpProfileAsync({ payload }: AnyAction) {
  try {
    const { data } = yield call(editOwnCpProfile(payload?.params))

    yield put(editOwnCpProfileSuccess(data?.data))

    if (payload?.refreshPageData) {
      yield call(payload?.refreshPageData)
    }

    yield put(
      openAlert({
        message: 'Profile Edited Successfully',
        severity: 'success',
      })
    )
  } catch (err) {
    yield put(openAlert({ message: err.message, severity: 'error' }))
    yield put(editOwnCpProfileFailure(err))
  }
}

export function* watchFetchReferredCollaborationPartner() {
  yield takeLatest(
    'collaborationPartner/fetchCollaborationPartnerStart',
    fetchReferredCollaborationPartnerAsync
  )
}

export function* watchFetchGlobalCollaborationPartner() {
  yield takeLatest(
    'collaborationPartner/fetchGlobalCollaborationPartnerStart',
    fetchGlobalCollaborationPartnerAsync
  )
}

export function* watchCreateCollaborationPartner() {
  yield takeLatest(
    'collaborationPartner/createCollaborationPartnerStart',
    createCollaborationPartnerAsync
  )
}

export function* watchFetchCollaborationPartnerById() {
  yield takeLatest(
    'collaborationPartner/fetchCPByIdStart',
    fetchCollaborationPartnerByIdAsync
  )
}

export function* watchFetchGlobalCollaborationPackage() {
  yield takeLatest(
    'collaborationPartner/fetchGlobalCollaborationPackageStart',
    fetchGlobalCollaborationPackageAsync
  )
}

export function* watchFetchCountryAdminCollaborationPackage() {
  yield takeLatest(
    'collaborationPartner/fetchCountryAdminCollaborationPackageStart',
    fetchCountryAdminCollaborationPackageAsync
  )
}

export function* watchFetchPremiumCodes() {
  yield takeLatest(
    'collaborationPartner/fetchPremiumCodesStart',
    fetchPremiumCodesAsync
  )
}

export function* watchFetchMerchantPremiumCodes() {
  yield takeLatest(
    'collaborationPartner/fetchMerchantPremiumCodesStart',
    fetchMerchantPremiumCodesAsync
  )
}
export function* watchFetchPremiumMerchantCodesList() {
  yield takeLatest(
    'collaborationPartner/fetchPremiumMerchantCodesListStart',
    fetchPremiumMerchantCodesListAsync
  )
}

export function* watchFetchPremiumMerchantCodesGetAll() {
  yield takeLatest(
    'collaborationPartner/fetchPremiumMerchantCodesGetAllStart',
    fetchPremiumMerchantCodesGetAllAsync
  )
}

export function* watchFetchPremiumMemberCodesGetAll() {
  yield takeLatest(
    'collaborationPartner/fetchPremiumMemberCodesGetAllStart',
    fetchPremiumMemberCodesGetAllAsync
  )
}

export function* watchFetchPremiumMemberCodesList() {
  yield takeLatest(
    'collaborationPartner/fetchPremiumMemberCodesListStart',
    fetchPremiumMemberCodesListAsync
  )
}

export function* watchupdatePremiumMerchantCode() {
  yield takeLatest(
    'collaborationPartner/updatePremiumMerchantCodeStart',
    updatePremiumMerchantCodeAsync
  )
}

export function* watchupdatePremiumMemberCode() {
  yield takeLatest(
    'collaborationPartner/updatePremiumMemberCodeStart',
    updatePremiumMemberCodeAsync
  )
}

export function* watchcreatePremiumMemberCode() {
  yield takeLatest(
    'collaborationPartner/createPremiumMemberCodeStart',
    createPremiumMemberCodeAsync
  )
}

export function* watchcreatePremiumMerchantCode() {
  yield takeLatest(
    'collaborationPartner/createPremiumMerchantCodeStart',
    createPremiumMerchantCodeAsync
  )
}

export function* watchFetchCPProfileInfoAsync() {
  yield takeLatest(
    'collaborationPartner/fetchCPProfileInfoStart',
    fetchCPProfileInfoInfoAsync
  )
}

export function* watchFetchStripeInfoAsync() {
  yield takeLatest(
    'collaborationPartner/fetchStripeInfoStart',
    fetchStripeInfoAsync
  )
}

export function* watchEditCP() {
  yield takeLatest(
    'collaborationPartner/editCollaborationPartnerStart',
    editCollaborationPartnerAsync
  )
}

export function* watchEditOwnCpProfile() {
  yield takeLatest(
    'collaborationPartner/editOwnCpProfileStart',
    editOwnCpProfileAsync
  )
}
export function* watchFetchPremiumCodesByPanel() {
  yield takeLatest(
    'collaborationPartner/fetchPremiumCodesByPanelStart',
    fetchPremiumCodesByPanelAsync
  )
}

export function* collaborationPartnerSagas() {
  yield all([
    call(watchFetchReferredCollaborationPartner),
    call(watchFetchGlobalCollaborationPartner),
    call(watchFetchCountryAdminCollaborationPackage),
    call(watchFetchGlobalCollaborationPackage),
    call(watchCreateCollaborationPartner),
    call(watchFetchCollaborationPartnerById),
    call(watchFetchPremiumCodes),
    call(watchFetchMerchantPremiumCodes),
    call(watchFetchPremiumMerchantCodesList),
    call(watchFetchPremiumMerchantCodesGetAll),
    call(watchFetchPremiumMemberCodesGetAll),
    call(watchFetchPremiumMemberCodesList),
    call(watchupdatePremiumMerchantCode),
    call(watchupdatePremiumMemberCode),
    call(watchcreatePremiumMemberCode),
    call(watchcreatePremiumMerchantCode),
    call(watchFetchCPProfileInfoAsync),
    call(watchEditCP),
    call(watchEditOwnCpProfile),
    call(watchFetchPremiumCodesByPanel),
    call(watchFetchStripeInfoAsync),
  ])
}
